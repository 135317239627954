export const uploadProductFileTypes = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const bankStatementFileTypes = {
    'application/pdf': '.pdf',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        '.xlsx',
}

export const auditFinancialReportTypes = {
    'text/csv': '.csv',
    'application/pdf': '.pdf',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        '.xlsx',
}

export const CACFileTypes = {
    'application/pdf': '.pdf',
    'image/jpeg': '.jpeg',
    'image/png': '.png',
    'image/jpg': '.jpg',
}

export const memorandumOfAssociationFileTypes = {
    'application/pdf': '.pdf',
    'image/jpeg': '.jpeg',
    'image/png': '.png',
    'image/jpg': '.jpg',
}

export const employmentContractFileTypes = {
    'application/pdf': '.pdf',
    'image/jpeg': '.jpeg',
    'image/png': '.png',
    'image/jpg': '.jpg',
}

export const zipSupportedbankStatementFileTypes = {
    'application/pdf': '.pdf',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        '.xlsx',
    'application/zip': '.zip',
}
