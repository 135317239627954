import axios from '../../../config/axios'

export const getProductLibraryUsers = async (
    current_page,
    provider_name = '',
    global_access = '',
    startDate = '',
    endDate = '',
    packages_count_start = '',
    packages_count_end = '',
    components_count_start = '',
    components_count_end = '',
) => {
    const request = await axios.get(
        `/admin/overview/products?current_page=${current_page}&provider_name=${provider_name}&global_access=${global_access}&start_date=${startDate}&end_date=${endDate}&packages_count_start=${packages_count_start}&packages_count_end=${packages_count_end}&components_count_start=${components_count_start}&components_count_end=${components_count_end}`,
    )
    return request
}
