import axios from '../../../config/axios'

export const initiateClusterRepLoginApi = async data => {
    const request = await axios.post(
        '/cluster-origination/auth/login/initiate',
        data,
    )
    return request
}

export const verifyClusterRepLoginApi = async data => {
    const request = await axios.post(
        '/cluster-origination/auth/login/validate',
        data,
    )
    return request
}
