import { useQuery } from 'react-query'
import axios from '../../config/axios'

export const getProfileInformationApi = async () => {
    const request = await axios.get('/users/me')
    return request
}

export const getBankDetailsApi = async inputs => {
    const request = await axios.get('/providers/details', inputs)
    return request
}

export const useGetProfileInformation = (options = {}) => {
    return useQuery('profile-info', getProfileInformationApi, { ...options })
}

export const getComponentBrandsApi = async () => {
    const request = await axios.get('/products/component-brands/category')
    return request
}
