import axios from '../../config/axios'

export const addExperienceApi = async inputs => {
  const request = await axios.post(`/providers/work-history`, inputs)
  return request
}

export const getAllExperienceApi = async () => {
  const request = await axios.get(`/providers/work-history`)
  return request
}

export const getAllTechApi = async () => {
  const request = await axios.get(`/providers/technologies`)
  return request
}

export const updateExperienceApi = async (id, inputs) => {
  const request = await axios.patch(`/providers/work-history/${id}`, inputs)
  return request
}

export const deleteExperienceApi = async id => {
  const request = await axios.delete(`/providers/work-history/${id}`)
  return request
}

export const bulkDeleteExperienceApi = async data => {
  const request = await axios.post(`/providers/work-history/bulk-delete`, data)
  
  return request
}

export const bulkSaveExperienceApi = async data => {
  const request = await axios.post(`/providers/work-history/bulk`, data)
  return request
}
