import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import s from '../profilesdetails.module.scss'
import { tableHeaders } from '../data'
import ButtonComponent from 'src/components/Button'
import NairaSymbol from 'src/components/NairaSymbol'
import EmptyState from 'src/components/EmptyState'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import Card from 'src/components/Card'
import { canModifyEnergyProfilePackage } from '../formatter'

const ProfilesPackagesTable = ({
    packages,
    handleEditPackage,
    fetchingPackages,
    paginationBlock,
}) => {
    const { isMobile } = useMediaQueries()
    // eslint-disable-next-line react/prop-types
    const CombinedNaira = ({ amount }) => (
        <span data-testid={`profiles-packages-combined-naira-${amount}`}>
            <NairaSymbol />
            {amount}
        </span>
    )

    return (
        <>
            {fetchingPackages ? (
                <>
                    {Array(6)
                        ?.fill()
                        ?.map((_, i) => (
                            <div
                                key={i}
                                className="ATPTOSNoHeader"
                                data-testid={`profiles-packages-skeleton-${i}`}
                            >
                                <Skeleton
                                    title={{
                                        width: 205,
                                        height: 21,
                                    }}
                                    paragraph={false}
                                />
                                <div>
                                    <Skeleton
                                        title={{ width: 31, height: 21 }}
                                        paragraph={false}
                                    />
                                </div>
                            </div>
                        ))}
                </>
            ) : !fetchingPackages && packages?.count > 0 ? (
                <>
                    {isMobile ? (
                        <div
                            className={s.cards}
                            data-testid="profiles-packages-mobile-cards"
                        >
                            {packages?.data?.map((row, i) => (
                                <Card
                                    key={i}
                                    topLeftHeading={'PACKAGE NAME'}
                                    topLeftContent={row?.name}
                                    bottomLeftHeading={'PACKAGE AMOUNT'}
                                    bottomLeftContent={
                                        <CombinedNaira amount={row?.amount} />
                                    }
                                    handleClick={() => handleEditPackage(row)}
                                    btnText="Edit package"
                                    data-testid={`profiles-packages-edit-btn-mobile-${i}`}
                                />
                            ))}
                        </div>
                    ) : (
                        <table
                            className={s.table}
                            data-testid="profiles-packages-desktop-table"
                        >
                            <tr className={s.tableHeads}>
                                {tableHeaders?.map((header, i) => (
                                    <th key={i}>{header}</th>
                                ))}
                            </tr>
                            {packages?.data?.map((row, i) => (
                                <tr key={i} className={s.tableRow}>
                                    <td>{row?.name}</td>
                                    <td>
                                        <NairaSymbol /> {row?.amount}
                                    </td>
                                    <td>
                                        <ButtonComponent
                                            btnTextColor={'#004AAD'}
                                            btnWidth={'100px'}
                                            btnHeight={'29px'}
                                            btnBgColor={'#FFFFFF'}
                                            handleClick={() =>
                                                handleEditPackage(row)
                                            }
                                            data-testid={`profiles-packages-edit-btn-desktop-${i}`}
                                            disabled={
                                                !canModifyEnergyProfilePackage
                                            }
                                        >
                                            Edit package
                                        </ButtonComponent>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    )}
                </>
            ) : !fetchingPackages && packages?.count === 0 ? (
                <EmptyState
                    title={'No packages found in this consumer profile'}
                />
            ) : null}
            {packages?.total_pages > 1 && !fetchingPackages && (
                <div
                    className={s.pagination_wrapper}
                    data-testid="profiles-packages-pagination"
                >
                    {paginationBlock()}
                </div>
            )}
        </>
    )
}

ProfilesPackagesTable.propTypes = {
    packages: PropTypes.array,
    handleEditPackage: PropTypes.func,
    fetchingPackages: PropTypes.bool,
    paginationBlock: PropTypes.element,
}

export default ProfilesPackagesTable
