/* eslint-disable react/prop-types */
import { useState } from 'react'
import NextArrow from '../../assets/images/next-right-arrow.svg'
import CircularProgressBar from './CircularProgressBar'

import styles from './onboardingstagelist.module.scss'

const OnboardingStageList = ({
    userInfo,
    personalInfoCount,
    creditInfoCount,
    selectSolutionCount,
    personalInformationTab,
    energyInformationTab,
    creditInformationTab,
}) => {
    const {
        Container,
        Title,
        SubText,
        ClearFix,
        StageBox,
        StageTitle,
        StageCompletion,
    } = styles

    const [stages] = useState({
        levels:
            userInfo === 'consumer'
                ? [
                      {
                          id: 1,
                          title: 'Personal Information',
                          completed:
                              personalInfoCount === 11
                                  ? 'done'
                                  : `${personalInfoCount} / 11`,
                          handleClick: personalInformationTab,
                      },
                      {
                          id: 2,
                          title: 'Energy Information',
                          completed: 'done',
                          handleClick: energyInformationTab,
                      },
                      {
                          id: 3,
                          title: 'Credit Information',
                          completed:
                              creditInfoCount === 6
                                  ? 'done'
                                  : `${creditInfoCount} / 6`,
                          handleClick: creditInformationTab,
                      },
                      {
                          id: 4,
                          title: 'Select Solution',
                          completed:
                              selectSolutionCount === 1
                                  ? 'done'
                                  : `${selectSolutionCount}/1`,
                      },
                  ]
                : userInfo?.companyName === 'none'
                ? [
                      {
                          id: 1,
                          title: 'Personal Information',
                          completed: 'done',
                      },
                      {
                          id: 2,
                          title: 'Work Experience',
                          completed: 'done',
                      },
                      {
                          id: 3,
                          title: 'Identity Verification',
                          completed: 'notstarted',
                      },
                      {
                          id: 4,
                          title: 'Guarantors/Referees',
                          completed: 'partial',
                      },
                  ]
                : [
                      {
                          id: 1,
                          title: 'Company’s Information',
                          completed: 'done',
                      },
                      {
                          id: 2,
                          title: 'Contact Information',
                          completed: 'done',
                      },
                      {
                          id: 3,
                          title: 'Verification',
                          completed: 'notstarted',
                      },
                  ],
    })

    const personalInfoCountState =
        personalInfoCount === 11 ? 'Completed' : `${personalInfoCount} / 11`

    const creditInfoCountState =
        creditInfoCount === 6 ? 'Completed' : `${creditInfoCount} / 6`

    const selectedSolutionState =
        selectSolutionCount === 1 ? 'Completed' : `${selectSolutionCount} / 1`

    return (
        <div
            className={Container}
            style={
                userInfo === 'consumer'
                    ? { top: '-380px' }
                    : userInfo?.companyName === 'none'
                    ? { top: '-369px' }
                    : { top: '-320px' }
            }
        >
            <div className={Title}>Complete Your Profile</div>
            <div className={SubText}>
                {userInfo === 'consumer'
                    ? 'To claim your expertly recommended energy solution, kindly complete your profile; personal & credit information'
                    : 'We’ve solved the problems that make it difficult for energy providers to transition millions of consu millions of consumers'}{' '}
            </div>
            <hr className={ClearFix} />
            {stages?.levels?.map((stage, i) => {
                return (
                    <div key={i}>
                        <div className={StageBox}>
                            <div>
                                {stage?.completed === 'done' ||
                                stage?.completed === 'notstarted' ? (
                                    <CircularProgressBar
                                        progress={
                                            stage?.completed === 'done'
                                                ? 100
                                                : 0
                                        }
                                        trackWidth={2}
                                        indicatorWidth={2}
                                        completed={
                                            stage?.completed === 'done'
                                                ? true
                                                : false
                                        }
                                    />
                                ) : (
                                    <CircularProgressBar
                                        progress={
                                            stage?.id === 1
                                                ? personalInfoCount === 11
                                                    ? 100
                                                    : personalInfoCount * 10
                                                : stage?.id === 3
                                                ? creditInfoCount === 6
                                                    ? 100
                                                    : creditInfoCount * 10
                                                : 0
                                        }
                                        trackWidth={2}
                                        indicatorWidth={2}
                                        completed={
                                            stage?.completed === 'done'
                                                ? true
                                                : stage?.id === 1 &&
                                                  personalInfoCount === 11
                                                ? true
                                                : stage?.id === 3 &&
                                                  creditInfoCount === 6
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </div>
                            <div className={StageTitle}>
                                &nbsp;&nbsp; {stage?.title}
                            </div>
                            <div className={StageCompletion}>
                                {stage?.completed === 'done'
                                    ? 'Completed'
                                    : userInfo === 'consumer'
                                    ? stage?.id === 1
                                        ? personalInfoCountState
                                        : stage?.id === 3
                                        ? creditInfoCountState
                                        : selectedSolutionState
                                    : '1/2'}
                                &nbsp;
                                <img src={NextArrow} alt="icon" />
                            </div>
                        </div>
                        <hr className={ClearFix} />
                    </div>
                )
            })}
        </div>
    )
}

export default OnboardingStageList
