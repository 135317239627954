export const packagePaymentTypes = (
    lease_to_own_supported,
    subscription_supported,
    outright_sale_supported,
    showAll = false,
) => {
    const supportedCount = [
        lease_to_own_supported,
        outright_sale_supported,
        subscription_supported,
    ].filter(Boolean).length
    const data = [
        showAll && {
            name: 'All',
            value: 'all',
            disabled: supportedCount >= 2,
        },
        {
            name: 'Lease to Own',
            value: 'Lease to Own',
            disabled: lease_to_own_supported,
        },
        {
            name: 'Subscription',
            value: 'Subscription',
            disabled: subscription_supported,
        },
        {
            name: 'Outright Sale',
            value: 'Outright Sale',
            disabled: outright_sale_supported,
        },
    ]
    const result = data.filter(data => data.disabled)

    return result?.map(item => {
        return {
            name: item.name,
            value: item.value,
        }
    })
}
