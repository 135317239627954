import { useState } from 'react'
import { Pagination } from 'antd'
import useGetProfiles from './useGetProfiles'
import useGetProfilePackages from './useGetProfilePackages'

export const usePullEnergyNeeds = () => {
    const [filterState, setFilterState] = useState({
        currentPage: 1,
        published: '',
        searchText: '',
    })

    const [selectedProfile, setSelectedProfile] = useState(null)
    const [selectedPackage, setSelectedPackage] = useState(null)
    const [selectedEnergyNeeds, setSelectedEnergyNeeds] = useState([])
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [actionModalType, setActionModalType] = useState('')
    const [currentScreen, setCurrentScreen] = useState(1)

    const {
        profiles,
        profilesFetching,
        totalPages: profilePages,
        profilesRefetch,
    } = useGetProfiles(filterState, setFilterState)

    const {
        profilePackages,
        profilePackagesFetching,
        totalPages: packagePages,
    } = useGetProfilePackages(selectedProfile?.id, filterState, setFilterState)

    const handleFilterChange = newFilters => {
        setFilterState(prevState => ({
            ...prevState,
            currentPage: 1,
            ...newFilters,
        }))
    }

    const handleViewProfile = profile => {
        handleFilterChange({ currentPage: 1 })
        setSelectedProfile(profile)
        setCurrentScreen(2)
    }

    const handleViewPackage = pkg => {
        handleFilterChange({ currentPage: 1 })
        setSelectedPackage(pkg)
        setSelectedEnergyNeeds(pkg.appliances || [])
        setCurrentScreen(3)
    }

    const updatePage = page => {
        handleFilterChange({ currentPage: page })
    }

    const paginationBlock = () => {
        let totalPages = profilePages
        let loading = profilesFetching
        let items = profiles

        if (selectedPackage) {
            totalPages = 0
        } else if (selectedProfile) {
            totalPages = packagePages
            loading = profilePackagesFetching
            items = profilePackages
        }

        return (
            <>
                {items?.length > 0 && !loading && (
                    <div className="PaymentPlansListings_Pagination">
                        {totalPages < 2 ? null : (
                            <Pagination
                                defaultCurrent={1}
                                current={filterState?.currentPage}
                                total={parseInt(totalPages, 10)}
                                defaultPageSize={1}
                                showSizeChanger={false}
                                showTitle={false}
                                showLessItems
                                onChange={updatePage}
                            />
                        )}
                    </div>
                )}
            </>
        )
    }

    const handleClose = onClose => {
        setSelectedProfile(null)
        setSelectedPackage(null)
        setCurrentScreen(1)
        onClose && onClose()
    }

    const handleButtonClick = onClose => {
        setFilterState(prev => ({ ...prev, currentPage: 1 }))

        if (selectedPackage) {
            setSelectedPackage(null)
            setCurrentScreen(2)
            profilesRefetch()
        } else if (selectedProfile) {
            setSelectedProfile(null)
            setCurrentScreen(1)
        } else {
            handleClose(onClose)
        }
    }

    const handleUseThisPackageClick = appliances => {
        setSelectedEnergyNeeds(appliances)
        setActionModalType('pull-confirm')
        setActionModalOpen(true)
    }

    const handleActionModalCancel = onClose => {
        switch (actionModalType) {
            case 'pull-success':
                setActionModalType('')
                setActionModalOpen(false)
                handleClose(onClose)
                break

            default:
                setActionModalOpen(false)
                setActionModalType('')
                break
        }
    }

    const actionModalHandler = (onClose, handlePullEnergyNeeds) => {
        switch (actionModalType) {
            case 'pull-confirm':
                setActionModalType('pull-success')
                handlePullEnergyNeeds &&
                    handlePullEnergyNeeds(selectedEnergyNeeds)
                setSelectedEnergyNeeds([])
                break
            case 'pull-success':
                setActionModalType('')
                setActionModalOpen(false)
                handleClose(onClose)
                break

            default:
                break
        }
    }

    return {
        filterState,
        setFilterState,
        selectedPackage,
        selectedProfile,
        setSelectedPackage,
        setSelectedProfile,
        handleButtonClick,
        paginationBlock,
        profiles,
        profilesFetching,
        handleViewProfile,
        handleViewPackage,
        handleClose,
        profilePackages,
        selectedEnergyNeeds,
        setSelectedEnergyNeeds,
        actionModalOpen,
        actionModalHandler,
        actionModalType,
        handleActionModalCancel,
        handleUseThisPackageClick,
        currentScreen,
        profilePackagesFetching,
    }
}
