import NairaSymbol from 'src/components/NairaSymbol'
import { currencyFormatter } from 'src/utils/currencyFormatter'

export const systemInfo = (
    isPromoPage,
    repaymentPlans,
    systemDetails,
    currentTenure,
    paymentPlans,
) => {
    const getValue = propertyName => {
        const result = paymentPlans?.find(item => item.tenure === currentTenure)
        if (result && propertyName in result) {
            return result[propertyName]
        }
    }
    return [
        {
            title: 'Available payment plan',
            key: 'available-plans',
            value: '',
            display: systemDetails?.payment_model === 'Lease to Own',
        },
        {
            title: 'Monthly payment',
            key: 'monthly-payment',
            value: (
                <>
                    <NairaSymbol />
                    {systemDetails?.payment_model === 'Lease to Own'
                        ? getValue('monthlyPayment')
                        : currencyFormatter(
                              isPromoPage
                                  ? repaymentPlans?.[repaymentPlans?.length - 1]
                                        ?.monthly_payment
                                  : systemDetails?.payment_plans?.[
                                        systemDetails?.payment_plans?.length - 1
                                    ]?.monthly_payment,
                          )}
                </>
            ),
            display:
                systemDetails?.payment_model === 'Lease to Own' ||
                systemDetails?.payment_model === 'Subscription',
        },
        {
            title: 'Upfront deposit',
            key: 'upfront-deposit',
            value: (
                <>
                    <NairaSymbol />
                    {getValue('upfrontDeposit')}
                </>
            ),
            display: systemDetails?.payment_model === 'Lease to Own',
        },
        {
            title: 'Total cost of ownership',
            key: 'total-cost',
            value: (
                <>
                    <NairaSymbol />
                    {systemDetails?.payment_model === 'Lease to Own'
                        ? getValue('solutionAmount')
                        : currencyFormatter(systemDetails.total_cost)}
                </>
            ),
            display: true,
        },
        {
            title: 'Aftercare service',
            key: 'aftercare-service',
            value: '',
            display: true,
        },
        {
            title: 'Can last up to',
            key: 'can-last',
            value: `${Math.round(
                systemDetails?.average_backup_hours,
            )}${' hour'}${systemDetails?.average_backup_hours > 1 ? 's' : ''}`,
            display: systemDetails?.average_backup_hours,
        },
    ]
}
