export const repaymentMethods = ['Card Debit', 'Direct Debit']

export const repaymentMethodMap = {
    'Card Debit': 'CARD_DEBIT',
    'Direct Debit': 'DIRECT_DEBIT',
}

export const repaymentMethodReverseMap = {
    CARD_DEBIT: 'Card Debit',
    DIRECT_DEBIT: 'Direct Debit',
}
