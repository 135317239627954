const { useState } = require('react')
import { categories } from './data.js'

const useHelpCenter = () => {
    const [nextPage, setNextPage] = useState(false)
    const [activeCategory, setActiveCategory] = useState(categories[0])
    const [activeAnswer, setActiveAnswer] = useState(0)
    const [helpForm, setHelpForm] = useState(false)
    const [showChatIcon, setShowChatIcon] = useState(true)

    const togglePage = () => {
        setNextPage(!nextPage)
        setHelpForm(false)
    }
    const handleAskQuestion = () => {
        setHelpForm(true)
        setShowChatIcon(false)
    }

    const defaultPage = () => {
        setNextPage(false)
        setHelpForm(false)
        setShowChatIcon(true)
    }

    return {
        nextPage,
        activeCategory,
        setActiveCategory,
        activeAnswer,
        setActiveAnswer,
        helpForm,
        setHelpForm,
        showChatIcon,
        setShowChatIcon,
        togglePage,
        handleAskQuestion,
        defaultPage,
        categories,
    }
}

export default useHelpCenter
