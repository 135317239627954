import PropTypes from 'prop-types'
import ActionModal from 'src/components/ActionModal'

const EditWarrantyModal = ({
    showModal,
    changePackageWarranty,
    handleGoBack,
}) => {
    return (
        <ActionModal
            actionModalOpen={showModal}
            headerText={'Edit warranty details?'}
            subTitle={
                'The selected inverter component and the package do not have the same warranty. Do you want to proceed? Proceeding will override the warranty you have added to match the inverter warranty.'
            }
            closeModal={handleGoBack}
            actionHandler={changePackageWarranty}
            actionText={'Proceed'}
            actionType={'warning'}
            closable={false}
            cancelText={'Go back'}
            noBackLink
        />
    )
}

EditWarrantyModal.propTypes = {
    showModal: PropTypes.bool,
    changePackageWarranty: PropTypes.func,
    handleGoBack: PropTypes.func,
}

export default EditWarrantyModal
