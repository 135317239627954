import { appTracking } from 'src/utils/appTracker'

export const useSelfEmployedAdditionalForm = ({
    setFieldValue,
    removeFromUpdateErrs,
}) => {
    const pageTitle =
        'KYC - Residential Basic Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const handleTracking = ({ name, value }) => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_EMPLOYMENT_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    const handleSelectChange = (name, value) => {
        setFieldValue(name, value)
        removeFromUpdateErrs(name)
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_EMPLOYMENT_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }
    return {
        handleTracking,
        handleSelectChange,
    }
}
