import axios from '../../config/axios'

// convert base64 image to blob
export const dataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',')
    const byteString =
        splitDataURI[0].indexOf('base64') >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0]?.split(':')[1]?.split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}


export const uploadImageApi = async url => {
    const request = await axios.post(`/users/avatar`, url)
    return request
}

export const uploadIdentificationApi = async payload => {
    const request = axios.post('/providers/verification-ids/upload', payload)
    return request
}
