import { ConsumerButton } from 'src/components/Button'
import styles from '../creditdecision.module.scss'
import ChatCTA from 'src/components/ChatCTA'
import { isMobile } from 'src/utils/mediaQueries'
const DecisionIsNo = () => {
    const { IsNoWrapper, isNoLogo, peopleImg, isNoContent } = styles
    return (
        <div className={IsNoWrapper}>
            <div className={isNoLogo}>
                <img
                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Blue.png"
                    alt="SunFi logo"
                />
            </div>

            <div className={peopleImg}>
                <img
                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/learn-energy.svg"
                    alt="two people in a conversation"
                />
            </div>

            <div className={isNoContent}>
                <h1>Thank you for submitting your KYC</h1>
                <p>
                    Thank you for submitting your KYC. We are reviewing your
                    information and will contact you via email within the next
                    48 hours.
                </p>
                <ConsumerButton
                    btnWidth={isMobile ? '184px' : '247px'}
                    btnHeight={'44px'}
                    btnBgColor={'#004AAD'}
                    btnTextColor={'#FFFFFF'}
                    handleClick={() => (window.location.href = '/')}
                >
                    Go back to landing page
                </ConsumerButton>
                <ChatCTA
                    text="Have questions about out process?"
                    justifyContent="center"
                    margin={isMobile ? '33px 0 0 0' : '23px 0 0 0'}
                />
            </div>
        </div>
    )
}
export default DecisionIsNo
