import { Skeleton } from 'antd'
import './tabpaneandtableskeletonone.scss'

const TabPaneAndTableOneSkeleton = () => {
    return (
        <>
            <div className="ATPTOSTabs">
                <Skeleton
                    title={{ width: 112, height: 21 }}
                    paragraph={false}
                />
                <Skeleton
                    title={{ width: 112, height: 21 }}
                    paragraph={false}
                />
                <Skeleton title={{ width: 33, height: 21 }} paragraph={false} />
            </div>
            <div className="ATPTOSBorder">
                <div className="ATPTOSBorder1"></div>
                <div className="ATPTOSBorder2"></div>
            </div>
            <div>
                <div className="ATPTOSNoHeader">
                    <Skeleton
                        title={{ width: 112, height: 21 }}
                        paragraph={false}
                    />
                    <div>
                        <Skeleton
                            title={{ width: 182, height: 21 }}
                            paragraph={false}
                            marginLeft={0}
                        />
                    </div>
                </div>

                <div className="ATPTOSNoHeader">
                    <Skeleton
                        title={{ width: 112, height: 21 }}
                        paragraph={false}
                    />

                    <div>
                        <Skeleton
                            title={{ width: 120, height: 21 }}
                            paragraph={false}
                        />
                    </div>
                </div>

                <div className="ATPTOSNoHeader">
                    <Skeleton
                        title={{ width: 143, height: 21 }}
                        paragraph={false}
                    />
                    <div>
                        <div>
                            <Skeleton
                                title={{
                                    width: 143,
                                    height: 187,
                                }}
                                paragraph={false}
                            />
                        </div>
                    </div>
                </div>

                <div className="ATPTOSNoHeader">
                    <Skeleton
                        title={{
                            width: 205,
                            height: 21,
                        }}
                        paragraph={false}
                    />
                    <div>
                        <Skeleton
                            title={{ width: 31, height: 21 }}
                            paragraph={false}
                        />
                    </div>
                </div>

                <div className="ATPTOSNoHeader">
                    <Skeleton
                        title={{ width: 33, height: 21 }}
                        paragraph={false}
                    />
                    <div>
                        <Skeleton
                            title={{ width: 33, height: 21 }}
                            paragraph={false}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TabPaneAndTableOneSkeleton
