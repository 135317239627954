import { appTracking } from 'src/utils/appTracker'
import { consumerBuildCustomSystemEvents } from 'src/utils/mp-event-tracker/consumer-build-custom-system'

export const pageUrlName = window.location.pathname
export const pageTitle =
    'View System | SunFi | Simplifying and Scaling Clean Energy'
export const pageTracker = 'VIEW_SOLAR_SYSTEM'

const handleAppTracking = event => {
    appTracking(
        pageUrlName,
        pageTracker,
        pageTitle,
        consumerBuildCustomSystemEvents[event].action,
        consumerBuildCustomSystemEvents[event].label,
        consumerBuildCustomSystemEvents[event].category,
        ['MP', 'GA'],
        'event',
    )
}

export const handleCustomBuildViewCountTracking = packageViewCount => {
    if (packageViewCount > 10) {
        handleAppTracking('viewedGreaterThanTenPackages')
    } else if (packageViewCount >= 9) {
        handleAppTracking('viewedTenPackages')
    } else if (packageViewCount >= 7) {
        handleAppTracking('viewedEightPackages')
    } else if (packageViewCount >= 5) {
        handleAppTracking('viewedSixPackages')
    } else if (packageViewCount >= 3) {
        handleAppTracking('viewedFourPackages')
    } else if (packageViewCount >= 1) {
        handleAppTracking('viewedTwoPackages')
    }
}

export const getStartedTrackingForCustomBuild = isCustomBuild => {
    if (isCustomBuild) {
        handleAppTracking('clicksGetStartedOnPackage')
    }
}
