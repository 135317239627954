import '../gettingstarted.scss'
import { ReactComponent as SuccessIcon } from 'src/assets/images/successImage.svg'

const DealApproved = () => {
    return (
        <div
            className={'GettingStartedEmailSentWrapper ForDealApproved'}
            data-testid="deal-approved-wrapper"
        >
            <SuccessIcon
                className={'GettingStartedDealApprovedImg'}
                data-testid="success-icon"
            />
            <h2 data-testid="redirect-heading">
                You will now be redirected to the checkout page
            </h2>
            <p data-testid="checkout-description">
                On the checkout page, you&apos;ll be required to share some
                basic information with us and then proceed to make payment.
            </p>
        </div>
    )
}

export default DealApproved
