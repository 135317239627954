import PropTypes from 'prop-types'
import { InputFields } from 'src/components/InputFields'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Toast from 'src/components/Toast'
import Label from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/_components/Label'
import { usePersonalInfoContext } from '../../../../context'

const ModalContent = ({ toastMessage, setToastMessage }) => {
    const { employmentInfoFormik } = usePersonalInfoContext()
    const { values, errors, touched, handleChange, handleBlur } =
        employmentInfoFormik
    return (
        <div className="RegisteredBusiness_CACMOdalContent-Wrapper">
            <div>
                {toastMessage?.length > 0 && (
                    <div style={{ position: 'relative' }}>
                        <VerticalSpacer top="24px" />
                        <Toast
                            messageType={'error-secondary'}
                            message={toastMessage}
                            setToast={setToastMessage}
                            closable={true}
                            position="absolute"
                        />
                    </div>
                )}
                <Label
                    title="Confirm your business name"
                    subTitle="Please confirm your registered name before continuing"
                />
                <InputFields
                    title="Business Name"
                    name="businessName"
                    value={values?.businessName}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={touched?.businessName && errors?.businessName}
                />
                <VerticalSpacer top="32px" />
                <Label
                    title="Confirm your CAC number"
                    subTitle="Please confirm your CAC number before continuing"
                />
                <InputFields
                    title="CAC Number"
                    name="cacNumber"
                    value={values?.cacNumber}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={touched.cacNumber && errors.cacNumber}
                />
            </div>
        </div>
    )
}

ModalContent.propTypes = {
    toastMessage: PropTypes.string,
    setToastMessage: PropTypes.func,
}

export default ModalContent
