import { convertToFloatWithoutCommas } from 'src/utils/formatting'
import axios from '../../config/axios'
import axiosv2 from '../../config/axiosv2'
import { dataURIToBlob } from 'src/utils/imageBlob'

export const postAuthenticatedDropOffApi = async (
    consumerResponse,
    offerToken,
) => {
    let formData = new FormData()
    formData.append('first_name', consumerResponse.first_name)
    formData.append('last_name', consumerResponse.last_name)
    formData.append('email', consumerResponse.email)
    formData.append('phone_number', consumerResponse.phone_number)
    formData.append('street_address', consumerResponse.street_address)
    formData.append('city', consumerResponse.city)
    formData.append('state_of_residence', consumerResponse.state_of_residence)
    formData.append('residence_type', consumerResponse.residence_type)
    formData.append('can_mount_solar', consumerResponse.can_mount_solar)
    formData.append(
        'employment_status',
        consumerResponse.employment_status ?? 'Unemployed',
    )
    formData.append('reason', consumerResponse.reason)
    formData.append('backup_hours', consumerResponse.backup_hours)
    formData.append(
        'gets_required_grid_hours',
        consumerResponse.gets_required_grid_hours,
    )
    formData.append('appliances', JSON.stringify(consumerResponse.appliances))
    formData.append('medium', consumerResponse.medium)
    formData.append('actor_type', consumerResponse.actor_type)
    consumerResponse.employer_business_name !== undefined &&
        formData.append(
            'employer_business_name',
            consumerResponse.employer_business_name,
        )
    consumerResponse.employer_business_registration_number !== undefined &&
        formData.append(
            'employer_business_registration_number',
            consumerResponse.employer_business_registration_number,
        )
    consumerResponse?.income_amount_range !== undefined &&
        formData.append(
            'income_amount_range',
            consumerResponse.income_amount_range,
        )
    consumerResponse.business_registration_number !== undefined &&
        formData.append(
            'business_registration_number',
            consumerResponse.business_registration_number,
        )
    consumerResponse?.business_name !== undefined &&
        formData.append('business_name', consumerResponse.business_name)

    consumerResponse?.identity_verification?.type !== undefined &&
        formData.append(
            'identity_verification',
            JSON.stringify(consumerResponse.identity_verification),
        )
    consumerResponse?.mono_code !== undefined &&
        formData.append('mono_code', consumerResponse.mono_code)
    const header = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${offerToken}`,
    }
    const request = await axios.post(`/consumers/offer/drop-off`, formData, {
        header,
    })
    return request
}
export const postUnauthenticatedDropOffApi = async consumerResponse => {
    let formData = new FormData()
    formData.append('first_name', consumerResponse.first_name)
    formData.append('last_name', consumerResponse.last_name)
    formData.append('email', consumerResponse.email)
    formData.append('phone_number', consumerResponse.phone_number)
    formData.append('street_address', consumerResponse.street_address)
    formData.append('city', consumerResponse.city)
    formData.append('state_of_residence', consumerResponse.state_of_residence)
    formData.append('residence_type', consumerResponse.residence_type)
    formData.append('can_mount_solar', consumerResponse.can_mount_solar)
    formData.append(
        'employment_status',
        consumerResponse.employment_status ?? 'Unemployed',
    )
    formData.append('reason', consumerResponse.reason)
    formData.append('backup_hours', consumerResponse.backup_hours)
    formData.append(
        'gets_required_grid_hours',
        consumerResponse.gets_required_grid_hours,
    )
    formData.append('appliances', JSON.stringify(consumerResponse.appliances))
    formData.append('medium', consumerResponse.medium)
    formData.append('actor_type', consumerResponse.actor_type)
    consumerResponse.employer_business_name !== undefined &&
        formData.append(
            'employer_business_name',
            consumerResponse.employer_business_name,
        )
    consumerResponse.employer_business_registration_number !== undefined &&
        formData.append(
            'employer_business_registration_number',
            consumerResponse.employer_business_registration_number,
        )
    consumerResponse?.income_amount_range !== undefined &&
        formData.append(
            'income_amount_range',
            consumerResponse.income_amount_range,
        )
    consumerResponse.business_registration_number !== undefined &&
        formData.append(
            'business_registration_number',
            consumerResponse.business_registration_number,
        )
    consumerResponse?.business_name !== undefined &&
        formData.append('business_name', consumerResponse.business_name)

    consumerResponse?.identity_verification?.type !== undefined &&
        formData.append(
            'identity_verification',
            JSON.stringify(consumerResponse.identity_verification),
        )
    consumerResponse?.mono_code !== undefined &&
        formData.append('mono_code', consumerResponse.mono_code)

    const header = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(`/consumers/offer/drop-off`, formData, {
        header,
    })
    return request
}
export const postConsumerOfferApi = async (consumerResponse, offerToken) => {
    let formData = new FormData()
    formData.append('first_name', consumerResponse.first_name)
    formData.append('last_name', consumerResponse.last_name)
    formData.append('email', consumerResponse.email)
    formData.append('phone_number', consumerResponse.phone_number)
    formData.append('street_address', consumerResponse.street_address)
    formData.append('city', consumerResponse.city)
    formData.append('state_of_residence', consumerResponse.state_of_residence)
    formData.append('residence_type', consumerResponse.residence_type)
    formData.append('can_mount_solar', consumerResponse.can_mount_solar)
    formData.append('backup_hours', consumerResponse.backup_hours)
    formData.append(
        'gets_required_grid_hours',
        consumerResponse.gets_required_grid_hours,
    )
    formData.append('appliances', JSON.stringify(consumerResponse.appliances))
    formData.append('income_amount_range', consumerResponse.income_amount_range)
    consumerResponse.employment_status !== undefined &&
        formData.append('employment_status', consumerResponse.employment_status)
    consumerResponse.employer_business_name !== undefined &&
        formData.append(
            'employer_business_name',
            consumerResponse.employer_business_name,
        )
    consumerResponse.employer_business_registration_number !== undefined &&
        formData.append(
            'employer_business_registration_number',
            consumerResponse.employer_business_registration_number,
        )
    consumerResponse.business_registration_number !== undefined &&
        formData.append(
            'business_registration_number',
            consumerResponse.business_registration_number,
        )
    consumerResponse?.business_name !== undefined &&
        formData.append('business_name', consumerResponse.business_name)

    consumerResponse?.identity_verification?.type !== undefined &&
        formData.append(
            'identity_verification',
            JSON.stringify(consumerResponse.identity_verification),
        )
    consumerResponse?.mono_code !== undefined &&
        formData.append('mono_code', consumerResponse.mono_code)

    const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${offerToken}`,
    }

    const request = await axios.post(`/consumers/offer`, formData, {
        headers,
    })
    return request
}

export const postThirdPartyOfferApi = async consumerResponse => {
    let formData = new FormData()
    formData.append('first_name', consumerResponse.first_name)
    formData.append('last_name', consumerResponse.last_name)
    formData.append('email', consumerResponse.email)
    formData.append('phone_number', consumerResponse.phone_number)
    formData.append('street_address', consumerResponse.street_address)
    formData.append('city', consumerResponse.city)
    formData.append('state_of_residence', consumerResponse.state_of_residence)
    formData.append('residence_type', consumerResponse.residence_type)
    formData.append('can_mount_solar', consumerResponse.can_mount_solar)
    formData.append('backup_hours', consumerResponse.backup_hours)
    formData.append('appliances', JSON.stringify(consumerResponse.appliances))
    formData.append('income_amount_range', consumerResponse.income_amount_range)
    formData.append(
        'gets_required_grid_hours',
        consumerResponse.gets_required_grid_hours,
    )
    consumerResponse.employment_status !== undefined &&
        formData.append('employment_status', consumerResponse.employment_status)
    consumerResponse.employer_business_name !== undefined &&
        formData.append(
            'employer_business_name',
            consumerResponse.employer_business_name,
        )
    consumerResponse.employer_business_registration_number !== undefined &&
        formData.append(
            'employer_business_registration_number',
            consumerResponse.employer_business_registration_number,
        )

    consumerResponse?.business_registration_number !== undefined &&
        formData.append(
            'business_registration_number',
            consumerResponse.business_registration_number,
        )
    consumerResponse?.business_name !== undefined &&
        formData.append('business_name', consumerResponse.business_name)
    consumerResponse?.identity_verification?.type !== undefined &&
        formData.append(
            'identity_verification',
            JSON.stringify(consumerResponse.identity_verification),
        )

    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    const request = await axios.post(`/consumers/offer/third-party`, formData, {
        headers,
    })
    return request
}

export const validateExistingConsumerApi = async inputs => {
    const request = await axios.post('/consumers/validation/existing', inputs)
    return request
}

export const validateClusterCodeApi = async inputs => {
    const request = await axios.post(
        '/consumers/offer/validate-cluster-code',
        inputs,
    )
    return request
}

export const getAppliances = async () => {
    const request = await axios.get('/providers/appliances?paginate=false')
    return request
}

export const postConsumerAvatarApi = async (id, Avatar) => {
    let formData = new FormData()
    formData.append('image', Avatar.avatar, 'avatar.jpeg')
    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    const request = await axios.post(
        `/estimations/mini/consumer-conversation/${id}/upload-avatar`,
        formData,
        {
            headers,
        },
    )
    return request
}

export const postConsumerOfferApiV2 = async consumerResponse => {
    const isPromo = consumerResponse.promo_type.length > 0

    let formData = new FormData()
    formData.append('first_name', consumerResponse.first_name)
    formData.append('last_name', consumerResponse.last_name)
    formData.append('email', consumerResponse.email)
    formData.append('phone_number', consumerResponse.phone_number)
    consumerResponse.cluster_code?.length > 0 &&
        consumerResponse.cluster_code !== "No, I don't" &&
        formData.append('cluster_code', consumerResponse.cluster_code)
    formData.append('income_amount_range', consumerResponse.income_amount_range)
    formData.append(
        'generator_maintenance_expense',
        consumerResponse.generator_maintenance_expense ===
            'I don’t have a generator'
            ? 0
            : convertToFloatWithoutCommas(
                  consumerResponse.generator_maintenance_expense,
              ),
    )
    if (isPromo) {
        const file = dataURIToBlob(consumerResponse.avatar)
        // TODO: HOT-FIX: Ensure to remove this when we can support same packages within the same promo flow.
        let package_id = consumerResponse.package_id
        ;(package_id =
            package_id === 'bafb6b96c8e74ce48d85cdfcd9caa07fblank'
                ? 'bafb6b96c8e74ce48d85cdfcd9caa07f'
                : package_id),
            formData.append('package_id', package_id)
        formData.append('promo_type', consumerResponse.promo_type)
        if (
            !(
                consumerResponse.promo_tenure === null ||
                consumerResponse.promo_tenure === undefined
            )
        ) {
            formData.append('promo_tenure', consumerResponse.promo_tenure)
        }
        formData.append('street_address', consumerResponse.street_address)
        formData.append(
            'identity_verification',
            JSON.stringify({
                type: consumerResponse.identity_type,
                number: consumerResponse.identity_number,
            }),
        )
        formData.append('avatar', file, 'avatar.jpg')
        formData.append(
            'guarantors',
            JSON.stringify(consumerResponse.guarantors),
        )
    } else {
        formData.append(
            'state_of_installation',
            consumerResponse.state_of_installation,
        )
        formData.append(
            'appliances',
            JSON.stringify(consumerResponse.appliances),
        )
        formData.append(
            'energy_needs_remark',
            consumerResponse.energy_needs_remark,
        )
        formData.append(
            'feasible_monthly_payment',
            convertToFloatWithoutCommas(
                consumerResponse.feasible_monthly_payment,
            ),
        )
        formData.append(
            'feasible_repayment_tenure',
            consumerResponse.feasible_repayment_tenure.split(' ')[0],
        )
        formData.append('can_mount_solar', consumerResponse.can_mount_solar)
        formData.append(
            'gets_required_grid_hours',
            consumerResponse.gets_required_grid_hours,
        )
    }

    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    const request = await axiosv2.post(`/consumers/offer`, formData, {
        headers,
    })
    return request
}
