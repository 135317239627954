import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import styles from 'src/components/LoginMethodsModal/loginmethodsmodal.module.scss'
import { InputFields } from 'src/components/InputFields'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { bvnRegex } from 'src/utils/validationSchema'
import { formatBvnVerifyMethods } from '../data'
import {
    fetchBVNDetailsApi,
    initiateBVNLookupApi,
    verifyBVNLookupApi,
    verifyBVNApi,
} from 'src/api/shoppingExperience/business-verification'
import Verify from 'src/components/GettingStarted/components/Verify'
import { otpSchema } from 'src/utils/validationSchema'
import Toast from 'src/components/Toast'
import { errorHandler } from 'src/utils/errorHandler'
import OTPIcon from 'src/assets/images/OTP-blue.svg'
import OTPWhiteIcon from 'src/assets/images/OTP.svg'
import userIcon from 'src/assets/images/user-check.svg'
import userWhiteIcon from 'src/assets/images/user-check-white.svg'
import { ReactComponent as GoBackIcon } from 'src/assets/images/blue-circle-left.svg'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'
import VerificationFailedContent from './verificationHelp'
import { formatInputValue } from 'src/utils/formatting'

const VerifyBVNModal = ({
    showModal,
    closeBVNModal,
    storedBvn,
    bvnVerifiedSuccessfully,
    userType,
    storedFirstName,
    storedLastName,
    storedEmail,
    storedPhoneNumber,
    handleBvnVerifyLater,
    setVerificationInProgress,
}) => {
    const [step, setStep] = useState(1)
    const [bvn, setBvn] = useState(null)
    const [error, setError] = useState(undefined)
    const [methods, setMethods] = useState([])
    const [method, setMethod] = useState('')
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)
    const [resent, setResent] = useState(false)
    const [showToast, setShowToast] = useState(true)
    const [toastMessage, setToastMessage] = useState('')
    const [isOTPVerificationMethod, setIsOTPVerificationMethod] = useState('')
    const [inputs, setInputs] = useState({ firstName: '', lastName: '' })
    const [verificationFailed, setVerificationFailed] = useState(false)

    const { wrapper, methodDiv, methodDivActive, methodIcon, methodText } =
        styles

    const initiateLookupMutation = useMutation({
        mutationFn: () => {
            return initiateBVNLookupApi({ bvn: bvn })
        },
        onSuccess: res => {
            const data = formatBvnVerifyMethods(res?.data?.data)
            setMethods(data)
            setStep(3)
        },
        onError: res => {
            setShowToast(true)
            setToastMessage(errorHandler(res))
        },
    })
    const verificationMethodPayload = [
        {
            title: 'Verify without OTP',
            description: 'Verify your BVN with your first and last names',
            icon: isOTPVerificationMethod === 'no' ? userWhiteIcon : userIcon,
            key: 'no',
        },
        {
            title: 'Verify with OTP',
            description:
                'An OTP will be sent to the email or phone no registered to your BVN',
            icon: isOTPVerificationMethod === 'yes' ? OTPWhiteIcon : OTPIcon,
            key: 'yes',
        },
    ]

    const handleVerificationMethod = key => {
        setIsOTPVerificationMethod(key)
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
    }

    const handleVerifyLookupMutation = useMutation({
        mutationFn: () => {
            return verifyBVNLookupApi({ method: method })
        },
        onSuccess: () => {
            setStep(5)
        },
        onError: res => {
            setShowToast(true)
            setToastMessage(errorHandler(res))
        },
    })

    const bvnVerifyPayload = {
        number: bvn,
        first_name: inputs.firstName,
        last_name: inputs.lastName,
    }

    // verify bvn without otp
    const handleVerifyBVNMutation = useMutation({
        mutationFn: () => {
            return verifyBVNApi(bvnVerifyPayload)
        },
        onSuccess: data => {
            // if api call is successful but its not verified there's a probabilty there's a third party error
            if (data?.data?.data?.verified) {
                bvnVerifiedSuccessfully()
            } else if (data?.data?.data?.ivs_server_error) {
                setVerificationFailed(true)
            } else if (
                data?.data?.data?.error_message.includes(
                    'issues initiating BVN verification with OTP',
                )
            ) {
                setVerificationFailed(true)
            } else {
                setShowToast(true)
                setToastMessage(data?.data?.data?.error_message)
            }
        },
        onError: res => {
            setShowToast(true)
            setToastMessage(errorHandler(res))
        },
    })

    // verify bvn with otp
    const fetchBVNMutation = useMutation({
        mutationFn: () => {
            return fetchBVNDetailsApi({ otp: otp.join('') })
        },
        onSuccess: data => {
            // if api call is successful but its not verified there's a probabilty there's a third party error
            if (data?.data?.data?.result) {
                bvnVerifiedSuccessfully()
            } else {
                setVerificationFailed(true)
            }
        },
        onError: res => {
            setShowToast(true)
            setToastMessage(errorHandler(res))
        },
    })

    const bvnHelpPayload = {
        name: inputs?.firstName + ' ' + inputs?.lastName,
        phone_number: storedPhoneNumber,
        email: storedEmail,
        message: 'BVN Verification failed and consumer is requesting for help',
    }

    const onCancel = () => {
        setStep(1)
        setBvn(storedBvn)
        closeBVNModal()
        setIsOTPVerificationMethod('')
        setVerificationFailed(false)
        setOtp(['', '', '', '', '', ''])
        setShowToast(false)
    }

    const handleInitiateLookup = () => {
        if (bvnRegex.test(bvn)) {
            setError(undefined)
            setStep(2)
        } else {
            setError('Please enter a valid BVN')
        }
    }

    const handleBlur = () => {
        if (bvnRegex.test(bvn)) {
            setError(undefined)
        } else {
            setError('Please enter a valid BVN')
        }
    }

    const handleSelectMethod = key => {
        setMethod(key)
    }

    // submit otp
    const handleOtpSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                fetchBVNMutation.mutate()
            })
            .catch(() => {
                setOtpError(true)
            })
    }

    useEffect(() => {
        setBvn(storedBvn)
        setInputs(prev => ({
            ...prev,
            firstName: storedFirstName,
            lastName: storedLastName,
        }))
    }, [storedBvn, storedFirstName, storedLastName])

    useOTPVerify({ otp, handleOtpSubmit })

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={onCancel}
            showFooter
            v2
            modalWidth={'475px'}
            title={
                verificationFailed
                    ? 'Unable to verify'
                    : step === 1
                    ? 'Confirm your BVN'
                    : step === 2 || step === 3
                    ? 'How do you want to verify your BVN?'
                    : step === 4
                    ? 'Confirm your details'
                    : 'Verify your BVN'
            }
            content={
                <>
                    {showToast && (
                        <Toast
                            message={toastMessage}
                            messageType={'error-secondary'}
                            position={'absolute'}
                            top={30}
                            closable={true}
                        />
                    )}
                    {verificationFailed ? (
                        <VerificationFailedContent
                            payload={bvnHelpPayload}
                            onCancel={onCancel}
                            setVerificationInProgress={
                                setVerificationInProgress
                            }
                            setToastMessage={setToastMessage}
                            setShowToast={setShowToast}
                            type={'bvn'}
                        />
                    ) : step === 1 ? (
                        <div className="SSEBusinessVerification_BVNModal_StepOne">
                            <h4>Confirm your BVN</h4>
                            <p>Please confirm your BVN before continuing</p>
                            <InputFields
                                name={'bvn'}
                                title={'Bank Verification number'}
                                value={bvn}
                                handleChange={e => setBvn(e.target.value)}
                                onBlur={handleBlur}
                                errorMessage={error}
                            />
                        </div>
                    ) : step === 2 ? (
                        <div className={wrapper}>
                            {verificationMethodPayload?.map((each, i) => (
                                <div
                                    key={i}
                                    role="button"
                                    className={`${methodDiv} ${
                                        isOTPVerificationMethod === each?.key
                                            ? methodDivActive
                                            : ''
                                    }`}
                                    onClick={() =>
                                        handleVerificationMethod(each?.key)
                                    }
                                >
                                    <div className={methodIcon}>
                                        <img src={each?.icon} />
                                    </div>
                                    <div className={methodText}>
                                        <h4>{each?.title}</h4>
                                        <p>{each?.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : step === 3 ? (
                        <div className={wrapper}>
                            <div
                                className="ASOGoBackBtn"
                                style={{ marginBottom: '10px' }}
                                onClick={() => setStep(2)}
                            >
                                <GoBackIcon />
                                <span className="ConsumerBackButtonSpan">
                                    Go back
                                </span>
                            </div>
                            {methods?.map((each, i) => (
                                <div
                                    key={i}
                                    role="button"
                                    className={`${methodDiv} ${
                                        method === each?.key
                                            ? methodDivActive
                                            : ''
                                    }`}
                                    onClick={() =>
                                        handleSelectMethod(each?.key)
                                    }
                                >
                                    <div className={methodIcon}>
                                        {each?.icon}
                                    </div>
                                    <div className={methodText}>
                                        <h4>{each?.title}</h4>
                                        <p>{each?.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : step === 4 ? (
                        <section className="SSEBusinessVerification_BVNModal_StepOne">
                            <div>
                                <h4>
                                    {userType === 'business'
                                        ? "Director's first name"
                                        : 'Your first name'}
                                </h4>
                                <p>
                                    {userType === 'business'
                                        ? "Confirm this is the Director's first name as it is on the BVN"
                                        : 'Confirm this is your first name as it is on your BVN'}
                                </p>
                                <InputFields
                                    name={'firstName'}
                                    title={'First name'}
                                    value={inputs.firstName}
                                    handleChange={handleInputChange}
                                    errorMessage={error}
                                />
                            </div>
                            <div>
                                <h4>
                                    {userType === 'business'
                                        ? "Director's surname"
                                        : 'Your surname'}
                                </h4>
                                <p>
                                    {userType === 'business'
                                        ? "Confirm this is the Director's surname as it is on the BVN"
                                        : 'Confirm this is your surname as it is on your BVN'}
                                </p>
                                <InputFields
                                    name={'lastName'}
                                    title={'Surname'}
                                    value={inputs.lastName}
                                    handleChange={handleInputChange}
                                    errorMessage={error}
                                />
                            </div>
                        </section>
                    ) : (
                        <Verify
                            contact={method}
                            otp={otp}
                            setOtp={setOtp}
                            otpError={otpError}
                            inputs={{ email: '', phone: '' }}
                            handleResendOtp={() =>
                                handleVerifyLookupMutation.mutate()
                            }
                            resent={resent}
                            setResent={setResent}
                            heading={`Verify your BVN through your ${
                                method === 'email'
                                    ? 'email address'
                                    : 'phone number'
                            }`}
                            bvnVerify={true}
                            handleBVNVerify={() => {
                                setIsOTPVerificationMethod('no')
                                setStep(4)
                            }}
                        />
                    )}
                </>
            }
            primaryBtnText={
                verificationFailed
                    ? 'Try again'
                    : step === 1
                    ? 'Confirm'
                    : step === 2
                    ? 'Continue'
                    : step === 4
                    ? 'Confirm'
                    : 'Verify BVN'
            }
            handlePrimaryBtnClick={() => {
                if (step === 1) {
                    handleInitiateLookup()
                }
                if (step === 2) {
                    if (isOTPVerificationMethod === 'yes') {
                        initiateLookupMutation.mutate()
                    } else {
                        setStep(4)
                    }
                }
                if (step === 3) {
                    handleVerifyLookupMutation.mutate()
                }
                if (step === 4) {
                    handleVerifyBVNMutation.mutate()
                }
                if (step === 5) {
                    handleOtpSubmit()
                }
            }}
            primaryBtnDisabled={
                (step === 1 && bvn?.length < 11) ||
                (step === 2 && isOTPVerificationMethod === '') ||
                (step === 3 && method === '') ||
                (step === 4 &&
                    (inputs.firstName === '' || inputs.lastName === ''))
            }
            primaryBtnLoading={
                initiateLookupMutation.isLoading ||
                handleVerifyLookupMutation.isLoading ||
                fetchBVNMutation.isLoading ||
                handleVerifyBVNMutation.isLoading
            }
            secondaryBtnText="I'll verify later"
            secondaryBtnHeight="53px"
            secondaryBtnWidth="160px"
            handleSecondaryBtnClick={() => {
                setStep(1)
                handleBvnVerifyLater()
            }}
            footerLeftContent={
                step === 5 && fetchBVNMutation.isLoading === true
                    ? 'chat'
                    : (step === 1 || step === 5) && !verificationFailed
                    ? 'button'
                    : 'chat'
            }
        />
    )
}

VerifyBVNModal.propTypes = {
    showModal: PropTypes.bool,
    setShowBVNModal: PropTypes.func,
    closeBVNModal: PropTypes.func,
    storedBvn: PropTypes.string,
    bvnVerifiedSuccessfully: PropTypes.func,
    userType: PropTypes.string,
    storedFirstName: PropTypes.string,
    storedLastName: PropTypes.string,
    storedEmail: PropTypes.string,
    storedPhoneNumber: PropTypes.string,
    handleBvnVerifyLater: PropTypes.func,
    setVerificationInProgress: PropTypes.func,
}

export default VerifyBVNModal
