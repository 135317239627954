import axios from '../../config/axios'

export const companyInfoApi = async info => {
  const request = await axios.patch('/providers/company-profile', info)
  return request
}

export const companyContactApi = async contact => {
  const request = await axios.post('/providers/contact-information', contact)
  return request
}

export const getDisiOemiInfoApi = async inputs => {
  const request = await axios.get('/providers/details', inputs)
  return request
}

export const changeDisiOemiPasswordApi = async inputs => {
  const request = await axios.post('/users/me/change-password', inputs)
  return request
}
