import PropTypes from 'prop-types'
import ButtonComponent from 'src/components/Button'
import s from './disputecreditdecisionmodal.module.scss'
import { disputeCreditDecisionModalData } from './data'

const DisputeCreditDecionModal = ({
    setDisputeCreditEngine,
    setShowManualDispute,
}) => {
    const handleContinue = () => {
        const selectedValue = Array.from(
            document.getElementsByName('dispute_modal_type'),
        )?.find(item => item.checked === true)?.value

        switch (selectedValue) {
            case '1':
                setDisputeCreditEngine(true)
                break
            case '2':
                setShowManualDispute(true)
                break

            default:
                setDisputeCreditEngine(true)
        }
    }

    return (
        <div className={s.dispute_modal_wrapper}>
            <div>
                <h2>Dispute credit decision</h2>
                <h3>Select the type of dispute</h3>
            </div>
            <div>
                {disputeCreditDecisionModalData?.map(data => (
                    <div key={data.id}>
                        <input
                            type="radio"
                            name="dispute_modal_type"
                            id={data.id}
                            value={data.id}
                        />
                        <label htmlFor={data.id} className={''}>
                            <div>{data.icon}</div>
                            <div>
                                <h3>{data.title}</h3>
                                <p>{data.subTitle}</p>
                            </div>
                        </label>
                    </div>
                ))}
            </div>
            <ButtonComponent
                btnBgColor={'#004AAD'}
                btnWidth={'160px'}
                btnHeight={'56px'}
                btnTextColor={'#FFFFFF'}
                handleClick={handleContinue}
            >
                Continue
            </ButtonComponent>
        </div>
    )
}

DisputeCreditDecionModal.propTypes = {
    setDisputeCreditEngine: PropTypes.func,
    setShowManualDispute: PropTypes.func,
}

export default DisputeCreditDecionModal
