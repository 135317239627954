import { useState } from 'react'
import { useQuery } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { getProfilePackagesApi } from 'src/api/admin/profiles/details'

const useGetProfilePackages = (id, filterState, setFilterState) => {
    const [profilePackages, setProfilePackages] = useState([])
    const [totalPages, setTotalPages] = useState(null)

    const { errorAlert } = useCustomToast()

    const { isFetching, refetch: profilePackagesRefetch } = useQuery({
        queryKey: ['fetch-profile-packages', filterState?.currentPage],
        queryFn: () => getProfilePackagesApi(id, filterState?.currentPage),
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        enabled: !!id,
        onSuccess: res => {
            const data = res?.data?.data
            setProfilePackages(data)
            setTotalPages(res?.data?.total_pages)
        },
        onError: error => {
            if (error.response.data.message === 'Invalid page.') {
                setFilterState(prevState => ({
                    ...prevState,
                    currentPage:
                        filterState?.currentPage > 1
                            ? filterState?.currentPage - 1
                            : 1,
                }))
                profilePackagesRefetch()
            } else {
                errorAlert(
                    errorHandler(error.response?.data) ||
                        `Couldn't fetch consumer profile packages. please try again`,
                )
            }
        },
    })

    return {
        profilePackages,
        profilePackagesFetching: isFetching,
        totalPages,
        profilePackagesRefetch,
    }
}

export default useGetProfilePackages
