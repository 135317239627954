import PropTypes from 'prop-types'
import styles from './actionmodalv2.module.scss'
import Toast from '../Toast'
import SecondaryModal from '../Modal/SecondaryModal'
import { isMobile } from 'src/utils/mediaQueries'
import { getIconByActionType } from './utils'

const { ActionHeader, ActionContainer, ActionText } = styles

const ActionModal = ({
    actionModalOpen = false,
    headerText,
    subTitle,
    actionType,
    closable,
    onCancel,
    errorMessage,
    toastError,
    errorMessageType = 'error',
    title,
    primaryBtnText,
    handlePrimaryBtnClick,
    primaryBtnLoading,
    v2 = true,
    subTitleMaxWidth,
    countdownTimerSeconds,
    onTimeEnd,
    footerLeftContent = 'chat',
    secondaryBtnText,
    handleSecondaryBtnClick,
    kycSecondaryButtonStopLoading,
    secondaryBtnLoading,
}) => {
    const iconType = getIconByActionType(
        actionType,
        countdownTimerSeconds,
        onTimeEnd,
        actionModalOpen,
    )
    return (
        <SecondaryModal
            showModal={actionModalOpen}
            closable={closable}
            onCancel={onCancel ? onCancel : () => {}}
            title={title}
            primaryBtnText={primaryBtnText}
            primaryBtnLoading={primaryBtnLoading}
            primaryBtnWidth={isMobile ? '127.15px' : ''}
            handlePrimaryBtnClick={handlePrimaryBtnClick}
            secondaryBtnText={secondaryBtnText}
            handleSecondaryBtnClick={handleSecondaryBtnClick}
            modalWidth={'475px'}
            showFooter
            v2={v2}
            footerLeftContent={footerLeftContent}
            kycSecondaryButtonStopLoading={kycSecondaryButtonStopLoading}
            dataTestId={'action-modal-v2'}
            primaryBtnDataTestId={'action-modal-v2-primary-btn'}
            secondaryBtnLoading={secondaryBtnLoading}
            footerClassName="ActionModalV2_Footer"
            content={
                <div style={{ position: 'relative' }}>
                    {toastError && (
                        <Toast
                            position="absolute"
                            top="30px"
                            messageType={errorMessageType}
                            message={errorMessage?.[0]}
                        />
                    )}
                    <div className={ActionContainer}>
                        {iconType}
                        <h2 className={ActionHeader}>{headerText}</h2>
                        <p
                            className={ActionText}
                            style={{ maxWidth: subTitleMaxWidth }}
                        >
                            {subTitle}
                        </p>
                    </div>
                </div>
            }
        />
    )
}

ActionModal.propTypes = {
    actionModalOpen: PropTypes.bool,
    headerText: PropTypes.string,
    subTitle: PropTypes.string,
    actionType: PropTypes.string,
    closable: PropTypes.any,
    onCancel: PropTypes.func,
    errorMessage: PropTypes.any,
    toastError: PropTypes.bool,
    errorMessageType: PropTypes.string,
    title: PropTypes.string,
    primaryBtnText: PropTypes.string,
    handlePrimaryBtnClick: PropTypes.func,
    primaryBtnLoading: PropTypes.bool,
    v2: PropTypes.bool,
    subTitleMaxWidth: PropTypes.string,
    countdownTimerSeconds: PropTypes.number,
    onTimeEnd: PropTypes.func,
    footerLeftContent: PropTypes.string,
    secondaryBtnText: PropTypes.string,
    handleSecondaryBtnClick: PropTypes.func,
    kycSecondaryButtonStopLoading: PropTypes.bool,
    secondaryBtnLoading: PropTypes.bool,
}

export default ActionModal
