import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import s from './create-group.module.scss'
import ButtonComponent from 'src/components/Button'
import {
    InputFields,
    SelectField,
    TreeSelectAll,
} from 'src/components/InputFields'
import { profileSortingType, profileType } from '../data'
import useCreateGroups from '../hooks/useCreateGroups'
import { InlineLoader } from 'src/components/Loader'
import { useEffect } from 'react'
import { profileSortingTypeMapping } from '../../Groups/Listings/data'

const CreateGroups = ({
    showModal,
    onClose,
    setShowActionModal,
    data: ProfileGroup,
    noProfile = false,
    utilFunc,
}) => {
    const {
        inputs,
        setInputs,
        profiles,
        profilesFetching,
        errors,
        selectedProfiles,
        setSelectedProfiles,
        handleInputChange,
        handleSelectChange,
        updateCheckedProfiles,
        handleSearch,
        handleSelectAll,
        handleUnselectAll,
        createProfileGroupLoading,
        handleSubmit,
    } = useCreateGroups({
        onSuccess: data => {
            if (utilFunc) {
                utilFunc(data)
            }
            onClose()
            setShowActionModal({
                open: true,
                type:
                    ProfileGroup.id?.length > 0
                        ? 'group_updated'
                        : 'group_created',
            })
        },
        data: ProfileGroup,
        enabled: showModal,
    })

    useEffect(() => {
        setInputs({
            name: ProfileGroup?.name || '',
            customer_type:
                profileType.filter(
                    item => item.value === ProfileGroup?.customer_type,
                )[0]?.name || '',
            profile_sorting:
                profileSortingTypeMapping[ProfileGroup?.profile_sorting] ||
                'Default: Sort by capacity (Ascending)',
            profiles:
                ProfileGroup?.profiles?.length > 0
                    ? ProfileGroup?.profiles
                    : [],
        })
        setSelectedProfiles(
            ProfileGroup?.profiles?.length > 0 ? ProfileGroup?.profiles : [],
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal])

    return (
        <>
            <SecondaryModal
                closable={true}
                showModal={showModal}
                onCancel={onClose}
                modalWidth="624px"
                content={
                    <div className={s.wrapper}>
                        <h2>
                            {ProfileGroup?.name?.length > 0
                                ? 'Edit profile group'
                                : 'Create a new profile group'}
                        </h2>
                        <p>
                            {ProfileGroup?.name?.length > 0
                                ? 'Edit the details of this profile group'
                                : 'Add the details of the new profile group'}
                        </p>

                        <div>
                            <SelectField
                                name="customer_type"
                                initialOption={'Profile group type'}
                                floatingLabel={'Profile group type'}
                                floatLabelOnSelectedValue
                                selectWidth={'100%'}
                                values={profileType.map(type => ({
                                    value: type.name,
                                }))}
                                withCheckBox
                                disabled={noProfile}
                                value={inputs?.customer_type}
                                currentSelected={inputs?.customer_type}
                                dropdownPositionRelative
                                handleChange={handleSelectChange}
                                errorMessage={errors?.customer_type}
                            />
                            <InputFields
                                name="name"
                                title={'Profile group name'}
                                marginBottom={'0'}
                                value={inputs?.name}
                                handleChange={handleInputChange}
                                errorMessage={errors?.name}
                            />

                            {!noProfile && (
                                <TreeSelectAll
                                    values={profiles?.map(profile => ({
                                        title: profile.name,
                                        value: profile.id,
                                    }))}
                                    placeholder="Select Profiles"
                                    floatingLabel="Select Profiles"
                                    handleTreeSelectChange={
                                        updateCheckedProfiles
                                    }
                                    disabled={profilesFetching}
                                    setSelectedValues={setSelectedProfiles}
                                    selectedValues={selectedProfiles}
                                    handleSelectAll={handleSelectAll}
                                    handleUnselectAll={handleUnselectAll}
                                    handleSearch={handleSearch}
                                    selectText="Select All"
                                    unselectText="Unselect All"
                                    selectWidth="100%"
                                    dropDownWidth="390px"
                                    dropdownMatchSelectWidth={false}
                                    totalLength={profiles?.length}
                                    mobileWidth
                                />
                            )}
                            <SelectField
                                name="profile_sorting"
                                initialOption={'Profile sorting type'}
                                floatingLabel={'Profile sorting type'}
                                floatLabelOnSelectedValue
                                selectWidth={'100%'}
                                values={profileSortingType.map(type => ({
                                    value: type.name,
                                }))}
                                withCheckBox
                                value={inputs?.profile_sorting}
                                currentSelected={inputs?.profile_sorting}
                                dropdownPositionRelative
                                handleChange={handleSelectChange}
                                errorMessage={errors?.profile_sorting}
                            />
                        </div>
                        <div>
                            <ButtonComponent
                                btnBgColor={'#004AAD'}
                                btnWidth={'160px'}
                                btnHeight={'56px'}
                                btnTextColor={'#FFFFFF'}
                                disabled={createProfileGroupLoading}
                                handleClick={() => handleSubmit()}
                            >
                                {createProfileGroupLoading ? (
                                    <InlineLoader />
                                ) : (
                                    `${
                                        ProfileGroup?.name?.length > 0
                                            ? 'Update'
                                            : 'Save'
                                    } profile group`
                                )}
                            </ButtonComponent>
                        </div>
                    </div>
                }
            />
        </>
    )
}

CreateGroups.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setShowActionModal: PropTypes.func,
    utilFunc: PropTypes.func,
    data: PropTypes.any,
    noProfile: PropTypes.bool,
}
export default CreateGroups
