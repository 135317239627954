import { useState } from 'react'
import PropTypes from 'prop-types'
import { InlineLoader } from 'src/components/Loader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { promoBanners } from 'src/pages/Consumer/SmartShoppingExperience/Home/PromoCarousel/data'
import fallbackImage from 'src/assets/images/fallback.svg'
import { ReactComponent as LightIcon } from 'src/assets/images/Bulb.svg'
import s from './styles.module.scss'
import Toast from 'src/components/Toast'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import ButtonComponent from 'src/components/Button'

const SharePackageModal = ({
    showModal,
    onCancel,
    sharePackageLink,
    shortenLinkLoading,
    isPromoPage,
    promoType,
    profile,
}) => {
    const [toastMessage, setToastMessage] = useState('')

    const handleCopyLink = () => {
        navigator.clipboard.writeText(sharePackageLink)
        setToastMessage('The link to this profile has been copied')
        setTimeout(() => {
            setToastMessage('')
        }, [2000])
    }

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={onCancel}
            modalHeight={525}
            showFooter
            modalWidth={478}
            primaryBtnDisabled={shortenLinkLoading}
            content={
                <div className={s.parent_wrapper}>
                    <div className={s.packageContent}>
                        <h2>Share this profile</h2>
                        <p>Copy the link to this profile to share</p>
                        {toastMessage !== '' && (
                            <Toast
                                messageType={'success-secondary'}
                                message={toastMessage}
                                closable
                            />
                        )}
                        <div className={s.details}>
                            <div className={s.packageImg}>
                                <LazyLoadImage
                                    src={
                                        isPromoPage && promoType !== undefined
                                            ? promoBanners[promoType]
                                            : profile?.has_solar_panel
                                            ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+Banner.png'
                                            : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Without+panels+Banner.png'
                                    }
                                    alt="image of the system"
                                    placeholderSrc={fallbackImage}
                                />
                            </div>
                            <div className={s.detailsText}>
                                <div>
                                    <h4>{profile?.name}</h4>
                                    <span className={s.detailsTags}>
                                        {profile?.customer_type}
                                    </span>
                                </div>
                                <div>
                                    <LightIcon
                                        style={{
                                            width: 22,
                                            height: 22,
                                            color: '#070C18',
                                        }}
                                    />
                                    <span>
                                        {profile?.package_added} Packages
                                        available
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={s.linkEl}>
                            {shortenLinkLoading ? (
                                <div>
                                    <InlineLoader color="primary" />
                                </div>
                            ) : (
                                <p>{sharePackageLink}</p>
                            )}
                        </div>
                    </div>
                    <ButtonComponent
                        btnBgColor="#004AAD"
                        btnTextColor="#fff"
                        handleClick={handleCopyLink}
                    >
                        {shortenLinkLoading ? (
                            <InlineLoader />
                        ) : (
                            'Copy this link'
                        )}
                    </ButtonComponent>
                </div>
            }
        />
    )
}

SharePackageModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    sharePackageLink: PropTypes.string,
    shortenLinkLoading: PropTypes.bool,
    isPromoPage: PropTypes.bool,
    promoType: PropTypes.string,
    profile: PropTypes.object,
    formatPaymentModel: PropTypes.object,
    paymentModel: PropTypes.string,
    repaymentPlans: PropTypes.array,
}

export default SharePackageModal
