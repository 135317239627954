import { permissionsControl } from 'src/utils/permissionsControl'

export const creditDetails = creditVariableData => {
    const type =
        creditVariableData.payment_model === 'SUBSCRIPTION'
            ? 'subscription'
            : 'l20'

    let data = [
        {
            label: 'Lowest Tenure',
            value: 'lowest_tenure',
            suffix: ' Months',
            model: ['l20', 'subscription'],
        },
        {
            label: 'Start Declining Balance Rate',
            value: 'lowest_tenure_annual_interest',
            suffix: `% @ ${creditVariableData.lowest_tenure} Months`,
            model: ['l20', 'subscription'],
        },
        {
            label: `Amortization Period`,
            value: 'amortization_month',
            suffix: ' Months',
            model: ['subscription'],
        },
        {
            label: 'Tenure Increment',
            value: 'tenure_increment',
            model: ['l20'],
        },
        {
            label: 'No. of Payment Plans',
            value: 'payment_plans_count',
            model: ['l20'],
        },
        {
            label: 'Possible Tenures (in Months)',
            value: 'possible_tenures',
            model: ['l20', 'subscription'],
        },
        {
            label: `Monthly Payment Markup Value`,
            value: 'markup_value',
            suffix: '%',
            model: ['subscription'],
        },
        {
            label: 'Minimum Upfront Deposit',
            value: 'upfront_deposit',
            suffix: '%',
            model: ['l20', 'subscription'],
        },
        {
            label: 'Monthly Rate Jump',
            value: 'monthly_interest_jump',
            suffix: '%',
            model: ['l20', 'subscription'],
        },
        {
            label: 'Cost of Capital',
            value: 'cost_of_capital',
            suffix: '%',
            model: ['l20', 'subscription'],
        },
        {
            label: 'Re-Investment Rate',
            value: 'reinvestment_rate',
            suffix: '%',
            model: ['l20', 'subscription'],
        },

        {
            label: 'Debt to Equity in Project',
            value: 'debt_to_equity',
            suffix: '%',
            model: ['l20', 'subscription'],
        },
        {
            label: 'Debt Cost',
            value: 'debt_cost',
            suffix: '%',
            model: ['l20', 'subscription'],
        },
        {
            label: 'Debt Months',
            value: 'debt_months',
            model: ['l20', 'subscription'],
        },
        ...(process.env.REACT_APP_INSURANCE_INCLUSIVE_PAYMENT_PLANS_ENABLED ===
        'true'
            ? [
                  {
                      label: 'Insurance Fee',
                      value: 'insurance',
                      suffix: '%',
                      model: ['l20', 'subscription'],
                  },
              ]
            : [{}]),
        ...(process.env.REACT_APP_INSURANCE_INCLUSIVE_PAYMENT_PLANS_ENABLED ===
        'true'
            ? [
                  {
                      label: 'SunFi Insurance Fee',
                      value: 'sunfi_insurance',
                      suffix: '%',
                      model: ['l20', 'subscription'],
                  },
              ]
            : [{}]),
    ]
    let newData = []
    data.forEach(function (arrayItem) {
        if (arrayItem?.model?.includes(type)) {
            newData.push(arrayItem)
        }
    })
    return newData
}

export const canUpdateCreditVariable = permissionsControl([
    'can_update_credit_variable',
])

export const canDeleteCreditVariable = permissionsControl([
    'can_delete_credit_variable',
])

export const canSimulatePlanWithCreditVariable = permissionsControl([
    'can_simulate_plans',
])
