//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useEffect, useState, useCallback } from 'react'
import { useQuery } from 'react-query'
import { Skeleton } from 'antd'
import { AppButton } from '../../components/Button'
import { MultipleSelectField } from '../../components/InputFields'
import Toast from '../../components/Toast'
import { getSinglePackageApi } from '../../api/products/packages'
import { getAllComponentsApi } from '../../api/products/library'
import { formatPackageComponents } from '../../components/ProductPackage/EditPackage/formatter'
import { currencyFormatter } from '../../utils/currencyFormatter'
import { numberWithCommas, subStringText } from '../../utils/formatting'

import CloseIcon from '../../assets/images/close-icon.svg'
import UpArrow from '../../assets/images/blackUpArrow.svg'
import DownArrow from '../../assets/images/blackDownArrow.svg'

import styles from '../Recommendations/EnergyRecommendation/energyrecommendation.module.scss'
import { replaceHtmlTags } from '../../utils/formatting'
import Label from 'src/components/Label'
import AddMargin from 'src/components/ProductPackage/AddMargin'
import GetModal from 'src/components/Modal'
import './simulation-stepone.scss'
import { isMobile } from 'src/utils/mediaQueries'

const EnergyRecommendation = ({
    onSelect,
    solution,
    closeModal,
    openModal,
    margin,
    setMargin,
    shouldFetchSinglePackage,
}) => {
    const {
        label,
        Title,
        SubTitle,
        RecommenadationParagraph,
        Wrapper,
        ComponentTitle,
        ButtonWrapper,
        ComponentName,
        ComponentType,
        ComponentAmount,
        ComponentQty,
        ComponentDetails,
        ComponentDetailsTotal,
        // SelectedComponents
    } = styles

    const [, setPackageComponent] = useState([])
    const [componentList, setComponentList] = useState([])
    const [allComponents, setAllComponents] = useState([])
    const [selected, setSelected] = useState([])
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [count, setCount] = useState([])
    const [components, setComponents] = useState([])
    const [subtotal, setSubtotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [showMarginModal, setShowMarginModal] = useState(false)
    const [solutionComponent, setSolutionComponent] = useState([])
    // fetch a single package api
    const { refetch: packageRefetch } = useQuery(
        ['fetchSinglePackage'],
        () => getSinglePackageApi(solution?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setPackageComponent(
                    formatPackageComponents(data?.data?.data?.components),
                )
                setSolutionComponent(data?.data?.data?.components)
                const packageInfo = data?.data?.data
                const packageMarginPercentage = parseFloat(packageInfo?.margin)
                if (packageMarginPercentage !== 0) {
                    const packageMarginAmount =
                        (packageMarginPercentage / 100) * packageInfo?.subtotal
                    const marginDescription =
                        packageMarginPercentage > 0
                            ? 'Add Margin'
                            : 'Subtract Margin'
                    handleMargin(
                        marginDescription,
                        packageMarginAmount < 0
                            ? packageMarginAmount * -1
                            : packageMarginAmount,
                        packageMarginPercentage,
                        true,
                        packageInfo?.subtotal,
                    )
                }
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't fetch package. Refresh page`)
            },
        },
    )

    //fetch all components
    const { isLoading: fetchComponentsLoading, refetch: fetchComponents } =
        useQuery(['fetchAllComponents'], () => getAllComponentsApi(), {
            enabled: false,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                setComponentList(data?.data?.data)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch components. Refresh page')
            },
        })

    useEffect(() => {
        packageRefetch()
        fetchComponents()
    }, [])
    useEffect(() => {
        if (shouldFetchSinglePackage) {
            packageRefetch()
        }
    }, [shouldFetchSinglePackage])

    useEffect(() => {
        if (componentList.length > 0) {
            const allComponentsList = componentList
            //compare and update recommended_solutions component with name and type
            let formatPreselectedComponents = solutionComponent?.map(function (
                x,
            ) {
                if (componentList.length > 0) {
                    x.name = x?.component?.name
                    x.type = x?.component?.type?.name
                    x.count = x?.quantity
                    x.realCost = x?.component?.cost
                    x.isGlobal = x?.component?.is_global
                }
                return x
            })

            let formatAllComponentList = allComponentsList?.map(item => {
                return {
                    ...item,
                    value: `${item?.name} (₦${currencyFormatter(
                        item?.cost,
                    )} | ${item?.type?.name})`,
                }
            })

            let formatPackageComponentList = formatPreselectedComponents?.map(
                item => {
                    return {
                        ...item,
                        value: `${item?.name} (₦${currencyFormatter(
                            item?.realCost,
                        )} | ${item?.type})`,
                        estimatedValue: `${item?.name} (₦${currencyFormatter(
                            item?.cost,
                        )} | ${item?.type})`,
                    }
                },
            )
            let countList = []
            let checked = []

            formatPackageComponentList?.forEach(item => {
                checked.push(item.value)
                countList.push({
                    id: item.id,
                    cost: item.cost,
                    count: item?.count,
                    realCost: parseFloat(item?.realCost, 10).toFixed(2),
                })
            })

            setCount(countList)
            setAllComponents(formatAllComponentList)
            setSelected(formatPackageComponentList)
        }
        // eslint-disable-next-line
    }, [solution, componentList])

    const handleSelectChange = useCallback(
        value => {
            setComponents([...components, value])
            let filteredComponent = allComponents.find(item => {
                const formattedItem = `${item?.name} (₦${currencyFormatter(
                    item?.cost,
                )} | ${item?.type?.name})`
                if (formattedItem == value) return item
            })

            let preselectedfilterComponent = [...selected, filteredComponent]
            const updatedSelected = preselectedfilterComponent?.map(
                component => {
                    return {
                        ...component,
                        estimatedValue: `${
                            component?.name
                        } (₦${numberWithCommas(component?.cost)} | ${
                            component?.type
                        })`,
                        count: component?.count || 1,
                        realCost:
                            component?.realCost === undefined
                                ? component?.cost
                                : component?.realCost,
                        disabled: false,
                    }
                },
            )
            const countList = updatedSelected.map(item => ({
                id: item.id,
                cost: item.cost,
                count: item?.count,
                realCost: item?.realCost,
            }))
            setCount(countList)
            setSelected(updatedSelected)
        },
        [allComponents, selected],
    )

    const getCount = componentId => {
        return count.filter(value => value.id === componentId)[0].count
    }

    const handleCancel = id => {
        let newComponent = selected.filter(data => data.id !== id)
        let countList = []

        newComponent.forEach(item => {
            countList.push({
                id: item?.id,
                cost: item?.cost,
                count: item?.count,
                realCost: item?.realCost,
            })
        })

        setCount(countList)
        setSelected(newComponent)
    }
    const updateCount = (currentCount, action, componentId, cost, realCost) => {
        let newCount = {
            id: componentId,
            cost: cost,
            count: currentCount,
            realCost,
        }
        if (action === 'add') {
            newCount.count = currentCount + 1
            newCount.cost = realCost * (currentCount + 1)
        }
        if (action === 'subtract' && currentCount > 1) {
            newCount.count = currentCount - 1
            newCount.cost = realCost * (currentCount - 1)
        }

        let countCopy = [...count]
        let selectedCopy = [...selected]

        let found = countCopy.findIndex(q => q.id === componentId)
        let foundSelected = selectedCopy.findIndex(q => q.id === componentId)

        if (found > -1) {
            countCopy[found] = newCount
        }

        if (foundSelected > -1) {
            selectedCopy[foundSelected] = {
                ...selectedCopy[foundSelected],
                cost: newCount.cost,
                count: newCount.count,
            }
        }

        setCount(countCopy)
        setSelected(selectedCopy)
    }

    useEffect(() => {
        let calculateSubtotal = count.reduce(
            (total, item) => total + item?.realCost * item?.count,
            0,
        )
        setSubtotal(calculateSubtotal.toFixed(2))
        if (margin?.action === 'Add Margin') {
            setTotal(calculateSubtotal + margin.amount)
        } else if (margin?.action === 'Subtract Margin') {
            setTotal(calculateSubtotal - margin.amount)
        }

        const packageMarginPercentage = margin
            ? parseFloat(margin.percentage)
            : 0
        if (
            packageMarginPercentage !== 0 &&
            calculateSubtotal !== 0 &&
            subtotal !== 0
        ) {
            const packageMarginAmount =
                (Math.abs(packageMarginPercentage) / 100) * calculateSubtotal
            const marginDescription =
                packageMarginPercentage > 0 ? 'Add Margin' : 'Subtract Margin'
            if (isNaN(packageMarginAmount) || isNaN(packageMarginPercentage)) {
                handleMargin('', '', '', false, calculateSubtotal.toFixed(2))
            } else {
                handleMargin(
                    marginDescription,
                    packageMarginAmount.toFixed(2),
                    packageMarginPercentage,
                    true,
                    calculateSubtotal.toFixed(2),
                )
            }
        }

        // eslint-disable-next-line
    }, [count, selected])

    const handleCloseMarginModal = () => {
        setShowMarginModal(false)
        openModal()
    }
    const handleMargin = (
        action,
        amount,
        percentage,
        isExisting,
        partTotal,
    ) => {
        const formatAmount = amount.toString().replaceAll(',', '')
        setMargin({ action, amount: formatAmount, percentage, isExisting })
        if (action === 'Add Margin') {
            let Total = Number(partTotal) + Number(formatAmount)
            setTotal(Total)
        } else if (action === 'Subtract Margin') {
            let Total = Number(partTotal) - Number(formatAmount)
            setTotal(Total)
        } else {
            setTotal(Number(partTotal))
        }
    }
    return (
        <>
            <GetModal
                app="workspace"
                showModal={showMarginModal}
                onCancel={handleCloseMarginModal}
                closable={true}
                content={
                    <AddMargin
                        packageName={solution.name}
                        subTotal={subtotal}
                        margin={margin}
                        handleClose={handleCloseMarginModal}
                        handleMargin={handleMargin}
                    />
                }
            />
            <div className={Wrapper}>
                {toastError && (
                    <Toast messageType="error" message={errorMessage} />
                )}
                <p className={label}>BEST MATCH</p>
                <div style={{ display: 'flex' }}>
                    <h1 className={Title}>{solution.name}</h1>
                    {solution?.is_global === true ? (
                        <Label
                            title="GLOBAL"
                            fontSize="10px"
                            padding="6px 8px"
                        />
                    ) : (
                        ''
                    )}
                </div>
                <div>
                    <p className={SubTitle}>DESCRIPTION</p>
                    <p className={RecommenadationParagraph}>
                        {replaceHtmlTags(solution.description)}
                    </p>
                </div>
                <div>
                    <p className={ComponentTitle}>Components Information</p>
                    <p className={ComponentName} style={{ marginBottom: 10 }}>
                        Select Component
                    </p>
                    <MultipleSelectField
                        selectWidth="100%"
                        initialOption="Select Component"
                        values={allComponents}
                        inputValue={selected?.map(item => item.value)}
                        value={selected?.map(item => item.value)}
                        maxTagCount="responsive"
                        handleMultipleSelectChange2={handleSelectChange}
                        onSelect
                    />
                    <div>
                        <table style={{ marginTop: isMobile ? 20 : 0 }}>
                            {fetchComponentsLoading
                                ? [...Array(4)].map(i => (
                                      <Skeleton.Input
                                          key={i}
                                          active
                                          className="EstimationSkeletalField"
                                      />
                                  ))
                                : selected?.length > 0 &&
                                  selected?.map(item => {
                                      return (
                                          <tr
                                              key={item?.id}
                                              className="StepOneSelectedComponents"
                                          >
                                              <td
                                                  className="ERComponentsFirstTD"
                                                  style={{
                                                      width: isMobile
                                                          ? '88%'
                                                          : '50%',
                                                  }}
                                              >
                                                  <div className="ERComponentsFirstTDDiv">
                                                      <span className="ERComponentsFirstTDSelectedName">
                                                          {subStringText(
                                                              item?.name,
                                                              30,
                                                          )}
                                                      </span>
                                                      {item?.isGlobal ===
                                                      true ? (
                                                          <Label
                                                              title="GLOBAL"
                                                              fontSize="10px"
                                                              padding="6px 8px"
                                                          />
                                                      ) : (
                                                          ''
                                                      )}
                                                  </div>
                                                  <span className="ERComponentAmountMobile">
                                                      NGN{' '}
                                                      {currencyFormatter(
                                                          item?.realCost,
                                                      )}
                                                  </span>
                                              </td>
                                              <td
                                                  className={ComponentType}
                                                  style={{ width: '20%' }}
                                              >
                                                  <span className="">
                                                      {subStringText(
                                                          item?.type?.name ||
                                                              item?.type,
                                                          9,
                                                      )}
                                                  </span>
                                              </td>
                                              {!isMobile && (
                                                  <td
                                                      className={
                                                          ComponentAmount
                                                      }
                                                      style={{ width: '25%' }}
                                                  >
                                                      <span className="SelectedCapacity">
                                                          &#8358;
                                                          {currencyFormatter(
                                                              item?.realCost,
                                                          )}
                                                      </span>
                                                  </td>
                                              )}
                                              <td
                                                  className={ComponentQty}
                                                  style={{
                                                      width: '7%',
                                                      fontWeight: '600',
                                                  }}
                                              >
                                                  <div className="Scroll">
                                                      <span
                                                          className="QuantityImageWrapper"
                                                          onClick={() =>
                                                              updateCount(
                                                                  getCount(
                                                                      item.id,
                                                                  ),
                                                                  'add',
                                                                  item?.id,
                                                                  item?.cost,
                                                                  item?.realCost,
                                                              )
                                                          }
                                                          disabled={
                                                              count >= 10
                                                                  ? true
                                                                  : false
                                                          }
                                                          style={{
                                                              cursor:
                                                                  count >= 10 &&
                                                                  'not-allowed',
                                                          }}
                                                      >
                                                          <img
                                                              src={UpArrow}
                                                              className="Arrow"
                                                              alt="up-arrow-icon"
                                                          />
                                                      </span>

                                                      <span className="Quantity">
                                                          {getCount(item.id)}
                                                      </span>
                                                      <span
                                                          className="QuantityImageWrapper"
                                                          onClick={() =>
                                                              updateCount(
                                                                  getCount(
                                                                      item.id,
                                                                  ),
                                                                  'subtract',
                                                                  item?.id,
                                                                  item?.cost,
                                                                  item?.realCost,
                                                              )
                                                          }
                                                          disabled={
                                                              count <= 1
                                                                  ? true
                                                                  : false
                                                          }
                                                          style={{
                                                              cursor:
                                                                  count <= 1 &&
                                                                  'not-allowed',
                                                          }}
                                                      >
                                                          <img
                                                              src={DownArrow}
                                                              className="Arrow"
                                                              alt="down-arrow-icon"
                                                          />
                                                      </span>
                                                  </div>
                                              </td>
                                              <td>
                                                  <span
                                                      onClick={() =>
                                                          handleCancel(item.id)
                                                      }
                                                      style={{
                                                          cursor: 'pointer',
                                                      }}
                                                  >
                                                      <img
                                                          src={CloseIcon}
                                                          alt="close-icon"
                                                      />
                                                  </span>
                                              </td>
                                          </tr>
                                      )
                                  })}
                        </table>
                    </div>
                    {subtotal > 0 && (
                        <div className={`${ComponentDetailsTotal}`}>
                            <p
                                style={{
                                    fontWeight: margin?.isExisting && '600',
                                }}
                            >
                                {!margin?.isExisting ? 'Total' : 'Subtotal'}
                            </p>
                            <p>
                                {!fetchComponentsLoading ? (
                                    <>
                                        &#8358;
                                        {currencyFormatter(subtotal)}
                                    </>
                                ) : (
                                    <Skeleton.Button
                                        active
                                        className="EstimationSkeletalShortField"
                                    />
                                )}
                            </p>
                        </div>
                    )}

                    {margin?.isExisting && (
                        <>
                            <div className={ComponentDetails}>
                                <p>Margin</p>
                                <p
                                    style={{
                                        color:
                                            margin?.action === 'Add Margin'
                                                ? '#32CC79'
                                                : 'red',
                                        fontWeight: '600',
                                    }}
                                >
                                    {!fetchComponentsLoading ? (
                                        <>
                                            {`${
                                                margin?.action === 'Add Margin'
                                                    ? '+'
                                                    : '-'
                                            }`}
                                            {`${Math.abs(margin.percentage)}%`}{' '}
                                            (&#8358;
                                            {currencyFormatter(margin.amount)})
                                        </>
                                    ) : (
                                        <Skeleton.Button
                                            active
                                            className="EstimationSkeletalShortField"
                                        />
                                    )}
                                </p>
                            </div>
                            <div className={ComponentDetails}>
                                <p style={{ fontWeight: '600' }}>Total</p>

                                <p style={{ fontWeight: '600' }}>
                                    {!fetchComponentsLoading ? (
                                        <>
                                            &#8358;
                                            {currencyFormatter(total)}
                                        </>
                                    ) : (
                                        <Skeleton.Button
                                            active
                                            className="EstimationSkeletalShortField"
                                        />
                                    )}
                                </p>
                            </div>
                        </>
                    )}

                    <div className="AddMarginBtnWrapper">
                        {!fetchComponentsLoading && (
                            <AppButton
                                btnWidth="88px"
                                btnHeight="29px"
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                borderRadius="25px"
                                fontSize="12px"
                                fontWeight="600"
                                handleClick={() => {
                                    closeModal()
                                    setShowMarginModal(true)
                                }}
                            >
                                {margin?.isExisting
                                    ? 'Edit Margin'
                                    : 'Add Margin'}
                            </AppButton>
                        )}
                    </div>
                    <div className={ButtonWrapper}>
                        <AppButton
                            btnTextColor="var(--white)"
                            btnBgColor="var(--blue)"
                            btnWidth="160px"
                            btnHeight="56px"
                            handleClick={() => {
                                onSelect({ ...solution, total })
                                closeModal()
                            }}
                            disabled={fetchComponentsLoading}
                        >
                            Select Package
                        </AppButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EnergyRecommendation
