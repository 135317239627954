import Proptypes from 'prop-types'
import Label from '../../PersonalInformation/_components/Label'
import { ConsumerDateField, InputFields } from 'src/components/InputFields'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Toast from 'src/components/Toast'
import { useState } from 'react'

const VerifyIdentity = ({
    inputs,
    handleInputChange,
    handleBlur,
    fieldErrors,
    identityToast,
}) => {
    const [showCalendar, setShowCalendar] = useState(false)
    return (
        <div className="VerifyIdentityModalWrapper">
            {identityToast && (
                <Toast
                    messageType="error-secondary"
                    message={identityToast}
                    position={'absolute'}
                    top={40}
                    closable={true}
                />
            )}
            <div>
                <VerticalSpacer top="7px" />
                <Label
                    variant="secondary"
                    title="Your full name"
                    subTitle="Confirm this is your full name as it is written on your submitted ID card"
                ></Label>
                <div>
                    <InputFields
                        title="First Name"
                        name="firstName"
                        value={inputs?.firstName}
                        handleChange={handleInputChange}
                        onBlur={handleBlur}
                        marginBottom={'33px'}
                        errorMessage={fieldErrors?.firstName}
                    />
                    <InputFields
                        title="Last Name"
                        name="lastName"
                        value={inputs?.lastName}
                        handleChange={handleInputChange}
                        onBlur={handleBlur}
                        marginBottom={'33px'}
                        errorMessage={fieldErrors?.lastName}
                    />
                </div>
                {inputs.identityType === 'Drivers License' && (
                    <ConsumerDateField
                        title="Date of Birth"
                        name="date_of_birth"
                        dateValue={
                            inputs?.date_of_birth?.length === 0
                                ? null
                                : new Date(inputs?.date_of_birth)
                        }
                        value={inputs?.date_of_birth}
                        errorMessage={fieldErrors.date_of_birth}
                        handleDateChange={(name, date) => {
                            handleInputChange({ target: { name, value: date } })
                        }}
                        closeCalendarHandler={() => {
                            setShowCalendar(false)
                        }}
                        openCalendar={showCalendar}
                        openCalendarHandler={() => {
                            setShowCalendar(true)
                        }}
                    />
                )}
                <Label
                    title="Your submitted ID card number"
                    subTitle="This is your ID card number"
                    variant="secondary"
                ></Label>
                <InputFields
                    title="ID card number"
                    name="identityNumber"
                    value={inputs?.identityNumber}
                    handleChange={handleInputChange}
                    onBlur={handleBlur}
                    marginBottom={'8px'}
                    errorMessage={fieldErrors?.identityNumber}
                />
            </div>
        </div>
    )
}

VerifyIdentity.propTypes = {
    inputs: Proptypes.shape({
        firstName: Proptypes.string,
        lastName: Proptypes.string,
        identityNumber: Proptypes.string,
        identityType: Proptypes.string,
        date_of_birth: Proptypes.string,
    }),
    handleInputChange: Proptypes.func.isRequired,
    handleBlur: Proptypes.func.isRequired,
    fieldErrors: Proptypes.object,
    identityToast: Proptypes.string,
}

export default VerifyIdentity
