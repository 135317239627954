export const getCookie = cname => {
    let name = cname + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

export function setCookie(name, value, days) {
    let expires = ''
    if (days) {
        let date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toUTCString()
    }
    // TODO: We might need to support other parameters like HTTPOnly, Secure
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export const removeDanglingProxyInfo = () => {
    localStorage.removeItem('esv-iyo')
    localStorage.removeItem('esv-esk')
    clearCookiesWithPrefix('esv')
}

export const clearCookiesWithPrefix = prefix => {
    // Get all cookies as a single string
    const cookies = document.cookie.split(';')

    // Iterate through the list of cookies
    for (let i = 0; i < cookies.length; i++) {
        // Get the cookie name and value
        const cookie = cookies[i]
        const cookieName = cookie.split('=')[0].trim()

        // Check if the cookie name starts with the specified prefix
        if (cookieName.startsWith(prefix)) {
            // Clear the cookie by setting its expiration date to a past date
            setCookie(cookieName, '', -1)
        }
    }
}
