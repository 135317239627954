import PropTypes from 'prop-types'

const VerticalSpacer = ({ top, bottom }) => {
    return <div style={{ marginTop: top, marginBottom: bottom }}></div>
}

VerticalSpacer.propTypes = {
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default VerticalSpacer
