import axios from 'src/config/axios'

export const getProfilesApi = async (
    published,
    currentPage,
    searchText,
    paginate = '',
    customer_type = '',
    profileGroupId = '',
    ungroupedProfile = '',
) => {
    const request = await axios.get(
        `/admin/energy-profiles?published=${published}&page=${currentPage}&name=${searchText}&paginate=${paginate}&customer_type=${customer_type}&profile_group_id=${profileGroupId}&ungrouped_profile=${ungroupedProfile}`,
    )
    return request
}
