const businessTypeData = [
  {
    id: 1,
    value: 'ENTERPRISE',
  },
  {
    id: 2,
    value: 'VENTURE',
  },
  {
    id: 3,
    value: 'LIMITED LIABILITY',
  },
  {
    id: 4,
    value: 'SOLE PROPRIETORSHIP',
  },
  {
    id: 5,
    value: 'SERIES LLC',
  },
]

export default businessTypeData