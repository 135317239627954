/* eslint-disable no-undef */
import mixpanel from 'mixpanel-browser'

export const initMixPanel = (
    id,
    type,
    pageUrl,
    pageTracker,
    pageTitle,
    eventAction,
    eventLabel,
    eventCategory,
    payload = {},
) => {
    if (
        ['production', 'sandbox', 'staging', 'development'].includes(
            process.env.REACT_APP_NODE_ENV,
        )
    ) {
        // const mixpanel = require('mixpanel-browser')
        mixpanel.init(id, {
            debug: ['staging', 'development'].includes(
                process.env.REACT_APP_NODE_ENV,
            ),
        })
        if (type === 'event') {
            mixpanel.track(eventAction, {
                pageTitle: pageTitle,
                pageUrl: pageUrl,
                pageTracker: pageTracker,
                eventLabel: eventLabel,
                eventCategory: eventCategory,
                ...payload?.event,
            })
        }
        if (type === 'pageview') {
            mixpanel.track('Page View', {
                pageTitle: pageTitle,
                pageUrl: pageUrl,
                pageTracker: pageTracker,
                ...payload?.pageview,
            })
        }
        if (type === 'register') {
            mixpanel.register_once({ ...payload?.register })
        }
        if (type === 'identify') {
            mixpanel.identify(payload?.identify?.id)
        }
        if (type === 'people_set') {
            mixpanel.people.set({ ...payload?.people_set })
        }
    }
}
