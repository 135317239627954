import { useState } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from 'react-query'
import { depStaffSignupApi } from '../../api/signup/depstaffsignup'
import FormBox from '../../components/FormBox'
import ButtonComponent from '../../components/Button'
import { SelectCountryCode } from '../../components/InputFields/SelectCountryCode'
import { DepStaffValidationSchema } from '..//../utils/validationSchema'
import FormTitleBar from '../../components/FormTitleBar'
import { InputFields, SelectField } from '../../components/InputFields'
import Confirmation from '../../components/Confirmation'
import successImage from '../../assets/images/successImage.svg'
import { errorHandler } from '../../utils/errorHandler'
import Toast from '../../components/Toast'
import { InlineLoader } from '../../components/Loader'
import { formatPhoneNumber } from '../../utils/formatting'
import { DepRoleData } from '../../utils/Data/roles'

import styles from './depstaffsignup.module.scss'
import PasswordInfo from '../../assets/images/passwordInfo.svg'

// eslint-disable-next-line react/prop-types
const StepTwoDEPStaffSignUp = ({ showTextHandler }) => {
    const { PasswordStyle, NameWrapper, ButtonWrapper } = styles
    const [success, setSuccess] = useState(false)
    const [errors, setErrors] = useState({})
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        role: '',
        password: '',
        email: '',
        countryCode: '+234',
    })

    const handleInputChange = event => {
        const { name, value } = event.target
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleInputBlur = event => {
        const { name, value } = event.target
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleSelectChange = (name, value) => {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const { refetch, isLoading } = useQuery(
        'dep-user-signup',
        () =>
            depStaffSignupApi({
                first_name: inputs.firstName,
                last_name: inputs.lastName,
                phone_number: formatPhoneNumber(
                    inputs.phoneNumber,
                    inputs.countryCode,
                ),
                role: inputs.role,
                password: inputs.password,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setSuccess(true)
                showTextHandler(false)
            },
            onError: error => {
                if (error) {
                    const errObj = error?.response?.data
                    if (error?.response?.status === 500) {
                        setErrorMessage(['Request failed. Please try again.'])
                    } else {
                        setErrorMessage(errorHandler(errObj))
                    }
                }
                setToastError(true)
            },
        },
    )

    const handleSubmit = () => {
        DepStaffValidationSchema.validate(inputs, { abortEarly: false })
            .then(() => {
                setErrors('')
                refetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const history = useHistory()
    const onButtonClick = () => {
        localStorage.removeItem('sunfiToken')
        history.push('/login')
    }

    return (
        <>
            {success ? (
                <Confirmation
                    imgUrl={successImage}
                    header="Registration Successful"
                    subHeader="Your account registration is successful. Click the button below to sign in."
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    onButtonClick={onButtonClick}
                    btnText="Proceed to Sign In"
                />
            ) : (
                <FormBox>
                    <>
                        <FormTitleBar
                            title="Let's Get to Know You"
                            subtitle="Enter your details to get started"
                        />
                        {errorMessage && (
                            <Toast
                                message={toastError ? errorMessage[0] : ''}
                                messageType="error"
                            />
                        )}
                        <div className={NameWrapper}>
                            <InputFields
                                title="First Name"
                                name="firstName"
                                inputWidth="100%"
                                handleChange={handleInputChange}
                                onBlur={handleInputBlur}
                                value={inputs.firstName}
                                errorMessage={errors?.firstName}
                                mobileMarginRight
                            />
                            <InputFields
                                title="Last Name"
                                name="lastName"
                                inputWidth="100%"
                                handleChange={handleInputChange}
                                onBlur={handleInputBlur}
                                value={inputs.lastName}
                                errorMessage={errors?.lastName}
                            />
                        </div>
                        <SelectCountryCode
                            handleChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            title="Phone Number"
                            name="phoneNumber"
                            inputValue={inputs.phoneNumber}
                            errorMessage={errors.phoneNumber}
                        />
                        <SelectField
                            initialOption="Select your Role"
                            selectWidth="100%"
                            name="role"
                            marginBottom="20px"
                            values={DepRoleData}
                            withCheckBox={true}
                            selectedValue={inputs.role}
                            currentSelected={inputs.role}
                            handleChange={handleSelectChange}
                            errorMessage={errors.role}
                            dropdownPositionRelative
                            showSearch
                        />
                        <InputFields
                            title="Choose a Password"
                            type="password"
                            name="password"
                            inputValue={inputs.password}
                            handleChange={handleInputChange}
                            errorMessage={errors.password}
                        />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}
                        >
                            <img src={PasswordInfo} alt="password info icon" />
                            <p className={PasswordStyle}>
                                Password must be atleast one UPPERCASE,
                                lowercase and a number
                            </p>
                        </div>

                        <div className={ButtonWrapper}>
                            <ButtonComponent
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                disabled={
                                    inputs.firstname === '' ||
                                    inputs.lastname === '' ||
                                    inputs.password === '' ||
                                    inputs.role === '' ||
                                    inputs.phoneNumber === ''
                                }
                                handleClick={handleSubmit}
                            >
                                {isLoading ? (
                                    <InlineLoader />
                                ) : (
                                    'Create Account'
                                )}
                            </ButtonComponent>
                        </div>
                    </>
                </FormBox>
            )}
        </>
    )
}

export default StepTwoDEPStaffSignUp
