export const consumerKYCEvents = {
    viewKYCCategory: {
        action: 'View KYC Cat.',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'View KYC Cat.',
    },
    confirmedEmail: {
        action: 'KYC - Confirm Email',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'KYC - Confirm Email',
    },
    confirmedPhone: {
        action: 'KYC - Confirm Phone No.',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'KYC - Confirm Phone No.',
    },
    validatedEmail: {
        action: 'KYC - Validate Email',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'KYC - Validate Email',
    },
    validatedPhone: {
        action: 'KYC - Validate Phone No.',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'KYC - Validate Phone No.',
    },
    completedKYCCategory: {
        action: 'Completed a KYC Ctgy.',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Completed a KYC Ctgy.',
    },
    completedTwoKYCCategories: {
        action: 'Completed 2 KYC Ctgy.',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Completed 2 KYC Ctgy.',
    },
    kybCompleted: {
        action: 'KYB Completion',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'KYB Completion',
    },
    kycCompleted: {
        action: 'KYC Completion',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'KYC Completion',
    },
    selectedChangePackage: {
        action: 'Select Change Package',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'Select Change Package',
    },
    viewedLP: {
        action: 'View KYC LP',
        category: 'SMART_SHOPPING_EXPERIENCE_KYC',
        label: 'View KYC LP',
    },
}
