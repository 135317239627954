import styles from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/FinancialInformation/FinancialInfoForm/financeinfo.module.scss'
import Label from '../../../../PersonalInformation/_components/Label'
import { useHistory } from 'react-router-dom'
import { SelectField, InputFields } from 'src/components/InputFields'
import mono from 'src/assets/images/mono.svg'
import okra from 'src/assets/images/okra.svg'
import lockIcon from 'src/assets/images/Lock1.svg'
import attachIcon from 'src/assets/images/clip.svg'
import { useEffect, useRef, useState } from 'react'
import UploadModal from 'src/components/UploadModal'
import UploadField from 'src/components/InputFields/UploadField'
import ModalContainer from '../../../../FinancialInformation/ModalContainer'
import ButtonComponent from 'src/components/Button'
import { useMono } from 'src/utils/Hooks/useMono'
import { isMobile } from 'src/utils/mediaQueries'
import { useQueryClient } from 'react-query'
import { useOkra } from 'src/utils/Hooks/useOkra'
import {
    useUploadBankStatement,
    useGetBanks,
} from '../../../../FinancialInformation/FinancialInfoForm/api'
import BankStatement from '../../../../FinancialInformation/_components/BankStatement'
import { checkPDFPassword } from 'src/utils/checkPDFPassword'
import { InlineLoader } from 'src/components/Loader'
import { useAppContext } from 'src/appContext/AppContext'
import {
    SET_AUDIT_FILE,
    SET_AUDIT_REPORT_SUCCESS,
    SET_DOC_URL,
    SET_MONO_CODE,
    SET_MONTHLY_REVENUE_ERROR,
    SHOW_AUDIT_MODAL,
    SHOW_BANKSTATEMENT_MODAL,
    SHOW_BANKSTATEMENT_PASSWORD_MODAL,
    SHOW_THIRD_PARTY,
    SHOW_UPLOAD_BANK_STATEMENT,
    SET_UPDATE_REQUEST_ERRORS,
    SET_BANK_STATEMENT_ERROR,
    SET_AUDIT_REPORT_ERROR,
    SET_AUDIT_REPORT_PASSWORD,
    SET_AUDIT_REPORT,
    SHOW_AUDITREPORT_PASSWORD_MODAL,
} from 'src/appContext/appReducer'
import { formatErrorMessages } from '../../../../utils/utils'
import { decodeUserInfo } from 'src/utils/auth'
import OverrideCheckModal from '../../../../_components/OverrideCheckModal'
import useMappedErrors from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/hooks/useMappedErrors'
import {
    BUSINESS_FINANCIAL_INFO_UPDATE_REQUESTS_MAP,
    RESIDENTIAL_FINANCIAL_INFO_UPDATE_REQUESTS_MAP,
} from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/utils'
import useScrollToError from '../../../../hooks/useScrollToError'
import RemoveFileModal from '../../../../_components/RemoveFileModal'
import BankStatementDisplay from '../../../../FinancialInformation/FinancialInfoForm/BankStatement/BankStatementDisplay'
import useGetBankStatement from '../../../../FinancialInformation/FinancialInfoForm/BankStatement/useGetBankStatement'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { ReactComponent as DocumentIcon } from 'src/assets/images/document-icon.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import {
    auditFinancialReportTypes,
    bankStatementFileTypes,
} from 'src/utils/supportedFileTypes'
import { useMutation } from 'react-query'
import { updatePrimaryStatement } from 'src/api/create-account'
import ActionModalV2 from 'src/components/ActionModalV2'
import AccountTypeModal from '../../../../FinancialInformation/_components/AccountTypeModal'
import { sharedFormComponentPropTypes } from '../utils'
import { RetrievingStatementLoader } from 'src/pages/Consumer/SmartShoppingExperience/ConsumerKYC/components/Loaders'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const BankStatementSection = ({
    kycData,
    errors: dynamicFormErrors,
    removeErrors,
    offer,
    showOnlyMono = false,
    isOnePageKyc = false,
    onError,
    startMonoLoading = () => {},
    stopMonoLoading = () => {},
    connectToMonoLoading = false,
}) => {
    const userType =
        kycData?.consumer?.customer_type === 'RESIDENTIAL'
            ? 'residential'
            : 'business'
    const { id } = useParams()

    const pageTitle =
        'KYC - Financial Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const {
        state,
        dispatch,
        estimationId,
        handleSubmitBusinessFinancialInfo: handleFinancialInfoSubmission,
        postDocumentToS3,
        isUploadingFileUrl,
        isAuditReportUploading,
        errorAlert,
        successAlert,
        finanicalInfoFormik,
        handleUploadAuditStatement,
        isCustomerApproved,
        showBankAnalysisModal,
        setShowBankAnalysisModal,
    } = useAppContext()
    const {
        values: financialInfoValues,
        errors: financialInfoError,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        touched,
    } = finanicalInfoFormik
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const errors = useMappedErrors(
        userType === 'business'
            ? BUSINESS_FINANCIAL_INFO_UPDATE_REQUESTS_MAP
            : RESIDENTIAL_FINANCIAL_INFO_UPDATE_REQUESTS_MAP,
    )
    const { data: bankLists } = useGetBanks()
    const bankNames = bankLists?.data?.map(el => ({ value: el.name }))

    const { consumerBankStatements, notAuthorized } = useGetBankStatement(
        isAdminUser ? id : offer?.offerId,
        isAdminUser,
    )
    const queryClient = useQueryClient()
    const { mutate: postBankStatement, isLoading: isBankStatementUploading } =
        useUploadBankStatement({
            onSuccess: () => {},
        })

    useEffect(() => {
        const consumerData = kycData
        if (consumerData?.financial_verification?.bank_statements?.length > 0) {
            dispatch({
                type: SHOW_UPLOAD_BANK_STATEMENT,
                payload: false,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycData])

    const customerData = kycData
    const customerFinancialData = customerData?.financial_verification

    const [bankStatementUpload, setBankStatmentSuccessUpload] = useState([])
    const [bankStatement, setBankStatement] = useState([])

    const [
        isBankstatementPasswordProtected,
        setIsBankstatementPasswordProtected,
    ] = useState(false)

    const [auditCallbackFunction, setAuditCallbackFunction] = useState(null)
    const [bankCallbackFunction, setBankCallbackFunction] = useState(null)

    const [errorMessage, setErrorMessage] = useState('')
    const [bankStatementPassword, setBankStatementPassword] = useState('')
    const [showRemoveFileModal, setShowRemoveFileModal] = useState(false)
    const [fileToBeRemoved, setFileToBeRemoved] = useState({
        key: '',
        title: '',
        url: '',
        bankStatementId: '',
        fileType: '',
    })
    const bankStatementPasswordCorrectRef = useRef(false)
    const auditReportPasswordCorrectRef = useRef(false)

    const [checkedStatementId, setCheckedStatementId] = useState('')
    const [bankDetails, setBankDetails] = useState({
        id: '',
        name: '',
    })
    const [showPrimaryStatementModal, setShowPrimaryStatementModal] =
        useState(false)

    const [isAuditPasswordProtected, setIsAuditPasswordProtected] =
        useState(false)
    const [showAccountTypeModal, setShowAccountTypeModal] = useState(false)
    const [accountType, setAccountType] = useState('')
    const [statementUploadMethod, setStatementUploadMethod] = useState('')

    const { monoConnect } = useMono({
        onSuccess: data => {
            const { code } = data
            dispatch({
                type: SET_MONO_CODE,
                payload: code,
            })
            postBankStatement(
                {
                    mono_code: code,
                    upload_method: 'MONO',
                    estimationId,
                    ...(userType === 'residential' && {
                        is_salary_account: accountType === 'salary-account',
                    }),
                },
                {
                    onSuccess: () => {
                        successAlert('Successfully connected to Mono')
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            'SAVE_MONO',
                            eventTrackers['sseSaveFinancialInfo'].label,
                            eventTrackers['sseSaveFinancialInfo'].category,
                        )
                        setAccountType('')
                        setStatementUploadMethod('')
                        queryClient.invalidateQueries(
                            isOnePageKyc
                                ? 'fetch_one_page_kyc'
                                : 'fetch-dynamic-kyc-info',
                        )
                        queryClient.invalidateQueries('kycStatus')
                    },
                    onError: err => {
                        errorAlert(err?.response?.data?.message)
                        onError?.()
                    },
                },
            )
        },
        data: customerData?.consumer?.user,
        onClose: () => {
            console.log('closed mono widget')
            stopMonoLoading()
        },
    })

    const { openOkraWidget } = useOkra({
        setCustomerId: id => {
            postBankStatement(
                {
                    customer_id: id,
                    upload_method: 'OKRA',
                    estimationId,
                    ...(userType === 'residential' && {
                        is_salary_account: accountType === 'salary-account',
                    }),
                },
                {
                    onSuccess: () => {
                        successAlert('Successfully connected to Okra')
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            'SAVE_OKRA',
                            eventTrackers['sseSaveFinancialInfo'].label,
                            eventTrackers['sseSaveFinancialInfo'].category,
                        )
                        setAccountType('')
                        setStatementUploadMethod('')
                        queryClient.invalidateQueries(
                            isOnePageKyc
                                ? 'fetch_one_page_kyc'
                                : 'fetch-dynamic-kyc-info',
                        )
                        queryClient.invalidateQueries('kycStatus')
                    },
                },
            )
        },
        onClose: () => {},
        page: !isAdminUser && 'financialInfo',
    })

    const handleShowUploadAuditReportModal = () => {
        dispatch({
            type: SHOW_AUDIT_MODAL,
            payload: true,
        })
    }

    const handleShowBankStatementModal = () => {
        dispatch({
            type: SHOW_BANKSTATEMENT_MODAL,
            payload: true,
        })
    }

    const handleCloseUploadAuditModal = () => {
        dispatch({
            type: SHOW_AUDIT_MODAL,
            payload: false,
        })
        dispatch({
            type: SHOW_AUDITREPORT_PASSWORD_MODAL,
            payload: true,
        })
        dispatch({
            type: SET_AUDIT_REPORT_SUCCESS,
            payload: [],
        })
        dispatch({
            type: SET_AUDIT_FILE,
            payload: { ...state.auditFile, name: '', url: '' },
        })
    }

    const handleCloseBankStatementModal = () => {
        dispatch({
            type: SHOW_BANKSTATEMENT_MODAL,
            payload: false,
        })

        dispatch({
            type: SHOW_BANKSTATEMENT_PASSWORD_MODAL,
            payload: true,
        })
    }

    const handleUploadBankStatementUrl = () => {
        postDocumentToS3(
            {
                category: 'BANK_STATEMENT',
                file: bankStatementUpload[0],
            },
            {
                onSuccess: res => {
                    const docUrl = res?.url + res?.fields?.key || ''
                    const bankStatementPayload =
                        isBankstatementPasswordProtected
                            ? {
                                  upload_method: 'MANUAL_UPLOAD',
                                  document_url: docUrl,
                                  estimationId,
                                  password: bankStatementPassword,
                                  bank_name: financialInfoValues.bankName,
                                  ...(userType === 'residential' && {
                                      is_salary_account:
                                          accountType === 'salary-account',
                                  }),
                              }
                            : {
                                  upload_method: 'MANUAL_UPLOAD',
                                  document_url: docUrl,
                                  estimationId,
                                  bank_name: financialInfoValues.bankName,
                                  ...(userType === 'residential' && {
                                      is_salary_account:
                                          accountType === 'salary-account',
                                  }),
                              }
                    postBankStatement(bankStatementPayload, {
                        onSuccess: () => {
                            successAlert('Bank statement uploaded successfully')
                            dispatch({
                                type: SHOW_BANKSTATEMENT_PASSWORD_MODAL,
                                payload: false,
                            })

                            setBankStatmentSuccessUpload([])
                            setBankStatement([])
                            dispatch({
                                type: SHOW_BANKSTATEMENT_MODAL,
                                payload: false,
                            })
                            dispatch({
                                type: SHOW_THIRD_PARTY,
                                payload: true,
                            })
                            setBankStatementPassword('')
                            setFieldValue('bankName', '')
                            setAccountType('')
                            setStatementUploadMethod('')
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                'SAVE_MANUAL_BANK_STATEMENT_UPLOAD',
                                eventTrackers['sseSaveFinancialInfo'].label,
                                eventTrackers['sseSaveFinancialInfo'].category,
                            )
                            queryClient.invalidateQueries(
                                isOnePageKyc
                                    ? 'fetch_one_page_kyc'
                                    : 'fetch-dynamic-kyc-info',
                            )
                            queryClient.invalidateQueries('kycStatus')
                        },
                        onError: err => {
                            errorAlert(
                                err?.response?.data?.message ||
                                    'Error occurred',
                            )
                        },
                    })
                    dispatch({
                        type: SET_DOC_URL,
                        payload: docUrl,
                    })
                },
                onError: err => {
                    errorAlert(formatErrorMessages(err?.response?.data?.errors))
                },
            },
        )
    }

    useEffect(() => {
        if (state.auditReportSuccessUpload[0]) {
            checkPDFPassword(
                state.auditReportSuccessUpload[0],
                setIsAuditPasswordProtected,
                auditReportPasswordCorrectRef,
                setAuditCallbackFunction,
                setErrorMessage,
                () => handleFinancialInfoSubmission(userType),
                state.auditReportPassword,
            )
        }
        if (bankStatementUpload[0]) {
            checkPDFPassword(
                bankStatementUpload[0],
                setIsBankstatementPasswordProtected,
                bankStatementPasswordCorrectRef,
                setBankCallbackFunction,
                setErrorMessage,
                handleUploadBankStatementUrl,
                bankStatementPassword,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.auditReportSuccessUpload, bankStatementUpload])

    const handleBankStatementPasswordSubmit = () => {
        if (bankCallbackFunction) {
            if (!bankStatementPassword) {
                return setErrorMessage('Password is required')
            }
            bankCallbackFunction(bankStatementPassword)
            checkPDFPassword(
                bankStatementUpload[0],
                setIsBankstatementPasswordProtected,
                bankStatementPasswordCorrectRef,
                setErrorMessage,
                handleUploadBankStatementUrl,
                bankStatementPassword,
            )
        }
    }

    const handleAuditStatementPasswordSubmit = () => {
        if (auditCallbackFunction) {
            if (!state.auditReportPassword) {
                return setErrorMessage('Password is required')
            }
            auditCallbackFunction(state.auditReportPassword)
            checkPDFPassword(
                state.auditReportSuccessUpload[0],
                setIsAuditPasswordProtected,
                auditReportPasswordCorrectRef,
                setBankCallbackFunction,
                setErrorMessage,
                () => handleFinancialInfoSubmission(userType),
                state.auditReportPassword,
            )
        }
    }

    const handleRemoveFile = (
        key,
        title,
        url = null,
        bankStatementId = null,
    ) => {
        setFileToBeRemoved({
            key: key,
            title: title,
            url: url,
            bankStatementId: bankStatementId,
            fileType:
                key === 'audit-report' ? 'AUDIT_REPORT' : 'BANK_STATEMENT',
        })
        setShowRemoveFileModal(true)
    }

    const closeRemoveFileModal = () => {
        setFileToBeRemoved({
            key: '',
            title: '',
            url: '',
            bankStatementId: '',
            fileType: '',
        })
        setShowRemoveFileModal(false)
    }

    const closeRemoveFileModalAfterSuccess = () => {
        if (fileToBeRemoved.key === 'audit-report') {
            dispatch({
                type: SET_AUDIT_REPORT_SUCCESS,
                payload: [],
            })
            dispatch({
                type: SET_AUDIT_FILE,
                payload: { ...state.auditFile, name: '', url: '' },
            })
        }

        if (fileToBeRemoved.key === 'bank-statement') {
            // timeout to make up for backend delay
            setTimeout(() => {
                queryClient.invalidateQueries(
                    isOnePageKyc
                        ? 'fetch_one_page_kyc'
                        : 'fetch-dynamic-kyc-info',
                )
                dispatch({
                    type: SHOW_THIRD_PARTY,
                    payload: true,
                })
                dispatch({
                    type: SHOW_UPLOAD_BANK_STATEMENT,
                    payload: true,
                })
                dispatch({
                    type: SET_BANK_STATEMENT_ERROR,
                    payload: '',
                })
            }, 3000)
        }
        setShowRemoveFileModal(false)
    }

    useEffect(() => {
        if (isNaN(Number(state.monthly_revenue))) {
            dispatch({
                type: SET_MONTHLY_REVENUE_ERROR,
                payload: 'Monthly revenue must be a valid number',
            })
        } else {
            dispatch({
                type: SET_MONTHLY_REVENUE_ERROR,
                payload: '',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.monthly_revenue])
    const handleCancelBankStatementPasswordModal = () => {
        dispatch({
            type: SHOW_BANKSTATEMENT_PASSWORD_MODAL,
            payload: false,
        })
        setBankStatmentSuccessUpload([])
        setBankStatementPassword('')
        setErrorMessage('')
    }
    const [showOverrideModal, setShowOverrideModal] = useState(false)

    useEffect(() => {
        dispatch({
            type: SET_UPDATE_REQUEST_ERRORS,
            payload: errors,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])

    useScrollToError(
        errors,
        typeof errors === 'object' ? !!Object.keys(errors).length : !!errors,
    )
    const showOkraBtn =
        process.env.REACT_APP_FLAG_SHOW_OKRA_INTEGRATION === 'true'
            ? true
            : false

    const handleTracking = e => {
        const { name, value } = e.target
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_FINANCIAL_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    const {
        mutate: updatePrimaryStatementMutation,
        isLoading: isPrimaryBankStatementLoading,
    } = useMutation({
        mutationFn: ({ id }) =>
            updatePrimaryStatement(estimationId, { bank_statement_id: id }),
        onSuccess: (_, variables) => {
            setCheckedStatementId(variables?.id)
            setShowPrimaryStatementModal(false)
            queryClient.invalidateQueries(
                isOnePageKyc ? 'fetch_one_page_kyc' : 'fetch-dynamic-kyc-info',
            )
            queryClient.invalidateQueries('kycStatus')
        },
        onError: err => {
            setShowPrimaryStatementModal(false)
            errorAlert(err?.response?.data?.message || 'Error occurred')
        },
    })

    const handleCheck = (e, name, id) => {
        e.preventDefault()

        if (id === checkedStatementId) return
        setBankDetails(prev => {
            return { ...prev, id, name }
        })
        setShowPrimaryStatementModal(true)
    }

    useEffect(() => {
        const primaryStatement = customerFinancialData?.bank_statements.find(
            statement => statement?.primary_statement === true,
        )
        if (primaryStatement) {
            setCheckedStatementId(primaryStatement?.id)
        }
    }, [customerFinancialData?.bank_statements])

    const history = useHistory()
    const latestBankStatement = customerFinancialData?.bank_statements[0]
    const banksStatementAnalysisInReview =
        latestBankStatement?.analysis_context?.status === 'IN_PROGRESS' ||
        latestBankStatement?.analysis_context?.status === 'RETRYING' ||
        latestBankStatement?.analysis_context?.status === 'EMPTY' ||
        latestBankStatement?.analysis_context?.status === 'FAILED'
    const bankStatementAnalysisIncomplete =
        latestBankStatement?.analysis_context?.status === 'DONE' &&
        latestBankStatement?.analysis_context?.statement_complete === false
    const bankStatementAnalysisInvalid =
        latestBankStatement?.document_meta_data?.invalid_statement === true

    const bankStatementAnalysisTexts = {
        'in-review': {
            heading: 'Your bank statement is currently being analyzed',
            paragraph:
                'We were not able to analyse your bank statement immediately, we are currently working on this and will reach out to you via email once its completed',
        },
        incomplete: {
            heading: 'Your previous uploaded bank statement is incomplete',
            paragraph: `We require ${
                userType === 'business' ? '12-24' : '6-12'
            } up-to-date months Verified  Bank Statement. Please upload more to continue`,
        },
        invalid: {
            heading: 'Your previous uploaded bank statement is Invalid',
            paragraph:
                'Your previous uploaded bank statement is Invalid, please upload a new bank statement',
        },
    }

    const openAccountTypeModal = method => {
        if (userType === 'residential') {
            setShowAccountTypeModal(true)
            if (method === 'manual-upload') {
                setStatementUploadMethod('manual-upload')
            }
            if (method === 'mono') {
                setStatementUploadMethod('mono')
            }
            if (method === 'okra') {
                setStatementUploadMethod('okra')
            }
        } else {
            handleAccountTypeModalClick(method)
        }
    }

    const handleAccountTypeModalClick = method => {
        const methodStrToUse =
            userType === 'residential' ? statementUploadMethod : method
        setShowAccountTypeModal(false)
        if (methodStrToUse === 'manual-upload') {
            handleShowBankStatementModal()
        }

        if (methodStrToUse === 'mono') {
            monoConnect.open()
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                `SELECTED MONO STATEMENT UPLOAD`,
                'SMART_SHOPPING_EXPERIENCE_KYC',
                'KYC_FINANCIAL_INFO',
            )
        }

        if (methodStrToUse === 'okra') {
            openOkraWidget()
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                `SELECTED OKRA STATEMENT UPLOAD`,
                'SMART_SHOPPING_EXPERIENCE_KYC',
                'KYC_FINANCIAL_INFO',
            )
        }

        dispatch({
            type: SET_BANK_STATEMENT_ERROR,
            payload: '',
        })
    }

    const closeAccountTypeModal = () => {
        setShowAccountTypeModal(false)
        setAccountType('')
        setStatementUploadMethod('')
        stopMonoLoading()
    }

    useEffect(() => {
        if (bankStatementAnalysisIncomplete || bankStatementAnalysisInvalid) {
            setShowBankAnalysisModal(true)
        }
        // eslint-disable-next-line
    }, [customerFinancialData])

    return (
        <>
            <ActionModalV2
                modalWidth="475px"
                onCancel={() => setShowPrimaryStatementModal(false)}
                actionModalOpen={showPrimaryStatementModal}
                handlePrimaryBtnClick={() => {
                    updatePrimaryStatementMutation({ id: bankDetails.id })
                }}
                primaryBtnLoading={isPrimaryBankStatementLoading}
                title="Primary bank Statement"
                primaryBtnText="Confirm"
                headerText="Set as primary bank statement"
                subTitle={`Please confirm that you would like to set ${
                    bankDetails?.name ?? 'this'
                } as your primary bank statement.`}
                actionType={'warning'}
                v2
                showFooter
            />
            <OverrideCheckModal
                showOverrideModal={showOverrideModal}
                type="BVN"
                onCancel={() => {
                    setShowOverrideModal(false)
                }}
            />
            {isBankstatementPasswordProtected && (
                <ModalContainer
                    title="Enter your bank statement password"
                    onClose={handleCancelBankStatementPasswordModal}
                    isOpen={state.showBankStatementPasswordModal}
                    footer={
                        <ButtonComponent
                            disabled={isUploadingFileUrl}
                            handleClick={handleBankStatementPasswordSubmit}
                            marginLeft="auto"
                            type="primary"
                        >
                            {isUploadingFileUrl || isBankStatementUploading ? (
                                <InlineLoader />
                            ) : (
                                'Confirm'
                            )}
                        </ButtonComponent>
                    }
                >
                    <div className={styles.modal_content}>
                        <div className={styles.password_section}>
                            <section className={styles.file_preview}>
                                <img
                                    src={lockIcon}
                                    alt="password"
                                    width="28px"
                                    height="28px"
                                />
                            </section>
                            <section className={styles.file_section_preview}>
                                <div className={styles.file_section}>
                                    <div className={styles.file_name}>
                                        <img src={attachIcon} />
                                        <p>{bankStatementUpload[0]?.name}</p>
                                    </div>
                                    <div className={styles.file_datestamp}>
                                        <p>
                                            {new Date().toDateString()} at{' '}
                                            {new Date().toLocaleTimeString()}
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <InputFields
                            title="Password"
                            type="password"
                            name="bankPassword"
                            value={bankStatementPassword}
                            handleChange={e =>
                                setBankStatementPassword(e.target.value)
                            }
                            onBlur={e => {
                                handleTracking(e)
                            }}
                        />
                        <span className={styles.errorMessage}>
                            {errorMessage}
                        </span>
                    </div>
                </ModalContainer>
            )}

            <AccountTypeModal
                showModal={showAccountTypeModal}
                onCancel={() => closeAccountTypeModal()}
                handleClick={() => {
                    handleAccountTypeModalClick()
                }}
                accountType={accountType}
                setAccountType={setAccountType}
            />

            <UploadModal
                acceptableFileTypes={bankStatementFileTypes}
                primaryBtnText={
                    isBankStatementUploading || isUploadingFileUrl ? (
                        <InlineLoader />
                    ) : (
                        'Confirm'
                    )
                }
                isLoading={isBankStatementUploading || isUploadingFileUrl}
                title="Upload Bank Statement"
                showModal={state.isBankStatementOpen}
                onCancel={handleCloseBankStatementModal}
                successUpload={bankStatementUpload}
                setSuccessUpload={setBankStatmentSuccessUpload}
                setFileToState={setBankStatement}
                handlePrimaryBtnClick={() => {
                    if (
                        bankStatementUpload.length > 0 &&
                        isBankstatementPasswordProtected === false
                    ) {
                        handleUploadBankStatementUrl()
                    } else {
                        handleCloseBankStatementModal()
                    }
                }}
            />
            <RemoveFileModal
                showModal={showRemoveFileModal}
                fileToBeRemoved={fileToBeRemoved}
                closeRemoveFileModal={closeRemoveFileModal}
                closeRemoveFileModalAfterSuccess={
                    closeRemoveFileModalAfterSuccess
                }
            />
            {/* Bank Analysis modal */}

            <SecondaryModal
                showModal={showBankAnalysisModal}
                onCancel={() => setShowBankAnalysisModal(false)}
                modalWidth={'475px'}
                title={
                    banksStatementAnalysisInReview
                        ? 'Bank statement in review'
                        : 'Update required on bank statement'
                }
                v2
                content={
                    <div style={{ position: 'relative' }}>
                        <div className={styles.BankAnalysisModalStyle}>
                            <DocumentIcon />
                            <h2>
                                {
                                    bankStatementAnalysisTexts[
                                        bankStatementAnalysisInvalid
                                            ? 'invalid'
                                            : bankStatementAnalysisIncomplete
                                            ? 'incomplete'
                                            : 'in-review'
                                    ].heading
                                }
                            </h2>
                            <p>
                                {
                                    bankStatementAnalysisTexts[
                                        bankStatementAnalysisInvalid
                                            ? 'invalid'
                                            : bankStatementAnalysisIncomplete
                                            ? 'incomplete'
                                            : 'in-review'
                                    ].paragraph
                                }
                            </p>
                        </div>
                    </div>
                }
                showFooter
                footerLeftContent="chat"
                primaryBtnText="Continue"
                handlePrimaryBtnClick={() => {
                    if (banksStatementAnalysisInReview) {
                        history.push({
                            pathname: userInfo?.consumerKYCHome,
                            state: { justCompletedKYC: true },
                        })
                        setShowBankAnalysisModal(false)
                    }
                    setShowBankAnalysisModal(false)
                }}
                primaryBtnWidth={'160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            />

            {!isAdminUser && userType === 'business' && (
                <UploadField
                    fileUploaded={
                        state.auditReportSuccessUpload.length > 0 ||
                        state.auditFile.name?.length > 0
                    }
                    handleRemoveClick={() => {
                        handleRemoveFile(
                            'audit-report',
                            'audit report',
                            state.auditFile.url,
                            null,
                        )
                    }}
                    handleUploadClick={() => {
                        handleShowUploadAuditReportModal()
                        dispatch({
                            type: SET_AUDIT_REPORT_ERROR,
                            payload: '',
                        })
                    }}
                    btnText="Audited financial report"
                    fileName={
                        state.auditFile.name ||
                        state.auditReportSuccessUpload?.[0]?.name
                    }
                    disabled={isAdminUser || isCustomerApproved}
                    errorMessage={state.errors.audit_report}
                    margin="0 0 24px 0"
                />
            )}

            {userType === 'business' && (
                <UploadModal
                    acceptableFileTypes={auditFinancialReportTypes}
                    primaryBtnText={
                        isUploadingFileUrl ? <InlineLoader /> : 'Confirm'
                    }
                    accept=".xls,.xlsx,docx,doc,.pdf"
                    title="Upload Financial Report"
                    showModal={state.isAuditReportOpen}
                    onCancel={
                        !isUploadingFileUrl && handleCloseUploadAuditModal
                    }
                    successUpload={state.auditReportSuccessUpload}
                    setSuccessUpload={e => {
                        dispatch({
                            type: SET_AUDIT_REPORT_SUCCESS,
                            payload: e,
                        })
                        setFieldValue('auditReport', {
                            fileName: e?.[0]?.name,
                            fileOriginObj: e?.[0]?.fileOriginObj,
                        })
                    }}
                    setFileToState={e =>
                        dispatch({ type: SET_AUDIT_REPORT, payload: e })
                    }
                    handlePrimaryBtnClick={() => {
                        if (
                            state.auditReportSuccessUpload.length > 0 &&
                            isAuditPasswordProtected === false
                        ) {
                            handleUploadAuditStatement()
                        } else {
                            handleCloseUploadAuditModal()
                        }
                    }}
                />
            )}

            {isAuditPasswordProtected && (
                <ModalContainer
                    title="Enter your audit report password"
                    onClose={() => {
                        dispatch({
                            type: SHOW_AUDITREPORT_PASSWORD_MODAL,
                            payload: false,
                        })
                        dispatch({
                            type: SET_AUDIT_REPORT_SUCCESS,
                            payload: [],
                        })
                        setErrorMessage('')
                    }}
                    isOpen={state.showAuditReportPasswordModal}
                    footer={
                        <ButtonComponent
                            handleClick={handleAuditStatementPasswordSubmit}
                            marginLeft="auto"
                            type="primary"
                        >
                            {isAuditReportUploading || isUploadingFileUrl ? (
                                <InlineLoader />
                            ) : (
                                'Confirm'
                            )}
                        </ButtonComponent>
                    }
                >
                    <div className={styles.modal_content}>
                        <div className={styles.password_section}>
                            <section className={styles.file_preview}>
                                <img
                                    src={lockIcon}
                                    alt="password"
                                    width="28px"
                                    height="28px"
                                />
                            </section>
                            <section className={styles.file_section_preview}>
                                <div className={styles.file_section}>
                                    <div className={styles.file_name}>
                                        <img src={attachIcon} />
                                        <p>
                                            {
                                                state
                                                    .auditReportSuccessUpload[0]
                                                    ?.name
                                            }
                                        </p>
                                    </div>
                                    <div className={styles.file_datestamp}>
                                        <p>
                                            {new Date().toDateString()} at{' '}
                                            {new Date().toLocaleTimeString()}
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <InputFields
                            title="Password"
                            type="password"
                            name="auditPassword"
                            value={state.auditReportPassword}
                            handleChange={e =>
                                dispatch({
                                    type: SET_AUDIT_REPORT_PASSWORD,
                                    payload: e.target.value,
                                })
                            }
                            onBlur={e => {
                                handleTracking(e)
                            }}
                        />
                        <span className={styles.errorMessage}>
                            {errorMessage}
                        </span>
                    </div>
                </ModalContainer>
            )}

            {!isAdminUser && !isOnePageKyc && (
                <Label
                    title={`Bank Statement ${
                        userType === 'business' ? '& Financial report' : ''
                    }`}
                    subTitle="To help us confirm your transaction history, kindly provide your bank statement covering at least 6 to 12 months. Please ensure that the name on the statement matches the one provided during your KYC."
                />
            )}

            {!isAdminUser &&
            customerFinancialData?.bank_statements?.length > 0 ? (
                <div className={styles.uploaded_bank_statements}>
                    {customerFinancialData?.bank_statements?.map(
                        (el, i, arr) => {
                            return (
                                <BankStatement
                                    meta_data={el}
                                    key={el.id}
                                    handleRemoveFile={handleRemoveFile}
                                    showPrimaryStatement={arr.length > 1}
                                    checkedStatementId={checkedStatementId}
                                    handleCheck={(event, name) =>
                                        handleCheck(event, name, el.id)
                                    }
                                    disableCheck={isAdminUser}
                                />
                            )
                        },
                    )}
                    <section className={styles.bank_statement}>
                        {!state.showThirdParty ||
                            (!state.showUploadBankStatement && (
                                <>
                                    <p>Want to add another bank statement?</p>
                                    <button
                                        onClick={() => {
                                            dispatch({
                                                type: SHOW_THIRD_PARTY,
                                                payload: true,
                                            })
                                            dispatch({
                                                type: SHOW_UPLOAD_BANK_STATEMENT,
                                                payload: true,
                                            })
                                            dispatch({
                                                type: SET_BANK_STATEMENT_ERROR,
                                                payload: '',
                                            })
                                        }}
                                        disabled={
                                            isAdminUser || isCustomerApproved
                                        }
                                    >
                                        Add another bank statement
                                    </button>
                                </>
                            ))}
                    </section>
                </div>
            ) : (
                <></>
            )}
            {!isAdminUser &&
                state.showThirdParty &&
                state.showUploadBankStatement && (
                    <>
                        {
                            <section
                                className={`${styles.thirdparty} ${
                                    isOnePageKyc ? styles.onepageKycClass : ''
                                }`}
                            >
                                <p>
                                    Click “connect to Mono”{' '}
                                    {showOkraBtn &&
                                        !showOnlyMono &&
                                        '"or Connect to Okra" '}
                                    to securely retrieve your account statement
                                    faster.{' '}
                                    <span>
                                        Please note that this does NOT give us
                                        access to your bank username, and
                                        password, nor can we make payments
                                        without your permission.
                                    </span>
                                </p>
                                <div className={styles.button_group}>
                                    {connectToMonoLoading ? (
                                        <RetrievingStatementLoader />
                                    ) : (
                                        <button
                                            onClick={() => {
                                                openAccountTypeModal('mono')
                                                removeErrors('bankStatements')
                                                startMonoLoading()
                                            }}
                                            disabled={
                                                isAdminUser ||
                                                isCustomerApproved
                                            }
                                        >
                                            <img src={mono} />
                                            {isMobile
                                                ? 'Use Mono'
                                                : 'Connect to Mono'}
                                        </button>
                                    )}

                                    {process.env
                                        .REACT_APP_FLAG_SHOW_OKRA_INTEGRATION ===
                                        'true' &&
                                        !showOnlyMono && (
                                            <button
                                                onClick={() => {
                                                    openAccountTypeModal('okra')
                                                    removeErrors(
                                                        'bankStatements',
                                                    )
                                                }}
                                                disabled={
                                                    isAdminUser ||
                                                    isCustomerApproved
                                                }
                                            >
                                                <img src={okra} />
                                                {isMobile
                                                    ? 'Use Okra'
                                                    : 'Connect to Okra'}
                                            </button>
                                        )}
                                </div>
                                <p className={styles.note}>
                                    Please note that we do not support dollar
                                    accounts or microfinance banks.
                                </p>
                            </section>
                        }

                        <section className={styles.bank_statement}>
                            <p>Do you have your bank statement in PDF?</p>
                            <button
                                onClick={() => {
                                    dispatch({
                                        type: SHOW_THIRD_PARTY,
                                        payload: false,
                                    })
                                    dispatch({
                                        type: SHOW_UPLOAD_BANK_STATEMENT,
                                        payload: false,
                                    })
                                    appTracking(
                                        pageUrlName,
                                        pageTracker,
                                        pageTitle,
                                        `SELECTED MANUAL STATEMENT UPLOAD`,
                                        'SMART_SHOPPING_EXPERIENCE_KYC',
                                        'KYC_FINANCIAL_INFO',
                                    )
                                }}
                                disabled={isAdminUser || isCustomerApproved}
                            >
                                Upload here
                            </button>
                        </section>
                    </>
                )}
            {!isAdminUser &&
                state.showThirdParty === false &&
                state.showUploadBankStatement === false && (
                    <>
                        <div className={`${isOnePageKyc ? styles.group : ''}`}>
                            <SelectField
                                value={financialInfoValues.bankName}
                                currentSelected={financialInfoValues.bankName}
                                handleChange={(_, value) => {
                                    setFieldValue('bankName', value)
                                    appTracking(
                                        pageUrlName,
                                        pageTracker,
                                        pageTitle,
                                        `FILLED_${_?.toUpperCase()}`,
                                        'SMART_SHOPPING_EXPERIENCE_KYC',
                                        'KYC_FINANCIAL_INFO',
                                        ['GA', 'MP'],
                                        'event',
                                        { value },
                                    )
                                }}
                                onBlur={() => {
                                    setFieldTouched('bankName', true)
                                    if (!financialInfoValues.bankName)
                                        setFieldError(
                                            'bankName',
                                            financialInfoError.bankName,
                                        )
                                }}
                                values={bankNames}
                                dropdownPositionRelative
                                name="bankName"
                                floatingLabel={
                                    financialInfoValues.bankName
                                        ? 'Select the bank you want to upload from.'
                                        : ''
                                }
                                initialOption="Select the bank you want to upload from."
                                selectWidth="100%"
                                errorMessage={
                                    touched.bankName &&
                                    financialInfoError.bankName
                                }
                                disabled={isAdminUser || isCustomerApproved}
                                withCheckBox
                                marginBottom="16px"
                                showSearch
                            />
                            <UploadField
                                fileUploaded={bankStatement.length > 0}
                                btnText={'Upload Bank Statement (PDF)'}
                                handleUploadClick={() => {
                                    if (!financialInfoValues.bankName) {
                                        setFieldError(
                                            'bankName',
                                            'Please select your bank name',
                                        )
                                        return
                                    }
                                    openAccountTypeModal('manual-upload')
                                    removeErrors('bankStatements')
                                }}
                                handleRemoveClick={() => setBankStatement([])}
                                fileName={bankStatement?.[0]?.name}
                                disabled={isAdminUser || isCustomerApproved}
                            />
                        </div>
                        <section className={styles.bank_statement}>
                            <p>Don&apos;t have your bank statement?</p>
                            <button
                                onClick={() => {
                                    dispatch({
                                        type: SHOW_THIRD_PARTY,
                                        payload: true,
                                    })
                                    dispatch({
                                        type: SHOW_UPLOAD_BANK_STATEMENT,
                                        payload: true,
                                    })
                                    dispatch({
                                        type: SET_BANK_STATEMENT_ERROR,
                                        payload: '',
                                    })
                                }}
                                disabled={isAdminUser || isCustomerApproved}
                            >
                                Use our third party partner
                            </button>
                        </section>
                    </>
                )}

            {!isAdminUser &&
                (state.errors.bankStatement ||
                    dynamicFormErrors?.bankStatements) && (
                    <p className={styles.bank_statement_error}>
                        {state.errors.bankStatement ||
                            dynamicFormErrors?.bankStatements}
                    </p>
                )}

            {isAdminUser && (
                <div className={styles.bankStatementWrapper}>
                    <h3>Customer&apos;s Bank Statement</h3>

                    {isAdminUser && notAuthorized ? null : isAdminUser &&
                      consumerBankStatements?.length <= 0 ? (
                        <BankStatementDisplay
                            bankStatement={{}}
                            isEmpty={true}
                        />
                    ) : (
                        consumerBankStatements.map(
                            (statement, index, statements) => (
                                <BankStatementDisplay
                                    key={index}
                                    bankStatement={statement}
                                    isEmpty={false}
                                    showPrimaryTag={statements.length > 1}
                                />
                            ),
                        )
                    )}
                </div>
            )}
        </>
    )
}

BankStatementSection.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default BankStatementSection
