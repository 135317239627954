/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import FormTitleBar from '../../../../components/FormTitleBar'
import { ActionSection } from '../../index'
import FormSubBox from '../../FormSubBox'
import Button from '../../../../components/Button'
// import { ReactComponent as ResumeIcon } from '../../../../assets/images/attach-icon.svg'

import { PlusOutlined } from '@ant-design/icons'
import Toast from '../../../../components/Toast'
import ActionModal from '../../../../components/ActionModal'
// import { InlineLoader } from '../../../../components/Loader'

import DataForm from './DataForm'

import { WorkExperienceValidationSchema } from '../../../../utils/validationSchema'

import {
    addExperienceApi,
    getAllExperienceApi,
    updateExperienceApi,
    deleteExperienceApi,
    getAllTechApi,
    bulkDeleteExperienceApi,
    bulkSaveExperienceApi,
} from '../../../../api/onboarding/workexperience'
import {
    resumeUploadApi,
    parseResumeApi,
} from '../../../../api/onboarding/parseResume'

import styles from './work.module.scss'
import { eventTrackers } from 'src/utils/eventTrackers'
import { appTracking } from 'src/utils/appTracker'

const {
    FormBox,
    AddNewButton,
    // TopActionBox, ResumeBox
} = styles

const WorkExperience = ({ nextHandler, prevHandler }) => {
    const pageTitle = 'SunFi - Onboarding | Work Experience'
    const pageUrlName = window.location.pathname
    const pageTracker = 'Onboarding'
    let [inputs, setInputs] = useState([
        {
            formId: 1,
            companyname: '',
            role: '',
            description: '',
            tech: [],
            startDate: null,
            endDate: null,
            currentWork: false,
            updateId: null,
            saved: false,
        },
    ])
    const [totalForm, setTotalForm] = useState(1)
    const [closedFormList, setCloseFormList] = useState([])
    const [openForm, setOpenForm] = useState([1])
    const [errors, setErrors] = useState([])
    const [selectedExperience, setSelectedExperience] = useState({})
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteFormId, setDeleteFormId] = useState(null)
    const [deleteFormType, setDeleteFormType] = useState('save')
    const [deleteProcess, setDeleteProcess] = useState(false)
    const [errorType, setErrorType] = useState(null)
    const [techUsedData, setTechUsedData] = useState([])
    const [uploadFile, setUploadFile] = useState(null)
    const [parseLoading, setParseLoading] = useState(false)
    const [uploadUrl, setUploadUrl] = useState('')
    const [actionPrompt, setActionPrompt] = useState(false)
    const [parsedData, setParsedData] = useState([])
    const [bulkProcessing, setBulkProcessing] = useState(false)
    //const [bulkProcessing, setBulkProcessing] = useState(false)
    const [itemsForDeletion, setItemsForDeletion] = useState([])
    const [, setDeleteProcessing] = useState(false)

    const handleCloseForm = id => {
        if (!closedFormList.includes(id)) {
            const checkList = openForm.filter(formId => {
                return formId !== id
            })
            setOpenForm([...checkList])
            updateCloseFormList([...closedFormList, id])
        }
    }

    const updateCloseFormList = val => {
        setCloseFormList(val)
    }

    const checkCloseFormState = id => {
        const stateValue = closedFormList.includes(id)
        return stateValue
    }

    const handleInputChange = (event, id, formDetail) => {
        if (event) {
            event.persist()
        }
        const singleInput = inputs.filter(input => {
            return input.formId === id
        })
        const allInputs = inputs
        const inputIndex = inputs.findIndex(input => input.formId === id)
        const newInputState = { ...singleInput[0], ...formDetail }

        allInputs[inputIndex] = newInputState

        updateInputState(allInputs)
        handleSaveExperience(newInputState, id)
    }

    const handleInputUpdate = (id, formDetail) => {
        const singleInput = inputs.filter(input => {
            return input.formId === id
        })
        const allInputs = inputs
        const inputIndex = inputs.findIndex(input => input.formId === id)
        const newInputState = { ...singleInput[0], ...formDetail }

        allInputs[inputIndex] = newInputState

        inputs = allInputs
    }

    const updateInputState = val => {
        setInputs([...val])
    }

    const updateFormTotal = () => {
        const formId = Math.random()

        setTotalForm(totalForm + 1)

        const newArray = openForm
        newArray.push(formId)
        setOpenForm(newArray)

        const newState = [
            ...inputs,
            {
                formId,
                companyname: '',
                role: '',
                description: '',
                tech: [],
                startDate: '',
                endDate: '',
                currentWork: false,
                updateId: null,
                saved: false,
            },
        ]
        setInputs(newState)
    }

    const getFormInputs = id => {
        const singleInput = inputs.filter(input => {
            return input.formId === id
        })
        return singleInput[0]
    }

    const editFormState = id => {
        const newArray = openForm
        newArray.push(id)
        setOpenForm(newArray)

        if (closedFormList.includes(id)) {
            const checkList = closedFormList.filter(formId => {
                return formId !== id
            })
            updateCloseFormList([...checkList])
        }
    }

    const removeForm = id => {
        const otherInputs = inputs.filter(input => {
            return input.formId !== id
        })
        const checkList = openForm.filter(formId => {
            return formId !== id
        })
        setOpenForm([...checkList])
        setTotalForm(totalForm - 1)
        setInputs([...otherInputs])
    }

    const { isLoading, data, error, refetch } = useQuery(
        'addSingleWorkExperience',
        () => addExperienceApi(selectedExperience),
        {
            enabled: false,
            retry: false,
        },
    )

    const { isFetching: bulkSaveLoading, refetch: bulkSaveRefetch } = useQuery(
        'bulkSaveWorkExperience',
        () => bulkSaveExperienceApi(selectedExperience),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                nextHandler()
                setBulkProcessing(false)
            },
            onError: () => {
                setBulkProcessing(false)
                setErrorType('save')
            },
        },
    )

    const {
        isLoading: updateLoading,
        data: updateData,
        error: updateError,
        refetch: updateRefetch,
    } = useQuery(
        'updateSingleWorkExperience',
        () =>
            updateExperienceApi(
                selectedExperience?.updateId,
                selectedExperience,
            ),
        {
            enabled: false,
            retry: false,
        },
    )

    const {
        isLoading: deleteLoading,
        data: deleteData,
        error: deleteError,
        refetch: deleteRefetch,
    } = useQuery(
        'deleteSingleWorkExperience',
        () => deleteExperienceApi(deleteFormId),
        {
            enabled: false,
            retry: false,
            onSuccess: () => setDeleteProcess(false),
            onError: () => {
                setDeleteModal(!deleteModal)
                setErrorType('delete')
            },
        },
    )

    const {
        data: getAllExperienceData,
        error: getAllExperienceError,
        refetch: getAllExperienceRefetch,
    } = useQuery('getAllWorkExperience', () => getAllExperienceApi(), {
        enabled: false,
        retry: false,
    })

    const { data: getAllTechData, refetch: getAllTechRefetch } = useQuery(
        'getAllTech',
        () => getAllTechApi(),
        {
            enabled: false,
            retry: false,
        },
    )

    const handleSaveExperience = (formInputs, formId) => {
        setErrors([])
        let errList = {}

        if (!formInputs.currentWork && !formInputs.endDate) {
            errList.endDate = 'Exit date is required'
            errList.formId = formId
        }

        if (Object.keys(errList).length === 0) {
            setErrors([])
        } else {
            setErrors([{ ...errList }])
        }

        WorkExperienceValidationSchema.validate(formInputs, {
            abortEarly: false,
        })
            .then(() => {
                const userInput = {
                    name: formInputs.companyname,

                    start_date: format(
                        new Date(formInputs.startDate),
                        'yyyy-MM-dd',
                    ),
                    current_role:
                        formInputs.currentWork === null ? false : true,
                    role: formInputs.role,
                    technologies: formInputs.tech,
                    description: formInputs.description,
                    updateId: formInputs.updateId,
                }

                setSelectedExperience(
                    formInputs?.endDate
                        ? {
                              ...userInput,

                              end_date: format(
                                  new Date(formInputs.endDate),
                                  'yyyy-MM-dd',
                              ),
                          }
                        : userInput,
                )

                if (errors?.length < 1 && Object.keys(errList).length === 0) {
                    handleCloseForm(formId)
                    if (formInputs?.updateId) {
                        updateRefetch()
                    } else {
                        refetch()
                    }
                }
            })
            .catch(err => {
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                    errList = { ...errList, formId }
                })

                let errorArray = errors
                const formOldErrors = errorArray.filter(
                    error => errors.formId === error.formId,
                )
                const findFormIndex = errorArray[formOldErrors]

                if (formOldErrors?.length > 0) {
                    errorArray[findFormIndex] = errList
                } else {
                    errorArray.push(errList)
                }
                setErrors(errorArray)
            })
    }

    const handleBulkSaveExperience = () => {
        setErrors([])
        const userInputs = inputs.map(input => {
            const formatInput = {
                companyname: input.companyname,

                startDate: input.startDate
                    ? format(new Date(input.startDate), 'yyyy-MM-dd')
                    : format(new Date(), 'yyyy-MM-dd'),
                current_role:
                    input.currentWork === null || input.currentWork === false
                        ? false
                        : true,
                role: input.role,
                tech: input.tech,
                description: input.description,
                updateId: input.updateId,
                formId: input.formId,
            }
            if (
                input.endDate &&
                (input.currentWork === false || input.currentWork === null)
            ) {
                return {
                    ...formatInput,
                    endDate: format(new Date(input.endDate), 'yyyy-MM-dd'),
                }
            } else if (
                (input.currentWork === false || input.currentWork === null) &&
                !input.endDate
            ) {
                return {
                    ...formatInput,
                    endDate: null,
                }
            } else {
                return formatInput
            }
        })

        const inputToSave = userInputs.filter(
            singleInput => singleInput.updateId === null,
        )

        if (inputToSave?.length > 0) {
            const checkErrors = []

            inputToSave.forEach(input => {
                WorkExperienceValidationSchema.validate(input, {
                    abortEarly: false,
                })
                    .then(() => {
                        setBulkProcessing(true)
                        let formattedInputs = []
                        inputs.forEach(input => {
                            const userInput = {
                                name: input.companyname,

                                start_date: format(
                                    new Date(input.startDate),
                                    'yyyy-MM-dd',
                                ),
                                current_role:
                                    input.currentWork === null ||
                                    input.currentWork === false
                                        ? false
                                        : true,
                                role: input.role,
                                technologies: input.tech,
                                description: input.description,
                                updateId: input.updateId,
                            }
                            const finalFormat = input?.endDate
                                ? {
                                      ...userInput,

                                      end_date: format(
                                          new Date(input.endDate),
                                          'yyyy-MM-dd',
                                      ),
                                  }
                                : userInput

                            formattedInputs.push(finalFormat)
                        })

                        setTimeout(() => {
                            setSelectedExperience(formattedInputs)
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                eventTrackers['onboardingWorkExperience']
                                    .action,
                                eventTrackers['onboardingWorkExperience'].label,
                                eventTrackers['onboardingWorkExperience']
                                    .category,
                                ['MP', 'GA'],
                                'event',
                                {
                                    event: {
                                        selected_experience: formattedInputs,
                                    },
                                },
                            )
                            setTimeout(() => {
                                setTimeout(() => bulkSaveRefetch(), 1500)
                            }, 1500)
                        }, 1500)
                    })
                    .catch(err => {
                        let errList = {}
                        if (!input.endDate) {
                            errList.endDate = 'Exit date is required'
                        }
                        err.inner.forEach(e => {
                            errList = {
                                ...errList,
                                [e.path]: e.message,
                                formId: input.formId,
                            }
                        })

                        setErrors([{ ...errList }])
                        checkErrors.push({ ...errList })
                    })
            })

            if (checkErrors?.length > 0) {
                setErrors(checkErrors)
            }
        } else {
            nextHandler()
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['onboardingWorkExperience'].action,
                eventTrackers['onboardingWorkExperience'].label,
                eventTrackers['onboardingWorkExperience'].category,
                ['MP', 'GA'],
                'event',
                {
                    event: selectedExperience,
                },
            )
        }
    }

    useEffect(() => {
        if (data) {
            handleCloseForm(data?.data?.data?.id)
        }
        if (updateData) {
            handleCloseForm(updateData.data?.data?.id)
        }

        // eslint-disable-next-line
    }, [data, updateData])

    useEffect(() => {
        getAllExperienceRefetch()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (getAllExperienceData?.data?.data?.length > 0) {
            setTotalForm(getAllExperienceData?.data?.data?.length)

            const newArray = []
            getAllExperienceData?.data?.data?.forEach(data => {
                newArray.push(data?.id)
            })

            setOpenForm([newArray[0]])

            const closedForms = newArray.filter(formId => {
                return formId !== newArray[0]
            })

            updateCloseFormList([...closedForms])

            const newState = []

            getAllExperienceData?.data?.data?.forEach(data => {
                newState.push({
                    formId: data?.id,
                    companyname: data?.name,
                    role: data?.role,
                    description: data?.description,
                    tech: data?.technologies,
                    startDate: data?.start_date,
                    endDate: data?.end_date,
                    currentWork: data?.current_role,
                    updateId: data?.id,
                    saved: true,
                })
            })

            setInputs(newState)
        }
    }, [getAllExperienceData])

    const removeFormUpdate = () => {
        setErrorType('')
        setDeleteProcess(true)
        deleteRefetch()
    }

    const updateModalState = formId => {
        setDeleteFormId(formId)
        setDeleteModal(!deleteModal)
    }

    const removeUnSavedFormUpdate = () => {
        setErrorType('')
        updateUnSavedModalState()
        removeForm(deleteFormId)
        setDeleteFormType('save')
    }

    const updateUnSavedModalState = formId => {
        setDeleteFormType('unsave')
        setDeleteFormId(formId)
        setDeleteModal(!deleteModal)
    }

    useEffect(() => {
        if (deleteData) {
            removeForm(deleteFormId)
            setDeleteModal(false)
        }
        // eslint-disable-next-line
    }, [deleteData])

    useEffect(() => {
        if (deleteError) {
            setErrorType('delete')
        }
        if (updateError) {
            setErrorType('update')
        }
        if (getAllExperienceError) {
            setErrorType('getAll')
        }
        if (error) {
            setErrorType('save')
        }
        // eslint-disable-next-line
    }, [deleteError, updateError, getAllExperienceError, error])

    useEffect(() => {
        getAllTechRefetch()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (getAllTechData) {
            let techList = []
            getAllTechData?.data?.data?.forEach(tech => {
                techList.push({ value: tech?.name, label: tech?.name })
            })
            setTechUsedData(techList)
        }
    }, [getAllTechData])

    const { refetch: uploadResumeFetch } = useQuery(
        'uploadResume',
        () => resumeUploadApi(uploadFile),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setUploadUrl(data?.data?.data?.resume_url)
                setTimeout(() => {
                    startResumeParsing()
                }, 2000)
            },
            onError: () => {
                setParseLoading(false)
                setErrorType('parseError')
            },
        },
    )

    const { refetch: parseResumeFetch } = useQuery(
        'parseResume',
        () => parseResumeApi(uploadUrl),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setParseLoading(false)
                setParsedData(data?.experience)
            },
            onError: () => {
                setParseLoading(false)
                setErrorType('parseError')
            },
        },
    )

    const { refetch: bulkDeleteResumeFetch } = useQuery(
        'bulkResumeDelete',
        () => bulkDeleteExperienceApi(itemsForDeletion),
        {
            refetchOnWindowFocus: false,
            enabled: false,
            retry: false,
            onSuccess: data => {
                if (data && uploadFile) {
                    setDeleteProcessing(false)
                    setUploadFile(uploadFile)
                    setParseLoading(true)
                }
            },
            onError: () => {
                setParseLoading(false)
                setDeleteProcessing(false)
                setErrorType('parseError')
            },
        },
    )

    const startResumeParsing = () => {
        parseResumeFetch()
    }

    // const parseResumeHandler = event => {
    //   setErrorType('')
    //   setParsedData([])

    //   event.preventDefault()

    //   const file = event.target.files

    //   setUploadFile(file[0])
    // }

    const promptActionHandler = () => {
        setActionPrompt(false)
        setDeleteProcessing(true)
        const getAllCurrentExperience = []
        inputs.forEach(input => {
            if (input?.saved === true) {
                getAllCurrentExperience.push(input.formId)
            }
        })
        setItemsForDeletion(getAllCurrentExperience)
        setTimeout(() => {
            bulkDeleteResumeFetch()
        }, 2000)
    }

    const closePromptModalState = () => {
        setActionPrompt(false)
    }

    useEffect(() => {
        if (uploadFile) {
            setActionPrompt(true)
        }
    }, [uploadFile])

    useEffect(() => {
        if (parseLoading) {
            uploadResumeFetch()
        }

        // eslint-disable-next-line
    }, [parseLoading])

    useEffect(() => {
        if (parsedData?.length > 0) {
            const workExperiences = parsedData.map(data => {
                const formId = Math.random()
                return {
                    formId,
                    companyname: data?.organization ? data?.organization : '',
                    role: data?.title ? data?.title : '',
                    description: '',
                    tech: [],
                    startDate: data?.dates
                        ? data?.dates[0]
                            ? format(new Date(data?.dates[0]), 'yyyy-MM-dd')
                            : format(new Date(), 'yyyy-MM-dd')
                        : format(new Date(), 'yyyy-MM-dd'),
                    endDate: data?.dates
                        ? data?.dates[1]
                            ? format(new Date(data?.dates[1]), 'yyyy-MM-dd')
                            : !data?.dates[0]
                            ? format(new Date(), 'yyyy-MM-dd')
                            : null
                        : format(new Date(), 'yyyy-MM-dd'),
                    currentWork: data?.dates
                        ? data?.dates[1]
                            ? false
                            : true
                        : false,
                    updateId: null,
                    saved: false,
                }
            })
            setTotalForm(workExperiences?.length)
            setInputs(workExperiences)
        }
    }, [parsedData])

    const findFormErrors = formId => {
        if (errors?.length > 0) {
            return errors.filter(error => error?.formId === formId)
        } else {
            return {}
        }
    }

    return (
        <>
            <ActionModal
                actionModalOpen={actionPrompt}
                headerText="Upload Resume"
                subTitle="If you continue, your previous work experiences would be lost and you’ll have to refill"
                closeModal={closePromptModalState}
                actionHandler={() => {
                    promptActionHandler()
                }}
                actionType="warning"
                actionText="Continue"
            />
            <ActionModal
                actionModalOpen={deleteModal}
                headerText="Delete Experience"
                subTitle="If you delete this work experience, you’ll have to refill the data"
                closeModal={updateModalState}
                actionHandler={() =>
                    deleteFormType === 'unsave'
                        ? removeUnSavedFormUpdate()
                        : removeFormUpdate()
                }
                actionType="delete"
                actionText="Delete"
                loading={deleteLoading || deleteProcess}
            />
            <div className={FormBox}>
                <FormSubBox>
                    <FormTitleBar
                        title="Work Experience"
                        subtitle="Fill your related work experiences below"
                    />
                    {/* <div className={TopActionBox}>
            <label className={ResumeBox}>
              {parseLoading || deleteProcessing ? (
                <InlineLoader />
              ) : (
                <>
                  <ResumeIcon />
                  <span>
                    Fill with CV/Resume
                    <input
                      type='file'
                      accept='.pdf,.docx,.doc'
                      onChange={e => {
                        parseResumeHandler(e)
                      }}
                      value={''}
                      onClick={() => {
                        setUploadFile(null)
                      }}
                    />
                  </span>
                </>
              )}
            </label>
          </div> */}
                    {errorType === 'parseError' && (
                        <Toast
                            messageType="error"
                            message={`Parsing your CV/Resume failed. Try Again`}
                        />
                    )}
                    {errorType === 'getAll' && (
                        <Toast
                            messageType="error"
                            message={`Failed to get your work experiences. Refresh page`}
                        />
                    )}
                    {errorType === 'delete' && (
                        <Toast
                            messageType="error"
                            message={`Failed to delete your work experience. Try again`}
                        />
                    )}
                    {errorType === 'save' ||
                        (errorType === 'update' && (
                            <Toast
                                messageType="error"
                                message={`Failed to ${
                                    errorType ? 'save' : 'update'
                                } your work experience. Try again`}
                            />
                        ))}
                    {inputs.map((input, i) => {
                        return (
                            <DataForm
                                key={Math.random()}
                                formId={input.formId}
                                formPosition={i + 1}
                                formName={`form-${i + 1}`}
                                closeFormHandler={formId =>
                                    handleCloseForm(formId)
                                }
                                checkCloseFormState={checkCloseFormState(
                                    input.formId,
                                )}
                                updateTotalForm={() => {
                                    setTotalForm(totalForm + 1)
                                }}
                                handleInputChange={handleInputChange}
                                formInputs={getFormInputs(input.formId)}
                                editFormHandler={formId =>
                                    editFormState(formId)
                                }
                                removeForm={formId => removeForm(formId)}
                                totalForm={inputs?.length}
                                errorMessages={findFormErrors(input.formId)[0]}
                                handleSaveExperience={handleSaveExperience}
                                updateLoading={updateLoading}
                                loading={isLoading}
                                deleteModalHandler={formId =>
                                    updateModalState(formId)
                                }
                                unSaveDeleteHandler={formId =>
                                    updateUnSavedModalState(formId)
                                }
                                techUsedData={techUsedData}
                                handleInputUpdate={(formId, formDetail) =>
                                    handleInputUpdate(formId, formDetail)
                                }
                            />
                        )
                    })}
                    {openForm?.length < 1 && (
                        <div className={AddNewButton}>
                            <Button
                                btnBgColor="var(--purple-light)"
                                btnTextColor="var(--blue)"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                btnWidth="127px"
                                handleClick={updateFormTotal}
                            >
                                <PlusOutlined
                                    style={{ fontSize: 16 }}
                                    fill="var(--blue)"
                                />{' '}
                                Add New
                            </Button>
                        </div>
                    )}
                </FormSubBox>
            </div>

            <ActionSection
                stage={2}
                prevHandler={prevHandler}
                nextHandler={handleBulkSaveExperience}
                loading={bulkProcessing || bulkSaveLoading}
            />
        </>
    )
}

export default WorkExperience
