import PropTypes from 'prop-types'
import styles from '../pageform.module.scss'
import { InputFields, SelectField } from 'src/components/InputFields'
import Editor from 'src/components/Editor'
import EnergyNeeds from 'src/components/EnergyNeeds'
import { profileTypeData } from '../../../data'
import { useGetClusterVariables } from '../../../hooks/useGetClusterVariable'

const StepOne = ({
    errors,
    profileData,
    handleSetAppliances,
    handleInputChange,
    handleSelectChange,
    handleEditorChange,
    availableCapacities,
    charactersLeft,
}) => {
    const {
        l2OCreditVariablesFetching,
        l2OCreditVariableData,
        subCreditVariablesFetching,
        subCreditVariableData,
        formatClusterData,
    } = useGetClusterVariables()

    return (
        <div className={styles.Container}>
            <section className={styles.FormSection}>
                <div className={styles.FormSectionContentCol}>
                    <InputFields
                        title="Consumer Profile Name"
                        inputWidth="100%"
                        name="name"
                        value={profileData.name}
                        marginBottom="0px"
                        handleChange={handleInputChange}
                        errorMessage={errors.name}
                    />
                    <Editor
                        name="description"
                        placeholder="Consumer Profile Description"
                        editorWidth="100%"
                        editorHeight="140px"
                        marginBottom="0px"
                        bulletOnly
                        hideErrorElementIfNoError
                        value={profileData.description}
                        onChangeHandler={handleEditorChange}
                        errorMessage={errors.description}
                        showCharacterLimit
                        charactersLeft={charactersLeft}
                    />
                    <SelectField
                        selectWidth="100%"
                        marginBottom="0px"
                        marginTop="0px"
                        initialOption="Consumer Profile Type"
                        optionStyleNoCheckbox={{ color: '#233862' }}
                        name="customer_type"
                        contentField="name"
                        dropdownPositionRelative
                        withCheckBox
                        floatingLabel={
                            profileData.customer_type && 'Consumer Profile Type'
                        }
                        currentSelected={profileData.customer_type}
                        value={profileData.customer_type}
                        values={profileTypeData}
                        handleChange={handleSelectChange}
                        errorMessage={errors.customer_type}
                    />
                </div>
            </section>
            <section className={styles.FormSection}>
                <h4>Enter the minimum and max sizes of systems</h4>
                <div className={styles.FormSectionContentRow}>
                    <SelectField
                        selectWidth="100%"
                        initialOption="Min. system size"
                        floatingLabel={
                            profileData.min_capacity && 'Min. system size'
                        }
                        optionStyleNoCheckbox={{ color: '#233862' }}
                        name="min_capacity"
                        contentField="name"
                        dropdownPositionRelative
                        withCheckBox
                        currentSelected={profileData.min_capacity}
                        value={profileData.min_capacity}
                        values={availableCapacities}
                        handleChange={handleSelectChange}
                        errorMessage={errors.min_capacity}
                    />
                    <SelectField
                        selectWidth="100%"
                        initialOption="Max. system size"
                        floatingLabel={
                            profileData.max_capacity && 'Max. system size'
                        }
                        optionStyleNoCheckbox={{ color: '#233862' }}
                        name="max_capacity"
                        contentField="name"
                        dropdownPositionRelative
                        withCheckBox
                        currentSelected={profileData.max_capacity}
                        value={profileData.max_capacity}
                        values={availableCapacities}
                        handleChange={handleSelectChange}
                        errorMessage={errors.max_capacity}
                    />
                </div>
            </section>
            <section className={styles.FormSection}>
                <h4>Apply cluster variable to this consumer profile</h4>
                <div className={styles.FormSectionContentRow}>
                    <div>
                        <SelectField
                            selectWidth="100%"
                            initialOption="Lease to own"
                            floatingLabel={
                                profileData.lease_to_own_cluster_code &&
                                'Lease to own'
                            }
                            optionStyleNoCheckbox={{ color: '#233862' }}
                            name="lease_to_own_cluster_code"
                            contentField="name"
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={
                                profileData.lease_to_own_cluster_code
                            }
                            value={profileData.lease_to_own_cluster_code}
                            values={formatClusterData(
                                l2OCreditVariableData?.data?.data,
                            )}
                            handleChange={handleSelectChange}
                            showSearch
                            searchValue
                            disabled={l2OCreditVariablesFetching}
                        />
                        <p className={styles.OptionalLabel}>Optional</p>
                    </div>
                    <div>
                        <SelectField
                            selectWidth="100%"
                            initialOption="Subscription"
                            floatingLabel={
                                profileData.subscription_cluster_code &&
                                'Subscription'
                            }
                            optionStyleNoCheckbox={{ color: '#233862' }}
                            name="subscription_cluster_code"
                            contentField="name"
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={
                                profileData.subscription_cluster_code
                            }
                            value={profileData.subscription_cluster_code}
                            values={formatClusterData(
                                subCreditVariableData?.data?.data,
                            )}
                            handleChange={handleSelectChange}
                            showSearch
                            searchValue
                            disabled={subCreditVariablesFetching}
                        />
                        <p className={styles.OptionalLabel}>Optional</p>
                    </div>
                </div>
            </section>
            <section className={styles.FormSection}>
                <h4>Add appliances for this consumer profile</h4>
                <div className={styles.FormSectionContentCol}>
                    <EnergyNeeds
                        section="consumer Profile"
                        actionText="Add"
                        setEnergyNeeds={handleSetAppliances}
                        energyNeeds={profileData.appliances}
                    />
                    {errors.appliances && (
                        <div className="ErrorText">
                            {errors.appliances || ''}
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}

StepOne.propTypes = {
    errors: PropTypes.object.isRequired,
    profileData: PropTypes.object.isRequired,
    handleSetAppliances: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleEditorChange: PropTypes.func.isRequired,
    availableCapacities: PropTypes.any.isRequired,
    charactersLeft: PropTypes.any.isRequired,
}

export default StepOne
