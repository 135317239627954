import axios from '../../config/axios'
export const initiateBVNLookupApi = payload => {
    const request = axios.post(
        '/identity-verification/bvn/initiate-lookup',
        payload,
    )
    return request
}

export const verifyBVNLookupApi = payload => {
    const request = axios.post(
        '/identity-verification/bvn/verify-lookup',
        payload,
    )
    return request
}

export const fetchBVNDetailsApi = payload => {
    const request = axios.post(
        '/identity-verification/bvn/fetch-details',
        payload,
    )
    return request
}

export const persistBusinessDocsApi = payload => {
    const request = axios.post(
        '/consumers/kyc/business/persist-business-documents',
        payload,
    )
    return request
}

export const verifyBVNApi = payload => {
    const request = axios.post(
        '/identity-verification/bvn/verify',
        payload,
    )
    return request
}