import { useFormik } from "formik"

export const useCustomFormik = ({ initialValues, validationSchema, ...options }) => {
  return useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    ...options
  })
}