import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import styles from './buildsystemmodal.module.scss'

import { useHistory } from 'react-router-dom'

const BuildSystemModal = ({ showModal, toggleModal, setShowModal }) => {
    const history = useHistory()
    const handleBuildSystemNav = () => {
        setShowModal(false)
        history.push({
            pathname: '/consumer/get-started',
            state: {
                buildCustomSystem: true,
            },
        })
    }

    return (
        <SecondaryModal
            v2
            showModal={showModal}
            title="Build a customised system instead"
            modalWidth={476}
            showFooter
            footerLeftContent="button"
            secondaryBtnText="Go back"
            primaryBtnText="Build your system"
            content={<ModalContent />}
            onCancel={toggleModal}
            handleSecondaryBtnClick={toggleModal}
            handlePrimaryBtnClick={handleBuildSystemNav}
        />
    )
}

BuildSystemModal.propTypes = {
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func,
    setShowModal: PropTypes.func,
}

export default BuildSystemModal

const ModalContent = () => {
    return (
        <div className={styles.ModalContentWrapper}>
            <img
                className={styles.CardImage}
                src={
                    'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/distributor-installer.svg'
                }
                alt="consumer-profile-card-image"
                role={'presentation'}
            />
            <h3>Can&apos;t find what works for you?</h3>
            <p>
                Customise your own system by providing your budget, location,
                what you want to power, and our system will generate
                recommendations based on what you have provided
            </p>
        </div>
    )
}
