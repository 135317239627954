//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import PropTypes from 'prop-types'
import './usertable.scss'
import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button, { AppButton } from 'src/components/Button'
import ActionModal from 'src/components/ActionModal'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import EditUserDetails from '../EditUserDetails'
import { useQuery } from 'react-query'
import {
    resendProviderUserInviteApi,
    revokeProviderUserInviteApi,
    updateProviderUserDetailsApi,
} from 'src/api/admin/provider'
import { errorHandler } from 'src/utils/errorHandler'
import { shortenEmail } from 'src/utils/shortenEmail'
import { removeCountryCode } from 'src/utils/NumberFormatter'
import { denyPermission } from 'src/utils/permissionFramework'
import { TableSkeletalCards } from '../../../../../components/SkeletalCards'
import UserCards from './UserCards'
import {
    canInviteProviderUser,
    canUpdateProviderUserDetails,
    canViewProviderUserDetails,
    userTableActionModalMappings,
    userTableSuccessActionModalMappings,
} from '../data'
import { applyDynamicPermissionsToAdminUser } from 'src/utils/permissionsControl'

const ProviderUserTable = ({ data, setTriggerRefetch, groups, isFetching }) => {
    const [openModal, setOpenModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [action, setAction] = useState('')
    const [successText, setSuccessText] = useState('')
    const [editModal, setEditModal] = useState(false)
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        role: '',
        groups: [],
    })
    const [savedUserData, setSavedUserData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        role: '',
        groups: [],
    })
    const [newData, setNewData] = useState({})
    const [userEmail, setUserEmail] = useState(null)
    const [userId, setUserId] = useState(null)
    const [pendingUserId, setPendingUserId] = useState(null)
    const [errors, setErrors] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const history = useHistory()
    const params = useParams()
    const { actionType, headerText, subTitle, actionText } =
        userTableActionModalMappings[action] ||
        userTableActionModalMappings.default
    const { successSubTitle } =
        userTableSuccessActionModalMappings[action] ||
        userTableSuccessActionModalMappings.default

    const reset = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        role: '',
        groups: [],
    }
    const { refetch: resendInviteRefetch, isLoading: resendInviteLoading } =
        useQuery(
            ['resend-provider-user-invite'],
            () => resendProviderUserInviteApi(userEmail),
            {
                enabled: false,
                retry: false,
                onSuccess: () => {
                    setSuccessText('Invitation Successfully Sent')
                    setOpenModal(false)
                    setSuccessModal(true)
                    setTriggerRefetch(true)
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                    const timer = setTimeout(() => setToastError(false), [4000])
                    return () => clearTimeout(timer)
                },
            },
        )

    const { refetch: revokeInviteRefetch, isLoading: revokeInviteLoading } =
        useQuery(
            ['revoke-provider-user-invite'],
            () => revokeProviderUserInviteApi(pendingUserId),
            {
                enabled: false,
                retry: false,
                onSuccess: () => {
                    setSuccessText('Invitation Revoked Successfully')
                    setOpenModal(false)
                    setSuccessModal(true)
                    setTriggerRefetch(true)
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                    const timer = setTimeout(() => setToastError(false), [4000])
                    return () => clearTimeout(timer)
                },
            },
        )

    //edit user details
    const { refetch: editUserRefetch, isLoading: editUserLoading } = useQuery(
        ['edit-provider-user-details'],
        () => updateProviderUserDetailsApi(userId, newData),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setSuccessText('User Details Updated Successfully')
                setEditModal(false)
                setSuccessModal(true)
                setTriggerRefetch(true)
                setInputs(reset)
                setSavedUserData(reset)
                setErrors('')
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const addGroup = () => {
        return inputs?.groups?.filter(object => {
            return !savedUserData?.groups?.some(oldObject => {
                return object?.id === oldObject?.id
            })
        })
    }

    const removeGroup = () => {
        return savedUserData?.groups?.filter(object => {
            return !inputs?.groups?.some(oldObject => {
                return object?.id === oldObject?.id
            })
        })
    }

    useEffect(() => {
        if (inputs?.firstName !== savedUserData?.firstName) {
            setNewData(prev => ({
                ...prev,
                first_name: inputs?.firstName,
            }))
        }

        if (inputs?.lastName !== savedUserData?.lastName) {
            setNewData(prev => ({
                ...prev,
                last_name: inputs?.lastName,
            }))
        }

        if (inputs?.phoneNumber !== savedUserData?.phoneNumber) {
            setNewData(prev => ({
                ...prev,
                phone_number: inputs?.phoneNumber,
            }))
        }

        if (inputs?.email !== savedUserData?.email) {
            setNewData(prev => ({
                ...prev,
                email: inputs?.email,
            }))
        }

        if (inputs?.role !== savedUserData?.role) {
            setNewData(prev => ({
                ...prev,
                role_name: inputs?.role,
            }))
        }

        setNewData(prev => ({
            ...prev,
            add_role_ids: addGroup().map(each => each?.id),
        }))

        setNewData(prev => ({
            ...prev,
            remove_role_ids: removeGroup().map(each => each?.id),
        }))
    }, [inputs])

    const handleButtonOne = (status, email, id) => {
        if (status === 'PENDING') {
            setUserEmail(email)
            setAction('resend-invite')
            setOpenModal(true)
        }

        if (status === 'ACTIVE') {
            history.push(`/admin/providers/user/${id}`, {
                providerId: params?.id,
            })
        }
    }

    const handleButtonTwo = (status, user, userId) => {
        if (status === 'PENDING') {
            setPendingUserId(user?.id)
            setAction('revoke-invite')
            setOpenModal(true)
        }
        if (status === 'ACTIVE') {
            setUserId(userId)
            setAction('edit-user-details')
            setInputs({
                firstName: user?.user?.first_name,
                lastName: user?.user?.last_name,
                phoneNumber: removeCountryCode(user?.user?.phone_number),
                email: user?.user?.email,
                role: user?.user?.role,
                groups: user?.user?.roles.map(item => ({
                    label: item?.name,
                    value: item?.name,
                    id: item?.id,
                })),
            })
            for (let i = 0; i < data?.length; i++) {
                if (userId === data?.[i]?.user?.id) {
                    setSavedUserData({
                        firstName: data?.[i]?.user?.first_name,
                        lastName: data?.[i]?.user?.last_name,
                        phoneNumber: removeCountryCode(
                            data?.[i]?.user?.phone_number,
                        ),
                        email: data?.[i]?.user?.email,
                        role: data?.[i]?.user?.role,
                        groups: data?.[i]?.user?.roles.map(item => ({
                            label: item?.name,
                            value: item?.name,
                            id: item?.id,
                        })),
                    })
                }
            }
            setEditModal(true)
        }
    }

    const handleModal = () => {
        if (action === 'resend-invite') {
            resendInviteRefetch()
        }
        if (action === 'revoke-invite') {
            revokeInviteRefetch()
        }
    }

    const handleEditModal = () => {
        setUserId(null)
        setEditModal(false)
        setInputs(reset)
        setSavedUserData(reset)
        setNewData({})
        setErrors('')
    }

    const handleSubmitCallback = () => {
        editUserRefetch()
    }

    const loadingState = resendInviteLoading || revokeInviteLoading

    const buttonOneDisabled = each => {
        const actionIsResendInvite = !each?.user?.email_verified
        const actionIsViewUser = each?.user?.email_verified
        let isDisabled = false

        if (actionIsResendInvite) {
            isDisabled = applyDynamicPermissionsToAdminUser
                ? !canInviteProviderUser
                : denyPermission(
                      'admin',
                      'approved_providers',
                      'providers:can_resend',
                  )
        } else if (actionIsViewUser) {
            isDisabled = applyDynamicPermissionsToAdminUser
                ? !canViewProviderUserDetails
                : false
        }
        return isDisabled
    }

    const buttonTwoDisabled = each => {
        const actionIsRevokeInvite = !each?.user?.email_verified
        const actionIsEditUser = each?.user?.email_verified
        let isDisabled = false

        if (actionIsRevokeInvite) {
            isDisabled = applyDynamicPermissionsToAdminUser
                ? !canInviteProviderUser
                : denyPermission(
                      'admin',
                      'approved_providers',
                      'providers:can_revoke',
                  )
        } else if (actionIsEditUser) {
            isDisabled = applyDynamicPermissionsToAdminUser
                ? !canUpdateProviderUserDetails
                : denyPermission(
                      'admin',
                      'approved_providers',
                      'providers:can_edit',
                  )
        }
        return isDisabled
    }

    return (
        <>
            {/*edit user details modal*/}
            <SecondaryModal
                showModal={editModal}
                modalWidth="624px"
                modalHeight={'fit-content'}
                noPadding="48px 80px"
                modalClass="RecommendedEnergy"
                onCancel={() => handleEditModal()}
                content={
                    <EditUserDetails
                        inputs={inputs}
                        setInputs={setInputs}
                        handleSubmitCallback={handleSubmitCallback}
                        errors={errors}
                        setErrors={setErrors}
                        toastError={toastError}
                        errorMessage={errorMessage}
                        groups={groups}
                        loading={editUserLoading}
                    />
                }
            />
            {/*resend invitation*/}
            <ActionModal
                actionModalOpen={openModal}
                actionType={actionType}
                headerText={headerText}
                subTitle={subTitle}
                actionText={actionText}
                closable
                actionHandler={() => handleModal()}
                onCancel={() => setOpenModal(false)}
                closeModal={() => setOpenModal(false)}
                noBackLink
                loading={loadingState}
                toastError={toastError}
                errorMessage={errorMessage}
            />

            {/*success for all actions*/}
            <ActionModal
                actionModalOpen={successModal}
                actionType="success"
                headerText={successText}
                subTitle={successSubTitle}
                actionText="Okay, Got it"
                closable
                onCancel={() => setSuccessModal(false)}
                actionHandler={() => setSuccessModal(false)}
                noBackLink
                noCancelBtn
            />

            {isFetching ? (
                <TableSkeletalCards total={2} />
            ) : (
                <div className="AdminProviderUserTableDesktop">
                    <div className="AdminProviderUserTableMainHead">
                        <p>Name</p>
                        <p>Role</p>
                    </div>
                    <div className="AdminProviderUserTable">
                        {data.map((each, i) => (
                            <div key={i} className="AdminProviderUserTableItem">
                                <div className="AdminProviderUserTableUserDetails">
                                    <h4>
                                        {shortenEmail(each?.user?.display_name)}
                                    </h4>
                                    {/*pending or deactivated status flag*/}
                                    <div
                                        style={{
                                            backgroundColor: `${
                                                each?.user?.state ===
                                                    'ACTIVE' &&
                                                each?.user?.email_verified ===
                                                    false
                                                    ? '#FFF9E7'
                                                    : each?.user?.state ===
                                                          'ACTIVE' &&
                                                      each?.user?.user_types?.includes(
                                                          'DEACTIVATED_USER',
                                                      )
                                                    ? '#E5E5E580'
                                                    : 'none'
                                            }`,
                                        }}
                                    >
                                        <p
                                            style={{
                                                color: `${
                                                    each?.user?.state ===
                                                        'ACTIVE' &&
                                                    each?.user
                                                        ?.email_verified ===
                                                        false
                                                        ? '#FFC222'
                                                        : each?.user?.state ===
                                                              'ACTIVE' &&
                                                          each?.user?.user_types?.includes(
                                                              'DEACTIVATED_USER',
                                                          )
                                                        ? '#233862E0'
                                                        : ''
                                                }`,
                                                display: `${
                                                    (each?.user?.state ===
                                                        'ACTIVE' &&
                                                        each?.user
                                                            ?.email_verified ===
                                                            false) ||
                                                    (each?.user?.state ===
                                                        'ACTIVE' &&
                                                        each?.user?.user_types?.includes(
                                                            'DEACTIVATED_USER',
                                                        ))
                                                        ? 'block'
                                                        : 'none'
                                                }`,
                                            }}
                                        >
                                            {each?.user?.state === 'ACTIVE' &&
                                            each?.user?.email_verified === false
                                                ? 'PENDING'
                                                : each?.user?.state ===
                                                      'ACTIVE' &&
                                                  each?.user?.user_types?.includes(
                                                      'DEACTIVATED_USER',
                                                  )
                                                ? 'DEACTIVATED'
                                                : ''}
                                        </p>
                                    </div>
                                    {/*    workspace owner status flag*/}
                                    {each?.owner === true && (
                                        <div
                                            style={{
                                                backgroundColor: '#E2EEFF',
                                            }}
                                        >
                                            <p style={{ color: '#004AAD' }}>
                                                OWNER
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <p>{each?.user?.role ?? 'Not Set'}</p>
                                <div className="AdminProviderUserTableButtons">
                                    <AppButton
                                        btnWidth="70px"
                                        btnBgColor="#E2EEFF"
                                        btnTextColor="var(--blue)"
                                        btnHeight="37px"
                                        fontSize="12px"
                                        fontFamily="SF-Pro-Display-Medium"
                                        handleClick={() =>
                                            handleButtonOne(
                                                each?.user?.state ===
                                                    'ACTIVE' &&
                                                    each?.user
                                                        ?.email_verified ===
                                                        false
                                                    ? 'PENDING'
                                                    : 'ACTIVE',
                                                each?.user?.email,
                                                each?.user?.id,
                                            )
                                        }
                                        disabled={buttonOneDisabled(each)}
                                        toolTipText={
                                            buttonOneDisabled(each)
                                                ? 'You are not authorised to perform this action'
                                                : null
                                        }
                                        toolTipPopupWidth={164}
                                        arrowPositionCenter
                                        toolTipMarginLeft={-52}
                                    >
                                        {each?.user?.state === 'ACTIVE' &&
                                        each?.user?.email_verified === false
                                            ? 'Resend'
                                            : 'View'}
                                    </AppButton>
                                    <Button
                                        type="MainButtonTwo"
                                        btnWidth="70px"
                                        btnBgColor="transparent"
                                        btnTextColor="var(--blue)"
                                        btnHeight="37px"
                                        fontSize="12px"
                                        handleClick={() =>
                                            handleButtonTwo(
                                                each?.user?.state ===
                                                    'ACTIVE' &&
                                                    each?.user
                                                        ?.email_verified ===
                                                        false
                                                    ? 'PENDING'
                                                    : 'ACTIVE',
                                                each,
                                                each?.user?.id,
                                            )
                                        }
                                        disabled={buttonTwoDisabled(each)}
                                        toolTipText={
                                            buttonTwoDisabled(each)
                                                ? 'You are not authorised to perform this action'
                                                : null
                                        }
                                        toolTipPopupWidth={140}
                                        arrowPositionCenter
                                        toolTipMarginLeft={-40}
                                    >
                                        {each?.user?.state === 'ACTIVE' &&
                                        each?.user?.email_verified === false
                                            ? 'Revoke'
                                            : 'Edit'}
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/*    mobile cards*/}
            {isFetching ? (
                <TableSkeletalCards />
            ) : (
                <UserCards
                    data={data}
                    handleButtonOne={handleButtonOne}
                    handleButtonTwo={handleButtonTwo}
                    buttonOneDisabled={buttonOneDisabled}
                    buttonTwoDisabled={buttonTwoDisabled}
                />
            )}
        </>
    )
}

ProviderUserTable.propTypes = {
    data: PropTypes.array,
    setTriggerRefetch: PropTypes.func,
    groups: PropTypes.array,
    isFetching: PropTypes.bool,
}

export default ProviderUserTable
