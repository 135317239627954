/* eslint-disable react/prop-types */
import styles from './productheader.module.scss'

export const ProductHeader = ({ title, subtitle }) => {
    const { Wrapper, Title, Subtitle } = styles

    return (
        <div className={Wrapper}>
            <p className={Title}>{title}</p>
            <h2 className={Subtitle}>{subtitle}</h2>
        </div>
    )
}
