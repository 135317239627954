/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from 'react'
import { NoFLoatingLabelInputFields } from '../InputFields'
import { searchImagesApi } from '../../api/products/component'
import { useQuery } from 'react-query'
import Button from '../Button'
import { Row, Col } from 'antd'
import useOnClickOutside from '../../utils/useOnclickOutside'
import Loader from '../Loader'

import './imagelisting.scss'

const ImageListing = ({
    onSelect,
    onClose,
    imageListingStyle,
    // eslint-disable-next-line no-unused-vars
    inputWidth,
    imgStyle,
    noButton,
    componentType,
    componentName,
    ImageListingBtns,
    height = 'auto',
}) => {
    const [imageFocus, setImageFocus] = useState(null)
    const [imageName, setImageName] = useState('')
    const [imageList, setImageList] = useState([])
    const [input, setInput] = useState('')
    const [image, setImage] = useState('')
    const [showButton, setShowButton] = useState(false)
    const handleInputChange = event => {
        setInput(event.target.value)
    }

    const handleBlur = e => {
        const { value } = e.target

        setInput(value.trim())
    }

    const filterImage = () => {
        refetch()
    }

    const { isLoading, refetch, isFetching } = useQuery(
        'imageSamples',
        () =>
            searchImagesApi(
                input ? input : componentName,
                input ? input : componentType,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => setImageList(data?.data.data),
        },
    )

    const selectForMobile = (imageName, image) => {
        onSelect(imageName, image)
    }

    useEffect(() => refetch(), [refetch])

    const ref = useRef()
    useOnClickOutside(ref, () => onClose())

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            refetch()
        }
    }
    return (
        <div
            className={
                imageListingStyle ? imageListingStyle : 'ImageListingWrapper'
            }
            ref={ref}
        >
            <NoFLoatingLabelInputFields
                value={input}
                inputHeight="50px"
                style={{ paddingBottom: 7 }}
                type="text"
                handleChange={handleInputChange}
                iconType="search"
                prefilled
                inputPlaceholder="Search Component Image"
                onIconClick={filterImage}
                showFloatingLabel
                onKeyUp={handleKeyPress}
                onBlur={handleBlur}
            />
            <Row
                gutter={[15, 10]}
                style={{ height, overflowY: 'scroll' }}
                className="DesktopImageSamples"
                data-testid="desktop-view"
            >
                {isFetching ? (
                    <div style={{ margin: '10px auto' }}>
                        <Loader text="Searching..." />
                    </div>
                ) : imageList?.length < 1 ? (
                    <div className="EmptyStateImageSearch">
                        <br />
                        <br />
                        No image found
                    </div>
                ) : (
                    imageList.map((img, id) => (
                        <Col key={id} span={8}>
                            <img
                                src={img.url}
                                alt={img.alt_text}
                                onClick={() => {
                                    setImageFocus(id)
                                    setImageName(img.alt_text)
                                    setImage(img.url)
                                    setShowButton(true)
                                }}
                                // onMouseLeave={() => setImageFocus(null)}
                                style={{
                                    border: `${
                                        imageFocus === id
                                            ? '2px solid var(--blue)'
                                            : 'none'
                                    }`,
                                    borderRadius: imageFocus === id && '10px',
                                    width: '145px',
                                    height: '123px',
                                }}
                                className={imgStyle ? imgStyle : 'SingleImage'}
                            />
                        </Col>
                    ))
                )}
            </Row>
            <div className="MobileImageSamples" data-testid="mobile-view">
                {isLoading ? (
                    <div className="SearchingStateImageBox">Suggesting...</div>
                ) : imageList?.length < 1 ? (
                    <div className="EmptyStateImageSearch">No image found</div>
                ) : (
                    imageList.map((img, id) => {
                        return (
                            <img
                                key={id}
                                src={img.url}
                                alt={img.alt_text}
                                onClick={() => {
                                    setImageFocus(id)
                                    setImageName(img.alt_text)
                                    setImage(img.url)
                                    selectForMobile(img.alt_text, img.url)
                                }}
                                style={{
                                    border: `${
                                        imageFocus === id
                                            ? '2px solid var(--blue)'
                                            : 'none'
                                    }`,
                                    borderRadius: imageFocus === id && '10px',
                                }}
                                className="SingleImage"
                                width="160px"
                                height="110px"
                            />
                        )
                    })
                )}
                <div className="MobileListingSelectButton">
                    {isLoading
                        ? ''
                        : imageList?.length > 0
                        ? showButton && (
                              <>
                                  {' '}
                                  <Button
                                      btnBgColor="var(--purple-light)"
                                      btnTextColor="var(--blue)"
                                      btnTextColorOutline="var(--blue)"
                                      btnOutlineColor="var(--purple-light)"
                                      btnBgColorOutline="#E2EEFF"
                                      type="outline"
                                      btnWidth="100px"
                                      btnHeight="47px"
                                      handleClick={onClose}
                                  >
                                      Cancel
                                  </Button>
                                  &nbsp; &nbsp; &nbsp;
                                  <Button
                                      btnBgColor="var(--blue)"
                                      btnTextColor="var(--white)"
                                      btnWidth="100px"
                                      btnHeight="47px"
                                      handleClick={() => {
                                          if (imageName) {
                                              onSelect(imageName, image)
                                              onClose()
                                          }
                                      }}
                                  >
                                      Pick Image
                                  </Button>
                              </>
                          )
                        : ''}
                </div>
            </div>

            {noButton ? (
                ''
            ) : (
                <div
                    className={
                        ImageListingBtns ? ImageListingBtns : 'ImageListingBtns'
                    }
                    data-testid="image-listing-btns-container"
                >
                    {isLoading
                        ? ''
                        : imageList?.length > 0
                        ? showButton && (
                              <>
                                  {' '}
                                  <Button
                                      btnBgColor="var(--purple-light)"
                                      btnTextColor="var(--blue)"
                                      btnTextColorOutline="var(--blue)"
                                      btnOutlineColor="var(--purple-light)"
                                      btnBgColorOutline="#E2EEFF"
                                      type="outline"
                                      btnWidth="100px"
                                      btnHeight="47px"
                                      handleClick={() => {
                                          onClose()
                                          setInput('')
                                      }}
                                  >
                                      Cancel
                                  </Button>
                                  &nbsp; &nbsp; &nbsp;
                                  <Button
                                      btnBgColor="var(--blue)"
                                      btnTextColor="var(--white)"
                                      btnWidth="100px"
                                      btnHeight="47px"
                                      handleClick={() => {
                                          if (imageName) {
                                              onSelect(imageName, image)
                                              onClose()
                                          }
                                      }}
                                  >
                                      Pick Image
                                  </Button>
                              </>
                          )
                        : ''}
                </div>
            )}
        </div>
    )
}

export default ImageListing
