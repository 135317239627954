import axios from 'src/config/axios'

export const getPromoPackageDetailsApi = async (packageId, clusterCode) => {
    const request = await axios.get(
        `/sse-packages/${packageId}/clusters/${clusterCode}`,
    )
    return request
}

export const selectNewSystemPromoAuthUser = async (estimation_id, cluster_code, package_id) => {
    const request = await axios.post(
        `/consumers/estimations/${estimation_id}/recommended-solution/change`,
        {
            cluster_code,
            package_id
        },
    )
    return request
}
