import { createSlice } from '@reduxjs/toolkit'
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'

const initialState = {
    systemsViewedCount: 0,
}

const consumerBuildCustomSystemSlice = createSlice({
    name: 'consumerBuildCustomSystem',
    initialState,
    reducers: {
        incrementCount(state) {
            state.systemsViewedCount += 1
        },
    },
})

const persistConfig = {
    key: 'consumerBuildCustomSystem',
    storage: storageSession,
}

export const { incrementCount } = consumerBuildCustomSystemSlice.actions
const consumerBuildCustomSystemReducer = consumerBuildCustomSystemSlice.reducer
export const persistedBuildCustomSystemReducer = persistReducer(
    persistConfig,
    consumerBuildCustomSystemReducer,
)
export default consumerBuildCustomSystemReducer
