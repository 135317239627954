/* eslint-disable react/prop-types */
import { Row, Col, Typography } from 'antd'
import styles from './sectionheader.module.scss'
const { Title } = Typography
const {
    SectionHeaderWrapper,
    HeaderTitle,
    HeaderText,
    SectionCol,
    Icon,
    MobileIcon,
    HeadingStyle,
    SectionHeaderWrapper2,
} = styles

const SectionHeader = ({
    title,
    type,
    text,
    icon,
    mobileIcon,
    titleWidth,
    textColor,
    iconPosition,
    heading,
}) => {
    return (
        <Row
            gutter={30}
            className={heading ? SectionHeaderWrapper2 : SectionHeaderWrapper}
        >
            {iconPosition === 'left' ? <Col className={Icon}>{icon}</Col> : ''}
            <p className={MobileIcon}>{mobileIcon}</p>

            <Col
                className={SectionCol}
                style={{
                    marginLeft: type === 'faq' && '0px',
                    marginTop: type === 'faq' && '20px',
                }}
            >
                {/* {iconPosition === 'top' ? icon : ''} */}
                <p className={HeadingStyle}>{heading}</p>
                <Title
                    className={HeaderTitle}
                    style={{ width: titleWidth }}
                    level={2}
                >
                    {title}
                </Title>
                <p
                    className={HeaderText}
                    style={{
                        color: textColor || 'var(--lightblue)',
                        width: titleWidth,
                    }}
                >
                    {text}
                </p>
            </Col>
        </Row>
    )
}

export default SectionHeader
