/* eslint-disable no-undef */

import Navigation from 'src/components/Navigation'
import ConsumerFooter from 'src/components/ConsumerFooter'
import EducationalContents from 'src/components/EducationalContents'
import CtaSection from 'src/components/CtaSection'
import styles from './learn.module.scss'
import SeoComponent from 'src/components/Seo'
import CookieNotification from 'src/components/CookieNotificiation'
import Testimonial from '../Consumer/SmartShoppingExperience/Home/Testimonial/Testimonial'

const Learn = () => {
    const { HeaderText, testimonialWrapper } = styles

    return (
        <>
            <SeoComponent
                title="Learn | SunFi | Simplifying and Scaling Clean Energy"
                tracker="LearnTracker"
                keywords="SunFi, buy solar, affordable solar, Price of Solar, Electricity challenge, Solar installment, Solar education"
            />

            <Navigation
                bgColor="var(--white)"
                btnBgColor="var(--white"
                textColor="var(--black)"
                btnOutlineColor="var(--blue)"
                btnTextColorOutline="var(--blue)"
                page="learn"
                btnType="outline"
            />
            <CookieNotification />
            <p className={HeaderText}>Educational Content</p>
            <EducationalContents />
            <CtaSection title="Living Should Be Easier" />
            <div className={testimonialWrapper}>
                <Testimonial />
            </div>

            <ConsumerFooter />
        </>
    )
}

export default Learn
