import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { useHistory } from 'react-router-dom'

const CreditDecisionInProgressModal = ({
    displayName,
    showDecisionInProgressModal,
}) => {
    const history = useHistory()

    return showDecisionInProgressModal ? (
        <SecondaryModal
            v2
            showModal={true}
            showFooter
            modalWidth={476}
            onCancel={() => history.push('/')}
            title={'Your application is still in review'}
            primaryBtnText={'Go back to home page'}
            handlePrimaryBtnClick={() => history.push('/')}
            content={
                <div className={'creditInProgress'}>
                    <img
                        src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/learn-energy.svg"
                        alt="learn about clean energy image"
                    />
                    <h2>Hi {displayName}, a decision is yet to be made</h2>
                    <p>
                        Your details required an additional review by our
                        Finance team. <br /> No worries, we’ll be reaching out
                        via email once your application is approved.
                    </p>
                </div>
            }
        />
    ) : null
}

CreditDecisionInProgressModal.propTypes = {
    displayName: PropTypes.string,
    showDecisionInProgressModal: PropTypes.bool,
}
export default CreditDecisionInProgressModal
