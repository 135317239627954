/* eslint-disable react/prop-types */
import { useState } from 'react'
import FormTitleBar from '../../FormTitleBar'
import { LeftOutlined } from '@ant-design/icons'
import { InputFields, SelectField } from '../../InputFields'
import { floatWithCommas } from '../../../utils/formatting'
import Button from '../../Button'
import { isMobile } from 'src/utils/mediaQueries'

import styles from './addmargin.module.scss'

const AddMargin = ({
    packageName,
    subTotal,
    margin,
    handleMargin,
    handleClose,
}) => {
    const {
        AddMarginForm,
        AddMarginMobileBackLink,
        MobileTitleBar,
        InputRight,
        BtnWrapper,
        Wrapper,
    } = styles
    const [action, setAction] = useState('Add Margin')
    // const [inputs, setInputs] = useState({ percentage: '', amount: '' })

    const [errorMessage, setErrorMessage] = useState({
        percentage: '',
        amount: '',
        action: '',
    })

    const handleSelectChange = (name, value) => {
        setAction(value)
        margin.action = value
    }
    const handleInputChange = e => {
        e.preventDefault()
        e.persist()

        setErrorMessage({
            percentage: '',
            amount: '',
            action: '',
        })

        const { name, value } = e.target
        const eventType = e.nativeEvent.inputType

        //regex to to allow numbers and comma
        const numberRegex = /^[0-9.,]*$/

        const formattedValue = numberRegex.test(value)
            ? value
            : name === 'percentage'
            ? margin.percentage
            : margin.amount

        if (name === 'percentage') {
            if (
                (action === 'Subtract Margin' ||
                    margin.action === 'Subtract Margin') &&
                formattedValue > 99
            ) {
                setErrorMessage({ percentage: 'Must be less than 100%' })
                if (eventType === 'deleteContentBackward') {
                    const calculatedAmount = (formattedValue / 100) * subTotal
                    margin.percentage = formattedValue
                    margin.amount = calculatedAmount
                }
            } else {
                const calculatedAmount = (formattedValue / 100) * subTotal
                margin.percentage = formattedValue
                margin.amount = calculatedAmount
            }
        }

        if (name === 'amount') {
            const replaceComma = formattedValue.replaceAll(',', '')
            const calculatedPercent = (replaceComma / subTotal) * 100
            if (
                (action === 'Subtract Margin' ||
                    margin.action === 'Subtract Margin') &&
                calculatedPercent > 99
            ) {
                setErrorMessage({
                    amount: `Must be less than/equal to ${subTotal * 0.99}`,
                })

                if (eventType === 'deleteContentBackward') {
                    margin.percentage = parseFloat(calculatedPercent, 10)
                    margin.amount = floatWithCommas(formattedValue)
                }
            } else {
                margin.percentage = parseFloat(calculatedPercent, 10)
                margin.amount = floatWithCommas(formattedValue)
            }
        }
    }
    const handleAddMargin = () => {
        if (margin.percentage !== '' || margin.amount !== '' || action !== '') {
            setErrorMessage({})
        }
        if (action === '' && margin.action === '') {
            return setErrorMessage({ action: 'This field is required' })
        }
        if (margin.percentage === '' && margin.amount === '') {
            return setErrorMessage({ percentage: 'This field is required' })
        }
        if (margin.amount === '' && margin.percentage === '') {
            return setErrorMessage({ amount: 'This field is required' })
        }
        if (margin.amount === 'NaN') {
            return setErrorMessage({ amount: 'Please enter a valid amount' })
        }
        if (
            (action === 'Subtract Margin' ||
                margin.action === 'Subtract Margin') &&
            margin.percentage > 99
        ) {
            return setErrorMessage({
                amount: `Must be less than/equal to ${subTotal * 0.99}`,
            })
        }
        handleMargin(
            action ? action : margin.action,
            margin.amount || '',
            margin.percentage,
            true,
            subTotal,
        )
        handleClose()
        //  setInputs({ percentage: '', amount: '' })
        setAction('')
    }
    let selectValues = [{ value: 'Add Margin' }, { value: 'Subtract Margin' }]
    const modalTitle = `${
        margin?.isExisting ? 'Edit' : 'Add'
    } Margin to ${packageName}`

    return (
        <div className={Wrapper}>
            <div className={AddMarginMobileBackLink}>
                <LeftOutlined
                    style={{ color: 'var(--blue)', marginRight: 10 }}
                />
                <a
                    href="/app/products/add-package"
                    style={{ color: 'var(--blue)' }}
                >
                    Back to products
                </a>
            </div>
            <div className={MobileTitleBar}>
                <FormTitleBar
                    title={modalTitle}
                    subtitle="Please specify the amount you will like to add or remove"
                    alignLeft={isMobile}
                    titleFontSize="20px"
                    textAlignCenter={!isMobile}
                />
            </div>
            <div>
                <SelectField
                    selectWidth="391px"
                    values={selectValues}
                    initialOption={
                        margin?.action === '' ? 'Action' : margin?.action
                    }
                    floatingLabel={margin?.action === '' ? null : 'Action'}
                    handleChange={handleSelectChange}
                    selectedValue={margin?.isExisting ? margin?.action : action}
                    name="action"
                    type="add-margin"
                    errorMessage={errorMessage.action}
                    value={margin?.isExisting ? margin?.action : action}
                />
                <div className={AddMarginForm}>
                    <InputFields
                        name="percentage"
                        inputWidth="185px"
                        title="Percentage"
                        type="number"
                        floatingLabel="%"
                        value={Math.abs(margin?.percentage)}
                        handleChange={handleInputChange}
                        errorMessage={
                            errorMessage.percentage?.length > 0
                                ? errorMessage.percentage
                                : ''
                        }
                        paddingRight="40px"
                    />
                    <span className={InputRight}></span>
                    <InputFields
                        name="amount"
                        inputWidth="185px"
                        title="Amount"
                        nairaFloatingLabel="₦"
                        value={margin?.amount === 'NaN' ? '' : margin?.amount}
                        handleChange={handleInputChange}
                        errorMessage={errorMessage.amount}
                    />
                </div>
                <div className={BtnWrapper}>
                    <Button
                        btnTextColor="var(--white)"
                        btnBgColor="var(--blue)"
                        btnWidth="160px"
                        btnHeight="56px"
                        handleClick={handleAddMargin}
                    >
                        {margin?.isExisting ? 'Save Changes' : 'Add Margin'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AddMargin
