import { permissionsControl } from 'src/utils/permissionsControl'

export const attributeTypeOptions = [{ value: 'Text' }, { value: 'Number' }]
export const allowedOptions = [{ value: 'AC' }, { value: 'DC' }]
export const unitOfMeasurement = [{ value: 'Watt' }, { value: 'kWh' }]
export const moreActions = [
    { id: 1, action: 'rename-component-type', label: 'Rename Component Type' },
    { id: 2, action: 'delete-component-type', label: 'Delete Component Type' },
]

export const canUpdateComponentType = permissionsControl([
    'can_update_component_type',
])

export const canDeleteComponentType = permissionsControl([
    'can_delete_component_type',
])
