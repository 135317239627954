import { useMutation } from 'react-query'
import { updateBrandNameAdminApi } from 'src/api/admin/provider'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

export const useUpdateProviderBrands = ({ onSuccess, showAlert }) => {
    const { errorAlert, successAlert } = useCustomToast()
    const { isMobile } = useMediaQueries()

    const { mutate: handleUpdateProviderBrands, isLoading } = useMutation({
        mutationFn: ({ providerId, data }) =>
            updateBrandNameAdminApi(providerId, data),
        onSuccess: () => {
            if (showAlert) {
                successAlert('Update successful!', isMobile ? 320 : 500)
            }

            if (onSuccess) {
                onSuccess()
            }
        },
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })
    return {
        handleUpdateProviderBrands,
        isLoading,
    }
}
