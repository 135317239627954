/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Bar, defaults } from 'react-chartjs-2'
import {
    getOverviewApi,
    getUserActivitiesApi,
    getEstimationsAnalytics,
} from '../../api/products/overview'
import OverviewImg from '../../assets/images/overviewImg.svg'
import { EmptyStateIllustrator } from '../../assets/images/EmptyStateIllustrator'
import overviewNoActivity from '../../assets/images/overviewNoActivity.svg'
import overviewPackages from '../../assets/images/overviewPackages.svg'
import overviewComponents from '../../assets/images/overviewComponent.svg'
import overviewEstimation from '../../assets/images/overviewEstimation.svg'
import overviewApproved from '../../assets/images/overviewApproved.svg'
import ApprovedIcon from '../../assets/images/approved.svg'
import DeclinedIcon from '../../assets/images/declined.svg'
import { errorHandler } from '../../utils/errorHandler'
import Toast from '../../components/Toast'
import SeoComponent from '../../components/Seo'
import HelpCenter from '../../components/HelpCenter'
import FloatingBox from '../../components/FloatingBox'
import QuestionMarkIcon from '../../assets/images/questionMark.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import CustomerTypeModal from 'src/pages/Estimations/CustomerTypeModal'
import { isMobile } from 'src/utils/mediaQueries'
import Button from '../../components/Button'
import styles from './overview.module.scss'
import { format, parseISO } from 'date-fns'
import { permissionsControl } from 'src/utils/permissionsControl'
import CustomerType from '../Estimations/CustomerType'
import AddBrandsNotification from './components/AddBrandsNotification'

const {
    Container,
    OverviewWrapper,
    TopSection,
    OverviewImage,
    Product,
    Library,
    Overlay,
    OverlayBtn,
    OverviewPackages,
    SubHeading,
    Total,
    SectionWrapper,
    ImageWrapper,
    ActivityWrapper,
    OverlayEstimation,
    EstimationWrapper,
    NoActivityWrapper,
    ActivitiesWrapper,
    EmptyHeading,
    EmptyText,
    ImgWrapper,
    CardImage,
    ImgStyle,
    Activity,
    ActivityDate,
    ActivityRow,
    ActivityText,
    RowWrapper,
    MobilePackageWrapper,
    MobileAnalyticBtn,
    MobileBtnWrapper,
    RecentActivities,
    BarChartDataContainer,
    EmptyStateWrapper,
    // HelpCenterWrapper_Overview,
} = styles

// eslint-disable-next-line no-unused-vars
const Overview = ({ userInfo }) => {
    const [packagesCount, setPackagesCount] = useState(0)
    const [componentsCount, setComponentsCount] = useState(0)
    const [approvedEstCount, setApprovedEstCount] = useState(0)
    const [estimationsCount, setEstimationsCount] = useState(0)
    const [userActivities, setUserActivities] = useState([])
    const [estAnalytics, setEstAnalytic] = useState([])
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [showCustomerTypeModal, setShowCustomerTypeModal] = useState(false)
    const [customerType, setCustomerType] = useState('')
    const [showCustomerType, setShowCustomerType] = useState(false)
    const [useType, setUseType] = useState({})
    const [useTypeError, setUseTypeError] = useState({})

    const history = useHistory()
    const canViewEstimationAnalytics = permissionsControl([''])

    const dataCards = [
        {
            title: 'Packages',
            iconUrl: overviewPackages,
            count: packagesCount,
        },
        {
            title: 'Components',
            iconUrl: overviewComponents,
            count: componentsCount,
        },
        {
            title: 'Plans',
            iconUrl: overviewEstimation,
            count: estimationsCount,
        },
        {
            title: 'approved pla.',
            iconUrl: overviewApproved,
            count: approvedEstCount,
        },
    ]

    defaults.font.family = 'SF-Pro-Display'

    const options = {
        scales: {
            x: {
                ticks: {
                    beginAtZero: true,
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                    offset: true,
                },
                ticks: {
                    display: false,
                },
            },
        },
        layout: {
            padding: {
                top: 0,
                bottom: 0,
            },
        },

        plugins: {
            legend: {
                align: 'start',
                labels: {
                    font: {
                        size: 11,
                        family: 'SF-Pro-Display',
                    },
                    boxWidth: 10,
                    boxHeight: 10,
                    padding: 20,
                    borderRadius: 2,
                },
            },
        },
    }

    const closeCustomerType = () => {
        setShowCustomerType(false)
        setUseType({})
        setUseTypeError({})
    }

    const { refetch } = useQuery('get-overview', () => getOverviewApi(), {
        enabled: false,
        retry: false,
        onSuccess: data => {
            setPackagesCount(data?.data?.data?.packages)
            setComponentsCount(data?.data?.data?.components)
            setApprovedEstCount(data?.data?.data?.estimations?.approved)
            setEstimationsCount(data?.data?.data?.estimations?.all)
        },
        onError: error => {
            if (error) {
                const errObj = error?.response?.data
                if (error?.response?.status === 500) {
                    setErrorMessage(['Request failed. Please try again.'])
                } else {
                    setErrorMessage(errorHandler(errObj))
                }
            }
            setToastError(true)
        },
    })

    const onSuccess = data => {
        const sortedData = data?.data?.data
        const barType = type =>
            Object.values(sortedData).map(status => status[type])

        const barChartData = {
            labels: Object.keys(sortedData).map(label => label),
            datasets: [
                {
                    label: 'Awaiting Approval',
                    data: barType('awaiting_approval'),
                    backgroundColor: '#FFC222',
                    stack: 'Stack 0',
                    borderRadius: 2,
                    categoryPercentage: 0.3,
                    barPercentage: 0.5,
                    base: 0,
                },
                {
                    label: 'Approved',
                    data: barType('approved'),
                    backgroundColor: '#004AAD',
                    stack: 'Stack 1',
                    borderRadius: 2,
                    categoryPercentage: 0.3,
                    barPercentage: 0.5,
                },
                {
                    label: 'Cancelled',
                    data: barType('declined'),
                    backgroundColor: '#5DADEC',
                    stack: 'Stack 2',
                    borderRadius: 2,
                    categoryPercentage: 0.3,
                    barPercentage: 0.5,
                },
            ],
            barThickness: 1,
        }

        setEstAnalytic(barChartData)
    }

    const { refetch: analyticsRefetch } = useQuery(
        'get-estimation-analytics',
        () => getEstimationsAnalytics(),
        {
            enabled: false,
            retry: false,
            onSuccess: onSuccess,
            onError: error => {
                if (error) {
                    const errObj = error?.response?.data
                    if (error?.response?.status === 500) {
                        setErrorMessage(['Request failed. Please try again.'])
                    } else {
                        setErrorMessage(errorHandler(errObj))
                    }
                }
                setToastError(true)
            },
        },
    )

    const { refetch: refetchActivities } = useQuery(
        'get-user-activities',
        () => getUserActivitiesApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                let userActivities = []
                if (data?.data?.data.length > 3) {
                    userActivities = data?.data?.data.slice(0, 3)
                } else {
                    userActivities = data?.data?.data
                }
                setUserActivities(userActivities)
            },
            onError: error => {
                if (error) {
                    const errObj = error?.response?.data
                    if (error?.response?.status === 500) {
                        setErrorMessage(['Request failed. Please try again.'])
                    } else {
                        setErrorMessage(errorHandler(errObj))
                    }
                }
                setToastError(true)
            },
        },
    )

    useEffect(() => {
        refetch()
        refetchActivities()
        if (canViewEstimationAnalytics) {
            analyticsRefetch()
        }
    }, [
        refetch,
        refetchActivities,
        analyticsRefetch,
        canViewEstimationAnalytics,
    ])

    const estimation_mapping = {
        ESTIMATION_DECLINED: 'declined',
        ESTIMATION_DECLINED_AND_RE_ESTIMATE: 'declined to be re-estimated',
        ESTIMATION_APPROVED: 'approved',
    }
    const handleCloseCustomerTypeModal = () => {
        setShowCustomerTypeModal(false)
    }

    const showCreateProductButton = permissionsControl(['can_create_package'])
    const showCreatePaymentPlanButton = permissionsControl([
        'can_create_estimation',
    ])
    const showPaymentPlanAnalytics = permissionsControl(['can_view_analytics'])

    return (
        <>
            <SecondaryModal
                showModal={showCustomerTypeModal}
                modalHeight="487px"
                modalWidth="459px"
                noPadding={isMobile ? '16px' : '40px'}
                onCancel={handleCloseCustomerTypeModal}
                content={
                    <CustomerTypeModal
                        closeModal={handleCloseCustomerTypeModal}
                        customerType={customerType}
                        setCustomerType={setCustomerType}
                        setShowCustomerType={setShowCustomerType}
                    />
                }
            />

            <SecondaryModal
                showModal={showCustomerType}
                onCancel={() => {
                    closeCustomerType()
                    setCustomerType('')
                }}
                modalWidth={'475px'}
                title={'Select the customer use type'}
                v2
                content={
                    <CustomerType
                        useType={useType}
                        setUseType={setUseType}
                        useTypeError={useTypeError}
                        setUseTypeError={setUseTypeError}
                    />
                }
                showFooter
                footerLeftContent={'chat'}
                primaryBtnText={'Confirm'}
                handlePrimaryBtnClick={() => {
                    if (
                        useType?.useType?.length === undefined ||
                        useType?.useType?.length === 0
                    ) {
                        setUseTypeError({
                            useType: 'Please select your use type to continue',
                        })
                    } else {
                        if (customerType === 'new') {
                            history.push({
                                pathname: '/app/payment-plans/create/one',
                                state: { newCustomerType: useType?.useType },
                            })
                        } else {
                            history.push({
                                pathname: '/app/payment-plans/create',
                                state: { customerType },
                            })
                        }
                    }
                }}
                primaryBtnWidth={isMobile ? '127.15px' : '160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            />

            <AddBrandsNotification />

            <div className={Container}>
                <SeoComponent
                    title="Overview | SunFi | Simplifying and Scaling Clean Energy"
                    tracker="Overview"
                />
                {toastError && (
                    <Toast message={errorMessage?.[0]} messageType="error" />
                )}

                <div className={OverviewWrapper}>
                    <div className={TopSection}>
                        <img
                            src={OverviewImg}
                            alt="overview"
                            className={OverviewImage}
                        />
                        <p className={`${Overlay} ${Product}`}>Product</p>
                        <p className={`${Overlay} ${Library}`}>Library</p>
                        {showCreateProductButton && (
                            <div className={`${Overlay} ${OverlayBtn}`}>
                                <Button
                                    type="overviewLargeScreenBtn"
                                    btnBgColor="#004AAD"
                                    btnTextColor="#fff"
                                    handleClick={() =>
                                        history.push('/app/products', {
                                            overview: true,
                                        })
                                    }
                                >
                                    Create Product
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className={MobilePackageWrapper}>
                        {dataCards?.map((card, i) => (
                            <div key={i} className={OverviewPackages}>
                                <div className={ImageWrapper}>
                                    <img
                                        src={card?.iconUrl}
                                        alt="packages"
                                        className={CardImage}
                                    />
                                </div>
                                <div className={SectionWrapper}>
                                    <p className={`${SubHeading}`}>
                                        {card?.title}
                                    </p>
                                    <p className={Total}>{card?.count}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div
                    className={ActivityWrapper}
                    style={{
                        width: !showPaymentPlanAnalytics && !isMobile && '63%',
                    }}
                >
                    {showPaymentPlanAnalytics && (
                        <div>
                            <div className={MobileBtnWrapper}>
                                <p className={OverlayEstimation}>
                                    Payment Plans Analytics
                                </p>
                                {showCreatePaymentPlanButton && (
                                    <div
                                        className={`${
                                            estAnalytics.length !== 0 &&
                                            MobileAnalyticBtn
                                        }`}
                                    >
                                        <Button
                                            type="overview-responsive-btn"
                                            btnBgColor="#004AAD"
                                            btnTextColor="#fff"
                                            btnHeight="40px"
                                            handleClick={() => {
                                                estimationsCount > 0
                                                    ? setShowCustomerTypeModal(
                                                          true,
                                                      )
                                                    : history.push({
                                                          pathname:
                                                              '/app/payment-plans/create',
                                                          state: {
                                                              customerType:
                                                                  'new',
                                                          },
                                                      })
                                            }}
                                        >
                                            Create Payment Plans
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div
                                className={EstimationWrapper}
                                style={{ marginTop: '20px' }}
                            >
                                {estAnalytics?.length === 0 ? (
                                    <>
                                        <div className={EmptyStateWrapper}>
                                            <EmptyStateIllustrator />
                                            <p className={EmptyHeading}>
                                                Nothing’s here
                                            </p>
                                            <p className={EmptyText}>
                                                You haven’t estimated any
                                                payment plans.
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={BarChartDataContainer}>
                                            <Bar
                                                data={estAnalytics}
                                                options={options}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <div>
                        <p
                            className={`${OverlayEstimation} ${RecentActivities}`}
                        >
                            Recent Activities
                        </p>

                        {userActivities?.length === 0 ? (
                            <>
                                <div className={NoActivityWrapper}>
                                    <img
                                        src={overviewNoActivity}
                                        alt="no activity"
                                    />
                                    <p className={EmptyHeading}>
                                        No activity yet
                                    </p>
                                    <p className={EmptyText}>
                                        Approved/declined activities on your
                                        payment plans
                                    </p>
                                </div>
                            </>
                        ) : (
                            <div className={ActivitiesWrapper}>
                                {userActivities?.map((activity, i) => {
                                    return (
                                        <div key={i} className={RowWrapper}>
                                            <div className={ActivityRow}>
                                                <div
                                                    className={ImgWrapper}
                                                    style={{
                                                        background: [
                                                            'ESTIMATION_DECLINED',
                                                            'ESTIMATION_DECLINED_AND_RE_ESTIMATE',
                                                        ].includes(
                                                            activity?.action,
                                                        )
                                                            ? '#FFEFF4'
                                                            : '#F1FFF6',
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            [
                                                                'ESTIMATION_DECLINED',
                                                                'ESTIMATION_DECLINED_AND_RE_ESTIMATE',
                                                            ].includes(
                                                                activity?.action,
                                                            )
                                                                ? DeclinedIcon
                                                                : ApprovedIcon
                                                        }
                                                        className={ImgStyle}
                                                        alt="approved payment plan"
                                                        style={{
                                                            padding:
                                                                [
                                                                    'ESTIMATION_DECLINED',
                                                                    'ESTIMATION_DECLINED_AND_RE_ESTIMATE',
                                                                ].includes(
                                                                    activity?.action,
                                                                ) && '11px',
                                                            paddingLeft:
                                                                [
                                                                    'ESTIMATION_DECLINED',
                                                                    'ESTIMATION_DECLINED_AND_RE_ESTIMATE',
                                                                ].includes(
                                                                    activity?.action,
                                                                ) && '13px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={ActivityText}>
                                                <p className={Activity}>
                                                    <strong>
                                                        {activity?.consumer
                                                            ?.user?.first_name +
                                                            "'s"}{' '}
                                                    </strong>{' '}
                                                    repayment plan has been{' '}
                                                    {
                                                        estimation_mapping[
                                                            activity?.action
                                                        ]
                                                    }
                                                </p>

                                                <span className={ActivityDate}>
                                                    {activity?.created_at !==
                                                        undefined &&
                                                        format(
                                                            parseISO(
                                                                activity?.created_at,
                                                            ),
                                                            'MMM dd, yyyy',
                                                        )}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="HelpCenterWrapper">
                <FloatingBox
                    floatIcon={QuestionMarkIcon}
                    floatBgColor="#011A3C"
                    floatBoxClass="floatBox"
                    renderComponent={props => <HelpCenter {...props} />}
                />
            </div>
        </>
    )
}

export default Overview
