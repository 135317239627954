//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import DetailsPage from 'src/components/DetailsPage'
import CapexReplacementTable from './CapexReplacementTable'
import PlanInfoTable from './PlanInfoTable'
import CapexReplacementModal from './CapexReplacementModal'
import ProviderRepaymentTable from './RepaymentScheduleTable'
import ViewRepaymentModal from './ViewRepaymentModal'
import AdminSolutionModal from 'src/components/AdminSolutionModal'
import { useQuery } from 'react-query'
import { getSingleCustomerApi } from 'src/api/providers/customers'
import { replaceHtmlTags } from 'src/utils/formatting'
import { format, parseISO } from 'date-fns'
import PayStack from 'src/pages/Consumer/SmartShoppingExperience/CreditDecision/components/PayStack'
import Toast from 'src/components/Toast'
import { statusMapping } from './data'
import StatusIndicator from 'src/components/StatusIndicator'
import StatusBreakdown from 'src/components/StatusBreakdown'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const ActiveCustomers = () => {
    const [showCapexModal, setShowCapexModal] = useState(false)
    const [showRepaymentModal, setShowRepaymentModal] = useState(false)
    const [showSolutionModal, setShowSolutionModal] = useState(false)
    const [repaymentDetails, setRepaymentDetails] = useState({})
    const [planData, setPlanData] = useState(null)
    const [showPayStack, setShowPaystack] = useState(false)
    const [paystackLoading, setPaystackLoading] = useState(false)
    const [verifyLoading, setVerifyLoading] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [estimationId, setEstimationId] = useState('')
    const [consumerEmail, setConsumerEmail] = useState('')
    const [capexModalData, setCapexModalData] = useState([])
    const params = useParams()
    const history = useHistory()
    const fromActiveTab = window.location.pathname.includes('active')
    const [callInitializePayment, setCallInitializePayment] = useState(true)

    const { width } = useMediaQueries()

    const {
        refetch: activePlanRefetch,
        isLoading: activePlanLoading,
        isFetching: activePlanFetching,
    } = useQuery(
        'single-active-provider-customer-plan',
        () => getSingleCustomerApi(params?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setPlanData(data?.data?.data)
                setEstimationId(data?.data?.data?.id)
                setConsumerEmail(data?.data?.data?.consumer?.user?.email)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't fetch details. Refresh page`)
            },
        },
    )

    useEffect(() => {
        activePlanRefetch()
    }, [])

    const closeCapexModal = () => {
        setShowCapexModal(false)
    }

    const openCapexReplacementModal = item => {
        setCapexModalData(item)
        setShowCapexModal(true)
    }

    const openRepaymentModal = () => {
        setShowRepaymentModal(true)
    }

    const closeRepaymentModal = () => {
        setShowRepaymentModal(false)
    }

    const closeSolutionModal = () => {
        setShowSolutionModal(false)
    }

    const handleShowPaystackModal = num => {
        setPaystackLoading(true)
        setShowPaystack(true)
        setCallInitializePayment(!callInitializePayment)
    }

    const handleInitializePaymentError = error => {
        setToastError(true)
        setErrorMessage(error)
    }

    const handleVerifyLoader = () => {
        setVerifyLoading(!verifyLoading)
    }

    const handleReload = () => {
        window.location.reload()
    }

    return (
        <>
            {toastError && <Toast message={errorMessage} messageType="error" />}

            <CapexReplacementModal
                closeModal={closeCapexModal}
                showModal={showCapexModal}
                item={capexModalData}
            />

            <ViewRepaymentModal
                closeModal={closeRepaymentModal}
                showModal={showRepaymentModal}
                repaymentDetails={repaymentDetails}
            />

            <AdminSolutionModal
                closeModal={closeSolutionModal}
                showModal={showSolutionModal}
                recommendedSolution={planData?.recommended_solution}
            />

            {showPayStack && (
                <PayStack
                    estimationId={estimationId}
                    event="monthly_repayment"
                    handleError={handleInitializePaymentError}
                    email={consumerEmail}
                    reloadPage={handleReload}
                    handleLoading={() => setPaystackLoading(false)}
                    handleVerifyLoader={handleVerifyLoader}
                    callInitializePayment={callInitializePayment}
                    openCardDetailsModal={handleShowPaystackModal}
                    type="provider"
                    closePaystack={() => setPaystackLoading(false)}
                />
            )}
            <DetailsPage
                backNavTitle="Back to Customers"
                backNavOnClick={() => history.goBack()}
                name={`${planData?.consumer?.user?.first_name} ${planData?.consumer?.user?.last_name}`}
                status={
                    fromActiveTab
                        ? 'ACTIVE'
                        : statusMapping[planData?.approval_status]
                }
                nameAndStatusClassName={'ProviderCustomers__nameAndStatus'}
                customStatusElement={
                    <StatusIndicator status={planData?.application_status} />
                }
                infoSubhead="APPROVED SOLUTION"
                infoButton
                infoButtonText="View Solution"
                handleInfoButton={() => setShowSolutionModal(true)}
                infoParagraph={`${replaceHtmlTags(
                    planData?.recommended_solution?.package_description,
                )} (${
                    planData?.recommended_solution?.package_energy_capacity
                        ?.value
                } ${
                    planData?.recommended_solution?.package_energy_capacity
                        ?.unit_of_measurement
                })`}
                tabPaneOneTab="Plan Info"
                tabPaneOneContent={<PlanInfoTable data={planData} />}
                tabPaneTwo={planData?.payment_plan === 'Subscription'}
                tabPaneTwoTab="Capex Replacement"
                tabPaneTwoContent={
                    <CapexReplacementTable
                        openModal={openCapexReplacementModal}
                        data={planData?.recommended_solution?.components}
                    />
                }
                tabPaneThree
                tabPaneThreeTab="Repayment Schedule"
                tabPaneThreeContent={
                    <ProviderRepaymentTable
                        openModal={openRepaymentModal}
                        setDetails={setRepaymentDetails}
                        data={planData}
                        handleShowPaystackModal={handleShowPaystackModal}
                        paystackLoading={paystackLoading}
                        dataFetching={activePlanFetching}
                        verifyLoading={verifyLoading}
                    />
                }
                rightSectionHeading="Offer Details"
                rowCount={2}
                gridItemOneHeading="CREATION DATE"
                gridItemOneContent={
                    planData?.created_at !== undefined &&
                    format(parseISO(planData?.created_at), 'd MMM, yyyy, hh:mm')
                }
                gridItemTwoHeading="CREATED BY"
                gridItemTwoContent={`${planData?.created_by?.first_name} ${planData?.created_by?.last_name}`}
                gridItemThreeHeading="UPDATED AT"
                gridItemThreeContent={
                    planData?.updated_at !== undefined &&
                    format(parseISO(planData?.updated_at), 'd MMM, yyyy, hh:mm')
                }
                gridItemFourHeading="PAYMENT TYPE"
                gridItemFourContent={
                    planData?.payment_plan ? planData?.payment_plan : 'Not Set'
                }
                customRightSectionComponent={
                    width >= 1179 && (
                        <StatusBreakdown
                            consumerType="DEP"
                            status={planData?.application_status}
                        />
                    )
                }
                additionalLeftSectionComponent={
                    width <= 1180 && (
                        <StatusBreakdown
                            consumerType="DEP"
                            status={planData?.application_status}
                            margin={'26px 0 0 0'}
                            showTitle={false}
                        />
                    )
                }
                isLoading={activePlanLoading || activePlanFetching}
            />
        </>
    )
}

export default ActiveCustomers
