import { useState } from 'react'
import { PropTypes } from 'prop-types'
import { ConsumerButton } from 'src/components/Button'
import { ReactComponent as ArrowDown } from 'src/assets/images/arrow-down-icon.svg'
import { ReactComponent as DayIcon } from 'src/assets/images/day-icon.svg'
import { ReactComponent as NightIcon } from 'src/assets/images/night-icon.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { numberWithCommas } from 'src/utils/formatting'
import styles from './packageitem.module.scss'

const PackageItem = props => {
    const [showSummary, setShowSummary] = useState(false)
    const { isMobile } = useMediaQueries()

    const toggleShowSummary = () => {
        setShowSummary(!showSummary)
    }

    const wattsPerAppliance = props.appliances?.map(
        item =>
            (props.useUnderscoredKeys ? item.power_rating : item.powerRating) *
            item.quantity,
    )

    const totalWattsAdded = wattsPerAppliance.reduce((a, b) => a + b, 0) || 0

    const dayUsage = props.appliances?.map(
        item =>
            (props.useUnderscoredKeys ? item.power_rating : item.powerRating) *
            item.backup_hours *
            item.quantity,
    )
    const totalDayUsage = dayUsage.reduce((a, b) => a + b, 0) || 0
    const nightUsage = props.appliances?.map(
        item =>
            (props.useUnderscoredKeys ? item.power_rating : item.powerRating) *
            item.backup_hours_night *
            item.quantity,
    )
    const totalNightUsage = nightUsage.reduce((a, b) => a + b, 0) || 0

    return (
        <div className={styles.Container}>
            <div className={`${styles.TopSection}`}>
                <div className={styles.TopSectionTitleWrapper}>
                    <h3>{props.title}</h3>
                    <div className={styles.TopRightSection}>
                        <span className={styles.Badge}>
                            {props.appliances?.length}
                        </span>
                        <p>Appliance(s) added</p>
                        <div
                            className={`${
                                showSummary
                                    ? 'GYOEnergySummaryArrowRotateVisible'
                                    : 'GYOEnergySummaryArrowRotateHidden'
                            } ${styles.IconBox}`}
                            onClick={toggleShowSummary}
                        >
                            <ArrowDown />
                        </div>
                    </div>
                </div>

                <div
                    className={`${
                        showSummary
                            ? styles.TopSectionSummaryVisible
                            : styles.TopSectionSummaryHidden
                    }`}
                >
                    <div className="space-between">
                        <p className="GYOEnergySummaryTotalWattsLabel">
                            Total watts added
                        </p>
                        <div
                            className="space-between"
                            style={{
                                gridColumnGap: '6px',
                                width: isMobile ? 'fit-content' : '184px',
                            }}
                        >
                            <div className="GYOEnergySummaryBodyUsageLabel">
                                <DayIcon />
                                Total day usage
                            </div>
                            <span className="GYOEnergySummaryBodyUsageValue">
                                {numberWithCommas(
                                    parseFloat(totalDayUsage, 10).toFixed(2),
                                )}{' '}
                                wh
                            </span>
                        </div>
                    </div>
                    <div
                        className="space-between"
                        style={{
                            marginTop: '4px',
                            alignItems: 'center',
                        }}
                    >
                        <p className="GYOEnergySummaryTotalWattsValue">
                            {numberWithCommas(
                                parseFloat(totalWattsAdded, 10).toFixed(2),
                            )}{' '}
                            <sup>watts</sup>
                        </p>
                        <div
                            className="space-between"
                            style={{
                                gridColumnGap: '6px',
                                width: isMobile ? 'fit-content' : '184px',
                            }}
                        >
                            <div className="GYOEnergySummaryBodyUsageLabel">
                                <NightIcon />
                                Total night usage
                            </div>
                            <span className="GYOEnergySummaryBodyUsageValue">
                                {numberWithCommas(
                                    parseFloat(totalNightUsage, 10).toFixed(2),
                                )}{' '}
                                wh
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.BottomSection}>
                <ConsumerButton
                    btnBgColor={'#E2EEFF'}
                    btnTextColor={'var(--blue)'}
                    btnWidth={'141px'}
                    btnHeight={'40px'}
                    padding={'0px'}
                    handleClick={props.handleView}
                    btnFontSize="12px"
                    fontFamily={'SF-Pro-Display-Medium'}
                    hover
                >
                    View appliances
                </ConsumerButton>
                <ConsumerButton
                    btnBgColor={'var(--blue)'}
                    btnTextColor={'#fff'}
                    btnWidth={'137px'}
                    btnHeight={'40px'}
                    handleClick={props.handleUse}
                    btnFontSize="12px"
                    fontFamily={'SF-Pro-Display-Medium'}
                    primaryhHover
                >
                    Use this package
                </ConsumerButton>
            </div>
        </div>
    )
}

PackageItem.propTypes = {
    title: PropTypes.string,
    appliances: PropTypes.array,
    handleView: PropTypes.func,
    handleUse: PropTypes.func,
    useUnderscoredKeys: PropTypes.bool,
}

export default PackageItem
