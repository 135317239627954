//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import GetModal from '../../components/Modal'
import { Pagination, Skeleton, Tabs } from 'antd'
import Toast from '../../components/Toast'
import './estimations-steptwo.scss'
import LeftArrow from '../../assets/images/left-blue-arrow.svg'
import Tick from '../../assets/images/tick.svg'
import { NoFLoatingLabelInputFields } from '../../components/InputFields'
import EmptyState from '../../components/EmptyState'
import {
    getEnergySolutionsApi,
    getComponentsApi,
    createEnergySolutionsApi,
    getEstimationByIdApi,
} from '../../api/estimations/estimations-step-two'
import { getProviderSubscriptionModelEnabled } from '../../api/estimations/estimations-step-one'
import { getSinglePackageApi } from '../../api/products/packages'

import { errorHandler } from '../../utils/errorHandler'
import { numberWithCommas } from '../../utils/formatting'
import { useLocation } from 'react-router-dom'
import { formatPackageComponents } from '../../components/ProductPackage/EditPackage/formatter'

import PackageDetails from './PackageDetails'
import CapexReplacement from './CapexReplacement'

import SeoComponent from '../../components/Seo'
import { currencyFormatter } from '../../utils/currencyFormatter'
import { eventTrackers } from '../../utils/eventTrackers.js'
import Label from 'src/components/Label'
import AddMargin from 'src/components/ProductPackage/AddMargin'
import { appTracking } from '../../utils/appTracker.js'
import ProductLayersDefault from 'src/assets/images/layers.svg'
import SelectLibrary from '../EstimationsStepOne/SelectLibrary'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { isMobile } from 'src/utils/mediaQueries'

const EstimationsStepTwo = () => {
    const history = useHistory()
    const [showModal, setShowModal] = useState(false)
    const [modalId, setModalId] = useState('')
    const [energySolutionsData, setEnergySolutionsData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [listTotal, setListTotal] = useState(1)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [estimation, setEstimation] = useState([])
    const [enableQuery, setEnableQuery] = useState(false)
    const estimationsId = localStorage.getItem('estimationsId')
    const [errorType, setErrorType] = useState('')
    const location = useLocation()
    //vicky's components functionality
    const [inputs, setInputs] = useState({
        packageName: '',
        packageDescription: '',
    })
    const [allComponents, setAllComponents] = useState([])
    const [errMessage] = useState({
        packageName: '',
        packageDescription: '',
        components: '',
    })
    const [selected, setSelected] = useState([])
    const [skeletalState, setSkeletalState] = useState(true)
    const [count, setCount] = useState([])
    const [total, setTotal] = useState(0)
    const [subtotal, setSubtotal] = useState(0)
    const [margin, setMargin] = useState({
        action: '',
        amount: '',
        percentage: '',
        isExisting: false,
    })
    const [componentList, setComponentList] = useState([])
    const [packageComponents, setPackageComponents] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const [showMarginModal, setShowMarginModal] = useState(false)
    const [showSelectLibraryModal, setShowSelectLibraryModal] = useState(false)
    const [library, setLibrary] = useState('')
    const [clusterCode, setClusterCode] = useState(location?.state?.clusterCode)

    const pageTitle = 'SunFi - Create A Payment Plan | Payment Plans'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CREATE_PAYMENT_PLAN_TRACKER'
    const [subscriptionModelEnabled, setSubscriptionModelEnabled] =
        useState(false)
    useEffect(() => {
        location.state &&
            location.state?.isEstimationTwo &&
            localStorage.setItem('isEstimationTwo', 'true')
        //eslint-disable-next-line
    }, [])
    const [shouldFetchSinglePackage, setShouldFetchSinglePackage] =
        useState(false)
    //stepper route to step one
    const pushRouteOne = () => {
        let currentUrl = location.pathname
        switch (currentUrl) {
            case '/app/payment-plans/create/two':
                history.push('/app/payment-plans/create/one', {
                    params: estimationsId,
                })
                break
            default:
                history.push(`/app/payment-plans/edit/one/${estimationsId}`)
        }
    }

    const OpenModal = () => {
        setShowModal(!showModal)
        setShouldFetchSinglePackage(!shouldFetchSinglePackage)
    }
    const CloseModal = () => {
        setShowModal(false)
        setMargin({
            action: '',
            amount: '',
            percentage: '',
            isExisting: false,
        })
    }
    //fetch energy solutions
    const {
        refetch: fetchEnergySolutions,
        isFetching: energySolutionsLoading,
    } = useQuery(
        ['fetchEnergySolutions', currentPage],
        () =>
            getEnergySolutionsApi(
                searchInput ? 1 : currentPage,
                searchInput,
                clusterCode,
            ),
        {
            enabled: enableQuery,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                setEnergySolutionsData(data?.data?.data)
                setListTotal(data?.data?.total_pages)
            },
            onError: () => {
                setToastError(true)
                setErrorType('component')
                setErrorMessage('Failed to fetch All Estimations. Refresh page')
            },
        },
    )

    //fetch repayment-plans
    const { refetch: fetchEstimation } = useQuery(
        ['fetchEstimation', estimationsId],
        () => getEstimationByIdApi(estimationsId),
        {
            enabled: false,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                setEstimation(data?.data?.data)
            },
            onError: () => {
                setToastError(true)
                // setErrorType('estimation');
                setErrorMessage('Failed to fetch Payment Plan. Refresh page')
            },
        },
    )
    useEffect(() => {
        fetchEstimation()
    }, [fetchEstimation])

    useEffect(() => {
        searchInput === '' && fetchEnergySolutions()
    }, [searchInput])

    useEffect(() => {
        fetchEnergySolutions()
    }, [currentPage])

    //pagination
    const paginationRequest = () => {
        return listTotal > 1 ? paginationBlock() : ''
    }

    const handlePage = pageNumber => {
        setCurrentPage(pageNumber)
    }
    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={currentPage}
                defaultPageSize={1}
                total={parseInt(listTotal, 10)}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={value => {
                    handlePage(value)
                }}
            />
        )
    }
    //POST selected energy solutions
    const componentsPayload = selected.map(components => ({
        id: components.id,
        quantity: components.count || components.quantity,
    }))
    const {
        isLoading: createEnergySolutionsLoading,
        refetch: createEnergySolutionsRefetch,
    } = useQuery(
        ['createEnergySolutions'],
        () =>
            createEnergySolutionsApi(estimationsId, {
                id: modalId,
                margin:
                    margin.action === ''
                        ? 0
                        : margin.action === 'Add Margin'
                        ? margin.percentage
                        : margin.percentage < 0
                        ? margin.percentage
                        : -margin.percentage,
                components: componentsPayload,
                ...(clusterCode !== '' && { cluster_code: clusterCode }),
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                let currentUrl = location.pathname
                switch (currentUrl) {
                    case '/app/payment-plans/create/two':
                        history.push('/app/payment-plans/create/three', {
                            isEstimationThree: true,
                        })
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            eventTrackers['createPaymentPlanStepTwo'].action,
                            eventTrackers['createPaymentPlanStepTwo'].label,
                            eventTrackers['createPaymentPlanStepTwo'].category,
                            ['MP', 'GA'],
                            'event',
                            {
                                event: {
                                    payment_plan_step_two_info: {
                                        margin:
                                            margin.action === ''
                                                ? 0
                                                : margin.action === 'Add Margin'
                                                ? margin.percentage
                                                : margin.percentage < 0
                                                ? margin.percentage
                                                : -margin.percentage,
                                        components: componentsPayload,
                                    },
                                },
                            },
                        )
                        break
                    default:
                        history.push('/app/payment-plans/edit/three', {
                            isEstimationThree: true,
                        })
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            eventTrackers['restimatedPaymentPlanStepTwo']
                                .action,
                            eventTrackers['restimatedPaymentPlanStepTwo'].label,
                            eventTrackers['restimatedPaymentPlanStepTwo']
                                .category,
                            ['MP', 'GA'],
                            'event',
                            {
                                event: {
                                    payment_plan_step_two_info: {
                                        margin:
                                            margin.action === ''
                                                ? 0
                                                : margin.action === 'Add Margin'
                                                ? margin.percentage
                                                : margin.percentage < 0
                                                ? margin.percentage
                                                : -margin.percentage,
                                        components: componentsPayload,
                                    },
                                },
                            },
                        )
                }
                setMargin({
                    action: '',
                    amount: '',
                    percentage: '',
                    isExisting: false,
                })
            },
            onError: data => {
                if (data?.response?.data?.errors) {
                    setErrorMessage(errorHandler(data?.response?.data))
                } else {
                    setErrorMessage([
                        'Failed to create package. Refresh/Try Again',
                    ])
                }
                setToastError(true)
            },
        },
    )

    const handleSaveEnergySolutions = () => {
        setToastError(false)
        createEnergySolutionsRefetch()
    }

    //vicky's functionality
    const { refetch: packageRefetch } = useQuery(
        ['fetchSinglePackage'],
        () => getSinglePackageApi(modalId, false, clusterCode),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const packageInfo = data?.data?.data

                setInputs(prev => ({
                    ...prev,
                    packageName: packageInfo?.name,
                    packageDescription: packageInfo?.description,
                }))

                const packageMarginPercentage = parseFloat(packageInfo?.margin)
                if (packageMarginPercentage !== 0) {
                    const packageMarginAmount =
                        (packageMarginPercentage / 100) * packageInfo?.subtotal
                    const marginDescription =
                        packageMarginPercentage > 0
                            ? 'Add Margin'
                            : 'Subtract Margin'
                    handleMargin(
                        marginDescription,
                        packageMarginAmount < 0
                            ? packageMarginAmount * -1
                            : packageMarginAmount,
                        packageMarginPercentage,
                        true,
                        packageInfo?.subtotal,
                    )
                }

                setPackageComponents(
                    formatPackageComponents(data?.data?.data?.components),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't fetch package. Refresh page`)
            },
        },
    )

    const { isLoading: fetchComponentsLoading, refetch: fetchComponents } =
        useQuery(['fetchAllComponents'], () => getComponentsApi(), {
            enabled: false,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                setComponentList(data?.data?.data)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch components. Refresh page')
            },
        })

    const handleSelectChange = useCallback(
        value => {
            let filteredComponent = allComponents.find(item => {
                const formattedItem = `${item?.name} (₦${currencyFormatter(
                    item?.cost,
                )} | ${item?.type?.name})`
                if (formattedItem == value) return item
            })
            let preselectedfilterComponent = [...selected, filteredComponent]
            const updatedSelected = preselectedfilterComponent.map(
                component => {
                    return {
                        ...component,
                        estimatedValue: `${
                            component?.name
                        } (₦${numberWithCommas(component?.cost)} | ${
                            component?.type
                        })`,
                        count: component?.count || 1,
                        realCost:
                            component?.realCost === undefined
                                ? component?.cost
                                : component?.realCost,
                        isGlobal: component?.is_global,
                    }
                },
            )
            const countList = updatedSelected.map(item => {
                return {
                    id: item.id,
                    cost: item.cost,
                    count: item?.count || 1,
                    realCost: item?.realCost,
                    isGlobal: item?.is_global,
                }
            })
            setCount(countList)
            setSelected(updatedSelected)
        },
        //eslint-disable-next-line
        [allComponents, selected],
    )
    const getCount = componentId => {
        return count.filter(value => value.id === componentId)[0].count
    }

    const updateCount = (currentCount, action, componentId, cost, realCost) => {
        let newCount = {
            id: componentId,
            cost: cost,
            count: currentCount,
            realCost,
        }
        if (action === 'add') {
            newCount.count = currentCount + 1
            newCount.cost = realCost * (currentCount + 1)
        }
        if (action === 'subtract' && currentCount > 1) {
            newCount.count = currentCount - 1
            newCount.cost = realCost * (currentCount - 1)
        }

        let countCopy = [...count]
        let selectedCopy = [...selected]

        let found = countCopy.findIndex(q => q.id === componentId)
        let foundSelected = selectedCopy.findIndex(q => q.id === componentId)

        if (found > -1) {
            countCopy[found] = newCount
        }

        if (foundSelected > -1) {
            selectedCopy[foundSelected] = {
                ...selectedCopy[foundSelected],
                cost: newCount.cost,
                count: newCount.count,
            }
        }

        setCount(countCopy)
        setSelected(selectedCopy)
    }

    const handleCancel = id => {
        let newComponent = selected.filter(data => data.id !== id)
        let countList = []

        newComponent.forEach(item => {
            countList.push({
                id: item?.id,
                cost: item?.cost,
                count: item?.count,
                realCost: item?.realCost,
                isGlobal: item?.is_global,
            })
        })

        setCount(countList)
        setSelected(newComponent)
    }

    const handleMargin = (
        action,
        amount,
        percentage,
        isExisting,
        partTotal,
    ) => {
        setMargin({ action, amount, percentage, isExisting })
        if (action === 'Add Margin') {
            let Total = Number(partTotal) + Number(amount)
            setTotal(Total)
        } else if (action === 'Subtract Margin') {
            let Total = Number(partTotal) - Number(amount)
            setTotal(Total)
        } else {
            setTotal(Number(partTotal))
        }
    }
    useEffect(() => {
        const allComponentsList = componentList
        //compare and update recommended_solutions component with name and type
        let formatPreselectedComponents =
            estimation.recommended_solution?.components?.map(function (x) {
                var result = componentList.filter(a1 => a1.id === x.id)
                if (result.length > 0) {
                    x.name = result[0].name
                    x.type = result[0].type.name
                    x['count'] = x['quantity']
                    x['cost'] = x['total_cost']
                    x['realCost'] = x['unit_cost']
                    x['capexReplacement'] = x['capex_replacement_schedule']
                    x['isGlobal'] = result[0].is_global
                }
                return x
            })

        const packageComponentsList =
            estimation.recommended_solution?.id === modalId
                ? formatPreselectedComponents
                : packageComponents
        let formatAllComponentList = allComponentsList?.map(item => {
            return {
                ...item,
                value: `${item?.name} (₦${currencyFormatter(item?.cost)} | ${
                    item?.type?.name
                })`,
            }
        })
        let formatPackageComponentList = packageComponentsList?.map(item => {
            return {
                ...item,

                value: `${
                    item?.name || item?.component_name
                } (₦${currencyFormatter(item?.realCost || item?.unit_cost)} | ${
                    item?.type || item?.component_type
                })`,
                estimatedValue: `${
                    item?.name || item?.component_name
                } (₦${currencyFormatter(item?.cost || item?.total_cost)} | ${
                    item?.type || item?.component_type
                })`,
                capexReplacement: item?.capexReplacement,
                isGlobal: item?.isGlobal,
            }
        })

        let countList = []
        let checked = []

        formatPackageComponentList.forEach(item => {
            checked.push(item.value)
            countList.push({
                id: item.id,
                cost: item.cost || item?.total_cost,
                count: item?.count || item?.quantity,
                realCost: parseFloat(
                    item?.realCost || item?.unit_cost,
                    10,
                ).toFixed(2),
                isGlobal: item?.is_global,
            })
        })

        setCount(countList)
        setAllComponents(formatAllComponentList)
        setSelected(formatPackageComponentList)
        setTimeout(() => {
            setSkeletalState(false)
        }, 1500)
        // eslint-disable-next-line
    }, [packageComponents, componentList])

    useEffect(() => {
        fetchComponents()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let calculateSubtotal = count.reduce(
            (total, item) => total + item?.realCost * item?.count,
            0,
        )
        setSubtotal(calculateSubtotal.toFixed(2))
        if (margin?.action === 'Add Margin') {
            setTotal(calculateSubtotal + Number(margin.amount))
        } else if (margin?.action === 'Subtract Margin') {
            setTotal(calculateSubtotal - Number(margin.amount))
        }

        const packageMarginPercentage = margin
            ? parseFloat(margin.percentage)
            : 0
        if (
            packageMarginPercentage !== 0 &&
            calculateSubtotal !== 0 &&
            subtotal !== 0
        ) {
            const packageMarginAmount =
                (Math.abs(packageMarginPercentage) / 100) * calculateSubtotal
            const marginDescription =
                packageMarginPercentage > 0 ? 'Add Margin' : 'Subtract Margin'
            if (isNaN(packageMarginAmount) || isNaN(packageMarginPercentage)) {
                handleMargin('', '', '', false, '')
            } else {
                handleMargin(
                    marginDescription,
                    packageMarginAmount.toFixed(2),
                    packageMarginPercentage,
                    true,
                    calculateSubtotal.toFixed(2),
                )
            }
        }

        // eslint-disable-next-line
    }, [count, selected])

    useEffect(() => {
        setSkeletalState(true)
        if (modalId && shouldFetchSinglePackage) {
            packageRefetch()
        }
    }, [modalId, shouldFetchSinglePackage])

    const { TabPane } = Tabs

    const handleSearchInput = e => {
        const { value } = e.target
        setSearchInput(value)
    }

    const handleOnBlur = e => {
        const { value } = e.target
        setSearchInput(value.trim())
    }

    const handleSearch = () => {
        fetchEnergySolutions()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
            setEnableQuery(true)
        }
    }

    // get subscription model enabled
    const { refetch: subscriptionModelSupportRefetch } = useQuery(
        'get-provider-subscription-model-enabled',
        () => getProviderSubscriptionModelEnabled(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setSubscriptionModelEnabled(data?.data?.data)
            },
        },
    )
    useEffect(() => subscriptionModelSupportRefetch(), [])

    const handleCloseMarginModal = () => {
        setShowMarginModal(false)
        setShowModal(true)
    }

    const paymentPlanModel = estimation?.payment_plan

    const handleProductPackages = () => {
        setClusterCode('')
        setShowSelectLibraryModal(false)
        const timeout = setTimeout(() => fetchEnergySolutions(), 1000)
        return () => clearTimeout(timeout)
    }
    return (
        <>
            <SecondaryModal
                showModal={showSelectLibraryModal}
                modalHeight="487px"
                modalWidth="459px"
                noPadding={isMobile ? '16px' : '40px'}
                onCancel={() => setShowSelectLibraryModal(false)}
                content={
                    <SelectLibrary
                        closeModal={() => setShowSelectLibraryModal(false)}
                        library={library}
                        setLibrary={setLibrary}
                        handleProductPackages={handleProductPackages}
                        paymentPlanModel={paymentPlanModel}
                    />
                }
            />

            <SeoComponent
                title="SunFi - Create Payment Plan | Step Two"
                tracker="CreatePaymentplanStepTwo"
            />

            <div className="Estimation_steptwo--container">
                <button
                    className="PrevButton"
                    onClick={() => history.push('/app/payment-plans')}
                >
                    <img src={LeftArrow} alt="left-arrow" /> Back to Payment
                    Plans
                </button>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <h1 className="EstimationStepOneH1">
                        Create a Single Payment Plan{' '}
                        {clusterCode && (
                            <span className="EstimationStepOneClusterSpan">
                                {`CLUSTER: ${clusterCode}`}
                            </span>
                        )}
                    </h1>
                    {clusterCode ? (
                        <p
                            className="EstimationStepOneClusterP"
                            onClick={() => setShowSelectLibraryModal(true)}
                        >
                            <img
                                src={ProductLayersDefault}
                                alt="library icon"
                            />
                            Select package from library
                        </p>
                    ) : (
                        <p
                            className="EstimationStepOneClusterP"
                            onClick={() =>
                                history.push({
                                    pathname:
                                        '/app/payment-plans/cluster-package',
                                    state: {
                                        paymentPlanModel: paymentPlanModel,
                                    },
                                })
                            }
                        >
                            <img
                                src={ProductLayersDefault}
                                alt="library icon"
                            />
                            Select package from a cluster library
                        </p>
                    )}
                </div>
                <GetModal
                    app="energy"
                    showModal={showModal}
                    onCancel={CloseModal}
                    modalWidth="550px"
                    modalHeight="100%"
                    noPaddingBoth
                    closable={true}
                    content={
                        <div className="EstimationStepTwoModal">
                            <Tabs
                                defaultActiveKey="1"
                                className={
                                    subscriptionModelEnabled
                                        ? 'RecommendedSolutionTab'
                                        : 'CenteredTab'
                                }
                            >
                                <TabPane tab="Package Details" key="1">
                                    <PackageDetails
                                        energySolutionsData={
                                            energySolutionsData
                                        }
                                        modalId={modalId}
                                        skeletalState={skeletalState}
                                        allComponents={
                                            clusterCode
                                                ? selected
                                                : allComponents
                                        }
                                        selected={selected}
                                        handleSelectChange={handleSelectChange}
                                        fetchComponentsLoading={
                                            fetchComponentsLoading
                                        }
                                        errMessage={errMessage}
                                        updateCount={updateCount}
                                        getCount={getCount}
                                        count={count}
                                        handleCancel={handleCancel}
                                        margin={margin}
                                        subtotal={subtotal}
                                        total={total}
                                        createEnergySolutionsLoading={
                                            createEnergySolutionsLoading
                                        }
                                        handleSaveEnergySolutions={
                                            handleSaveEnergySolutions
                                        }
                                        handleShowMarginModal={() => {
                                            setShowModal(false)
                                            setShowMarginModal(true)
                                        }}
                                        disabled={clusterCode !== undefined}
                                    />
                                </TabPane>
                                {subscriptionModelEnabled && (
                                    <TabPane tab="Capex Replacement" key="2">
                                        <CapexReplacement
                                            energySolutionsData={
                                                energySolutionsData
                                            }
                                            modalId={modalId}
                                            skeletalState={skeletalState}
                                            allComponents={allComponents}
                                            selected={selected}
                                            handleSelectChange={
                                                handleSelectChange
                                            }
                                            fetchComponentsLoading={
                                                fetchComponentsLoading
                                            }
                                            errMessage={errMessage}
                                            updateCount={updateCount}
                                            getCount={getCount}
                                            count={count}
                                            handleCancel={handleCancel}
                                            margin={margin}
                                            subtotal={subtotal}
                                            total={total}
                                            createEnergySolutionsLoading={
                                                createEnergySolutionsLoading
                                            }
                                            handleSaveEnergySolutions={
                                                handleSaveEnergySolutions
                                            }
                                        />
                                    </TabPane>
                                )}
                            </Tabs>
                        </div>
                    }
                />
                <GetModal
                    app="workspace"
                    showModal={showMarginModal}
                    onCancel={handleCloseMarginModal}
                    closable={true}
                    content={
                        <AddMargin
                            packageName={inputs?.packageName}
                            subTotal={subtotal}
                            margin={margin}
                            handleClose={handleCloseMarginModal}
                            handleMargin={handleMargin}
                        />
                    }
                />
                <div className="EstimationStepOneContainer">
                    <div className="StepsContainer">
                        <div className="StepsChildContainer StepsChildContainerDiv">
                            <button
                                className="StepsButton"
                                style={{
                                    background: '#61c9a8',
                                    border: 'none',
                                }}
                                onClick={pushRouteOne}
                            >
                                <img
                                    src={Tick}
                                    alt="tick"
                                    style={{ padding: '4px' }}
                                />
                            </button>
                            <p className="StepsText">Payment Plan Info</p>
                        </div>
                        <div
                            className="StepsLine"
                            style={{ borderColor: '#61c9a8' }}
                        ></div>
                        <div className="StepsChildContainer">
                            <button className="StepsButton StepsButtonStyle">
                                2
                            </button>
                            <p className="StepsText">Energy Solution</p>
                        </div>
                        <div className="StepsLine"></div>
                        <div className="StepsChildContainer">
                            <button
                                className="StepsButton"
                                style={{ pointerEvents: 'none' }}
                            >
                                3
                            </button>
                            <p className="StepsText">Payment Plans</p>
                        </div>
                    </div>
                    <div className="EstimationStepTwoLayout">
                        <div style={{ width: '100%', display: 'block' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <h2 className="EstimationStepOneH2">
                                        Energy Solution
                                    </h2>
                                    <p className="EstimationStepOneSubTitle">
                                        Select Package to get Payment Plan
                                    </p>
                                </div>
                                <div className="EstimationStepTwo_Search--mobile-responsiveness">
                                    <NoFLoatingLabelInputFields
                                        TextPrefilled={
                                            searchInput ? true : false
                                        }
                                        inputWidth="361px"
                                        inputHeight="52px"
                                        type="text"
                                        value={searchInput}
                                        inputBackground="#F6F6F6"
                                        iconType="search"
                                        handleChange={handleSearchInput}
                                        prefilled
                                        onBlur={handleOnBlur}
                                        inputPlaceholder="Search Products"
                                        onIconClick={handleSearch}
                                        onKeyUp={handleKeyPress}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: '43px' }}></div>
                            {toastError &&
                                errorType === 'All Payment Plans' && (
                                    <Toast
                                        messageType="error"
                                        message={errorMessage}
                                    />
                                )}
                            {energySolutionsData.length > 0 &&
                            !energySolutionsLoading ? (
                                <div className="EnergySolutionSelectContainer">
                                    {energySolutionsData.map(
                                        (energy, index) => (
                                            <div
                                                key={index}
                                                className="EnergySolutionSelectChildContainer"
                                            >
                                                <input
                                                    type="radio"
                                                    name="fav_lang"
                                                    id={energy.name}
                                                    value={energy.name}
                                                    style={{ display: 'none' }}
                                                />
                                                <label
                                                    htmlFor={energy.name}
                                                    className="EnergySolutionSelectLabel"
                                                >
                                                    <div className="EstimationPackageName">
                                                        <p
                                                            style={{
                                                                fontWeight:
                                                                    '600',
                                                            }}
                                                        >
                                                            {energy.name}
                                                        </p>

                                                        {energy?.is_global ===
                                                        true ? (
                                                            <Label title="GLOBAL" />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>

                                                    <p className="EstimationSolutionSelectChildAmountText">
                                                        {estimation
                                                            ?.recommended_solution
                                                            ?.id === energy.id
                                                            ? `${
                                                                  energy.currency
                                                              } ${currencyFormatter(
                                                                  estimation
                                                                      ?.recommended_solution
                                                                      ?.total_cost,
                                                              )}`
                                                            : `${
                                                                  energy.currency
                                                              } ${currencyFormatter(
                                                                  energy.total,
                                                              )}`}
                                                    </p>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'flex-end',
                                                        }}
                                                    >
                                                        <span
                                                            className={
                                                                estimation
                                                                    ?.recommended_solution
                                                                    ?.id ===
                                                                energy.id
                                                                    ? 'SolutionSelectButtonActive'
                                                                    : 'SolutionSelectButton'
                                                            }
                                                            onClick={() => {
                                                                OpenModal()
                                                                setModalId(
                                                                    energy.id,
                                                                )
                                                            }}
                                                        >
                                                           
                                                            {estimation
                                                                ?.recommended_solution
                                                                ?.id ===
                                                            energy.id
                                                                ? 'Selected'
                                                                : 'Select'}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        ),
                                    )}
                                </div>
                            ) : energySolutionsLoading ? (
                                <>
                                    <div className="EnergySolutionSelectContainer">
                                        {[...Array(8)].map(i => (
                                            <div
                                                key={i}
                                                className="EnergySolutionSelectChildContainer"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Skeleton.Button
                                                    active
                                                    className="EstimationStepTwoSkeletalField1"
                                                />
                                                <Skeleton.Button
                                                    active
                                                    className="EstimationStepTwoSkeletalField2"
                                                />
                                                <Skeleton.Button
                                                    active
                                                    className="EstimationStepTwoSkeletalField3"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <EmptyState
                                    subTitle={
                                        'You do not have any energy solutions.'
                                    }
                                />
                            )}
                            <div style={{ marginBottom: '86px' }}></div>
                            <div className="pages">{paginationRequest()}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EstimationsStepTwo
