import { Drawer } from 'antd'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { DateField } from 'src/components/InputFields'
import { MultiSelectButtons, SelectButtons } from 'src/components/SelectButtons'
import ButtonComponent from 'src/components/Button'
import { format } from 'date-fns'
import {
    removeAllQueryParamsFromUrl,
    addQueryParamsToUrl,
} from 'src/utils/urlTracker'
import { resetAdminPaymentPlanFilters } from 'src/redux/reducers/admin/payment-plan/listing'
import { useDispatch } from 'react-redux'

const FilterDrawer = ({
    visible,
    onClose,
    filterState,
    setFilterState,
    setSearchTextInput,
    currentTab,
}) => {
    const dispatch = useDispatch()
    const showUpdatedSSEFlow =
        process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW === 'true'

    const [showCalendar, setShowCalendar] = useState(null)
    const [localInputs, setLocalInputs] = useState({
        start_date: null,
        end_date: null,
        provider_type: [],
        campaign_type: '',
        payment_plan_type: '',
        statement_type: [],
        onboarding_type: [],
        searchText: '',
    })
    const reset = {
        start_date: null,
        searchText: '',
        end_date: null,
        provider_type: [],
        campaign_type: '',
        payment_plan_type: '',
        statement_type: [],
        onboarding_type: [],
    }
    const [currentStartDate, setCurrentStartDate] = useState(null)
    const [currentEndDate, setCurrentEndDate] = useState(null)

    const toggleSelection = (filterType, value) => {
        if (localInputs[filterType]?.includes(value)) {
            setLocalInputs(prevLocalInputs => ({
                ...prevLocalInputs,
                [filterType]: prevLocalInputs[filterType].filter(
                    each => each !== value,
                ),
            }))
        } else {
            setLocalInputs(prevLocalInputs => ({
                ...prevLocalInputs,
                [filterType]: [...prevLocalInputs[filterType], value],
            }))
        }
    }

    const resetFilter = () => {
        setLocalInputs(reset)
        setSearchTextInput('')
        setFilterState(prevState => ({
            ...prevState,
            filterInputs: reset,
            currentPage: 1,
            searchText: '',
        }))
        dispatch(resetAdminPaymentPlanFilters())
        removeAllQueryParamsFromUrl()
        onClose()
    }

    const applyFilter = () => {
        setFilterState(prevState => ({
            ...prevState,
            filterInputs: localInputs,
            currentPage: 1,
        }))
        addQueryParamsToUrl({
            startDate: format(new Date(localInputs?.start_date), 'yyyy-MM-dd'),
            endDate: format(new Date(localInputs?.end_date), 'yyyy-MM-dd'),
            page: '1',
        })
        onClose()
    }

    const handleDateChange = (name, date) => {
        if (name === 'start_date') {
            setCurrentStartDate(format(date, 'MM/dd/yyyy'))
        }
        if (name === 'end_date') {
            setCurrentEndDate(format(date, 'MM/dd/yyyy'))
        }
    }

    const setHandleDateChange = name => {
        setLocalInputs(prev => ({
            ...prev,
            [name]: name === 'start_date' ? currentStartDate : currentEndDate,
        }))
    }

    useEffect(() => {
        setLocalInputs(filterState?.filterInputs)

        return () => {
            setLocalInputs()
        }
    }, [filterState?.filterInputs])

    return (
        <Drawer
            placement="right"
            visible={visible}
            onClose={() => onClose()}
            closable={false}
            className="PaymentPlansListings_Drawer"
            width={367}
        >
            <div className="PaymentPlansListings_Drawer_Content">
                <h2>Filter Payment Plans</h2>
                <h3>Filter by Date Created</h3>
                <div className="PaymentPlansListings_Drawer_DateFields">
                    <DateField
                        placeholder="Start Date"
                        name="start_date"
                        caretStyle="caretStyle"
                        dateValue={
                            localInputs?.start_date === null
                                ? null
                                : new Date(localInputs?.start_date)
                        }
                        handleDateChange={(name, date) =>
                            handleDateChange(name, date)
                        }
                        openCalendar={showCalendar === 1}
                        openCalendarHandler={() => setShowCalendar(1)}
                        closeCalendarHandler={() => setShowCalendar(null)}
                        setHandleDateChange={name => setHandleDateChange(name)}
                    />
                    <DateField
                        placeholder="End Date"
                        name="end_date"
                        caretStyle="caretStyle"
                        dateValue={
                            localInputs?.end_date === null
                                ? null
                                : new Date(localInputs?.end_date)
                        }
                        handleDateChange={(name, date) =>
                            handleDateChange(name, date)
                        }
                        openCalendar={showCalendar === 2}
                        openCalendarHandler={() => setShowCalendar(2)}
                        closeCalendarHandler={() => setShowCalendar(null)}
                        setHandleDateChange={name => setHandleDateChange(name)}
                        placePopperRight
                    />
                </div>
                <>
                    {currentTab !== '1' && (
                        <>
                            <h3>Filter by Provider Type</h3>
                            <MultiSelectButtons
                                values={['OEMI', 'DISI', 'ASI']}
                                selectedValues={localInputs?.provider_type}
                                handleChange={value =>
                                    toggleSelection('provider_type', value)
                                }
                                margin="0 0 38px 0"
                            />
                        </>
                    )}
                    <h3>Filter by Campaign</h3>
                    <SelectButtons
                        values={['Promo', 'Cluster']}
                        selectedValue={localInputs?.campaign_type}
                        handleChange={value =>
                            setLocalInputs(prev => ({
                                ...prev,
                                campaign_type: value,
                            }))
                        }
                        margin="0 0 38px 0"
                    />
                    <h3>Filter by Payment Plan Type</h3>
                    <SelectButtons
                        values={['Lease to Own', 'Subscription']}
                        selectedValue={localInputs?.payment_plan_type}
                        handleChange={value =>
                            setLocalInputs(prev => ({
                                ...prev,
                                payment_plan_type: value,
                            }))
                        }
                        margin="0 0 38px 0"
                    />
                    <h3>Filter by Statement Type</h3>
                    <MultiSelectButtons
                        values={['Mono', 'Okra', 'Manual']}
                        selectedValues={localInputs?.statement_type}
                        handleChange={value =>
                            toggleSelection('statement_type', value)
                        }
                        margin="0 0 38px 0"
                    />
                    {showUpdatedSSEFlow && (
                        <>
                            <h3>Filter by onboarding type</h3>
                            <MultiSelectButtons
                                values={[
                                    'Powercurve',
                                    'Profiles',
                                    'Systems',
                                    'Promo',
                                ]}
                                selectedValues={localInputs?.onboarding_type}
                                handleChange={value =>
                                    toggleSelection('onboarding_type', value)
                                }
                                margin="0 0 38px 0"
                                width="103px"
                                flexWrap="wrap"
                            />
                        </>
                    )}
                </>
            </div>
            <div className="PaymentPlansListings_Drawer_Buttons">
                <ButtonComponent
                    btnBgColor={'#E2EEFF'}
                    btnTextColor={'#004AAD'}
                    width={'160px'}
                    handleClick={() => resetFilter()}
                >
                    Reset Filter
                </ButtonComponent>
                <ButtonComponent
                    btnBgColor={'#004AAD'}
                    btnTextColor={'#FFFFFF'}
                    width={'160px'}
                    handleClick={() => applyFilter()}
                >
                    Apply Filter
                </ButtonComponent>
            </div>
        </Drawer>
    )
}

FilterDrawer.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    filterState: PropTypes.shape({
        filterInputs: PropTypes.shape({
            start_date: PropTypes.string,
            end_date: PropTypes.string,
            provider_type: PropTypes.arrayOf(PropTypes.string),
            campaign_type: PropTypes.arrayOf(PropTypes.string),
            payment_plan_type: PropTypes.arrayOf(PropTypes.string),
            statement_type: PropTypes.string,
        }).isRequired,
        currentPage: PropTypes.number.isRequired,
    }).isRequired,
    setFilterState: PropTypes.func.isRequired,
    setSearchTextInput: PropTypes.func.isRequired,
    currentTab: PropTypes.string,
}

export default FilterDrawer
