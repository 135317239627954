import PropTypes from 'prop-types'
import styles from './modalcontainer.module.scss'
import cancelIcon from 'src/assets/images/close-icon-grey.svg'

const ModalContainer = ({ isOpen, onClose, title, footer, children, ...rest }) => {

  return (
    <>
      {isOpen &&
        <div className={styles.modal_container} {...rest}>
          <div className={styles.modal_content}>
            <header className={styles.modal_header}>
              <h3>{title}</h3>
              <img onClick={onClose} src={cancelIcon} alt='cancel icon' className={styles.cancel_icon} />
            </header>
            {children}
            <div className={styles.modal_footer}>
              {footer}
            </div>
          </div>
        </div>}
    </>


  )
}

ModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  footer: PropTypes.node
}
export default ModalContainer;