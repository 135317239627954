import { useMutation } from 'react-query'
import { shortenLinkApi } from 'src/api/utils/shorten-link'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const useShortenLink = onSuccess => {
    const { errorAlert } = useCustomToast()

    const { mutate, isLoading } = useMutation(shortenLinkApi, {
        onSuccess: res => {
            const shortenedLink = res?.data?.data?.shortened_url
            onSuccess?.(shortenedLink)
        },
        onError: error => {
            errorAlert(errorHandler(error?.response?.data))
        },
    })

    const shortenLinkMutate = originalLink => {
        mutate({
            url: originalLink,
        })
    }

    return {
        shortenLinkMutate,
        shortenLinkLoading: isLoading,
    }
}

export default useShortenLink
