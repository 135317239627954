/* eslint-disable no-undef */
// prettier-ignore
const hotjar = function () {
    const environments = ['production', 'staging']
    if (environments.includes(process.env.REACT_APP_NODE_ENV)) {
        (function (h, o, t, j, a, r) {
            h.hj =
                h.hj ||
                function () {
                    (h.hj.q = h.hj.q || []).push(arguments)
                }
            h._hjSettings = {
                hjid: process.env.REACT_APP_HOTJAR_ID,
                hjsv: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION,
            }
            a = o.getElementsByTagName('head')[0]
            r = o.createElement('script')
            r.async = 1
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
            a.appendChild(r)
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    }
}
export default hotjar
