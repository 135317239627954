import { useState } from 'react'
import PropTypes from 'prop-types'
import Layout from '../_components/Layout/Layout'
import Description from '../_components/Description'
import BusinessForm from './BusinessForm/BusinessForm'
import ResidenceForm from './ResidenceForm'
import { usePersonalInformation } from './hook'
import { PersonalInfoContext } from './context'
import SeoComponent from 'src/components/Seo'

const PersonalInformation = ({ userType }) => {
    const {
        description,
        residentialInfoInputs,
        setResidentialInfoInputs,
        persistInfo,
        persistResidentialInfoLoading,
        errorPersonalInfo,
        refetchInfo,
        toastMessage,
        setToastMessage,
        setFieldErrors,
        modalType,
        setModalType,
        currentTab,
        setCurrentTab,
        activeTab,
        setActiveTab,
        personalInfoFormik,
        employmentInfoFormik,
        assistedKycResidenceUpdateLoading,
        residenceUpdateJustCompleted,
        residenceConsumerName,
        setResidenceUpdateJustCompleted,
        setResidenceConsumerName,
    } = usePersonalInformation({ userType })

    const [submitHandler, setSubmitHandler] = useState(null)
    const [saveHandler, setSaveHandler] = useState(null)

    return (
        <PersonalInfoContext.Provider
            value={{
                persistInfo,
                residentialInfoInputs,
                setResidentialInfoInputs,
                persistResidentialInfoLoading,
                errorPersonalInfo,
                refetchInfo,
                setFieldErrors,
                modalType,
                setModalType,
                setActiveTab,
                activeTab,
                personalInfoFormik,
                employmentInfoFormik,
                assistedKycResidenceUpdateLoading,
                residenceUpdateJustCompleted,
                residenceConsumerName,
                setResidenceUpdateJustCompleted,
                setResidenceConsumerName,
            }}
        >
            <SeoComponent
                title={`KYC - ${
                    userType === 'business'
                        ? 'Business Basic Information'
                        : 'Residential Basic Information'
                } | SunFi | Simplifying and Scaling Clean Energy`}
            />
            <Layout
                description={
                    <Description
                        title={description?.title}
                        subTitle={description?.subTitle}
                        step={userType === 'residential' ? 'basic-info' : 1}
                    />
                }
                step={userType === 'residential' ? 'basic-info' : 1}
                form={
                    userType === 'business' ? (
                        <BusinessForm
                            setSubmitHandler={setSubmitHandler}
                            setSaveHandler={setSaveHandler}
                        />
                    ) : (
                        <ResidenceForm
                            toastMessage={toastMessage}
                            setToastMessage={setToastMessage}
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                        />
                    )
                }
                userType={userType}
                submitHandler={submitHandler}
                saveHandler={saveHandler}
            />
        </PersonalInfoContext.Provider>
    )
}

PersonalInformation.propTypes = {
    userType: PropTypes.string.isRequired,
}
export default PersonalInformation
