import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import BackNav from 'src/components/BackNav'
import s from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/OutrightSaleInformation/components/Header/styles.module.scss'
import s2 from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/_components/Header/header.module.scss'
import { ConsumerButton } from 'src/components/Button'
import { ReactComponent as CircleWhiteIcon } from 'src/assets/images/circle-right-white.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import { ReactComponent as SaveIcon } from 'src/assets/images/saveIcon.svg'
import { decodeUserInfo } from 'src/utils/auth'

const Header = ({
    userType,
    submitLoading = false,
    handleSaveInfo,
    handleSubmitInfo,
    isCustomerApproved,
    pageLoading,
}) => {
    const userInfo = decodeUserInfo()
    const history = useHistory()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const submitBtnLoading = submitLoading

    return (
        <>
            {isAdminUser ? (
                <header
                    className={s2.adminNav}
                    data-testid={'dynamic-form-header'}
                >
                    <div>
                        <h4>
                            Customer&apos;s{' '}
                            {userType === 'RESIDENTIAL'
                                ? 'personal'
                                : 'business'}{' '}
                            information
                        </h4>
                        <p>Consumer submitted data</p>
                    </div>
                    <ConsumerButton
                        btnBgColor={'#004AAD'}
                        btnWidth={'117px'}
                        btnHeight={'50px'}
                        btnTextColor={'#FFFFFF'}
                        handleClick={() => history.goBack()}
                        dataTestId={'header-go-back-button'}
                    >
                        Go Back
                    </ConsumerButton>
                </header>
            ) : (
                <header
                    className={s.consumerHeader}
                    data-testid={'dynamic-form-header'}
                >
                    <BackNav
                        title="Go back"
                        v2
                        onClick={() =>
                            history.push('/consumer/account-setup/overview')
                        }
                    />

                    {!isCustomerApproved && !pageLoading && (
                        <div className={s.right}>
                            <CustomToolTip
                                text="Save and continue later"
                                showTooltip="true"
                                toolTipPopupWidth="170px"
                                toolTipMarginLeft="-67px"
                                arrowPositionCenter
                            >
                                <SaveIcon
                                    role="button"
                                    onClick={() => {
                                        handleSaveInfo()
                                    }}
                                    data-testid="dynamic-form-header-save-info-button"
                                />
                            </CustomToolTip>
                            <div className={s.divider}></div>
                            <ConsumerButton
                                btnWidth={'102px'}
                                btnHeight={'50px'}
                                btnBgColor={'#004AAD'}
                                btnTextColor={'#FFFFFF'}
                                hoverNoFill
                                type={submitBtnLoading ? undefined : 'iconBtn'}
                                handleClick={() => {
                                    handleSubmitInfo()
                                }}
                                loading={submitBtnLoading}
                                dataTestId={'dynamic-form-header-submit-button'}
                            >
                                Next
                                <CircleWhiteIcon />
                            </ConsumerButton>
                        </div>
                    )}
                </header>
            )}
        </>
    )
}

Header.propTypes = {
    userType: PropTypes.oneOf(['RESIDENTIAL', 'BUSINESS', '']),
    handleSaveInfo: PropTypes.func,
    handleSubmitInfo: PropTypes.func,
    submitLoading: PropTypes.bool,
    isCustomerApproved: PropTypes.bool,
    pageLoading: PropTypes.bool,
}

export default Header
