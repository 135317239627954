import PropTypes from 'prop-types'
import styles from './backnav.module.scss'
import { ReactComponent as Arrow } from 'src/assets/images/blue-circle-left.svg'

const BackNav = ({
    title,
    url,
    onClick,
    onChange,
    marginTop,
    marginBottom,
    marginLeft,
    closeIcon,
    isHelpMode = true,
    top,
    v2 = false,
    width,
    dataTestId = undefined,
}) => {
    const {
        BackNavLink,
        BackNavIcon,
        BackNavWrapper,
        BackNavCloseIcon,
        BackLink,
        v2Style,
    } = styles
    return (
        <div
            className={`${closeIcon ? BackNavWrapper : ''}`}
            data-testid={dataTestId}
        >
            <div
                className={`${!isHelpMode ? `link` : BackLink} ${v2Style}`}
                style={{
                    position: 'relative',
                    top: top,
                    marginTop: marginTop,
                    marginBottom: marginBottom,
                    marginLeft: marginLeft,
                    width: width,
                }}
                onClick={onClick}
            >
                {v2 ? (
                    <Arrow />
                ) : (
                    <div className={BackNavIcon}>
                        <svg
                            width="8"
                            height="12"
                            viewBox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.02464 6.0006L7.14964 10.1256L5.9713 11.3039L0.667969 6.0006L5.9713 0.697266L7.14964 1.8756L3.02464 6.0006Z"
                                fill="#004AAD"
                            />
                        </svg>
                    </div>
                )}

                <a href={url} className={BackNavLink} onChange={onChange}>
                    {title}
                </a>
            </div>

            {closeIcon && (
                <div className={BackNavCloseIcon}>
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={onClick}
                        style={{ cursor: 'pointer' }}
                    >
                        <path
                            d="M7.49934 5.9086L13.0681 0.339844L14.6588 1.93059L9.09009 7.49935L14.6588 13.0681L13.0681 14.6588L7.49934 9.0901L1.93059 14.6588L0.339844 13.0681L5.90859 7.49935L0.339844 1.93059L1.93059 0.339844L7.49934 5.9086Z"
                            fill="#233862"
                        />
                    </svg>
                </div>
            )}
        </div>
    )
}

BackNav.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    closeIcon: PropTypes.any,
    isHelpMode: PropTypes.bool,
    top: PropTypes.string,
    v2: PropTypes.bool,
    width: PropTypes.string,
    dataTestId: PropTypes.string,
}

export default BackNav
