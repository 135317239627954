import axios from 'src/config/axios'
const isAdmin = window.location.href.includes('/admin')

export const getProviderUsersApi = async (
    page,
    searchText = '',
    startDate = '',
    endDate = '',
    state = '',
) => {
    const request = await axios.get(
        `/providers/users?page=${page}&name=${searchText}&start_date=${startDate}&end_date=${endDate}&state=${state}&has_accepted_invite=true`,
    )
    return request
}

export const getProviderGroupsApi = async (
    page,
    searchText = '',
    startDate = '',
    endDate = '',
    state = '',
    workspace = 'provider',
) => {
    const request = await axios.get(
        `/provider/roles?page=${page}&name=${searchText}&start_date=${startDate}&end_date=${endDate}&state=${state}&workspace=${workspace}`,
    )
    return request
}

export const getActiveProviderGroupsApi = async () => {
    const url = isAdmin
        ? `admin/provider/roles?state=active&paginate=false`
        : `provider/roles?state=active&paginate=false`

    const request = await axios.get(url)
    return request
}

export const inviteUsersFromProviderWorkspaceApi = async provider_users => {
    const request = await axios.post(`providers/invite-users`, {
        provider_users: provider_users,
    })
    return request
}

export const getParentProviderWorkspaceResourcesApi = async () => {
    const request = axios(`providers/resources?paginate=false`)
    return request
}

export const getChildProviderWorkspaceResourcesApi = async parent_id => {
    const request = axios(
        `providers/resources?parent_id=${parent_id}&paginate=false`,
    )
    return request
}

export const getPermissionsUnderResourceProviderWorkspaceApi =
    async resource_id => {
        const request = axios(
            `providers/permissions?resource_id=${resource_id}`,
        )
        return request
    }

export const addNewProviderWorkspaceApi = async data => {
    const request = await axios.post(`/provider/roles`, data)
    return request
}

export const updateProviderUserDetailsApi = async (userId, data) => {
    const request = await axios.patch(`providers/users/${userId}`, data)
    return request
}

export const getSingleProviderUserApi = async (userId, providerId) => {
    const url = isAdmin
        ? `admin/provider/${providerId}/users/${userId}`
        : `providers/users/${userId}`

    const request = await axios.get(url)
    return request
}

export const getProviderUserActivitiesApi = async (userId, providerId) => {
    const url = isAdmin
        ? `admin/provider/${providerId}/users/${userId}/activities`
        : `providers/users/${userId}/activities`

    const request = await axios.get(url)
    return request
}

export const deactivateProviderUserApi = async (userId, obj) => {
    const request = await axios.post(
        `providers/users/${userId}/deactivate`,
        obj,
    )
    return request
}

export const reactivateProviderUserApi = async (userId, obj) => {
    const request = await axios.post(`providers/users/${userId}/activate`, obj)
    return request
}
