import { Skeleton } from 'antd'
import PropTypes from 'prop-types'
import './skeletons.scss'

const FormSkeleton = ({ style, type = 'default' }) => {
    const fieldCount = type === 'includesAvatar' ? 1 : 2
    return (
        <div
            className={`CSSECreateAccount_FormSkeleton ${
                type === 'includesAvatar' ? 'includesAvatar' : ''
            }`}
            style={style}
        >
            {type === 'includesAvatar' && (
                <div className="CSSECreateAccount_FormSkeleton_Field">
                    <Skeleton active paragraph={{ rows: 1 }} />
                    <Skeleton.Image active />
                </div>
            )}
            {[...Array(fieldCount)].map((_, i) => (
                <div key={i} className="CSSECreateAccount_FormSkeleton_Field">
                    <Skeleton active paragraph={{ rows: 2 }} />
                    <Skeleton.Input active />
                </div>
            ))}
        </div>
    )
}

FormSkeleton.propTypes = {
    style: PropTypes.object,
    type: PropTypes.oneOf(['default', 'includesAvatar']),
}

export default FormSkeleton
