import { createSlice } from '@reduxjs/toolkit'
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'

const initialState = {
    published: false,
    is_promotional: false,
    name: '',
    description: '',
    customer_type: '',
    min_capacity: '',
    max_capacity: '',
    appliances: [],
    packages: [],
    subscription_cluster_code: '',
    lease_to_own_cluster_code: '',
    image_url: '',
    sort_by: 'ENERGY_CAPACITY_ASCENDING',
    group_id: '',
    credit_scorecard_id: '',
    cluster_origination_setup: {
        name: '',
        website: '',
        logo: '',
    },
    representatives: [],
    notify_representatives: false,
    modules: [],
}

const createConsumerProfile = createSlice({
    name: 'createConsumerProfile',
    initialState,
    reducers: {
        updateConsumerProfile(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetConsumerProfile(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateConsumerProfile, resetConsumerProfile } =
    createConsumerProfile.actions

export const updatedConsumerProfile = state => state.createconsumerProfileData

const persistConfig = {
    key: 'createConsumerProfile',
    storage: storageSession,
}

export const persistedConsumerProfileReducer = persistReducer(
    persistConfig,
    createConsumerProfile.reducer,
)

export default createConsumerProfile.reducer
