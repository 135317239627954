/* eslint-disable react/prop-types */
import { useState } from 'react'
import { Select } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import LockIcon from './../../../assets/images/lock.svg'
import { InlineLoaderDark } from '../../Loader'
import './combinedselectandinputfield.scss'
import { SelectCheckbox } from 'src/components/Checkbox'

export const CombinedSelectAndInputField = ({
    title,
    handleChange,
    name,
    inputValue,
    defaultValue,
    errorMessage = '',
    inputWidth,
    disabled,
    fullWidth,
    prefilled,
    selectWidth,
    dropDown,
    inputStyle = {},
    handleSelect,
    onBlur,
    onMouseOut,
    loadingIcon,
    marginBottom,
    noMarginBottom,
    sseUse = false,
    inputType,
    values = [],
    withCheckBox,
    selectedField = 'value',
    selectedValue,
    dropdownPositionRelative,
}) => {
    const [toggleStyle, setToggleStyle] = useState(false)
    const { Option } = Select
    
    return (
        <div onMouseOut={onMouseOut && onMouseOut}>
            <div
                className={`CSIInputWrapper`}
                onClick={() => setToggleStyle(true)}
                onBlur={() => setToggleStyle(false)}
                style={{
                    border:
                        errorMessage !== ''
                            ? '1px solid #DA1E28'
                            : toggleStyle &&
                              !disabled &&
                              '1px solid var(--blue)',
                    marginBottom: marginBottom
                        ? marginBottom
                        : (errorMessage || noMarginBottom) && '0px',
                    width: inputWidth ? inputWidth : '100%',
                }}
            >
                <Select
                    value={defaultValue || selectedValue}
                    style={{
                        width: selectWidth ? selectWidth : '150px',
                        background: '#fff !important',
                        position: 'relative',
                        pointerEvents: !dropDown || defaultValue ? 'none' : '',
                    }}
                    onChange={e => {
                        handleSelect(e)
                    }}
                    dropdownStyle={{
                        position: `${
                            dropdownPositionRelative ? 'relative' : 'fixed'
                        }`,
                        borderRadius: '15px',
                        minWidth: '150px',
                        width: '100%',
                        padding: '20px 16px 8px 16px',
                    }}
                    className="SelectCountry"
                    suffixIcon={dropDown ? <CaretDownOutlined /> : <></>}
                    disabled={disabled ? true : !dropDown ? true : false}
                >
                    {withCheckBox
                        ? values.map((option, index) => {
                              return (
                                  <>
                                      <Option
                                          value={option[selectedField]}
                                          key={`option-${index}`}
                                          disabled={option.disabled}
                                          style={{ paddingBottom: 20 }}
                                      >
                                          <div className="CountryCodeBox">
                                              <SelectCheckbox
                                                  isChecked={
                                                      option[selectedField] ===
                                                      selectedValue
                                                          ? true
                                                          : false
                                                  }
                                                  id={`select-${index}`}
                                              >
                                                  {option.label}
                                              </SelectCheckbox>
                                          </div>
                                      </Option>
                                  </>
                              )
                          })
                        : values.map((option, index) => {
                              return (
                                  <Option
                                      value={option.value}
                                      key={`option-${index}`}
                                  >
                                      {option.value}
                                  </Option>
                              )
                          })}
                </Select>
                <input
                    type={inputType || 'text'}
                    name={name}
                    value={inputValue}
                    className="CSIDropdownInput"
                    onChange={handleChange}
                    onBlur={onBlur && onBlur}
                    required
                    style={{
                        width: fullWidth ? '400px' : '',
                        ...inputStyle,
                    }}
                    disabled={disabled ? true : false}
                />
                {prefilled && (
                    <img
                        src={LockIcon}
                        style={{
                            color: 'var(--blue-2)',
                            cursor: 'not-allowed',
                            position: dropDown ? 'relative' : '',
                            right: dropDown ? '11.5px' : '',
                        }}
                    />
                )}
                <span className={`FloatingInput SCC ${sseUse && 'sseLabel'}`}>
                    {title}
                </span>
                {loadingIcon && (
                    <i
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            position: 'absolute',
                            right: '10px',
                        }}
                    >
                        <InlineLoaderDark sseUse={sseUse} />
                        <InlineLoaderDark sseUse={sseUse} />
                        <InlineLoaderDark sseUse={sseUse} />
                    </i>
                )}
            </div>
            {errorMessage !== '' && <p className="ErrorText">{errorMessage}</p>}
        </div>
    )
}
