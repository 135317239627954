import PropTypes from 'prop-types'
import { InputFields, SelectField } from 'src/components/InputFields'
import Label from '../../../PersonalInformation/_components/Label'
import s from './styles.module.scss'
import { decodeUserInfo } from 'src/utils/auth'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/ResidenceForm/residenceform.scss'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { localGovtData, stateData } from 'src/utils/stateData'
import { SelectButtons } from 'src/components/SelectButtons'
import { countries } from '../../utils'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import { businessIndustry, businessType } from 'src/utils/business'
import { InlineLoader } from 'src/components/Loader'

const Form = ({
    inputs,
    errors,
    handleOnBlur,
    handleChange,
    handleSelectChange,
    handleSelectOnBlur,
    sameAddressOptions,
    handleSameAddressOptionChange,
    userType,
    initiateContactVerification,
    initiateEmailVerificationLoading,
    initiatePhoneVerificationLoading,
    hasPaidOutrightly,
}) => {
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const { isMobile } = useMediaQueries()

    return (
        <div className={s.form}>
            {userType === 'BUSINESS' && (
                <>
                    <Label
                        title="Business Information"
                        subTitle="Provide us with your business name as it was registered"
                    />
                    <InputFields
                        name="businessName"
                        title="Business Name"
                        value={inputs?.businessName}
                        handleChange={handleChange}
                        disabled={isAdminUser || hasPaidOutrightly}
                        onBlur={handleOnBlur}
                        errorMessage={errors?.businessName}
                    />
                    <SelectField
                        floatLabelOnSelectedValue
                        name="businessType"
                        floatingLabel="Business Type"
                        initialOption="Business Type"
                        selectWidth="100%"
                        handleChange={handleSelectChange}
                        onBlur={() => handleSelectOnBlur('businessType')}
                        values={businessType.map(option => ({
                            value: option,
                        }))}
                        value={inputs?.businessType}
                        errorMessage={errors?.businessType}
                        showSearch
                        dropdownPositionRelative
                        disabled={isAdminUser || hasPaidOutrightly}
                        withCheckBox
                        currentSelected={inputs?.businessType}
                        selectedValue={inputs?.businessType}
                        marginBottom={16}
                    />
                    <SelectField
                        floatLabelOnSelectedValue
                        name="businessIndustry"
                        floatingLabel="Business Industry"
                        initialOption="Business Industry"
                        selectWidth="100%"
                        handleChange={handleSelectChange}
                        onBlur={() => handleSelectOnBlur('businessIndustry')}
                        values={businessIndustry.map(option => ({
                            value: option,
                        }))}
                        value={inputs?.businessIndustry}
                        errorMessage={errors?.businessIndustry}
                        showSearch
                        dropdownPositionRelative
                        disabled={isAdminUser || hasPaidOutrightly}
                        withCheckBox
                        currentSelected={inputs?.businessIndustry}
                        selectedValue={inputs?.businessIndustry}
                    />
                </>
            )}
            {userType === 'BUSINESS' && <VerticalSpacer top={32} />}
            <Label
                title={
                    userType === 'RESIDENTIAL'
                        ? "What's your name?"
                        : 'Contact person details'
                }
                subTitle={
                    userType === 'RESIDENTIAL'
                        ? 'Please provide your first and last name as it appears on your ID card.'
                        : 'Share a contact person to represent the business'
                }
            />
            <InputFields
                name="firstName"
                title="First Name"
                value={inputs?.firstName}
                handleChange={handleChange}
                disabled={isAdminUser || hasPaidOutrightly}
                onBlur={handleOnBlur}
                errorMessage={errors?.firstName}
            />
            <InputFields
                name="lastName"
                title="Surname/Last Name"
                value={inputs?.lastName}
                handleChange={handleChange}
                disabled={isAdminUser || hasPaidOutrightly}
                onBlur={handleOnBlur}
                errorMessage={errors?.lastName}
            />
            {userType === 'RESIDENTIAL' && (
                <Label
                    title="Your phone number and email"
                    subTitle="Provide us with your phone number and confirm your email address."
                    showHelpIcon
                    tooltipText={
                        'Type in your phone number for verification, and confirm your email address.'
                    }
                />
            )}
            <div className="VerifyInputWrapper OSIFormPhoneWrapper">
                <SelectCountryCode
                    name="phone"
                    title="Phone Number"
                    inputType={'tel'}
                    inputValue={inputs?.phone}
                    handleChange={handleChange}
                    onBlur={handleOnBlur}
                    disabled={
                        isAdminUser ||
                        inputs?.phoneVerified ||
                        hasPaidOutrightly
                    }
                    selectWidth="114px"
                    inputStyle={{ width: '100%' }}
                    errorMessage={errors?.phone || errors?.phoneVerified}
                    marginBottom={isAdminUser || inputs?.phoneVerified ? 16 : 8}
                    verified={inputs?.phoneVerified}
                />
                {!isAdminUser && !inputs?.phoneVerified && (
                    <div className="VerifyCTA">
                        <button
                            style={{
                                width: initiatePhoneVerificationLoading
                                    ? '84px'
                                    : '',
                            }}
                            onClick={() => initiateContactVerification('phone')}
                        >
                            {initiatePhoneVerificationLoading ? (
                                <InlineLoader color="primary" />
                            ) : isMobile ? (
                                'Verify'
                            ) : (
                                'Verify phone number'
                            )}
                        </button>
                    </div>
                )}
            </div>
            {userType === 'BUSINESS' && (
                <Label
                    title="Business email address"
                    subTitle="Provide us with your business email address."
                    showHelpIcon
                    tooltipText={'Confirm your email address.'}
                />
            )}
            <div className="VerifyInputWrapper">
                <InputFields
                    name="email"
                    title="Your email address"
                    value={inputs?.email}
                    handleChange={handleChange}
                    onBlur={handleOnBlur}
                    disabled={
                        isAdminUser ||
                        inputs?.emailVerified ||
                        hasPaidOutrightly
                    }
                    marginBottom={
                        isAdminUser || inputs?.emailVerified ? '16px' : '8px'
                    }
                    errorMessage={errors?.email || errors?.emailVerified}
                    inputWidth={'100%'}
                    verified={inputs?.emailVerified}
                />
                {!isAdminUser && !inputs?.emailVerified && (
                    <div className="VerifyCTA">
                        <button
                            style={{ width: '84px' }}
                            onClick={() => initiateContactVerification('email')}
                        >
                            {initiateEmailVerificationLoading ? (
                                <InlineLoader color="primary" />
                            ) : isMobile ? (
                                'Verify'
                            ) : (
                                'Verify email'
                            )}
                        </button>
                    </div>
                )}
            </div>
            <Label
                title={`Your ${
                    userType === 'BUSINESS' ? 'business ' : ''
                }contact address`}
                subTitle="Please provide your contact address. This does not have to be the same 
                as your installation address."
            />
            <InputFields
                name="contactAddress"
                title="House no. and street address"
                handleChange={handleChange}
                onBlur={handleOnBlur}
                value={inputs?.contactAddress}
                errorMessage={errors?.contactAddress}
                disabled={isAdminUser || hasPaidOutrightly}
            />
            <div className="PersonalInfo_DualSelectField-Wrapper">
                <SelectField
                    floatLabelOnSelectedValue
                    name="contactCountry"
                    floatingLabel="Country"
                    initialOption="Country"
                    selectWidth="100%"
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactCountry')}
                    values={countries.map(option => ({
                        value: option,
                    }))}
                    value={inputs?.contactCountry}
                    errorMessage={errors?.contactCountry}
                    showSearch
                    dropdownPositionRelative
                    disabled={isAdminUser || hasPaidOutrightly}
                    withCheckBox
                    currentSelected={inputs?.contactCountry}
                    selectedValue={inputs?.contactCountry}
                />
                <SelectField
                    floatLabelOnSelectedValue
                    name="contactState"
                    floatingLabel="State"
                    initialOption="State"
                    selectWidth="100%"
                    values={stateData?.map(option => ({
                        value: option,
                    }))}
                    value={inputs?.contactState}
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactState')}
                    errorMessage={errors?.contactState}
                    showSearch
                    dropdownPositionRelative
                    disabled={isAdminUser || hasPaidOutrightly}
                    withCheckBox
                    currentSelected={inputs?.contactState}
                    selectedValue={inputs?.contactState}
                />
                <SelectField
                    floatLabelOnSelectedValue
                    name="contactCity"
                    floatingLabel="L.G.A."
                    initialOption="L.G.A."
                    selectWidth="100%"
                    values={localGovtData[inputs?.contactState || 'Lagos']?.map(
                        option => ({
                            value: option,
                        }),
                    )}
                    disabled={
                        isAdminUser ||
                        inputs?.contactState?.length < 1 ||
                        hasPaidOutrightly
                    }
                    value={inputs?.contactCity}
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactCity')}
                    errorMessage={errors?.contactCity}
                    showSearch
                    dropdownPositionRelative
                    withCheckBox
                    currentSelected={inputs?.contactCity}
                    selectedValue={inputs?.contactCity}
                />
            </div>
            <div className="ContactAddress_Confirmation-Wrapper">
                <p>
                    Is your contact address the same as where you want to
                    install the system?
                </p>
                <SelectButtons
                    selectedValue={inputs?.sameAddress?.title}
                    values={sameAddressOptions?.map(o => o?.title)}
                    handleChange={value => handleSameAddressOptionChange(value)}
                    width={163}
                    height={46}
                    disabled={isAdminUser || hasPaidOutrightly}
                />
                {errors?.confirmAddress && (
                    <span className="fieldError">{errors?.confirmAddress}</span>
                )}
            </div>
            <VerticalSpacer top={16} />
            {!inputs?.sameAddress?.value && (
                <>
                    <Label
                        title="Your installation address"
                        subTitle="Please provide us with where you want to have the inverter installed."
                    />
                    <InputFields
                        name="installationAddress"
                        title="House no. and street address"
                        handleChange={handleChange}
                        onBlur={handleOnBlur}
                        value={inputs?.installationAddress}
                        errorMessage={errors?.installationAddress}
                        disabled={isAdminUser || hasPaidOutrightly}
                    />
                    <div className="PersonalInfo_DualSelectField-Wrapper">
                        <SelectField
                            floatLabelOnSelectedValue
                            name="installationCountry"
                            floatingLabel="Country"
                            initialOption="Country"
                            selectWidth="100%"
                            handleChange={handleSelectChange}
                            onBlur={() =>
                                handleSelectOnBlur('installationCountry')
                            }
                            values={countries.map(option => ({
                                value: option,
                            }))}
                            value={inputs?.installationCountry}
                            errorMessage={errors?.installationCountry}
                            showSearch
                            dropdownPositionRelative
                            disabled={isAdminUser || hasPaidOutrightly}
                            withCheckBox
                            currentSelected={inputs?.installationCountry}
                            selectedValue={inputs?.installationCountry}
                        />
                        <SelectField
                            floatLabelOnSelectedValue
                            name="installationState"
                            floatingLabel="State"
                            initialOption="State"
                            selectWidth="100%"
                            values={stateData?.map(option => ({
                                value: option,
                            }))}
                            value={inputs?.installationState}
                            handleChange={handleSelectChange}
                            onBlur={() =>
                                handleSelectOnBlur('installationState')
                            }
                            errorMessage={errors?.installationState}
                            showSearch
                            dropdownPositionRelative
                            disabled={isAdminUser || hasPaidOutrightly}
                            withCheckBox
                            currentSelected={inputs?.installationState}
                            selectedValue={inputs?.installationState}
                        />
                        <SelectField
                            floatLabelOnSelectedValue
                            name="installationCity"
                            floatingLabel="L.G.A."
                            initialOption="L.G.A."
                            selectWidth="100%"
                            values={localGovtData[
                                inputs?.installationState || 'Lagos'
                            ]?.map(option => ({
                                value: option,
                            }))}
                            disabled={
                                isAdminUser ||
                                inputs?.installationState?.length < 1 ||
                                hasPaidOutrightly
                            }
                            value={inputs?.installationCity}
                            handleChange={handleSelectChange}
                            onBlur={() =>
                                handleSelectOnBlur('installationCity')
                            }
                            errorMessage={errors?.installationCity}
                            showSearch
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={inputs?.installationCity}
                            selectedValue={inputs?.installationCity}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

Form.propTypes = {
    inputs: PropTypes.object,
    errors: PropTypes.object,
    handleOnBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSelectChange: PropTypes.func,
    handleSelectOnBlur: PropTypes.func,
    sameAddressOptions: PropTypes.array,
    handleSameAddressOptionChange: PropTypes.func,
    userType: PropTypes.oneOf(['RESIDENTIAL', 'BUSINESS', '']),
    initiateContactVerification: PropTypes.func,
    initiateEmailVerificationLoading: PropTypes.bool,
    initiatePhoneVerificationLoading: PropTypes.bool,
    hasPaidOutrightly: PropTypes.bool,
}

export default Form
