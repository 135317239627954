// source: https://dirask.com/posts/JavaScript-remove-empty-values-from-object-D6MRm1
// author: https://dirask.com/users/Palpys-7ad4pa#mode=view
// modified by: https://github.com/temitopetoluwalase

export const removeEmptyValues = object => {
    const keys = Object.keys(object)
    for (let i = 0; i < keys.length; ++i) {
        const key = keys[i]
        const value = object[key]

        if (
            value === null ||
            value === undefined ||
            value === '' ||
            (typeof value === 'object' &&
                !Array.isArray(value) &&
                Object.keys(value).length === 0)
        ) {
            delete object[key]
        }
    }
    return object
}

export const removeEmptyObjects = object => {
    const keys = Object.keys(object)
    for (let i = 0; i < keys.length; ++i) {
        const key = keys[i]
        const value = object[key]
        if (value === null || value === undefined || value === '') {
            delete object[key]
        }
    }
    return object
}

export const removeEmptyObjectsV2 = object => {
    const keys = Object.keys(object)
    for (let i = 0; i < keys.length; ++i) {
        const key = keys[i]
        const value = object[key]
        if (
            value === null ||
            value === undefined ||
            value === '' ||
            (typeof value === 'object' && !Object.keys(value).length) ||
            (typeof value === 'string' && value.includes('undefined'))
        ) {
            delete object[key]
        }
    }
    return object
}

export const isObjectEmpty = obj => {
    return obj && typeof obj === 'object' && Object.keys(obj).length === 0
}

export const isNonEmptyString = value =>
    typeof value === 'string' && value.length > 0
