import { useState, useEffect } from 'react'
import { ProductHeader } from '../ProductHeader'
import Button from '../../Button'
import { InlineLoader } from '../../Loader'
import SeoComponent from '../../Seo'
import PropTypes from 'prop-types'
import plusBlueIcon from '../../../assets/images/Plus-Blue.svg'
import BlueCloseIcon from '../../../assets/images/blue-close-icon.svg'
import './addprotectivedevice.scss'
import { InputFields, SelectField } from '../../InputFields'
import DescriptionComponent from 'src/components/DescriptionComponent/DescriptionComponent'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { v4 as uuidv4 } from 'uuid'
import {
    filteredProtectiveDevice,
    unitOfMeasurement,
    protectiveDeviceDescription,
} from './data'
import { CombinedSelectAndInputField } from 'src/components/InputFields/CombinedSelectAndInputField'
import { PackageProtectiveDeviceValidationSchema } from 'src/utils/validationSchema'

const AddProtectiveDevice = ({
    handleStage,
    handleSavePackage,
    saveLoading,
    createProtectiveDevice,
    setCreateProtectiveDevice,
}) => {
    const [errors, setErrors] = useState({})

    // app tracker variables
    const pageTitle = 'SunFi - Add A Package | Add Protective Device'
    const pageUrlName = window.location.pathname
    const pageTracker = 'ADD_PACKAGE_TRACKER'
    const addPDRow = () => {
        setCreateProtectiveDevice(prev => [
            ...prev,
            {
                id: uuidv4(),
                name: '',
                category: '',
                rating: '',
                quantity: '',
                unit_of_measurement: 'Amps (A)',
            },
        ])
    }

    useEffect(() => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['addPackageProtectiveDevice'].action,
            eventTrackers['addPackageProtectiveDevice'].label,
            eventTrackers['addPackageProtectiveDevice'].category,
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const removeRow = id => {
        const filteredDevice = createProtectiveDevice.filter(
            device => device.id !== id,
        )
        setCreateProtectiveDevice(filteredDevice)
    }

    const saveUserPackage = () => {
        PackageProtectiveDeviceValidationSchema.validate(
            { inputs: createProtectiveDevice },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                handleSavePackage()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    return (
        <>
            <SeoComponent
                title="SunFi - Add A Package | Add Protective Device"
                tracker="AddPackage"
            />
            <div className="ComponentWrapper">
                <div className="AddComponentRow">
                    <div className="LeftComponent">
                        <ProductHeader
                            title="Create New Product Package"
                            subtitle="Add Protective Device"
                        />
                        <div className="PDParentWrapper">
                            {createProtectiveDevice.map((item, i) => (
                                <div className="PDWrapper" key={item.id}>
                                    <div>
                                        <SelectField
                                            initialOption={'Add Description'}
                                            floatingLabel={'Add Description'}
                                            floatLabelOnSelectedValue
                                            selectWidth="224px"
                                            values={protectiveDeviceDescription?.map(
                                                option => ({
                                                    value: option,
                                                }),
                                            )}
                                            name="description"
                                            showSearch
                                            withCheckBox={true}
                                            handleChange={(name, value) => {
                                                item.category = value
                                                item.name = ''
                                                item.rating = ['Overall Protection'].includes(
                                                    item.category,
                                                ) ? 'NA' :''
                                                setCreateProtectiveDevice([
                                                    ...createProtectiveDevice,
                                                ])
                                            }}
                                            dropdownPositionRelative
                                            errorMessage={
                                                errors[`inputs[${i}].category`]
                                            }
                                            currentSelected={item.category}
                                            value={item.category}
                                            marginBottom="10px"
                                        />
                                        <SelectField
                                            initialOption={
                                                'Add protective device'
                                            }
                                            floatingLabel={
                                                'Add protective device'
                                            }
                                            floatLabelOnSelectedValue
                                            selectWidth="224px"
                                            values={filteredProtectiveDevice(
                                                item.category,
                                            )?.map(option => ({
                                                value: option,
                                            }))}
                                            disabled={item.category.length < 1}
                                            name="protective_device"
                                            showSearch
                                            withCheckBox={true}
                                            handleChange={(name, value) => {
                                                item.name = value
                                                item.rating = ['Overall Protection'].includes(
                                                    item.category,
                                                ) ? 'NA' :''
                                                setCreateProtectiveDevice([
                                                    ...createProtectiveDevice,
                                                ])
                                            }}
                                            dropdownPositionRelative
                                            errorMessage={
                                                errors[`inputs[${i}].name`]
                                            }
                                            currentSelected={item.name}
                                            value={item.name}
                                            marginBottom="10px"
                                        />
                                    </div>
                                    <div>
                                        {!['Overall Protection'].includes(
                                            item.category,
                                        ) && (
                                            <CombinedSelectAndInputField
                                                title={'Enter Rating'}
                                                selectedValue={
                                                    item.unit_of_measurement
                                                }
                                                values={unitOfMeasurement.map(
                                                    val => {
                                                        return {
                                                            label: val,
                                                            value: val,
                                                        }
                                                    },
                                                )}
                                                name="rating"
                                                inputType="number"
                                                dropDown
                                                inputWidth={'224px'}
                                                dropdownPositionRelative
                                                withCheckBox={true}
                                                handleSelect={data => {
                                                    item.unit_of_measurement =
                                                        data
                                                    setCreateProtectiveDevice([
                                                        ...createProtectiveDevice,
                                                    ])
                                                }}
                                                handleChange={e => {
                                                    item.rating = e.target.value
                                                    setCreateProtectiveDevice([
                                                        ...createProtectiveDevice,
                                                    ])
                                                }}
                                                errorMessage={
                                                    errors[
                                                        `inputs[${i}].unit_of_measurement`
                                                    ] ||
                                                    errors[
                                                        `inputs[${i}].rating`
                                                    ]
                                                }
                                                value={item.rating}
                                                marginBottom="10px"
                                            />
                                        )}
                                        <InputFields
                                            title={'Add quantity'}
                                            inputWidth="224px"
                                            type="number"
                                            name="quantity"
                                            handleChange={e => {
                                                item.quantity = e.target.value
                                                setCreateProtectiveDevice([
                                                    ...createProtectiveDevice,
                                                ])
                                            }}
                                            errorMessage={
                                                errors[`inputs[${i}].quantity`]
                                            }
                                            value={item.quantity}
                                            marginBottom="10px"
                                        />
                                    </div>

                                    {createProtectiveDevice.length > 1 && (
                                        <div
                                            className="PDRemoveDeviceWrapper"
                                            onClick={() => {
                                                removeRow(item?.id)
                                            }}
                                        >
                                            <img
                                                src={BlueCloseIcon}
                                                alt="close icon"
                                            />
                                            <p>Remove device</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                            className="AddBtn"
                        >
                            <Button
                                btnBgColor="var(--purple-light)"
                                btnTextColor="var(--blue)"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                btnWidth="141px"
                                handleClick={addPDRow}
                            >
                                <img
                                    src={plusBlueIcon}
                                    alt="plus-icon"
                                    style={{
                                        marginRight: '12.75px',
                                    }}
                                />
                                Add New
                            </Button>
                        </div>
                        <div className="ComponentBtnWrapper">
                            <div style={{ marginRight: '15px' }}>
                                <Button
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="132px"
                                    handleClick={() => {
                                        handleStage('capex')
                                    }}
                                >
                                    Previous
                                </Button>
                            </div>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                btnPadding="10px 15px"
                                btnHeight="50px"
                                handleClick={saveUserPackage}
                                disabled={saveLoading ? true : false}
                            >
                                {saveLoading ? (
                                    <InlineLoader />
                                ) : (
                                    'Create Package'
                                )}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <DescriptionComponent text="We’ve solved the problems that make it difficult for energy providers to transition millions" />
                        <div className="MobileComponentBtnWrapper">
                            <div style={{ marginRight: '15px' }}>
                                <Button
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="132px"
                                    handleClick={() => {
                                        handleStage('capex')
                                    }}
                                >
                                    Previous
                                </Button>
                            </div>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                btnPadding="10px 15px"
                                btnHeight="50px"
                                handleClick={saveUserPackage}
                                disabled={saveLoading ? true : false}
                            >
                                {saveLoading ? (
                                    <InlineLoader />
                                ) : (
                                    'Create Package'
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

AddProtectiveDevice.propTypes = {
    handleStage: PropTypes.func,
    handleSavePackage: PropTypes.func,
    saveLoading: PropTypes.bool,
    createProtectiveDevice: PropTypes.array,
    setCreateProtectiveDevice: PropTypes.func,
}

export default AddProtectiveDevice
