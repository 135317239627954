import axios from 'src/config/axios'

export const sseIdentityVerification = payload => {
    const request = axios.post('/identity-verification/id-card', payload)
    return request
}

export const sseIdentityVerificationCompare = id => {
    const request = axios.post(
        `/identity-verification/id-card/${id?.id}/compare`,
    )
    return request
}

export const sseSelfieUpload = payload => {
    const request = axios.post('/identity-verification/selfie', payload)
    return request
}
