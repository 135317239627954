import { useHistory } from 'react-router-dom'
import '../../NewProfile/newprofile.scss'
import BackNav from 'src/components/BackNav'
import SeoComponent from 'src/components/Seo'
import DescriptionComponent from 'src/components/DescriptionComponent/DescriptionComponent'
import s from '../components/PageForm/pageform.module.scss'
import ButtonComponent from 'src/components/Button'
import UploadLogo from 'src/assets/images/upload.svg'
import SuccessUpload from 'src/assets/images/successUpload.svg'
import { Upload } from 'antd'
import { ReactComponent as QuestionIcon } from 'src/assets/images/question-mark-circle.svg'
import { extractFileNameFromUrl } from 'src/utils/formatting'
import { useClusterBanner } from './hook'
import ActionModal from 'src/components/ActionModal'
import SkeletonInput from 'antd/lib/skeleton/Input'

const ProfileClusterBanner = () => {
    const history = useHistory()
    const { Dragger } = Upload
    const {
        inputs,
        errors,
        beforeUpload,
        props,
        handleNext,
        uploadToS3Loading,
        width,
        updateProfileLoading,
        showActionModal,
        setShowActionModal,
        getClusterOriginationFetching,
        isLoadingProfile,
        refetchProfile,
    } = useClusterBanner()
    return (
        <>
            <SeoComponent title="SunFi | Admin | Add Promo Banner" />

            <ActionModal
                actionModalOpen={showActionModal}
                headerText="Consumer profile updated"
                subTitle="You have successfully edited this consumer profile"
                actionType="success"
                noCancelBtn
                noBackLink
                actionText="Done"
                actionHandler={() => {
                    setShowActionModal(false)
                    refetchProfile()
                }}
                closable
                onCancel={() => setShowActionModal(false)}
                closeModal={() => {
                    setShowActionModal(false)
                    refetchProfile()
                }}
            />
            <div className="NewProfileContainer">
                <BackNav title={'Go Back'} onClick={() => history.goBack()} />
                <div className="NewProfileContentWrapper">
                    <div
                        className="NewProfileFormWrapper"
                        style={{ width: '100%' }}
                    >
                        <p className="NewProfileTitle">
                            Promote this consumer profile
                        </p>
                        {isLoadingProfile || getClusterOriginationFetching ? (
                            <div style={{ display: 'grid', maxWidth: '409px' }}>
                                <SkeletonInput
                                    style={{ height: 19, marginBottom: 16 }}
                                />
                                <SkeletonInput style={{ height: 222 }} />
                            </div>
                        ) : (
                            <div className={s.FormSection}>
                                <div className="space-between">
                                    <h4>Add landing page banner</h4>
                                    <span className="align-items-center">
                                        <QuestionIcon />
                                    </span>
                                </div>

                                <div>
                                    <Dragger
                                        className={s.UploadContainer}
                                        maxCount={1}
                                        beforeUpload={beforeUpload}
                                        showUploadList={false}
                                        accept="image/png"
                                        disabled={uploadToS3Loading}
                                        customRequest={() => {}}
                                        {...props}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            {uploadToS3Loading &&
                                            width < 100 ? (
                                                <div className="SlideContainer">
                                                    <p
                                                        className="Slider"
                                                        style={{
                                                            width: `${width}%`,
                                                        }}
                                                    ></p>
                                                </div>
                                            ) : inputs?.image_url?.length >
                                              0 ? (
                                                <img
                                                    src={SuccessUpload}
                                                    className="UploadLogo"
                                                    alt="success logo"
                                                />
                                            ) : (
                                                <img
                                                    src={UploadLogo}
                                                    className="UploadLogo"
                                                    alt="upload logo"
                                                />
                                            )}
                                        </p>
                                        <p
                                            className="UploadText"
                                            style={{ position: 'relative' }}
                                        >
                                            {uploadToS3Loading &&
                                            width < 100 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: '10px',
                                                        left: '36%',
                                                    }}
                                                >
                                                    Uploading picture...
                                                </div>
                                            ) : inputs?.image_url?.length >
                                              0 ? (
                                                <>
                                                    <p
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        {extractFileNameFromUrl(
                                                            inputs?.image_url,
                                                        )}
                                                    </p>
                                                    <p>
                                                        <u
                                                            className="Browse"
                                                            style={{
                                                                marginTop:
                                                                    '20px',
                                                                lineHeight:
                                                                    '40px',
                                                            }}
                                                        >
                                                            Replace document
                                                        </u>
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        Upload file. Drag and
                                                        drop or
                                                        <u className="Browse">
                                                            browse
                                                        </u>
                                                    </p>
                                                    <p className={s.fileSize}>
                                                        The max file size is
                                                        5MB. Please ensure{' '}
                                                        <br />
                                                        the image is of high
                                                        quality.
                                                    </p>
                                                    <p className="OIDSupportedFormatText">
                                                        Supported format : PNG
                                                    </p>
                                                </>
                                            )}
                                        </p>
                                    </Dragger>
                                    {!uploadToS3Loading && (
                                        <p className="ErrorText">
                                            {errors['image_url']}
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                        <ButtonComponent
                            btnBgColor={'#004AAD'}
                            btnWidth={'160px'}
                            btnHeight={'56px'}
                            btnTextColor={'#FFFFFF'}
                            handleClick={handleNext}
                            disabled={
                                updateProfileLoading ||
                                uploadToS3Loading ||
                                isLoadingProfile
                            }
                            loading={updateProfileLoading}
                        >
                            Continue
                        </ButtonComponent>
                    </div>
                    <div className="NewProfileDescriptionWrapper">
                        <DescriptionComponent text="Enter the details of the new consumer profile you want to create. Ensure you choose the correct profile type and system size." />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileClusterBanner
