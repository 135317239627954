export const businessType = ['Sole Proprietorship', 'LLC', 'NGO']

export const formatBusinessType = data => {
    switch (data) {
        case 'Sole Proprietorship':
            return 'Sole Proprietorship'
        case 'Limited Liability Company':
            return 'LLC'
        case 'Non-governmental Organisation':
            return 'NGO'
        case 'Self-Employed':
            return 'Self Employed'
        default:
            return ''
    }
}

export const formatBusinessTypeMapping = {
    'Sole Proprietorship': 'Sole Proprietorship',
    LLC: 'Limited Liability Company',
    NGO: 'Non-governmental Organisation',
    'Self Employed': 'Self-Employed',
}

export const businessIndustry = [
    'Agriculture',
    'Aviation',
    'Bakery',
    'Barber Shops',
    'Car Washes',
    'Cleaning Services',
    'Commercial/Retail',
    'Construction',
    'Education and Training',
    'Electrical Parts and Equipments',
    'Energy and Power Generation',
    'FMCG',
    'Fashion',
    'Fast Food Restaurants',
    'Financial Services',
    'Haulage/Logistics',
    'Healthcare',
    'ICT',
    'Manufacturing',
    'Media & Entertainment',
    'Oil & Gas',
    'Pharmacies',
    'Photo Studio',
    'Professional Services',
    'Telecommunication',
    'Tourism/Hospitality',
    'Transportation',
    'Waste Management',
]
