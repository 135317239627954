import axios from '../../../config/axios'

export const submitBusinessKycInfoByAdmin = async (estimationId, payload) => {
    const request = await axios.post(
        `/admin/sse-consumers/estimations/${estimationId}/business/info`,
        payload,
    )
    return request
}

export const submitBusinessKycContactInfoByAdmin = async (
    estimationId,
    payload,
) => {
    const request = await axios.post(
        `/admin/sse-consumers/estimations/${estimationId}/business/contact-info`,
        payload,
    )
    return request
}

export const submitResidenceKycPersonalInfoByAdmin = async (
    estimationId,
    payload,
) => {
    const request = await axios.post(
        `/admin/sse-consumers/estimations/${estimationId}/personal/info`,
        payload,
    )
    return request
}

export const submitResidenceKycEmploymentInfoByAdmin = async (
    estimationId,
    payload,
) => {
    const request = await axios.post(
        `/admin/sse-consumers/estimations/${estimationId}/employment/info`,
        payload,
    )
    return request
}

export const notifyConsumerOfKycUpdate = async estimationId => {
    const request = await axios.post(
        `/admin/sse-consumers/estimations/${estimationId}/kyc/notify-consumer`,
    )
    return request
}

export const notifyConsumerToSubmitKyc = async estimationId => {
    const request = await axios.post(
        `/admin/sse-consumers/estimations/${estimationId}/send-submission-notification`,
    )
    return request
}
