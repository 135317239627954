//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState } from 'react'
import CreateAccount from '../../components/CreateAccount'
import PartnerType from '../../components/PartnerType'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import SeoComponent from 'src/components/Seo'

const Signup = ({ showTextHandler }) => {
    const [partnerId, setPartnerId] = useState('')

    let history = useHistory()
    useEffect(() => {
        setPartnerId(history?.location?.state?.partnerId)
        showTextHandler(true)
    }, [history?.location?.state?.partnerId])

    const handleChange = partnerId => {
        setPartnerId(partnerId)
    }

    return (
        <>
            <SeoComponent
                title="SunFi - Sign Up | Partners"
                tracker="SignUpTracker"
            />
            {partnerId ? (
                <CreateAccount
                    partnerId={partnerId}
                    showTextHandler={showTextHandler}
                />
            ) : (
                <PartnerType handleChange={handleChange} />
            )}
        </>
    )
}

export default Signup
