import PropTypes from 'prop-types'
import '../gettingstarted.scss'
import { requiredInfoList } from '../data'
import infoIcon from 'src/assets/images/Info-warning-blue.svg'

const RequiredInfo = ({ inputs, heading = '' }) => {
    return (
        <div
            className={'GettingStartedRequiredInfo'}
            data-testid="required-info-container"
        >
            <div
                className={'GettingStartedHeadingAndText noBottomMargin'}
                data-testid="heading-and-text"
            >
                {heading === '' ? (
                    <h3 data-testid="verification-heading">
                        Package Selection was Successful
                    </h3>
                ) : (
                    <h3>{heading}</h3>
                )}
                <p>
                    Thank you for selecting a system that meets your needs. Now
                    let&apos;s begin your KYC to help us get to know you better.
                    But first, here is a list of things you&apos;ll need.
                </p>
            </div>
            <div
                className={'GettingStartedRequiredInfoList'}
                data-testid="info-list"
            >
                <h3>Required information to be requested from you:</h3>
                <ul>
                    {requiredInfoList(inputs)?.map((each, i) => (
                        <li key={i} data-testid={`info-item-${i}`}>
                            {each}
                        </li>
                    ))}
                </ul>
            </div>
            <div
                className={'GettingStartedRequiredInfoDisclaimer'}
                data-testid="disclaimer"
            >
                <img src={infoIcon} alt={'info'} role={'presentation'} />
                <p>
                    Please note that we only accept Naira bank statements at
                    this time, which means we cannot accept statements from
                    Dollar accounts or Microfinance banks. Be rest assured that
                    none of the requested information will give access to your
                    bank account or transactions.
                </p>
            </div>
        </div>
    )
}

RequiredInfo.propTypes = {
    inputs: PropTypes.object,
    heading: PropTypes.string,
}

export default RequiredInfo
