//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { Tabs, Skeleton } from 'antd'
import BackNav from '../../components/BackNav'
import Tick from '../../assets/images/tick.svg'
import { decodeUserInfo } from '../../utils/auth'
import Toast from '../../components/Toast'
import { adminSimulatePaymentPlan } from '../../api/admin/credit-variables/detailed-page'
import { errorHandler } from '../../utils/errorHandler'
import { v4 as uuidv4 } from 'uuid'

import './simulation-steptwo.scss'
import '../SimulationStepOne/simulation-stepone.scss'
import { isMobile } from 'src/utils/mediaQueries'
import SeoComponent from 'src/components/Seo'

const SimulationStepTwo = () => {
    const [currentTab, setCurrentTab] = useState(0)
    const { TabPane } = Tabs
    const userInfo = decodeUserInfo()

    let history = useHistory()
    const location = useLocation()
    const [paymentPlans, setPaymentPlans] = useState()
    const [adminSimulateData, setAdminSimulateData] = useState({})
    const [amount, setAmount] = useState('')
    const [providerCreditId, setProviderCreditId] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const providerId =
        localStorage.getItem('providerId') === 'undefined'
            ? userInfo?.providerId
            : localStorage.getItem('providerId')

    const isManualSimulate = Boolean(
        new URLSearchParams(location.search).get('manualPrice'),
    )
    const PaymentPlans = location.state
    const userIsAdmin = userInfo?.isAdminWorkspaceUser === 'true'
    const packages = [
        {
            name: uuidv4(),
            cost: Number(amount),
        },
    ]

    const {
        isFetching: simulatePaymentPlansFetching,
        refetch: simulatePaymentPlanRefetch,
    } = useQuery(
        'admin-simulate-payment-plan',
        () =>
            adminSimulatePaymentPlan({
                packages,
                plan_type: adminSimulateData?.plan_type,
                upfront_deposit: parseFloat(adminSimulateData?.upfront_deposit),
                provider_id: providerId,
                cluster_code: adminSimulateData?.cluster_code,
            }),
        {
            enabled: false,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                setPaymentPlans(data?.data?.data)
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
                setToastError(true)
            },
        },
    )

    useEffect(() => {
        if (userIsAdmin) {
            setAdminSimulateData(
                JSON.parse(localStorage.getItem('simulatePlanData')),
            )
            setAmount(localStorage.getItem('packageAmount'))
            setProviderCreditId(localStorage.getItem('providerCreditId'))
        }
    }, [userIsAdmin])

    const adminData = Object.keys(adminSimulateData).length > 0

    useEffect(() => {
        if (adminData && isManualSimulate) {
            simulatePaymentPlanRefetch()
        }
    }, [userIsAdmin, adminData, isManualSimulate])

    const Plan = ({ paymentPlan }) => (
        <div className="SimulationPlanWrapper">
            {paymentPlan?.repayment_plans?.map((repaymentPlan, i) => (
                <div className="SimulationPaymentPlanCard" key={i}>
                    <div>
                        <p className="SimulationPlanNumber">{`Plan ${
                            i + 1
                        }`}</p>
                        <p className="SimulationPlanCost">
                            {repaymentPlan?.currency?.symbol}
                            {repaymentPlan.monthly_payment}
                            <span
                                style={{
                                    fontSize: '12px',
                                }}
                                className="SimulatinCardSubtitle"
                            >
                                / Month
                            </span>
                        </p>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <p className="SimulatinCardSubtitle">
                                MONTHS OF REPAYMENT
                            </p>
                            <p className="SimulationCardSubtitleContent">
                                {repaymentPlan.tenure} Months
                            </p>
                        </div>
                        <div>
                            <p className="SimulatinCardSubtitle">
                                INITIAL DEPOSIT
                            </p>
                            <p className="SimulationCardSubtitleContent">
                                {repaymentPlan?.currency?.symbol}
                                {repaymentPlan.initial_deposit}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )

    return (
        <div className="SimulationContainer">
            <SeoComponent
                title="Simulate Payment Plans - Step Two | SunFi | Simplifying and Scaling Clean Energy"
                tracker="PaymentPlansTracker"
            />
            <BackNav
                title={
                    userIsAdmin ? 'Back to Provider' : 'Back to Payment Plans'
                }
                onClick={() =>
                    history.push(
                        userIsAdmin
                            ? `/admin/credit/view/${providerCreditId}`
                            : '/app/payment-plans',
                    )
                }
                top={isMobile ? '-30px' : '-50px'}
            />
            <h1 className="SimulationTitle">Simulate Payment Plans</h1>
            {PaymentPlans && (
                <div className="SimulationStepsContainer">
                    <div className="SimulationStepsChildContainer">
                        <button
                            className="SimulationStepsButton"
                            style={{ background: '#61C9A8' }}
                        >
                            <img
                                src={Tick}
                                alt="tick"
                                style={{ padding: '4px' }}
                            />
                        </button>
                        <p className="StepsText">Energy Solution</p>
                    </div>
                    <div className="SimulationStepsLine_two"></div>
                    <div className="SimulationStepsChildContainer">
                        <button className="SimulationStepsButton SimulationActiveStepsButton">
                            2
                        </button>
                        <p className="StepsText">Payment Plans</p>
                    </div>
                </div>
            )}
            <div className="SimulationTopContent">
                <div>
                    <p className="SimulationFirstText">Payment Plans</p>
                    <p className="SimulationSecondText">
                        Available Payment Plans
                    </p>
                </div>
                {PaymentPlans && (
                    <div
                        style={{ display: 'flex' }}
                        onClick={() => {
                            history.push({
                                pathname: '/app/payment-plans/simulate/one',
                                state: true,
                            })
                        }}
                    >
                        <p style={{ color: 'var(--blue)', cursor: 'pointer' }}>
                            Change Energy Solution
                        </p>
                    </div>
                )}
            </div>
            {toastError && <Toast messageType="error" message={errorMessage} />}

            {PaymentPlans ? (
                <div style={{ marginTop: 20 }}>
                    <Tabs
                        defaultActiveKey={currentTab}
                        onChange={values => {
                            setCurrentTab(values)
                        }}
                    >
                        {Object.values(PaymentPlans)?.map((paymentPlan, i) => (
                            <TabPane tab={paymentPlan.name} key={i}>
                                <Plan paymentPlan={paymentPlan} />
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            ) : simulatePaymentPlansFetching ? (
                <div className="SimulationSkeletalBox">
                    {[...Array(4)].map(() => (
                        <Skeleton
                            key={uuidv4()}
                            className="SimulationCard"
                            active
                        />
                    ))}
                </div>
            ) : paymentPlans ? (
                <div style={{ marginTop: 20 }}>
                    {Object.values(paymentPlans)?.map((paymentPlan, i) => (
                        <div key={i}>
                            <Plan paymentPlan={paymentPlan} />
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default SimulationStepTwo
