export const numberToWords = (number) => {
  if (!number) return ''
  // Arrays to hold word equivalents of numbers
  const ones = [
    "", "one", "two", "three", "four", "five",
    "six", "seven", "eight", "nine", "ten",
    "eleven", "twelve", "thirteen", "fourteen", "fifteen",
    "sixteen", "seventeen", "eighteen", "nineteen"
  ];

  const tens = [
    "", "", "twenty", "thirty", "forty", "fifty",
    "sixty", "seventy", "eighty", "ninety"
  ];

  const scales = ["", "thousand", "million", "billion"];

  // Helper function to convert a group of three digits
  function convertGroup(num) {
    const parts = [];

    if (num >= 100) {
      parts.push(ones[Math.floor(num / 100)] + " hundred");
      num %= 100;
    }

    if (num >= 20) {
      parts.push(tens[Math.floor(num / 10)]);
      num %= 10;
    }

    if (num > 0) {
      parts.push(ones[num]);
    }

    return parts.join(" ");
  }

  // Main conversion logic
  if (number === 0) {
    return "zero";
  }

  const numChunks = [];
  while (number > 0) {
    numChunks.push(number % 1000);
    number = Math.floor(number / 1000);
  }

  const words = [];
  for (let i = numChunks.length - 1; i >= 0; i--) {
    const numChunk = numChunks[i];
    if (numChunk > 0) {
      words.push(convertGroup(numChunk) + " " + scales[i]);
    }
  }

  return words.join(" ");
}
