import { useMutation } from 'react-query'
import { getNewSystemReturningUserApi } from 'src/api/shoppingExperience/view-system'
import { errorHandler } from 'src/utils/errorHandler'

const useGetNewSystemForReturningUser = ({ onSuccess, onError }) => {
    const { mutate, isLoading } = useMutation({
        mutationFn: payload => getNewSystemReturningUserApi(payload),
        onSuccess: () => onSuccess?.(),
        onError: error => onError?.(errorHandler(error?.response?.data)),
    })

    return {
        getNewSystemMutate: mutate,
        getNewSystemLoading: isLoading,
    }
}

export default useGetNewSystemForReturningUser
