import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
    retrieveEstimationForAdminApi,
    retrieveEstimationApi,
} from 'src/api/shoppingExperience/account-overview'
import { decodeUserInfo } from 'src/utils/auth'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'

const useOfferInfo = (formatter, estimationId = '') => {
    const [offer, setOffer] = useState({})
    const userInfo = decodeUserInfo()
    const { id } = useParams()
    const { errorAlert } = useCustomToast()

    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const { isFetching } = useQuery(
        'fetch-estimation',
        () =>
            isAdminUser
                ? retrieveEstimationForAdminApi(estimationId || id)
                : retrieveEstimationApi(),
        {
            enabled: true,
            retry: false,
            refetchOnMount: true,
            onSuccess: res => {
                const data = res?.data?.data
                if (formatter) {
                    setOffer(formatter(data))
                } else {
                    setOffer(data)
                }
            },
            onError: error => {
                errorAlert(
                    errorHandler(
                        error?.response?.data ||
                            'An error occurred, please try again later.',
                    ),
                )
            },
        },
    )

    return {
        offer,
        offerFetching: isFetching,
    }
}

export default useOfferInfo
