import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import BackNav from 'src/components/BackNav'
import LoginMethodsModal from 'src/components/LoginMethodsModal'
import styles from './sselogin.module.scss'
import LoginAction from './components/LoginAction'
import LoginComponent from 'src/components/LoginComponent'
import SeoComponent from 'src/components/Seo'

const SSELogin = () => {
    const [option, setOption] = useState('')
    const [method, setMethod] = useState('')
    const [step, setStep] = useState(1)
    const [showModal, setShowModal] = useState(false)

    const { page, backNavContainer } = styles
    const history = useHistory()

    const handleOptionClick = key => {
        setOption(key)
    }

    const handleContinueClick = () => {
        setShowModal(true)
    }

    const handleLoginMethodClick = () => {
        setStep(2)
        setShowModal(false)
    }

    const handleBackNav = () => {
        if (step === 1) {
            history.goBack()
        } else {
            setStep(1)
        }
    }

    return (
        <>
            <SeoComponent title="Consumer Login | SunFi | Simplifying and Scaling Clean Energy" />
            <LoginMethodsModal
                showModal={showModal}
                setShowModal={setShowModal}
                method={method}
                setMethod={setMethod}
                handleLoginMethodClick={handleLoginMethodClick}
            />
            <div className={page}>
                <div className={backNavContainer}>
                    <BackNav
                        v2
                        title={'Go back'}
                        marginTop={'76px'}
                        marginBottom={'16px'}
                        onClick={() => handleBackNav()}
                    />
                </div>
                {step === 1 ? (
                    <LoginAction
                        option={option}
                        handleOptionClick={handleOptionClick}
                        handleContinueClick={handleContinueClick}
                    />
                ) : (
                    <LoginComponent method={method} setMethod={setMethod} />
                )}
            </div>
        </>
    )
}

export default SSELogin
