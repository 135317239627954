//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Bar, defaults } from 'react-chartjs-2'
import OverviewImg from 'src/assets/images/overviewImg.svg'
import { EmptyStateIllustrator } from 'src/assets/images/EmptyStateIllustrator'
import overviewNoActivity from 'src/assets/images/overviewNoActivity.svg'
import overviewPackages from 'src/assets/images/overviewPackages.svg'
import overviewComponents from 'src/assets/images/overviewComponent.svg'
import overviewEstimation from 'src/assets/images/overviewEstimation.svg'
import overviewApproved from 'src/assets/images/overviewApproved.svg'
import ApprovedIcon from 'src/assets/images/approved.svg'
import DeclinedIcon from 'src/assets/images/declined.svg'
import Toast from 'src/components/Toast'
import SeoComponent from 'src/components/Seo'
import { SelectField } from 'src/components/InputFields'
import Button from 'src/components/Button'
import styles from 'src/pages/Overview/overview.module.scss'
import { format, parseISO } from 'date-fns'

import {
    getAdminOview,
    getAdminAnalytics,
    getAdminUserActivities,
} from '../../../api/admin/overview'
import { errorHandler } from '../../../utils/errorHandler'

import './adminoverview.scss'
import { permissionsControl } from 'src/utils/permissionsControl'
// import AddCreditVariable from '../Credit/AddVariable'
// import SecondaryModal from '../../../components/Modal/SecondaryModal'

const {
    Container,
    OverviewWrapper,
    TopSection,
    OverviewImage,
    Product,
    Library,
    Overlay,
    OverlayBtn,
    OverviewPackages,
    SubHeading,
    Total,
    SectionWrapper,
    ImageWrapper,
    ActivityWrapper,
    OverlayEstimation,
    EstimationWrapper,
    NoActivityWrapper,
    EmptyHeading,
    EmptyText,
    ImgWrapper,
    CardImage,
    ImgStyle,
    Activity,
    ActivityDate,
    ActivityRow,
    ActivityText,
    AdminRowWrapper,
    MobilePackageWrapper,
    MobileAnalyticBtn,
    MobileBtnWrapper,
    RecentActivities,
    BarChartDataContainer,
    EmptyStateWrapper,
    // HelpCenterWrapper_Overview,
} = styles

// eslint-disable-next-line no-unused-vars
const AdminOverview = () => {
    const [packagesCount, setPackagesCount] = useState(0)
    const [componentsCount, setComponentsCount] = useState(0)
    const [approvedPaymentPlansCount, setApprovedPaymentPlansCount] =
        useState(0)
    const [approvedRequestsCount, setApprovedRequestsCount] = useState(0)
    const [userActivities, setUserActivities] = useState([])
    const [estAnalytics, setEstAnalytic] = useState([])
    const [toastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const history = useHistory()
    const canViewPaymentPlanAnalytics = permissionsControl([
        'can_view_estimation_analytics',
    ])
    const [analyticsChart, setAnalyticsChart] = useState(
        canViewPaymentPlanAnalytics ? 'Payment plans' : 'Consumer requests',
    )
    // const [showModal, setShowModal] = useState(true)

    //fetch overview counts
    const { refetch: overviewRefetch } = useQuery(
        'get-overview',
        () => getAdminOview(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setComponentsCount(data?.data?.data?.components)
                setPackagesCount(data?.data?.data?.packages)
                setApprovedPaymentPlansCount(
                    data?.data?.data?.estimations?.payment_plans,
                )
                setApprovedRequestsCount(
                    data?.data?.data?.estimations?.consumer_request,
                )
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const onSuccess = data => {
        const sortedData = data?.data?.data?.graph_data
        const barType = type =>
            Object.values(sortedData).map(status => status[type])

        const barChartData = {
            labels: Object.keys(sortedData).map(label => label),
            datasets: [
                {
                    label: 'Awaiting Approval',
                    data: barType('awaiting_approval'),
                    backgroundColor: '#FFC222',
                    stack: 'Stack 0',
                    borderRadius: 2,
                    categoryPercentage: 0.3,
                    barPercentage: 0.5,
                    base: 0,
                },
                {
                    label: 'Approved',
                    data: barType('approved'),
                    backgroundColor: '#004AAD',
                    stack: 'Stack 1',
                    borderRadius: 2,
                    categoryPercentage: 0.3,
                    barPercentage: 0.5,
                },
                {
                    label: 'In-Progress',
                    data: barType('in_progress'),
                    backgroundColor: '#5DADEC',
                    stack: 'Stack 2',
                    borderRadius: 2,
                    categoryPercentage: 0.3,
                    barPercentage: 0.5,
                },
            ],
            barThickness: 1,
        }

        setEstAnalytic(barChartData)
    }
    // fetch analytics
    const { refetch: analyticsRefetch } = useQuery(
        'get-admin-analytic',
        () =>
            getAdminAnalytics(
                analyticsChart === 'Payment plans' ? 'DFS' : 'CFS',
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: onSuccess,
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    // fetch user activities
    const { refetch: userActivitiesRefetch } = useQuery(
        'get-admin-user-activities',
        () => getAdminUserActivities(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const mostRecentThreeActivities = data?.data?.data.slice(0, 3)
                setUserActivities(mostRecentThreeActivities)
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const dataCards = [
        {
            title: 'appr. plans',
            iconUrl: overviewEstimation,
            count: approvedPaymentPlansCount,
        },
        {
            title: 'appr. requests',
            iconUrl: overviewApproved,
            count: approvedRequestsCount,
        },
        {
            title: 'Packages',
            iconUrl: overviewPackages,
            count: packagesCount,
        },
        {
            title: 'Components',
            iconUrl: overviewComponents,
            count: componentsCount,
        },
    ]

    defaults.font.family = 'SF-Pro-Display'

    const options = {
        scales: {
            x: {
                ticks: {
                    beginAtZero: true,
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                    offset: true,
                },
                ticks: {
                    display: false,
                },
            },
        },
        layout: {
            padding: {
                top: 0,
                bottom: 0,
            },
        },

        plugins: {
            legend: {
                align: 'start',
                labels: {
                    font: {
                        size: 11,
                        family: 'SF-Pro-Display',
                    },
                    boxWidth: 10,
                    boxHeight: 10,
                    padding: 20,
                    borderRadius: 2,
                },
            },
        },
    }

    const estimation_mapping = {
        ESTIMATION_DECLINED: 'declined',
        ESTIMATION_DECLINED_AND_RE_ESTIMATE: 'declined to be re-estimated',
        ESTIMATION_APPROVED: 'approved',
    }

    const dropDownItems = [
        { value: 'Consumer requests' },
        { value: 'Payment plans' },
    ]

    const handleSelectChange = (name, value) => {
        setAnalyticsChart(value)
    }

    useEffect(() => analyticsRefetch(), [analyticsChart])

    useEffect(() => {
        overviewRefetch()
        analyticsRefetch()
        userActivitiesRefetch()
    }, [])

    return (
        <div className={Container}>
            {/* <SecondaryModal 
                modalWidth="624px"
                closable={false}
                modalHeight='475px'
                app="adminsolution"
                showModal={showModal}
                content={
                    <AddCreditVariable closeModal={() => setShowModal(false)}/>
                }
            /> */}
            <SeoComponent
                title="SunFi - Admin Overview"
                tracker="AdminOverviewTracker"
            />
            {toastError && (
                <Toast message={errorMessage?.[0]} messageType="error" />
            )}

            <div className={OverviewWrapper}>
                <div className={TopSection}>
                    <img
                        src={OverviewImg}
                        alt="overview"
                        className={OverviewImage}
                    />
                    <p className={`${Overlay} ${Product}`}>Payment</p>
                    <p className={`${Overlay} ${Library}`}>Plans</p>
                    <div className={`${Overlay} ${OverlayBtn}`}>
                        <Button
                            type="overviewLargeScreenBtn"
                            btnBgColor="#004AAD"
                            btnTextColor="#fff"
                            handleClick={() =>
                                history.push('/admin/payment-plans', {
                                    overview: true,
                                })
                            }
                        >
                            Manage
                        </Button>
                    </div>
                </div>
                <div className={MobilePackageWrapper}>
                    {dataCards?.map((card, i) => (
                        <div key={i} className={OverviewPackages}>
                            <div className={ImageWrapper}>
                                <img
                                    src={card?.iconUrl}
                                    alt="packages"
                                    className={CardImage}
                                />
                            </div>
                            <div className={SectionWrapper}>
                                <p className={`${SubHeading}`}>{card?.title}</p>
                                <p className={Total}>{card?.count}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className={ActivityWrapper}>
                <div>
                    <div className={MobileBtnWrapper}>
                        <p className={OverlayEstimation}>
                            {analyticsChart === 'Payment plans'
                                ? 'Payment plans Analytics'
                                : 'Consumer request Analytics'}
                        </p>
                        <div
                            className={`SelectBtn ${
                                estAnalytics.length !== 0 && MobileAnalyticBtn
                            }`}
                        >
                            <SelectField
                                values={dropDownItems}
                                value={analyticsChart}
                                currentSelected={analyticsChart}
                                handleChange={handleSelectChange}
                                name="analytics"
                                selectedValue={analyticsChart}
                                selectWidth="148px"
                                type="analyticsChart"
                                marginBottom="-5px"
                                dropdownPositionRelative
                                optionStyleNoCheckbox={{
                                    fontSize: 14,
                                    fontFamily: 'SF-Pro-Display',
                                    color: '#233862',
                                    marginBottom: 10,
                                }}
                                // disabled={
                                //     analyticsChart === 'Consumer requests' &&
                                //     !canViewPaymentPlanAnalytics
                                // }
                            />
                        </div>
                    </div>
                    <div
                        className={EstimationWrapper}
                        style={{ marginTop: '20px' }}
                    >
                        {estAnalytics?.length === 0 ||
                        (analyticsChart === 'Payment plans' &&
                            !canViewPaymentPlanAnalytics) ? (
                            <>
                                <div className={EmptyStateWrapper}>
                                    <EmptyStateIllustrator />
                                    <p className={EmptyHeading}>
                                        Nothing’s here
                                    </p>
                                    <p className={EmptyText}>
                                        {canViewPaymentPlanAnalytics
                                            ? 'You haven’t estimated any payment plans.'
                                            : 'You are not authorised to view this data.'}
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={BarChartDataContainer}>
                                    <Bar
                                        data={estAnalytics}
                                        options={options}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div>
                    <p className={`${OverlayEstimation} ${RecentActivities}`}>
                        Recent Activities
                    </p>

                    {userActivities?.length === 0 ? (
                        <>
                            <div className={NoActivityWrapper}>
                                <img
                                    src={overviewNoActivity}
                                    alt="no activity"
                                />
                                <p className={EmptyHeading}>No activity yet</p>
                                <p className={EmptyText}>
                                    You’ve not added any product to your
                                    library.
                                </p>
                            </div>
                        </>
                    ) : (
                        <div className="adminActivitiesWrapper">
                            {userActivities?.map((activity, i) => {
                                return (
                                    <div key={i} className={AdminRowWrapper}>
                                        <div className={ActivityRow}>
                                            <div
                                                className={ImgWrapper}
                                                style={{
                                                    background: [
                                                        'ESTIMATION_DECLINED',
                                                        'ESTIMATION_DECLINED_AND_RE_ESTIMATE',
                                                    ].includes(activity?.action)
                                                        ? '#FFEFF4'
                                                        : '#F1FFF6',
                                                }}
                                            >
                                                <img
                                                    src={
                                                        [
                                                            'ESTIMATION_DECLINED',
                                                            'ESTIMATION_DECLINED_AND_RE_ESTIMATE',
                                                        ].includes(
                                                            activity?.action,
                                                        )
                                                            ? DeclinedIcon
                                                            : ApprovedIcon
                                                    }
                                                    className={ImgStyle}
                                                    alt="approved payment plan"
                                                    style={{
                                                        padding:
                                                            [
                                                                'ESTIMATION_DECLINED',
                                                                'ESTIMATION_DECLINED_AND_RE_ESTIMATE',
                                                            ].includes(
                                                                activity?.action,
                                                            ) && '11px',
                                                        paddingLeft:
                                                            [
                                                                'ESTIMATION_DECLINED',
                                                                'ESTIMATION_DECLINED_AND_RE_ESTIMATE',
                                                            ].includes(
                                                                activity?.action,
                                                            ) && '14px',
                                                        paddingRight:
                                                            [
                                                                'ESTIMATION_DECLINED',
                                                                'ESTIMATION_DECLINED_AND_RE_ESTIMATE',
                                                            ].includes(
                                                                activity?.action,
                                                            ) && '14px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={ActivityText}>
                                            <p className={Activity}>
                                                <strong>
                                                    {activity?.consumer?.user
                                                        ?.first_name +
                                                        "'s"}{' '}
                                                </strong>{' '}
                                                repayment plan has been{' '}
                                                {
                                                    estimation_mapping[
                                                        activity?.action
                                                    ]
                                                }
                                            </p>

                                            <span className={ActivityDate}>
                                                {format(
                                                    parseISO(
                                                        activity.created_at,
                                                    ),
                                                    'MMM dd, yyyy',
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AdminOverview
