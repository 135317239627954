import PropTypes from 'prop-types'
import s from '../componentbrands.module.scss'
import emptyFolder from 'src/assets/images/empty_folder.svg'
import { ConsumerButton } from 'src/components/Button'
import { ReactComponent as AddIcon } from 'src/assets/images/add-icon-no-stroke.svg'
import { ReactComponent as Edit } from 'src/assets/images/edit-icon-no-stroke.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const BrandCard = ({
    category, // category is the component type
    brandNames,
    showButtons,
    margin,
    emptyText = 'No brands added',
    handleAddBrand,
    handleEditBrand,
    error,
}) => {
    const { isMobile } = useMediaQueries()

    return (
        <div style={{ margin: margin }}>
            <div className={s.brandCard} data-testid={`brand-card-${category}`}>
                <div className={s.brandCard_top}>
                    <h4>{category}</h4>
                    {showButtons && (
                        <div className={s.brandButtons}>
                            <ConsumerButton
                                dataTestId={`brand-card-add-button-${category}`}
                                btnWidth={'120px'}
                                btnHeight={'29px'}
                                btnBgColor={'#004AAD'}
                                btnTextColor={'#FFFFFF'}
                                hoverNoFill
                                type={'iconBtn'}
                                handleClick={() => handleAddBrand(category)}
                            >
                                <AddIcon style={{ stroke: 'white' }} />
                                Add a brand
                            </ConsumerButton>
                            <ConsumerButton
                                btnWidth={isMobile ? '40px' : '67px'}
                                btnHeight={'29px'}
                                btnBgColor={'#F9FCFF'}
                                btnTextColor={'#004AAD'}
                                hoverNoFill
                                type={'iconBtn'}
                                borderOutlineColor={'#004AAD'}
                                handleClick={() => handleEditBrand(category)}
                                disabled={!brandNames?.length}
                            >
                                <Edit />
                                {isMobile ? '' : 'Edit'}
                            </ConsumerButton>
                        </div>
                    )}
                </div>
                <span>ADDED BRANDS</span>
                {brandNames?.length ? (
                    <div className={s.brandNameList}>
                        {brandNames.map((brand, index) => (
                            <div key={index}>
                                <p>{brand}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div
                        className={s.noBrands}
                        data-testid="brand-card-no-brands"
                    >
                        <img src={emptyFolder} alt="empty folder" />
                        <p>{emptyText}</p>
                    </div>
                )}
            </div>
            <p className={s.brandCardError}>{error}</p>
        </div>
    )
}

BrandCard.propTypes = {
    category: PropTypes.string,
    showButtons: PropTypes.bool,
    brandNames: PropTypes.array,
    margin: PropTypes.string,
    emptyText: PropTypes.string,
    handleAddBrand: PropTypes.func,
    handleEditBrand: PropTypes.func,
    error: PropTypes.string,
}

export default BrandCard
