export const errorHandler = (errObj, type) => {
    if (errObj?.response?.data?.message) {
        let errorMsg = errObj?.response?.data?.message
        return [errorMsg]
    } else {
        const errors =
            errObj?.response?.data?.errors || errObj?.errors || errObj
        if (errors) {
            let errorMsg = Object.keys(errors).map(key => {
                if (type === 'new') {
                    return { [key]: errors?.[key][0] }
                } else {
                    return errors?.[key]
                }
            })
            return errorMsg
        }
    }
}

export const fieldErrorHandler = (errObj, type) => {
    const errors = errObj?.errors

    if (!errors) return ''

    let errorMsg = Object.keys(errors).map(key => {
        if (type === 'new') {
            return { [key]: errors[key] }
        } else {
            return { [key]: errors[key][0] }
        }
    })

    return errorMsg
}

export const errorMessageHandler = errObj => {
    let errorMsg = errObj?.response?.data?.message
    return errorMsg
}

export const errorProcessor = errorMessage => {
    const err = errorMessage?.[0] ? errorMessage?.[0] : ''
    if (err?.message) {
        let errorMsg = err?.message
        return [{ msg: errorMsg }]
    }
    if (err?.attributes) {
        return Object.values(err).map(key => {
            const getKey = Object.keys(key)
            return key[getKey[0]]?.[0]
        })
    }
    if (err?.image) {
        return err['image']?.[0]
    }
    if (err?.components) {
        if (err?.components?.['non_field_errors']) {
            return { msg: err?.components?.['non_field_errors'] }
        }
    }

    if (err?.email || err?.phone_number) {
        return [err]
    }
    if (err?.name) {
        return [err]
    } else {
        return [{ msg: err }]
    }
}

export const formatYupError = err => {
    let errList = {}
    err.inner.forEach(e => {
        errList = {
            ...errList,
            [e.path]: e.message,
        }
    })
    return errList
}