import { AES, enc } from 'crypto-js'

export const encrypt = plainText => {
    return AES.encrypt(
        (plainText ?? '').toString(),
        process.env.REACT_APP_SUNFI_APP_TOKEN ?? '',
    ).toString()
}

export const decrypt = cipherText => {
    if (cipherText) {
        return AES.decrypt(
            cipherText,
            process.env.REACT_APP_SUNFI_APP_TOKEN,
        ).toString(enc.Utf8)
    }
}

export const encode = plainText => {
    return window.btoa(plainText)
}

export const decode = encodedText => {
    return window.atob(encodedText)
}
