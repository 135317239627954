import { useState, useEffect } from 'react'
import { ProductHeader } from '../ProductHeader'
import Button from '../../Button'
import { InlineLoader } from '../../Loader'
import SeoComponent from '../../Seo'
import PropTypes from 'prop-types'
import './addimage.scss'
import DescriptionComponent from 'src/components/DescriptionComponent/DescriptionComponent'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { useUploadMultipleToS3 } from 'src/utils/Hooks/useUploadToS3'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { generateUniqueFilename } from 'src/utils/formatting'
import ImageUpload from './ImageUpload'

const MAX_IMAGE_COUNT = 3

const AddImage = ({
    isEdit,
    handleStage,
    setImages,
    imageFiles,
    setImageFiles,
    saveLoading,
    handleSavePackage,
    setRemovedImages,
}) => {
    const { errorAlert } = useCustomToast()
    const [prevImages, setPrevImages] = useState([])
    const [hasPendingUploads, setHasPendingUploads] = useState(false)

    const handleSave = () => {
        setHasPendingUploads(false)

        if (isEdit) {
            handleSavePackage()
        } else {
            handleStage('components')
        }
    }

    // Image upload
    const { uploadMultipleFilesToS3Loading, uploadMultipleToS3Mutate } =
        useUploadMultipleToS3({
            onSuccess: data => {
                setImages([...data])

                handleSave()
            },
            onError: e => {
                errorAlert(errorHandler(e) || 'Something went wrong')
            },
        })

    // app tracker variables
    const pageTitle = isEdit
        ? 'SunFi - Edit Product | Edit Images of this product'
        : 'SunFi - Add A Package | Add Images of this package'
    const pageUrlName = window.location.pathname
    const pageTracker = 'ADD_PACKAGE_TRACKER'

    const handleImagesArray = () => {
        const existingImages = []

        const files = imageFiles.filter(imageFile => {
            if (typeof imageFile === 'string') {
                existingImages.push(imageFile)
                return false
            }

            return true
        })

        const prevImagesClone = [...prevImages]

        const deletedImages = prevImagesClone.filter(
            image => !existingImages.includes(image),
        )

        return {
            existingImages,
            files,
            deletedImages,
        }
    }

    const handleUploadImages = () => {
        if (imageFiles.length) {
            const { files } = handleImagesArray()

            if (files.length && hasPendingUploads) {
                uploadMultipleToS3Mutate({
                    category: 'COMPONENTS',
                    files: files.map(file => ({
                        ...file,
                        name: generateUniqueFilename(file.name),
                    })),
                })
            } else {
                handleSave()
            }
        } else {
            handleSave()
        }
    }

    useEffect(() => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['addPackageImage'].action,
            eventTrackers['addPackageImage'].label,
            eventTrackers['addPackageImage'].category,
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (imageFiles?.length) {
            const imageStrings = []

            for (const file of imageFiles) {
                if (typeof file === 'string') {
                    imageStrings.push(file)
                }
            }

            setPrevImages(imageStrings)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (imageFiles && Array.isArray(imageFiles)) {
            const { deletedImages } = handleImagesArray()

            if (isEdit) {
                setRemovedImages?.(deletedImages)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageFiles])

    return (
        <>
            <SeoComponent
                title={
                    isEdit
                        ? 'SunFi - Edit Product | Edit Images of this product'
                        : 'SunFi - Add A Package | Add Images of this package'
                }
                tracker="AddPackage"
            />

            <div className="AddImageContentWrapper">
                <div className="AddImageRow">
                    <div className="AddImageLeftContent">
                        <ProductHeader
                            title={isEdit ? '' : 'Create New Product Package'}
                            subtitle={
                                isEdit
                                    ? 'Edit Images of this product'
                                    : 'Add Images of this package'
                            }
                        />
                        <ImageUpload
                            multiple
                            maxCount={MAX_IMAGE_COUNT}
                            successUpload={imageFiles}
                            setSuccessUpload={payload => {
                                setImageFiles(payload)

                                if (!hasPendingUploads) {
                                    setHasPendingUploads(true)
                                }
                            }}
                            isLoading={uploadMultipleFilesToS3Loading}
                        />
                        <div className="AddImageBtnWrapper">
                            <div style={{ marginRight: '15px' }}>
                                <Button
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="132px"
                                    handleClick={() => {
                                        handleStage(isEdit ? '' : 'name')
                                    }}
                                >
                                    Previous
                                </Button>
                            </div>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                btnPadding="10px 15px"
                                btnHeight="50px"
                                handleClick={handleUploadImages}
                                disabled={
                                    uploadMultipleFilesToS3Loading ||
                                    saveLoading
                                }
                            >
                                {uploadMultipleFilesToS3Loading ||
                                saveLoading ? (
                                    <InlineLoader />
                                ) : isEdit ? (
                                    'Save Changes'
                                ) : (
                                    'Continue'
                                )}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <DescriptionComponent text="We’ve solved the problems that make it difficult for energy providers to transition millions" />
                        <div className="MobileAddImageBtnWrapper">
                            <div style={{ marginRight: '15px' }}>
                                <Button
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="132px"
                                    handleClick={() => {
                                        handleStage(isEdit ? '' : 'name')
                                    }}
                                >
                                    Previous
                                </Button>
                            </div>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                btnPadding="10px 15px"
                                btnHeight="50px"
                                handleClick={handleUploadImages}
                                disabled={
                                    uploadMultipleFilesToS3Loading ||
                                    saveLoading
                                }
                            >
                                {uploadMultipleFilesToS3Loading ||
                                saveLoading ? (
                                    <InlineLoader />
                                ) : isEdit ? (
                                    'Save Changes'
                                ) : (
                                    'Continue'
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

AddImage.propTypes = {
    isEdit: PropTypes.Boolean,
    handleStage: PropTypes.func,
    handleSavePackage: PropTypes.func,
    saveLoading: PropTypes.Boolean,
    images: PropTypes.array,
    setImages: PropTypes.func,
    imageFiles: PropTypes.array,
    setImageFiles: PropTypes.func,
    setRemovedImages: PropTypes.func,
}

export default AddImage
