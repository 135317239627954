import { useState } from 'react'
import { useGetEnergyProfiles } from 'src/api/shoppingExperience/energy-profile/queries'
import { appTracking } from 'src/utils/appTracker'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'

export const useProfilelistings = ({ isBusiness }) => {
    const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = `View ${isBusiness ? 'BP' : 'RP'} listing`
    const [currentPage, setCurrentPage] = useState(1)
    const [showModal, setShowModal] = useState(false)
    const { getEnergyProfilesData, getEnergyProfilesFetching } =
        useGetEnergyProfiles({
            enabled: true,
            params: {
                customer_type: isBusiness ? 'BUSINESS' : 'RESIDENTIAL',
                page: currentPage,
            },
        })

    const toggleModal = () => {
        setShowModal(prev => !prev)
        !showModal &&
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                consumerProfileEvents(!isBusiness ? 'RP' : 'BP')[
                    'selectBuildSytem'
                ].action,
                consumerProfileEvents(!isBusiness ? 'RP' : 'BP').label,
                consumerProfileEvents(!isBusiness ? 'RP' : 'BP').category,
                ['MP', 'GA'],
                'event',
            )
    }

    return {
        getEnergyProfilesData,
        getEnergyProfilesFetching,
        currentPage,
        setCurrentPage,
        showModal,
        setShowModal,
        toggleModal,
    }
}
