import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { SelectField } from 'src/components/InputFields'
import employmentStatus, {
    fullEmploymentOptions,
    retiredOptions,
    selfEmployedOptions,
} from 'src/utils/employmentStatus'

const EmploymentType = ({
    section,
    inputs,
    errors,
    handleSelectChange,
    handleSelectOnBlur,
    isAdminUser,
    isCustomerApproved,
}) => {
    const employmentOptions =
        section?.value === 'full_employment'
            ? fullEmploymentOptions
            : section?.value === 'pension'
            ? retiredOptions
            : section?.value === 'business'
            ? selfEmployedOptions
            : employmentStatus

    return (
        <>
            <Label
                title={'Employment type'}
                subTitle={'What type of employment are you currently on?'}
            />
            <SelectField
                floatLabelOnSelectedValue
                floatingLabel="Employment Type"
                name="employmentType"
                initialOption="Employment Type"
                selectWidth="100%"
                marginBottom="24px"
                values={employmentOptions.map(option => ({
                    value: option,
                }))}
                value={inputs.employmentType}
                errorMessage={errors?.employmentType}
                handleChange={handleSelectChange}
                onBlur={() => handleSelectOnBlur('employmentType')}
                disabled={isAdminUser || isCustomerApproved}
            />

            <VerticalSpacer top={12} />
        </>
    )
}

EmploymentType.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default EmploymentType
