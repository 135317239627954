import PropTypes from 'prop-types'
import { useEffect } from 'react'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import MobileModal from './components/MobileModal'
import {
    footerPropTypes,
    mobileModalPropTypes,
    standardModalPropTypes,
} from './utils'
import StandardModal from './components/StandardModal'

const ConsumerModalBase = ({
    showModal,
    onCancel,
    title,
    children,
    footerLeftContent,
    chatCTAText,
    secondaryBtnWidth,
    secondaryBtnHeight,
    handleSecondaryBtnClick,
    secondaryBtnDisabled,
    secondaryBtnLoading,
    secondaryBtnText,
    primaryBtnWidth,
    primaryBtnHeight,
    handlePrimaryBtnClick,
    primaryBtnDisabled,
    primaryBtnLoading,
    primaryBtnText,
    mobileHeight,
    standardWidth,
    standardHeight,
    footerClassName,
    standardContentStyle,
    mobileContentStyle,
    closable = true,
    dataTestId,
    primaryBtnDataTestId,
}) => {
    const { isMobile } = useMediaQueries()

    useEffect(() => {
        const body = document.body
        if (showModal) {
            body.style.overflow = 'hidden'
        } else {
            body.style.overflow = 'auto'
        }

        return () => {
            body.style.overflow = 'auto'
        }
    }, [showModal])

    return (
        <>
            {isMobile ? (
                <MobileModal
                    showModal={showModal}
                    onCancel={onCancel}
                    mobileHeight={mobileHeight}
                    title={title}
                    footerLeftContent={footerLeftContent}
                    chatCTAText={chatCTAText}
                    secondaryBtnWidth={secondaryBtnWidth}
                    secondaryBtnHeight={secondaryBtnHeight}
                    handleSecondaryBtnClick={handleSecondaryBtnClick}
                    secondaryBtnDisabled={secondaryBtnDisabled}
                    secondaryBtnLoading={secondaryBtnLoading}
                    secondaryBtnText={secondaryBtnText}
                    primaryBtnWidth={primaryBtnWidth}
                    primaryBtnHeight={primaryBtnHeight}
                    handlePrimaryBtnClick={handlePrimaryBtnClick}
                    primaryBtnDisabled={primaryBtnDisabled}
                    primaryBtnLoading={primaryBtnLoading}
                    primaryBtnText={primaryBtnText}
                    footerClassName={footerClassName}
                    mobileContentStyle={mobileContentStyle}
                    closable={closable}
                >
                    {children}
                </MobileModal>
            ) : (
                <StandardModal
                    showModal={showModal}
                    onCancel={onCancel}
                    title={title}
                    standardWidth={standardWidth}
                    standardHeight={standardHeight}
                    footerLeftContent={footerLeftContent}
                    chatCTAText={chatCTAText}
                    secondaryBtnWidth={secondaryBtnWidth}
                    secondaryBtnHeight={secondaryBtnHeight}
                    handleSecondaryBtnClick={handleSecondaryBtnClick}
                    secondaryBtnDisabled={secondaryBtnDisabled}
                    secondaryBtnLoading={secondaryBtnLoading}
                    secondaryBtnText={secondaryBtnText}
                    primaryBtnWidth={primaryBtnWidth}
                    primaryBtnHeight={primaryBtnHeight}
                    handlePrimaryBtnClick={handlePrimaryBtnClick}
                    primaryBtnDisabled={primaryBtnDisabled}
                    primaryBtnLoading={primaryBtnLoading}
                    primaryBtnText={primaryBtnText}
                    footerClassName={footerClassName}
                    standardContentStyle={standardContentStyle}
                    closable={closable}
                    dataTestId={dataTestId}
                    primaryBtnDataTestId={primaryBtnDataTestId}
                >
                    {children}
                </StandardModal>
            )}
        </>
    )
}

ConsumerModalBase.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    children: PropTypes.node,
    title: PropTypes.string,
    ...footerPropTypes,
    ...mobileModalPropTypes,
    ...standardModalPropTypes,
}

export default ConsumerModalBase
