import { useMutation } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { solutionChangeApi } from 'src/api/shoppingExperience/account-overview'

export const useChangePackage = ({ onSuccess }) => {
    const { errorAlert } = useCustomToast()

    const changePackage = useMutation({
        mutationFn: ({ id, payload }) => solutionChangeApi(id, payload),
        onSuccess: () => {
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })
    return {
        changePackage,
    }
}
