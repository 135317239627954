import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

export const isElementInView = (element, threshold) => {
    const rect = element.getBoundingClientRect()
    const viewportHeight = globalThis.window.innerHeight
    const viewportWidth = globalThis.window.innerWidth

    const verticallyInView =
        rect.top <= viewportHeight * threshold &&
        rect.bottom >= viewportHeight * threshold
    const horizontallyInView =
        rect.left <= viewportWidth && rect.right >= viewportWidth * threshold

    return verticallyInView && horizontallyInView
}

export const useInView = (observables = {}, config = { threshold: 0.2 }) => {
    const history = useHistory()
    const [inViewData, setInViewData] = useState({})
    const inViewDataRef = useRef({})
    const pathname = history?.location?.pathname

    useEffect(() => {
        const elementIDs = observables?.[pathname]
        const handleScroll = () => {
            if (elementIDs) {
                for (const id of elementIDs) {
                    const element = document.getElementById(id)
                    if (element) {
                        const isInView = isElementInView(
                            element,
                            config.threshold,
                        )
                        if (inViewDataRef.current[id] !== isInView) {
                            inViewDataRef.current[id] = isInView
                            setInViewData(prev => ({ ...prev, [id]: isInView }))
                        }
                    }
                }
            }
        }

        window.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleScroll)

        // Initial check
        handleScroll()

        return () => {
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleScroll)
        }
    }, [pathname, observables, config])

    return inViewData
}
