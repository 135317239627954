import PropTypes from 'prop-types'
import { useState } from 'react'
import { ReactComponent as CircleArrowIcon } from 'src/assets/images/Caret-Circle-Down.svg'
import { ReactComponent as DayIcon } from 'src/assets/images/day-icon.svg'
import { ReactComponent as NightIcon } from 'src/assets/images/night-icon.svg'
import { ReactComponent as ConsumerAddIcon } from 'src/assets/images/consumer-plus-icon.svg'
import { isMobile } from 'src/utils/mediaQueries'
import { ConsumerButton } from '../Button'
import { displaySummaryBody } from './utils'
import { numberWithCommas } from 'src/utils/formatting'

const ConsumerEnergyNeedsSummary = ({
    energyNeeds,
    setShowApplianceModal,
    totalDayUsage,
    totalWattsAdded,
    totalNightUsage,
    page,
}) => {
    const [toggledText, setToggledText] = useState('See/add more')

    return (
        <div
            className="SSEEnergySummaryWrapper"
            style={{ justifyContent: page === 'SSEEditAppliance' && 'start' }}
        >
            <div className="SSESummaryChild">
                <div className="SSESlideWrapper">
                    <div className="SSEEnergySummaryHeader">
                        <p className="SSEEnergySummaryHeaderP">
                            Your added appliances
                        </p>
                        <div
                            className="GYOEnergySummaryCollapse"
                            onClick={() =>
                                displaySummaryBody(toggledText, setToggledText)
                            }
                        >
                            <span
                                id="close"
                                className="SSEEnergySummaryEnergyCountLabel"
                            >
                                {toggledText}
                            </span>
                            <span id="arrow" data-testid="collapse-button">
                                <CircleArrowIcon
                                    style={{
                                        marginTop: '6px',
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="SSEEnergySummaryBody" id="consumer-summary">
                        <div className="SSEEnergySummaryLargeHeader">
                            <div className={!isMobile && 'space-between'}>
                                <p className="SSEEnergySummaryTotalWattsLabel">
                                    Total watts added
                                </p>
                                {isMobile && (
                                    <p className="SSEEnergySummaryTotalWattsValue">
                                        {numberWithCommas(
                                            parseFloat(
                                                totalWattsAdded,
                                                10,
                                            ).toFixed(2),
                                        )}{' '}
                                        <sup>watts</sup>
                                    </p>
                                )}
                                <div
                                    className="space-between"
                                    style={{
                                        gridColumnGap: '6px',
                                        width: '160px',
                                        margin: isMobile && '10px 0px',
                                    }}
                                >
                                    <div className="SSEEnergySummaryBodyUsageLabel">
                                        <DayIcon />
                                        <span>Total day usage</span>
                                    </div>
                                    <span className="SSEEnergySummaryBodyUsageValue">
                                        {numberWithCommas(
                                            parseFloat(
                                                totalDayUsage,
                                                10,
                                            ).toFixed(2),
                                        )}
                                        <span className="SSEUnitStyle">
                                            &nbsp;wh
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div
                                className="space-between"
                                style={{
                                    marginTop: '4px',
                                    alignItems: 'center',
                                }}
                            >
                                {!isMobile && (
                                    <p className="SSEEnergySummaryTotalWattsValue">
                                        {numberWithCommas(
                                            parseFloat(
                                                totalWattsAdded,
                                                10,
                                            ).toFixed(2),
                                        )}{' '}
                                        <sup>watts</sup>
                                    </p>
                                )}
                                <div
                                    className="space-between"
                                    style={{
                                        gridColumnGap: '6px',
                                        width: '160px',
                                        marginBottom: isMobile && '10px',
                                    }}
                                >
                                    <div className="SSEEnergySummaryBodyUsageLabel">
                                        <NightIcon />
                                        <span>Total night usage</span>
                                    </div>
                                    <span className="SSEEnergySummaryBodyUsageValue">
                                        {numberWithCommas(
                                            parseFloat(
                                                totalNightUsage,
                                                10,
                                            ).toFixed(2),
                                        )}
                                        <span className="SSEUnitStyle">
                                            &nbsp;wh
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <p className="SSEEnergySummaryResponseCardAppliances">
                                {energyNeeds?.map((item, i) => (
                                    <span
                                        key={i}
                                        className="GYOEnergySummaryResponseCardAppliancesSpan"
                                    >
                                        {energyNeeds?.length !== 1 &&
                                            energyNeeds?.length - 1 === i && (
                                                <span>and </span>
                                            )}
                                        {item?.quantity}{' '}
                                        <span>{item?.name}</span>
                                        {energyNeeds?.length - 1 !== i && ', '}
                                    </span>
                                ))}
                            </p>

                            <div>
                                <ConsumerButton
                                    btnBgColor="#E2EEFF"
                                    btnTextColor="#004AAD"
                                    btnHeight="46px"
                                    btnWidth={isMobile ? '100%' : '297px'}
                                    handleClick={() =>
                                        setShowApplianceModal(true)
                                    }
                                    borderRadius="10px"
                                >
                                    <ConsumerAddIcon
                                        className="SSEConsumerAddIcon"
                                        style={{ verticalAlign: 'sub' }}
                                    />
                                    <span
                                        style={{
                                            paddingLeft: '5px',
                                        }}
                                    >
                                        Add new appliance
                                    </span>
                                </ConsumerButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ConsumerEnergyNeedsSummary.propTypes = {
    setShowApplianceModal: PropTypes.func,
    energyNeeds: PropTypes.array,
    totalDayUsage: PropTypes.number,
    totalWattsAdded: PropTypes.number,
    totalNightUsage: PropTypes.number,
    page: PropTypes.string,
}

export default ConsumerEnergyNeedsSummary
