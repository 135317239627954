export const landingPageEvents = {
    email: {
        action: 'Email entry',
        category: 'Energy Savings Form',
        label: 'Email entry',
    },
    firstName: {
        action: 'First name entry',
        category: 'Energy Savings Form',
        label: 'First name entry',
    },
    phone: {
        action: 'Phone number entry',
        category: 'Energy Savings Form',
        label: 'Phone number entry',
    },
    residential: {
        action: 'Select Residential',
        category: 'Energy Savings Form',
        label: 'Select Residential',
    },
    business: {
        action: 'Select Business',
        category: 'Energy Savings Form',
        label: 'Select Business',
    },
    getStarted: {
        action: 'Click Get started',
        category: 'Energy Savings Form',
        label: 'Click Get started',
    },
    allSystems: {
        action: 'Select all systems',
        category: 'Energy Savings Form',
        label: 'Select all systems',
    },
    formCompletion: {
        action: 'Entry form completion',
        category: 'Energy Savings Form',
        label: 'Entry form completion',
    },
    solarSavingsReport: {
        action: 'Email savings report',
        category: 'Solar savings calculator',
        label: 'Email savings report',
    },
}
