//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useEffect, useState } from 'react'

import { useQuery } from 'react-query'
import styles from './bankdetails.module.scss'
import Button from '../../../components/Button'
import Toast from '../../../components/Toast'
import { InlineLoader } from '../../../components/Loader'
import { ReactComponent as BankIcon } from '../../../assets/images/bank-icon.svg'
import { ReactComponent as BankDetailsImage } from '../../../assets/images/bank-details-image.svg'
import { getBankDetailsApi } from '../../../api/profile'
import SecondaryModal from '../../../components/Modal/SecondaryModal'
import NubanAccountDetails from '../../Onboarding/Steps/NubanAccountDetails'

const BankDetails = () => {
    const {
        LeftWrapper,
        Header,
        SubHeader,
        BankName,
        BankDetailsInfo,
        BankDetailsMainWrapper,
        BankDetailsWrapper,
        BankAccountNumber,
        ImageWrapper,
        SaveBankDetails,
        ReplaceBank,
        BankLogo,
        AccountName,
    } = styles

    const [bankName, setBankName] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [accountName, setAccountName] = useState('')
    const [loading, setIsLoading] = useState(false)
    const [showNubanFormModal, setShowNubanFormModal] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const { data, isLoading, refetch } = useQuery(
        'getBankDetailsApi',
        () => getBankDetailsApi(),
        {
            enabled: false,
            retry: false,
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch your information. Try Again')
            },
            onSuccess: data => {
                setBankName(data?.data?.data?.bank_name)
                setAccountName(data?.data?.data?.bank_account_name)
                setAccountNumber(data?.data?.data?.bank_account)
                setIsLoading(false)
            },
        },
    )

    const handleShowNubanModal = () => {
        setShowNubanFormModal(true)
    }

    useEffect(() => {
        refetch()
    }, [])

    const handleSave = () => {
        refetch()
        setIsLoading(true)
        setSuccessMessage('Saved successfully')
    }

    return (
        <>
            <SecondaryModal
                noPadding="40px"
                showModal={showNubanFormModal}
                onCancel={() => setShowNubanFormModal(false)}
                modalWidth="624px"
                content={
                    <NubanAccountDetails
                        page="profilePage"
                        profilePageNextHandler={() => {
                            setShowNubanFormModal(false)
                            refetch()
                        }}
                    />
                }
            />
            {toastError ? (
                <Toast messageType="error" message={errorMessage} />
            ) : data ? (
                <Toast messageType="success" message={successMessage} />
            ) : (
                ''
            )}
            {isLoading ? (
                'Loading...'
            ) : (
                <div className={BankDetailsMainWrapper}>
                    <div className={BankDetailsInfo}>
                        <div className={LeftWrapper}>
                            <p className={Header}>Account Details</p>
                            <p className={SubHeader}>
                                Customize your account name
                            </p>
                        </div>
                        {data?.data?.data?.bank_code === null ? (
                            <Button
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                btnWidth="160px"
                                btnHeight="56px"
                                handleClick={handleShowNubanModal}
                            >
                                Add Bank Details
                            </Button>
                        ) : (
                            <div>
                                <div className={BankDetailsWrapper}>
                                    <div>
                                        <h1 className={BankName}>{bankName}</h1>
                                        <div style={{ marginTop: '55px' }}>
                                            <p className={BankAccountNumber}>
                                                {accountNumber}
                                            </p>
                                            <p className={AccountName}>
                                                {accountName}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={ImageWrapper}>
                                            <BankDetailsImage />
                                        </div>
                                        <div className={BankLogo}>
                                            <BankIcon />
                                        </div>
                                    </div>
                                </div>
                                <>
                                    <div
                                        className={ReplaceBank}
                                        onClick={handleShowNubanModal}
                                    >
                                        <p>Replace Bank</p>
                                    </div>
                                    <div className={SaveBankDetails}>
                                        <Button
                                            btnBgColor="var(--blue)"
                                            btnTextColor="var(--white)"
                                            btnWidth="160px"
                                            btnHeight="56px"
                                            handleClick={handleSave}
                                        >
                                            {loading ? (
                                                <InlineLoader />
                                            ) : (
                                                'Save Changes'
                                            )}
                                        </Button>
                                    </div>
                                </>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default BankDetails
