import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import FormBox from '../../components/FormBox'
import ButtonComponent from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import FormTitleBar from '../../components/FormTitleBar'
import { InputFields } from '../../components/InputFields'
import { emailVerificationApi } from '../../api/signup/emailVerification'
import Confirmation from '../../components/Confirmation'
import errorImage from '../../assets/images/deletemodal-icon.svg'
import { useHistory } from 'react-router-dom'
import { setToken } from '../../utils/auth'

import styles from './depstaffsignup.module.scss'

const StepOneDEPStaffSignUp = props => {
    const { ButtonWrapper, CheckBoxWrapper, CheckboxLink } = styles

    const [checked, setChecked] = useState(false)
    const [verified, setVerified] = useState(false)
    const [errors, setErrors] = useState(false)

    // eslint-disable-next-line react/prop-types
    const parsed = queryString.parse(props.location.search)

    const formattedParsed = {
        ...parsed,
        email: parsed?.email?.split(' ').join('+'),
    }

    const { refetch, data } = useQuery(
        'dep email verification',
        () => emailVerificationApi(formattedParsed),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setErrors(false)
                setVerified(true)
            },
            onError: () => {
                setErrors(true)
                setVerified(false)
            },
        },
    )

    useEffect(() => {
        refetch()
    }, [refetch])

    const handleChecked = () => {
        setChecked(!checked)
    }

    const history = useHistory()

    const onButtonClick = () => {
        history.push('/')
    }

    if (verified) {
        setToken(data?.data?.data?.token?.access)
    }

    return (
        <>
            {errors && (
                <Confirmation
                    imgUrl={errorImage}
                    header="Verification Unsuccessful"
                    subHeader="We’ve solved the problems that make it difficult for energy providers to transition"
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    onButtonClick={onButtonClick}
                    btnText="Go Home"
                />
            )}

            {verified && (
                <FormBox>
                    <>
                        <FormTitleBar
                            title="Hi there!!"
                            subtitle="Kindly confirm email address and click continue"
                        />
                        <InputFields
                            title="Email Address"
                            name="email"
                            inputWidth="100%"
                            value={formattedParsed?.email}
                            prefilled
                        />
                        <div style={{ marginTop: 37 }}>
                            <Checkbox
                                handleChecked={handleChecked}
                                signupchecked={checked}
                                className={CheckBoxWrapper}
                            >
                                I agree to the
                                <Link
                                    to="/terms-of-service"
                                    target="_blank"
                                    className={CheckboxLink}
                                >
                                    terms of service
                                </Link>
                                and
                                <Link
                                    to="/privacy-policy"
                                    target="_blank"
                                    className={CheckboxLink}
                                >
                                    privacy policy
                                </Link>
                            </Checkbox>
                        </div>

                        <div
                            className={ButtonWrapper}
                            style={{ marginTop: 40 }}
                        >
                            <ButtonComponent
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                disabled={checked === false}
                                handleClick={() =>
                                    history.push('/workspace/member-signup')
                                }
                            >
                                Continue
                            </ButtonComponent>
                        </div>
                    </>
                </FormBox>
            )}
        </>
    )
}

export default StepOneDEPStaffSignUp
