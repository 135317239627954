import { currencyFormatter } from '../../../utils/currencyFormatter'
import { convertToSentenceCase } from '../../../utils/formatting'

export const formatPackageComponents = components => {
    let formattedValues = []
    components.forEach(component => {
        formattedValues.push({
            name: convertToSentenceCase(component?.component?.name),
            quantity: component?.quantity,
            amount: '₦' + currencyFormatter(component?.cost),
            viewLink: `/app/products/view-component/${component?.component?.id}`,
            editLink: `/app/products/edit-component/${component?.component?.id}`,
            isGlobal: component?.component?.is_global,
            id: component?.component?.id,
            providerId: component?.component?.provider_id,
            created_at: component?.component?.created_at,
        })
    })

    return formattedValues
}
