/* eslint-disable react/prop-types */
import './circularprogressbar.scss'

const CircularProgressBar = props => {
    let {
        size = 18,
        progress = 0,
        trackWidth = 10,
        trackColor = `transparent`,
        indicatorWidth = 10,
        indicatorColor = `#18B661`,
        indicatorCap = `round`,
        spinnerMode = false,
        spinnerSpeed = 1,
        completed,
    } = props

    const center = size / 2,
        radius =
            center -
            (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
        dashArray = 2 * Math.PI * radius,
        dashOffset = dashArray * ((100 - progress) / 100)
    return (
        <>
            <div
                className="svg-pi-wrapper"
                style={{ width: size, height: size }}
            >
                <svg className="svg-pi" style={{ width: size, height: size }}>
                    <circle
                        className="svg-pi-track"
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={trackColor}
                        strokeWidth={trackWidth}
                    />
                    <circle
                        className={`svg-pi-indicator ${
                            spinnerMode ? 'svg-pi-indicator--spinner' : ''
                        }`}
                        style={{ animationDuration: spinnerSpeed * 1000 }}
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={indicatorColor}
                        strokeWidth={indicatorWidth}
                        strokeDasharray={dashArray}
                        strokeDashoffset={dashOffset}
                        strokeLinecap={indicatorCap}
                    />
                </svg>

                <div className="svg-pi-label">
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10 19.1667C4.93729 19.1667 0.833374 15.0627 0.833374 10C0.833374 4.93725 4.93729 0.833333 10 0.833333C15.0628 0.833333 19.1667 4.93725 19.1667 10C19.1667 15.0627 15.0628 19.1667 10 19.1667ZM9.08612 13.6667L15.567 7.18492L14.2708 5.88875L9.08612 11.0743L6.49287 8.48108L5.19671 9.77725L9.08612 13.6667Z"
                            fill={completed ? '#18B661' : '#F2F2F2'}
                        />
                    </svg>
                </div>
            </div>
        </>
    )
}

export default CircularProgressBar
