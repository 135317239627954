import { useState } from 'react'
import '../identityinformation.scss'
import attachIcon from 'src/assets/images/attach-blue.svg'
import helpIcon from 'src/assets/images/help-circle-black.svg'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { useSSERetrieveBasicInfo } from 'src/api/shoppingExperience/kyc/queries'
import { formatGender, getAgeInYearsFromDob } from 'src/utils/formatting'

const IdCardDataSection = ({ title, handleOverride }) => {
    const { sseBasicInfoData } = useSSERetrieveBasicInfo({
        enabled: true,
    })
    const imageAnalysis =
        sseBasicInfoData?.data?.data?.consumer?.user?.avatar_meta_data
    const customerSelfie =
        sseBasicInfoData?.data?.data?.consumer?.user?.avatar_url
    const idVerificationDetailsAnalysis =
        sseBasicInfoData?.data?.data?.identity_verification?.context
    const idCardDetails = idVerificationDetailsAnalysis?.verified_data
    const isVoterCard =
        sseBasicInfoData?.data?.data?.identity_verification?.type ===
        'Voters Card'

    const allChecksPassed = idVerificationDetailsAnalysis?.all_checks_passed
    const isGenderMatch =
        idVerificationDetailsAnalysis?.comparison_data?.filter(
            el => el.criteria === 'gender',
        )[0]?.match
    const isAgeMatch = idVerificationDetailsAnalysis?.comparison_data?.filter(
        el => el.criteria === 'dob',
    )[0]?.match
    const isPhotoMatch = idVerificationDetailsAnalysis?.comparison_data?.filter(
        el => el.criteria === 'photo',
    )[0]?.match
    const min_age = imageAnalysis?.facial_recognition?.age_analysis?.value[0]
    const max_age = imageAnalysis?.facial_recognition?.age_analysis?.value[1]
    const gender = imageAnalysis?.facial_recognition?.gender_analysis?.value
    const [showModal, setShowModal] = useState(false)
    const [showFaceCheckModal, setFaceCheckModal] = useState(false)

    const NotAvailable = () => {
        return (
            <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="7.5" cy="7.5" r="7.5" fill="#FFF5DA" />
                <path
                    d="M7.62516 11.4792C9.75376 11.4792 11.4793 9.75364 11.4793 7.62504C11.4793 5.49644 9.75376 3.77087 7.62516 3.77087C5.49657 3.77087 3.771 5.49644 3.771 7.62504C3.771 9.75364 5.49657 11.4792 7.62516 11.4792Z"
                    stroke="#B28717"
                    strokeWidth="0.770833"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.50391 6.46885C6.59452 6.21126 6.77337 5.99406 7.00878 5.8557C7.2442 5.71735 7.52098 5.66677 7.79011 5.71293C8.05924 5.7591 8.30335 5.89902 8.4792 6.10792C8.65505 6.31681 8.75129 6.5812 8.75089 6.85426C8.75089 7.6251 7.59464 8.01051 7.59464 8.01051"
                    stroke="#B28717"
                    strokeWidth="0.770833"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.625 9.55212H7.62885"
                    stroke="#B28717"
                    strokeWidth="0.770833"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )
    }

    return (
        <>
            <SecondaryModal
                v2
                showModal={showModal}
                onCancel={() => setShowModal(false)}
                modalHeight="450px"
                modalWidth="550px"
                modalClass="adminModal"
                closable
                title="Image returned"
                content={
                    <>
                        <div className="imageModalContainer">
                            <img
                                src={
                                    'data:image/png;base64,' +
                                    idCardDetails?.photo
                                }
                                alt={idCardDetails?.firstName}
                            />
                        </div>
                    </>
                }
            />
            <SecondaryModal
                v2
                showModal={showFaceCheckModal}
                onCancel={() => setFaceCheckModal(false)}
                modalHeight="450px"
                modalWidth="550px"
                modalClass="adminModal"
                closable
                title="Image returned"
                content={
                    <>
                        <div className="imageModalContainer">
                            <img
                                src={customerSelfie}
                                alt="Image returned from ID card"
                            />
                        </div>
                    </>
                }
            />
            <div className="idCardMetaDataContainer">
                <div className="titleSection">
                    <div className="title">
                        <h3>{title}</h3>
                        <Tooltip
                            overlayStyle={{
                                padding: '9px 8px',
                                maxWidth: '178px',
                            }}
                            overlayInnerStyle={{
                                fontSize: '12px',
                                fontFamily: 'SF-Pro-Display',
                            }}
                            color="#070C18"
                            placement="bottom"
                            title="This data is being matched with the data result from the selfie/photo the consumer added on their KYC"
                        >
                            <img src={helpIcon} />
                        </Tooltip>
                    </div>
                    {allChecksPassed && (
                        <div className={`tag success`}>PASSED</div>
                    )}
                    {!allChecksPassed && (
                        <button
                            className="override_btn"
                            onClick={handleOverride}
                        >
                            Override
                        </button>
                    )}
                </div>
                <div className="identityMetaData">
                    <div>
                        {isGenderMatch ? (
                            <h4>Gender</h4>
                        ) : (
                            <div className={`check_failure`}>
                                <h4>Gender</h4> <div>FAILED</div>
                            </div>
                        )}
                        <p>{formatGender(idCardDetails?.gender)}</p>
                    </div>
                    <div>
                        {isAgeMatch ? (
                            <h4>Age</h4>
                        ) : !isAgeMatch && isVoterCard ? (
                            <h4>Age</h4>
                        ) : (
                            <div className={`check_failure`}>
                                <h4>Age</h4> <div>FAILED</div>
                            </div>
                        )}
                        {!isAgeMatch && isVoterCard ? (
                            <div className={`check_not_available`}>
                                <div>
                                    <span>N/A</span>{' '}
                                    <Tooltip
                                        overlayStyle={{
                                            padding: '9px 8px',
                                            maxWidth: '178px',
                                        }}
                                        overlayInnerStyle={{
                                            fontSize: '12px',
                                            fontFamily: 'SF-Pro-Display',
                                        }}
                                        color="#070C18"
                                        placement="bottom"
                                        title="The third party is not able to spool the age data for a voter's card"
                                    >
                                        <span className="icon">
                                            <NotAvailable />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        ) : (
                            <p>
                                {getAgeInYearsFromDob(
                                    idCardDetails?.birthdate,
                                ) || '-'}
                            </p>
                        )}
                    </div>
                    <div>
                        <h4>Date of birth</h4>
                        {!isAgeMatch && isVoterCard ? (
                            <div className={`check_not_available`}>
                                <div>
                                    <span>N/A</span>{' '}
                                    <Tooltip
                                        overlayStyle={{
                                            padding: '9px 8px',
                                            maxWidth: '178px',
                                        }}
                                        overlayInnerStyle={{
                                            fontSize: '12px',
                                            fontFamily: 'SF-Pro-Display',
                                        }}
                                        color="#070C18"
                                        placement="bottom"
                                        title="The third party is not able to spool the date of birth data for a voter's card"
                                    >
                                        <span className="icon">
                                            <NotAvailable />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        ) : (
                            <p>{idCardDetails?.birthdate}</p>
                        )}
                    </div>
                    <div>
                        {isPhotoMatch ? (
                            <h4>Image returned</h4>
                        ) : !isAgeMatch && isVoterCard ? (
                            <h4>Image returned</h4>
                        ) : (
                            <div className={`check_failure`}>
                                <h4>Image returned</h4> <div>FAILED</div>
                            </div>
                        )}

                        {!isAgeMatch && isVoterCard ? (
                            <div className={`check_not_available`}>
                                <div>
                                    <span>N/A</span>{' '}
                                    <Tooltip
                                        overlayStyle={{
                                            padding: '9px 8px',
                                            maxWidth: '178px',
                                        }}
                                        overlayInnerStyle={{
                                            fontSize: '12px',
                                            fontFamily: 'SF-Pro-Display',
                                        }}
                                        color="#070C18"
                                        placement="bottom"
                                        title="The third party is not able to spool the image data for a voter's card"
                                    >
                                        <span className="icon">
                                            <NotAvailable />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={'imageAnalyzed'}
                                onClick={() => setShowModal(true)}
                            >
                                <img
                                    src={attachIcon}
                                    width="18px"
                                    alt="Image returned from ID card"
                                />
                                <p>Image</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="titleSection">
                    <div className="title">
                        <h3>Compare with data from image</h3>
                        <Tooltip
                            overlayStyle={{
                                padding: '9px 8px',
                                maxWidth: '178px',
                            }}
                            overlayInnerStyle={{
                                fontSize: '12px',
                                fontFamily: 'SF-Pro-Display',
                            }}
                            color="#070C18"
                            placement="bottom"
                            title="This is the data set about their business submitted by the consumer when filling their KYC"
                        >
                            <img src={helpIcon} />
                        </Tooltip>
                    </div>
                </div>
                <div className="identityMetaData">
                    <div>
                        <h4>Gender</h4>
                        <p>{formatGender(gender)}</p>
                    </div>
                    <div>
                        <h4>Age</h4>
                        <p>
                            {min_age}-{max_age}
                        </p>
                    </div>
                    <div>
                        <h4>Face check</h4>
                        <div
                            className={'imageAnalyzed'}
                            onClick={() => setFaceCheckModal(true)}
                        >
                            <img src={attachIcon} width="18px" />
                            <p>Image</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

IdCardDataSection.propTypes = {
    title: PropTypes.string,
    handleOverride: PropTypes.func,
}

export default IdCardDataSection
