import PropTypes from 'prop-types'
import styles from './logincomponent.module.scss'
import { InputFields } from '../InputFields'
import { ConsumerButton } from 'src/components/Button'
import ChatCTA from 'src/components/ChatCTA'
import { SelectCountryCode } from '../InputFields/SelectCountryCode'

import VerifyLoginOTP from './components/VerifyLoginOTP'
import Toast from '../Toast'
import { formatPhoneNumber } from 'src/utils/formatting'
import { isMobile } from 'src/utils/mediaQueries'
import useLoginComponent from './hooks/useLoginComponent'

const LoginComponent = ({ method, setMethod, userType = 'consumer' }) => {
    const { component, spanBtn, inputAndBtn, button } = styles
    const {
        showModal,
        errors,
        toastError,
        errorMessage,
        newMethod,
        subHeading,
        handleChangeMethod,
        handleContinueClick,
        setShowModal,
        emailAddress,
        phoneNumber,
        setPhoneNumber,
        setEmailAddress,
        initiateLoginMutation,
        handleBlur,
    } = useLoginComponent(method, setMethod, userType)

    return (
        <>
            <VerifyLoginOTP
                showModal={showModal}
                setShowModal={setShowModal}
                method={method}
                inputs={{
                    email: emailAddress,
                    phone: formatPhoneNumber(phoneNumber),
                }}
                userType={userType}
            />
            <div className={component} data-testid="login-component">
                {toastError && (
                    <Toast
                        messageType={'error-secondary'}
                        message={errorMessage}
                    />
                )}
                <h1>Hello, Welcome</h1>
                <p>{subHeading}</p>
                <div className={inputAndBtn}>
                    {method === 'phone' ? (
                        <SelectCountryCode
                            inputValue={phoneNumber}
                            handleChange={e => setPhoneNumber(e.target.value)}
                            errorMessage={errors.phoneNumber}
                            name={'phoneNumber'}
                            title="Phone Number"
                            onBlur={handleBlur}
                        />
                    ) : (
                        <InputFields
                            title={"What's your email?"}
                            name={'emailAddress'}
                            inputValue={emailAddress}
                            handleChange={e => setEmailAddress(e.target.value)}
                            errorMessage={errors.emailAddress}
                            onBlur={handleBlur}
                        />
                    )}
                    <span className={spanBtn}>
                        <span
                            role="button"
                            onClick={() => handleChangeMethod()}
                            data-testid="method-switch"
                        >
                            Use {newMethod} instead
                        </span>
                    </span>
                </div>
                <div className={button}>
                    <ConsumerButton
                        btnWidth={isMobile ? '157px' : '334px'}
                        btnHeight={'53px'}
                        btnTextColor={'#ffffff'}
                        btnBgColor={'#004aad'}
                        handleClick={() => handleContinueClick()}
                        loading={initiateLoginMutation.isLoading}
                    >
                        Continue
                    </ConsumerButton>
                </div>
                <ChatCTA justifyContent="center" />
            </div>
        </>
    )
}
LoginComponent.propTypes = {
    method: PropTypes.oneOf(['phone', 'email']).isRequired,
    setMethod: PropTypes.func.isRequired,
    userType: PropTypes.oneOf(['consumer', 'cluster-rep']).isRequired,
}
export default LoginComponent
