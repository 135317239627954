import PackageImage from 'src/assets/images/package.svg'
import Info from 'src/assets/images/info.svg'
import PropTypes from 'prop-types'
import styles from './description-component.module.scss'

const DescriptionComponent = ({ text, type, wrapperStyle }) => {
    const { RightWrapper, RightWrapperConsumer, Header, InfoIcon } = styles
    return (
        <div
            className={
                type === 'consumer' ? RightWrapperConsumer : RightWrapper
            }
            style={wrapperStyle}
        >
            <img src={Info} alt="info-icon" className={InfoIcon} />
            {type === 'package' ? (
                <p className={Header}>
                    <strong>Pro Tip:</strong> {text}
                </p>
            ) : (
                <div>{text}</div>
            )}
            <img src={PackageImage} alt="solar" />
        </div>
    )
}

DescriptionComponent.propTypes = {
    text: PropTypes.any,
    type: PropTypes.string,
    wrapperStyle: PropTypes.object,
}

export default DescriptionComponent
