import { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useUpdateProfile } from './useUpdateProfile'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateConsumerProfile,
    updatedConsumerProfile,
} from 'src/redux/reducers/admin/profiles/create-profile'

export const useNewProfile = () => {
    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const dispatch = useDispatch()
    const consumer_profile_data = useSelector(updatedConsumerProfile)

    const initialProfileData = {
        name: '',
        description: '',
        customer_type: '',
        min_capacity: '',
        max_capacity: '',
        appliances: [],
        packages: [],
        subscription_cluster_code: '',
        lease_to_own_cluster_code: '',
        image_url: '',
        sort_by: 'ENERGY_CAPACITY_ASCENDING',
        group_id: '',
    }

    const step = location.state?.step ?? 1
    const isPrivateProfile =
        consumer_profile_data?.promotional_type?.toLowerCase() === 'private' &&
        consumer_profile_data?.is_promotional === true
    const isPublicProfile =
        consumer_profile_data?.promotional_type?.toLowerCase() === 'public' &&
        consumer_profile_data?.is_promotional === true
    const [profileData, setProfileData] = useState(consumer_profile_data)
    const [pendingUpdates, setPendingUpdates] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [showActionModal, setShowActionModal] = useState(false)
    const [profileId, setProfileId] = useState('')
    const onUpdateSucces = () => {
        setShowActionModal(true)
    }

    const formatPendingUpdates = updates => {
        const formattedUpdates = { ...updates }

        if (updates.min_capacity) {
            formattedUpdates.min_capacity = JSON.parse(updates.min_capacity)
        }

        if (updates.max_capacity) {
            formattedUpdates.max_capacity = JSON.parse(updates.max_capacity)
        }

        return formattedUpdates
    }

    const { handleUpdateProfile, isLoading: updateProfileLoading } =
        useUpdateProfile({
            data: {
                ...formatPendingUpdates(pendingUpdates),
                id: profileId,
            },
            onSuccess: onUpdateSucces,
        })

    const backNavTitle =
        step === 1 ? `Back to consumer profile${isEdit ? '' : 's'}` : 'Go Back'

    const backToProfiles = () => {
        if (isEdit) {
            step === 1
                ? history.push(`/admin/consumer-profiles/${profileId}`)
                : history.push({
                      pathname: `/admin/consumer-profiles/edit-consumer-profile/${profileId}`,
                      state: { step: 1 },
                  })
        } else {
            step === 1
                ? history.goBack()
                : history.push({
                      pathname: '/admin/consumer-profiles/add-consumer-profile',
                      state: { step: 1 },
                  })
        }
    }

    const actionModalHandler = () => {
        setShowActionModal(false)
    }

    const closeActionModal = () => {
        setShowActionModal(false)
    }

    const handleSubmit = () => {
        if (isEdit) {
            handleUpdateProfile()
            setPendingUpdates({})
        } else {
            !isPrivateProfile && !isPublicProfile
                ? history.replace({
                      pathname: location.pathname,
                      state: { profileData, step: 2 },
                  })
                : null

            dispatch(updateConsumerProfile(profileData))
            history.push({
                pathname: '/admin/consumer-profiles/add-package',
                state: {
                    profileData,
                    isEdit,
                    backUrl: window.location.pathname,
                    step: isPrivateProfile || isPublicProfile ? 1 : 2,
                },
            })
        }
    }

    useEffect(() => {
        const savedData = location.state?.profileData

        if (savedData) {
            setProfileData(savedData)
        }
    }, [location])

    useEffect(() => {
        if (
            location.pathname?.includes(
                '/admin/consumer-profiles/edit-consumer-profile',
            )
        ) {
            if (params?.id) {
                setProfileId(params.id)
            } else {
                history.push('/admin/consumer-profiles')
            }
        }
    }, [location, params, history])

    return {
        profileData,
        setProfileData,
        backToProfiles,
        setIsEdit,
        handleSubmit,
        setPendingUpdates,
        isLoading: updateProfileLoading,
        isEdit,
        profileId,
        pendingUpdates,
        initialProfileData,
        showActionModal,
        setShowActionModal,
        actionModalHandler,
        closeActionModal,
        backNavTitle,
    }
}
