import { createContext, useContext } from 'react'

export const PersonalInfoContext = createContext({
    persistInfo: () => {},
    residentialInfoInputs: {},
    setResidentialInfoInputs: () => {},
    persistResidentialInfoLoading: false,
    errorPersonalInfo: undefined,
    refetchInfo: () => {},
    sseBasicInfoIsFetching: false,
    setFieldErrors: () => {},
    modalType: '',
    setModalType: () => {},
    setActiveTab: () => {},
    activeTab: '1',
    personalInfoFormik: null,
    employmentInfoFormik: {
        values: {},
        touched: {},
        errors: {},
        handlechange: () => {},
        handleblur: () => {},
    },
    assistedKycResidenceUpdateLoading: false,
    residenceUpdateJustCompleted: false,
    residenceConsumerName: '',
    setResidenceUpdateJustCompleted: () => {},
    setResidenceConsumerName: () => {},
})

export const usePersonalInfoContext = () => useContext(PersonalInfoContext)
