import residenceIconNew from 'src/assets/images/residence-icon-new.png'
import businessIconNew from 'src/assets/images/business-icon-new.png'

export const useTypeDetailsNew = [
    {
        icon: residenceIconNew,
        title: 'Home or Stalls/Shops',
        description:
            'Suitable for remote workers, shop owners, residential homes and apartments.',
        value: 'RESIDENTIAL',
    },
    {
        icon: businessIconNew,
        title: 'Registered Business',
        description:
            'Suitable for NGOs, LLCs, SMEs, or large businesses with CAC registration.',
        value: 'BUSINESS',
    },
]

export const urgencyOptions = [
    'Not sure - exploring options',
    'At some point this year',
    'Within the next month or so',
    'Within the next 2 weeks',
    'ASAP, within the next few days',
]

export const urgencyOptionsMap = {
    [urgencyOptions[0]]: 'NOT_SURE',
    [urgencyOptions[1]]: 'THIS_YEAR',
    [urgencyOptions[2]]: 'NEXT_MONTH',
    [urgencyOptions[3]]: 'IN_2_WEEKS',
    [urgencyOptions[4]]: 'IN_FEW_DAYS',
}

export const heardAboutUsOptions = [
    { label: 'Google Search', value: 'GOOGLE SEARCH' },
    { label: 'Family/friend', value: 'FAMILY/FRIEND' },
    { label: 'Facebook', value: 'FACEBOOK' },
    { label: 'Instagram', value: 'INSTAGRAM' },
    { label: 'Twitter', value: 'TWITTER' },
    { label: 'LinkedIn', value: 'LINKEDIN' },
    { label: 'Bill board', value: 'BILL BOARD' },
    { label: 'Fliers', value: 'FLIERS' },
    { label: 'Supermarket', value: 'SUPERMARKET' },
    { label: 'Another energy provider', value: 'ANOTHER ENERGY PROVIDER' },
    { label: 'Stickers on SunFi systems', value: 'STICKERS ON SUNFI SYSTEMS' },
    { label: 'Others', value: 'OTHERS' },
]
