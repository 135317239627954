/* eslint-disable no-undef */
import { useState } from 'react'
import { Row, Col, Collapse } from 'antd'
import Button from '../Button'
import styles from './faq.module.scss'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { ReactComponent as BlueMinusIcon } from '../../assets/images/minus-icon.svg'
import { ReactComponent as BluePlusIcon } from '../../assets/images/plus-icon.svg'
import SectionHeader from './../SectionHeader'
import { ReactComponent as Rectangle } from './../../assets/images/rectangle.svg'
import { faq3, faq3b, faq3c, faq4, faq4b, faq4c, faq5 } from './data'
import ChatCTA from '../ChatCTA'

const FrequentlyAskedQuestions = ({ page, useV2 = false }) => {
    const [thiskey, setThisKey] = useState('0')

    const {
        Wrapper,
        Wrapper3,
        Faqs,
        Faqs2,
        PanelDropdown,
        PanelDropdown2,
        CollapseRow,
        Faq3,
        FaqButton,
        Title,
        MinusIcon,
        PlusIcon,
        CollapseStyle,
        WrapperV2,
        SectionV2,
        HeaderV2,
        HeaderV2Text,
        CollapseStyleV2,
        SSEHeaderWrapper,
        SSEColTitle,
    } = styles
    const { Panel } = Collapse

    const changeKey = key => {
        setThisKey(key)
    }

    const HeadingTitle = title => {
        return <p className={Title}>{title}</p>
    }

    const faqListing = texts => {
        return texts.map(item => {
            return (
                <Collapse
                    key={item.id}
                    onChange={changeKey}
                    activeKey={thiskey}
                    ghost
                    expandIcon={({ isActive }) => {
                        if (isActive) {
                            return (
                                <BlueMinusIcon
                                    className={MinusIcon}
                                    role={'presentation'}
                                />
                            )
                        } else {
                            return (
                                <BluePlusIcon
                                    className={PlusIcon}
                                    role={'presentation'}
                                />
                            )
                        }
                    }}
                    expandIconPosition="right"
                    accordion
                    className={`${CollapseStyle} ${
                        useV2 ? CollapseStyleV2 : ''
                    }`}
                >
                    <Panel
                        header={
                            parseInt(thiskey) === item.id
                                ? HeadingTitle(item.title)
                                : item.title
                        }
                        key={item.id}
                        id={
                            page === 'sse-home' ? PanelDropdown2 : PanelDropdown
                        }
                        style={{
                            backgroundColor:
                                parseInt(thiskey) === item.id
                                    ? '#F6F6F6'
                                    : 'var(--white)',
                            boxShadow: parseInt(thiskey) === item.id && 'none',
                        }}
                    >
                        <p
                            style={{
                                marginLeft: '10px',
                                padding:
                                    page === 'sse-home' ? '0 24px 0 13px' : '',
                                color: 'rgba(1, 26, 60, 0.8)',
                            }}
                        >
                            {parse(item.message)}
                        </p>
                    </Panel>
                </Collapse>
            )
        })
    }

    const toggleFaq = () => {
        const x = document.getElementById('tog')
        x.style.display = 'block'
        if (x.style.display === 'block') {
            const hideBtn = document.getElementById('hide')
            hideBtn.style.display = 'none'
        }
    }
    return (
        <div
            className={`${page === 'sse-home' ? Wrapper3 : Wrapper} ${
                useV2 ? WrapperV2 : ''
            }`}
            id="faq"
        >
            <div className={`${useV2 ? SectionV2 : ''}`}>
                {useV2 ? (
                    <div className={HeaderV2}>
                        <div className={HeaderV2Text}>
                            <h2>Frequently asked questions</h2>
                            <p>
                                SunFi is leveraging financial technology to
                                scale clean energy experiences to every
                                consumer. We’ve built an energy fintech
                                ecosystem which provides solar installers tools
                                to simplify access to clean energy experiences
                                for millions of consumers.{' '}
                            </p>
                        </div>
                    </div>
                ) : page === 'sse-home' ? (
                    <div className={SSEHeaderWrapper}>
                        <div className={HeaderV2}>
                            <div className={HeaderV2Text}>
                                <h2>Frequently asked questions</h2>
                            </div>
                        </div>

                        <ChatCTA
                            text="Have questions?"
                            width="fit-content"
                            textOneFontSize="14px"
                        />
                    </div>
                ) : (
                    <SectionHeader
                        title={'Frequently Asked Questions'}
                        heading={'GET ANSWERS TO YOUR QUESTIONS'}
                        icon={<Rectangle style={{ marginTop: '35px' }} />}
                        textColor="var(--black)"
                        type="faq"
                    />
                )}
                <div className={page === 'sse-home' ? Faqs2 : Faqs}>
                    <Row className={CollapseRow}>
                        <Col
                            sm={useV2 ? null : 24}
                            className={page === 'sse-home' ? Faq3 : ''}
                            lg={useV2 ? null : 12}
                        >
                            {page === 'sse-home' && (
                                <p className={SSEColTitle}>For consumers</p>
                            )}
                            {page === 'partners'
                                ? faqListing(faq3b)
                                : page === 'sse-home'
                                ? faqListing(faq3c)
                                : page === 'closedCluster'
                                ? faqListing(faq5)
                                : faq3 && faqListing(faq3)}
                        </Col>

                        {!useV2 && (
                            <Col sm={24} lg={12} className={Faq3} id="tog">
                                {page === 'sse-home' && (
                                    <p className={SSEColTitle}>For partners</p>
                                )}
                                {page === 'partners'
                                    ? faqListing(faq4b)
                                    : page === 'sse-home'
                                    ? faqListing(faq4c)
                                    : faq4 && faqListing(faq4)}
                            </Col>
                        )}
                    </Row>
                    {!useV2 && (
                        <div className={FaqButton} id="hide">
                            <Button
                                btnBgColor="var(--purple-light)"
                                btnTextColor="var(--blue)"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="var(--purple-light)"
                                type="outline"
                                handleClick={() => toggleFaq()}
                            >
                                Load More
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            {useV2 && (
                <ChatCTA
                    text="Have questions about this package?"
                    width="fit-content"
                    textOneFontSize="14px"
                />
            )}
        </div>
    )
}

FrequentlyAskedQuestions.propTypes = {
    page: PropTypes.string,
    useV2: PropTypes.bool,
}

export default FrequentlyAskedQuestions
