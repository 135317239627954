export const updateComponentsListProcessing = (selected, packageComponents) => {

  let addComponents = []

  let removedComponents = []

  let updatedComponents = []

  selected.forEach(data => {
    const checkIFInitiallyExisted = packageComponents.filter(
      value => value?.id === data?.id
    )
    if (checkIFInitiallyExisted?.length < 1) {
      addComponents.push(data)
    }
  })

  packageComponents.forEach(data => {
    const checkIFRemoved = selected.filter(value => value?.id === data?.id)
    if (checkIFRemoved?.length < 1) {
      removedComponents.push(data)
    }
  })

  selected.forEach(data => {
    const checkIFUpdated = packageComponents.filter(
      value => value?.id === data?.id && value?.count !== data?.quantity
    )
    if (checkIFUpdated?.length > 0) {
      updatedComponents.push(data)
    }
  })
  
  return {
    add: addComponents?.length > 0 ? addComponents : [],
    remove: removedComponents?.length > 0 ? removedComponents : [],
    update: updatedComponents?.length > 0 ? updatedComponents : []
  }
}
