import PropTypes from 'prop-types'
import { InputFields } from 'src/components/InputFields'
import '../estimations-stepone.scss'
import { AppButton } from 'src/components/Button'
import { useState } from 'react'
import useGetMinimumDeposit from '../hooks/useMinimumDeposit'
import { InlineLoader } from 'src/components/Loader'

const ClusterMinimumDeposit = ({ clusterCode }) => {
    const [input, setInput] = useState('')
    const [error, setError] = useState('')
    const {
        minimumDeposit,
        updateCreateEstimationsStepOne2,
        isLoadingCreateEstimationsStepOne2,
    } = useGetMinimumDeposit(clusterCode)

    const handleSubmit = () => {
        if (!input) {
            setError('Please enter upfront deposit')
            return
        } else if (Number(input) < minimumDeposit) {
            setError(`The lowest upfront deposit is ${minimumDeposit}`)
            return
        } else {
            setError('')
        }

        updateCreateEstimationsStepOne2(input)
    }

    return (
        <div className="clusterMinimumDeposit">
            <div className="clusterMinimumDeposit__content">
                <h2>Enter Upfront Deposit</h2>
                <p>
                    Enter upfront deposit with a minimum of the selected package
                </p>

                <div className="clusterMinimumDeposit__input">
                    <InputFields
                        name="upfront_deposit"
                        inputWidth="317px"
                        title="Upfront Deposit"
                        type="number"
                        marginBottom="0px"
                        inputValue={input}
                        value={input}
                        floatingLabel="%"
                        handleChange={e => {
                            setInput(e.target.value)
                            setError('')
                        }}
                        errorMessage={error}
                    />
                </div>
            </div>

            <AppButton
                btnBgColor="#004AAD"
                btnTextColor="#fff"
                btnWidth="160px"
                btnHeight="56px"
                fontSize="14px"
                fontFamily="SF-Pro-Display-Semibold"
                handleClick={handleSubmit}
                disabled={isLoadingCreateEstimationsStepOne2}
            >
                {isLoadingCreateEstimationsStepOne2 ? (
                    <InlineLoader />
                ) : (
                    'Confirm'
                )}
            </AppButton>
        </div>
    )
}

ClusterMinimumDeposit.propTypes = {
    clusterCode: PropTypes.string,
}

export default ClusterMinimumDeposit
