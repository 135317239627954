/* eslint-disable react/prop-types */
import { useCallback, useState, useReducer, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { format } from 'date-fns'

import {
    Tabs,
    Pagination,
    Menu,
    Dropdown,
    Drawer,
    Slider,
    Carousel,
    Space,
    Skeleton,
} from 'antd'
import {
    DateField,
    MultipleSelectField,
    NoFLoatingLabelInputFields,
} from '../../../components/InputFields'
import CarouselLine from '../../../components/CarouselLine'
import BackNav from '../../../components/BackNav'
import Button from '../../../components/Button'
import FloatingBox from '../../../components/FloatingBox'
import Table from '../../../components/Table'
import SeoComponent from '../../../components/Seo'
import GetModal from '../../../components/Modal'
import UploadProducts from '../UploadProducts'
import EmptyState from '../../../components/EmptyState'
import HelpCenter from '../../../components/HelpCenter'
import { InlineLoader } from '../../../components/Loader'

import { packagesTableHead, componentsTableHead } from './tableInfo'
import { formatPackage, formatComponent } from './formatter'
import Toast from '../../../components/Toast'
import { useQuery } from 'react-query'
import { getComponentsApi, getPackagesApi } from '../../../api/products/library'

// import { ReactComponent as UploadIcon } from '../../../assets/images/uploadIcon.svg'
import { ReactComponent as PlusIcon } from '../../../assets/images/plusIcon.svg'

import QuestionMarkIcon from '../../../assets/images/questionMark.svg'
import ProductsContainer from '../../../components/ProductsContainer'
import StepOne from '../AddComponent/StepOne'
import StepTwo from '../AddComponent/StepTwo'
import StepThree from '../AddComponent/StepThree'

import { getComponentTypeApi } from '../../../api/products/component'
import './productlibrary.scss'
import { denyPermission } from '../../../utils/permissionFramework'
import { decodeUserInfo } from '../../../utils/auth'
import { permissionsControl } from 'src/utils/permissionsControl'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateProviderProductsFilters,
    updatedProviderProductsFilters,
    resetProviderProductsFilters,
} from 'src/redux/reducers/provider/products/listing'
import { convertQueryParamsToObject, getKeyByValue } from 'src/utils/formatting'
import {
    addQueryParamsToUrl,
    removeQueryParamFromUrl,
    getSearchParamsFromUrl,
    removeAllQueryParamsFromUrl,
} from 'src/utils/urlTracker'

const ProductLibrary = props => {
    const productLibraryTabs = {
        1: 'packages',
        2: 'components',
    }
    const { TabPane } = Tabs
    const history = useHistory()
    const location = useLocation()
    const filterQuery = getSearchParamsFromUrl() // Retrieve filter, tab and pagination data from url
    const defaultQueries = useSelector(updatedProviderProductsFilters)
    const dispatchRedux = useDispatch()
    const providerId = location?.state?.id ?? ''
    // sunfiid is used to determine if its the system admin, this determines if the admin will be able to add or edit products and components
    const sunfiId = location?.state?.sunfiId ?? ''
    const providerName = location?.state?.name ?? ''
    const maxFilterAmount = 10000000
    const initialState = {
        formatedComponentTypes: [],
        componentName: '',
        componentType: '',
        componentCost: '',
        componentDescription: '',
        componentTypeId: '',
        processStep: 1,
        selectedAttributes: [],
        userSelectedType: '',
    }

    function productReducer(state = initialState, action) {
        switch (action.type) {
            case 'parent/set-component-type': {
                const payload = action.payload
                const formatedComponentTypes = payload.map(item => {
                    return {
                        ...item,
                        value: item.name,
                        id: item.id,
                    }
                })

                return { ...state, formatedComponentTypes }
            }

            case 'parent/goto-next': {
                const payload = action.payload

                // Todo: Know when to submit

                const step = (state.processStep + 1) % 3

                return { ...state, ...payload, processStep: step }
            }

            case 'parent/goto-previous': {
                const payload = action.payload
                let step = state.processStep - 1

                if (payload?.attributes?.length < 1) {
                    step = 1
                }

                return { ...state, ...payload, processStep: step }
            }

            case 'parent/open-modal': {
                return {
                    ...state,
                    componentName: '',
                    componentType: '',
                    componentCost: '',
                    componentDescription: '',
                    componentTypeId: '',
                    processStep: 1,
                    selectedAttributes: [],
                    userSelectedType: '',
                }
            }

            case 'parent/goto-step3': {
                const payload = action.payload
                if (payload?.attributes?.length < 1) {
                    return {
                        ...state,
                        ...payload,
                        processStep: 3,
                    }
                } else {
                    return {
                        ...state,
                        selectedAttributes: payload,
                        processStep: 3,
                    }
                }
            }

            case 'parent/goto-step1': {
                const payload = action.payload

                return { ...state, selectedAttributes: payload, processStep: 1 }
            }

            case 'refresh': {
                return {
                    ...state,
                    componentName: '',
                    componentType: '',
                    componentCost: '',
                    componentDescription: '',
                    componentTypeId: '',
                    processStep: 1,
                    selectedAttributes: [],
                    userSelectedType: '',
                }
            }

            default:
                return state
        }
    }

    const [inputFocus, setInputFocus] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [packagesData, setPackagesData] = useState([])
    const [componentsData, setComponentsData] = useState([])
    const [currentTab, setCurrentTab] = useState(
        getKeyByValue(productLibraryTabs, filterQuery.tab) ||
            defaultQueries.current_tab,
    )
    const [currentPage, setCurrentPage] = useState(
        Number(filterQuery?.page || defaultQueries.page),
    )
    const [totalComponentCount, setTotalComponentCount] = useState(0)
    const [totalPackageCount, setTotalPackageCount] = useState(0)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorType, setErrorType] = useState('')

    const [filterMode, setFilterMode] = useState(
        defaultQueries.filter_mode === 'true' ? true : false,
    )
    const [searchText, setSearchText] = useState(
        filterQuery?.search || defaultQueries.name || '',
    )
    const [visible, setVisible] = useState(false)
    const [showCalendar, setShowCalendar] = useState(0)
    const [range, setRange] = useState([0, 0])
    const [showAddModal, setShowAddModal] = useState(false)
    const [screenType, setScreenType] = useState('')
    const [inputs, setInputs] = useState({
        startDate: filterQuery?.startDate || defaultQueries.start_date || null,
        endDate: filterQuery?.endDate || defaultQueries.end_date || null,
    })
    const [filterLoading, setFilterLoading] = useState(false)
    const [allComponentTypes, setAllComponentTypes] = useState([])
    const [selectedTypes, setSelectedTypes] = useState(
        defaultQueries?.component_types?.length
            ? [defaultQueries.component_types]
            : [],
    )

    const [currentStartDate, setCurrentStartDate] = useState(
        inputs.startDate === null ? null : new Date(inputs.startDate),
    )

    const [currentEndDate, setCurrentEndDate] = useState(
        inputs.endDate === null ? null : new Date(inputs.endDate),
    )

    const [state, dispatch] = useReducer(productReducer, initialState)

    const userInfo = decodeUserInfo()
    const isAdminWorkspace = userInfo?.isAdminWorkspaceUser === 'true'

    const handleModal = screen => {
        setShowModal(!showModal)
        setScreenType(screen)
    }

    const handleMobileModal = () => {
        setShowAddModal(!showAddModal)
        setShowMenu(false)
    }

    // const handleVisibleChange = flag => {
    //   setShowMenu(flag)
    // }

    const handlePage = pageNumber => {
        setCurrentPage(pageNumber)
        addQueryParamsToUrl({
            page: pageNumber,
        })
    }

    const showDrawer = () => {
        setVisible(true)
    }
    const hideDrawer = () => {
        setVisible(false)
    }

    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(format(date, 'MM/dd/yyyy'))
        }
        if (name === 'endDate') {
            setCurrentEndDate(format(date, 'MM/dd/yyyy'))
        }
    }

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]: name === 'startDate' ? currentStartDate : currentEndDate,
        }))
    }

    const handleInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
    }

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }
    const handleFilter = type => {
        setFilterMode(true)
        setCurrentPage(1)

        if (type === 'filter' && currentTab === '1') {
            setFilterLoading(true)
            fetchPackages()
        }

        if (type === 'filter' && currentTab === '2') {
            setFilterLoading(true)
            fetchComponents()
        }
    }

    let reset
    const handleReset = () => {
        setFilterMode(false)
        removeAllQueryParamsFromUrl()
        reset = true
        setRange([0, 0])
        setInputs(prev => ({
            ...prev,
            endDate: null,
            startDate: null,
        }))
        setCurrentStartDate(null)
        setCurrentEndDate(null)
        setSelectedTypes([])
        hideDrawer()
        if (currentTab === '1') {
            fetchPackages()
        }

        if (currentTab === '2') {
            fetchComponents()
        }
    }
    const redirectTo = pageUrl => {
        history.push({
            pathname: pageUrl,
            state: {
                sunfiId: sunfiId,
                isAdminWorkspace: isAdminWorkspace,
                name: providerName,
            },
        })
    }
    const {
        isFetching: fetchComponentsLoading,
        refetch: fetchComponents,
        data: componentData,
    } = useQuery(
        ['fetchAllComponents', currentTab === '2' ? currentPage : 1],
        () =>
            getComponentsApi(
                searchText ? 1 : currentPage,
                searchText,
                reset
                    ? ''
                    : currentStartDate
                    ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                    : '',
                reset
                    ? ''
                    : currentEndDate
                    ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                    : '',
                reset ? 0 : range[0],
                reset ? '' : range[1] > 0 ? range[1] : '',
                reset
                    ? ''
                    : selectedTypes?.length > 0
                    ? selectedTypes?.toString()
                    : '',
                providerId || sunfiId,
                isAdminWorkspace,
            ),
        {
            enabled: true,
            refetchOnMount: true,
            retry: false,
            onSuccess: data => {
                const formattedComponents = formatComponent(data?.data?.data)
                setComponentsData(formattedComponents)
                setTotalComponentCount(data?.data?.count)
                if (filterMode) {
                    setFilterLoading(false)
                }

                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatchRedux(
                    updateProviderProductsFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            `current_tab:${currentTab}`,
                            `filter_mode:${filterMode}`,
                        ]),
                    ),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorType('component')
                setErrorMessage('Failed to fetch components. Refresh page')
            },
        },
    )

    const {
        isFetching: fetchPackagesLoading,
        refetch: fetchPackages,
        data: packageData,
    } = useQuery(
        ['fetchAllPackages', currentTab === '1' ? currentPage : 1],
        () =>
            getPackagesApi(
                searchText ? 1 : currentPage,
                searchText ? searchText : '',
                reset
                    ? ''
                    : currentStartDate
                    ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                    : '',
                reset
                    ? ''
                    : currentEndDate
                    ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                    : '',
                reset ? 0 : range[0],
                reset ? '' : range[1] > 0 ? range[1] : '',
                providerId || sunfiId,
                isAdminWorkspace,
            ),
        {
            enabled: true,
            refetchOnMount: true,
            retry: false,
            onSuccess: data => {
                const formattedPackages = formatPackage(data?.data?.data)
                setPackagesData(formattedPackages)
                setTotalPackageCount(data?.data?.count)
                if (filterMode) {
                    setFilterLoading(false)
                }
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')

                dispatchRedux(
                    updateProviderProductsFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            `current_tab:${currentTab}`,
                            `filter_mode:${filterMode}`,
                        ]),
                    ),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorType('packages')
                setErrorMessage('Failed to fetch packages. Refresh page')
            },
        },
    )

    const { refetch: componentTypeRefetch } = useQuery(
        ['fetchAllComponentTypes'],
        () => getComponentTypeApi(isAdminWorkspace),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const formattedList = []
                data?.data?.data?.forEach(value => {
                    formattedList.push({
                        id: value?.id,
                        value: value?.name,
                    })
                })
                setAllComponentTypes(formattedList)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch component types. Refresh page')
            },
        },
    )

    // define permissions
    const canCreateComponent = permissionsControl(['can_create_a_component'])
    const canViewComponentsList = permissionsControl([
        'can_view_component_list',
    ])
    const canCreatePackage = permissionsControl(['can_create_package'])
    const canViewPackagesList = permissionsControl(['can_view_package_list'])

    const menu = (
        <Menu className="menu" style={{ width: '150px' }}>
            {canCreateComponent && (
                <Menu.Item
                    key={1}
                    disabled={denyPermission(
                        'admin',
                        'product_library',
                        'component:can_create',
                    )}
                    onClick={() => redirectTo('/app/products/add-component')}
                >
                    <p className="menuItem">New Component</p>
                </Menu.Item>
            )}
            {canCreatePackage && (
                <Menu.Item
                    key={2}
                    disabled={denyPermission(
                        'admin',
                        'product_library',
                        'package:can_create',
                    )}
                    onClick={() => redirectTo('/app/products/add-package')}
                >
                    <p className="menuItem">New Package</p>
                </Menu.Item>
            )}
        </Menu>
    )

    const paginationRequest = () => {
        if (currentTab === '1') {
            return packagesData?.length > 0
                ? paginationBlock(packageData?.data?.total_pages)
                : ''
        }

        if (currentTab === '2') {
            return componentsData?.length > 0
                ? paginationBlock(componentData?.data?.total_pages)
                : ''
        }
    }

    const paginationBlock = total_page => {
        return (
            <Pagination
                defaultCurrent={currentPage}
                defaultPageSize={1}
                total={parseInt(total_page, 10)}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={value => handlePage(value)}
            />
        )
    }

    const mobileMenu = (
        <Menu className="menu">
            {canCreateComponent && (
                <Menu.Item
                    style={{ marginBottom: 5 }}
                    onClick={() => {
                        dispatch({ type: 'parent/open-modal' })
                        handleMobileModal()
                    }}
                    key={3}
                    disabled={denyPermission(
                        'admin',
                        'product_library',
                        'component:can_create',
                    )}
                >
                    New Component
                </Menu.Item>
            )}
            {canCreatePackage && (
                <Menu.Item
                    key={4}
                    disabled={denyPermission(
                        'admin',
                        'product_library',
                        'package:can_create',
                    )}
                    onClick={() => redirectTo('/app/products/add-package')}
                >
                    <p className="menuItem">New Package</p>
                </Menu.Item>
            )}
        </Menu>
    )

    const stepsRef = useRef(null)

    const gotoNextStep = useCallback(payload => {
        dispatch({ type: 'parent/goto-next', payload })
        stepsHandler('next')
    }, [])

    const gotoPreviousStep = useCallback(payload => {
        dispatch({ type: 'parent/goto-previous', payload })
    }, [])

    const stepsHandler = action => {
        if (action === 'next') {
            stepsRef.current.next(2)
        }
        if (action === 'pause') {
            stepsRef.current.goTo(0, false)
        }
    }
    const afterChangeHandler = stageNumber => {
        stepsHandler('pause', stageNumber > 0 ? stageNumber - 1 : 0)
    }

    const handleModalClose = () => {
        setShowAddModal(false)
        stepsRef.current.goTo(0, true)
    }

    useEffect(() => {
        !fetchPackagesLoading && location.state?.overview && setShowMenu(true)
    }, [fetchPackagesLoading, location.state?.overview])

    useEffect(() => {
        if (searchText === '' && currentTab === '1') {
            fetchPackages()
        } else if (searchText === '' && currentTab === '2') {
            fetchComponents()
        }
        removeQueryParamFromUrl('search')
        // eslint-disable-next-line
    }, [searchText])

    useEffect(() => {
        componentTypeRefetch()

        // eslint-disable-next-line
    }, [])

    const handleSearch = () => {
        setCurrentPage(1)
        if (currentTab === '1') {
            fetchPackages()
        } else {
            fetchComponents()
        }
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
            if (event.target.value.length > 0) {
                addQueryParamsToUrl({
                    search: event.target.value,
                    page: '1',
                })
            } else {
                removeQueryParamFromUrl('search')
                addQueryParamsToUrl({
                    page: '1',
                })
            }
        }
    }

    const updateCheckedTypes = type => {
        setSelectedTypes(type)
    }

    const { refetch } = useQuery(
        'getComponentTypes',
        () => getComponentTypeApi(isAdminWorkspace),
        {
            enabled: state.processStep === 1,
            retry: false,
            onSuccess: data => {
                dispatch({
                    type: 'parent/set-component-type',
                    payload: data?.data?.data,
                })
            },
        },
    )

    useEffect(() => {
        refetch()

        // eslint-disable-next-line
    }, [])

    const step = state.processStep

    return (
        <>
            <SeoComponent
                title={`Products - ${
                    currentTab === '1' ? 'Packages' : 'Components'
                } | SunFi | Simplifying and Scaling Clean Energy`}
                tracker="Products"
            />
            <GetModal
                app="workspace"
                showModal={showModal}
                closable={screenType === 'mobile' ? true : false}
                content={
                    screenType === 'mobile' ? (
                        <ProductsContainer>
                            <UploadProducts handleModal={handleModal} />
                        </ProductsContainer>
                    ) : (
                        <UploadProducts handleModal={handleModal} />
                    )
                }
                onCancel={() => setShowModal(false)}
            />

            <GetModal
                app="workspace"
                closable={true}
                showModal={showAddModal}
                content={
                    <Carousel afterChange={afterChangeHandler} ref={stepsRef}>
                        {step === 1 && (
                            <ProductsContainer>
                                <div className="PageTitle">
                                    Create New Component
                                </div>
                                <StepOne
                                    initialState={state}
                                    nextHandler={() => stepsHandler('next')}
                                    gotoNext={payload => {
                                        gotoNextStep(payload)
                                    }}
                                    goToPrevious={payload =>
                                        gotoPreviousStep(payload)
                                    }
                                    gotoStep3={payload =>
                                        dispatch({
                                            type: 'parent/goto-step3',
                                            payload,
                                        })
                                    }
                                />

                                <CarouselLine total={3} stage={1} />
                            </ProductsContainer>
                        )}

                        {step === 2 && (
                            <ProductsContainer>
                                <div className="PageTitle">
                                    Create New Component
                                </div>
                                <StepTwo
                                    initialState={state}
                                    nextHandler={() => stepsHandler('next')}
                                    gotoNext={payload => {
                                        dispatch({
                                            type: 'parent/goto-step3',
                                            payload,
                                        })
                                    }}
                                    gotoPrevious={payload =>
                                        dispatch({
                                            type: 'parent/goto-step1',
                                            payload,
                                        })
                                    }
                                />
                                <CarouselLine total={3} stage={2} />
                            </ProductsContainer>
                        )}

                        {step === 3 && (
                            <ProductsContainer>
                                <div className="PageTitle">
                                    Create New Component
                                </div>
                                <StepThree
                                    initialState={state}
                                    closeModal={handleModalClose}
                                    gotoNext={payload => {
                                        gotoNextStep(payload)
                                    }}
                                    goToPrevious={payload =>
                                        gotoPreviousStep(payload)
                                    }
                                    restart={() =>
                                        dispatch({ type: 'refresh' })
                                    }
                                    sunfiId={sunfiId}
                                    isAdminWorkspace={isAdminWorkspace}
                                />
                                <CarouselLine total={3} stage={3} />
                            </ProductsContainer>
                        )}
                    </Carousel>
                }
                onCancel={handleModalClose}
            />
            <div className="ProductLibrary">
                {userInfo.isAdminWorkspaceUser === 'true' && !filterMode && (
                    <div className="AdminProductLibraryBackLink">
                        <BackNav
                            title="Back"
                            onClick={() => {
                                dispatchRedux(resetProviderProductsFilters())
                                history.push(
                                    `/admin/products?tab=${location?.state?.tab}`,
                                )
                            }}
                        />
                    </div>
                )}
                {filterMode ? (
                    <div className="FilterBackLink">
                        <BackNav
                            title="Clear Filter"
                            onClick={() => {
                                setSearchText('')
                                setTimeout(() => {
                                    handleReset()
                                }, 300)
                            }}
                        />
                    </div>
                ) : (
                    <div className="MobileTitle">
                        <h1 className="Title">
                            {`${providerName} `}Product Library
                        </h1>
                        {providerId.length === 0 && (
                            <div className="MobileButton">
                                <Dropdown
                                    overlay={mobileMenu}
                                    visible={showMenu}
                                >
                                    <Button
                                        btnBgColor="var(--blue)"
                                        btnTextColor="var(--white)"
                                        btnWidth="40px"
                                        btnHeight="40px"
                                        handleClick={() =>
                                            setShowMenu(showMenu ? false : true)
                                        }
                                    >
                                        <span
                                            style={{
                                                position: 'relative',
                                                top: '2.5px',
                                            }}
                                        >
                                            <PlusIcon />
                                        </span>
                                    </Button>
                                </Dropdown>
                            </div>
                        )}
                    </div>
                )}

                {fetchPackagesLoading || fetchComponentsLoading ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                <Skeleton.Input
                                    className="LoadingTopRowLarge"
                                    active
                                />
                                <Skeleton.Input
                                    className="LoadingTopRowSmall"
                                    active
                                />
                            </div>
                            <Space className="MobileLoadingTopRow">
                                <Skeleton.Input
                                    style={{
                                        width: '130px',
                                    }}
                                    className="LoadingTopRow"
                                    active
                                />
                                <Skeleton.Input
                                    style={{
                                        width: '130px',
                                    }}
                                    className="LoadingTopRow"
                                    active
                                />
                            </Space>
                        </div>
                        <div style={{ marginTop: '50px' }}>
                            <Space>
                                <Skeleton.Button
                                    style={{
                                        width: '134px',
                                        borderRadius: '7px',
                                        height: '25px',
                                    }}
                                    active
                                />
                                <Skeleton.Button
                                    style={{
                                        width: '134px',
                                        borderRadius: '7px',
                                        height: '25px',
                                    }}
                                    active
                                />
                            </Space>
                        </div>
                        <hr className="LoadingHr" />
                        <div className="LoadingBtnsWrapper">
                            {[...Array(5)].map(i => (
                                <Skeleton.Button
                                    key={i * Math.random()}
                                    className="LoadingBtns"
                                    active
                                />
                            ))}
                        </div>

                        <div className="LoadingRowsWrapper">
                            {[...Array(6)].map(i => (
                                <Skeleton.Input
                                    key={i * Math.random()}
                                    className="LoadingRows"
                                    active
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{ display: 'flex' }}>
                            <div className="ProductListInputWrapper">
                                <NoFLoatingLabelInputFields
                                    TextPrefilled={searchText ? true : false}
                                    borderRadius="10px"
                                    inputWidth="350px"
                                    inputHeight="45px"
                                    value={searchText}
                                    type="text"
                                    inputBackground="#F6F6F6"
                                    iconType="search"
                                    prefilled
                                    inputPlaceholder="Search Products"
                                    style={{ paddingBottom: 7 }}
                                    handleChange={handleInputChange}
                                    onIconClick={handleSearch}
                                    onKeyUp={handleKeyPress}
                                    onBlur={handleOnBlur}
                                />
                                &nbsp; &nbsp;
                                <>
                                    <div
                                        onClick={showDrawer}
                                        className="filterBox"
                                        onMouseEnter={() => setInputFocus(true)}
                                        onMouseLeave={() =>
                                            setInputFocus(false)
                                        }
                                        style={{
                                            border: `${
                                                inputFocus || visible
                                                    ? '1px solid var(--blue)'
                                                    : '1px solid #e5e5e5'
                                            }`,
                                        }}
                                    >
                                        <svg
                                            width="12"
                                            height="15"
                                            viewBox="0 0 12 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ marginTop: 4 }}
                                        >
                                            <path
                                                d="M7.5 8.5V13L4.5 14.5V8.5L0 1.75V0.25H12V1.75L7.5 8.5ZM1.803 1.75L6 8.0455L10.197 1.75H1.803Z"
                                                fill={`${
                                                    inputFocus || visible
                                                        ? 'var(--blue)'
                                                        : '#1D2A30'
                                                }`}
                                            />
                                        </svg>

                                        <p className="filter">Filters</p>
                                    </div>
                                    <Drawer
                                        placement="right"
                                        visible={visible}
                                        closable={true}
                                        width="367px"
                                        onClose={hideDrawer}
                                        className={'ProductFilterDrawer'}
                                    >
                                        <div
                                            className={
                                                currentTab === '1'
                                                    ? 'PackagesDrawerFormWrapper'
                                                    : 'DrawerFormWrapper'
                                            }
                                        >
                                            <h2 className="FilterTitle">
                                                {currentTab === '2'
                                                    ? 'Filter Components'
                                                    : 'Filter Packages'}
                                            </h2>
                                            <p className="FilterSubTitle">
                                                Filter by Created Date
                                            </p>
                                            <div className="dateWrapper">
                                                <div className="DateInputLeft">
                                                    <DateField
                                                        placeholder="Start Date"
                                                        name="startDate"
                                                        caretStyle="caretStyle"
                                                        dateValue={
                                                            inputs.startDate ===
                                                            null
                                                                ? null
                                                                : new Date(
                                                                      inputs.startDate,
                                                                  )
                                                        }
                                                        handleDateChange={(
                                                            name,
                                                            date,
                                                        ) =>
                                                            handleDateChange(
                                                                name,
                                                                date,
                                                            )
                                                        }
                                                        openCalendar={
                                                            showCalendar === 1
                                                                ? true
                                                                : false
                                                        }
                                                        closeCalendarHandler={() =>
                                                            setShowCalendar(
                                                                null,
                                                            )
                                                        }
                                                        openCalendarHandler={() =>
                                                            setShowCalendar(1)
                                                        }
                                                        setHandleDateChange={name =>
                                                            setHandleDateChange(
                                                                name,
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <span className="Separator"></span>

                                                <div className="DateInputRight">
                                                    <DateField
                                                        placeholder="End Date"
                                                        name="endDate"
                                                        caretStyle="caretStyle"
                                                        dateValue={
                                                            inputs.endDate ===
                                                            null
                                                                ? null
                                                                : new Date(
                                                                      inputs.endDate,
                                                                  )
                                                        }
                                                        handleDateChange={(
                                                            name,
                                                            date,
                                                        ) =>
                                                            handleDateChange(
                                                                name,
                                                                date,
                                                            )
                                                        }
                                                        openCalendar={
                                                            showCalendar === 2
                                                                ? true
                                                                : false
                                                        }
                                                        closeCalendarHandler={() =>
                                                            setShowCalendar(
                                                                null,
                                                            )
                                                        }
                                                        openCalendarHandler={() =>
                                                            setShowCalendar(2)
                                                        }
                                                        setHandleDateChange={name =>
                                                            setHandleDateChange(
                                                                name,
                                                            )
                                                        }
                                                        placePopperRight
                                                    />
                                                </div>
                                            </div>
                                            <p
                                                className="FilterSubTitle"
                                                style={{ marginTop: 30 }}
                                            >
                                                {currentTab === '2'
                                                    ? 'Filter By Component Type'
                                                    : ''}
                                            </p>

                                            {currentTab === '2' ? (
                                                <>
                                                    <MultipleSelectField
                                                        values={
                                                            allComponentTypes
                                                        }
                                                        value={selectedTypes.map(
                                                            item => item,
                                                        )}
                                                        handleMultipleSelectChange={value => {
                                                            updateCheckedTypes(
                                                                value,
                                                            )
                                                        }}
                                                        selectWidth="95%"
                                                        dropDownWidth="390px"
                                                        title="Select Component Type"
                                                        inputValue={
                                                            selectedTypes
                                                        }
                                                        maxTagCount="responsive"
                                                        maxTagPlaceholder="Select Component"
                                                        mobileWidth
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {/* {PaymentType.map((payment, id) => {
                      return (
                        <div style={{ margin: '8px 0' }}>
                          <Checkbox>
                            <p>{payment.payment}</p>
                          </Checkbox>
                        </div>
                      )
                    })} */}
                                                </>
                                            )}
                                            <p
                                                className="FilterSubTitle"
                                                style={{ marginTop: 50 }}
                                            >
                                                Filter By Total Amount
                                            </p>
                                            <Slider
                                                range={{ draggableTrack: true }}
                                                defaultValue={[0, 0]}
                                                max={maxFilterAmount}
                                                value={range}
                                                tooltipPlacement={'bottom'}
                                                onChange={value =>
                                                    setRange(value)
                                                }
                                                tipFormatter={value =>
                                                    `₦${new Intl.NumberFormat().format(
                                                        value,
                                                    )}`
                                                }
                                            />

                                            {/* {currentTab === '2' ? (
                    <>
                      <p className='FilterSubTitle' style={{ marginTop: 60 }}>
                        Filter By Unit Measurements
                      </p>
                      <div style={{ margin: '8px 0' }}>
                        <Checkbox>
                          <p>KWhA</p>
                        </Checkbox>
                      </div>
                      <div style={{ margin: '8px 0' }}>
                        <Checkbox>
                          <p>Amphere</p>
                        </Checkbox>
                      </div>
                    </>
                  ) : null} */}
                                        </div>
                                        <div className="FilterBtnWrapper">
                                            <Button
                                                btnBgColor="var(--purple-light)"
                                                btnTextColor="var(--blue)"
                                                btnTextColorOutline="var(--blue)"
                                                btnOutlineColor="var(--purple-light)"
                                                btnBgColorOutline="#E2EEFF"
                                                type="outline"
                                                btnWidth="150px"
                                                handleClick={() => {
                                                    setSearchText('')
                                                    setTimeout(() => {
                                                        handleReset()
                                                    }, 300)
                                                }}
                                            >
                                                Reset Filter
                                            </Button>
                                            &nbsp; &nbsp; &nbsp;
                                            <Button
                                                btnBgColor="var(--blue)"
                                                btnTextColor="var(--white)"
                                                btnWidth="150px"
                                                handleClick={() =>
                                                    handleFilter('filter')
                                                }
                                                disabled={
                                                    filterLoading ? true : false
                                                }
                                            >
                                                {filterLoading ? (
                                                    <InlineLoader />
                                                ) : (
                                                    'Add Filter'
                                                )}
                                            </Button>
                                        </div>
                                    </Drawer>
                                </>
                            </div>
                            {(sunfiId?.length > 0 || !isAdminWorkspace) && (
                                <div className="buttons">
                                    {/* <Button
                                    btnBgColor="var(--purple-light)"
                                    btnTextColor="var(--blue)"
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="150px"
                                    btnHeight="45px"
                                    btnPadding="10px 15px"
                                    handleClick={() => handleModal()}
                                >
                                    Upload Products
                                </Button> */}
                                    {/* &nbsp; &nbsp; &nbsp; */}
                                    {(canCreateComponent ||
                                        canCreatePackage) && (
                                        <Dropdown
                                            overlay={menu}
                                            visible={showMenu}
                                            // onVisibleChange={handleVisibleChange}
                                        >
                                            <Button
                                                btnBgColor="var(--blue)"
                                                btnTextColor="var(--white)"
                                                btnWidth="150px"
                                                btnHeight="45px"
                                                handleClick={() => {
                                                    setShowMenu(
                                                        showMenu ? false : true,
                                                    )
                                                }}
                                                btnPadding="10px 15px"
                                            >
                                                Add Products
                                                <span
                                                    style={{ marginLeft: 14 }}
                                                >
                                                    <svg
                                                        width="12"
                                                        height="8"
                                                        viewBox="0 0 12 8"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M6.00011 4.97634L10.1251 0.851341L11.3034 2.02967L6.00011 7.33301L0.696777 2.02967L1.87511 0.851341L6.00011 4.97634Z"
                                                            fill="white"
                                                            fillOpacity="0.9"
                                                        />
                                                    </svg>
                                                </span>
                                            </Button>
                                        </Dropdown>
                                    )}
                                </div>
                            )}
                        </div>

                        {filterMode && (
                            <h1 className="FilterText">
                                Filter Results (
                                {currentTab === '1'
                                    ? totalPackageCount
                                    : totalComponentCount}
                                )
                            </h1>
                        )}

                        <Tabs
                            activeKey={
                                !canViewPackagesList
                                    ? '2'
                                    : !canViewComponentsList
                                    ? '1'
                                    : currentTab
                            }
                            style={{ marginTop: 38 }}
                            onChange={values => {
                                setSearchText('')
                                setCurrentPage(1)
                                setCurrentTab(values)
                                addQueryParamsToUrl({
                                    tab:
                                        values === '1'
                                            ? 'packages'
                                            : 'components',
                                    page: '1',
                                })
                                values === '1' && currentPage === 1
                                    ? fetchPackages()
                                    : values === '2' && currentPage === 1
                                    ? fetchComponents()
                                    : null
                            }}
                        >
                            {canViewPackagesList && (
                                <TabPane
                                    tab={`Packages ${
                                        currentTab === '1'
                                            ? '(' + totalPackageCount + ')'
                                            : ''
                                    }`}
                                    key="1"
                                >
                                    {toastError && errorType === 'packages' && (
                                        <Toast
                                            messageType="error"
                                            message={errorMessage}
                                        />
                                    )}
                                    {packagesData?.length > 0 ? (
                                        <Table
                                            products={packagesData}
                                            tabId="1"
                                            noEdit={
                                                isAdminWorkspace
                                                    ? sunfiId?.length === 0
                                                    : false
                                            }
                                            providerId={providerId || sunfiId}
                                            providerName={providerName}
                                            loading={fetchPackagesLoading}
                                            tableHead={packagesTableHead}
                                            globalType="package"
                                            userInfo={props.userInfo}
                                            productType="packageList"
                                            isAdminWorkspace={isAdminWorkspace}
                                        />
                                    ) : !fetchPackagesLoading &&
                                      packagesData?.length === 0 ? (
                                        <EmptyState
                                            buttonText={'Add Packages'}
                                            subTitle={
                                                'You’ve not added any product to your library. '
                                            }
                                            buttonClick={() =>
                                                history.push(
                                                    '/app/products/add-package',
                                                )
                                            }
                                            buttonVisible={
                                                !providerId?.length > 0
                                            }
                                        />
                                    ) : (
                                        'Fetching List...'
                                    )}
                                </TabPane>
                            )}
                            {canViewComponentsList && (
                                <TabPane
                                    tab={`Components ${
                                        currentTab === '2'
                                            ? '(' + totalComponentCount + ')'
                                            : ''
                                    }`}
                                    key="2"
                                >
                                    {toastError &&
                                        errorType === 'component' && (
                                            <Toast
                                                messageType="error"
                                                message={errorMessage}
                                            />
                                        )}
                                    {componentsData?.length > 0 ? (
                                        <Table
                                            products={componentsData}
                                            tabId="2"
                                            loading={fetchComponentsLoading}
                                            tableHead={componentsTableHead}
                                            noEdit={
                                                isAdminWorkspace
                                                    ? sunfiId?.length === 0
                                                    : false
                                            }
                                            providerId={providerId || sunfiId}
                                            providerName={providerName}
                                            productType="componentList"
                                            isAdminWorkspace={isAdminWorkspace}
                                        />
                                    ) : !fetchComponentsLoading &&
                                      componentsData?.length === 0 ? (
                                        <EmptyState
                                            buttonText={'Add Component'}
                                            subTitle={
                                                'You’ve not added any product to your library. '
                                            }
                                            buttonClick={() =>
                                                history.push(
                                                    '/app/products/add-component',
                                                )
                                            }
                                            buttonVisible={
                                                !providerId?.length > 0
                                            }
                                        />
                                    ) : (
                                        'Fetching List...'
                                    )}
                                </TabPane>
                            )}
                        </Tabs>

                        <div className="pages">{paginationRequest()}</div>
                    </>
                )}
            </div>

            <div className="HelpCenterWrapper">
                <FloatingBox
                    floatIcon={QuestionMarkIcon}
                    floatBgColor="#011A3C"
                    floatBoxClass="floatBox"
                    renderComponent={props => <HelpCenter {...props} />}
                />
            </div>
        </>
    )
}

export default ProductLibrary
