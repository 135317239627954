import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import { validateRequestNewDealInfo } from 'src/utils/validationSchema'

const useRequestNewDealModal = (onCancel, showUrgencyField) => {
    const initialInputs = {
        urgency: '',
        useType: '',
    }
    const [inputs, setInputs] = useState(initialInputs)
    const [errors, setErrors] = useState({})

    const validateField = useValidateOnBlur()
    const history = useHistory()

    const closeModal = () => {
        setInputs(initialInputs)
        setErrors({})
        onCancel()
    }

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const handleSelectOnBlur = async name => {
        await validateField({
            name,
            value: inputs[`${name}`],
            schema: validateRequestNewDealInfo(showUrgencyField),
            setErrors,
            context: {},
        })
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)
    }

    const handleSubmit = () => {
        validateRequestNewDealInfo(showUrgencyField)
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                sessionStorage.setItem(
                    'returningActiveOrClosedCustomer',
                    JSON.stringify({
                        status: true,
                        useType: inputs?.useType,
                    }),
                )
                history.push(
                    inputs.useType === 'Residential use'
                        ? '/consumer/individual-profiles'
                        : '/consumer/business-profiles',
                )
                closeModal()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    return {
        inputs,
        errors,
        handleSelectOnBlur,
        handleSelectChange,
        closeModal,
        handleSubmit,
    }
}

export default useRequestNewDealModal
