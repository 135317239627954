import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { InputFields } from 'src/components/InputFields'

const WhereYouWork = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    isCustomerApproved,
}) => {
    return (
        <>
            <Label
                title={'Where do you work?'}
                subTitle={
                    'Provide us with your employer’s details and what you do'
                }
            />
            <InputFields
                name="companyName"
                title="Company Name"
                value={inputs?.companyName}
                handleChange={handleChange}
                errorMessage={errors?.companyName}
                onBlur={handleOnBlur}
                disabled={isAdminUser || isCustomerApproved}
            />
            <InputFields
                name="jobTitle"
                title="Job Title"
                value={inputs?.jobTitle}
                handleChange={handleChange}
                errorMessage={errors?.jobTitle}
                onBlur={handleOnBlur}
                disabled={isAdminUser || isCustomerApproved}
            />
            <VerticalSpacer top={12} />
        </>
    )
}

WhereYouWork.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default WhereYouWork
