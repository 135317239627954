import { useEffect, useState } from 'react'
import useGetComponentBrands from 'src/pages/Profile/ComponentBrands/hooks/useGetComponentBrands'
import useUpdateBrands from 'src/pages/Profile/ComponentBrands/hooks/useUpdateBrands'
import { createDynamicComponentsSchema } from 'src/utils/validationSchema'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const useAddComponentBrands = (setModalHeight, nextHandler) => {
    const [action, setAction] = useState('see-overview') // see-overview || add-brands || edit-brands
    const [componentType, setComponentType] = useState('')
    const {
        componentTypes,
        componentBrands,
        componentTypeMap,
        componentBrandsFetching,
    } = useGetComponentBrands()
    const [inputs, setInputs] = useState(componentBrands)
    const [errors, setErrors] = useState({})
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [toast, setToast] = useState(toastData)
    const { isMobile } = useMediaQueries()

    const onUpdateBrandsSuccess = () => {
        setModalHeight(isMobile ? '' : 568)
        nextHandler()
    }

    const onAPIError = error => {
        setToast({
            showToast: true,
            toastMessage: error || 'An error occurred, please try again later.',
            messageType: 'error-secondary',
        })
        setTimeout(() => {
            setToast(toastData)
        }, 2000)
    }

    const { updateBrandsMutate, updateBrandsLoading } = useUpdateBrands(
        onUpdateBrandsSuccess,
        onAPIError,
    )

    const deleteError = key => {
        setErrors(prev => {
            const newErrors = { ...prev }
            delete newErrors[key]
            return newErrors
        })
    }

    const handleChangeAction = action => {
        setAction(action)
    }

    const handleAddBrand = category => {
        handleChangeAction('add-brands')
        setComponentType(category)
        setModalHeight(isMobile ? '' : 531)
    }

    const handleEditBrand = category => {
        handleChangeAction('edit-brands')
        setComponentType(category)
        setModalHeight(isMobile ? '' : 531)
    }

    const handleGoBack = () => {
        handleChangeAction('see-overview')
        setModalHeight(isMobile ? '' : '90vh')
    }

    const handleSave = names => {
        setInputs(prev => ({
            ...prev,
            [componentType]: names,
        }))
        handleChangeAction('see-overview')
        setModalHeight(isMobile ? '' : '90vh')
        deleteError(componentType)
    }

    const convertObjectToArray = obj => {
        const result = []

        for (const componentType in obj) {
            const componentBrands = obj[componentType].map(brand => ({
                name: brand,
            }))
            result.push({
                component_type: componentTypeMap[componentType],
                component_brands: componentBrands,
            })
        }

        return result
    }

    const handleSubmit = async () => {
        const dynamicSchema = createDynamicComponentsSchema(componentTypes)

        try {
            await dynamicSchema.validate(inputs, { abortEarly: false })
            const payload = convertObjectToArray(inputs)

            updateBrandsMutate({ payload })
        } catch (err) {
            const errorObj = {}
            err.inner.forEach(error => {
                errorObj[error.path] = error.message
            })
            setErrors(errorObj)
        }
    }

    useEffect(() => {
        if (componentBrands) {
            setInputs(componentBrands)
        }
    }, [componentBrands])

    return {
        componentTypes,
        componentBrandsFetching,
        action,
        handleAddBrand,
        handleEditBrand,
        componentType,
        inputs,
        errors,
        handleGoBack,
        handleSave,
        handleSubmit,
        updateBrandsLoading,
        toast,
        onAPIError,
    }
}

export default useAddComponentBrands
