import '../consumerkyc.scss'
import PropTypes from 'prop-types'
import BackNav from 'src/components/BackNav'
// import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import ChatCTA from 'src/components/ChatCTA'
import { useConsumerKYCContext } from '../context'
import { useHistory } from 'react-router-dom'

const PageHeading = ({
    // handleShowVideo,
    isAdminUser,
}) => {
    const history = useHistory()
    const { isMobile } = useMediaQueries()
    const { goBackToSystem } = useConsumerKYCContext()
    const abTestInBoundRequest = history?.location?.state?.abTestInBoundRequest

    return (
        <div className="OnePageKYC_HeadingSection" style={{ paddingBottom: 0 }}>
            {!abTestInBoundRequest && !isMobile && (
                <BackNav
                    v2
                    title={isAdminUser ? 'Back to admin portal' : 'Go back'}
                    marginBottom="16px"
                    onClick={() => {
                        if (isAdminUser) {
                            history.goBack()
                        } else {
                            goBackToSystem()
                        }
                    }}
                />
            )}
            {isAdminUser ? (
                <h3 className="Heading">View customer&apos;s information</h3>
            ) : (
                <h3 className="Heading">Welcome to the KYC section!</h3>
            )}
            {isAdminUser ? (
                <p className="SubHeading">
                    Consumer submitted data is readily accessible for
                    administrative review, with the ability to examine each
                    section in detail
                </p>
            ) : (
                <p className="SubHeading">
                    We would love to get to know you better! <br /> Kindly
                    provide the information requested below.
                </p>
            )}
            {/* TODO: uncomment when an updated video for one page kyc is inserted here */}
            {/* {!isAdminUser && (
                <div
                    className="KYCPageVideo"
                    onClick={() => handleShowVideo(true)}
                >
                    <PlayIcon />
                    <p>How to complete your KYC</p>
                </div>
            )} */}
            {isMobile && (
                <ChatCTA
                    text="Have questions about this process?"
                    margin="19px 0 0 0"
                />
            )}
        </div>
    )
}

PageHeading.propTypes = {
    handleShowVideo: PropTypes.func,
    isAdminUser: PropTypes.bool,
}

export default PageHeading
