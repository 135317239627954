import axios from 'src/config/axios'

export const getProfilesGroupsApi = async (
    currentPage,
    searchText,
    customer_type,
    paginate = true,
) => {
    const request = await axios.get(
        `/admin/energy-profile-groups?page=${currentPage}&name=${searchText}&customer_type=${customer_type}&paginate=${paginate}`,
    )
    return request
}

export const getProfilesGroupApi = async id => {
    const request = await axios.get(`/admin/energy-profile-groups/${id}`)
    return request
}

export const deleteProfilesGroupApi = async id => {
    const request = await axios.delete(`/admin/energy-profile-groups/${id}`)
    return request
}

export const createProfilesGroupApi = async payload => {
    const request = await axios.post('/admin/energy-profile-groups', payload)
    return request
}

export const updateProfilesGroupApi = async (id, payload) => {
    const request = await axios.patch(
        `/admin/energy-profile-groups/${id}`,
        payload,
    )
    return request
}
