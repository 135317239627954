import './updateneeds.scss'
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { getAppliances } from 'src/api/getyouroffer'
import { ConsumerButton } from 'src/components/Button'
import AddIcon from 'src/assets/images/addIcon.svg'
import { ReactComponent as ConsumerAddIcon } from 'src/assets/images/consumer-plus-icon.svg'
import CloseIcon from 'src/assets/images/black-close-icon.svg'
import ConsumerModalCloseIcon from 'src/assets/images/consumer-modal-close-icon.svg'
import BlueCloseIcon from 'src/assets/images/blue-close-icon.svg'
import { ReactComponent as ConsumerCloseIcon } from 'src/assets/images/consumer-close-icon.svg'
import { ReactComponent as ArrowIcon } from 'src/assets/images/blue-arrow.svg'
import { ReactComponent as CircleArrowIcon } from 'src/assets/images/Caret-Circle-Down.svg'
import { ReactComponent as DayIcon } from 'src/assets/images/day-icon.svg'
import { ReactComponent as NightIcon } from 'src/assets/images/night-icon.svg'
import {
    DoubleInputFields,
    SelectField,
    InputFields,
} from 'src/components/InputFields'
import {
    getLengthofEnergyInputArray,
    numberWithCommas,
} from 'src/utils/formatting'

import styles from './updateneeds.module.scss'
import CustomToolTip from '../CustomToolTip'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import {
    AppliancesValidationSchema,
    AppliancesValidationUpdatedSchema,
} from 'src/utils/validationSchema'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import map from 'lodash/map'
import ActionModal from '../ActionModalV2'
import { eventTrackers } from 'src/utils/eventTrackers'
import { appTracking } from 'src/utils/appTracker'
import { InlineLoader } from '../Loader'
import Toast from '../Toast'

// app tracker variables
const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'SSE_LANDING_PAGE'

const UpdateNeeds = ({
    closeModal,
    closeModalAfterUpdate = true,
    updateEnergyNeeds,
    energyNeeds,
    selectedAppliances = [],
    actionType,
    noPadding,
    page,
    sseUse,
    includeActionButton = true,
    parentModalHeight,
    underscoredKeys,
    title = undefined,
    description = undefined,
    summaryTitle = undefined,
    btnText,
    isLoading,
    toastError,
    errorMessage,
    isUpdatedSSEFlow,
    isPullEnergyNeeds,
    handleSecondaryButtonClick,
}) => {
    const {
        EnergyWrapper,
        HeaderWrapper,
        Header,
        SubHeader,
        ContentWrapper,
        ContentWrapperNoPadding,
        BtnWrapper,
        CloseIconWrapper,
        InputsWrapper,
        InputSection,
        InputSectionNoPadding,
        AddButton,
        AddButtonWrapper,
        RemoveApplianceStyle,
        RemoveApplianceText,
        UpdateApplianceName,
        ConsumerSSEHeader,
        SSEContentWrapper,
        SSEEnergySummaryChild,
        SSEAddButtonWrapper,
        ConsumerSSEFooter,
        SSEApplianceTopWrapper,
        SSEUpdateApplianceName,
        SSERemoveApplianceWrapper,
        SSERemoveApplianceText,
        SSECloseIcon,
        SSEConsumerAddIcon,
    } = styles
    const useUnderscoredKeys = page === 'estimation' || underscoredKeys
    const energyNeedInitialState = [
        {
            id: uuidv4(),
            name: '',
            quantity: '',
            backup_hours: '',
            backup_hours_night: '',
            powerRating: '',
            unitOfMeasurement: '',
        },
    ]
    let selectedEnergyNeeds =
        selectedAppliances.length === 0
            ? energyNeedInitialState
            : useUnderscoredKeys
            ? selectedAppliances.map(appliance => ({
                  ...cloneDeep(appliance),
                  id: uuidv4(),
              }))
            : selectedAppliances.map(appliance => ({
                  id: uuidv4(),
                  backup_hours: appliance.backup_hours,
                  backup_hours_night: appliance.backup_hours_night,
                  name: appliance.name,
                  quantity: appliance.quantity,
                  powerRating: appliance.powerRating,
                  unitOfMeasurement: appliance.unitOfMeasurement,
              }))

    const [applianceData, setApplianceData] = useState([])
    const [energyNeedsInputs, setEnergyNeedsInputs] = useState([])
    const [errors, setErrors] = useState({})
    const [showTooltip, setShowTooltip] = useState(true)
    const [showActionModal, setShowActionModal] = useState(false)
    const [changedAppliances, setChangedAppliances] = useState(false)
    const { isMobile } = useMediaQueries()

    const { refetch } = useQuery('getAppliances', () => getAppliances(), {
        enabled: false,
        retry: false,
        onSuccess: data => setApplianceData(data?.data?.data),
    })
    const ValidationSchema = useUnderscoredKeys
        ? AppliancesValidationUpdatedSchema
        : AppliancesValidationSchema

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => refetch(), [])

    useEffect(() => {
        if (isUpdatedSSEFlow) {
            const omitEnergyNeedsIds = map(energyNeedsInputs, needs =>
                omit(needs, 'id'),
            )
            const madeChanges = !(
                JSON.stringify(selectedAppliances) ===
                JSON.stringify(omitEnergyNeedsIds)
            )
            setChangedAppliances(madeChanges)
        } else {
            setChangedAppliances(true)
        }
    }, [energyNeedsInputs, selectedAppliances, isUpdatedSSEFlow])

    const handleAddAppliance = () => {
        setEnergyNeedsInputs([
            ...energyNeedsInputs,
            useUnderscoredKeys
                ? {
                      id: uuidv4(),
                      name: '',
                      quantity: null,
                      backup_hours: '',
                      backup_hours_night: '',
                      power_rating: '',
                      unit_of_measurement: '',
                  }
                : {
                      id: uuidv4(),
                      name: '',
                      quantity: null,
                      backup_hours: '',
                      backup_hours_night: '',
                      powerRating: '',
                      unitOfMeasurement: '',
                  },
        ])
    }
    const handleRemoveAppliance = id => {
        const filteredAppliances = energyNeedsInputs.filter(
            appliance => appliance.id !== id,
        )
        setEnergyNeedsInputs(filteredAppliances)
    }

    const handleClearAll = () => {
        setEnergyNeedsInputs(energyNeedInitialState)
    }

    const handleCloseIconClick = () => {
        if (energyNeedsInputs?.length > energyNeeds?.length) {
            setShowActionModal(true)
            return
        }
        setEnergyNeedsInputs(deepCopyEnergyNeeds(selectedEnergyNeeds))
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseApplianceModal'].action,
            eventTrackers['sseApplianceModal'].label,
            eventTrackers['sseApplianceModal'].category,
            ['MP', 'GA'],
            'event',
            {
                event: deepCopyEnergyNeeds(selectedEnergyNeeds),
            },
        )
        setErrors({})
        closeModal && closeModal()
    }

    //check for errors in mapped inputs
    const handleClose = () => {
        let updatedEnergyNeedsInputs = energyNeedsInputs.map(item => {
            if (item.backup_hours === '') {
                item.backup_hours = 0
            }
            if (item.backup_hours_night === '') {
                item.backup_hours_night = 0
            }
            return item
        })

        ValidationSchema.validate(
            { appliances: updatedEnergyNeedsInputs },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                setErrors({})
                updateEnergyNeeds &&
                    updateEnergyNeeds(updatedEnergyNeedsInputs, true)
                if (closeModalAfterUpdate && closeModal) {
                    closeModal()
                }
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }
    //summary calculation

    const wattsPerAppliance = energyNeedsInputs.map(
        item =>
            (useUnderscoredKeys ? item.power_rating : item.powerRating) *
            item.quantity,
    )

    const totalWattsAdded = wattsPerAppliance.reduce((a, b) => a + b, 0) || 0

    const dayUsage = energyNeedsInputs.map(
        item =>
            (useUnderscoredKeys ? item.power_rating : item.powerRating) *
            item.backup_hours *
            item.quantity,
    )
    const totalDayUsage = dayUsage.reduce((a, b) => a + b, 0) || 0
    const nightUsage = energyNeedsInputs.map(
        item =>
            (useUnderscoredKeys ? item.power_rating : item.powerRating) *
            item.backup_hours_night *
            item.quantity,
    )
    const totalNightUsage = nightUsage.reduce((a, b) => a + b, 0) || 0
    const averageDailyUsage = totalDayUsage + totalNightUsage || 0

    const modalHeightResponsiveStyles = () => {
        switch (parentModalHeight) {
            case 590:
                return {
                    maxHeightOnSummaryHidden: '300px',
                    maxHeightOnSummaryVisible: '200px',
                }
            default:
                return {
                    maxHeightOnSummaryHidden: '415px',
                    maxHeightOnSummaryVisible: '300px',
                }
        }
    }

    const { maxHeightOnSummaryHidden, maxHeightOnSummaryVisible } =
        modalHeightResponsiveStyles()

    function displaySummaryBody() {
        const cb = document.querySelector('#summary')
        cb.className === 'GYOEnergySummaryBodyVisible'
            ? ((document.getElementById('summary').className =
                  'GYOEnergySummaryBody'),
              (document.getElementById('arrow').className =
                  'GYOEnergySummaryArrowRotateHidden'),
              !isMobile &&
                  (document.getElementById('inputs-height').style.maxHeight =
                      maxHeightOnSummaryHidden))
            : ((document.getElementById('summary').className =
                  'GYOEnergySummaryBodyVisible'),
              (document.getElementById('arrow').className =
                  'GYOEnergySummaryArrowRotateVisible'),
              !isMobile &&
                  (document.getElementById('inputs-height').style.maxHeight =
                      maxHeightOnSummaryVisible))
        setShowTooltip(!showTooltip)
    }

    // is true if the page is consumer-SSE, assigned this to a variable so it'll be easy to change if the name of the page changes
    const isConsumerSSE = page === 'consumer-SSE'

    const deepCopyEnergyNeeds = arr => JSON.parse(JSON.stringify(arr))

    useEffect(() => {
        setEnergyNeedsInputs(deepCopyEnergyNeeds(selectedEnergyNeeds))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const acceptIntegersGreaterThanOne = val => {
        const numberRegex = /^[0-9.,]*$/
        const result = val === '0' ? '' : numberRegex.test(val) ? val : ''
        return result
    }

    const handlePowerRatingInputOnBlur = (e, index) => {
        if (e.target.value === '' || e.target.value == 0) {
            setErrors(prev => ({
                ...prev,
                [`appliances[${index}].${
                    useUnderscoredKeys ? 'power_rating' : 'powerRating'
                }`]: 'Please enter a valid power rating',
            }))
        }
    }

    return (
        <>
            <ActionModal
                actionModalOpen={showActionModal}
                title="Save your appliances"
                actionType="warning"
                onCancel={() => {
                    setShowActionModal(false)
                    setEnergyNeedsInputs(
                        deepCopyEnergyNeeds(selectedEnergyNeeds),
                    )
                    setErrors({})
                    closeModal && closeModal()
                }}
                primaryBtnText="Save"
                handlePrimaryBtnClick={() => {
                    handleClose()
                    setShowActionModal(false)
                }}
                headerText="You’re yet to save your added appliances"
                subTitle="Closing without saving your appliances, you might
                lose the appliances submitted already. Click on the save button 
                below to save the added appliances"
            />
            {!isUpdatedSSEFlow && (
                <>
                    {isConsumerSSE ? (
                        <div className={ConsumerSSEHeader}>
                            <p></p>
                            <p>Describe your Energy Needs</p>
                            <img
                                src={ConsumerModalCloseIcon}
                                alt="close icon"
                                onClick={() => {
                                    handleCloseIconClick()
                                }}
                            />
                        </div>
                    ) : (
                        <div className={CloseIconWrapper}>
                            <img
                                src={CloseIcon}
                                alt="close icon"
                                onClick={() => {
                                    closeModal && closeModal()
                                    setEnergyNeedsInputs(
                                        deepCopyEnergyNeeds(
                                            selectedEnergyNeeds,
                                        ),
                                    )
                                }}
                            />
                        </div>
                    )}
                </>
            )}
            <div
                className={`${
                    isConsumerSSE ? SSEContentWrapper : ContentWrapper
                } ${noPadding && ContentWrapperNoPadding}`}
            >
                {toastError && (
                    <Toast messageType={'error'} message={errorMessage} />
                )}
                <div className={EnergyWrapper}>
                    {page !== 'consumer-SSE' && (
                        <div className={HeaderWrapper}>
                            <h1 className={Header}>
                                {title ?? 'Describe your Energy Needs'}
                            </h1>
                            <p className={SubHeader}>
                                {description ??
                                    'Fill in the appliances you’d like to power below'}
                            </p>
                        </div>
                    )}
                </div>

                {!isPullEnergyNeeds && (
                    <div
                        className="GYOEnergySummaryWrapper"
                        style={{ marginBottom: isConsumerSSE && '0px' }}
                    >
                        <div
                            className={
                                isConsumerSSE
                                    ? SSEEnergySummaryChild
                                    : 'GYOEnergySummaryChild'
                            }
                        >
                            <div className="GYOEnergySummaryHeader">
                                <p
                                    style={{
                                        textTransform:
                                            isConsumerSSE && 'inherit',
                                    }}
                                >
                                    {summaryTitle ??
                                        'See your energy needs summary'}
                                </p>
                                <div>
                                    <span className="GYOEnergySummaryEnergyCount">
                                        {/* use non-transformed data in estimation page preview and transformed data in editing mode and others */}
                                        {page === 'estimation' &&
                                        actionType === 'preview'
                                            ? selectedAppliances?.length
                                            : getLengthofEnergyInputArray(
                                                  energyNeedsInputs,
                                              )}
                                    </span>
                                    <CustomToolTip
                                        text={
                                            'Click here to view the total power capacity of your energy needs.'
                                        }
                                        toolTipFontSize="12px"
                                        toolTipPadding="10px 6px 14px 11px"
                                        showTooltip={showTooltip}
                                    >
                                        <div
                                            className="GYOEnergySummaryCollapse"
                                            onClick={displaySummaryBody}
                                        >
                                            <p
                                                className="GYOEnergySummaryEnergyCountLabel"
                                                style={{
                                                    marginRight:
                                                        isConsumerSSE && '5px',
                                                }}
                                            >
                                                Appliance(s) added
                                            </p>
                                            <span
                                                id="arrow"
                                                className="GYOEnergySummaryArrowHidden"
                                            >
                                                {isConsumerSSE ? (
                                                    <CircleArrowIcon
                                                        style={{
                                                            marginTop: '5px',
                                                        }}
                                                    />
                                                ) : (
                                                    <ArrowIcon />
                                                )}
                                            </span>
                                        </div>
                                    </CustomToolTip>
                                </div>
                            </div>
                            <div className="GYOEnergySummaryBody" id="summary">
                                <div className="space-between">
                                    <p className="GYOEnergySummaryTotalWattsLabel">
                                        Total watts added
                                    </p>
                                    <div
                                        className="space-between"
                                        style={{
                                            gridColumnGap: '6px',
                                            width: isMobile
                                                ? 'fit-content'
                                                : '184px',
                                        }}
                                    >
                                        <div className="GYOEnergySummaryBodyUsageLabel">
                                            <DayIcon />
                                            Total day usage
                                        </div>
                                        <span className="GYOEnergySummaryBodyUsageValue">
                                            {numberWithCommas(
                                                parseFloat(
                                                    totalDayUsage,
                                                    10,
                                                ).toFixed(2),
                                            )}{' '}
                                            wh
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="space-between"
                                    style={{
                                        marginTop: '4px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <p className="GYOEnergySummaryTotalWattsValue">
                                        {numberWithCommas(
                                            parseFloat(
                                                totalWattsAdded,
                                                10,
                                            ).toFixed(2),
                                        )}{' '}
                                        <sup>watts</sup>
                                    </p>
                                    <div
                                        className="space-between"
                                        style={{
                                            gridColumnGap: '6px',
                                            width: isMobile
                                                ? 'fit-content'
                                                : '184px',
                                        }}
                                    >
                                        <div className="GYOEnergySummaryBodyUsageLabel">
                                            <NightIcon />
                                            Total night usage
                                        </div>
                                        <span className="GYOEnergySummaryBodyUsageValue">
                                            {numberWithCommas(
                                                parseFloat(
                                                    totalNightUsage,
                                                    10,
                                                ).toFixed(2),
                                            )}{' '}
                                            wh
                                        </span>
                                    </div>
                                </div>
                                <div className="GYOEnergySummaryDivider"></div>
                                <div className="space-between">
                                    <p className="GYOEnergySummaryBodyUsageLabel">
                                        Daily Energy Consumption
                                    </p>
                                    <p className="GYOEnergySummaryBodyUsageValue">
                                        {numberWithCommas(
                                            parseFloat(
                                                averageDailyUsage,
                                                10,
                                            ).toFixed(2),
                                        )}{' '}
                                        wh
                                    </p>
                                </div>
                            </div>
                            {/* )} */}
                        </div>
                    </div>
                )}

                <div
                    className={`${InputSection} ${
                        noPadding && InputSectionNoPadding
                    }`}
                    style={{
                        maxHeight: isConsumerSSE
                            ? isMobile
                                ? ''
                                : '415px'
                            : '300px',
                    }}
                    id="inputs-height"
                >
                    {isUpdatedSSEFlow && !isPullEnergyNeeds && (
                        <div className="EditPrompt">
                            <p>Edit the listed appliances below</p>
                            <p>
                                Compatible appliances with the selected system
                                are listed below, choose to add or remove. Its
                                important to note that adding heavy appliances
                                can overload the system.
                            </p>
                        </div>
                    )}
                    {energyNeedsInputs.map((item, index) => (
                        <>
                            {isConsumerSSE ? (
                                <div className={SSEApplianceTopWrapper}>
                                    <p className={SSEUpdateApplianceName}>
                                        Appliance {index + 1}
                                    </p>
                                    {energyNeedsInputs.length > 1 && (
                                        <div
                                            onClick={() =>
                                                energyNeedsInputs.length > 1 &&
                                                handleRemoveAppliance(item.id)
                                            }
                                            className={
                                                SSERemoveApplianceWrapper
                                            }
                                            style={{
                                                pointerEvents:
                                                    energyNeedsInputs.length ===
                                                        1 && 'none',
                                            }}
                                        >
                                            <ConsumerCloseIcon
                                                className={SSECloseIcon}
                                                fill={
                                                    energyNeedsInputs.length ===
                                                    1
                                                        ? '#B0B0B0'
                                                        : '#004AAD'
                                                }
                                                stroke={
                                                    energyNeedsInputs.length ===
                                                    1
                                                        ? '#B0B0B0'
                                                        : '#004AAD'
                                                }
                                            />
                                            <p
                                                className={
                                                    SSERemoveApplianceText
                                                }
                                                style={{
                                                    color:
                                                        energyNeedsInputs.length ===
                                                            1 && '#B0B0B0',
                                                }}
                                            >
                                                Remove this appliance
                                            </p>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <p
                                    className={UpdateApplianceName}
                                    style={{
                                        marginTop: isConsumerSSE && '20px',
                                    }}
                                >
                                    Appliance {index + 1}
                                </p>
                            )}

                            <div className={InputsWrapper} key={item.id}>
                                <SelectField
                                    initialOption={
                                        item.name || 'Select Appliance'
                                    }
                                    values={applianceData.map(option => ({
                                        value: option.name,
                                    }))}
                                    name="name"
                                    activeBorder
                                    withCheckBox={true}
                                    selectWidth={
                                        isConsumerSSE ? '274px' : '224px'
                                    }
                                    currentSelected={item.name}
                                    value={item.name}
                                    handleChange={(name, value) => {
                                        item.name = value
                                        const selectedAppliance =
                                            applianceData.find(
                                                item => item.name === value,
                                            )
                                        useUnderscoredKeys
                                            ? (item.power_rating =
                                                  selectedAppliance.power_rating)
                                            : (item.powerRating =
                                                  selectedAppliance.power_rating)

                                        useUnderscoredKeys
                                            ? (item.unit_of_measurement =
                                                  selectedAppliance.unit_of_measurement)
                                            : (item.unitOfMeasurement =
                                                  selectedAppliance.unit_of_measurement)

                                        setEnergyNeedsInputs([
                                            ...energyNeedsInputs,
                                        ])
                                        delete errors[
                                            `appliances[${index}].name`
                                        ]
                                        delete errors[
                                            `appliances[${index}].power_rating`
                                        ]
                                        delete errors[
                                            `appliances[${index}].powerRating`
                                        ]
                                    }}
                                    disabled={
                                        actionType === 'preview' ? true : false
                                    }
                                    marginBottom="20px"
                                    marginRight="20px"
                                    errorMessage={
                                        errors[`appliances[${index}].name`]
                                    }
                                    floatingLabel={
                                        item.name === ''
                                            ? ''
                                            : isPullEnergyNeeds
                                            ? 'Appliance'
                                            : 'Select Appliance'
                                    }
                                    dropdownPositionRelative
                                    showSearch
                                    hideSuffixIcon={
                                        actionType === 'preview' &&
                                        isPullEnergyNeeds
                                    }
                                />
                                <InputFields
                                    inputWidth={
                                        isConsumerSSE ? '274px' : '224px'
                                    }
                                    title="Quantity"
                                    type={'text'}
                                    value={item.quantity}
                                    inputValue={item.quantity}
                                    handleChange={e => {
                                        item.quantity =
                                            acceptIntegersGreaterThanOne(
                                                e.target.value,
                                            )
                                        setEnergyNeedsInputs([
                                            ...energyNeedsInputs,
                                        ])
                                        delete errors[
                                            `appliances[${index}].quantity`
                                        ]
                                    }}
                                    name="quantity"
                                    marginRight="-3px"
                                    disabled={
                                        actionType === 'preview' ? true : false
                                    }
                                    onBlur={e => {
                                        item.quantity = e.target.value.trim()
                                        setEnergyNeedsInputs([
                                            ...energyNeedsInputs,
                                        ])
                                    }}
                                    errorMessage={
                                        errors[`appliances[${index}].quantity`]
                                    }
                                />
                                <DoubleInputFields
                                    title="Usage In Hours"
                                    sseUse={sseUse}
                                    type="number"
                                    inputWidthA={
                                        isConsumerSSE ? '135px' : '116px'
                                    }
                                    inputWidthB={
                                        isConsumerSSE ? '130px' : '106px'
                                    }
                                    border="none"
                                    nameA="backup_hours"
                                    nameB="backup_hours_night"
                                    inputPlaceholderA={`Day ${
                                        sseUse ? 'Usage' : ''
                                    }`}
                                    inputPlaceholderB={`Night ${
                                        sseUse ? 'Usage' : ''
                                    }`}
                                    valueA={item.backup_hours}
                                    valueB={item.backup_hours_night}
                                    disabled={
                                        actionType === 'preview' ? true : false
                                    }
                                    handleChangeA={e => {
                                        item.backup_hours =
                                            e.target.value.length === 0
                                                ? ''
                                                : parseFloat(
                                                      e.target.value.trim(),
                                                  )
                                        setEnergyNeedsInputs([
                                            ...energyNeedsInputs,
                                        ])
                                        delete errors[
                                            `appliances[${index}].backup_hours_combined`
                                        ]
                                        delete errors[
                                            `appliances[${index}].backup_hours`
                                        ]
                                    }}
                                    handleChangeB={e => {
                                        item.backup_hours_night =
                                            e.target.value.length === 0
                                                ? ''
                                                : parseFloat(
                                                      e.target.value.trim(),
                                                  )
                                        setEnergyNeedsInputs([
                                            ...energyNeedsInputs,
                                        ])
                                        delete errors[
                                            `appliances[${index}].backup_hours_combined`
                                        ]
                                        delete errors[
                                            `appliances[${index}].backup_hours_night`
                                        ]
                                    }}
                                    marginRight="20px"
                                    step={
                                        innerWidth > 500
                                            ? 'addAttributesToComponent'
                                            : ''
                                    }
                                    marginBottom={innerWidth < 1024 && '20px'}
                                    inputTooltipTextA={
                                        'Your daytime backup hours would be between 8am to 6pm'
                                    }
                                    inputTooltipTextB={
                                        'Your night-time backup hours would be between 6pm to 8am'
                                    }
                                    inputTooltipWidthA={'180px'}
                                    inputTooltipWidthB={'180px'}
                                    toolTipMarginLeftA={
                                        isMobile ? '0px' : '3px'
                                    }
                                    arrowPositionLeftTooltipA
                                    errorMessage={
                                        errors[
                                            `appliances[${index}].backup_hours_combined`
                                        ] ||
                                        errors[
                                            `appliances[${index}].backup_hours`
                                        ] ||
                                        errors[
                                            `appliances[${index}].backup_hours_night`
                                        ]
                                    }
                                />
                                <InputFields
                                    title="Power Rating"
                                    type="number"
                                    inputWidth={
                                        isConsumerSSE ? '274px' : '224px'
                                    }
                                    name="powerRating"
                                    value={
                                        useUnderscoredKeys
                                            ? item.power_rating
                                            : item.powerRating
                                    }
                                    handleChange={e => {
                                        useUnderscoredKeys
                                            ? (item.power_rating =
                                                  e.target.value)
                                            : (item.powerRating =
                                                  e.target.value)
                                        setEnergyNeedsInputs([
                                            ...energyNeedsInputs,
                                        ])
                                        delete errors[
                                            `appliances[${index}].power_rating`
                                        ]
                                        delete errors[
                                            `appliances[${index}].powerRating`
                                        ]
                                    }}
                                    step="addAttributesToComponent"
                                    floatingLabel={item.unitOfMeasurement}
                                    disabled={
                                        actionType === 'preview' ? true : false
                                    }
                                    onBlur={e => {
                                        useUnderscoredKeys
                                            ? (item.power_rating =
                                                  e.target.value.trim())
                                            : (item.powerRating =
                                                  e.target.value.trim())
                                        setEnergyNeedsInputs([
                                            ...energyNeedsInputs,
                                        ])
                                        handlePowerRatingInputOnBlur(e, index)
                                    }}
                                    errorMessage={
                                        useUnderscoredKeys
                                            ? errors[
                                                  `appliances[${index}].power_rating`
                                              ]
                                            : errors[
                                                  `appliances[${index}].powerRating`
                                              ]
                                    }
                                />
                            </div>

                            {!isConsumerSSE && !isPullEnergyNeeds && (
                                <div className={RemoveApplianceStyle}>
                                    {actionType !== 'preview' && (
                                        <div
                                            onClick={() =>
                                                handleRemoveAppliance(item.id)
                                            }
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                            }}
                                        >
                                            <img
                                                src={BlueCloseIcon}
                                                alt="close icon"
                                            />
                                            <p className={RemoveApplianceText}>
                                                Remove appliance
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    ))}
                    {actionType !== 'preview' &&
                        !isPullEnergyNeeds &&
                        (isConsumerSSE ? (
                            <div className={SSEAddButtonWrapper}>
                                <ConsumerButton
                                    btnBgColor="#F9FCFF"
                                    btnTextColor="#004AAD"
                                    btnHeight="50px"
                                    btnWidth="190px"
                                    handleClick={handleAddAppliance}
                                    hover={true}
                                    type="iconBtn"
                                >
                                    <ConsumerAddIcon
                                        className={SSEConsumerAddIcon}
                                    />
                                    <span style={{ paddingLeft: '7px' }}>
                                        Add new appliance
                                    </span>
                                </ConsumerButton>
                            </div>
                        ) : (
                            <div className={AddButtonWrapper}>
                                <button
                                    className={AddButton}
                                    onClick={handleAddAppliance}
                                >
                                    <img src={AddIcon} />{' '}
                                    <span style={{ paddingLeft: '15px' }}>
                                        Add Appliance
                                    </span>
                                </button>
                            </div>
                        ))}
                </div>
            </div>
            {isConsumerSSE ? (
                <footer className={ConsumerSSEFooter}>
                    <ConsumerButton
                        btnBgColor="#FFFFFF"
                        btnTextColor={
                            energyNeedsInputs.length === 1
                                ? '#E2EEFF'
                                : '#004AAD'
                        }
                        btnWidth="160px"
                        btnHeight="53px"
                        btnFontSize="14px"
                        borderOutlineColor={
                            energyNeedsInputs.length === 1
                                ? '#E2EEFF'
                                : '#004AAD'
                        }
                        handleClick={handleClearAll}
                        disabled={energyNeedsInputs.length === 1}
                    >
                        Clear all{' '}
                    </ConsumerButton>
                    <ConsumerButton
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnWidth="160px"
                        btnHeight="53px"
                        btnFontSize="14px"
                        handleClick={handleClose}
                        disabled={!!isUpdatedSSEFlow && !changedAppliances}
                    >
                        {isLoading ? (
                            <InlineLoader />
                        ) : isUpdatedSSEFlow ? (
                            'Confirm'
                        ) : (
                            'Save appliances'
                        )}
                        {}
                    </ConsumerButton>
                </footer>
            ) : (
                <>
                    {includeActionButton && (
                        <div className={BtnWrapper}>
                            {isPullEnergyNeeds && (
                                <ConsumerButton
                                    btnBgColor="#E2EEFF"
                                    btnTextColor="var(--blue)"
                                    btnWidth="162px"
                                    btnHeight="56px"
                                    btnFontSize="14px"
                                    handleClick={handleSecondaryButtonClick}
                                    hover
                                >
                                    Go back
                                </ConsumerButton>
                            )}
                            <ConsumerButton
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                btnWidth="162px"
                                btnHeight="56px"
                                btnFontSize="14px"
                                handleClick={handleClose}
                                primaryhHover
                            >
                                {isLoading ? (
                                    <InlineLoader />
                                ) : actionType === 'preview' &&
                                  !isPullEnergyNeeds ? (
                                    'Okay, Got it'
                                ) : (
                                    btnText ?? 'Continue'
                                )}
                            </ConsumerButton>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

UpdateNeeds.propTypes = {
    closeModal: PropTypes.func,
    closeModalAfterUpdate: PropTypes.bool,
    updateEnergyNeeds: PropTypes.any,
    energyNeeds: PropTypes.any,
    selectedAppliances: PropTypes.array,
    actionType: PropTypes.any,
    noPadding: PropTypes.string,
    page: PropTypes.string,
    sseUse: PropTypes.any,
    includeActionButton: PropTypes.bool,
    parentModalHeight: PropTypes.number,
    underscoredKeys: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    summaryTitle: PropTypes.string,
    btnText: PropTypes.string,
    isLoading: PropTypes.bool,
    toastError: PropTypes.bool,
    errorMessage: PropTypes.string,
    isUpdatedSSEFlow: PropTypes.bool,
    isPullEnergyNeeds: PropTypes.bool,
    handleSecondaryButtonClick: PropTypes.func,
}

export default UpdateNeeds
