import { useState, useEffect } from 'react'
import { ProductHeader } from '../ProductHeader'
import Button from '../../Button'
import GetModal from '../../Modal'
import { InlineLoader } from '../../Loader'
import { floatWithCommas, subStringText } from '../../../utils/formatting'
import SeoComponent from '../../Seo'
import PropTypes from 'prop-types'
import plusBlueIcon from '../../../assets/images/Plus-Blue.svg'
import BlueCloseIcon from '../../../assets/images/blue-close-icon.svg'
import './addcomponents.scss'
import { CostField } from '../../InputFields/CostField'
import { SelectField } from '../../InputFields'
import { months } from './data'
import cloneDeep from 'lodash/cloneDeep'
import DescriptionComponent from 'src/components/DescriptionComponent/DescriptionComponent'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { v4 as uuidv4 } from 'uuid'

const AddCapexReplacement = ({
    handleStage,
    handleOpen,
    handleSavePackage,
    saveLoading,
    savePackage,
    displayedComponents,
    setDisplayedComponents,
    handleComponents,
}) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [componentId, setComponentId] = useState('')
    const [componentCopy, setComponentCopy] = useState([])
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [capexClick, setCapexClick] = useState(false)
    const [cost, setCost] = useState('')

    // app tracker variables
    const pageTitle = 'SunFi - Add A Package | Add Capex'
    const pageUrlName = window.location.pathname
    const pageTracker = 'ADD_PACKAGE_TRACKER'

    const handleModal = id => {
        setModalOpen(true)
        setComponentId(id)
    }

    useEffect(() => {
        setComponentCopy(cloneDeep(displayedComponents))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        handleComponents(displayedComponents)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addCapexRow = () => {
        componentCopy
            ?.find(displayedComponent => displayedComponent?.id === componentId)
            .capex_replacement_schedule.push({
                id: uuidv4(),
                cost: '',
                month: '',
            })
        setComponentCopy([...componentCopy])
    }

    useEffect(() => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['addPackageCapex'].action,
            eventTrackers['addPackageCapex'].label,
            eventTrackers['addPackageCapex'].category,
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const removeRow = id => {
        const filteredCapex = componentCopy
            ?.find(displayedComponent => displayedComponent?.id === componentId)
            .capex_replacement_schedule.filter(capex => capex.id !== id)
        const updatedDisplayedComponents = componentCopy?.find(
            displayedComponent => displayedComponent?.id === componentId,
        )
        updatedDisplayedComponents.capex_replacement_schedule =
            filteredCapex.length > 0
                ? filteredCapex
                : [
                      {
                          id: uuidv4(),
                          cost: '',
                          month: '',
                      },
                  ]
        setComponentCopy([...componentCopy])
    }
    const saveUserPackage = () => {
        handleSavePackage()
    }

    useEffect(() => {
        if (savePackage) {
            handleOpen()
        }

        // eslint-disable-next-line
    }, [savePackage])

    const noCapexInputs = componentCopy
        ?.find(displayedComponent => displayedComponent.id === componentId)
        ?.capex_replacement_schedule?.filter(
            item => item.cost.length < 1 || item.month.length < 1,
        )
    const updateCapex =
        displayedComponents
            ?.find(displayedComponent => displayedComponent.id === componentId)
            ?.capex_replacement_schedule?.filter(
                item => item.cost.length > 1 && item.month.length > 1,
            ).length > 0

    const saveCapex = () => {
        setDisplayedComponents(componentCopy)
        handleComponents(componentCopy)
        setShowErrorMessage(false)
        setModalOpen(false)
        setCapexClick(false)
    }
    const showError = () => {
        setShowErrorMessage(true)
        setCapexClick(false)
    }

    const handleAddCapex = () => {
        noCapexInputs.length > 0 ? showError() : saveCapex()
    }

    const handleClose = () => {
        setDisplayedComponents(displayedComponents)
        setModalOpen(false)
        setCapexClick(false)
        setShowErrorMessage(false)
        setComponentCopy(cloneDeep(displayedComponents))
    }

    useEffect(() => {
        capexClick && handleAddCapex()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capexClick])

    return (
        <>
            <SeoComponent
                title="SunFi - Add A Package | Add Capex"
                tracker="AddPackage"
            />
            <div className="ComponentWrapper">
                <div className="AddComponentRow">
                    <div className="LeftComponent">
                        <ProductHeader
                            title="Create New Product Package"
                            subtitle="Add capex here"
                        />
                        <div className="SelectedComponentsWrapper">
                            <table>
                                <tbody>
                                    {displayedComponents?.length > 0 &&
                                        displayedComponents.map(
                                            (item, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="SelectedComponents"
                                                    >
                                                        <td
                                                            style={{
                                                                width: '45%',
                                                            }}
                                                            className="AddComponentTd"
                                                        >
                                                            <span className="SelectedName">
                                                                {subStringText(
                                                                    item?.name,
                                                                    30,
                                                                )}{' '}
                                                            </span>
                                                        </td>

                                                        <td
                                                            style={{
                                                                width: '35%',
                                                            }}
                                                        >
                                                            <span className="SelectedCapacity">
                                                                {item
                                                                    ?.capex_replacement_schedule[0]
                                                                    ?.month
                                                                    ?.length > 1
                                                                    ? `${
                                                                          item
                                                                              .capex_replacement_schedule
                                                                              .length
                                                                      } Schedule${
                                                                          item
                                                                              .capex_replacement_schedule
                                                                              .length >
                                                                          1
                                                                              ? 's'
                                                                              : ''
                                                                      }`
                                                                    : 'No Schedule'}
                                                            </span>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '22%',
                                                            }}
                                                        >
                                                            <span
                                                                onClick={() =>
                                                                    handleModal(
                                                                        item.id,
                                                                    )
                                                                }
                                                                className="CapexButton"
                                                            >
                                                                {item
                                                                    ?.capex_replacement_schedule[0]
                                                                    ?.month
                                                                    ?.length > 1
                                                                    ? 'View'
                                                                    : 'Add'}{' '}
                                                                Capex
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            },
                                        )}
                                </tbody>
                            </table>
                        </div>
                        <div className="ComponentBtnWrapper">
                            <div style={{ marginRight: '15px' }}>
                                <Button
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="132px"
                                    handleClick={() => {
                                        handleStage('components')
                                    }}
                                >
                                    Previous
                                </Button>
                            </div>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                btnPadding="10px 15px"
                                btnHeight="50px"
                                handleClick={() => {
                                    process.env
                                        .REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                                    'true'
                                        ? handleStage('protective_device')
                                        : saveUserPackage()
                                }}
                                disabled={saveLoading ? true : false}
                            >
                                {saveLoading ? (
                                    <InlineLoader />
                                ) : process.env
                                      .REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                                  'true' ? (
                                    'Continue'
                                ) : (
                                    'Create Package'
                                )}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <DescriptionComponent
                            type="package"
                            text="Components are the building blocks of packages or your product library. Create components to enable faster creation of packages or customized solutions for customers"
                        />
                        <div className="MobileComponentBtnWrapper">
                            <div style={{ marginRight: '15px' }}>
                                <Button
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="132px"
                                    handleClick={() => {
                                        handleStage('components')
                                    }}
                                >
                                    Previous
                                </Button>
                            </div>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="132px"
                                btnPadding="10px 15px"
                                btnHeight="50px"
                                handleClick={() => {
                                    process.env
                                        .REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                                    'true'
                                        ? handleStage('protective_device')
                                        : saveUserPackage()
                                }}
                                disabled={saveLoading ? true : false}
                            >
                                {saveLoading ? (
                                    <InlineLoader />
                                ) : process.env
                                      .REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                                  'true' ? (
                                    'Continue'
                                ) : (
                                    'Create Package'
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
                <GetModal
                    app="workspace"
                    showModal={modalOpen}
                    onCancel={() => handleClose()}
                    closable={true}
                    content={
                        <>
                            <p className="CCFModalTitle">Capex Replacement</p>
                            <p className="CCFModalSubTitle">
                                Enter Your Capex Replacement Details
                            </p>
                            <div
                                style={{
                                    maxHeight: '235px',
                                    overflowY: 'auto',
                                }}
                            >
                                {componentId.length > 1 &&
                                    componentCopy
                                        ?.find(
                                            component =>
                                                component?.id === componentId,
                                        )
                                        .capex_replacement_schedule?.map(
                                            item => (
                                                <div
                                                    className="ApplianceWrapper"
                                                    key={item?.id}
                                                    onMouseLeave={() =>
                                                        setCost('')
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                        }}
                                                        className="MobileWrapper Capex"
                                                    >
                                                        <SelectField
                                                            initialOption={
                                                                'Select Month'
                                                            }
                                                            floatingLabel={
                                                                'Select Month'
                                                            }
                                                            floatLabelOnSelectedValue
                                                            selectWidth="224px"
                                                            values={months?.map(
                                                                option => ({
                                                                    value: option,
                                                                }),
                                                            )}
                                                            name="month"
                                                            showSearch
                                                            withCheckBox={true}
                                                            handleChange={(
                                                                name,
                                                                value,
                                                            ) => {
                                                                item.month =
                                                                    value
                                                                setShowErrorMessage(
                                                                    false,
                                                                )
                                                                setComponentCopy(
                                                                    [
                                                                        ...componentCopy,
                                                                    ],
                                                                )
                                                            }}
                                                            errorMessage={
                                                                showErrorMessage &&
                                                                item.month
                                                                    .length < 1
                                                                    ? 'Please select month'
                                                                    : ''
                                                            }
                                                            currentSelected={
                                                                item.month
                                                            }
                                                            value={item.month}
                                                            marginBottom="10px"
                                                            marginRight="20px"
                                                        />
                                                        <CostField
                                                            title={
                                                                'Predicted Amount'
                                                            }
                                                            inputMarginLeft={
                                                                '-24px'
                                                            }
                                                            selectWidth="80px"
                                                            type="text"
                                                            inputWidth="224px"
                                                            name="cost"
                                                            errorMessage={
                                                                showErrorMessage &&
                                                                item.cost
                                                                    .length < 1
                                                                    ? 'Please enter predicted amount'
                                                                    : ''
                                                            }
                                                            currencyCode={'₦'}
                                                            value={
                                                                item.cost ||
                                                                cost
                                                            }
                                                            handleChange={e => {
                                                                //regex to to allow numbers and comma
                                                                const numberRegex =
                                                                    /^[0-9.,]*$/
                                                                item.cost =
                                                                    numberRegex.test(
                                                                        e.target
                                                                            .value,
                                                                    )
                                                                        ? floatWithCommas(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                          )
                                                                        : cost
                                                                setShowErrorMessage(
                                                                    false,
                                                                )
                                                                setCost(
                                                                    numberRegex.test(
                                                                        e.target
                                                                            .value,
                                                                    )
                                                                        ? floatWithCommas(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                          )
                                                                        : cost,
                                                                )
                                                                setComponentCopy(
                                                                    componentCopy,
                                                                )
                                                            }}
                                                            noFloat
                                                            prefixDisabled={
                                                                true
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className="CapexRemoveApplianceWrapper"
                                                        onClick={() => {
                                                            removeRow(item?.id)
                                                            setShowErrorMessage(
                                                                false,
                                                            )
                                                        }}
                                                    >
                                                        <img
                                                            src={BlueCloseIcon}
                                                            alt="close icon"
                                                        />
                                                        <p>Remove capex</p>
                                                    </div>
                                                </div>
                                            ),
                                        )}
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                    className="AddBtn"
                                >
                                    <Button
                                        btnBgColor="var(--purple-light)"
                                        btnTextColor="var(--blue)"
                                        btnTextColorOutline="var(--blue)"
                                        btnOutlineColor="var(--purple-light)"
                                        btnBgColorOutline="#E2EEFF"
                                        type="outline"
                                        btnWidth="141px"
                                        handleClick={addCapexRow}
                                    >
                                        <img
                                            src={plusBlueIcon}
                                            alt="plus-icon"
                                            style={{
                                                marginRight: '12.75px',
                                            }}
                                        />
                                        Add New
                                    </Button>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <button
                                    className="CCFModalBtn"
                                    onClick={() => {
                                        setCapexClick(true)
                                    }}
                                >
                                    {updateCapex ? 'Update' : 'Add'} Capex
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    )
}

AddCapexReplacement.propTypes = {
    handleStage: PropTypes.func,
    handleOpen: PropTypes.func,
    name: PropTypes.string,
    displayedComponents: PropTypes.any,
    setDisplayedComponents: PropTypes.func,
    handleSavePackage: PropTypes.func,
    saveLoading: PropTypes.bool,
    savePackage: PropTypes.bool,
    handleComponents: PropTypes.func,
}

export default AddCapexReplacement
