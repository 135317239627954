import PropTypes from 'prop-types'

import Button from 'src/components/Button'
import csProviderActive from 'src/assets/images/cs_provider_active.svg'
import csProviderDefault from 'src/assets/images/cs_provider_default.svg'
import csClusterActive from 'src/assets/images/cs_clusters_active.svg'
import csClusterDefault from 'src/assets/images/cs_clusters_default.svg'

import style from './selectcredittype.module.scss'
import { InlineLoader } from 'src/components/Loader'

const SelectCreditTypeModal = ({
    closeModal,
    creditVariableType,
    setCreditVariableType,
    fetchingSunFiPackages,
    fetchingProviders,
}) => {
    const {
        ModalWrapper,
        HeaderWrapper,
        HeaderStyle,
        SubheaderStyle,
        SectionWrapper,
        UserImgStyle,
        SectionTextWrapper,
        SectionHeader,
        SectionText,
    } = style

    return (
        <div className={ModalWrapper}>
            <div className={HeaderWrapper}>
                <header className={HeaderStyle}>Variable Type</header>
                <h3 className={SubheaderStyle}>
                    Which type of variable do you want to create?
                </h3>
            </div>
            <section
                className={SectionWrapper}
                onClick={() => setCreditVariableType('new_cluster')}
                style={{
                    background:
                        creditVariableType === 'new_cluster'
                            ? '#E2EEFF'
                            : '#fff',
                    border:
                        creditVariableType === 'new_cluster'
                            ? '0.5px solid #004AAD'
                            : '0.5px solid #E5E5E5',
                }}
            >
                <div
                    className={UserImgStyle}
                    style={{
                        background:
                            creditVariableType === 'new_cluster'
                                ? '#004AAD'
                                : '#fff',
                    }}
                >
                    <img
                        src={
                            creditVariableType === 'new_cluster'
                                ? csClusterActive
                                : csClusterDefault
                        }
                        alt="new-user-icon"
                    />
                </div>

                <div className={SectionTextWrapper}>
                    <h3 className={SectionHeader}>DTC Clusters</h3>
                    <p className={SectionText}>
                        I want to create a new DTC cluster & credit variables
                    </p>
                </div>
            </section>
            <section
                className={SectionWrapper}
                onClick={() => setCreditVariableType('new_dep_cluster')}
                style={{
                    background:
                        creditVariableType === 'new_dep_cluster'
                            ? '#E2EEFF'
                            : '#fff',
                    border:
                        creditVariableType === 'new_dep_cluster'
                            ? '0.5px solid #004AAD'
                            : '0.5px solid #E5E5E5',
                }}
            >
                <div
                    className={UserImgStyle}
                    style={{
                        background:
                            creditVariableType === 'new_dep_cluster' &&
                            '#004AAD',
                    }}
                >
                    <img
                        src={
                            creditVariableType === 'new_dep_cluster'
                                ? csClusterActive
                                : csClusterDefault
                        }
                        alt="new-user-icon"
                    />
                </div>
                <div className={SectionTextWrapper}>
                    <h3 className={SectionHeader}>DEP Clusters</h3>
                    <p className={SectionText}>
                        I want to create a new DEP cluster & credit variables
                    </p>
                </div>
            </section>
            <section
                className={SectionWrapper}
                onClick={() => setCreditVariableType('existing_provider')}
                style={{
                    background:
                        creditVariableType === 'existing_provider'
                            ? '#E2EEFF'
                            : '#fff',
                    border:
                        creditVariableType === 'existing_provider'
                            ? '0.5px solid #004AAD'
                            : '0.5px solid #E5E5E5',
                }}
            >
                <div
                    className={UserImgStyle}
                    style={{
                        background:
                            creditVariableType === 'existing_provider' &&
                            '#004AAD',
                    }}
                >
                    <img
                        src={
                            creditVariableType === 'existing_provider'
                                ? csProviderActive
                                : csProviderDefault
                        }
                        alt="new-user-icon"
                    />
                </div>
                <div className={SectionTextWrapper}>
                    <h3 className={SectionHeader}>Provider Credit Variables</h3>
                    <p className={SectionText}>
                        I want to create credit variables for an approved
                        provider
                    </p>
                </div>
            </section>
            <div style={{ marginTop: '20px' }}>
                <Button
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnWidth="160px"
                    btnHeight="56px"
                    disabled={creditVariableType === ''}
                    handleClick={() => {
                        closeModal()
                    }}
                >
                    {fetchingProviders || fetchingSunFiPackages ? (
                        <InlineLoader />
                    ) : (
                        'Create Variable'
                    )}
                </Button>
            </div>
        </div>
    )
}

SelectCreditTypeModal.propTypes = {
    closeModal: PropTypes.func,
    setCreditVariableType: PropTypes.func,
    creditVariableType: PropTypes.string,
    fetchingProviders: PropTypes.bool,
    fetchingSunFiPackages: PropTypes.bool,
}

export default SelectCreditTypeModal
