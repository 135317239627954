import PropTypes from 'prop-types'
import { ReactComponent as QuestionIcon } from '../../../../../../assets/images/question-mark-circle.svg'
import { ReactComponent as CircleWarning } from '../../../../../../assets/images/Circle_Warning.svg'
import { assessmentCriteriaMap, getCriteriaKey } from '../../utils/utils'
import styles from './CreditReviewTab.module.scss'
import { useState } from 'react'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { AppButton } from 'src/components/Button'

const CreditScore = ({ plan }) => {
    const [showFailureDetails, setShowFailureDetails] = useState(false)
    const evalCriteria = plan?.credit_decision?.evaluation_criteria
    const evalErrors = plan?.credit_decision?.evaluation_errors

    return (
        <>
            <div className={styles.dropdown}>
                <div className={styles.dropdown__head}>
                    <h4>Assessment Criteria</h4>
                    <a
                        href="https://docs.google.com/spreadsheets/d/19ZUVkt1NgxXVys1jaqRfn63PFHjEo1JH9Si5d3pjYgk/edit#gid=1169875630"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <QuestionIcon /> See credit curve model
                    </a>
                </div>

                <ul className={styles.dropdown__body}>
                    {getCriteriaKey(evalCriteria)?.map(keyName => (
                        <div
                            key={keyName}
                            className={styles.dropdown__body__link}
                        >
                            {evalCriteria[keyName]?.computation_status ===
                            'FAILED' ? (
                                <>
                                    <SecondaryModal
                                        showModal={showFailureDetails}
                                        onCancel={() =>
                                            setShowFailureDetails(false)
                                        }
                                        modalWidth={'459px'}
                                        modalHeight={'416px'}
                                        content={
                                            <div
                                                className={
                                                    styles.creditFailureModal
                                                }
                                            >
                                                <h2>
                                                    There was an error while
                                                    processing{' '}
                                                </h2>
                                                <p>
                                                    These are the following
                                                    errors encountered while
                                                    processing the result
                                                </p>
                                                <div
                                                    className={
                                                        styles.creditFailureModal__body
                                                    }
                                                >
                                                    <CircleWarning />
                                                    <p>{evalErrors[keyName]}</p>
                                                </div>
                                                <div
                                                    className={
                                                        styles.creditFailureModal__btns
                                                    }
                                                >
                                                    <AppButton
                                                        btnTextColor="#fff"
                                                        btnBgColor="#004AAD"
                                                        btnHeight="50px"
                                                        btnWidth="132px"
                                                        handleClick={() => {
                                                            setShowFailureDetails(
                                                                false,
                                                            )
                                                        }}
                                                    >
                                                        Done
                                                    </AppButton>
                                                </div>
                                            </div>
                                        }
                                        handlePrimaryBtnClick={() => {
                                            setShowFailureDetails(false)
                                        }}
                                    />
                                    <p>
                                        {assessmentCriteriaMap[keyName]}{' '}
                                        <span
                                            className={styles.creditEngineError}
                                        >
                                            Unable to Process
                                        </span>
                                    </p>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() =>
                                            setShowFailureDetails(true)
                                        }
                                    >
                                        See reason
                                    </a>
                                </>
                            ) : (
                                <>
                                    <p>{assessmentCriteriaMap[keyName]} </p>
                                    <small>
                                        <span>
                                            {
                                                evalCriteria[keyName]
                                                    ?.weighted_score
                                            }
                                        </span>
                                        /
                                        <span>
                                            {
                                                evalCriteria[keyName]
                                                    ?.weight_value
                                            }
                                        </span>
                                    </small>
                                </>
                            )}
                        </div>
                    ))}
                    {/* TODO: This code below is commented out because the file urls aren't being returned from the BE yet  */}
                    {/* <div className={styles.dropdown__body__link}>
                                    <p>
                                        Bank statement report{' '}
                                        <span>Indica</span>
                                    </p>
                                    <a
                                        href={
                                            creditDecisionData
                                                ?.bank_statement_analysis?.url
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <AttachIcon />
                                        Bank statement analysis.pdf
                                    </a>
                                </div>
                                <div className={styles.dropdown__body__link}>
                                    <p>
                                        Credit bureau report{' '}
                                        <span>First central</span>
                                    </p>
                                    <a
                                        href={
                                            creditDecisionData?.credit_report
                                                ?.url
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <AttachIcon />
                                        Credit analysis.pdf
                                    </a>
                                </div> */}
                </ul>
            </div>
        </>
    )
}

export default CreditScore

CreditScore.propTypes = {
    plan: PropTypes.object,
}
