import PropTypes from 'prop-types'
import { useState } from 'react'
import FormTitleBar from 'src/components/FormTitleBar'
import { SelectField, TextArea } from 'src/components/InputFields'
import Button from 'src/components/Button'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { useQuery } from 'react-query'
import {
    assignProviderResourceApi,
    deactivateAdminProviderApi,
} from 'src/api/admin/provider'
import { InlineLoader } from 'src/components/Loader'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'src/utils/mediaQueries'

const AdminProviderMoreActions = ({
    ownerId,
    providerId,
    action,
    showModal,
    setMoreActionsModal,
    inputs,
    setInputs,
    errors,
    setErrors,
    setSuccessModal,
    availableResources,
    setTriggerRefetch,
    setTriggerResourceRefetch,
}) => {
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const history = useHistory()

    const handleSelect = (name, value) => {
        setInputs(prev => ({ ...prev, [name]: value }))
    }
    const handleChange = event => {
        const { name, value } = event.target
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    // assign provider to resource
    const { refetch: assignResourceRefetch, isLoading: assignResourceLoading } =
        useQuery(
            ['assign-provider-to-resource'],
            () => assignProviderResourceApi(providerId, inputs.resource),
            {
                enabled: false,
                retry: false,
                onSuccess: () => {
                    setMoreActionsModal(false)
                    setSuccessModal(true)
                    setTriggerRefetch(true)
                    setTriggerResourceRefetch(true)
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    //deactivate calls
    const {
        refetch: deactivateProviderRefetch,
        isLoading: deactivateProviderLoading,
    } = useQuery(
        ['deactivate-admin-provider'],
        () => deactivateAdminProviderApi(ownerId, inputs.reason),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                cancelModal()
                setSuccessModal(true)
                setTimeout(() => {
                    setSuccessModal(false)
                    history.goBack()
                }, 2000)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const handleSubmit = () => {
        if (action === 'assign-resource') {
            if (inputs?.resource === null) {
                setErrors({ resource: 'This field is required' })
            } else {
                assignResourceRefetch()
            }
        }

        if (action === 'deactivate-provider') {
            if (inputs?.reason === null) {
                setErrors({ reason: 'This field is required' })
            } else {
                deactivateProviderRefetch()
            }
        }
    }

    const cancelModal = () => {
        setMoreActionsModal(false)
        setInputs({
            resource: null,
            provider: null,
            reason: null,
        })
        setErrors('')
    }

    const loading = deactivateProviderLoading || assignResourceLoading

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={() => cancelModal()}
            modalWidth="624px"
            modalHeight={'fit-content'}
            noPadding="48px 80px"
            modalClass="RecommendedEnergy"
            content={
                <div>
                    {toastError && (
                        <Toast messageType="error" message={errorMessage} />
                    )}
                    <FormTitleBar
                        title={
                            action === 'assign-resource'
                                ? 'Assign Provider to Resource'
                                : action === 'deactivate-provider' &&
                                  'Deactivate Provider'
                        }
                        subtitle={
                            action === 'assign-resource'
                                ? 'Please select from the options below'
                                : action === 'deactivate-provider' &&
                                  'Enter a reason for deactivating the user'
                        }
                        fontFamily="SF-Pro-Display-Bold"
                        titleFontSize={20}
                        alignLeft={isMobile}
                    />

                    {action === 'assign-resource' && (
                        <SelectField
                            name={action === 'assign-resource' && 'resource'}
                            values={
                                action === 'assign-resource' &&
                                availableResources?.map(option => ({
                                    value: option,
                                }))
                            }
                            value={
                                action === 'assign-resource'
                                    ? inputs?.resource === null
                                        ? 'Select Resource'
                                        : inputs?.resource
                                    : action === 'change-type' &&
                                      inputs?.provider === null
                                    ? 'Select Provider Type'
                                    : inputs?.provider
                            }
                            type="approvedModal"
                            initialOption={
                                action === 'assign-resource' &&
                                'Select Resource'
                            }
                            selectedValue={
                                action === 'assign-resource' && inputs?.resource
                            }
                            currentSelected={
                                action === 'assign-resource' && inputs?.resource
                            }
                            handleChange={handleSelect}
                            optionStyle={{
                                width: '100%',
                                marginTop: '6px',
                                marginBottom: '6px',
                            }}
                            selectWidth="100%"
                            withCheckBox={true}
                            errorMessage={
                                action === 'assign-resource' && errors?.resource
                            }
                        />
                    )}

                    {action === 'deactivate-provider' && (
                        <TextArea
                            width="464px"
                            height="146px"
                            placeholder={'Enter Reason'}
                            name="reason"
                            value={inputs?.reason}
                            handleChange={handleChange}
                            errorMessage={errors?.reason}
                            padding="10px 20px"
                        />
                    )}

                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: `${
                                action === 'deactivate-provider'
                                    ? '50px'
                                    : '136px'
                            }`,
                        }}
                    >
                        <Button
                            btnWidth="160px"
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnHeight="56px"
                            handleClick={() => handleSubmit()}
                        >
                            {loading ? (
                                <InlineLoader />
                            ) : action === 'assign-resource' ? (
                                'Continue'
                            ) : (
                                action === 'deactivate-provider' && 'Deactivate'
                            )}
                        </Button>
                    </div>
                </div>
            }
        />
    )
}

AdminProviderMoreActions.propTypes = {
    providerId: PropTypes.string,
    ownerId: PropTypes.string,
    action: PropTypes.string,
    showModal: PropTypes.bool,
    setMoreActionsModal: PropTypes.func,
    inputs: PropTypes.any,
    setInputs: PropTypes.func,
    errors: PropTypes.any,
    setErrors: PropTypes.func,
    setSuccessModal: PropTypes.func,
    setWarningModal: PropTypes.func,
    setErrorMessage: PropTypes.func,
    availableResources: PropTypes.array,
    setTriggerRefetch: PropTypes.func,
    setTriggerResourceRefetch: PropTypes.func,
}

export default AdminProviderMoreActions
