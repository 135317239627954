import queryString from 'query-string'

export const addQueryParamsToUrl = queryParamsObject => {
    const url = new URL(window.location.href)
    for (const key in queryParamsObject) {
        url.searchParams.set(key, queryParamsObject[key])
    }
    window.history.pushState({}, '', url)
}

export const removeQueryParamFromUrl = paramToRemove => {
    const url = new URL(window.location.href)
    url.searchParams.delete(paramToRemove)
    window.history.pushState({}, '', url)
}

export const removeAllQueryParamsFromUrl = () => {
    const url = new URL(window.location.href)
    url.search = ''
    window.history.pushState({}, '', url)
}

export const getSearchParamsFromUrl = () => {
    return queryString.parse(window.location.search)
}
