import PropTypes from 'prop-types'
import styles from './table.module.scss'
import { formatInfoFromDep } from '../data'

const PlanInfoTable = ({ data }) => {
    const { Table, TableItem } = styles
    return (
        <div className={Table}>
            {formatInfoFromDep(data).map((item, i) => (
                <div key={i} className={TableItem}>
                    <h4>{item.label}</h4>
                    <p>{item.value ?? '-'}</p>
                </div>
            ))}
        </div>
    )
}

PlanInfoTable.propTypes = {
    data: PropTypes.any,
}

export default PlanInfoTable
