import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    current_page: 1,
    provider_name: '',
    global_access: '',
    start_date: null,
    end_date: null,
    packages_count_start: 0,
    packages_count_end: 0,
    components_count_start: '',
    components_count_end: '',
    current_tab: '1',
    filter_mode: '',
}

const adminProductsListing = createSlice({
    name: 'adminProductsListingFilter',
    initialState,
    reducers: {
        updateAdminProductsFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetAdminProductsFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateAdminProductsFilters, resetAdminProductsFilters } =
    adminProductsListing.actions

export const updatedAdminProductsFilters = state => state.adminProductsFilter

const persistConfig = {
    key: 'adminProductsListingFilter',
    storage,
}

export const persistedAdminProductsListingReducer = persistReducer(
    persistConfig,
    adminProductsListing.reducer,
)

export default adminProductsListing.reducer
