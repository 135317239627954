import { ReactComponent as Email } from 'src/assets/images/email-no-stroke.svg'
import { ReactComponent as Phone } from 'src/assets/images/phone-no-stroke.svg'
export const signInMethods = [
    {
        title: 'Sign in with Email',
        description: 'An OTP will be sent to your email address',
        key: 'email',
        icon: <Email />,
    },
    {
        title: 'Sign in with Phone',
        description: 'An OTP will be sent to your phone number',
        key: 'phone',
        icon: <Phone />,
    },
]
