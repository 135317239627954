/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { useHistory } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { Row, Col, Skeleton } from 'antd'
import { useQuery } from 'react-query'
import SeoComponent from 'src/components/Seo'
import Toast from '../../../components/Toast'
import Button from '../../../components/Button'
import ActionModal from '../../../components/ActionModal'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/deleteIcon.svg'
import Table from '../../../components/Table'
import HelpCenter from '../../../components/HelpCenter'
import FloatingBox from '../../../components/FloatingBox'
import QuestionMarkIcon from '../../../assets/images/questionMark.svg'
import BackNav from '../../../components/BackNav'
import { eventTrackers } from '../../../utils/eventTrackers.js'
import { appTracking } from '../../../utils/appTracker.js'
import { errorHandler } from '../../../utils/errorHandler'
import {
    getSingleComponentApi,
    deleteSingleComponentApi,
} from '../../../api/products/component'

import { convertToSentenceCase } from '../../../utils/formatting'
import { formatAttributes } from './formatter'
import { currencyFormatter } from '../../../utils/currencyFormatter'
import { checkLibraryProductAccess } from '../../../utils/checkLibraryProductAccess'
import { denyPermission } from '../../../utils/permissionFramework'
import { isMobile } from 'src/utils/mediaQueries'
import { decodeUserInfo } from 'src/utils/auth'
import { permissionsControl } from 'src/utils/permissionsControl'

import '../package.scss'

const tableHead = [
    { key: 'name', title: 'Attribute Name', isBold: true },
    { key: 'attributeValue', title: 'Attribute Value' },
    { key: 'unit', title: 'Unit of Measurement' },
    { key: 'icon', title: '' },
]

const ViewComponent = ({ match, productId }) => {
    const history = useHistory()

    const itemId = match?.params?.id || productId
    const noEditButton = history?.location?.state?.noEdit
    const providerId = history?.location?.state?.providerId
    const providerName = history?.location?.state?.providerName
    const referrer = history?.location?.state?.referrer ?? ''

    const [componentData, setComponentData] = useState({})
    const [componentAttributes, setComponentAttributes] = useState([])
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)
    const [usedComponent, setUsedComponent] = useState(false)
    const userInfo = decodeUserInfo()
    const isAdminWorkspace = userInfo?.isAdminWorkspaceUser === 'true'

    // define permissions
    const canDeleteComponent = permissionsControl(['can_delete_component'])
    const canEditComponent = permissionsControl(['can_update_component'])

    const toolTipEditText = `${
        !canEditComponent
            ? 'You are not authorised to perform this action'
            : 'You are not authorised to edit a global component'
    }`

    const toolTipDeleteText = `${
        !canDeleteComponent
            ? 'You are not authorised to perform this action'
            : 'You are not authorised to delete a global component'
    }`

    const pageTitle =
        'Delete A Component - Delete Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'DELETE_COMPONENT_TRACKER'

    const { isLoading, refetch, isFetchedAfterMount } = useQuery(
        ['fetchSingleComponent'],
        () => getSingleComponentApi(itemId, isAdminWorkspace),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setComponentData(data?.data?.data)
                setComponentAttributes(
                    formatAttributes(data?.data?.data?.attributes),
                )
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch component. Refresh page')
            },
        },
    )

    const { isFetching: deleteLoading, refetch: deleteRefetch } = useQuery(
        ['deleteSingleComponent'],
        () => deleteSingleComponentApi(match?.params?.id, isAdminWorkspace),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                backToComponents('/app/products')
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['deleteComponent'].action,
                    eventTrackers['deleteComponent'].label,
                    eventTrackers['deleteComponent'].category,
                )
            },
            onError: error => {
                let componentInUseError = errorHandler(error?.response?.data)[0]
                if (
                    componentInUseError[0] ===
                    "You can't delete this component because it's being used in a package."
                ) {
                    setErrorMessage(errorHandler(error?.response?.data))
                    setUsedComponent(true)
                } else {
                    setToastError(true)
                    setDeleteModal(false)
                    setErrorMessage('Failed to delete component. Refresh page')
                }
            },
        },
    )

    const handleModalState = () => {
        setDeleteModal(!deleteModal)
    }

    const redirectToEditPackage = () => {
        history.push({
            pathname: `/app/products/edit-component/${componentData?.id}`,
            state: {
                previousPath: 'viewComponent',
            },
        })
    }

    const handleDelete = () => {
        deleteRefetch()
    }

    useEffect(() => {
        refetch()

        // eslint-disable-next-line
    }, [itemId])

    const denyBtnAction =
        componentData?.is_global &&
        !checkLibraryProductAccess(componentData?.provider_id)

    const denyEditComponentBtnAction = denyPermission(
        'admin',
        'product_library',
        'component:can_edit',
    )
    const denyDeleteComponentBtnAction = denyPermission(
        'admin',
        'product_library',
        'component:can_delete',
    )

    const Btn = () => (
        <div className="btnWrapper ViewPage">
            <div>
                <Button
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnWidth={isMobile ? '160px' : '136px'}
                    btnHeight={isMobile ? '56px' : '44px'}
                    handleClick={
                        denyBtnAction || denyEditComponentBtnAction
                            ? () => {}
                            : redirectToEditPackage
                    }
                    disabled={
                        denyBtnAction ||
                        denyEditComponentBtnAction ||
                        !canEditComponent
                            ? true
                            : false
                    }
                    toolTipText={
                        denyBtnAction ||
                        denyEditComponentBtnAction | !canEditComponent
                            ? toolTipEditText
                            : null
                    }
                >
                    Edit Component
                    <img src={editIcon} alt="" style={{ marginLeft: 4 }} />
                </Button>
            </div>
            <div style={{ marginLeft: '37px' }} className="viewButton">
                <Button
                    btnBgColor="var(--purple-light)"
                    btnTextColor="var(--blue)"
                    btnTextColorOutline="var(--blue)"
                    btnOutlineColor="var(--purple-light)"
                    btnBgColorOutline="#E2EEFF"
                    type="outline"
                    btnWidth={isMobile ? '160px' : '136px'}
                    btnHeight={isMobile ? '56px' : '44px'}
                    handleClick={
                        denyBtnAction || denyDeleteComponentBtnAction
                            ? () => {}
                            : handleModalState
                    }
                    disabled={
                        denyBtnAction ||
                        denyDeleteComponentBtnAction ||
                        !canDeleteComponent
                            ? true
                            : false
                    }
                    toolTipText={
                        denyBtnAction ||
                        denyDeleteComponentBtnAction ||
                        !canDeleteComponent
                            ? toolTipDeleteText
                            : null
                    }
                >
                    Delete
                    <img src={deleteIcon} alt="" style={{ marginLeft: 12 }} />
                </Button>
            </div>
        </div>
    )

    const backToComponents = url => {
        if (referrer === 'view_package') {
            history.goBack()
        } else {
            history.push(
                providerId?.length < 0
                    ? url
                    : {
                          pathname: url,
                          search: '?tab=components',
                          state: {
                              id: providerId,
                              name: providerName,
                              isAdminWorkspace: isAdminWorkspace,
                              sunfiId: noEditButton ? '' : providerId,
                          },
                      },
            )
        }
    }

    return (
        <>
            <SeoComponent
                title="View Component - Products | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Products"
            />
            <ActionModal
                actionModalOpen={deleteModal}
                headerText={
                    usedComponent ? 'Component in Use' : 'Delete Component'
                }
                subTitle={
                    usedComponent
                        ? errorMessage
                        : 'Are you sure you want to delete this component?'
                }
                closeModal={handleModalState}
                actionHandler={usedComponent ? handleModalState : handleDelete}
                actionType="delete"
                actionText={usedComponent ? 'Okay, Got it' : 'Delete'}
                loading={deleteLoading}
                noCancelBtn={usedComponent && true}
            />
            <div className="viewPackage">
                <div className="viewMobileBackLink">
                    <BackNav
                        title={
                            referrer === 'view_package'
                                ? 'Back to Package'
                                : 'Back to Components'
                        }
                        onClick={() => backToComponents('/app/products')}
                    />
                </div>

                {toastError && (
                    <Toast messageType="error" message={errorMessage} />
                )}
                {!isFetchedAfterMount && !isLoading ? (
                    <div style={{ display: 'flex', marginTop: '50px' }}>
                        <div style={{ width: '60%' }}>
                            <Skeleton.Input className="ViewSkeleton" active />
                            <Skeleton.Input
                                style={{
                                    width: '85px',
                                    borderRadius: '7px',
                                    height: '25px',
                                }}
                                active
                            />

                            <div
                                style={{
                                    marginTop: '50px',
                                    marginBottom: '10px',
                                }}
                            >
                                <Skeleton.Input
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div>
                                <Skeleton.Input
                                    style={{ height: '20px' }}
                                    className="DescriptionSkeleton"
                                    active
                                />
                            </div>

                            <div
                                style={{
                                    marginTop: '45px',
                                    marginBottom: '25px',
                                }}
                            >
                                <Skeleton.Input
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div className="LoadingBtnsWrapper">
                                {[...Array(4)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        className="LoadingBtns"
                                        active
                                    />
                                ))}
                            </div>

                            <div>
                                {[...Array(4)].map(i => {
                                    return (
                                        <div
                                            key={i * Math.random()}
                                            className="componentsSkeleton"
                                        >
                                            <Skeleton.Button
                                                className="LoadingBtns"
                                                active
                                            />
                                            <Skeleton.Button
                                                style={{
                                                    width: '60px',
                                                    borderRadius: '7px',
                                                    height: '25px',
                                                }}
                                                active
                                            />
                                            <Skeleton.Button
                                                style={{
                                                    width: '150px',
                                                    borderRadius: '7px',
                                                    height: '25px',
                                                }}
                                                active
                                            />
                                            <Skeleton.Button
                                                className="LoadingBtns"
                                                active
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="rightSection">
                            <div>
                                <Skeleton.Input
                                    style={{
                                        marginRight: '20px',
                                    }}
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                ) : !isLoading ? (
                    Object.keys(componentData).length === 0 ? (
                        `Component Doesn't Exit`
                    ) : (
                        <div
                            style={{ display: 'flex' }}
                            className="ViewWrapper"
                        >
                            <div
                                style={{ width: '60%' }}
                                className="leftSection"
                            >
                                <div className="titleWrapper">
                                    <h2 className="MainTitle">
                                        {convertToSentenceCase(
                                            componentData?.name,
                                        )}
                                    </h2>
                                    <div className="label">
                                        {' '}
                                        {componentData?.is_global
                                            ? 'GLOBAL COMPONENT'
                                            : 'COMPONENT'}
                                    </div>
                                </div>
                                <div className="DescriptionWrapper">
                                    <p className="DescriptionTitle">
                                        DESCRIPTION
                                    </p>
                                    <p className="DescriptionText">
                                        {parse(componentData?.description) ||
                                            'No description available.'}
                                    </p>
                                </div>
                                <div className="ViewMobileButton">
                                    {isLoading ? '' : noEditButton && <Btn />}
                                </div>
                                <h2
                                    className="detailsTitle"
                                    style={{ margin: '30px 0' }}
                                >
                                    Attributes
                                </h2>
                                {componentAttributes?.length > 0 ? (
                                    <Table
                                        products={componentAttributes}
                                        tableHead={tableHead}
                                        regularTableFlow
                                    />
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <img
                                            src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/no-attributes.svg"
                                            alt="no-attributes"
                                        />
                                        <h2 className="noAttributesTitle">
                                            No attributes
                                        </h2>
                                        <p className="noAttributesSubTitle">
                                            You’ve not added any product to your
                                            library.
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="rightSection">
                                <div className="MobiletitleWrapper">
                                    <h2 className="viewMobileMainTitle">
                                        {convertToSentenceCase(
                                            componentData?.name,
                                        )}
                                    </h2>
                                    <div className="label">COMPONENT</div>
                                </div>
                                <h2 className="detailsTitle">
                                    Component Details
                                </h2>
                                <Row gutter={[32]}>
                                    <Col span={12}>
                                        <p className="subTitle">
                                            DATE OF CREATION
                                        </p>
                                        <p className="details">
                                            {format(
                                                parseISO(
                                                    componentData.created_at,
                                                ),
                                                'dd MMM, yyyy',
                                            ).toUpperCase()}
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className="subTitle">
                                            COMPONENT TYPE
                                        </p>
                                        <p className="details">
                                            {componentData?.type?.name?.toUpperCase()}
                                        </p>
                                    </Col>
                                </Row>
                                <Row gutter={32} style={{ marginTop: 30 }}>
                                    <Col span={12}>
                                        <p className="subTitle">
                                            COMPONENT COST
                                        </p>
                                        <p className="details">
                                            ₦
                                            {currencyFormatter(
                                                componentData?.cost,
                                            )}
                                        </p>
                                    </Col>
                                    {/* <Col span={12}>
                    <p className='subTitle'>MANUFACTURER</p>
                    <p className='details'>
                      {convertToSentenceCase(componentData?.manufacturer_name)}
                    </p>
                  </Col> */}
                                </Row>
                                <div className="viewButtonWrapper">
                                    {isLoading ? '' : !noEditButton && <Btn />}
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div style={{ display: 'flex', marginTop: '50px' }}>
                        <div style={{ width: '60%' }}>
                            <Skeleton.Input className="ViewSkeleton" active />
                            <Skeleton.Input
                                style={{
                                    width: '85px',
                                    borderRadius: '7px',
                                    height: '25px',
                                }}
                                active
                            />

                            <div
                                style={{
                                    marginTop: '50px',
                                    marginBottom: '10px',
                                }}
                            >
                                <Skeleton.Input
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div>
                                <Skeleton.Input
                                    style={{ height: '20px' }}
                                    className="DescriptionSkeleton"
                                    active
                                />
                            </div>

                            <div
                                style={{
                                    marginTop: '45px',
                                    marginBottom: '25px',
                                }}
                            >
                                <Skeleton.Input
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div className="LoadingBtnsWrapper">
                                {[...Array(4)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        className="LoadingBtns"
                                        active
                                    />
                                ))}
                            </div>

                            <div>
                                {[...Array(4)].map(i => (
                                    <div
                                        key={i * Math.random()}
                                        className="componentsSkeleton"
                                    >
                                        <Skeleton.Button
                                            className="LoadingBtns"
                                            active
                                        />
                                        <Skeleton.Button
                                            style={{
                                                width: '60px',
                                                borderRadius: '7px',
                                                height: '25px',
                                            }}
                                            active
                                        />
                                        <Skeleton.Button
                                            style={{
                                                width: '150px',
                                                borderRadius: '7px',
                                                height: '25px',
                                            }}
                                            active
                                        />
                                        <Skeleton.Button
                                            className="LoadingBtns"
                                            active
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="rightSection">
                            <div>
                                <Skeleton.Input
                                    style={{
                                        marginRight: '20px',
                                    }}
                                    className="ViewSkeleton"
                                    active
                                />
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>

                            <div className="RightSectionSkeleton">
                                {[...Array(2)].map(i => (
                                    <Skeleton.Button
                                        key={i * Math.random()}
                                        style={{
                                            width: '130px',
                                            borderRadius: '7px',
                                        }}
                                        active
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="HelpCenterWrapper">
                <FloatingBox
                    floatIcon={QuestionMarkIcon}
                    floatBgColor="#011A3C"
                    floatBoxClass="floatBox"
                    renderComponent={props => <HelpCenter {...props} />}
                />
            </div>
        </>
    )
}

export default ViewComponent
