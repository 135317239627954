import 'src/pages/Admin/Providers/Active/adminprovidersactive.scss'
import { Menu } from 'antd'
import PropTypes from 'prop-types'

const ListingsMenu = ({ goToCreateProfileGroup }) => {
    return (
        <Menu>
            <Menu.Item onClick={() => goToCreateProfileGroup()}>
                <span className="AdminProvidersActiveDropdownLinks">
                    Create a new group
                </span>
            </Menu.Item>
        </Menu>
    )
}

ListingsMenu.propTypes = {
    goToCreateProfileGroup: PropTypes.func.isRequired,
}

export default ListingsMenu
