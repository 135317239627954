import { useState } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { closePaymentPlan } from 'src/api/admin/payment-plans/ssePlan'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const useClosePlan = (estimationId, reason, closeModal) => {
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const queryClient = useQueryClient()

    const { successAlert } = useCustomToast()
    const { mutate, isLoading } = useMutation({
        mutationFn: () =>
            closePaymentPlan(estimationId, {
                action: 'CLOSE_APPLICATION',
                reason: reason,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries('single-payment-plan-estimation')
            successAlert('Plan closed successfully')
            closeModal()
        },
        onError: error => {
            setErrorMessage(errorHandler(error?.response?.data))
            setToastError(true)
        },
    })

    return {
        closePlanMutate: mutate,
        closePlanLoading: isLoading,
        closePlanErrorMessage: errorMessage,
        closePlanToastError: toastError,
    }
}

export default useClosePlan
