import axios from '../../config/axios'
export const requestConsumerUpdateApi = async (id, inputs) => {
    const request = await axios.post(
        `/user-services/estimations/${id}/request-changes`,
        inputs,
    )

    return request
}

export const getSSERecentUpdatesApi = async pageSize => {
    const request = await axios.get(
        `/consumers/estimations/requested-updates?page_size=${pageSize}`,
    )

    return request
}
