/* eslint-disable react/prop-types */
import { Drawer } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import BackNav from '../BackNav'
import FloatingBox from '../FloatingBox'

import ChatIcon from '../../assets/images/chat-icon.svg'
import './helpcenter.scss'
import ConsumerQuestion from './ConsumerHelpCenter/ConsumerQuestion'
import useHelpCenter from './hook'

const HelpCenter = ({ visible, onClose, noChat }) => {
    const {
        nextPage,
        activeCategory,
        setActiveCategory,
        activeAnswer,
        setActiveAnswer,
        helpForm,
        showChatIcon,
        togglePage,
        handleAskQuestion,
        defaultPage,
        categories,
    } = useHelpCenter()

    return (
        <div data-testid="help-center">
            <Drawer open={visible} onClose={onClose} width="367px">
                {helpForm ? (
                    <ConsumerQuestion
                        close={onClose}
                        handleBackToHelp={defaultPage}
                    />
                ) : nextPage ? (
                    <div>
                        <div>
                            <p onClick={togglePage}>
                                <BackNav title="Back to Help" />
                                <h1 className="HelpCenterTitle">Help Center</h1>
                            </p>
                        </div>
                        {activeCategory.questions[activeAnswer]?.answer()}
                    </div>
                ) : (
                    <div>
                        <h1 className="HelpCenterTitle">Help Center</h1>
                        <div>
                            <h2 className="header">{activeCategory.name}</h2>
                            {activeCategory.questions.map((question, id) => (
                                <p
                                    key={id}
                                    onClick={() => {
                                        setActiveAnswer(id)
                                        togglePage()
                                    }}
                                    className="questions"
                                    data-testid="questions"
                                >
                                    {question.question}
                                </p>
                            ))}
                            <div
                                className="line"
                                style={{ marginTop: '40px' }}
                            ></div>
                            {categories.map((category, i) => (
                                <>
                                    {i !== 0 && (
                                        <div
                                            className="categoryNameWrapper line"
                                            key={i}
                                            onClick={() =>
                                                setActiveCategory(category)
                                            }
                                            data-testid="category"
                                        >
                                            <h2 className="header ">
                                                {category.name}
                                            </h2>
                                            <RightOutlined
                                                style={{
                                                    color: 'rgba(1, 26, 60, 0.21)',
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            ))}
                        </div>
                    </div>
                )}
                {noChat ? (
                    ''
                ) : showChatIcon ? (
                    <FloatingBox
                        floatIcon={ChatIcon}
                        floatBgColor="var(--blue)"
                        floatBoxClass="floatBoxClass"
                        onClick={handleAskQuestion}
                    />
                ) : (
                    ''
                )}
            </Drawer>
        </div>
    )
}

export default HelpCenter
