/* eslint-disable no-unused-vars */
import axios from 'src/config/axios'

export const getDirectDebitBankAPi = async partner => {
    const request = await axios.get(
        `/payments/banks/direct-debit?paginate=false&partner=${partner}`,
    )
    return request
}

export const getUserBankDetailsApi = async (account_number, bank_code) => {
    const request = await axios.get(
        `/payments/banks/resolve-name?bank_code=${bank_code}&account_number=${account_number}`,
    )
    return request
}

export const createDebitMandateApi = async (
    estimation_id,
    bank_code,
    account_number,
    account_name,
) => {
    const request = await axios.post(
        `/payments/direct-debit/create/${estimation_id}`,
        {
            bank_code,
            account_number,
            account_name,
        },
    )
    return request
}

export const printMandateApi = async estimation_id => {
    const request = axios.get(
        `/payments/direct-debit/print-mandate/${estimation_id}`,
    )
    return request
}

export const requestMandateActivationApi = async estimation_id => {
    const request = await axios.get(
        `/payments/direct-debit/request-activation/${estimation_id}`,
    )

    return request
}

export const validateMandateActivationApi = async (
    estimation_id,
    remitaTransRef,
    authParams,
) => {
    const request = await axios.post(
        `/payments/direct-debit/validate-activation/${estimation_id}`,
        {
            remitaTransRef,
            authParams,
        },
    )

    return request
}

export const initializePaystackDirectDebitApi = async (
    estimation_id,
    bank_code,
    account_number,
    account_name,
) => {
    const request = await axios.post(
        `repayments/direct-debit/paystack/initialize/${estimation_id}`,
        {
            bank_code,
            account_number,
            account_name,
        },
    )
    return request
}

export const initializeRecovaDirectDebitApi = async (
    estimation_id,
    bank_code,
    account_number,
    account_name,
) => {
    const request = await axios.post(
        `payments/recova/create-consent/estimations/${estimation_id}`,
        {
            bank_code,
            account_number,
            account_name,
        },
    )
    return request
}

export const verifyPaystackDirectDebitApi = async estimation_id => {
    const request = await axios.post(
        `repayments/direct-debit/paystack/verify/${estimation_id}`,
    )
    return request
}
