import PropTypes from 'prop-types'
import styles from './edituserdetails.module.scss'
import FormTitleBar from '../../../../../components/FormTitleBar'
import {
    InputFields,
    MultipleSelectField,
    SelectField,
} from '../../../../../components/InputFields'
import { SelectCountryCode } from '../../../../../components/InputFields/SelectCountryCode'
import Button from '../../../../../components/Button'
import { DepRoleData } from '../../../../../utils/Data/roles'
import Toast from '../../../../../components/Toast'
import { InlineLoader } from '../../../../../components/Loader'
import { updateUserValidationSchema } from '../../../../../utils/validationSchema'
import { isMobile } from 'src/utils/mediaQueries'
import { formatInputValue } from 'src/utils/formatting'

const EditUserDetails = ({
    inputs,
    setInputs,
    errors,
    setErrors,
    toastError,
    errorMessage,
    handleSubmitCallback,
    groups,
    loading,
    userType,
}) => {
    const { NameWrapper, GroupSelect, ButtonDiv } = styles
    const handleChange = e => {
        const { name, value } = e.target
        setInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
    }

    const handleSelectRole = (name, value) => {
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleSubmit = () => {
        setErrors({})
        updateUserValidationSchema
            .validate(inputs, { abortEarly: false })
            .then(() => {
                setErrors({})
                handleSubmitCallback()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    return (
        <div>
            {toastError && <Toast messageType="error" message={errorMessage} />}
            <FormTitleBar
                title="Update User Details"
                subtitle="Enter the details of the user"
                fontFamily="SF-Pro-Display-Bold"
                titleFontSize={20}
                alignLeft={isMobile}
            />

            <div className={NameWrapper}>
                <InputFields
                    title="First Name"
                    name="firstName"
                    inputWidth={isMobile ? '100%' : '224px'}
                    value={inputs.firstName ?? ''}
                    inputValue={inputs.firstName}
                    handleChange={handleChange}
                    errorMessage={errors?.firstName}
                />
                <InputFields
                    title="Last Name"
                    name="lastName"
                    inputWidth={isMobile ? '100%' : '224px'}
                    value={inputs.lastName ?? ''}
                    inputValue={inputs.lastName}
                    handleChange={handleChange}
                    errorMessage={errors?.lastName}
                />
            </div>

            <SelectCountryCode
                title="Phone Number"
                name="phoneNumber"
                inputValue={inputs.phoneNumber}
                value={inputs.phoneNumber}
                handleChange={handleChange}
                errorMessage={errors?.phoneNumber}
                selectWidth={isMobile}
            />
            <InputFields
                title="Email Address"
                name="email"
                inputWidth="100%"
                value={inputs.email}
                inputValue={inputs.email}
                handleChange={handleChange}
                errorMessage={errors?.email}
            />
            {userType === 'admin-user' ||
            userType === 'provider-user' ? null : (
                <SelectField
                    name="role"
                    selectWidth="100%"
                    withCheckBox={true}
                    initialOption="Select Role"
                    selectedValue={inputs?.role}
                    currentSelected={inputs?.role}
                    values={DepRoleData}
                    value={inputs?.role === null ? 'Select Role' : inputs?.role}
                    type="approvedModal"
                    handleChange={handleSelectRole}
                    optionStyle={{
                        width: '100%',
                    }}
                    floatingLabel={inputs?.role !== null && 'Role'}
                    errorMessage={errors?.role}
                    marginBottom="20px"
                />
            )}
            <div className={GroupSelect}>
                <MultipleSelectField
                    title="Select Groups"
                    selectWidth="100%"
                    dropDownWidth="280px"
                    values={groups}
                    name={'groups'}
                    dropdownMatchSelectWidth={false}
                    value={inputs?.groups?.map(item => item?.value)}
                    inputValue={inputs?.groups?.map(item => item?.value)}
                    handleMultipleSelectChange={value => {
                        const values = value?.map(item => ({
                            value: item,
                            label: item,
                            id: groups?.filter(each => each.value === item)?.[0]
                                ?.id,
                        }))
                        inputs.groups = values
                        setInputs(prev => ({
                            ...prev,
                            group: [values],
                        }))
                    }}
                    errorMessage={errors?.groups}
                />
            </div>

            {userType === 'admin-user' && (
                <InputFields
                    title="Password"
                    type="password"
                    inputValue={inputs.password}
                    inputWidth="100%"
                    name={'password'}
                    handleChange={handleChange}
                    errorMessage={errors.password}
                />
            )}
            <div className={ButtonDiv}>
                <Button
                    btnWidth="160px"
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnHeight="56px"
                    handleClick={() => handleSubmit()}
                >
                    {loading ? <InlineLoader /> : 'Update Details'}
                </Button>
            </div>
        </div>
    )
}

EditUserDetails.propTypes = {
    inputs: PropTypes.any,
    setInputs: PropTypes.func,
    errors: PropTypes.any,
    setErrors: PropTypes.func,
    toastError: PropTypes.bool,
    errorMessage: PropTypes.string,
    handleSubmitCallback: PropTypes.func,
    groups: PropTypes.array,
    loading: PropTypes.bool,
    userType: PropTypes.string,
}

export default EditUserDetails
