/* eslint-disable react/prop-types */
import { Skeleton } from 'antd'
import styles from './style.module.scss'

const RightSectionSkeleton = ({
    rowCount,
    actionButton,
    secondButton,
    infoButton,
    noStatusFlag,
    noTopInfo,
}) => {
    const { Row, Item, NameAndStatus, InfoHeading } = styles

    const isNotDesktop = window.innerWidth < 1188
    return (
        <>
            {/*name and status tag for non-desktop screens*/}
            <div className={NameAndStatus}>
                {isNotDesktop && (
                    <>
                        <Skeleton.Input
                            active
                            size="small"
                            style={{
                                width: '140px',
                            }}
                        />
                        {!noStatusFlag && (
                            <Skeleton.Input
                                active
                                size="small"
                                style={{ width: '100px' }}
                            />
                        )}
                    </>
                )}
            </div>

            {/*section heading*/}
            <Skeleton.Input
                active
                size="small"
                style={{ width: '125px', marginBottom: '48px' }}
            />

            {/*grid*/}
            <div style={{ width: '100%' }}>
                {[...Array(rowCount)].map(i => (
                    <div key={i} className={Row}>
                        <div className={Item}>
                            <Skeleton.Input
                                active
                                size="small"
                                style={{
                                    width: '100px',
                                }}
                            />
                            <Skeleton.Input
                                active
                                size="small"
                                style={{
                                    width: '130px',
                                }}
                            />
                        </div>
                        <div className={Item}>
                            <Skeleton.Input
                                active
                                size="small"
                                style={{
                                    width: '100px',
                                }}
                            />
                            <Skeleton.Input
                                active
                                size="small"
                                style={{
                                    width: '130px',
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>

            {/*info section for non-desktop screens*/}
            {isNotDesktop && (
                <>
                    {noTopInfo ? (
                        <></>
                    ) : (
                        <div>
                            <div className={InfoHeading}>
                                <Skeleton.Input
                                    active
                                    size="small"
                                    style={{ width: '125px' }}
                                />
                                {infoButton && (
                                    <div>
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{ width: '125px' }}
                                        />
                                    </div>
                                )}
                            </div>
                            <div style={{ display: 'grid', marginBottom: 35 }}>
                                {[...Array(2)].map(i => (
                                    <Skeleton.Input
                                        active
                                        key={i}
                                        size="small"
                                        style={{
                                            width: '100%',
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}

            {/*buttons*/}
            <div className={Row}>
                {actionButton && (
                    <Skeleton.Input
                        active
                        size="large"
                        style={{
                            width: '130px',
                        }}
                    />
                )}
                {secondButton && (
                    <Skeleton.Input
                        active
                        size="large"
                        style={{
                            width: '130px',
                        }}
                    />
                )}
            </div>
        </>
    )
}

export default RightSectionSkeleton
