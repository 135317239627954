import { useState } from 'react'
import { useQuery } from 'react-query'
import {
    getKycModulesApi,
    getAdminKycModulesApi,
} from 'src/api/shoppingExperience/account-overview'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'

export const useFetchKycModules = (
    estimationId,
    isAdminUser,
    isDynamicKycUser = true,
    onSuccess,
) => {
    const [kycModules, setKycModules] = useState([])
    const { errorAlert } = useCustomToast()

    const { isFetching, refetch } = useQuery(
        ['fetch-kyc-modules', estimationId],
        () =>
            isDynamicKycUser &&
            (isAdminUser
                ? getAdminKycModulesApi(estimationId)
                : getKycModulesApi(estimationId)),
        {
            enabled: !!estimationId,
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            onSuccess: res => {
                const data = res?.data?.data
                setKycModules(data?.modules)
                onSuccess?.(data)
            },
            onError: error => {
                errorAlert(
                    errorHandler(error.response?.data) ||
                        "Couldn't fetch KYC. Please try again.",
                )
            },
        },
    )

    return {
        kycModulesFetching: isFetching,
        kycModules,
        refetchKycModules: refetch,
    }
}
