import SecondaryModal from 'src/components/Modal/SecondaryModal'
import PropTypes from 'prop-types'
import { availablePaymentPlans, sortPaymentPlans } from '../data'
import { isMobile } from 'src/utils/mediaQueries'
import NairaSymbol from 'src/components/NairaSymbol'

const RepaymentOptionsModal = ({
    showModal,
    onCancel,
    paymentPlans,
    preferred_payment_tenure,
}) => {
    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={onCancel}
            modalWidth={'649px'}
            v2
            title={'All available payment plans for this package'}
            showFooter
            content={
                <div className={'ViewSystemL20PlanCards'}>
                    {sortPaymentPlans(
                        availablePaymentPlans(
                            paymentPlans,
                            preferred_payment_tenure,
                        ),
                    )?.map((item, i) => (
                        <div
                            key={i}
                            className={'ViewSystemL20PlanCard ModalVersion'}
                        >
                            <div className={'ViewSystemL20PlanCardRowOne'}>
                                <h4>
                                    <span>{item?.count} monthly</span>
                                    {isMobile ? <br /> : ' '}
                                    payments of
                                </h4>
                                <h5>
                                    <span>
                                        <NairaSymbol />
                                        {item?.monthly_payment?.toLocaleString(
                                            'en-US',
                                        )}{' '}
                                    </span>
                                    / Month
                                </h5>
                            </div>
                            <div className={'ViewSystemL20PlanCardRowTwo'}>
                                <span>Upfront payment of</span>
                                <p>
                                    <NairaSymbol />
                                    {item?.initial_deposit?.toLocaleString(
                                        'en-US',
                                    )}
                                </p>
                            </div>
                            {process.env
                                .REACT_APP_INSURANCE_INCLUSIVE_PAYMENT_PLANS_ENABLED ===
                                'true' &&
                                item?.investment_meta_data
                                    ?.monthly_insurance_amount && (
                                    <div
                                        className={
                                            'ViewSystemL20PlanCard_InsuranceRow'
                                        }
                                    >
                                        <span>Insurance amount of</span>
                                        <p>
                                            <NairaSymbol />
                                            {item?.investment_meta_data?.monthly_insurance_amount?.toLocaleString(
                                                'en-US',
                                            )}
                                        </p>
                                    </div>
                                )}
                            <div className={'ViewSystemL20PlanCard_TotalRow'}>
                                <span>Total payment of</span>
                                <p>
                                    <NairaSymbol />
                                    {item?.total_payment?.toLocaleString(
                                        'en-US',
                                    )}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            }
            primaryBtnText={'Got it'}
            primaryBtnWidth={isMobile ? '105px' : '150px'}
            primaryBtnHeight={isMobile ? '45px' : '53px'}
            handlePrimaryBtnClick={onCancel}
        />
    )
}

RepaymentOptionsModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    paymentPlans: PropTypes.array,
    preferred_payment_tenure: PropTypes.number,
}

export default RepaymentOptionsModal
