const employmentStatus = [
    'Self Employed',
    'Part Time',
    'Full Time',
    'Contract',
    'Retired',
]

export default employmentStatus

export const fullEmploymentOptions = ['Full Time', 'Part Time', 'Contract']

export const retiredOptions = ['Retired']

export const selfEmployedOptions = ['Self Employed']
