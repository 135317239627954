import PropTypes from 'prop-types'
import { useTypeDetails } from './data'
import './estimations.scss'
import checked from 'src/assets/images/circle_check_blue.svg'

const CustomerType = ({
    useType,
    setUseType,
    useTypeError,
    setUseTypeError,
}) => {
    const handleSelectChange = (name, value) => {
        setUseType(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    return (
        <div className={'CTWrapper UseType'}>
            <div className={'CTHeadingAndText'}>
                <h3>What is this customer getting this system for?</h3>
                <p>
                    Will the solar system be used for residential, small
                    business or other business purposes?
                </p>
                <div className={'CTUseTypes'}>
                    {useTypeDetails?.map((each, i) => (
                        <div
                            key={i}
                            className={'CTUseType'}
                            role={'button'}
                            onClick={() => {
                                handleSelectChange('useType', each.value)
                                setUseTypeError(prev => ({
                                    ...prev,
                                    useType: '',
                                }))
                            }}
                            style={{
                                backgroundColor:
                                    useType?.useType === each.value
                                        ? '#EBF3FF'
                                        : '#F9FCFF',
                                border:
                                    useType?.useType === each.value
                                        ? '0.5px solid #004AAD'
                                        : '0.5px solid #FFFFFF',
                            }}
                        >
                            <img
                                src={each.icon}
                                alt={each.title}
                                className={'CTUseType_Img'}
                            />
                            <div>
                                <h4>{each.title}</h4>
                                <p>{each.description}</p>
                            </div>
                            {useType?.useType === each.value && (
                                <img
                                    src={checked}
                                    alt={'checked'}
                                    className={'CTUseType_CheckedIcon'}
                                />
                            )}
                        </div>
                    ))}
                    {useTypeError?.useType && (
                        <p className={'CTUseTypes_Err'}>
                            {useTypeError?.useType}
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

CustomerType.propTypes = {
    useType: PropTypes.object,
    setUseType: PropTypes.func,
    useTypeError: PropTypes.object,
    setUseTypeError: PropTypes.func,
}

export default CustomerType
