import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export const checkPDFPassword = (
    file,
    setIsPasswordProtected,
    passwordCorrectRef,
    setCallbackFunction,
    setErrorMessage,
    handleBankStatement,
    bankStatementPassword,
) => {
    let fileReader = new FileReader()
    fileReader.onload = () => {
        let result = fileReader.result
        const pdfFile = pdfjs.getDocument(result)

        pdfFile.onPassword = (callback, reason) => {
            switch (reason) {
                case 1: {
                    // needs password
                    setIsPasswordProtected(true)

                    if (!passwordCorrectRef.current) {
                        setCallbackFunction(() => callback)
                        passwordCorrectRef.current = true
                    } else {
                        if (bankStatementPassword !== '') {
                            setErrorMessage('')
                            handleBankStatement()
                        }
                    }
                    break
                }
                case 2: {
                    // password is not correct
                    setCallbackFunction(() => callback)
                    passwordCorrectRef.current = false
                    setErrorMessage('Please enter correct password')
                    break
                }
            }
        }
    }

    fileReader.readAsArrayBuffer(file.originFileObj)
}
