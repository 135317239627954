export const getActionModalData = (name, profileName, pkg) => {
    switch (name) {
        case 'pull-confirm': {
            return {
                headerText: 'Pull energy needs from this package?',
                subTitle: (
                    <>
                        <p>
                            You’re about to use the energy details from{' '}
                            <strong>{pkg?.name}</strong>
                            {profileName?.length ? ' for ' : ''}
                            <strong>{profileName}</strong>. Please confirm.
                        </p>
                    </>
                ),
                actionType: 'warning',
                actionText: 'Confirm',
                noCancelBtn: false,
            }
        }

        case 'pull-success': {
            return {
                headerText: 'Successful',
                subTitle: (
                    <>
                        <p>
                            You have successfully pulled the energy needs from
                            from <strong>{pkg?.name}</strong>
                            {profileName?.length ? ' to ' : ''}
                            <strong>{profileName}</strong>.
                        </p>
                    </>
                ),
                actionType: 'success',
                actionText: 'Done',
                noCancelBtn: true,
            }
        }

        default: {
            return {
                headerText: '',
                subTitle: '',
                actionType: 'warning',
                noCancelBtn: true,
            }
        }
    }
}
