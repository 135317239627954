/* eslint-disable react/prop-types */
import styles from './emptyprofilepic.module.scss'

const EmptyProfilePic = ({
    firstLetter,
    secondLetter,
    profilePage = false,
    marginTop,
}) => {
    const {
        EmptyStateContainer,
        EmptyStateContainerProfilePage,
        TextBox,
        TextBoxProfilePage,
    } = styles
    return (
        <div
            className={
                profilePage
                    ? EmptyStateContainerProfilePage
                    : EmptyStateContainer
            }
            style={{ background: '#004AAD', marginTop: marginTop || '0px' }}
        >
            <span className={profilePage ? TextBoxProfilePage : TextBox}>
                {firstLetter}
                {secondLetter}
            </span>
        </div>
    )
}

export default EmptyProfilePic
