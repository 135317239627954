/* eslint-disable react/prop-types */
import { EmptyStateIllustrator } from '../../assets/images/EmptyStateIllustrator'
import overviewNoActivity from '../../assets/images/overviewNoActivity.svg'
import emptyFolder from '../../assets/images/empty_folder.svg'

import Button from '../Button'
import styles from './emptystate.module.scss'

const EmptyState = ({
    subTitle,
    buttonText,
    buttonClick,
    type,
    title,
    buttonVisible = true,
    buttonToolTipText,
    buttonDisabled = false,
    secondaryTitle,
    buttonWidth = '140px',
    showBrightIcon = false,
}) => {
    const {
        EmptyStateContainer,
        EmptyStateTitle,
        EmptyStateSubTitle,
        EmptyStateButtonContainer,
        EmptyStateSecondaryTitle,
    } = styles
    return (
        <div className={EmptyStateContainer} data-testid="empty-state">
            {type === 'noActivity' ? (
                <img
                    src={showBrightIcon ? emptyFolder : overviewNoActivity}
                    alt="no activity illustration"
                />
            ) : (
                <EmptyStateIllustrator />
            )}
            <h2 className={EmptyStateTitle}>{title || "Nothing's here"}</h2>
            <p className={EmptyStateSubTitle}>{subTitle}</p>
            <h4 className={EmptyStateSecondaryTitle}>{secondaryTitle}</h4>
            {buttonText?.length > 0 && buttonVisible && (
                <div className={EmptyStateButtonContainer}>
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnWidth={buttonWidth}
                        handleClick={buttonClick}
                        disabled={buttonDisabled}
                        toolTipText={buttonToolTipText}
                    >
                        {buttonText}
                    </Button>
                </div>
            )}
        </div>
    )
}

export default EmptyState
