export const approvalStatusMap = {
    AWAITING_APPROVAL: {
        label: 'AWAITING APPROVAL',
        color: '#233862B2',
        background: '#E5E5E580',
    },
    APPROVED: {
        label: 'APPROVED',
        color: '#004AAD',
        background: '#E2EEFF',
    },
    DECLINED: {
        label: 'DECLINED',
        color: '#233862',
        background: '#F9FCFF',
    },
    IN_PROGRESS: {
        label: 'IN PROGRESS',
        color: '#E4AA0F',
        background: '#FFFAEC',
    },

    // not in design
    CLOSED: {
        label: 'CLOSED',
        color: '#233862',
        background: '#F9FCFF',
    },
    AWAITING_UPDATE: {
        label: 'AWAITING UPDATE',
        color: '#233862',
        background: '#F9FCFF',
    },
    DECLINED_AND_RE_ESTIMATE: {
        label: 'DECLINED AND RE-ESTIMATE',
        color: '#233862',
        background: '#F9FCFF',
    },
    AWAITING_SOLUTION: {
        label: 'AWAITING SOLUTION',
        color: '#233862B2',
        background: '#E5E5E580',
    },
    SOLUTION_PROVIDED: {
        label: 'SOLUTION PROVIDED',
        color: '#004AAD',
        background: '#E2EEFF',
    },
}

export const creditReviewResMap = {
    YES: 'Yes',
    NO: 'No',
    MAYBE: 'Maybe',
    UNDECIDED: 'Undecided',
}

export const assessmentCriteriaMap = {
    REMOTE_MANAGEMENT: 'Remote management capabilities',
    TECHNO_ECONOMICS: 'Techno-economic fit',
    REPAYMENT_METHOD: 'Repayment method certainty',
    BANK_STATEMENT_ANALYSIS: 'Bank statement',
    CREDIT_REPORT: 'Credit bureau',
    FINANCIAL_ANALYSIS: 'Financial report',
    UPFRONT_DEPOSIT: 'Upfront deposit',
    EASE_OF_RECOVERY: 'Location / ease of recovery',
    CONSUMER_PERFORMANCE: 'Existing repayment performance',
    PROVIDER_EQUIPMENT_QUALITY: 'Provider / equipment quality',
}

export function getCriteriaKey(keys) {
    if (!keys) return []

    return Object.keys(keys)
}

export const avatarParams = [
    {
        key: 'face_detected',
        label: 'Face detected',
    },
]

export const checkParams = [
    {
        key: 'age_match',
        label: 'Age match',
    },
    {
        key: 'name_match',
        label: 'Name match',
    },
    {
        key: 'gender_match',
        label: 'Gender match',
    },
    {
        key: 'face_match',
        label: 'Face match',
    },
]

export const dropdownItems = [
    { id: 1, title: 'Decline', permission: 'request:can_decline' },
    { id: 2, title: 'Re-estimate', permission: 'request:can_re_estimate' },
    {
        id: 3,
        title: 'Resend PII Request',
        permission: 'request:can_request_pii_resubmission',
    },
    {
        id: 4,
        title: 'Request Statement Resubmission',
        permission: 'request:can_request_statement_resubmission',
    },
    {
        id: 5,
        title: 'Close Payment Plan',
        permission: 'request:can_close',
    },
]

export const consumerTypeMap = {
    RESIDENTIAL: 'Residential',
    BUSINESS: 'Business',
}

// Function to handle positioning
export const handlePositioning = () => {
    const targetElement = document.getElementById('planDetailMobile')
    const absoluteElement = document.getElementById('asideButtons')

    if (targetElement && absoluteElement) {
        const targetRect = targetElement.getBoundingClientRect()

        const topOffset = targetRect.top + window.scrollY
        const leftOffset = targetRect.left + window.scrollX

        // Check viewport width
        if (window.innerWidth < 399) {
            // Calculate desired position
            const newPositionTop = topOffset + 150 // Example: 10px below the target
            const newPositionLeft = leftOffset + 0 // Example: 10px to the right of the target

            // Apply positioning
            absoluteElement.style.position = 'absolute'
            absoluteElement.style.top = `${newPositionTop}px`
            absoluteElement.style.left = `${newPositionLeft}px`
        } else if (window.innerWidth < 701) {
            const newPositionTop = topOffset + 90
            const newPositionLeft = leftOffset + 0

            absoluteElement.style.position = 'absolute'
            absoluteElement.style.top = `${newPositionTop}px`
            absoluteElement.style.left = `${newPositionLeft}px`
        } else if (window.innerWidth < 1180) {
            const newPositionTop = topOffset + 70
            const newPositionLeft = leftOffset + 0

            absoluteElement.style.position = 'absolute'
            absoluteElement.style.top = `${newPositionTop}px`
            absoluteElement.style.left = `${newPositionLeft}px`
        } else {
            // Reset positioning if viewport width is greater than or equal to 1180px
            absoluteElement.style.position = 'static'
        }
    }
}

export const numberFloat = value => parseFloat(value, 10).toFixed(2)
