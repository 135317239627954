/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom'
import Formbox from '../FormBox'
import AI from '../../assets/images/AI.svg'
import { ReactComponent as ArrorRight } from '../../assets/images/ArrorRight.svg'
import DISI from '../../assets/images/DISI.svg'
import OEMI from '../../assets/images/OEMI.svg'
import FormTitleBar from '../FormTitleBar'

import styles from './partnertype.module.scss'

const {
    Wrapper,
    PartnerWrapper,
    ImageBox,
    PartnerTypeIcon,
    PartnerTitle,
    PartnerSubTitle,
    PartnerTitleWrapper,
    ArrowIcon,
    LinkWrapper,
} = styles

const PartnerType = ({ handleChange }) => {
    const partnerTypeData = [
        {
            icon: AI,
            id: 1,
            title: 'Associate Installer (ASI)',
            subtitle: 'You’ve got the electrical skills',
        },
        {
            icon: DISI,
            id: 2,
            title: 'Distributor that installs (DISI)',
            subtitle: 'You own a solar sales and installation business',
        },
        {
            icon: OEMI,
            id: 3,
            title: 'Manufacturer that installs (OEMI)',
            subtitle: 'You build and install  clean energy equipments',
        },
    ]

    return (
        <Formbox>
            <div className={Wrapper}>
                <FormTitleBar
                    title="Partner Type"
                    subtitle="Which type of partner are you?"
                />

                <div style={{ maxWidth: 420, margin: 'auto' }}>
                    {partnerTypeData.map(partner => {
                        return (
                            <div
                                key={Math.random()}
                                className={PartnerWrapper}
                                onClick={() => handleChange(partner.id)}
                            >
                                <div
                                    className={ImageBox}
                                >
                                    <img
                                        src={partner.icon}
                                        alt="partner types Icon"
                                        className={PartnerTypeIcon}
                                    />
                                </div>
                                <div className={PartnerTitleWrapper}>
                                    <p className={PartnerTitle}>
                                        {partner.title}
                                    </p>
                                    <p className={PartnerSubTitle}>
                                        {partner.subtitle}
                                    </p>
                                </div>
                                <div className={ArrowIcon}>
                                    <ArrorRight />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={LinkWrapper}>
                    <p>
                        Already have an account?{' '}
                        <span>
                            <Link to="/login" style={{ color: 'var(--blue)' }}>
                                Sign In
                            </Link>
                        </span>
                    </p>
                </div>
            </div>
        </Formbox>
    )
}

export default PartnerType
