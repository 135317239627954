/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router'
import { useQuery, useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { Carousel } from 'antd'
import SeoComponent from '../../components/Seo'
import CarouselLine from '../../components/CarouselLine'
import GetModal from '../../components/Modal'
import { InlineLoader } from '../../components/Loader'
import Button from '../../components/Button'
import PersonalInfo from './Steps/PersonalInfo'
import WorkExperience from './Steps/WorkExperience'
import IdentityVerification from './Steps/IdentityVerification'
import CompanyInfo from './Steps/CompanyInfo'
import CompanyContact from './Steps/CompanyContact'
import NubanAccountDetails from './Steps/NubanAccountDetails'
import successImage from '../../assets/images/DisiSuccessImg.svg'
import styles from './onboarding.module.scss'
import logo from '../../assets/images/logo2.svg'
import { logoutApi } from '../../api/logout'
import { removeToken, getToken } from '../../utils/auth.js'
import { providerOnboardingFeedbackApi } from 'src/api/onboarding/feedback'
import NPSRatingModal from 'src/components/NPSRatingModal'
import AddComponentBrands from './Steps/AddComponentBrands'
import Success from './Steps/Success'

const {
    ModalContentBox,
    SuccessModalContentBox,
    WelcomeBoxTitle,
    WelcomeBoxSubTitle,
    ButtonBox,
    ActionBox,
    SuccessBoxTitle,
    SuccessBoxSubTitle,
    SuccessButtonBox,
    OnboardingWrapperStyle,
    ComponentBrandsWrapper,
    OnboardingWrapperLogo,
    SuccessBoxImg,
} = styles

const WelcomeScreen = ({
    titleText,
    subTitle,
    buttonText,
    buttonWidth,
    buttonAction,
}) => {
    return (
        <div className={ModalContentBox}>
            <div>
                <img
                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Welcome.svg"
                    height="50%"
                    width="50%"
                    alt="welcome"
                />
            </div>
            <div className={WelcomeBoxTitle}>{titleText}</div>
            <div className={WelcomeBoxSubTitle}>{subTitle}</div>
            <div className={ButtonBox}>
                <Button
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnWidth={buttonWidth}
                    handleClick={buttonAction}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    )
}

const SuccessScreen = ({
    titleText,
    subTitle,
    buttonText,
    buttonWidth,
    buttonAction,
}) => {
    return (
        <div className={SuccessModalContentBox}>
            <div>
                <img
                    src={successImage}
                    className={SuccessBoxImg}
                    alt="welcome"
                />
            </div>
            <div className={SuccessBoxTitle}>{titleText}</div>
            <div className={SuccessBoxSubTitle}>{subTitle}</div>
            <div className={SuccessButtonBox}>
                <Button
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnWidth={buttonWidth}
                    handleClick={buttonAction}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    )
}

export const ActionSection = ({
    stage,
    nextHandler,
    prevHandler,
    loading,
    btnText,
    margin,
}) => {
    return (
        <div className={ActionBox} style={{ margin: margin && margin }}>
            {stage > 1 && (
                <>
                    <Button
                        btnBgColor="var(--purple-light)"
                        btnTextColor="var(--blue)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        type="outline"
                        btnWidth="150px"
                        handleClick={prevHandler}
                    >
                        Previous
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                </>
            )}
            <Button
                btnBgColor="var(--blue)"
                btnTextColor="var(--white)"
                btnWidth="150px"
                handleClick={nextHandler}
                disabled={loading ? true : false}
            >
                {loading ? <InlineLoader /> : btnText ? btnText : 'Continue'}
            </Button>
        </div>
    )
}

const OnboardingWrapper = ({ children, OnboardingWrapperClassName = '' }) => {
    return (
        <div
            className={`${OnboardingWrapperStyle} ${OnboardingWrapperClassName}`}
        >
            <div className={OnboardingWrapperLogo}>
                <Link to="/">
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            {children}
        </div>
    )
}

const Onboarding = ({ userInfo, onboardingStageHandler }) => {
    const [modalOpen, setModalOpen] = useState(true)
    const [npsModalOpen, setNpsModalOpen] = useState(false)
    const [onboardingPhase, setOnboardingPhase] = useState('notstarted')
    const [stage, setStage] = useState(0)
    const [nextStage, setNextStage] = useState(1)
    const [additionalPayload, setAdditionalPayload] = useState({})
    const [modalHeight, setModalHeight] = useState(676)
    const canUseComponentBrands =
        process.env.REACT_APP_FLAG_SHOW_COMPONENT_BRANDS === 'true'
    const totalASICarouselSteps = canUseComponentBrands ? 5 : 4
    const totalDISIOrOEMICarouselSteps = canUseComponentBrands ? 4 : 3

    const history = useHistory()

    const stepsRef = useRef(null)

    const extraModalBoxProps = {
        bodyStyle: {
            padding: 0,
            height: modalHeight,
            width: 624,
        },
        width: 624,
        wrapClassName: 'OnboardingModalBox',
    }

    const updateStage = (action, stage) => {
        if (action === 'continue') {
            setStage(stage + 1)
        }
        if (action === 'prev') {
            setStage(stage - 1)
        }
        if (action === 'goto') {
            setStage(stage)

            setTimeout(() => {
                stepsRef.current.goTo(stage)
            }, 500)
        }
    }
    const stepsHandler = (action, stageNumber) => {
        if (action === 'next') {
            stepsRef.current.next()
        }
        if (action === 'prev') {
            stepsRef.current.prev()
        }
        if (action === 'pause') {
            stepsRef.current.goTo(0, false)
        }
    }

    const afterChangeHandler = stageNumber => {
        stepsHandler('pause', stageNumber > 0 ? stageNumber - 1 : 0)
    }

    useEffect(() => {
        if (history?.location?.state) {
            setOnboardingPhase(history?.location?.state?.onboardingState)
            setNextStage(history?.location?.state?.nextOnboardingStage)
        }
    }, [history])

    const { refetch: logoutAction } = useQuery(
        'logout',
        () => logoutApi({ access_token: getToken() }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                removeToken()
                window.location.href = '/partners'
            },
        },
    )

    const switchToOnboardingModal = () => {
        setNpsModalOpen(false)
        setModalOpen(true)
    }

    const providerOnboardingFeedbackMutation = useMutation({
        mutationFn: () => providerOnboardingFeedbackApi(additionalPayload),
        onSuccess: () => {
            switchToOnboardingModal()
        },
        onError: () => {
            switchToOnboardingModal()
        },
    })

    const triggerFeedbackModal = () => {
        setModalOpen(false)
        setNpsModalOpen(true)
    }

    const onButtonClickGoHome = () => {
        // Let us find a way to logout the user
        logoutAction()
    }

    const handleFormCompletion = () => {
        stepsHandler('next')
        triggerFeedbackModal()
    }

    const handleAccountDetailsFormClose = () => {
        if (canUseComponentBrands) {
            setModalHeight('90vh')
            stepsHandler('next')
        } else {
            handleFormCompletion()
        }
    }

    return (
        <>
            <SeoComponent title="SunFi - Onboarding " tracker="Onboarding" />
            <NPSRatingModal
                showModal={npsModalOpen}
                closeModal={() => switchToOnboardingModal()}
                feature={'provider-completed-onboarding'}
                setAdditionalPayload={setAdditionalPayload}
                handleClick={() => providerOnboardingFeedbackMutation.mutate()}
                btnLoading={providerOnboardingFeedbackMutation.isLoading}
            />
            {stage === 0 ? (
                <GetModal
                    extraModalBoxProps={extraModalBoxProps}
                    showModal={modalOpen}
                    closable={false}
                    content={
                        <OnboardingWrapper>
                            <WelcomeScreen
                                titleText={
                                    onboardingPhase === 'started'
                                        ? 'Welcome Back'
                                        : userInfo?.companyName !== 'none'
                                        ? `Welcome ${userInfo?.companyName}`
                                        : `Welcome ${userInfo?.firstName}`
                                }
                                subTitle={
                                    userInfo?.partnerType === 'ai'
                                        ? 'Time is money! Let’s get going with building your clean energy business '
                                        : 'Time is money! Let’s get going with scaling your clean energy business '
                                }
                                buttonText={
                                    onboardingPhase === 'started'
                                        ? 'Continue Where I Stopped'
                                        : 'Get Started'
                                }
                                buttonWidth={
                                    onboardingPhase === 'started'
                                        ? '200px'
                                        : '150px'
                                }
                                buttonAction={
                                    onboardingPhase === 'started'
                                        ? () => updateStage('goto', nextStage)
                                        : () => updateStage('continue', stage)
                                }
                            />
                        </OnboardingWrapper>
                    }
                />
            ) : userInfo?.partnerType === 'ai' ? (
                <GetModal
                    extraModalBoxProps={extraModalBoxProps}
                    noPadding="24px 0px"
                    showModal={modalOpen}
                    closable={false}
                    content={
                        <Carousel
                            swipeToSlide={false}
                            swipe={false}
                            afterChange={afterChangeHandler}
                            accessibility={false}
                            ref={stepsRef}
                        >
                            <OnboardingWrapper>
                                <PersonalInfo
                                    nextHandler={() => stepsHandler('next')}
                                />
                                <CarouselLine
                                    total={totalASICarouselSteps}
                                    stage={1}
                                    customMargin="10px 41%"
                                />
                            </OnboardingWrapper>

                            <OnboardingWrapper>
                                <WorkExperience
                                    nextHandler={() => stepsHandler('next')}
                                    prevHandler={() => stepsHandler('prev')}
                                />
                                <CarouselLine
                                    total={totalASICarouselSteps}
                                    stage={2}
                                    customMargin="10px 41%"
                                />
                            </OnboardingWrapper>

                            <OnboardingWrapper>
                                <IdentityVerification
                                    prevHandler={() => stepsHandler('prev')}
                                    nextHandler={() => stepsHandler('next')}
                                    setModalOpen={setModalOpen}
                                />
                                <CarouselLine
                                    total={totalASICarouselSteps}
                                    stage={3}
                                    customMargin="10px 41%"
                                />
                            </OnboardingWrapper>
                            <OnboardingWrapper>
                                <NubanAccountDetails
                                    prevHandler={() => stepsHandler('prev')}
                                    nextHandler={handleAccountDetailsFormClose}
                                    handleFormClose={
                                        handleAccountDetailsFormClose
                                    }
                                    depType="ai"
                                />
                                <CarouselLine
                                    total={totalASICarouselSteps}
                                    stage={4}
                                    customMargin="10px 41%"
                                />
                            </OnboardingWrapper>
                            {canUseComponentBrands && (
                                <OnboardingWrapper
                                    OnboardingWrapperClassName={
                                        ComponentBrandsWrapper
                                    }
                                >
                                    <AddComponentBrands
                                        prevHandler={() => stepsHandler('prev')}
                                        nextHandler={handleFormCompletion}
                                        setModalHeight={setModalHeight}
                                        carousel={
                                            <CarouselLine
                                                total={totalASICarouselSteps}
                                                stage={5}
                                                customMargin="10px 41%"
                                            />
                                        }
                                    />
                                </OnboardingWrapper>
                            )}
                            <OnboardingWrapper>
                                {canUseComponentBrands ? (
                                    <Success
                                        buttonAction={onButtonClickGoHome}
                                    />
                                ) : (
                                    <SuccessScreen
                                        titleText={'Yasss!!! you made it'}
                                        subTitle="Thank you for your interest in partnering with SunFi to grow your clean energy business. 
                                            We are currently only accepting a handful of providers at this time so that we can deliver utmost value to all who have onboarded to ensure our offerings are well designed specifically for future partners like you. 
                                            To reserve your spot on our platform, we have placed you on a waitlist and will be approving off the waitlist gradually. We will get back to you as soon as there are available slots."
                                        buttonText={'Go Home'}
                                        buttonAction={onButtonClickGoHome}
                                    />
                                )}
                            </OnboardingWrapper>
                        </Carousel>
                    }
                />
            ) : (
                <GetModal
                    extraModalBoxProps={extraModalBoxProps}
                    showModal={modalOpen}
                    closable={false}
                    content={
                        <Carousel
                            afterChange={afterChangeHandler}
                            swipeToSlide={false}
                            swipe={false}
                            ref={stepsRef}
                            accessibility={false}
                        >
                            <OnboardingWrapper>
                                <CompanyInfo
                                    companyName={userInfo?.companyName}
                                    nextHandler={() => stepsHandler('next')}
                                />
                                <CarouselLine
                                    total={totalDISIOrOEMICarouselSteps}
                                    stage={1}
                                    customMargin="10px 41%"
                                />
                            </OnboardingWrapper>
                            <OnboardingWrapper>
                                <CompanyContact
                                    email={userInfo?.email}
                                    phoneNumber={userInfo?.phoneNumber}
                                    nextHandler={() => stepsHandler('next')}
                                    prevHandler={() => stepsHandler('prev')}
                                />
                                <CarouselLine
                                    total={totalDISIOrOEMICarouselSteps}
                                    stage={2}
                                    customMargin="10px 41%"
                                />
                            </OnboardingWrapper>
                            <OnboardingWrapper>
                                <NubanAccountDetails
                                    prevHandler={() => stepsHandler('prev')}
                                    nextHandler={handleAccountDetailsFormClose}
                                    handleFormClose={
                                        handleAccountDetailsFormClose
                                    }
                                />
                                <CarouselLine
                                    total={totalDISIOrOEMICarouselSteps}
                                    stage={3}
                                    customMargin="10px 41%"
                                />
                            </OnboardingWrapper>
                            {canUseComponentBrands && (
                                <OnboardingWrapper
                                    OnboardingWrapperClassName={
                                        ComponentBrandsWrapper
                                    }
                                >
                                    <AddComponentBrands
                                        prevHandler={() => stepsHandler('prev')}
                                        nextHandler={handleFormCompletion}
                                        setModalHeight={setModalHeight}
                                        carousel={
                                            <CarouselLine
                                                total={
                                                    totalDISIOrOEMICarouselSteps
                                                }
                                                stage={4}
                                                customMargin="10px 41%"
                                            />
                                        }
                                    />
                                </OnboardingWrapper>
                            )}
                            <OnboardingWrapper>
                                {canUseComponentBrands ? (
                                    <Success
                                        buttonAction={onButtonClickGoHome}
                                    />
                                ) : (
                                    <SuccessScreen
                                        titleText={'Yasss!!! you made it'}
                                        subTitle="Thank you for your interest in partnering with SunFi to grow your clean energy business. 
                                            We are currently only accepting a handful of providers at this time so that we can deliver utmost value to all who have onboarded to ensure our offerings are well designed specifically for future partners like you. 
                                            To reserve your spot on our platform, we have placed you on a waitlist and will be approving off the waitlist gradually. We will get back to you as soon as there are available slots."
                                        buttonText={'Go Home'}
                                        buttonAction={onButtonClickGoHome}
                                    />
                                )}
                            </OnboardingWrapper>
                        </Carousel>
                    }
                />
            )}
        </>
    )
}

export default Onboarding
