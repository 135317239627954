import { Skeleton } from 'antd'
import '../../consumerworkspace.scss'

const SkeletalView = () => {
    return (
        <div style={{ marginTop: '40px' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Skeleton.Avatar size="large" active />
                    <Skeleton.Input className="LongSkeletonLoader" active />
                </div>
                <Skeleton.Input className="LoadingCardTopRight" active />
            </div>
            <div style={{ display: 'flex' }}>
                <div className="VerticalLine"></div>
                <div className="SmallSkeletonCard">
                    <div
                        className="SkeletonLoadingCardWrapper"
                        style={{ padding: '15px' }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Skeleton.Avatar size="large" active />
                            <div className="LeftSideWrapper">
                                <Skeleton.Input
                                    className="SmallSkeletonTop"
                                    active
                                />
                                <Skeleton.Input
                                    className="SmallSkeletonBottom"
                                    active
                                />
                            </div>
                        </div>
                        <div className="LeftSideWrapper">
                            <Skeleton.Input
                                className="BigSkeletonLoader"
                                active
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Skeleton.Avatar size="large" active />
                    <Skeleton.Input className="LongSkeletonLoader" active />
                </div>
                <Skeleton.Input className="LoadingCardTopRight" active />
            </div>
            <div className="VerticalLine" style={{ height: '20px' }}></div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Skeleton.Avatar size="large" active />
                    <Skeleton.Input className="LongSkeletonLoader" active />
                </div>
                <Skeleton.Input className="LoadingCardTopRight" active />
            </div>

            <div className="SmallSkeletonCard">
                <div
                    className="SkeletonLoadingCardWrapper"
                    style={{ padding: '10px' }}
                >
                    <div style={{ display: 'flex' }}>
                        <Skeleton.Avatar size="large" active />
                        <div className="LeftSideWrapper">
                            <Skeleton.Input
                                className="LongerSkeletonLoader"
                                active
                            />
                            <Skeleton.Input
                                className="SmallSkeletonTop"
                                active
                            />
                            <Skeleton.Input
                                className="SmallSkeletonBottom"
                                active
                            />
                        </div>
                    </div>
                    <div className="LeftSideWrapper">
                        <Skeleton.Input className="BigSkeletonLoader" active />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletalView
