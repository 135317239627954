 const sizeData = [
  {
    id: 1,
    value: '1 - 20 employees',
  },
  {
    id: 2,
    value: '21 - 50 employees',
  },
  {
    id: 3,
    value: '51 - 70 employees',
  },
  {
    id: 4,
    value: '71 - 100 employees',
  },
  {
    id: 5,
    value: 'Above 100 employees',
  },
]

export default sizeData