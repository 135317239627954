import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { duplicateProfileApi } from 'src/api/admin/profiles/details'
import { errorHandler } from 'src/utils/errorHandler'

const useDuplicateProfile = onSuccess => {
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { id } = useParams()

    const { mutate, isLoading } = useMutation({
        mutationFn: () => duplicateProfileApi(id),
        onSuccess: res => {
            const newProfileId = res?.data?.data?.id
            onSuccess?.(newProfileId)
        },
        onError: error => {
            const errorMessage = errorHandler(error?.response?.data)
            setToastError(true)
            setErrorMessage(errorMessage)
        },
    })

    return {
        duplicateProfileMutate: mutate,
        duplicateProfileIsLoading: isLoading,
        duplicateProfileToastError: toastError,
        duplicateProfileErrorMessage: errorMessage,
    }
}

export default useDuplicateProfile
