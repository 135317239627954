import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import styles from '../PromoListings/promolistings.module.scss'
import ConsumerNavigation from 'src/components/Navigation/consumerNav'
import CSSEPromoListingsHeader from '../Components/CSSEPromoListingsHeader'
import CSSECTA from 'src/components/CSSECTA'
import { NoFLoatingLabelInputFields } from 'src/components/InputFields'
import { ReactComponent as FilterIcon } from 'src/assets/images/filter-blue.svg'
import { ReactComponent as MobileFilterIcon } from 'src/assets/images/mobile-filter.svg'
import { isMobile } from 'src/utils/mediaQueries'
import ConsumerFooter from 'src/components/ConsumerFooter'
import FilterRecommendations from '../../SolarSystemsListings/FilterRecommendations'
import { ReactComponent as ConsumerCloseIcon } from 'src/assets/images/consumer-close-icon.svg'
import NairaSymbol from 'src/components/NairaSymbol'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { ConsumerButton } from 'src/components/Button'
import ClosedClusterModal from './ClosedCluster'
import SeoComponent from 'src/components/Seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { promoPackages } from '../PromoCarousel/data'
import { removeToken } from 'src/utils/auth'
import { encrypt } from 'src/utils/cryptography'
import { activePromos } from '../PromoCarousel/data'

const MultiplePackagesPromoListings = () => {
    const {
        cssePromoListingsWrapper,
        csseSearchInputWrapper,
        csseFilterWrapperStyle,
        cssePromoListingsStyle,
        MultiplePromoPackagesDivWrapper,
        MultiplePromoPackagesImageWrapper,
        csseMultiplePackagePromoListingsStyle,
    } = styles

    const [searchText, setSearchText] = useState('')
    const [addFilter, setAddFilter] = useState(false)
    const [, setFiltersPayload] = useState({})
    const [showPackages, setShowPackages] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const handleSearchInputChange = e => {
        setSearchText(e.target.value)
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }
    const handleSearch = () => {}

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const handleFilter = filterVariables => {
        setFiltersPayload(filterVariables)
        setAddFilter(false)
    }

    const history = useHistory()

    const closedClusters = [
        'pbn-promo',
        'ventures-platform-promo',
        'damilola-ola-promo',
        'clafiya-promo',
        'betking-promo',
        'sms-promo',
    ]

    const { id } = useParams()

    const allPackages = promoPackages(id === 'dlight' ? '' : id)
    const isAuthenticatedUser =
        history.location?.state?.isAuthenticatedUser ?? false
    const estimation_id = history.location?.state?.estimation_id ?? null

    useEffect(() => {
        if (closedClusters.includes(id)) {
            setShowModal(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (!isAuthenticatedUser) {
            removeToken()
        }
    }, [isAuthenticatedUser])

    useEffect(() => {
        // if the promo does not contain any package, return consumers to promo listings page
        if (allPackages.length < 1) {
            history.push('/consumer/promos')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // redirect to homepage if id is not in active promos
    useEffect(() => {
        const activePromoIds = activePromos.map(promo => promo.type)
        if (!activePromoIds.includes(id)) {
            history.push('/')
        }
    }, [id, history])

    return (
        <>
            <SeoComponent
                title="Multiple Package Promo | SunFi | Simplifying and Scaling Clean Energy"
                tracker="PromoTracker"
            />
            {showModal && (
                <ClosedClusterModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    promoType={id}
                />
            )}
            {addFilter && (
                <FilterRecommendations
                    addFilter={addFilter}
                    setAddFilter={setAddFilter}
                    setShowPackages={setShowPackages}
                    handleFilter={handleFilter}
                    isLoading={false}
                />
            )}
            <ConsumerNavigation />
            <section>
                <CSSEPromoListingsHeader
                    page="multiple-package"
                    title="Get a solar inverter to power your 
basic home appliances"
                    subTitle="Get access to some already available product, all you just need to do is choose the product that can best power what you want to power and choose a plan"
                />
                <section className={cssePromoListingsWrapper}>
                    <div className={csseSearchInputWrapper}>
                        <NoFLoatingLabelInputFields
                            TextPrefilled={searchText ? true : false}
                            border="0px"
                            inputWidth="350px"
                            inputHeight="45px"
                            value={searchText}
                            type="text"
                            inputBackground="#FFFFFF"
                            iconType="promo-search"
                            prefilled
                            inputPlaceholder="Search for a particular product"
                            style={{ paddingBottom: 7 }}
                            handleChange={handleSearchInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                            onBlur={handleOnBlur}
                        />

                        <>
                            {showPackages ? (
                                <div
                                    className={csseFilterWrapperStyle}
                                    onClick={() => {
                                        setShowPackages(false),
                                            setFiltersPayload({})
                                    }}
                                >
                                    <ConsumerCloseIcon
                                        fill={'#004AAD'}
                                        stroke={'#004AAD'}
                                    />
                                    <span>Filtered packages</span>
                                </div>
                            ) : (
                                <div
                                    className={csseFilterWrapperStyle}
                                    onClick={() => setAddFilter(true)}
                                >
                                    {isMobile ? (
                                        <MobileFilterIcon />
                                    ) : (
                                        <FilterIcon />
                                    )}
                                    <span>Filter packages</span>
                                </div>
                            )}
                        </>
                    </div>
                    <div
                        className={`${cssePromoListingsStyle} ${csseMultiplePackagePromoListingsStyle}`}
                    >
                        {allPackages?.map((packages, i) => (
                            <div
                                key={i}
                                className={MultiplePromoPackagesDivWrapper}
                            >
                                <div
                                    className={
                                        MultiplePromoPackagesImageWrapper
                                    }
                                >
                                    <LazyLoadImage
                                        src={packages.img}
                                        alt="Promo-package-image"
                                        style={{ objectFit: 'cover' }}
                                    />
                                </div>
                                <div style={{ padding: '10px 16px 0px 16px' }}>
                                    <p className="SolarSystemNameStyle">
                                        {packages.name}
                                    </p>
                                    {packages.energy_capacity?.value && (
                                        <p className="SolarSystemDescriptionStyle">
                                            {packages.energy_capacity?.value +
                                                packages.energy_capacity
                                                    ?.unit_of_measurement}
                                        </p>
                                    )}
                                    <div className="SolarSystemPlanWrapper">
                                        {packages.payment_model && (
                                            <p>{packages.payment_model}</p>
                                        )}
                                        {packages.average_backup_hours && (
                                            <p>{`${Math.round(
                                                packages.average_backup_hours,
                                            )} hours`}</p>
                                        )}
                                    </div>
                                    <section className="SolarSystemPriceWrapper">
                                        <div>
                                            <p
                                                style={{
                                                    color:
                                                        packages?.best_fit &&
                                                        '#E2EEFF',
                                                }}
                                            >
                                                {packages.amount
                                                    ? 'Initial Deposit'
                                                    : 'Today’s full price'}
                                            </p>
                                            <p className="SolarSystemNameStyle">
                                                {packages.upfront ? (
                                                    <>
                                                        <NairaSymbol />
                                                        {currencyFormatter(
                                                            packages.total_cost ||
                                                                packages.upfront,
                                                        )}
                                                    </>
                                                ) : (
                                                    '-'
                                                )}
                                            </p>
                                        </div>
                                        <div>
                                            <p
                                                style={{
                                                    color:
                                                        packages?.best_fit &&
                                                        '#E2EEFF',
                                                }}
                                            >
                                                Least amount payable
                                            </p>
                                            <p
                                                className="SolarSystemDescriptionStyle"
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <NairaSymbol />
                                                {currencyFormatter(
                                                    packages.least_payable_amount ||
                                                        packages.amount,
                                                )}
                                            </p>
                                        </div>
                                    </section>
                                </div>
                                <div className="SolarSytemViewBtn">
                                    <ConsumerButton
                                        btnHeight="53px"
                                        btnWidth="160px"
                                        btnBgColor={'#004AAD'}
                                        btnTextColor={'#FFFFFF'}
                                        btnFontSize="14px"
                                        secondaryHover
                                        handleClick={() => {
                                            history.push({
                                                pathname: `/consumer/view-system/${packages?.package_id}`,
                                                state: {
                                                    page: 'promo',
                                                    clusterCode:
                                                        packages?.clusterCode,
                                                    recommendedCombo:
                                                        packages?.recommendedCombo,
                                                    promoType: id,
                                                    isAuthenticatedUser:
                                                        isAuthenticatedUser,
                                                    action: 'select_new_system',
                                                    estimation_id:
                                                        estimation_id,
                                                },
                                            })
                                            localStorage.setItem(
                                                'promoDetails',
                                                encrypt(
                                                    JSON.stringify({
                                                        promoType: id,
                                                    }),
                                                ),
                                            )
                                        }}
                                    >
                                        View this system
                                    </ConsumerButton>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </section>
            <CSSECTA />
            <ConsumerFooter />
        </>
    )
}

export default MultiplePackagesPromoListings
