export const outrightSaleOfferStatusMap = {
    KYC_NOT_STARTED: 'KYC not started',
    KYC_IN_PROGRESS: 'KYC in-progress',
    AWAITING_PAYMENT: 'Awaiting payment',
    PAYMENT_DISBURSED: 'Payment disbursed',
    AGREEMENT_SIGNING: 'Signed agreement',
    SOLUTION_INSTALLED: 'Solution installed',
    DEAL_COMPLETED: 'Deal completed',
    ARCHIVED: 'Archived',
}

export const outrightSaleOfferStatusTitles = Object?.values(
    outrightSaleOfferStatusMap,
)

export const getOutrightSaleOfferStatusKeyByTitle = title => {
    return Object?.keys(outrightSaleOfferStatusMap)?.find(
        key => outrightSaleOfferStatusMap[key] === title,
    )
}

// get offer status in obj[] format of [{ status:  IN_PROGRESS, title: 'In-progress' },...]
export const mapOutrightSaleOOfferStatusAndTitle = () => {
    return Object.entries(outrightSaleOfferStatusMap).map(
        ([status, title]) => ({
            status,
            title,
        }),
    )
}

export const statusOptions = outrightSaleOfferStatusTitles?.map(status => ({
    value: status,
}))

export const statusInverseMapping = {
    APPROVED: 'Approved',
    AWAITING_APPROVAL: 'Awaiting Approval',
    IN_PROGRESS: 'In Progress',
    AWAITING_UPDATE: 'In Progress',
    DECLINED: 'Declined',
    CLOSED: 'Declined',
}

export const AlternateStatusPayloadMapping = {
    Approved: 'APPROVED',
    'Awaiting Approval': 'AWAITING_APPROVAL',
    'In Progress': 'IN_PROGRESS,AWAITING_UPDATE',
    Declined: 'DECLINED,CLOSED',
}

export const statementTypePayloadMap = {
    Mono: 'mono',
    Okra: 'okra',
    Manual: 'document',
}

export const providerTypePayloadMap = {
    OEMI: 'OEM Installer',
    DISI: 'Distributor Installer',
    ASI: 'Associate Installer',
}

export const providerTypeInverseMap = {
    'OEM Installer': 'OEMI',
    'Distributor Installer': 'DISI',
    'Associate Installer': 'ASI',
}

export const campaignTypePayloadMap = {
    Promo: 'PROMO',
    Cluster: 'CLUSTER',
}

export const onboardingTypePayloadMap = {
    Powercurve: 'ENERGY_ASSESSMENT',
    Profiles: 'ENERGY_PROFILE',
    Systems: 'ALL_SOLUTIONS',
    Promo: 'PROMO',
}
export const onboardingTypeColorMapping = {
    ALL_SOLUTIONS: {
        bgColor: '#FFFAEC',
        color: '#E4AA0F',
    },
    ENERGY_ASSESSMENT: {
        bgColor: '#E2EEFF',
        color: '#004AAD',
    },
    ENERGY_PROFILE: {
        bgColor: 'rgba(229, 229, 229, 0.50)',
        color: '#4D4D4D',
    },
    PROMO: {
        bgColor: 'rgba(50, 204, 121, 0.15)',
        color: '#32CC79',
    },
}

export const onboardingTypeMapping = {
    ENERGY_ASSESSMENT: 'Powercurve',
    ENERGY_PROFILE: 'Profile',
    ALL_SOLUTIONS: 'Select System',
    PROMO: 'Promo',
}
