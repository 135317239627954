//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { Tabs, Pagination, Drawer, Slider, Dropdown, Menu } from 'antd'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import CreditListingTable from './table'
import { NoFLoatingLabelInputFields } from '../../../../components/InputFields'
import Button, { AppButton } from '../../../../components/Button'
import SecondaryModal from '../../../../components/Modal/SecondaryModal'
import { ReactComponent as ArrowDown } from '../../../../assets/images/arrowDown.svg'
import { ReactComponent as ArrowDownWhite } from '../../../../assets/images/arrow-down-white-outlined.svg'
import { DateField } from '../../../../components/InputFields'
import { format } from 'date-fns'
import SeoComponent from '../../../../components/Seo'
import { denyPermission } from 'src/utils/permissionFramework'

import AddCreditVariable from '../AddVariable'
import { isMobile } from 'src/utils/mediaQueries'
import SelectCreditTypeModal from '../SelectCreditType'
import {
    getAdminCreditVariablesApi,
    getProvidersApi,
    getSunFiPackagesApi,
} from '../../../../api/admin/credit-variables/listing'
import { ListingsSkeletalCards } from '../../../../components/SkeletalCards'
import { InlineLoader } from '../../../../components/Loader'
import EmptyState from '../../../../components/EmptyState'
import { errorHandler } from '../../../../utils/errorHandler'
import Toast from '../../../../components/Toast'
import BackNav from '../../../../components/BackNav'
import { ReactComponent as EyeIcon } from '../../../../assets/images/eye-icon-filled.svg'
import { ReactComponent as AddIcon } from '../../../../assets/images/plus-icon-filled.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateAdminCreditSystemFilters,
    updatedAdminCreditSystemFilters,
} from 'src/redux/reducers/admin/credit-system/listing'
import { convertQueryParamsToObject, getKeyByValue } from 'src/utils/formatting'
import {
    applyDynamicPermissionsToAdminUser,
    permissionsControl,
} from 'src/utils/permissionsControl'
import {
    addQueryParamsToUrl,
    getSearchParamsFromUrl,
    removeAllQueryParamsFromUrl,
    removeQueryParamFromUrl,
} from 'src/utils/urlTracker'
import SelectEditTypeModal from './components/SelectEditTypeModal'
import SelectVariablesDrawer from './components/SelectVariablesDrawer'
import EditAndPreviewModal from './components/EditAndPreviewModal'
import ActionModal from 'src/components/ActionModal'
import { actionModalValues } from './data'

const AdminCreditListings = props => {
    const creditTabs = {
        1: 'all',
        2: 'lease-to-own',
        3: 'subscription',
    }
    const filterQuery = getSearchParamsFromUrl()
    const defaultQueries = useSelector(updatedAdminCreditSystemFilters)
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [mouseOver, setMouseOver] = useState(false)
    const [inputs, setInputs] = useState({
        startDate: filterQuery.startDate || defaultQueries.start_date || null,
        endDate: filterQuery.startDate || defaultQueries.end_date || null,
    })

    const [creditVariableType, setCreditVariableType] = useState('')

    const [currentStartDate, setCurrentStartDate] = useState(
        inputs.startDate === null ? null : new Date(inputs.startDate),
    )

    const [currentEndDate, setCurrentEndDate] = useState(
        inputs.endDate === null ? null : new Date(inputs.endDate),
    )
    const [showCalendar, setShowCalendar] = useState(0)
    const [range, setRange] = useState([
        Number(filterQuery.start_lowest_tenure_annual_interest) ||
            Number(defaultQueries.start_lowest_tenure_annual_interest),
        Number(filterQuery.end_lowest_tenure_annual_interest) ||
            Number(defaultQueries.end_lowest_tenure_annual_interest),
    ])
    const [currentTab, setCurrentTab] = useState(
        getKeyByValue(creditTabs, filterQuery.tab) ||
            defaultQueries.current_tab ||
            '1',
    )
    const [showModal, setShowModal] = useState(false)
    const [showClusterModal, setShowClusterModal] = useState(false)
    const [count, setCount] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [creditVariables, setCreditVariables] = useState([])
    const [showCreditVariableTypeModal, setShowCreditVariableTypeModal] =
        useState(false)
    const [currentPage, setCurrentPage] = useState(
        Number(filterQuery.page) || Number(defaultQueries.page) || 1,
    )
    const [searchText, setSearchText] = useState(
        filterQuery.search || defaultQueries.provider_name || '',
    )
    const [filterLoading, setFilterLoading] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [selectedVariable, setSelectedVariable] = useState(
        defaultQueries.payment_model ?? '',
    )
    const [providersList, setProvidersList] = useState([])
    const [packagesList, setPackagesList] = useState([])
    const [width, setWidth] = useState(window.innerWidth)
    const [showSelectEditTypeModal, setShowSelectEditTypeModal] =
        useState(false)
    const [editVariablesMethod, setEditVariablesMethod] = useState('')
    const [variablesDrawerVisible, setVariablesDrawerVisible] = useState(false)
    const [editAndPreviewModalAction, setEditAndPreviewModalAction] =
        useState('edit') // edit | preview
    const [showEditAndPreviewModal, setShowEditAndPreviewModal] =
        useState(false)
    const [modalAction, setModalAction] = useState('') // bulk-edit-variables-successful
    const [actionModalOpen, setActionModalOpen] = useState(false)

    const canViewCreditVariableDetails = permissionsControl([
        'can_create_credit_variable',
    ])
    const bulkEditVariables =
        process.env.REACT_APP_FLAG_BULK_EDIT_CREDIT_VARIABLES === 'true'

    const updatePage = page => {
        setCurrentPage(page)
        addQueryParamsToUrl({
            page: page,
        })
    }
    let history = useHistory()

    const {
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalShowCancelBtn,
        actionModalActionText,
    } = actionModalValues(modalAction)

    const openActionModal = action => {
        setModalAction(action)
        setActionModalOpen(true)
    }

    const actionModalHandler = () => {
        switch (modalAction) {
            case 'bulk-edit-variables-successful':
                setActionModalOpen(false)
                setModalAction('')
                break
        }
    }

    const handleDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    const handleAddFilter = () => {
        resetPage = 1
        setFilterLoading(true)
        creditVariableRefetch()
        setDrawerVisible(false)
        addQueryParamsToUrl({
            startDate: currentStartDate
                ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                : '',
            endDate: currentEndDate
                ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                : '',
            start_lowest_tenure_annual_interest: range[0],
            end_lowest_tenure_annual_interest: range[1],
            page: '1',
        })
    }

    //changing icon color on top button depending on hover state
    const buttonIcon = mouseOver ? (
        <ArrowDownWhite style={{ marginLeft: 12 }} />
    ) : (
        <ArrowDown style={{ marginLeft: 12 }} />
    )

    const { TabPane } = Tabs

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]: name === 'startDate' ? currentStartDate : currentEndDate,
        }))
    }

    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(format(date, 'MM/dd/yyyy'))
        }
        if (name === 'endDate') {
            setCurrentEndDate(format(date, 'MM/dd/yyyy'))
        }
    }

    const DATA_MAP = {
        LEASE_TO_OWN: 'Lease to Own',
        SUBSCRIPTION: 'Subscription',
    }

    const closeSelectEditTypeModal = () => {
        setShowSelectEditTypeModal(false)
    }

    const openVariablesDrawer = () => {
        setVariablesDrawerVisible(true)
    }

    const closeVariablesDrawer = () => {
        setVariablesDrawerVisible(false)
    }

    const openEditAndPreviewModal = () => {
        if (editVariablesMethod === 'multiple-variables-individually') {
            setEditAndPreviewModalAction('edit')
            setShowEditAndPreviewModal(true)
            setVariablesDrawerVisible(false)
        }
    }

    const closeEditAndPreviewModal = () => {
        setShowEditAndPreviewModal(false)
        setEditAndPreviewModalAction('edit')
    }

    const menu = (
        <Menu>
            <Menu.Item
                key={'LEASE_TO_OWN'}
                onClick={() => {
                    setSelectedVariable('Lease to Own')
                    setShowCreditVariableTypeModal(true)
                    setCreditVariableType('')
                }}
            >
                <span className="dropdownLinks">Lease to Own</span>
            </Menu.Item>
            <Menu.Item
                key={'SUBSCRIPTION'}
                onClick={() => {
                    setSelectedVariable('Subscription')
                    setShowCreditVariableTypeModal(true)
                    setCreditVariableType('')
                }}
            >
                <span className="dropdownLinks">Subscription</span>
            </Menu.Item>
        </Menu>
    )
    const paymentModel = ['All', 'lease', 'subscription']
    const paymentModelName = {
        All: 'All Variables',
        lease: 'Lease to Own',
        subscription: 'Subscription',
    }

    let resetPage = null

    //get credit variables
    const {
        isFetching: crediVariablesFetching,
        refetch: creditVariableRefetch,
    } = useQuery(
        ['get-admin-credit-variables', currentPage, currentTab],
        () =>
            getAdminCreditVariablesApi(
                currentPage,
                paymentModel[parseInt(currentTab) - 1],
                reset ? '' : searchText,
                reset
                    ? ''
                    : currentStartDate
                    ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                    : '',
                reset
                    ? ''
                    : currentEndDate
                    ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                    : '',
                reset ? 0 : range[0],
                reset ? '' : range[1] > 0 ? range[1] : '',
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateAdminCreditSystemFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            `current_tab:${currentTab}`,
                        ]),
                    ),
                )
                setCreditVariables(data?.data?.data)
                setCount(data?.data?.count)
                setTotalPages(data?.data?.total_pages)
                setFilterLoading(false)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )
    // get providers
    const paymentModelType =
        creditVariableType === 'new_dep_cluster'
            ? ''
            : selectedVariable === 'Lease to Own'
            ? `has_lease_to_own_credit_variable`
            : `has_subscription_credit_variable`

    const { refetch: getProvidersRefetch, isFetching: fetchingProviders } =
        useQuery(
            ['get-providers'],
            () =>
                getProvidersApi(
                    paymentModelType,
                    'display_name',
                    'id,display_name',
                ),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setProvidersList(data?.data?.data)
                    if (creditVariableType === 'existing_provider') {
                        setShowCreditVariableTypeModal(false)
                        setShowModal(true)
                    }
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    const {
        refetch: getSunFiPackagesRefetch,
        isFetching: fetchingSunFiPackages,
    } = useQuery(['get-sunfi-packages'], () => getSunFiPackagesApi(), {
        enabled: false,
        retry: false,
        onSuccess: data => {
            setPackagesList(data?.data?.data)
            if (creditVariableType === 'new_cluster') {
                setShowCreditVariableTypeModal(false)
                setShowModal(true)
            }
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    useEffect(() => {
        if (
            creditVariableType === 'new_dep_cluster' &&
            !fetchingSunFiPackages &&
            !fetchingProviders
        ) {
            setShowCreditVariableTypeModal(false)
            setShowModal(true)
        }
    }, [fetchingSunFiPackages, fetchingProviders])
    let reset

    const resetValues = () => {
        reset = true
        setRange([0, 0])
        setInputs(prev => ({
            ...prev,
            endDate: null,
            startDate: null,
        }))
        setCurrentStartDate(null)
        setCurrentEndDate(null)
        setCurrentPage(1)
        setSearchText('')
        setTotalPages(0)
        setCreditVariables([])
    }

    const handleReset = () => {
        resetValues()
        removeAllQueryParamsFromUrl()
        creditVariableRefetch()
        setDrawerVisible(false)
    }
    useEffect(() => {
        // reset page to 1 when another tab is clicked
        resetPage = 1
        setTotalPages(0)
        setCreditVariables([])
        creditVariableRefetch()
        const currentTabUrl =
            currentTab === '1'
                ? 'all-variables'
                : currentTab === '2'
                ? 'lease-to-own'
                : currentTab === '3'
                ? 'subscription'
                : ''
        addQueryParamsToUrl({
            tab: currentTabUrl,
        })
    }, [currentTab])

    useEffect(() => {
        setTotalPages(0)
        setCreditVariables([])
        creditVariableRefetch()
    }, [currentPage])

    const fetchPackages = () => {
        if (['new_cluster', 'new_dep_cluster'].includes(creditVariableType)) {
            getSunFiPackagesRefetch()
        }
    }

    const fetchProviders = () => {
        if (
            ['existing_provider', 'new_dep_cluster'].includes(
                creditVariableType,
            )
        ) {
            getProvidersRefetch()
        }
    }

    const handleSearchInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
    }

    const handleSearch = () => {
        setTotalPages(0)
        setCreditVariables([])
        creditVariableRefetch()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
            if (event.target.value.length > 0) {
                addQueryParamsToUrl({
                    search: event.target.value,
                    page: '1',
                })
            } else {
                removeQueryParamFromUrl('search')
                addQueryParamsToUrl({
                    page: '1',
                })
            }
        }
    }

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }

    useEffect(() => {
        if (!searchText) {
            removeQueryParamFromUrl('search')
            creditVariableRefetch()
        }
    }, [searchText])
    const maxFilterAmount = 100
    const emptyStateSubtitle = ['Lease to Own', 'Subscription']

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleWindowResize)

        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    return (
        <>
            <SeoComponent
                title="SunFi - Admin Credits System | Simplifying and Scaling Clean Energy"
                tracker="AdminCreditsSystemTracker"
            />
            <SecondaryModal
                modalWidth="624px"
                closable={false}
                modalHeight="575px"
                app="adminsolution"
                showModal={showModal}
                content={
                    <AddCreditVariable
                        closeModal={() => setShowModal(false)}
                        selectedVariable={selectedVariable}
                        providersList={
                            creditVariableType === 'new_dep_cluster'
                                ? providersList
                                : providersList?.filter(
                                      data => !data[paymentModelType],
                                  )
                        }
                        fetchingSunFiPackages={fetchingSunFiPackages}
                        fetchingProviders={fetchingProviders}
                        packagesList={packagesList}
                        creditVariableType={creditVariableType}
                    />
                }
            />
            <SecondaryModal
                modalWidth="624px"
                closable={false}
                modalHeight="475px"
                app="adminsolution"
                showModal={showClusterModal}
                content={<div></div>}
            />
            <SecondaryModal
                showModal={showCreditVariableTypeModal}
                modalHeight="587px"
                modalWidth="459px"
                noPadding={isMobile ? '16px' : '40px'}
                onCancel={() => setShowCreditVariableTypeModal(false)}
                content={
                    <SelectCreditTypeModal
                        closeModal={() => {
                            fetchPackages()
                            fetchProviders()
                        }}
                        creditVariableType={creditVariableType}
                        setCreditVariableType={setCreditVariableType}
                        setShowClusterModal={setShowClusterModal}
                        fetchingSunFiPackages={fetchingSunFiPackages}
                        fetchingProviders={fetchingProviders}
                    />
                }
            />

            <SelectEditTypeModal
                showModal={showSelectEditTypeModal}
                openVariablesDrawer={openVariablesDrawer}
                closeModal={closeSelectEditTypeModal}
                setEditVariablesMethod={setEditVariablesMethod}
            />

            <EditAndPreviewModal
                showModal={showEditAndPreviewModal}
                closeModal={closeEditAndPreviewModal}
                editAndPreviewModalAction={editAndPreviewModalAction}
                setEditAndPreviewModalAction={setEditAndPreviewModalAction}
                handleSuccess={() => {
                    openActionModal('bulk-edit-variables-successful')
                    setShowEditAndPreviewModal(false)
                }}
            />

            <ActionModal
                actionModalOpen={actionModalOpen}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                actionType={actionModalActionType}
                noCancelBtn={!actionModalShowCancelBtn}
                actionText={actionModalActionText}
                actionHandler={actionModalHandler}
                onCancel={actionModalHandler}
                closable
                noBackLink
                primaryBtnWidth={'100%'}
            />

            {/* drawers */}
            <Drawer
                placement="right"
                width="367px"
                visible={drawerVisible}
                onClose={handleDrawer}
                closable={width <= 500}
            >
                <h2 className="ACLFilterHeader">Filter Variables</h2>

                {/*filer by date*/}
                <h4 className="ACLFilterByDateHeader">
                    Filter by Created Date
                </h4>
                <div className="ACLDateFieldsWrapper">
                    <div className="ACLDateField">
                        <DateField
                            placeholder="Start Date"
                            name="startDate"
                            caretStyle="caretStyle"
                            dateValue={
                                inputs.startDate === null
                                    ? null
                                    : new Date(inputs.startDate)
                            }
                            handleDateChange={(name, date) =>
                                handleDateChange(name, date)
                            }
                            openCalendar={showCalendar === 1 ? true : false}
                            closeCalendarHandler={() => setShowCalendar(null)}
                            openCalendarHandler={() => setShowCalendar(1)}
                            setHandleDateChange={name =>
                                setHandleDateChange(name)
                            }
                        />
                    </div>
                    <div className="ACLDateField">
                        <DateField
                            placeholder="End Date"
                            name="endDate"
                            caretStyle="caretStyle"
                            dateValue={
                                inputs.endDate === null
                                    ? null
                                    : new Date(inputs.endDate)
                            }
                            handleDateChange={(name, date) =>
                                handleDateChange(name, date)
                            }
                            openCalendar={showCalendar === 2 ? true : false}
                            closeCalendarHandler={() => setShowCalendar(null)}
                            openCalendarHandler={() => setShowCalendar(2)}
                            setHandleDateChange={name =>
                                setHandleDateChange(name)
                            }
                            placePopperRight
                        />
                    </div>
                </div>

                {/*    filter by interest*/}
                <h4 className="ACLFilterByInterestHeader">Lowest Rate</h4>
                <Slider
                    range={{ draggableTrack: true }}
                    defaultValue={[0, 0]}
                    tooltipPlacement={'bottom'}
                    value={range}
                    onChange={value => setRange(value)}
                    max={maxFilterAmount}
                    tipFormatter={value => `${value} %`}
                />
                {/*    buttons*/}
                <div className="ACLFilterButtonWrapper">
                    <AppButton
                        btnBgColor="#E2EEFF"
                        btnTextColor="#004AAD"
                        btnWidth={160}
                        btnHeight={56}
                        fontFamily="SF-Pro-Display-Semibold"
                        fontSize="14px"
                        handleClick={handleReset}
                    >
                        Reset Filter
                    </AppButton>
                    <AppButton
                        btnTextColor="#FFFFFF"
                        btnBgColor="#004AAD"
                        btnWidth={160}
                        btnHeight={56}
                        fontFamily="SF-Pro-Display-Semibold"
                        fontSize="14px"
                        handleClick={handleAddFilter}
                        disabled={filterLoading ? true : false}
                    >
                        {filterLoading ? <InlineLoader /> : 'Apply Filter'}
                    </AppButton>
                </div>
            </Drawer>

            <SelectVariablesDrawer
                drawerVisible={variablesDrawerVisible}
                handleDrawerClose={closeVariablesDrawer}
                openEditAndPreviewModal={openEditAndPreviewModal}
            />

            {/*page content*/}
            <div className="ACLWrapper">
                <div>
                    <div className="ACLTitle">
                        <div className="ACLTitleComponents">
                            <p>Credit System</p>
                            <div className="ACLTitleComponentsButtonWrapper">
                                {applyDynamicPermissionsToAdminUser
                                    ? canViewCreditVariableDetails
                                    : !denyPermission(
                                          'admin',
                                          'credit_variable',
                                          'credit_variable:can_create',
                                      ) && (
                                          <Dropdown
                                              overlay={menu}
                                              placement="bottomCenter"
                                              trigger={['click']}
                                          >
                                              <a
                                                  onClick={e =>
                                                      e.preventDefault()
                                                  }
                                              >
                                                  <AddIcon />
                                              </a>
                                          </Dropdown>
                                      )}
                            </div>
                        </div>
                    </div>
                </div>

                {/*    search & filter, view buttons*/}
                <div className="ACLTopWrapper">
                    <div className="ACLInputWrapper">
                        <NoFLoatingLabelInputFields
                            TextPrefilled={searchText ? true : false}
                            borderRadius="10px"
                            inputWidth="350px"
                            inputHeight="45px"
                            value={searchText}
                            type="text"
                            inputBackground="#F6F6F6"
                            iconType="search"
                            prefilled
                            inputPlaceholder="Search"
                            style={{ paddingBottom: 7 }}
                            handleChange={handleSearchInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                            onBlur={handleOnBlur}
                        />
                        <div
                            className="AdminRequestListingFilterBox"
                            style={{ border: '1px solid #e5e5e5' }}
                            onClick={handleDrawer}
                        >
                            <svg
                                width="12"
                                height="15"
                                viewBox="0 0 12 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginTop: 4 }}
                            >
                                <path
                                    d="M7.5 8.5V13L4.5 14.5V8.5L0 1.75V0.25H12V1.75L7.5 8.5ZM1.803 1.75L6 8.0455L10.197 1.75H1.803Z"
                                    fill={'#1D2A30'}
                                />
                            </svg>

                            <p className="AdminRequestListingFilterText">
                                Filters
                            </p>
                        </div>
                    </div>
                    <div className="ACLTopButtonsWrapper">
                        {bulkEditVariables && (
                            <Button
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                btnHeight="50px"
                                btnWidth="157px"
                                fontWeight={600}
                                handleClick={() =>
                                    setShowSelectEditTypeModal(true)
                                }
                            >
                                Edit variables
                            </Button>
                        )}
                        {/*to be wrapped in dropdown tags*/}
                        <div>
                            <Dropdown
                                overlay={menu}
                                placement="bottomCenter"
                                trigger={['click']}
                            >
                                <a onClick={e => e.preventDefault()}>
                                    <Button
                                        btnBgColor="#004AAD"
                                        btnTextColor="#ffffff"
                                        btnHeight="50px"
                                        btnWidth="157px"
                                        fontWeight={600}
                                        disabled={
                                            applyDynamicPermissionsToAdminUser
                                                ? !canViewCreditVariableDetails
                                                : denyPermission(
                                                      'admin',
                                                      'credit_variable',
                                                      'credit_variable:can_create',
                                                  )
                                        }
                                        toolTipText={
                                            !canViewCreditVariableDetails
                                                ? 'You are not authorised to perform this action'
                                                : null
                                        }
                                        toolTipPopupWidth={164}
                                        arrowPositionCenter
                                        toolTipFontSize={'14px'}
                                        toolTipMarginLeft={-8}
                                    >
                                        Add Variable{' '}
                                        <ArrowDownWhite
                                            style={{ marginLeft: 12 }}
                                        />
                                    </Button>
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                {/*    tabpane and tables*/}
                <div className="ARLTableWrapper">
                    {crediVariablesFetching ? (
                        <ListingsSkeletalCards total={3} />
                    ) : (
                        <Tabs
                            activeKey={currentTab}
                            style={{ marginTop: 38 }}
                            onChange={values => {
                                setCurrentTab(values)
                                addQueryParamsToUrl({
                                    page: 1,
                                })
                                resetValues()
                            }}
                        >
                            {toastError && (
                                <Toast
                                    messageType="error"
                                    message={errorMessage}
                                />
                            )}
                            {paymentModel.map((data, index) => {
                                return (
                                    <TabPane
                                        tab={`${paymentModelName[data]} ${
                                            index + 1 === parseInt(currentTab)
                                                ? '(' + count + ')'
                                                : ''
                                        }`}
                                        key={index + 1}
                                    >
                                        {creditVariables.length > 0 ? (
                                            <CreditListingTable
                                                tableData={creditVariables}
                                                tabId={parseInt(currentTab)}
                                            />
                                        ) : (
                                            <div className="NoActivityStyle">
                                                <EmptyState
                                                    type="noActivity"
                                                    title="No recent Credit Variables"
                                                    subTitle={
                                                        parseInt(currentTab) -
                                                            1 ===
                                                        0
                                                            ? ''
                                                            : `No ${
                                                                  emptyStateSubtitle[
                                                                      parseInt(
                                                                          currentTab,
                                                                      ) - 2
                                                                  ]
                                                              } Credit Variables yet`
                                                    }
                                                />
                                            </div>
                                        )}
                                    </TabPane>
                                )
                            })}
                        </Tabs>
                    )}
                </div>
                {creditVariables.length > 0 && !crediVariablesFetching ? (
                    <div className="ACLPaginationWrapper">
                        <Pagination
                            defaultCurrent={1}
                            current={currentPage}
                            total={parseInt(totalPages, 10)}
                            defaultPageSize={1}
                            showSizeChanger={false}
                            showTitle={false}
                            showLessItems
                            onChange={updatePage}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    )
}

export default AdminCreditListings
