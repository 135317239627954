import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ActionModal from 'src/components/ActionModal'
import useGetComponentBrands from 'src/pages/Profile/ComponentBrands/hooks/useGetComponentBrands'

const AddBrandsNotificationModal = ({ shouldDisplay }) => {
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const {
        componentBrands,
        componentBrandsInReview,
        getComponentBrandsSuccess,
    } = useGetComponentBrands(shouldDisplay)
    const history = useHistory()

    const closeModal = () => {
        localStorage.setItem('addBrandNotificationDisplayed', 'true')
        setActionModalOpen(false)
    }

    useEffect(() => {
        if (
            shouldDisplay &&
            getComponentBrandsSuccess &&
            !componentBrands.length &&
            !componentBrandsInReview.length
        ) {
            setActionModalOpen(true)
        }
    }, [
        shouldDisplay,
        getComponentBrandsSuccess,
        componentBrands,
        componentBrandsInReview,
    ])

    return (
        <ActionModal
            actionModalOpen={actionModalOpen}
            onCancel={closeModal}
            closeModal={closeModal}
            closable
            headerText={
                <>
                    Please add the brands
                    <br />
                    you work with
                </>
            }
            subTitle="All providers on SunFi are now required to add brands for each component that they work with"
            actionType={'warning'}
            actionText={'Add my brands now'}
            actionHandler={() => {
                history.push('/app/profile?tab=component-brands')
                closeModal()
            }}
            cancelText={"I'll do this later"}
            noBackLink
            cancelButtonOutlined
            cancelButtonWidth={'175px'}
            primaryBtnWidth={'175px'}
            modalBorderRadius={'10px'}
        />
    )
}

AddBrandsNotificationModal.propTypes = {
    shouldDisplay: PropTypes.bool.isRequired,
}

const AddBrandsNotification = () => {
    const { componentBrands, componentBrandsInReview } = useGetComponentBrands()

    const addBrandNotificationDisplayed =
        localStorage.getItem('addBrandNotificationDisplayed') === 'true'
    const canUseComponentBrands =
        process.env.REACT_APP_FLAG_SHOW_COMPONENT_BRANDS === 'true'
    const hasNotAddedBrands =
        componentBrands?.length === 0 && componentBrandsInReview?.length === 0
    const shouldDisplay =
        canUseComponentBrands &&
        !addBrandNotificationDisplayed &&
        hasNotAddedBrands

    return <AddBrandsNotificationModal shouldDisplay={shouldDisplay} />
}

export default AddBrandsNotification
