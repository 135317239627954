/* eslint-disable react/prop-types */
import { useState } from 'react'
import FormTitleBar from '../../../components/FormTitleBar'
import ActionModal from '../../../components/ActionModal'
import { Upload } from 'antd'
import Toast from '../../../components/Toast'
import { ActionSection } from '../../Onboarding'
import ImageListing from '../../../components/ImageListing'
import { useQuery } from 'react-query'
import { errorHandler } from '../../../utils/errorHandler'
import { removeCommaFromNum } from '../../../utils/NumberFormatter'
import { createComponentApi } from '../../../api/products/component'
import successUpload from '../../../assets/images/successUpload.svg'
import { truncateFileName } from '../../../utils/formatting'
import { decodeUserInfo } from 'src/utils/auth'

import './modalstepthree.scss'

const AddComponentsModalStageThree = ({
    goToPrevious,
    // eslint-disable-next-line no-unused-vars
    closeModal,
    initialState,
    parentCallback,
    restart,
    sunfiId,
}) => {
    const [fileName, setFileName] = useState('')
    const [uploadedFile, setUploadedFile] = useState([])
    const [successModal, setSuccessModal] = useState(false)
    const [sampleImgUrl, setSampleImgUrl] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [validationMessage, setValidationMessage] = useState('')
    const [showImageSample, setShowImageSample] = useState(false)
    const [isparentCallback, setIsParentCallback] = useState(false)
    const userInfo = decodeUserInfo()
    const isAdminWorkspace = userInfo?.isAdminWorkspaceUser === 'true'

    const props = {
        name: 'file',
        onChange(info) {
            setUploadedFile(info.fileList)
            setFileName(info.file.name)
        },
        showUploadList: false,
    }

    let selectedAttributes = initialState?.selectedAttributes
        ?.filter(value => value?.show === true)
        .map(att => {
            const { name, unit, value } = att
            return {
                [name]: { value: value, unit_of_measurement: unit },
            }
        })

    let formattedAttribute = selectedAttributes?.reduce(function (
        result,
        item,
    ) {
        var key = Object.keys(item)[0]
        result[key] = item[key]
        return result
    },
    {})
    const { refetch, isFetching } = useQuery(
        'createComponent',
        () =>
            createComponentApi(
                {
                    name: initialState.componentName,
                    description: initialState.componentDescription,
                    type_id: initialState.componentTypeId,
                    componentType: initialState.componentType,
                    warranty: initialState?.componentWarranty,
                    cost: removeCommaFromNum(initialState.componentCost),
                    attributes: JSON.stringify(formattedAttribute),
                    image:
                        uploadedFile.length > 0 &&
                        uploadedFile[0]?.originFileObj,
                    suggested_image_url: sampleImgUrl && sampleImgUrl,
                    is_global:
                        initialState.isGlobal === true ? 'True' : 'False',
                    providerId: sunfiId,
                    brand_id: initialState.brandId,
                },
                isAdminWorkspace,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                let componentData = data?.data?.data
                localStorage.setItem(
                    'ComponentData',
                    JSON.stringify(componentData),
                )
                setTimeout(() => {
                    setSuccessModal(true)
                    setIsParentCallback(true)
                    parentCallback(isparentCallback)
                }, 1500)
            },
            onError: data => {
                if (data?.response?.data?.errors) {
                    setErrorMessage(errorHandler(data?.response?.data))
                } else {
                    setErrorMessage([
                        'Failed to create Component. Refresh/Try Again',
                    ])
                }
                setToastError(true)
            },
        },
    )

    const handleSubmit = () => {
        setValidationMessage('')
        refetch()
    }

    const closeSuccessModal = () => {
        setSuccessModal(false)
        localStorage.removeItem('ComponentData')
        restart()
    }

    return (
        <div>
            {toastError ? (
                <Toast messageType="error" message={errorMessage[0]} />
            ) : validationMessage ? (
                <Toast messageType="error" message={validationMessage} />
            ) : null}
            <ActionModal
                actionModalOpen={successModal}
                headerText="Component Created Successfully"
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                closeModal={true}
                actionHandler={closeSuccessModal}
                actionType="success"
                actionText="Go back"
                loading={''}
                noCancelBtn
            />

            <FormTitleBar
                title="Create New Component"
                subtitle="Add Image to your Component"
            />
            <div className={`image-Wrapper ${'mobileImageWrapper'}`}>
                <p className="ant-upload-drag-icon">
                    <img src={successUpload} alt="successful-upload" />
                </p>
                <div className="filename">{truncateFileName(fileName)}</div>

                <Upload {...props}>
                    <div
                        style={{
                            marginBottom: '13px',
                            color: 'rgba(35, 56, 98, 0.78)',
                        }}
                    >
                        {!fileName ? (
                            <>
                                <span>
                                    Upload image. Drag and drop or &nbsp;
                                </span>
                                <span className="replaceDoc">browse</span>
                            </>
                        ) : (
                            <span className="replaceDoc">Replace document</span>
                        )}
                    </div>
                </Upload>
            </div>
            <div
                className={`$'ImageBox' ${
                    showImageSample && 'ShowSampleImage'
                }`}
            >
                <ImageListing
                    imageListingStyle="listingWrapper"
                    inputWidth="300px"
                    imgStyle="imgStyle"
                    height={110}
                    ImageListingBtns="ImageListingBtns"
                    onClose={() => setShowImageSample(false)}
                    onSelect={(imageName, imageUrl) => {
                        setFileName(imageName)
                        setSampleImgUrl(imageUrl)
                    }}
                    componentType={initialState?.componentType}
                    componentName={initialState?.componentName}
                />
            </div>
            <div style={{ margin: '-80px 0px -20px' }}>
                <ActionSection
                    stage={3}
                    nextHandler={handleSubmit}
                    prevHandler={() => goToPrevious(initialState)}
                    btnText="Add Component"
                    loading={isFetching}
                />
            </div>
        </div>
    )
}

export default AddComponentsModalStageThree
