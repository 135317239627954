import axios from 'src/config/axios'

export const getProviderClusterApi = async (
    page,
    paymentModel = '',
    searchText = '',
) => {
    const request = await axios.get(
        `/providers/credit/clusters?page=${page}&payment_model=${paymentModel}&name=${searchText}`,
    )
    return request
}
