import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import EnergyNeeds from 'src/components/EnergyNeeds'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import { updateConsumerProfile } from 'src/redux/reducers/admin/profiles/create-profile'

const ProfileDetails = ({
    fetchingProfile,
    profileDetails,
    energyNeeds,
    setEnergyNeeds,
    step,
    isPrivateProfile,
    isPublicProfile,
}) => {
    const dispatch = useDispatch()
    return (
        <>
            {step === 2 ? (
                <div>
                    {fetchingProfile ? (
                        <ListingsSkeletalCards total={1} columnCount={3} />
                    ) : (
                        <div className="ProfileDetails">
                            <div className="InfoContainer">
                                <div>
                                    <p className="InfoHead">Cluster name</p>
                                    <p className="Info">
                                        {
                                            profileDetails
                                                ?.cluster_origination_setup
                                                ?.name
                                        }
                                    </p>
                                </div>
                                <div>
                                    <p className="InfoHead">
                                        Cluster email domain
                                    </p>
                                    <p
                                        className="Info"
                                        style={{
                                            color: '#026DFF',
                                            textTransform: 'lowercase',
                                        }}
                                    >
                                        {profileDetails
                                            .cluster_origination_setup
                                            ?.website ?? '-'}
                                    </p>
                                </div>
                            </div>

                            <div className="InfoContainer">
                                <div>
                                    <p className="InfoHead">
                                        score card template
                                    </p>
                                    <p className="Info">
                                        {profileDetails?.credit_scorecard_name}
                                    </p>
                                </div>
                                <div>
                                    <p className="InfoHead">Cluster logo</p>
                                    <img
                                        src={
                                            profileDetails
                                                ?.cluster_origination_setup
                                                ?.logo
                                        }
                                        alt="cluster-logo"
                                        width="116px"
                                        height="37px"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </div>
                            </div>

                            <div className="InfoContainer">
                                <div>
                                    <p className="InfoHead">Add KYC modules</p>
                                    {profileDetails?.modules?.map(
                                        (module, i) => (
                                            <p key={i} className="Info">
                                                {module?.title ?? '-'}
                                            </p>
                                        ),
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    {fetchingProfile ? (
                        <ListingsSkeletalCards total={1} columnCount={5} />
                    ) : (
                        <div className="ProfileDetails">
                            <div className="InfoContainer">
                                <div>
                                    <p className="InfoHead">
                                        Consumer Profile Name
                                    </p>
                                    <p className="Info">
                                        {profileDetails?.name}
                                    </p>
                                </div>
                                <div>
                                    <p className="InfoHead">
                                        Consumer Profile Type
                                    </p>
                                    <p className="Info">
                                        {profileDetails?.customer_type}
                                    </p>
                                </div>
                            </div>
                            {!isPrivateProfile && !isPublicProfile && (
                                <div className="InfoContainer">
                                    <div>
                                        <p className="InfoHead">
                                            Consumer Profile Picture
                                        </p>
                                        <img
                                            src={
                                                profileDetails?.meta_data
                                                    ?.image_url
                                            }
                                            alt="profile-picture"
                                            className="Info-Img"
                                        />
                                    </div>
                                </div>
                            )}
                            {!isPrivateProfile && !isPublicProfile && (
                                <div className="InfoContainer">
                                    <div>
                                        <p className="InfoHead">
                                            Consumer Profile Group
                                        </p>
                                        <p className="Info">
                                            {profileDetails?.group?.name}
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className="InfoDescription">
                                <p className="InfoHead">
                                    Consumer Profile Description
                                </p>
                                <p
                                    className="ProfileDescription"
                                    dangerouslySetInnerHTML={{
                                        __html: profileDetails?.description,
                                    }}
                                ></p>
                            </div>
                            <div className="InfoContainer">
                                <div>
                                    <p className="InfoHead">Min. system size</p>
                                    <p className="Info">
                                        {
                                            profileDetails?.meta_data
                                                ?.energy_capacity
                                                ?.minimum_capacity?.value
                                        }
                                        KVA
                                    </p>
                                </div>
                                <div>
                                    <p className="InfoHead">MAX. system size</p>
                                    <p className="Info">
                                        {
                                            profileDetails?.meta_data
                                                ?.energy_capacity
                                                ?.maximum_capacity?.value
                                        }
                                        kVA
                                    </p>
                                </div>
                            </div>
                            <div
                                className="InfoContainer"
                                style={{ columnGap: 69 }}
                            >
                                <div>
                                    <p className="InfoHead">
                                        added L2O Variable
                                    </p>
                                    <p className="Info">
                                        {profileDetails?.meta_data?.cluster_info
                                            .LEASE_TO_OWN ?? '-'}
                                    </p>
                                </div>
                                <div>
                                    <p className="InfoHead">
                                        added SUBSCRIPTION Variable
                                    </p>
                                    <p className="Info">
                                        {profileDetails?.meta_data?.cluster_info
                                            .SUBSCRIPTION ?? '-'}
                                    </p>
                                </div>
                            </div>
                            {isPublicProfile && (
                                <div className="InfoContainer">
                                    <div>
                                        <p className="InfoHead">Promo Banner</p>
                                        <img
                                            src={profileDetails?.image_url}
                                            alt="promo-banner"
                                            className="Info-Img"
                                        />
                                    </div>
                                </div>
                            )}
                            <EnergyNeeds
                                energyNeeds={energyNeeds}
                                setEnergyNeeds={setEnergyNeeds}
                                onAfterUpdate={appliances =>
                                    dispatch(
                                        updateConsumerProfile({
                                            appliances: appliances,
                                        }),
                                    )
                                }
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

ProfileDetails.propTypes = {
    fetchingProfile: PropTypes.bool,
    profileDetails: PropTypes.any,
    energyNeeds: PropTypes.any,
    setEnergyNeeds: PropTypes.any,
    handleActionModal: PropTypes.any,
    step: PropTypes.number,
    isPrivateProfile: PropTypes.bool,
    isPublicProfile: PropTypes.bool,
}

export default ProfileDetails
