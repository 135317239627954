import axios from '../../config/axios'

export const synchronizePaystackApi = async estimationId => {
    const request = axios.get(
        `/payments/synchronize-subscription/estimation/${estimationId}`,
    )
    return request
}

export const checkDebitStatusApi = async (estimationId, invoiceId) => {
    const request = axios.get(
        `/payments/direct-debit/debit-status/${estimationId}/${invoiceId}`,
    )
    return request
}
