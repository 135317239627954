import { currencyFormatter } from 'src/utils/currencyFormatter'
import { convertToSentenceCase } from 'src/utils/formatting'

export const formatAlternatePackage = packages => {
    let formattedPackages = []
    packages.forEach(singlePackage => {
        const newObject = {
            name: convertToSentenceCase(singlePackage?.name),
            components: singlePackage?.components?.length,
            amount: '₦' + currencyFormatter(singlePackage?.total),
            id: singlePackage?.id,
            energyCapacity:
                singlePackage?.energy_capacity?.value +
                singlePackage?.energy_capacity?.unit_of_measurement,
        }
        formattedPackages.push(newObject)
    })

    return formattedPackages
}
