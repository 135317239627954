import { useMutation } from 'react-query'
import { createProfile } from 'src/api/admin/profiles/manage-profile'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

export const useCreateProfile = ({ data, handleResposneData }) => {
    const { errorAlert } = useCustomToast()
    const { mutate: handleCreateProfile, isLoading } = useMutation({
        mutationFn: () => createProfile(data),
        onSuccess: res => {
            if (handleResposneData) {
                handleResposneData(res.data)
            }
        },
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })
    return {
        handleCreateProfile,
        isLoading,
    }
}
