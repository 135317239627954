/* eslint-disable react/prop-types */
import { useState } from 'react'
import FormTitleBar from '../../../../components/FormTitleBar'
import { SelectField } from '../../../../components/InputFields'
import { InputFields, TreeSelectAll } from '../../../../components/InputFields'
import Button from '../../../../components/Button'
import CloseIcon from '../../../../assets/images/black-close-icon.svg'
import CreditVariableModal from './CreditVariableModal'
import SeoComponent from '../../../../components/Seo'
import './addvariable.scss'
import { isMobile } from 'src/utils/mediaQueries'

const AddCreditVariable = ({
    closeModal,
    selectedVariable,
    providersList,
    packagesList,
    creditVariableType,
    fetchingSunFiPackages,
    fetchingProviders,
}) => {
    const [provider, setProvider] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [errors, setErrors] = useState('')
    const [selectedPackages, setSelectedPackages] = useState([])
    const [selectedProviders, setSelectedProviders] = useState([])
    const [clusterInfo, setClusterInfo] = useState({
        name: '',
        packages: [],
        providers: [],
    })
    const [searchValue, setSearchValue] = useState('')

    const closeContextModal = () => {
        setClusterInfo({ name: '', packages: [], providers: [] })
        setSelectedPackages([])
        setSelectedProviders([])
        setProvider('')
        setErrors('')
        closeModal()
    }

    const closeVariableDetailsModal = () => {
        setClusterInfo({ name: '', packages: [], providers: [] })
        setSelectedPackages([])
        setSelectedProviders([])
        setProvider('')
        setShowModal(false)
    }

    const handleSelectChange = (name, value) => {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
        setProvider(value)
    }

    const handleSelectProvider = () => {
        if (provider === '') {
            setErrors({ provider: 'This field is required' })
        } else {
            setShowModal(true)
            closeModal()
        }
    }

    const handleClusterSetupContinue = () => {
        if (clusterInfo.name === '') {
            setErrors({ name: 'This field is required' })
        } else if (clusterInfo.name.length < 3) {
            setErrors({ name: 'The name has to be a minimum of 3 characters' })
        } else if (clusterInfo.packages.length === 0) {
            setErrors({ packages: 'You should select at least 1 package' })
        } else if (
            creditVariableType === 'new_dep_cluster' &&
            clusterInfo.providers.length === 0
        ) {
            setErrors({ providers: 'You should select at least 1 provider' })
        } else {
            setShowModal(true)
            closeModal()
        }
    }

    const updateCheckedPackages = values => {
        setSelectedPackages(values)
        setClusterInfo(prev => ({
            ...prev,
            packages: values,
        }))
    }

    const updateCheckedProviders = values => {
        setSelectedProviders(values)
        setClusterInfo(prev => ({
            ...prev,
            providers: values,
        }))
    }

    const handleSelectAll = () => {
        if (searchValue === '') {
            const allIds = packagesList?.map(product => product.id)
            setSelectedPackages(allIds)
            setClusterInfo(prev => ({
                ...prev,
                packages: allIds,
            }))
        } else {
            const searchedIds = packagesList.reduce((acc, product) => {
                if (
                    product.name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                ) {
                    acc.push(product.id)
                }
                return acc
            }, [])
            setSelectedPackages(searchedIds)
            setClusterInfo(prev => ({
                ...prev,
                packages: searchedIds,
            }))
        }
    }

    const handleSelectAllProviders = () => {
        if (searchValue === '') {
            const allIds = providersList?.map(provider => provider.id)
            setSelectedProviders(allIds)
            setClusterInfo(prev => ({
                ...prev,
                providers: allIds,
            }))
        } else {
            const searchedIds = providersList.reduce((acc, provider) => {
                if (
                    provider.display_name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                ) {
                    acc.push(provider.id)
                }
                return acc
            }, [])
            setSelectedProviders(searchedIds)
            setClusterInfo(prev => ({
                ...prev,
                providers: searchedIds,
            }))
        }
    }

    const handleUnselectAll = () => {
        setSelectedPackages([])
        setClusterInfo(prev => ({
            ...prev,
            packages: [],
        }))
    }

    const handleUnselectAllProviders = () => {
        setSelectedProviders([])
        setClusterInfo(prev => ({
            ...prev,
            providers: [],
        }))
    }

    const handleSearch = value => {
        setSearchValue(value)
    }
    const handleChange = e => {
        const { name, value } = e.target
        setClusterInfo(prev => ({ ...prev, [name]: value.trim() }))
    }

    const subscriptionPackages = packagesList?.filter(
        packages => packages.subscription_supported === true,
    )
    const leaseToOwnPackages = packagesList?.filter(
        packages => packages.lease_to_own_supported === true,
    )
    const packageListDropdown =
        selectedVariable?.toLowerCase() === 'subscription'
            ? subscriptionPackages
            : leaseToOwnPackages

    const formTitle =
        creditVariableType === 'existing_provider'
            ? 'Select Provider'
            : 'Set Cluster Details'
    const formSubTitle =
        creditVariableType === 'existing_provider'
            ? 'Please select a provider from the list below'
            : 'Please enter the details of the Cluster'
    return (
        <>
            <SeoComponent
                title="SunFi - Admin Credits System | Add Credit variable"
                tracker="AdminCreditsSystemTracker"
            />
            <CreditVariableModal
                showModal={showModal}
                setShowModal={setShowModal}
                closeVariableDetailsModal={closeVariableDetailsModal}
                closeContextModal={closeContextModal}
                selectedVariable={selectedVariable}
                provider={provider}
                clusterInfo={clusterInfo}
                type="addVariable"
                creditVariableType={creditVariableType}
            />
            <div className="ACVAddWrapper">
                <div className="ACVAddTopWrapper">
                    <span
                        className="AWCloseIconWrapper"
                        style={{ alignSelf: isMobile ? 'flex-start' : '' }}
                    >
                        <img
                            src={CloseIcon}
                            alt="close icon"
                            onClick={closeContextModal}
                            style={{ width: '27px' }}
                        />
                    </span>
                    <FormTitleBar
                        title={formTitle}
                        subtitle={formSubTitle}
                        titleFontSize="20px"
                        alignLeft={isMobile}
                        marginBottom={isMobile && '0'}
                    />
                </div>
                <div className="ACVAddContentWrapper">
                    <div
                        className="ACVAddInputMainWrapper"
                        style={{ marginTop: 32 }}
                    >
                        {creditVariableType === 'existing_provider' ? (
                            <SelectField
                                name="provider"
                                initialOption="Select Provider"
                                selectWidth="464px"
                                handleChange={handleSelectChange}
                                withCheckBox={true}
                                dropdownPositionRelative
                                selectedValue={provider}
                                currentSelected={provider}
                                values={providersList?.map(provider => ({
                                    ...provider,
                                    value: provider.display_name,
                                }))}
                                disabled={fetchingProviders}
                                errorMessage={errors?.provider}
                                selectedField="id"
                                value={provider}
                                filterOption={(input, option) => {
                                    const prov = providersList?.find(
                                        provider =>
                                            provider.id === option.value,
                                    )
                                    if (prov) {
                                        return prov?.display_name
                                            .toLowerCase()
                                            .includes(input)
                                    } else {
                                        return null
                                    }
                                }}
                                showSearch
                            />
                        ) : (
                            <div className="ACVMultiSelect">
                                <InputFields
                                    inputWidth="464px"
                                    title="Cluster Name"
                                    type="text"
                                    name="name"
                                    value={clusterInfo?.name}
                                    inputValue={clusterInfo?.name}
                                    handleChange={handleChange}
                                    errorMessage={errors?.name}
                                />

                                <TreeSelectAll
                                    values={packageListDropdown?.map(
                                        product => ({
                                            title: product.name,
                                            value: product.id,
                                        }),
                                    )}
                                    placeholder="Select Packages"
                                    floatingLabel="Select Packages"
                                    handleTreeSelectChange={
                                        updateCheckedPackages
                                    }
                                    disabled={fetchingSunFiPackages}
                                    setSelectedValues={setSelectedPackages}
                                    selectedValues={selectedPackages}
                                    handleSelectAll={handleSelectAll}
                                    handleUnselectAll={handleUnselectAll}
                                    handleSearch={handleSearch}
                                    selectText="Select All"
                                    unselectText="Unselect All"
                                    selectWidth="100%"
                                    dropDownWidth="390px"
                                    dropdownMatchSelectWidth={false}
                                    errorMessage={errors.packages}
                                    totalLength={packageListDropdown?.length}
                                    mobileWidth
                                />

                                {creditVariableType === 'new_dep_cluster' && (
                                    <TreeSelectAll
                                        values={providersList?.map(
                                            provider => ({
                                                title: provider.display_name,
                                                value: provider.id,
                                            }),
                                        )}
                                        placeholder="Select Providers"
                                        floatingLabel="Select Providers"
                                        handleTreeSelectChange={
                                            updateCheckedProviders
                                        }
                                        setSelectedValues={setSelectedProviders}
                                        selectedValues={selectedProviders}
                                        handleSelectAll={
                                            handleSelectAllProviders
                                        }
                                        handleUnselectAll={
                                            handleUnselectAllProviders
                                        }
                                        handleSearch={handleSearch}
                                        selectText="Select All"
                                        unselectText="Unselect All"
                                        selectWidth="100%"
                                        dropDownWidth="390px"
                                        dropdownMatchSelectWidth={false}
                                        errorMessage={errors.providers}
                                        totalLength={providersList?.length}
                                        mobileWidth
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div
                        className={
                            creditVariableType === 'existing_provider'
                                ? 'ACVAddButtonWrapper'
                                : 'ACVAddClusterButtonWrapper'
                        }
                    >
                        <Button
                            btnWidth="160px"
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnHeight="56px"
                            handleClick={
                                creditVariableType === 'existing_provider'
                                    ? handleSelectProvider
                                    : handleClusterSetupContinue
                            }
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCreditVariable
