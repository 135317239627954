import { LEARN_ROUTES } from 'src/routes/pathnames'

export const articles = [
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/introducing-the-new-consumer-smart-shopping-experience-V2-thumbnail.png',
        text: `Introducing a simpler way to shop for your solar systems`,
        date: 'May 12 , 2024',
        link: `${LEARN_ROUTES.learnContentThirteen}`,
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/remote_worker-thumbnail.png',
        text: `How solar has helped Adedamola Save Money:  A Remote Worker’s POV`,
        date: 'November 25, 2023',
        link: `${LEARN_ROUTES.learnContentTwelve}`,
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/debunk-thumbnail.png',
        text: 'Debunking the Top Solar Energy Myths',
        date: 'November 25, 2023',
        link: `${LEARN_ROUTES.learnContentEleven}`,
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/fuel__pump--sm117x117.png',
        text: 'Affordable Solar Energy: An Alternative To The Hike In Fuel Price ',
        date: 'August 25, 2023',
        link: `${LEARN_ROUTES.learnContentTen}`,
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/empower__customer--sm117x117.png',
        text: `Empower Your Customers With Sunfi's Financing Solutions`,
        date: 'August 25, 2023',
        link: `${LEARN_ROUTES.learnContentNine}`,
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/business__guide--sm117x117.png',
        text: `Solar Energy Made Simple: A Beginner's Guide to Going Solar`,
        date: 'August 25, 2023',
        link: `${LEARN_ROUTES.learnContentEight}`,
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/+Five-ways-clean-energy-benefits-Nigerian-businesses-small.png',
        text: 'Five ways clean energy benefits Nigerian businesses ',
        date: 'March 2, 2023',
        link: '/learn/five-ways-clean-energy-benefits-nigerian-businesses',
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/350+x+350+Thumbnail+1.png',
        text: '5 Reasons you should switch to solar in 2023',
        date: 'January 12, 2023',
        link: '/learn/5-reasons-why-you-should-switch-to-solar-in-2023',
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Payment+Plans+Are+The+Bottleneck+Of+Clean+Energy.svg',
        text: 'Payment Plans are the bottleneck of clean energy',
        date: 'November 8, 2021',
        link: '/learn/payment-plans-are-the-bottleneck-of-clean-energy',
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Predictions.svg',
        text: 'Predictions of the future with SunFi -  Launch',
        date: 'August 2, 2021',
        link: '/learn/predictions-of-the-future-with-SunFi-Launch',
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Solar+on+Every+Roof.svg',
        text: 'SunFi - We envision Solar on every roof',
        date: 'July 16, 2021',
        link: '/learn/we-envision-solar-on-every-roof',
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Virtual+Agent+Network+of+Associate+Installers.svg',
        text: 'A virtual agent network of Associate Installers will accelerate the transition',
        date: 'July 23, 2021',
        link: '/learn/a-virtual-agent-network-of-associate-installers-will-accelerate-the-transition',
    },
    {
        url: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Energy+Storage+in+Every+Home.svg',
        text: 'Energy Storage will be in every home and building',
        date: 'July 30, 2021',
        link: '/learn/energy-storage-will-be-in-every-home-and-building',
    },
]
