import { useMutation } from 'react-query'
import { updateProfile } from 'src/api/admin/profiles/manage-profile'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

export const useUpdateProfile = ({ data, onSuccess, showAlert }) => {
    const { errorAlert, successAlert } = useCustomToast()
    const { isMobile } = useMediaQueries()

    const { mutate: handleUpdateProfile, isLoading } = useMutation({
        mutationFn: () => updateProfile(data),
        onSuccess: () => {
            if (showAlert) {
                successAlert('Update successful!', isMobile ? 320 : 500)
            }

            if (onSuccess) {
                onSuccess()
            }
        },
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })
    return {
        handleUpdateProfile,
        isLoading,
    }
}
