export const getActionModalData = (name, brandType, componentBrands = []) => {
    switch (name) {
        case 'save-review': {
            return {
                headerText: 'Save review for this brand',
                subTitle: (
                    <>
                        <p>You&apos;re about to approve the following:</p>
                        <p>
                            {componentBrands.length
                                ? componentBrands
                                      ?.map(
                                          componentBrand => componentBrand.name,
                                      )
                                      ?.join(', ')
                                : ''}
                        </p>
                    </>
                ),
                actionType: 'warning',
                actionText: 'Approve',
                noCancelBtn: false,
            }
        }

        case 'approve-all-brands': {
            return {
                headerText: `Approve added ${brandType} brand?`,
                subTitle: (
                    <>
                        <p>You&apos;re about to approve the following:</p>
                        <p>
                            {componentBrands.length
                                ? componentBrands
                                      ?.map(
                                          componentBrand => componentBrand.name,
                                      )
                                      ?.join(', ')
                                : ''}
                        </p>
                    </>
                ),
                actionType: 'warning',
                actionText: 'Approve',
                noCancelBtn: false,
            }
        }

        case 'approve-brand': {
            return {
                headerText: `Approve added ${brandType} brand?`,
                subTitle: (
                    <>
                        <p>You&apos;re about to approve the following:</p>
                        <p>
                            {componentBrands.length
                                ? componentBrands
                                      ?.map(
                                          componentBrand => componentBrand.name,
                                      )
                                      ?.join(', ')
                                : ''}
                        </p>
                    </>
                ),
                actionType: 'warning',
                actionText: 'Approve',
                noCancelBtn: false,
            }
        }

        case 'decline-brand': {
            return {
                headerText: `Decline added ${brandType} brand?`,
                subTitle: (
                    <>
                        <p>You&apos;re about to decline the following:</p>
                        <p>
                            {componentBrands.length
                                ? componentBrands
                                      ?.map(
                                          componentBrand => componentBrand.name,
                                      )
                                      ?.join(', ')
                                : ''}
                        </p>
                    </>
                ),
                actionType: 'warning',
                actionText: 'Decline',
                noCancelBtn: false,
            }
        }

        case 'approve-success': {
            return {
                headerText: 'Brand approved successfully',
                subTitle: (
                    <>
                        <p>The added brand by the provider</p>
                        <p>has been successfully approved</p>
                    </>
                ),
                actionType: 'success',
                actionText: 'Okay, Got it',
                noCancelBtn: false,
            }
        }

        case 'decline-success': {
            return {
                headerText: 'Brand rejected successfully ',
                subTitle: (
                    <>
                        <p>The added brand by the provider</p>
                        <p>has been successfully rejected</p>
                    </>
                ),
                actionType: 'success',
                actionText: 'Okay, Got it',
                noCancelBtn: false,
            }
        }

        default: {
            return {
                headerText: '',
                subTitle: '',
                actionType: 'warning',
                noCancelBtn: true,
            }
        }
    }
}

export const findComponentBrandInArray = (componentBrands, compomnent) => {
    return !!componentBrands?.find(
        componentBrand => componentBrand.id === compomnent?.id,
    )
}
