import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import Formbox from '../../components/FormBox'
import FormTitleBar from '../../components/FormTitleBar'
import { InputFields } from '../../components/InputFields'
import { SelectCountryCode } from '../../components/InputFields/SelectCountryCode'
import Verification from '../../components/Verification'
import Confirmation from '../../components/Confirmation'
import { InlineLoader } from '../../components/Loader'
import ConfirmationImage from '../../assets/images/confirmationImage.svg'
import Toast from '../../components/Toast'
import Button from '../../components/Button'
import { resetPasswordInitiateApi } from '../../api/reset'
import {
    AIResetPhoneValidationSchema,
    DISIResetEmailValidationSchema,
} from '../../utils/validationSchema'

import styles from './forgotpassword.module.scss'
import { formatPhoneNumber } from '../../utils/formatting'
import { errorHandler } from '../../utils/errorHandler'
import FormBoxText from '../../components/FormBoxText'

import useErrorHandler from '../../utils/Hooks/useErrorHandler'

// eslint-disable-next-line react/prop-types
const ForgetPassword = ({ showTextHandler }) => {
    const { CreateAccount, FormText, LinkResend, ButtonWrapper } = styles
    const history = useHistory()
    const [type, setType] = useState('email')
    const [inputs, setInputs] = useState({
        email: '',
        phonenumber: '',
    })
    const [countDown, setCountDown] = useState(0)
    const [count, setCount] = useState(1)
    const [countryCode, setCountryCode] = useState('+234')
    const [verified, setVerified] = useState(false)
    const [viewErrorMessage, setViewErrorMessage] = useState(false)

    const [, setErrors] = useState({})
    const [validationError, setValidationError] = useState({})
    const [verifyUser, setVerifyUser] = useState(false)
    const [, setErrorMsg] = useState('')

    const [errs, setErrs] = useState(null)

    const handleChange = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleBlur = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value.trim() }))
    }

    const clearInputValue = () => {
        setInputs({
            email: '',
            phonenumber: '',
        })
    }

    const handleFormSubmit = () => {
        if (type === 'phone') {
            AIResetPhoneValidationSchema.validate(inputs, { abortEarly: false })
                .then(() => {
                    setValidationError('')
                    refetch()
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setValidationError(errList)
                })
        } else {
            DISIResetEmailValidationSchema.validate(inputs, {
                abortEarly: false,
            })
                .then(() => {
                    setValidationError('')
                    refetch()
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setValidationError(errList)
                })
        }
    }

    const handleSelectChange = value => {
        setCountryCode(value)
    }

    useEffect(() => {
        const handleTimer = () => {
            if (countDown > 0) {
                setCountDown(prev => prev - 1)
            }
        }
        const reduceTimer = setTimeout(handleTimer, 1000)
        return () => clearTimeout(reduceTimer)
    }, [countDown])

    let { isLoading, data, error, refetch } = useQuery(
        'verifyPhone',
        () => {
            return resetPasswordInitiateApi(
                type,
                type === 'email'
                    ? {
                          email: inputs.email,
                      }
                    : {
                          phone_number: formatPhoneNumber(
                              inputs.phonenumber,
                              countryCode,
                          ),
                      },
            )
        },
        {
            enabled: false,
            retry: false,
            onError: error => {
                setErrs(error)
                setViewErrorMessage(true)
            },
        },
    )

    useEffect(() => {
        if (data) {
            setVerified(true)
        }
        if (data?.data?.data?.action === 'PHONE_VERIFICATION') {
            setVerifyUser(true)
            setVerified(true)
        }
    }, [data])

    //resend verification link
    const {
        isLoading: resendLoading,
        error: resendError,
        refetch: resendRefetch,
    } = useQuery(
        'resendLink',
        () =>
            resetPasswordInitiateApi(
                type,
                type === 'email'
                    ? {
                          email: inputs.email,
                      }
                    : {
                          phone_number: formatPhoneNumber(
                              inputs.phonenumber,
                              countryCode,
                          ),
                      },
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => setCountDown(60 * count),
        },
    )

    const handleResendEmail = () => {
        resendRefetch()
        setCount(prev => prev + 1)
    }

    const hideEmail = email => {
        let newEmail = ''
        for (let i = 0; i < email.length; i++) {
            if (i > 3 && i < 15) newEmail += '*'
            else newEmail += email[i]
        }
        return newEmail
    }

    useEffect(() => {
        if (error && viewErrorMessage) {
            if (error?.response?.data?.message) {
                setViewErrorMessage(true)
                setErrorMsg(error?.response?.data?.message)
            } else {
                const errObj = error?.response?.data
                setViewErrorMessage(true)
                setErrors(errorHandler(errObj))
            }
        }

        // eslint-disable-next-line
    }, [error])

    const returnToLogin = () => {
        setViewErrorMessage(false)
        history.push('/login')
    }

    const handleChangePhone = () => {
        setVerified(false)
    }

    const errorValue = useErrorHandler(errs)

    const handleFormChange = () => {
        setType(type === 'phone' ? 'email' : 'phone')
        setVerified(false)
        clearInputValue()
        setValidationError('')
        setErrs()
        setViewErrorMessage(false)
    }

    return (
        <>
            {verified ? (
                type === 'phone' ? (
                    <Verification
                        fullPhoneNumber={formatPhoneNumber(
                            inputs.phonenumber,
                            countryCode,
                        )}
                        phoneNumber={inputs?.phonenumber}
                        countDown={countDown}
                        setCountDown={setCountDown}
                        resendOtp={resetPasswordInitiateApi}
                        processType={verifyUser ? '' : 'forgotPassword'}
                        identifier={formatPhoneNumber(
                            inputs.phonenumber,
                            countryCode,
                        )}
                        showTextHandler={
                            verifyUser ? showTextHandler : () => {}
                        }
                        handleChangePhone={handleChangePhone}
                    />
                ) : (
                    <Confirmation
                        imgUrl={ConfirmationImage}
                        header="Confirmation Link Sent"
                        subHeader={`We sent a confirmation link to your email address ${hideEmail(
                            inputs?.email,
                        )}                                                                                                                                                                                                                                               `}
                        btnText="Open Email App"
                        mailTo
                    >
                        <div className={LinkResend}>
                            <p style={{ marginBottom: '0px' }}>
                                You didn’t get the link?
                            </p>
                            <p>
                                {countDown < 1 ? (
                                    resendError ? (
                                        <p
                                            style={{
                                                textAlign: 'center',
                                                color: '#DA1E28',
                                            }}
                                        >
                                            {
                                                resendError?.response?.data
                                                    ?.error?.email[0]
                                            }
                                        </p>
                                    ) : (
                                        <span
                                            style={{
                                                color: '#004AAD',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleResendEmail}
                                            disabled={resendLoading}
                                        >
                                            Resend Link
                                        </span>
                                    )
                                ) : (
                                    `Resend Link (${
                                        countDown > 60
                                            ? `${Math.floor(countDown / 60)}m ${
                                                  countDown % 60 > 0
                                                      ? `${countDown % 60}s`
                                                      : ''
                                              }`
                                            : `${countDown}s`
                                    })`
                                )}
                            </p>
                        </div>
                    </Confirmation>
                )
            ) : (
                <Formbox>
                    <FormTitleBar
                        title="Forgot Password"
                        subtitle={`${
                            type === 'phone'
                                ? 'Enter Your Phone Number'
                                : 'Enter Your Email Address'
                        }`}
                    />
                    {viewErrorMessage && (
                        <Toast
                            messageType="error"
                            message={
                                isLoading
                                    ? ''
                                    : errorValue['msg']
                                    ? errorValue['msg']
                                    : ''
                            }
                        />
                    )}
                    <div style={{ marginTop: '40px' }}>
                        <div className={FormText}>
                            <FormBoxText
                                textOne="Switch to Email"
                                textTwo="Switch to Phone"
                                type={type}
                                onClick={handleFormChange}
                            />
                        </div>
                        {type === 'email' && (
                            <InputFields
                                title="Email Address"
                                type="text"
                                name="email"
                                value={inputs.email}
                                handleChange={handleChange}
                                onBlur={handleBlur}
                                errorMessage={validationError.email}
                            />
                        )}
                        {type === 'phone' && (
                            <SelectCountryCode
                                type="phone"
                                title="Phone Number"
                                name="phonenumber"
                                inputValue={inputs.phonenumber}
                                handleChange={handleChange}
                                onBlur={handleBlur}
                                handleSelectChange={handleSelectChange}
                                errorMessage={validationError.phonenumber}
                            />
                        )}
                    </div>
                    <br />
                    <div className={ButtonWrapper}>
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            handleClick={handleFormSubmit}
                            disabled={
                                type === 'email'
                                    ? inputs.email === ''
                                    : inputs.phonenumber === ''
                            }
                        >
                            {isLoading ? <InlineLoader /> : 'Reset Password'}
                        </Button>
                    </div>
                    <div className={CreateAccount}>
                        <span onClick={() => returnToLogin()}>
                            Return to Sign In
                        </span>
                    </div>
                </Formbox>
            )}

            <></>
        </>
    )
}

export default ForgetPassword
