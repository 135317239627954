import PropTypes from 'prop-types'
import styles from './selectbuttons.module.scss'

export const SelectButtons = ({
    values,
    selectedValue,
    handleChange,
    margin,
    width,
    height,
    disabled,
}) => {
    return (
        <div className={styles.wrapper} style={{ margin }}>
            {values?.map((value, i) => (
                <div
                    key={i}
                    style={{
                        background:
                            value === selectedValue ? '#011A3C' : '#FFF',
                        color: value === selectedValue ? '#FFF' : '#011A3C',
                        width: width,
                        height: height,
                        cursor: disabled ? 'not-allowed' : 'pointer',
                    }}
                    className={styles.btn}
                    role="button"
                    onClick={disabled ? () => {} : () => handleChange(value)}
                    aria-disabled={disabled}
                    data-testid={`select-button-${value}`}
                >
                    {value}
                </div>
            ))}
        </div>
    )
}

export const MultiSelectButtons = ({
    values,
    selectedValues,
    handleChange,
    margin,
    width,
    flexWrap,
}) => {
    return (
        <div
            className={styles.wrapper}
            style={{ margin, flexWrap: flexWrap && flexWrap }}
        >
            {values?.map((value, i) => (
                <div
                    key={i}
                    style={{
                        background: selectedValues?.includes(value)
                            ? '#011A3C'
                            : '#FFF',
                        color: selectedValues?.includes(value)
                            ? '#FFF'
                            : '#011A3C',
                        width: width && width,
                    }}
                    className={styles.btn}
                    role="button"
                    onClick={() => handleChange(value)}
                >
                    {value}
                </div>
            ))}
        </div>
    )
}

const sharedPropTypes = {
    values: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    margin: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
}

SelectButtons.propTypes = {
    ...sharedPropTypes,
    selectedValue: PropTypes.string.isRequired,
}

MultiSelectButtons.propTypes = {
    ...sharedPropTypes,
    selectedValues: PropTypes.array.isRequired,
}
