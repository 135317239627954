/* eslint-disable react/prop-types */
import parse from 'html-react-parser'
import styles from './energysolution.module.scss'

const EnergySolution = ({ onClose, energySolutionData, BtnMarginBottom }) => {
    const {
        TopRectangle,
        Title,
        Subtitle,
        BottomRectangleTitle,
        BtnWrapper,
        OkayBtn,
        DescriptionWrapper,
        DescriptionText,
    } = styles

    return (
        <>
            <div className={TopRectangle}>
                <p className={Title}>Package Name</p>
                <p className={Subtitle}>
                    {energySolutionData?.recommended_solution?.package_name}
                </p>
            </div>

            <div className={DescriptionWrapper}>
                <p className={BottomRectangleTitle}>PACKAGE DESCRIPTION</p>
                <p className={DescriptionText}>
                    {parse(
                        `${energySolutionData?.recommended_solution?.package_description}`,
                    )}
                </p>
            </div>

            <div className={BtnWrapper}>
                <button
                    className={OkayBtn}
                    style={{ marginBottom: BtnMarginBottom || '' }}
                    onClick={onClose}
                >
                    Okay, Got it
                </button>
            </div>
        </>
    )
}

export default EnergySolution
