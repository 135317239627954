import style from './style.module.scss'
import { ReactComponent as InfoIcon } from 'src/assets/images/Info-warning-blue.svg'
import { ReactComponent as CopyIcon } from 'src/assets/images/copy2-icon.svg'
import PropType from 'prop-types'

const BankDetailsContent = ({ successAlert, totalDuePaymentAmount }) => {
    const handleCopy = text => {
        navigator.clipboard.writeText(text)
        successAlert('Account number copied')
    }
    return (
        <div className={style.contentContainer}>
            <p className={style.heading}>Make bank transfer</p>
            <p className={style.subHeading}>
                To complete your transaction, please make a bank transfer to the
                account details listed below.{' '}
            </p>
            <div className={style.detailsContainer}>
                <p>Transfer NGN{totalDuePaymentAmount} to</p>
                <div>
                    <p>Account number</p>
                    <p className={style.accountNumber}>
                        9381934289
                        <CopyIcon onClick={() => handleCopy('9381934289')} />
                    </p>
                </div>
                <div>
                    <p>Bank name</p>
                    <p>Eco bank</p>
                </div>
                <div>
                    <p> Account Name</p>
                    <p>SunFi Cluster</p>
                </div>
            </div>
            <div className={style.info}>
                <InfoIcon />
                <p>
                    {' '}
                    Please ensure you confirm here once you have completed the
                    bank transfer
                </p>
            </div>
        </div>
    )
}

export default BankDetailsContent

BankDetailsContent.propTypes = {
    successAlert: PropType.func,
    totalDuePaymentAmount: PropType.string,
}
