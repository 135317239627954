/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-undef */
import axios from 'axios'
import { getToken, logsOutUser } from 'src/utils/auth'
import { getClientLocator } from 'src/utils/user-tracking'
import { getCookie } from 'src/utils/cookie'
import Hashes from 'jshashes'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

const exception401Urls = [
    '/consumers/offer/third-party',
    '/auth/phone-login',
    '/auth/email-login',
    '/auth/consumers/phone',
    '/auth/providers/complete-signup',
    '/consumers/offer',
    '/consumers/offer/retrieve',
    '/consumers​/information​/validate',
    '/sse-auth/login/initiate',
    '/consumers/estimations/offer',
    '/sse-auth/validate-existing-user',
    '/solar-savings/run-calculator',
    '/solar-savings/send-detailed-report',
    '/url-shortener/create',
]

const exception404Urls = [
    '/consumers/offer/third-party',
    '/auth/phone-login',
    '/auth/email-login',
    '/auth/consumers/phone',
    '/auth/providers/complete-signup',
    '/users/activities',
    '/estimations/analytics',
    '/products/overview',
    '/products​/packages​/import',
    '/auth/consumers/email-verification/initiate',
    '/auth/consumers/phone-verification/initiate',
    '/consumers/offer',
    '/consumers/offer/retrieve',
    '/consumers​/information​/validate',
    '/payments/banks/resolve-name',
    '/payments/fetch-card-detail',
    '/payments/initialize-payment',
    '/payments/verify-deposit',
    '/sse-auth/login/initiate',
    '/payments/cancel',
    '/solar-savings/run-calculator',
    '/solar-savings/send-detailed-report',
    '/provider/payments/initialize-payment',
    '/admin/energy-profiles',
    '/url-shortener/create',
]

const exception403Urls = [
    '/consumers/offer/third-party',
    '/auth/phone-login',
    '/auth/email-login',
    '/auth/consumers/phone',
    '/auth/providers/complete-signup',
    '/create-account',
    '/consumers/offer',
    '/consumers/offer/retrieve',
    '/consumers​/information​/validate',
    '/sse-auth/login/initiate',
    '/consumers/kyc/verify-business',
    '/payments/initialize-payment',
    '/payments/verify-deposit',
    '/payments/cancel',
    '/solar-savings/run-calculator',
    '/solar-savings/send-detailed-report',
    '/provider/payments/initialize-payment',
    '/url-shortener/create',
]

const routesWithoutAuthorization = [
    '/sse-auth/validate-existing-user',
    '/sse-auth/validate-ab-testing-consumer',
    '/solar-savings/run-calculator',
    '/sse-consumers/drop-off',
    '/power-curve/feedback',
    '/solar-savings/feedback',
    '/url-shortener/create',
]

const promoPackageDetailsApiRegex = /\/sse-packages\/([^/]+)\/clusters\/([^/]+)/

const isMaintenanceMode =
    process.env.REACT_APP_REDIRECT_TO_MAINTENANCE_PAGE === 'true'

instance.interceptors.request.use(
    async config => {
        const token = getToken()
        const thirdParty = config?.headers?.thirdParty
        const needsAuthentication = routesWithoutAuthorization.includes(
            config.url,
        )
            ? false
            : promoPackageDetailsApiRegex.test(config.url)
            ? false
            : true
        if (thirdParty === 'AWS' || thirdParty === 'AZURE') {
            return config
        } else if (token && needsAuthentication) {
            // add token to header
            // sample code: config.headers['header name'] = `expected token header format e.g Bearer ${token}`
            config.headers['Authorization'] = `Bearer ${token}`
        }
        const allowCookie = getCookie('allow-cookie')

        if (allowCookie === 'true') {
            const locator = await getClientLocator(true)
            config.headers['User-IP_Address'] = locator?.ipAddress
        }

        const SHA512 = new Hashes.SHA512()
        const timestamp = Date.now().toString()
        const plainText = config.baseURL + config.url + timestamp

        const hash_data = SHA512.hex_hmac(
            process.env.REACT_APP_SUNFI_APP_TOKEN,
            plainText,
        )

        if (isMaintenanceMode) {
            const locator = await getClientLocator(false)
            const hash_address = SHA512.hex_hmac(
                process.env.REACT_APP_SUNFI_APP_TOKEN,
                locator?.ipAddress,
            )
            config.headers['x-astronaut-address'] = hash_address
        }

        config.headers['x-sunfi-signature'] = hash_data
        config.headers['x-sunfi-timestamp'] = timestamp

        return config
    },
    error => Promise.reject(error),
)

instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error?.response?.status === 403) {
            if (isMaintenanceMode) {
                logsOutUser()
            } else {
                const regexBVNURL =
                    /^\/admin\/estimations\/mini\/([A-Za-z\d]*)\/bvn$/
                const regexBankStatementURL =
                    /^\/admin\/estimations\/mini\/([A-Za-z\d]*)\/bank-statement$/
                if (
                    exception403Urls.includes(error?.response?.config?.url) ||
                    regexBVNURL.test(error?.response?.config?.url) ||
                    regexBankStatementURL.test(error?.response?.config?.url)
                ) {
                    return Promise.reject(error)
                } else {
                    window.location.href = '/app/403-page'
                }
            }
        }
        if (error?.response?.status === 401) {
            if (exception401Urls.includes(error?.response?.config?.url)) {
                return Promise.reject(error)
            } else {
                const exceptionUrls = ['/app/403-page', '/app/not-found']
                const lastPage =
                    window.location.pathname + window.location.search

                logsOutUser(exceptionUrls.includes(lastPage) ? '' : lastPage)
            }
        }

        if (error?.response?.status === 404) {
            let staticUrl = error?.response?.config?.url
            let dynamicUrl = staticUrl?.split('?')?.[0]
            if (
                exception404Urls.includes(staticUrl) ||
                exception404Urls.includes(dynamicUrl)
            ) {
                return Promise.reject(error)
            } else {
                if (
                    error?.response?.config?.url?.substring(0, 40) ===
                        '/estimations/mini/consumer-conversation/' ||
                    localStorage.getItem('isSSEConsumer') === 'true'
                ) {
                    window.location.href = '/not-found'
                } else {
                    window.location.href = '/app/not-found'
                }
            }
        }

        return Promise.reject(error)
    },
)

export default instance
