export const creditVariablesData = [
    {
        id: 1,
        name: 'Koolbooks Fridge',
        details: [
            { label: 'Payment Model', value: 'Lease to Own' },
            { label: 'Debt Cost', value: '3%' },
            { label: 'Insurance Rate', value: '28%' },
            { label: 'Minimum Upfront Deposit', value: '45%' },
            { label: 'Debt to Equity in Project', value: '39%' },
            { label: 'Lowest Tenure', value: '6 months' },
            { label: 'Monthly Rate Jump', value: '10%' },
            { label: 'Start Declining Balance Rate', value: '3%' },
            { label: 'Payment Plans', value: '3' },
            { label: 'Cost of Capital', value: '3%' },
            { label: 'Re-Investment Rate', value: '3%' },
            { label: 'Number of Payment Plans', value: '3,6,12' },
            { label: 'Tenure Increment', value: '3%' },
            { label: 'Debt Months', value: '4 months' },
        ],
    },
    {
        id: 2,
        name: 'Silverstar Equipment',
        details: [
            { label: 'Payment Model', value: 'Lease to Own' },
            { label: 'Debt Cost', value: '2%' },
            { label: 'Insurance Rate', value: '25%' },
            { label: 'Minimum Upfront Deposit', value: '50%' },
            { label: 'Debt to Equity in Project', value: '45%' },
            { label: 'Lowest Tenure', value: '12 months' },
            { label: 'Monthly Rate Jump', value: '12%' },
            { label: 'Start Declining Balance Rate', value: '2.5%' },
            { label: 'Payment Plans', value: '2' },
            { label: 'Cost of Capital', value: '4%' },
            { label: 'Re-Investment Rate', value: '4%' },
            { label: 'Number of Payment Plans', value: '6,12' },
            { label: 'Tenure Increment', value: '4%' },
            { label: 'Debt Months', value: '6 months' },
        ],
    },
    {
        id: 3,
        name: 'GreenTech Solar',
        details: [
            { label: 'Payment Model', value: 'Lease to Own' },
            { label: 'Debt Cost', value: '4%' },
            { label: 'Insurance Rate', value: '22%' },
            { label: 'Minimum Upfront Deposit', value: '40%' },
            { label: 'Debt to Equity in Project', value: '35%' },
            { label: 'Lowest Tenure', value: '3 months' },
            { label: 'Monthly Rate Jump', value: '8%' },
            { label: 'Start Declining Balance Rate', value: '1.5%' },
            { label: 'Payment Plans', value: '5' },
            { label: 'Cost of Capital', value: '2.5%' },
            { label: 'Re-Investment Rate', value: '5%' },
            { label: 'Number of Payment Plans', value: '3,6' },
            { label: 'Tenure Increment', value: '2%' },
            { label: 'Debt Months', value: '3 months' },
        ],
    },
]

export const creditVariablesInputData = [
    {
        id: 1,
        name: 'Koolbooks Fridge',
        details: [
            {
                label: 'Payment Model',
                value: 'Lease to Own',
                name: 'paymentModel',
            },
            { label: 'Debt Cost (%)', value: 3, name: 'debtCost' },
            { label: 'Insurance Rate (%)', value: 28, name: 'insuranceRate' },
            {
                label: 'Minimum Upfront Deposit (%)',
                value: 45,
                name: 'minimumUpfrontDeposit',
            },
            {
                label: 'Debt to Equity in Project (%)',
                value: 39,
                name: 'debtToEquityInProject',
            },
            {
                label: 'Lowest Tenure (Months)',
                value: 6,
                name: 'lowestTenure',
            },
            {
                label: 'Monthly Rate Jump (%)',
                value: 10,
                name: 'monthlyRateJump',
            },
            {
                label: 'Start Declining Balance Rate (%)',
                value: 3,
                name: 'startDecliningBalanceRate',
            },
            { label: 'Payment Plans', value: 3, name: 'paymentPlans' },
            { label: 'Cost of Capital (%)', value: 3, name: 'costOfCapital' },
            {
                label: 'Re-Investment Rate (%)',
                value: 3,
                name: 'reInvestmentRate',
            },
            {
                label: 'Number of Payment Plans',
                value: '3,6,12',
                name: 'numberOfPaymentPlans',
            },
            {
                label: 'Tenure Increment (%)',
                value: 3,
                name: 'tenureIncrement',
            },
            { label: 'Debt Months (Months)', value: 4, name: 'debtMonths' },
        ],
    },
    {
        id: 2,
        name: 'Silverstar Equipment',
        details: [
            {
                label: 'Payment Model',
                value: 'Lease to Own',
                name: 'paymentModel',
            },
            { label: 'Debt Cost (%)', value: 2, name: 'debtCost' },
            { label: 'Insurance Rate (%)', value: 25, name: 'insuranceRate' },
            {
                label: 'Minimum Upfront Deposit (%)',
                value: 50,
                name: 'minimumUpfrontDeposit',
            },
            {
                label: 'Debt to Equity in Project (%)',
                value: 45,
                name: 'debtToEquityInProject',
            },
            {
                label: 'Lowest Tenure (Months)',
                value: 12,
                name: 'lowestTenure',
            },
            {
                label: 'Monthly Rate Jump (%)',
                value: 12,
                name: 'monthlyRateJump',
            },
            {
                label: 'Start Declining Balance Rate (%)',
                value: 2.5,
                name: 'startDecliningBalanceRate',
            },
            { label: 'Payment Plans', value: 2, name: 'paymentPlans' },
            { label: 'Cost of Capital (%)', value: 4, name: 'costOfCapital' },
            {
                label: 'Re-Investment Rate (%)',
                value: 4,
                name: 'reInvestmentRate',
            },
            {
                label: 'Number of Payment Plans',
                value: '6,12',
                name: 'numberOfPaymentPlans',
            },
            {
                label: 'Tenure Increment (%)',
                value: 4,
                name: 'tenureIncrement',
            },
            { label: 'Debt Months (Months)', value: 6, name: 'debtMonths' },
        ],
    },
    {
        id: 3,
        name: 'GreenTech Solar',
        details: [
            {
                label: 'Payment Model',
                value: 'Lease to Own',
                name: 'paymentModel',
            },
            { label: 'Debt Cost (%)', value: 4, name: 'debtCost' },
            { label: 'Insurance Rate (%)', value: 22, name: 'insuranceRate' },
            {
                label: 'Minimum Upfront Deposit (%)',
                value: 40,
                name: 'minimumUpfrontDeposit',
            },
            {
                label: 'Debt to Equity in Project (%)',
                value: 35,
                name: 'debtToEquityInProject',
            },
            {
                label: 'Lowest Tenure (Months)',
                value: 3,
                name: 'lowestTenure',
            },
            {
                label: 'Monthly Rate Jump (%)',
                value: 8,
                name: 'monthlyRateJump',
            },
            {
                label: 'Start Declining Balance Rate (%)',
                value: 1.5,
                name: 'startDecliningBalanceRate',
            },
            { label: 'Payment Plans', value: 5, name: 'paymentPlans' },
            {
                label: 'Cost of Capital (%)',
                value: 2.5,
                name: 'costOfCapital',
            },
            {
                label: 'Re-Investment Rate (%)',
                value: 5,
                name: 'reInvestmentRate',
            },
            {
                label: 'Number of Payment Plans',
                value: '3,6',
                name: 'numberOfPaymentPlans',
            },
            {
                label: 'Tenure Increment (%)',
                value: 2,
                name: 'tenureIncrement',
            },
            { label: 'Debt Months (Months)', value: 3, name: 'debtMonths' },
        ],
    },
]
