import axios from '../../config/axios'

export const getAdminOview = async () => {
    const request = axios.get('/admin/overview')
    return request
}

export const getAdminAnalytics = async survey_type => {
    const request = axios.get(`/admin/analytics?survey_type=${survey_type}`)
    return request
}

export const getAdminUserActivities = async () => {
    const request = axios.get(
        '/admin/users/activities?limit=10&categories=ESTIMATION',
    )
    return request
}
