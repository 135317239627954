import BackNav from 'src/components/BackNav'
import Button from 'src/components/Button'
import EmptyState from 'src/components/EmptyState'
import { InlineLoader } from 'src/components/Loader'
import SeoComponent from 'src/components/Seo'
import PackageCard from './components/PackageCard'
import AddPackageDrawer from './components/AddPackageDrawer'
import { useAddPackage } from './hooks/useAddPackage'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

import './addpackage.scss'

const AddPackage = () => {
    const {
        showAddPackageModal,
        setShowAddPackageModal,
        toggleAddPackageModal,
        selectedPackages,
        setSelectedPackages,
        profileData,
        goBackNav,
        handleConfirmPackages,
        isLoading,
        parsedCapacities,
    } = useAddPackage()

    const { isMobile } = useMediaQueries()

    return (
        <>
            <SeoComponent title="SunFi | Admin | Consumer Profile Packages" />

            <div className="AddPackageContainer">
                <BackNav title="Go back" onClick={goBackNav} />
                <div className="AddPackageTitleWrapper">
                    <p className="AddPackageTitle">
                        Recommendations for{' '}
                        <span>{profileData?.name || ''}</span>
                    </p>
                    <Button
                        btnTextColor="var(--white)"
                        btnBgColor="var(--blue)"
                        btnWidth={isMobile ? '40px' : '168px'}
                        btnHeight={isMobile ? '40px' : '44px'}
                        fontSize={isMobile ? '20px' : '14px'}
                        handleClick={toggleAddPackageModal}
                    >
                        {isMobile ? '+' : 'Add Packages'}
                    </Button>
                </div>
                {selectedPackages.length ? (
                    <div className="AddPackageCardGridWrapper">
                        <div className="AddPackageCardGrid">
                            {selectedPackages.map((pkg, index) => (
                                <PackageCard
                                    key={index}
                                    packageData={pkg}
                                    setSelectedPackages={setSelectedPackages}
                                    profileName={profileData?.name}
                                />
                            ))}
                        </div>
                        <div className="AddPackageButtonWrapper">
                            <p>
                                You have added {selectedPackages.length}{' '}
                                packages to this consumer profile
                            </p>
                            <Button
                                btnTextColor="var(--white)"
                                btnBgColor="var(--blue)"
                                btnWidth="136px"
                                btnHeight="32px"
                                handleClick={handleConfirmPackages}
                            >
                                {isLoading ? (
                                    <InlineLoader />
                                ) : (
                                    'Confirm Packages'
                                )}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="AddPackageEmptyStateWrapper">
                        <EmptyState
                            type="noActivity"
                            title="Add a package to this consumer profile"
                            subTitle="You've not added any package to this new consumer profile."
                        />
                    </div>
                )}
            </div>
            <AddPackageDrawer
                showAddPackageModal={showAddPackageModal}
                setShowAddPackageModal={setShowAddPackageModal}
                selectedPackages={selectedPackages}
                setSelectedPackages={setSelectedPackages}
                minCapacity={parsedCapacities.minCapacity}
                maxCapacity={parsedCapacities.maxCapacity}
                profileName={profileData?.name}
            />
        </>
    )
}

export default AddPackage
