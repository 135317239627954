import { useEffect } from 'react'
import React from 'react'

//Zoho Sales Iq Script:
const useScript = (url, widgetCode) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.setAttribute('type', 'text/javascript')

        let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`

        script.appendChild(document.createTextNode(code))
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [url, widgetCode])
}

const ZohoChat = () => {
    return (
        <React.Fragment>
            {useScript(
                'https://salesiq.zoho.com/widget',
                process.env.REACT_APP_ZOHO_TOKEN,
            )}
        </React.Fragment>
    )
}

export default function CustomerChat() {
    return (
        <React.Fragment>
            {process.env.REACT_APP_ZOHO_CHAT_ENABLED === 'true' && <ZohoChat />}
        </React.Fragment>
    )
}
