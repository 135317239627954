import { useEffect } from 'react'

const useScript = (w, d, s) => {
    useEffect(() => {
        var a = d.getElementsByTagName('head')[0]
        var r = d.createElement('script')
        r.async = 1
        r.src = s
        r.setAttribute('id', 'usetifulScript')
        r.dataset.token = process.env.REACT_APP_USETIFUL_TOKEN
        a.appendChild(r)
    }, [d, s])
}

const UsetifulTour = () => {
    return (
        <>
            {useScript(
                window,
                document,
                'https://www.usetiful.com/dist/usetiful.js',
            )}
        </>
    )
}

export default function CustomerTour() {
    return (
        <>
            {process.env.REACT_APP_SHOW_USETIFUL_TOUR === 'true' && (
                <UsetifulTour />
            )}
        </>
    )
}
