import { filterOptions } from '../data'
import PropTypes from 'prop-types'
import styles from '../solarsystemslistingsv2.module.scss'
import '../sharedform.scss'
import { MultiSelectButtons, SelectButtons } from 'src/components/SelectButtons'
import { Skeleton, Slider } from 'antd'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { useSolarSystemsContext } from '../hooks/useSolarSystems'
import { useEffect, useState } from 'react'
import { showClearFilter } from '../utils'
import { appTracking } from 'src/utils/appTracker'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'
import {
    addQueryParamsToUrl,
    removeAllQueryParamsFromUrl,
} from 'src/utils/urlTracker'

const Filter = ({ toggleModal }) => {
    const {
        FilterWrapper,
        FilterTitleWrapper,
        FilterOptionsWrapper,
        FilterOptionsChildWrapper,
        FilterCardWrapper,
    } = styles

    const pageTitle =
        'Solar systems | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = `SOLAR_SYSTEM_LISTING`
    const isAllSystems = window.location.pathname.includes('/all-systems')

    const {
        filterInputs,
        setFilterInputs,
        currentPage,
        setCurrentPage,
        getEnergyProfileSolutionsFilterData,
        getEnergyProfileSolutionsFilterFetching,
        clearFilters,
        getEnergyProfileData,
        isClosedCluster,
    } = useSolarSystemsContext()
    const isResidential =
        getEnergyProfileData?.data?.data?.customer_type == 'RESIDENTIAL'

    const [pseudoInputs, setPseudoInputs] = useState({ ...filterInputs })

    useEffect(() => {
        setPseudoInputs({ ...filterInputs })
    }, [filterInputs])

    const toggleSingleSelection = (name, value) => {
        if (currentPage > 1) {
            setCurrentPage(1)
        }
        setFilterInputs(prev => ({
            ...prev,
            [`${name}`]: filterInputs[name] === value ? '' : value,
        }))
        addQueryParamsToUrl({
            [`${name}`]: filterInputs[name] === value ? '' : value,
        })
        if (filterInputs[name] === value) {
            removeAllQueryParamsFromUrl([`${name}`])
        }
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            consumerProfileEvents(
                isAllSystems ? 'AASP' : !isResidential ? 'BP' : 'RP',
            )['useFilter'].action,
            consumerProfileEvents(
                isAllSystems ? 'AASP' : !isResidential ? 'BP' : 'RP',
            ).label,
            consumerProfileEvents(
                isAllSystems ? 'AASP' : !isResidential ? 'BP' : 'RP',
            ).category,
            ['MP', 'GA'],
            'event',
        )
    }

    const toggleMultiSelection = (filterType, value) => {
        if (currentPage > 1) {
            setCurrentPage(1)
        }
        if (filterInputs[filterType]?.includes(value)) {
            setFilterInputs(prev => ({
                ...prev,
                [filterType]: prev[filterType].filter(each => each !== value),
            }))
            addQueryParamsToUrl({
                [filterType]: [filterType].filter(each => each !== value),
            })
        } else {
            setFilterInputs(prev => {
                return {
                    ...prev,
                    [filterType]:
                        prev[filterType] === undefined
                            ? [value]
                            : [...prev[filterType], value],
                }
            })
        }
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            consumerProfileEvents(
                isAllSystems ? 'AASP' : !isResidential ? 'BP' : 'RP',
            )['useFilter'].action,
            consumerProfileEvents(
                isAllSystems ? 'AASP' : !isResidential ? 'BP' : 'RP',
            ).label,
            consumerProfileEvents(
                isAllSystems ? 'AASP' : !isResidential ? 'BP' : 'RP',
            ).category,
            ['MP', 'GA'],
            'event',
        )
    }

    return (
        <section className={FilterWrapper}>
            {getEnergyProfileSolutionsFilterFetching ? (
                <Skeleton
                    active
                    loading
                    paragraph={{ rows: 12, width: '100%' }}
                />
            ) : (
                <div className={FilterWrapper}>
                    {!isClosedCluster && (
                        <div className={FilterCardWrapper}>
                            <h4>Can&apos;t find what works for you?</h4>
                            <p>
                                Customise your own system by providing your
                                budget, location, what you want to power, etc
                            </p>
                            <button onClick={toggleModal}>
                                Build your own system
                            </button>
                        </div>
                    )}

                    {filterOptions(
                        getEnergyProfileSolutionsFilterData?.data?.data,
                    )?.filter(item => item?.isActive)?.length ? (
                        <div className={FilterTitleWrapper}>
                            <h2>Filter</h2>
                            {showClearFilter(
                                filterInputs,
                                getEnergyProfileSolutionsFilterData?.data?.data,
                            ) && (
                                <button
                                    onClick={() => {
                                        clearFilters(
                                            getEnergyProfileSolutionsFilterData
                                                ?.data?.data,
                                        )
                                        removeAllQueryParamsFromUrl()
                                    }}
                                >
                                    Clear filter
                                </button>
                            )}
                        </div>
                    ) : null}
                    {filterOptions(
                        getEnergyProfileSolutionsFilterData?.data?.data,
                    ).map((item, index) => {
                        return (
                            <>
                                {item.isActive ? (
                                    <div
                                        key={index}
                                        className={FilterOptionsWrapper}
                                    >
                                        <h3>{item.title}</h3>
                                        <div
                                            className={
                                                ['radio', 'checkbox'].includes(
                                                    item.type,
                                                ) && FilterOptionsChildWrapper
                                            }
                                        >
                                            {item.type === 'radio' ? (
                                                <SelectButtons
                                                    selectedValue={
                                                        filterInputs[
                                                            `${item.name}`
                                                        ]
                                                    }
                                                    values={item.options}
                                                    handleChange={value =>
                                                        toggleSingleSelection(
                                                            item.name,
                                                            value,
                                                        )
                                                    }
                                                />
                                            ) : item.type === 'checkbox' ? (
                                                <MultiSelectButtons
                                                    selectedValues={
                                                        filterInputs[
                                                            `${item.name}`
                                                        ]
                                                    }
                                                    values={item.options}
                                                    handleChange={value => {
                                                        toggleMultiSelection(
                                                            item.name,
                                                            value,
                                                        )
                                                    }}
                                                />
                                            ) : item.type === 'range' ? (
                                                <Slider
                                                    range={{
                                                        draggableTrack: true,
                                                    }}
                                                    defaultValue={[0, 0]}
                                                    value={
                                                        pseudoInputs[item.name]
                                                    }
                                                    onChange={value => {
                                                        setPseudoInputs(
                                                            prev => ({
                                                                ...prev,
                                                                [`${item.name}`]:
                                                                    value,
                                                            }),
                                                        )
                                                        addQueryParamsToUrl({
                                                            [`${item.name}_min`]:
                                                                value[0],
                                                            [`${item.name}_max`]:
                                                                value[1],
                                                        })
                                                    }}
                                                    onAfterChange={value => {
                                                        if (currentPage > 1) {
                                                            setCurrentPage(1)
                                                        }
                                                        setFilterInputs(
                                                            prev => ({
                                                                ...prev,
                                                                [`${item.name}`]:
                                                                    value,
                                                            }),
                                                        )

                                                        appTracking(
                                                            pageUrlName,
                                                            pageTracker,
                                                            pageTitle,
                                                            consumerProfileEvents(
                                                                isAllSystems
                                                                    ? 'AASP'
                                                                    : !isResidential
                                                                    ? 'BP'
                                                                    : 'RP',
                                                            )['useFilter']
                                                                .action,
                                                            consumerProfileEvents(
                                                                isAllSystems
                                                                    ? 'AASP'
                                                                    : !isResidential
                                                                    ? 'BP'
                                                                    : 'RP',
                                                            ).label,
                                                            consumerProfileEvents(
                                                                isAllSystems
                                                                    ? 'AASP'
                                                                    : !isResidential
                                                                    ? 'BP'
                                                                    : 'RP',
                                                            ).category,
                                                            ['MP', 'GA'],
                                                            'event',
                                                        )
                                                    }}
                                                    step={
                                                        item.name ===
                                                        'system_size'
                                                            ? 0.1
                                                            : 1
                                                    }
                                                    max={item.max}
                                                    min={item.min}
                                                    tooltip={{
                                                        placement: 'bottom',
                                                        formatter: value =>
                                                            item.name ===
                                                            'system_size'
                                                                ? `${value}KVA`
                                                                : item.name ===
                                                                  'cost'
                                                                ? `₦${currencyFormatter(
                                                                      value,
                                                                  )}`
                                                                : value,
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        )
                    })}
                </div>
            )}
        </section>
    )
}

Filter.propTypes = {
    toggleModal: PropTypes.func,
}

export default Filter
