import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import PropTypes from 'prop-types'
import infoIcon from 'src/assets/images/Info-warning-blue.svg'
import { ReactComponent as InfoIcon } from 'src/assets/images/Info-warning-blue.svg'
import { ReactComponent as ArrowDownIcon } from 'src/assets/images/Caret-Circle-Down.svg'
import QuestionMarkIcon from 'src/assets/images/question-mark-circle.svg'
import { ReactComponent as ClockIcon } from 'src/assets/images/clock.svg'
import { customerTypeMapping, customerTypeRemapping } from './data'
import GettingStarted from 'src/components/GettingStarted'
import {
    savePotentialCustomerApi,
    selectNewSystemAuthUser,
} from 'src/api/shoppingExperience/view-system'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { decrypt } from 'src/utils/cryptography'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import { formatPhoneNumber } from 'src/utils/formatting'
import './quickviewsystem.scss'
import NairaSymbol from 'src/components/NairaSymbol'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import AppliancesPreview from 'src/components/AppliancesPreview'
import CustomToolTip from 'src/components/CustomToolTip'
import { Dropdown, Menu, Skeleton } from 'antd'
import { retrieveEnergySolutionDetails } from 'src/api/shoppingExperience/energy-profile/api'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import { isMobile } from 'react-device-detect'
import useGetNewSystemForReturningUser from '../SolarSystemsListingsV2/hooks/useGetNewSystemForReturningUser'
import { appTracking } from 'src/utils/appTracker'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'
import ApplianceCombination from './components/ApplianceCombination'
import { decodeUserInfo, isAuthenticated } from 'src/utils/auth'
import useOnePageKYCSignup from '../ViewSystem/hooks/useOnePageKYCSignup'
import { useSolarSystemsContext } from '../SolarSystemsListingsV2/hooks/useSolarSystems'
import useGetReturningCustomerStatus from 'src/components/ConsumerLandingPageBody/hooks/useGetReturningCustomerStatus'
import getConsumerKYCHome from 'src/utils/getConsumerKYCHome'

const QuickViewSystem = ({
    id,
    showQuickView,
    setShowQuickView,
    handleFullDetails,
    estimation_id,
    isAllSystems,
    profile_id,
    customer_type,
    payment_model,
    onboardingType,
    isClosedCluster,
    isPromoCluster,
    appliances,
    isSharedCluster,
    solutionId,
    previousPaymentModel,
}) => {
    const history = useHistory()
    const [showModal, setShowModal] = useState(false)
    const [checked, setChecked] = useState(false)
    const [payload, setPayload] = useState({})
    const decryptedData =
        localStorage?.getItem('sunfiUserSSEGettingStartedInfo') &&
        JSON.parse(
            decrypt(localStorage?.getItem('sunfiUserSSEGettingStartedInfo')),
        )
    const [systemDetails, setSystemsDetails] = useState(showQuickView?.data)
    const [showToastError, setShowToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showAppliancePreview, setShowAppliancePreview] = useState(false)
    const [energyNeeds, setEnergyNeeds] = useState(
        showQuickView?.data?.fit_appliances,
    )
    const systemAppliances = showQuickView?.data?.fit_appliances
    const [moreExamples, setMoreExamples] = useState(false)
    const [moreDetails, setMoreDetails] = useState(false)
    const energyProfileId = profile_id
    const isProfile = profile_id?.length > 0
    const [activePaymentModel, setActivePaymentModel] = useState(payment_model)
    const { goBack } = useSolarSystemsContext()
    const [tenure, setTenure] = useState(0)
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [modalToast, setModalToast] = useState(toastData)
    const [signUpInputs, setSignUpInputs] = useState({
        firstName: '',
        phone: '',
        email: '',
        useType: '',
    })
    const [hasDiscardedDeal, setHasDiscardedDeal] = useState(false)
    const userInfo = decodeUserInfo()

    const returningActiveOrClosedCustomer = JSON.parse(
        sessionStorage.getItem('returningActiveOrClosedCustomer'),
    )
    const allowReturningCustomers =
        process.env.REACT_APP_FLAG_RETURNING_DTC_CUSTOMERS === 'true'
    const shouldPersistUserInfo =
        process.env.REACT_APP_FLAG_PERSIST_USER_GET_STARTED_INFO === 'true'
    const isOnePageKYC = process.env.REACT_APP_FLAG_SHOW_ONE_PAGE_KYC === 'true'
    const clusterCode = history?.location?.state?.clusterCode ?? ''
    const isNotSharedPromoCluster = isPromoCluster && !isSharedCluster
    const useType = decryptedData?.useType
    const usedOnePageKYC = !userInfo?.consumerKYCHome?.includes('overview')
    const usesDynamicKYC = isClosedCluster
    const consumerKycHome = getConsumerKYCHome(
        usedOnePageKYC,
        customer_type,
        usesDynamicKYC,
    )

    const resetToast = () => {
        setTimeout(() => {
            setModalToast(toastData)
        }, 2000)
    }

    const consumerCurrentSolution =
        solutionId === id && previousPaymentModel === payment_model

    const getNewSystemOnSuccess = () => {
        history.push(consumerKycHome)
    }

    const getNewSystemOnError = errorMsg => {
        setModalToast({
            showToast: true,
            toastMessage: errorMsg,
            messageType: 'error-secondary',
        })
        resetToast()
    }

    const { getNewSystemMutate, getNewSystemLoading } =
        useGetNewSystemForReturningUser({
            onSuccess: getNewSystemOnSuccess,
            onError: getNewSystemOnError,
        })

    const {
        onePageKYCSignupMutate,
        onePageKYCSignupLoading,
        signUpModalToast,
        consumerKYCHome,
    } = useOnePageKYCSignup({
        onSuccess: () => {
            setShowAppliancePreview(false)
            setShowModal(true)
        },
        isClosedCluster: isClosedCluster,
    })
    const handleChecked = () => {
        setChecked(!checked)
    }

    const handleAppliancePreview = () => {
        setShowAppliancePreview(true)
    }

    // for profiles / all systems users
    const { isFetching: ProfilesOrAllSystemsUserSystemFetching } = useQuery(
        'fetch-system-for-profiles-or-auth-user',
        () => {
            return retrieveEnergySolutionDetails({
                id: id,
                payload: {
                    payment_model: payment_model,
                    appliances: appliances ?? undefined,
                    onboardingType: onboardingType,
                    profile_id:
                        energyProfileId?.length > 0
                            ? energyProfileId
                            : undefined,
                },
            })
        },
        {
            enabled: payment_model?.length > 0,
            retry: false,
            onSuccess: data => {
                setSystemsDetails(data?.data?.data)
                setEnergyNeeds(data.data.data.fit_appliances)
                setActivePaymentModel(data.data.data.payment_model)
                setTenure(data.data.data.preferred_payment_tenure)
            },
            onError: error => {
                setShowToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const undoPaymentOptionSelection = () => {
        setActivePaymentModel(payment_model)
        setShowModal(false)
    }

    const selectNewSystem = useMutation({
        mutationKey: ['select_new_system'],
        mutationFn: () =>
            selectNewSystemAuthUser(
                estimation_id,
                id,
                energyProfileId ?? '',
                energyNeeds?.length > 0 ? energyNeeds : undefined,
                isAllSystems
                    ? 'ALL_SOLUTIONS'
                    : isProfile
                    ? 'ENERGY_PROFILE'
                    : 'ENERGY_ASSESSMENT',
                activePaymentModel ?? '',
            ),
        onSuccess: () => {
            history.push({
                pathname: userInfo?.consumerKYCHome,
                state: {
                    payment_model: activePaymentModel,
                    onboardingType,
                },
            })
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    // persist consumer details and solution id in case of dropoff
    const savePotentialCustomer = useMutation({
        mutationKey: ['save-potential-customer'],
        mutationFn: phone =>
            savePotentialCustomerApi({
                selected_solution_id: id,
                ...(decryptedData?.contactType === 'email'
                    ? {
                          email: decryptedData?.email,
                      }
                    : {
                          phone_number: isPromoCluster
                              ? formatPhoneNumber(phone)
                              : decryptedData?.phone,
                      }),
            }),
    })

    useEffect(() => {
        const fields = {
            appliances: energyNeeds,
            selected_solution_id: systemDetails?.id,
            ...(!!energyProfileId && {
                energy_profile_id: energyProfileId,
            }),
            payment_model: activePaymentModel,
            customer_type: customer_type,
            flow: isAllSystems ? 'ALL_SOLUTIONS' : 'ENERGY_PROFILE',
        }
        setPayload(fields)
    }, [
        activePaymentModel,
        energyNeeds,
        energyProfileId,
        isAllSystems,
        isPromoCluster,
        customer_type,
        systemDetails,
    ])

    const handlePromoDropoff = phone => {
        savePotentialCustomer.mutate(phone)
    }

    const isOutrightSalePage =
        activePaymentModel?.toLowerCase() === 'outright sale'

    const isL2OPage = activePaymentModel?.toLowerCase() === 'lease to own'

    const menu = (
        <Menu>
            {systemDetails?.payment_plans?.map((item, i) => (
                <Menu.Item
                    key={i}
                    onClick={() => {
                        setTenure(item.tenure)
                        appTracking(
                            '',
                            '',
                            '',
                            consumerProfileEvents()['qvViewMultiplePlans']
                                .action,
                            consumerProfileEvents()['qvViewMultiplePlans']
                                .label,
                            consumerProfileEvents()['qvViewMultiplePlans']
                                .category,
                            ['MP', 'GA'],
                            'event',
                        )
                    }}
                >
                    {item.tenure} months
                </Menu.Item>
            ))}
        </Menu>
    )

    const { getReturningCustomerStatus, getReturningCustomerStatusLoading } =
        useGetReturningCustomerStatus({
            email: signUpInputs?.email,
            phone: formatPhoneNumber(signUpInputs?.phone),
            onSuccess: data => {
                const hasDiscardedDeal = data?.has_discarded_deal
                setHasDiscardedDeal(hasDiscardedDeal)
            },
        })

    const signUpPayload = {
        customer_type:
            location?.state?.customer_type ||
            customerTypeMapping[signUpInputs?.useType] ||
            customer_type,
        selected_solution_id: id,
        ...(clusterCode !== '' && {
            cluster_code: clusterCode,
        }),
        ...(energyProfileId !== '' && {
            energy_profile_id: energyProfileId,
        }),
        payment_model,
        flow: isAllSystems ? 'ALL_SOLUTIONS' : 'ENERGY_PROFILE',
        ...(signUpInputs?.firstName !== '' && {
            first_name: signUpInputs.firstName,
        }),
        appliances: energyNeeds,
        email: signUpInputs?.email,
        phone_number: formatPhoneNumber(signUpInputs?.phone),
    }

    const handleApproveAppliances = () => {
        if (
            (isOnePageKYC && isAllSystems) ||
            (isOnePageKYC && hasDiscardedDeal) ||
            !isOnePageKYC
        ) {
            setShowAppliancePreview(false)
            setShowModal(true)
        } else if (isAuthenticated()) {
            selectNewSystem.mutate()
        } else {
            onePageKYCSignupMutate(signUpPayload)
        }
    }

    useEffect(() => {
        try {
            const data = localStorage.getItem('sunfiUserSSEGettingStartedInfo')
            if (returningActiveOrClosedCustomer?.status) {
                setSignUpInputs(prev => ({
                    ...prev,
                    email: userInfo?.email,
                    phone: userInfo?.phoneNumber?.replace('+234', 0),
                    useType: returningActiveOrClosedCustomer?.useType,
                }))
                return
            } else if (data !== null && !isNotSharedPromoCluster) {
                const decryptedData = JSON.parse(decrypt(data))
                if (decryptedData) {
                    setSignUpInputs(prev => ({
                        ...prev,
                        firstName: decryptedData?.firstName,
                        email: decryptedData?.email,
                        ...(decryptedData?.phone && {
                            phone: decryptedData?.phone?.replace('+234', 0),
                        }),
                        ...(decryptedData?.useType
                            ? {
                                  useType:
                                      customerTypeRemapping[
                                          decryptedData?.useType
                                      ],
                              }
                            : {}),
                    }))
                    return
                }
            } else {
                setSignUpInputs({
                    useType: useType,
                    email: '',
                    phone: '',
                    firstName: '',
                })
            }
        } catch (error) {
            setSignUpInputs(prev => ({
                ...prev,
                email: '',
                phone: '',
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNotSharedPromoCluster])

    useEffect(() => {
        if (signUpInputs?.email !== '') {
            getReturningCustomerStatus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signUpInputs])

    return (
        <div className="QView_Parent_Container">
            <GettingStarted
                showModal={showModal}
                setShowModal={setShowModal}
                selectedSolutionId={id}
                isPromo={decryptedData === null}
                handlePromoDropoff={handlePromoDropoff}
                initiatePayload={payload}
                showUseTypeField={
                    isAllSystems || onboardingType === 'custom-build'
                }
                showUseTypeFieldWithoutContact={isOnePageKYC && isAllSystems}
                useV2SaveUseTypeFn={onboardingType !== 'custom-build'}
                selectedDifferentPaymentPlan={false}
                activePaymentModel={activePaymentModel}
                handleChangePaymentModel={() => selectNewSystem.mutate()}
                undoPaymentOptionSelection={undoPaymentOptionSelection}
                useType={
                    customerTypeRemapping[customer_type] ||
                    signUpInputs?.useType
                }
                isClosedCluster={isClosedCluster}
                isPromoCluster={isPromoCluster}
                isSharedCluster={isSharedCluster}
                energyProfileId={profile_id}
                paymentModel={payment_model}
                isAllSystems={isAllSystems}
                signUpPayload={signUpPayload}
                consumerKYCHome={consumerKYCHome}
                hasDiscardedDeal={hasDiscardedDeal}
            />

            <SecondaryModal
                showModal={showAppliancePreview}
                mobileModalTop="unset"
                title="Confirm to continue"
                onCancel={() => {
                    setShowAppliancePreview(false)
                    setChecked(false)
                }}
                content={
                    <>
                        {signUpModalToast?.showToast && (
                            <Toast
                                message={signUpModalToast?.toastMessage}
                                messageType={signUpModalToast?.messageType}
                            />
                        )}
                        <AppliancesPreview
                            systemDetails={systemDetails}
                            appliances={systemAppliances}
                            handleChecked={handleChecked}
                            checked={checked}
                            isQuickView
                            showToast={modalToast?.showToast}
                            toastMessage={modalToast?.toastMessage}
                            messageType={modalToast?.messageType}
                        />
                    </>
                }
                modalWidth="475px"
                v2
                showFooter
                primaryBtnText="Confirm"
                footerLeftContent={'chat'}
                footerClassName="AppliancePreviewFooter"
                primaryBtnDisabled={
                    !checked ||
                    selectNewSystem.isLoading ||
                    getReturningCustomerStatusLoading
                }
                primaryBtnLoading={
                    selectNewSystem.isLoading ||
                    getNewSystemLoading ||
                    onePageKYCSignupLoading
                }
                kycSecondaryButtonStopLoading
                handlePrimaryBtnClick={() => {
                    if (
                        allowReturningCustomers &&
                        returningActiveOrClosedCustomer?.status
                    ) {
                        getNewSystemMutate(payload)
                    } else {
                        handleApproveAppliances()
                    }
                }}
            />

            <ConsumerModalBase
                v2
                showModal={showQuickView?.open}
                title="Package quick view"
                standardWidth={476}
                showFooter
                footerLeftContent="button"
                handleSecondaryBtnClick={handleFullDetails}
                secondaryBtnText={'View full details'}
                primaryBtnText={
                    consumerCurrentSolution
                        ? `Continue with KYC`
                        : 'Get this system'
                }
                primaryBtnLoading={
                    isProfile || isAllSystems
                        ? false
                        : selectNewSystem.isLoading
                }
                primaryBtnDisabled={ProfilesOrAllSystemsUserSystemFetching}
                secondaryBtnDisabled={ProfilesOrAllSystemsUserSystemFetching}
                mobileHeight={576}
                onCancel={() => {
                    setShowQuickView({ open: false, data: {} })
                    setMoreExamples(false)
                    setMoreDetails(false)
                    setChecked(false)
                }}
                handlePrimaryBtnClick={() => {
                    if (consumerCurrentSolution) {
                        goBack()
                    } else {
                        setShowQuickView(prev => ({ ...prev, open: false }))
                        handleAppliancePreview()
                        appTracking(
                            '',
                            '',
                            '',
                            consumerProfileEvents()['qvGetSystem'].action,
                            consumerProfileEvents()['qvGetSystem'].label,
                            consumerProfileEvents()['qvGetSystem'].category,
                            ['MP', 'GA'],
                            'event',
                        )
                        if (shouldPersistUserInfo) {
                            sessionStorage.removeItem(
                                'sunfiUserSSEGettingStartedPersist',
                            )
                        }
                    }
                    setChecked(false)
                }}
                footerClassName="Qview_Footer"
            >
                <>
                    {showToastError && (
                        <Toast
                            messageType="error-secondary"
                            message={errorMessage}
                            closable={true}
                        />
                    )}
                    <div className="QViewSystemWrapper">
                        {ProfilesOrAllSystemsUserSystemFetching ? (
                            <Skeleton.Input
                                active
                                loading
                                style={{ height: '65px' }}
                            />
                        ) : (
                            <div className="first_row">
                                <div>
                                    <h2>{systemDetails?.name}</h2>

                                    <p>{activePaymentModel}</p>
                                </div>
                                <div>
                                    <h2>
                                        {systemDetails?.energy_capacity?.value +
                                            systemDetails?.energy_capacity
                                                ?.unit_of_measurement}
                                    </h2>
                                    <CustomToolTip
                                        text={
                                            'These plans require an initial deposit and specific monthly payments to purchase the product.'
                                        }
                                        toolTipFontSize="12px"
                                        toolTipPadding="9px 12px 9px 8px"
                                        toolTipPopupWidth={174}
                                        toolTipMarginLeft={-150}
                                    >
                                        <img
                                            src={infoIcon}
                                            alt={'info icon'}
                                            role={'presentation'}
                                        />

                                        <span>What does this mean?</span>
                                    </CustomToolTip>
                                </div>
                            </div>
                        )}
                        {ProfilesOrAllSystemsUserSystemFetching ? (
                            <Skeleton.Input
                                active
                                loading
                                style={{ height: '65px' }}
                            />
                        ) : (
                            <div className="second_row">
                                <div>
                                    <CustomToolTip
                                        text={
                                            'Using fewer appliances at the same time helps your system last longer'
                                        }
                                        toolTipFontSize="12px"
                                        toolTipPadding="9px 12px 9px 8px"
                                        toolTipPopupWidth={174}
                                        toolTipMarginLeft={0}
                                    >
                                        <span>
                                            Limited appliance load
                                            {isMobile ? '' : ' at once'}
                                        </span>
                                        <img
                                            src={QuestionMarkIcon}
                                            alt={'question-mark-icon'}
                                            role={'presentation'}
                                            width={8}
                                            height={8}
                                        />
                                    </CustomToolTip>
                                    <div>
                                        <span>
                                            <ClockIcon />
                                        </span>
                                        <span>
                                            Last up to{' '}
                                            {systemDetails?.appliance_combinations_hours ??
                                                0}{' '}
                                            hours
                                        </span>
                                    </div>
                                </div>
                                {systemDetails?.appliance_combinations?.length >
                                    0 && (
                                    <div>
                                        <div></div>
                                        <span
                                            onClick={() =>
                                                setMoreExamples(prev => !prev)
                                            }
                                        >
                                            <p>
                                                {moreExamples ? 'Less' : 'More'}{' '}
                                                details
                                            </p>
                                            <span
                                                className={
                                                    moreExamples
                                                        ? 'second_row_arrow_animate'
                                                        : 'second_row_arrow'
                                                }
                                            >
                                                <ArrowDownIcon />
                                            </span>
                                        </span>
                                        <div></div>
                                    </div>
                                )}
                                {systemDetails?.appliance_combinations?.length >
                                    0 && (
                                    <div
                                        className={
                                            moreExamples
                                                ? 'second_row_details_animate'
                                                : 'second_row_details'
                                        }
                                    >
                                        <p className="second_row_appliance_title">
                                            Each box below shows different
                                            appliances that can run together
                                        </p>
                                        <div
                                            className={
                                                'appliance_combination_wrapper'
                                            }
                                        >
                                            {systemDetails?.appliance_combinations?.map(
                                                (item, i) => (
                                                    <CustomToolTip
                                                        text={`Example ${
                                                            i + 1
                                                        } of load combination you can use at the same time: ${item?.combination
                                                            ?.map(
                                                                item =>
                                                                    item?.name,
                                                            )
                                                            ?.join(', ')}`}
                                                        toolTipFontSize="12px"
                                                        toolTipPadding="9px 12px 9px 8px"
                                                        toolTipPopupWidth={174}
                                                        toolTipMarginLeft={0}
                                                        key={i}
                                                        arrowPositionLeft
                                                    >
                                                        <ApplianceCombination
                                                            appliance_combinations={
                                                                item
                                                            }
                                                        />
                                                    </CustomToolTip>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {ProfilesOrAllSystemsUserSystemFetching ? (
                            <Skeleton.Input
                                active
                                loading
                                style={{ height: '40px' }}
                            />
                        ) : (
                            <div className="third_row">
                                <CustomToolTip
                                    text={
                                        'Putting on all your appliances at the same time reduces the time your system will last up to'
                                    }
                                    toolTipFontSize="12px"
                                    toolTipPadding="9px 12px 9px 8px"
                                    toolTipPopupWidth={174}
                                    toolTipMarginLeft={-16}
                                >
                                    <span>
                                        Full appliance load
                                        {isMobile ? '' : ' at once'}
                                    </span>
                                    <img
                                        src={QuestionMarkIcon}
                                        alt={'question-mark-icon'}
                                        role={'presentation'}
                                        width={8}
                                        height={8}
                                    />
                                </CustomToolTip>
                                <div>
                                    <span>
                                        <ClockIcon />
                                    </span>
                                    <span>
                                        Last up to{' '}
                                        {systemDetails?.average_backup_hours}{' '}
                                        hours
                                    </span>
                                </div>
                            </div>
                        )}

                        {ProfilesOrAllSystemsUserSystemFetching ? (
                            <Skeleton.Input
                                active
                                loading
                                style={{
                                    height: isOutrightSalePage
                                        ? '40px'
                                        : '65px',
                                }}
                            />
                        ) : isOutrightSalePage &&
                          !ProfilesOrAllSystemsUserSystemFetching ? (
                            <div className="fourth_row_outright">
                                <div>
                                    <h2>Upfront Deposit</h2>
                                    <p>
                                        <NairaSymbol />
                                        {currencyFormatter(
                                            systemDetails?.payment_plans?.[
                                                systemDetails?.payment_plans
                                                    ?.length - 1
                                            ]?.initial_deposit || 0,
                                        )}
                                    </p>
                                </div>
                                <div>
                                    <h2>One time payment of</h2>
                                    <p>
                                        <NairaSymbol />
                                        {currencyFormatter(
                                            systemDetails?.total_cost,
                                        )}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="fourth_row">
                                <div>
                                    <Dropdown
                                        trigger={['click']}
                                        overlay={menu}
                                        placement="bottomRight"
                                        disabled={!isL2OPage}
                                        className={
                                            isL2OPage
                                                ? 'fourth_row_dropdown'
                                                : ''
                                        }
                                    >
                                        <div>
                                            <h2>
                                                {tenure} months
                                                {isMobile
                                                    ? ''
                                                    : ' payment'}{' '}
                                                plan
                                            </h2>
                                            {isL2OPage && <ArrowDownIcon />}
                                        </div>
                                    </Dropdown>
                                    <div>
                                        <p>
                                            <NairaSymbol />
                                            {currencyFormatter(
                                                isL2OPage
                                                    ? systemDetails?.payment_plans?.find(
                                                          item =>
                                                              item.tenure ===
                                                              tenure,
                                                      )?.monthly_payment
                                                    : systemDetails
                                                          ?.payment_plans?.[
                                                          systemDetails
                                                              ?.payment_plans
                                                              ?.length - 1
                                                      ]?.monthly_payment,
                                            )}
                                            /Month
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div></div>
                                    <span
                                        onClick={() =>
                                            setMoreDetails(prev => !prev)
                                        }
                                    >
                                        <p>
                                            {moreDetails ? 'Less' : 'More'}{' '}
                                            details
                                        </p>
                                        <span
                                            className={
                                                moreDetails
                                                    ? 'fourth_row_arrow_animate'
                                                    : 'fourth_row_arrow'
                                            }
                                        >
                                            <ArrowDownIcon />
                                        </span>
                                    </span>
                                    <div></div>
                                </div>
                                <div
                                    className={
                                        moreDetails && !isL2OPage
                                            ? 'fourth_row_details_animate_min'
                                            : moreDetails && isL2OPage
                                            ? 'fourth_row_details_animate_max'
                                            : 'fourth_row_details'
                                    }
                                >
                                    {isL2OPage && (
                                        <div className="fourth_row_amount">
                                            <div>
                                                <h2>Upfront Deposit</h2>
                                                <p>
                                                    <NairaSymbol />
                                                    {currencyFormatter(
                                                        systemDetails
                                                            ?.payment_plans?.[
                                                            systemDetails
                                                                ?.payment_plans
                                                                ?.length - 1
                                                        ]?.initial_deposit || 0,
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <h2>
                                                    Total amount in {tenure}{' '}
                                                    months
                                                </h2>
                                                <p>
                                                    <NairaSymbol />
                                                    {currencyFormatter(
                                                        systemDetails?.payment_plans?.find(
                                                            item =>
                                                                item.tenure ===
                                                                tenure,
                                                        )?.investment_meta_data
                                                            ?.total_paid,
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <div className="fourth_row_info">
                                        <div
                                        // TODO: replace with a tooltip when a copy is provided
                                        // text={`${
                                        //     systemDetails?.consumer_avg_demand?.includes(
                                        //         'kVA',
                                        //     )
                                        //         ? ''
                                        //         : `${systemDetails?.consumer_avg_demand} also means ${systemDetails?.consumer_avg_demand_kva}. `
                                        // }Keep total power use at the recommended level to get the listed backup hours.`}
                                        // toolTipFontSize="12px"
                                        // toolTipPadding="9px 12px 9px 8px"
                                        // toolTipPopupWidth={174}
                                        // toolTipMarginLeft={-150}
                                        >
                                            <InfoIcon />
                                            {isL2OPage ? (
                                                <span>
                                                    The total price covers the
                                                    installation cost, but you
                                                    may need to pay an extra fee
                                                    for logistics if you are
                                                    located outside of Lagos.
                                                </span>
                                            ) : (
                                                <span>
                                                    The installation cost is
                                                    also covered and you will
                                                    not be required to make any
                                                    other payment asides your
                                                    monthly pay
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            </ConsumerModalBase>
        </div>
    )
}

QuickViewSystem.propTypes = {
    id: PropTypes.string,
    showQuickView: PropTypes.bool,
    setShowQuickView: PropTypes.func,
    handleFullDetails: PropTypes.func,
    estimation_id: PropTypes.string,
    action: PropTypes.string,
    isAllSystems: PropTypes.bool,
    profile_id: PropTypes.string,
    customer_type: PropTypes.string,
    payment_model: PropTypes.string,
    onboardingType: PropTypes.string,
    isClosedCluster: PropTypes.string,
    isPromoCluster: PropTypes.bool,
    isSharedCluster: PropTypes.bool,
    appliances: PropTypes.array,
    solutionId: PropTypes.string,
    previousPaymentModel: PropTypes.string,
}

export default QuickViewSystem
