import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { message, Upload } from 'antd'
import {
    InputFields,
    SelectField,
    NoFLoatingLabelInputFields,
} from 'src/components/InputFields'
import { IconInputField } from 'src/components/InputFields/CostField'
import { ReactComponent as UploadIcon } from 'src/assets/images/upload.svg'
import { ReactComponent as SuccessUploadIcon } from 'src/assets/images/successUpload.svg'
import { zipSupportedbankStatementFileTypes } from 'src/utils/supportedFileTypes'
import { isMobile } from 'src/utils/mediaQueries'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import { accountSweepData, accountTypeData } from './data'

import styles from './CreditReviewTab.module.scss'

const DisputeCreditDecionBankStatement = ({
    categoryId,
    inputs,
    handleInputChange,
    handleSelectChange,
    setFileValid,
    successUpload,
    setSuccessUpload,
    bankLists,
    handleBlur,
    errorMessage,
    accountName,
}) => {
    const { Dragger } = Upload
    const {
        DCDBankstatementStyle,
        DCDUploadBankStatementStyle,
        DCDUploadModalContent,
    } = styles

    const [uploading, setUploading] = useState(false)
    const [width, setWidth] = useState(40)
    // handle upload bank statement
    const props = {
        name: 'file',
        onChange({ fileList }) {
            setUploading(true)
            setSuccessUpload(fileList)
            setUploading(false)
        },
        onDrop() {
            setUploading(false)
        },
    }

    useEffect(() => {
        const handleSlider = () => {
            if (uploading && width < 100) {
                setWidth(prev => prev + 20)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploading])

    const beforeStatementUpload = file => {
        const isLt2M = file.size / 1000 / 1000 <= 10

        if (
            !Object.keys(zipSupportedbankStatementFileTypes)?.includes(
                file.type,
            )
        ) {
            message.error(
                `${
                    file.name
                } is not one of the supported file types (${Object.values(
                    zipSupportedbankStatementFileTypes,
                )?.join(',')})`,
            )
            setFileValid(false)
            return false
        } else if (!isLt2M) {
            message.error('File should be less than 10mb')
            setFileValid(false)
            return false
        } else {
            setFileValid(true)
            return true
        }
    }

    return (
        <>
            {categoryId === 4 ? (
                <div className={DCDBankstatementStyle}>
                    <IconInputField
                        icon={NairaIcon}
                        name="loanRepayment"
                        title="Loan repayment"
                        value={inputs.loanRepayment}
                        handleChange={handleInputChange}
                        width={isMobile ? '100%' : '234px'}
                        marginBottom={isMobile ? '10px' : '15px'}
                    />
                    <IconInputField
                        icon={NairaIcon}
                        name="avgRecurringExpense"
                        title="Average recurring expense"
                        value={inputs.avgRecurringExpense}
                        handleChange={handleInputChange}
                        width={isMobile ? '100%' : '234px'}
                        marginBottom={isMobile ? '10px' : '15px'}
                    />
                    <IconInputField
                        icon={NairaIcon}
                        name="avgMonthlyBalance"
                        title="Average monthly balance"
                        value={inputs.avgMonthlyBalance}
                        handleChange={handleInputChange}
                        width={isMobile ? '100%' : '234px'}
                        marginBottom={isMobile ? '10px' : '15px'}
                    />
                    <IconInputField
                        icon={NairaIcon}
                        name="closingBalance"
                        title="Closing balance"
                        value={inputs.closingBalance}
                        handleChange={handleInputChange}
                        width={isMobile ? '100%' : '234px'}
                        marginBottom={isMobile ? '10px' : '15px'}
                    />
                    <IconInputField
                        icon={NairaIcon}
                        name="avgCredit"
                        title="Average credit"
                        value={inputs.avgCredit}
                        handleChange={handleInputChange}
                        width={isMobile ? '100%' : '234px'}
                        marginBottom={isMobile ? '10px' : '15px'}
                    />
                    <IconInputField
                        icon={NairaIcon}
                        name="avgDebit"
                        title="Average Debit"
                        value={inputs.avgDebit}
                        handleChange={handleInputChange}
                        width={isMobile ? '100%' : '234px'}
                        marginBottom={isMobile ? '10px' : '15px'}
                    />

                    <SelectField
                        values={accountSweepData.map(option => ({
                            value: option,
                        }))}
                        selectWidth="234px"
                        name="accountSweep"
                        initialOption={
                            inputs.accountSweep === '' && 'Account sweep'
                        }
                        handleChange={handleSelectChange}
                        marginBottom="10px"
                        dropdownPositionRelative
                        floatingLabel={
                            inputs.accountSweep === '' ? '' : 'Account sweep?'
                        }
                        currentSelected={inputs.accountSweep}
                        selectedValue={inputs.accountSweep}
                        withCheckBox
                    />
                    <InputFields
                        name="gamblingRate"
                        title="Gambling rate"
                        value={inputs.gamblingRate}
                        handleChange={handleInputChange}
                        inputWidth="234px"
                        type="number"
                        marginBottom={isMobile ? '10px' : '15px'}
                    />
                    <IconInputField
                        icon={NairaIcon}
                        name="avgOtherIncome"
                        title="Average Other Income"
                        value={inputs.avgOtherIncome}
                        handleChange={handleInputChange}
                        width={isMobile ? '100%' : '234px'}
                        marginBottom={isMobile ? '10px' : '15px'}
                    />
                    <SelectField
                        values={accountTypeData.map(option => ({
                            value: option,
                        }))}
                        selectWidth="234px"
                        name="accountType"
                        initialOption={
                            inputs.accountType === '' && 'Account Type'
                        }
                        handleChange={handleSelectChange}
                        marginBottom="10px"
                        dropdownPositionRelative
                        floatingLabel={
                            inputs.accountType === '' ? '' : 'Account Type?'
                        }
                        currentSelected={inputs.accountType}
                        selectedValue={inputs.accountType}
                        withCheckBox
                    />
                    {inputs.accountType === accountTypeData[0] ? (
                        <IconInputField
                            icon={NairaIcon}
                            name="avgSalary"
                            title="Average salary"
                            value={inputs.avgSalary}
                            handleChange={handleInputChange}
                            width={isMobile ? '100%' : '480px'}
                            marginBottom={isMobile ? '10px' : '15px'}
                        />
                    ) : (
                        <IconInputField
                            icon={NairaIcon}
                            name="avgLoanAmount"
                            title="Average Loan Amount"
                            value={inputs.avgLoanAmount}
                            handleChange={handleInputChange}
                            width={isMobile ? '100%' : '480px'}
                            marginBottom={isMobile ? '10px' : '15px'}
                        />
                    )}
                </div>
            ) : (
                <div className={'UploadModalWrapper'}>
                    <div>
                        <div>
                            <SelectField
                                name="bankDetails"
                                initialOption="Select Customer's bank"
                                selectWidth="100%"
                                handleChange={handleSelectChange}
                                withCheckBox={true}
                                dropdownPositionRelative
                                selectedValue={inputs?.bankDetails?.name}
                                currentSelected={inputs?.bankDetails?.name}
                                values={bankLists?.map(bank => ({
                                    value: bank.name,
                                }))}
                                value={inputs?.bankDetails?.name}
                                marginBottom="10px"
                                disabled={bankLists?.length === 0}
                                showSearch
                            />
                            <InputFields
                                inputWidth="100%"
                                title="Enter Customer's account number"
                                type={'number'}
                                value={inputs?.accountNumber}
                                handleChange={handleInputChange}
                                name="accountNumber"
                                errorMessage={errorMessage?.accountNumber}
                                onBlur={handleBlur}
                                inputFieldWrapperStyle={{
                                    pointerEvents:
                                        inputs?.bankDetails === ''
                                            ? 'none'
                                            : '',
                                }}
                                marginBottom="10px"
                            />

                            {accountName && (
                                <NoFLoatingLabelInputFields
                                    name="bankName"
                                    inputPlaceholder="Account Name"
                                    inputWidth="100%"
                                    value={accountName}
                                    inputBackground="#f8f8f8"
                                    marginBottom="10px"
                                    disabled
                                />
                            )}
                        </div>
                    </div>
                    <Dragger
                        className={DCDUploadBankStatementStyle}
                        fileList={successUpload}
                        maxCount={1}
                        showUploadList={true}
                        accept={Object.values(
                            zipSupportedbankStatementFileTypes,
                        )?.join(',')}
                        beforeUpload={beforeStatementUpload}
                        customRequest={() => {}}
                        {...props}
                    >
                        {uploading ? (
                            <div className="SlideContainer">
                                <p
                                    className="Slider"
                                    style={{
                                        width: `${width}%`,
                                    }}
                                ></p>
                                <p
                                    className="UploadText"
                                    style={{ marginTop: '5px' }}
                                >
                                    Uploading document...{' '}
                                </p>
                            </div>
                        ) : successUpload?.length > 0 ? (
                            <div className={DCDUploadModalContent}>
                                <SuccessUploadIcon />
                                <p>{successUpload?.[0]?.name}</p>
                            </div>
                        ) : (
                            <div className={DCDUploadModalContent}>
                                <UploadIcon />
                                <p>
                                    Upload bank statement here. Drag and drop or
                                    &nbsp;
                                    <span>browse</span>
                                </p>
                                <p style={{ fontSize: '13px' }}>
                                    If more than one bank statement, please zip
                                    and upload file <br />
                                    Supported format : pdf or zip
                                </p>
                            </div>
                        )}
                    </Dragger>
                </div>
            )}
        </>
    )
}

export default DisputeCreditDecionBankStatement

DisputeCreditDecionBankStatement.propTypes = {
    handleInputChange: PropTypes.func,
    inputs: PropTypes.object,
    handleSelectChange: PropTypes.func,
    categoryId: PropTypes.string,
    setFileValid: PropTypes.func,
    successUpload: PropTypes.array,
    setSuccessUpload: PropTypes.func,
    bankLists: PropTypes.array,
    handleBlur: PropTypes.func,
    errorMessage: PropTypes.string,
    accountName: PropTypes.string,
}
