/* eslint-disable react/prop-types */
import { Skeleton } from 'antd'
import { MultipleSelectField } from 'src/components/InputFields'
import { subStringText } from 'src/utils/formatting'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import UpArrow from 'src/assets/images/blackUpArrow.svg'
import DownArrow from 'src/assets/images/blackDownArrow.svg'
import CloseIcon from 'src/assets/images/close-icon.svg'
import { InlineLoader } from 'src/components/Loader'
import Label from 'src/components/Label'
import { isMobile } from 'src/utils/mediaQueries'
import { AppButton } from 'src/components/Button'

const PackageDetails = ({
    energySolutionsData,
    modalId,
    skeletalState,
    allComponents,
    selected,
    handleSelectChange,
    fetchComponentsLoading,
    errMessage,
    updateCount,
    getCount,
    count,
    handleCancel,
    margin,
    subtotal,
    total,
    createEnergySolutionsLoading,
    handleSaveEnergySolutions,
    handleShowMarginModal,
    disabled,
}) => {
    return (
        <>
            {energySolutionsData.map(
                (energy, index) =>
                    energy.id === modalId && (
                        <div
                            key={index}
                            style={{
                                padding: isMobile ? '16px 30px' : '30px 60px',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <h2 className="ModalTitle">{energy.name}</h2>
                                {energy?.is_global === true ? (
                                    <Label
                                        title="GLOBAL"
                                        width={'fit-content'}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>

                            <h3 className="ModalSubTitle1">DESCRIPTION</h3>
                            <p
                                className="ModalParagraph"
                                dangerouslySetInnerHTML={{
                                    __html: energy.description,
                                }}
                            ></p>
                            <h3 className="ModalSubTitle2">
                                Components Information
                            </h3>
                            <p className="ModalComponent">Select Component</p>
                            <MultipleSelectField
                                values={!skeletalState ? allComponents : []}
                                value={
                                    !skeletalState
                                        ? !selected.map(item => item.value)
                                        : ''
                                }
                                onSelect
                                handleMultipleSelectChange2={handleSelectChange}
                                selectWidth="95%"
                                dropDownWidth="390px"
                                title={
                                    fetchComponentsLoading || skeletalState
                                        ? 'Loading'
                                        : 'Select Component'
                                }
                                inputValue={
                                    !skeletalState
                                        ? selected.map(item => item.value)
                                        : []
                                }
                                errorMessage={errMessage.components}
                                maxTagCount="responsive"
                                maxTagPlaceholder="Select Component"
                                mobileWidth
                                disabled={
                                    fetchComponentsLoading || disabled
                                        ? true
                                        : false
                                }
                            />
                            <div>
                                <div
                                    className="SelectedComponentsWrapper"
                                    style={{ width: '100%' }}
                                >
                                    <table>
                                        {!skeletalState &&
                                            selected.map((item, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="StepOneSelectedComponents"
                                                    >
                                                        <td
                                                            style={{
                                                                width: isMobile
                                                                    ? '88%'
                                                                    : '50%',
                                                            }}
                                                            className="ERComponentsFirstTD"
                                                        >
                                                            <div className="ERComponentsFirstTDDiv">
                                                                <span className="ERComponentsFirstTDSelectedName">
                                                                    {subStringText(
                                                                        item?.name ||
                                                                            item?.component_name,
                                                                        30,
                                                                    )}
                                                                </span>
                                                                {item?.isGlobal ===
                                                                true ? (
                                                                    <Label
                                                                        title="GLOBAL"
                                                                        fontSize="10px"
                                                                        padding="6px 8px"
                                                                    />
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                            <span className="ERComponentAmountMobile">
                                                                NGN{' '}
                                                                {currencyFormatter(
                                                                    item?.realCost ||
                                                                        item?.unit_cost,
                                                                )}
                                                            </span>
                                                        </td>
                                                        {!isMobile && (
                                                            <td className="SelectedTypeStyle">
                                                                <span className="">
                                                                    {subStringText(
                                                                        item
                                                                            ?.type
                                                                            ?.name ||
                                                                            item?.type,
                                                                        9,
                                                                    )}
                                                                </span>
                                                            </td>
                                                        )}
                                                        {!isMobile && (
                                                            <td
                                                                style={{
                                                                    width: '25%',
                                                                }}
                                                            >
                                                                <span className="SelectedCapacity">
                                                                    &#8358;
                                                                    {currencyFormatter(
                                                                        item?.realCost ||
                                                                            item?.unit_cost,
                                                                    )}
                                                                </span>
                                                            </td>
                                                        )}
                                                        <td
                                                            style={{
                                                                width: '10%',
                                                            }}
                                                        >
                                                            <div className="Scroll">
                                                                <span
                                                                    className="QuantityImageWrapper"
                                                                    onClick={() =>
                                                                        disabled
                                                                            ? {}
                                                                            : updateCount(
                                                                                  getCount(
                                                                                      item.id,
                                                                                  ),
                                                                                  'add',
                                                                                  item?.id,
                                                                                  item?.cost,
                                                                                  item?.realCost,
                                                                              )
                                                                    }
                                                                    disabled={
                                                                        count >=
                                                                            10 ||
                                                                        disabled
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    style={{
                                                                        cursor:
                                                                            (count >=
                                                                                10 ||
                                                                                disabled) &&
                                                                            'not-allowed',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            UpArrow
                                                                        }
                                                                        className="Arrow"
                                                                        alt="up-arrow-icon"
                                                                    />
                                                                </span>

                                                                <span className="Quantity">
                                                                    {getCount(
                                                                        item.id,
                                                                    )}
                                                                </span>
                                                                <span
                                                                    className="QuantityImageWrapper"
                                                                    onClick={() =>
                                                                        disabled
                                                                            ? {}
                                                                            : updateCount(
                                                                                  getCount(
                                                                                      item.id,
                                                                                  ),
                                                                                  'subtract',
                                                                                  item?.id,
                                                                                  item?.cost,
                                                                                  item?.realCost,
                                                                              )
                                                                    }
                                                                    disabled={
                                                                        count <=
                                                                            1 ||
                                                                        disabled
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    style={{
                                                                        cursor:
                                                                            (count <=
                                                                                1 ||
                                                                                disabled) &&
                                                                            'not-allowed',
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            DownArrow
                                                                        }
                                                                        className="Arrow"
                                                                        alt="down-arrow-icon"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '5%',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <span
                                                                onClick={() =>
                                                                    disabled
                                                                        ? {}
                                                                        : handleCancel(
                                                                              item.id,
                                                                          )
                                                                }
                                                                style={{
                                                                    cursor:
                                                                        disabled &&
                                                                        'not-allowed',
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        CloseIcon
                                                                    }
                                                                    alt="close-icon"
                                                                />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        {skeletalState &&
                                            [...Array(4)].map(i => (
                                                <Skeleton.Input
                                                    key={i}
                                                    active
                                                    className="EstimationSkeletalField"
                                                />
                                            ))}
                                    </table>
                                </div>
                                <div
                                    //className={`firstSubTotalWrapper ${EditSubtotalWrapper}`}
                                    className="SubTotalDiv"
                                >
                                    {
                                        <>
                                            <span className="Subtotal">
                                                {margin?.isExisting
                                                    ? 'Subtotal'
                                                    : 'Total'}
                                            </span>

                                            <p className="Subtotal">
                                                {!skeletalState ? (
                                                    <>
                                                        &#8358;
                                                        {currencyFormatter(
                                                            subtotal,
                                                        )}
                                                    </>
                                                ) : (
                                                    <Skeleton.Button
                                                        active
                                                        className="EstimationSkeletalShortField"
                                                    />
                                                )}
                                            </p>
                                        </>
                                    }
                                </div>

                                {margin?.isExisting && (
                                    <>
                                        <div
                                            //className={EditSubtotalWrapper}
                                            className="MarginDiv"
                                        >
                                            {
                                                <span
                                                    className="Subtotal"
                                                    style={{
                                                        fontWeight: 'normal',
                                                    }}
                                                >
                                                    Margin
                                                </span>
                                            }

                                            {skeletalState ? (
                                                <Skeleton.Button
                                                    active
                                                    className=""
                                                />
                                            ) : (
                                                <p className="Subtotal">
                                                    {margin.action ===
                                                    'Add Margin' ? (
                                                        <span
                                                            style={{
                                                                color: '#32CC79',
                                                            }}
                                                        >
                                                            {`+${margin.percentage}%`}{' '}
                                                            (&#8358;
                                                            {currencyFormatter(
                                                                margin.amount,
                                                            )}
                                                            )
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {`${
                                                                margin.percentage <
                                                                0
                                                                    ? margin.percentage
                                                                    : -margin.percentage
                                                            }%`}{' '}
                                                            (&#8358;
                                                            {currencyFormatter(
                                                                margin.amount,
                                                            )}
                                                            )
                                                        </span>
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                        <div className="TotalDiv">
                                            {
                                                <>
                                                    <span className="Subtotal">
                                                        Total
                                                    </span>
                                                    <p className="Subtotal">
                                                        {!skeletalState ? (
                                                            <>
                                                                {' '}
                                                                &#8358;
                                                                {currencyFormatter(
                                                                    selected?.length >
                                                                        0
                                                                        ? total
                                                                        : '0.00',
                                                                )}
                                                            </>
                                                        ) : (
                                                            <Skeleton.Button
                                                                active
                                                                className="Subtotal"
                                                            />
                                                        )}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                    </>
                                )}
                                <div className="AddMarginBtnWrapper">
                                    {!skeletalState && (
                                        <AppButton
                                            btnWidth="88px"
                                            btnHeight="29px"
                                            btnBgColor="#E2EEFF"
                                            btnTextColor="#004AAD"
                                            borderRadius="25px"
                                            fontSize="12px"
                                            fontWeight="600"
                                            handleClick={handleShowMarginModal}
                                            disabled={disabled}
                                        >
                                            {margin?.isExisting
                                                ? 'Edit Margin'
                                                : 'Add Margin'}
                                        </AppButton>
                                    )}
                                </div>
                            </div>
                            <div className="SelectPackageDiv">
                                {!skeletalState ? (
                                    <button
                                        className="ModalButton"
                                        disabled={createEnergySolutionsLoading}
                                        onClick={() =>
                                            handleSaveEnergySolutions()
                                        }
                                    >
                                        {createEnergySolutionsLoading ? (
                                            <InlineLoader />
                                        ) : (
                                            'Select Package'
                                        )}
                                    </button>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    ),
            )}
        </>
    )
}

export default PackageDetails
