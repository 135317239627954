/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import CloseFloatIcon from '../../assets/images/close-float-icon.svg'

import OnboardingStageList from '../OnboardingStageList'
import styles from './floatingbox.module.scss'

const FloatingBox = ({
    userInfo,
    showOnboardingBox,
    setShowOnboardingBox,
    type,
    floatIcon,
    floatBgColor,
    floatBoxClass,
    renderComponent,
    onClick,
    personalInfoCount,
    creditInfoCount,
    selectSolutionCount,
    personalInformationTab,
    energyInformationTab,
    creditInformationTab,
}) => {
    const { FloatBox, FloatContainer } = styles
    const [floatOpen, setFloatOpen] = useState(false)

    useEffect(() => {
        if (showOnboardingBox) {
            setFloatOpen(true)
        } else {
            setFloatOpen(false)
        }
    }, [showOnboardingBox])

    const RenderComponent = renderComponent
    
    return (
        <div
            className={floatBoxClass ? floatBoxClass : FloatBox}
            onClick={onClick}
        >
            {floatOpen && type === 'onboarding' ? (
                <OnboardingStageList
                    userInfo={userInfo}
                    personalInfoCount={personalInfoCount}
                    creditInfoCount={creditInfoCount}
                    selectSolutionCount={selectSolutionCount}
                    personalInformationTab={personalInformationTab}
                    energyInformationTab={energyInformationTab}
                    creditInformationTab={creditInformationTab}
                />
            ) : floatOpen ? (
                <>
                    {renderComponent && (
                        <RenderComponent
                            visible={floatOpen}
                            onClose={() => setFloatOpen(!floatOpen)}
                        />
                    )}
                </>
            ) : null}
            <div
                className={FloatContainer}
                style={{ backgroundColor: floatBgColor }}
                onClick={() => {
                    setFloatOpen(!floatOpen)
                    setShowOnboardingBox && setShowOnboardingBox(!floatOpen)
                }}
            >
                {floatOpen && type === 'onboarding' ? (
                    <img src={CloseFloatIcon} alt="icon" />
                ) : (
                    <img src={floatIcon} alt="icon" />
                )}
            </div>
        </div>
    )
}

export default FloatingBox
