import { useState } from 'react'
import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { isMobile } from 'src/utils/mediaQueries'
import styles from '../creditdecision.module.scss'
import { maybeStepMapping, step2OptionsMap } from '../utils/utils'
import { useHistory } from 'react-router-dom'
import { useAppContext } from 'src/appContext/AppContext'
import {
    useSimulateSSEPaymentPlans,
    useSwitchSSEPaymentPlans,
} from '../utils/queries'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'

const DecisionIsMaybe = ({ maybe, toAvailablePlans = () => {} }) => {
    const [isModalOpen, setIsModalOpen] = useState(true)
    const [stepBeforeClose, setStepBeforeClose] = useState(null)
    const [activeStep, setActiveStep] = useState(maybe ? 2 : 1)
    const [step2Selection, setStep2Selection] = useState('')
    const { overviewInfo } = useAppContext()
    const history = useHistory()
    const pageTitle =
        'CREDIT DECISION - MAYBE | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CREDIT_DECISION'
    const {
        mutateSSESimulatePaymentPlans,
        simulateSSEPaymentPlansLoading,
        simulateSSEPaymentPlansData,
    } = useSimulateSSEPaymentPlans({
        onSuccess: () => {
            setActiveStep(activeStep + 1)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['creditDecisionMaybeRentalSimulationSuccessFul']
                    .action,
                eventTrackers['creditDecisionMaybeRentalSimulationSuccessFul']
                    .label,
                eventTrackers['creditDecisionMaybeRentalSimulationSuccessFul']
                    .category,
            )
        },
    })

    const { mutateSSESwitchPaymentPlans, switchSSEPaymentPlansLoading } =
        useSwitchSSEPaymentPlans({
            onSuccess: () => {
                history.push({
                    pathname: '/consumer/workspace',
                    state: { isSubscription: true },
                })
                if (history.location.pathname.includes('/consumer/workspace')) {
                    location.reload()
                }
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['creditDecisionMaybeRentalSwitchSuccessFul']
                        .action,
                    eventTrackers['creditDecisionMaybeRentalSwitchSuccessFul']
                        .label,
                    eventTrackers['creditDecisionMaybeRentalSwitchSuccessFul']
                        .category,
                )
            },
        })

    const handlePrimaryBtnClick = () => {
        if (activeStep === 0) {
            setIsModalOpen(false)
            history.push('/')
        } else if (activeStep === 1) {
            setActiveStep(activeStep + 1)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['creditDecisionMaybeOptions'].action,
                eventTrackers['creditDecisionMaybeOptions'].label,
                eventTrackers['creditDecisionMaybeOptions'].category,
            )
        } else if (activeStep === 2) {
            if (step2Selection === step2OptionsMap.CHANGE) {
                mutateSSESimulatePaymentPlans({
                    estimation_id: overviewInfo?.estimationId,
                    payment_model: 'Subscription',
                })
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['creditDecisionMaybeRentalOption'].action,
                    eventTrackers['creditDecisionMaybeRentalOption'].label,
                    eventTrackers['creditDecisionMaybeRentalOption'].category,
                )
            } else if (step2Selection === step2OptionsMap.TAKEOUT) {
                // navigate to takeout page
            }
        } else if (activeStep === 3) {
            mutateSSESwitchPaymentPlans({
                estimation_id: overviewInfo?.estimationId,
                payment_plan: 'Subscription',
            })
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['creditDecisionMaybeRentalSwitch'].action,
                eventTrackers['creditDecisionMaybeRentalSwitch'].label,
                eventTrackers['creditDecisionMaybeRentalSwitch'].category,
            )
        }
    }

    const handleSecondaryBtnClick = () => {
        if (activeStep === 3) {
            setActiveStep(2)
        } else {
            setActiveStep(stepBeforeClose)
            if (stepBeforeClose && activeStep !== 0) {
                setStepBeforeClose(null)
            }
        }
    }

    const handleCloseModal = () => {
        const heldStep = activeStep
        if (heldStep === 0) {
            setActiveStep(stepBeforeClose)
            return
        }
        setStepBeforeClose(heldStep)
        setActiveStep(0)
    }

    return (
        <section>
            <SecondaryModal
                showModal={isModalOpen}
                closable={true}
                onCancel={handleCloseModal}
                title={
                    maybeStepMapping(styles, step2Selection, setStep2Selection)[
                        activeStep
                    ]?.title
                }
                primaryBtnText={
                    maybeStepMapping(styles, step2Selection, setStep2Selection)[
                        activeStep
                    ]?.primaryBtnText
                }
                primaryBtnLoading={
                    activeStep === 2 &&
                    step2Selection === step2OptionsMap.CHANGE
                        ? simulateSSEPaymentPlansLoading
                        : activeStep === 3
                        ? switchSSEPaymentPlansLoading
                        : false
                }
                kycSecondaryButtonStopLoading={
                    step2Selection === step2OptionsMap.CHANGE &&
                    simulateSSEPaymentPlansLoading
                        ? true
                        : activeStep === 3 && switchSSEPaymentPlansLoading
                        ? true
                        : false
                }
                primaryBtnWidth={isMobile ? '127.15px' : ''}
                handlePrimaryBtnClick={handlePrimaryBtnClick}
                modalWidth={isMobile ? '100%' : '475px'}
                showFooter
                primaryBtnDisabled={activeStep === 2 && !step2Selection}
                handleSecondaryBtnClick={handleSecondaryBtnClick}
                secondaryBtnText={
                    maybeStepMapping(styles, step2Selection, setStep2Selection)[
                        activeStep
                    ]?.secondaryBtnText
                }
                footerLeftContent={
                    maybeStepMapping(styles, step2Selection, setStep2Selection)[
                        activeStep
                    ]?.footerLeftContent
                }
                v2={true}
                content={
                    maybeStepMapping(
                        styles,
                        step2Selection,
                        setStep2Selection,
                        {
                            firstName: overviewInfo?.firstName,
                            monthlyPayment:
                                simulateSSEPaymentPlansData?.data?.data
                                    ?.monthly_payment,
                            packageImageUrl: simulateSSEPaymentPlansData?.data
                                ?.data?.package_has_solar_panel
                                ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+thumbnail.png'
                                : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Without+panels+thumbnail.png',
                        },
                        toAvailablePlans,
                    )[activeStep]?.content
                }
            />
        </section>
    )
}

DecisionIsMaybe.propTypes = {
    maybe: PropTypes.bool,
    toAvailablePlans: PropTypes.func,
}

export default DecisionIsMaybe
