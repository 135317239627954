import { permissionsControl } from 'src/utils/permissionsControl'

export const canCreateProfileGroup = permissionsControl([
    'can_create_energy_profile_group',
])

export const canViewProfileGroupDetails = permissionsControl([
    'can_view_energy_profile_group',
])

export const canUpdateProfileGroup = permissionsControl([
    'can_update_energy_profile_group',
])

export const canDeleteProfileGroup = permissionsControl([
    'can_delete_energy_profile_group',
])
