import axios from '../../config/axios'

export const updateAiInformationApi = async inputs => {
    const request = await axios.patch('/users/me', inputs)
    return request
}

export const getAiInformationApi = async () => {
    const request = await axios.get('/users/me')
    return request
}

export const changeAiPasswordApi = async inputs => {
    const request = await axios.post('/users/me/change-password', inputs)
    return request
}

export const registerASI = async data => {
    const request = await axios.post('/auth/providers/asi/register', data)
    return request
}

export const sendOTP = async data => {
    const request = await axios.post('/auth/providers/send-otp', data)
    return request
}

export const setPasswordApi = async data => {
    const request = await axios.post('/auth/providers/password', data)
    return request
}
