import { useEffect, useState } from 'react'
import styles from './HowSunfiWorks.module.scss'
import { stepsMap, videoMap } from './utils'
import ReactPlayer from 'react-player'

const HowSunfiWorks = () => {
    const [activeStep, setActiveStep] = useState(0)
    const [previousActiveStep, setPreviousActiveStep] = useState(null)

    const handleStepClick = index => {
        setPreviousActiveStep(activeStep)
        setActiveStep(index)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const active = activeStep === 3 ? 0 : activeStep + 1
            setPreviousActiveStep(activeStep)
            setActiveStep(active)
        }, 10000)
        return () => clearInterval(interval)
    }, [activeStep])

    return (
        <section className={styles.howSunfiWorks} data-testid="how-sunfi-works">
            <div className={styles.howSunfiWorks__wrapper}>
                <div className={styles.howSunfiWorks__wrapper__left}>
                    <ReactPlayer
                        url={videoMap[activeStep].link}
                        width="100%"
                        height="100%"
                        playing={true}
                        loop={true}
                        playIcon={null}
                        volume={1}
                        muted={true}
                        config={{
                            file: {
                                attributes: {
                                    poster: videoMap[activeStep].fallback,
                                },
                            },
                        }}
                        controls={false}
                        playsinline={true}
                        data-testid={`react-player-${activeStep}`}
                    />
                </div>
                <div className={styles.howSunfiWorks__wrapper__right}>
                    <h2 data-testid="how-sunfi-works-title">How SunFi works</h2>
                    <h6>
                        Get a Solar System Installed in Two Weeks with SunFi
                    </h6>

                    <div className={styles.stepsWrapper}>
                        {stepsMap.map((step, index) => (
                            <div
                                key={index}
                                className={`${styles.stepsWrapper__step} ${
                                    activeStep === index
                                        ? styles.stepsWrapper__step__active
                                        : previousActiveStep === index
                                        ? styles.stepsWrapper__step__previousActive
                                        : ''
                                }`}
                                onClick={() => handleStepClick(index)}
                                data-testid={`step-${index}`}
                            >
                                <h4>{step.title}</h4>
                                <p>{step.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowSunfiWorks
