import { ReactComponent as User } from 'src/assets/images/user-no-stroke.svg'
import { ReactComponent as Users } from 'src/assets/images/users-thick-no-stroke.svg'

export const addMembersMethods = [
    {
        title: 'Add a single member',
        description: 'Add one member to your group',
        key: 'single',
        icon: <User />,
    },
]

if (process.env.REACT_APP_FLAG_SHOW_ADD_MULTIPLE_MEMBERS_OPTIONS === 'true') {
    addMembersMethods.push({
        title: 'Add multiple group members',
        description: 'Add more than one member at a time',
        key: 'multiple',
        icon: <Users />,
    })
}

export const consumerModalValues = action => {
    switch (action) {
        case 'show-multiple-members-csv-file':
            return {
                primaryBtnText: 'Ready to upload',
            }
        default: {
            return {
                primaryBtnText: 'Continue',
            }
        }
    }
}
