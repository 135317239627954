import './paymentplanslistings.scss'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import { ReactComponent as SearchIcon } from 'src/assets/images/search.svg'
import { ReactComponent as FilterIcon } from 'src/assets/images/filter.svg'
import { Tabs, Pagination } from 'antd'
import PlansTable from './components/PlansTable'
import { MultipleSelectField } from 'src/components/InputFields'
import { getPaymentPlans } from 'src/api/admin/payment-plans/listings'
import { useEffect, useState } from 'react'
import FilterDrawer from './components/FilterDrawer'
import {
    campaignTypePayloadMap,
    newDEPStatusOptions,
    newDTCStatusOptions,
    providerTypeInverseMap,
    providerTypePayloadMap,
    statementTypePayloadMap,
    statusInverseMapping,
    statusOptions,
    onboardingTypePayloadMap,
} from './data'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import { format } from 'date-fns'
import EmptyState from 'src/components/EmptyState'
import SeoComponent from 'src/components/Seo'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import { getOfferStatusKeyByTitle, offerStatusMap } from 'src/utils/Data/status'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateAdminPaymentPlanFilters,
    updatedAdminPaymentPlanFilters,
} from 'src/redux/reducers/admin/payment-plan/listing'
import {
    convertQueryParamsToObject,
    toTitleCase,
    getKeyByValue,
} from 'src/utils/formatting'
import {
    addQueryParamsToUrl,
    getSearchParamsFromUrl,
    removeQueryParamFromUrl,
} from 'src/utils/urlTracker'

const PaymentPlansListings = () => {
    const paymentPlanTabs = {
        1: 'dtc',
        2: 'dep',
    }
    const filterQuery = getSearchParamsFromUrl()
    const defaultQueries = useSelector(updatedAdminPaymentPlanFilters)
    const dispatch = useDispatch()

    const [plans, setPlans] = useState([])
    const [totalPages, setTotalPages] = useState(null)
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [searchTextInput, setSearchTextInput] = useState(
        filterQuery.search || defaultQueries.name || '',
    )

    const formattedDefaultQueriesApplicationStatus = [
        filterQuery.applicationStatus || defaultQueries.application_status,
    ].map(status => ({
        label: offerStatusMap[status],
        value: offerStatusMap[status],
    }))

    const formattedDefaultQueriesStatus = () => {
        const status = defaultQueries.statuses?.split(',')?.map(status => ({
            label: statusInverseMapping[status],
            value: statusInverseMapping[status],
            remove:
                status === 'AWAITING_UPDATE' || status === 'CLOSED'
                    ? false
                    : true,
        }))

        return status?.filter(status => status.remove === true)
    }

    const [filterState, setFilterState] = useState({
        surveyType:
            filterQuery.surveyType || defaultQueries.survey_type || 'CFS,UFS',
        currentPage:
            Number(filterQuery.page) || Number(defaultQueries.page) || 1,
        searchText: filterQuery.search || defaultQueries.name || '',
        status:
            defaultQueries.statuses?.length > 0
                ? formattedDefaultQueriesStatus()
                : defaultQueries.application_status?.length > 0
                ? formattedDefaultQueriesApplicationStatus
                : [],
        filterInputs: {
            start_date:
                filterQuery.startDate || defaultQueries.start_date || null,
            end_date: filterQuery.endDate || defaultQueries.end_date || null,
            provider_type:
                defaultQueries.provider_type?.length > 0
                    ? [providerTypeInverseMap[defaultQueries.provider_type]]
                    : [],
            campaign_type:
                defaultQueries.campaign?.length > 0
                    ? toTitleCase(defaultQueries.campaign)
                    : '',
            payment_plan_type:
                filterQuery.paaymentPlan ||
                defaultQueries.payment_plan ||
                'Lease to Own,Subscription',
            statement_type:
                defaultQueries.bank_statement_source?.length > 0
                    ? [toTitleCase(defaultQueries.bank_statement_source)]
                    : [],

            onboarding_type:
                defaultQueries.onboarding_type?.length > 0
                    ? [toTitleCase(defaultQueries.onboarding_type)]
                    : [],
        },
    })

    const [currentTab, setCurrentTab] = useState(
        getKeyByValue(paymentPlanTabs, filterQuery.tab) ||
            defaultQueries.current_tab ||
            '1',
    )
    const [toastError, setToastError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const showUpdatedSSEFlow =
        process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW === 'true'

    const queryKey = [
        'fetch-payment-plans',
        filterState?.surveyType,
        filterState?.currentPage,
        filterState?.searchText,
        filterState?.status,
        filterState?.filterInputs?.start_date,
        filterState?.filterInputs?.end_date,
        filterState?.filterInputs?.payment_plan_type,
        filterState?.filterInputs?.provider_type,
        filterState?.filterInputs?.campaign_type,
        filterState?.filterInputs?.statement_type,
        currentTab,
    ]

    const { refetch: plansRefetch, isFetching: plansFetching } = useQuery(
        queryKey,
        () =>
            getPaymentPlans(
                filterState?.surveyType,
                filterState?.currentPage,
                filterState?.searchText,
                filterState?.status
                    ?.map(item => getOfferStatusKeyByTitle(item?.value))
                    ?.join(',') || '',
                (filterState?.filterInputs?.start_date !== null &&
                    format(
                        new Date(filterState?.filterInputs?.start_date),
                        'yyyy-MM-dd',
                    )) ||
                    '',
                (filterState?.filterInputs?.end_date !== null &&
                    format(
                        new Date(filterState?.filterInputs?.end_date),
                        'yyyy-MM-dd',
                    )) ||
                    '',
                filterState?.filterInputs?.payment_plan_type ||
                    'Lease to Own,Subscription',
                filterState?.filterInputs?.provider_type
                    ?.map(item => providerTypePayloadMap[item])
                    ?.join(',') || '',
                filterState?.filterInputs?.campaign_type !== ''
                    ? campaignTypePayloadMap[
                          filterState?.filterInputs?.campaign_type
                      ]
                    : '',
                filterState?.filterInputs?.statement_type?.length > 0
                    ? filterState?.filterInputs?.statement_type
                          ?.map(item => statementTypePayloadMap[item])
                          ?.join(',')
                    : '',
                showUpdatedSSEFlow,
                filterState?.filterInputs?.onboarding_type?.length > 0
                    ? filterState?.filterInputs?.onboarding_type
                          ?.map(item => onboardingTypePayloadMap[item])
                          ?.join(',')
                    : '',
            ),
        {
            enabled: true,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: res => {
                const queries = res?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')
                dispatch(
                    updateAdminPaymentPlanFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            `current_tab:${currentTab}`,
                        ]),
                    ),
                )
                const data = res?.data?.data
                setPlans(data)
                setTotalPages(res?.data?.total_pages)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const handleFilterChange = newFilters => {
        setFilterState(prevState => ({
            ...prevState,
            currentPage: 1,
            ...newFilters,
        }))
    }

    const onTabChange = key => {
        setCurrentTab(key)
        const newSurveyType = key === '1' ? 'CFS,UFS' : 'DFS'
        handleFilterChange({
            surveyType: newSurveyType,
            status: [],
            current_page: '',
            searchText: '',
            filterInputs: {
                start_date: null,
                end_date: null,
                provider_type: [],
                payment_plan_type: 'Lease to Own,Subscription',
                campaign_type: '',
                statement_type: [],
            },
        })
        addQueryParamsToUrl({
            tab: paymentPlanTabs[key],
            page: '1',
        })
        removeQueryParamFromUrl('search')
        setSearchTextInput('')
    }

    const updatePage = page => {
        handleFilterChange({ currentPage: page })
        addQueryParamsToUrl({
            page: page,
        })
    }

    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={1}
                current={filterState?.currentPage}
                total={parseInt(totalPages, 10)}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={updatePage}
            />
        )
    }

    const handleDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    const addSearchTextToFilter = () => {
        if (searchTextInput !== '') {
            handleFilterChange({ searchText: searchTextInput })
            addQueryParamsToUrl({
                search: searchTextInput,
                page: '1',
            })
        }
    }

    const newStatusOptions =
        currentTab === '1' ? newDTCStatusOptions : newDEPStatusOptions

    useEffect(() => {
        plansRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState])

    return (
        <>
            <SeoComponent title="SunFi | Admin | Payment Plans" />
            <FilterDrawer
                visible={drawerVisible}
                onClose={handleDrawer}
                filterState={filterState}
                setFilterState={setFilterState}
                setSearchTextInput={setSearchTextInput}
                currentTab={currentTab}
                data-testid="payment-plans-listings-filter-drawer"
            />
            {toastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                    data-testid="payment-plans-listings-error-toast"
                />
            )}
            <div className="PaymentPlansListings_Wrapper">
                <h1 className="PaymentPlansListings_Heading">Payment Plans</h1>
                <div className="PaymentPlansListings_SearchAndFilter">
                    <div className="PaymentPlansListings_Search">
                        <input
                            placeholder={
                                'Search by consumer name or provider name'
                            }
                            value={searchTextInput}
                            onChange={e => {
                                if (e.target.value === '') {
                                    setSearchTextInput('')
                                    handleFilterChange({ searchText: '' })
                                } else {
                                    const value = e.target.value
                                    setSearchTextInput(value)
                                }
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    addSearchTextToFilter()
                                    e.target.blur()
                                }
                            }}
                            onBlur={() => addSearchTextToFilter()}
                            data-testid="payment-plans-listings-search-input"
                        />
                        <SearchIcon
                            role="button"
                            onClick={() => plansRefetch()}
                        />
                    </div>
                    <div
                        className="PaymentPlansListings_FiltersBtn"
                        role="button"
                        onClick={() => handleDrawer()}
                        data-testid={'payment-plans-listings-filter-btn'}
                    >
                        <FilterIcon role="presentation" />
                        <span>Filters</span>
                    </div>
                </div>
                <div className="PaymentPlansListings_Tabs">
                    <div
                        className={`PaymentPlansListings_Tabs_Filter ${
                            plansFetching && 'isFetching'
                        }`}
                    >
                        <span>Filter by {'Status'}</span>
                        <MultipleSelectField
                            title={'All'}
                            name={'status'}
                            selectWidth={'171px'}
                            selectHeight={'48px'}
                            values={newStatusOptions}
                            value={filterState?.status?.map(
                                item => item?.value,
                            )}
                            inputValue={filterState?.status?.map(
                                item => item?.value,
                            )}
                            handleMultipleSelectChange={value => {
                                const values = value?.map(item => ({
                                    value: item,
                                    label: item,
                                    id: statusOptions?.filter(
                                        each => each.value === item,
                                    )?.[0]?.id,
                                }))
                                handleFilterChange({ status: values })
                            }}
                            maxTagCount={0}
                            optionStyle={{
                                width: '100%',
                                paddingRight: '12px',
                            }}
                        />
                    </div>
                    {plansFetching ? (
                        <ListingsSkeletalCards
                            total={2}
                            columnCount={5}
                            data-testid="payment-plans-listings-skeletal-cards"
                        />
                    ) : (
                        <Tabs activeKey={currentTab} onChange={onTabChange}>
                            <Tabs.TabPane tab="DTC Requests" key="1">
                                {plans?.length > 0 ? (
                                    <PlansTable view="DTC" data={plans} />
                                ) : (
                                    <EmptyState title="No payment plans found" />
                                )}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="DEP Requests" key="2">
                                {plans?.length > 0 ? (
                                    <PlansTable view="DEP" data={plans} />
                                ) : (
                                    <EmptyState title="No payment plans found" />
                                )}
                            </Tabs.TabPane>
                        </Tabs>
                    )}
                </div>

                {plans?.length > 0 && !plansFetching && (
                    <div className="PaymentPlansListings_Pagination">
                        {paginationBlock()}
                    </div>
                )}
            </div>
        </>
    )
}

PaymentPlansListings.propTypes = {
    defaultQuery: PropTypes.shape({
        page: PropTypes.string,
        current_tab: PropTypes.string,
        survey_type: PropTypes.string,
        name: PropTypes.string,
        application_status: PropTypes.array,
        statuses: PropTypes.array,
        start_date: PropTypes.any,
        end_date: PropTypes.any,
        provider_type: PropTypes.array,
        campaign: PropTypes.string,
        payment_plan_type: PropTypes.string,
        bank_statement_source: PropTypes.string,
    }),
}

export default PaymentPlansListings
