export const formatPackageComponents = components => {
    let formattedValues = []
    components.forEach(component => {
        formattedValues.push({
            name: component?.component?.name,
            count: component?.quantity,
            cost: component?.cost,
            id: component?.component?.id,
            type: component?.component?.type?.name,
            realCost: component?.component?.cost,
            capexReplacement: component?.capex_replacement_schedule,
            isGlobal: component?.component?.is_global,
            meta_data: { warranty: component?.component?.meta_data?.warranty },
        })
    })

    return formattedValues
}

export const formatComponents = components => {
    let formattedValues = []

    components.forEach(component => {
        formattedValues.push({
            quantity: parseInt(component?.count, 10),
            id: component?.id,
        })
    })

    return formattedValues
}
