import { useMutation, useQueryClient } from 'react-query'
import { sseIdentityVerification } from 'src/api/shoppingExperience/identity-verification/api'
import {
    sseSubmitEmploymentInfo,
    sseSubmitPersonalInfo,
} from 'src/api/shoppingExperience/kyc/api'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const usePersistKycInfo = (
    saveOrSubmitAction,
    onSaveSuccess,
    onSubmitSuccess,
    setActionModalToast,
    resetToast,
    moduleKey,
    onSubmitIdCardInfoSuccess,
) => {
    const queryClient = useQueryClient()
    const { errorAlert } = useCustomToast()

    const apiMapping = {
        personal_information: sseSubmitPersonalInfo,
        employment_information: sseSubmitEmploymentInfo,
        identity_information: sseIdentityVerification,
    }

    const { mutate, isLoading } = useMutation({
        mutationFn: payload => apiMapping[moduleKey](payload),
        onSuccess: res => {
            if (saveOrSubmitAction === 'Save') {
                queryClient.invalidateQueries('fetch-dynamic-kyc-info')
                onSaveSuccess()
            } else if (saveOrSubmitAction === 'Submit-id-card-info') {
                onSubmitIdCardInfoSuccess(res)
            } else {
                onSubmitSuccess()
            }
        },
        onError: error => {
            if (
                saveOrSubmitAction === 'Save' ||
                saveOrSubmitAction === 'Submit-id-card-info'
            ) {
                setActionModalToast({
                    showToast: true,
                    toastMessage:
                        errorHandler(error?.response?.data) ||
                        'An error occurred, please try again later.',
                    messageType: 'error-secondary',
                })
                resetToast()
            } else {
                errorAlert(
                    errorHandler(
                        error?.response?.data ||
                            'An error occurred, please try again later.',
                    ),
                )
            }
        },
    })

    return {
        persistInfoMutate: mutate,
        persistInfoLoading: isLoading,
    }
}

export default usePersistKycInfo
