//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import Styles from './workexperience.module.scss'
import { format } from 'date-fns'
import EmptyState from 'src/components/EmptyState'
import { useQuery } from 'react-query'
import { getWorkExperienceApi } from 'src/api/admin/provider'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import { Skeleton } from 'antd'
const WorkExperience = ({ providerId }) => {
    const {
        WEWrapper,
        WECardWrapper,
        WECompanyName,
        WEJobTitle,
        WEDescription,
        WEFooterLabel,
        WEFooterContent,
        WEBottomWrapper,
        WELeftItem,
        WERightItem,
    } = Styles

    const [workExperienceData, setWorkExperienceData] = useState([])
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    //get provider work experience
    const { refetch: workExperienceRefetch, isLoading: workExperienceLoading } =
        useQuery(
            ['get-work-experience'],
            () => getWorkExperienceApi(providerId),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setWorkExperienceData(data?.data?.data)
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    useEffect(() => {
        workExperienceRefetch()
    }, [])

    return (
        <>
            {workExperienceLoading ? (
                <div>
                    {[...Array(2)].map(i => (
                        <div key={i} className={WECardWrapper}>
                            <Skeleton.Input className={WECompanyName} active />
                            <Skeleton.Input className={WEJobTitle} active />
                            <Skeleton.Input className={WEDescription} active />
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    {toastError && (
                        <Toast messageType="error" message={errorMessage} />
                    )}
                    {workExperienceData?.length < 1 ? (
                        <EmptyState subTitle="This provider has not yet updated their work experience." />
                    ) : (
                        <div className={WEWrapper}>
                            {workExperienceData.map(item => (
                                <div key={item.id} className={WECardWrapper}>
                                    <h4 className={WECompanyName}>
                                        {item?.name}
                                    </h4>
                                    <h2 className={WEJobTitle}>{item?.role}</h2>
                                    <p className={WEDescription}>
                                        {item?.description}
                                    </p>
                                    <div className={WEBottomWrapper}>
                                        <div className={WELeftItem}>
                                            <h4 className={WEFooterLabel}>
                                                DATE
                                            </h4>
                                            <p className={WEFooterContent}>
                                                {format(
                                                    new Date(item?.start_date),
                                                    'MMM yyyy',
                                                )}{' '}
                                                -{' '}
                                                {item?.end_date === null
                                                    ? 'Present'
                                                    : format(
                                                          new Date(
                                                              item?.end_date,
                                                          ),
                                                          'MMM yyyy',
                                                      )}
                                            </p>
                                        </div>
                                        <div>
                                            <div className={WERightItem}>
                                                <h4 className={WEFooterLabel}>
                                                    TECHNOLOGIES
                                                </h4>
                                                <p className={WEFooterContent}>
                                                    {item?.technologies?.map(
                                                        (each, i) => (
                                                            <span key={i}>
                                                                {each}
                                                                {item
                                                                    ?.technologies
                                                                    ?.length -
                                                                    1 ===
                                                                i
                                                                    ? ''
                                                                    : ', '}
                                                            </span>
                                                        ),
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </>
    )
}

WorkExperience.propTypes = {
    providerId: PropTypes.string,
}

export default WorkExperience
