/* eslint-disable react/prop-types */
import { createContext, useContext, useState } from 'react'

const AdminPlanContext = createContext({
    setTriggerRefetch: () => {},
    setInputs: () => {},
})

const AdminApprovedProvider = ({ children }) => {
    // content on the to-do table
    const [toDoContent, setToDoContent] = useState([])
    // content under more actions
    const [moreActionsContent, setMoreActionsContent] = useState([])
    // content under record deposit
    const [recordDepositContent, setRecordDepositContent] = useState([])
    // content under (record) a repayment
    const [repaymentDepositContent, setRepaymentDepositContent] = useState([])
    // content under record a payment (for outright sale)
    const [recordPaymentContent, setRecordPaymentContent] = useState([])
    // content under start a repayment schedule
    const [repaymentScheduleContent, setRepaymentScheduleContent] = useState([])
    // holds updated files
    const [successUpload, setSuccessUpload] = useState([])
    // open modal
    const [openModal, setOpenModal] = useState(false)
    // general inputs
    const [inputs, setInputs] = useState({
        dateOfAction: null,
        financingStartDate: null,
        firstRepaymentDate: null,
        timeOfAction: null,
        dateTime: '',
        repaymentMonth: null,
        repaymentMethod: '',
        evidenceOfAction: [],
        repaymentMethodSetupType: null,
        repaymentMethodSetupSubscriptionCode: '',
        installedSolutionEvidenceDriveLink: '',
        updatePlanTenure: null,
        updatePlanTenurePlanId: null,
        closureReason: null,
        recordRepaymentTotalAmount: '',
        upfrontDepositTotalAmount: '',
        providerPaymentTotalAmount: '',
        outrightSaleTotalAmount: '',
    })

    //
    const [showUploadStandingOrder, setShowUploadStandingOrder] =
        useState(false)
    const [showPaystackSubscriptionCode, setShowPaystackSubscriptionCode] =
        useState(false)

    // state for right date picker
    const [currentDatePaid, setCurrentDatePaid] = useState(
        inputs.dateOfAction === null ? null : new Date(inputs.dateOfAction),
    )
    // state for left date picker
    const [firstRepaymentDate, setRepaymentDate] = useState(
        inputs.firstRepaymentDate === null
            ? null
            : new Date(inputs.firstRepaymentDate),
    )
    const [hour, setHour] = useState('')
    const [minute, setMinute] = useState('')
    const [time, setTime] = useState('')
    // set time picker dropdown
    const [showTimeDropdown, setShowTimeDropdown] = useState(false)
    const [dateTime, setDateTime] = useState('')
    // decides which values will be loaded into the modal
    const [modalDisplayValues, setModalDisplayValues] = useState('')
    // input errors
    const [errors, setErrors] = useState('')
    const [uploadError, setUploadError] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [successHeaderText, setSuccessHeaderText] = useState('')
    const [successModalSubtitle, setSuccessModalSubtitle] = useState('')
    const [investmentMetaData, setInvestmentMetaData] = useState([])
    const [triggerRefetch, setTriggerRefetch] = useState(false)
    const [closureModal, setClosureModal] = useState(false)
    const [showPackagesDrawer, setShowPackagesDrawer] = useState(false)
    const paymentMethods = [
        'Auto Payment Link',
        'Bank Transfer',
        'Post Dated Cheque',
        'Direct Deposit',
    ]
    return (
        <AdminPlanContext.Provider
            value={{
                toDoContent,
                setToDoContent,
                openModal,
                setOpenModal,
                successModal,
                setSuccessModal,
                successHeaderText,
                setSuccessHeaderText,
                inputs,
                setInputs,
                currentDatePaid,
                setCurrentDatePaid,
                firstRepaymentDate,
                setRepaymentDate,
                hour,
                setHour,
                minute,
                setMinute,
                time,
                setTime,
                showTimeDropdown,
                setShowTimeDropdown,
                dateTime,
                setDateTime,
                errors,
                setErrors,
                uploadError,
                setUploadError,
                successUpload,
                setSuccessUpload,
                modalDisplayValues,
                setModalDisplayValues,
                moreActionsContent,
                setMoreActionsContent,
                recordDepositContent,
                setRecordDepositContent,
                repaymentDepositContent,
                setRepaymentDepositContent,
                repaymentScheduleContent,
                setRepaymentScheduleContent,
                investmentMetaData,
                setInvestmentMetaData,
                triggerRefetch,
                setTriggerRefetch,
                showUploadStandingOrder,
                setShowUploadStandingOrder,
                showPaystackSubscriptionCode,
                setShowPaystackSubscriptionCode,
                closureModal,
                setClosureModal,
                showPackagesDrawer,
                setShowPackagesDrawer,
                paymentMethods,
                recordPaymentContent,
                setRecordPaymentContent,
                successModalSubtitle,
                setSuccessModalSubtitle,
            }}
        >
            {children}
        </AdminPlanContext.Provider>
    )
}

export const usePlanContext = () => useContext(AdminPlanContext)
export default AdminApprovedProvider
