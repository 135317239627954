import { ReactComponent as StackImg } from 'src/assets/images/stack-no-stroke.svg'
import { ReactComponent as StackTwoImg } from 'src/assets/images/stack-2-no-stroke.svg'

export const variableEditMethods = [
    {
        title: 'Apply one change to multiple variables',
        description:
            'Select a single component to modify across multiple credit variables at once',
        key: 'one-change-to-multiple',
        icon: <StackImg />,
    },
    {
        title: 'Edit multiple variables individually',
        description:
            'Choose variables first, then edit their individual components separately',
        key: 'multiple-variables-individually',
        icon: <StackTwoImg />,
    },
]
