import { sourceMap } from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/FinancialInformation/FinancialInfoForm/BankStatement/utils'
import { appTracking } from './appTracker'
import { currencyFormatter } from './currencyFormatter'

export const consumerAttributesMixpanel = data => {
    const statement_source =
        data?.financial_verification?.bank_statements?.find(
            item => item.analysis_context?.status === 'DONE',
        )?.source
    appTracking('', '', '', '', '', '', ['MP'], 'people_set', {
        people_set: {
            'Payment tenure':
                data?.estimated_repayment_info?.tenure + ' Months',
            'System capacity':
                data?.recommended_solution?.energy_capacity?.value +
                data?.recommended_solution?.energy_capacity
                    ?.unit_of_measurement,
            'Cost of system': currencyFormatter(
                data?.recommended_solution?.total_cost ?? 0,
            ),
            'Entry channel':
                data?.onboarding_type === 'ALL_SOLUTIONS'
                    ? 'View all available systems'
                    : data?.profile_info?.promotional_type === 'PRIVATE' &&
                      data?.profile_info?.is_promotional
                    ? 'Private Promotional Cluster'
                    : data?.profile_info?.promotional_type === 'PUBLIC' &&
                      data?.profile_info?.is_promotional
                    ? 'Public Promotional Cluster'
                    : 'Non-Promotional Consumer Profile',
            'Direct debt repayment setup type': '',
            'Identity type': data?.identity_verification?.type,
            'Customer type': data?.customer_type,
            'Contact address': data?.consumer?.user?.street_address,
            'Contact city': data?.consumer?.user?.city,
            'Contact state': data?.consumer?.user?.state_of_residence,
            'Contact country': data?.consumer?.user?.country,
            'Installation address':
                data?.consumer?.installation_details?.street_address,
            'Installation city': data?.consumer?.installation_details?.city,
            'Installation state': data?.consumer?.installation_details?.state,
            'Installation Country':
                data?.consumer?.installation_details?.country,
            'Solar panels': data?.recommended_solution?.has_solar_panel
                ? 'Yes'
                : 'No',
            'Employment type': data?.consumer?.employment_status,
            'Job title': data?.consumer?.employment_details?.job_title,
            'Business type': data?.consumer?.business?.business_type,
            'Business industry': data?.consumer?.business?.business_industry,
            'Business name': data?.consumer?.business?.business_name,
            'Registered business':
                data?.consumer?.business?.business_registration_number?.length >
                0
                    ? data?.consumer?.business
                          ?.business_registration_number_verified
                        ? 'Yes'
                        : 'No'
                    : 'N/A',
            'Bank statement type':
                statement_source === sourceMap.MANUAL_UPLOAD
                    ? 'Manual Upload'
                    : statement_source === sourceMap.MONO
                    ? 'Mono'
                    : statement_source === sourceMap.OKRA
                    ? 'Okra'
                    : '',
        },
    })
}
