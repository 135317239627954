import { useQueryClient } from 'react-query'
import useVerifyCac from '../../../PersonalInformation/BusinessForm/hooks/useVerifyCac'
import { useEffect, useState } from 'react'
import { formatInputValue } from 'src/utils/formatting'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import { validateCacDetails } from 'src/utils/validationSchema'
import { useCustomToast } from 'src/utils/Hooks/useToast'

const useVerifyCacModal = (onCancel, inputs, onSuccess) => {
    const [modalInputs, setModalInputs] = useState({
        businessName: '',
        cacNumber: '',
    })
    const [verificationFailedModal, setVerificationFailedModal] =
        useState(false)
    const [modalOnlyInputError, setModalOnlyInputError] = useState({})

    const validateField = useValidateOnBlur()
    const { successAlert } = useCustomToast()

    const handleModalInputError = (action, field, msg) => {
        if (action === 'add') {
            setModalOnlyInputError(prevState => ({
                ...prevState,
                [field]: msg,
            }))
        } else {
            setModalOnlyInputError(prevState => {
                const newState = { ...prevState }
                delete newState[field]
                return newState
            })
        }
    }

    const queryClient = useQueryClient()
    const refetchKyc = () => {
        successAlert('CAC number verified successfully')
        queryClient.invalidateQueries('fetch-dynamic-kyc-info')
        onCancel()
    }

    const closeModal = () => {
        setModalInputs({
            businessName: inputs?.businessName,
            cacNumber: inputs?.cacNumber,
        })
        setModalOnlyInputError({})
    }

    const handleSubmit = () => {
        validateCacDetails
            .validate(modalInputs, {
                abortEarly: false,
            })
            .then(() => {
                confirmCac()
                setModalOnlyInputError({})
                onSuccess()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setModalOnlyInputError(errList)
            })
    }

    const {
        validateCacMutation,
        setToastMessage,
        modalToastType,
        modalToastMessage,
    } = useVerifyCac({
        closeModal,
        refetch: refetchKyc,
        setVerificationFailedModal,
        handleModalInputError,
    })

    const confirmCac = () => {
        validateCacMutation.mutate({
            cac_number: modalInputs?.cacNumber,
            business_name: modalInputs?.businessName,
        })
    }

    const removeErrors = name => {
        setModalOnlyInputError(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const handleChange = e => {
        const { name, value } = e.target

        setModalInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
        removeErrors(name)
    }

    const handleOnBlur = async e => {
        const { name, value } = e.target

        await validateField({
            name,
            value,
            schema: validateCacDetails,
            setErrors: setModalOnlyInputError,
        })
    }

    useEffect(() => {
        setModalInputs({
            businessName: inputs?.businessName,
            cacNumber: inputs?.cacNumber,
        })
    }, [inputs?.businessName, inputs?.cacNumber])

    return {
        verificationFailedModal,
        validateCacMutation,
        setToastMessage,
        modalToastMessage,
        modalToastType,
        modalOnlyInputError,
        modalInputs,
        closeModal,
        handleChange,
        handleOnBlur,
        handleSubmit,
    }
}

export default useVerifyCacModal
