/* eslint-disable no-undef */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Widget } from 'react-typeform-embed'
import GetModal from '../Modal/index'

import { useHistory } from 'react-router-dom'
import Button from '../Button'

import styles from './cta.module.scss'

const {
    CtaBox,
    Title,
    PartnersTitle,
    SubTitle,
    ActionBox,
    TextBoxContainer,
    TextBox,
} = styles

const CtaSection = ({ title, subtitle, page, bgColor }) => {
    const [showModal, setShowModal] = useState(false)
    const history = useHistory()

    const getOfferTypeForm = (
        <Widget
            id={`${process.env.REACT_APP_GET_OFFER_FORM_URL}`}
            height={700}
        />
    )

    const handleCancel = () => {
        setShowModal(false)
    }

    return (
        <div
            className={CtaBox}
            style={{
                backgroundColor: bgColor,
            }}
        >
            <div className={TextBoxContainer}>
                <div className={TextBox}>
                    <div className={SubTitle}>{title}</div>
                    <div
                        className={page === 'partners' ? PartnersTitle : Title}
                    >
                        {subtitle || 'Simplifying and Scaling Clean Energy'}
                    </div>
                    <div className={ActionBox}>
                        <>
                            <Button
                                btnBgColor="#FFF"
                                btnTextColor="#004AAD"
                                btnWidth="160px"
                                btnHeight="54px"
                                fontSize="14px"
                                handleClick={() =>
                                    history.push(
                                        page === 'partners' ? '/signup' : '/',
                                    )
                                }
                            >
                                {page === 'partners'
                                    ? 'Partner With Us'
                                    : 'Get Your Offer'}
                            </Button>
                        </>
                    </div>
                </div>
            </div>

            <GetModal
                showModal={showModal}
                onCancel={handleCancel}
                content={getOfferTypeForm}
            />
        </div>
    )
}

CtaSection.propTypes = {
    title: PropTypes.any,
    subtitle: PropTypes.any,
    page: PropTypes.any,
    bgColor: PropTypes.any,
}

export default CtaSection
