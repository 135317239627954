import axios from '../../../config/axios'

export const getSingleAdminProviderApi = async id => {
    const request = await axios.get(`admin/provider/${id}`)
    return request
}

export const getAdminProviderUsersApi = async id => {
    const request = await axios.get(`admin/provider/${id}/users`)
    return request
}

export const changeProviderTypeApi = async (id, type) => {
    const request = await axios.post(`admin/provider/${id}/type-change`, {
        provider_type: `${type}`,
    })

    return request
}

export const deactivateAdminProviderApi = async (id, reason) => {
    const request = await axios.post(`admin/users/${id}/deactivate`, {
        reason: reason,
    })
    return request
}

export const inviteProviderUserApi = async (providerId, users) => {
    const request = await axios.post(`admin/provider/invite-provider`, {
        provider_id: providerId,
        provider_users: users,
    })
    return request
}

export const resendProviderUserInviteApi = async email => {
    const request = await axios.post(`admin/provider/resend-invitation`, {
        email: email,
    })
    return request
}

export const revokeProviderUserInviteApi = async userId => {
    const request = await axios.post(
        `admin/provider/${userId}/revoke-invitation`,
    )
    return request
}

export const getNonAssignedResourcesApi = async providerId => {
    const request = await axios.get(
        `admin/provider/${providerId}/non-assigned-resources`,
    )
    return request
}

//endpoint to get groups (permission roles)
export const getActiveProviderGroupsApi = async providerId => {
    const request = await axios.get(
        `admin/roles?workspace=provider&provider_id=${providerId}&state=active&paginate=false`,
    )
    return request
}

export const assignProviderResourceApi = async (providerId, resource) => {
    const request = await axios.post(
        `admin/provider/${providerId}/assign-resource`,
        {
            resource_name: resource,
        },
    )
    return request
}

export const removeProviderResourceApi = async (providerId, resource) => {
    const request = await axios.post(
        `admin/provider/${providerId}/remove-resource`,
        resource,
    )
    return request
}

export const updateProviderUserDetailsApi = async (userId, data) => {
    const request = await axios.post(
        `admin/provider/${userId}/update-provider-user`,
        data,
    )
    return request
}

export const getWorkExperienceApi = async providerId => {
    const request = await axios.get(`admin/provider/${providerId}/work-history`)
    return request
}

export const updateProviderStatusApi = async (id, data) => {
    const request = await axios.post(
        `/admin/provider/${id}/update-status`,
        data,
    )
    return request
}

export const getBrandNamesApi = async (providerId, status = 'APPROVED') => {
    const request = await axios.get(
        `/admin/component-brands?paginate=false${
            providerId ? '&provider_id=' + providerId : ''
        }${status ? '&status=' + status : ''}`,
    )
    return request
}

export const updateBrandNameAdminApi = async (providerId, inputs) => {
    const request = await axios.post(
        `/admin/component-brands/update-provider-brand/${providerId}`,
        inputs,
    )
    return request
}
