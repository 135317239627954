/* eslint-disable react/prop-types */
import styles from '../recentupdate.module.scss'

const IdentityUpdate = ({ page }) => {
    const {
        SelectSolution,
        BtnStyle,
        SelectEnergy,
        SelectEnergyText,
        CommentWrapper,
        CommentWrapperMobile,
        CommentText,
    } = styles

    return (
        <div
            className={SelectSolution}
            style={{
                height: 'fit-content',
            }}
        >
            <div className={SelectEnergy}>
                <div className={CommentWrapper}>
                    <p className={CommentText}>
                        It&apos;s very nice to have you here. We&apos;ve
                        collected your energy information. Now we need you to
                        profile your personal information. Please note that your
                    </p>
                    <p className={SelectEnergyText}>Tobi Awonusi</p>
                </div>
                <div className={CommentWrapperMobile} onClick={() => {}}>
                    <p className={CommentText}>
                        It&apos;s very nice to have you here. We&apos;ve
                        collected your energy information. Now we need you to
                        profile your personal information. Please note that your
                    </p>
                    <p className={SelectEnergyText}>Tobi Awonusi</p>
                </div>
            </div>
            <button className={BtnStyle} onClick={() => {}}>
                {page === 'request-details' ? 'Edit Request' : 'Upload File'}
            </button>
        </div>
    )
}

export default IdentityUpdate
