import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { InputFields } from 'src/components/InputFields'
import 'src/pages/Consumer/SmartShoppingExperience/AccountSetupOverview/accountsetupoverview.scss'
import { isMobile } from 'src/utils/mediaQueries'

const ConfirmEmail = ({
    email,
    setEmail,
    emailError,
    showModal,
    onCancel,
    handleClick,
    loading,
}) => {
    return (
        <SecondaryModal
            title={'Save and continue later'}
            showModal={showModal}
            onCancel={onCancel}
            showFooter
            primaryBtnText={'Confirm'}
            primaryBtnWidth={isMobile ? '127.15px' : ''}
            handlePrimaryBtnClick={handleClick}
            primaryBtnLoading={loading}
            footerLeftContent={''}
            v2
            content={
                <div
                    className={'GettingStartedWrapper'}
                    data-testid="confirm-email-modal"
                >
                    <div className={'GettingStartedHeadingAndText'}>
                        <h3>Add your email address</h3>
                        <p>
                            To save and continue later, we need your email
                            address to send you the details on how you can
                            continue
                        </p>
                        <InputFields
                            title={'Email Address'}
                            name={'email'}
                            value={email}
                            errorMessage={emailError}
                            handleChange={e => {
                                setEmail(e.target.value)
                            }}
                        />
                    </div>
                </div>
            }
        />
    )
}

ConfirmEmail.propTypes = {
    email: PropTypes.string,
    setEmail: PropTypes.func,
    emailError: PropTypes.string,
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    handleClick: PropTypes.func,
    loading: PropTypes.bool,
}

export default ConfirmEmail
