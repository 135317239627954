import { Upload } from 'antd'
import { format, getTime } from 'date-fns'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ConsumerButton } from 'src/components/Button'
import { DateField } from 'src/components/InputFields'
import { InputFields } from 'src/components/InputFields'
import { TextArea } from 'src/components/InputFields'
import { SelectField } from 'src/components/InputFields'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { errorHandler, formatYupError } from 'src/utils/errorHandler'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import {
    updateRepaymentByCustomerSchema,
    updateRepaymentSetupSchema,
} from 'src/utils/validationSchema'
import SuccessUpload from 'src/assets/images/successUpload.svg'
import UploadLogo from 'src/assets/images/upload.svg'
import PropTypes from 'prop-types'
import Toast from 'src/components/Toast'
import { usePlanContext } from '../../hooks/planProvider'
import {
    repaymentActions,
    repaymentMethodValues,
    repaymentMethods,
    whoToUpdateData,
} from '../../utils/data'
import {
    useRequestRepaymentUpdateFromConsumer,
    useUpdateRepaymentSetup,
    useUpdateFirstRepaymentDateApi,
} from 'src/api/admin/payment-plans/ssePlan'
import { isNotInconsistentDate } from 'src/utils/dateFilter'
import './updaterepayment.scss'

const UpdateRepaymentModal = ({
    showUpdateRepaymentModal,
    setShowUpdateRepaymentModal,
    paymentPlanDataRefetch,
    paymentPlanData,
}) => {
    const params = useParams()
    const { Dragger } = Upload

    const draggerprops = {
        name: 'file',

        onChange({ fileList }) {
            setIsUploadingStandingOrder(true)
            setStandingOrder(fileList)
            setIsUploadingStandingOrder(false)
        },
        onDrop() {
            setIsUploadingStandingOrder(false)
        },
    }
    const {
        inputs,
        setInputs,
        currentDatePaid,
        setCurrentDatePaid,
        firstRepaymentDate,
        setRepaymentDate,
    } = usePlanContext()

    const [notes, setNotes] = useState('')
    const [showCalendar, setShowCalendar] = useState(0)
    const [isUploadingStandingOrder, setIsUploadingStandingOrder] =
        useState(false)
    const [repaymentAction, setRepaymentAction] = useState('')
    const [standingOrderValid, setStandingOrderValid] = useState(false)
    const [newRepaymentDate, setNewRepaymentDate] = useState(
        paymentPlanData?.repayment_method_setup?.created_at || '',
    )
    const [updateRepaymentErrors, setUpdateRepaymentErrors] = useState({
        subscriptionCode: '',
        repaymentMethod: '',
        newRepaymentDate: '',
        repaymentAction: '',
        repayment_method_setup_file: '',
        dateOfAction: '',
        firstRepaymentDate: '',
    })
    const [subscriptionCode, setSubscriptionCode] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [toastError, setToastError] = useState(false)
    const [toastSuccess, setToastSuccess] = useState(false)
    const handleInputChange = event => {
        const { value } = event.target
        setNotes(value)
    }
    const [customerRequestError, setCustomerRequestError] = useState({
        whoToUpdate: '',
        notes: '',
    })
    const { isMobile } = useMediaQueries()

    const [standingOrder, setStandingOrder] = useState([])
    const [whoToUpdate, setWhoToUpdate] = useState('')
    const {
        mutate: updateRepaymentSetupMutate,
        isLoading: isUpdateRepaymentLoading,
    } = useUpdateRepaymentSetup()

    const {
        mutate: requestRepaymentFromCustomer,
        isLoading: isRequestingFromCustomer,
    } = useRequestRepaymentUpdateFromConsumer()

    const {
        mutate: updateRepaymentDateMutate,
        isLoading: isUpdateFirstRepaymentDateLoading,
    } = useUpdateFirstRepaymentDateApi()

    const clearState = resetInputDates => {
        setNotes('')
        setRepaymentAction('')
        setWhoToUpdate('')
        setSubscriptionCode('')
        setStandingOrder([])
        setInputs(prev => ({
            ...prev,
            ...(resetInputDates
                ? { dateOfAction: null, firstRepaymentDate: null }
                : {
                      dateOfAction: paymentPlanData?.financing_start_date,
                      firstRepaymentDate:
                          paymentPlanData?.repayment_schedule?.[0] &&
                          format(
                              new Date(
                                  paymentPlanData.repayment_schedule[0].due_date,
                              ),
                              "yyyy-MM-dd'T'hh:mm",
                          ),
                  }),
        }))
        setNewRepaymentDate(paymentPlanData?.repayment_method_setup?.created_at)
        setUpdateRepaymentErrors({
            subscriptionCode: '',
            newRepaymentDate: '',
            repaymentMethod: '',
            repayment_method_setup_file: '',
            repaymentAction: '',
            dateOfAction: '',
            firstRepaymentDate: '',
        })
    }
    const successActions = res => {
        setToastSuccess(true)
        setSuccessMessage(res.message || 'Repayment setup updated successfully')
        paymentPlanDataRefetch()
        clearState()
        setTimeout(() => {
            setShowUpdateRepaymentModal(false)
            setSuccessMessage('')
        }, 4000)
    }

    const handleUpdateRepaymentSetup = () => {
        if (repaymentAction === 'Change repayment day') {
            if (!newRepaymentDate) {
                setUpdateRepaymentErrors(prev => ({
                    ...prev,
                    newRepaymentDate: 'Select new repayment day',
                }))
                return
            }
            const payload = {
                action: 'UPDATE_REPAYMENT_DAY',
                new_repayment_day: new Date(newRepaymentDate).getDate(),
            }
            updateRepaymentSetupMutate(
                { id: params?.id, body: payload },
                {
                    onSuccess: res => {
                        successActions(res)
                    },
                    onError: err => {
                        setErrorMessage(errorHandler(err))
                        setToastError(true)
                    },
                },
            )
            return
        }

        if (repaymentAction === 'Change repayment schedule') {
            let timeDifference =
                getTime(new Date(inputs.firstRepaymentDate)) -
                getTime(new Date(inputs.dateOfAction))

            if (!inputs.dateOfAction) {
                setUpdateRepaymentErrors(prev => ({
                    ...prev,
                    dateOfAction: 'Select new financing date',
                }))
                return
            } else if (!inputs.firstRepaymentDate) {
                setUpdateRepaymentErrors(prev => ({
                    ...prev,
                    firstRepaymentDate: 'Select new repayment date',
                }))
                return
            }

            if (timeDifference < 0) {
                setUpdateRepaymentErrors(prev => ({
                    ...prev,
                    firstRepaymentDate:
                        'First Repayment date should not be earlier than Financing start date',
                }))
                return
            }

            const payload = {
                new_financing_start_date: format(
                    new Date(inputs?.dateOfAction),
                    "yyyy-MM-dd'T'hh:mm",
                ),
                first_repayment_date: format(
                    new Date(inputs?.firstRepaymentDate),
                    "yyyy-MM-dd'T'hh:mm",
                ),
            }
            updateRepaymentDateMutate(
                { id: params?.id, body: payload },
                {
                    onSuccess: res => {
                        successActions(res)
                    },
                    onError: err => {
                        setErrorMessage(errorHandler(err))
                        setToastError(true)
                    },
                },
            )
            return
        }

        updateRepaymentSetupSchema
            .validate(
                {
                    subscriptionCode,
                    newRepaymentDate,
                    repaymentMethod: !initialRepaymentMethod
                        ? inputs.repaymentMethod
                        : initialRepaymentMethod,
                    repayment_method_setup_file: standingOrder,
                    repaymentAction,
                },
                { abortEarly: false },
            )
            .then(() => {
                setUpdateRepaymentErrors({
                    subscriptionCode: '',
                    newRepaymentDate: '',
                    repaymentMethod: '',
                    repayment_method_setup_file: '',
                    repaymentAction: '',
                    dateOfAction: '',
                    firstRepaymentDate: '',
                })
                let payload = {}
                if (repaymentAction === 'Select new repayment method') {
                    payload = {
                        action: 'REPAYMENT_METHOD_SETUP',
                        repayment_method_setup_type:
                            repaymentMethodValues[inputs.repaymentMethod],

                        repayment_method_setup_subscription_code:
                            subscriptionCode || undefined,
                        repayment_method_setup_file:
                            standingOrder.length > 0 &&
                            inputs.repaymentMethod ===
                                'Bank transfer (Standing order)'
                                ? standingOrder?.[0]?.originFileObj
                                : undefined,
                        repayment_method_setup_created_at: new Date(
                            newRepaymentDate,
                        ).toISOString(),
                    }
                }
                updateRepaymentSetupMutate(
                    { id: params?.id, body: payload },
                    {
                        onSuccess: res => {
                            successActions(res)
                        },
                        onError: err => {
                            setErrorMessage(errorHandler(err))
                            setToastError(true)
                        },
                    },
                )
            })
            .catch(err => {
                const errList = formatYupError(err)
                setUpdateRepaymentErrors(errList)
            })
    }
    const handleRequestFromCustomer = () => {
        updateRepaymentByCustomerSchema
            .validate(
                {
                    notes,
                    whoToUpdate,
                },
                { abortEarly: false },
            )
            .then(() => {
                setCustomerRequestError({
                    whoToUpdate: '',
                    notes: '',
                })
                requestRepaymentFromCustomer(
                    { id: params?.id, notes },
                    {
                        onSuccess: res => {
                            successActions(res)
                        },
                        onError: err => {
                            setErrorMessage(errorHandler(err))
                            setToastError(true)
                        },
                    },
                )
            })
            .catch(err => {
                const errList = formatYupError(err)
                setCustomerRequestError(errList)
            })
    }
    const initialRepaymentMethod = Object.entries(repaymentMethodValues).filter(
        ([, v]) => v === paymentPlanData?.repayment_method_setup?.type,
    )?.[0]?.[0]

    const beforeStandingOrderUpload = file => {
        const isLt3M = file.size / 1000 / 1000 < 3

        if (!isLt3M) {
            setErrorMessage('File size should be less than 3mb.')
            setToastError(true)
            setTimeout(() => {
                setErrorMessage('')
                setToastError(false)
            }, 4000)
            setStandingOrderValid(false)
            return false
        } else {
            setStandingOrderValid(true)
            return true
        }
    }
    const handleRepaymentMethodChange = (name, value) => {
        setInputs(prev => ({ ...prev, [name]: value }))
    }
    useEffect(() => {
        if (showUpdateRepaymentModal) {
            setInputs(prev => ({
                ...prev,
                repaymentMethod: initialRepaymentMethod,
                dateOfAction: paymentPlanData?.financing_start_date,

                firstRepaymentDate:
                    paymentPlanData?.repayment_schedule?.[0] &&
                    format(
                        new Date(
                            paymentPlanData.repayment_schedule[0].due_date,
                        ),
                        "yyyy-MM-dd'T'hh:mm",
                    ),
            }))
            setNewRepaymentDate(
                paymentPlanData?.repayment_method_setup?.created_at,
            )
        }
    }, [
        initialRepaymentMethod,
        paymentPlanData,
        setInputs,
        showUpdateRepaymentModal,
    ])

    useEffect(() => {
        if (repaymentAction === 'Change repayment day') {
            setInputs(prev => ({ ...prev, repaymentMethod: '' }))
        }
    }, [repaymentAction, setInputs])

    // handle change on datepickers
    const handleDateChange = (name, date) => {
        setUpdateRepaymentErrors({
            dateOfAction: '',
            firstRepaymentDate: '',
        })
        if (name === 'dateOfAction') {
            setCurrentDatePaid(format(date, 'MM/dd/yyyy'))
        }

        if (name === 'firstRepaymentDate') {
            setRepaymentDate(format(date, 'MM/dd/yyyy'))
        }
    }

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]:
                name === 'dateOfAction' ? currentDatePaid : firstRepaymentDate,
        }))
    }

    return (
        <SecondaryModal
            modalWidth="624px"
            modalHeight="675px"
            modalClass="RecommendedEnergy"
            showModal={showUpdateRepaymentModal}
            onCancel={() => {
                setShowUpdateRepaymentModal(false)
                clearState(true)
            }}
            closable={true}
            content={
                <div className="UpdateRepaymentContainer">
                    {toastError && (
                        <Toast
                            position="absolute"
                            left="0%"
                            messageType="error"
                            message={errorMessage}
                        />
                    )}
                    {toastSuccess && (
                        <Toast
                            position="absolute"
                            left="0%"
                            messageType="success"
                            message={successMessage}
                        />
                    )}
                    <div className="ModalHeading">
                        <h2>Update repayment setup</h2>
                        <p>
                            Only update the field the consumer has requested an
                            update on
                        </p>
                    </div>
                    <div className="FormFieldsContainer">
                        <SelectField
                            withCheckBox
                            values={whoToUpdateData}
                            value={whoToUpdate}
                            selectWidth="100%"
                            initialOption="How do you want to update the setup"
                            handleChange={(name, value) => {
                                setWhoToUpdate(value)
                            }}
                            name="whoToUpdate"
                            currentSelected={whoToUpdate}
                            floatingLabel={
                                whoToUpdate === ''
                                    ? ''
                                    : 'How do you want to update the setup'
                            }
                            errorMessage={customerRequestError.whoToUpdate}
                        />
                        <div className="FormFields">
                            {whoToUpdate === 'Update done by admin' && (
                                <>
                                    <SelectField
                                        withCheckBox
                                        values={repaymentActions}
                                        value={repaymentAction}
                                        selectWidth="100%"
                                        initialOption="Select type of update"
                                        handleChange={(name, value) => {
                                            setRepaymentAction(value)
                                        }}
                                        name="repaymentAction"
                                        currentSelected={repaymentAction}
                                        floatingLabel={
                                            repaymentAction === ''
                                                ? ''
                                                : 'Select type of update'
                                        }
                                        errorMessage={
                                            updateRepaymentErrors.repaymentAction
                                        }
                                    />

                                    {repaymentAction ===
                                        'Change repayment day' && (
                                        <div className="UpdateRepaymentCalenderWrapper">
                                            <div
                                                style={{
                                                    width: isMobile
                                                        ? '100%'
                                                        : '500px',
                                                }}
                                            >
                                                <DateField
                                                    placeholder={
                                                        <div className="APAMInputAutoFilled">
                                                            <p>
                                                                Choose a new
                                                                repayment day
                                                            </p>
                                                            <span>
                                                                {format(
                                                                    new Date(
                                                                        newRepaymentDate,
                                                                    ),
                                                                    'd MMM yyyy',
                                                                )}
                                                            </span>
                                                        </div>
                                                    }
                                                    dateValue={
                                                        new Date(
                                                            newRepaymentDate,
                                                        )
                                                    }
                                                    name="newRepaymentDate"
                                                    handleDateChange={(
                                                        _name,
                                                        date,
                                                    ) => {
                                                        setNewRepaymentDate(
                                                            format(
                                                                date,
                                                                'yyyy-MM-dd',
                                                            ),
                                                        )
                                                    }}
                                                    setHandleDateChange={() => {
                                                        setNewRepaymentDate(
                                                            newRepaymentDate,
                                                        )
                                                    }}
                                                    openCalendar={
                                                        showCalendar === 1
                                                            ? true
                                                            : false
                                                    }
                                                    closeCalendarHandler={() =>
                                                        setShowCalendar(null)
                                                    }
                                                    openCalendarHandler={() =>
                                                        setShowCalendar(1)
                                                    }
                                                    maxDateType="future dates included"
                                                    errorMessage={
                                                        updateRepaymentErrors.newRepaymentDate
                                                    }
                                                    position={'absolute'}
                                                    floatingLabel={true}
                                                    filterRangeFn={
                                                        isNotInconsistentDate
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {repaymentAction ===
                                        'Change repayment schedule' && (
                                        <div className="UpdateRepaymentCalenderWrapper">
                                            <div className="UpdateRepaymentCalenderDiv">
                                                <DateField
                                                    placeholder={
                                                        <div className="APAMInputAutoFilled">
                                                            <p>
                                                                Financing Start
                                                                Date
                                                            </p>
                                                            <span>
                                                                {format(
                                                                    new Date(
                                                                        inputs.dateOfAction,
                                                                    ),
                                                                    'd MMM yyyy',
                                                                )}
                                                            </span>
                                                        </div>
                                                    }
                                                    dateValue={
                                                        new Date(
                                                            inputs.dateOfAction,
                                                        )
                                                    }
                                                    name="dateOfAction"
                                                    handleDateChange={(
                                                        name,
                                                        date,
                                                    ) =>
                                                        handleDateChange(
                                                            name,
                                                            date,
                                                        )
                                                    }
                                                    setHandleDateChange={name =>
                                                        setHandleDateChange(
                                                            name,
                                                        )
                                                    }
                                                    openCalendarHandler={() =>
                                                        setShowCalendar(1)
                                                    }
                                                    openCalendar={
                                                        showCalendar === 1
                                                            ? true
                                                            : false
                                                    }
                                                    closeCalendarHandler={() =>
                                                        setShowCalendar(null)
                                                    }
                                                    position={'absolute'}
                                                    maxDateType="future dates included"
                                                    errorMessage={
                                                        updateRepaymentErrors?.dateOfAction
                                                    }
                                                    floatingLabel={true}
                                                />
                                            </div>
                                            <div className="UpdateRepaymentCalenderDiv">
                                                <DateField
                                                    placeholder={
                                                        <div className="APAMInputAutoFilled">
                                                            <p>
                                                                First Repayment
                                                                Date
                                                            </p>
                                                            <span>
                                                                {format(
                                                                    new Date(
                                                                        inputs.firstRepaymentDate,
                                                                    ),
                                                                    'd MMM yyyy',
                                                                )}
                                                            </span>
                                                        </div>
                                                    }
                                                    dateValue={
                                                        new Date(
                                                            inputs.firstRepaymentDate,
                                                        )
                                                    }
                                                    name="firstRepaymentDate"
                                                    handleDateChange={(
                                                        name,
                                                        date,
                                                    ) =>
                                                        handleDateChange(
                                                            name,
                                                            date,
                                                        )
                                                    }
                                                    setHandleDateChange={name =>
                                                        setHandleDateChange(
                                                            name,
                                                        )
                                                    }
                                                    openCalendarHandler={() =>
                                                        setShowCalendar(2)
                                                    }
                                                    openCalendar={
                                                        showCalendar === 2
                                                            ? true
                                                            : false
                                                    }
                                                    closeCalendarHandler={() =>
                                                        setShowCalendar(null)
                                                    }
                                                    position={'absolute'}
                                                    maxDateType="future dates included"
                                                    errorMessage={
                                                        updateRepaymentErrors?.firstRepaymentDate
                                                    }
                                                    floatingLabel={true}
                                                    marginLeft="0px"
                                                    filterRangeFn={
                                                        isNotInconsistentDate
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {repaymentAction ===
                                        'Select new repayment method' && (
                                        <SelectField
                                            withCheckBox
                                            values={repaymentMethods}
                                            value={
                                                inputs.repaymentMethod ||
                                                initialRepaymentMethod
                                            }
                                            selectWidth="100%"
                                            initialOption="Select new payment method"
                                            handleChange={
                                                handleRepaymentMethodChange
                                            }
                                            name="repaymentMethod"
                                            currentSelected={
                                                inputs.repaymentMethod ||
                                                initialRepaymentMethod
                                            }
                                            floatingLabel={
                                                inputs.repaymentMethod === ''
                                                    ? ''
                                                    : 'Select new payment method'
                                            }
                                            errorMessage={
                                                updateRepaymentErrors.repaymentMethod
                                            }
                                        />
                                    )}

                                    {repaymentAction ===
                                        'Select new repayment method' &&
                                        inputs.repaymentMethod ===
                                            'Paystack plan subscription' && (
                                            <InputFields
                                                title="New Paystack subscription code"
                                                type="text"
                                                name="subscriptionCode"
                                                width="100%"
                                                value={subscriptionCode}
                                                handleChange={e =>
                                                    setSubscriptionCode(
                                                        e.target.value,
                                                    )
                                                }
                                                errorMessage={
                                                    updateRepaymentErrors.subscriptionCode
                                                }
                                            />
                                        )}
                                    {repaymentAction ===
                                        'Select new repayment method' &&
                                        inputs.repaymentMethod ===
                                            'Bank transfer (Standing order)' && (
                                            <div className="APADraggerArea">
                                                <p className="standing_order_error">
                                                    {
                                                        updateRepaymentErrors.repayment_method_setup_file
                                                    }
                                                </p>

                                                <Dragger
                                                    className="DraggerArea"
                                                    {...draggerprops}
                                                    beforeUpload={
                                                        beforeStandingOrderUpload
                                                    }
                                                    showUploadList={true}
                                                    fileList={standingOrder}
                                                    maxCount={1}
                                                    accept=".pdf,.jpg,.jpeg,.png"
                                                    fileValid={
                                                        standingOrderValid
                                                    }
                                                    customRequest={() => {}}
                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        {isUploadingStandingOrder ? (
                                                            <div className="SlideContainer">
                                                                <p
                                                                    className="Slider"
                                                                    style={{
                                                                        width: `${100}%`,
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        ) : standingOrder >
                                                          0 ? (
                                                            <img
                                                                src={
                                                                    SuccessUpload
                                                                }
                                                                className="UploadLogo"
                                                                alt="success logo"
                                                            />
                                                        ) : (
                                                            <img
                                                                src={UploadLogo}
                                                                className="UploadLogo"
                                                                alt="upload logo"
                                                            />
                                                        )}
                                                    </p>
                                                    <p className="UploadText">
                                                        {isUploadingStandingOrder ? (
                                                            <div>
                                                                Uploading
                                                                document...
                                                            </div>
                                                        ) : standingOrder.length >
                                                          0 ? (
                                                            <>
                                                                <p className="Upload">
                                                                    <u
                                                                        className="Browse"
                                                                        style={{
                                                                            marginTop:
                                                                                '20px',
                                                                        }}
                                                                    >
                                                                        Replace
                                                                        document
                                                                    </u>
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                Upload file.
                                                                Drag and drop or
                                                                <u className="Browse">
                                                                    browse
                                                                </u>
                                                            </>
                                                        )}
                                                    </p>
                                                </Dragger>
                                            </div>
                                        )}
                                </>
                            )}
                            {whoToUpdate === 'Update done by consumer' && (
                                <TextArea
                                    title={`Add Short Note`}
                                    height="170px"
                                    resize
                                    name="notes"
                                    inputValue={notes}
                                    value={notes}
                                    rows={10}
                                    handleChange={handleInputChange}
                                    errorMessage={customerRequestError?.notes}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={`SubmitButton ${
                            inputs?.repaymentMethod ===
                                'Bank transfer (Standing order)' &&
                            repaymentAction === 'Select new repayment method'
                                ? 'upload'
                                : ''
                        }`}
                    >
                        <ConsumerButton
                            btnWidth={isMobile ? '100%' : '160px'}
                            btnHeight="56px"
                            btnTextColor="#FFFFFF"
                            btnBgColor="#004AAD"
                            btnFontSize="14px"
                            handleClick={
                                whoToUpdate === 'Update done by admin'
                                    ? handleUpdateRepaymentSetup
                                    : handleRequestFromCustomer
                            }
                            loading={
                                isUpdateRepaymentLoading ||
                                isRequestingFromCustomer ||
                                isUpdateFirstRepaymentDateLoading
                            }
                            disabled={
                                whoToUpdate === 'Update done by admin' &&
                                repaymentAction ===
                                    'Select new repayment method' &&
                                inputs.repaymentMethod ===
                                    'Bank transfer (Standing order)' &&
                                !standingOrderValid
                            }
                        >
                            Save update
                        </ConsumerButton>
                    </div>
                </div>
            }
        />
    )
}

export default UpdateRepaymentModal

UpdateRepaymentModal.propTypes = {
    showUpdateRepaymentModal: PropTypes.bool.isRequired,
    setShowUpdateRepaymentModal: PropTypes.func,
    paymentPlanDataRefetch: PropTypes.func,
    paymentPlanData: PropTypes.any,
}
