import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Pagination } from 'antd'
import { useQuery } from 'react-query'
import ClusterLibraryTable from './table'
import { NoFLoatingLabelInputFields } from 'src/components/InputFields'
import SeoComponent from 'src/components/Seo'

import { getProviderClusterApi } from 'src/api/providers/clusterproducts'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import EmptyState from 'src/components/EmptyState'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import LeftArrow from 'src/assets/images/left-blue-arrow.svg'

const ProviderClusterLibrary = () => {
    const [totalPages, setTotalPages] = useState(0)
    const [clusterPackages, setClusterPackages] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [searchText, setSearchText] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const location = useLocation()
    const paymentPlanModel = location?.state?.paymentPlanModel

    const [, setWidth] = useState(window.innerWidth)

    const updatePage = page => {
        setCurrentPage(page)
    }

    const paymentPlanMapping = {
        'Lease to Own': 'LEASE_TO_OWN',
        Subscription: 'SUBSCRIPTION',
    }

    //get provider cluster packages
    const {
        isFetching: clusterPackagesFetching,
        refetch: clusterPackagesRefetch,
    } = useQuery(
        ['get-provider-cluster-packages', currentPage],
        () =>
            getProviderClusterApi(
                currentPage,
                paymentPlanMapping[paymentPlanModel],
                searchText,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setClusterPackages(data?.data?.data)
                setTotalPages(data?.data?.total_pages)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        setTotalPages(0)
        setClusterPackages([])
        clusterPackagesRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    const handleSearchInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
    }

    const handleSearch = () => {
        setTotalPages(0)
        setClusterPackages([])
        clusterPackagesRefetch()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => searchText === '' && clusterPackagesRefetch(), [searchText])

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleWindowResize)

        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])
    const history = useHistory()
    return (
        <>
            <SeoComponent
                title="SunFi - Admin Credits System | Simplifying and Scaling Clean Energy"
                tracker="AdminCreditsSystemTracker"
            />

            {/*page content*/}
            <div className="ACLWrapper">
                <button
                    className="PrevButton"
                    onClick={() => {
                        history.push('/app/payment-plans')
                    }}
                >
                    <img src={LeftArrow} alt="left-arrow" />
                    Back to Payment Plans
                </button>
                <div>
                    <div className="ACLTitle" style={{ marginTop: 0 }}>
                        <div className="ACLTitleComponents">
                            <p>Select a cluster </p>
                        </div>
                    </div>
                </div>

                {/*    search & filter, view buttons*/}
                <div className="ACLTopWrapper">
                    <div className="ACLInputWrapper">
                        <NoFLoatingLabelInputFields
                            TextPrefilled={searchText ? true : false}
                            borderRadius="10px"
                            inputWidth="350px"
                            inputHeight="45px"
                            value={searchText}
                            type="text"
                            inputBackground="#F6F6F6"
                            iconType="search"
                            prefilled
                            inputPlaceholder="Search cluster name"
                            style={{ paddingBottom: 7 }}
                            handleChange={handleSearchInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                            onBlur={handleOnBlur}
                        />
                    </div>
                </div>

                {/*    tabpane and tables*/}
                <div className="ARLTableWrapper">
                    {clusterPackagesFetching ? (
                        <ListingsSkeletalCards total={3} />
                    ) : (
                        <>
                            {toastError && (
                                <Toast
                                    messageType="error"
                                    message={errorMessage}
                                />
                            )}

                            <>
                                {clusterPackages.length > 0 ? (
                                    <ClusterLibraryTable
                                        tableData={clusterPackages}
                                    />
                                ) : (
                                    <div className="NoActivityStyle">
                                        <EmptyState
                                            type="noActivity"
                                            title="No Cluster "
                                            subTitle="No Cluster  yet"
                                        />
                                    </div>
                                )}
                            </>
                        </>
                    )}
                </div>
                {clusterPackages.length > 0 && !clusterPackagesFetching ? (
                    <div className="ACLPaginationWrapper">
                        <Pagination
                            defaultCurrent={1}
                            current={currentPage}
                            total={parseInt(totalPages, 10)}
                            defaultPageSize={1}
                            showSizeChanger={false}
                            showTitle={false}
                            showLessItems
                            onChange={updatePage}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    )
}

export default ProviderClusterLibrary
