/* eslint-disable react/prop-types */

import { Layout, Row, Col } from 'antd'
import styles from './package.module.scss'

const PackageSection = ({
    SectionOne,
    SectionTwo,
    bgColor,
    sectionStyle,
    Style,
    content,
}) => {
    const { RowWrapper, RowWrapper2 } = styles
    return (
        <Layout>
            <div style={{ background: bgColor, ...sectionStyle }}>
                <br />
                <Row
                    style={Style}
                    className={content === 'first' ? RowWrapper : RowWrapper2}
                >
                    <Col sm={24} lg={12} md={24}>
                        {SectionOne}
                    </Col>
                    <Col sm={24} lg={12} md={24}>
                        {SectionTwo}
                    </Col>
                </Row>
                <br />
            </div>
        </Layout>
    )
}

export default PackageSection
