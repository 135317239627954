//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Tabs, Pagination, Dropdown, Menu, Skeleton } from 'antd'
import { format } from 'date-fns'
import { NoFLoatingLabelInputFields } from '../../../../components/InputFields'
import './products-listings.scss'
import { getProductLibraryUsers } from '../../../../api/admin/products/listings'
import {
    canCreatePackage,
    formatAllData,
    formatGlobalContributorsData,
} from './formatter'
import Filter from './components/Filter'
import All from './components/All'
import { errorHandler } from '../../../../utils/errorHandler'
import Toast from '../../../../components/Toast'
import Button from '../../../../components/Button'
import { ReactComponent as PlusIcon } from 'src/assets/images/plusIcon.svg'
import GlobalContributors from './components/GlobalContributors'
import ActionModal from 'src/components/ActionModal'
import { decodeUserInfo } from 'src/utils/auth'
import BackNav from 'src/components/BackNav'
import queryString from 'query-string'
import SeoComponent from 'src/components/Seo'
import { isTablet } from '../../../../utils/mediaQueries'
import { denyPermission } from 'src/utils/permissionFramework'
import { useDispatch, useSelector } from 'react-redux'
import { convertQueryParamsToObject } from 'src/utils/formatting'
import {
    resetAdminProductsFilters,
    updateAdminProductsFilters,
    updatedAdminProductsFilters,
} from 'src/redux/reducers/admin/products/listing'
import {
    applyDynamicPermissionsToAdminUser,
    permissionsControl,
} from 'src/utils/permissionsControl'
import {
    addQueryParamsToUrl,
    removeQueryParamFromUrl,
    getSearchParamsFromUrl,
    removeAllQueryParamsFromUrl,
} from 'src/utils/urlTracker'

const AdminProducts = props => {
    const history = useHistory()
    const filterQuery = getSearchParamsFromUrl()
    const defaultQueries = useSelector(updatedAdminProductsFilters)
    const dispatch = useDispatch()
    const { TabPane } = Tabs
    const [currentTab, setCurrentTab] = useState(
        filterQuery?.tab || defaultQueries.current_tab || '1',
    )
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [currentPage, setCurrentPage] = useState(
        Number(filterQuery?.page || defaultQueries.current_page),
    )
    const [globalContributorsCurrentPage, setGlobalContributorsCurrentPage] =
        useState(Number(filterQuery?.page || defaultQueries.current_page))
    const [packagesRange, setPackagesRange] = useState([
        Number(
            filterQuery?.packagesStartCount ||
                defaultQueries.packages_count_start,
        ),
        Number(
            filterQuery?.packagesEndCount || defaultQueries.packages_count_end,
        ),
    ])
    const [componentsRange, setComponentsRange] = useState([
        Number(
            filterQuery?.componentsStartCount ||
                defaultQueries.components_count_start,
        ),
        Number(
            filterQuery?.componentsEndCount ||
                defaultQueries.components_count_end,
        ),
    ])
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [inputs, setInputs] = useState({
        startDate: filterQuery?.startDate || defaultQueries.start_date || null,
        endDate: filterQuery?.endDate || defaultQueries.end_date || null,
    })
    const [showCalendar, setShowCalendar] = useState(0)
    const [currentStartDate, setCurrentStartDate] = useState(
        inputs.startDate === null ? null : new Date(inputs.startDate),
    )

    const [currentEndDate, setCurrentEndDate] = useState(
        inputs.endDate === null ? null : new Date(inputs.endDate),
    )
    const [count, setCount] = useState('')
    const [globalContributorsCount, setGlobalContributorsCount] = useState('')
    const [data, setData] = useState([])
    const [globalContributorsData, setGlobalContributorsData] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [globalContributorsTotalPages, setGlobalContributorsTotalPages] =
        useState(1)
    const [allSearchText, setAllSearchText] = useState(
        filterQuery?.search || defaultQueries.provider_name || '',
    )
    const [globalContributorsSearchText, setGlobalContributorsSearchText] =
        useState(filterQuery?.search || defaultQueries.provider_name || '')
    const [globalAccess, setGlobalAccess] = useState(filterQuery?.globalAccess)
    const [clearGlobalAccess, setClearGlobalAccess] = useState(false)
    const [filterMode, setFilterMode] = useState(
        defaultQueries.filter_mode === 'true' ? true : false,
    )
    let resetPage = null
    let reset

    const [displayAddModal, setDisplayAddModal] = useState(false)
    const [addProduct, setAddProduct] = useState('')
    const canCreateComponent = permissionsControl(['can_create_a_component'])

    // get a list of product library users
    const { refetch, isFetching } = useQuery(
        ['get-product-library-users'],
        () =>
            getProductLibraryUsers(
                resetPage === 1
                    ? resetPage
                    : allSearchText?.length > 1
                    ? 1
                    : currentPage,
                reset ? '' : allSearchText,
                reset ? '' : globalAccess,
                reset
                    ? ''
                    : currentStartDate
                    ? format(currentStartDate, 'yyyy-MM-dd')
                    : '',
                reset
                    ? ''
                    : currentEndDate
                    ? format(currentEndDate, 'yyyy-MM-dd')
                    : '',
                reset ? 0 : packagesRange[0],
                reset ? '' : packagesRange[1] > 0 ? packagesRange[1] : '',
                reset ? 0 : componentsRange[0],
                reset ? '' : componentsRange[1] > 0 ? componentsRange[1] : '',
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setCount(data?.data?.count)
                setData(formatAllData(data?.data?.data))
                setTotalPages(data?.data?.total_pages)
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')

                dispatch(
                    updateAdminProductsFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            `current_tab:${currentTab}`,
                            `filter_mode:${filterMode}`,
                        ]),
                    ),
                )
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    // get a list of product library users
    const {
        refetch: globalContributorsRefetch,
        isFetching: globalContributorsIsFetching,
    } = useQuery(
        ['get-product-library-global-contributors'],
        () =>
            getProductLibraryUsers(
                resetPage === 1
                    ? resetPage
                    : globalContributorsSearchText?.length > 1
                    ? 1
                    : globalContributorsCurrentPage,
                reset ? '' : globalContributorsSearchText,
                'Contributor',
            ),
        {
            enabled: true,
            retry: false,
            onSuccess: data => {
                setGlobalContributorsCount(data?.data?.count)
                setGlobalContributorsData(
                    formatGlobalContributorsData(data?.data?.data),
                )
                setGlobalContributorsTotalPages(data?.data?.total_pages)
                const queries = data?.config?.url
                    .split('?')[1]
                    ?.replaceAll('=', ':')
                    ?.split('&')

                dispatch(
                    updateAdminProductsFilters(
                        convertQueryParamsToObject([
                            ...queries,
                            `current_tab:${currentTab}`,
                            `filter_mode:${filterMode}`,
                        ]),
                    ),
                )
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )
    const currentTabUrl =
        currentTab === '1'
            ? 'all'
            : currentTab === '2'
            ? 'globalcontributors'
            : ''

    useEffect(() => {
        currentTab === '1' && refetch()

        addQueryParamsToUrl({
            tab: currentTabUrl || 'all',
        })
    }, [currentTab, currentPage])

    useEffect(() => {
        currentTab === '2' && globalContributorsRefetch()
        addQueryParamsToUrl({
            tab: currentTabUrl,
            page: globalContributorsCurrentPage,
        })
    }, [currentTab, globalContributorsCurrentPage])

    useEffect(() => {
        const parsed = queryString.parse(props?.location?.search)

        if (Object.keys(parsed).length > 0) {
            switch (parsed.tab) {
                case 'all':
                    setCurrentTab('1')
                    break
                case 'globalcontributors':
                    setCurrentTab('2')
                    break
                default:
                    setCurrentTab('1')
            }
        }
    }, [])

    //handle pagination
    const updatePage = page => {
        setCurrentPage(page)
        addQueryParamsToUrl({
            page: page,
        })
    }

    const globalContributorsUpdatePage = page => {
        setGlobalContributorsCurrentPage(page)
    }

    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={currentPage}
                current={currentPage}
                total={totalPages}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={updatePage}
            />
        )
    }

    const globalContributorsPaginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={globalContributorsCurrentPage}
                current={globalContributorsCurrentPage}
                total={globalContributorsTotalPages}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={globalContributorsUpdatePage}
            />
        )
    }

    //handle search
    const handleSearchInputChange = e => {
        e.persist()
        currentTab === '1'
            ? setAllSearchText(e.target.value)
            : setGlobalContributorsSearchText(e.target.value)
    }

    const handleSearch = () => {
        currentTab === '1'
            ? setCurrentPage(1)
            : setGlobalContributorsCurrentPage(1)
        currentTab === '1'
            ? setTotalPages(0)
            : setGlobalContributorsTotalPages(0)
        currentTab === '1' ? setData([]) : setGlobalContributorsData([])
        currentTab === '1' ? refetch() : globalContributorsRefetch()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
            if (event.target.value.length > 0) {
                addQueryParamsToUrl({
                    search: event.target.value,
                    page: '1',
                })
            } else {
                removeQueryParamFromUrl('search')
                addQueryParamsToUrl({
                    page: '1',
                })
            }
        }
    }

    const handleOnBlur = e => {
        currentTab === '1'
            ? setAllSearchText(e.target.value.trim())
            : setGlobalContributorsSearchText(e.target.value.trim())
    }
    //effect to refetch data when search is cleared
    useEffect(() => {
        if (currentTab === '1' && allSearchText === '') {
            refetch()
            removeQueryParamFromUrl('search')
        }
    }, [allSearchText])

    useEffect(
        () =>
            currentTab === '2' && globalContributorsSearchText === ''
                ? globalContributorsRefetch()
                : null,
        [globalContributorsSearchText],
    )

    //filter functions
    const showDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }
    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(date)
        }
        if (name === 'endDate') {
            setCurrentEndDate(date)
        }
    }

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]: name === 'startDate' ? currentStartDate : currentEndDate,
        }))
    }

    const handleAddFilter = () => {
        setCurrentPage(1)
        setTimeout(() => {
            refetch()
            setFilterMode(true)
            setDrawerVisible(false)
            addQueryParamsToUrl({
                globalAccess: globalAccess,
                startDate: currentStartDate
                    ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                    : '',
                endDate: currentEndDate
                    ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                    : '',
                packagesStartCount: packagesRange[0],
                packagesEndCount: packagesRange[1],
                componentsStartCount: componentsRange[0],
                componentsEndCount: componentsRange[1],
                page: '1',
            })
        }, 500)
    }

    //reset filter functions
    const resetValues = () => {
        reset = true
        setAllSearchText('')
        removeAllQueryParamsFromUrl()
        setFilterMode(false)
        setInputs(prev => ({
            ...prev,
            endDate: null,
            startDate: null,
        }))
        setCurrentPage(1)
        setCurrentTab('1')
        setCurrentStartDate(null)
        setCurrentEndDate(null)
        setGlobalAccess('')
        setClearGlobalAccess(true)
        setPackagesRange([0, 0])
        setComponentsRange([0, 0])
        setDrawerVisible(false)
        dispatch(resetAdminProductsFilters())
    }

    const handleReset = () => {
        resetValues()
        refetch()
    }

    //menu to add package and component

    const menu = (
        <Menu className="menu">
            <Menu.Item
                key={1}
                disabled={
                    applyDynamicPermissionsToAdminUser
                        ? !canCreateComponent
                        : denyPermission(
                              'admin',
                              'product_library',
                              'component:can_create',
                          )
                }
                onClick={() => {
                    setDisplayAddModal(true)
                    setAddProduct('Component')
                }}
            >
                <a className="menuItem">New Component</a>
            </Menu.Item>
            <Menu.Item
                key={2}
                disabled={
                    applyDynamicPermissionsToAdminUser
                        ? !canCreatePackage
                        : denyPermission(
                              'admin',
                              'product_library',
                              'component:can_create',
                          )
                }
                onClick={() => {
                    setDisplayAddModal(true)
                    setAddProduct('Package')
                }}
            >
                <a className="menuItem">New Package</a>
            </Menu.Item>
        </Menu>
    )

    const redirectTo = () => {
        history.push({
            pathname: `/app/products/${
                addProduct === 'Component' ? 'add-component' : 'add-package'
            }`,
            state: {
                prev: 'all products library',
                isAdminWorkspace: 'true',
                sunfiId: userInfo?.providerId,
                name: loggedinProviderName,
            },
        })
    }
    const userInfo = decodeUserInfo()
    const loggedinProviderName = userInfo?.companyName

    return (
        <div>
            <SeoComponent
                title="Products | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Admin-Products"
            />
            <ActionModal
                actionModalOpen={displayAddModal}
                headerText={`Add ${addProduct} to ${loggedinProviderName}?`}
                subTitle={`The ${addProduct?.toLowerCase()} you are about to add will be included in the ${loggedinProviderName} product library.`}
                closeModal={() => setDisplayAddModal(false)}
                actionHandler={redirectTo}
                onCancel={() => setDisplayAddModal(false)}
                actionType="warning"
                actionText="Continue"
                noBackLink
            />
            <div className="APLPageTitleWrapper">
                {filterMode ? (
                    <BackNav
                        title="Back to Products Library"
                        onClick={() => {
                            handleReset()
                        }}
                    />
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <p className="NavPageTitle">Product Library</p>
                        <div className="MobileButton">
                            <Dropdown overlay={menu} trigger={['click']}>
                                <a onClick={e => e.preventDefault()}>
                                    <Button
                                        btnBgColor="var(--blue)"
                                        btnTextColor="var(--white)"
                                        btnWidth="40px"
                                        btnHeight="40px"
                                    >
                                        <span
                                            style={{
                                                position: 'relative',
                                                top: '2.5px',
                                            }}
                                        >
                                            <PlusIcon />
                                        </span>
                                    </Button>
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                )}
            </div>

            <div className="AdminPLWrapper">
                {toastError && (
                    <Toast message={errorMessage} messageType="error" />
                )}
                <Filter
                    showDrawer={showDrawer}
                    drawerVisible={drawerVisible}
                    inputs={inputs}
                    handleDateChange={handleDateChange}
                    setHandleDateChange={setHandleDateChange}
                    showCalendar={showCalendar}
                    setShowCalendar={setShowCalendar}
                    packagesRange={packagesRange}
                    setPackagesRange={setPackagesRange}
                    componentsRange={componentsRange}
                    setComponentsRange={setComponentsRange}
                    setDrawerVisible={setDrawerVisible}
                    handleAddFilter={handleAddFilter}
                    globalAccess={globalAccess}
                    setGlobalAccess={setGlobalAccess}
                    clearGlobalAccess={clearGlobalAccess}
                    handleReset={handleReset}
                />
                <div className="AdminPLSpaceBetween">
                    <div style={{ display: 'flex' }}>
                        <NoFLoatingLabelInputFields
                            borderRadius="10px"
                            inputWidth={isTablet ? '200px' : '350px'}
                            inputHeight="45px"
                            type="text"
                            value={
                                currentTab === '1'
                                    ? allSearchText
                                    : globalContributorsSearchText
                            }
                            inputBackground="#F6F6F6"
                            iconType="search"
                            prefilled
                            disabled={
                                isFetching || globalContributorsIsFetching
                            }
                            inputPlaceholder="Search"
                            style={{ paddingBottom: 7 }}
                            handleChange={handleSearchInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                            onBlur={handleOnBlur}
                        />
                        <div
                            className="AdminPLFilterBox"
                            style={{ border: '1px solid #e5e5e5' }}
                            onClick={() => {
                                !isFetching && setDrawerVisible(true)
                                clearGlobalAccess && setClearGlobalAccess(false)
                            }}
                        >
                            <svg
                                width="12"
                                height="15"
                                viewBox="0 0 12 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginTop: 4 }}
                            >
                                <path
                                    d="M7.5 8.5V13L4.5 14.5V8.5L0 1.75V0.25H12V1.75L7.5 8.5ZM1.803 1.75L6 8.0455L10.197 1.75H1.803Z"
                                    fill={'#1D2A30'}
                                />
                            </svg>

                            <p className="AdminPLFilterText">Filters</p>
                        </div>
                    </div>
                    <div className="APLDropDownButtonWrapper">
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a onClick={e => e.preventDefault()}>
                                <Button
                                    btnBgColor="var(--blue)"
                                    btnTextColor="var(--white)"
                                    btnWidth="150px"
                                    btnHeight="45px"
                                    btnPadding="10px 15px"
                                >
                                    Add Products
                                    <span style={{ marginLeft: 14 }}>
                                        <svg
                                            width="12"
                                            height="8"
                                            viewBox="0 0 12 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M6.00011 4.97634L10.1251 0.851341L11.3034 2.02967L6.00011 7.33301L0.696777 2.02967L1.87511 0.851341L6.00011 4.97634Z"
                                                fill="white"
                                                fillOpacity="0.9"
                                            />
                                        </svg>
                                    </span>
                                </Button>
                            </a>
                        </Dropdown>
                    </div>
                </div>
                {filterMode && (
                    <h1 className="AdminPLFilterResultTitle">
                        Filter Results ({isFetching ? 0 : count})
                    </h1>
                )}
                <div className="AdminPLTabWrapper">
                    {filterMode ? (
                        <>
                            <All data={data} loading={isFetching} />
                        </>
                    ) : (
                        <Tabs
                            activeKey={currentTab}
                            style={{ marginTop: 38 }}
                            onChange={values => {
                                setCurrentTab(values)
                                removeQueryParamFromUrl('search')
                                setCurrentPage(1)
                                setGlobalContributorsCurrentPage(1)
                                setAllSearchText('')
                                setGlobalContributorsSearchText('')
                                setGlobalAccess('')
                            }}
                        >
                            <TabPane
                                tab={
                                    isFetching ||
                                    globalContributorsIsFetching ? (
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{ width: '125px' }}
                                        />
                                    ) : (
                                        `All ${
                                            currentTab === '1'
                                                ? `(${count})`
                                                : ''
                                        }`
                                    )
                                }
                                key="1"
                            >
                                <All data={data} loading={isFetching} />
                            </TabPane>
                            <TabPane
                                tab={
                                    isFetching ||
                                    globalContributorsIsFetching ? (
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{ width: '125px' }}
                                        />
                                    ) : (
                                        `Global Contributors ${
                                            currentTab === '2'
                                                ? `(${globalContributorsCount})`
                                                : ''
                                        }
                                `
                                    )
                                }
                                key="2"
                            >
                                <GlobalContributors
                                    data={globalContributorsData}
                                    loading={globalContributorsIsFetching}
                                />
                            </TabPane>
                        </Tabs>
                    )}
                </div>
                <div className="AdminPLPaginationWrapper">
                    {currentTab === '1'
                        ? data.length > 0 && paginationBlock()
                        : globalContributorsData.length > 0 &&
                          globalContributorsPaginationBlock()}
                </div>
            </div>
        </div>
    )
}

export default AdminProducts
