import { useState } from 'react'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import PropTypes from 'prop-types'
import { isMobile } from 'src/utils/mediaQueries'
import NairaSymbol from 'src/components/NairaSymbol'
import questionMark from 'src/assets/images/question-mark-circle.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import Checkbox from 'src/components/Checkbox'
import { otherOptionCardContent, paymentModelMap } from '../data'

const ExploreOtherOptionsModal = ({
    showModal,
    onCancel,
    activePaymentModel,
    setActivePaymentModel,
    solutions,
}) => {
    const [selected, setSelected] = useState(null)

    const handleSelection = solution => {
        if (selected?.payment_model === solution?.payment_model) {
            setSelected(null)
        } else {
            setSelected(solution)
        }
    }

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={onCancel}
            modalWidth={'649px'}
            v2
            title={'Explore other payment options'}
            showFooter
            content={
                <div className={'ViewSystemOtherOptionsModal'}>
                    <div className="ViewSystemOtherOptionsModalDescriptionHeader">
                        <h5>
                            Select from other available payment options for the
                            system you selected
                        </h5>
                        <p>
                            We offer alternative payment options tailored to
                            your budget and preferences. Choose the one that
                            suits you best.
                        </p>
                    </div>

                    <div className="ViewSystemOtherOptionsCardWrapper">
                        {solutions
                            ?.filter(
                                solution =>
                                    solution.payment_model !==
                                    activePaymentModel,
                            )
                            ?.map((solution, i) => (
                                <div
                                    key={i}
                                    className={'ViewSystemOtherOptionsCard'}
                                    onClick={() => handleSelection(solution)}
                                >
                                    <div className="ViewSystemOtherOptionsCardHeader">
                                        <span>
                                            {solution.payment_model || ''}
                                        </span>
                                        <Checkbox
                                            page={'SSE'}
                                            isChecked={
                                                selected?.payment_model ===
                                                solution.payment_model
                                            }
                                        />
                                    </div>
                                    <div className="ViewSystemOtherOptionsCardBody">
                                        <div
                                            className={
                                                'ViewSystemOtherOptionsCardRow'
                                            }
                                        >
                                            <div>
                                                <span>SYSTEM SIZE</span>
                                                <p>
                                                    {solution.energy_capacity?.value?.toFixed(
                                                        1,
                                                    )}
                                                    {
                                                        solution.energy_capacity
                                                            ?.unit_of_measurement
                                                    }
                                                </p>
                                            </div>
                                            <div
                                                className={
                                                    'ViewSystemOtherOptionsCardRowRight'
                                                }
                                            >
                                                <span>
                                                    {
                                                        otherOptionCardContent[
                                                            paymentModelMap[
                                                                solution
                                                                    .payment_model
                                                            ]
                                                        ]?.paymentModelText
                                                    }
                                                </span>
                                                <p>
                                                    {paymentModelMap[
                                                        solution.payment_model
                                                    ] === 'OUTRIGHT_SALE' ? (
                                                        <>
                                                            <NairaSymbol />
                                                            {solution.total_cost?.toLocaleString(
                                                                'en-US',
                                                                {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                },
                                                            )}
                                                        </>
                                                    ) : (
                                                        `${solution.least_payment_tenure}Months`
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        {paymentModelMap[
                                            solution.payment_model
                                        ] !== 'OUTRIGHT_SALE' ? (
                                            <div
                                                className={
                                                    'ViewSystemOtherOptionsCardRow'
                                                }
                                            >
                                                <div>
                                                    <span>UPFRONT DEPOSIT</span>
                                                    <p>
                                                        <NairaSymbol />
                                                        {solution.upfront_deposit?.toLocaleString(
                                                            'en-US',
                                                        )}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        'ViewSystemOtherOptionsCardRowRight'
                                                    }
                                                >
                                                    <span>
                                                        MIN. MONTHLY PAYMENT
                                                    </span>
                                                    <p>
                                                        <NairaSymbol />
                                                        {solution.least_payable_amount?.toLocaleString(
                                                            'en-US',
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div
                                        className={
                                            'ViewSystemOtherOptionsCardFooter'
                                        }
                                    >
                                        <CustomToolTip
                                            arrowPositionLeft
                                            text={
                                                otherOptionCardContent[
                                                    paymentModelMap[
                                                        solution.payment_model
                                                    ]
                                                ]?.tooltipContent
                                            }
                                            toolTipPopupWidth={174}
                                            toolTipMarginLeft={-18}
                                            top={24}
                                            toolTipPadding={
                                                '9px 10px 25px 10px'
                                            }
                                        >
                                            <div className="ViewSystemOtherOptionsCardFooterInfo">
                                                <img
                                                    src={questionMark}
                                                    alt={'info icon'}
                                                    role={'presentation'}
                                                />
                                                <p>
                                                    {
                                                        otherOptionCardContent[
                                                            paymentModelMap[
                                                                solution
                                                                    .payment_model
                                                            ]
                                                        ]?.tooltipTilte
                                                    }
                                                </p>
                                            </div>
                                        </CustomToolTip>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            }
            primaryBtnText={'Proceed'}
            primaryBtnWidth={isMobile ? '105px' : '150px'}
            primaryBtnHeight={isMobile ? '45px' : '53px'}
            primaryBtnDisabled={
                !selected || selected.payment_model === activePaymentModel
            }
            handlePrimaryBtnClick={() => {
                setActivePaymentModel &&
                    setActivePaymentModel(selected.payment_model)
                onCancel && onCancel()
            }}
        />
    )
}

ExploreOtherOptionsModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    activePaymentModel: PropTypes.string,
    setActivePaymentModel: PropTypes.func,
    solutions: PropTypes.array,
}

export default ExploreOtherOptionsModal
