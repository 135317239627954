import { useState } from 'react'
import { useMutation } from 'react-query'
import { otpSchema } from 'src/utils/validationSchema'
import { useHistory } from 'react-router-dom'
import {
    initiateSSEConsumerLoginApi,
    verifySSEConsumerLoginApi,
} from 'src/api/shoppingExperience/login'
import { errorHandler } from 'src/utils/errorHandler'
import { encodeUserInfo, setToken } from 'src/utils/auth'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'
import {
    initiateClusterRepLoginApi,
    verifyClusterRepLoginApi,
} from 'src/api/representative/auth'
import { appTracking } from 'src/utils/appTracker'
import getConsumerKYCHome from 'src/utils/getConsumerKYCHome'

const useVerifyLoginOTP = (
    setShowModal,
    method,
    inputs,
    userType,
    verifyApi,
    onVerifySuccess,
    preventConsumerRedirect = false,
    handlePostSuccess = () => {},
) => {
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [resent, setResent] = useState(false)
    const history = useHistory()
    const isSSELogIn =
        location.pathname === '/consumer/login' ||
        location.pathname === '/' ||
        location.pathname === '/home'
    const isRepLogin = location.pathname === '/rep/login'

    const verifyLoginApi =
        userType === 'consumer'
            ? verifySSEConsumerLoginApi
            : verifyClusterRepLoginApi
    const loginApi =
        userType === 'consumer'
            ? initiateSSEConsumerLoginApi
            : initiateClusterRepLoginApi

    //resend OTP
    const resendOTPMutation = useMutation({
        mutationFn: () => {
            return loginApi(
                method === 'email'
                    ? { email: inputs.email }
                    : { phone_number: inputs.phone },
            )
        },
        onSuccess: () => {
            setResent(true)
            const timeout = setTimeout(() => setResent(false), [2000])
            return () => clearTimeout(timeout)
        },
        onError: error => {
            setToastError(true)
            setResent(false)
            setErrorMessage(errorHandler(error?.response.data))
        },
    })

    // verify submitted otp
    const verifyLoginMutation = useMutation({
        mutationFn: () => {
            const apiFunction = verifyApi || verifyLoginApi
            return apiFunction({
                code: otp.join(''),
                ...(method === 'phone'
                    ? { phone_number: inputs.phone }
                    : { email: inputs.email }),
            })
        },
        onSuccess: res => {
            if (onVerifySuccess) {
                onVerifySuccess()
            } else {
                const data = res?.data?.data
                setToken(res?.data?.data?.token?.access)
                encodeUserInfo(res)
                appTracking('', '', '', '', '', '', ['MP'], 'identify', {
                    identify: { id: data?.user?.id },
                })
                appTracking('', '', '', '', '', '', ['MP'], 'people_set', {
                    people_set: {
                        'User Types': data?.user?.user_types,
                        'Phone Number': data?.user?.phone_number,
                        $email: data?.user?.email,
                        $name: data?.user?.display_name,
                        $avatar: data?.user?.avatar_url,
                        'Payment Plan Type': data?.payment_model,
                        'Consumer Type': data?.is_dep_customer
                            ? 'DEP Cons.'
                            : 'DTC Cons.',
                    },
                })
                handlePostSuccess?.()

                if (isRepLogin) {
                    localStorage.setItem('isClusterRep', JSON.stringify(true))
                    history.push('/rep/workspace')
                } else {
                    localStorage.removeItem('isClusterRep')
                }

                if (isSSELogIn && !preventConsumerRedirect) {
                    localStorage.setItem('isSSEConsumer', JSON.stringify(true))
                    const isApproved =
                        res?.data?.data?.approval_status?.toLowerCase() ===
                            'approved' ||
                        data?.application_status === 'TENURE_SELECTION'
                    const isClosed =
                        data?.application_status === 'PLAN_COMPLETED' ||
                        data?.application_status === 'ARCHIVED'

                    const isOutrightSaleCustomer =
                        data?.payment_model === 'Outright Sale'
                    const usedOnePageKYC = data?.used_one_page_kyc
                    const customerType = data?.customer_type
                    const usesDynamicKYC = data?.uses_dynamic_kyc
                    const consumerKYCHome = getConsumerKYCHome(
                        usedOnePageKYC,
                        customerType,
                        usesDynamicKYC,
                    )
                    if (isOutrightSaleCustomer && isApproved) {
                        history.push('/consumer/account-setup/overview')
                    } else if (isOutrightSaleCustomer && !isApproved) {
                        history.push(
                            '/consumer/account-setup/outright-sale-information',
                        )
                    } else {
                        if (isApproved || isClosed) {
                            history.push('/consumer/workspace')
                        } else {
                            history.push(consumerKYCHome)
                        }
                    }
                } else {
                    localStorage.removeItem('isSSEConsumer')
                }
                setOtp(['', '', '', '', '', ''])
            }
        },
        onError: err => {
            setToastError(true)
            setErrorMessage(errorHandler(err?.response?.data))
        },
    })

    const handleOtpSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                verifyLoginMutation.mutate()
            })
            .catch(() => {
                setOtpError(true)
            })
    }

    const fullMethodName = method === 'phone' ? 'phone number' : 'email address'

    const closeModal = () => {
        setShowModal(false)
        setOtp(['', '', '', '', '', ''])
        setOtpError(false)
    }

    useOTPVerify({ otp, handleOtpSubmit })

    return {
        otpError,
        toastError,
        errorMessage,
        resent,
        resendOTPMutation,
        fullMethodName,
        closeModal,
        handleOtpSubmit,
        verifyLoginMutation,
        otp,
        setOtp,
        setResent,
    }
}

export default useVerifyLoginOTP
