import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/ResidenceForm/residenceform.scss'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { ConsumerDateField, SelectField } from 'src/components/InputFields'
import { format } from 'date-fns'
import { IconInputField } from 'src/components/InputFields/CostField'
import LinkIcon from 'src/assets/images/link.svg'
import { pensionManagers } from 'src/utils/pensionManagers'

const PensionDetails = ({
    inputs,
    errors,
    handleChange,
    handleSelectChange,
    handleOnBlur,
    handleSelectOnBlur,
    isAdminUser,
    showCalendar,
    setShowCalendar,
    isCustomerApproved,
    v2 = false,
    onePageKyc,
}) => {
    return (
        <>
            {!v2 && (
                <>
                    <Label
                        title={'Employment details'}
                        subTitle={
                            'Provide us with more details about your work'
                        }
                    />

                    <>
                        <IconInputField
                            icon={LinkIcon}
                            name="linkedinPage"
                            title="Linkedin Page"
                            width={'100%'}
                            value={inputs?.linkedinPage}
                            handleChange={handleChange}
                            errorMessage={errors?.linkedinPage}
                            disabled={isAdminUser || isCustomerApproved}
                        />
                        {!errors?.linkedinPage && (
                            <p className="OptionalTypoGraphy">Optional</p>
                        )}
                    </>
                </>
            )}

            <SelectField
                floatLabelOnSelectedValue
                floatingLabel="Pension Manager"
                name="pensionManager"
                initialOption="Pension Manager"
                selectWidth="100%"
                marginBottom="24px"
                values={pensionManagers.map(option => ({
                    value: option,
                }))}
                value={inputs.pensionManager}
                errorMessage={errors.pensionManager}
                currentSelected={inputs?.pensionManager}
                handleChange={handleSelectChange}
                onBlur={() => handleSelectOnBlur('pensionManager')}
                withCheckBox
                dropdownPositionRelative
                disabled={isAdminUser || isCustomerApproved}
            />

            <div className="DynamicInfoDateFieldWrapper">
                <ConsumerDateField
                    title={v2 ? 'Date of Retirement' : 'When did you retire?'}
                    width={'100%'}
                    name="retirementDate"
                    caretStyle="caretStyle"
                    dateValue={
                        inputs?.retirementDate?.length === 0
                            ? null
                            : new Date(inputs?.retirementDate)
                    }
                    value={inputs?.retirementDate}
                    errorMessage={errors?.retirementDate}
                    handleDateChange={(name, date) => {
                        handleChange({
                            target: {
                                name: 'retirementDate',
                                value: format(
                                    new Date(date),
                                    onePageKyc ? 'yyyy-MM-dd' : 'MMM dd, yyyy',
                                ),
                            },
                        })
                    }}
                    closeCalendarHandler={() => {
                        setShowCalendar(false)
                        if (!inputs.retirementDate) {
                            handleOnBlur({
                                target: {
                                    name: 'retirementDate',
                                    value: inputs.retirementDate,
                                },
                            })
                        }
                    }}
                    openCalendar={showCalendar}
                    openCalendarHandler={() => {
                        setShowCalendar(true)
                    }}
                    disabled={isAdminUser || isCustomerApproved}
                />
            </div>
        </>
    )
}

PensionDetails.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default PensionDetails
