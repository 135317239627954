import './outrightsalelistings.scss'
import { ReactComponent as SearchIcon } from 'src/assets/images/search.svg'
import { ReactComponent as FilterIcon } from 'src/assets/images/filter.svg'
import { Pagination } from 'antd'
import OutrightSaleTable from './components/OutrightSaleTable'
import { MultipleSelectField } from 'src/components/InputFields'
import FilterDrawer from './components/FilterDrawer'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import EmptyState from 'src/components/EmptyState'
import SeoComponent from 'src/components/Seo'
import Toast from 'src/components/Toast'
import { statusOptions } from './data'
import { useOutrightListings } from './hooks/useOutrightListings'
import { removeQueryParamFromUrl } from 'src/utils/urlTracker'

const OutrightSaleListings = () => {
    const {
        handleDrawer,
        handleFilterChange,
        updatePage,
        addSearchTextToFilter,
        outrightSalesRefetch,
        outrightSaleFetching,
        toastError,
        totalPages,
        errorMessage,
        setSearchTextInput,
        searchTextInput,
        data,
        drawerVisible,
        setFilterState,
        filterState,
    } = useOutrightListings()
    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={1}
                current={filterState?.currentPage}
                total={parseInt(totalPages, 10)}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={updatePage}
            />
        )
    }

    return (
        <>
            <SeoComponent title="SunFi | Admin | Outright Sale" />
            <FilterDrawer
                visible={drawerVisible}
                onClose={handleDrawer}
                filterState={filterState}
                setFilterState={setFilterState}
                setSearchTextInput={setSearchTextInput}
                data-testid="outright-sale-listings-filter-drawer"
            />
            {toastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                    data-testid="outright-sale-listings-error-toast"
                />
            )}
            <div className="OutrightSaleListings_Wrapper">
                <h1 className="OutrightSaleListings_Heading">Outright sale</h1>
                <div className="OutrightSaleListings_SearchAndFilter">
                    <div className="OutrightSaleListings_Search">
                        <input
                            placeholder={
                                'Search by consumer name or provider name'
                            }
                            value={searchTextInput}
                            onChange={e => {
                                if (e.target.value === '') {
                                    setSearchTextInput('')
                                    handleFilterChange({ searchText: '' })
                                    removeQueryParamFromUrl('search')
                                } else {
                                    const value = e.target.value
                                    setSearchTextInput(value)
                                }
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    addSearchTextToFilter()
                                    e.target.blur()
                                }
                            }}
                            onBlur={() => addSearchTextToFilter()}
                            data-testid="outright-sale-listings-search-input"
                        />
                        <SearchIcon
                            role="button"
                            data-testid="outright-sale-listings-search-button"
                            onClick={() => outrightSalesRefetch()}
                        />
                    </div>
                    <div
                        className="OutrightSaleListings_FiltersBtn"
                        role="button"
                        onClick={() => handleDrawer()}
                        data-testid={'outright-sale-listings-filter-btn'}
                    >
                        <FilterIcon role="presentation" />
                        <span>Filters</span>
                    </div>
                </div>
                <div className="OutrightSaleListings_Table_Wrapper">
                    <div
                        className={`OutrightSaleListings_Filter ${
                            outrightSaleFetching && 'isFetching'
                        }`}
                    >
                        <span>Filter by {'Status'}</span>
                        <MultipleSelectField
                            title={'All'}
                            name={'status'}
                            selectWidth={'171px'}
                            selectHeight={'48px'}
                            values={statusOptions}
                            value={filterState?.status?.map(
                                item => item?.value,
                            )}
                            inputValue={filterState?.status?.map(
                                item => item?.value,
                            )}
                            handleMultipleSelectChange={value => {
                                const values = value?.map(item => ({
                                    value: item,
                                    label: item,
                                    id: statusOptions?.filter(
                                        each => each.value === item,
                                    )?.[0]?.id,
                                }))
                                handleFilterChange({ status: values })
                            }}
                            maxTagCount={0}
                            optionStyle={{
                                width: '100%',
                                paddingRight: '12px',
                            }}
                        />
                    </div>
                    {outrightSaleFetching ? (
                        <ListingsSkeletalCards
                            total={0}
                            columnCount={5}
                            data-testid="outright-sale-listings-skeletal-cards"
                        />
                    ) : data?.length > 0 ? (
                        <OutrightSaleTable data={data} />
                    ) : (
                        <EmptyState title="No payment plans found" />
                    )}
                </div>

                {data?.length > 0 && !outrightSaleFetching && (
                    <div
                        className="OutrightSaleListings_Pagination"
                        role="navigation"
                    >
                        {paginationBlock()}
                    </div>
                )}
            </div>
        </>
    )
}

export default OutrightSaleListings
