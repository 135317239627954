//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { InputFields } from '../../components/InputFields'
import FormTitleBar from '../../components/FormTitleBar'
import Button from '../../components/Button'
import { replaceSpacewithUnderscore } from '../../utils/formatting'

import styles from './upfrontdeposit.module.scss'
import { isMobile } from 'src/utils/mediaQueries'
import { appTracking } from 'src/utils/appTracker'

const { UpfrontDepositModalWrapper, InputsContainer, UpfrontDepositBtn } =
    styles

const UpfrontDeposit = ({ closeModal, selectedPlanType, creditVariables }) => {
    const history = useHistory()
    const [upfrontDeposit, setUpfrontDeposit] = useState('')
    const [inputValue, setInputValue] = useState('')

    const [errors, setErrors] = useState('')
    const pageTitle = 'SunFi - Create A Payment Plan | Payment Plans'
    const pageUrlName = window.location.pathname
    const pageTracker = 'PAYMENT_PLAN_SIMULATION_TRACKER'
    const handleInputChange = event => {
        const { value } = event.target
        setInputValue(value)
        if (value === '') {
            return setErrors('This field is required')
        }

        if (Number(value) > 100) {
            return setErrors('Upfront deposit should not be greater than 100%')
        }

        if (upfrontDeposit !== '' && Number(value) < upfrontDeposit) {
            return setErrors(
                `Upfront deposit should not be less than ${upfrontDeposit}%`,
            )
        }

        setErrors('')
    }

    const handleOnBlur = event => {
        setUpfrontDeposit(event.target.value.trim())
    }

    useEffect(() => {
        let defaultValue = ''
        if (selectedPlanType === 'Subscription') {
            defaultValue = creditVariables.find(
                item => item?.payment_model === 'SUBSCRIPTION',
            )
        } else if (selectedPlanType === 'Lease to own') {
            defaultValue = creditVariables.find(
                item => item?.payment_model === 'LEASE_TO_OWN',
            )
        }
        setUpfrontDeposit(
            defaultValue?.upfront_deposit === undefined
                ? ''
                : parseInt(defaultValue?.upfront_deposit),
        )
        setInputValue(
            defaultValue?.upfront_deposit === undefined
                ? ''
                : parseInt(defaultValue?.upfront_deposit),
        )
    }, [selectedPlanType])

    const formatSelectedPlanType =
        replaceSpacewithUnderscore(selectedPlanType).toUpperCase()
    return (
        <div className={UpfrontDepositModalWrapper}>
            <FormTitleBar
                title="Upfront Deposit"
                subtitle="Enter the details of the request below to continue"
                titleFontSize="20px"
                alignLeft={isMobile}
                marginTop={isMobile ? '54px' : '0'}
            />
            <div className={InputsContainer}>
                <InputFields
                    name="upfrontDeposit"
                    inputWidth="464px"
                    title="Upfront Deposit"
                    type="number"
                    floatingLabel="%"
                    value={inputValue}
                    handleChange={handleInputChange}
                    onBlur={handleOnBlur}
                    errorMessage={errors}
                />
            </div>
            <div className={UpfrontDepositBtn}>
                <Button
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnWidth="160px"
                    disabled={inputValue === '' || errors !== ''}
                    handleClick={() => {
                        closeModal()
                        setErrors('')
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            'SIMULATION_PAYMENT_PLAN_TYPE',
                            'SIMULATION_PAYMENT_PLAN_TYPE',
                            'PAYMENT_PLAN_SIMULATION',
                            ['MP', 'GA'],
                            'event',
                            {
                                event: {
                                    SIMULATION_PAYMENT_PLAN_TYPE:
                                        formatSelectedPlanType,
                                },
                            },
                        )
                        history.push({
                            pathname: '/app/payment-plans/simulate/one',
                            state: { upfrontDeposit, formatSelectedPlanType },
                        })
                    }}
                >
                    Continue
                </Button>
            </div>
        </div>
    )
}

export default UpfrontDeposit
