import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    page: '1',
    name: '',
    start_date: null,
    end_date: null,
    status: '',
    payment_model: '',
    current_tab: '1',
}

const adminDTCReviewListing = createSlice({
    name: 'adminDTCReviewListingFilter',
    initialState,
    reducers: {
        updateAdminDTCReviewFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetAdminDTCReviewFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateAdminDTCReviewFilters, resetAdminDTCReviewFilters } =
    adminDTCReviewListing.actions

export const updatedAdminDTCReviewFilters = state =>
    state.adminDTCReviewListingFilter

const persistConfig = {
    key: 'adminDTCReviewListingFilter',
    storage,
}

export const persistedAdminDTCReviewListingReducer = persistReducer(
    persistConfig,
    adminDTCReviewListing.reducer,
)

export default adminDTCReviewListing.reducer
