import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import circleLeftBlue from 'src/assets/images/circle-left-pri-blue.svg'
import styles from './pagenav.module.scss'
import { ConsumerButton } from 'src/components/Button'
import { isMobile } from 'react-device-detect'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { usePageNav } from './usePageNav'
import RequestNewDealModal from 'src/pages/Consumer/ConsumerWorkspace/components/RequestNewDealModal'

const PageNav = ({ isBusiness, loading }) => {
    const {
        errors,
        inputs,
        contact,
        handleInputChange,
        validateUserIdMutation,
        registerDropOffLoading,
        showModal,
        setShowModal,
        handleBackNav,
        switchText,
        switchBtnText,
        handleSwitchType,
        showRequestNewDealModal,
        toggleRequestNewDealModal,
    } = usePageNav({ isBusiness })

    const returningActiveOrClosedCustomer = JSON.parse(
        sessionStorage.getItem('returningActiveOrClosedCustomer'),
    )

    return (
        <>
            <RequestNewDealModal
                showModal={showRequestNewDealModal}
                onCancel={toggleRequestNewDealModal}
                showUrgencyField={false}
            />

            <div className={styles.PageNavWrapper}>
                <div onClick={handleBackNav}>
                    <img
                        src={circleLeftBlue}
                        alt="circle-left-blue"
                        role={'presentation'}
                    />
                    <span>Go back</span>
                </div>
                <div>
                    {loading ? (
                        <Skeleton.Button
                            active
                            loading
                            style={{ width: 180, height: 20 }}
                        />
                    ) : (
                        <div>
                            <p className={styles.switchtext}>{switchText}</p>
                            <ConsumerButton
                                btnBgColor="#F9FCFF"
                                btnTextColor="#004AAD"
                                border="0.5px solid #004AAD"
                                btnHeight="31px"
                                borderRadius="30px"
                                fontFamily={'SF-Pro-Display-Medium'}
                                btnFontSize={12}
                                padding="0px 12px"
                                hover
                                handleClick={() => {
                                    if (
                                        returningActiveOrClosedCustomer?.status
                                    ) {
                                        toggleRequestNewDealModal()
                                    } else setShowModal(true)
                                }}
                            >
                                {switchBtnText}
                            </ConsumerButton>
                        </div>
                    )}
                </div>
                <SecondaryModal
                    showModal={showModal}
                    onCancel={() => {
                        registerDropOffLoading ||
                        validateUserIdMutation.isLoading
                            ? null
                            : setShowModal(false)
                    }}
                    modalWidth={'475px'}
                    title={'Switch your use type'}
                    v2
                    content={
                        <>
                            <div className={'GettingStartedWrapper UseType'}>
                                <div
                                    className={
                                        'GettingStartedHeadingAndText UseTypeContact'
                                    }
                                >
                                    <h3>
                                        Your added{' '}
                                        {contact === 'email'
                                            ? 'email address'
                                            : 'phone number'}
                                    </h3>
                                    <p>
                                        Please confirm you’re using this same{' '}
                                        {contact === 'email'
                                            ? 'email address'
                                            : 'phone number'}
                                    </p>
                                    {contact === 'email' ? (
                                        <InputFields
                                            title={'Email Address'}
                                            name={'email'}
                                            value={inputs?.email}
                                            handleChange={handleInputChange}
                                            marginBottom={0}
                                            errorMessage={
                                                errors['email'] ||
                                                errors['checkExistingStatus']
                                            }
                                            activateEnterClick
                                            enterFunc={handleSwitchType}
                                        />
                                    ) : (
                                        <SelectCountryCode
                                            title="Phone Number"
                                            name="phone"
                                            inputValue={inputs?.phone}
                                            handleChange={handleInputChange}
                                            marginBottom={0}
                                            sseUse={true}
                                            errorMessage={
                                                errors['phone'] ||
                                                errors['checkExistingStatus']
                                            }
                                            activateEnterClick
                                            enterFunc={handleSwitchType}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    }
                    showFooter
                    footerLeftContent={'chat'}
                    primaryBtnText={'Confirm'}
                    handlePrimaryBtnClick={() => {
                        handleSwitchType()
                    }}
                    primaryBtnWidth={isMobile ? '127.15px' : '160px'}
                    primaryBtnHeight={isMobile ? '44.5px' : '53px'}
                    primaryBtnLoading={
                        registerDropOffLoading ||
                        validateUserIdMutation.isLoading
                    }
                    secondaryBtnWidth={isMobile ? '140px' : '160px'}
                    secondaryBtnHeight={isMobile ? '44.5px' : '53px'}
                    secondaryBtnText={"I don't have these"}
                    handleSecondaryBtnClick={() => {}}
                    mobileModalTop="40%"
                />
            </div>
        </>
    )
}

PageNav.propTypes = {
    isBusiness: PropTypes.bool,
    loading: PropTypes.bool,
}

export default PageNav
