import 'src/components/GettingStarted/gettingstarted.scss'
import PropTypes from 'prop-types'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import useRequestNewDealModal from './hook'
import { SelectField } from 'src/components/InputFields'
import { urgencyOptions } from 'src/pages/Consumer/SmartShoppingExperience/Home/SelectUseType/data'
import Label from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/_components/Label'
import { useTypeDetails } from 'src/components/GettingStarted/data'
import { ReactComponent as Checked } from 'src/assets/images/circle-check-no-stroke-white-fill.svg'

const RequestNewDealModal = ({
    showModal,
    onCancel,
    showUrgencyField = true,
}) => {
    const { isMobile } = useMediaQueries()

    const {
        inputs,
        errors,
        handleSelectOnBlur,
        handleSelectChange,
        closeModal,
        handleSubmit,
    } = useRequestNewDealModal(onCancel, showUrgencyField)

    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={closeModal}
            modalWidth={'475px'}
            title={'Requesting a new deal'}
            footerLeftContent={'chat'}
            secondaryBtnText="I'll verify later"
            primaryBtnText={'Confirm'}
            handlePrimaryBtnClick={handleSubmit}
            primaryBtnWidth={isMobile ? '127.15px' : '160px'}
            primaryBtnHeight={isMobile ? '44.5px' : '53px'}
        >
            <div style={{ padding: isMobile ? 20 : '32px 42px' }}>
                {showUrgencyField && (
                    <SelectField
                        name={'urgency'}
                        initialOption="When do you need the solar installation?"
                        selectWidth={'100%'}
                        marginBottom={'20px'}
                        values={urgencyOptions?.map(option => ({
                            value: option,
                        }))}
                        value={inputs.urgency}
                        currentSelected={inputs.urgency}
                        handleChange={handleSelectChange}
                        onBlur={() => handleSelectOnBlur('urgency')}
                        withCheckBox
                        floatingLabel={
                            inputs?.urgency !== '' &&
                            'When do you need the solar installation?'
                        }
                        errorMessage={errors?.urgency}
                        dropdownPositionRelative
                    />
                )}
                <Label
                    title={'What are you getting this system for?'}
                    subTitle={
                        'Will the solar system be used for residential, SME or business purposes?'
                    }
                    titleFont="12px/18px var(--font-regular)"
                    subtitleFont="12px/18px var(--font-regular)"
                />
                <div className={'GettingStartedUseTypes RequestNewDealTypes'}>
                    {useTypeDetails?.map((each, i) => (
                        <div
                            key={i}
                            className={
                                'GettingStartedUseType RequestNewDealType'
                            }
                            role={'button'}
                            onClick={() => {
                                handleSelectChange('useType', each.value)
                            }}
                            style={{
                                backgroundColor:
                                    inputs.useType === each.value
                                        ? '#EBF3FF'
                                        : '#F9FCFF',
                                border:
                                    inputs.useType === each.value
                                        ? '0.5px solid #004AAD'
                                        : '0.5px solid #FFFFFF',
                            }}
                        >
                            <img
                                src={each.icon}
                                alt={each.title}
                                className={'GettingStartedUseType_Img'}
                            />
                            <div>
                                <h4>{each.title}</h4>
                                <p>{each.description}</p>
                            </div>
                            <Checked
                                className={'RequestNewDealType_CheckIcon'}
                                style={{
                                    stroke:
                                        inputs.useType === each.value
                                            ? '#004AAD'
                                            : '#E2EEFF',
                                }}
                            />
                        </div>
                    ))}
                    {errors?.useType && (
                        <p className={'GettingStartedUseTypes_Err'}>
                            {errors?.useType}
                        </p>
                    )}
                </div>
            </div>
        </ConsumerModalBase>
    )
}

RequestNewDealModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    showUrgencyField: PropTypes.bool,
}

export default RequestNewDealModal
