import { Skeleton } from 'antd'
import '../viewsystem.scss'
const SystemNavSkeleton = () => {
    return (
        <div className={'ViewSystemNav'}>
            <div className={'ViewSystemNav_SkeletonRow'}>
                <Skeleton.Image />
                <Skeleton active paragraph={{ rows: 1 }} />
            </div>
            <div className={'ViewSystemNav_SkeletonRight'}>
                <div className={'ViewSystemNav_SkeletonRight_Rows'}>
                    <Skeleton active paragraph={{ rows: 1 }} />
                    <Skeleton active paragraph={{ rows: 1 }} />
                </div>
                <Skeleton.Button active />
            </div>
        </div>
    )
}
export default SystemNavSkeleton
