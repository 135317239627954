import { useMutation, useQuery } from 'react-query'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { getClusterOrigination, postClusterOrigination } from './api'

export const useGetClusterOrigination = ({
    enabled = false,
    onSuccess,
    onError,
    params = {
        id: '',
    },
}) => {
    const { errorAlert } = useCustomToast()
    const { data, refetch, isFetching } = useQuery(
        ['get-cluster-origination'],
        () => getClusterOrigination(params?.id),
        {
            enabled: enabled,
            retry: false,
            refetchOnMount: true,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: err => {
                if (onError) {
                    onError(err?.response?.data?.errors)
                }
                errorAlert(errorHandler(err?.response?.data))
            },
        },
    )

    return {
        getClusterOriginationRefetch: refetch,
        getClusterOriginationFetching: isFetching,
        getClusterOriginationData: data?.data,
    }
}

export const usePostClusterOrigination = ({ onSuccess, onError, id }) => {
    const { errorAlert } = useCustomToast()
    const { data, mutate, isLoading } = useMutation({
        mutationFn: payload => postClusterOrigination(id, payload),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
            errorAlert(errorHandler(err?.response?.data))
        },
    })

    return {
        postClusterOriginationMutate: mutate,
        postClusterOriginationLoading: isLoading,
        postClusterOriginationData: data,
    }
}
