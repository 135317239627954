import 'src/pages/Admin/PaymentPlans/Details/components/AlternativePackages/alternativepackages.scss'
import { Drawer } from 'antd'
import PropTypes from 'prop-types'
import BackNav from 'src/components/BackNav'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { ReactComponent as CloseIcon } from 'src/assets/images/close-icon.svg'
import s from './selectvariables.module.scss'
import ButtonComponent from 'src/components/Button'
import { NoFLoatingLabelInputFields } from 'src/components/InputFields'
import { creditVariablesData } from './data'
import { useState } from 'react'
import arrow from 'src/assets/images/blue-arrow.svg'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'

const SelectVariablesDrawer = ({
    drawerVisible,
    handleDrawerClose,
    openEditAndPreviewModal,
}) => {
    const [selectedVariables, setSelectedVariables] = useState([])
    const [isOpen, setIsOpen] = useState(
        Array(creditVariablesData?.length).fill(false),
    )
    const { isMobile } = useMediaQueries()

    const onClose = () => {
        handleDrawerClose()
    }

    const checkSelected = data => {
        return selectedVariables?.find(sol => sol?.id === data.id)
    }

    const handleSelectVariable = data => {
        const currentVariable = selectedVariables.find(
            sol => sol?.id === data.id,
        )
        if (!currentVariable) {
            setSelectedVariables(prev => [...prev, data])
        } else {
            const otherSelectedVariables = selectedVariables.filter(
                sol => sol?.id !== data.id,
            )
            setSelectedVariables(otherSelectedVariables)
        }
    }

    const toggleBox = index => {
        setIsOpen(prevOpen => {
            const newOpen = [...prevOpen]
            newOpen[index] = !newOpen[index]
            return newOpen
        })
        //close all other boxes
        for (let i = 0; i < isOpen.length; i++) {
            if (i !== index) {
                setIsOpen(prevOpen => {
                    const newOpen = [...prevOpen]
                    newOpen[i] = false
                    return newOpen
                })
            }
        }
    }

    const allSelected =
        selectedVariables?.length === creditVariablesData?.length

    const toggleSelectAll = () => {
        if (allSelected) {
            setSelectedVariables([])
        } else setSelectedVariables(creditVariablesData)
    }

    return (
        <Drawer
            placement="right"
            width={isMobile ? '100%' : '480px'}
            visible={drawerVisible}
            onClose={onClose}
            closable={false}
            className="SelectVariablesDrawer_Wrapper"
        >
            <div className={s.goBackBtns}>
                <BackNav title="Back to Credit System" onClick={onClose} />
                <CloseIcon role={'button'} onClick={onClose} />
            </div>
            <div className={s.titleAndBtns}>
                <h2>Select Credit Variables</h2>
                <div>
                    <ButtonComponent
                        type={'outline'}
                        btnBgColorOutline={'#F9FCFF'}
                        border={'1px solid #004AAD'}
                        btnTextColorOutline={'#004AAD'}
                        btnWidth={allSelected ? '95px' : '84px'}
                        btnHeight={'44px'}
                        handleClick={toggleSelectAll}
                    >
                        {allSelected ? 'Unselect all' : 'Select all'}
                    </ButtonComponent>
                    <ButtonComponent
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#FFFFFF'}
                        btnWidth={'91px'}
                        btnHeight={'44px'}
                        disabled={!selectedVariables?.length}
                        handleClick={openEditAndPreviewModal}
                    >
                        Apply edit
                    </ButtonComponent>
                </div>
            </div>
            <NoFLoatingLabelInputFields
                borderRadius="7px"
                inputWidth="100%"
                inputHeight="45px"
                type="text"
                iconType="search"
                prefilled
                inputPlaceholder="Search credit variables"
                style={{ paddingBottom: 7 }}
                handleChange={() => {}}
            />
            <VerticalSpacer top={44} />
            <div>
                {creditVariablesData?.map((each, i) => {
                    const isSelected = checkSelected(each)
                    return (
                        <div
                            key={i}
                            className="CustomRecommendationEnergySolutionWrapper"
                        >
                            <div className={s.dropdownTop}>
                                <h4>{each?.name}</h4>
                                <div className={s.flagAndBtn}>
                                    {isSelected ? (
                                        <span className={s.selectedFlag}>
                                            SELECTED
                                        </span>
                                    ) : null}
                                    <ButtonComponent
                                        btnBgColor={
                                            isSelected ? '#E2EEFF' : '#004AAD'
                                        }
                                        btnTextColor={
                                            isSelected ? '#004AAD' : '#FFFFFF'
                                        }
                                        btnWidth={'70px'}
                                        btnHeight={'35.58px'}
                                        handleClick={() =>
                                            handleSelectVariable(each)
                                        }
                                    >
                                        {isSelected ? 'Unselect' : 'Select'}
                                    </ButtonComponent>
                                </div>
                                <div
                                    onClick={() => toggleBox(i)}
                                    className={'CRESTopArrowWrapper'}
                                    style={{
                                        justifySelf: 'flex-end',
                                        marginLeft: 19,
                                    }}
                                >
                                    <img
                                        src={arrow}
                                        alt="arrow"
                                        className={
                                            isOpen[i]
                                                ? 'CRESTopArrowOpen'
                                                : 'CRESTopArrowClosed'
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                className={
                                    isOpen[i]
                                        ? 'CustomRecommendationEnergySolutionBottomOpen'
                                        : 'CustomRecommendationEnergySolutionBottomClosed'
                                }
                            >
                                <div className={s.detailsGrid}>
                                    {each?.details?.map((detail, i) => (
                                        <div key={i}>
                                            <h5>{detail.label}</h5>
                                            <p>{detail.value}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </Drawer>
    )
}

SelectVariablesDrawer.propTypes = {
    drawerVisible: PropTypes.bool,
    handleDrawerClose: PropTypes.func,
    openEditAndPreviewModal: PropTypes.func,
}

export default SelectVariablesDrawer
