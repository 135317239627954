import styles from './accountsetupfooter.module.scss'
import { Link } from 'react-router-dom'
import { ReactComponent as Twitter } from 'src/assets/images/twitter-grey.svg'
import { ReactComponent as Facebook } from 'src/assets/images/facebook-grey.svg'
import { ReactComponent as Instagram } from 'src/assets/images/instagram-grey.svg'
import { ReactComponent as LinkedIn } from 'src/assets/images/linkedin-grey.svg'
const AccountSetupFooter = () => {
    const { footer, links, right, socials, logo } = styles
    return (
        <footer className={footer}>
            <div className={links}>
                <img
                    src={
                        'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Blue.png'
                    }
                    alt="SunFi logo"
                    className={logo}
                    onClick={() => (window.location.pathname = '/')}
                />
                <Link to="/privacy-policy" target={'_blank'}>
                    Privacy Policy
                </Link>
                <Link to="/terms-of-service" target={'_blank'}>
                    Terms of Service
                </Link>
            </div>
            <div className={right}>
                <div className={socials}>
                    <a
                        href="https://twitter.com/sunfi_hq"
                        target="_blank"
                        rel="noreferrer"
                        aria-label={'Link to the SunFi Twitter page'}
                    >
                        <Twitter />
                    </a>
                    <a
                        href="https://www.instagram.com/sunfihq/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label={'Link to the SunFi Instagram page'}
                    >
                        <Instagram />
                    </a>
                    <a
                        href="https://web.facebook.com/sunfihq"
                        target="_blank"
                        rel="noreferrer"
                        aria-label={'Link to the SunFi Facebook page'}
                    >
                        <Facebook />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/sunfihq/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label={'Link to the SunFi LinkedIn page'}
                    >
                        <LinkedIn />
                    </a>
                </div>
                <p>© 2024 SunFi, all rights reserved</p>
            </div>
        </footer>
    )
}

export default AccountSetupFooter
