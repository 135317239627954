/* eslint-disable react/prop-types */
import './tooltip.scss'

const CustomToolTip = ({
    children,
    text,
    consumerPaymentPlanToolTip,
    toolTipWidth,
    toolTipFontSize,
    toolTipPadding,
    showTooltip = true,
    toolTipPopupWidth,
    toolTipMarginTop,
    toolTipMarginLeft,
    arrowPositionLeft,
    arrowPositionCenter,
    lineHeight,
    background,
    borderRadius,
    top,
    visibility,
    tooltipZIndex,
    textAlign,
    preventLineBreak,
    disabled,
    handleClick,
}) => {
    const consumerPaymentPlanToolTipStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        width: toolTipWidth ?? '174px',
    }

    return (
        <div
            className={`tooltip ${
                arrowPositionLeft
                    ? 'arrowPositionLeft'
                    : arrowPositionCenter
                    ? 'arrowPositionCenter'
                    : ''
            }`}
            style={{
                ...(consumerPaymentPlanToolTip
                    ? consumerPaymentPlanToolTipStyle
                    : {}),
                cursor: disabled ? 'not-allowed' : 'default',
            }}
            data-testid="custom-tooltip"
            onClick={handleClick}
        >
            {children}
            <span
                className="tooltiptext"
                style={{
                    fontSize: toolTipFontSize,
                    padding: toolTipPadding,
                    display: showTooltip ? '' : 'none',
                    width: toolTipPopupWidth && toolTipPopupWidth,
                    marginTop: toolTipMarginTop && toolTipMarginTop,
                    marginLeft: toolTipMarginLeft && toolTipMarginLeft,
                    lineHeight: lineHeight && lineHeight,
                    background: background && background,
                    borderRadius: borderRadius && borderRadius,
                    top: top && top,
                    visibility: visibility && visibility,
                    zIndex: tooltipZIndex && tooltipZIndex,
                    textAlign: textAlign && textAlign,
                    whiteSpace: preventLineBreak ? 'nowrap' : '',
                }}
            >
                {text}
            </span>
        </div>
    )
}

export default CustomToolTip
