import { ReactComponent as PagaIcon } from 'src/assets/images/paga.svg'
import { ReactComponent as CardDebitIcon } from 'src/assets/images/card-debit.svg'
import { ReactComponent as DirectDebitIcon } from 'src/assets/images/direct-debit.svg'
import { ReactComponent as PaystackLogo } from 'src/assets/images/paystack-icon-2.svg'
import { ReactComponent as RemitaLogo } from 'src/assets/images/remita-logo.svg'
import { ReactComponent as RecovaLogo } from 'src/assets/images/recova-logo.svg'

export const paymentTypeList = () => {
    const isDirectDebit = process.env.REACT_APP_ENABLE_REMITTA_DIRECT_DEBIT
    const isPaga = process.env.REACT_APP_ENABLE_PAGA_FOR_REPAYMENT_METHOD_SETUP

    const list = [
        {
            id: 1,
            icon: <CardDebitIcon />,
            hoverIcon: '',
            title: 'Card Debit',
            subTitle: 'Setup your SunFi repayment with your debit card ',
        },
    ]

    isDirectDebit === 'true' &&
        list.splice(1, 0, {
            id: 2,
            icon: <DirectDebitIcon />,
            hoverIcon: '',
            title: 'Direct Debit',
            subTitle: 'Pay directly from your bank account using direct debit',
        })

    isPaga === 'true' &&
        list.splice(3, 0, {
            id: 3,
            icon: <PagaIcon />,
            hoverIcon: '',
            title: 'Pay with Paga',
            subTitle: 'Setup your SunFi repayment using Paga',
        })

    return list
}

export const directDebitTypeList = directDebitTypes => {
    return directDebitTypes
        .map((type, index) => {
            let icon
            let title
            let subTitle
            let value

            switch (type) {
                case 'PAYSTACK:DIRECT_DEBIT':
                    if (
                        process.env.REACT_APP_SHOW_PAYSTACK_DIRECT_DEBIT ===
                        'true'
                    ) {
                        icon = <PaystackLogo />
                        title = 'Pay using Paystack'
                        value = 'paystack'
                        subTitle =
                            'Pay with your credit approved bank account using Paystack'
                    }

                    break
                case 'REMITA:DIRECT_DEBIT':
                    if (
                        process.env.REACT_APP_ENABLE_REMITTA_DIRECT_DEBIT ===
                        'true'
                    ) {
                        icon = <RemitaLogo style={{ width: '36px' }} />
                        title = 'Pay using Remita'
                        value = 'remita'
                        subTitle =
                            'Pay with your credit approved bank account using Remitta'
                    }
                    break
                case 'RECOVA:DIRECT_DEBIT':
                    if (
                        process.env.REACT_APP_ENABLE_RECOVA_DIRECT_DEBIT ===
                        'true'
                    ) {
                        icon = <RecovaLogo style={{ width: '36px' }} />
                        title = 'Pay using Recova'
                        value = 'recova'
                        subTitle =
                            'Pay with your credit approved bank account using Recova'
                    }

                    break
                // Add more cases for other direct debit types as needed
                default:
                    icon = null
                    title = ''
                    subTitle = ''
            }

            if (icon && title && subTitle) {
                return {
                    id: index + 1,
                    icon,
                    title,
                    value,
                    subTitle,
                }
            }
            return null
        })
        .filter(Boolean)
}

export const selectDirectDebitCopyOptions = [
    {
        title: 'How would you like to make payment?',
        description: 'Choose a preferred payment type from the options below.',
    },
    {
        title: 'Experiencing trouble with Recova? Select another option',
        description:
            "We noticed you're experiencing a challenge setting up your repayment through Recova. No worries, you can try using any of the options below.",
    },
]
