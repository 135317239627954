export const generatorSize = [
    '1kVA',
    '2.5kVA',
    '3.5kVA',
    '4.1kVA',
    '5kVA',
    '7kVA',
    '8.5kVA',
    '10kVA',
    '15kVA',
    '20kVA',
    '25kVA',
    '30kVA',
    '40kVA',
    '50kVA',
    '75kVA',
    '100kVA',
    '135kVA',
    '200kVA',
    '250kVA',
    '350kVA',
    '500kVA',
    '600kVA',
    '700kVA',
    '800kVA',
]

export const dropdownPaymentPlansData = data => {
    const filteredData = data?.filter(item => {
        return [3, 6, 9, 12, 15, 18, 21, 24, 36].includes(item.month)
    })

    const savingsData = filteredData
        ?.filter(
            data =>
                parseFloat(
                    data.cumulative_savings ||
                        data.break_even_amount?.replace(',', ''),
                ) > 0,
        )
        .map(data => data?.month)
    return savingsData
}

export const getMonthlyValues = (data, selectedMonth) => {
    const filteredData = data?.filter(item => {
        return [3, 6, 9, 12, 15, 18, 21, 24, 36].includes(item.month)
    })
    const selectedData = filteredData?.find(
        item => item.month === selectedMonth,
    )

    return selectedData
}