import { useQuery } from 'react-query'
import { getProfilesApi } from 'src/api/admin/profiles/listings'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { profileType } from '../data'

const useGetProfiles = ({ params, onSuccess, enabled }) => {
    const { errorAlert } = useCustomToast()

    const {
        data,
        isFetching,
        refetch: profilesRefetch,
    } = useQuery({
        queryKey: [
            'fetch-profiles-for-groups',
            params?.customer_type,
            params?.ungrouped_profile,
            params?.profileGroupId,
        ],
        queryFn: () =>
            getProfilesApi(
                '',
                '',
                '',
                false,
                profileType.filter(
                    item => item.name === params?.customer_type,
                )[0]?.value || '',
                params?.profileGroupId || '',
                params?.ungrouped_profile,
            ),
        retry: false,
        enabled: enabled,
        refetchOnMount: true,
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: error => {
            errorAlert(
                errorHandler(error.response?.data) ||
                    `Couldn't fetch consumer profiles. please try again`,
            )
        },
    })

    return {
        profiles: data?.data?.data || [],
        profilesFetching: isFetching,
        profilesRefetch,
    }
}

export default useGetProfiles
