import { useEffect, useState } from 'react'
import { offerStatusMap } from 'src/utils/Data/status'
import { useSelector } from 'react-redux'
import { updateAdminOutrightSaleFilters } from 'src/redux/reducers/admin/outright-sale/listing'
import { toTitleCase } from 'src/utils/formatting'
import { useGetOutrightSales } from './useGetOutrightSales'
import { providerTypeInverseMap, statusInverseMapping } from '../data'
import {
    addQueryParamsToUrl,
    getSearchParamsFromUrl,
} from 'src/utils/urlTracker'

export const useOutrightListings = () => {
    const filterQuery = getSearchParamsFromUrl()
    const defaultQueries = useSelector(updateAdminOutrightSaleFilters)

    const [data, setData] = useState([])
    const [totalPages, setTotalPages] = useState(null)
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [searchTextInput, setSearchTextInput] = useState(
        filterQuery.search || defaultQueries.name || '',
    )

    const formattedDefaultQueriesApplicationStatus = [
        defaultQueries.application_status,
    ].map(status => ({
        label: offerStatusMap[status],
        value: offerStatusMap[status],
    }))

    const formattedDefaultQueriesStatus = () => {
        const status = defaultQueries.statuses?.split(',')?.map(status => ({
            label: statusInverseMapping[status],
            value: statusInverseMapping[status],
            remove:
                status === 'AWAITING_UPDATE' || status === 'CLOSED'
                    ? false
                    : true,
        }))

        return status?.filter(status => status.remove === true)
    }

    const [filterState, setFilterState] = useState({
        surveyType: defaultQueries.survey_type || 'CFS,UFS',
        currentPage:
            Number(filterQuery.page) || Number(defaultQueries.page) || 1,
        searchText: filterQuery.search || defaultQueries.name || '',
        status:
            defaultQueries.statuses?.length > 0
                ? formattedDefaultQueriesStatus()
                : defaultQueries.application_status?.length > 0
                ? formattedDefaultQueriesApplicationStatus
                : [],
        filterInputs: {
            start_date:
                filterQuery.startDate || defaultQueries.start_date || null,
            end_date: filterQuery.endDate || defaultQueries.end_date || null,
            provider_type:
                defaultQueries.provider_type?.length > 0
                    ? [providerTypeInverseMap[defaultQueries.provider_type]]
                    : [],
            campaign_type:
                defaultQueries.campaign?.length > 0
                    ? toTitleCase(defaultQueries.campaign)
                    : '',
            payment_plan_type: defaultQueries.payment_plan || 'Outright Sale',
            statement_type:
                defaultQueries.bank_statement_source?.length > 0
                    ? [toTitleCase(defaultQueries.bank_statement_source)]
                    : [],

            onboarding_type:
                defaultQueries.onboarding_type?.length > 0
                    ? [toTitleCase(defaultQueries.onboarding_type)]
                    : [],
        },
    })

    const [toastError, setToastError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const showUpdatedSSEFlow =
        process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW === 'true'

    const handleFilterChange = newFilters => {
        setFilterState(prevState => ({
            ...prevState,
            currentPage: 1,
            ...newFilters,
        }))
    }

    const updatePage = page => {
        handleFilterChange({ currentPage: page })
        addQueryParamsToUrl({
            page: page,
        })
    }

    const handleDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    const addSearchTextToFilter = () => {
        if (searchTextInput !== '') {
            handleFilterChange({ searchText: searchTextInput })
            addQueryParamsToUrl({
                search: searchTextInput,
                page: '1',
            })
        }
    }

    const { outrightSaleFetching, outrightSalesRefetch } = useGetOutrightSales({
        filterState,
        showUpdatedSSEFlow,
        setData,
        setTotalPages,
        setToastError,
        setErrorMessage,
    })

    useEffect(() => {
        outrightSalesRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState])

    return {
        handleDrawer,
        handleFilterChange,
        updatePage,
        addSearchTextToFilter,
        outrightSalesRefetch,
        outrightSaleFetching,
        toastError,
        totalPages,
        errorMessage,
        setSearchTextInput,
        searchTextInput,
        data,
        drawerVisible,
        setFilterState,
        filterState,
    }
}
