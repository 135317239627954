/* eslint-disable no-undef */
import axios from '../../config/axios'

export const resumeUploadApi = async file => {
    const formData = new FormData()
    formData.append('name', 'resume')
    formData.append('image', file)

    const request = await axios.post(`/providers/resumes/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    return request
}

export const parseResumeApi = async url => {
    var myHeaders = new Headers()
    myHeaders.append('apikey', process.env.REACT_APP_PROMPT_API_KEY)

    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders,
    }

    const request = await fetch(
        `https://api.promptapi.com/resume_parser/url?url=${url}`,
        requestOptions,
    )
    const response = await request.json()

    return response
}
