/* eslint-disable no-unused-vars */
import { ReactComponent as Battery } from 'src/assets/images/battery.svg'
import { ReactComponent as Inverter } from 'src/assets/images/inverter.svg'
import { ReactComponent as EnergyCapacity } from 'src/assets/images/energy-capacity.svg'
import { ReactComponent as Warranty } from 'src/assets/images/warranty.svg'
import { ReactComponent as BatteryVoltage } from 'src/assets/images/battery-voltage.svg'
import { ReactComponent as ComponentName } from 'src/assets/images/component-name.svg'
import { ReactComponent as Package } from 'src/assets/images/package-grey-bg.svg'
import { ReactComponent as Details } from 'src/assets/images/details-grey-bg.svg'
import { ReactComponent as Plan } from 'src/assets/images/plan-grey-bg.svg'
import { ReactComponent as Percentage } from 'src/assets/images/percent-grey-bg.svg'
import { isMobile } from 'src/utils/mediaQueries'
import { ReactComponent as Checklist } from 'src/assets/images/checklist.svg'
import { ReactComponent as BatteryCheck } from 'src/assets/images/battery-check.svg'
import { ReactComponent as SunCheck } from 'src/assets/images/sun-check.svg'
import { currencyFormatter } from 'src/utils/currencyFormatter'

export const tabLabels = (page, isDEPConsumer, recommendedCombo) => {
    const tabLabel = [
        page === 'promo' &&
            recommendedCombo?.length > 0 && {
                label: 'Recommended combo',
                id: 'VS-RecommendedCombo',
            },
        { label: 'System Overview', id: 'VS-Overview' },
        { label: 'Payment Breakdown', id: 'VS-PaymentBreakdown' },
        page !== 'promo' &&
            !isDEPConsumer && {
                label: 'Savings Summary',
                id: 'VS-SavingsSummary',
            },
    ].filter(Boolean)

    return tabLabel
}

export const recommendedComboData = recommendedCombo => {
    const systemCombo = recommendedCombo?.map(combo => {
        return {
            icon: <EnergyCapacity />,
            label: combo.label,
            value: combo.value,
        }
    })
    return systemCombo
}

export const overviewData = (systemDetails, isPromoPage) => {
    const batteryName = systemDetails?.components
        ?.filter(component => component.type?.includes('Battery'))
        ?.map(
            component =>
                `${component?.attributes['Rated Capacity']?.value} ${
                    component?.attributes['Rated Capacity']?.unit_of_measurement
                } 
                ${component.type}  
                ${
                    component?.quantity > 1
                        ? `(${component?.quantity} units)`
                        : ''
                }`,
        )

    const promoPackageBatteryName = systemDetails?.components
        ?.filter(component =>
            component?.component?.type?.name?.includes('Battery'),
        )
        ?.map(
            component =>
                `${component?.component?.attributes['Rated Capacity']?.value} ${
                    component?.component?.attributes['Rated Capacity']
                        ?.unit_of_measurement
                }
                ${component?.component?.type}               
                ${
                    component?.component?.quantity > 1
                        ? `(${component?.component?.quantity} units)`
                        : ''
                }`,
        )

    const batteryVoltage = systemDetails?.components
        ?.filter(component => component.type?.includes('Battery'))
        ?.map(
            component =>
                `${component?.attributes?.Voltage?.value} ${component?.attributes?.Voltage?.unit_of_measurement}`,
        )

    const promoPackageBatteryVoltage = systemDetails?.components
        ?.filter(component =>
            component?.component?.type?.name?.includes('Battery'),
        )
        ?.map(
            component =>
                `${component?.component?.attributes?.Voltage?.value} ${component?.component?.attributes?.Voltage?.unit_of_measurement}`,
        )

    const inverterName = systemDetails?.components
        ?.filter(component => component.type?.includes('Inverter'))
        ?.map(
            component =>
                `${component?.attributes['Rated Capacity']?.value} ${
                    component?.attributes['Rated Capacity']?.unit_of_measurement
                } 
                ${component.type}  
                ${
                    component?.quantity > 1
                        ? `(${component?.quantity} units)`
                        : ''
                }`,
        )

    const promoPackageInverterName = systemDetails?.components
        ?.filter(component =>
            component?.component?.type?.name?.includes('Inverter'),
        )
        ?.map(
            component =>
                `${component?.component?.attributes['Rated Capacity']?.value} ${
                    component?.component?.attributes['Rated Capacity']
                        ?.unit_of_measurement
                }
                ${component?.component?.type}  ${
                    component?.component?.quantity > 1
                        ? `(${component?.component?.quantity} units)`
                        : ''
                }`,
        )

    const componentName = systemDetails?.components
        ?.filter(component => component.type?.includes('Panel'))
        ?.map(
            component => `${component?.attributes['Rated Capacity']?.value} ${
                component?.attributes['Rated Capacity']?.unit_of_measurement
            }
         ${component?.type} ${
                component?.quantity > 1 ? `(${component?.quantity} units)` : ''
            }`,
        )

    const promoPackageComponentName = systemDetails?.components
        ?.filter(component =>
            component?.component?.type?.name?.includes('Panel'),
        )
        ?.map(
            component => `${
                component?.component?.attributes['Rated Capacity']?.value
            } ${
                component?.component?.attributes['Rated Capacity']
                    ?.unit_of_measurement
            }
         ${component?.component?.type} ${
                component?.component?.quantity > 1
                    ? `(${component?.component?.quantity} units)`
                    : ''
            }`,
        )

    const energyCapacity =
        systemDetails?.energy_capacity?.value +
        systemDetails?.energy_capacity?.unit_of_measurement

    const warranty = systemDetails?.warranty
        ? systemDetails.warranty + ' Months'
        : undefined

    const batteryWarranty = systemDetails?.components
        ?.filter(component => component?.type?.includes('Battery'))
        ?.map(component =>
            component?.warranty && component.warranty > 0
                ? `${component.warranty} Months`
                : null,
        )
        .filter(warranty => warranty !== null)

    const solarPanelWarranty = systemDetails?.components
        ?.filter(component => component?.type?.includes('Solar Panel'))
        ?.map(component =>
            component?.warranty && component.warranty > 0
                ? `${component.warranty} Months`
                : null,
        )
        .filter(warranty => warranty !== null)

    const systemOverviewData = []
    const addIfExist = (icon, label, value) => {
        if (
            value !== null &&
            value !== undefined &&
            (Array.isArray(value) ? value.length > 0 : true)
        ) {
            systemOverviewData.push({
                icon,
                label,
                value: Array.isArray(value) ? value.join(', ') : value,
            })
        }
    }

    addIfExist(
        <Battery />,
        'Battery',
        isPromoPage ? promoPackageBatteryName : batteryName,
    )
    addIfExist(
        <Inverter />,
        'Inverter',
        isPromoPage ? promoPackageInverterName : inverterName,
    )
    addIfExist(<EnergyCapacity />, 'System Capacity', energyCapacity)
    addIfExist(
        <BatteryVoltage />,
        'Battery Voltage',
        isPromoPage ? promoPackageBatteryVoltage : batteryVoltage,
    )
    addIfExist(
        <ComponentName />,
        'Solar Panel',
        isPromoPage ? promoPackageComponentName : componentName,
    )
    addIfExist(<Warranty />, 'Package/Inverter warranty', warranty)
    addIfExist(<BatteryCheck />, 'Battery warranty', batteryWarranty)
    addIfExist(<SunCheck />, 'Solar panel warranty', solarPanelWarranty)

    return systemOverviewData
}
export const sortPaymentPlans = (arr = []) => {
    return arr?.sort((a, b) => {
        if (a.count < b.count) {
            return -1
        }
        if (a.count > b.count) {
            return 1
        }
        return 0
    })
}
export const availablePaymentPlans = (
    paymentPlans = [],
    preferred_payment_tenure,
) => {
    if (!preferred_payment_tenure) {
        return paymentPlans.map(plan => ({
            count: plan?.tenure,
            monthly_payment: plan?.monthly_payment,
            initial_deposit: plan?.initial_deposit,
            total_payment: plan?.investment_meta_data?.total_paid,
        }))
    }
    const noOfPaymentPlans = paymentPlans?.length
    const cheapestPaymentPlan = paymentPlans?.[noOfPaymentPlans - 1]
    const preferredPaymentPlan = paymentPlans?.filter(
        el => el?.tenure === preferred_payment_tenure,
    )[0]
    const preferredPaymentIndex = paymentPlans?.findIndex(
        el => el.tenure === preferred_payment_tenure,
    )

    // // if the preferred payment plan is the cheapest
    if (preferredPaymentIndex === noOfPaymentPlans - 1) {
        const paymentPlansToShow = [
            preferredPaymentPlan,
            paymentPlans[noOfPaymentPlans - 2],
        ]
        // show the preferred payment plan and the second cheapest
        const remainingPaymentPlans = paymentPlans?.filter(
            (_, idx) =>
                idx !== preferredPaymentIndex && idx !== noOfPaymentPlans - 2,
        )
        const systemPaymentPlans = paymentPlansToShow.concat(
            remainingPaymentPlans,
        )

        return systemPaymentPlans?.map(plan => {
            return {
                count: plan?.tenure,
                monthly_payment: plan?.monthly_payment,
                initial_deposit: plan?.initial_deposit,
                total_payment: plan?.investment_meta_data?.total_paid,
                investment_meta_data: plan?.investment_meta_data,
            }
        })
    }

    //else show the cheapest plan and the most preferred payment plan
    const paymentPlansToShow = [cheapestPaymentPlan]

    if (preferredPaymentPlan) {
        paymentPlansToShow.push(preferredPaymentPlan)
    }

    const remainingPaymentPlans = paymentPlans?.filter(
        (_, idx) =>
            idx !== preferredPaymentIndex && idx !== noOfPaymentPlans - 1,
    )
    const systemPaymentPlans = paymentPlansToShow.concat(remainingPaymentPlans)

    const filteredSystemPaymentPlans = systemPaymentPlans?.map(plan => {
        return {
            count: plan?.tenure,
            monthly_payment: plan?.monthly_payment,
            initial_deposit: plan?.initial_deposit,
            total_payment: plan?.investment_meta_data?.total_paid,
            investment_meta_data: plan?.investment_meta_data,
        }
    })

    return filteredSystemPaymentPlans
}

export const stepsItems = [
    {
        icon: <Package />,
        title: 'Select your preferred package',
        description:
            'Choose the particular system that is perfect for you on the list of recommended systems.',
    },
    {
        icon: <Details />,
        title: 'Provide us with your details',
        description:
            'Create an account on SunFi by providing us with other required details.',
    },
    {
        icon: <Plan />,
        title: 'Select your plan ',
        description:
            'Select the plan that is perfect for you from the recommended plans for the plan you’ve chosen.',
    },
    {
        icon: <Percentage />,
        title: 'Make your initial deposit',
        description:
            'Make your initial deposit for the selected plan and then set up your method for your monthly repayment',
    },
]

export const getStartedStepsItems = [
    {
        icon: <Package />,
        title: 'Fill Out the "Get Offer" Form',
        description:
            'To receive a tailored offer, complete our "Get Offer" form and share your information and energy requirements.',
    },
    {
        icon: <Checklist />,
        title: 'Review Our Recommended Solutions',
        description:
            "We'll provide you with recommended solutions from our trusted provider network. Our team will work with your shared information to recommend the best solar options for your needs and budget.",
    },
    {
        icon: <Plan />,
        title: 'Choose a Payment Plan',
        description:
            'Select a payment plan that works for you, with options including lease-to-own and subscriptions.',
    },
    {
        icon: <Percentage />,
        title: 'Swift and Efficient Execution',
        description:
            'Our team will promptly schedule a site assessment and installation with one of our certified installers.',
    },
]

export const savingsChartData = (genCost, solarCost, isMonthlyCostChart) => {
    //400 or 260 is the max height of the bar
    const maxCost = Math.max(genCost, solarCost)
    const maxRatio = (isMobile ? 260 : 400) / maxCost

    return [
        {
            id: 1,
            label: isMonthlyCostChart
                ? 'Est. gen monthly <br/>cost+maintenance for year 1'
                : 'Est. gen cost+maintenance <br/>over 60 months',
            cost: genCost,
            color: '#E2EEFF',
            height: genCost * maxRatio,
            className: 'second-bar',
        },
        {
            id: 2,
            label: isMonthlyCostChart
                ? 'Est. solar monthly cost for year 1'
                : 'Est. solar cost over 60 months',

            cost: solarCost,
            color: '#004AAD',
            height: solarCost * maxRatio,
            className: 'first-bar',
        },
    ]
}

export const paymentModelMap = {
    'Lease to Own': 'LEASE_TO_OWN',
    Subscription: 'SUBSCRIPTION',
    'Outright Sale': 'OUTRIGHT_SALE',
}

export const otherOptionCardContent = {
    LEASE_TO_OWN: {
        tag: 'Lease to Own',
        paymentModelText: 'MIN. TENURE TO OWN',
        tooltipTilte: 'What is a Lease to Own payment type?',
        tooltipContent:
            'Own this system by paying in installments, choosing a tenure that works for you to pay back, after making an upfront deposit.',
    },
    SUBSCRIPTION: {
        tag: 'Subscription',
        paymentModelText: 'MIN. TENURE TO SUBSCRIBE',
        tooltipTilte: 'What is a Subscrition payment type?',
        tooltipContent:
            'Get this system by subscribing to solar-as-a-servicer, paying a specific amount monthly to use the system.',
    },
    OUTRIGHT_SALE: {
        tag: 'Outright Sale',
        paymentModelText: 'PAY IN FULL',
        tooltipTilte: 'What is an Outright Sale payment type?',
        tooltipContent:
            'Own this system by paying in full at once without any interest.',
    },
}

export const paymentOptions = ['LEASE_TO_OWN', 'SUBSCRIPTION', 'OUTRIGHT_SALE']

export const customerTypeRemapping = {
    BUSINESS: 'Business use',
    RESIDENTIAL: 'Residential use',
}

export const paymentPlansFormatter = data => {
    return data?.map(plan => ({
        monthlyPayment: plan?.monthly_payment,
        upfrontDeposit: currencyFormatter(plan?.initial_deposit),
        solutionAmount: currencyFormatter(plan?.solution_amount),
        tenure: plan?.tenure,
        tenureTitle: `${plan?.tenure} months`,
    }))
}

export const actionModalValues = action => {
    switch (action) {
        case 'customer-exists':
            return {
                actionModalTitle: 'You already have an account',
                actionModalHeaderText:
                    'Welcome back, you have previously created an account',
                actionModalSubtitle:
                    "Sign in into your account to continue to view this system, once you're signed you’ll be able to view this system.",
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Sign in',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'confirm-continue-to-package':
            return {
                actionModalTitle: 'You already have a selected package',
                actionModalHeaderText: (
                    <>
                        Welcome back, would you like
                        <br />
                        to continue with your selected package?
                    </>
                ),
                actionModalSubtitle:
                    'You have previously selected a package with your created account. Your old package will be discarded if you will like to change to this new package, please confirm.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm new package',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Use old package',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
    }
}
