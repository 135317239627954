import PropTypes from 'prop-types'
import ApprovedBrandsCard from './ApprovedBrandsCard'
import ReviewBrandsCard from './ReviewBrandsCard'
import { useGetProviderBrands } from './hooks/useGetProviderBrands'
import { ComponentBrandsSkeletalCards } from 'src/components/SkeletalCards'
import EmptyState from 'src/components/EmptyState'
import styles from './componentbrands.module.scss'

const ComponentBrands = ({ providerId, handleConfirmBrandingDecision }) => {
    const {
        componentBrands: pendingComponentBrands,
        refetchProviderBrands: refetchPendingProviderBrands,
        isLoadingProviderBrands: isLoadingPendingProviderBrands,
    } = useGetProviderBrands({
        providerId,
        status: 'PENDING',
    })
    const {
        componentBrands: approvedComponentBrands,
        refetchProviderBrands: refetchApprovedProviderBrands,
        isLoadingProviderBrands: isLoadingApprovedProviderBrands,
    } = useGetProviderBrands({
        providerId,
        status: 'APPROVED',
    })

    const handleRefetch = () => {
        refetchPendingProviderBrands()
        refetchApprovedProviderBrands()
    }

    return (
        <>
            <div className={styles.Container}>
                {isLoadingPendingProviderBrands &&
                !pendingComponentBrands.length ? (
                    <ComponentBrandsSkeletalCards total={2} />
                ) : (
                    !!pendingComponentBrands.length && (
                        <section className={styles.CardSection}>
                            <h2>Up for review</h2>
                            <div className={styles.CardGallery}>
                                {pendingComponentBrands?.map(
                                    (pendingComponentBrand, idx) => (
                                        <ReviewBrandsCard
                                            key={idx}
                                            providerId={providerId}
                                            componentType={
                                                pendingComponentBrand.componentType
                                            }
                                            componentBrands={
                                                pendingComponentBrand.componentBrands
                                            }
                                            handleRefetch={handleRefetch}
                                            handleConfirmBrandingDecision={
                                                handleConfirmBrandingDecision
                                            }
                                        />
                                    ),
                                )}
                            </div>
                        </section>
                    )
                )}

                {!!approvedComponentBrands.length && (
                    <section className={styles.CardSection}>
                        <h2>Approved brands</h2>
                        <div className={styles.CardGallery}>
                            {approvedComponentBrands?.map(
                                (approvedComponentBrand, idx) => (
                                    <ApprovedBrandsCard
                                        key={idx}
                                        componentType={
                                            approvedComponentBrand.componentType
                                        }
                                        componentBrands={
                                            approvedComponentBrand.componentBrands
                                        }
                                    />
                                ),
                            )}
                        </div>
                    </section>
                )}
                {!isLoadingPendingProviderBrands &&
                    !isLoadingApprovedProviderBrands &&
                    !pendingComponentBrands.length &&
                    !approvedComponentBrands.length && (
                        <EmptyState
                            title="Nothing’s here"
                            subTitle={
                                'This provider has not submitted any component brands'
                            }
                        />
                    )}
            </div>
        </>
    )
}

ComponentBrands.propTypes = {
    providerId: PropTypes.string,
    handleConfirmBrandingDecision: PropTypes.func,
}

export default ComponentBrands
