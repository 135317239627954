import PropTypes from 'prop-types'
import { useMutation } from 'react-query'
import { helpQuestionApi } from 'src/api/consumer/helpform'
import { ReactComponent as DeleteIcon } from 'src/assets/images/deletemodal-icon.svg'
import ButtonComponent from 'src/components/Button'
import { errorHandler } from 'src/utils/errorHandler'
import { determineVerificationHelpValues } from '../../utils'

const VerificationFailedContent = ({
    payload,
    onCancel,
    setVerificationInProgress,
    setToastMessage = () => {},
    setShowToast = () => {},
    type,
}) => {
    const { name } = determineVerificationHelpValues(type)
    // verification fails, ask admin for help
    const handleBVNHelp = useMutation({
        mutationFn: () => helpQuestionApi(payload),
        onSuccess: () => {
            onCancel()
            setVerificationInProgress(true)
        },
        onError: err => {
            setShowToast(true)
            setToastMessage(errorHandler(err))
        },
    })

    return (
        <section className="SSEBVNFailedWrapper">
            <DeleteIcon />
            <h4>We are not able to verify your {name} at this time</h4>
            <p>
                We are currently experiencing issues verifying {name} at this
                time, you can have us help you with it or try again later.
            </p>
            <div>
                <p>Having issues verifying your {name}?</p>
                <ButtonComponent
                    type="outline"
                    btnWidth="81px"
                    btnHeight="29px"
                    btnTextColorOutline="#004AAD"
                    btnBgColorOutline="#FFFFFFF"
                    fontSize="12px"
                    btnPadding="4px"
                    border="0.3px solid #004AAD"
                    handleClick={() => handleBVNHelp.mutate()}
                >
                    Let us help
                </ButtonComponent>
            </div>
        </section>
    )
}

VerificationFailedContent.propTypes = {
    payload: PropTypes.object,
    onCancel: PropTypes.func,
    setVerificationInProgress: PropTypes.func,
    setShowToast: PropTypes.func,
    setToastMessage: PropTypes.func,
    type: PropTypes.string,
}

export default VerificationFailedContent
