import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { InputFields, MultipleSelectField } from 'src/components/InputFields'
import { ReactComponent as BlueCloseIcon } from 'src/assets/images/blue-close-icon.svg'
import ButtonComponent from 'src/components/Button'
import { ReactComponent as PlusIcon } from 'src/assets/images/plus-icon.svg'
import { errorHandler } from 'src/utils/errorHandler'
import { v4 as uuidv4 } from 'uuid'

import { InviteUserValidationSchema } from 'src/utils/validationSchema'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { useQuery } from 'react-query'
import { inviteProviderUserApi } from 'src/api/admin/provider'
import { InlineLoader } from 'src/components/Loader'
import Toast from 'src/components/Toast'
import { inviteUsersFromProviderWorkspaceApi } from 'src/api/providers/users'

const InviteAdminUser = ({
    providerId,
    selectedUsers,
    setSelectedUsers,
    inviteErrors,
    setInviteErrors,
    closeInviteModal,
    showInviteModal,
    setSuccessModal,
    setTriggerRefetch = () => {},
    setAction = () => {},
    groups,
    isProviderWorkspace,
}) => {
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [selectedData, setSelectedData] = useState([])

    const { refetch: addUsersRefetch, isLoading: addUsersLoading } = useQuery(
        ['invite-provider-users'],
        () => inviteProviderUserApi(providerId, selectedData),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeInviteModal()
                setSuccessModal(true)
                setTriggerRefetch(true)
                setAction('add-user')
            },
            onError: error => {
                setToastError(true)

                if (
                    error?.response?.data?.errors?.provider_users?.[0]
                        ?.email?.[0]
                ) {
                    setErrorMessage(
                        error?.response?.data?.errors?.provider_users?.[0]
                            ?.email?.[0],
                    )
                } else {
                    setErrorMessage(errorHandler(error?.response?.data))
                }
            },
        },
    )

    const {
        refetch: addUsersProviderWSRefetch,
        isLoading: addUsersProviderWSLoading,
    } = useQuery(
        ['invite-users-from-provider-workspace'],
        () => inviteUsersFromProviderWorkspaceApi(selectedData),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeInviteModal()
                setSuccessModal(true)
            },
            onError: error => {
                setToastError(true)

                if (
                    error?.response?.data?.errors?.provider_users?.[0]
                        ?.email?.[0]
                ) {
                    setErrorMessage(
                        error?.response?.data?.errors?.provider_users?.[0]
                            ?.email?.[0],
                    )
                } else {
                    setErrorMessage(errorHandler(error?.response?.data))
                }
            },
        },
    )

    const handleRemoveUsers = id => {
        const filteredUsers = selectedUsers.filter(user => user.id !== id)
        setSelectedUsers(filteredUsers)
    }

    const handleAddUsers = () => {
        setSelectedUsers([
            ...selectedUsers,
            {
                id: uuidv4(),
                email: '',
                roles: [],
            },
        ])
    }

    const sendInvite = () => {
        InviteUserValidationSchema.validate(
            { users: selectedUsers },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                setInviteErrors({})
                if (isProviderWorkspace) {
                    addUsersProviderWSRefetch()
                } else addUsersRefetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setInviteErrors(errList)
            })
    }

    useEffect(() => {
        setSelectedData(
            selectedUsers?.map(each => ({
                email: each?.email,
                ...(process.env.REACT_APP_FLAG_SHOW_ADMIN_SETTINGS ===
                    'true' && {
                    roles: each?.groups?.map(item => item?.id),
                }),
            })),
        )
    }, [selectedUsers])

    return (
        <SecondaryModal
            type="recommendedEnergy"
            modalWidth="624px"
            modalHeight="100%"
            showModal={showInviteModal}
            closable={true}
            onCancel={() => closeInviteModal()}
            content={
                <div>
                    <div className="AULInviteWrapper">
                        {toastError && (
                            <Toast messageType="error" message={errorMessage} />
                        )}
                        <h2 className="AULInviteTitle">Invite Users</h2>
                        <p className="AULInviteSubTitle">
                            Enter the details of the user(s){' '}
                        </p>
                        <div className="AULInviterUserScrollWrapper">
                            <div className="AULInviteUserWrapper">
                                {selectedUsers.map((user, i) => (
                                    <div key={i}>
                                        <h3 className="AULInviteUserTitle">
                                            User {i + 1}
                                        </h3>
                                        <InputFields
                                            title="Email Address"
                                            name={`users[${i}].email`}
                                            value={user.email}
                                            inputValue={user.email}
                                            handleChange={event => {
                                                user.email = event.target.value
                                                setSelectedUsers([
                                                    ...selectedUsers,
                                                ])
                                            }}
                                            errorMessage={
                                                inviteErrors[
                                                    `users[${i}].email`
                                                ]
                                            }
                                        />
                                        {process.env
                                            .REACT_APP_FLAG_SHOW_ADMIN_SETTINGS ===
                                            'true' && (
                                            <MultipleSelectField
                                                title="Select Groups"
                                                selectWidth="100%"
                                                dropDownWidth="280px"
                                                values={groups}
                                                name={`users[${i}].groups`}
                                                dropdownMatchSelectWidth={false}
                                                value={user?.groups?.map(
                                                    item => item?.value,
                                                )}
                                                inputValue={user?.groups?.map(
                                                    item => item?.value,
                                                )}
                                                handleMultipleSelectChange={value => {
                                                    const values = value?.map(
                                                        item => ({
                                                            value: item,
                                                            label: item,
                                                            id: groups?.filter(
                                                                each =>
                                                                    each.value ===
                                                                    item,
                                                            )?.[0]?.id,
                                                        }),
                                                    )
                                                    user.groups = values
                                                    setSelectedUsers([
                                                        ...selectedUsers,
                                                    ])
                                                }}
                                                errorMessage={
                                                    inviteErrors[
                                                        `users[${i}].groups`
                                                    ]
                                                }
                                            />
                                        )}
                                        {selectedUsers.length > 1 && (
                                            <div
                                                onClick={() =>
                                                    handleRemoveUsers(user.id)
                                                }
                                                className="AULRemoveUserWrapper"
                                            >
                                                <BlueCloseIcon />
                                                <p className="AULRemoveUserText">
                                                    Remove User
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="AULAddUserWrapper">
                                <ButtonComponent
                                    type="secondary"
                                    btnWidth="117px"
                                    btnHeight="40px"
                                    handleClick={handleAddUsers}
                                >
                                    <PlusIcon className="AULPlusIcon" /> Add
                                    User
                                </ButtonComponent>
                            </div>
                        </div>
                        <div
                            className="AULInviteUserBtnWrapper"
                            style={{ marginBottom: 30 }}
                        >
                            <ButtonComponent
                                type="primary"
                                handleClick={() => sendInvite()}
                            >
                                {addUsersLoading ||
                                addUsersProviderWSLoading ? (
                                    <InlineLoader />
                                ) : (
                                    'Send Invite'
                                )}
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            }
        />
    )
}

InviteAdminUser.propTypes = {
    providerId: PropTypes.string,
    selectedUsers: PropTypes.any,
    setSelectedUsers: PropTypes.func,
    inviteErrors: PropTypes.any,
    setInviteErrors: PropTypes.func,
    closeInviteModal: PropTypes.func,
    showInviteModal: PropTypes.bool,
    setSuccessModal: PropTypes.func,
    setToastError: PropTypes.func,
    setErrorMessage: PropTypes.func,
    setTriggerRefetch: PropTypes.func,
    groups: PropTypes.array,
    setAction: PropTypes.func,
    isProviderWorkspace: PropTypes.bool,
}

export default InviteAdminUser
