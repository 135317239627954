import { ReactComponent as WarningIcon } from 'src/assets/images/warning-icon.svg'

const SaveInfo = () => {
    return (
        <section className="SSEBVNFailedWrapper">
            <WarningIcon />
            <h4>Verification in progress, Save your KYC</h4>
            <p>
                You cannot submit your KYC now because your ID has not been
                verified. We will notify you once we verify your ID.
            </p>
        </section>
    )
}

export default SaveInfo
