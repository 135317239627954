import { decodeUserInfo } from './auth'
export const checkLibraryProductAccess = productProviderId => {
    const userProviderId = decodeUserInfo()?.providerId
    const isAdminWorkspaceUser = decodeUserInfo()?.isAdminWorkspaceUser
    if (userProviderId && productProviderId) {
        if (
            productProviderId === userProviderId ||
            isAdminWorkspaceUser === 'true'
        ) {
            return true
        } else {
            return false
        }
    }
}
