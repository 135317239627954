import { useMutation } from 'react-query'
import { persistBrandsApi } from 'src/api/providers/brand-names'
import { errorHandler } from 'src/utils/errorHandler'

const useUpdateBrands = (onSuccess, onError) => {
    const { mutate, isLoading } = useMutation(
        ({ payload }) => persistBrandsApi(payload),
        {
            onSuccess: () => {
                onSuccess?.()
            },
            onError: error => {
                onError?.(errorHandler(error?.response?.data))
            },
        },
    )

    return {
        updateBrandsMutate: mutate,
        updateBrandsLoading: isLoading,
    }
}

export default useUpdateBrands
