import { useState, useEffect } from 'react'

export const useScrollPosition = () => {
    // function extracted from https://stackoverflow.com/questions/16422124/javascript-detect-browser-scroll-to-the-top
    // Author: https://stackoverflow.com/users/1740600/putrakg
    // Modified in code by: https://github.com/temitopetoluwalase
    const [currentPosition, setCurrentPosition] = useState(0)

    useEffect(() => {
        let B = document.body //IE 'quirks'
        let doc = document.documentElement //IE with doctype

        window.onscroll = function () {
            doc = doc.clientHeight ? doc : B
            return setCurrentPosition(doc.scrollTop)
        }
    }, [])

    return { currentPosition: currentPosition }
}
