import './profilesdetails.scss'
import DetailsPage from 'src/components/DetailsPage'
import ProfilesPackagesTable from './components/ProfilePackagesTable'
import EnergyNeeds from 'src/components/EnergyNeeds'
import MoreActionsMenu from './components/MoreActionsMenu'
import SeoComponent from 'src/components/Seo'
import EditPackageDrawer from './components/EditPackageDrawer'
import useAdminProfileDetails from './hooks/useAdminProfileDetails'
import ActionModal from 'src/components/ActionModal'
import { actionModalValues } from './data'
import useGetProfile from './hooks/useGetProfile'
import useGetProfilePackages from './hooks/useGetProfilePackages'
import { format, parseISO } from 'date-fns'
import { toTitleCase } from 'src/utils/formatting'
import { parseHTML } from 'src/utils/parseHTML'
import AddPackageDrawer from '../AddPackage/components/AddPackageDrawer'
import ClusterInformation from './components/ClusterInformation'
import SharePackageModal from './components/SharePackageModal'
import { canUpdateEnergyProfile } from './formatter'

const AdminProfilesDetails = () => {
    const { profile, profileFetching, energyNeeds, setEnergyNeeds } =
        useGetProfile()

    const {
        paginationBlock,
        profilePackages,
        profilePackagesFetching,
        setPage,
    } = useGetProfilePackages()

    const {
        singlePackageEnergyNeeds,
        setSinglePackageEnergyNeeds,
        showEditPackageDrawer,
        goBack,
        toggleEditPackageDrawer,
        showActionModal,
        action,
        storeActionAndOpenModal,
        closeActionModal,
        actionModalHandler,
        handleEditPackage,
        singlePackageEnergyInfo,
        handleSavePackage,
        updateProfileLoading,
        toastError,
        errorMessage,
        handleRemovePackage,
        handleUpdateProfileAppliances,
        updateProfilePackageLoading,
        deleteProfileLoading,
        deleteProfileToastError,
        deleteProfileErrorMessage,
        duplicateProfileIsLoading,
        duplicateProfileToastError,
        duplicateProfileErrorMessage,
        handleUpdateProfile,
        showAddPackageDrawer,
        toggleAddPackageDrawer,
        handleUpdatePackages,
        addPackagesLoading,
        cluster,
        setCluster,
        handleCopy,
        shortenLinkLoading,
        sharePackageLink,
        setSharePackageLink,
        showSharePackageModal,
        setShowSharePackageModal,
        currentTab,
        paymentType,
        setPaymentType,
        paymentTypeError,
        setPaymentTypeError,
    } = useAdminProfileDetails({
        selectedPackages: profile?.packages || [],
        setPage: setPage,
        profile,
    })

    const {
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalShowCancelBtn,
        actionModalActionText,
    } = actionModalValues(action)

    return (
        <>
            <SeoComponent title="SunFi | Admin | Consumer Profile Details" />

            {/* modals */}
            <ActionModal
                actionModalOpen={showActionModal}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                actionType={actionModalActionType}
                noCancelBtn={!actionModalShowCancelBtn}
                actionText={actionModalActionText}
                actionHandler={actionModalHandler}
                closable
                onCancel={() => closeActionModal()}
                closeModal={() => closeActionModal()}
                toastError={
                    toastError ||
                    deleteProfileToastError ||
                    duplicateProfileToastError
                }
                errorMessage={
                    errorMessage ||
                    deleteProfileErrorMessage ||
                    duplicateProfileErrorMessage
                }
                loading={
                    updateProfileLoading ||
                    deleteProfileLoading ||
                    duplicateProfileIsLoading
                }
                noBackLink
                primaryBtnWidth={'100%'}
            />

            {/* Share package modal */}

            <SharePackageModal
                showModal={showSharePackageModal}
                onCancel={() => {
                    setShowSharePackageModal(false)
                    setSharePackageLink('')
                }}
                sharePackageLink={sharePackageLink}
                shortenLinkLoading={shortenLinkLoading}
                profile={profile}
            />

            {/* drawers */}
            <EditPackageDrawer
                visible={showEditPackageDrawer}
                toggleEditPackageDrawer={toggleEditPackageDrawer}
                singlePackageEnergyNeeds={singlePackageEnergyNeeds}
                setSinglePackageEnergyNeeds={setSinglePackageEnergyNeeds}
                singlePackageEnergyInfo={singlePackageEnergyInfo}
                handleSavePackage={handleSavePackage}
                savePackageLoading={updateProfilePackageLoading}
                handleRemovePackage={handleRemovePackage}
                singleCluster={cluster}
                setSingleCluster={setCluster}
                paymentPlanTypes={paymentType}
                setPaymentPlanTypes={setPaymentType}
                paymentPlanTypesError={paymentTypeError}
                setPaymentPlanTypesError={setPaymentTypeError}
            />
            <AddPackageDrawer
                showAddPackageModal={showAddPackageDrawer}
                setShowAddPackageModal={toggleAddPackageDrawer}
                selectedPackages={profile?.packages || []}
                handleAddPackagesClick={handleUpdatePackages}
                addPackagesLoading={addPackagesLoading}
                minCapacity={
                    profile?.meta_data?.energy_capacity?.minimum_capacity?.value
                }
                maxCapacity={
                    profile?.meta_data?.energy_capacity?.maximum_capacity?.value
                }
            />

            {/* page */}
            <DetailsPage
                tabCurrentTab={currentTab}
                isUpdateTabUrl
                backNavTitle={'Back to consumer profiles'}
                backNavOnClick={goBack}
                name={profile?.name ?? 'N/A'}
                noStatusFlag={profile?.promotional_type == null}
                status={profile?.promotional_type}
                statusType={
                    profile?.promotional_type?.toLowerCase() === 'private'
                        ? 'secondary'
                        : ''
                }
                infoSubhead={'CONSUMER PROFILE DESCRIPTION'}
                infoParagraph={parseHTML(profile?.description)}
                tabPaneOneTab={'Consumer Profile packages'}
                tabPaneOneContent={
                    <ProfilesPackagesTable
                        packages={profilePackages}
                        fetchingPackages={profilePackagesFetching}
                        handleEditPackage={handleEditPackage}
                        paginationBlock={paginationBlock}
                    />
                }
                tabPaneTwo
                tabPaneTwoTab={'Consumer profile energy needs'}
                tabPaneTwoContent={
                    <EnergyNeeds
                        section={'CONSUMER PROFILE'}
                        energyNeeds={energyNeeds}
                        setEnergyNeeds={setEnergyNeeds}
                        modalTitle={'Edit consumer profile energy needs'}
                        modalDescription={
                            'Edit the energy needs that fit this consumer profile'
                        }
                        modalSummaryTitle={'See energy needs summary'}
                        modalBtnText={'Save Changes'}
                        onAfterUpdate={handleUpdateProfileAppliances}
                        modalBtnLoading={
                            updateProfileLoading &&
                            action === 'update-profile-appliances'
                        }
                        closeModalAfterUpdate={false}
                        modalToastError={toastError}
                        modalErrorMessage={errorMessage}
                        cardButtonDisabeld={!canUpdateEnergyProfile}
                    />
                }
                tabPaneThree={profile?.is_promotional}
                tabPaneThreeTab={'Consumer profile cluster information'}
                tabPaneThreeContent={
                    <ClusterInformation
                        data={profile}
                        buttonDisabled={!canUpdateEnergyProfile}
                    />
                }
                rightSectionHeading={'Consumer Profile Details'}
                rowCount={4}
                gridItemOneHeading={'DATE OF CREATION'}
                gridItemOneContent={
                    profile?.created_at !== undefined &&
                    format(parseISO(profile?.created_at), 'dd MMM yyyy, HH:mm')
                }
                gridItemTwoHeading={'DATE PUBLISHED'}
                gridItemTwoContent={
                    profile?.date_published !== undefined
                        ? format(
                              parseISO(profile?.date_published),
                              'dd MMM yyyy, HH:mm',
                          )
                        : '-'
                }
                gridItemThreeHeading={'TOTAL PACKAGES AVAILABLE'}
                gridItemThreeContent={profile?.package_added}
                gridItemFourHeading={'CONSUMER PROFILE STATUS'}
                gridItemFourContent={
                    profile?.is_published ? 'Published' : 'Unpublished'
                }
                gridItemFiveHeading={'CONSUMER PROFILE TYPE'}
                gridItemFiveContent={toTitleCase(profile?.customer_type) ?? '-'}
                gridItemSixHeading={'PROFILE CODE'}
                gridItemSixContent={profile?.profile_code ?? '-'}
                gridItemSevenHeading={'MIN. SYSTEM SIZE'}
                gridItemSevenContent={
                    profile?.meta_data?.energy_capacity?.minimum_capacity
                        ?.value +
                    ' ' +
                    profile?.meta_data?.energy_capacity?.minimum_capacity
                        ?.unit_of_measurement
                }
                gridItemEightHeading={'MAX. SYSTEM SIZE'}
                gridItemEightContent={
                    profile?.meta_data?.energy_capacity?.maximum_capacity
                        ?.value +
                    ' ' +
                    profile?.meta_data?.energy_capacity?.maximum_capacity
                        ?.unit_of_measurement
                }
                actionButton
                buttonOneText={'Add Packages'}
                buttonOneDisabled={!canUpdateEnergyProfile}
                buttonOneHandleClick={toggleAddPackageDrawer}
                secondButton
                secondButtonType={'moreActions'}
                dropdownMenu={
                    <MoreActionsMenu
                        storeActionAndOpenModal={storeActionAndOpenModal}
                        handleUpdateProfile={handleUpdateProfile}
                        isPublished={profile?.is_published}
                    />
                }
                isLoading={profileFetching}
                userType={profile?.is_published && 'admin_consumer_profile'}
                shareProfileClick={() => {
                    handleCopy()
                }}
            />
        </>
    )
}

export default AdminProfilesDetails
