import { useState, useEffect, useRef } from 'react'
const useVerify = (handleResendOtp, otp, setOtp) => {
    const [activeOtpIndex, setActiveOtpIndex] = useState(0)
    const inputsRef = useRef(null)
    const intervalRef = useRef(null)
    const timerRef = useRef({ time: 30 })

    const startCountdown = () => {
        timerRef.current = { ...timerRef.current, time: 30 }
        timerRef.current.setTime?.(timerRef.current.time)

        intervalRef.current = setInterval(() => {
            if (timerRef.current?.time <= 0) {
                clearInterval(intervalRef.current)
                timerRef.current.time = 0
            } else {
                timerRef.current.time -= 1
            }

            timerRef.current.setTime?.(timerRef.current.time)
        }, 1000)
    }
    const requestCode = () => {
        handleResendOtp()
        if (timerRef.current?.time === 0) {
            startCountdown()
        }
    }
    const handleInputChange = (value, index) => {
        const newOtp = [...otp]
        newOtp[index] = value.substring(value.length - 1)

        if (!value) setActiveOtpIndex(index - 1)
        else setActiveOtpIndex(Math.min(index + 1, 5))

        setOtp(newOtp)
    }

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') setActiveOtpIndex(index - 1)
    }

    const handleInputPaste = (e, index) => {
        e.preventDefault()
        const pastedData = e.clipboardData
            .getData('text/plain')
            .trim()
            .replace(/\D/g, '')
            .slice(0, 6)

        const newOtp = [...otp]
        for (let i = 0; i < pastedData.length; i++) {
            if (index + i >= 6) break
            newOtp[index + i] = pastedData[i]
        }
        setOtp(newOtp)
        if (pastedData.length === 6) {
            setActiveOtpIndex(pastedData.length - 1)
        } else {
            const newIndex = Math.min(pastedData.length + index, 5)
            setActiveOtpIndex(newIndex)
        }
    }

    useEffect(() => {
        inputsRef.current?.focus()
    }, [activeOtpIndex])

    useEffect(() => {
        if (intervalRef?.current) {
            clearInterval(intervalRef.current)
        }

        startCountdown()
    }, [])

    return {
        requestCode,
        handleInputChange,
        handleKeyDown,
        handleInputPaste,
        activeOtpIndex,
        inputsRef,
        timerRef,
    }
}

export default useVerify
