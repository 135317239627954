import { useEffect, useState } from 'react'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import useUpdateBrands from './useUpdateBrands'
import { useQueryClient } from 'react-query'
import useDeleteApprovedBrands from './useDeleteApprovedBrands'

const useComponentBrands = (
    componentBrands,
    componentTypeMap,
    approvedBrandsIdMap,
) => {
    const [action, setAction] = useState('') // add-brands || edit-brands
    const [componentType, setComponentType] = useState('')
    const [inputs, setInputs] = useState(componentBrands)
    const [showAddOrEditBrandModal, setShowAddOrEditBrandModal] =
        useState(false)
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [toast, setToast] = useState(toastData)
    const queryClient = useQueryClient()

    const updateBrandsSuccess = () => {
        setShowAddOrEditBrandModal(false)
        setActionModalOpen(true)
        queryClient.invalidateQueries('component-brands')
        window.scrollTo(0, 0)
    }

    const deleteBrandsSuccess = () => {
        setShowAddOrEditBrandModal(false)
        setActionModalOpen(true)
        queryClient.invalidateQueries('component-brands')
    }

    const onAPIError = error => {
        setToast({
            showToast: true,
            toastMessage: error || 'An error occurred, please try again later.',
            messageType: 'error-secondary',
        })
        setTimeout(() => {
            setToast(toastData)
        }, 2000)
    }

    const { errorAlert } = useCustomToast()
    const { updateBrandsMutate, updateBrandsLoading } = useUpdateBrands(
        updateBrandsSuccess,
        onAPIError,
    )
    const { deleteApprovedBrandsMutate, deleteApprovedBrandsLoading } =
        useDeleteApprovedBrands(deleteBrandsSuccess, onAPIError)

    const handleChangeAction = action => {
        setAction(action)
    }

    const handleAddBrand = category => {
        handleChangeAction('add-brands')
        setComponentType(category)
        setShowAddOrEditBrandModal(true)
    }

    const handleEditBrand = category => {
        handleChangeAction('edit-brands')
        setComponentType(category)
        setShowAddOrEditBrandModal(true)
    }

    const handleGoBack = () => {
        setShowAddOrEditBrandModal(false)
    }

    const handleSave = names => {
        const payload = [
            {
                component_type: componentTypeMap[componentType],
                component_brands: names?.map(name => ({
                    name: name,
                })),
            },
        ]
        updateBrandsMutate({ payload })
    }

    const pageError = error => {
        errorAlert(
            errorHandler(error || 'An error occurred, please try again later.'),
        )
    }

    const actionModalHandler = () => {
        setActionModalOpen(false)
    }

    const handlePersistEdit = brands => {
        const brandIds = []
        if (approvedBrandsIdMap[componentType]) {
            brands.forEach(brandName => {
                const brandId = approvedBrandsIdMap[componentType][brandName]
                if (brandId) {
                    brandIds.push(brandId)
                }
            })
        }
        const payload = {
            brand_ids: brandIds,
        }

        deleteApprovedBrandsMutate({ payload })
    }

    useEffect(() => {
        if (componentBrands) {
            setInputs(componentBrands)
        }
    }, [componentBrands])

    return {
        action,
        componentType,
        handleAddBrand,
        handleEditBrand,
        inputs,
        showAddOrEditBrandModal,
        handleGoBack,
        handleSave,
        pageError,
        updateBrandsLoading,
        deleteApprovedBrandsLoading,
        actionModalOpen,
        toast,
        actionModalHandler,
        handlePersistEdit,
    }
}

export default useComponentBrands
