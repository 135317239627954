const repaymentSetupMapping = {
    'BANK_TRANSFER:STANDING_ORDER': 'Bank - Standing Order',
    'PAYSTACK:PLAN_SUBSCRIPTION': 'Paystack - Plan/Subscription',
    'BANK_TRANSFER:ADMIN_MANUAL_ENTRY': 'Bank Transfer',
    'PAYSTACK:AUTO_CARD': 'Auto Card Debit',
    'PAYSTACK:AUTO_DIRECT_DEBIT': 'Paystack Direct Debit',
    'REMITA:AUTO_DIRECT_DEBIT': 'Remita Direct Debit',
    'RECOVA:AUTO_DIRECT_DEBIT': 'Recova Direct Debit',
}

export default repaymentSetupMapping
