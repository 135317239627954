import axios from '../../config/axios'

export const resetPasswordInitiateApi = async (type, inputs) => {
  let url = (type === 'phone') ? '/auth/phone-password-reset/initiate' : '/auth/email-password-reset/initiate'
  const request = await axios.post(url, inputs)
  return request
}

export const resetPasswordValidateApi = async (type, inputs) => {
  let url = (type === 'phone') ? '/auth/phone-password-reset/validate' : '/auth/email-password-reset/validate'
  const request = await axios.post(url, inputs)
  return request
}

export const resetPasswordFinalizeApi = async (type, inputs) => {
  let url = (type === 'phone') ? '/auth/phone-password-reset/finalize' : '/auth/email-password-reset/finalize'
  const request = await axios.post(url, inputs)
  return request
}
