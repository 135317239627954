/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom'
import styles from './formbox.module.scss'

const Formbox = ({ children, text, url, padding, width, height }) => {
    const { FormBox, Text } = styles
    return (
        <>
            <div
                className={FormBox}
                style={{
                    padding: padding,
                    width: width,
                    height: height ? height : 'auto',
                }}
            >
                {children}
            </div>
            {!!url && (
                <div className={Text}>
                    <Link to={url} className={Text}>
                        {text}
                    </Link>
                </div>
            )}
        </>
    )
}

export default Formbox
