import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { ConsumerButton } from 'src/components/Button'
import { getClientLocator } from 'src/utils/user-tracking'
import { deviceDetect } from 'react-device-detect'
import styles from './cookienotification.module.scss'
import { getCookie, setCookie } from 'src/utils/cookie'
import { v4 as uuidv4 } from 'uuid'

import { AES } from 'crypto-js'

const CookieNotification = () => {
    const { CookieWrapper, CookieContentWrapper, CookieHeader, CookieContent } =
        styles

    const [ipAddress, setIpAddress] = useState('')
    const [showCookieNotification, setShowCookieNotification] = useState(false)

    const allowCookie = getCookie('allow-cookie')
    const cookieUserInfo = getCookie('userInfo')

    useEffect(() => {
        if (allowCookie === 'true') {
            setShowCookieNotification(false)
            if (!cookieUserInfo) {
                handleAcceptCookie()
            }
        } else {
            setShowCookieNotification(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowCookie, cookieUserInfo])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => ipAddressRefetch(), [])

    //get ipaddress
    const { refetch: ipAddressRefetch } = useQuery(
        'get-ipAddress',
        () => getClientLocator(true),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setIpAddress(data.ipAddress)
            },
        },
    )

    const handleAcceptCookie = () => {
        const browserInfo = deviceDetect()

        const userInfo = {
            visitorToken: uuidv4(),
            ipAddress: ipAddress,
            browserName: browserInfo?.browserName,
            browserVersion: browserInfo?.browserFullVersion,
            osName: browserInfo?.osName,
            osVersion: browserInfo?.osVersion,
        }

        const cipherText = AES.encrypt(
            JSON.stringify(userInfo),
            process.env.REACT_APP_SUNFI_APP_TOKEN,
        ).toString()
        // TODO: We might revisit the 24 hours expiration, maybe set it to lower if we are not getting the best information.
        setCookie('userInfo', cipherText, 1) // Expires in 1 day so we can retrieve another.
        // TODO: We might need to persist this consent to the backend. Study more on the GDPR Cookie regulation.
        setCookie('allow-cookie', 'true', 395) // Expires in 13months as directed by ePrivacy Directive (GDPR)
        setShowCookieNotification(false)
    }

    return (
        <div
            className={CookieWrapper}
            style={{ display: showCookieNotification ? 'flex' : 'none' }}
        >
            <div className={CookieContentWrapper}>
                <h2 className={CookieHeader}>Cookie Notification</h2>
                <p className={CookieContent}>
                    This website uses cookies for optimization and to gather
                    data for interaction analysis. All data shared through
                    cookies is secure and compliant with our{' '}
                    <a href="/privacy-policy">privacy policy</a>.
                </p>
                <section>
                    <ConsumerButton
                        btnWidth="92px"
                        btnHeight="36px"
                        btnBgColor="#004AAD"
                        btnTextColor="#fff"
                        handleClick={handleAcceptCookie}
                    >
                        Accept
                    </ConsumerButton>
                </section>
            </div>
        </div>
    )
}
export default CookieNotification
