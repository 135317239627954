import PropTypes from 'prop-types'
import styles from './chatCTA.module.scss'
const ChatCTA = ({
    margin,
    justifyContent,
    text = '',
    textOneColor,
    textTwoColor,
    width,
    fontFamily,
    textOneFontSize,
}) => {
    const { wrapper } = styles

    const openChatWidget = () => {
        window.$zoho?.salesiq?.floatwindow.visible('show')
    }
    return (
        <div
            className={wrapper}
            style={{
                margin: margin,
                justifyContent: justifyContent,
                width: width,
            }}
        >
            <p
                style={{
                    color: textOneColor,
                    fontFamily: fontFamily,
                    fontSize: textOneFontSize,
                }}
            >
                {text === '' ? 'Have questions?' : text}
            </p>
            <span style={{ color: textTwoColor }} onClick={openChatWidget}>
                Chat with us
            </span>
        </div>
    )
}

ChatCTA.propTypes = {
    margin: PropTypes.string,
    justifyContent: PropTypes.oneOf(['center', 'flex-start', 'flex-end', '']),
    text: PropTypes.string,
    textOneColor: PropTypes.string,
    textTwoColor: PropTypes.string,
    width: PropTypes.string || PropTypes.number,
    fontFamily: PropTypes.string,
    textOneFontSize: PropTypes.string || PropTypes.number,
}

export default ChatCTA
