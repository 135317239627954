import { useEffect, useState } from 'react'
import { encrypt, decrypt } from './cryptography'

const INITIAL_NPS_DISPLAY_VALUES = {
    solarCalculatorNPSDisplayed: false,
    energyAssessmentNPSDisplayed: false,
    kycNPSDisplayed: false,
    paymentPlanCreationNPSDisplayed: false,
    packageCreationNPSDisplayed: false,
}

export const getNPSState = () => {
    const key = 'npsState'
    try {
        const encryptedValue = localStorage.getItem(key)
        if (encryptedValue) {
            const decryptedValue = decrypt(encryptedValue)
            return JSON.parse(decryptedValue)
        }
    } catch (error) {
        return null
    }
    return
}

// for modals controlled by a last display date
export const hasShownNPSModalInLast30Days = date => {
    if (!date || (typeof date !== 'string' && typeof date !== 'number')) {
        return false
    }
    const lastDisplayDate = new Date(date)
    if (isNaN(lastDisplayDate.getTime())) {
        return false
    }
    const currentDate = new Date()
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000
    const timeDifference = currentDate.getTime() - lastDisplayDate.getTime()

    return timeDifference < thirtyDaysInMilliseconds
}

export const npsInitialization = () => {
    const existingNpsState = localStorage.getItem('npsState')

    if (!existingNpsState) {
        const npsState = {
            ...INITIAL_NPS_DISPLAY_VALUES,
        }
        localStorage.setItem('npsState', encrypt(JSON.stringify(npsState)))
    }
}

export const useUpdateNPSValues = key => {
    const [npsState, setNpsState] = useState({})

    const updateNPSAndResetValues = () => {
        const newNpsState = { ...npsState }
        const storesTimeStamps = [
            'paymentPlanCreationNPSDisplayed',
            'packageCreationNPSDisplayed',
        ]
        if (storesTimeStamps.includes(key)) {
            newNpsState[key] = new Date().toISOString()
        } else {
            newNpsState[key] = true
        }
        setNpsState(newNpsState)
        localStorage.setItem('npsState', encrypt(JSON.stringify(newNpsState)))
    }

    useEffect(() => {
        const tryFetchingNPSState = () => {
            const currentState = getNPSState(key)
            if (currentState) {
                setNpsState(currentState)
                return true
            }
            return false
        }

        if (!tryFetchingNPSState()) {
            const intervalId = setInterval(() => {
                tryFetchingNPSState()
            }, 1000)

            // clear the interval after 10 seconds
            const timeoutId = setTimeout(() => {
                clearInterval(intervalId)
            }, 10000)

            return () => {
                clearInterval(intervalId)
                clearTimeout(timeoutId)
            }
        }
    }, [key])

    return { npsState, updateNPSAndResetValues }
}
