export const protectiveDeviceDescription = [
    'Inverter Input Protection',
    'Inverter Output/Overload',
    'Battery Protection',
    'Solar Panel Protection',
    'System Isolation/Byepass',
    'Overall Protection',
]

export const filteredProtectiveDevice = description => {
    if (
        description === 'Inverter Input Protection' ||
        description === 'Inverter Output/Overload'
    ) {
        return [
            'SPD (Surge Protective Device)',
            'MCB Miniature Circuit Breakers',
            'MCCB Molded Case Circuit Breaker',
            'DC Fuse and Holder',
        ]
    } else if (description === 'Battery Protection') {
        return [
            'MCB Miniature Circuit Breakers',
            'MCCB Molded Case Circuit Breaker',
        ]
    } else if (description === 'Solar Panel Protection') {
        return ['Solar DB']
    } else if (description === 'System Isolation/Byepass') {
        return ['Change over switch', 'Knife switch']
    } else if (description === 'Overall Protection') {
        return ['Lightning Arrestor', 'Earthing System', 'Non Exist']
    } else return []
}

export const unitOfMeasurement = ['Amps (A)', 'Volts (V)', 'Watts (W)']
