import './admingroupsandpermissions.scss'
import PropTypes from 'prop-types'
import { Dropdown, Menu } from 'antd'
import { CaretDownFilled, CaretRightFilled } from '@ant-design/icons'
import selected from 'src/assets/images/radio-selected-icon.svg'
import unselected from 'src/assets/images/radio-unselected-icon.svg'
import { useMemo } from 'react'
import CloseIcon from 'src/assets/images/close-circle.svg'

const PermissionsMenu = ({
    allPermissions,
    parentResource,
    fetchChildResources,
    childResource,
    handleSelectAll,
    fetchPermissions,
    handlePermissionClick,
    selectedPermissionIds,
    resetSelection,
}) => {
    return (
        <Menu mode="vertical" style={{ width: 198 }} multiple={true}>
            {allPermissions?.map(parent => (
                <Menu.SubMenu
                    key={parent?.id}
                    title={
                        <div className="AGAPSubMenuItem TopLevel">
                            <p>{parent?.name}</p>
                            <span>
                                {parent?.id === parentResource?.id ? (
                                    '...'
                                ) : parent?.children?.length > 0 ? (
                                    <CaretRightFilled />
                                ) : (
                                    ''
                                )}
                            </span>
                        </div>
                    }
                    popupClassName="AGAPSubMenu"
                    onTitleClick={() => fetchChildResources(parent)}
                    expandIcon={<></>}
                >
                    {parent?.children?.map(child => (
                        <Menu.SubMenu
                            key={child?.id}
                            title={
                                <div className="AGAPSubMenuItem">
                                    <p>{child?.name}</p>
                                    <span>
                                        {child?.id === childResource?.id ? (
                                            '...'
                                        ) : child?.permissions?.length > 0 ? (
                                            <CaretRightFilled />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </div>
                            }
                            popupClassName="AGAPSubMenu"
                            onTitleClick={() => fetchPermissions(child)}
                            expandIcon={<></>}
                        >
                            {child?.permissions && (
                                <>
                                    <div
                                        className="space-between"
                                        style={{ paddingRight: 14 }}
                                    >
                                        <p
                                            className="AGAPSelectAllButton"
                                            onClick={() =>
                                                handleSelectAll(
                                                    parent?.name,
                                                    child?.name,
                                                    child?.permissions,
                                                )
                                            }
                                        >
                                            Select All
                                        </p>
                                        <div
                                            className="AGAPSelectAllButton"
                                            onClick={() =>
                                                resetSelection(child?.name)
                                            }
                                        >
                                            <img
                                                src={CloseIcon}
                                                alt="close-icon"
                                            />
                                            <span className="TreeReselectText">
                                                Reset Selection
                                            </span>
                                        </div>
                                    </div>
                                    {child?.permissions?.map(permission => (
                                        <Menu.Item
                                            key={permission?.id}
                                            popupClassName="AGAPSubMenu"
                                        >
                                            <span
                                                onClick={event =>
                                                    handlePermissionClick(
                                                        parent?.name,
                                                        child?.name,
                                                        permission?.name,
                                                        permission?.id,
                                                        selectedPermissionIds?.includes(
                                                            permission?.id,
                                                        )
                                                            ? 'remove'
                                                            : 'add',
                                                        event,
                                                    )
                                                }
                                                className="AGAPMenuItemSpan"
                                            >
                                                <img
                                                    src={
                                                        selectedPermissionIds?.includes(
                                                            permission?.id,
                                                        )
                                                            ? selected
                                                            : unselected
                                                    }
                                                    alt="checkbox"
                                                />
                                                {permission?.name}
                                            </span>
                                        </Menu.Item>
                                    ))}
                                </>
                            )}
                        </Menu.SubMenu>
                    ))}
                </Menu.SubMenu>
            ))}
        </Menu>
    )
}

PermissionsMenu.propTypes = {
    allPermissions: PropTypes.array,
    parentResource: PropTypes.object,
    fetchChildResources: PropTypes.func,
    childResource: PropTypes.object,
    handleSelectAll: PropTypes.func,
    fetchPermissions: PropTypes.func,
    handlePermissionClick: PropTypes.func,
    selectedPermissionIds: PropTypes.array,
    resetSelection: PropTypes.func,
}

function AdminPermissionsMenu({
    allPermissions,
    fetchChildResources,
    fetchPermissions,
    selectedPermissionIds,
    handlePermissionClick,
    parentResource,
    childResource,
    handleSelectAll,
    resetSelection,
}) {
    const menu = useMemo(() => {
        return (
            <PermissionsMenu
                allPermissions={allPermissions}
                fetchChildResources={fetchChildResources}
                fetchPermissions={fetchPermissions}
                selectedPermissionIds={selectedPermissionIds}
                handlePermissionClick={handlePermissionClick}
                parentResource={parentResource}
                childResource={childResource}
                handleSelectAll={handleSelectAll}
                resetSelection={resetSelection}
            />
        )
    }, [
        allPermissions,
        fetchChildResources,
        fetchPermissions,
        selectedPermissionIds,
        handlePermissionClick,
        parentResource,
        childResource,
        handleSelectAll,
        resetSelection,
    ])

    return (
        <div>
            <Dropdown trigger={['click']} overlay={menu}>
                <div className="AGAPDropdownWrapper">
                    <p>Select Permissions</p>
                    <CaretDownFilled
                        style={{ color: '#233862', fontSize: 12 }}
                    />
                </div>
            </Dropdown>
        </div>
    )
}

AdminPermissionsMenu.propTypes = {
    permissions: PropTypes.array,
    handlePermissions: PropTypes.func,
    allPermissions: PropTypes.array,
    fetchChildResources: PropTypes.func,
    fetchPermissions: PropTypes.func,
    selectedPermissionIds: PropTypes.array,
    handlePermissionClick: PropTypes.func,
    parentResource: PropTypes.object,
    childResource: PropTypes.object,
    handleSelectAll: PropTypes.func,
    resetSelection: PropTypes.func,
}

export default AdminPermissionsMenu
