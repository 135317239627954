import axios from '../../../config/axios'
import { useQuery } from 'react-query'

export const getProviders = async (
    page,
    searchText = '',
    startDate = '',
    endDate = '',
    statusType = '',
    exportProviders = '',
    providerType = '',
    location = '',
) => {
    const request = await axios.get(
        `/admin/provider?page=${page}&company_name=${searchText}&start_date=${startDate}&end_date=${endDate}&status=${statusType}&export_providers=${exportProviders}&provider_type=${providerType}&location=${location}`,
    )
    return request
}

export const useGetProviders = (
    page,
    search,
    startDate,
    endDate,
    statusType,
    exportProviders,
    providerType,
    location,
    options = {},
) => {
    return useQuery({
        queryFn: () =>
            getProviders(
                page,
                search,
                startDate,
                endDate,
                statusType,
                exportProviders,
                providerType,
                location,
            ),
        queryKey: [
            'all-providers-lists',
            page,
            search,
            startDate,
            endDate,
            statusType,
            exportProviders,
            providerType,
            location,
        ],
        ...options,
    })
}
