/* eslint-disable react/prop-types */
import { useState } from 'react'
import './providerinstallerinfo.scss'
import { formatInstallerInfo } from './data'
import SecondaryModal from '../../../../../components/Modal/SecondaryModal'

const ProviderInstallerInfo = ({ data }) => {
    const [viewPicture, setViewPicture] = useState(false)
    const [viewIdentificationDetails, setViewIdentificationDetails] =
        useState(false)
    return (
        <>
            <SecondaryModal
                showModal={viewIdentificationDetails}
                modalWidth="500px"
                modalHeight="450px"
                onCancel={() => setViewIdentificationDetails(false)}
                content={
                    <>
                        <div>
                            <p className="APIITIImageTextTitle">
                                Identification Details
                            </p>
                        </div>
                        <div className="AdminProviderInstallerImg">
                            <img
                                src={`${data?.identification_details?.file_url}`}
                                className="APIITIImageStyle"
                                alt="picture of the identification of the installer"
                            />
                        </div>
                    </>
                }
            />
            <SecondaryModal
                showModal={viewPicture}
                modalWidth="500px"
                modalHeight="450px"
                onCancel={() => setViewPicture(false)}
                content={
                    <>
                        <div>
                            <p className="APIITIImageTextTitle">Avatar</p>
                        </div>
                        <div className="AdminProviderInstallerImg">
                            <img
                                src={`${data?.user?.avatar_url}`}
                                alt="provider-avatar"
                                className="APIITIImageStyle"
                            />
                        </div>
                    </>
                }
            />
            <div className="AdminProviderInstallerInfoTable">
                {formatInstallerInfo(
                    data,
                    setViewIdentificationDetails,
                    setViewPicture,
                ).map((item, i) => (
                    <div
                        key={i}
                        className="AdminProviderInstallerInfoTableItem"
                    >
                        <h4>{item.label}</h4>
                        <p>{item.value}</p>
                    </div>
                ))}
            </div>
        </>
    )
}

export default ProviderInstallerInfo
