/* eslint-disable no-undef */

// gtm: Google Tags Manager
// https://www.npmjs.com/package//react-gtm-module
const gtmUtils = function () {
    const environments = ['production', 'staging', 'sandbox']
    if (environments.includes(process.env.REACT_APP_NODE_ENV)) {
        const TagManager = require('react-gtm-module')
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TAGS_MANAGER_ID,
            auth: process.env.REACT_APP_GOOGLE_TAGS_MANAGER_AUTH,
            preview: process.env.REACT_APP_GOOGLE_TAGS_MANAGER_PREVIEW,
        }

        TagManager.initialize(tagManagerArgs)
    }
}
export default gtmUtils
