import { useEffect, useState } from 'react'

const useAddOrEditBrand = (
    brandNameArr,
    handleGoBack,
    handleSave,
    approvedComponentBrands,
    pendingComponentBrands,
    module,
    actionType,
    handlePersistEdit,
) => {
    const [localBrandName, setLocalBrandName] = useState('')
    const [localBrandNameArr, setLocalBrandNameArr] = useState([])
    const [localBrandNamesToRemove, setLocalBrandNamesToRemove] = useState([])
    const [error, setError] = useState('')

    const persistNewBrands =
        module === 'profile-component-brands-page' && actionType === 'Add'
    const editingPersistedBrands =
        module === 'profile-component-brands-page' && actionType === 'Edit'

    const handleBrandInputChange = e => {
        const { value } = e.target
        setLocalBrandName(value)
        setError('')
    }

    const handleBrandInputEnter = e => {
        const { value } = e.target
        const lowerCaseTrimmedValue = value.trim().toLowerCase()

        if (value.trim() === '') {
            return
        }

        if (
            approvedComponentBrands
                .map(brand => brand.toLowerCase())
                .includes(lowerCaseTrimmedValue)
        ) {
            setError('Brand already exists')
            return
        }

        if (
            pendingComponentBrands
                .map(brand => brand.toLowerCase())
                .includes(lowerCaseTrimmedValue)
        ) {
            setError('Brand is in review')
            return
        }

        if (!localBrandNameArr.includes(value)) {
            setLocalBrandNameArr(prev => [...prev, value.trim()])
            setLocalBrandName('')
            setError('')
        } else {
            setLocalBrandName('')
            setError('')
            return
        }
    }

    const removeBrandNameFromLocal = value => {
        setLocalBrandNameArr(prev => prev.filter(item => item !== value))

        if (editingPersistedBrands) {
            setLocalBrandNamesToRemove(prev => [...prev, value])
        }
    }

    const handleGoBackClick = () => {
        setLocalBrandName('')
        setError('')
        if (editingPersistedBrands) {
            setLocalBrandNamesToRemove([])
            setLocalBrandNameArr(brandNameArr)
        }
        handleGoBack()
    }

    const handleSaveClick = () => {
        if (editingPersistedBrands) {
            handlePersistEdit?.(localBrandNamesToRemove)
        } else {
            if (localBrandName?.length) {
                setError('Press Enter/Return to add your brand')
                return
            }
            if (!localBrandNameArr?.length) {
                setError('Add at least one brand')
                return
            }

            // if passed
            handleSave(localBrandNameArr)
            setLocalBrandNameArr([])
        }
    }

    useEffect(() => {
        if (persistNewBrands) {
            setLocalBrandNameArr([])
        } else {
            setLocalBrandNameArr(brandNameArr)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionType])

    return {
        localBrandName,
        handleBrandInputChange,
        handleBrandInputEnter,
        localBrandNameArr,
        removeBrandNameFromLocal,
        handleGoBackClick,
        handleSaveClick,
        error,
    }
}

export default useAddOrEditBrand
