import PropTypes from 'prop-types'
import { useState } from 'react'
import EnergyNeedsModal from './components/Modal'
import EnergyNeedsCard from './components/Card'
import PullEnergyNeedsModal from './components/PullEnergyNeedsModal'

const EnergyNeeds = ({
    energyNeeds,
    setEnergyNeeds,
    section,
    modalTitle,
    modalDescription,
    modalSummaryTitle,
    modalBtnText,
    actionText,
    page,
    cardBorderWithNoShadow,
    onAfterUpdate,
    modalBtnLoading,
    closeModalAfterUpdate,
    modalToastError,
    modalErrorMessage,
    canPullEnergyNeeds,
    profileName,
    cardButtonDisabeld,
}) => {
    const [showModal, setShowModal] = useState(false)
    const [showPullEnergyNeedsModal, setShowPullEnergyNeedsModal] =
        useState(false)

    const togglePullEnergyNeedsModal = () => {
        setShowPullEnergyNeedsModal(prev => !prev)
    }

    const wattsPerAppliance = energyNeeds?.map(
        item => item.power_rating * item.quantity,
    )
    const totalWattsAdded = wattsPerAppliance?.reduce((a, b) => a + b, 0)
    const dayUsage = energyNeeds?.map(
        item => item.power_rating * item.backup_hours * item.quantity,
    )
    const totalDayUsage = dayUsage?.reduce((a, b) => a + b, 0)
    const nightUsage = energyNeeds?.map(
        item => item.power_rating * item.backup_hours_night * item.quantity,
    )
    const totalNightUsage = nightUsage?.reduce((a, b) => a + b, 0)
    const averageDailyUsage = totalDayUsage + totalNightUsage

    const openModal = () => {
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    const updateEnergyNeeds = appliances => {
        setEnergyNeeds(appliances)
        if (onAfterUpdate) {
            onAfterUpdate(appliances)
        }
    }
    return (
        <>
            <PullEnergyNeedsModal
                showModal={showPullEnergyNeedsModal}
                onClose={togglePullEnergyNeedsModal}
                handlePullEnergyNeeds={updateEnergyNeeds}
                profileName={profileName}
            />
            <EnergyNeedsModal
                showModal={showModal}
                energyNeeds={energyNeeds}
                closeModal={closeModal}
                closeModalAfterUpdate={closeModalAfterUpdate}
                updateEnergyNeeds={updateEnergyNeeds}
                modalTitle={modalTitle}
                modalDescription={modalDescription}
                modalSummaryTitle={modalSummaryTitle}
                modalBtnText={modalBtnText}
                page={page}
                modalBtnLoading={modalBtnLoading}
                modalToastError={modalToastError}
                modalErrorMessage={modalErrorMessage}
            />
            <EnergyNeedsCard
                energyNeeds={energyNeeds}
                totalWattsAdded={totalWattsAdded}
                totalDayUsage={totalDayUsage}
                totalNightUsage={totalNightUsage}
                averageDailyUsage={averageDailyUsage}
                section={section}
                actionText={actionText}
                openModal={openModal}
                cardBorderWithNoShadow={cardBorderWithNoShadow}
                canPullEnergyNeeds={canPullEnergyNeeds}
                handlePullEnergyNeedsClick={togglePullEnergyNeedsModal}
                cardButtonDisabeld={cardButtonDisabeld}
            />
        </>
    )
}

EnergyNeeds.propTypes = {
    section: PropTypes.string,
    energyNeeds: PropTypes.array,
    setEnergyNeeds: PropTypes.func,
    modalTitle: PropTypes.string,
    modalDescription: PropTypes.string,
    modalSummaryTitle: PropTypes.string,
    modalBtnText: PropTypes.string,
    actionText: PropTypes.string,
    page: PropTypes.string,
    cardBorderWithNoShadow: PropTypes.bool,
    onAfterUpdate: PropTypes.func,
    modalBtnLoading: PropTypes.bool,
    closeModalAfterUpdate: PropTypes.bool,
    modalToastError: PropTypes.bool,
    modalErrorMessage: PropTypes.string,
    canPullEnergyNeeds: PropTypes.bool,
    profileName: PropTypes.string,
    cardButtonDisabeld: PropTypes.bool,
}

export default EnergyNeeds
