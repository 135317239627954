/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { InfoCircleFilled } from '@ant-design/icons'
import { Alert } from 'antd'
import './toast.scss'
import { ReactComponent as Check } from 'src/assets/images/check-green.svg'
import { ReactComponent as ErrorIcon } from 'src/assets/images/toast-error.svg'

// MessageType = 'success' | 'error'
const Toast = ({
    message,
    messageType,
    setToast,
    width,
    position,
    minHeight,
    top,
    left,
    zIndex,
    timeout = 4000,
    closable,
    autoHide = true,
}) => {
    const [toastMessage, setToastMessage] = useState('')
    const [classState, setClassState] = useState('toast-fade-none')
    let fadeTimeOut = null
    let classTimeOut = null

    const triggerFadeTimeout = () => {
        fadeTimeOut = setTimeout(() => {
            setClassState('toast-fade-out')
            triggerClassTimeout('')
            clearTimeout(fadeTimeOut)
        }, timeout)
    }

    const triggerClassTimeout = () => {
        classTimeOut = setTimeout(() => {
            setToastMessage('')
            setToast && setToast('')
            setClassState('')
            clearTimeout(classTimeOut)
        }, 1000)
    }

    const handleAlertClose = () => {
        setToastMessage('')
        setToast && setToast('')
        setClassState('')

        if (fadeTimeOut) {
            clearTimeout(fadeTimeOut)
        }

        if (classTimeOut) {
            clearTimeout(classTimeOut)
        }
    }

    useEffect(() => {
        if (message === '') {
            setClassState('toast-fade-none')
        }
        if (message !== '') {
            setToastMessage(message)
            setClassState('')

            if (autoHide) {
                triggerFadeTimeout()
            }
        }

        // eslint-disable-next-line
    }, [message])

    return toastMessage !== '' ? (
        <div
            className={`${messageType}-wrapper wrapper ${classState}`}
            style={{
                width: width || '100%',
                position: position || 'relative',
                top: top && top,
                left: left && left,
                zIndex: zIndex || '',
                minHeight: minHeight || '',
            }}
        >
            <Alert
                message={message}
                type={
                    messageType === 'success-secondary'
                        ? 'success'
                        : messageType === 'error-secondary'
                        ? 'error'
                        : messageType
                }
                showIcon
                closable={closable}
                onClose={handleAlertClose}
                icon={
                    messageType === 'error' ? (
                        <InfoCircleFilled />
                    ) : messageType === 'success-secondary' ? (
                        <Check role="presentation" />
                    ) : messageType === 'error-secondary' ? (
                        <ErrorIcon />
                    ) : null
                }
            />
        </div>
    ) : (
        ''
    )
}

export default Toast
