import { useMutation } from 'react-query'
import { useState } from 'react'
import {
    runCreditDecision,
    runCreditDecisionWithBody,
} from 'src/api/shoppingExperience/creditDecision'
import { errorHandler } from 'src/utils/errorHandler'

const useRunCreditDecision = ({
    estimationId,
    onSuccess,
    selectedCreditDecision = {},
}) => {
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [runCreditDecisionToast, setRunCreditDecisionToast] =
        useState(toastData)

    const simulateCreditDecision =
        process.env.REACT_APP_SHOW_SIMULATED_CREDIT_DECISION_ENGINE === 'true'

    const resetToast = () => {
        setTimeout(() => {
            setRunCreditDecisionToast(toastData)
        }, 2000)
    }

    const { mutate, isLoading } = useMutation({
        mutationKey: ['run-credit-decision'],
        mutationFn: () =>
            simulateCreditDecision
                ? runCreditDecisionWithBody({
                      estimation_id: estimationId,
                      type: selectedCreditDecision,
                  })
                : runCreditDecision(estimationId),
        onSuccess: () => {
            onSuccess?.()
        },
        onError: error => {
            setRunCreditDecisionToast({
                showToast: true,
                toastMessage: errorHandler(
                    error?.response?.data?.message ||
                        'An error occurred, please try again later.',
                ),
                messageType: 'error-secondary',
            })
            resetToast()
        },
    })

    return {
        runCreditDecisionMutate: mutate,
        runCreditDecisionLoading: isLoading,
        runCreditDecisionToast,
    }
}

export default useRunCreditDecision
