import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import './create-estimations.scss'
import LeftArrow from 'src/assets/images/left-blue-arrow.svg'
import { eventTrackers } from 'src/utils/eventTrackers.js'
import { appTracking } from 'src/utils/appTracker.js'
import SeoComponent from 'src/components/Seo'
import Button from 'src/components/Button'
import CustomersListings from 'src/pages/Estimations/CustomersListings'

import userImg from 'src/assets/images/user2.svg'
import userWhiteImg from 'src/assets/images/user-white.svg'
import BusinessImg from 'src/assets/images/suitcase.svg'
import BusinessWhiteImg from 'src/assets/images/suitcase-white.svg'

const CreateEstimations = () => {
    const history = useHistory()

    const pageTitle = 'SunFi - Create A Payment Plan | Payment Plans'
    const pageUrlName = window.location.pathname
    const pageTracker = 'CREATE_PAYMENT_PLAN_TRACKER'

    let customerType = history?.location?.state?.customerType
    const [newCustomerType, setNewCustomerType] = useState('')

    const createPaymentPlan = () => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['createPaymentPlan'].action,
            eventTrackers['createPaymentPlan'].label,
            eventTrackers['createPaymentPlan'].category,
        )
        {
            history.push({
                pathname: '/app/payment-plans/create/one',
                state: { newCustomerType },
            })
        }
    }

    return (
        <>
            <SeoComponent
                title="SunFi - Create Payment Plan"
                tracker="CreatePaymentplan"
            />
            <div className="Estimation_create-Container">
                <button
                    className="PrevButton"
                    onClick={() => history.push('/app/payment-plans')}
                >
                    <img src={LeftArrow} alt="left-arrow" /> Back to Payment
                    Plans
                </button>
                <div style={{ display: 'flex' }}>
                    <h1 className="CreateEstimationH1">
                        Create a Payment Plan
                    </h1>
                </div>
                {customerType === 'new' ? (
                    <>
                        <p className="NewCustomerTypeSubheaderStyle">
                            {customerType === 'new' &&
                                'Select the customer type'}
                        </p>
                        <section className="NewCustomerTypeSectionWrapper">
                            <div
                                className="NewCustomerTypeCardStyle"
                                onClick={() => setNewCustomerType('individual')}
                                style={{
                                    background:
                                        newCustomerType === 'individual'
                                            ? '#E2EEFF'
                                            : '#fff',
                                    border:
                                        newCustomerType === 'individual'
                                            ? '0.5px solid #004AAD'
                                            : '0.5px solid #E5E5E5',
                                }}
                            >
                                <div
                                    className="UserImgStyle"
                                    style={{
                                        background:
                                            newCustomerType === 'individual' &&
                                            '#004AAD',
                                    }}
                                >
                                    <img
                                        src={
                                            newCustomerType === 'individual'
                                                ? userWhiteImg
                                                : userImg
                                        }
                                        alt="new-user-icon"
                                    />
                                </div>
                                <h3 className="NewCustomerTypeCardHeader">
                                    Residential
                                </h3>
                                <p className="NewCustomerTypeCardText">
                                    Create a one-time SunFi payment plan for
                                    Residential use.
                                </p>
                            </div>

                            <div
                                className="NewCustomerTypeCardStyle"
                                onClick={() => setNewCustomerType('business')}
                                style={{
                                    background:
                                        newCustomerType === 'business'
                                            ? '#E2EEFF'
                                            : '#fff',
                                    border:
                                        newCustomerType === 'business'
                                            ? '0.5px solid #004AAD'
                                            : '0.5px solid #E5E5E5',
                                }}
                            >
                                <div
                                    className="UserImgStyle"
                                    style={{
                                        background:
                                            newCustomerType === 'business' &&
                                            '#004AAD',
                                    }}
                                >
                                    <img
                                        src={
                                            newCustomerType === 'business'
                                                ? BusinessWhiteImg
                                                : BusinessImg
                                        }
                                        alt="new-user-icon"
                                    />
                                </div>
                                <h3 className="NewCustomerTypeCardHeader">
                                    Business
                                </h3>
                                <p className="NewCustomerTypeCardText">
                                    Create a one-time SunFi payment plan for a
                                    Business.
                                </p>
                            </div>
                        </section>
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnWidth="132px"
                            btnHeight="50px"
                            handleClick={createPaymentPlan}
                        >
                            Continue
                        </Button>
                    </>
                ) : customerType === 'existing' ? (
                    <div className="CreateEstimationContainer">
                        <div className="StepsContainer">
                            <div className="StepsChildContainer">
                                <button
                                    className="StepsButton"
                                    style={{
                                        background: '#011A3C',
                                        color: '#fff',
                                    }}
                                >
                                    1
                                </button>
                                <p className="StepsText">
                                    Customer Information
                                </p>
                            </div>
                            <div className="StepsLine"></div>
                            <div className="StepsChildContainer">
                                <button className="StepsButton">2</button>
                                <p className="StepsText"> Payment Terms</p>
                            </div>
                            <div className="StepsLine"></div>
                            <div className="StepsChildContainer">
                                <button className="StepsButton">3</button>
                                <p className="StepsText">Select Plans</p>
                            </div>
                        </div>
                        <CustomersListings />
                    </div>
                ) : (
                    <div className="CreateEstimationContainer">
                        <div className="StepsContainer">
                            <div className="StepsChildContainer">
                                <button
                                    className="StepsButton"
                                    style={{
                                        color: '#011a3c',
                                    }}
                                >
                                    1
                                </button>
                                <p className="StepsText">
                                    Customer Information
                                </p>
                            </div>
                            <div className="StepsLine"></div>
                            <div className="StepsChildContainer">
                                <button className="StepsButton">2</button>
                                <p className="StepsText"> Payment Terms</p>
                            </div>
                            <div className="StepsLine"></div>
                            <div className="StepsChildContainer">
                                <button className="StepsButton">3</button>
                                <p className="StepsText">Select Plans</p>
                            </div>
                        </div>
                        <div style={{ marginTop: '141px' }}>
                            <h2 className="CreateEstimationH2">
                                Start a Single Payment Plan
                            </h2>
                            <p className="CreateEstimationSubTitle">
                                Create Payment Plans for your customers. SunFi{' '}
                                <br /> is solving a problem we know better
                            </p>
                            <div style={{ textAlign: 'center' }}>
                                <button
                                    className="CreateEstimationBtnPrimary"
                                    onClick={createPaymentPlan}
                                >
                                    Start Payment Plan
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default CreateEstimations
