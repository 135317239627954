import { useQuery } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { getProfile } from 'src/api/admin/profiles/manage-profile'
import { formatPackageData } from '../../AddPackage/components/AddPackageDrawer/data'

export const useGetProfile = ({
    setProfileData,
    setIsEdit,
    profileId,
    onSuccess,
}) => {
    const { errorAlert } = useCustomToast()

    const {
        refetch: refetchProfile,
        isFetching: isLoadingProfile,
        data,
    } = useQuery({
        queryKey: [profileId],
        queryFn: () => getProfile(profileId),
        retry: false,
        enabled: !!profileId,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        onSuccess: res => {
            const data = res.data
            onSuccess?.(data?.data)

            if (data?.data) {
                const profile = data.data
                if (setProfileData) {
                    setProfileData({
                        id: profile?.id,
                        name: profile?.name,
                        group_id: profile?.group?.id,
                        sort_by: profile?.meta_data?.sort_by,
                        customer_type: profile?.customer_type,
                        appliances: profile?.appliances,
                        description: profile?.description,
                        packages: formatPackageData(profile?.packages),
                        initial_packages: formatPackageData(profile?.packages),
                        image_url: profile?.meta_data?.image_url,
                        credit_scorecard_id:
                            profile?.cluster_origination?.credit_scorecard_id,
                        modules: profile?.cluster_origination?.modules,
                        min_capacity:
                            JSON.stringify(
                                profile?.meta_data?.energy_capacity
                                    ?.minimum_capacity,
                            ) || 0,
                        max_capacity:
                            JSON.stringify(
                                profile?.meta_data?.energy_capacity
                                    ?.maximum_capacity,
                            ) || 0,
                        subscription_cluster_code:
                            profile?.meta_data?.cluster_info?.SUBSCRIPTION?.toString() ||
                            '',
                        lease_to_own_cluster_code:
                            profile?.meta_data?.cluster_info?.LEASE_TO_OWN?.toString() ||
                            '',
                        is_published: profile?.is_published,
                    })
                }
                if (setIsEdit) {
                    setIsEdit(true)
                }
            }
        },
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })

    return {
        refetchProfile,
        isLoadingProfile,
        data,
    }
}
