import Okra from 'okra-js'
import { useGetEstimation } from 'src/api/shoppingExperience/account-overview'

export const useOkra = ({ setCustomerId, onClose, page = '' }) => {
    const { data: estimation } = useGetEstimation(false, null, {
        enabled: page === 'financialInfo' ? true : false,
    })
    const formId = estimation?.data?.data?.form_id || ''
    let success = false
    const widgetOkra = () => {
        Okra.buildWithOptions({
            name: 'SunFi',
            env: process.env.REACT_APP_OKRA_ENV,
            app_id: process.env.REACT_APP_OKRA_APP_ID,
            key: process.env.REACT_APP_OKRA_KEY,
            token: process.env.REACT_APP_OKRA_TOKEN,
            limit: 24,
            meta: {
                formId,
            },
            products: ['auth', 'transactions'],
            onSuccess: data => {
                success = data?.success
                if (setCustomerId) {
                    setCustomerId(data?.customer_id)
                }
            },
            onClose: () => {
                if (onClose && success === true) {
                    onClose()
                    success = false
                }
            },
            onError: () => {
                Okra.onClose()
            },
        })
    }

    return {
        openOkraWidget: widgetOkra,
    }
}
