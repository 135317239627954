/* eslint-disable react/prop-types */
import { Skeleton, Space } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import styles from './skeletalcards.module.scss'

export const SkeletalCards = ({ total = 3 }) => {
    const { Card, SkeletalBox } = styles
    return (
        <div className={SkeletalBox}>
            {[...Array(total)].map(() => (
                <Skeleton key={uuidv4()} className={Card} active />
            ))}
        </div>
    )
}

export const RecommendedSkeletalCards = ({ total = 3 }) => {
    const { CardStyle, Card, SkeletalBox, SkeletalBoxStyle } = styles
    return (
        <div className={`${SkeletalBoxStyle} ${SkeletalBox}`}>
            {[...Array(total)].map(() => (
                <Skeleton
                    key={uuidv4()}
                    className={`${CardStyle} ${Card}`}
                    active
                />
            ))}
        </div>
    )
}

export const NewSolutionSkeletalCards = ({ total = 3 }) => {
    const { NewSolutionCard, NewSolutionSkeleton } = styles

    return [...Array(total)].map(() => (
        <div key={uuidv4()} className={NewSolutionCard}>
            <Skeleton.Input active className={NewSolutionSkeleton} />
        </div>
    ))
}

export const ListingsSkeletalCards = ({ total = 4, columnCount }) => {
    const { LargeRows, MobileCards, MobileCard } = styles
    return (
        <>
            <div style={{ marginTop: '50px' }}>
                <Space>
                    {[...Array(total)].map(() => (
                        <Skeleton.Button
                            key={uuidv4()}
                            style={{
                                width: '134px',
                                borderRadius: '7px',
                                height: '25px',
                            }}
                            active
                        />
                    ))}
                </Space>
            </div>
            {total > 0 && <hr className="LoadingHr" />}
            <div className={LargeRows}>
                <div className="LoadingBtnsWrapper">
                    {[...Array(columnCount)].map(() => (
                        <Skeleton.Button
                            key={uuidv4()}
                            className="LoadingBtns"
                            active
                        />
                    ))}
                </div>

                <div className="LoadingRowsWrapper">
                    {[...Array(6)].map(() => (
                        <Skeleton.Input
                            key={uuidv4()}
                            className="LoadingRows"
                            active
                        />
                    ))}
                </div>
            </div>
            <div className={MobileCards}>
                {[...Array(6)].map(i => (
                    <div key={i} className={MobileCard}>
                        <div>
                            <p>
                                <Skeleton.Input
                                    active
                                    size="small"
                                    style={{ width: '125px' }}
                                />
                            </p>
                            <p>
                                <Skeleton.Input
                                    active
                                    size="small"
                                    style={{ width: '70px' }}
                                />
                            </p>
                        </div>
                        <div>
                            <div>
                                <p>
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{ width: '100px' }}
                                    />
                                </p>
                            </div>
                            {columnCount !== 3 ? (
                                <div>
                                    <p>
                                        <Skeleton.Input
                                            active
                                            size="small"
                                            style={{ width: '100px' }}
                                        />
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export const TableSkeletalCards = ({ total = 4, mobileType = 'rows' }) => {
    const { LargeRows, MobileCards, MobileCard } = styles
    return (
        <>
            <div className={LargeRows}>
                <div className="LoadingBtnsWrapper Table">
                    {[...Array(total)].map(() => (
                        <Skeleton.Button
                            key={uuidv4()}
                            className="LoadingBtns"
                            active
                        />
                    ))}
                </div>

                <div className="LoadingRowsWrapper">
                    {[...Array(6)].map(() => (
                        <Skeleton.Input
                            key={uuidv4()}
                            className="LoadingRows"
                            active
                        />
                    ))}
                </div>
            </div>
            <div className={MobileCards}>
                {mobileType === 'cards'
                    ? [...Array(6)].map(i => (
                          <div key={i} className={MobileCard}>
                              <div>
                                  <p>
                                      <Skeleton.Input
                                          active
                                          size="small"
                                          style={{ width: '125px' }}
                                      />
                                  </p>
                                  <p>
                                      <Skeleton.Input
                                          active
                                          size="small"
                                          style={{ width: '70px' }}
                                      />
                                  </p>
                              </div>
                              <div>
                                  <div>
                                      <p>
                                          <Skeleton.Input
                                              active
                                              size="small"
                                              style={{ width: '100px' }}
                                          />
                                      </p>
                                  </div>
                                  <div>
                                      <p>
                                          <Skeleton.Input
                                              active
                                              size="small"
                                              style={{ width: '100px' }}
                                          />
                                      </p>
                                  </div>
                              </div>
                          </div>
                      ))
                    : [...Array(6)].map(() => (
                          <Skeleton.Input
                              key={uuidv4()}
                              className="LoadingRows"
                              active
                          />
                      ))}
            </div>
        </>
    )
}

export const RowSkeletalLoader = ({ total = 6 }) => {
    return (
        <div className="LoadingRowsWrapper">
            {[...Array(total)].map(() => (
                <Skeleton.Input key={uuidv4()} className="LoadingRows" active />
            ))}
        </div>
    )
}

export const ComponentBrandsSkeletalCards = ({ total = 3 }) => {
    const {
        ComponentBrandsCard,
        ComponentBrandsCardsContainer,
        CardHeader,
        CardButton,
        CardText,
    } = styles
    return (
        <div className={ComponentBrandsCardsContainer}>
            {[...Array(total)].map(() => (
                <div key={uuidv4()} className={ComponentBrandsCard}>
                    <div className={CardHeader}>
                        <Skeleton.Input className={CardText} active />
                        <Skeleton.Button className={CardButton} active />
                    </div>
                    <div>
                        <Skeleton.Input className={CardText} active />
                    </div>
                    <div>
                        <Skeleton.Input className={CardText} active />
                    </div>
                    <div className={CardHeader}>
                        <Skeleton.Button className={CardButton} active />
                        <Skeleton.Button className={CardButton} active />
                    </div>
                </div>
            ))}
        </div>
    )
}
