import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Slider from 'react-slick'
import circleLeftBlue from 'src/assets/images/csse-carousel-left.svg'
import circleRightWhite from 'src/assets/images/csse-carousel-right.svg'
import { ConsumerButton } from 'src/components/Button'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './cssepromocarousel.scss'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'

// app tracker variables
const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'SSE_LANDING_PAGE'

const PrevButton = props => {
    const { className, onClick } = props

    return (
        <div className={className} onClick={onClick} style={{ zIndex: 1 }}>
            <img src={circleLeftBlue} alt="carousel prev button" />
        </div>
    )
}

const NextButton = props => {
    const { className, onClick } = props
    return (
        <div onClick={onClick} style={{ zIndex: 1 }} className={className}>
            <img src={circleRightWhite} alt="carousel right button" />
        </div>
    )
}
const CSSEPromoCarousel = ({ data }) => {
    const history = useHistory()
    const { isMobile } = useMediaQueries()

    const settings = {
        className: 'center',
        // centerMode: true,
        infinite: true,
        centerPadding: '160px',
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
        draggable: true,
        variableWidth: true,
        dots: false,
        arrows: true,
        prevArrow: !isMobile && <PrevButton />,
        nextArrow: !isMobile && <NextButton />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 3200,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    }

    return (
        <section
            className="cssePromoCarouselSectionWrapper"
            data-testid="carousel-section"
        >
            <div className="cssePromoCarouselWrapper">
                <div>
                    <section
                        className="cssePromoHeaderWrapper"
                        style={{
                            alignItems: data?.length === 1 && 'center',
                        }}
                    >
                        <div>
                            <h3 className="cssePromoCarouselHeader">
                                Browse some available promos for you
                            </h3>
                            <p className="cssePromoCarouselParagraph">
                                Get access to some already available product,
                                all you just need to do is choose the product
                                that can best power what you want to power and
                                choose a plan
                            </p>
                        </div>
                        {data?.length === 1 && !isMobile && (
                            <div
                                onClick={() => {
                                    history.push(
                                        `/consumer/promo-detail/${data[0]?.id}`,
                                    )
                                }}
                            >
                                <LazyLoadImage
                                    src={data[0]?.image_url}
                                    alt={data[0].name}
                                    className="cssepromoOneImg"
                                />
                            </div>
                        )}
                        {data.length > 1 && (
                            <ConsumerButton
                                btnWidth={isMobile ? '140px' : '238px'}
                                btnHeight={isMobile ? '46px' : '56px'}
                                btnBgColor="#F9FCFF"
                                btnTextColor="#004AAD"
                                btnFontSize="#14px"
                                handleClick={() => {
                                    history.push('/consumer/promos')
                                    appTracking(
                                        pageUrlName,
                                        pageTracker,
                                        pageTitle,
                                        eventTrackers['seeAllPromoButton']
                                            .action,
                                        eventTrackers['seeAllPromoButton']
                                            .label,
                                        eventTrackers['seeAllPromoButton']
                                            .category,
                                        ['MP', 'GA'],
                                    )
                                }}
                                dataTestId={'see-all-promos-button'}
                            >
                                See all promos
                            </ConsumerButton>
                        )}
                    </section>
                </div>
            </div>

            <Slider {...settings} style={{ marginLeft: '2px' }}>
                {(data.length > 1 || isMobile) &&
                    data?.map((promo, i) => (
                        <div
                            key={i}
                            className="cssePromoCarouselCard"
                            onClick={() => {
                                history.push(
                                    `/consumer/promo-detail/${promo?.id}`,
                                )
                            }}
                            data-testid={`promo-card-${i}`}
                        >
                            <LazyLoadImage
                                src={promo?.image_url}
                                alt={promo.name}
                                className="cssepromoCarouselImg"
                            />
                        </div>
                    ))}
            </Slider>
        </section>
    )
}

CSSEPromoCarousel.propTypes = {
    data: PropTypes.array,
}

export default CSSEPromoCarousel

PrevButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
}

NextButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
}
