import { toast } from 'react-hot-toast'
import PropTypes from 'prop-types'
import checkIcon from 'src/assets/images/circle-check-green.svg'
import closeIcon from 'src/assets/images/close-icon-white.svg'
import errorIcon from 'src/assets/images/toast-error.svg'
import warningIcon from 'src/assets/images/toast-warning-icon.svg'
import styles from './toast.module.scss'
import BVNWarningIcon from 'src/assets/images/warning-icon-2.svg'

export const ToastMsg = ({
    message,
    type,
    onClose,
    width,
    marginTop,
    exitClassName,
}) => {
    const toastIcon = {
        success: checkIcon,
        error: errorIcon,
        warning: warningIcon,
        bvnAlert: BVNWarningIcon,
    }
    const handleDismiss = () => {
        // You can add exit animation here if needed before dismissing
        onClose()
    }
    return (
        <>
            <div
                className={
                    type === 'warning'
                        ? styles.warning_container + ' ' + styles[exitClassName]
                        : styles.toast_container + ' ' + styles[exitClassName]
                }
                style={{ width: width, marginTop: marginTop }}
            >
                <div>
                    <div className={styles.message_section}>
                        <img src={toastIcon[type]} alt="success icon" />
                        <p data-testid="toast-error-message">
                            {message || 'Success'}
                        </p>
                    </div>
                </div>
                {type !== 'warning' && (
                    <div
                        className={styles.close_section}
                        onClick={handleDismiss}
                    >
                        <img src={closeIcon} />
                    </div>
                )}
            </div>
        </>
    )
}

ToastMsg.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string,
    width: PropTypes.string || PropTypes.number,
    marginTop: PropTypes.string || PropTypes.number,
    onClose: PropTypes.func,
    exitClassName: PropTypes.string,
}

export const useCustomToast = () => {
    const successAlert = (message, width, marginTop) => {
        toast.custom(
            t => (
                <ToastMsg
                    message={message}
                    type="success"
                    width={width}
                    marginTop={marginTop}
                    exitClassName={t.visible ? '' : 'exit'}
                    onClose={() => toast.dismiss(t.id)}
                />
            ),
            { id: 'success' },
            { duration: 5000 },
        )
    }
    const errorAlert = (message = 'Error', width, marginTop) => {
        toast.custom(
            t => (
                <ToastMsg
                    message={message}
                    type="error"
                    width={width}
                    marginTop={marginTop}
                    exitClassName={t.visible ? '' : 'exit'}
                    onClose={() => toast.dismiss(t.id)}
                />
            ),
            { id: 'error' },
            { duration: 5000 },
        )
    }
    const BVNAlert = (message, width, marginTop) => {
        toast.custom(
            t => (
                <ToastMsg
                    message={message}
                    type="bvnAlert"
                    width={width}
                    marginTop={marginTop}
                    exitClassName={t.visible ? '' : 'exit'}
                    onClose={() => toast.dismiss(t.id)}
                />
            ),
            { id: 'bvn' },
            { duration: 5000 },
        )
    }
    const warningAlert = (message, width) => {
        toast.custom(
            t => (
                <ToastMsg
                    message={message}
                    type="warning"
                    width={width}
                    exitClassName={t.visible ? '' : 'exit'}
                />
            ),
            { id: 'warning' },
            { duration: 5000 },
        )
    }
    return {
        successAlert,
        errorAlert,
        warningAlert,
        BVNAlert,
    }
}
