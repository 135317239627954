import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Pagination } from 'antd'
import useGetProfileGroups from './useGetProfilesGroups'
import useGetProfileGroup from './useGetProfilesGroup'
import useDeleteProfilesGroup from './useDeleteProfileGroup'
import {
    addQueryParamsToUrl,
    getSearchParamsFromUrl,
} from 'src/utils/urlTracker'

const useAdminProfilesGroups = () => {
    const filterQuery = getSearchParamsFromUrl()
    const [filterState, setFilterState] = useState({
        currentPage: Number(filterQuery.page) || 1,
        searchText: filterQuery.search || '',
        customerType: filterQuery.customerType || '',
    })
    const [searchTextInput, setSearchTextInput] = useState(
        filterQuery.search || '',
    )
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showActionModal, setShowActionModal] = useState({
        open: false,
        type: '',
    })

    const [profilesGroup, setProfilesGroup] = useState({})
    const [showViewModal, setShowViewModal] = useState({
        open: false,
        id: '',
    })

    const history = useHistory()

    const {
        profilesGroups,
        profilesGroupsFetching,
        totalPages,
        profilesGroupsRefetch,
    } = useGetProfileGroups({
        filterState,
        onSuccess: () => {
            setProfilesGroup({})
        },
    })

    const { profilesGroupRefetch, profilesGroupFetching } = useGetProfileGroup({
        id: showViewModal?.id,
        onSuccess: data => {
            setProfilesGroup(data)
        },
    })

    const {
        deleteProfileMutate,
        deleteProfileLoading,
        deleteProfileErrorMessage,
        deleteProfileToastError,
    } = useDeleteProfilesGroup({
        onSuccess: () => {
            setShowActionModal({ open: true, type: 'group_deleted' })
        },
    })

    useEffect(() => {
        if (showViewModal?.id?.length > 0) {
            profilesGroupRefetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showViewModal])

    const actionModalHandler = () => {
        if (['group_created', 'group_deleted'].includes(showActionModal.type)) {
            setShowActionModal({ open: false, type: '' })
            profilesGroupsRefetch()
            setProfilesGroup({})
        } else if (showActionModal.type === 'group_updated') {
            setShowActionModal({ open: false, type: '' })
            setShowViewModal(prev => ({ open: true, id: prev.id }))
        } else if (showActionModal.type === 'delete_group_with_no_profiles') {
            deleteProfileMutate(showViewModal?.id)
        }
    }

    const deleteGroup = () => {
        setShowViewModal(prev => ({ open: false, id: prev.id }))
        setShowActionModal({
            open: true,
            type:
                profilesGroup?.profiles_added > 0
                    ? 'delete_group_with_profiles'
                    : 'delete_group_with_no_profiles',
        })
    }

    const closeActionModal = () => {
        setShowViewModal(prev => ({ open: true, id: prev.id }))
        setShowActionModal({
            open: false,
            type: '',
        })
    }

    const handleFilterChange = newFilters => {
        setFilterState(prevState => ({
            ...prevState,
            currentPage: 1,
            ...newFilters,
        }))
    }

    const updatePage = page => {
        handleFilterChange({ currentPage: page })
        addQueryParamsToUrl({
            page: page,
        })
    }

    const goToCreateProfileGroup = () => {
        if (showViewModal?.open) {
            setShowViewModal(prev => ({ open: false, id: prev.id }))
        } else {
            setProfilesGroup({})
        }
        setShowCreateModal(prev => !prev)
    }

    const closeViewGroup = () => {
        setShowViewModal({ open: false, id: '' })
        setProfilesGroup({})
        profilesGroupsRefetch()
    }

    const goToProfile = () => {
        history.goBack()
    }

    const addSearchTextToFilter = () => {
        if (searchTextInput !== '') {
            handleFilterChange({ searchText: searchTextInput })
            addQueryParamsToUrl({
                search: searchTextInput,
                page: '1',
            })
        }
    }

    const paginationBlock = () => {
        return (
            <>
                {totalPages < 2 ? null : (
                    <Pagination
                        defaultCurrent={1}
                        current={filterState?.currentPage}
                        total={parseInt(totalPages, 10)}
                        defaultPageSize={1}
                        showSizeChanger={false}
                        showTitle={false}
                        showLessItems
                        onChange={updatePage}
                    />
                )}
            </>
        )
    }

    return {
        profilesGroups,
        profilesGroupsFetching,
        paginationBlock,
        goToCreateProfileGroup,
        searchTextInput,
        setSearchTextInput,
        handleFilterChange,
        addSearchTextToFilter,
        profilesGroupsRefetch,
        goToProfile,
        showCreateModal,
        showActionModal,
        setShowActionModal,
        actionModalHandler,
        showViewModal,
        setShowViewModal,
        profilesGroup,
        profilesGroupFetching,
        deleteGroup,
        closeActionModal,
        deleteProfileLoading,
        deleteProfileErrorMessage,
        deleteProfileToastError,
        closeViewGroup,
    }
}

export default useAdminProfilesGroups
