import { Skeleton } from 'antd'
import { isMobile } from 'src/utils/mediaQueries'
const ViewSystemSkeleton = () => {
    return (
        <>
            <Skeleton.Input
                active
                style={{ marginBottom: 25, borderRadius: 4, width: 70 }}
            />
            <div className="ViewSystem_Skeleton">
                <Skeleton.Image
                    active
                    style={{ width: '100%', height: isMobile ? 223 : 479 }}
                />
                <div style={{ width: '100%' }}>
                    <Skeleton
                        active
                        paragraph={{ rows: 1 }}
                        className="ViewSystem_Skeleton_Title"
                        style={{ marginBottom: 24 }}
                    />
                    <div className="ViewSystem_Skeleton_Cards">
                        {[...Array(3)].map((_, i) => (
                            <Skeleton.Input key={i} active />
                        ))}
                    </div>
                    <div className="ViewSystem_Skeleton_Inner">
                        <Skeleton
                            active
                            paragraph={{ rows: 1 }}
                            className="ViewSystem_Skeleton_Title"
                            style={{ marginTop: 40 }}
                        />
                        <div className="ViewSystem_Skeleton_DoubleColumn">
                            <Skeleton
                                active
                                paragraph={{ rows: 1 }}
                                className="ViewSystem_Skeleton_Title"
                            />
                            <Skeleton
                                active
                                paragraph={{ rows: 1 }}
                                className="ViewSystem_Skeleton_Title"
                            />
                        </div>
                        <div className="ViewSystem_Skeleton_DoubleColumn">
                            <Skeleton
                                active
                                paragraph={{ rows: 1 }}
                                className="ViewSystem_Skeleton_Title"
                            />
                            <Skeleton
                                active
                                paragraph={{ rows: 1 }}
                                className="ViewSystem_Skeleton_Title"
                            />
                        </div>
                        <div className="ViewSystem_Skeleton_DoubleColumn">
                            <Skeleton.Button active />
                            <Skeleton.Button active />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewSystemSkeleton
