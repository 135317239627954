/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PageMeta from '../PageMeta'
import { initGA } from '../../utils/gaUtils'
import { initMixPanel } from '../../utils/mixpanelUtils'

const SeoComponent = ({
    title,
    tracker,
    description,
    image,
    imageDescription,
    post,
    preloadImages,
    keywords,
}) => {
    const location = useLocation()
    const pageTitle = title
    const pageTracker = tracker
    const pageDescription = description
    const pageImage = image
    const pageImageDescription = imageDescription
    const pagePost = post
    const pagePreloadedImages = preloadImages

    useEffect(() => {
        const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID
        if (GA_TRACKING_ID) {
            initGA(
                GA_TRACKING_ID,
                'pageview',
                location.pathname,
                pageTracker,
                pageTitle,
            )
        }

        const MIXPANEL_ID = process.env.REACT_APP_MIXPANEL_ID
        if (MIXPANEL_ID) {
            initMixPanel(
                MIXPANEL_ID,
                'pageview',
                location.pathname,
                pageTracker,
                pageTitle,
            )
        }

        // eslint-disable-next-line
    }, [title])

    return (
        <>
            <PageMeta
                title={pageTitle}
                description={pageDescription}
                image={pageImage}
                imageDescription={pageImageDescription}
                post={pagePost}
                preloadImages={pagePreloadedImages}
                keywords={keywords}
            />
        </>
    )
}

export default SeoComponent
