import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateConsumerProfile } from 'src/redux/reducers/admin/profiles/create-profile'
import { formatPaymentTypesOptionsForApi } from '../../AddPackage/components/AddPackageDrawer/data'

export const useEditPackage = ({ setSelectedPackages }) => {
    const dispatch = useDispatch()
    const [appliances, setAppliances] = useState([])
    const [packageData, setPackage] = useState([])
    const [cluster, setCluster] = useState({
        subscription_cluster_code: '',
        lease_to_own_cluster_code: '',
    })
    const [paymentType, setPaymentType] = useState([])
    const [paymentTypeError, setPaymentTypeError] = useState('')

    const [openEditDrawer, setOpenEditDrawer] = useState(false)
    const toggleEditDrawer = row => {
        if (row) {
            setPackage(row)
            setAppliances(row?.appliances)
            setPaymentType(row?.payment_plan_types || [])
            setCluster({
                subscription_cluster_code: row?.lease_to_own_cluster_code ?? '',
                lease_to_own_cluster_code: row?.subscription_cluster_code ?? '',
            })
        }

        setOpenEditDrawer(prev => !prev)
    }

    const handlePackageUpdate = isSave => {
        setSelectedPackages(prev => {
            const selectedPackages = [...prev]
            const index = selectedPackages.findIndex(
                obj => obj.id === packageData?.id,
            )
            if (index !== -1) {
                if (isSave) {
                    let payment_type = [...paymentType]
                    if (!payment_type.length) {
                        setPaymentTypeError('Payment type is required')
                    }

                    selectedPackages.splice(index, 1, {
                        ...packageData,
                        appliances,
                        ...cluster,
                        payment_plan_types:
                            formatPaymentTypesOptionsForApi(payment_type),
                    })
                } else {
                    selectedPackages.splice(index, 1)
                }
            }
            dispatch(updateConsumerProfile({ packages: selectedPackages }))
            return selectedPackages
        })
        toggleEditDrawer()
    }

    return {
        appliances,
        setAppliances,
        openEditDrawer,
        toggleEditDrawer,
        handlePackageUpdate,
        cluster,
        setCluster,
        packageData,
        paymentType,
        setPaymentType,
        paymentTypeError,
        setPaymentTypeError,
    }
}
