export const offerStatusMap = {
    IN_PROGRESS: 'In-progress',
    KYC_NOT_STARTED: 'KYC not started',
    KYC_IN_PROGRESS: 'KYC in-progress',
    ...(process.env.REACT_APP_FLAG_SHOW_REESTIMATE_DEP_CONSUMER_PACKAGE ===
    'true'
        ? { AWAITING_RE_ESTIMATION: 'Awaiting re-estimation' }
        : {}),
    AWAITING_UPDATE: 'Awaiting update',
    AWAITING_DECISION: 'Awaiting decision',
    CREDIT_DECISION_MAYBE: 'Approved - maybe',
    AWAITING_APPROVAL: 'Awaiting approval',
    DECLINED: 'Declined',
    REVIEW_REQUIRED: 'Review Required',
    TENURE_SELECTION: 'Tenure selection',
    AGREEMENT_SIGNING: 'Agreement signing',
    UPFRONT_DEPOSIT_PAYMENT: 'Upfront deposit payment',
    REPAYMENT_METHOD_SETUP: 'Repayment method setup',
    SOLUTION_INSTALLED: 'Awaiting installation',
    ACTIVE: 'Active',
    PLAN_COMPLETED: 'Plan completed',
    ARCHIVED: 'Archived',
}

export const offerStatusTitles = Object?.values(offerStatusMap)

export const getOfferStatusKeyByTitle = title => {
    return Object?.keys(offerStatusMap)?.find(
        key => offerStatusMap[key] === title,
    )
}

// get offer status in obj[] format of [{ status:  IN_PROGRESS, title: 'In-progress' },...]
export const mapOfferStatusAndTitle = () => {
    return Object.entries(offerStatusMap).map(([status, title]) => ({
        status,
        title,
    }))
}
