export const marginValues = action => {
    switch (action) {
        case 'Add Margin':
            return {
                color: '#32CC79',
                symbol: '+',
            }

        case 'Subtract Margin':
            return {
                color: '#DA1E28',
                symbol: '-',
            }
        default:
            return {
                color: '#32CC79',
                symbol: '',
            }
    }
}
