export const footerOptions = [
    {
        title: 'Go to our homepage',
        key: 'go-home',
        description: 'Explore more options we have for you.',
        link: '/',
    },
    {
        title: 'Chat with us',
        key: 'chat',
        description: 'Talk to someone from our team.',
        link: '',
    },
    {
        title: 'Frequently asked questions ',
        key: 'faq',
        description: 'Get answers to any questions you might have.',
        link: '',
    },
]
