import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { appTracking } from '../../../utils/appTracker'
import { BlogContentData, BlogSEOMetaData } from '../utils'

const LearnContentTwelve = () => {
    const [feedbackResponse, setFeedbackResponse] = useState(false)
    const location = useLocation()
    const pageTitle = 'Learn | SunFi | Simplifying and Scaling Clean Energy'

    const actionEqualsYes = () => {
        appTracking(
            location.pathname,
            'LearnContentTracker',
            pageTitle,
            'USEFUL_CLICK',
            'USEFUL_BUTTON',
            'LEARN_PAGE_FEEDBACK',
        )
        setFeedbackResponse(true)
    }

    const actionEqualsNo = () => {
        appTracking(
            location.pathname,
            'LearnContentTracker',
            pageTitle,
            'NOT_USEFUL_CLICK',
            'NOT_USEFUL_BUTTON',
            'LEARN_PAGE_FEEDBACK',
        )
        setFeedbackResponse(true)
    }

    return (
        <div>
            <BlogSEOMetaData
                created="November 25, 2023"
                tracker="ContentTwelveTracker"
                yesAction={actionEqualsYes}
                noAction={actionEqualsNo}
                feedbackResponse={feedbackResponse}
            />
            <BlogContentData contentId={12} title="Living Should Be Easier" />
        </div>
    )
}

export default LearnContentTwelve
