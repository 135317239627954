import SecondaryModal from 'src/components/Modal/SecondaryModal'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import FormTitleBar from 'src/components/FormTitleBar'
import {
    InputFields,
    MultipleSelectField,
    TextArea,
} from 'src/components/InputFields'
import { requestUpdateSchema } from 'src/utils/validationSchema'
import { ConsumerButton } from 'src/components/Button'
import { useMutation, useQuery } from 'react-query'
import {
    getRequestUpdatFieldsApi,
    requestUpdateApi,
} from 'src/api/shoppingExperience/account-overview'
import { InlineLoader } from 'src/components/Loader'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { isMobile } from 'src/utils/mediaQueries'
import {
    removeUnderscoresAndToTitleCase,
    revertToOriginalFormat,
} from 'src/utils/formatting'

const RequestUpdateModal = ({
    showModal,
    closeFunc,
    estimation_id_for_admin,
}) => {
    const [inputs, setInputs] = useState({
        title: '',
        fields: [],
        notes: '',
    })
    const [errors, setErrors] = useState({})
    const [updateFieldOptions, setUpdateFieldOptions] = useState([])
    const { successAlert, errorAlert } = useCustomToast()

    const { refetch } = useQuery(
        'retrieve-update-fields',
        () => getRequestUpdatFieldsApi(estimation_id_for_admin),
        {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data?.fields
                setUpdateFieldOptions([])
                data?.map(item => {
                    setUpdateFieldOptions(prevOptions => [
                        ...prevOptions,
                        { value: removeUnderscoresAndToTitleCase(item) },
                    ])
                })
            },
        },
    )

    const requestUpdateMutation = useMutation({
        mutationKey: ['admin-request-update'],
        mutationFn: () =>
            requestUpdateApi(estimation_id_for_admin, {
                ...inputs,
                fields: inputs.fields.map(field =>
                    revertToOriginalFormat(field.value),
                ),
            }),
        onSuccess: () => {
            closeModal()
            successAlert('Request sent successfully', isMobile ? 320 : 500)
        },
        onError: error => {
            errorAlert(
                errorHandler(error?.response?.data) ||
                    'An error occurred. Please try again later',
                isMobile ? 320 : 500,
            )
        },
    })

    const handleInputChange = event => {
        const { value } = event.target
        const { name } = event.target
        setInputs(prev => ({ ...prev, [name]: value }))
        if (errors[name]) {
            delete errors[name]
        }
    }

    const handleRequest = () => {
        requestUpdateSchema
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors('')
                requestUpdateMutation.mutate()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const closeModal = () => {
        setInputs({
            title: '',
            fields: [],
            notes: '',
        })
        setErrors({})
        closeFunc()
    }

    useEffect(() => {
        if (showModal) {
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal])

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={() => closeModal()}
            modalWidth={682}
            noPadding
            content={
                <div className="ASO_RequestUpdate">
                    <FormTitleBar
                        title={'Request Update'}
                        subtitle={
                            'Enter the details of the request below to continue'
                        }
                    />
                    <InputFields
                        title={'Request Title'}
                        name={'title'}
                        value={inputs.title}
                        handleChange={handleInputChange}
                        inputWidth={'100%'}
                        errorMessage={errors.title}
                    />
                    <MultipleSelectField
                        width={'100%'}
                        name={'fields'}
                        maxTagCount="responsive"
                        maxTagPlaceholder="Select Required Fields"
                        title={'Select Required Fields'}
                        values={updateFieldOptions}
                        value={inputs.fields?.map(item => item?.value)}
                        inputValue={inputs.fields?.map(item => item?.value)}
                        handleMultipleSelectChange={value => {
                            const values = value?.map(item => ({
                                value: item,
                                label: item,
                                id: inputs.fields?.filter(
                                    each => each.value === item,
                                )?.[0]?.id,
                            }))
                            setInputs(prev => ({
                                ...prev,
                                fields: values,
                            }))
                        }}
                        errorMessage={errors.fields}
                    />
                    <TextArea
                        title={'Add Short Note'}
                        height={122}
                        name={'notes'}
                        value={inputs.notes}
                        handleChange={handleInputChange}
                        errorMessage={errors.notes}
                    />
                    <ConsumerButton
                        btnWidth={'160px'}
                        btnHeight={'56px'}
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#FFFFFF'}
                        handleClick={() => handleRequest()}
                    >
                        {requestUpdateMutation.isLoading ? (
                            <InlineLoader />
                        ) : (
                            'Send Request'
                        )}
                    </ConsumerButton>
                </div>
            }
        />
    )
}

RequestUpdateModal.propTypes = {
    showModal: PropTypes.bool,
    closeFunc: PropTypes.func,
    estimation_id_for_admin: PropTypes.string,
}

export default RequestUpdateModal
