import '../consumerkyc.scss'
import PropTypes from 'prop-types'
import NairaSymbol from 'src/components/NairaSymbol'

const EnergyInfo = ({ energyInfo, paymentPlan }) => {
    return (
        <div className="EnergyInfo">
            <div className="EnergyInfoDetails">
                {energyInfo?.map(info => (
                    <div key={info.title}>
                        <h6>{info.title}</h6>
                        <p>
                            {info?.isAmount ? <NairaSymbol /> : ''}{' '}
                            {info?.value}
                        </p>
                    </div>
                ))}
            </div>

            <div className="GroupItems">
                <div>
                    <h6>Payment plan type</h6>
                    <p>{paymentPlan}</p>
                </div>
            </div>
        </div>
    )
}

EnergyInfo.propTypes = {
    energyInfo: PropTypes.object,
    paymentPlan: PropTypes.string,
}

export default EnergyInfo
