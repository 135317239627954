/* eslint-disable no-case-declarations */

export const initialValues = {
    paymentPlans: [],
    selectedPaymentPlan: null,
    showDealApprovalModal: false,
    showDealApprovalModalSub: false,
    showPaymentPlanModal: false,
    showSubscriptionTenureModal: false,
    showPaymentDetailsModal: false,
    showSubscriptionDetailsModal: false,
    showPaymentSetup: false,
    showUpfrontDepositModal: false,
    showPaystackModal: false,
    subscriptionTenure: '',
    paymentType: '',
    formattedAmount: 20000,
    consumerEmail: 'user@gmail.com',
    showSubCardDetails: false,
    showSubDirectDetails: false,
    isPaystackingLoading: false,
    isPaystackVerifying: false,
    isCardDetailsModal: false,
    isMandate: false,
    subPaymentSuccess: false,
    showDirectDebitTypes: false,
    selectedDirectDebitType: '',
    showAgreementSigningModal: false,
    showAgreementSigningConfirmationModal: false,
}

export const PAYMENT_PLANS = 'payment_plans'
export const SELECT_PAYMENT_PLAN = 'select_payment_plan'
export const DEFAULT_SELECT_PAYMENT_PLAN = 'default_select_payment_plan'
export const SET_DEAL_APPROVAL_MODAL = 'deal_approval_modal'
export const SET_DEAL_APPROVAL_MODAL_SUBSCRIPTION = 'deal_approval_modal_sub'
export const SET_PAYMENT_PLAN_MODAL = 'payment_plan_modal'
export const SET_SUBSCRIPTION_TENURE_MODAL = 'subscription_tenure_modal'
export const SET_PAYMENT_DETAILS_MODAL = 'set_payment_details_modal'
export const SET_SUBSCRIPTION_DETAILS_MODAL = 'set_subscription_details_modal'
export const SET_SUBSCRIPTION_TENURE = 'subscription_tenure'
export const SET_PAYMENT_SETUP = 'set_payment_setup'
export const SET_PAYMENT_TYPE = 'set_payment_type'
export const SET_UPFRONT_DEPOSIT_MODAL = 'show_upfront_deposit_modal'
export const SET_PAYSTACK_MODAL = 'show_paystack_modal'
export const SET_PAYSTACK_LOADING = 'set_paystack_loading'
export const SET_PAYSTACK_VERIFYING = 'set_paystack_verifying'
export const SET_SUB_CARD_DETAILS = 'set_sub_card_details_modal'
export const SET_SUB_DIRECT_DETAILS = 'set_sub_direct_details_modal'
export const SET_CARD_DETAILS = 'set_card_details_modal'
export const SET_DIRECT_MANDATE = 'set_direct_mandate'
export const SET_SUB_PAYMENT_SUCCESS = 'set_sub_payment-success'
export const SET_DIRECT_DEBIT_TYPES = 'set_direct_debit_types'
export const SET_SELECTED_DIRECT_DEBIT_TYPE = 'set_selected_direct_debit_type'
export const SET_AGREEMENT_SIGNING_MODAL = 'show_agreement_signing_modal'
export const SET_AGREEMENT_SIGNING_CONFIRMATION_MODAL =
    'show_agreement_signing_confirmation_modal'

export const reducer = (state = initialValues, action) => {
    const { type, payload } = action
    switch (type) {
        case PAYMENT_PLANS:
            return { ...state, paymentPlans: payload }

        case DEFAULT_SELECT_PAYMENT_PLAN:
            const default_selected_plan =
                state.paymentPlans.filter(el => el.selected)[0] || null

            return {
                ...state,
                selectedPaymentPlan: default_selected_plan,
            }

        case SELECT_PAYMENT_PLAN:
            const updatedPlans = state.paymentPlans.map((plan, idx) =>
                idx === payload
                    ? { ...plan, selected: !plan.selected }
                    : { ...plan, selected: false },
            )
            const selected_plan =
                updatedPlans.filter(el => el.selected)[0] || null
            return {
                ...state,
                paymentPlans: updatedPlans,
                selectedPaymentPlan: selected_plan,
            }
        case SET_DEAL_APPROVAL_MODAL:
            return { ...state, showDealApprovalModal: payload }
        case SET_DEAL_APPROVAL_MODAL_SUBSCRIPTION:
            return { ...state, showDealApprovalModalSub: payload }
        case SET_PAYMENT_PLAN_MODAL:
            return { ...state, showPaymentPlanModal: payload }
        case SET_SUBSCRIPTION_TENURE_MODAL:
            return { ...state, showSubscriptionTenureModal: payload }
        case SET_PAYMENT_DETAILS_MODAL:
            return { ...state, showPaymentDetailsModal: payload }
        case SET_SUBSCRIPTION_DETAILS_MODAL:
            return { ...state, showSubscriptionDetailsModal: payload }
        case SET_SUBSCRIPTION_TENURE:
            return { ...state, subscriptionTenure: payload }
        case SET_UPFRONT_DEPOSIT_MODAL:
            return { ...state, showUpfrontDepositModal: payload }
        case SET_PAYMENT_SETUP:
            return { ...state, showPaymentSetup: payload }
        case SET_PAYMENT_TYPE:
            return { ...state, paymentType: payload }
        case SET_PAYSTACK_MODAL:
            return { ...state, showPaystackModal: payload }
        case SET_PAYSTACK_LOADING:
            return { ...state, isPaystackLoading: payload }
        case SET_PAYSTACK_VERIFYING:
            return { ...state, isPaystackVerifying: payload }
        case SET_CARD_DETAILS:
            return { ...state, isCardDetailsModal: payload }
        case SET_SUB_CARD_DETAILS:
            return { ...state, showSubCardDetails: payload }
        case SET_SUB_DIRECT_DETAILS:
            return { ...state, showSubDirectDetails: payload }
        case SET_DIRECT_MANDATE:
            return { ...state, isMandate: payload }
        case SET_SUB_PAYMENT_SUCCESS:
            return { ...state, subPaymentSuccess: payload }
        case SET_DIRECT_DEBIT_TYPES:
            return { ...state, showDirectDebitTypes: payload }
        case SET_SELECTED_DIRECT_DEBIT_TYPE:
            return { ...state, selectedDirectDebitType: payload }
        case SET_AGREEMENT_SIGNING_MODAL:
            return { ...state, showAgreementSigningModal: payload }
        case SET_AGREEMENT_SIGNING_CONFIRMATION_MODAL:
            return { ...state, showAgreementSigningConfirmationModal: payload }
        default:
            return state
    }
}
