import { extractFileNameFromUrl } from 'src/utils/formatting'
import CustomToolTip from 'src/components/CustomToolTip'
import Avatar from '../../../../../components/Avatar/Avatar'
import { AppButton } from '../../../../../components/Button'
import EmptyProfilePic from '../../../../../components/EmptyProfilePic'
import { ReactComponent as FileAttachmentIcon } from 'src/assets/images/attach-blue.svg'
import { truncateSentence } from 'src/utils/formatting'

export const formatInstallerInfo = (
    data,
    setViewIdentificationDetails,
    setViewPicture,
) => {
    let formattedValues = []

    const email = data?.user?.email ?? '-'
    const phoneNumber = data?.user?.phone_number ?? '-'
    const companyName = data?.company_name ?? '-'
    const educationLevel = data?.user?.education_level ?? '-'
    const identificationType = data?.identification_details?.type ?? '-'
    const newAsiOnboardingFlow =
        process.env.REACT_APP_FLAG_SHOW_NEW_ASI_ONBOARDING_FLOW === 'true'

    newAsiOnboardingFlow &&
        formattedValues.push(
            { label: 'Email Address', value: email },
            {
                label: 'Phone Number',
                value: phoneNumber,
            },
            {
                label: 'Location',
                value: data.state,
            },
            {
                label: 'Years of Solar Installation Experience',
                value: data?.meta_data?.years_of_experience,
            },
            {
                label: 'Documents',
                value: (
                    <div className="resume_wrapper">
                        <FileAttachmentIcon />
                        <a
                            href={data?.resume}
                            className="resume_text"
                            target="_blank"
                            rel="noreferrer"
                            download
                        >
                            <CustomToolTip
                                text={extractFileNameFromUrl(data?.resume)}
                                toolTipFontSize="12px"
                                toolTipPadding="6px 11px"
                                toolTipPopupWidth={'fit-content'}
                                toolTipMarginLeft={-100}
                                arrowPositionCenter
                            >
                                <span className="one">
                                    {truncateSentence(
                                        extractFileNameFromUrl(data?.resume),
                                        25,
                                    )}
                                </span>
                                <span className="two">
                                    {truncateSentence(
                                        extractFileNameFromUrl(data?.resume),
                                        40,
                                    )}
                                </span>
                            </CustomToolTip>
                            <span className="three">
                                {extractFileNameFromUrl(data?.resume)}
                            </span>
                        </a>
                    </div>
                ),
            },
        )
    !newAsiOnboardingFlow &&
        formattedValues.push(
            { label: 'Email', value: email },
            {
                label: 'Phone Number',
                value: phoneNumber,
            },
            {
                label: 'Company Name',
                value: companyName,
            },
            {
                label: 'Qualification',
                value: educationLevel,
            },
            {
                label: 'Type of Identification',
                value: identificationType,
            },
            {
                label: 'Identification Details',
                value: (
                    <AppButton
                        btnTextColor={'#004AAD'}
                        btnBgColor={'#E2EEFF'}
                        type="buttonTwo"
                        handleClick={() => setViewIdentificationDetails(true)}
                        disabled={
                            data?.identification_details?.file_url === null ||
                            data?.identification_details?.file_url === undefined
                        }
                    >
                        View
                    </AppButton>
                ),
            },
            {
                label: 'Profile Picture',
                value: (
                    <div
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        {data?.user?.avatar_url ? (
                            <div onClick={() => setViewPicture(true)}>
                                <Avatar
                                    avatar={`${data?.user?.avatar_url}`}
                                    alt="provider-avatar"
                                />
                            </div>
                        ) : (
                            <EmptyProfilePic
                                firstLetter={data?.user?.first_name
                                    ?.substring(0, 1)
                                    ?.toUpperCase()}
                                secondLetter={data?.user?.last_name
                                    ?.substring(0, 1)
                                    ?.toUpperCase()}
                                profilePage={true}
                            />
                        )}
                    </div>
                ),
            },
        )
    return formattedValues
}
