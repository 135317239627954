import PropTypes from 'prop-types'
import { ReactComponent as SunfiTopIcon } from 'src/assets/images/sunfi-top-icon.svg'
import { ReactComponent as SunfiBottomIcon } from 'src/assets/images/sunfi-bottom-icon.svg'
import { ReactComponent as SunfiTopIconMedium } from 'src/assets/images/sunfi-top-icon-medium.svg'
import { ReactComponent as SunfiBottomIconMedium } from 'src/assets/images/sunfi-bottom-icon-medium.svg'

import styles from './consumerloader.module.scss'

const ConsumerLoader = ({
    text,
    overlayWidth,
    overlayHeight,
    overlayBgColor,
    overlayPosition,
    iconSize = 'small',
}) => {
    const {
        ConsumerLoaderOverlay,
        ConsumerLoaderSpinner,
        ConsumerLoaderIcon,
        ConsumerLoaderTopIcon,
        ConsumerLoaderBottomIcon,
        ConsumerLoaderText,
        MediumIcon,
    } = styles

    const IconComponents = {
        small: {
            TopIcon: SunfiTopIcon,
            BottomIcon: SunfiBottomIcon,
        },
        medium: {
            TopIcon: SunfiTopIconMedium,
            BottomIcon: SunfiBottomIconMedium,
        },
    }

    const { TopIcon, BottomIcon } = IconComponents[iconSize]
    return (
        <div
            id={ConsumerLoaderOverlay}
            style={{
                width: overlayWidth,
                height: overlayHeight,
                backgroundColor: overlayBgColor,
                position: overlayPosition,
            }}
        >
            <div className={ConsumerLoaderSpinner}>
                <TopIcon
                    className={`${ConsumerLoaderIcon} ${ConsumerLoaderTopIcon} ${
                        iconSize === 'medium' ? MediumIcon : ''
                    }`}
                />
                <BottomIcon
                    className={`${ConsumerLoaderIcon} ${ConsumerLoaderBottomIcon} ${
                        iconSize === 'medium' ? MediumIcon : ''
                    }`}
                />
            </div>
            <p className={ConsumerLoaderText}>{text}</p>
        </div>
    )
}

ConsumerLoader.propTypes = {
    text: PropTypes.string,
    overlayWidth: PropTypes.string,
    overlayHeight: PropTypes.string,
    overlayBgColor: PropTypes.string,
    overlayPosition: PropTypes.string,
    iconSize: PropTypes.oneOf(['small', 'medium']),
}

export default ConsumerLoader
