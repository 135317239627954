/* eslint-disable no-undef */
import React from 'react'
import * as Sentry from '@sentry/react'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './components/ErrorHandler'
import ReactDOM from 'react-dom'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import ScrollToTop from '../src/utils/scrollToTop'
import 'antd/dist/antd.min.css'

import { QueryClient, QueryClientProvider } from 'react-query'
import './assets/styles/colors.scss'
import './assets/styles/index.scss'

import App from './App'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'

if (process.env.NODE_ENV !== 'production') {
    const { worker } = require('./redux/api/mock/browser')
    if (worker && worker.active) {
        worker.stop()
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    },
})

if (process.env.REACT_APP_ENABLE_SENTRY === 'enabled') {
    if (process.env.REACT_APP_ENABLE_LOGROCKET === 'enabled') {
        const logRocketId = process.env.REACT_APP_LOGROCKET_APP_ID

        LogRocket.init(logRocketId)

        setupLogRocketReact(LogRocket)

        LogRocket.getSessionURL(sessionURL => {
            Sentry.configureScope(scope => {
                scope.setExtra('sessionURL', sessionURL)
            })
        })
    }

    Sentry.init({
        beforeSend(event) {
            const logRocketSession = LogRocket.sessionURL
            if (logRocketSession !== null) {
                event.extra['LogRocket'] = logRocketSession
                return event
            } else {
                return event
            }
        },
        dsn: process.env.REACT_APP_SENTRY_DSN,
    })
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ScrollToTop />
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <App />
                        </PersistGate>
                    </Provider>
                </BrowserRouter>
            </QueryClientProvider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
)
