import PropTypes from 'prop-types'
import styles from 'src/components/LoginMethodsModal/loginmethodsmodal.module.scss'
import { addMembersMethods } from '../utils'

const Options = ({ method, handleSelectMethod }) => {
    const { wrapper, methodDiv, methodDivActive, methodIcon, methodText } =
        styles
    return (
        <div className={wrapper} style={{ height: '100%' }}>
            {addMembersMethods?.map((each, i) => (
                <div
                    key={i}
                    role="button"
                    className={`${methodDiv} ${
                        method === each?.key ? methodDivActive : ''
                    }`}
                    onClick={() => handleSelectMethod(each?.key)}
                >
                    <div className={methodIcon}>{each?.icon}</div>
                    <div className={methodText}>
                        <h4>{each?.title}</h4>
                        <p>{each?.description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

Options.propTypes = {
    method: PropTypes.string,
    handleSelectMethod: PropTypes.func,
}

export default Options
