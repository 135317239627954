import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Drawer, Collapse } from 'antd'
import BackNav from '../../BackNav'
import FloatingBox from '../../FloatingBox'
import ConsumerQuestion from './ConsumerQuestion'
import { categories } from './data.js'

import ChatIcon from '../../../assets/images/chat-icon.svg'
import './consumerhelpcenter.scss'
import { Fragment } from 'react'

const ConsumerHelpCenter = ({ visible, onClose }) => {
    const [showChatIcon, setShowChatIcon] = useState(true)
    const [activePage, setActivePage] = useState(null)

    const { Panel } = Collapse

    const backToQuestionList = (key = 0) => {
        handlePageUpdate('questionList', '', () => {}, key)
    }

    const AnswerPage = ({ selectedQuestion, answerComponent, categoryId }) => {
        return (
            <div>
                <div>
                    <div>
                        <BackNav
                            title="Back to Help"
                            onClick={() => backToQuestionList(categoryId)}
                        />
                    </div>
                    <h1 className="AnswerHelpCenterTitle">Help Center</h1>
                </div>
                <div className="SelectedQuestion">{selectedQuestion}</div>
                <div className="SelectedAnswer">{answerComponent}</div>
            </div>
        )
    }

    AnswerPage.propTypes = {
        selectedQuestion: PropTypes.any,
        answerComponent: PropTypes.any,
        categoryId: PropTypes.any,
    }

    const handleQuestionClick = (
        selectedQuestion,
        answerComponent,
        categoryId,
    ) => {
        handlePageUpdate(
            'answerPage',
            selectedQuestion,
            answerComponent,
            categoryId,
        )
    }

    const HelpSection = ({ categoryId = 0 }) => {
        return (
            <>
                <h1 className="ConsumerHelpCenterTitle">Help Center</h1>

                <Collapse
                    accordion
                    defaultActiveKey={[categoryId]}
                    bordered={false}
                    expandIconPosition="right"
                    ghost
                >
                    {categories.map(category => (
                        <Fragment key={category.id}>
                            <div className="CategoryLineDivide"> </div>
                            <Panel
                                header={category.category}
                                key={category.id}
                                className="ConsumerCategoryheader"
                            >
                                <div>
                                    {category.questions.map(question => (
                                        <p
                                            key={question.id}
                                            className="questions"
                                            onClick={() =>
                                                handleQuestionClick(
                                                    question.question,
                                                    question.answer(),
                                                    category.id,
                                                )
                                            }
                                        >
                                            {question.question}
                                        </p>
                                    ))}
                                </div>
                            </Panel>
                        </Fragment>
                    ))}
                    <div className="CategoryLineDivide"> </div>
                </Collapse>
            </>
        )
    }

    HelpSection.propTypes = {
        categoryId: PropTypes.any,
    }

    const handlePageUpdate = (
        page,
        selectedQuestion,
        answerComponent,
        categoryId,
    ) => {
        if (page === 'chatForm') {
            setShowChatIcon(false)
        } else {
            setShowChatIcon(true)
        }

        switch (page) {
            case 'questionList':
                setActivePage(<HelpSection categoryId={categoryId} />)
                break
            case 'chatForm':
                setActivePage(
                    <ConsumerQuestion
                        close={onClose}
                        handleBackToHelp={() => backToQuestionList(0)}
                    />,
                )
                break
            case 'answerPage':
                setActivePage(
                    <AnswerPage
                        selectedQuestion={selectedQuestion}
                        answerComponent={answerComponent}
                        categoryId={categoryId}
                    />,
                )
                break
            default:
                setActivePage(<HelpSection />)
        }
    }

    const handleAskQuestion = () => {
        handlePageUpdate('chatForm')
    }

    useEffect(() => {
        handlePageUpdate('questionList')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="ConsumerDrawer">
            <Drawer visible={visible} onClose={onClose} width="367px">
                {activePage}

                {showChatIcon && (
                    <FloatingBox
                        floatIcon={ChatIcon}
                        floatBgColor="var(--blue)"
                        floatBoxClass="ConsumerFloatBoxClass"
                        onClick={handleAskQuestion}
                    />
                )}
            </Drawer>
        </div>
    )
}

ConsumerHelpCenter.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
}

export default ConsumerHelpCenter
