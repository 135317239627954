/* eslint-disable react/prop-types */
import styles from './carouselline.module.scss'

const CarouselLine = ({ total, stage = 1, customMargin }) => {
    const { InActiveLine, ActiveLine } = styles

    return (
        <div>
            <div
                className={''}
                style={{
                    display: 'flex',
                    textAlign: 'center',
                    margin: customMargin ? customMargin : '10px 37%',
                }}
            >
                {[...Array(total).keys()].map((value, i) => (
                    <div
                        key={i}
                        className={
                            value + 1 === stage ? ActiveLine : InActiveLine
                        }
                    ></div>
                ))}
            </div>
        </div>
    )
}

export default CarouselLine
