import PropTypes from 'prop-types'
import '../viewsystem.scss'
import { ConsumerButton } from 'src/components/Button'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { isMobileOrTablet } from 'src/utils/mediaQueries'
import NairaSymbol from 'src/components/NairaSymbol'
import SystemNavSkeleton from './SystemNavSkeleton'
import { useEffect, useState } from 'react'

const SystemNav = ({
    handleGetStarted,
    viewingFromAccountSetup,
    viewingFromOutrightSaleKYCForm,
    systemDetails,
    isLoading,
    pageLoading,
    isPromoPage,
    repaymentPlans,
    promoType,
    planType,
    viewingFromOnePageKYC,
    consumerCurrentSolution,
    viewingFromWorkspace,
}) => {
    const [primaryPackageImage, setPrimaryPackageImage] = useState('')
    const promoImages = {
        dlight: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Dlight+Thumbnail.png',
        surechill:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Surechill+Thumbnail.png',
        renergy:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Renergy+Thumbnail.png',
    }

    const showPackageImagesCarousel =
        process.env.REACT_APP_FLAG_SHOW_PACKAGE_IMAGE_UPLOAD === 'true'

    useEffect(() => {
        const image = systemDetails?.image?.length
            ? systemDetails?.image?.[0]
            : systemDetails?.has_solar_panel
            ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+Banner.png'
            : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Without+panels+Banner.png'
        setPrimaryPackageImage(image)
    }, [systemDetails])

    return (
        <>
            {pageLoading ? (
                <SystemNavSkeleton />
            ) : (
                <div className={'ViewSystemNav'}>
                    <div className={'ViewSystemNavLeft'}>
                        <img
                            src={
                                showPackageImagesCarousel
                                    ? primaryPackageImage
                                    : isPromoPage && promoType !== undefined
                                    ? promoImages[promoType]
                                    : systemDetails?.has_solar_panel
                                    ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+thumbnail.png'
                                    : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Without+panels+thumbnail.png'
                            }
                            alt="image of the system"
                            data-testid="system-nav-image"
                        />
                        <div className={'ViewSystemNavTextSection'}>
                            <h4>{systemDetails?.name}</h4>
                            <h1>
                                {systemDetails?.energy_capacity?.value +
                                    systemDetails?.energy_capacity
                                        ?.unit_of_measurement}{' '}
                                system
                            </h1>
                        </div>
                    </div>
                    <div className={'ViewSystemNavRight'}>
                        <div>
                            <div className={'ViewSystemNavTextSection'}>
                                {planType === 'lease to own' ? (
                                    <span>Min. amount payable per month</span>
                                ) : planType === 'outright sale' ? (
                                    <span>One-time payment of</span>
                                ) : (
                                    <span>Amount payable per month</span>
                                )}
                                <h2>
                                    <NairaSymbol />
                                    {planType === 'outright sale'
                                        ? currencyFormatter(
                                              systemDetails?.total_cost,
                                          )
                                        : currencyFormatter(
                                              isPromoPage
                                                  ? repaymentPlans?.[
                                                        repaymentPlans?.length -
                                                            1
                                                    ]?.monthly_payment
                                                  : systemDetails
                                                        ?.payment_plans?.[
                                                        systemDetails
                                                            ?.payment_plans
                                                            ?.length - 1
                                                    ]?.monthly_payment,
                                          )}
                                </h2>
                            </div>
                            {planType === 'lease to own' && (
                                <div className={'ViewSystemNavTextSection'}>
                                    <span>Highest possible plan</span>
                                    <h2>{`${
                                        isPromoPage
                                            ? repaymentPlans?.[
                                                  repaymentPlans?.length - 1
                                              ]?.tenure
                                            : systemDetails?.payment_plans?.[
                                                  systemDetails?.payment_plans
                                                      ?.length - 1
                                              ]?.tenure
                                    } months`}</h2>
                                </div>
                            )}
                        </div>
                        <ConsumerButton
                            btnBgColor={'#FFFFFF'}
                            btnTextColor={'#004AAD'}
                            btnWidth={'160px'}
                            btnHeight={'53px'}
                            handleClick={handleGetStarted}
                            loading={isLoading}
                            role={'cta'}
                            marginTop={isMobileOrTablet ? '20px' : '0px'}
                        >
                            {(viewingFromAccountSetup ||
                                viewingFromOutrightSaleKYCForm ||
                                viewingFromOnePageKYC) &&
                            consumerCurrentSolution
                                ? viewingFromWorkspace
                                    ? 'Back to Profile'
                                    : 'Continue with KYC'
                                : 'Get this system'}
                        </ConsumerButton>
                    </div>
                </div>
            )}
        </>
    )
}

SystemNav.propTypes = {
    handleGetStarted: PropTypes.func,
    viewingFromAccountSetup: PropTypes.bool,
    viewingFromOutrightSaleKYCForm: PropTypes.bool,
    systemDetails: PropTypes.object,
    isLoading: PropTypes.bool,
    pageLoading: PropTypes.bool,
    isPromoPage: PropTypes.bool,
    repaymentPlans: PropTypes.array,
    promoType: PropTypes.string,
    planType: PropTypes.string,
    viewingFromOnePageKYC: PropTypes.bool,
    consumerCurrentSolution: PropTypes.bool,
    viewingFromWorkspace: PropTypes.bool,
}

export default SystemNav
