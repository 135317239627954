export const pensionManagers = [
    'Access Pensions Limited',
    'ARM Pension Managers Limited',
    'Crusader Sterling Pensions Limited',
    'FCMB Pensions Limited',
    'Fidelity Pension Managers Limited',
    'Guaranty Trust Pension Managers Ltd',
    'Leadway Pensure PFA Limited',
    'Nigerian University Pension Management Company (NUPEMCO)',
    'NLPC Pension Fund Administrators Limited',
    'Norrenberger Pensions Limited',
    'NPF Pensions Fund Administrators Limited',
    'OAK Pensions Limited',
    'Pensions Alliance Limited',
    'Premium Pension Limited',
    'Radix Pension Managers Limited',
    'Stanbic IBTC Pension Managers Limited',
    'Tangerine APT Pensions Limited',
    'Trustfund Pensions Limited',
    'Veritas Glanvills Pensions Limited',
]
