import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import circleLeftBlue from 'src/assets/images/circle-left-pri-blue.svg'
import styles from '../PromoListings/promolistings.module.scss'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const CSSEPromoListingsHeader = ({ page, title, subTitle }) => {
    const {
        cssePromoHeaderWrapper,
        cssePromoCarouselDiv,
        cssePromoBackBtnStyle,
        SolarRoofImgStyle,
    } = styles
    const { isMobile } = useMediaQueries()

    const multiplePackagePage = page === 'multiple-package'
    const history = useHistory()

    return (
        <section
            className={cssePromoHeaderWrapper}
            style={{ background: multiplePackagePage && '#FFFFFF' }}
        >
            <div>
                <div className={cssePromoCarouselDiv}>
                    {multiplePackagePage && (
                        <div className={cssePromoBackBtnStyle}>
                            <div
                                onClick={() => {
                                    history.push('/consumer/promos')
                                }}
                            >
                                <img
                                    src={circleLeftBlue}
                                    alt="circle-left-blue"
                                    role={'presentation'}
                                />
                                <span>Go back</span>
                            </div>
                        </div>
                    )}
                    <h3 className="cssePromoCarouselHeader">{title}</h3>
                    <p className="cssePromoCarouselParagraph">{subTitle}</p>
                </div>
                {!multiplePackagePage && !isMobile && (
                    <img
                        src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/solar-roof.png"
                        className={SolarRoofImgStyle}
                        alt="solar roof image"
                    />
                )}
            </div>
        </section>
    )
}

CSSEPromoListingsHeader.propTypes = {
    page: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
}

export default CSSEPromoListingsHeader
