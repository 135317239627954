import PropTypes from 'prop-types'
// import SendIcon from '../../assets/images/sendIcon.svg'
import styles from './draftedschedule.module.scss'

const DraftedSchedule = ({
    closeModal,
    data,
    markupValue,
    planType,
    amortizationMonth,
}) => {
    const {
        ScheduleWrapper,
        MobileWrapper,
        DSBtnWrapper,
        DSButton,
        DSSection,
        DSdiv,
        InterestStyle,
        MonthStyle,
        TopItemStyle,
        BottomItemStyle,
    } = styles

    return (
        <div className={ScheduleWrapper}>
            <div className={MobileWrapper}>
                <section
                    className={DSSection}
                    style={{
                        marginBottom:
                            planType !== 'Subscription' ? '0' : '20px',
                    }}
                >
                    {planType !== 'Subscription' && (
                        <div className={DSdiv}>
                            <p className={InterestStyle}>
                                {data?.investment_meta_data?.monthly_interest}{' '}
                                RATE
                            </p>
                            <p className={MonthStyle}>{data?.tenure} Months</p>
                        </div>
                    )}
                    {/* <div className={DSdiv} style={{ marginTop: '15px' }}>
                        <a>View Drafted Schedule</a>
                        <img
                            src={SendIcon}
                            style={{ marginBottom: '4px' }}
                            alt="An icon to open drafted schedule in another page"
                        />
                    </div> */}
                </section>
                <h2>Investment Metrics</h2>
                <section className={DSSection}>
                    <div className={DSdiv}>
                        <p className={TopItemStyle}>
                            {planType !== 'Subscription'
                                ? 'IRR'
                                : 'AMORTIZATION MONTH'}
                        </p>
                        <p className={BottomItemStyle}>
                            {planType !== 'Subscription'
                                ? data?.investment_meta_data?.irr_cashflow
                                : amortizationMonth}
                        </p>
                    </div>
                    <div className={DSdiv}>
                        <p className={TopItemStyle}>
                            {planType !== 'Subscription'
                                ? 'XIRR'
                                : 'FINANCE CHARGE'}
                        </p>
                        <p className={BottomItemStyle}>
                            {planType !== 'Subscription'
                                ? data?.investment_meta_data?.xirr_cashflow
                                : data?.investment_meta_data
                                      ?.annual_roi_fin_charge}
                        </p>
                    </div>
                </section>
                <section className={DSSection}>
                    <div className={DSdiv}>
                        <p className={TopItemStyle}>
                            {planType !== 'Subscription'
                                ? 'MIRR'
                                : 'MARKUP VALUE'}
                        </p>
                        <p className={BottomItemStyle}>
                            {planType !== 'Subscription'
                                ? data?.investment_meta_data?.mirr_cashflow
                                : markupValue}
                        </p>
                    </div>
                    {planType !== 'Subscription' && (
                        <div className={DSdiv}>
                            <p className={TopItemStyle}>
                                Actual Annualize Rate
                            </p>
                            <p className={BottomItemStyle}>
                                {data?.investment_meta_data?.actual_ann_lzd}
                            </p>
                        </div>
                    )}
                </section>

                <div
                    className={DSBtnWrapper}
                    style={{
                        marginTop: planType !== 'Subscription' ? '0' : '50px',
                    }}
                >
                    <button className={DSButton} onClick={closeModal}>
                        Okay, Got it
                    </button>
                </div>
            </div>
        </div>
    )
}

DraftedSchedule.propTypes = {
    closeModal: PropTypes.func.isRequired,
    data: PropTypes.object,
    markupValue: PropTypes.number,
    planType: PropTypes.string,
    amortizationMonth: PropTypes.string,
    financeCharge: PropTypes.string,
}

export default DraftedSchedule
