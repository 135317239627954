export const packagesTableHead = [
    { key: '', title: '' },
    { key: 'name', title: 'Package Name', isBold: true },
    { key: 'date', title: 'Date of Creation' },
    { key: 'components', title: 'Components' },
    { key: 'amount', title: 'Total Amount' },
    { key: 'actions', title: 'Actions' },
]

export const componentsTableHead = [
    { key: '', title: '' },
    { key: 'name', title: 'Component Name', isBold: true },
    { key: 'componentType', title: 'Component Type' },
    { key: 'attributes', title: 'Attributes' },
    { key: 'amount', title: 'Total Amount' },
    { key: 'actions', title: 'Actions' },
]
