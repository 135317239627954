export const remarkOptions = ['Technical Fit', 'Budget Fit', 'Economy Fit']

export const remarkMapping = {
    TECHNICAL_FIT: 'Technical Fit',
    BUDGET_FIT: 'Budget Fit',
    ECONOMY_FIT: 'Economy Fit',
}

export const remarkMappingReverse = {
    'Technical Fit': 'TECHNICAL_FIT',
    'Budget Fit': 'BUDGET_FIT',
    'Economy Fit': 'ECONOMY_FIT',
}

export function transformRemarkValue(value, shouldHaveUnderscore) {
    const hasUnderscore = value?.includes('_')
    if (shouldHaveUnderscore && hasUnderscore) {
        return value
    }
    if (!shouldHaveUnderscore && hasUnderscore) {
        return remarkMapping[value] || value
    }
    if (shouldHaveUnderscore && !hasUnderscore) {
        return remarkMappingReverse[value] || value
    }
    return value
}
