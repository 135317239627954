import { ReactComponent as Users } from 'src/assets/images/users-no-stroke.svg'
import { ReactComponent as User } from 'src/assets/images/user-circled-no-stroke.svg'
import { ReactComponent as Contact } from 'src/assets/images/contact-no-stroke.svg'
export const actionOptions = [
    {
        title: 'Submit your data',
        description:
            'A provider has created a payment plan for you? Get started here.',
        key: 'submit-data',
        icon: <Users />,
    },
    {
        title: 'Continue your application',
        description: 'Already started your application and want to continue?',
        key: 'continue-application',
        icon: <User />,
    },
    {
        title: 'Get into your portal',
        description:
            'Already submitted your application and done filling your KYC?',
        key: 'consumer-workspace',
        icon: <Contact />,
    },
]
