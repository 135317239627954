import axios from '../../../config/axios'

export const sseRetrieveConsumerEstimation = async () => {
    const request = axios.get('consumers/estimations')
    return request
}

export const sseRetrieveConsumerEstimationSummary = async () => {
    const request = axios.get('consumers/estimations/summary')
    return request
}

export const sseRepaymentTenure = async ({ estimation_id, tenure }) => {
    const request = axios.post(
        `consumers/estimations/${estimation_id}/select-tenure`,
        {
            tenure: tenure,
        },
    )
    return request
}

export const sseRetriveRepaymentSetupTypeApi = async estimation_id => {
    const request = await axios.get(
        `payments/${estimation_id}/repayment-method-setup/type`,
    )
    return request
}
