import PropTypes from 'prop-types'
import { savingsChartData } from '../data'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import NairaSymbol from 'src/components/NairaSymbol'
import { parseHTML } from 'src/utils/parseHTML'

const SavingsChart = ({ genCost, solarCost, isMonthlyCostChart }) => {
    const savings = Math.abs(genCost - solarCost)
    // const savingsText =
    // ? 'Est. monthly savings'
    // : 'Your gen cost to solar cost'
    const savingsText = isMonthlyCostChart
        ? 'Est. monthly savings for year 1'
        : 'Est. savings over 60 months'

    const diffHeight = Math.abs(
        savingsChartData(genCost, solarCost, isMonthlyCostChart)[1].height -
            savingsChartData(genCost, solarCost, isMonthlyCostChart)[0].height,
    )
    return (
        <div className={'ViewSystemChart'}>
            {savingsChartData(genCost, solarCost, isMonthlyCostChart)?.map(
                (each, i) => (
                    <div key={i} className={`ViewSystemChartItem `}>
                        <div className={'ViewSystemChartItemText'}>
                            <h4>
                                <NairaSymbol />
                                {each?.cost?.toLocaleString('en-US')}
                            </h4>
                            <span>{parseHTML(each?.label)}</span>
                        </div>
                        <div
                            className={`ViewSystemChartItemLine ${
                                i === 0 ? 'first-line' : 'second-line'
                            }`}
                        ></div>
                        <div
                            className={`ViewSystemChartBar ${each?.className}`}
                            style={{
                                background: each?.color,
                                height: each?.height,
                            }}
                        ></div>
                    </div>
                ),
            )}
            <div className={'ViewSystemChartDifference'}>
                <div
                    className={'ViewSystemChartDifferenceMain'}
                    style={{
                        height: diffHeight,
                        marginBottom: genCost > solarCost ? -diffHeight : 0,
                    }}
                >
                    <div className={'ViewSystemChartDifferenceLine'}>
                        <UpOutlined
                            style={{
                                color: '#B0B0B0',
                                marginBottom: -9,
                            }}
                        />
                        <div></div>
                        <DownOutlined
                            style={{
                                color: '#B0B0B0',
                                marginTop: -9,
                            }}
                        />
                    </div>
                    <div className={'ViewSystemChartDifferenceContent'}>
                        <h4>
                            <NairaSymbol />
                            {savings.toLocaleString('en-US')}
                        </h4>
                        <p>{savingsText}</p>
                    </div>
                </div>
                <div
                    style={{
                        height: savingsChartData(
                            genCost,
                            solarCost,
                            isMonthlyCostChart,
                        )[0].height,
                    }}
                ></div>
            </div>
        </div>
    )
}

SavingsChart.propTypes = {
    genCost: PropTypes.number,
    solarCost: PropTypes.number,
    isMonthlyCostChart: PropTypes.bool,
}
export default SavingsChart
