import { Tabs } from 'antd'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ASIPersonalInfo from './ASIPersonalInfo'
import ASISecurity from './ASISecurity'
import BankDetails from '../Profile/BankDetails'
import { decodeUserInfo } from '../../utils/auth'

import './asiprofile.scss'
import ComponentBrands from '../Profile/ComponentBrands'

const ASIProfile = ({ parsed }) => {
    const [currentTab, setCurrentTab] = useState('1')
    const { TabPane } = Tabs
    const userInfo = decodeUserInfo()

    const canUseComponentBrands =
        process.env.REACT_APP_FLAG_SHOW_COMPONENT_BRANDS === 'true'

    useEffect(() => {
        if (Object.keys(parsed).length > 0) {
            switch (parsed.tab) {
                case 'component-brands':
                    setCurrentTab('2')
                    break
            }
        }
    }, [parsed])

    return (
        <div className="ASIProfileWrapper">
            <h1 className="ASIProfileTitle">My Profile</h1>
            <Tabs
                defaultActiveKey="1"
                className="ASIProfileTab"
                activeKey={currentTab}
                onChange={values => {
                    setCurrentTab(values)
                }}
            >
                <TabPane tab="Personal Info" key="1">
                    <ASIPersonalInfo />
                </TabPane>
                {canUseComponentBrands && (
                    <TabPane tab={'Component Brands'} key={'2'}>
                        <ComponentBrands />
                    </TabPane>
                )}
                {userInfo?.workspaceMember !== 'true' &&
                    userInfo?.isAdminWorkspaceUser !== 'true' && (
                        <TabPane
                            tab="Bank Details"
                            key={canUseComponentBrands ? '3' : '2'}
                        >
                            <BankDetails />
                        </TabPane>
                    )}
                <TabPane
                    tab="Security"
                    key={
                        canUseComponentBrands
                            ? userInfo?.workspaceMember !== 'true' &&
                              userInfo?.isAdminWorkspaceUser !== 'true'
                                ? `4`
                                : `3`
                            : userInfo?.workspaceMember !== 'true' &&
                              userInfo?.isAdminWorkspaceUser !== 'true'
                            ? `3`
                            : `2`
                    }
                >
                    <ASISecurity />
                </TabPane>
            </Tabs>
        </div>
    )
}

ASIProfile.propTypes = {
    parsed: PropTypes.object,
}

export default ASIProfile
