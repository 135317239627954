import axios from '../../config/axios'

export const getOverviewApi = async () => {
  const request = await axios.get('/products/overview')
  return request
}

export const getUserActivitiesApi = async () => {
  const request = await axios.get('/users/activities')
  return request
}

export const getEstimationsAnalytics = async () => {
  const request = await axios.get('/estimations/analytics')
  return request
}
