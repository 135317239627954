import axios from '../../../config/axios'

export const getSingleCreditVariable = async id => {
    const request = axios.get(`/credit/credit-variables/${id}`)
    return request
}

export const updateSingleCreditVariable = async (id, inputs) => {
    const request = axios.patch(`/credit/credit-variables/${id}/update`, inputs)
    return request
}

export const createCreditVariable = async inputs => {
    if (inputs?.cluster_info?.name) {
        delete inputs.provider_id
    }
    const request = axios.post(`/credit/credit-variables`, inputs)
    return request
}

export const getPackages = async (
    pageNumber,
    searchText = '',
    providerId = '',
    clusterPackagesCode = '',
) => {
    const request = axios.get(
        `/admin/packages?page=${pageNumber}&name=${searchText}&provider_id=${providerId}&cluster_packages_code=${clusterPackagesCode}`,
    )
    return request
}

export const adminSimulatePaymentPlan = async inputs => {
    const request = await axios.post(`/admin/packages/compare`, inputs)
    return request
}

export const deleteCreditVariable = async id => {
    const request = await axios.delete(`/credit/credit-variables/${id}`)
    return request
}

export const getAddedClusterPackagesApi = async (
    page,
    clusterCode,
    isAdded,
    searchText,
) => {
    const request = await axios.get(
        `/credit/credit-variables/${clusterCode}/cluster-packages?page=${page}&package_name=${searchText}&is_added=${isAdded}`,
    )
    return request
}

export const getAddedProviderClusterPackagesApi = async (
    page,
    clusterCode,
    isAdded,
    searchText,
) => {
    const request = await axios.get(
        `/credit/credit-variables/cluster/${clusterCode}/providers?page=${page}&provider_name=${searchText}&is_added=${isAdded}`,
    )
    return request
}

export const removeClusterPackagesApi = async inputs => {
    const request = await axios.post(
        `/credit/credit-variables/cluster-packages`,
        inputs,
    )
    return request
}

export const removeAddProviderClusterPackagesApi = async (
    clusterCode,
    inputs,
) => {
    const request = await axios.post(
        `/credit/credit-variables/cluster/${clusterCode}/providers`,
        inputs,
    )
    return request
}
