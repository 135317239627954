export const alternativeRecomActionModalValues = action => {
    switch (action) {
        case 'initiate-sending-recommendations':
            return {
                headerText:
                    'Confirm you want to send these recommendations to the consumer',
                subTitle:
                    'The consumer will receive an email of these new recommendations',
                actionText: 'Yes, confirm',
                actionType: 'warning',
            }
        case 'recommendations-sent':
            return {
                headerText: 'New recommendation sent',
                subTitle:
                    'You have successfully sent new recommendations and this consumer will be notified',
                actionText: 'Okay, Got it',
                actionType: 'success',
            }
        default:
            return {
                headerText: '',
                subTitle: '',
                actionText: '',
                actionType: '',
            }
    }
}

export const determinePaymentModelString = responseData => {
    let hasLeaseToOwn = false
    let hasSubscription = false

    responseData?.forEach(item => {
        if (item.payment_model === 'Lease to Own') {
            hasLeaseToOwn = true
        } else if (item.payment_model === 'Subscription') {
            hasSubscription = true
        }
    })

    if (hasLeaseToOwn) {
        return 'Lease to Own'
    } else if (hasSubscription) {
        return 'Subscription'
    }
    return 'No Valid'
}
