export const stepsMap = [
    {
        title: 'Fill Out the "Get Offer" Form',
        description:
            'To receive a tailored offer, complete our "Get Offer" form and share your information and energy requirements.',
    },
    {
        title: 'Review Our Recommended Solutions',
        description: `We'll provide you with recommended solutions from our trusted provider network. Our team will work with your shared information to recommend the best solar options for your needs and budget.`,
    },
    {
        title: 'Choose a Payment Plan',
        description: `Select a payment plan that works for you, with options including
        lease-to-own and subscriptions.`,
    },
    {
        title: 'Swift and Efficient Execution',
        description: `Our team will promptly schedule a site assessment and installation 
        with one of our certified installers.`,
    },
]

export const videoMap = [
    {
        link: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/how-it-works-get-offer.webm',
        fallback:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/get_offer.mp4',
    },
    {
        link: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/how-it-works-review-recommendation.webm',
        fallback:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/recommended_solutions.mp4',
    },
    {
        link: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/how-it-works-payment-plan.webm',
        fallback:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/payment_plans.mp4',
    },
    {
        link: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/how-it-works-swift-efficient-execution.webm',
        fallback:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/swift_efficient_execution.mp4',
    },
]
