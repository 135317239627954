import PropTypes from 'prop-types'
import Button from 'src/components/Button'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { InlineLoader } from 'src/components/Loader'
import { usePageForm } from './hooks/usePageForm'
import { useGetInverterCapacities } from '../../hooks/useGetInverterCapacities'
import styles from './pageform.module.scss'
import StepOne from './components/StepOne'
import StepTwo from './components/StepTwo'

const PageForm = ({
    profileData,
    isEdit,
    isLoading,
    handleSubmit,
    setProfileData,
    setIsEdit,
    pendingUpdates,
    setPendingUpdates,
    initialProfileData,
}) => {
    const {
        errors,
        setErrors,
        handleSetAppliances,
        handleInputChange,
        handleSelectChange,
        handleEditorChange,
        handleNext,
        availableCapacities,
        setAvailableCapacities,
        charactersLeft,
        step,
        uploadToS3Loading,
        uploadToS3Mutate,
        isPromotional,
        editKyc,
    } = usePageForm({
        profileData,
        isEdit,
        handleSubmit,
        setProfileData,
        setIsEdit,
        initialProfileData,
        setPendingUpdates,
    })

    const { isMobile } = useMediaQueries()
    useGetInverterCapacities({ setAvailableCapacities })

    return (
        <div className={styles.Container}>
            <section className={styles.FormSection}>
                <h4>Enter Consumer Profile Information</h4>
            </section>
            {step === 1 ? (
                <StepOne
                    errors={errors}
                    profileData={profileData}
                    handleSetAppliances={handleSetAppliances}
                    handleInputChange={handleInputChange}
                    handleSelectChange={handleSelectChange}
                    handleEditorChange={handleEditorChange}
                    availableCapacities={availableCapacities}
                    charactersLeft={charactersLeft}
                />
            ) : step === 2 ? (
                <StepTwo
                    errors={errors}
                    profileData={profileData}
                    handleSelectChange={handleSelectChange}
                    uploadToS3Loading={uploadToS3Loading}
                    uploadToS3Mutate={uploadToS3Mutate}
                    setErrors={setErrors}
                />
            ) : null}

            <section
                className={styles.FormSection}
                style={{
                    flexDirection: isPromotional ? 'row' : '',
                    gap: isPromotional ? '16px' : '',
                }}
            >
                <Button
                    btnTextColor={
                        isPromotional ? 'var(--blue)' : 'var(--white)'
                    }
                    btnBgColor={isPromotional ? '#e2eeff' : 'var(--blue)'}
                    btnWidth={isMobile ? '162px' : '132px'}
                    handleClick={handleNext}
                    disabled={
                        isEdit &&
                        !Object.keys(pendingUpdates).length &&
                        (isPromotional || step === 2)
                    }
                >
                    {isEdit && (isPromotional || step === 2) ? (
                        isLoading ? (
                            <InlineLoader />
                        ) : (
                            'Save'
                        )
                    ) : (
                        'Continue'
                    )}
                </Button>
                {isPromotional && (
                    <Button
                        btnTextColor="var(--white)"
                        btnBgColor="var(--blue)"
                        btnWidth={isMobile ? '162px' : '132px'}
                        handleClick={editKyc}
                    >
                        Edit KYC
                    </Button>
                )}
            </section>
        </div>
    )
}

PageForm.propTypes = {
    profileData: PropTypes.object,
    isEdit: PropTypes.bool,
    isLoading: PropTypes.bool,
    handleSubmit: PropTypes.func,
    setProfileData: PropTypes.func,
    setPendingUpdates: PropTypes.func,
    setIsEdit: PropTypes.func,
    pendingUpdates: PropTypes.object,
    initialProfileData: PropTypes.object,
}

export default PageForm
