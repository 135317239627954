import PropTypes from 'prop-types'
import styles from './approvedbrandscard.module.scss'

const ApprovedBrandsCard = ({ componentType, componentBrands }) => {
    return (
        <div className={styles.Card}>
            <div className={styles.CardHeader}>
                <h3>{componentType?.name || ''}</h3>
                {!!componentBrands?.length && (
                    <div className={styles.ApprovedBrandsRight}>
                        <span>{componentBrands?.length || ''}</span>
                        <p>Brands added</p>
                    </div>
                )}
            </div>
            <div className={styles.ApprovedBrandsCardBody}>
                {componentBrands?.map((componentBrand, index) => (
                    <div key={index} className={styles.BrandTag}>
                        <p>{componentBrand?.name || ''}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

ApprovedBrandsCard.propTypes = {
    componentType: PropTypes.object,
    brandNames: PropTypes.array,
    componentBrands: PropTypes.array,
}

export default ApprovedBrandsCard
