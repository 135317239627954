export const categoryMap = {
    1: 'creditReport',
    2: 'upfrontDeposit',
    3: 'technoEconomicFit',
    4: 'bankStatement',
    5: 'bankStatement',
}

export const creditReportScoreData = ['First central', 'CRC']

export const creditReportScoreMapping = {
    'First central': 'FIRST_CENTRAL',
    CRC: 'CRC',
}

export const simulationData = [
    'Credit decision is Yes',
    'Credit decision is Yes With Condition',
    'Credit decision is Maybe',
    'Credit decision is No',
    'Credit decision is Undecided due to errors',
]

export const simulationDataMapping = {
    'Credit decision is Yes': 'YES',
    'Credit decision is Yes With Condition': 'YES_WITH_CONDITION',
    'Credit decision is Maybe': 'MAYBE',
    'Credit decision is No': 'NO',
    'Credit decision is Undecided due to errors': 'UNDECIDED',
}

export const accountSweepData = ['Yes', 'No']
export const accountTypeData = ['Salary account', 'Not a salary account']

export const isCategoryDisabled = (
    accountName,
    inputs,
    categoryId,
    customerType,
    successUpload,
) => {
    switch (categoryId) {
        case 1:
            return (
                inputs.creditReportScore === '' ||
                inputs.creditReportSource === ''
            )
        case 2:
            return inputs.upfrontDeposit === ''
        case 3:
            return inputs.generatorSpend === '' || inputs.solarSpend === ''
        case 4:
            return inputs.avgRecurringExpense === '' ||
                inputs.loanRepayment === '' ||
                inputs.avgMonthlyBalance === '' ||
                inputs.avgOtherIncome === '' ||
                inputs.closingBalance === '' ||
                inputs.avgCredit === '' ||
                inputs.avgDebit === '' ||
                inputs.accountSweep === '' ||
                inputs.gamblingRate === '' ||
                inputs.accountType === accountTypeData[0]
                ? inputs.avgSalary === ''
                : inputs.avgLoanAmount === ''

        case 5:
            return (
                inputs.bankDetails === '' ||
                inputs.accountNumber === '' ||
                accountName === '' ||
                successUpload.length === 0
            )
        default:
            return false
    }
}

export const disputeCreditDecisionModalData = [
    {
        id: 1,
        title: 'Dispute the credit engine decision',
        subTitle: 'Enter new details and compare the results',
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
            >
                <path
                    d="M11.5 19.167H20.125"
                    stroke="#004AAD"
                    strokeWidth="2.16667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.8125 3.35422C16.1937 2.97297 16.7108 2.75879 17.25 2.75879C17.517 2.75879 17.7813 2.81137 18.028 2.91354C18.2746 3.0157 18.4987 3.16545 18.6875 3.35422C18.8763 3.543 19.026 3.7671 19.1282 4.01375C19.2303 4.2604 19.2829 4.52475 19.2829 4.79172C19.2829 5.05869 19.2303 5.32304 19.1282 5.56969C19.026 5.81634 18.8763 6.04045 18.6875 6.22922L6.70833 18.2084L2.875 19.1667L3.83333 15.3334L15.8125 3.35422Z"
                    stroke="#004AAD"
                    strokeWidth="2.16667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    },
    {
        id: 2,
        title: 'Manually dispute by providing documents',
        subTitle: 'Upload some documents to dispute manually',
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="22"
                viewBox="0 0 19 22"
                fill="none"
            >
                <path
                    d="M11.4 1.33301H3.13338C2.58526 1.33301 2.0596 1.55075 1.67202 1.93832C1.28445 2.3259 1.06671 2.85156 1.06671 3.39967V19.933C1.06671 20.4811 1.28445 21.0068 1.67202 21.3944C2.0596 21.7819 2.58526 21.9997 3.13338 21.9997H15.5334C16.0815 21.9997 16.6072 21.7819 16.9947 21.3944C17.3823 21.0068 17.6 20.4811 17.6 19.933V7.53301L11.4 1.33301Z"
                    stroke="#004AAD"
                    strokeWidth="2.06667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.4012 1.33301V7.53301H17.6012"
                    stroke="#004AAD"
                    strokeWidth="2.06667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.4679 12.7002H5.20123"
                    stroke="#004AAD"
                    strokeWidth="2.06667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.4679 16.833H5.20123"
                    stroke="#004AAD"
                    strokeWidth="2.06667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.2679 8.56641H6.23457H5.20123"
                    stroke="#004AAD"
                    strokeWidth="2.06667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    },
]

export const documentType = [
    'Credit report',
    'Bank statement',
    'Audited Financial Report',
    'Non Audited Financial Report',
    'Others',
]

export const documentUploadMapping = {
    'Bank statement': 'BANK_STATEMENT',
    'Credit report': 'FINANCIAL_STATEMENT_ANALYSIS',
    'Audited Financial Report': 'FINANCIAL_STATEMENT_ANALYSIS',
    'Non Audited Financial Report': 'FINANCIAL_STATEMENT_ANALYSIS',
    Others: 'FINANCIAL_STATEMENT_ANALYSIS',
}

export const documentTypeMapping = {
    'Bank statement': 'BANK_STATEMENT',
    'Credit report': 'CREDIT_REPORT',
    'Audited Financial Report': 'AUDITED_FINANCIAL_REPORT',
    'Non Audited Financial Report': 'FINANCIAL_REPORT',
    Others: 'OTHERS',
}

export const decisionData = ['Approve', 'Decline']

export const decisionDataMapping = {
    Approve: 'APPROVE',
    Decline: 'DECLINE',
}

export const titleData = {
    0: 'Select the type of document and upload ',
    1: 'Enter the tenure information',
    2: 'Enter reason for your decision',
}

export const subtitleData = {
    0: 'Select the type of document you want upload and go ahead to upload',
    1: 'Enter the decision and tenure for the payment plan',
    2: 'Add a note before disputing the result',
}

export const reviewTypeData = {
    MANUAL: 'Manual Review',
    AUTOMATED: 'Automated Review',
}

export const documentTypeReMapping = {
    BANK_STATEMENT: 'Bank statement',
    CREDIT_REPORT: 'Credit report',
    AUDITED_FINANCIAL_REPORT: 'Audited Financial Report',
    FINANCIAL_REPORT: 'Non Audited Financial Report',
    OTHERS: 'Others',
}
