import React from 'react'
import styles from './learncontent.module.scss'
import {
    contentSixBulletList,
    contentSevenBulletList,
    contentEightBulletList,
    contentTwelveBulletList,
    contentTwelveBulletListOne,
    contentThirteenBulletList,
} from './BulletList'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { LEARN_ROUTES } from 'src/routes/pathnames'
const {
    BulletWrapper,
    BulletTitle,
    BulletText,
    BulletTitleWrapper,
    ContentParagraphs,
    Number,
    ContainsOrdered,
    BulletArticleHeader,
    BulletArticleSubText,
    ArticleListImage,
    DesktopImageView,
    MobileImageView,
} = styles

const bulletListMapping = {
    6: contentSixBulletList,
    7: contentSevenBulletList,
    8: contentEightBulletList,
    12: contentTwelveBulletList,
    12.1: contentTwelveBulletListOne,
    13: contentThirteenBulletList,
}

const BulletedArticle = ({ id, listType, step, header = '', subtext = '' }) => (
    <>
        <div className={BulletWrapper}>
            {header && <h4 className={BulletArticleHeader}>{header}</h4>}
            {subtext && <p className={BulletArticleSubText}>{subtext}</p>}
            {bulletListMapping[id]?.map((list, key) => (
                <React.Fragment key={key}>
                    <div
                        className={`${BulletTitleWrapper} ${
                            listType === 'ordered' ? ContainsOrdered : ''
                        }`}
                    >
                        {listType === 'ordered' ? (
                            <span className={`${BulletTitle} ${Number}`}>
                                {step ? `Step ${key + 1}:` : `${key + 1}.`}
                            </span>
                        ) : listType === 'unordered' ? (
                            <div style={{ marginBottom: 10 }}>
                                <svg
                                    width="6"
                                    height="6"
                                    viewBox="0 0 6 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginRight: '15px' }}
                                >
                                    <circle
                                        cx="3"
                                        cy="3"
                                        r="3"
                                        fill="#011A3C"
                                        fillOpacity="0.8"
                                    />
                                </svg>
                            </div>
                        ) : (
                            <></>
                        )}
                        <h2 className={BulletTitle}>{list.title}</h2>
                    </div>
                    {(list.imageUrl || list.thumbnailUrl) && (
                        <div className={ArticleListImage}>
                            <picture className={MobileImageView}>
                                <img src={list.thumbnailUrl} alt="content" />
                            </picture>
                            <picture className={DesktopImageView}>
                                <img
                                    className={DesktopImageView}
                                    src={list.imageUrl}
                                    alt="content"
                                />
                            </picture>
                        </div>
                    )}
                    <p className={BulletText}>{parse(list.text)}</p>
                </React.Fragment>
            ))}
        </div>
        {id === 6 && (
            <p className={ContentParagraphs}>
                Ready to make the switch from generators to solar? Click{' '}
                <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.sunfi.co/"
                >
                    here
                </a>{' '}
                to get started.
            </p>
        )}
        {id === 7 && (
            <p className={ContentParagraphs}>
                In conclusion, by switching to clean energy, businesses can
                reduce their environmental impact, improve productivity, and
                show their commitment to sustainability. Make the switch today
                with payment plans from SunFi. <br />
                Click{' '}
                <a
                    rel="noreferrer"
                    href={'/consumer/get-started'}
                    target="_blank"
                >
                    here
                </a>{' '}
                to get an offer today.
            </p>
        )}
        {id === 8 && (
            <p className={ContentParagraphs}>
                Are you ready to begin the journey to a more sustainable life?
                SunFi is here to guide you through the process and help you find
                the perfect solar plan for your home and business. <br />
                Click the link{' '}
                <a
                    rel="noreferrer"
                    href="https://www.sunfi.co/consumer/get-started"
                    target="_blank"
                >
                    here
                </a>{' '}
                to get started on your solar journey today.
            </p>
        )}
    </>
)

BulletedArticle.propTypes = {
    id: PropTypes.number,
    listType: PropTypes.string,
    step: PropTypes.bool,
    header: PropTypes.string,
    subtext: PropTypes.string,
}

const contentData = [
    {
        id: 1,
        headerText: 'Predictions of the future with SunFi - Part I',
        time: '3 minutes',
        date: 'July 16, 2021',
        title: `We envision Solar on every roof`,
        paragraphs: [
            `At SunFi we believe that every home and office in the world will have solar panels within the next 30- 50  years. Solar panels fit in aesthetically to existing roofs and help generate the cheapest cost of electricity in many countries right where it's consumed. To make this vision real, we need armies of sales and installation agents connected to customers in their communities who help facilitate the transition. Innovative financing solutions, fast great quality installations and equipment, and amazing long term service are required to ensure the transition is sustainable.`,
            `If you live in Lagos, you’re already noticing a solar panel on at least one roof on every street you visit. What’s happening here? Nigerians are educating themselves about their energy options, installers and solar energy businesses are becoming more vocal and more skilled at helping Nigerians make the leap to clean energy. They are pathfinding the way to the future. But the transition is not happening fast enough. As an everyday Nigerian, it’s hard to trust every solar installer you meet when your friend’s solar inverter stopped working and the installer didn’t answer her call to repair it. It’s tough to find financing to help make the transition - not everyone can afford to pay the upfront costs of clean energy systems. It’s also hard for some installers to actually get the solution sizing correct or even harder to know what to do when the customer’s requirements change. There’s got to be an ecosystem that makes it easier for clean energy installers to access all the tools they need to transition millions of everyday consumers to cleaner energy experiences. That’s our goal at SunFi and we look forward to simplifying clean energy.`,
        ],
        link: '/learn/we-envision-solar-on-every-roof',
        smallImageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Solar+on+Every+Roof.svg',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Virtual+Agent+Network+of+Associate+Installers.svg',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Solar+on+Every+Roof+(Banner).svg',
    },
    {
        id: 2,
        headerText: 'Predictions of the future with SunFi - Part II',
        time: '3 minutes',
        date: 'July 23, 2021',
        title: `A virtual agent network of Associate Installers will accelerate the transition `,
        paragraphs: [
            `A self employed network of skilled electricians will help accelerate clean energy access across Africa. A massive amount of wealth creation will follow. About 33% of Nigeria’s working population (70 million people in the working population) are unemployed and a high percentage of these people are youth who are seeking great opportunities to build better lives for themselves and their country. Many of these people can learn or have learned electrical skill sets at their technical colleges, universities, and apprenticeships and are keen to participate in the fast growing renewable energy sector. But it has always been an employers market in Nigeria. This means these talented men and women compete with peers to secure work in the available full time jobs that exist with good employers in the renewable energy sector. Those that don’t pass the interview have their dreams dashed and turn to opportunities that may not use their full potential. `,
            `What if we could digitally organize, and allocate this tremendous human capital to a task that is in high demand and will change the world? At SunFi we believe a high percentage of Nigeria’s unemployed population will build great self employed businesses as Solar Sales and Installation agents and will help change the world in the process. Dozens of technical colleges, universities, training institutes and apprenticeships are pumping electrical engineering talent out faster than the market knows what to do with them. At SunFi, our platform will connect these installers to the great opportunities and will partner with them to simplify clean energy. Are you in? Let’s Go!`,
        ],
        link: '/learn/a-virtual-agent-network-of-associate-installers-will-accelerate-the-transition',
        smallImageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Virtual+Agent+Network+of+Associate+Installers.svg',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Virtual+Agent+Network+of+Associate+Installers.svg',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Virtual+Agent+Network+of+Associate+Installers+(Banner)..svg',
    },
    {
        id: 3,
        headerText: 'Predictions of the future with SunFi - Part III',
        time: '3 minutes',
        date: 'July 30, 2021',
        title: `Energy Storage will be in every home and building`,
        paragraphs: [
            `Energy Storage helps you store energy for consumption whenever it is needed. Imagine the possibilities. As the world transitions to cleaner, more intermittent renewable energy systems, energy storage makes this clean energy more flexible and dispatchable when people need it most. Energy Storage is here to stay and will be integrated into every part of our lives.`,
            `Now, of course you need to be efficient about how you consume this stored electricity. You know how you charge your phone’s battery at night, wake up all excited, call your friend for an hour, or three, on Saturday morning? Then your phone lets you know, it’s time to charge me up again? Well, if you hadn’t used all that stored energy to have that long conversation, the battery might have lasted you till later in the day and you would have arrived at that important meeting earlier because you weren’t stuck trying to charge up your battery again - for the third time!! Let’s get real, using stored energy means you need to be smart.`,
            `This means having access to smartphone apps, or desktop dashboards which help you visualize your energy consumption will help you consume your stored energy more efficiently and thus will help you achieve your desired energy experience. In upcoming articles, we’ll explore how  energy efficiency, smart energy systems, apps, and dashboards, can help you get the most out of your Battery Energy Storage.`,
        ],
        link: '/learn/energy-storage-will-be-in-every-home-and-building',
        smallImageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Energy+Storage+in+Every+Home.svg',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Energy+Storage+in+Every+Home.svg',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Energy+Storage+in+Every+Home+(Banner)..svg',
    },
    {
        id: 4,
        headerText: 'Predictions of the future with SunFi -  Launch',
        time: '3 minutes',
        date: 'August 2, 2021',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/launch-thumnail-rs.png',

        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Predictions+(Banner).svg',
        paragraphs: [
            {
                text: `Let’s face it, living in Nigeria is not always a walk in the park. Especially because we continue to lack access to affordable and reliable modern electrification. As one Bloomberg article put it, Nigerians run on generators and 9 hours of grid power per day. The grid will eventually be able to provide large amounts of electricity to its citizenry, but it will take decades for this to be predictable and reliable. And so, we turn to millions of noisy, expensive petrol and diesel generators to fill the void. Why? Because it’s not easy to find, pay for, and get great customer experiences around cleaner, more affordable alternatives. By now, most of us know about solar, but in Nigeria it’s still hard to figure out which solar capacity to use, what inverter is best, which type of battery to use, what happens when there’s an issue, how to pay for or finance a solution, who should install and service it, what optimal combination of assets to deploy, and so on…`,
                offset: [{ offset: 171, length: 17 }],
                link: 'https://www.bloomberg.com/news/articles/2019-09-23/nigeria-runs-on-generators-and-nine-hours-of-power-a-day',
            },
            {
                title: 'Problem',
                text: `In Nigeria alone, more than 16.7 millions middle income citizens are forced to use petrol or diesel generators to supplements weak unreliable grids. Small Distributed Energy Providers & Solar Installers (DEPs) can end this challenge but no ecosystems exists to accelerate their climate and energy access impact. The key challenges preventing Small Distributed Energy Providers from scaling clean energy access are (1) access to financing (2) trained installer technicians, (3) durable clean energy technology (4) great customer service, and (5) optimized power solutions`,
            },
            {
                text: `That’s why we decided to start SunFi.co. SunFi, which is short for “Sun Finance”, is building 
        Nigeria and Africa’s first Energy FinTech ecosystem which is purpose built to help Small Distributed Energy 
        Providers scale clean energy solutions to serve millions of consumers. We do so by building digital workspaces that contain technology tools and financial services which energy providers can use to scale their businesses. We work with financial institutions and investors at scale to bring financing into SunFi workspaces by innovating tools that help financiers achieve their impact and financial return objectives, safely. We also partner with the best Original Equipment Manufacturers to directly serve their customers or simplify the supply chain for Small Clean Energy Providers and their consumers. `,
                offset: [{ offset: 30, length: 10 }],
                link: '',
            },
            {
                imageUrl: 'aa',
            },
            {
                text: `At SunFi, we optimize the workflow of Distributed Energy Providers with purpose-built tools to make their jobs of optimizing, selling, installing, and managing solutions for their consumers easier. We develop these tools by leveraging our previous experience as a Distributed Energy Provider, and by listening to other energy providers who leverage our ecosystem to scale their businesses. SunFi will become the one-stop shop for clean energy providers, financial partners, and equipment manufacturers looking to scale clean energy solutions to millions of consumers in Nigeria and beyond. `,
            },
            {
                text: `We have many exciting features and tools which we will release over the coming year and beyond. For now, if you’re interested in building or growing a small solar energy business, you’re an Original Equipment Manufacturer looking to expand your business, or a Supporter looking to help us execute our projects, please visit us at SunFi.co and sign up to see how you can get involved.`,
            },
            {
                text: `SunFi is going to become a global ecosystem that scales clean energy solutions to millions of people. We’re starting our global journey here, in Nigeria, and we hope you will join us for the ride. Let’s Scale and Simplify Clean Energy Solutions together!`,
                offset: [{ offset: 197, length: 57 }],
                link: '',
            },
        ],

        link: '/learn/predictions-of-the-future-with-SunFi-Launch',
    },
    {
        id: 5,
        headerText: 'Payment Plans are the bottleneck of clean energy',
        time: '~4 minutes',
        date: 'November 8, 2021',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/payment_plans+are+the+bottleneck+of+clean+energy+banner.svg',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/payment_plans+are+the+bottleneck+of+clean+energy+banner.svg',
        paragraphs: [
            `Clean energy solutions such as Solar and Energy storage, Energy Efficiency tech such as efficient air conditioning, and energy management tech including smart meters and digitalized energy management systems. These sound like exactly what you want in your home, business, or community in Nigeria where electricity access is quite dirty (old diesel generators anyone?), inefficiently consumed, and poorly managed by consumers which lack the right information. These technologies are here and accessible to all. So what’s the hold up? Why is it that so many people want to make the transition to better energy technologies in the world’s largest Distributed Energy Market but still can’t make it happen? 
      `,
            `Well, turns out the key bottleneck is financing or access to installment payment plans that translate large capital expenditures of clean, smart energy management technology into manageable payment plans for everyday consumers. The financial technology revolution is here, and it’s enabling all manners of business to be done online with payment technologies making money fluid for African consumers and financing technologies channeling stranded capital into the hands of consumers who need this capital to improve the quality of their lives, to expand their businesses, or run initiatives. These two trends make it possible to tackle the key bottleneck of the clean energy industry. And that’s exactly what SunFi is doing. 
      `,
            `SunFi has built Africa’s first platform that is built by energy providers with payment plan experience for clean energy and energy management solutions. SunFi is building technology which helps energy providers serve more consumers by providing a digital workspace featuring payment plan tools and features that enable energy providers to access intelligent recommendations of energy solutions for consumers improving the ability of energy providers to sell their solutions to consumers. 
      `,
            `As an energy provider, you know what it feels like to finally figure out the right solution for your consumer and deliver that stellar sales pitch only to find out the customer can’t afford to pay upfront for the entire solution. What does he ask for? “Can I pay in installments?”. Every distributed energy provider is familiar with this scenario. The first thing the energy provider does is turn to its bank to see if it can help provide a loan to the energy consumer for the solution. The problem is the bank needs the customer to open an account with it in order to access this loan, and even after going through the sometimes painful process of doing so, the consumer might still not be qualified for a loan from the bank. But this doesn't mean the right department in the bank does not want the consumer’s business or the right energy consumer’s business. The banks or financial partners just need a platform through which trusted energy providers which solid projects and great energy consumers can be accessed resulting in low default rates on repayments. Until SunFi, this did not exist, but now it does.`,
            `This means financial partners seeking to support the development of clean energy and smart energy providers now have a trusted platform through which they can reach the best distributed energy providers and their customers in Nigeria. For energy providers, this is awesome. Instead of being able to serve 5 solar or inverter customers a month, an energy provider will be able to reach multiples of that number due to access to payment plan solutions from SunFi. More customers will transition to better, cleaner energy technology because customers can access their desired energy solution now and but pay for this solution over time. Access benefits now, spread payments over time. Hmmm - this results in a Win Win Win Win. The energy provider wins because it can do more business, consumers win because they can now access their desired clean and smart energy solutions, the investors win because SunFi reduces repayment risk, and the planet wins because millions more consumers can now transition to clean energy sources. 
      `,
            `Make no mistake, energy-financial technology is the bridge to transitioning millions of consumers in Africa and around the world to more economically and environmentally beneficial solutions. People, their businesses, and their communities deserve better energy solutions that are affordable but also better for the world. SunFi is rising to that challenge. Reach out to schedule your demo to learn more about how we can help you serve more energy customers through our SunFi workspace for energy providers.`,
        ],

        link: '/learn/payment-plans-are-the-bottleneck-of-clean-energy',
    },
    {
        id: 6,
        headerText: '5 Reasons you should switch to solar in 2023',
        time: '3 minutes',
        date: 'January 12, 2023',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Big+Thumbnail.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Big+Thumbnail.png',
        paragraphs: [
            `Being a business owner or a remote worker without stable light is second to none on the list of frustrating things anyone can experience, but that is the experience of most, if not all, Nigerians.`,
            `With nothing less than five national grid collapses, countless load shedding and fuel scarcity episodes last year, it’s easy to see why Nigerians need an alternative source of power to enjoy electricity for longer hours and live a more comfortable life in 2023.`,
            `Nigeria’s history with poor power supply goes as far back as its inception. That explains why in moments when power is available for a longer-than-usual period (which isn’t so much of an extended period, i.e. 18 hours), Nigerians are quick to share their scepticism <a rel="noreferrer" target='_blank' href='https://twitter.com/thetimileyin/status/1587795461729124352?s=20&t=JEOsAyalfn4lAWpyETTLSQ'>and fear on social media.</a>`,
            `In 2021 the World Bank reported that over 78% of electricity consumers in Nigeria received less than 12 hours of electricity supply daily. The federal government refuted the report by insisting that power distribution had steadily improved. However, the energy situation in 2022 proved that power distribution hadn’t improved as much.`,
            `With the presence of generators, it is still difficult for everyday Nigerians to rely on them due to the rising costs and never-ending fuel scarcity.`,
            `According to <a rel="noreferrer" href='https://nigerianstat.gov.ng/elibrary/read/1241243' target='_blank'>this report</a> from the Nigerian Bureau of Statistics, the average retail price of diesel in September 2022 was N789.90 per litre, an increase of 210.20% from N254.64 per litre recorded in September 2021.`,
            `Currently, petrol is sold on average at 350 per litre. The soaring cost is putting holes in pockets of businesses and homes. With the federal government’s plans to remove fuel subsidy in June 2023, one can only imagine how frustrating things would be.`,
            `Other alternatives like solar may seem unachievable as it is believed to be costlier and more challenging to manage than generators, but with the flexible payment plans from <a rel="noreferrer" target='_blank' href='https://www.sunfi.co/'>SunFi</a>, it’s 100x easier and cheaper for anyone to enjoy solar power. SunFi’s payment plans enable people to pay small small in 3- 36 months to own quality solar systems.`,
            `Aside from being cheaper to acquire with SunFi, there are several other reasons you should choose solar this year.`,
        ],
        bulletedArticle: <BulletedArticle id={6} listType="unordered" />,
        link: '/learn/5-reasons-why-you-should-switch-to-solar-in-2023',
    },
    {
        id: 7,
        headerText: 'Five ways clean energy benefits Nigerian businesses',
        time: '3 minutes',
        date: 'March 2, 2023',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/+Five-ways-clean-energy-benefits-Nigerian-businesses+.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/+Five-ways-clean-energy-benefits-Nigerian-businesses+.png',
        paragraphs: [
            `One problem businesses face in Nigeria is power failure. It has become a severe issue that causes production interruptions and slows down many businesses' growth.`,
            `Businesses that depend on constant electricity supply face a huge setback when the power goes out. For example, businesses that rely on refrigeration for food storage risk losing their products when the power goes out, affecting not only their profits but also the health and safety of their customers.`,
            `The frequent blackouts and collapses of the power grid also increase the costs of running businesses, forcing them to rely on expensive and unreliable backup generators.`,
            `<b>Why Clean Energy is the Solution:</b>`,
            `One way to overcome the problem of power failure is to switch to clean energy sources. Clean energy means energy sources that don't cause harm to the environment or create pollution, like solar, wind, and water power. 
             Switching to clean energy can be great for businesses; they can save money on energy costs & reduce environmental pollution.`,
            `In Nigeria, more and more people are using solar power instead of generators, and this is happening because fuel is becoming more expensive and hard to find each day.`,
            `A <a rel="noreferrer" href='https://businessday.ng/opinion/article/solar-adoption-in-nigeria-is-a-socioeconomic-game-changer/' target="_blank">report</a> from Business Day says that Nigeria's solar market is growing. The article also reported that around 500,000 Nigerian households (1.25%) use solar energy.`,
            `Alongside reducing energy costs, the benefits of clean energy include the following:`,
        ],
        bulletedArticle: <BulletedArticle id={7} listType="ordered" />,
        link: '/learn/five-ways-clean-energy-benefits-nigerian-businesses',
    },
    {
        id: 8,
        headerText: `Solar Energy Made Simple: A Beginner's Guide to Going Solar`,
        time: '4 minutes',
        date: 'August 25, 2023',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/business__guide--sm117x117.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/business__guide--lg1110x515.png',
        paragraphs: [
            `Meet Lola, a content marketing manager who works remotely in Lagos, Nigeria. Like many others, she has been struggling with her productivity at work due to frequent power outages and is considering switching to solar; however, her major hurdle lies in not knowing where to begin.`,
            `If you're like Lola and wondering where to begin, you're not alone. The world of solar energy can be overwhelming, especially for beginners. This guide holds your hand and walks you through transitioning to a more energy-independent lifestyle.
            `,
            `As you begin the journey to making your home or business more self-sufficient in terms of energy, consider the following essential steps.
            `,
        ],
        bulletedArticle: <BulletedArticle id={8} listType="ordered" step />,
        link: LEARN_ROUTES.learnContentEight,
    },
    {
        id: 9,
        headerText: `Empower Your Customers With SunFi's Financing Solutions`,
        time: '3 minutes',
        date: 'August 25, 2023',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/empower__customer--sm117x117.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/empower__customer--lg1110x515.png',
        paragraphs: [
            `As renewable energy continues to gain momentum, solar power is becoming an increasingly popular choice for consumers and businesses. Even business mogul <a rel="noreferrer" href='https://energy.economictimes.indiatimes.com/news/renewable/civilisation-will-be-mostly-solar-powered-in-future-elon-musk/93640020#:~:text=In%20a%20tweet%2C%20Musk%20said,amount%20of%20energy%20by%20comparison.' target="_blank">Elon Musk</a> believes that civilization will be mostly solar-powered in the future. As Nigerians also seek alternatives to the expenses of petrol and diesel, solar energy has emerged as a promising solution. However, despite the growing interest in solar power, solar businesses are facing a challenge—potential customers are deterred by the upfront costs associated with solar installations.`,
            `To bridge this gap and accelerate the adoption of solar energy, SunFi offers an innovative partnership that allows solar installers and businesses to provide easy and accessible financing options to their customers. There are a lot of benefits to partnering with SunFi, and we'll explore how this collaboration can positively impact the solar industry and the environment.`,
            `<b>SunFi: Revolutionising Solar Financing</b><br />
            <a href="https://www.sunfi.co/" target="_blank">SunFi</a> is a leading solar financing company that helps Nigerians transition from petrol and diesel generators to solar by providing flexible and affordable payment plans. SunFi achieves this by collaborating with solar energy businesses to offer quality solar solutions to their customers.`,
            `Partnering with SunFi offers numerous benefits and amongst them are:<br />
            <b>a. Expanding Customer Base:</b> By partnering with SunFi, solar energy businesses can tap into a more extensive customer base. This increased market reach can result in higher sales and business growth.<br />
            <b>b. Building Customer Trust:</b> SunFi's reputation as a reliable financing partner can boost your customer's confidence in your business. The assurance of a well-established financing company often helps customers feel more comfortable switching to solar energy.<br />
            <b>c. Environmental Impact:</b> The overall environmental impact improves as more solar projects are implemented. By partnering with SunFi, you can contribute to a greener and cleaner future by encouraging more customers to adopt solar energy.<br />
            <b>d. Simplified Process:</b> SunFi handles the complexities of the financing process, reducing the administrative burden on you as a solar energy business. This allows you to focus on what you do best – providing exceptional solar solutions.<br />
            <b>e. Competitive Advantage:</b> Embracing solar financing through SunFi can give you a competitive edge over those who don't offer such financing options. This differentiation can sway potential customers towards choosing you over your competitors.<br />
            `,
            `Partnering with SunFi and offering financing options for solar projects presents a compelling opportunity for solar installers and businesses to impact the renewable energy landscape positively. By eliminating upfront costs, simplifying the financing process, and expanding the customer base, this collaboration contributes to the accelerated adoption of solar energy.
            `,
            `<a href="https://www.wavelengthips.com/" target="_blank">Wavelength's</a> Executive Director, David Idem, mentioned in a recent <a href="https://www.youtube.com/watch?v=coAaPM37vBY&t=10s" target="_blank">interview</a> that partnering with SunFi has made it easy for their customers to access funding within two to three days, making it easy for them to own their solar system.`,
            `Mr Idem says, "We've been partnering with SunFi for close to a year now, and I can say that we've been able to triple the number of customers that come in for solar solutions due to the support we get from SunFi."`,
            `As solar power becomes more accessible to a broader audience, we also move closer to a sustainable and environmentally responsible future. You can partner with us today and let your business experience some exponential growth. Begin your journey <a href="https://www.sunfi.co/partners" target="_blank">here</a>.`,
        ],
        link: LEARN_ROUTES.learnContentNine,
    },
    {
        id: 10,
        headerText: `Affordable Solar Energy: An Alternative to the Hike in Fuel Price`,
        time: '4 minutes',
        date: 'August 25, 2023',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/fuel__pump--sm117x117.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/fuel__pump--lg1110x515.png',
        paragraphs: [
            `Amid the daily hustle and bustle, Nigerians constantly face a challenge that keeps hitting where it hurts the most – our wallets. The price of fuel has skyrocketed, leaving everyone struggling to afford even the basics. In just a matter of months, we've seen the price of petrol shoot up from N185 to a jaw-dropping N617, and according to <a href="https://businessday.ng/news/article/petrol-price-set-for-another-increase-as-landing-cost-goes-up/" target="_blank">this article</a> on Business Day, the price of petrol is set for another increase.<br />
            This is enough to make anyone get frustrated and depressed; however, in a country like Nigeria, where sunshine is abundant and survival feels like a distant dream, it is obvious that the only way to enjoy electricity and save money is through renewable energy sources such as solar.<br />
            Online, conversations about solar energy continue to grow daily, proving that Nigerians are also aware of the better alternative. However, how can everyday Nigerians access this without boring more holes into their wallets?`,
            `This is where SunFi comes in. <a href="https://www.sunfi.co/" target="_blank">SunFi</a> is making solar energy affordable, accessible, and stress-free. Read more about our mission in <a href="https://techcrunch.com/2023/02/03/sunfi-wants-to-become-the-fastest-way-for-nigerians-to-find-finance-install-and-manage-solar/" target="_blank">this</a> Tech Crunch feature. But before we get into the nitty-gritty of this article, here's a stat that should leave you worried: did you know that according to <a href="https://www.vanguardngr.com/2023/04/energy-poverty-over-60-of-nigerias-population-lack-supply-nlng/" target="_blank">NLNG Limited</a>, a whopping 60% of Nigerian households struggle with energy poverty?  It’s a reality check for everyone. 
            `,
            `As you read this article, get ready to discover how SunFi is flipping the script, allowing everyone to power their lives, families and businesses without draining their pockets.`,
            `<br /><b>The Reliability of Solar Energy</b><br />
            In the face of this current crisis, it is essential to re-establish how <a href="https://www.solarreviews.com/blog/solar-panel-reliability-how-reliable-is-solar-power" target="_blank">reliable solar energy</a> is as an alternative power solution. Unlike generators, solar energy is fueled by the sun, a renewable energy source - solar panels convert sunlight into electricity, ensuring a continuous and reliable power supply. These panels require minimal maintenance, no moving parts, and can operate for up to 25 years. On the other hand, generators require regular maintenance, including oil changes, fuel refills, and engine inspections. Solar energy eliminates this inconvenience and its accompanying costs.`,
            `But, let's not just stop at reliability and convenience. Solar energy plays a pivotal role in safeguarding our environment. By embracing solar power, we're reducing our reliance on fossil fuels and supporting the reduction of carbon emissions, the culprits of climate change. Each watt generated from the sun brings us closer to a world where we give back as much as we take, ensuring the safety of our planet for us and generations to come.`,
            `<br /><b>SunFi: A Go-to Solution</b><br />
            SunFi is your all-in-one solution, breaking down barriers to solar energy with flexible payment plans. You can bid farewell to the upfront costs that often deter many from embracing solar power. Our mission? To empower remote workers, households, and businesses with affordable access to the endless benefits of solar energy. Our flexible payment plans allow you to choose between paying in instalments over time to own the system entirely or paying a monthly subscription to enjoy the benefits of solar energy. These plans pave the way for the stress-free acquisition of solar inverters, transforming how Nigerians power their lives. According to Rotimi Thomas, the CEO, SunFi plans to help millions of everyday Nigerians transition from petrol and diesel generators to solar inverter systems, leveraging their network of quality solar energy providers.`,
            `We are currently running some promos you can take advantage of, see the promos below.`,
            `<b>500 Watts Solar Inverter</b> - Ideal for remote workers, small businesses, young families, etc. 
            This is a 500W hybrid inverter with 2 x 200W/p solar panels and a 1,075Wh lithium-ion battery. 
            The system is charged by solar but can also be charged by the grid or a generator. 
            It offers up to <b>18 hours</b> to power essential appliances such as a small fan, decoder, laptop, phone, small speaker, small TV and clippers. `,
            `Warranty Details: 2 years <br />
            Pricing Information: <br />
            Subscription: ₦ 15,000/month <br />
            Lease-To-Own: ₦ 25,000 for 24 months and an upfront deposit of ₦25,000
            `,
            `<a href="https://www.sunfi.co/consumer/get-your-offer?promo=true" target="_blank">Click here</a> to request this solution.`,
            `<b>1kVA Solar-</b> Ideal for - households and small businesses.<br />
            This has two 150W solar panels, a 30A Charge controller, and a 200Ah Tubular battery. It powers six bulbs, one fan, phones and a 32-inch TV.<br />
            Warranty Details: 1 year. <br />
            Pricing Information: Lease-To-Own: N38,675.87  for 12 Months and an upfront deposit of N153,000.`,
            `<a href="https://www.sunfi.co/consumer/get-your-offer?promo=true&promo_type=renergy" target="_blank">Click here</a> to request this solution.`,
            `<b>65L Solar Fridge-</b> Ideal for households, pharmacies and small businesses, i.e. cold drinks vendors etc.<br />`,
            `This 65L solar-powered fridge comes with two units of 120W solar panels.  It is solely powered by the sun and doesn't require electricity from the grid or a battery. It maintains a cooling temperature of 4°C. The fridge provides continuous cooling for 72 hours, even during periods without sunshine. `,
            `Warranty Details: 2 years <br />
            Pricing Information: <br />
            Subscription: ₦13,609.70 <br />
            Lease-To-Own:  ₦28,000.00/month for 24 months and an upfront deposit of ₦16,000. 
            `,
            `<a href="https://www.sunfi.co/consumer/get-your-offer?promo=true&promo_type=surechill" target="_blank">Click here</a> to request this solution.`,
            `As the country grapples with the consequences of rising fuel prices and electricity tariffs, the need for a change in the energy sector becomes ever more evident. SunFi emerges not just as a provider of solar solutions but as an agent of change, offering a pathway towards affordable and sustainable energy access for all. By addressing financial barriers through flexible payment plans and innovative subscription models, SunFi is empowering individuals, businesses, and communities to embrace the potential of solar energy.
            `,
            `Imagine a world where clean, affordable energy is not a luxury but a reality. The possibilities become unlimited. Begin your journey to limitless possibilities with solar today by visiting our website, <a href="https://www.sunfi.co/" target="_blank">sunfi.co</a>.`,
        ],
        bulletedArticle: <BulletedArticle id={10} listType="ordered" />,
        link: LEARN_ROUTES.learnContentTen,
    },
    {
        id: 11,
        headerText: (
            <span>
                Debunking the Top <br /> Solar Energy Myths
            </span>
        ),
        time: '3 minutes',
        date: 'November 25, 2023',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/debunk-thumbnail.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/debunk_myth--banner.png',
        paragraphs: [
            `People tend to believe myths even when there is evidence against them because myths can sometimes sound believable. People hold on to these myths because they align with their existing beliefs. Sorting out the facts from the myths can be confusing, but it reveals how our minds work in intriguing ways. While the <a href="https://businessday.ng/opinion/article/solar-adoption-in-nigeria-is-a-socioeconomic-game-changer/" target="_blank">Nigerian solar off-grid market</a> is among the fastest-growing in Africa, the industry is still riddled with myths. Despite being an eco-friendly choice, these misconceptions persist. We are here to debunk some of these myths.
            `,

            `<br /><b>Myth 1: Solar panels are expensive</b><br />
            <b>Fact: There has been <a href="https://arka360.com/ros/solar-panel-prices-decline/ " target="_blank">a decline in the prices of solar panels</a> in recent years,</b>
            with their cost dropping significantly. Choosing solar energy can be a smart financial move in the long run, as the savings over time often exceed the initial installation costs. For homeowners, there are options like lease-to-own and subscription, making solar energy more affordable and accessible for everyday families.`,

            `<br /><b>Myth 2: Solar energy is not reliable</b><br />
            <b>Fact: Solar energy is reliable, <a href="https://www.cnet.com/home/energy-and-utilities/do-solar-panels-work-when-its-cloudy/" target="_blank"> even in cloudy or overcast conditions</a>.</b>
            Advances in solar technology, such as battery storage systems, allow for storing excess energy generated during sunny periods for use during cloudy days or at night. Also, solar systems can connect to the grid, so when there is not enough sun, we can still use electricity from the regular power supply to keep the battery charged.`,

            `<br /><b>Myth 3: Solar energy is not efficient</b><br />
            <b>Fact: Solar panels have become more efficient over the years,</b> with some panels <a href="https://corporate.enelx.com/en/question-and-answers/are-solar-panels-energy-efficient" target="_blank">reaching conversion efficiencies of over 20%.</a>  Researchers are continually working towards improving solar technology so it produces more energy. To get the most out of your solar panels, it is important to install them properly, place them in the right direction, and maintain them regularly. This is where SunFi stands out. We have a network of skilled installers who take pride in doing a meticulous and high-quality job. There are several benefits to choosing SunFi.`,

            `<br /><b>Myth 4: Solar panels will damage my roof</b><br />
            <b>Fact: Solar panels are installed with great care to ensure they do not harm your roof.</b> Professional solar installers use unique mounts that distribute weight evenly, preventing damage. Before installation, they check your roof's condition and make any necessary repairs. The panels are raised above the roof, allowing airflow for cooling. High-quality materials withstand various weather, ensuring a long-lasting, safe addition to your home.`,

            `<br /><b>Myth 5: Solar energy is not environmentally friendly</b><br />
            <b>Fact: Solar energy is a <a href="https://www.treehugger.com/how-much-co-does-one-solar-panel-create-4868753#:~:text=Solar%20panels%20don't%20produce%20emissions%20while%20generating%20electricity%2C%20but,most%20significant%20sources%20of%20emissions." target="_blank">clean and renewable source of energy</a></b> that produces no greenhouse gas emissions like carbon monoxide during operation. The production of solar panels has some environmental impact, but it is significantly lower than the emissions associated with fossil fuel-based energy generation. Solar energy helps reduce dependence on fossil fuels and contributes to a more sustainable future.`,

            `<br /><b>Conclusion</b><br />
            By clearing up these misconceptions about solar energy, it is obvious that solar power is a reliable and Earth-friendly choice. As technology improves and costs decrease, solar energy will become even more important in powering our homes and helping the environment. We understand that financing might be a concern for many, so we have highlighted some of our best offers <a href="https://www.sunfi.co/learn/affordable-solar-energy-an-alternative-to-the-hike-in-fuel-price" target="_blank">here</a>, making the transition to solar energy more accessible for everyone.
            `,
        ],
        link: LEARN_ROUTES.learnContentEleven,
    },
    {
        id: 12,
        headerText: `How Solar Has Helped Adedamola Save Money: A Remote Worker’s POV`,
        time: '2 minutes',
        date: 'November 25, 2023',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/remote_worker-thumbnail.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/remote__worker--banner.png',
        paragraphs: [
            `Time is money; wasted time is wasted money. Growing up, we learned the importance of timeliness and how wasted time can mean wasted opportunity. We also learned the importance of saving money, which has shaped our mindset about finances. We earn, spend, save, invest, and negotiate every other day to cut costs. But what happens when we have to pay through the nose for something vital to our productivity? We pay the total cost or seek alternatives that yield the same result. And for the simple fact that our productivity hinges on time, we have to find a solution. This is the reality of a remote worker named Adedamola.`,

            `Adedamola, SunFi’s Creative Designer, shared his experience using solar power instead of generators powered by the now costly fuel. Confronted by frequent power outages while working from home and tight deadlines always looming, he turned to solar power. More than seamless work and quality output, his choice ensured his ability to save more time and energy spending. Here are some of the ways solar has helped him save time, which has, in turn, saved him money:
            `,
        ],
        bulletedArticle: <BulletedArticle id={12} listType="unordered" step />,
        subListHeader:
            'Other additional benefits of going solar as a remote worker:',
        bulletedArticle2: (
            <BulletedArticle id={12.1} listType="unordered" step />
        ),
        conclusion: [
            `To sum it up, embracing solar energy goes far beyond just saving money for remote workers. It means having a reliable and sustainable energy source, making a positive impact on the environment, enjoying and gaining valuable educational opportunities. Plus, it empowers individuals to be inspirational figures in their communities, driving positive change and creating a greener, more sustainable world.`,

            `If you are a remote worker, you understand the rudiments of productivity and time. Save time and cost today by going solar. Be like Adedamola, get started <a href="https://www.sunfi.co/consumer/welcome" target="_blank">here</a> 🚀 🚀`,
        ],
        link: LEARN_ROUTES.learnContentTwelve,
    },
    {
        id: 13,
        headerText: `Introducing a simpler way to shop for your solar systems`,
        time: '3 minutes',
        date: 'May 12, 2024',
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/introducing-the-new-consumer-smart-shopping-experience-V2-thumbnail.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/introducing-the-new-consumer-smart-shopping-experience-V2.png',
        paragraphs: [
            `Access to electricity has become an ever-growing challenge in Nigeria. People are faced with skyrocketing fuel prices leaving many in darkness.`,
            `Businesses struggle, homes are left powerless, and remote workers face mounting obstacles. The time for change is now, and at SunFi, we're leading the charge towards a brighter, greener future.`,
            `For years, we've been committed to simplifying the switch to solar, ensuring our customers enjoy seamless service. But like every good business, we've also been getting some feedback. We listened and learned from our users' experiences, and now we have an easier and more convenient method available for our users to purchase solar energy systems.`,
            `Continuously monitoring how users engage with our platform has provided us with valuable insights and feedback. We aimed to understand what aspects resonated with users, what didn't, and why some users didn't complete the onboarding process they initiated.`,
            `Additionally, we sought to ensure that users comprehended the onboarding process and now, finding the perfect solar package is easier than ever, as we’ve gone ahead to work on something for everyone.`,
        ],
        bulletedArticle: (
            <BulletedArticle
                id={13}
                listType="ordered"
                header={`So what's new on SunFi?`}
                subtext={`Well, allow me to introduce you to the latest improvements that have been made.`}
            />
        ),
        conclusion: [
            `<span style="font-family: SF-Pro-Display-Bold">Excited to see these improvements in action?</span> Don't take my word for it! Head over to <a href="https://www.sunfi.co" target="_blank">sunfi.co</a> and experience the future of solar shopping for yourself. `,

            `If you need any assistance along the way, our team is here to help at <a href="mailto:help@sunfi.co">help@sunfi.co</a> Together, let's light up Nigeria, one solar panel at a time.`,
        ],
        link: LEARN_ROUTES.learnContentThirteen,
    },
]

export { contentData }
