/* eslint-disable react/prop-types */
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

import { Tooltip } from 'antd'
import { AppButton } from '../Button'
import GetModal from '../Modal'
import EditPackage from '../ProductPackage/EditPackage'
import EditComponent from '../../pages/Products/EditComponent'
import ViewComponent from '../../pages/Products/ViewComponent'
import ViewPackage from '../../pages/Products/ViewPackage'
import Label from '../Label'
import ActionModal from '../ActionModal'
import { checkLibraryProductAccess } from '../../utils/checkLibraryProductAccess'
import styles from './table.module.scss'
import { denyPermission } from '../../utils/permissionFramework'
import { subStringText } from '../../utils/formatting'
import { permissionsControl } from 'src/utils/permissionsControl'

const Table = ({
    products,
    tabId,
    tableHead,
    hideHeader,
    regularTableFlow = false,
    productType,
    globalType,
    noEdit,
    providerId,
    providerName,
    page = '',
    isAdminWorkspace,
}) => {
    const {
        ProductName,
        ProductNameSpan,
        ProductContent,
        TableHeader,
        TableRow,
        LargeTable,
        MobileCardWrapper,
        MobileHeaderWrapper,
        MobileHeader,
        DateWrapper,
        AmountWrapper,
        MobileTitle,
        MobileSubtitle,
        ResponsiveTable,
        MobileTable,
        ResponsiveTablHead,
        mobileProductContent,
        unitClass,
        MobileTableListing,
        NoTableHeader,
        MobileProductName,
        ComponentFlag,
        ProductFlag,
    } = styles

    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState('')
    const [productId, setProductId] = useState('')
    const [openModal, setOpenModal] = useState(false)

    const handleModal = () => {
        setShowModal(!showModal)
    }
    const history = useHistory()
    const redirectTo = pageUrl => {
        history.push({
            pathname: pageUrl,
            state: {
                noEdit: noEdit,
                providerId: providerId,
                providerName: providerName,
                ...(page === 'view_package'
                    ? { referrer: 'view_package' }
                    : {}),
                isAdminWorkspace: isAdminWorkspace,
            },
        })
    }
    const handleClick = (id, type) => {
        setProductId(id)
        setType(type)
        handleModal()
    }

    const EditModal = tabId === '1' ? EditPackage : EditComponent
    const ViewModal = tabId === '1' ? ViewPackage : ViewComponent
    const DisplayModal = type === 'view' ? ViewModal : EditModal

    const handleActionModal = () => {
        setOpenModal(!openModal ? true : false)
    }

    const canViewComponentDetails = permissionsControl([
        'can_view_component_detail',
    ])
    const canEditComponent = permissionsControl(['can_update_component'])

    const canViewPackageDetails = permissionsControl([
        'can_view_package_detail',
    ])
    const canEditPackage = permissionsControl(['can_update_package'])

    const cannotViewComponentOrPackage =
        !canViewComponentDetails || !canViewPackageDetails

    const cannotEditComponentOrPackage = !canEditComponent || !canEditPackage

    const hideToolTipText =
        (tabId === '1' && canViewPackageDetails) ||
        (tabId === '2' && canViewComponentDetails) ||
        tabId === undefined

    const hideEditToolTipText =
        (tabId === '1' && canEditPackage) ||
        (tabId === '2' && canEditComponent) ||
        tabId === undefined

    return (
        <div>
            <ActionModal
                actionModalOpen={openModal}
                headerText={`Global ${
                    globalType === 'package' ? 'Package' : 'Component'
                }  ?`}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionHandler={handleActionModal}
                actionType="message"
                actionText="Okay"
                noCancelBtn
            />
            <GetModal
                app="workspace"
                showModal={showModal}
                closable={true}
                content={
                    <DisplayModal
                        handleModal={handleModal}
                        productId={productId}
                    />
                }
                onCancel={() => setShowModal(false)}
            />

            <table className={regularTableFlow ? ResponsiveTable : LargeTable}>
                <thead>
                    <tr
                        className={`${
                            regularTableFlow ? ResponsiveTablHead : TableHeader
                        } ${hideHeader ? NoTableHeader : TableHeader}`}
                    >
                        {tableHead &&
                            tableHead.map((h, index) => (
                                <th
                                    className={
                                        h.key === 'unit' ? unitClass : undefined
                                    }
                                    key={`table-head-${index}`}
                                >
                                    {h.title}
                                </th>
                            ))}
                    </tr>
                </thead>

                <tbody>
                    {products?.map((product, i) => (
                        <tr className={TableRow} key={i}>
                            {tableHead.map(({ key, isBold }) => {
                                if (key === 'actions') {
                                    return (
                                        <td
                                            style={{ width: '20%' }}
                                            key={Math.random()}
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <AppButton
                                                    btnBgColor="var(--purple-light)"
                                                    btnTextColor="var(--blue)"
                                                    btnTextColorOutline="var(--blue)"
                                                    btnOutlineColor="var(--purple-light)"
                                                    btnBgColorOutline="#E2EEFF"
                                                    type="outline"
                                                    btnWidth="70px"
                                                    btnHeight="37px"
                                                    handleClick={() =>
                                                        redirectTo(
                                                            product?.viewLink,
                                                        )
                                                    }
                                                    disabled={
                                                        tabId === '1'
                                                            ? !canViewPackageDetails
                                                            : !canViewComponentDetails
                                                    }
                                                    toolTipText={
                                                        hideToolTipText
                                                            ? null
                                                            : 'You are not authorised to perform this action'
                                                    }
                                                    toolTipFontSize={
                                                        cannotViewComponentOrPackage &&
                                                        '12px'
                                                    }
                                                    toolTipPadding={
                                                        cannotViewComponentOrPackage &&
                                                        '10px 6px 14px 11px'
                                                    }
                                                    toolTipPopupWidth={
                                                        cannotViewComponentOrPackage &&
                                                        '140px'
                                                    }
                                                    toolTipMarginLeft={
                                                        cannotViewComponentOrPackage &&
                                                        '-40px'
                                                    }
                                                    arrowPositionCenter={
                                                        cannotViewComponentOrPackage &&
                                                        true
                                                    }
                                                >
                                                    View
                                                </AppButton>
                                                &nbsp; &nbsp; &nbsp;
                                                {!noEdit && (
                                                    <AppButton
                                                        btnBgColor="var(--white)"
                                                        btnTextColor="var(--blue)"
                                                        btnWidth="70px"
                                                        btnHeight="37px"
                                                        type="buttonTwo"
                                                        handleClick={
                                                            (product.isGlobal &&
                                                                !checkLibraryProductAccess(
                                                                    product?.providerId,
                                                                )) ||
                                                            denyPermission(
                                                                'admin',
                                                                'product_library',
                                                                globalType ===
                                                                    'package'
                                                                    ? 'package:can_edit'
                                                                    : 'component:can_edit',
                                                            )
                                                                ? () => {}
                                                                : () =>
                                                                      redirectTo(
                                                                          product?.editLink,
                                                                      )
                                                        }
                                                        opacityValue={
                                                            (product.isGlobal &&
                                                                !checkLibraryProductAccess(
                                                                    product?.providerId,
                                                                )) ||
                                                            denyPermission(
                                                                'admin',
                                                                'product_library',
                                                                globalType ===
                                                                    'package'
                                                                    ? 'package:can_edit'
                                                                    : 'component:can_edit',
                                                            )
                                                                ? '0.5'
                                                                : '1'
                                                        }
                                                        display={
                                                            productType ===
                                                            'componentList'
                                                                ? checkLibraryProductAccess(
                                                                      product?.providerId,
                                                                  )
                                                                    ? 'block'
                                                                    : 'none'
                                                                : 'block'
                                                        }
                                                        disabled={
                                                            tabId === '1'
                                                                ? !canEditPackage
                                                                : !canEditComponent
                                                        }
                                                        toolTipText={
                                                            hideEditToolTipText
                                                                ? null
                                                                : 'You are not authorised to perform this action'
                                                        }
                                                        toolTipFontSize={
                                                            cannotEditComponentOrPackage &&
                                                            '12px'
                                                        }
                                                        toolTipPadding={
                                                            cannotEditComponentOrPackage &&
                                                            '10px 6px 14px 11px'
                                                        }
                                                        toolTipPopupWidth={
                                                            cannotEditComponentOrPackage &&
                                                            '140px'
                                                        }
                                                        toolTipMarginLeft={
                                                            cannotEditComponentOrPackage &&
                                                            '-40px'
                                                        }
                                                        arrowPositionCenter={
                                                            cannotEditComponentOrPackage &&
                                                            true
                                                        }
                                                    >
                                                        {(product.isGlobal &&
                                                            !checkLibraryProductAccess(
                                                                product?.providerId,
                                                            )) ||
                                                        denyPermission(
                                                            'admin',
                                                            'product_library',
                                                            globalType ===
                                                                'package'
                                                                ? 'package:can_edit'
                                                                : 'component:can_edit',
                                                        ) ? (
                                                            <Tooltip
                                                                placement="bottomRight"
                                                                title={
                                                                    globalType ===
                                                                    'package'
                                                                        ? `You are not authorised to edit a global package`
                                                                        : `You are not authorised to edit a global component`
                                                                }
                                                            >
                                                                Edit
                                                            </Tooltip>
                                                        ) : (
                                                            <span>Edit</span>
                                                        )}
                                                    </AppButton>
                                                )}
                                            </div>
                                        </td>
                                    )
                                }
                                return (
                                    <td
                                        key={Math.random()}
                                        className={
                                            isBold
                                                ? ProductName
                                                : regularTableFlow &&
                                                  key !== 'unit'
                                                ? mobileProductContent
                                                : ProductContent
                                        }
                                    >
                                        <span
                                            onClick={
                                                key === 'name'
                                                    ? () =>
                                                          redirectTo(
                                                              product?.viewLink,
                                                          )
                                                    : null
                                            }
                                            className={ProductNameSpan}
                                        >
                                            {product[key]}
                                        </span>
                                        {product?.isGlobal && key === 'name' ? (
                                            <Label
                                                title={'GLOBAL'}
                                                handleClick={() =>
                                                    handleActionModal()
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                )
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className={MobileTableListing}>
                {products?.map((product, i) => {
                    return (
                        <div
                            key={i}
                            className={
                                regularTableFlow
                                    ? MobileTable
                                    : MobileCardWrapper
                            }
                        >
                            <div className={MobileHeaderWrapper}>
                                <div className={MobileProductName}>
                                    <div style={{ width: 100 }}>
                                        {productType === 'componentList' && (
                                            <p className={MobileTitle}>
                                                Component Name
                                            </p>
                                        )}
                                        {productType === 'packageList' && (
                                            <p className={MobileTitle}>
                                                Product Name
                                            </p>
                                        )}

                                        <p
                                            className={MobileHeader}
                                            onClick={() =>
                                                handleClick(product?.id, 'view')
                                            }
                                        >
                                            {subStringText(product.name, 20)}
                                        </p>
                                    </div>
                                    <span
                                        className={
                                            productType === 'componentList'
                                                ? ComponentFlag
                                                : ProductFlag
                                        }
                                    >
                                        {product?.isGlobal ? (
                                            <Label
                                                title={'GLOBAL'}
                                                fontSize="10px"
                                                padding="5px 8px"
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </div>
                                <div
                                    className={AmountWrapper}
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    <p className={MobileTitle}>Amount</p>
                                    <p className={MobileSubtitle}>
                                        {product.amount}
                                    </p>
                                </div>
                            </div>
                            <div
                                className={
                                    regularTableFlow
                                        ? MobileTable
                                        : MobileHeaderWrapper
                                }
                                style={{ alignItems: 'flex-end' }}
                            >
                                <div className={DateWrapper}>
                                    <p className={MobileTitle}>
                                        {product?.created_at &&
                                            'Date of Creation'}
                                    </p>
                                    <p className={MobileSubtitle}>
                                        {product?.created_at !== undefined &&
                                            format(
                                                parseISO(product?.created_at),
                                                'dd MMM, yyyy',
                                            )}
                                    </p>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <AppButton
                                        btnBgColor="#E2EEFF"
                                        btnTextColor="var(--blue)"
                                        btnTextColorOutline="var(--blue)"
                                        btnOutlineColor="var(--purple-light)"
                                        btnBgColorOutline="#E2EEFF"
                                        type="outline"
                                        fontFamily="SF-Pro-Display-Medium"
                                        btnWidth="70px"
                                        btnHeight="37px"
                                        handleClick={() =>
                                            handleClick(product?.id, 'view')
                                        }
                                        disabled={
                                            tabId === '1'
                                                ? !canViewPackageDetails
                                                : !canViewComponentDetails
                                        }
                                        toolTipText={
                                            hideToolTipText
                                                ? null
                                                : 'You are not authorised to perform this action'
                                        }
                                        toolTipFontSize={
                                            !cannotViewComponentOrPackage &&
                                            '12px'
                                        }
                                        toolTipPadding={
                                            !cannotViewComponentOrPackage &&
                                            '10px 6px 14px 11px'
                                        }
                                        toolTipPopupWidth={
                                            !cannotViewComponentOrPackage &&
                                            '140px'
                                        }
                                        toolTipMarginLeft={
                                            !cannotViewComponentOrPackage &&
                                            '-40px'
                                        }
                                        arrowPositionCenter={
                                            !cannotViewComponentOrPackage &&
                                            true
                                        }
                                    >
                                        View
                                    </AppButton>
                                    &nbsp;
                                    {/* TODO: When the permission is denied for the Edit button, the style of the btn should show appropriately */}
                                    {!noEdit && (
                                        <AppButton
                                            btnBgColor="white"
                                            btnTextColor="var(--blue)"
                                            btnTextColorOutline="var(--blue)"
                                            btnOutlineColor="var(--purple-light)"
                                            btnBgColorOutline="#E2EEFF"
                                            btnWidth="70px"
                                            btnHeight="37px"
                                            type="outline"
                                            fontFamily="SF-Pro-Display-Medium"
                                            opacityValue={
                                                (product.isGlobal &&
                                                    !checkLibraryProductAccess(
                                                        product?.providerId,
                                                    )) ||
                                                denyPermission(
                                                    'admin',
                                                    'product_library',
                                                    globalType === 'package'
                                                        ? 'package:can_edit'
                                                        : 'component:can_edit',
                                                )
                                                    ? '0.5'
                                                    : '1'
                                            }
                                            handleClick={
                                                (product?.isGlobal &&
                                                    !checkLibraryProductAccess(
                                                        product?.providerId,
                                                    )) ||
                                                denyPermission(
                                                    'admin',
                                                    'product_library',
                                                    globalType === 'package'
                                                        ? 'package:can_edit'
                                                        : 'component:can_edit',
                                                )
                                                    ? () => {}
                                                    : () => {
                                                          setProductId(
                                                              product?.id,
                                                          )
                                                          handleModal()
                                                          setType('edit')
                                                      }
                                            }
                                            disabled={
                                                tabId === '1'
                                                    ? !canEditPackage
                                                    : !canEditComponent
                                            }
                                            toolTipText={
                                                hideEditToolTipText
                                                    ? null
                                                    : 'You are not authorised to perform this action'
                                            }
                                            toolTipFontSize={
                                                cannotEditComponentOrPackage &&
                                                '12px'
                                            }
                                            toolTipPadding={
                                                cannotEditComponentOrPackage &&
                                                '10px 6px 14px 11px'
                                            }
                                            toolTipPopupWidth={
                                                cannotEditComponentOrPackage &&
                                                '140px'
                                            }
                                            toolTipMarginLeft={
                                                cannotEditComponentOrPackage &&
                                                '-40px'
                                            }
                                            arrowPositionCenter={
                                                cannotEditComponentOrPackage &&
                                                true
                                            }
                                        >
                                            Edit
                                        </AppButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Table
