import { useState } from 'react'

import { useMutation } from 'react-query'
import { removeKYCFileApi } from 'src/api/shoppingExperience/kyc/api'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { isMobile } from 'src/utils/mediaQueries'
import { errorHandler } from 'src/utils/errorHandler'

const useRemoveFileModal = ({
    closeRemoveFileModalAfterSuccess,
    fileToBeRemoved,
}) => {
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const { successAlert } = useCustomToast()
    const { mutate, isLoading } = useMutation({
        mutationFn: () => {
            removeKYCFileApi({
                file_type: fileToBeRemoved?.fileType,
                ...(fileToBeRemoved?.fileType === 'BANK_STATEMENT'
                    ? { bank_statement_id: fileToBeRemoved?.bankStatementId }
                    : { file_url: fileToBeRemoved?.url }),
            })
        },
        onSuccess: () => {
            successAlert(
                `Your document has been deleted successfully`,
                isMobile ? 320 : 500,
            )
            closeRemoveFileModalAfterSuccess()
        },
        onError: res => {
            setToastError(true)
            setErrorMessage(errorHandler(res))
        },
    })
    return {
        toastError,
        errorMessage,
        mutate,
        isLoading,
    }
}

export default useRemoveFileModal
