import axios from '../../config/axios'

export const getKycInfo = async () => {
    const request = await axios.get(`/consumers/kyc/info`)
    return request
}

export const verifyCac = async payload => {
    const request = await axios.post(`/consumers/kyc/verify-business`, payload)
    return request
}

export const initiatePhoneVerify = async payload => {
    const request = await axios.post(`/sse-auth/initiate-otp`, payload)
    return request
}

export const validatePhoneOtp = async payload => {
    const request = await axios.post(`/sse-auth/auth-validate-otp`, payload)
    return request
}

export const submitBusinessKycInfoBusiness = async payload => {
    const request = await axios.post(`/consumers/kyc/business/info`, payload)
    return request
}

export const submitBusinessKycInfoContact = async payload => {
    const request = await axios.post(
        `/consumers/kyc/business/contact-info`,
        payload,
    )
    return request
}

export const updatePrimaryStatement = async (estimationId, payload) => {
    const request = await axios.patch(
        `/credit/sse-bank-statement/${estimationId}/select-primary-bank-statement`,
        payload,
    )
    return request
}
