/* eslint-disable react/prop-types */
/* eslint-disable no-undef */


const FormBoxText = ({ onClick, type, textOne, textTwo}) => {
  return (
    <div>
        <>
            {type === 'phone' ? (
              <p
                className='FormBoxText'
                onClick={onClick}
              >
                {textOne}
              </p>
            ) : (
              <p
                className='FormBoxText'
                onClick={onClick}
              >
                {textTwo}
              </p>
            )}
          </>
    </div>
  )
}

export default FormBoxText
