import PropTypes from 'prop-types'
import styles from './description.module.scss'
import lightning from 'src/assets/images/lightning.svg'
import ChatCTA from 'src/components/ChatCTA'
import { decodeUserInfo } from 'src/utils/auth'
const Description = ({ title, subTitle, step = 1, durationInMins = 10 }) => {
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const imgSrc =
        step === 'identity-information' || step === 'financial-info'
            ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/new_partnerships-icon.svg'
            : step === 'basic-info' || step === 'outright-sale-info'
            ? lightning
            : step === 2
            ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/business-verification-illustration.png'
            : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/kyc_illustration_1.png'
    const { Wrapper } = styles
    return (
        <div className={Wrapper} data-testid="create-account-description">
            {!isAdminUser && (
                <div>
                    <h2>Set up your SunFi account</h2>
                    <p>This takes approximately {durationInMins}mins</p>
                </div>
            )}
            <img src={imgSrc} alt="description" height="234px" width="234px" />
            <div>
                <h2
                    style={{
                        color: '#FFFFFF',
                        fontFamily: isAdminUser ? 'SF-Pro-Display-Bold' : '',
                    }}
                >
                    {title}
                </h2>
                {!isAdminUser && <p>{subTitle}</p>}
            </div>
            {!isAdminUser && (
                <ChatCTA
                    text="Have questions about our process?"
                    textOneColor="#FFFFFF"
                    textOneFontSize={14}
                />
            )}
        </div>
    )
}

Description.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    step: PropTypes.any,
    durationInMins: PropTypes.number,
}

export default Description
