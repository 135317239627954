import '../addcomponents.scss'
import PropTypes from 'prop-types'
import DescriptionComponent from 'src/components/DescriptionComponent/DescriptionComponent'
import CostCard from '../../CostCard'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useEffect } from 'react'

const DescriptionAndPricing = ({
    selected,
    subtotal,
    margin,
    outrightSaleMargin,
    total,
    handleRemoveMargin,
    packagePaymentPlanTypes,
    totalForOutrightSale,
    subtotalForOutrightSale,
    handleRemoveMarginForOutrightSale,
    handleAddOrEditMargin,
}) => {
    const { width } = useMediaQueries()
    const cardWidth = width > 500 && width < 1200 ? 407 : '100%'

    useEffect(() => {
        const planTypes = packagePaymentPlanTypes?.map(each => each?.value)
        if (!planTypes?.includes('Outright sale')) {
            handleRemoveMarginForOutrightSale()
        }
        if (
            !planTypes?.includes('Lease to Own') &&
            !planTypes?.includes('Subscription')
        ) {
            handleRemoveMargin()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packagePaymentPlanTypes])

    return (
        <div>
            <DescriptionComponent
                type="package"
                text="Components are the building blocks of packages or your product library. Create components to enable faster creation of packages or customized solutions for customers."
                wrapperStyle={{
                    width: '100%',
                    transform: 'none',
                }}
            />
            <>
                {selected?.length > 0 &&
                    (packagePaymentPlanTypes?.find(
                        record => record?.value === 'Lease to Own',
                    ) ||
                        packagePaymentPlanTypes?.find(
                            record => record?.value === 'Subscription',
                        )) && (
                        <CostCard
                            title="FINANCING"
                            margin={margin}
                            total={total}
                            costOfComponents={subtotal}
                            selected={selected}
                            handleMargin={() =>
                                handleAddOrEditMargin('financing')
                            }
                            handleRemoveMargin={handleRemoveMargin}
                            styleMargin={'22px 0 0 0'}
                            width={cardWidth}
                        />
                    )}
                {selected?.length > 0 &&
                    packagePaymentPlanTypes?.find(
                        record => record?.value === 'Outright sale',
                    ) && (
                        <CostCard
                            title="OUTRIGHT SALE"
                            margin={outrightSaleMargin}
                            total={totalForOutrightSale}
                            costOfComponents={subtotalForOutrightSale}
                            selected={selected}
                            handleMargin={() =>
                                handleAddOrEditMargin('outright-sale')
                            }
                            handleRemoveMargin={
                                handleRemoveMarginForOutrightSale
                            }
                            styleMargin={'14px 0 0 0'}
                            width={cardWidth}
                        />
                    )}
            </>
        </div>
    )
}

DescriptionAndPricing.propTypes = {
    selected: PropTypes.arrayOf(PropTypes.object).isRequired,
    subtotal: PropTypes.number.isRequired,
    margin: PropTypes.shape({
        isExisting: PropTypes.bool,
        action: PropTypes.string,
        percentage: PropTypes.number,
        amount: PropTypes.number,
    }).isRequired,
    outrightSaleMargin: PropTypes.object,
    total: PropTypes.number.isRequired,
    handleRemoveMargin: PropTypes.func.isRequired,
    packagePaymentPlanTypes: PropTypes.array,
    totalForOutrightSale: PropTypes.number,
    subtotalForOutrightSale: PropTypes.number,
    handleRemoveMarginForOutrightSale: PropTypes.func,
    handleAddOrEditMargin: PropTypes.func,
}

export default DescriptionAndPricing
