import { useState, useEffect } from 'react'
import { articles } from './articles'
import styles from './educationalcontents.module.scss'

const EducationalContents = () => {
    const {
        Wrapper,
        BlogTitle,
        BlogPosts,
        ImgContainer,
        BlogDate,
        CurrentArticlesWrapper,
    } = styles

    const [currentArticles, setCurrentArticles] = useState([])

    useEffect(() => {
        setCurrentArticles(articles)
    }, [])

    const redirectTo = pageUrl => {
        window.location.href = pageUrl
    }

    return (
        <div className={Wrapper}>
            <div className={BlogPosts}>
                {currentArticles.map(article => {
                    return (
                        <div
                            key={Math.random()}
                            className={CurrentArticlesWrapper}
                            onClick={() => redirectTo(article.link)}
                        >
                            <img
                                src={article.url}
                                alt="icon"
                                className={ImgContainer}
                                onClick={() => redirectTo(article.link)}
                            />

                            <p
                                className={BlogTitle}
                                onClick={() => redirectTo(article.link)}
                            >
                                {article.text}
                            </p>
                            <p className={BlogDate}>{article.date}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default EducationalContents
