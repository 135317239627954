export function recommendationsErrorFormatter(error) {
    let formattedStr = ''

    const key = Object.keys(error)[0]
    if (!key) return formattedStr

    if (key !== 'appliances') {
        formattedStr = `${key}: ${error[key]} \n`
    } else {
        Object.keys(error[key]).forEach(appliance => {
            formattedStr += `appliance-${appliance}: ${error[key][appliance]} \n`
        })
    }
    return formattedStr
}

export function displaySummaryBody(toggledText, setToggledText) {
    const cb = document.querySelector('#consumer-summary')
    cb.className === 'SSEEnergySummaryBodyVisible'
        ? ((cb.className = 'SSEEnergySummaryBody'),
          (document.getElementById('arrow').className =
              'GYOEnergySummaryArrowRotateHidden'))
        : ((cb.className = 'SSEEnergySummaryBodyVisible'),
          (document.getElementById('arrow').className =
              'GYOEnergySummaryArrowRotateVisible'))
    setToggledText(toggledText === 'Close' ? 'See/add more' : 'Close')
}

export const handleSelectChange = (
    name,
    value,
    errors,
    setInputs,
    setSearch,
) => {
    setSearch(false)
    setInputs(prev => ({ ...prev, [name]: value }))
    if (errors[name]) {
        delete errors[name]
    }
}

export const handleInputChange = (event, setInputs, setErrors) => {
    const { name, value } = event.target

    if (setErrors) {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
    }

    setInputs(preState => ({
        ...preState,
        [name]: value,
    }))
}

export const handleSearch = (
    name,
    value,
    setInputs,
    setSearch,
    setSearchValue,
) => {
    if (value !== '') {
        setInputs(prev => ({ ...prev, [name]: value }))
    } else {
        setSearch(true)
        setSearchValue(value)
    }
}

export const actionModalValues = (action, firstName) => {
    switch (action) {
        case 'notify-user-who-has-not-started-kyc':
            return {
                actionModalTitle: 'Your deal is approved!',
                actionModalHeaderText: `Hi ${firstName}, you didn’t start your KYC the last time`,
                actionModalSubtitle:
                    'You’ve already selected a deal. Would you like to continue with the deal or get a new system?',
                actionModalActionType: 'store',
                actionModalPrimaryBtnText: 'Continue with old deal',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Get a new system',
            }
        case 'notify-user-who-has-started-kyc':
            return {
                actionModalTitle: 'You already have a deal',
                actionModalHeaderText: `Hi ${firstName}, you didn’t complete your KYC the last time`,
                actionModalSubtitle:
                    'You already have a deal in progress. Would you like to continue with the deal or get a new system?',
                actionModalActionType: 'store',
                actionModalPrimaryBtnText: 'Continue with old deal',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Get a new system',
            }
        case 'notify-user-with-an-approved-deal':
            return {
                actionModalTitle: 'You already have a deal',
                actionModalHeaderText: `Hi ${firstName}, would you like to continue with your deal?`,
                actionModalSubtitle:
                    'The last time you were here, your deal was approved but you didn’t select a payment plan. Would you like to resume with that or start a new deal?',
                actionModalActionType: 'store',
                actionModalPrimaryBtnText: 'Continue with old deal',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Get a new system',
            }
        case 'notify-user-with-an-active-or-closed-plan':
            return {
                actionModalTitle: 'Getting started on SunFi',
                actionModalHeaderText: `Hey ${firstName}, you have an existing account with us`,
                actionModalSubtitle:
                    'To get a new system, please sign in to continue with your existing account.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Sign in',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'confirm-discard-deal-for-user-who-has-not-started-kyc':
        case 'confirm-discard-deal-for-user-who-has-started-kyc':
        case 'confirm-discard-deal-for-user-with-an-approved-deal':
            return {
                actionModalTitle: 'Getting started on SunFi',
                actionModalHeaderText:
                    'We would have to discard your current deal',
                actionModalSubtitle:
                    'In order to get a new deal, we would have to discard your current application. Would you like to proceed?',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Continue',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Go back',
            }
        case 'discard-deal-success':
            return {
                actionModalTitle: 'Getting started on SunFi',
                actionModalHeaderText:
                    'We have successfully discarded your deal',
                actionModalSubtitle:
                    'We have successfully discarded your deal. You can go ahead to select a new package.',
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Proceed',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
    }
}
