import PropTypes from 'prop-types'
import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { regex } from './utils'

const nameError = {
    firstName: 'First Name',
    lastName: 'Last Name',
}

const AdditionalInfoInputModalContent = ({
    setAdditionalInputs,
    additionalInputs,
    additionalFieldErrors,
    setAdditionalFieldErrors,
}) => {
    const handleChange = e => {
        const { name, value } = e.target
        setAdditionalFieldErrors(prev => ({
            ...prev,
            [name]: '',
        }))

        setAdditionalInputs(inputs => ({
            ...inputs,
            [name]: value,
        }))
    }

    const handleInputBlur = e => {
        const { name, value } = e.target

        if (!value && name !== 'phone') {
            return setAdditionalFieldErrors(prevErrors => ({
                ...prevErrors,
                [name]: `${
                    name.includes('mail') ? 'email' : nameError[name]
                } is required`,
            }))
        }

        if (name.includes('mail') && !regex[name].test(value)) {
            setAdditionalFieldErrors(prevErrors => ({
                ...prevErrors,
                [name]: `Provide a valid email`,
            }))
        } else if (name === 'phone' && value && !regex[name].test(value)) {
            setAdditionalFieldErrors(prevErrors => ({
                ...prevErrors,
                [name]: `Provide a valid phone`,
            }))
        } else if (
            (name === 'lastName' || name === 'firstName') &&
            !regex['name'].test(value)
        ) {
            setAdditionalFieldErrors(prevErrors => ({
                ...prevErrors,
                [name]: `Provide a valid Name`,
            }))
        } else {
            setAdditionalFieldErrors(prevErrors => ({
                ...prevErrors,
                [name]: '',
            }))
        }
    }

    const handleSelectChange = (name, value) => {
        setAdditionalFieldErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }))
        setAdditionalInputs(prev => ({ ...prev, [name]: value }))
    }

    return (
        <div className="emailModalContent">
            <div>
                <h4 className="emailModalContent__head">
                    Add your contact details
                </h4>
                <p className="emailModalContent__text">
                    Please enter your contact details so we can notify you when
                    your alternative recommendation is ready
                </p>
            </div>

            <div className="emailModalContent__name">
                <InputFields
                    name="firstName"
                    value={additionalInputs.firstName}
                    handleChange={handleChange}
                    inputWidth={'158px'}
                    title="First Name"
                    errorMessage={additionalFieldErrors['firstName']}
                    onBlur={handleInputBlur}
                    marginBottom={'0px'}
                />
                <InputFields
                    name="lastName"
                    value={additionalInputs.lastName}
                    handleChange={handleChange}
                    inputWidth={'158px'}
                    title="Last Name"
                    errorMessage={additionalFieldErrors['lastName']}
                    onBlur={handleInputBlur}
                    marginBottom={'0px'}
                />
            </div>

            <InputFields
                name="email"
                value={additionalInputs.email}
                handleChange={handleChange}
                title="Email"
                errorMessage={additionalFieldErrors['email']}
                onBlur={handleInputBlur}
                marginBottom={'0px'}
            />

            <SelectCountryCode
                handleChange={handleChange}
                handleSelectChange={handleSelectChange}
                title="Phone number"
                name="phone"
                inputValue={additionalInputs.phone}
                errorMessage={additionalFieldErrors['phone']}
                onBlur={handleInputBlur}
                sseUse={true}
                marginBottom={'0px'}
            />
            <small className="emailModalContent__optional">Optional</small>
        </div>
    )
}

AdditionalInfoInputModalContent.propTypes = {
    setAdditionalInputs: PropTypes.func,
    additionalInputs: PropTypes.object,
    additionalFieldErrors: PropTypes.object,
    setAdditionalFieldErrors: PropTypes.func,
}

export default AdditionalInfoInputModalContent
