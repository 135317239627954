import PropTypes from 'prop-types'
import Asiprofile from '../ASIProfile'
import Disiprofile from '../DISIProfile'
import { decodeUserInfo } from '../../utils/auth'
import SeoComponent from '../../components/Seo'
import queryString from 'query-string'

const Profile = props => {
    const userInfo = decodeUserInfo()
    const parsed = queryString.parse(props?.location?.search)

    return (
        <>
            <SeoComponent
                title="Profile | SunFi | Simplifying and Scaling Clean Energy"
                tracker="UserProfileTracker"
            />
            {userInfo?.partnerType === 'ai' ||
            userInfo?.workspaceMember === 'true' ? (
                <Asiprofile parsed={parsed} />
            ) : (
                <Disiprofile parsed={parsed} />
            )}
        </>
    )
}
Profile.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
}

export default Profile
