import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/ResidenceForm/residenceform.scss'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import RemoveFileModal from '../../../../_components/RemoveFileModal'
import { ConsumerDateField } from 'src/components/InputFields'
import { format } from 'date-fns'
import UploadField from 'src/components/InputFields/UploadField'
import { IconInputField } from 'src/components/InputFields/CostField'
import LinkIcon from 'src/assets/images/link.svg'
import { extractFileNameFromUrl } from 'src/utils/formatting'
import UploadModal from 'src/components/UploadModal'
import { employmentContractFileTypes } from 'src/utils/supportedFileTypes'

const EmploymentDetails = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    showRemoveFileModal,
    fileToBeRemoved,
    closeRemoveFileModal,
    closeRemoveFileModalAfterSuccess,
    showCalendar,
    setShowCalendar,
    setShowUploadModal,
    handleRemoveFile,
    employmentContract,
    setEmploymentContract,
    showUploadModal,
    handleCloseUploadModal,
    successEmploymentContractUpload,
    setSuccessEmploymentContractUpload,
    isCustomerApproved,
}) => {
    return (
        <>
            <UploadModal
                showModal={showUploadModal}
                onCancel={handleCloseUploadModal}
                title={'Employment Contract'}
                primaryBtnText={'Confirm'}
                successUpload={successEmploymentContractUpload}
                setSuccessUpload={setSuccessEmploymentContractUpload}
                acceptableFileTypes={employmentContractFileTypes}
                setFileToState={setEmploymentContract}
            />

            <RemoveFileModal
                showModal={showRemoveFileModal}
                fileToBeRemoved={fileToBeRemoved}
                closeRemoveFileModal={closeRemoveFileModal}
                closeRemoveFileModalAfterSuccess={
                    closeRemoveFileModalAfterSuccess
                }
            />

            <Label
                title={'Employment details'}
                subTitle={'Provide us with more details about your work'}
            />
            <VerticalSpacer top={12} />

            <div className="DynamicInfoDateFieldWrapper">
                <ConsumerDateField
                    title="When did you start at the company"
                    width={'100%'}
                    name="jobStartDate"
                    caretStyle="caretStyle"
                    dateValue={
                        inputs?.jobStartDate?.length === 0
                            ? null
                            : new Date(inputs?.jobStartDate)
                    }
                    value={inputs?.jobStartDate}
                    errorMessage={errors?.jobStartDate}
                    handleDateChange={(name, date) => {
                        handleChange({
                            target: {
                                name: 'jobStartDate',
                                value: format(new Date(date), 'MMM dd, yyyy'),
                            },
                        })
                    }}
                    closeCalendarHandler={() => {
                        setShowCalendar(false)
                        if (!inputs.jobStartDate) {
                            handleOnBlur({
                                target: {
                                    name: 'jobStartDate',
                                    value: inputs.jobStartDate,
                                },
                            })
                        }
                    }}
                    openCalendar={showCalendar}
                    openCalendarHandler={() => {
                        setShowCalendar(true)
                    }}
                    disabled={isAdminUser || isCustomerApproved}
                />
            </div>

            <>
                <UploadField
                    btnText="Employment contract"
                    handleUploadClick={() => setShowUploadModal(true)}
                    handleRemoveClick={() => {
                        if (inputs?.employmentContractUrl?.length > 0) {
                            handleRemoveFile(
                                'employment-contract',
                                'employment contract',
                                inputs?.employmentContractUrl,
                            )
                        } else {
                            setEmploymentContract([])
                            if (inputs?.employmentContractUrl?.length > 0) {
                                handleChange({
                                    name: 'employmentContractUrl',
                                    value: '',
                                })
                            }
                        }
                    }}
                    fileUploaded={
                        inputs?.employmentContractUrl?.length > 0 ||
                        employmentContract?.length > 0
                    }
                    fileName={
                        extractFileNameFromUrl(inputs?.employmentContractUrl) ||
                        employmentContract?.[0]?.name
                    }
                    margin={'0 0 20px 0'}
                    disabled={isAdminUser || isCustomerApproved}
                    dataTestIdSuffix={'employment-details'}
                />
                <p className="OptionalTypoGraphy">Optional</p>
            </>

            <>
                <IconInputField
                    icon={LinkIcon}
                    name="linkedinPage"
                    title="Linkedin Page"
                    width={'100%'}
                    value={inputs?.linkedinPage}
                    handleChange={handleChange}
                    errorMessage={errors?.linkedinPage}
                    disabled={isAdminUser || isCustomerApproved}
                />
                {!errors?.linkedinPage && (
                    <p className="OptionalTypoGraphy">Optional</p>
                )}
            </>
        </>
    )
}

EmploymentDetails.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default EmploymentDetails
