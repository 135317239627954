export const detectDescriptionLabel = (type, isAdminUser) => {
    const businessPersonalDescription = {
        title: isAdminUser
            ? "Customer's business information"
            : 'Add your business information',
        subTitle:
            'To create your account, we need you to provide your business and contact information',
    }
    const residentialPersonalDescription = {
        title: isAdminUser
            ? "Customer's Personal and Employment Information"
            : 'Add your personal and employment Info',
        subTitle:
            'To create your account, we need you to provide your personal and employment information',
    }
    const financialDescription = {
        title: 'Credit Check',
        subTitle: `We need to run a credit check to be sure you're eligible for financing. 
        This does not give us access to your bank account, as a financing company, we use this to verify your credit history.`,
    }
    const residentialVerificationDescription = {
        title: 'Identity Information',
        subTitle:
            'In order to verify your identity, we will need your picture and ID card. Please provide these details for the verification process',
    }

    switch (type) {
        case 'business':
            return businessPersonalDescription
        case 'residential':
            return residentialPersonalDescription
        case 'financial':
            return financialDescription
        case 'residential-verification':
            return residentialVerificationDescription
        default:
            return ''
    }
}

export const UPDATE_REQUEST_MESSAGE_MAP = {
    business__business_name: 'business name',
    business__business_type: 'business type',
    business__business_industry: 'business industry',
    business__business_registration_number: 'CAC number',
    business__years_in_operation: 'number of years in operation',
    business__business_description: 'business description',
    business__monthly_revenue: 'monthly revenue',
    first_name: 'first name',
    last_name: 'last name',
    phone_number: 'phone number',
    email: 'email',
    street_address: 'address',
    country: 'country',
    state_of_residence: 'state',
    city: 'city',
    installation_details__street_address: 'installation address',
    installation_details__country: 'installation country',
    installation_details__state: 'installation state',
    installation_details__city: 'installation city',
    bvn: 'BVN',
    business__memorandum_of_association: 'MOA',
    business__cac_certificate: 'CAC',
    bank_statement: 'bank statement',
    financial_analysis_statements: 'audit report',
    repayment_method: 'repayment method',
    avatar_url: 'photo',
    identity_type: 'identity type',
    identity_number: 'identity number',
    employment_type: 'employment type',
    employment_details__company_name: 'company name',
    employment_details__job_title: 'job title',
    employment_details__employment_start_date: 'employment start date',
    pension_details__pension_manager: 'pension manager',
    pension_details__retirement_date: 'retirement date',
    director_details__first_name: "director's first name",
    director_details__last_name: "director's last name",
    director_details__phone_number: "director's phone number",
    director_details__email: "director's email",
}

export const BUSINESS_INFO_UPDATE_REQUESTS_MAP = {
    businessName: 'business__business_name',
    businessType: 'business__business_type',
    businessIndustry: 'business__business_industry',
    cacNumber: 'business__business_registration_number',
    numberOfYearsInOperation: 'business__years_in_operation',
    businessDescription: 'business__business_description',
    firstName: 'first_name',
    lastName: 'last_name',
    phone: 'phone_number',
    email: 'email',
    directorsFirstName: 'director_details__first_name',
    directorsLastName: 'director_details__last_name',
    directorsPhoneNumber: 'director_details__phone_number',
    directorsEmail: 'director_details__email',
    businessAddress: 'street_address',
    businessCountry: 'country',
    businessState: 'state_of_residence',
    businessCity: 'city',
    installationAddress: 'installation_details__street_address',
    installationCountry: 'installation_details__country',
    installationState: 'installation_details__state',
    installationCity: 'installation_details__city',
}

export const BUSINESS_VERIFICATION_UPDATE_REQUESTS_MAP = {
    bvn: 'bvn',
    moa: 'business__memorandum_of_association',
    cac: 'business__cac_certificate',
}

export const BUSINESS_FINANCIAL_INFO_UPDATE_REQUESTS_MAP = {
    monthly_revenue: 'business__monthly_revenue',
    bankStatement: 'bank_statement',
    audit_report: 'financial_analysis_statements',
    payment_method: 'repayment_method',
}

export const RESIDENTIAL_BASIC_INFO_UPDATE_REQUESTS_MAP = {
    firstName: 'first_name',
    lastName: 'last_name',
    phone: 'phone_number',
    email: 'email',
    contactAddress: 'street_address',
    contactCountry: 'country',
    contactState: 'state_of_residence',
    contactCity: 'city',
    installationAddress: 'installation_details__street_address',
    installationCountry: 'installation_details__country',
    installationState: 'installation_details__state',
    installationCity: 'installation_details__city',
    employmentType: 'employment_type',
    companyName: 'employment_details__company_name',
    jobTitle: 'employment_details__job_title',
    jobStartDate: 'employment_details__employment_start_date',
    pensionManager: 'pension_details__pension_manager',
    retirementDate: 'pension_details__retirement_date',
    businessName: 'business__business_name',
    businessType: 'business__business_type',
    businessIndustry: 'business__business_industry',
    cacNumber: 'business__business_registration_number',
    numberOfYearsInOperation: 'business__years_in_operation',
    businessDescription: 'business__business_description',
}

export const RESIDENTIAL_IDENTITY_INFO_UPDATE_REQUESTS_MAP = {
    imageUrl: 'avatar_url',
    identityType: 'identity_type',
    identityNumber: 'identity_number',
}

export const RESIDENTIAL_FINANCIAL_INFO_UPDATE_REQUESTS_MAP = {
    bankStatement: 'bank_statement',
    payment_method: 'repayment_method',
    bvnError: 'bvn',
}

export const determineVerificationHelpValues = type => {
    switch (type) {
        case 'cac':
            return {
                name: 'CAC',
            }
        case 'bvn': {
            return {
                name: 'BVN',
            }
        }
        case 'ID': {
            return {
                name: 'ID',
            }
        }
    }
}
