/* eslint-disable react/prop-types */

import Paragraph from "../components/Paragraph";
import parse from 'html-react-parser'
import { Badge } from 'antd'
import { 
    ASITermsOfService, 
    DISITermsOfService, 
    OEMITermsOfService, 
    ConsumerTermsOfService 
} from "../pages/TermsOfService/data";

export const ASI  = ({signUpPageModal}) => (
   <div className={signUpPageModal && 'TOSWrapper'}>
       { ASITermsOfService.map((data, i) => (
        <div
            className={"InformationsContainer"}
            key={i}
        >
            <Paragraph
                count={i === 0 ? null : `${i + 0}.`}
                title={data.title}
                size="20px"
                marginBottom="20px"
                titleLineHeight="36px"
                textLineHeight="28px"
                fontSize={signUpPageModal && '15px'}
                text={
                    data.text
                        ? parse(data.text)
                        : ''
                }
            >
                {data.subParagraph &&
                    data.subParagraph.map(
                        (sub, j) => (
                            <div
                                className="SubParagraph"
                                key={j}
                            >
                                {data.type ===
                                'dotted-list' ? (
                                    <Badge
                                        className="badge"
                                        color="var(--deepblue)"
                                        text={parse(
                                            sub.subtext,
                                        )}
                                    ></Badge>
                                ) : data.type ===
                                  'numbered-list' ? (
                                    <Paragraph
                                        textCount={`${
                                            j + 1
                                        }.`}
                                        size="18px"
                                        fontSize={signUpPageModal && '15px'}
                                        textLineHeight="28px"
                                        text={parse(
                                            sub
                                                .subtext
                                                ?.text ||
                                                sub.subtext,
                                        )}
                                        textChildrenType={
                                            sub
                                                .subtext
                                                ?.type
                                        }
                                    />
                                ) : data.type ===
                                  'decimal-numbered-list' ? (
                                    <Paragraph
                                        textCount={`${
                                            i + 0
                                        }.${j + 1}`}
                                        size="18px"
                                        fontSize={signUpPageModal && '15px'}
                                        textLineHeight="28px"
                                        text={parse(
                                            sub
                                                .subtext
                                                ?.text ||
                                                sub.subtext,
                                        )}
                                        textChildren={
                                            sub
                                                .subtext
                                                .children
                                        }
                                        textChildrenType={
                                            sub
                                                .subtext
                                                ?.type
                                        }
                                    />
                                ) : (
                                    <Paragraph
                                        count={`${
                                            i + 1
                                        }.${j + 1}`}
                                        title={
                                            sub.subtitle
                                        }
                                        textChildrenType={
                                            sub
                                                .subtext
                                                ?.type
                                        }
                                        size= "18px"
                                        fontSize={signUpPageModal && '15px'}
                                        text={parse(
                                            sub
                                                .subtext
                                                ?.text ||
                                                sub.subtext,
                                        )}
                                    />
                                )}
                            </div>
                        ),
                    )}
            </Paragraph>
        </div>
    ))}
   </div>
)

export const DISI = ({signUpPageModal}) => (
   <div className={signUpPageModal && "TOSWrapper"}>
       {
            DISITermsOfService.map((data, i) => (
                <div
                    className="InformationsContainer"
                    key={i}
                >
                    <Paragraph
                        count={i === 0 ? null : `${i + 0}.`}
                        title={data.title}
                        size="20px"
                        marginBottom="20px"
                        titleLineHeight="36px"
                        textLineHeight="28px"
                        fontSize={signUpPageModal && '15px'}
                        text={
                            data.text
                                ? parse(data.text)
                                : ''
                        }
                    >
                        {data.subParagraph &&
                            data.subParagraph.map(
                                (sub, j) => (
                                    <div
                                        className="SubParagraph"
                                        key={j}
                                    >
                                        {data.type ===
                                        'dotted-list' ? (
                                            <Badge
                                                className="badge"
                                                color="var(--deepblue)"
                                                text={parse(
                                                    sub.subtext,
                                                )}
                                            ></Badge>
                                        ) : data.type ===
                                          'numbered-list' ? (
                                            <Paragraph
                                                textCount={`${
                                                    j + 1
                                                }.`}
                                                size= "18px"
                                                textLineHeight="28px"
                                                fontSize={signUpPageModal && '15px'}
                                                text={parse(
                                                    sub
                                                        .subtext
                                                        ?.text ||
                                                        sub.subtext,
                                                )}
                                                textChildrenType={
                                                    sub
                                                        .subtext
                                                        ?.type
                                                }
                                            />
                                        ) : data.type ===
                                          'decimal-numbered-list' ? (
                                            <Paragraph
                                                textCount={`${
                                                    i + 0
                                                }.${j + 1}`}
                                                size="18px"
                                                fontSize={signUpPageModal && '15px'}
                                                textLineHeight="28px"
                                                text={parse(
                                                    sub
                                                        .subtext
                                                        ?.text ||
                                                        sub.subtext,
                                                )}
                                                textChildren={
                                                    sub
                                                        .subtext
                                                        .children
                                                }
                                                textChildrenType={
                                                    sub
                                                        .subtext
                                                        ?.type
                                                }
                                            />
                                        ) : (
                                            <Paragraph
                                                count={`${
                                                    i + 1
                                                }.${j + 1}`}
                                                title={
                                                    sub.subtitle
                                                }
                                                textChildrenType={
                                                    sub
                                                        .subtext
                                                        ?.type
                                                }
                                                size= "18px"
                                                fontSize={signUpPageModal && '15px'}
                                                text={parse(
                                                    sub
                                                        .subtext
                                                        ?.text ||
                                                        sub.subtext,
                                                )}
                                            />
                                        )}
                                    </div>
                                ),
                            )}
                    </Paragraph>
                </div>
            ))
       }
   </div>
)

export const OEMI = ({signUpPageModal}) => (
   <div className={signUpPageModal && "TOSWrapper"}>
       {
            OEMITermsOfService.map((data, i) => (
                <div
                    className="InformationsContainer"
                    key={i}
                >
                    <Paragraph
                        count={i === 0 ? null : `${i + 0}.`}
                        title={data.title}
                        size="20px"
                        marginBottom="20px"
                        titleLineHeight="36px"
                        fontSize={signUpPageModal && '15px'}
                        textLineHeight="28px"
                        text={
                            data.text
                                ? parse(data.text)
                                : ''
                        }
                    >
                        {data.subParagraph &&
                            data.subParagraph.map(
                                (sub, j) => (
                                    <div
                                        className="SubParagraph"
                                        key={j}
                                    >
                                        {data.type ===
                                        'dotted-list' ? (
                                            <Badge
                                                className="badge"
                                                color="var(--deepblue)"
                                                text={parse(
                                                    sub.subtext,
                                                )}
                                            ></Badge>
                                        ) : data.type ===
                                          'numbered-list' ? (
                                            <Paragraph
                                                textCount={`${
                                                    j + 1
                                                }.`}
                                                size= "18px"
                                                fontSize={signUpPageModal && '15px'}
                                                textLineHeight="28px"
                                                text={parse(
                                                    sub
                                                        .subtext
                                                        ?.text ||
                                                        sub.subtext,
                                                )}
                                                textChildrenType={
                                                    sub
                                                        .subtext
                                                        ?.type
                                                }
                                            />
                                        ) : data.type ===
                                          'decimal-numbered-list' ? (
                                            <Paragraph
                                                textCount={`${
                                                    i + 0
                                                }.${j + 1}`}
                                                size="18px"
                                                fontSize={signUpPageModal && '15px'}
                                                textLineHeight="28px"
                                                text={parse(
                                                    sub
                                                        .subtext
                                                        ?.text ||
                                                        sub.subtext,
                                                )}
                                                textChildren={
                                                    sub
                                                        .subtext
                                                        .children
                                                }
                                                textChildrenType={
                                                    sub
                                                        .subtext
                                                        ?.type
                                                }
                                            />
                                        ) : (
                                            <Paragraph
                                                count={`${
                                                    i + 1
                                                }.${j + 1}`}
                                                title={
                                                    sub.subtitle
                                                }
                                                textChildrenType={
                                                    sub
                                                        .subtext
                                                        ?.type
                                                }
                                                size="18px"
                                                fontSize={signUpPageModal && '15px'}
                                                text={parse(
                                                    sub
                                                        .subtext
                                                        ?.text ||
                                                        sub.subtext,
                                                )}
                                            />
                                        )}
                                    </div>
                                ),
                            )}
                    </Paragraph>
                </div>
            ))
       }
   </div>
)

export const Consumer = ({signUpPageModal}) => (
 <div className={signUpPageModal && "TOSWrapper"}>
     {
            ConsumerTermsOfService.map((data, i) => (
                <div
                    className="InformationsContainer"
                    key={i}
                >
                    <Paragraph
                        count={i === 0 ? null : `${i + 0}.`}
                        title={data.title}
                        size="20px"
                        marginBottom="20px"
                        titleLineHeight="36px"
                        textLineHeight="28px"
                        fontSize={signUpPageModal && '15px'}
                        text={
                            data.text
                                ? parse(data.text)
                                : ''
                        }
                    >
                        {data.subParagraph &&
                            data.subParagraph.map(
                                (sub, j) => (
                                    <div
                                        className="SubParagraph"
                                        key={j}
                                    >
                                        {data.type ===
                                        'dotted-list' ? (
                                            <Badge
                                                className="badge"
                                                color="var(--deepblue)"
                                                text={parse(
                                                    sub.subtext,
                                                )}
                                            ></Badge>
                                        ) : data.type ===
                                          'numbered-list' ? (
                                            <Paragraph
                                                textCount={`${
                                                    j + 1
                                                }.`}
                                                size="18px"
                                                fontSize={signUpPageModal && '15px'}
                                                textLineHeight="28px"
                                                text={parse(
                                                    sub
                                                        .subtext
                                                        ?.text ||
                                                        sub.subtext,
                                                )}
                                                textChildrenType={
                                                    sub
                                                        .subtext
                                                        ?.type
                                                }
                                            />
                                        ) : data.type ===
                                          'decimal-numbered-list' ? (
                                            <Paragraph
                                                textCount={`${
                                                    i + 0
                                                }.${j + 1}`}
                                                size="18px"
                                                fontSize={signUpPageModal && '15px'}
                                                textLineHeight="28px"
                                                text={parse(
                                                    sub
                                                        .subtext
                                                        ?.text ||
                                                        sub.subtext,
                                                )}
                                                textChildren={
                                                    sub
                                                        .subtext
                                                        .children
                                                }
                                                textChildrenType={
                                                    sub
                                                        .subtext
                                                        ?.type
                                                }
                                            />
                                        ) : (
                                            <Paragraph
                                                count={`${
                                                    i + 1
                                                }.${j + 1}`}
                                                title={
                                                    sub.subtitle
                                                }
                                                textChildrenType={
                                                    sub
                                                        .subtext
                                                        ?.type
                                                }
                                                size="18px"
                                                fontSize={signUpPageModal && '15px'}
                                                text={parse(
                                                    sub
                                                        .subtext
                                                        ?.text ||
                                                        sub.subtext,
                                                )}
                                            />
                                        )}
                                    </div>
                                ),
                            )}
                    </Paragraph>
                </div>
            ))
     }
 </div>
)