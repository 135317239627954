import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import { useEffect, useState } from 'react'
import ContactInfo from './ContactInfo'
import CompanyInfo from './CompanyInfo'
import Security from './Security'
import BankDetails from '../Profile/BankDetails'
import { decodeUserInfo } from '../../utils/auth'

import './disiprofile.scss'
import ComponentBrands from '../Profile/ComponentBrands'

const DISIProfile = ({ parsed }) => {
    const [currentTab, setCurrentTab] = useState('1')
    const { TabPane } = Tabs
    const userInfo = decodeUserInfo()

    const canUseComponentBrands =
        process.env.REACT_APP_FLAG_SHOW_COMPONENT_BRANDS === 'true'

    useEffect(() => {
        if (Object.keys(parsed).length > 0) {
            switch (parsed.tab) {
                case 'component-brands':
                    setCurrentTab('3')
                    break
            }
        }
    }, [parsed])

    return (
        <div className="DISIProfileWrapper">
            <h1 className="DISIProfileTitle">My Profile</h1>

            <Tabs
                defaultActiveKey="1"
                className="ProfileTab"
                activeKey={currentTab}
                onChange={values => {
                    setCurrentTab(values)
                }}
            >
                <TabPane tab="Contact Info" key="1">
                    <ContactInfo />
                </TabPane>
                <TabPane tab="Company's Contact" key="2">
                    <CompanyInfo />
                </TabPane>
                {canUseComponentBrands && (
                    <TabPane tab={'Component Brands'} key={'3'}>
                        <ComponentBrands />
                    </TabPane>
                )}
                {userInfo?.isAdminWorkspaceUser !== 'true' && (
                    <TabPane
                        tab="Bank Details"
                        key={canUseComponentBrands ? '4' : '3'}
                    >
                        <BankDetails />
                    </TabPane>
                )}
                <TabPane
                    tab="Security"
                    key={
                        canUseComponentBrands
                            ? userInfo?.isAdminWorkspaceUser === 'true'
                                ? `4`
                                : `5`
                            : userInfo?.isAdminWorkspaceUser === 'true'
                            ? `3`
                            : `4`
                    }
                >
                    <Security />
                </TabPane>
            </Tabs>
        </div>
    )
}

DISIProfile.propTypes = {
    parsed: PropTypes.object,
}

export default DISIProfile
