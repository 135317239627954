import { useEffect, useState } from 'react'
import { getRecommendedSolutions } from 'src/api/admin/payment-plans/ssePlan'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { useQuery } from 'react-query'

const useGetSolutions = (estimationId, recommendationType) => {
    const [solutions, setSolutions] = useState([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [count, setCount] = useState(0)
    const { errorAlert } = useCustomToast()

    const { isFetching: isRecommendedSolutionsLoading, refetch } = useQuery({
        queryKey: ['plan-recommended-solutions', estimationId],
        queryFn: () => getRecommendedSolutions(estimationId, page),
        retry: false,
        enabled:
            estimationId !== (null || undefined) &&
            recommendationType !== 'alternative',
        onSuccess: data => {
            const solutions = data?.data

            setSolutions(solutions?.data)
            setPage(solutions?.current_page)
            setTotalPages(solutions?.total_pages)
            setCount(solutions?.count)
        },
        onError: error => {
            errorAlert(
                errorHandler(error.response?.data) ||
                    `Couldn't fetch solutions. please try again`,
            )
        },
    })

    useEffect(() => {
        if (recommendationType !== 'alternative') {
            refetch()
        }
    }, [page, refetch, recommendationType])

    return {
        solutions,
        isRecommendedSolutionsLoading,
        page,
        setPage,
        totalPages,
        setTotalPages,
        count,
    }
}

export default useGetSolutions
