import '../accountsetupoverview.scss'
import { Drawer } from 'antd'
import PropTypes from 'prop-types'
import { useState } from 'react'
import arrow from 'src/assets/images/Caret-Circle-Down.svg'
import { packageDrawerItems, providerDetailsList } from '../data'
import SelectedSolutionCard from './SelectedSolutionCard'
import Checkbox from 'src/components/Checkbox'
import Button from 'src/components/Button'
import { format, parseISO } from 'date-fns'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useMutation } from 'react-query'
import { acceptPackageApi } from 'src/api/shoppingExperience/account-overview'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { InlineLoader } from 'src/components/Loader'

const PackageDrawer = ({
    open,
    estimation,
    goBackToSystem,
    handleDisputePackage,
    handleAcceptPackage,
    AppliancesFromProvider,
    hasPaidOutrightly,
}) => {
    const [isOpen, setIsOpen] = useState(
        Array(packageDrawerItems.length).fill(false),
    )
    const [checked, setChecked] = useState(false)
    const { errorAlert, successAlert } = useCustomToast()
    const { isMobile } = useMediaQueries()

    const noOfItems = packageDrawerItems.length

    const toggleBox = index => {
        setIsOpen(prevOpen => {
            const newOpen = [...prevOpen]
            newOpen[index] = !newOpen[index]
            return newOpen
        })
        //close all other boxes
        for (let i = 0; i < isOpen.length; i++) {
            if (i !== index) {
                setIsOpen(prevOpen => {
                    const newOpen = [...prevOpen]
                    newOpen[i] = false
                    return newOpen
                })
            }
        }
    }

    const acceptPackageMutation = useMutation({
        mutationKey: ['accept_package'],
        mutationFn: () => acceptPackageApi(estimation?.id),
        onSuccess: () => {
            handleAcceptPackage()
            successAlert(
                'You have successfully confirmed this deal',
                isMobile ? 320 : 550,
            )
        },
        onError: err => {
            errorAlert(errorHandler(err?.response?.data, isMobile ? 320 : 400))
        },
    })
    return (
        <Drawer
            open={open}
            closable={false}
            width={532}
            className={'ASO_Package_Drawer'}
            footer={
                <>
                    <div className={'ASO_Package_Drawer_Checkbox'}>
                        <Checkbox
                            isChecked={checked}
                            handleChecked={() => setChecked(!checked)}
                        />
                        <p role="button" onClick={() => setChecked(!checked)}>
                            I have read and looked through the information
                            submitted on my behalf from my provider.
                        </p>
                    </div>
                    <div className={'ASO_Package_Drawer_Buttons'}>
                        <Button
                            btnBgColorOutline={'#FFFFFF'}
                            btnTextColorOutline={'#004AAD'}
                            btnWidth={'133px'}
                            btnHeight={'53px'}
                            type="outline"
                            disabled={!checked}
                            handleClick={() => handleDisputePackage()}
                        >
                            I don&apos;t agree
                        </Button>
                        <Button
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            btnWidth={'124px'}
                            btnHeight={'53px'}
                            marginLeft={'auto'}
                            disabled={!checked}
                            handleClick={() => acceptPackageMutation.mutate()}
                        >
                            {acceptPackageMutation.isLoading ? (
                                <InlineLoader />
                            ) : (
                                'Confirm'
                            )}
                        </Button>
                    </div>
                </>
            }
            footerStyle={{
                background: '#FFFFFF',
                border: 'none',
                padding: 0,
                zIndex: 1,
            }}
        >
            <div className={'ASO_Package_Drawer_Content'}>
                <h2>See more information about your package</h2>
                <h3>
                    Heres all the info your provider has added for you on SunFi
                </h3>
                <div className={'ASO_Package_Drawer_Content_Dropdowns'}>
                    <h3>See more information about your selected package</h3>

                    {/* dropdown items */}
                    {packageDrawerItems?.map((item, i) => (
                        <div
                            className={'ASO_Package_Drawer_Dropdown'}
                            key={i}
                            style={{
                                borderWidth:
                                    i === noOfItems - 1
                                        ? '1px'
                                        : '1px 1px 0px 1px',
                                borderRadius:
                                    i === 0
                                        ? '8px 8px 0px 0px'
                                        : i === noOfItems - 1
                                        ? '0px 0px 8px 8px'
                                        : '0px',
                                display: i === 0 ? 'none' : '',
                            }}
                        >
                            <div className={'ASO_Package_Drawer_Dropdown_Top'}>
                                <div>
                                    <h4>{item?.title}</h4>
                                    <p>{item?.details}</p>
                                </div>
                                <img
                                    role="button"
                                    src={arrow}
                                    alt="arrow"
                                    onClick={() => toggleBox(i)}
                                    className={
                                        isOpen[i]
                                            ? 'ASO_Package_Drawer_Dropdown_Icon_Open'
                                            : 'ASO_Package_Drawer_Dropdown_Icon_Closed'
                                    }
                                />
                            </div>
                            <div
                                className={`ASO_Package_Drawer_Dropdown_Bottom ${
                                    isOpen[i]
                                        ? 'ASO_Package_Drawer_Dropdown_Bottom_Open'
                                        : 'ASO_Package_Drawer_Dropdown_Bottom_Closed'
                                }`}
                            >
                                {i === 0 ? (
                                    <></>
                                ) : i === 1 ? (
                                    <SelectedSolutionCard
                                        estimation={estimation}
                                        goBackToSystem={goBackToSystem}
                                        hasPaidOutrightly={hasPaidOutrightly}
                                    />
                                ) : i === 2 ? (
                                    <div className="APDDB">
                                        {isMobile && (
                                            <h4>YOUR ADDED APPLIANCES</h4>
                                        )}
                                        <AppliancesFromProvider />
                                    </div>
                                ) : (
                                    <div
                                        className={
                                            'ASO_Package_Drawer_ProviderDetails'
                                        }
                                    >
                                        <div
                                            className={
                                                'ASO_Package_Drawer_ProviderDetails_Top'
                                            }
                                        >
                                            <div>
                                                <span>
                                                    {
                                                        estimation?.provider_initials
                                                    }
                                                </span>
                                            </div>
                                            <div>
                                                <h4>
                                                    {estimation?.provider_name}
                                                </h4>
                                                <p>
                                                    Since{' '}
                                                    {estimation.provider_created_at !==
                                                        undefined &&
                                                        estimation.provider_created_at !==
                                                            null &&
                                                        format(
                                                            parseISO(
                                                                estimation.provider_created_at,
                                                            ),
                                                            'MMMM yyyy',
                                                        )}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'ASO_Package_Drawer_ProviderDetails_Bottom'
                                            }
                                        >
                                            {providerDetailsList(
                                                estimation,
                                            )?.map((item, i) => (
                                                <div key={i}>
                                                    <img
                                                        src={item?.icon}
                                                        alt={item?.alt}
                                                    />
                                                    <h4>{item?.title}</h4>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Drawer>
    )
}

PackageDrawer.propTypes = {
    open: PropTypes.bool,
    estimation: PropTypes.object,
    goBackToSystem: PropTypes.func,
    handleDisputePackage: PropTypes.func,
    handleAcceptPackage: PropTypes.func,
    AppliancesFromProvider: PropTypes.node,
    hasPaidOutrightly: PropTypes.bool,
}

export default PackageDrawer
