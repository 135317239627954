import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    page: 1,
    name: '',
    state: '',
    start_date: null,
    end_date: null,
    start_power_rating: '',
    end_power_rating: '',
    start_users_count: '',
    end_users_count: '',
    measurement: '',
    current_tab: '1',
    filter_mode: 'false',
}

const adminSettingsListing = createSlice({
    name: 'adminSettingsListingFilter',
    initialState,
    reducers: {
        updateAdminSettingsFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetAdminSettingsFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateAdminSettingsFilters, resetAdminSettingsFilters } =
    adminSettingsListing.actions

export const updatedAdminSettingsFilters = state =>
    state.adminSettingsListingFilter

const persistConfig = {
    key: 'adminSettingsListingFilter',
    storage,
}

export const persistedAdminSettingsListingReducer = persistReducer(
    persistConfig,
    adminSettingsListing.reducer,
)

export default adminSettingsListing.reducer
