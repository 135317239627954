// convert number strings with comma to numbers
export const removeCommaFromNum = num => {
    return Number(num?.replace(/,/g, ''))
}

// format phone number to not include the +234
export const removeCountryCode = number => {
    if (number) {
        const formattedNumber = number?.split('+234')
        if (formattedNumber?.[1]) {
            return '0' + formattedNumber[1]
        } else {
            return number
        }
    }
}
