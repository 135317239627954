import { useMemo } from 'react'
import useUpdateRequests from './useUpdateRequests'

const useMappedErrors = FIELD_NAME_MAP => {
    const { requestMsg } = useUpdateRequests()

    const errors = useMemo(() => {
        let newErrors = {}
        for (const [inputFieldName, messageKeyName] of Object.entries(
            FIELD_NAME_MAP,
        )) {
            if (requestMsg[messageKeyName]) {
                newErrors[inputFieldName] = requestMsg[messageKeyName]
            }
        }
        return newErrors
    }, [requestMsg, FIELD_NAME_MAP])

    return errors
}

export default useMappedErrors
