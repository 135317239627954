import { useHistory, useLocation } from 'react-router-dom'
import BackNav from 'src/components/BackNav'
import SeoComponent from 'src/components/Seo'
import DescriptionComponent from 'src/components/DescriptionComponent/DescriptionComponent'
import { SelectField } from 'src/components/InputFields'
import s from '../components/PageForm/pageform.module.scss'
import ButtonComponent, { ConsumerButton } from 'src/components/Button'
import { ReactComponent as QuestionIcon } from 'src/assets/images/question-mark-circle.svg'
import { useGetCreditScoreCard } from 'src/api/credit/queries'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateConsumerProfile,
    updatedConsumerProfile,
} from 'src/redux/reducers/admin/profiles/create-profile'
import { useGetAdminClusterOriginationApp } from 'src/api/cluster-origination/queries'
import { ReactComponent as PencilIcon } from 'src/assets/images/pencil-icon.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { Skeleton, Switch } from 'antd'
import { employmentTypeList, kycModuleMapping } from '../data'
import EmptyState from 'src/components/EmptyState'
import { useUpdateProfile } from '../hooks/useUpdateProfile'
import ActionModal from 'src/components/ActionModal'

const ProfileKYCInformation = () => {
    const history = useHistory()
    const location = useLocation()
    const isEdit = location.pathname.includes('edit')
    const profile_id = location?.state?.profile_id
    const dispatch = useDispatch()
    const consumer_profile_data = useSelector(updatedConsumerProfile)
    const isPublicProfile =
        consumer_profile_data?.promotional_type?.toLowerCase() === 'public' &&
        consumer_profile_data?.is_promotional === true
    const { creditScoreCardData, creditScoreCardFetching } =
        useGetCreditScoreCard({
            enabled: true,
            customer_type: consumer_profile_data?.customer_type,
        })
    const [creditScoreName, setCreditScoreName] = useState(
        consumer_profile_data?.credit_scorecard_name ?? '',
    )
    const [creditScore, setCreditScore] = useState(
        consumer_profile_data?.credit_scorecard_id ?? '',
    )
    const [employmentType, setEmploymentType] = useState('FULL_TIME')
    const [kycModule, setKycModule] = useState(
        (location?.state?.modules || consumer_profile_data?.modules) ?? [],
    )
    const [showKycModal, setShowKycModal] = useState({
        open: false,
        component: {},
    })
    const [showActionModal, setShowActionModal] = useState(false)

    const { getAdminClusterOriginationAppFetching } =
        useGetAdminClusterOriginationApp({
            id: creditScore,
            enabled: creditScore.length ? true : false,
            onSuccess: data => {
                setKycModule(data?.modules)
            },
        })

    const handleSelectChange = (name, value, option) => {
        if (name === 'employment_type') {
            setEmploymentType(value)
        } else {
            setCreditScore(value)
            setCreditScoreName(option?.children?.props?.children)
        }
    }

    const closeKycModal = () => {
        setShowKycModal({ open: false, component: {} })
    }

    const { handleUpdateProfile, isLoading: updateProfileLoading } =
        useUpdateProfile({
            data: {
                cluster_origination_setup: {
                    credit_scorecard_id:
                        creditScore || location?.state?.credit_scorecard_id,
                    modules: kycModule?.map((item, i) => ({
                        id: item?.id,
                        section: item?.section?.filter(item => {
                            return {
                                key: item.key,
                                required: item.required,
                            }
                        }),
                        order: i + 1,
                    })),
                },
                id: profile_id,
            },
            onSuccess: () => {
                setShowActionModal(true)
            },
        })

    const handleNext = () => {
        if (isEdit) {
            handleUpdateProfile()
        } else {
            dispatch(
                updateConsumerProfile({
                    credit_scorecard_id: creditScore,
                    credit_scorecard_name: creditScoreName,
                    modules: kycModule,
                    employmentType: employmentType,
                }),
            )
            isPublicProfile
                ? history.push({
                      pathname: `/admin/consumer-profiles/add-cluster-banner`,
                  })
                : history.push({
                      pathname: `/admin/consumer-profiles/add-cluster-information`,
                  })
        }
    }

    return (
        <>
            <SeoComponent title="SunFi | Admin | Manage Consumer Profile" />
            <ActionModal
                actionModalOpen={showActionModal}
                headerText="Consumer profile updated"
                subTitle="You have successfully edited this consumer profile"
                actionType="success"
                noCancelBtn
                noBackLink
                actionText="Done"
                actionHandler={() => setShowActionModal(false)}
                closable
                onCancel={() => setShowActionModal(false)}
                closeModal={() => setShowActionModal(false)}
            />

            <SecondaryModal
                closable={true}
                showModal={showKycModal?.open}
                onCancel={closeKycModal}
                modalWidth="624px"
                content={
                    <div className={'kyc__module_modal_wrapper'}>
                        <div>
                            <h2>Edit this KYC module</h2>
                            <h3>
                                Edit the sections under the added KYC module
                            </h3>
                        </div>
                        <div>
                            <div>
                                <h5>
                                    Edit the {showKycModal?.component?.title}{' '}
                                    KYC module
                                </h5>
                                <h6>
                                    You can switch any section between required
                                    and optional.
                                </h6>
                            </div>
                            {showKycModal?.component?.section?.map(
                                (section, i) => {
                                    return (employmentType === 'FULL_TIME' &&
                                        [
                                            'pension_details',
                                            'business',
                                        ].includes(section.key)) ||
                                        (employmentType === 'SELF_EMPLOYED' &&
                                            [
                                                'pension_details',
                                                'work_info',
                                                'employee_data',
                                                'employment_details',
                                            ].includes(section.key)) ||
                                        (employmentType === 'RETIRED' &&
                                            [
                                                'work_info',
                                                'employee_data',
                                                'employment_details',
                                                'business',
                                            ].includes(section.key)) ? null : (
                                        <div key={i}>
                                            <div className="kyc_modal_list">
                                                <p>
                                                    {
                                                        kycModuleMapping[
                                                            section?.key
                                                        ]
                                                    }
                                                </p>
                                                <div>
                                                    <span
                                                        style={{
                                                            color: !section?.required
                                                                ? '#004aad'
                                                                : '',
                                                        }}
                                                    >
                                                        Optional
                                                    </span>
                                                    <Switch
                                                        checked={
                                                            section?.required
                                                        }
                                                        id={i}
                                                        tabIndex={i}
                                                        onChange={(v, e) => {
                                                            let newComponent
                                                            newComponent = {
                                                                ...showKycModal?.component,
                                                                section:
                                                                    showKycModal.component.section?.map(
                                                                        (
                                                                            item,
                                                                            i,
                                                                        ) =>
                                                                            i ==
                                                                            e
                                                                                .currentTarget
                                                                                .id
                                                                                ? {
                                                                                      ...showKycModal
                                                                                          .component
                                                                                          .section[
                                                                                          e
                                                                                              .currentTarget
                                                                                              .id
                                                                                      ],
                                                                                      required:
                                                                                          v,
                                                                                  }
                                                                                : item,
                                                                    ),
                                                            }
                                                            setShowKycModal(
                                                                prev => {
                                                                    return {
                                                                        ...prev,
                                                                        component:
                                                                            newComponent,
                                                                    }
                                                                },
                                                            )
                                                        }}
                                                    />
                                                    <span
                                                        style={{
                                                            color: section?.required
                                                                ? '#004aad'
                                                                : '',
                                                        }}
                                                    >
                                                        Required
                                                    </span>
                                                </div>
                                            </div>
                                            {section?.key ===
                                                'employment_type' && (
                                                <SelectField
                                                    name="employment_type"
                                                    selectWidth="100%"
                                                    initialOption="Select Employment type"
                                                    floatingLabel={
                                                        'Select Employment type'
                                                    }
                                                    floatLabelOnSelectedValue
                                                    optionStyleNoCheckbox={{
                                                        color: '#233862',
                                                    }}
                                                    contentField="name"
                                                    dropdownPositionRelative
                                                    withCheckBox
                                                    currentSelected={
                                                        employmentType
                                                    }
                                                    marginTop={'10px'}
                                                    value={employmentType}
                                                    values={employmentTypeList}
                                                    handleChange={
                                                        handleSelectChange
                                                    }
                                                />
                                            )}
                                        </div>
                                    )
                                },
                            )}
                        </div>
                        <div>
                            <ButtonComponent
                                btnBgColor={'#E2EEFF'}
                                btnWidth={'160px'}
                                btnHeight={'56px'}
                                btnTextColor={'#004AAD'}
                                marginTop={'24px'}
                                handleClick={closeKycModal}
                            >
                                Cancel
                            </ButtonComponent>
                            <ButtonComponent
                                btnBgColor={'#004AAD'}
                                btnWidth={'160px'}
                                btnHeight={'56px'}
                                btnTextColor={'#FFFFFF'}
                                marginTop={'24px'}
                                handleClick={() => {
                                    setKycModule(
                                        kycModule?.map(item =>
                                            item.id ===
                                            showKycModal?.component?.id
                                                ? showKycModal?.component
                                                : item,
                                        ),
                                    )
                                    closeKycModal()
                                }}
                            >
                                Save this component
                            </ButtonComponent>
                        </div>
                    </div>
                }
            />

            <div className="NewProfileContainer">
                <BackNav title={'Go Back'} onClick={() => history.goBack()} />
                <div className="NewProfileContentWrapper">
                    <div className="KYCFormWrapper">
                        <p className="NewProfileTitle">
                            Enter profile KYC information
                        </p>
                        <div className={s.FormSection}>
                            <div>
                                <div className="space-between">
                                    <h4>
                                        Add credit engine score card template
                                    </h4>
                                    <span className="align-items-center">
                                        <QuestionIcon />
                                    </span>
                                </div>
                                <SelectField
                                    name="credit_profile"
                                    selectWidth="100%"
                                    initialOption="Select credit profile"
                                    floatingLabel={'Select credit profile'}
                                    floatLabelOnSelectedValue
                                    optionStyleNoCheckbox={{ color: '#233862' }}
                                    contentField="name"
                                    dropdownPositionRelative
                                    withCheckBox
                                    disabled={creditScoreCardFetching}
                                    currentSelected={
                                        creditScore ||
                                        location?.state?.credit_scorecard_id
                                    }
                                    value={
                                        creditScore ||
                                        location?.state?.credit_scorecard_id
                                    }
                                    values={creditScoreCardData?.map(item => ({
                                        name: item.title,
                                        value: item.id,
                                    }))}
                                    handleChange={handleSelectChange}
                                />
                            </div>
                            <div>
                                <div className="space-between">
                                    <h4>Add KYC modules</h4>
                                    <span className="align-items-center">
                                        <QuestionIcon />
                                    </span>
                                </div>
                                {getAdminClusterOriginationAppFetching ? (
                                    <div className="kyc_module_wrapper">
                                        <div>
                                            <div>
                                                <Skeleton.Input />
                                            </div>
                                            <div>
                                                <Skeleton.Input
                                                    style={{
                                                        marginTop: 16,
                                                        height: 30,
                                                    }}
                                                />
                                                <Skeleton.Input
                                                    style={{
                                                        marginTop: 16,
                                                        height: 30,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div>
                                            <Skeleton />
                                        </div> */}
                                    </div>
                                ) : !getAdminClusterOriginationAppFetching &&
                                  kycModule?.length === 0 ? (
                                    <div>
                                        <EmptyState
                                            title={'No kyc module'}
                                            subTitle={'Select a credit profile'}
                                            type="noActivity"
                                        />
                                    </div>
                                ) : (
                                    <div className="kyc_module_wrapper">
                                        {kycModule?.map(item => (
                                            <div key={item?.id}>
                                                <div>
                                                    <h2>{item?.title}</h2>
                                                    <ConsumerButton
                                                        btnWidth={'81px'}
                                                        btnHeight={'29px'}
                                                        btnBgColor={'#F9FCFF'}
                                                        btnTextColor={'#004AAD'}
                                                        hover
                                                        type={'iconBtn'}
                                                        btnFontSize={'12px'}
                                                        fontFamily={
                                                            'SF-Pro-Display-Medium'
                                                        }
                                                        handleClick={() => {
                                                            setShowKycModal({
                                                                open: true,
                                                                component: item,
                                                            })
                                                        }}
                                                    >
                                                        <PencilIcon /> Edit
                                                    </ConsumerButton>
                                                </div>
                                                <div>
                                                    <h3>Added components</h3>
                                                    <div>
                                                        {item?.section?.map(
                                                            (section, i) =>
                                                                (employmentType ===
                                                                    'FULL_TIME' &&
                                                                    [
                                                                        'pension_details',
                                                                        'business',
                                                                    ].includes(
                                                                        section?.key,
                                                                    )) ||
                                                                (employmentType ===
                                                                    'SELF_EMPLOYED' &&
                                                                    [
                                                                        'pension_details',
                                                                        'work_info',
                                                                        'employee_data',
                                                                        'employment_details',
                                                                    ].includes(
                                                                        section?.key,
                                                                    )) ||
                                                                (employmentType ===
                                                                    'RETIRED' &&
                                                                    [
                                                                        'work_info',
                                                                        'employee_data',
                                                                        'employment_details',
                                                                        'business',
                                                                    ].includes(
                                                                        section?.key,
                                                                    )) ? null : (
                                                                    <div
                                                                        key={i}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                kycModuleMapping[
                                                                                    section
                                                                                        ?.key
                                                                                ]
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                ),
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <ButtonComponent
                            btnBgColor={'#004AAD'}
                            btnWidth={'160px'}
                            btnHeight={'56px'}
                            btnTextColor={'#FFFFFF'}
                            disabled={!isEdit && creditScore.length <= 0}
                            loading={updateProfileLoading}
                            handleClick={handleNext}
                        >
                            {isEdit ? 'Save' : 'Continue'}
                        </ButtonComponent>
                    </div>
                    <div className="NewProfileDescriptionWrapper">
                        <DescriptionComponent text="Enter the details of the new consumer profile you want to create. Ensure you choose the correct profile type and system size." />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileKYCInformation
