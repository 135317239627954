import PropTypes from 'prop-types'
import Verify from 'src/components/GettingStarted/components/Verify'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import Toast from 'src/components/Toast'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const VerifyContactModal = ({
    showModal,
    onCancel,
    contact,
    inputs,
    otp,
    setOtp,
    otpError,
    handleResendOtp,
    verifyContactToast,
    resent,
    setResent,
    handleOtpSubmit,
    loading,
}) => {
    const { isMobile } = useMediaQueries()

    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={onCancel}
            standardWidth={476}
            standardHeight={524}
            title={`Verify your ${contact}`}
            primaryBtnText={`Verify ${contact}`}
            handlePrimaryBtnClick={handleOtpSubmit}
            primaryBtnWidth={isMobile ? '120px' : '160px'}
            primaryBtnHeight={isMobile ? '45px' : '53px'}
            primaryBtnLoading={loading}
        >
            <>
                {verifyContactToast?.showToast && (
                    <Toast
                        message={verifyContactToast?.toastMessage}
                        messageType={verifyContactToast?.messageType}
                    />
                )}
                <Verify
                    contact={contact}
                    otp={otp}
                    setOtp={setOtp}
                    otpError={otpError}
                    inputs={inputs}
                    handleResendOtp={handleResendOtp}
                    resent={resent}
                    setResent={setResent}
                />
            </>
        </ConsumerModalBase>
    )
}

VerifyContactModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    contact: PropTypes.oneOf(['phone', 'email', '']),
    inputs: PropTypes.object,
    otp: PropTypes.array,
    setOtp: PropTypes.func,
    otpError: PropTypes.bool,
    handleResendOtp: PropTypes.func,
    verifyContactToast: PropTypes.object,
    resent: PropTypes.bool,
    setResent: PropTypes.func,
    handleOtpSubmit: PropTypes.func,
    loading: PropTypes.bool,
}

export default VerifyContactModal
