import { appTracking } from 'src/utils/appTracker'
import { consumerKYCEvents } from 'src/utils/mp-event-tracker/consumer-kyc'

const pageUrlName = window.location.pathname
const pageTracker = 'KYC_SSE'

export function formatErrorMessages(errors) {
    let errorMessage = ''

    for (const errorObject of errors) {
        for (const field in errorObject) {
            const fieldErrors = errorObject[field]
            const formattedErrors = fieldErrors
                .map(error => `${field}: ${error}`)
                .join('\n')
            errorMessage += `${formattedErrors}\n`
        }
    }

    return errorMessage
}

export function checkConfirmAddress(contactInfo) {
    if (!contactInfo) return ''

    if (contactInfo?.businessAddressAndInstallationAddressAreSame === 'yes') {
        return 'yes'
    } else if (
        contactInfo?.businessAddressAndInstallationAddressAreSame === 'no'
    ) {
        if (
            !contactInfo?.businessAddress ||
            !contactInfo?.businessCity ||
            !contactInfo?.businessState ||
            !contactInfo?.businessCountry
        ) {
            return ''
        } else {
            return 'no'
        }
    }
}

export const handleAppTrackingOnKYC = (event, pageTitle) => {
    appTracking(
        pageUrlName,
        pageTracker,
        pageTitle,
        consumerKYCEvents[event].action,
        consumerKYCEvents[event].label,
        consumerKYCEvents[event].category,
        ['MP', 'GA'],
        'event',
    )
}
