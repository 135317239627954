import PropTypes from 'prop-types'
import styles from '../../../components/LearnContentDetail/learncontent.module.scss'
const { ContentParagraphs, subListHeaderName } = styles

const TwelveMeta = ({ subListHeader, bulletArticle2, conclusions }) => {
    return (
        <>
            <h4
                className={subListHeaderName}
                dangerouslySetInnerHTML={{
                    __html: subListHeader,
                }}
            />

            {bulletArticle2}

            {conclusions?.map((information, index) => {
                return (
                    <p
                        key={`${index}-paragraph-text-content`}
                        className={ContentParagraphs}
                        dangerouslySetInnerHTML={{
                            __html: information,
                        }}
                    ></p>
                )
            })}
        </>
    )
}

TwelveMeta.propTypes = {
    subListHeader: PropTypes.string,
    bulletArticle2: PropTypes.string,
    conclusions: PropTypes.array,
}

export default TwelveMeta
