import PropTypes from 'prop-types'
import { ConsumerButton } from 'src/components/Button'
import { getGreetingTime } from 'src/utils/formatting'
import { ReactComponent as AddIcon } from 'src/assets/images/add-icon-no-stroke.svg'
import s from './styles.module.scss'
import BackNav from 'src/components/BackNav'
import { isMobile } from 'src/utils/mediaQueries'
import { Dropdown, Menu } from 'antd'
import MenuItem from 'antd/lib/menu/MenuItem'
import { ReactComponent as DropdownIcon } from 'src/assets/images/arrow-down-icon.svg'

const ActionsPanel = ({
    openAddMembersModal,
    pageAction,
    handleChangePageAction,
    storeActionAndOpenModal,
    firstName,
    handleSaveClusterMembers,
    disableActionBtn,
}) => {
    const dropdownMenu = (
        <Menu>
            <MenuItem onClick={() => handleChangePageAction('make-payment')}>
                <span>Make due payments</span>
            </MenuItem>
            <MenuItem
                onClick={() =>
                    storeActionAndOpenModal(
                        'upload-multiple-credit-report-request',
                    )
                }
            >
                <span>Upload credit reports</span>
            </MenuItem>
        </Menu>
    )
    return (
        <div className={s.wrapper}>
            {pageAction === 'view-dashboard' && (
                <>
                    <h2 className={s.greeting}>
                        Good {getGreetingTime()}, {firstName}
                    </h2>
                    <div className={s.btns}>
                        <Dropdown trigger={['click']} overlay={dropdownMenu}>
                            <div className={s.otherActions}>
                                <span>Other Actions</span>
                                <DropdownIcon style={{ cursor: 'pointer' }} />
                            </div>
                        </Dropdown>
                        <ConsumerButton
                            btnWidth={isMobile ? '100%' : '146px'}
                            btnHeight={'44px'}
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            hoverNoFill
                            type={'iconBtn'}
                            handleClick={openAddMembersModal}
                        >
                            <AddIcon style={{ stroke: 'white' }} />
                            Add members
                        </ConsumerButton>
                    </div>
                </>
            )}
            {pageAction === 'make-payment' && (
                <>
                    <div className={s.leftContent}>
                        <div className={s.navAndBtn}>
                            <BackNav
                                v2
                                title="Go back"
                                onClick={() =>
                                    handleChangePageAction('view-dashboard')
                                }
                                marginBottom={10}
                            />
                            <ConsumerButton
                                btnWidth={'125px'}
                                btnHeight={'44px'}
                                btnBgColor={'#004AAD'}
                                btnTextColor={'#FFFFFF'}
                                disabled={disableActionBtn()}
                                handleClick={() =>
                                    storeActionAndOpenModal(
                                        'make-group-payment-confirmation-request',
                                    )
                                }
                            >
                                Make payment
                            </ConsumerButton>
                        </div>
                        <h2>All your members with due payment</h2>
                        <p>
                            Below is a list of all your group members with due
                            payment.
                        </p>
                    </div>
                    <div className={s.desktopBtn}>
                        <ConsumerButton
                            btnWidth={'200px'}
                            btnHeight={'44px'}
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            disabled={disableActionBtn()}
                            handleClick={() =>
                                storeActionAndOpenModal(
                                    'make-group-payment-confirmation-request',
                                )
                            }
                        >
                            Make payment for members
                        </ConsumerButton>
                    </div>
                </>
            )}
            {pageAction === 'upload-multiple-credit-report' && (
                <>
                    <div className={s.leftContent}>
                        <div className={s.navAndBtn}>
                            <BackNav
                                v2
                                title="Go back"
                                onClick={() =>
                                    handleChangePageAction('view-dashboard')
                                }
                                marginBottom={10}
                            />
                            <ConsumerButton
                                btnWidth={'125px'}
                                btnHeight={'44px'}
                                btnBgColor={'#004AAD'}
                                btnTextColor={'#FFFFFF'}
                                handleClick={() =>
                                    storeActionAndOpenModal(
                                        'confirm-multiple-credit-reports-upload',
                                    )
                                }
                                disabled={disableActionBtn()}
                            >
                                Confirm Upload
                            </ConsumerButton>
                        </div>
                        <h2>Review your added credit report</h2>
                        <p>
                            Below is a list of your newly added credit reports.
                        </p>
                    </div>
                    <div className={s.desktopBtn}>
                        <ConsumerButton
                            btnWidth={'123px'}
                            btnHeight={'44px'}
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            handleClick={() =>
                                storeActionAndOpenModal(
                                    'confirm-multiple-credit-reports-upload',
                                )
                            }
                            disabled={disableActionBtn()}
                        >
                            Confirm Upload
                        </ConsumerButton>
                    </div>
                </>
            )}
            {pageAction === 'upload-multiple-cluster-members' && (
                <>
                    <div className={s.leftContent}>
                        <div className={s.navAndBtn}>
                            <BackNav
                                v2
                                title="Go back"
                                onClick={() =>
                                    storeActionAndOpenModal(
                                        'save-multiple-cluster-members-warning',
                                    )
                                }
                                marginBottom={10}
                            />
                            <ConsumerButton
                                btnWidth={'125px'}
                                btnHeight={'44px'}
                                btnBgColor={'#004AAD'}
                                btnTextColor={'#FFFFFF'}
                                disabled={disableActionBtn()}
                                handleClick={handleSaveClusterMembers}
                            >
                                Save members
                            </ConsumerButton>
                        </div>
                        <h2>Review your new group members</h2>
                        <p>
                            Below is a list of your newly added group members,
                            with status of each member
                        </p>
                    </div>
                    <div className={s.multipleMemberBtns}>
                        <div className={s.desktopBtn}>
                            <ConsumerButton
                                btnWidth={'200px'}
                                btnHeight={'44px'}
                                btnBgColor={'#004AAD'}
                                btnTextColor={'#FFFFFF'}
                                disabled={disableActionBtn()}
                                handleClick={handleSaveClusterMembers}
                            >
                                Save all added members
                            </ConsumerButton>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

ActionsPanel.propTypes = {
    openAddMembersModal: PropTypes.func,
    pageAction: PropTypes.string,
    handleChangePageAction: PropTypes.func,
    storeActionAndOpenModal: PropTypes.func,
    firstName: PropTypes.string,
    storeActionAndOpenUploadModal: PropTypes.func,
    handleSaveClusterMembers: PropTypes.func,
    disableActionBtn: PropTypes.func,
}

export default ActionsPanel
