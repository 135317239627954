import { useState, useEffect } from 'react'
import { InputFields, SelectField, TextArea } from 'src/components/InputFields'
import { InlineLoader } from 'src/components/Loader'
import { useQuery } from 'react-query'
import Toast from 'src/components/Toast'
import Button from 'src/components/Button'
import {
    getDisiOemiInfoApi,
    companyInfoApi,
} from 'src/api/companyInfo/index.js'
import { CompanyInfoValidationSchema } from 'src/utils/validationSchema'
import { stateData } from 'src/utils/stateData'
import businessTypeData from 'src/utils/Data/businessType'
import sizeData from 'src/utils/Data/companySize'
import { removeEmptyFieldFromObject } from 'src/utils/formatting'

import styles from './companyinfo.module.scss'

const DISICompanyInfo = () => {
    const {
        Wrapper,
        Row,
        LeftWrapper,
        Header,
        SubHeader,
        BasicInfo,
        SaveBtn,
        PhoneNumberWrapper,
        States,
    } = styles

    const [errors, setErrors] = useState({})
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [inputs, setInputs] = useState({
        address: '',
        city: '',
        state: '',
        website: '',
        businessType: '',
        size: '',
        companyName: '',
        businessInfo: '',
    })

    const handleInputChange = event => {
        const { name, value } = event.target

        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleBlur = event => {
        const { name, value } = event.target

        setInputs(preState => ({
            ...preState,
            [name]: value.trim(),
        }))
    }

    const handleSelectChange = (name, value) => {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleSubmit = () => {
        setToastError(false)
        setErrors({})

        CompanyInfoValidationSchema()
            .validate(inputs, { abortEarly: false })
            .then(() => {
                setErrors('')
                refetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const { isFetching, data, refetch } = useQuery(
        'companyInfo',
        () =>
            companyInfoApi(
                removeEmptyFieldFromObject({
                    company_name: inputs.companyName,
                    staff_strength: inputs.size,
                    company_street_address: inputs.address,
                    state: inputs.state,
                    city: inputs.city,
                    business_type: inputs.businessType,
                    company_website: inputs.website,
                    business_info: inputs.businessInfo,
                    is_update: true,
                }),
            ),
        {
            enabled: false,
            retry: false,
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to update information. Try Again')
            },
            onSuccess: () => {
                setSuccessMessage('Your profile has been updated')
            },
        },
    )

    const { refetch: getInfoRefetch } = useQuery(
        'getDisiOemiInfoApi',
        () => getDisiOemiInfoApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setInputs(preState => ({
                    ...preState,
                    companyName: data?.data?.data?.company_name,
                    address: data?.data?.data?.company_street_address,
                    businessType: data?.data?.data?.business_type,
                    website: data?.data?.data?.company_website,
                    city: data?.data?.data?.city,
                    state: data?.data?.data?.state,
                    size: data?.data?.data?.staff_strength,
                    businessInfo: data?.data?.data.business_info,
                }))
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to get your information. Refresh Page')
            },
        },
    )

    useEffect(() => {
        setToastError(false)
        getInfoRefetch()

        // eslint-disable-next-line
    }, [])

    return (
        <div className={Wrapper}>
            {toastError ? (
                <Toast messageType="error" message={errorMessage} />
            ) : data ? (
                <Toast messageType="success" message={successMessage} />
            ) : (
                ''
            )}
            <div className={Row}>
                <div className={LeftWrapper}>
                    <p className={Header}>Basic Information</p>
                    <p className={SubHeader}>
                        Please enter your company information
                    </p>
                </div>

                <div className={BasicInfo}>
                    <InputFields
                        inputWidth="100%"
                        title="Company's Name"
                        name="companyName"
                        inputFieldWrapperStyle={{ marginBottom: '-10px' }}
                        handleChange={handleInputChange}
                        value={inputs.companyName}
                        inputValue={inputs.companyName}
                        errorMessage={errors?.companyName}
                        onBlur={handleBlur}
                    />
                    <SelectField
                        selectWidth="100%"
                        initialOption={inputs.size || 'Staff Strength or size'}
                        selectedValue={inputs.size}
                        name="size"
                        currentSelected={inputs.size}
                        handleChange={handleSelectChange}
                        inputFieldWrapperStyle={{ marginBottom: '-10px' }}
                        values={sizeData}
                        withCheckBox={true}
                        marginBottom="10px"
                        errorMessage={errors?.size}
                        dropdownPositionRelative
                    />
                    <SelectField
                        selectWidth="100%"
                        initialOption={inputs.businessType || 'Business Type'}
                        selectedValue={inputs.businessType}
                        name="businessType"
                        currentSelected={inputs.businessType}
                        handleChange={handleSelectChange}
                        inputFieldWrapperStyle={{ marginBottom: '-10px' }}
                        values={businessTypeData}
                        withCheckBox={true}
                        marginBottom="10px"
                        errorMessage={errors?.businessType}
                        dropdownPositionRelative
                    />
                    <InputFields
                        inputWidth="100%"
                        title="Company's Website"
                        inputFieldWrapperStyle={{ marginBottom: '-10px' }}
                        handleChange={handleInputChange}
                        inputValue={inputs.website}
                        value={inputs.website}
                        errorMessage={errors.website}
                        name="website"
                        onBlur={handleBlur}
                    />
                    <TextArea
                        height="146px"
                        name="businessInfo"
                        placeholder="Business Info"
                        rows={10}
                        maxHeight={193}
                        value={inputs.businessInfo}
                        handleChange={handleInputChange}
                    />
                </div>
            </div>
            <div className={PhoneNumberWrapper}>
                <div className={LeftWrapper}>
                    <p className={Header}>Address</p>
                    <p className={SubHeader}>Enter your address</p>
                </div>
                <div className={BasicInfo}>
                    <div>
                        <InputFields
                            inputWidth="100%"
                            title="Street Address"
                            name="address"
                            handleChange={handleInputChange}
                            inputValue={inputs.address}
                            value={inputs.address}
                            errorMessage={errors.address}
                            onBlur={handleBlur}
                        />
                        <div className={States}>
                            <div style={{ width: '100%' }}>
                                <InputFields
                                    inputWidth="100%"
                                    title="City"
                                    name="city"
                                    handleChange={handleInputChange}
                                    inputValue={inputs.city}
                                    value={inputs.city}
                                    errorMessage={errors.city}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div style={{ width: '100%' }}>
                                <SelectField
                                    values={stateData.map(option => ({
                                        value: option,
                                    }))}
                                    name="state"
                                    initialOption={inputs.state || 'State'}
                                    withCheckBox={true}
                                    showSearch
                                    selectWidth="100%"
                                    handleChange={handleSelectChange}
                                    currentSelected={inputs.state}
                                    selectedValue={inputs.state}
                                    errorMessage={errors.state}
                                    dropdownPositionRelative
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={SaveBtn}>
                <Button
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnWidth="160px"
                    btnHeight="56px"
                    handleClick={handleSubmit}
                >
                    {isFetching ? <InlineLoader /> : 'Save Changes'}
                </Button>
            </div>
        </div>
    )
}

export default DISICompanyInfo
