/* eslint-disable no-undef */

// gtag: Global Site Tag
// https://www.npmjs.com/package/ga-gtag
const gtagUtils = function () {
    const environments = ['production']
    if (environments.includes(process.env.REACT_APP_NODE_ENV)) {
        const globalSiteTag = require('ga-gtag')
        const GLOBAL_SITE_TAG_ID = process.env.REACT_APP_GLOBAL_SITE_TAG_ID
        globalSiteTag.install(GLOBAL_SITE_TAG_ID)
        globalSiteTag.gtag('js', new Date())
        globalSiteTag.gtag('config', GLOBAL_SITE_TAG_ID)
    }
}
export default gtagUtils
