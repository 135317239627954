import ButtonComponent from 'src/components/Button'
import styles from '../../Details/profilesdetails.module.scss'
import PropTypes from 'prop-types'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import Card from 'src/components/Card'

const PackagesList = ({
    packages,
    isProfileDetailsLoading,
    handleEditPackage,
    paginationBlock,
}) => {
    return (
        <div className="PackageList">
            <p className="InfoHead">Packages Added</p>

            {isProfileDetailsLoading ? (
                <ListingsSkeletalCards total={1} columnCount={4} />
            ) : (
                <>
                    <table className={`${styles.table} PackageTable`}>
                        {packages?.data?.map((row, i) => (
                            <tr key={i} className={styles.tableRow}>
                                <td>{row?.name}</td>
                                <td>{row?.amount}</td>
                                <td>
                                    <ButtonComponent
                                        btnTextColor={'#004AAD'}
                                        btnWidth={'100px'}
                                        btnHeight={'29px'}
                                        btnBgColor={'#FFFFFF'}
                                        handleClick={() =>
                                            handleEditPackage(row)
                                        }
                                    >
                                        Edit package
                                    </ButtonComponent>
                                </td>
                            </tr>
                        ))}
                    </table>
                    <div className="Cards">
                        {packages?.data?.map((row, i) => (
                            <Card
                                key={i}
                                topLeftHeading={'PACKAGE NAME'}
                                topLeftContent={row?.name}
                                bottomLeftHeading={'PACKAGE AMOUNT'}
                                bottomLeftContent={row?.amount}
                                btnText="Edit Package"
                                handleClick={() => handleEditPackage(row)}
                            />
                        ))}
                    </div>
                </>
            )}

            {!isProfileDetailsLoading && packages?.total_pages > 1 && (
                <div className="justify-center">{paginationBlock()}</div>
            )}
        </div>
    )
}

PackagesList.propTypes = {
    packages: PropTypes.object,
    isProfileDetailsLoading: PropTypes.bool,
    handleEditPackage: PropTypes.any,
    paginationBlock: PropTypes.element,
}

export default PackagesList
