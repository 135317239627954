/* eslint-disable react/prop-types */
import './bankdetailstable.scss'
import EmptyState from '../../../../../components/EmptyState'
import { titleCase } from '../../../../../utils/formatting'
import { isMobile } from 'src/utils/mediaQueries'

const ProviderBankDetailsTable = ({ data }) => {
    return (
        <>
            {data?.bank_account === null ? (
                <EmptyState subTitle="This provider has not submitted their bank details." />
            ) : (
                <div className="AdminProviderBankDetailsTableItem">
                    <h4>{titleCase(data?.bank_account_name)}</h4>
                    <p>
                        {data?.bank_account}
                        {isMobile && <br />}({titleCase(data?.bank_name)})
                    </p>
                </div>
            )}
        </>
    )
}
export default ProviderBankDetailsTable
