import { useState } from 'react'
import { useUpdateProviderBrands } from '../../hooks/useUpdateProviderBrands'

export const useReviewBrandsCard = ({
    handleRefetch,
    componentType,
    providerId,
    handleConfirmBrandingDecision,
}) => {
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [actionModalType, setActionModalType] = useState('')
    const [approvedComponentBrands, setApprovedComponentBrands] = useState([])
    const [rejectedComponentBrands, setRejectedComponentBrands] = useState([])
    const [selectedComonentBrands, setSelectedComponentBrands] = useState([])
    const [editBrandsModalOpen, setEditBrandsModalOpen] = useState(false)

    const { handleUpdateProviderBrands, isLoading } = useUpdateProviderBrands({
        showAlert: false,
        onSuccess: () => {
            setActionModalType('approve-success')
            setActionModalOpen(true)

            setApprovedComponentBrands([])
            setRejectedComponentBrands([])
            setSelectedComponentBrands([])
            handleRefetch && handleRefetch()
            handleConfirmBrandingDecision && handleConfirmBrandingDecision()
        },
    })

    const handleAddApprovedComponentBrand = brand => {
        setSelectedComponentBrands([brand])
        setActionModalType('approve-brand')
        setActionModalOpen(true)
    }

    const handleAddRejectedComponentBrand = brand => {
        setSelectedComponentBrands([brand])
        setActionModalType('decline-brand')
        setActionModalOpen(true)
    }

    const handleUndoSelection = () => {
        setApprovedComponentBrands([])
        setRejectedComponentBrands([])
    }

    const handleApproveAllClick = componentBrands => {
        setSelectedComponentBrands(componentBrands)
        setActionModalType('approve-all-brands')
        setActionModalOpen(true)
    }

    const handleSaveThisReviewClick = () => {
        setSelectedComponentBrands([
            ...approvedComponentBrands,
            ...rejectedComponentBrands,
        ])
        setActionModalType('save-review')
        setActionModalOpen(true)
    }

    const handleActionModalCancel = () => {
        setActionModalOpen(false)
        setActionModalType('')
        setSelectedComponentBrands([])
    }

    const handleClickEditBrand = () => {
        setEditBrandsModalOpen(true)
    }

    const actionModalHandler = () => {
        switch (actionModalType) {
            case 'approve-brand':
                setApprovedComponentBrands(prev => {
                    const cloned = Array.from(
                        new Set([...prev, selectedComonentBrands[0]]),
                    )

                    return cloned
                })
                setActionModalOpen(false)
                setActionModalType('')
                setSelectedComponentBrands([])
                break

            case 'decline-brand':
                setRejectedComponentBrands(prev => {
                    const cloned = Array.from(
                        new Set([...prev, selectedComonentBrands[0]]),
                    )

                    return cloned
                })
                setActionModalOpen(false)
                setActionModalType('')
                setSelectedComponentBrands([])
                break

            case 'approve-all-brands': {
                const updateData = selectedComonentBrands.map(brand => ({
                    id: brand.id,
                    status: 'APPROVED',
                }))

                handleUpdateProviderBrands({
                    providerId,
                    data: [
                        {
                            component_type_id: componentType.id,
                            component_brands: updateData,
                        },
                    ],
                })
                break
            }

            case 'save-review': {
                const componentBrandsToApprove = approvedComponentBrands.map(
                    brand => ({
                        id: brand.id,
                        status: 'APPROVED',
                    }),
                )

                const componentBrandsToDecline = rejectedComponentBrands.map(
                    brand => ({
                        id: brand.id,
                        status: 'REJECTED',
                    }),
                )

                const updateData = [
                    ...componentBrandsToApprove,
                    ...componentBrandsToDecline,
                ]

                handleUpdateProviderBrands({
                    providerId,
                    data: [
                        {
                            component_type_id: componentType.id,
                            component_brands: updateData,
                        },
                    ],
                })
                break
            }

            case 'approve-success':
                setActionModalType('')
                setActionModalOpen(false)
                break

            case 'decline-success':
                setActionModalType('')
                setActionModalOpen(false)
                break

            default:
                break
        }
    }

    return {
        actionModalOpen,
        actionModalType,
        approvedComponentBrands,
        rejectedComponentBrands,
        editBrandsModalOpen,
        selectedComonentBrands,
        isLoading,
        setActionModalOpen,
        setActionModalType,
        setApprovedComponentBrands,
        setRejectedComponentBrands,
        handleAddApprovedComponentBrand,
        handleAddRejectedComponentBrand,
        handleUndoSelection,
        handleApproveAllClick,
        handleActionModalCancel,
        actionModalHandler,
        handleSaveThisReviewClick,
        setEditBrandsModalOpen,
        handleClickEditBrand,
        setSelectedComponentBrands,
    }
}
