export const countries = ['Nigeria']

export const sameAddressOptions = [
    {
        title: 'Yes, it is',
        value: true,
    },
    {
        title: `No, it's not`,
        value: false,
    },
]

export const actionModalValues = action => {
    switch (action) {
        case 'payment-successful':
            return {
                actionModalTitle: 'Payment Successful',
                actionModalHeaderText: (
                    <p>
                        Congratulations! &#127881;
                        <br /> Your payment was successful!
                    </p>
                ),
                actionModalSubtitle:
                    "We'll reach out to you via email with the next steps regarding the delivery of your system.",
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Done',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'save-progress':
            return {
                actionModalTitle: 'Save your progress',
                actionModalHeaderText: 'Save your progress? Please confirm',
                actionModalSubtitle:
                    'You can save your progress and come back to continue anytime. We’ll also send an email on how you can continue.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'progress-saved':
            return {
                actionModalTitle: 'Redirecting to home page',
                actionModalHeaderText: 'Progress Saved, check your mail',
                actionModalSubtitle:
                    "Progress saved! We've emailed you instructions to resume when you have the required information.",
                actionModalActionType: 'countdown-timer',
                actionModalPrimaryBtnText: 'Proceed to Log Out',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Continue KYC',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
    }
}

// offer/estimation data
export const formatOfferData = data => {
    let values = {
        offerId: data?.id,
        systemName: data?.recommended_solution?.name ?? 'N/A',
        capacity:
            data?.recommended_solution?.energy_capacity?.value +
                ' ' +
                data?.recommended_solution?.energy_capacity
                    ?.unit_of_measurement ?? 'N/A',
        totalPrice:
            data?.recommended_solution?.total_cost?.toLocaleString('US') ?? 0,
        onboardingType: data?.onboarding_type || '',
        energyProfileId: data?.consumer_form_questions?.energy_profile_id,
        isPromo: data?.is_promo_estimation,
        solutionId: data?.recommended_solution?.id,
        clusterCode: data?.consumer_form_questions?.cluster_code,
        backupHours: data?.recommended_solution?.average_backup_hours
            ? Math.trunc(data?.recommended_solution?.average_backup_hours)
            : 'N/A',
        paymentType: data?.payment_plan,
        approvalStatus: data?.approval_status,
        surveyType: data?.survey_type,
    }

    return values
}
