import PropTypes from 'prop-types'
import Layout from '../_components/Layout/Layout'
import Description from '../_components/Description'
import { detectDescriptionLabel } from '../utils'
import IdentityInformationForm from './components/IdentityInformationForm'
import { SSEResidentialIdentityInfoContext } from './context'
import { useUploadImage } from './hook'
import SeoComponent from 'src/components/Seo'
const IdentityInformation = ({ userType }) => {
    const description = detectDescriptionLabel(`${userType}-verification`)
    const {
        persistResidentialIdentityInfo,
        persistResidentialIdentityInfoLoading,
        persistResidentialIdentityInfoSuccess,
        errorResidentialIdentityInfo,
        sseBasicInfoRefetch,
        sseBasicInfoFetching,
        toast,
        setToast,
        step,
        setStep,
        selected,
        setSelected,
        inputs,
        showModal,
        setShowModal,
        uploading,
        successUpload,
        setSuccessUpload,
        pictureUrl,
        setPictureUrl,
        width,
        setWidth,
        handleInputChange,
        handleBlur,
        handleSelectChange,
        closeModal,
        modalFooterLeftContent,
        modalPrimaryBtnText,
        modalTitle,
        disableModalPrimaryBtn,
        handleImageSteps,
        webcamRef,
        handleRetake,
        uploadImageToS3,
        showIdentityModal,
        setShowIdentityModal,
        fieldErrors,
        actionModal,
        setActionModal,
        selfieLoading,
        modalRIDType,
        setModalRIDType,
        kycResidentialIdentityCompareLoading,
        IdentitySelfieRef,
        verificationFailedModal,
        setVerificationFailedModal,
        identityToast,
        setIdentityToast,
        verificationFailed,
        setVerificationFailed,
    } = useUploadImage()

    return (
        <SSEResidentialIdentityInfoContext.Provider
            value={{
                persistResidentialIdentityInfo,
                persistResidentialIdentityInfoLoading,
                persistResidentialIdentityInfoSuccess,
                errorResidentialIdentityInfo,
                sseBasicInfoRefetch,
                sseBasicInfoFetching,
                toast,
                setToast,
                step,
                setStep,
                selected,
                setSelected,
                inputs,
                showModal,
                setShowModal,
                uploading,
                successUpload,
                setSuccessUpload,
                pictureUrl,
                setPictureUrl,
                width,
                setWidth,
                handleInputChange,
                handleBlur,
                handleSelectChange,
                closeModal,
                modalFooterLeftContent,
                modalPrimaryBtnText,
                modalTitle,
                disableModalPrimaryBtn,
                handleImageSteps,
                webcamRef,
                handleRetake,
                uploadImageToS3,
                showIdentityModal,
                setShowIdentityModal,
                fieldErrors,
                actionModal,
                setActionModal,
                selfieLoading,
                modalRIDType,
                setModalRIDType,
                kycResidentialIdentityCompareLoading,
                IdentitySelfieRef,
                verificationFailedModal,
                setVerificationFailedModal,
                identityToast,
                setIdentityToast,
                verificationFailed,
                setVerificationFailed,
            }}
        >
            <SeoComponent title="KYC - Identity Information | SunFi | Simplifying and Scaling Clean Energy" />
            <Layout
                description={
                    <Description
                        title={description?.title}
                        subTitle={description?.subTitle}
                        step="identity-information"
                    />
                }
                step="identity-information"
                form={<IdentityInformationForm />}
                userType={userType}
            />
        </SSEResidentialIdentityInfoContext.Provider>
    )
}

IdentityInformation.propTypes = {
    userType: PropTypes.string.isRequired,
}
export default IdentityInformation
