import { ReactComponent as MoneyIcon } from 'src/assets/images/money.svg'
import { ReactComponent as GiftIcon } from 'src/assets/images/gift.svg'

export const formatCardsData = data => {
    return [
        {
            icon: <MoneyIcon />,
            title: 'TOTAL OUTSTANDING',
            value: data?.total_outstanding,
        },
        {
            icon: <GiftIcon />,
            title: 'MONTHLY REPAYMENT',
            value: data?.monthly_repayment,
        },
        {
            icon: <GiftIcon />,
            title: 'AMOUNT FINANCED',
            value: data?.amount_financed,
        },
    ]
}

export const formattedActivePlans = data => {
    const formattedData = data?.map((item, i) => {
        return {
            plan: 'Plan' + ' 0' + (i + 1),
            monthlyPayment: [null, undefined].includes(
                item?.admin_approved_repayment_plan?.monthly_repayment,
            )
                ? '-'
                : `${item?.admin_approved_repayment_plan?.monthly_repayment}`,
            tenureDuration: [null, undefined].includes(
                item?.admin_approved_repayment_plan?.tenure,
            )
                ? '-'
                : `${item?.admin_approved_repayment_plan?.tenure} Months`,
            packageType: item?.payment_plan ?? '-',
            id: item?.id,
            applicationStatus: item?.application_status,
        }
    })

    return formattedData
}

export const categories = {
    personalInformation: 'personal_information',
    creditInformation: 'credit_information',
    energyInformation: 'energy_information',
    recommendedSolutions: 'recommended_solutions',
    upfrontDeposit: 'upfront_deposit',
    repaymentSetup: 'repayment_setup',
    subscriptionPayment: 'subscription_payment',
    selectPaymentPlan: 'select_payment_plan',
    agreementSigning: 'signed_agreement',
}
