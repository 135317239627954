import SecondaryModal from 'src/components/Modal/SecondaryModal'
import s from './componentbrands.module.scss'
import BrandCard from './components/BrandCard'
import BrandCardLoader from './components/BrandCardLoader'
import useGetComponentBrands from './hooks/useGetComponentBrands'
import AddOrEditBrand from './components/AddOrEditBrand'
import useComponentBrands from './hooks/useComponentBrands'
import useFetchApprovedBrands from './components/AddOrEditBrand/hooks/useFetchApprovedBrands'
import Toast from 'src/components/Toast'
import ActionModal from 'src/components/ActionModal'
import { actionModalValues } from './utils'

const ComponentBrands = () => {
    const {
        componentTypes,
        componentTypesInReview,
        componentBrands,
        componentBrandsInReview,
        componentTypeMap,
        componentBrandsFetching,
        approvedBrandsIdMap,
    } = useGetComponentBrands()

    const {
        action,
        componentType,
        handleAddBrand,
        handleEditBrand,
        inputs,
        showAddOrEditBrandModal,
        handleGoBack,
        handleSave,
        pageError,
        updateBrandsLoading,
        deleteApprovedBrandsLoading,
        actionModalOpen,
        toast,
        actionModalHandler,
        handlePersistEdit,
    } = useComponentBrands(
        componentBrands,
        componentTypeMap,
        approvedBrandsIdMap,
    )
    const { approvedBrands } = useFetchApprovedBrands(pageError)

    const {
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalShowCancelBtn,
        actionModalActionText,
    } = actionModalValues(action, componentType)

    return (
        <>
            {/* modals */}
            <ActionModal
                actionModalOpen={actionModalOpen}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                actionType={actionModalActionType}
                noCancelBtn={!actionModalShowCancelBtn}
                actionText={actionModalActionText}
                actionHandler={actionModalHandler}
                onCancel={actionModalHandler}
                closable
                noBackLink
                primaryBtnWidth={'100%'}
            />
            <SecondaryModal
                showModal={showAddOrEditBrandModal}
                modalWidth={624}
                closable={false}
                content={
                    <div className={s.addOrEditBrandWrapper}>
                        {toast?.showToast && (
                            <Toast
                                message={toast?.toastMessage}
                                messageType={toast?.messageType}
                            />
                        )}
                        <AddOrEditBrand
                            componentType={componentType}
                            actionType={
                                action === 'add-brands' ? 'Add' : 'Edit'
                            }
                            brandNameArr={inputs[componentType]}
                            handleGoBack={handleGoBack}
                            handleSave={handleSave}
                            approvedBrands={approvedBrands}
                            loading={
                                updateBrandsLoading ||
                                deleteApprovedBrandsLoading
                            }
                            approvedComponentBrands={
                                componentBrands[componentType] || []
                            }
                            pendingComponentBrands={
                                componentBrandsInReview[componentType] || []
                            }
                            module={'profile-component-brands-page'}
                            handlePersistEdit={handlePersistEdit}
                            outlinedGoBackBtn
                        />
                    </div>
                }
            />

            {/* page */}
            <div className={s.wrapper}>
                <div className={s.left}>
                    <h1>Brands you currently work with</h1>
                    <p>
                        Your added brands based on different component you use
                    </p>
                </div>
                <div style={{ width: '100%' }}>
                    {/* IN REVIEW */}
                    {componentTypesInReview?.length ? (
                        <>
                            <h2 className={s.sectionTitle}>UP FOR REVIEW</h2>
                            <>
                                {componentTypesInReview?.map((type, index) => (
                                    <BrandCard
                                        key={index}
                                        category={type}
                                        brandNames={
                                            componentBrandsInReview[type]
                                        }
                                        margin="0 0 24px 0"
                                    />
                                ))}
                            </>
                        </>
                    ) : null}

                    {/* APPROVED */}
                    <>
                        <h2 className={s.sectionTitle} style={{ marginTop: 5 }}>
                            APPROVED BRANDS
                        </h2>
                        {componentBrandsFetching
                            ? [...Array(4)]?.map((_, index) => (
                                  <BrandCardLoader
                                      key={index}
                                      margin="0 0 24px 0"
                                  />
                              ))
                            : componentTypes?.map((type, index) => (
                                  <BrandCard
                                      key={index}
                                      category={type}
                                      brandNames={inputs[type]}
                                      margin="0 0 24px 0"
                                      showButtons
                                      handleAddBrand={handleAddBrand}
                                      handleEditBrand={handleEditBrand}
                                  />
                              ))}
                    </>
                </div>
            </div>
        </>
    )
}

export default ComponentBrands
