
import axios from 'src/config/axios'
import { useMutation } from 'react-query'
export const overrideVerification = async (estimation_id, payload) => {
  const res = await axios.post(`/admin/sse-consumers/estimations/${estimation_id}/override-data`, payload)
  return res.data
}

export const useOverrideVerification = () => {
  return useMutation({
    mutationKey: 'override-verification',
    mutationFn: (body) => overrideVerification(body.estimation_id, body.payload)
  })
}