import PropTypes from 'prop-types'
import ButtonComponent from 'src/components/Button'
import styles from './packageimagecard.module.scss'

const PackageImageCard = ({ image }) => {
    return (
        <div className={styles.Container}>
            <img src={image} alt="image" width="100%" height="100%" />
            <div className={styles.ImageOverlay}>
                <ButtonComponent
                    btnBgColor="var(--purple-light)"
                    btnTextColor="var(--blue)"
                    btnTextColorOutline="var(--blue)"
                    btnOutlineColor="var(--purple-light)"
                    type="outline"
                    btnWidth={'50px'}
                    btnHeight={'29px'}
                    btnPadding={'6px'}
                    border={'none'}
                    handleClick={() => {
                        window.open(image, '_blank')
                    }}
                    noFocus
                >
                    View
                </ButtonComponent>
            </div>
        </div>
    )
}

PackageImageCard.propTypes = {
    image: PropTypes.string.isRequired,
}

export default PackageImageCard
