import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './capex.module.scss'
import { AppButton } from 'src/components/Button'
import EmptyState from 'src/components/EmptyState'
import Card from 'src/components/Card'

const CapexReplacementTable = ({ openModal, data }) => {
    const [capexCount, setCapexCount] = useState(0)
    const { Table, HeaderRow, ContentRow, cards } = styles

    useEffect(() => {
        for (let i = 0; i < data?.length; i++) {
            if (data?.[i]?.capex_replacement_schedule.length > 0) {
                setCapexCount(prev => prev + 1)
            }
        }
    }, [data])

    return (
        <>
            {capexCount === 0 ? (
                <EmptyState subTitle="There are no capex replacement schedules for this plan." />
            ) : (
                <>
                    <div className={Table}>
                        <div className={HeaderRow}>
                            <h4>Component Name</h4>
                            <h4>Schedules</h4>
                            <h4>Action</h4>
                        </div>
                        {data.map(
                            (item, i) =>
                                item?.capex_replacement_schedule?.length !==
                                    0 && (
                                    <div key={i} className={ContentRow}>
                                        <h4>{item?.component_name}</h4>
                                        <p>
                                            {
                                                item?.capex_replacement_schedule
                                                    .length
                                            }{' '}
                                            Reschedules
                                        </p>
                                        <AppButton
                                            btnWidth="70px"
                                            btnHeight="37px"
                                            btnBgColor="#E2EEFF"
                                            btnTextColor="#004AAD"
                                            fontSize="12px"
                                            fontFamily="SF-Pro-Display-Medium"
                                            handleClick={() => openModal(item)}
                                        >
                                            View
                                        </AppButton>
                                    </div>
                                ),
                        )}
                    </div>
                    <div className={cards}>
                        {data?.map((item, i) => (
                            <Card
                                key={i}
                                topLeftHeading={'Component Name'}
                                topLeftContent={item?.component_name}
                                topLeftContentSize={15}
                                bottomLeftHeading={'Schedules'}
                                bottomLeftContent={
                                    item?.capex_replacement_schedule.length +
                                    ' ' +
                                    'Reschedules'
                                }
                                handleClick={() => openModal(item)}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    )
}

CapexReplacementTable.propTypes = {
    openModal: PropTypes.func,
    data: PropTypes.array,
}

export default CapexReplacementTable
