//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
    initializePaymentApi,
    initializeProviderPaymentApi,
    verifyPaymentApi,
    cancelPaystackPaymentApi,
    saveCardApi,
    initializeConsumerOutrightSalePaymentApi,
    verifyOutrightSalePaymentApi,
} from 'src/api/consumer/payment'
import ActionModal from 'src/components/ActionModal'
import ActionModalV2 from 'src/components/ActionModalV2'
import { usePaystackPayment } from 'react-paystack'
import { errorHandler } from 'src/utils/errorHandler'
import { appTracking } from 'src/utils/appTracker'

const PayStack = ({
    estimationId,
    reloadPage,
    email,
    handleLoading = () => {},
    handleError,
    event,
    closeAddNewModal = () => {},
    callInitializePayment = false,
    handleVerifyLoader,
    openCardDetailsModal = () => {},
    setVerifyLoading = () => {},
    onSuccess,
    closePaystack,
    v2 = false,
    type = 'admin',
    onCardSaved,
}) => {
    const [reference, setReference] = useState('')
    const [paymentRecordSuccess, setPaymentRecordSuccess] = useState(false)
    const [paystackSuccessModal, setPaystackSuccessModal] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [amount, setAmount] = useState(0)
    const [confirmCard, setConfirmCard] = useState(false)
    const [cardSavedSuccessfully, setCardSavedSuccessfully] = useState(false)
    const [paymentChannel, setPaymentChannel] = useState('')
    //initialize payment to get reference
    const { refetch: initializePaymentRefetch } = useQuery(
        'initialize-payment',
        () =>
            type === 'provider'
                ? initializeProviderPaymentApi({
                      event,
                      estimation_id: estimationId,
                  })
                : type === 'outright-sale-customer'
                ? initializeConsumerOutrightSalePaymentApi({
                      estimation_id: estimationId,
                  })
                : initializePaymentApi({
                      event,
                      estimation_id: estimationId,
                  }),
        {
            retry: false,
            enabled: false,
            onSuccess: data => {
                const reference = data?.data?.data?.reference
                setReference(reference)
                let amount = data?.data?.data?.kobo_amount
                setAmount(amount)
                if (handleVerifyLoader !== undefined) {
                    handleVerifyLoader()
                }
                closeAddNewModal?.()
                setTimeout(() => handleLoading(), [2500])
                appTracking(
                    '',
                    '',
                    '',
                    event === 'repayment_method_setup'
                        ? 'REPAYMENT_SCHEDULE_INITIALISE_PAYSTACK_SUCCESSFUL'
                        : 'UPFRONT_DEPOSIT_INITIALISE_PAYSTACK_SUCCESSFUL',
                    event === 'repayment_method_setup'
                        ? 'REPAYMENT_SCHEDULE_INITIALISE_PAYSTACK'
                        : 'UPFRONT_DEPOSIT_INITIALISE_PAYSTACK',
                    event === 'repayment_method_setup'
                        ? 'REPAYMENT_SCHEDULE'
                        : 'UPFRONT_DEPOSIT',
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {},
                    },
                )
            },
            onError: error => {
                setErrorModal(true)
                setErrorMessage(errorHandler(error?.response?.data))
                closeAddNewModal()
                handleLoading()
            },
        },
    )
    useEffect(() => initializePaymentRefetch(), [callInitializePayment])

    // initialize paystack payment when reference has been updated
    useEffect(
        () => initializePayment(onPaystackPaymentSuccess, onPaystackClose),
        [reference],
    )

    //paystack configuration
    const config = {
        reference: reference,
        email,
        amount: amount,
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
        channels:
            event === 'repayment_method_setup'
                ? ['card']
                : ['card', 'bank', 'ussd'],
    }

    const initializePayment = usePaystackPayment(config)

    const onPaystackPaymentSuccess = () => {
        // make an api call with reference and estimation id to let the backend know payment on paystack was successful
        verifyDepositRefetch()
        setVerifyLoading(true)
    }

    // called if the customer closes the payment window instead of making a payment
    const onPaystackClose = () => {
        cancelPaymentRefetch()
        if (closePaystack) {
            closePaystack()
        }
    }

    // verify payment
    const { refetch: verifyDepositRefetch } = useQuery(
        'verify-deposit',
        () =>
            type === 'outright-sale-customer'
                ? verifyOutrightSalePaymentApi({
                      estimation_id: estimationId,
                      reference,
                  })
                : verifyPaymentApi({
                      event,
                      estimation_id: estimationId,
                      reference,
                  }),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setPaymentChannel(data?.data?.data?.payment_channel)
                if (onSuccess) {
                    onSuccess()
                } else {
                    setPaystackSuccessModal(true)
                    setPaymentRecordSuccess(true)
                    setVerifyLoading(false)
                    if (handleVerifyLoader !== undefined) {
                        handleVerifyLoader()
                    }
                }
                appTracking(
                    '',
                    '',
                    '',
                    event === 'repayment_method_setup'
                        ? 'REPAYMENT_SCHEDULE_PAYMENT_WITH_PAYSTACK_SUCCESSFUL'
                        : 'UPFRONT_DEPOSIT_PAYMENT_WITH_PAYSTACK_SUCCESSFUL',
                    event === 'repayment_method_setup'
                        ? 'REPAYMENT_SCHEDULE_PAYSTACK_SUCCESS'
                        : 'UPFRONT_DEPOSIT_PAYSTACK_SUCCESS',
                    event === 'repayment_method_setup'
                        ? 'REPAYMENT_SCHEDULE'
                        : 'UPFRONT_DEPOSIT',
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {},
                    },
                )
            },
            onError: error => {
                setErrorModal(true)
                setErrorMessage(errorHandler(error?.response?.data))
                setVerifyLoading(false)
            },
        },
    )

    // cancel payment
    const { refetch: cancelPaymentRefetch } = useQuery(
        'cancel-payment',
        () =>
            cancelPaystackPaymentApi({
                event,
                estimation_id: estimationId,
                reference,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                appTracking(
                    '',
                    '',
                    '',
                    event === 'repayment_method_setup'
                        ? 'CANCEL_REPAYMENT_METHOD_SETUP_WITH_PAYSTACK'
                        : 'CANCEL_UPFRONT_DEPOSIT_PAYMENT_WITH_PAYSTACK',
                    event === 'repayment_method_setup'
                        ? 'CANCEL_REPAYMENT_METHOD_SETUP'
                        : 'CANCEL_UPFRONT_DEPOSIT',
                    event === 'repayment_method_setup'
                        ? 'REPAYMENT_METHOD_SETUP'
                        : 'UPFRONT_DEPOSIT',
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {},
                    },
                )
            },
        },
    )

    // save card information
    const { mutate: saveCardRefetch, isLoading: saveCardLoading } = useMutation(
        () =>
            saveCardApi({
                save_card: true,
                reference,
            }),
        {
            onSuccess: () => {
                if (onCardSaved) {
                    onCardSaved()
                }
                setCardSavedSuccessfully(true)
                appTracking(
                    '',
                    '',
                    '',
                    'SAVE_CARD_WITH_PAYSTACK',
                    'SAVE_CARD',
                    event === 'repayment_method_setup'
                        ? 'REPAYMENT_METHOD_SETUP'
                        : 'UPFRONT_DEPOSIT',
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {},
                    },
                )
            },
        },
    )

    const handlePaystackSuccess = () => {
        reloadPage()
        setPaystackSuccessModal(false)
        setConfirmCard(false)
        setCardSavedSuccessfully(false)
    }

    const handleUseNewCard = () => {
        setErrorModal(false)
        openCardDetailsModal()
    }

    return (
        <>
            {v2 ? (
                <ActionModalV2
                    modalWidth="610px"
                    onCancel={handlePaystackSuccess}
                    actionModalOpen={paystackSuccessModal}
                    handlePrimaryBtnClick={() => {
                        paymentRecordSuccess && paymentChannel === 'card'
                            ? setConfirmCard(true)
                            : null
                        paymentRecordSuccess && paymentChannel !== 'card'
                            ? reloadPage()
                            : null
                        setPaystackSuccessModal(false)
                    }}
                    title={`${
                        event === 'repayment_method_setup' ? 'Setup' : 'Payment'
                    } ${paymentRecordSuccess ? 'Successful' : 'Unsuccessful'}`}
                    primaryBtnText={
                        paymentRecordSuccess && paymentChannel === 'card'
                            ? 'Save my card'
                            : paymentRecordSuccess && paymentChannel !== 'card'
                            ? 'Close'
                            : 'Try again'
                    }
                    headerText={`Your ${
                        event === 'repayment_method_setup' ? 'setup' : 'payment'
                    } was ${
                        paymentRecordSuccess ? 'successful' : 'unsuccessful'
                    }`}
                    subTitle="We are really excited to have you onboard on Sunfi and you are eligible for solar financing by Sunfi."
                    actionType={paymentRecordSuccess ? 'success' : 'delete'}
                    v2
                    showFooter
                />
            ) : (
                <ActionModal
                    actionModalOpen={paystackSuccessModal}
                    headerText={
                        paymentRecordSuccess
                            ? event === 'repayment_method_setup'
                                ? 'Setup Successful'
                                : 'Payment Successful'
                            : event === 'repayment_method_setup'
                            ? 'Setup Unsuccessful'
                            : 'Payment Unsuccessful'
                    }
                    subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                    closeModal={handlePaystackSuccess}
                    actionHandler={
                        paymentRecordSuccess
                            ? handlePaystackSuccess
                            : () => setPaystackSuccessModal(false)
                    }
                    actionType={paymentRecordSuccess ? 'success' : 'delete'}
                    actionText="Okay, Got it"
                    loading={''}
                    noCancelBtn={true}
                    noBackLink
                />
            )}
            <ActionModalV2
                modalWidth="610px"
                onCancel={handlePaystackSuccess}
                actionModalOpen={confirmCard}
                handlePrimaryBtnClick={saveCardRefetch}
                title="Save Card Details"
                primaryBtnText="Confirm"
                headerText="Confirm that you want to save your card details"
                subTitle="We are really excited to have you onboard on Sunfi and you are eligible for solar financing by Sunfi."
                actionType={'warning'}
                v2
                showFooter
                primaryBtnLoading={saveCardLoading}
            />
            <ActionModalV2
                modalWidth="610px"
                onCancel={handlePaystackSuccess}
                actionModalOpen={cardSavedSuccessfully}
                handlePrimaryBtnClick={handlePaystackSuccess}
                title="Card Details Saved"
                primaryBtnText="Done"
                headerText="Your card has been saved successfully"
                subTitle="We are really excited to have you onboard on Sunfi and you are eligible for solar financing by Sunfi."
                actionType={'success'}
                v2
                showFooter
            />
            <ActionModal
                actionModalOpen={errorModal}
                headerText="Sorry, Your Card Was Declined"
                subTitle={errorMessage}
                actionHandler={handleUseNewCard}
                actionType="delete"
                actionText="Use A New Card"
                loading={''}
                closeModal={() => {
                    setErrorModal(false)
                }}
            />
        </>
    )
}

export default PayStack
