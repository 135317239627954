import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useUploadToS3 } from 'src/utils/Hooks/useUploadToS3'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { ProfileClusterInfoValidator } from 'src/utils/validationSchema'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateConsumerProfile,
    updatedConsumerProfile,
} from 'src/redux/reducers/admin/profiles/create-profile'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { useGetProfile } from '../hooks/useGetProfile'
import { useGetClusterOrigination } from 'src/api/cluster_origination/queries'
import { useUpdateProfile } from '../hooks/useUpdateProfile'

export const useClusterInfo = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const consumer_profile_data = useSelector(updatedConsumerProfile)
    const { profile_id } = useParams()
    const { errorAlert } = useCustomToast()
    const [width, setWidth] = useState(5)
    const [repData, setRepData] = useState(
        consumer_profile_data?.representatives,
    )
    const [inputs, setInputs] = useState({
        name: consumer_profile_data?.cluster_origination_setup?.name,
        website: consumer_profile_data?.cluster_origination_setup?.website,
        logo: consumer_profile_data?.cluster_origination_setup?.logo,
        cluster_representative: consumer_profile_data?.representatives?.length,
    })
    const [errors, setErrors] = useState({})
    const [showActionModal, setShowActionModal] = useState(false)

    const {
        data: staleProfile,
        isLoadingProfile,
        refetchProfile,
    } = useGetProfile({
        profileId: profile_id,
        onSuccess: data => {
            setInputs({
                name: data?.cluster_origination?.name,
                website: data?.cluster_origination?.website,
                logo: data?.cluster_origination?.logo,
                cluster_representative:
                    data?.cluster_origination?.representative_count > 0,
            })
        },
    })

    const { getClusterOriginationData, getClusterOriginationFetching } =
        useGetClusterOrigination({
            enabled: profile_id?.length ? true : false,
            params: {
                id: profile_id,
            },
            onSuccess: data => {
                setRepData(
                    data?.map(item => ({
                        first_name: item?.user?.first_name,
                        last_name: item?.user?.last_name,
                        email: item?.user?.email,
                        phone: item?.user?.phone_number,
                        id: item?.id,
                    })),
                )
            },
        })

    const { uploadToS3Loading, uploadToS3Mutate } = useUploadToS3({
        onSuccess: data => {
            setInputs(prev => ({ ...prev, logo: data }))
            setErrors(prev => ({ ...prev, logo: '' }))
        },
        onError: e => {
            errorAlert(errorHandler(e) || 'Something went wrong')
        },
    })

    useEffect(() => {
        const handleSlider = () => {
            if (uploadToS3Loading && width === 97.5) {
                setWidth(prev => prev - 50)
            } else if (uploadToS3Loading && width < 100) {
                setWidth(prev => prev + 2.5)
            } else if (!uploadToS3Loading && width > 5) {
                setWidth(100)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploadToS3Loading])

    const beforeUpload = file => {
        const isLt2M = file.size / 1000 / 1000 < 5

        if (!isLt2M) {
            setErrors(prev => ({
                ...prev,
                logo: 'Please upload an svg, jpeg, or png file no more than 5mb',
            }))
        } else if (
            file.type !== 'image/svg+xml' ||
            file.type !== 'image/jpeg' ||
            file.type !== 'image/png'
        ) {
            setErrors(prev => ({
                ...prev,
                logo: 'Please upload an svg, jpeg, or png file',
            }))
        }
    }

    const props = {
        name: 'file',
        onChange({ fileList }) {
            uploadToS3Mutate({
                category: 'CLUSTER_ORIGINATION',
                file: fileList[0],
            })
        },
    }

    const toggleRep = checked => {
        if (checked) {
            setInputs(prev => ({
                ...prev,
                cluster_representative: true,
            }))
        } else {
            setInputs(prev => ({
                ...prev,
                cluster_representative: false,
            }))
            setRepData([])
        }
    }

    const { handleUpdateProfile, isLoading: updateProfileLoading } =
        useUpdateProfile({
            data: {
                cluster_origination_setup: {
                    name: inputs?.name,
                    website: inputs?.website,
                    logo: inputs?.logo,
                },
                id: profile_id,
            },
            onSuccess: () => {
                setShowActionModal(true)
            },
        })

    const handleNext = () => {
        ProfileClusterInfoValidator.validate(
            {
                ...inputs,
                cluster_representative:
                    profile_id?.length > 0
                        ? false
                        : inputs?.cluster_representative,
                representatives: repData,
            },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                if (profile_id) {
                    const noUpdates = () => {
                        const logo =
                            staleProfile?.data?.data?.cluster_origination
                                ?.logo === inputs?.logo
                        const website =
                            staleProfile?.data?.data?.cluster_origination
                                ?.website === inputs?.website
                        const name =
                            staleProfile?.data?.data?.cluster_origination
                                ?.name === inputs?.name
                        return name && website && logo
                    }

                    if (noUpdates()) {
                        history.push(`/admin/consumer-profiles/${profile_id}`)
                    } else {
                        handleUpdateProfile()
                    }
                } else {
                    dispatch(
                        updateConsumerProfile({
                            cluster_origination_setup: {
                                name: inputs?.name,
                                website: inputs?.website,
                                logo: inputs?.logo,
                            },
                            representatives: repData,
                        }),
                    )
                    history.push({
                        pathname: `/admin/consumer-profiles/review/private`,
                    })
                }
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    return {
        inputs,
        setInputs,
        errors,
        setErrors,
        beforeUpload,
        props,
        toggleRep,
        handleNext,
        uploadToS3Loading,
        width,
        repData,
        setRepData,
        getClusterOriginationData,
        updateProfileLoading,
        showActionModal,
        setShowActionModal,
        getClusterOriginationFetching,
        isLoadingProfile,
        profile_id,
        refetchProfile,
    }
}
