import { useState } from 'react'
import {
    formatOtpVerifyMethods,
    otpMethodsNoInitialDetails,
} from 'src/components/GettingStarted/utils'
import useVerifyLoginOTP from 'src/components/LoginComponent/hooks/useVerifyLoginOTP'
import useInitiateLoginMutation from '../hooks/useInitiateLoginMutation'
import { errorHandler } from 'src/utils/errorHandler'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import { validateLoginInfo } from 'src/utils/validationSchema'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'

const useLoginModal = (
    onCancel,
    loginModalContent,
    setLoginModalContent,
    inputs,
    setShowModal,
    otpMethod,
    setOtpMethod,
    initiateOTP,
    verifyApi,
    onVerifySuccess,
    preventConsumerRedirect = false,
    handlePostSuccess = () => {},
    initialDetailsNotProvided,
) => {
    const [errors, setErrors] = useState({})
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [localInputs, setLocalInputs] = useState({
        email: '',
        phone: '',
    })

    const userType = 'consumer'

    const loginPayload = initialDetailsNotProvided
        ? {
              email: localInputs?.email,
              phone: formatPhoneNumber(localInputs?.phone),
          }
        : inputs

    const {
        otpError,
        toastError: verifyLoginToastErr,
        errorMessage: verifyLoginErrMsg,
        resent,
        resendOTPMutation,
        fullMethodName,
        closeModal,
        handleOtpSubmit,
        verifyLoginMutation,
        otp,
        setOtp,
        setResent,
    } = useVerifyLoginOTP(
        setShowModal,
        otpMethod,
        loginPayload,
        userType,
        verifyApi,
        onVerifySuccess,
        preventConsumerRedirect,
        handlePostSuccess,
    )
    const validateField = useValidateOnBlur()

    const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_LOGIN'

    const { mutate: initiateLogin, isLoading: initiateLoginLoading } =
        useInitiateLoginMutation(
            loginPayload,
            () => {
                setLoginModalContent('enter-otp')
                setErrors({})
            },
            err => {
                setToastError(true)
                setErrorMessage(errorHandler(err))
            },
            userType,
            pageUrlName,
            pageTracker,
            pageTitle,
        )

    const otpMethods = initialDetailsNotProvided
        ? otpMethodsNoInitialDetails
        : formatOtpVerifyMethods(inputs)

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const validationSchemaContext = {
        otpMethod: otpMethod,
    }

    const handleOnBlur = async e => {
        const { name, value } = e.target

        await validateField({
            name,
            value,
            schema: validateLoginInfo,
            setErrors,
            context: validationSchemaContext,
        })
    }

    const handleChange = e => {
        const { name, value } = e.target

        setLocalInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
        removeErrors(name)
    }

    const handleSelectOtpMethod = key => {
        setOtpMethod(key)
        removeErrors('otpMethod')
    }

    const handleClick = () => {
        if (
            loginModalContent === 'select-otp-method' &&
            initialDetailsNotProvided
        ) {
            if (otpMethod === '') {
                setErrors(prev => ({
                    ...prev,
                    otpMethod: 'Please select one option',
                }))
            } else {
                removeErrors('otpMethod')
                setLoginModalContent('enter-contact-details')
            }
        } else if (
            loginModalContent === 'select-otp-method' &&
            !initialDetailsNotProvided
        ) {
            if (otpMethod === '') {
                setErrors(prev => ({
                    ...prev,
                    otpMethod: 'Please select one option',
                }))
            } else {
                removeErrors('otpMethod')
                if (initiateOTP) {
                    initiateOTP()
                } else {
                    initiateLogin(otpMethod)
                }
            }
            return
        } else if (loginModalContent === 'enter-contact-details') {
            validateLoginInfo
                .validate(localInputs, {
                    context: validationSchemaContext,
                    abortEarly: false,
                })
                .then(() => {
                    setErrors({})
                    initiateLogin(otpMethod)
                })
                .catch(err => {
                    let errList = {}
                    err?.inner?.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        } else if (loginModalContent === 'enter-otp') {
            handleOtpSubmit()
            return
        }
    }

    const handleClose = () => {
        setOtpMethod('')
        setErrors({})
        closeModal()
        onCancel?.()
        setOtp(['', '', '', '', '', ''])
    }

    return {
        handleClose,
        fullMethodName,
        handleClick,
        verifyLoginMutation,
        initiateLoginLoading,
        toastError,
        verifyLoginToastErr,
        errorMessage,
        verifyLoginErrMsg,
        otpMethods,
        otpMethod,
        handleSelectOtpMethod,
        errors,
        otp,
        setOtp,
        otpError,
        resendOTPMutation,
        resent,
        setResent,
        localInputs,
        handleOnBlur,
        handleChange,
        loginPayload,
    }
}

export default useLoginModal
