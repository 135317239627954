/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Upload } from 'antd'
import { useQuery } from 'react-query'
import SeoComponent from 'src/components/Seo'
import Editor from '../../../components/Editor'
import {
    NoFLoatingLabelInputFields,
    InputFields,
    SelectField,
} from '../../../components/InputFields'
import { CostField } from '../../../components/InputFields/CostField'
import BackNav from '../../../components/BackNav'
import Button from '../../../components/Button'
import Toast from '../../../components/Toast'
import Checkbox from '../../../components/Checkbox'
import { InlineLoader } from '../../../components/Loader'
import ImageListing from '../../../components/ImageListing'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import successUpload from '../../../assets/images/successUpload.svg'
import { Menu, Dropdown } from 'antd'
import HelpCenter from '../../../components/HelpCenter'
import FloatingBox from '../../../components/FloatingBox'
import QuestionMarkIcon from '../../../assets/images/questionMark.svg'
import { eventTrackers } from '../../../utils/eventTrackers.js'
import { appTracking } from '../../../utils/appTracker.js'

import { getComponentTypeApi } from '../../../api/products/component'
import {
    getSingleComponentApi,
    updateComponentApi,
} from '../../../api/products/component'

import useErrorHandler from '../../../utils/Hooks/useErrorHandler'

import './editproduct.scss'

import { truncateFileName, floatWithCommas } from '../../../utils/formatting'
import { denyPermission } from '../../../utils/permissionFramework'
import { checkLibraryProductAccess } from '../../../utils/checkLibraryProductAccess'
import { decodeUserInfo } from 'src/utils/auth'
import { editComponentValidationSchema } from 'src/utils/validationSchema'

// eslint-disable-next-line no-unused-vars
const EditComponent = ({ match, productId, location }) => {
    const itemId = match?.params?.id || productId
    const [showMenu, setShowMenu] = useState(false)
    const [showImageSample, setShowImageSample] = useState(false)
    const [componentData, setComponentData] = useState({})
    const [allComponentTypes, setAllComponentTypes] = useState([])
    const [componentAttributes, setComponentAttributes] = useState([])
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [fileName, setFileName] = useState('')
    const [uploadedFile, setUploadedFile] = useState([])
    const [sampleImgUrl, setSampleImgUrl] = useState('')
    const [allComponentAttributes, setAllComponentAttributes] = useState([])
    const [allErrors, setAllErrors] = useState([])
    const [successMessage, setSuccessMessage] = useState('')
    const [errors, setErrors] = useState({})

    const [inputs, setInputs] = useState({
        componentName: '',
        componentType: [],
        componentWarranty: '',
        unitsOfMeasurement: '',
        componentDescription: '',
        componentAttribute: '',
        componentImgUrl: '',
        componentCost: '',
        componentTypeId: '',
    })

    const [errs, setErrs] = useState({})

    const editorRef = useRef()

    const editor = editorRef?.current?.getEditor()

    let history = useHistory()
    const userInfo = decodeUserInfo()
    const referrer = history?.location?.state?.referrer ?? ''
    const isAdminWorkspace = userInfo?.isAdminWorkspaceUser === 'true'
    const noEditButton = history?.location?.state?.noEdit
    const providerId = history?.location?.state?.providerId
    const providerName = history?.location?.state?.providerName
    const unprivilegedEditor =
        editorRef?.current?.makeUnprivilegedEditor(editor)

    const pageTitle =
        'Edit A Component - Edit Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'EDIT_COMPONENT_TRACKER'
    const componentIsSolarPanel = inputs?.componentType === 'Solar Panel'

    const props = {
        name: 'file',
        onChange(info) {
            setFileName(info.file.name)
            setUploadedFile(info.fileList)
            setInputs(preState => ({
                ...preState,
                componentImgUrl: '',
            }))
            setSampleImgUrl('')
        },
        showUploadList: false,
    }

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const { isLoading, refetch } = useQuery(
        ['fetchSingleComponent'],
        () => getSingleComponentApi(itemId),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const singleData = data?.data?.data
                let allAttributesArray = []

                singleData?.type?.attributes?.forEach(value => {
                    allAttributesArray.push({
                        name: value?.name,
                        unit: value?.units_of_measurement,
                        options: value?.options,
                        selected:
                            singleData?.attributes[value?.name] ||
                            value?.required
                                ? true
                                : false,
                    })
                })
                setAllComponentAttributes(allAttributesArray)
                setComponentData(singleData)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch component. Refresh page')
            },
        },
    )

    useEffect(() => {
        const denyPageAction =
            componentData?.is_global &&
            !checkLibraryProductAccess(componentData?.provider_id)

        const denyEditComponentPageAction = denyPermission(
            'admin',
            'product_library',
            'component:can_edit',
        )
        if (denyPageAction || denyEditComponentPageAction) {
            history.push('/app/403-page')
        }
    }, [history, componentData])

    const { refetch: componentTypeRefetch } = useQuery(
        ['fetchAllComponentTypes'],
        () => getComponentTypeApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const formattedList = []
                data?.data?.data?.forEach(value => {
                    formattedList.push({
                        id: value?.id,
                        value: value?.name,
                    })
                })
                setAllComponentTypes(formattedList)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch component types. Refresh page')
            },
        },
    )

    useEffect(() => {
        componentTypeRefetch()
        setErrs({})
        refetch()

        // eslint-disable-next-line
    }, [itemId])

    useEffect(() => {
        if (componentData?.image_url) {
            setFileName(componentData?.image_url)
        }
        let attributesArray = []

        for (const key in componentData?.attributes) {
            const checkAttributeInfo = componentData?.type?.attributes?.filter(
                value => value?.name === key,
            )

            let optionsArray = []

            checkAttributeInfo[0]?.options?.forEach(option => {
                optionsArray.push({
                    value: option,
                })
            })

            attributesArray.push({
                name: key,
                value: componentData?.attributes[key]?.value,
                unit: componentData?.attributes[key]?.unit_of_measurement,
                options: optionsArray,
                required:
                    checkAttributeInfo?.length > 0
                        ? checkAttributeInfo[0]?.required
                        : false,
            })
        }

        setComponentAttributes(attributesArray)
        setInputs(preState => ({
            ...preState,
            componentName: componentData?.name,
            componentType: componentData?.type?.name,
            componentDescription: componentData?.description,
            componentImgUrl: componentData?.image_url,
            componentCost: componentData?.cost,
            componentTypeId: componentData?.type?.id,
            componentWarranty: componentData?.meta_data?.warranty,
        }))
    }, [componentData])

    const handleInputChange = event => {
        //regex to to allow numbers and comma
        const numberRegex = /^[0-9.,]*$/
        const { name, value } = event.target
        setInputs(preState => ({
            ...preState,
            [name]:
                name === 'componentCost'
                    ? numberRegex.test(value)
                        ? floatWithCommas(value)
                        : inputs.componentCost
                    : value,
        }))

        componentErrorHandler(event.target.name, event.target.value)

        removeErrors(name)
    }

    const handleOnBlur = event => {
        const { name, value } = event.target
        setInputs(preState => ({
            ...preState,
            [name]: value.trim(),
        }))
    }

    const handleVisibleChange = flag => {
        setShowMenu(flag)
    }

    const menu = () => {
        const unSelectedAttributes = allComponentAttributes.filter(
            value => value?.selected !== true,
        )

        return (
            <Menu className="menu">
                {unSelectedAttributes.map(menuItem => (
                    <Menu.Item key={menuItem}>
                        <Checkbox
                            acceptTextClick
                            handleChecked={() => {
                                addNewAttribute(menuItem?.name, menuItem?.unit)
                            }}
                        >
                            {menuItem?.name}
                        </Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        )
    }

    const handleSelectChange = (name, value) => {
        const filterType = allComponentTypes?.filter(
            type => type?.value === value,
        )
        setInputs(preState => ({
            ...preState,
            [name]: value,
            componentTypeId: filterType[0]?.id,
        }))
    }

    const checkAddNewListTotal = () => {
        return allComponentAttributes?.filter(value => value?.selected !== true)
            ?.length
    }

    const removeAttribute = name => {
        const filteredAttributes = componentAttributes?.filter(
            value => value?.name !== name,
        )
        setComponentAttributes(filteredAttributes)
        updateAllComponentAttribute(name, false)
    }

    const updateAllComponentAttribute = (name, selected) => {
        const filteredAttribute = allComponentAttributes?.filter(
            value => value?.name === name,
        )[0]

        let newValue = {
            name: filteredAttribute?.name,
            unit: filteredAttribute?.unit,
            options: filteredAttribute?.options,
            selected,
        }

        let attributesCopy = [...allComponentAttributes]

        let found = attributesCopy.findIndex(a => a.name === name)
        if (found > -1) {
            attributesCopy[found] = newValue
        }

        setAllComponentAttributes(attributesCopy)
    }

    const getAttributeUnits = name => {
        const attributeUnits = allComponentAttributes?.filter(
            value => value?.name === name,
        )[0]?.unit
        const formattedAttributesArray = []
        attributeUnits?.forEach(value => {
            formattedAttributesArray.push({
                value,
            })
        })

        return formattedAttributesArray
    }

    const addNewAttribute = (name, unit) => {
        const checkIfRequired = componentData?.type?.attributes?.filter(
            value => value?.name === name,
        )

        const componentAttributesCopy = [...componentAttributes]

        componentAttributesCopy.push({
            name,
            value: 0,
            unit: unit[0] && unit[0],
            options: [],
            required:
                checkIfRequired?.length > 0
                    ? checkIfRequired[0]?.required
                    : false,
        })

        setComponentAttributes(componentAttributesCopy)
        updateAllComponentAttribute(name, true)
    }

    const handleAttributeChange = (name, value) => {
        const attributeValue = value
        const attributeName = name
        const filteredAttribute = componentAttributes?.filter(
            value => value?.name === attributeName?.substring(4),
        )[0]

        const checkIfRequired = componentData?.type?.attributes?.filter(
            value => value?.name === attributeName?.substring(4),
        )

        let newValue = {}
        if (attributeName?.substring(0, 4) === 'text') {
            newValue = {
                name: attributeName?.substring(4),
                value: attributeValue ? parseFloat(attributeValue, 10) : 0,
                unit: filteredAttribute?.unit,
                options: filteredAttribute?.options,
                required:
                    checkIfRequired?.length > 0
                        ? checkIfRequired[0]?.required
                        : false,
            }
        } else {
            newValue = {
                name: filteredAttribute?.name,
                value: filteredAttribute?.value,
                unit: attributeValue,
                options: filteredAttribute?.options,
                required:
                    checkIfRequired?.length > 0
                        ? checkIfRequired[0]?.required
                        : false,
            }
        }

        let attributesCopy = [...componentAttributes]

        let found = attributesCopy.findIndex(
            a => a.name === attributeName?.substring(4),
        )
        if (found > -1) {
            attributesCopy[found] = newValue
        }

        if (attributeName?.substring(0, 4) !== 'text') {
            componentAttributes.find(
                type => type.name === attributeName,
            ).value = attributeValue
        }

        componentErrorHandler(
            attributeName?.substring(4),
            newValue?.value < 1 ? '' : newValue?.value,
        )
        setComponentAttributes(attributesCopy)
    }

    const handleEditorChange = value => {
        setInputs(prev => ({ ...prev, componentDescription: value }))

        const editorValue = unprivilegedEditor?.getText().trim()

        if (!editorValue && !value) {
            componentErrorHandler('componentDescription', '')
        } else {
            componentErrorHandler('componentDescription', editorValue)
        }

        removeErrors('componentDescription')
    }

    const componentErrorHandler = (name, value) => {
        const errorsCopy = [...allErrors]
        if (value?.length < 1) {
            errorsCopy.push(name)
        } else {
            const index = errorsCopy.indexOf(name)
            if (index > -1) {
                errorsCopy.splice(index, 1)
            }
        }

        setAllErrors(errorsCopy)
    }

    let objectifyComponentAttributes = () => {
        let componentAttributesObj = componentAttributes.map(att => {
            const { name, unit, value } = att
            return {
                [name]: { value: value, unit_of_measurement: unit },
            }
        })
        const finalValue = componentAttributesObj.reduce(function (
            result,
            item,
        ) {
            var key = Object.keys(item)[0]
            result[key] = item[key]
            return result
        },
        {})

        return finalValue
    }
    const handleUpdateProcess = () => {
        editComponentValidationSchema
            .validate(inputs, {
                context: {
                    checkWarranty: isAdminWorkspace && !componentIsSolarPanel,
                },
                abortEarly: false,
            })
            .then(() => {
                setErrs({})
                setErrors({})
                setSuccessMessage('')
                updateComponentRefetch()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const checkSuggestedUrl = () => {
        const payload = {
            name: inputs.componentName,
            description: inputs.componentDescription,
            attributes: JSON.stringify(objectifyComponentAttributes()),
            image: uploadedFile.length > 0 && uploadedFile[0]?.originFileObj,
            typeId: inputs?.componentTypeId,
            cost: inputs?.componentCost?.replace(/,/g, ''),
            warranty: inputs?.componentWarranty,
        }

        const url = sampleImgUrl ? sampleImgUrl : inputs?.componentImgUrl
        if (url) {
            return {
                ...payload,
                suggested_image_url: sampleImgUrl
                    ? sampleImgUrl
                    : inputs?.componentImgUrl,
            }
        } else {
            return payload
        }
    }
    const {
        isFetching: updateComponentFetching,
        refetch: updateComponentRefetch,
    } = useQuery(
        'updateComponent',
        () => updateComponentApi(itemId, checkSuggestedUrl(), isAdminWorkspace),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setSuccessMessage('Your changes have been saved')
                setToastError(false)

                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['editComponent'].action,
                    eventTrackers['editComponent'].label,
                    eventTrackers['editComponent'].category,
                )
            },
            onError: data => {
                if (data?.response?.data?.errors?.attributes) {
                    setErrs(data?.response?.data)
                }
                if (data?.response?.data?.errors) {
                    setErrs(data?.response?.data)
                } else {
                    setErrorMessage([
                        'Failed to update Component. Refresh/Try Again',
                    ])
                }
                setToastError(true)
            },
        },
    )

    const errorValue = useErrorHandler(errs)

    const backToComponents = url => {
        history.push(
            providerId?.length < 0
                ? url
                : {
                      pathname: url,
                      search: '?tab=components',
                      state: {
                          id: providerId,
                          name: providerName,
                          isAdminWorkspace: isAdminWorkspace,
                          sunfiId: noEditButton ? '' : providerId,
                      },
                  },
        )
    }

    return (
        <div className="editProduct">
            <SeoComponent
                title="Edit Component - Products | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Products"
            />
            <div className="link mobileLink">
                <BackNav
                    title={
                        referrer === 'view_package'
                            ? 'Back to Package'
                            : 'Back to Components'
                    }
                    onClick={
                        location?.state?.previousPath === 'viewComponent' ||
                        referrer === 'view_package'
                            ? history.goBack
                            : () => backToComponents('/app/products')
                    }
                />
            </div>
            {!isLoading ? (
                <>
                    {toastError && errorValue['msg']?.[0] && (
                        <Toast
                            message={
                                updateComponentFetching ? '' : errorValue['msg']
                            }
                            messageType="error"
                        />
                    )}
                    {toastError ? (
                        <>
                            <br />
                            <Toast messageType="error" message={errorMessage} />
                        </>
                    ) : (
                        <Toast
                            messageType="success"
                            message={
                                updateComponentFetching ? '' : successMessage
                            }
                        />
                    )}
                    <h1 className="MainTitleComponent">Edit Component</h1>
                    <div className="Details">
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <h2 className="SubTitle">Basic Information</h2>
                            <p className="InputTitle">Component Name</p>
                            <NoFLoatingLabelInputFields
                                type="text"
                                inputWidth="390px"
                                handleChange={handleInputChange}
                                name="componentName"
                                value={inputs.componentName}
                                inputValue={inputs.componentName}
                                errorMessage={
                                    allErrors?.includes('componentName')
                                        ? 'This field is Required'
                                        : errorValue?.name?.[0]
                                        ? errorValue?.name?.[0]
                                        : '' || errors?.componentName
                                }
                                onBlur={handleOnBlur}
                            />
                            <br />
                            <p className="InputTitle">Component Cost</p>
                            <CostField
                                name="componentCost"
                                defaultValue={'₦'}
                                currencyCode={'₦'}
                                value={
                                    inputs?.componentCost !== undefined &&
                                    floatWithCommas(inputs?.componentCost)
                                }
                                inputWidth="390px"
                                fullWidth={true}
                                placeholder="Component Cost"
                                handleChange={handleInputChange}
                                errorMessage={
                                    allErrors?.includes('componentCost')
                                        ? 'This field is Required'
                                        : '' || errors?.componentCost
                                }
                                noFloat
                                selectWidth="90px"
                                prefixDisabled={true}
                            />
                            <br />
                            <p className="InputTitle">Component Type</p>
                            <SelectField
                                values={allComponentTypes}
                                currentSelected={inputs.componentType}
                                initialOption={inputs.componentType}
                                handleChange={handleSelectChange}
                                name="componentType"
                                selectedValue={inputs.componentType}
                                withCheckBox={true}
                                selectWidth="390px"
                                style={{
                                    marginBottom: 20,
                                    border: '',
                                    width: '390px',
                                }}
                                dropdownClassName="componentType"
                                disabled={true}
                            />
                            {isAdminWorkspace && (
                                <>
                                    <p className="InputTitle">
                                        Component Warranty (Months)
                                    </p>
                                    <NoFLoatingLabelInputFields
                                        type="text"
                                        inputWidth="390px"
                                        handleChange={handleInputChange}
                                        value={inputs?.componentWarranty}
                                        inputValue={inputs?.componentWarranty}
                                        name="componentWarranty"
                                        errorMessage={errors?.componentWarranty}
                                        marginBottom="10px"
                                        optional={componentIsSolarPanel}
                                    />
                                </>
                            )}
                            <p className="InputTitle">Component Description</p>
                            <Editor
                                value={inputs?.componentDescription || ''}
                                name="componentDescription"
                                placeholder="Component Description"
                                onChangeHandler={value =>
                                    handleEditorChange(value)
                                }
                                editorWidth="100%"
                                editorHeight="150px"
                                errorMessage={
                                    allErrors?.includes('componentDescription')
                                        ? 'This field is Required'
                                        : ''
                                }
                                editorRef={editorRef}
                            />
                            <br />
                        </div>

                        <div className="ComponentImg">
                            <h2 className="SubTitle">Component Image</h2>

                            <div className="img-wrapper">
                                <p className="ant-upload-drag-icon">
                                    <br />
                                    <img
                                        src={successUpload}
                                        alt="successful-upload"
                                    />
                                </p>
                                <div className="filename">
                                    {truncateFileName(fileName)}
                                </div>
                                <Upload {...props}>
                                    <div style={{ marginBottom: '13px' }}>
                                        <span className="replaceDoc">
                                            Replace Image
                                        </span>
                                    </div>
                                </Upload>

                                <Button
                                    btnBgColor="var(--purple-light)"
                                    btnTextColor="var(--blue)"
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    btnWidth="147px"
                                    handleClick={() => setShowImageSample(true)}
                                >
                                    Use Image Sample
                                </Button>
                                <br />
                            </div>
                            {showImageSample && (
                                <div className="ImageListingEdit">
                                    <ImageListing
                                        onClose={() =>
                                            setShowImageSample(false)
                                        }
                                        onSelect={(ImageName, imageUrl) => {
                                            setSampleImgUrl(imageUrl)
                                            setFileName(ImageName)
                                        }}
                                        componentType={inputs?.componentType}
                                        componentName={inputs?.componentName}
                                    />
                                    <br />
                                </div>
                            )}
                            {componentAttributes.length === 0 ? (
                                ''
                            ) : (
                                <>
                                    <h2 className="SubTitle mobileAttributeInfo">
                                        Attribute Information
                                    </h2>
                                </>
                            )}
                            <div className="AttributeInformationWrapper">
                                {/* {getAttributeOptions(attribute?.name)?.length > 0 ? (
                    <SelectField
                      values={getAttributeOptions(attribute?.name)}
                      currentSelected={attribute?.value}
                      initialOption={attribute?.name}
                      handleChange={handleInputChange}
                      name={attribute.name}
                      value={attribute?.value}
                      withCheckBox={true}
                      selectWidth={
                        getAttributeUnits(attribute?.name)?.length > 0
                          ? '200px'
                          : '100%'
                      }
                      dropdownClassName=''
                      errorMessage={
                        allErrors?.includes(attribute.name)
                          ? 'This field is Required'
                          : ''
                      }
                    />
                  ) : (
                    <InputFields
                      step='addAttributesToComponent'
                      type={checkFiledType(attribute?.name)}
                      inputWidth={
                        getAttributeUnits(attribute?.name)?.length > 0
                          ? '200px'
                          : 100
                      }
                      title={attribute.name}
                      value={attribute?.value}
                      handleChange={handleInputChange}
                      name={attribute.name}
                      autoFocus={index === 0 ? true : false}
                      errorMessage={
                        allErrors?.includes(attribute.name)
                          ? 'This field is Required'
                          : ''
                      }
                    />
                  )} */}
                                {componentAttributes.map((value, i) => (
                                    <div key={i}>
                                        <div
                                            style={{
                                                display: !value?.unit
                                                    ? 'block'
                                                    : 'flex',
                                            }}
                                        >
                                            {value?.options?.length > 0 ? (
                                                <SelectField
                                                    values={value?.options}
                                                    currentSelected={
                                                        value?.value
                                                    }
                                                    initialOption={value?.value}
                                                    handleChange={(
                                                        name,
                                                        selected,
                                                    ) => {
                                                        handleAttributeChange(
                                                            name,
                                                            selected,
                                                        )
                                                    }}
                                                    name={value?.name}
                                                    value={value?.value}
                                                    withCheckBox={true}
                                                    selectWidth={'100%'}
                                                    dropdownClassName=""
                                                    errorMessage={
                                                        allErrors?.includes(
                                                            value?.name,
                                                        )
                                                            ? 'This field is Required'
                                                            : ''
                                                    }
                                                />
                                            ) : (
                                                <InputFields
                                                    type="number"
                                                    inputWidth={
                                                        value?.unit
                                                            ? '200px'
                                                            : 100
                                                    }
                                                    title={value?.name}
                                                    handleChange={event =>
                                                        handleAttributeChange(
                                                            event?.target?.name,
                                                            event?.target
                                                                ?.value,
                                                        )
                                                    }
                                                    inputValue={value?.value}
                                                    name={'text' + value?.name}
                                                    TextPrefilled={
                                                        value?.value?.length > 0
                                                            ? true
                                                            : false
                                                    }
                                                    errorMessage={
                                                        allErrors?.includes(
                                                            value?.name,
                                                        )
                                                            ? 'This field is Required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                            &nbsp; &nbsp;
                                            {!value?.unit ? (
                                                ''
                                            ) : (
                                                <div style={{ width: '100%' }}>
                                                    <SelectField
                                                        values={getAttributeUnits(
                                                            value?.name,
                                                        )}
                                                        currentSelected={
                                                            value?.unit
                                                        }
                                                        initialOption="Unit of Measurement"
                                                        handleChange={(
                                                            name,
                                                            selected,
                                                        ) =>
                                                            handleAttributeChange(
                                                                name,
                                                                selected,
                                                            )
                                                        }
                                                        name={
                                                            'drop' + value?.name
                                                        }
                                                        selectedValue={
                                                            value?.unit
                                                        }
                                                        withCheckBox={true}
                                                        selectWidth="100%"
                                                        dropdownClassName="units"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {value?.required ? (
                                            ''
                                        ) : (
                                            <div
                                                className="removeAttributeWrapper"
                                                style={{ top: '-15px' }}
                                            >
                                                <CloseOutlined className="removeAttributeIcon" />

                                                <span
                                                    onClick={() =>
                                                        removeAttribute(
                                                            value?.name,
                                                        )
                                                    }
                                                    className="replaceDoc"
                                                    style={{
                                                        textDecoration: 'none',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Remove attribute
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <br />
                            </div>
                            {checkAddNewListTotal() > 0 ? (
                                <div className="AddNewButton">
                                    <Dropdown
                                        overlay={menu}
                                        visible={showMenu}
                                        onVisibleChange={handleVisibleChange}
                                    >
                                        <Button
                                            btnBgColor="var(--purple-light)"
                                            btnTextColor="var(--blue)"
                                            btnTextColorOutline="var(--blue)"
                                            btnOutlineColor="var(--purple-light)"
                                            btnBgColorOutline="#E2EEFF"
                                            type="outline"
                                            btnWidth="127px"
                                            handleClick={setShowMenu}
                                        >
                                            <PlusOutlined
                                                style={{
                                                    fontSize: 16,
                                                    color: 'var(--blue)',
                                                }}
                                            />
                                            Add New
                                        </Button>
                                    </Dropdown>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <div className="btn">
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnTextColorOutline="var(--blue)"
                            btnOutlineColor="var(--purple-light)"
                            btnBgColorOutline="#E2EEFF"
                            btnWidth="160px"
                            handleClick={() => handleUpdateProcess()}
                        >
                            {updateComponentFetching ? (
                                <InlineLoader />
                            ) : (
                                'Save changes'
                            )}
                        </Button>
                    </div>
                    <div className="HelpCenterWrapper">
                        <FloatingBox
                            floatIcon={QuestionMarkIcon}
                            floatBgColor="#011A3C"
                            floatBoxClass="floatBox"
                            renderComponent={props => <HelpCenter {...props} />}
                        />
                    </div>
                </>
            ) : (
                'Fetching Component...'
            )}
        </div>
    )
}

export default EditComponent
