import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { deleteProfileApi } from 'src/api/admin/profiles/details'
import { errorHandler } from 'src/utils/errorHandler'

const useDeleteProfile = onSuccess => {
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { id } = useParams()

    const { mutate, isLoading } = useMutation({
        mutationFn: () => deleteProfileApi(id),
        onSuccess: () => {
            onSuccess()
        },
        onError: error => {
            const errorMessage = errorHandler(error?.response?.data)
            setToastError(true)
            setErrorMessage(errorMessage)
        },
    })

    return {
        deleteProfileMutate: mutate,
        deleteProfileLoading: isLoading,
        deleteProfileToastError: toastError,
        deleteProfileErrorMessage: errorMessage,
    }
}

export default useDeleteProfile
