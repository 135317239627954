/* eslint-disable react/prop-types */
import { format } from 'date-fns'
import styles from './invoice.module.scss'
import { currencyFormatter } from '../../../../utils/currencyFormatter'

const Invoice = ({ onClose, invoiceData, repaymentMethod }) => {
    const {
        TopRectangle,
        Title,
        StatusWrapper,
        PaidStatus,
        Subtitle,
        BottomRectangle,
        LeftWrapper,
        RightWrapper,
        BottomRectangleTitle,
        BottomRectangleSubTitle,
        BtnWrapper,
        CloseBtn,
        // DownloadBtn,
        // DownloadLink,
        DueStatus,
    } = styles

    const formattedDate = format(new Date(invoiceData.due_date), 'd MMM, yyyy')

    return (
        <>
            <div className={TopRectangle}>
                <div className={StatusWrapper}>
                    <p className={Title}>Amount Due</p>
                    <p
                        className={
                            invoiceData.status === 'UNPAID'
                                ? DueStatus
                                : PaidStatus
                        }
                    >
                        {invoiceData?.status}
                    </p>
                </div>
                <p className={Subtitle}>
                    {invoiceData.currency.symbol}
                    {currencyFormatter(invoiceData?.amount_due)}
                </p>
            </div>

            <div className={BottomRectangle}>
                <div className={LeftWrapper}>
                    <p className={BottomRectangleTitle}>Date Due</p>
                    <p className={BottomRectangleSubTitle}>
                        {invoiceData.due_date !== undefined && formattedDate}
                    </p>
                </div>

                <div className={RightWrapper}>
                    <p className={BottomRectangleTitle}>Method of payment</p>
                    <p className={BottomRectangleSubTitle}>{repaymentMethod}</p>
                </div>
            </div>

            <div className={BtnWrapper}>
                <button className={CloseBtn} onClick={onClose}>
                    Close
                </button>

                {/* <button className={DownloadBtn}>
                    <a
                        className={DownloadLink}
                        href={invoiceData?.invoice_url}
                        rel="noreferrer"
                        target="_blank"
                    >
                        Download
                    </a>
                </button> */}
            </div>
        </>
    )
}

export default Invoice
