import ConsumerNavigation from 'src/components/Navigation/consumerNav'
import ConsumerMainSection from 'src/components/ConsumerLandingPageBody'
import ConsumerFooter from 'src/components/ConsumerFooter'
import SeoComponent from 'src/components/Seo'
import CookieNotification from 'src/components/CookieNotificiation'

const ConsumerGetStarted = () => {
    return (
        <>
            <SeoComponent
                title="Get Started | SunFi | Simplifying and Scaling Clean Energy"
                tracker="GetStartedTracker"
            />
            <ConsumerNavigation page="getStarted" />
            <ConsumerMainSection page="getStarted" />
            <CookieNotification />
            <ConsumerFooter />
        </>
    )
}

export default ConsumerGetStarted
