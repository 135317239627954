export const promos = [
    {
        id: 1,
        title: 'SureChill Promo',
        mobileImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/surechill-promo-banner-mobile.png',
        desktopImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/surechill-promo-banner-desktop.png',
        link: '/consumer/get-your-offer?promo=true&promo_type=surechill',
        active: true,
    },
    {
        id: 2,
        title: 'Arnergy Promo',
        mobileImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/arnergy5000-promo-banner-mobile-new.png',
        desktopImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/arnergy5000-promo-banner-desktop-new.png',
        link: '/consumer/get-your-offer?promo=true&promo_type=arnergy5000',
        active: false,
    },
    {
        id: 3,
        title: 'Renergy Promo',
        mobileImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Renergy+promo+Mobile.png',
        desktopImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Renergy+promo+Desktop.png',
        link: '/consumer/get-your-offer?promo=true&promo_type=renergy',
        active: true,
    },
    {
        id: 4,
        title: 'Arnergy Promo',
        mobileImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/arnergy5000x-promo-banner-mobile-new.png',
        desktopImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/arnergy5000x-promo-banner-desktop-new.png',
        link: '/consumer/get-your-offer?promo=true&promo_type=arnergy5000x',
        active: false,
    },
    {
        id: 5,
        title: 'D.Light Promo',
        mobileImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Dlight+promo+Mobile_x.png',
        desktopImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/D.light+promo+Desktop_x.png',
        link: '/consumer/get-your-offer?promo=true',
        active: true,
    },
]

export const activePromos = promos.filter(promo => promo.active)
