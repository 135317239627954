import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { InputFields } from 'src/components/InputFields'
import { useMutation } from 'react-query'
import { sendDetailedCalculatorReportApi } from 'src/api/shoppingExperience/solar-savings'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'

// app tracker variables
const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'SSE_LANDING_PAGE'

const EmailModal = ({
    showModal,
    closeModal,
    email,
    setEmail,
    emailError,
    handleBlur,
}) => {
    const { errorAlert, successAlert } = useCustomToast()

    const { mutate: SendDetailedReportMutation, isLoading } = useMutation({
        mutationFn: () =>
            sendDetailedCalculatorReportApi({
                session_id: sessionStorage.getItem(
                    'solar-savings-session-id' ?? '',
                ),
                email,
            }),
        onSuccess: () => {
            successAlert('Email sent successfully')
            closeModal()
        },
        onError: error => {
            errorAlert(errorHandler(error?.response?.data))
        },
    })

    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={() => closeModal()}
            modalWidth={'475px'}
            title={'Send detailed copy to your email'}
            v2
            content={
                <div className={'GettingStartedWrapper'}>
                    <div className={'GettingStartedHeadingAndText'}>
                        <h3>Enter your email address</h3>
                        <p>
                            Please enter your email address to receive a
                            detailed copy from us
                        </p>
                        <InputFields
                            title={'Your Email Address'}
                            name={'email'}
                            value={email}
                            errorMessage={emailError}
                            handleChange={e => {
                                setEmail(e.target.value)
                            }}
                            onBlur={handleBlur}
                        />
                    </div>
                </div>
            }
            showFooter
            footerLeftContent=""
            primaryBtnText={'Confirm'}
            primaryBtnLoading={isLoading}
            handlePrimaryBtnClick={() => {
                SendDetailedReportMutation()
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['sendDetailedSavingCalculations'].action,
                    eventTrackers['sendDetailedSavingCalculations'].label,
                    eventTrackers['sendDetailedSavingCalculations'].category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event: email,
                    },
                )
            }}
            primaryBtnWidth={'160px'}
            primaryBtnHeight={'53px'}
        />
    )
}

EmailModal.propTypes = {
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    emailError: PropTypes.string,
    handleBlur: PropTypes.func,
}

export default EmailModal
