/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import styles from './checkbox.module.scss'

const Checkbox = ({
    handleChecked,
    children,
    className,
    isChecked,
    validate,
    signupchecked,
    acceptTextClick = false,
    page,
    lightBlueUncheckedBg,
}) => {
    const { CheckBoxWrapper, CheckboxText, CheckboxCursor } = styles
    const [checked, setChecked] = useState(false)
    const toggle = () => {
        setChecked(!checked)
        if (handleChecked) {
            handleChecked({ checked: !checked })
        }
    }

    useEffect(() => {
        setChecked(isChecked)
    }, [isChecked])
    // onClick={ acceptTextClick && toggle}
    return (
        <div
            className={className ? className : CheckBoxWrapper}
            onClick={acceptTextClick ? toggle : () => {}}
        >
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={!acceptTextClick ? toggle : () => {}}
                className={CheckboxCursor}
            >
                <path
                    d="M10 19.1667C4.93729 19.1667 0.833374 15.0627 0.833374 10C0.833374 4.93725 4.93729 0.833333 10 0.833333C15.0628 0.833333 19.1667 4.93725 19.1667 10C19.1667 15.0627 15.0628 19.1667 10 19.1667ZM9.08612 13.6667L15.567 7.18492L14.2708 5.88875L9.08612 11.0743L6.49287 8.48108L5.19671 9.77725L9.08612 13.6667Z"
                    fill={
                        checked
                            ? 'var(--blue)'
                            : validate || signupchecked
                            ? '#32CC79'
                            : page === 'SSE' || lightBlueUncheckedBg
                            ? '#E2EEFF'
                            : 'var(--grey)'
                    }
                />
            </svg>
            <div>
                <p className={CheckboxText}>{children}</p>
            </div>
        </div>
    )
}

export const SelectCheckbox = ({ children, isChecked, id, fontColor }) => {
    const { CheckBoxWrapper, CheckboxText } = styles
    const [checked, setChecked] = useState(false)
    const toggle = () => {
        setChecked(!checked)
    }

    return (
        <div className={CheckBoxWrapper} onClick={toggle} key={id}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10 19.1667C4.93729 19.1667 0.833374 15.0627 0.833374 10C0.833374 4.93725 4.93729 0.833333 10 0.833333C15.0628 0.833333 19.1667 4.93725 19.1667 10C19.1667 15.0627 15.0628 19.1667 10 19.1667ZM9.08612 13.6667L15.567 7.18492L14.2708 5.88875L9.08612 11.0743L6.49287 8.48108L5.19671 9.77725L9.08612 13.6667Z"
                    fill={isChecked ? 'var(--blue)' : 'var(--grey)'}
                />
            </svg>
            <div>
                <p className={CheckboxText} style={{ color: fontColor }}>
                    {children}
                </p>
            </div>
        </div>
    )
}

export default Checkbox
