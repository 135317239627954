import { InputFields } from 'src/components/InputFields'
import './inputfieldwithbutton.scss'
import PropTypes from 'prop-types'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const InputFieldWithButton = ({
    title,
    btnText,
    name,
    mobileBtnText,
    type,
    handleChange,
    disabled,
    value,
    verified,
    handleBlur,
    errorMessage,
    inprogress,
    handleFocus,
    onBtnClick,
    btnDisabled,
    showBtn,
    progressText,
}) => {
    const { isMobile } = useMediaQueries()
    return (
        <div className="field_container">
            <InputFields
                title={title}
                type={type}
                name={name}
                marginBottom="10px"
                handleChange={handleChange}
                disabled={disabled}
                value={value}
                verified={verified}
                onBlur={handleBlur}
                errorMessage={errorMessage}
                inprogress={inprogress}
                onFocus={handleFocus}
            />
            {inprogress && <span className="inProgress">{progressText}</span>}
            {showBtn ? (
                <button onClick={onBtnClick} disabled={btnDisabled}>
                    <span>{isMobile ? mobileBtnText : btnText}</span>
                </button>
            ) : null}
        </div>
    )
}

InputFieldWithButton.propTypes = {
    title: PropTypes.string,
    btnText: PropTypes.string,
    name: PropTypes.string,
    mobileBtnText: PropTypes.string,
    type: PropTypes.string,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    verified: PropTypes.bool,
    handleBlur: PropTypes.func,
    errorMessage: PropTypes.string,
    inprogress: PropTypes.bool,
    handleFocus: PropTypes.func,
    onBtnClick: PropTypes.func,
    btnDisabled: PropTypes.bool,
    showBtn: PropTypes.bool,
    progressText: PropTypes.string,
}

export default InputFieldWithButton
