import { useMutation } from 'react-query'
import { notifyConsumerOfKycUpdate } from 'src/api/admin/assisted-kyc'

export const useNotifyConsumerOfKycUpdate = () => {
    const notifyConsumerOfUpdate = useMutation({
        mutationFn: ({ estimationId }) =>
            notifyConsumerOfKycUpdate(estimationId),
        onSuccess: (response, variables) => {
            if (response.data) {
                variables.handleSuccess?.()
            }
        },
    })

    return {
        notifyConsumerOfUpdate,
    }
}
