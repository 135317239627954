import { useMutation } from 'react-query'
import { initiateSSEConsumerLoginApi } from 'src/api/shoppingExperience/login'
import { appTracking } from 'src/utils/appTracker'
import { formatPhoneNumber } from 'src/utils/formatting'

const useInitiateLoginMutation = (
    inputs,
    onSuccess,
    onError,
    userType,
    pageUrlName,
    pageTracker,
    pageTitle,
) => {
    const initiateLoginMutation = useMutation({
        mutationFn: otpMethod => {
            return initiateSSEConsumerLoginApi(
                otpMethod === 'phone'
                    ? { phone_number: formatPhoneNumber(inputs.phone) }
                    : { email: inputs.email },
            )
        },
        onSuccess: data => {
            const response = data?.data?.data
            if (response.action === 'REDIRECT') {
                const url = new URL(response.url)
                const redirectUrl = url.pathname + url.search
                history.push(redirectUrl)
            } else {
                onSuccess?.()
            }
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                'CONSUMER_LOGIN_SUCCESSFUL',
                'KYC_CONSUMER_LOGIN_METHOD_PAGE',
                'KYC_CONSUMER_LOGIN_PAGE',
            )
        },
        onError: err => {
            onError?.(err)
            userType === 'consumer' &&
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    'CONSUMER_LOGIN_UNSUCCESSFUL',
                    'KYC_CONSUMER_LOGIN_METHOD_PAGE',
                    'KYC_CONSUMER_LOGIN_PAGE',
                    ['GA', 'MP'],
                    'event',
                    { err },
                )
        },
    })

    return initiateLoginMutation
}

export default useInitiateLoginMutation
