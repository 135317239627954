import PropTypes from 'prop-types'
import ActionModal from 'src/components/ActionModal'
import { ConsumerButton } from 'src/components/Button'
import { ReactComponent as EditIcon } from 'src/assets/images/edit-icon-no-stroke.svg'
import { ReactComponent as ApproveIcon } from 'src/assets/images/circle-green-approve.svg'
import { ReactComponent as RejectIcon } from 'src/assets/images/circle-red-reject.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { getActionModalData, findComponentBrandInArray } from '../data'
import { useReviewBrandsCard } from './hooks/useReviewBrandsCard'
import styles from './reviewbrandscard.module.scss'
import EditBrandModal from './EditBrandsModal'

const ReviewBrandsCard = ({
    componentType,
    componentBrands,
    providerId,
    handleRefetch,
    handleConfirmBrandingDecision,
}) => {
    const { isMobile } = useMediaQueries()
    const {
        actionModalOpen,
        actionModalType,
        approvedComponentBrands,
        rejectedComponentBrands,
        editBrandsModalOpen,
        selectedComonentBrands,
        isLoading,
        handleAddApprovedComponentBrand,
        handleAddRejectedComponentBrand,
        handleUndoSelection,
        handleApproveAllClick,
        handleActionModalCancel,
        actionModalHandler,
        handleSaveThisReviewClick,
        handleClickEditBrand,
        setEditBrandsModalOpen,
    } = useReviewBrandsCard({
        handleRefetch,
        componentType,
        providerId,
        handleConfirmBrandingDecision,
    })

    const actionModalData = getActionModalData(
        actionModalType,
        componentType?.name,
        selectedComonentBrands,
    )
    return (
        <>
            <ActionModal
                actionModalOpen={actionModalOpen}
                headerText={actionModalData.headerText}
                subTitle={actionModalData.subTitle}
                actionHandler={actionModalHandler}
                actionType={actionModalData.actionType}
                actionText={actionModalData.actionText}
                noCancelBtn={actionModalData.noCancelBtn}
                closeModal={handleActionModalCancel}
                loading={isLoading}
                noBackLink
            />
            <EditBrandModal
                showModal={editBrandsModalOpen}
                closeModal={() => setEditBrandsModalOpen(false)}
                componentType={componentType}
                componentBrands={componentBrands}
                providerId={providerId}
                handleRefetch={handleRefetch}
            />
            <div className={styles.Card}>
                <div className={styles.CardHeader}>
                    <h3>{componentType?.name || ''}</h3>
                    <ConsumerButton
                        btnWidth={'82px'}
                        btnHeight={'29px'}
                        btnBgColor={'#F9FCFF'}
                        btnTextColor={'#004AAD'}
                        hoverNoFill
                        type={'outline'}
                        borderOutlineColor={'#004AAD'}
                        btnFontSize={'12px'}
                        padding={'0px'}
                        fontFamily={'SF-Pro-Display-Medium'}
                        handleClick={() =>
                            handleApproveAllClick(componentBrands)
                        }
                    >
                        Approve all
                    </ConsumerButton>
                </div>
                <div className={styles.ReviewBrandsCardBody}>
                    <div className={styles.BodyHeader}>
                        <h4>Added brands</h4>
                        <button onClick={handleClickEditBrand}>
                            <EditIcon />
                            {isMobile ? '' : 'Edit'}
                        </button>
                    </div>
                    {componentBrands?.map((componentBrand, index) => (
                        <div key={index} className={styles.BrandRow}>
                            <p>{componentBrand.name || ''}</p>
                            {findComponentBrandInArray(
                                approvedComponentBrands,
                                componentBrand,
                            ) ? (
                                <span className={styles.ApprovedTag}>
                                    Approved
                                </span>
                            ) : findComponentBrandInArray(
                                  rejectedComponentBrands,
                                  componentBrand,
                              ) ? (
                                <span className={styles.RejectedTag}>
                                    Declined
                                </span>
                            ) : (
                                <div className={styles.BrandRowActions}>
                                    <button
                                        onClick={() =>
                                            handleAddApprovedComponentBrand(
                                                componentBrand,
                                            )
                                        }
                                    >
                                        <ApproveIcon />
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleAddRejectedComponentBrand(
                                                componentBrand,
                                            )
                                        }
                                    >
                                        <RejectIcon />
                                    </button>
                                </div>
                            )}{' '}
                        </div>
                    ))}
                </div>
                <div className={styles.ReviewBrandsCardFooter}>
                    <ConsumerButton
                        disabled={
                            !approvedComponentBrands.length &&
                            !rejectedComponentBrands.length
                        }
                        btnWidth={'164px'}
                        btnHeight={'44px'}
                        btnBgColor={'#F9FCFF'}
                        btnTextColor={'#004AAD'}
                        btnFontSize={'13px'}
                        padding={'0px'}
                        fontFamily={'SF-Pro-Display-Semibold'}
                        handleClick={handleUndoSelection}
                    >
                        Undo all action
                    </ConsumerButton>
                    <ConsumerButton
                        disabled={
                            !approvedComponentBrands.length &&
                            !rejectedComponentBrands.length
                        }
                        btnWidth={'164px'}
                        btnHeight={'44px'}
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#FFF'}
                        btnFontSize={'13px'}
                        padding={'0px'}
                        fontFamily={'SF-Pro-Display-Semibold'}
                        handleClick={handleSaveThisReviewClick}
                    >
                        Save this review
                    </ConsumerButton>
                </div>
            </div>
        </>
    )
}

ReviewBrandsCard.propTypes = {
    componentType: PropTypes.object,
    componentBrands: PropTypes.array,
    providerId: PropTypes.string,
    handleRefetch: PropTypes.func,
    handleConfirmBrandingDecision: PropTypes.func,
}

export default ReviewBrandsCard
