import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    page: '1',
    name: '',
    start_date: null,
    end_date: null,
    start_amount: 0,
    end_amount: null,
    component_types: '',
    current_tab: '1',
    filter_mode: false,
    provider_id: '',
}

const providerProductsListing = createSlice({
    name: 'providerProductsListingFilter',
    initialState,
    reducers: {
        updateProviderProductsFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetProviderProductsFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateProviderProductsFilters, resetProviderProductsFilters } =
    providerProductsListing.actions

export const updatedProviderProductsFilters = state =>
    state.providerProductsFilter

const persistConfig = {
    key: 'providerProductsListingFilter',
    storage,
}

export const persistedProviderProductsListingReducer = persistReducer(
    persistConfig,
    providerProductsListing.reducer,
)

export default providerProductsListing.reducer
