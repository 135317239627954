import { useState } from 'react'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'
import {
    otpSchema,
    validateOutrightSaleInfoKyc,
    verifyContactSchema,
} from 'src/utils/validationSchema'
import { formatOfferData, sameAddressOptions } from '../utils'
import { useHistory } from 'react-router-dom'
import useFetchKYCInfo from './useFetchKYCInfo'
import useOfferInfo from './useOfferInfo'
import { useInitiateVerification } from './useVerifyContact'
import usePersistOutrightSaleInfo from './usePersistOutrightSaleInfo'
import { removeEmptyValues } from 'src/utils/removeEmptyValues'
import { useSSEAuthValidateOtp } from 'src/api/shoppingExperience/auth/queries'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'
import { useQueryClient } from 'react-query'
import { decodeUserInfo } from 'src/utils/auth'
import { useChangePackage } from '../../../AccountSetupOverview/hooks/useChangePackage'

const useOutrightSaleInformation = () => {
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        phoneVerified: false,
        email: '',
        emailVerified: false,
        contactAddress: '',
        contactState: '',
        contactCity: '',
        contactCountry: '',
        sameAddress: sameAddressOptions[0],
        installationAddress: '',
        installationCountry: '',
        installationState: '',
        installationCity: '',
        businessName: '',
        businessType: '',
        businessIndustry: '',
    })
    const [errors, setErrors] = useState({})
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [action, setAction] = useState('')
    const [showVerifyContactModal, setShowVerifyContactModal] = useState(false)
    const [contactTypeToVerify, setContactTypeToVerify] = useState('') // email || phone
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)
    const toastData = {
        showToast: false,
        toastMessage: '',
        messageType: '',
    }
    const [verifyContactToast, setVerifyContactToast] = useState(toastData)
    const [actionModalToast, setActionModalToast] = useState(toastData)
    const [saveOrSubmitAction, setSaveOrSubmitAction] = useState('') // Save || Submit
    const [paystackLoading, setPaystackLoading] = useState(false)
    const [showPayStack, setShowPaystack] = useState(false)
    const [callInitializePayment, setCallInitializePayment] = useState(true)
    const [verifyLoading, setVerifyLoading] = useState(false)
    const formattedContactTypeToVerify =
        contactTypeToVerify.charAt(0).toUpperCase() +
        contactTypeToVerify.slice(1).toLowerCase()
    const [showAddPackageDrawer, setShowAddPackageDrawer] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState([])
    const [showActionModal, setShowActionModal] = useState({
        open: false,
        action: '',
    })

    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const history = useHistory()
    const queryClient = useQueryClient()
    const { kycData, userType, kycInfoFetching, kycRefetch } = useFetchKYCInfo(
        inputs,
        setInputs,
    )
    const { offer, offerFetching } = useOfferInfo(formatOfferData)
    const hasPaidOutrightly = offer?.approvalStatus === 'APPROVED'

    const { successAlert, errorAlert } = useCustomToast()
    const { sseValidateAuthOtp } = useSSEAuthValidateOtp({
        onSuccess: () => {
            successAlert(
                `${formattedContactTypeToVerify} verified successfully`,
            )
            setOtp(['', '', '', '', '', ''])
            setShowVerifyContactModal(false)
            queryClient.invalidateQueries('fetch-outright-sale-kyc')
            setContactTypeToVerify('')
        },
        onError: error => {
            setVerifyContactToast({
                showToast: true,
                toastMessage:
                    error?.[0] ||
                    errorHandler(error?.response?.data) ||
                    'Unable to verify otp, please try again',
                messageType: 'error-secondary',
            })
            resetToast()
        },
    })

    const handleShowPaystackModal = () => {
        setPaystackLoading(true)
        setShowPaystack(true)
        setCallInitializePayment(!callInitializePayment)
    }

    const resetToast = () => {
        setTimeout(() => {
            setVerifyContactToast(toastData)
            setActionModalToast(toastData)
        }, 2000)
    }

    const { persistInfoMutate, persistInfoLoading } =
        usePersistOutrightSaleInfo(
            saveOrSubmitAction,
            () => setAction('progress-saved'),
            () => handleShowPaystackModal(),
            setActionModalToast,
            resetToast,
        )
    const pageLoading = kycInfoFetching || offerFetching

    const verifyContactsModalInputs = {
        phone: inputs?.phone,
        email: inputs?.email,
    }
    const {
        initiateVerificationMutate,
        initiateEmailVerificationLoading,
        initiatePhoneVerificationLoading,
        initiateVerificationResent,
        initiateVerificationSetResent,
    } = useInitiateVerification(
        contactTypeToVerify,
        verifyContactsModalInputs,
        setShowVerifyContactModal,
        setVerifyContactToast,
        resetToast,
    )

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const handleSameAddressOptionChange = option => {
        const selectedOption = sameAddressOptions.find(
            opt => opt.title === option,
        )

        setInputs(prev => ({
            ...prev,
            sameAddress: selectedOption,
            installationAddress: '',
            installationCountry: '',
            installationState: '',
            installationCity: '',
        }))

        if (selectedOption?.value) {
            removeErrors('installationAddress')
            removeErrors('installationCountry')
            removeErrors('installationState')
            removeErrors('installationCity')
        }
    }

    const validateField = useValidateOnBlur()

    const handleOnBlur = async e => {
        const { name, value } = e.target

        await validateField({
            name,
            value,
            schema: validateOutrightSaleInfoKyc,
            setErrors,
            context: { sameAddress: inputs.sameAddress?.value, userType },
        })
    }

    const handleSelectOnBlur = async name => {
        await validateField({
            name,
            value: inputs[`${name}`],
            schema: validateOutrightSaleInfoKyc,
            setErrors,
            context: { sameAddress: inputs.sameAddress?.value, userType },
        })
    }

    const handleChange = e => {
        const { name, value } = e.target

        setInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
        removeErrors(name)
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)

        if (name === 'contactState') {
            setInputs(prev => ({
                ...prev,
                contactCity: '',
            }))
        }

        if (name === 'installationState') {
            setInputs(prev => ({
                ...prev,
                installationCity: '',
            }))
        }
    }

    const storeActionAndOpenModal = action => {
        setAction(action)
        setActionModalOpen(true)
    }

    const closeActionModal = () => {
        setAction('')
        setActionModalOpen(false)
    }

    const goToSSEHome = () => {
        setActionModalOpen(false)
        history.push('/')
    }

    const actionModalHandler = () => {
        switch (action) {
            case 'payment-successful':
                history.push('/consumer/account-setup/overview')
                break
            case 'save-progress':
                handleSaveOrSubmit('Save')
                break
            case 'progress-saved':
                goToSSEHome()
                break
        }
    }

    const actionModalSecondaryHandler = () => {
        switch (action) {
            case 'progress-saved':
                setActionModalOpen(false)
                setAction('')
                break
        }
    }

    const initiateContactVerification = async type => {
        try {
            await verifyContactSchema
                .validate(
                    { phone: inputs?.phone, email: inputs?.email },
                    { context: { type: type }, abortEarly: false },
                )
                .then(() => {
                    const payload = {
                        [type === 'email' ? 'email' : 'phone_number']:
                            type === 'email'
                                ? inputs?.email
                                : formatPhoneNumber(inputs?.phone, '+234'),
                    }
                    setContactTypeToVerify(type)
                    initiateVerificationMutate(payload)
                    removeErrors(`${type}Verified`)
                })
        } catch (err) {
            const errorObj = {}
            err.inner.forEach(error => {
                errorObj[error.path] = error.message
            })

            setErrors(prev => ({ ...prev, ...errorObj }))
        }
    }

    const closeVerifyContactModal = () => {
        setShowVerifyContactModal(false)
        setContactTypeToVerify('')
    }

    const handleResendOtp = () => {
        const payload = {
            [contactTypeToVerify === 'email' ? 'email' : 'phone_number']:
                contactTypeToVerify === 'email' ? inputs?.email : inputs?.phone,
        }
        initiateVerificationMutate(payload)
    }

    const handleOtpSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                const phoneNumber = {
                    phone_number: formatPhoneNumber(inputs?.phone, '+234'),
                    code: otp.join(''),
                }
                const email = {
                    email: inputs?.email,
                    code: otp.join(''),
                }
                sseValidateAuthOtp(
                    contactTypeToVerify === 'email' ? email : phoneNumber,
                )
            })
            .catch(() => {
                setOtpError(true)
            })
    }

    const toggleAddPackageDrawer = () => {
        setShowAddPackageDrawer(prev => !prev)
        showAddPackageDrawer && setSelectedPackage([])
    }

    const handleChangePackage = () => {
        const allSolutions = offer?.onboardingType === 'ALL_SOLUTIONS'
        const profile = offer?.onboardingType === 'ENERGY_PROFILE'
        const profile_id = offer?.energyProfileId
        if (isAdminUser) {
            toggleAddPackageDrawer()
        } else if (allSolutions) {
            history.push({
                pathname: '/consumer/all-systems',
                state: {
                    isAuthenticatedUser: true,
                    estimation_id: offer?.offerId,
                    auth_user_id: kycData?.consumer?.user?.id,
                },
            })
        } else if (profile) {
            history.push({
                pathname: `/consumer/profile-details/${profile_id}`,
                state: {
                    isAuthenticatedUser: true,
                    estimation_id: offer?.offerId,
                    auth_user_id: kycData?.consumer?.user?.id,
                },
            })
        } else {
            history.push({
                pathname: offer?.isPromo
                    ? '/consumer/promos'
                    : '/consumer/systems',
                state: {
                    isAuthenticatedUser: true,
                    estimation_id: offer?.offerId,
                    auth_user_id: kycData?.consumer?.user?.id,
                },
            })
        }
    }

    const convertedOnboardingType =
        offer?.onboardingType === 'ALL_SOLUTIONS' ||
        offer?.onboardingType === 'ENERGY_PROFILE'
            ? 'profiles-or-all-systems'
            : 'custom-build'

    const seePackageInfo = () => {
        history.push({
            pathname: `/consumer/view-system/${offer?.solutionId}`,
            state: {
                isAuthenticatedUser: true,
                estimation_id: offer?.offerId,
                action: 'view_from_outright_sale_kyc_form',
                auth_user_id: kycData?.consumer?.user?.id,
                page: offer?.isPromo && 'promo',
                clusterCode: offer?.clusterCode,
                payment_model: offer?.paymentType,
                onboardingType: convertedOnboardingType,
                profile_id: offer?.energyProfileId,
                solution_id: offer?.solutionId,
                previous_payment_model: offer?.paymentType,
            },
        })
    }

    const handleSaveInfo = () => {
        storeActionAndOpenModal('save-progress')
        setSaveOrSubmitAction('Save')
    }

    const handleSubmitInfo = () => {
        setSaveOrSubmitAction('Submit')
        handleSaveOrSubmit('Submit')
    }

    const handleSaveOrSubmit = action => {
        if (action === 'Save') {
            saveOrSubmitInfo(action)
        } else if (action === 'Submit') {
            validateOutrightSaleInfoKyc
                .validate(inputs, {
                    context: {
                        sameAddress: inputs?.sameAddress?.value,
                        userType,
                    },
                    abortEarly: false,
                })
                .then(() => {
                    saveOrSubmitInfo(action)
                })
                .catch(err => {
                    let errList = {}
                    err?.inner?.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        }
    }

    const saveOrSubmitInfo = action => {
        let payload = {
            first_name: inputs?.firstName,
            last_name: inputs?.lastName,
            contact_address: removeEmptyValues({
                street_address: inputs?.contactAddress,
                city: inputs?.contactCity,
                state_of_residence: inputs?.contactState,
                country: inputs?.contactCountry,
            }),
            button: action,
        }

        if (inputs?.sameAddress?.value) {
            payload.installation_address = removeEmptyValues({
                street_address: inputs?.contactAddress,
                city: inputs?.contactCity,
                state: inputs?.contactState,
                country: inputs?.contactCountry,
            })
        } else {
            payload.installation_address = removeEmptyValues({
                street_address: inputs?.installationAddress,
                city: inputs?.installationCity,
                state: inputs?.installationState,
                country: inputs?.installationCountry,
            })
        }

        if (userType === 'BUSINESS') {
            payload.business_type = inputs?.businessType
            payload.business_industry = inputs?.businessIndustry
            payload.business_name = inputs?.businessName
            payload.business_email = inputs?.email
        }

        payload = removeEmptyValues(payload)

        persistInfoMutate(payload)
    }

    const reloadPage = () => {
        window.location.reload()
    }

    const handlePaystackLoading = () => setPaystackLoading(false)

    const handleInitializePaymentError = error => {
        errorAlert(error)
    }

    const onPaymentSuccess = () => {
        setVerifyLoading(false)
        setAction('payment-successful')
        setActionModalOpen(true)
    }

    useOTPVerify({ otp, handleOtpSubmit })

    const handleUpdatePackages = data => {
        setSelectedPackage(data)
        setShowActionModal({
            open: true,
            action: 'confirm',
        })
    }

    const { changePackage } = useChangePackage({
        onSuccess: () => {
            setShowActionModal(prev => ({
                ...prev,
                action: 'success',
            }))
        },
    })

    const handleChangePackageSuccess = () => {
        if (showActionModal?.action === 'confirm') {
            changePackage.mutate({
                id: offer.offerId,
                payload: {
                    dtc: {
                        id: selectedPackage[0]?.id,
                        appliances: selectedPackage[0]?.appliances,
                        ...(selectedPackage[0]?.payment_plan_types?.length >
                            0 && {
                            payment_plan_types:
                                selectedPackage[0]?.payment_plan_types,
                        }),
                        ...(selectedPackage[0]?.subscription_cluster_code !==
                            '' && {
                            subscription_cluster_code:
                                selectedPackage[0]?.subscription_cluster_code,
                        }),
                        ...(selectedPackage[0]?.lease_to_own_cluster_code !==
                            '' && {
                            lease_to_own_cluster_code:
                                selectedPackage[0]?.lease_to_own_cluster_code,
                        }),
                    },
                },
            })
        } else {
            if (
                ['Subscription', 'Lease to Own'].includes(
                    selectedPackage[0]?.payment_plan_types[0],
                )
            ) {
                history.push(`/admin/plans/${offer.offerId}/kyc`)
            } else {
                setShowActionModal({
                    open: false,
                    action: '',
                })
                setSelectedPackage([])
                toggleAddPackageDrawer()
                kycRefetch()
            }
        }
    }

    return {
        inputs,
        errors,
        handleOnBlur,
        handleChange,
        handleSelectChange,
        handleSelectOnBlur,
        sameAddressOptions,
        handleSameAddressOptionChange,
        actionModalOpen,
        action,
        closeActionModal,
        actionModalHandler,
        goToSSEHome,
        actionModalSecondaryHandler,
        userType,
        showVerifyContactModal,
        initiateContactVerification,
        closeVerifyContactModal,
        contactTypeToVerify,
        verifyContactsModalInputs,
        otp,
        setOtp,
        otpError,
        handleResendOtp,
        verifyContactToast,
        initiateVerificationResent,
        initiateVerificationSetResent,
        handleOtpSubmit,
        initiateEmailVerificationLoading,
        initiatePhoneVerificationLoading,
        pageLoading,
        offer,
        handleChangePackage,
        seePackageInfo,
        saveLoading: persistInfoLoading && saveOrSubmitAction === 'Save',
        submitLoading: persistInfoLoading && saveOrSubmitAction === 'Submit',
        actionModalToast,
        showPayStack,
        reloadPage,
        paystackLoading,
        handlePaystackLoading,
        handleInitializePaymentError,
        callInitializePayment,
        verifyLoading,
        setVerifyLoading,
        handleShowPaystackModal,
        onPaymentSuccess,
        hasPaidOutrightly,
        handleSaveInfo,
        handleSubmitInfo,
        isAdminUser,
        selectedPackage,
        showAddPackageDrawer,
        toggleAddPackageDrawer,
        handleUpdatePackages,
        showActionModal,
        setShowActionModal,
        changePackage,
        handleChangePackageSuccess,
    }
}

export default useOutrightSaleInformation
