import { format } from 'date-fns'
import { useMutation } from 'react-query'
import {
    repaymentMethodSetupApi,
    uploadPaymentPlanUpfrontDepositApi,
    uploadProviderPaymentEvidenceApi,
    uploadSignedSunFiAgreementApi,
} from 'src/api/admin/payment-plans/ssePlan'
import { uploadOurightSalePaymentEvidenceApi } from 'src/api/admin/outright-sale'

const useSubmitToDo = (estimationId, onSuccess, onError, plan) => {
    const apiMap = {
        SUNFI_AGREEMENT: uploadSignedSunFiAgreementApi,
        UPFRONT_DEPOSIT: uploadPaymentPlanUpfrontDepositApi,
        REPAYMENT_METHOD_SETUP: repaymentMethodSetupApi,
        PROVIDER_PAYMENT: uploadProviderPaymentEvidenceApi,
        MARK_A_PAYMENT: uploadOurightSalePaymentEvidenceApi,
    }

    const successMessageMap = {
        SUNFI_AGREEMENT: 'Agreement Updated Successfully',
        UPFRONT_DEPOSIT: 'Deposit Recorded Successfully',
        REPAYMENT_METHOD_SETUP: 'Repayment Method Setup Successfully',
        PROVIDER_PAYMENT: 'Provider Payment Recorded Successfully',
        MARK_A_PAYMENT: 'Payment evidence submitted',
    }

    const mutationFn = ({ s3Url, inputs, actionKeyID }) => {
        return apiMap[actionKeyID](estimationId, {
            action: actionKeyID,
            ...(actionKeyID === 'SUNFI_AGREEMENT' && {
                agreement_signed_at: inputs.dateTime,
                signed_agreement_file_url: s3Url,
            }),
            ...(actionKeyID === 'UPFRONT_DEPOSIT' && {
                upfront_deposit_paid_at: inputs.dateTime,
                upfront_deposit_evidence_file_url: s3Url,
                total_amount_paid: inputs.upfrontDepositTotalAmount
                    .toString()
                    .replace(/,/g, ''),
            }),
            ...(actionKeyID === 'REPAYMENT_METHOD_SETUP' && {
                repayment_method_setup_created_at: format(
                    new Date(inputs.dateTime),
                    "yyyy-MM-dd'T'hh:mm",
                ),
                repayment_method_setup_type: inputs.repaymentMethodSetupType,
                repayment_method_setup_subscription_code:
                    inputs.repaymentMethodSetupSubscriptionCode,
                repayment_method_setup_file_url: s3Url,
            }),
            ...(actionKeyID === 'PROVIDER_PAYMENT' && {
                provider_received_payment_at: inputs.dateTime,
                evidence_of_provider_payment_file_url: s3Url,
                total_amount_paid: inputs.providerPaymentTotalAmount
                    .toString()
                    .replace(/,/g, ''),
            }),
            ...(actionKeyID === 'MARK_A_PAYMENT' &&
                plan === 'Outright Sale' && {
                    date_repayment_was_made: inputs.dateTime,
                    repayment_evidence_url: s3Url,
                    total_amount_paid: inputs.outrightSaleTotalAmount
                        .toString()
                        .replace(/,/g, ''),
                    repayment_method: inputs.repaymentMethod,
                }),
        })
    }

    const { mutate, isLoading } = useMutation(mutationFn, {})
    const submitToDoMutate = (s3Url, inputs, actionKeyID) => {
        mutate(
            {
                s3Url,
                inputs,
                actionKeyID,
            },
            {
                onSuccess: data => {
                    onSuccess?.(successMessageMap[actionKeyID], data)
                },
                onError: err => {
                    onError?.(err)
                },
            },
        )
    }

    return {
        submitToDoMutate,
        submitToDoLoading: isLoading,
    }
}

export default useSubmitToDo
