export const handleFilterDisplay = filter => {
    const filterText = []
    if (filter?.member_status.length > 0) {
        filterText.push(`${filter.member_status} status`)
    }
    if (filter?.due_payment) filterText.push(`Due Payment`)
    if (filter?.system_size) {
        filterText.push(`${filter.system_size} system size`)
    }
    if (filter?.payment_type) {
        filterText.push(`${filter.payment_type} payment plan`)
    }

    return filterText
}
