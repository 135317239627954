import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { getProfileApi } from 'src/api/admin/profiles/details'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const useGetProfile = () => {
    const [profile, setProfile] = useState({})
    const [energyNeeds, setEnergyNeeds] = useState([
        {
            id: uuidv4(),
            name: '',
            quantity: '',
            backup_hours: '',
            backup_hours_night: '',
            power_rating: '',
            unitOfMeasurement: '',
        },
    ])

    const { id } = useParams()
    const { errorAlert } = useCustomToast()

    const { isFetching } = useQuery({
        queryKey: ['fetch-admin-consumer-profile'],
        queryFn: () => getProfileApi(id),
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        onSuccess: res => {
            const data = res?.data?.data
            setProfile(data)
            setEnergyNeeds(data?.appliances)
        },
        onError: error => {
            errorAlert(
                errorHandler(error.response?.data) ||
                    `Couldn't fetch consumer profile. please try again`,
            )
        },
    })

    return {
        profile,
        profileFetching: isFetching,
        energyNeeds,
        setEnergyNeeds,
    }
}

export default useGetProfile
