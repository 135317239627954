import PropTypes from 'prop-types'
import { useState } from 'react'
import s from './textdropdown.module.scss'
import { ReactComponent as Arrow } from 'src/assets/images/down-arrow-rounded-edges.svg'

const TextDropdown = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className={s.wrapper} data-testid="text-dropdown-wrapper">
            <div
                className={s.header}
                onClick={toggleDropdown}
                data-testid="text-dropdown-header"
            >
                <h2 className={s.title}>{title}</h2>
                <Arrow className={isOpen ? s.iconUp : s.iconDown} />
            </div>
            <div
                className={`${s.content} ${isOpen ? s.open : s.closed}`}
                data-testid="text-dropdown-content"
            >
                <hr />
                {content}
            </div>
        </div>
    )
}
TextDropdown.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
}

export default TextDropdown
