import { decodeUserInfo } from '../utils/auth'

const permissionFramework = {
    admin: {
        approved_requests: {
            'signed_agreement:can_view': ['growth', 'finance', 'user_service'],
            'signed_agreement:can_upload': ['growth'],
            'upfront_deposit:can_view': ['growth', 'finance', 'user_service'],
            'upfront_deposit:can_upload': ['finance'],
            'upfront_deposit:can_request': ['finance', 'growth'],
            'repayment_method_setup:can_view': [
                'growth',
                'finance',
                'user_service',
            ],
            'repayment_method_setup:can_upload': ['finance'],
            'repayment_method_setup:can_request': ['finance', 'growth'],
            'repayment_schedule:can_view': [
                'growth',
                'finance',
                'user_service',
            ],
            'repayment_schedule:can_upload': ['finance'],
            'provider_payment:can_view': ['growth', 'finance', 'user_service'],
            'provider_payment:can_upload': ['finance'],
            'solution_installed:can_view': [
                'growth',
                'finance',
                'user_service',
            ],
            'solution_installed:can_upload': ['user_service', 'growth'],
            'bank_statement:can_view': ['finance'],
            'bank_statement:can_download': ['finance'],
            'repayment_schedule_monthly_payment:can_record': ['finance'],
            'repayment_schedule_monthly_payment:can_view': [
                'finance',
                'growth',
                'user_service',
            ],
            'plan_tenure:can_update': ['finance'],
            'payment_plan:can_close': ['finance', 'growth'],
        },
        awaiting_approval_requests: {
            'bank_statement:can_view': ['finance', 'growth'],
            'bank_statement:can_download': ['finance'],
            'request:can_approve': ['finance'],
            'request:can_decline': ['finance'],
            'request:can_re_estimate': ['finance', 'growth'],
            'request:can_request_pii_resubmission': ['finance', 'growth'],
            'request:can_request_statement_resubmission': ['finance', 'growth'],
            'request:can_close': ['finance', 'growth'],
            'request:can_view_pii_link': ['growth'],
        },
        inprogress_requests: {
            'bank_statement:can_view': ['finance', 'growth'],
            'bank_statement:can_download': ['finance'],
            'request:can_close': ['growth', 'user_service'],
            'record_payment:can_view': ['growth', 'finance', 'user_service'],
            'record_payment:can_upload': ['finance'],
        },
        declined_requests: {
            'bank_statement:can_view': ['finance'],
            'bank_statement:can_download': ['finance'],
        },
        dtc_reviews: {
            'request:can_assign_as_reviewer': ['user_service'],
        },
        product_library: {
            'package:can_create': ['user_service'],
            'package:can_edit': ['user_service'],
            'package:can_delete': ['user_service'],
            'package:can_duplicate': ['user_service'],
            'component:can_create': ['user_service'],
            'component:can_edit': ['user_service'],
            'component:can_delete': ['user_service'],
        },
        credit_variable: {
            'credit_variable:can_create': ['finance', 'user_service'],
            'credit_variable:can_update': ['finance'],
            'credit_variable:can_delete': ['finance'],
            'credit_variable:can_simulate_plan': [
                'finance',
                'user_service',
                'growth',
            ],
        },
        approved_providers: {
            'providers:can_add_user': ['growth', 'user_service'],
            'providers:can_assign_to_resource': ['growth', 'user_service'],
            'providers:can_deactivate': ['growth', 'user_service'],
            'providers:can_edit': ['growth', 'user_service'],
            'providers:can_resend': ['growth', 'user_service'],
            'providers:can_revoke': ['growth', 'user_service'],
        },
        unapproved_providers: {
            'providers:can_approve': ['growth', 'user_service'],
            'providers:can_change_type': ['growth', 'user_service'],
            'providers:can_decline': ['growth', 'user_service'],
            'providers:can_edit': ['growth', 'user_service'],
        },
        suspended_providers: {
            'providers:can_activate': ['growth', 'user_service'],
            'providers:can_edit': ['growth', 'user_service'],
        },
        manual_verification: {
            can_review: ['finance'],
        },
        dispute_credit_decision: {
            can_dispute: ['finance'],
        },
    },
}
export const denyPermission = (workspace, module, resourcePermissionDef) => {
    const userInfo = decodeUserInfo()
    // If a Provider comes down here while they are on the product library, kindly return false
    if (
        userInfo.isAdminWorkspaceUser === 'false' &&
        module === 'product_library'
    ) {
        return false
    }

    if (userInfo.isDeveloperUser === 'true') return false

    if (userInfo.isSystemAdmin === 'true') return false

    const user_type =
        userInfo.isGrowthUser === 'true'
            ? 'growth'
            : userInfo.isFinanceUser === 'true'
            ? 'finance'
            : userInfo.isUserService === 'true'
            ? 'user_service'
            : ''
    // If the user type is not found, deny the user
    if (user_type === '') return true

    const resource =
        permissionFramework?.[workspace]?.[module]?.[resourcePermissionDef]
    // If the resource specified is not found, deny the user to perform the action
    if (resource == null) return true
    return !resource.includes(user_type)
}

export const denyPermissionToolTipText =
    'You are not authorised to perform this action'
