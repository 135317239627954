import PropTypes from 'prop-types'
import styles from './card.module.scss'
import { Dropdown } from 'antd'
import Button, { ConsumerButton } from '../Button'
import { ReactComponent as CircleDown } from 'src/assets/images/circle-down-no-stroke.svg'
const Card = ({
    topLeftHeading,
    topLeftContent,
    bottomLeftHeading,
    bottomLeftContent,
    bottomRightHeading,
    bottomRightContent,
    bottomRightElement,
    btnText,
    handleClick,
    topLeftContentSize,
    btnDisabled,
    cardStyleProps,
    includeFlag,
    flagText,
    flagStyleProps,
    buttonType = 'button',
    dropdownMenu,
    toolTipText,
    arrowPositionCenter,
    toolTipFontSize,
    toolTipPadding,
    toolTipPopupWidth,
    toolTipMarginLeft,
    showTooltip,
    btnDataTestId,
}) => {
    const { card, top, bottom, text, paragraphAndFlag } = styles
    return (
        <div className={card} style={cardStyleProps}>
            <div className={top}>
                <div className={text}>
                    <h4>{topLeftHeading}</h4>
                    {includeFlag ? (
                        <div className={paragraphAndFlag}>
                            <p style={{ fontSize: topLeftContentSize }}>
                                {topLeftContent}
                            </p>
                            <span style={flagStyleProps}>{flagText}</span>
                        </div>
                    ) : (
                        <p style={{ fontSize: topLeftContentSize }}>
                            {topLeftContent}
                        </p>
                    )}
                </div>
                {buttonType === 'dropdown' ? (
                    <Dropdown trigger={['click']} overlay={dropdownMenu}>
                        <a onClick={e => e.preventDefault()}>
                            <ConsumerButton
                                btnWidth={'81px'}
                                btnHeight={'44px'}
                                btnBgColor={'#F9FCFF'}
                                btnTextColor={'#004AAD'}
                                hoverNoFill
                                type={'iconBtn'}
                            >
                                {btnText ?? 'View'}
                                <CircleDown />
                            </ConsumerButton>
                        </a>
                    </Dropdown>
                ) : (
                    <Button
                        btnBgColor="#E2EEFF"
                        btnTextColor="#004AAD"
                        btnWidth="70px"
                        btnHeight="37px"
                        fontSize="12px"
                        handleClick={handleClick}
                        disabled={btnDisabled}
                        toolTipText={toolTipText}
                        arrowPositionCenter={arrowPositionCenter}
                        toolTipFontSize={toolTipFontSize}
                        toolTipPadding={toolTipPadding}
                        toolTipPopupWidth={toolTipPopupWidth}
                        toolTipMarginLeft={toolTipMarginLeft}
                        showTooltip={showTooltip}
                        dataTestId={btnDataTestId}
                    >
                        {btnText ?? 'View'}
                    </Button>
                )}
            </div>
            <div className={bottom}>
                <div className={text}>
                    <h4>{bottomLeftHeading}</h4>
                    <p>{bottomLeftContent}</p>
                </div>
                <div className={text} style={{ textAlign: 'right' }}>
                    <h4>{bottomRightHeading}</h4>
                    {bottomRightElement ? (
                        bottomRightElement
                    ) : (
                        <p>{bottomRightContent}</p>
                    )}
                </div>
            </div>
        </div>
    )
}

Card.propTypes = {
    topLeftHeading: PropTypes.string,
    topLeftContent: PropTypes.string,
    bottomLeftHeading: PropTypes.string,
    bottomLeftContent: PropTypes.string,
    bottomRightHeading: PropTypes.string,
    bottomRightContent: PropTypes.string,
    bottomRightElement: PropTypes.node,
    btnText: PropTypes.string,
    handleClick: PropTypes.func,
    topLeftContentSize: PropTypes.number,
    btnDisabled: PropTypes.bool,
    cardStyleProps: PropTypes.object,
    includeFlag: PropTypes.bool,
    flagText: PropTypes.string,
    flagStyleProps: PropTypes.object,
    buttonType: PropTypes.oneOf(['dropdown', 'button']),
    dropdownMenu: PropTypes.node,
    toolTipText: PropTypes.string,
    arrowPositionCenter: PropTypes.bool,
    toolTipFontSize: PropTypes.string,
    toolTipPadding: PropTypes.string,
    toolTipPopupWidth: PropTypes.string,
    toolTipMarginLeft: PropTypes.string,
    showTooltip: PropTypes.string,
    btnDataTestId: PropTypes.string,
}
export default Card
