import PropTypes from 'prop-types'
import { applianceIcon } from 'src/pages/Consumer/ProfileListings/data'

const ApplianceCombination = ({ appliance_combinations }) => {
    return (
        <div className="appliance_combination">
            {appliance_combinations?.combination?.map((item, i) => (
                <img key={i} src={applianceIcon(item.name)} />
            ))}
        </div>
    )
}

ApplianceCombination.propTypes = {
    appliance_combinations: PropTypes.array,
}

export default ApplianceCombination
