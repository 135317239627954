import axios from '../../config/axios'

export const getRepaymentScheduleStatsApi = async token => {
    const request = await axios.get(
        '/estimations/mini/repayment-schedule-stats',
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    )
    return request
}

export const getRepaymentScheduleStatsByIdApi = async id => {
    const request = await axios.get(
        `/estimations/mini/${id}/repayment-schedule-stats`,
    )
    return request
}

export const getRepaymentScheduleApi = async (token, pageNumber) => {
    const request = await axios.get(
        `/estimations/mini/repayment-schedule?current_page=${pageNumber}`,
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    )
    return request
}

export const getRepaymentScheduleByIdApi = async (id, pageNumber) => {
    const request = await axios.get(
        `/estimations/mini/${id}/repayment-schedule?current_page=${pageNumber}`,
    )
    return request
}

export const getEstimationIdApi = async id => {
    const request = await axios.get(`/estimations/${id}`)
    return request
}

export const getConsumerLatestOffer = async () => {
    const request = await axios.get('/consumers/offer/approved/retrieve')
    return request
}
