import PropTypes from 'prop-types'
import { useState, useEffect, useCallback } from 'react'
import { Drawer } from 'antd'
import { InputFields, SelectField } from 'src/components/InputFields'
import { IconInputField } from 'src/components/InputFields/CostField'
import circleLeftBlue from 'src/assets/images/circle-left-pri-blue.svg'
import ConsumerEnergyNeedsSummary from 'src/components/ConsumerLandingPageBody/ConsumerEnergyNeedsSummary'
import ConsumerModal from 'src/components/Modal/ConsumerModal'
import UpdateNeeds from 'src/components/UpdateNeeds'
import { ConsumerButton } from 'src/components/Button'
import { stateData } from 'src/utils/stateData'
import {
    paymentPlansData,
    reasonForSolarData,
    paymentPlansMapReverse,
    reasonForSolarMapReverse,
    reasonForSolarMap,
    generatorSizeData,
    generatorMaintenanceData,
    paymentPlansMap,
    PAYMENT_TYPES,
    electricityPerDayData,
    electricityPerDayDataMap,
} from 'src/components/ConsumerLandingPageBody/data'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import { isMobile } from 'src/utils/mediaQueries'
import {
    EditApplianceValidationSchemaSSE,
    additionalFieldsValidator,
    manualRecoValidationSchema,
} from 'src/utils/validationSchema'
import { useAppContext } from 'src/appContext/AppContext'
import useGetRecommendations from 'src/components/ConsumerLandingPageBody/hooks/useGetRecommendations'
import { InlineLoader } from 'src/components/Loader'
import Toast from 'src/components/Toast'
import { ReactComponent as GoBackIcon } from 'src/assets/images/blue-circle-left.svg'
import { encrypt } from 'src/utils/cryptography'
import {
    floatWithCommas,
    formatPhoneNumber,
    numberWithCommas,
} from 'src/utils/formatting'
import { decrypt } from 'src/utils/cryptography'
import { modalType, regex, handleAppTracking } from './utils'
import Editor from 'src/components/Editor'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import AdditionalInfoInputModalContent from './AdditionalInfoInputModalContent'
import { useMutation } from 'react-query'
import { requestManualRecommendation } from 'src/api/shoppingExperience/landingPage'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import ActionModalV2 from 'src/components/ActionModalV2'
import { useHistory } from 'react-router-dom'

const EditApplianceInput = ({
    showEditInputDrawer,
    hideDrawer,
    energyNeeds,
    energyInputs,
    action,
    setEditedPackages,
    setPrevEnergyNeeds,
    auth_user_id,
    knowGenMonthlyCost,
    editModalType,
    manualRecommCategory,
    editAppTrackingCallback,
}) => {
    const history = useHistory()
    const { errorAlert } = useCustomToast()
    const page = 'edit'
    const isManualReco = editModalType === modalType.manual

    const {
        validateRequestInfoMutation,
        validateRequestInfoAuthUserMutation,
        loading,
        setLoading,
        errorState,
    } = useGetRecommendations(
        hideDrawer,
        setEditedPackages,
        page,
        auth_user_id,
        energyInputs?.estimation_id,
    )

    const { formResponse } = useAppContext()
    const inputData =
        action === 'update_from_account_setup' ||
        action === 'update_listings_as_auth_user'
            ? energyInputs
            : formResponse?.data

    const [showApplianceModal, setShowApplianceModal] = useState(false)
    const [energyInputNeeds, setEnergyNeeds] = useState([])
    const [inputs, setInputs] = useState({})
    const [userId] = useState(inputData?.user_id)

    const [canMountSolar, setCanMountSolar] = useState(
        inputData?.can_mount_solar ? 'true' : 'false',
    )
    const [errors, setErrors] = useState({})
    const [showNext, setShowNext] = useState(false)
    const [paymentModel, setPaymentModel] = useState(PAYMENT_TYPES.NULL)
    const [knowsGenMonthlyCost, setKnowsGenMonthlyCost] =
        useState(knowGenMonthlyCost)

    const [generatorUse, setGeneratorUse] = useState(
        knowGenMonthlyCost === 'yes' ? 'yes' : 'no',
    )
    const [editorNode, setEditorNode] = useState(null)
    const [contactType, setContactType] = useState('')
    const [additionalInfoModal, setAdditionalInfoModal] = useState(false)
    const [additionalInputs, setAdditionalInputs] = useState({
        email: inputData?.user_id?.includes('@') ? inputData.user_id : '',
        firstName: '',
        lastName: '',
        phone: !inputData?.user_id?.includes('@')
            ? inputData?.user_id?.slice(4)
            : '',
    })

    const [additionalFieldErrors, setAdditionalFieldErrors] = useState({})
    const [notesVal, setNotesValue] = useState('')
    const [savedPayload, setSavedPayload] = useState(null)
    const [showConfirmation, setShowConfirmation] = useState(false)

    const editorRef = useCallback(node => {
        setEditorNode(node)
    }, [])

    const editor = editorNode?.getEditor()
    const unprivilegedEditor = editorNode?.makeUnprivilegedEditor(editor)
    const editorValue = unprivilegedEditor?.getText().trim()

    const handleEditorChange = value => {
        setInputs(prev => ({ ...prev, notes: value }))
        setNotesValue(editorValue)
    }

    const deleteErrorOnSelection = key => {
        delete errors[key]
    }
    const updateEnergyNeeds = appliances => {
        setEnergyNeeds(appliances)
    }
    const handleInputChange = event => {
        const { name, value } = event.target

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }))

        //regex to to allow numbers and comma
        const numberRegex = /^[0-9.,]*$/
        if (name === 'monthlyPayment') {
            setInputs(prev => ({
                ...prev,
                monthlyPayment: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.monthlyPayment,
            }))
        } else if (name === 'generatorSpend') {
            setInputs(prev => ({
                ...prev,
                generatorSpend: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.generatorSpend,
            }))
        } else if (name === 'email' || name === 'phone') {
            setInputs(prev => ({ ...prev, [name]: value.replaceAll(' ', '') }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value }))
        }
    }

    const handleSelectChange = (name, value) => {
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }))
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleBlur = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value.trim() }))
    }

    useEffect(() => {
        const gridHours = inputData?.gets_required_grid_hours
        const initialInputs = {
            electricityPerDay: electricityPerDayDataMap[gridHours],
            generatorSpend: inputData?.generator_maintenance_expense,
            location: inputData?.state_of_installation,
            monthlyPayment: inputData?.feasible_monthly_payment,
            paymentPlanDuration:
                paymentPlansMapReverse[
                    inputData?.feasible_repayment_tenure
                ]?.[0],
            reasonForSolar:
                reasonForSolarMapReverse[inputData?.solution_reason]?.[0],
            ...(isManualReco && inputData?.user_id?.includes('@')
                ? {
                      email: inputData?.user_id,
                  }
                : {
                      phone: inputData?.user_id?.slice(4),
                  }),
        }
        setInputs(initialInputs)
        setEnergyNeeds(energyNeeds)
        setCanMountSolar(inputData?.can_mount_solar ? 'true' : 'false')
        setPaymentModel(
            inputData?.payment_model === 'Subscription'
                ? 'Subscription'
                : 'Lease to Own',
        )
    }, [energyNeeds, inputData, isManualReco])

    useEffect(() => {
        if (inputData?.user_id?.includes('@')) {
            setContactType('email')
        } else {
            setContactType('phone')
        }
    }, [inputData?.user_id])

    //summary calculation
    const wattsPerAppliance = energyInputNeeds?.map(
        item => item.powerRating * item.quantity,
    )
    const totalWattsAdded = wattsPerAppliance?.reduce((a, b) => a + b, 0)

    const dayUsage = energyInputNeeds?.map(
        item => item.powerRating * item.backup_hours * item.quantity,
    )
    const totalDayUsage = dayUsage?.reduce((a, b) => a + b, 0)
    const nightUsage = energyInputNeeds?.map(
        item => item.powerRating * item.backup_hours_night * item.quantity,
    )
    const totalNightUsage = nightUsage?.reduce((a, b) => a + b, 0)

    const handleSaveEdit = () => {
        const sanitizedInputs = {
            ...inputs,
            monthlyPayment:
                typeof inputs.monthlyPayment === 'string'
                    ? inputs.monthlyPayment.replace(',', '')
                    : inputs.monthlyPayment,
            generatorSpend:
                typeof inputs.generatorSpend === 'string'
                    ? inputs.generatorSpend.replace(',', '')
                    : inputs.generatorSpend,
        }
        EditApplianceValidationSchemaSSE.validate(
            { ...sanitizedInputs, canMountSolar, paymentModel },
            { abortEarly: false },
        )
            .then(() => {
                setErrors({})
                getRecommendations()
                editAppTrackingCallback?.()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const getRecommendations = (manualRecomm = false) => {
        setLoading(true)
        action !== 'update_from_account_setup' &&
            setPrevEnergyNeeds(energyNeeds)

        const reshapedEnergyNeeds = energyInputNeeds.map(item => {
            return {
                name: item.name,
                quantity: item.quantity,
                backup_hours: item.backup_hours,
                backup_hours_night: item.backup_hours_night,
                power_rating: item.powerRating,
                unit_of_measurement: item.unitOfMeasurement,
            }
        })

        let generatorSpend = 0
        if (typeof inputs.generatorSpend === 'string') {
            const numericString = inputs.generatorSpend.replaceAll(',', '')
            generatorSpend =
                numericString === '' ? 0 : parseFloat(numericString)
        } else if (typeof inputs.generatorSpend === 'number') {
            generatorSpend = inputs.generatorSpend
        }

        const payload = {
            can_mount_solar: canMountSolar,
            gets_required_grid_hours: parseInt(
                inputs.electricityPerDay.replace(/hours?/i, ''),
            ),
            feasible_repayment_tenure:
                paymentPlansMap[inputs.paymentPlanDuration],
            feasible_monthly_payment:
                typeof inputs.monthlyPayment === 'string'
                    ? inputs.monthlyPayment.replaceAll(',', '')
                    : inputs.monthlyPayment,
            generator_maintenance_expense: generatorSpend,
            state_of_installation: inputs.location,
            user_id: userId,
            solution_reason: reasonForSolarMap[inputs.reasonForSolar],
            appliances: reshapedEnergyNeeds,
            payment_model: paymentModel,
        }
        if (manualRecomm) {
            setLoading(false)
            return payload
        }
        if (
            action === 'update_from_account_setup' ||
            action === 'update_listings_as_auth_user'
        ) {
            validateRequestInfoAuthUserMutation.mutate({
                id: energyInputs?.estimation_id,
                payload: payload,
            })
        } else validateRequestInfoMutation.mutate(payload)

        const decryptedData =
            localStorage?.getItem('sunfiUserSSEGettingStartedInfo') &&
            JSON.parse(
                decrypt(
                    localStorage?.getItem('sunfiUserSSEGettingStartedInfo'),
                ),
            )

        decryptedData.knowsGenMonthlyCost = knowsGenMonthlyCost
        localStorage.setItem(
            'sunfiUserSSEGettingStartedInfo',
            encrypt(JSON.stringify(decryptedData)),
        )
    }

    const {
        mutate: sendManualRecommMutation,
        isLoading: customRecommIsLoading,
    } = useMutation({
        mutationFn: payload => requestManualRecommendation(payload),
        onSuccess: () => {
            setShowConfirmation(true)
            setAdditionalInfoModal(false)
            handleAppTracking('wantsAltRecommendation')
        },
        onError: error => {
            errorAlert(errorHandler(error?.response?.data))
            setAdditionalInfoModal(false)
        },
    })

    //todo - refactor the logic to show when a new appliance is added
    const handleShowNext = () => {
        setShowNext(true)
    }

    const handleShowNextManual = () => {
        if (errors[contactType]) return

        if (!inputs[contactType]) {
            return setErrors(prevErrors => ({
                ...prevErrors,
                [contactType]: `${contactType} is required`,
            }))
        }

        setShowNext(true)
    }

    const handleManualRecommendation = () => {
        let sanitizedInputs = {
            generatorUse,
            knowsGenMonthlyCost,
            monthlyPayment:
                typeof inputs.monthlyPayment === 'string'
                    ? inputs.monthlyPayment.replace(',', '')
                    : inputs.monthlyPayment,
            // Todo: generator spend to be taken out and the one below used
            generatorSpend:
                typeof inputs.generatorSpend === 'string'
                    ? inputs.generatorSpend.replace(',', '')
                    : inputs.generatorSpend,
        }

        // Todo: this will be changed to 'yes' when backend allows generator size and main
        if (generatorUse === 'to be changed to yes') {
            if (knowsGenMonthlyCost === 'yes') {
                sanitizedInputs = {
                    ...sanitizedInputs,
                    generatorSpend:
                        typeof inputs.generatorSpend === 'string'
                            ? inputs.generatorSpend.replace(',', '')
                            : inputs.generatorSpend,
                }
            } else if (knowsGenMonthlyCost === 'no') {
                sanitizedInputs.generatorSize = inputs?.generatorSize || ''
                sanitizedInputs.generatorMaintenance =
                    inputs?.generatorMaintenance || ''
            }
        }

        manualRecoValidationSchema
            .validate(sanitizedInputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                let payload = getRecommendations(true)
                payload.category = manualRecommCategory
                if (notesVal) payload.notes = notesVal

                setSavedPayload(payload)
                setAdditionalInfoModal(true)
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const handleEmailModal = () => {
        additionalFieldsValidator
            .validate(additionalInputs, {
                abortEarly: false,
            })
            .then(() => {
                const payload = {
                    ...savedPayload,
                    user_id:
                        contactType === 'phone'
                            ? formatPhoneNumber(inputs?.phone)
                            : inputs.email,
                    custom_email: additionalInputs.email,
                    first_name: additionalInputs.firstName,
                    last_name: additionalInputs.lastName,
                    ...(additionalInputs.phone
                        ? {
                              custom_phone_number: formatPhoneNumber(
                                  additionalInputs.phone,
                              ),
                          }
                        : {}),
                }

                sendManualRecommMutation(payload)
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setAdditionalFieldErrors(errList)
            })
    }

    const handleInputBlur = e => {
        const { name, value } = e.target

        if (!value) {
            return setErrors(prevErrors => ({
                ...prevErrors,
                [name]: `${name.includes('mail') ? 'email' : name} is required`,
            }))
        }

        if (!regex[name].test(value)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: `Provide a valid ${
                    name.includes('mail') ? 'email' : name
                }`,
            }))
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: '',
            }))
        }
    }

    return (
        <>
            <ActionModalV2
                modalWidth="610px"
                onCancel={() => {
                    setAdditionalInfoModal(false)
                    hideDrawer()
                }}
                actionModalOpen={showConfirmation}
                handlePrimaryBtnClick={() => {
                    setAdditionalInfoModal(false)
                    hideDrawer()
                    history.push('/')
                }}
                title="Information Confirmed"
                primaryBtnText="Done"
                headerText="Your information has been submitted"
                subTitle="You have successfully submitted your details and you'll be sent an email once your recommendation is ready. Please be on the lookout"
                actionType={'success'}
                v2
                showFooter
            />
            <ConsumerModal
                showModal={showApplianceModal}
                modalWidth="649px"
                modalHeight="687px"
                closable={false}
                content={
                    <UpdateNeeds
                        closeModal={() => setShowApplianceModal(false)}
                        updateEnergyNeeds={updateEnergyNeeds}
                        page="consumer-SSE"
                        selectedAppliances={energyInputNeeds}
                        energyNeeds={energyNeeds}
                    />
                }
            />
            <SecondaryModal
                showModal={additionalInfoModal}
                onCancel={() => setAdditionalInfoModal(false)}
                modalWidth={'475px'}
                title={'Request Alternative Recommendation'}
                showFooter
                footerLeftContent={null}
                content={
                    <AdditionalInfoInputModalContent
                        additionalInputs={additionalInputs}
                        setAdditionalInputs={setAdditionalInputs}
                        additionalFieldErrors={additionalFieldErrors}
                        setAdditionalFieldErrors={setAdditionalFieldErrors}
                        handleInputBlur={handleInputBlur}
                    />
                }
                v2
                primaryBtnText={'Confirm'}
                primaryBtnLoading={customRecommIsLoading}
                primaryBtnDisabled={
                    !Object.values(additionalFieldErrors).every(
                        error => !error.trim(),
                    )
                }
                handlePrimaryBtnClick={handleEmailModal}
                primaryBtnWidth={isMobile ? '116px' : '160px'}
                primaryBtnHeight={isMobile ? '45px' : '53px'}
            />
            <Drawer
                visible={showEditInputDrawer}
                closable={false}
                width="450px"
                onClose={hideDrawer}
                className="RecommendedSolutionDrawer"
            >
                <div
                    className="ViewSystemTopButtons"
                    style={{ marginLeft: isMobile ? 0 : '14px' }}
                >
                    <div onClick={hideDrawer}>
                        <img
                            src={circleLeftBlue}
                            alt="circle-left-blue"
                            role={'presentation'}
                        />
                        <span>Go back</span>
                    </div>
                </div>

                <section className="SSEEditApplianceWrapper">
                    {errorState && (
                        <Toast
                            messageType="error-secondary"
                            message={errorState}
                            closable={true}
                        />
                    )}
                    <h3>
                        {!isManualReco
                            ? 'Edit your previously added information'
                            : 'Confirm your previously added information'}
                    </h3>
                    <p className="SSEEditApplianceSubHeading">
                        {!isManualReco
                            ? `Please, be aware that editing your previously added info
                            might affect your recommendations.`
                            : 'Please, confirm your previously added info for a more accurate recommendation from our team'}
                    </p>

                    <ConsumerEnergyNeedsSummary
                        totalDayUsage={totalDayUsage}
                        totalWattsAdded={totalWattsAdded}
                        totalNightUsage={totalNightUsage}
                        energyNeeds={energyInputNeeds}
                        setShowApplianceModal={setShowApplianceModal}
                        page="SSEEditAppliance"
                    />
                    <div className="ConsumerBackButtonStyle">
                        {showNext && (
                            <div
                                className="ConsumerBackButtonWrapper"
                                onClick={() => setShowNext(false)}
                            >
                                <GoBackIcon />
                                <span className="ConsumerBackButtonSpan">
                                    Go back
                                </span>
                            </div>
                        )}
                        <hr
                            style={{
                                background: showNext
                                    ? 'linear-gradient(to right, #004AAD 50%, #F9FCFF 50%)'
                                    : '#F9FCFF',
                            }}
                        />
                    </div>
                    {showNext ? (
                        <form className="ConsumerNextFormWrapper">
                            <p className="FormPStyle">
                                Do you want to own or rent a solar inverter?
                            </p>
                            <div className="AnswerStyleWrapper">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setPaymentModel(PAYMENT_TYPES.OWN)
                                        deleteErrorOnSelection(
                                            'paymentModelErr',
                                        )
                                    }}
                                    className={
                                        paymentModel === PAYMENT_TYPES.OWN &&
                                        'AnswerStyleWrapper__active'
                                    }
                                >
                                    Own
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setPaymentModel(PAYMENT_TYPES.RENT)
                                        deleteErrorOnSelection(
                                            'paymentModelErr',
                                        )
                                    }}
                                    className={
                                        paymentModel === PAYMENT_TYPES.RENT &&
                                        'AnswerStyleWrapper__active'
                                    }
                                >
                                    Rent
                                </button>
                            </div>
                            <p className="AnswerStyleWrapperError">
                                {errors['paymentModelErr']}
                            </p>

                            <IconInputField
                                icon={NairaIcon}
                                name="monthlyPayment"
                                value={
                                    inputs?.monthlyPayment !== undefined
                                        ? numberWithCommas(
                                              inputs?.monthlyPayment,
                                          )
                                        : 0
                                }
                                handleChange={handleInputChange}
                                title={`How much do you want to pay monthly to ${
                                    paymentModel === PAYMENT_TYPES.RENT
                                        ? 'rent'
                                        : 'own'
                                }?`}
                                onBlur={handleBlur}
                                errorMessage={errors['monthlyPayment']}
                            />
                            {generatorUse === 'yes' ? (
                                knowsGenMonthlyCost === 'yes' ? (
                                    <IconInputField
                                        icon={NairaIcon}
                                        name="generatorSpend"
                                        title="What is your monthly spend on generators?"
                                        value={
                                            inputs?.generatorSpend !== undefined
                                                ? numberWithCommas(
                                                      inputs?.generatorSpend,
                                                  )
                                                : 0
                                        }
                                        handleChange={handleInputChange}
                                        onBlur={handleBlur}
                                        errorMessage={errors['generatorSpend']}
                                    />
                                ) : (
                                    <>
                                        <SelectField
                                            values={generatorSizeData.map(
                                                option => ({
                                                    value: option,
                                                }),
                                            )}
                                            selectWidth="338px"
                                            name="generatorSize"
                                            initialOption="Whats the size of your generator?"
                                            handleChange={handleSelectChange}
                                            marginBottom="20px"
                                            dropdownPositionRelative
                                            errorMessage={
                                                errors['generatorSize']
                                            }
                                            floatingLabel={
                                                !inputs?.generatorSize ||
                                                inputs?.generatorSize === ''
                                                    ? ''
                                                    : 'Whats the size of your generator?'
                                            }
                                            currentSelected={
                                                inputs.generatorSize
                                            }
                                            withCheckBox
                                        />
                                        <SelectField
                                            values={generatorMaintenanceData.map(
                                                option => ({
                                                    value: option,
                                                }),
                                            )}
                                            selectWidth="338px"
                                            name="generatorMaintenance"
                                            initialOption="How often do you maintain your generator?"
                                            handleChange={handleSelectChange}
                                            marginBottom="20px"
                                            dropdownPositionRelative
                                            errorMessage={
                                                errors['generatorMaintenance']
                                            }
                                            floatingLabel={
                                                !inputs?.generatorMaintenance ||
                                                inputs?.generatorMaintenance ===
                                                    ''
                                                    ? ''
                                                    : 'How often do you maintain your generator?'
                                            }
                                            currentSelected={
                                                inputs.generatorMaintenance
                                            }
                                            withCheckBox
                                        />
                                    </>
                                )
                            ) : null}
                            <SelectField
                                values={electricityPerDayData.map(option => ({
                                    value: option,
                                }))}
                                selectWidth="338px"
                                name="electricityPerDay"
                                initialOption={
                                    inputs.electricityPerDay ||
                                    'How much of electricity do you get in a day?'
                                }
                                handleChange={handleSelectChange}
                                marginBottom="15px"
                                dropdownPositionRelative
                                errorMessage={errors['electricityPerDay']}
                                floatingLabel={
                                    inputs.electricityPerDay === ''
                                        ? ''
                                        : 'How much of electricity do you get in a day?'
                                }
                                currentSelected={inputs.electricityPerDay}
                                selectedValue={inputs.electricityPerDay}
                                withCheckBox
                                showSearch
                            />
                            <p className="FormPStyle">
                                Can you authorize solar installation on your
                                roof?
                            </p>
                            <div className="AnswerStyleWrapper">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setCanMountSolar(true)
                                        deleteErrorOnSelection(
                                            'canMountSolarErr',
                                        )
                                    }}
                                    className={
                                        canMountSolar &&
                                        'AnswerStyleWrapper__active'
                                    }
                                >
                                    Yes, I can
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setCanMountSolar(false)
                                        deleteErrorOnSelection(
                                            'canMountSolarErr',
                                        )
                                    }}
                                    className={
                                        canMountSolar === false &&
                                        'AnswerStyleWrapper__active'
                                    }
                                >
                                    No, I can’t
                                </button>
                            </div>
                            <p className="AnswerStyleWrapperError">
                                {errors['canMountSolarErr']}
                            </p>

                            <div>
                                {errorState && (
                                    <Toast
                                        messageType="error-secondary"
                                        message={errorState}
                                        closable={true}
                                    />
                                )}
                                <ConsumerButton
                                    btnWidth={isMobile ? '100%' : '334px'}
                                    btnHeight="53px"
                                    btnBgColor="#004AAD"
                                    btnTextColor="#FFFFFF"
                                    secondaryHover
                                    handleClick={() => {
                                        if (!isManualReco) {
                                            handleSaveEdit()
                                        } else {
                                            handleManualRecommendation()
                                        }
                                    }}
                                >
                                    {loading ? (
                                        <InlineLoader />
                                    ) : !isManualReco ? (
                                        'Save my new edit'
                                    ) : (
                                        'Submit for review'
                                    )}
                                </ConsumerButton>
                            </div>
                        </form>
                    ) : (
                        <div className="TabStyle">
                            <form>
                                {isManualReco ? (
                                    contactType === 'email' ? (
                                        <InputFields
                                            name="email"
                                            value={inputs.email}
                                            handleChange={handleInputChange}
                                            paddingBottom="18px"
                                            title="What's your email?"
                                            errorMessage={errors['email']}
                                            onBlur={handleInputBlur}
                                        />
                                    ) : (
                                        <SelectCountryCode
                                            handleChange={handleInputChange}
                                            handleSelectChange={
                                                handleSelectChange
                                            }
                                            title="Phone Number"
                                            name="phone"
                                            inputValue={inputs.phone}
                                            errorMessage={errors['phone']}
                                            onBlur={handleInputBlur}
                                            sseUse={true}
                                        />
                                    )
                                ) : null}
                                <SelectField
                                    values={stateData.map(option => ({
                                        value: option,
                                    }))}
                                    selectWidth="338px"
                                    name="location"
                                    initialOption="Select your location"
                                    handleChange={handleSelectChange}
                                    marginBottom="20px"
                                    floatingLabel={
                                        inputs.location === ''
                                            ? ''
                                            : 'Select your location'
                                    }
                                    currentSelected={inputs.location}
                                    selectedValue={inputs.location}
                                    errorMessage={errors.location}
                                    withCheckBox
                                    dropdownPositionRelative
                                    showSearch
                                />
                                <SelectField
                                    values={paymentPlansData.map(option => ({
                                        value: option,
                                    }))}
                                    selectWidth="338px"
                                    name="paymentPlanDuration"
                                    initialOption="How long do you want a payment plan?"
                                    handleChange={handleSelectChange}
                                    marginBottom="20px"
                                    dropdownPositionRelative
                                    floatingLabel={
                                        inputs.paymentPlanDuration === ''
                                            ? ''
                                            : 'How long do you want a payment plan?'
                                    }
                                    errorMessage={errors.paymentPlanDuration}
                                    currentSelected={inputs.paymentPlanDuration}
                                    selectedValue={inputs.paymentPlanDuration}
                                    withCheckBox
                                    showSearch
                                />
                                <div
                                    style={{
                                        marginBottom: '22px',
                                    }}
                                >
                                    <p className="FormPStyle">
                                        Do you know your monthly cost for gen
                                        and maintenance?
                                    </p>
                                    <div
                                        className="AnswerStyleWrapper GenCostQuestionStyle"
                                        style={{ justifyContent: 'flex-start' }}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setKnowsGenMonthlyCost('yes')
                                                setGeneratorUse('yes')
                                                deleteErrorOnSelection(
                                                    'knowsMonthlyGenCostErr',
                                                )
                                            }}
                                            className={
                                                knowsGenMonthlyCost === 'yes' &&
                                                'AnswerStyleWrapper__active'
                                            }
                                            style={{ marginRight: '5px' }}
                                        >
                                            Yes, I do
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setKnowsGenMonthlyCost('no')
                                                setGeneratorUse('no')
                                                deleteErrorOnSelection(
                                                    'knowsMonthlyGenCostErr',
                                                )
                                            }}
                                            className={
                                                knowsGenMonthlyCost === 'no' &&
                                                'AnswerStyleWrapper__active'
                                            }
                                            style={{ marginRight: '5px' }}
                                        >
                                            No, I don&apos;t
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setKnowsGenMonthlyCost(
                                                    'invalid',
                                                )
                                                setGeneratorUse('no')
                                                deleteErrorOnSelection(
                                                    'knowsMonthlyGenCostErr',
                                                )
                                            }}
                                            className={
                                                knowsGenMonthlyCost ===
                                                    'invalid' &&
                                                'AnswerStyleWrapper__active'
                                            }
                                            style={{
                                                width: isMobile ? '105px' : '',
                                            }}
                                        >
                                            {isMobile
                                                ? 'No gen'
                                                : `I don't use gen`}
                                        </button>
                                    </div>
                                    <div className="AnswerStyleWrapperBottomTexts">
                                        <p
                                            className="AnswerStyleWrapperError"
                                            style={{
                                                margin: 0,
                                            }}
                                        >
                                            {errors['knowsMonthlyGenCostErr']}
                                        </p>
                                    </div>
                                </div>
                                <SelectField
                                    values={reasonForSolarData.map(option => ({
                                        value: option,
                                    }))}
                                    selectWidth="338px"
                                    name="reasonForSolar"
                                    initialOption={
                                        isMobile
                                            ? 'Your primary goal to solve with Solar'
                                            : 'What is your primary goal to solve with Solar?'
                                    }
                                    handleChange={handleSelectChange}
                                    marginBottom="20px"
                                    dropdownPositionRelative
                                    floatingLabel={
                                        inputs.reasonForSolar === ''
                                            ? ''
                                            : isMobile
                                            ? 'Your primary goal to solve with Solar'
                                            : 'What is your primary goal to solve with Solar?'
                                    }
                                    errorMessage={errors.reasonForSolar}
                                    currentSelected={inputs.reasonForSolar}
                                    selectedValue={inputs.reasonForSolar}
                                    withCheckBox
                                    showSearch
                                />
                                {isManualReco ? (
                                    <Editor
                                        value={inputs?.notes}
                                        name="notes"
                                        placeholder="Please add any other additional info here"
                                        onChangeHandler={handleEditorChange}
                                        editorWidth="338px"
                                        editorHeight="150px"
                                        errorMessage={errors?.notes}
                                        editorRef={editorRef}
                                        bulletOnly
                                    />
                                ) : null}
                                <div style={{ marginTop: '20px' }}>
                                    <ConsumerButton
                                        btnWidth={isMobile ? '100%' : '334px'}
                                        btnHeight="53px"
                                        btnBgColor="#004AAD"
                                        btnTextColor="#FFFFFF"
                                        handleClick={
                                            !isManualReco
                                                ? handleShowNext
                                                : handleShowNextManual
                                        }
                                        disabled={
                                            !isManualReco
                                                ? false
                                                : errors[contactType]
                                        }
                                    >
                                        Next
                                    </ConsumerButton>
                                </div>
                            </form>
                        </div>
                    )}
                </section>
            </Drawer>
        </>
    )
}

EditApplianceInput.propTypes = {
    showEditInputDrawer: PropTypes.bool,
    hideDrawer: PropTypes.bool,
    energyInputs: PropTypes.object,
    energyNeeds: PropTypes.array,
    action: PropTypes.oneOf(['update_from_account_setup']),
    setEditedPackages: PropTypes.func,
    setPrevEnergyNeeds: PropTypes.func,
    auth_user_id: PropTypes.string,
    knowGenMonthlyCost: PropTypes.string,
    editModalType: PropTypes.string,
    manualRecommCategory: PropTypes.string,
    editAppTrackingCallback: PropTypes.func,
}

export default EditApplianceInput
