import { appTracking } from 'src/utils/appTracker'
import { consumerBuildCustomSystemEvents } from 'src/utils/mp-event-tracker/consumer-build-custom-system'
import {
    emailRegex,
    nameRegex,
    phoneNumberRegex,
} from 'src/utils/validationSchema'

const pageUrlName = window.location.pathname
const pageTitle =
    'Recommended Systems | SunFi | Simplifying and Scaling Clean Energy'
const pageTracker = 'View PCR listing'

export const sortByData = [
    'Price: low to high',
    'Price: high to low',
    'Popularity',
    'All',
]

export const modalType = {
    edit: 'edit',
    manual: 'manual',
}

export const regex = {
    email: emailRegex,
    phone: phoneNumberRegex,
    customEmail: emailRegex,
    name: nameRegex,
}

export const manualRecommReason = {
    noRecommendation: 'Zero Recommendations',
    unsatisfiedRecommendation: 'Unsatisfied with Recommendations',
}

export const handleAppTracking = event => {
    appTracking(
        pageUrlName,
        pageTracker,
        pageTitle,
        consumerBuildCustomSystemEvents[event].action,
        consumerBuildCustomSystemEvents[event].label,
        consumerBuildCustomSystemEvents[event].category,
        ['MP', 'GA'],
        'event',
    )
}
