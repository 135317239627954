import { Skeleton } from 'antd'

const wrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}
const style = { width: '100%', marginBottom: 16, height: 80 }
export const LeftSkeletons = () => {
    return (
        <div style={wrapperStyle} data-testid="aso-left-skeleton">
            <Skeleton.Image active style={{ width: '100%', height: 300 }} />
            {[...Array(3)].map((_, i) => (
                <Skeleton.Input key={i} active style={style} />
            ))}
        </div>
    )
}

export const RightSkeletons = () => {
    return (
        <div style={wrapperStyle} data-testid="aso-right-skeleton">
            {[...Array(7)].map((_, i) => (
                <Skeleton.Input key={i} active style={style} />
            ))}
        </div>
    )
}
