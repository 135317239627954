import { useQuery } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { getAdminClusterOriginationApp } from './api'
import { errorHandler } from 'src/utils/errorHandler'

export const useGetAdminClusterOriginationApp = ({
    enabled = false,
    onSuccess,
    onError,
    id,
}) => {
    const { errorAlert } = useCustomToast()
    const { data, refetch, isFetching } = useQuery(
        ['admin-cluster-origination-app', id],
        () => getAdminClusterOriginationApp(id),
        {
            enabled: enabled,
            retry: false,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: err => {
                if (onError) {
                    onError(err?.response?.data?.errors)
                }
                errorAlert(errorHandler(err?.response?.data))
            },
        },
    )

    return {
        getAdminClusterOriginationAppRefetch: refetch,
        getAdminClusterOriginationAppFetching: isFetching,
        getAdminClusterOriginationAppData: data?.data?.data,
    }
}
