// this hook is called on the kyc forms. It checks if the consumer has completed
// the previous forms. If not, they are redirected to the kyc landing page.

import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { decodeUserInfo } from 'src/utils/auth'
const useKYCFormCompletionGuard = (
    kycStatus = {},
    formPosition,
    customerType,
) => {
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const history = useHistory()
    const businessKeyMap = {
        business_information_completion_status: 1,
        business_verification_completion_status: 2,
        business_financial_information_completion_status: 3,
    }

    const location = useLocation()
    const verificationFailed = location?.state?.verificationFailed ?? null

    const residentialKeyMap = {
        residential_basic_information_completion_status: 1,
        residential_identity_information_completion_status: 2,
        residential_financial_information_completion_status: 3,
    }

    const keyMap =
        customerType === 'BUSINESS' ? businessKeyMap : residentialKeyMap

    function replaceKeys() {
        return Object.keys(kycStatus).reduce((newObj, key) => {
            const newKey = keyMap[key] || key
            newObj[newKey] = kycStatus[key]
            return newObj
        }, {})
    }

    useEffect(() => {
        if (Object.keys(kycStatus).length === 0) {
            return
        }

        if (isAdminUser) {
            return
        }
        const replacedKycStatus = replaceKeys()
        for (let position = 1; position < formPosition; position++) {
            if (!replacedKycStatus[position] && !verificationFailed) {
                history.push('/consumer/account-setup/overview')
                break
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycStatus, formPosition, customerType])
}

export default useKYCFormCompletionGuard
