import { useState, useRef } from 'react'
import { Carousel } from 'antd'
import './promocarousel.scss'
import circleLeftWhite from 'src/assets/images/circle-left-white.svg'
import circleLeftBlue from 'src/assets/images/circle-left-blue.svg'
import { activePromos } from './data'
import { isMobile } from 'src/utils/mediaQueries'
import circleRightWhite from 'src/assets/images/circle-right-white.svg'
import circleRightBlue from 'src/assets/images/circle-right-blue.svg'

const PromoCarousel = () => {
    const [current, setCurrent] = useState(0)
    const carouselRef = useRef()
    const leftArrow = current === 0 ? circleLeftBlue : circleLeftWhite
    const rightArrow =
        current === activePromos.length - 1 ? circleRightBlue : circleRightWhite

    const handlePrevClick = () => {
        carouselRef.current.prev()
    }

    const handleNextClick = () => {
        carouselRef.current.next()
    }
    return (
        <div className={'promoCarouselWrapper'}>
            <div>
                <div className={'promoCarouselBtns'}>
                    <button onClick={handlePrevClick} disabled={current === 0}>
                        <img src={leftArrow} alt={'previous button'} />
                    </button>
                    <button
                        onClick={handleNextClick}
                        disabled={current === activePromos.length - 1}
                    >
                        <img src={rightArrow} alt={'previous button'} />
                    </button>
                </div>
            </div>
            <Carousel
                autoplay
                dotPosition={'top'}
                afterChange={current => setCurrent(current)}
                ref={carouselRef}
                className={`promoCarousel ${
                    current >= 0 && current < activePromos.length - 1
                        ? 'contentRight'
                        : ''
                }`}
                dots={{ className: 'promoCarouselDots' }}
            >
                {activePromos?.map((promo, i) => (
                    <div
                        key={i}
                        onClick={() => (window.location.href = `${promo.link}`)}
                    >
                        <img
                            src={
                                isMobile
                                    ? promo?.mobileImgSrc
                                    : promo?.desktopImgSrc
                            }
                            alt={promo.title}
                            className={'promoCarouselImg'}
                        />
                    </div>
                ))}
            </Carousel>
        </div>
    )
}

export default PromoCarousel
