import BackNav from 'src/components/BackNav'
import PageForm from './components/PageForm'
import DescriptionComponent from 'src/components/DescriptionComponent/DescriptionComponent'
import SeoComponent from 'src/components/Seo'
import { useNewProfile } from './hooks/useNewProfile'
import { useGetProfile } from './hooks/useGetProfile'
import ActionModal from 'src/components/ActionModal'

import './newprofile.scss'

const NewProfile = () => {
    const {
        profileData,
        isEdit,
        isLoading,
        profileId,
        pendingUpdates,
        initialProfileData,
        handleSubmit,
        setProfileData,
        setIsEdit,
        backToProfiles,
        setPendingUpdates,
        showActionModal,
        actionModalHandler,
        closeActionModal,
        backNavTitle,
    } = useNewProfile()

    const { refetchProfile } = useGetProfile({
        setProfileData,
        setIsEdit,
        profileId,
    })

    return (
        <>
            <SeoComponent title="SunFi | Admin | Manage Consumer Profile" />

            <div className="NewProfileContainer">
                <BackNav title={backNavTitle} onClick={backToProfiles} />
                <div className="NewProfileContentWrapper">
                    <div className="NewProfileFormWrapper">
                        <p className="NewProfileTitle">
                            Create new consumer profile
                        </p>
                        <PageForm
                            profileData={profileData}
                            isEdit={isEdit}
                            isLoading={isLoading}
                            profileId={profileId}
                            pendingUpdates={pendingUpdates}
                            initialProfileData={initialProfileData}
                            handleSubmit={handleSubmit}
                            setProfileData={setProfileData}
                            setIsEdit={setIsEdit}
                            setPendingUpdates={setPendingUpdates}
                        />
                    </div>
                    <div className="NewProfileDescriptionWrapper">
                        <DescriptionComponent text="Enter the details of the new consumer profile you want to create. Ensure you choose the correct profile type and system size." />
                    </div>
                </div>
            </div>
            <ActionModal
                actionModalOpen={showActionModal}
                headerText="Consumer profile updated"
                subTitle="You have successfully edited this consumer profile"
                actionType="success"
                noCancelBtn
                noBackLink
                actionText="Done"
                actionHandler={() => {
                    actionModalHandler()
                    refetchProfile()
                }}
                closable
                onCancel={() => closeActionModal()}
                closeModal={() => closeActionModal()}
            />
        </>
    )
}

export default NewProfile
