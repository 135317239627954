import axios from '../../config/axios'
export const initiateSSEConsumerLoginApi = async data => {
    const request = await axios.post('/sse-auth/login/initiate', data)
    return request
}

export const verifySSEConsumerLoginApi = async data => {
    const request = await axios.post('/sse-auth/login/verify', data)
    return request
}
