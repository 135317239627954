/* eslint-disable react/prop-types */

import styles from './paragraph.module.scss'
import parse from 'html-react-parser'

const Paragraph = ({
    title,
    text,
    textChildren,
    textChildrenType,
    count,
    size,
    children,
    textCount,
    titleStyle,
    textStyle,
    textCountStyle,
    titleLineHeight,
    marginBottom,
    textLineHeight,
    fontSize,
}) => {
    const { ParagraphTitle, ParagraphText, ParagraphWrapper } = styles
    return (
        <div className={ParagraphWrapper}>
            {count
                ? title && (
                      <h2
                          className={titleStyle ? titleStyle : ParagraphTitle}
                          style={{
                              fontSize: size || '32px',
                              lineHeight: titleLineHeight || '28px',
                              marginBottom: marginBottom || '28px',
                          }}
                      >
                          {count} {title}
                      </h2>
                  )
                : title && (
                      <h2
                          className={titleStyle ? titleStyle : ParagraphTitle}
                          style={{
                              fontSize: size || '32px',
                              lineHeight: titleLineHeight || '28px',
                              marginBottom: marginBottom || '28px',
                          }}
                      >
                          {title}
                      </h2>
                  )}

            {textCount ? (
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <h2
                        style={{ marginRight: '12px', fontSize: size || '' }}
                        className={
                            textCountStyle ? textCountStyle : ParagraphTitle
                        }
                    >
                        {textCount}
                    </h2>
                    <div
                        className={textStyle ? textStyle : ParagraphText}
                        style={{
                            lineHeight: textLineHeight || '35px',
                            fontSize: fontSize || '18px',
                        }}
                    >
                        {text}
                        <br />
                        {textChildren &&
                            textChildren.map((child, i) => (
                                <div
                                    key={i}
                                    style={{
                                        display: 'flex',
                                        paddingTop: '20px',
                                    }}
                                >
                                    <span
                                        className={ParagraphTitle}
                                        style={{ fontWeight: 'normal' }}
                                    >
                                        {textChildrenType ===
                                            'decimal-numbered-list' &&
                                            `${textCount}.${i + 1}.`}
                                        {textChildrenType !==
                                            'decimal-numbered-list' && (
                                            <>&#8226;</>
                                        )}
                                    </span>
                                    <span
                                        style={{
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        {parse(child)}
                                    </span>
                                </div>
                            ))}
                    </div>
                </div>
            ) : (
                <div
                    className={textStyle ? textStyle : ParagraphText}
                    style={{
                        lineHeight: textLineHeight || '35px',
                        fontSize: fontSize || '18px',
                    }}
                >
                    {text}
                </div>
            )}
            {children}
        </div>
    )
}

export default Paragraph
