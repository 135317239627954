const consumerPages = [
    '/consumer/repayment-schedule',
    '/consumer/update-application',
    '/consumer/workspace',
]

export const checkConsumerPages = path => {
    if (consumerPages.includes(path)) {
        return true
    } else {
        return false
    }
}
