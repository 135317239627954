import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import './radiobutton.scss'

const RadioButton = ({
    list,
    onCheck = v => v,
    clearSelected,
    defaultChecked,
}) => {
    const [check, setCheck] = useState(defaultChecked || null)
    const handleClick = (i, text) => {
        setCheck(text)
        onCheck(text)
    }

    useEffect(() => {
        clearSelected && setCheck(null)
    }, [clearSelected])

    return (
        <div className="radioBtnWrapper">
            {list?.map((text, i) => (
                <label
                    key={i}
                    onClick={() => handleClick(i, text)}
                    className={`radioLabel ${
                        check === text ? 'checked' : 'notchecked'
                    }`}
                >
                    <p className="radioBtnText">{text}</p>
                </label>
            ))}
        </div>
    )
}

RadioButton.propTypes = {
    list: PropTypes.array,
    onCheck: PropTypes.any,
    clearSelected: PropTypes.bool,
    defaultChecked: PropTypes.string,
}

export default RadioButton
