import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from '../sselogin.module.scss'
import { actionOptions } from '../data'
import Button from 'src/components/Button'
import ChatCTA from 'src/components/ChatCTA'
import { isMobile } from 'src/utils/mediaQueries'
import { removeToken } from 'src/utils/auth'
import { appTracking } from 'src/utils/appTracker'

const LoginAction = ({ option, handleOptionClick, handleContinueClick }) => {
    const {
        pageContent,
        actionOptionsWrapper,
        actionOption,
        actionOptionActive,
        descWrapper,
        button,
    } = styles
    const location = useLocation()
    const referredFrom = location?.state?.referredFrom ?? null

    const pageTitle =
        'Consumer Login | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_LOGIN'

    useEffect(() => {
        const keys = Object.keys(localStorage)
        // preserve sse consumer before they sign up
        if (
            referredFrom === 'sseSystemsListings' ||
            referredFrom === 'sseViewSystem'
        ) {
            keys.forEach(key => {
                if (key !== 'sunfiUserSSEGettingStartedInfo') {
                    localStorage.removeItem(key)
                }
            })
            return
        }
        removeToken()
    }, [referredFrom])

    return (
        <div className={pageContent}>
            <h1>What would you like to do?</h1>
            <p>Please select the action you would like to take.</p>
            <div className={actionOptionsWrapper}>
                {actionOptions?.map((action, i) => (
                    <div
                        key={i}
                        className={`${actionOption} ${
                            action.key === option ? actionOptionActive : ''
                        }`}
                        onClick={() => {
                            handleOptionClick(action.key)
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                i === 0
                                    ? 'CONSUMER_CLICKS_SUBMIT_YOUR_DATA'
                                    : i === 1
                                    ? 'CONSUMER_CLICKS_CONTINUE_YOUR_APPLICATION'
                                    : 'CONSUMER_CLICKS_GET_INTO_YOUR_PORTAL',
                                'KYC_CONSUMER_LOGIN_ACTION',
                                'KYC_CONSUMER_LOGIN_PAGE',
                            )
                        }}
                    >
                        <div>{action?.icon}</div>
                        <section className={descWrapper}>
                            <h4>{action?.title}</h4>
                            <p>{action?.description}</p>
                        </section>
                    </div>
                ))}
            </div>
            <div className={button}>
                <Button
                    btnWidth={isMobile ? '157px' : '334px'}
                    btnHeight={'53px'}
                    btnTextColor={'#ffffff'}
                    btnBgColor={'#004aad'}
                    disabled={option === ''}
                    handleClick={() => handleContinueClick()}
                >
                    Continue
                </Button>
            </div>
            <ChatCTA justifyContent="center" />
        </div>
    )
}

LoginAction.propTypes = {
    option: PropTypes.string,
    handleOptionClick: PropTypes.func,
    handleContinueClick: PropTypes.func,
}

export default LoginAction
