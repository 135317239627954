/* eslint-disable react/prop-types */
import styles from './otpinput.module.scss'

const OtpInput = ({
    onChangeHandler = () => {},
    value = '',
    totalInputBox = 6,
}) => {
    const { OtpInputBox, OtpInputBoxFilled, FourOtpInputBox } = styles
    return (
        <>
            {[...Array(parseInt(totalInputBox)).keys()].map(i => {
                return (
                    <input
                        type="text"
                        key={i + 'otpinput'}
                        placeholder=""
                        className={`${OtpInputBox} ${
                            value.length > 0 ? OtpInputBoxFilled : ''
                        } ${totalInputBox && FourOtpInputBox}`}
                        onChange={onChangeHandler}
                        name={`otp-${i + 1}`}
                        maxLength={1}
                        value={`${value[`otp-${i + 1}`]}`}
                    />
                )
            })}
        </>
    )
}

export default OtpInput
