import { ConsumerButton } from '../Button'
import solarCity from 'src/assets/images/solar_city.png'
import styles from './cssecta.module.scss'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import useHandleSectionScroll from 'src/utils/Hooks/useHandleSectionScroll'

// app tracker variables
const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'SSE_LANDING_PAGE'

const CSSECTA = () => {
    const { CSSECTAWrapper, CSSECTARightWrapper } = styles

    const { handleSectionScroll } = useHandleSectionScroll()

    return (
        <section className={CSSECTAWrapper}>
            <div>
                <img src={solarCity} alt="solar city" />

                <div className={CSSECTARightWrapper}>
                    <h1>Get started on SunFi and keep the lights on</h1>
                    <ConsumerButton
                        btnWidth="253px"
                        btnHeight="56px"
                        btnBgColor="#FFFFFF"
                        btnTextColor="#004AAD"
                        btnFontSize="14px"
                        role="get-started"
                        handleClick={() => {
                            handleSectionScroll('landing-page-form')
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                eventTrackers['clickedGetStartedButton'].action,
                                eventTrackers['clickedGetStartedButton'].label,
                                eventTrackers['clickedGetStartedButton']
                                    .category,
                                ['MP', 'GA'],
                            )
                        }}
                    >
                        Get Started
                    </ConsumerButton>
                </div>
            </div>
        </section>
    )
}

export default CSSECTA
