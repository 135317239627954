import { useState } from 'react'
import LoginComponent from 'src/components/LoginComponent'
import './replogin.scss'
import BackNav from 'src/components/BackNav'
import { useHistory } from 'react-router-dom'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const REPLogin = () => {
    const [method, setMethod] = useState('email')
    const history = useHistory()
    const { isMobile } = useMediaQueries()

    const handleBackNav = () => {
        history.push('/consumer/welcome')
    }

    return (
        <div className="page">
            <div className="backNavContainer">
                <BackNav
                    v2
                    title={'Go back'}
                    marginTop={isMobile ? '56px' : '76px'}
                    marginBottom={isMobile ? '6px' : '16px'}
                    onClick={() => handleBackNav()}
                />
            </div>

            <LoginComponent
                method={method}
                setMethod={setMethod}
                userType="cluster-rep"
            />
        </div>
    )
}

export default REPLogin
