export const CustomerAcquisitionData = ['New Location', 'Existing Location']
export const paymentMethodData = ['Card Debit', 'Direct Debit']

export const tenurePaymentData = [
    '3 months',
    '6 months',
    '9 months',
    '12 months',
    '15 months',
    '18 months',
    '21 months',
    '24 months',
]

export const systemsRecoveryData = [
    'Customer site accessible in event of default',
    'Customer site not accessible in event of default',
]

export const remoteMonitoringData = [
    'No remote management capabilities',
    'Remote monitoring only',
    'External remote shutdown only',
    'Internal remote shutdown only',
    'External (IOT Device) remote monitoring and shutdown capability',
    'Internal/In-built remote monitoring and remote shutdown capability',
]

export const generatorSizeData = [
    '1kVA',
    '2.5kVA',
    '3.5kVA',
    '4.1kVA',
    '5kVA',
    '7kVA',
    '8.5kVA',
    '10kVA',
    '15kVA',
    '20kVA',
    '25kVA',
    '30kVA',
    '40kVA',
    '50kVA',
    '75kVA',
    '100kVA',
    '135kVA',
    '200kVA',
    '250kVA',
    '350kVA',
    '500kVA',
    '600kVA',
    '700kVA',
    '800kVA',
]

export const installationDetailsData = ['Yes', 'No']

export const installationDetailsDataMapping = {
    true: 'Yes',
    false: 'No',
}

export const selectPackageFromOptions = [
    'Product Library',
    'Shared-Cluster Library',
]
