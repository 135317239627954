import PropTypes from 'prop-types'
import { AppButton } from 'src/components/Button'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import ClusterMinimumDeposit from './ClusterMinimumDeposit'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useState } from 'react'

const SingleCluster = ({ data }) => {
    const { isMobile } = useMediaQueries()
    const [showMinimumDepositModal, setShowMinimumDepositModal] =
        useState(false)
    const [clickedClusterCode, setClickedClusterCode] = useState('')

    return (
        <>
            <SecondaryModal
                showModal={showMinimumDepositModal}
                modalHeight="487px"
                modalWidth="469px"
                noPadding={isMobile ? '16px' : '40px'}
                onCancel={() => setShowMinimumDepositModal(false)}
                content={
                    <ClusterMinimumDeposit clusterCode={clickedClusterCode} />
                }
            />
            <div
                className="ACLTableRow"
                style={{ gridTemplateColumns: '30% 28% 21% 27%' }}
            >
                <p>{data?.cluster_info?.name}</p>

                <p>{data?.cluster_info?.code}</p>

                <p>{data?.cluster_info?.packages?.length}</p>
                <AppButton
                    btnBgColor="#E2EEFF"
                    btnTextColor="#004AAD"
                    btnWidth="70px"
                    btnHeight="37px"
                    fontSize="12px"
                    fontFamily="SF-Pro-Display-Medium"
                    handleClick={() => {
                        setClickedClusterCode(data?.cluster_info?.code)
                        setShowMinimumDepositModal(true)
                    }}
                >
                    View
                </AppButton>
            </div>
        </>
    )
}

SingleCluster.propTypes = {
    data: PropTypes.object.isRequired,
}

export default SingleCluster
