// import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { useState, useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import {
    formatProviderUserActivity,
    formatAdminUserActivity,
} from './formatter'
import { TableSkeletalCards } from '../../SkeletalCards'
import EmptyState from '../../EmptyState'
import parse from 'html-react-parser'
import { toTitleCase } from 'src/utils/formatting'
import { permissionsControl } from 'src/utils/permissionsControl'

const UserActivitiesTable = ({
    actions,
    userType,
    pageLoading,
    createActivities,
    setCreateActivities,
}) => {
    const [activityData, setActivityData] = useState([])
    const [loading, setLoading] = useState(true)
    const { Heading, Table, Row, Activity, DateOf } = styles

    useEffect(() => {
        const formatter =
            userType === 'provider-user'
                ? formatProviderUserActivity
                : formatAdminUserActivity
        if (actions?.length !== 0 && createActivities) {
            formatter(actions)?.map(item => {
                setActivityData(prev => [...prev, item])
            })
            setCreateActivities(false)
        }
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [actions, createActivities, userType, setCreateActivities])

    return (
        <>
            {pageLoading || loading ? (
                <TableSkeletalCards total={1} />
            ) : (
                <>
                    <h4 className={Heading}>User Activities</h4>
                    {activityData.length === 0 ||
                    (userType === 'provider-user' &&
                        !permissionsControl([
                            'can_view_provider_user_activities',
                        ])) ? (
                        <EmptyState
                            subTitle={
                                !permissionsControl([
                                    'can_view_provider_user_activities',
                                ])
                                    ? "You don't have the permission to view this user activities"
                                    : 'This user has not performed any recent activities'
                            }
                        />
                    ) : (
                        <div className={Table}>
                            {activityData?.map((each, i) => {
                                const taskLabel = parse(each?.task)[0]
                                const taskValue = toTitleCase(
                                    parse(each?.task)[1]?.props?.children,
                                )

                                return (
                                    <div className={Row} key={i}>
                                        <p className={Activity}>
                                            {taskLabel} <span>{taskValue}</span>
                                        </p>
                                        <p className={DateOf}>
                                            {format(
                                                parseISO(each?.date),
                                                'MMM dd yyyy',
                                            )}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </>
            )}
        </>
    )
}

UserActivitiesTable.propTypes = {
    actions: PropTypes.array,
    userType: PropTypes.string,
    pageLoading: PropTypes.bool,
    createActivities: PropTypes.bool,
    setCreateActivities: PropTypes.func,
}

export default UserActivitiesTable
