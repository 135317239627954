import '../addcomponents.scss'
import PropTypes from 'prop-types'
import NairaSymbol from 'src/components/NairaSymbol'
import { subStringText } from 'src/utils/formatting'
import CloseIcon from 'src/assets/images/close-icon.svg'
import DownArrow from 'src/assets/images/blackDownArrow.svg'
import UpArrow from 'src/assets/images/blackUpArrow.svg'

const SelectedComponentsTable = ({
    selected,
    updateCount,
    getCount,
    handleCancel,
    isMobile,
    currencyFormatter,
}) => {
    return (
        <table>
            <tbody>
                {selected?.length > 0 &&
                    selected.map((item, index) => (
                        <tr key={index} className="StepOneSelectedComponents">
                            <td
                                className="ERComponentsFirstTD"
                                style={{ width: isMobile ? '88%' : '50%' }}
                            >
                                <div className="ERComponentsFirstTDDiv">
                                    <span className="ERComponentsFirstTDSelectedName">
                                        {subStringText(item?.name, 30)}
                                    </span>
                                </div>
                                <span className="ERComponentAmountMobile">
                                    NGN {currencyFormatter(item?.realCost)}
                                </span>
                            </td>
                            <td style={{ width: '25%' }} className="HideMobile">
                                <span className="SelectedCapacity">
                                    {subStringText(item?.type?.name, 9)}
                                </span>
                            </td>
                            <td
                                style={{ width: '25%', textAlign: '' }}
                                className="MobileAmount"
                            >
                                <span className="SelectedCapacity HideMobile">
                                    <NairaSymbol />{' '}
                                    {currencyFormatter(
                                        item?.realCost ?? item?.cost,
                                    )}
                                </span>
                            </td>
                            <td style={{ width: '10%' }} className="MobileQty">
                                <div className="Scroll">
                                    <span
                                        className="QuantityImageWrapper"
                                        onClick={() =>
                                            updateCount(
                                                getCount(item.id),
                                                'add',
                                                item.id,
                                                item.cost,
                                                item?.realCost,
                                            )
                                        }
                                        disabled={
                                            getCount(item.id) >= 10
                                                ? true
                                                : false
                                        }
                                        style={{
                                            cursor:
                                                getCount(item.id) >= 10
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                        }}
                                    >
                                        <img
                                            src={UpArrow}
                                            className="Arrow"
                                            alt="up-arrow-icon"
                                        />
                                    </span>
                                    <span className="Quantity">
                                        {getCount(item.id)}
                                    </span>
                                    <span
                                        className="QuantityImageWrapper"
                                        onClick={() =>
                                            updateCount(
                                                getCount(item.id),
                                                'subtract',
                                                item.id,
                                                item.cost,
                                                item?.realCost,
                                            )
                                        }
                                        disabled={
                                            getCount(item.id) <= 1
                                                ? true
                                                : false
                                        }
                                        style={{
                                            cursor:
                                                getCount(item.id) <= 1
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                        }}
                                    >
                                        <img
                                            src={DownArrow}
                                            className="Arrow"
                                            alt="down-arrow-icon"
                                        />
                                    </span>
                                </div>
                            </td>
                            <td style={{ width: '16px', cursor: 'pointer' }}>
                                <span onClick={() => handleCancel(item.id)}>
                                    <img src={CloseIcon} alt="close-icon" />
                                </span>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}

SelectedComponentsTable.propTypes = {
    selected: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            realCost: PropTypes.number.isRequired,
            type: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
            cost: PropTypes.number.isRequired,
        }),
    ).isRequired,
    updateCount: PropTypes.func.isRequired,
    getCount: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    currencyFormatter: PropTypes.func.isRequired,
}

export default SelectedComponentsTable
