import PropTypes from 'prop-types'
import SecondaryModal from '../Modal/SecondaryModal'
import { signInMethods } from './data'
import styles from './loginmethodsmodal.module.scss'
import { isMobile } from 'src/utils/mediaQueries'
import { appTracking } from 'src/utils/appTracker'

const LoginMethodsModal = ({
    showModal,
    setShowModal,
    method,
    setMethod,
    handleLoginMethodClick,
}) => {
    const { wrapper, methodDiv, methodDivActive, methodIcon, methodText } =
        styles
    const closeModal = () => {
        setShowModal(false)
        setMethod('')
    }
    const pageTitle =
        'Consumer Login | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_LOGIN'

    const handleSelectMethod = key => {
        setMethod(key)
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            key === 'phone'
                ? 'CONSUMER_CLICKS_SIGN_IN_WITH_PHONE_NUMBER'
                : 'CONSUMER_CLICKS_SIGN_IN_WITH_EMAIL_ADDRESS',
            'KYC_CONSUMER_LOGIN_TYPE',
            'KYC_CONSUMER_LOGIN_PAGE',
        )
    }
    return (
        <SecondaryModal
            title={'How would you like to sign in?'}
            showModal={showModal}
            onCancel={closeModal}
            closable
            modalWidth={'475px'}
            showFooter
            v2
            primaryBtnText={'Continue'}
            primaryBtnDisabled={method === ''}
            primaryBtnWidth={isMobile ? '127px' : '160px'}
            handlePrimaryBtnClick={() => handleLoginMethodClick()}
            chatCTAText={
                isMobile ? 'Have questions?' : 'Have trouble signing in?'
            }
            dataTestId={'login-methods-modal'}
            primaryBtnDataTestId={'login-methods-modal-primary-btn'}
            content={
                <div className={wrapper}>
                    {signInMethods?.map((each, i) => (
                        <div
                            key={i}
                            role="button"
                            className={`${methodDiv} ${
                                method === each?.key ? methodDivActive : ''
                            }`}
                            onClick={() => handleSelectMethod(each?.key)}
                            data-testid={`handle-select-method-btn-${each?.key}`}
                        >
                            <div className={methodIcon}>{each?.icon}</div>
                            <div className={methodText}>
                                <h4>{each?.title}</h4>
                                <p>{each?.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            }
        />
    )
}

LoginMethodsModal.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    handleLoginMethodClick: PropTypes.func,
    method: PropTypes.oneOf(['phone', 'email']),
    setMethod: PropTypes.func,
}

export default LoginMethodsModal
