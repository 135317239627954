import axios from '../../../config/axios'

export const getEnergyProfileSolutions = async ({
    profile_id = '',
    page = 1,
    price_sort = '',
    battery_types = '',
    system_types = '',
    payment_plan = '',
    min_cost = '',
    max_cost = '',
    min_backup_hour = '',
    max_backup_hour = '',
    min_system_size = '',
    max_system_size = '',
    solution_id = '',
}) => {
    const request = axios.get(
        `/energy-profiles/solutions?profile_id=${profile_id}&solution_id=${solution_id}&page=${page}&price_sort=${price_sort}&battery_types=${battery_types}&system_types=${system_types}&min_cost=${min_cost}&max_cost=${max_cost}&min_backup_hour=${min_backup_hour}&max_backup_hour=${max_backup_hour}&min_system_size=${min_system_size}&max_system_size=${max_system_size}&payment_plan=${payment_plan}`,
    )
    return request
}

export const getEnergyProfileSolutionsFilter = async ({ profile_id }) => {
    const request = axios.get(
        `/energy-profiles/solutions/all-filter?profile_id=${profile_id}`,
    )
    return request
}
export const getEnergyProfiles = async ({
    customer_type = '',
    name = '',
    page = 1,
    promotional_type = '',
    is_promotional = false,
}) => {
    const request = axios.get(
        `/energy-profiles?customer_type=${customer_type}&name=${name}&page=${page}&promotional_type=${promotional_type?.toUpperCase()}&is_promotional=${is_promotional}`,
    )
    return request
}

export const getEnergyProfile = async ({ id = '' }) => {
    const request = axios.get(`/energy-profiles/${id}`)
    return request
}

export const retrieveEnergySolutionDetails = async ({ id, payload }) => {
    const request = await axios.post(
        `/energy-profiles/solutions/${id}`,
        payload,
    )
    return request
}

export const matchEnergySolutionAppliances = async (id, payload) => {
    const request = await axios.post(
        `/energy-profiles/solutions/${id}/appliance/match`,
        payload,
    )
    return request
}
