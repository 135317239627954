import axios from '../../config/axios'

export const loginApi = async (type, inputs) => {
    if (type === 'phone') {
        const request = await axios.post('/auth/phone-login', inputs)
        return request
    } else {
        const request = await axios.post('/auth/email-login', inputs)
        return request
    }
}

export const verifyLoginOTPApi = async inputs => {
    const request = await axios.post('/auth/validate-login-otp', inputs)
    return request
}
