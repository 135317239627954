/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import Navigation from 'src/components/Navigation'
import { contentData } from './contents'
import Button from 'src/components/Button'
import { subStringText } from 'src/utils/formatting'
import { ReactComponent as XIcon } from 'src/assets/images/X_logo-dark.svg'
import { ReactComponent as FacebookDark } from 'src/assets/images/facebook-dark.svg'
import { ReactComponent as LinkedInDark } from 'src/assets/images/linkedin-dark.svg'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share'
import styles from './learncontent.module.scss'
import TwelveMeta from 'src/pages/LearnContent/ContentTwelve/TwelveMeta'

const bulletArticleList = [6, 7, 8, 12, 12.1, 13]

const {
    HeadingText,
    WiderHeading,
    HeadingDate,
    CircleDot,
    SocialBox,
    SocialCircle,
    ContentImage,
    ContentTitle,
    ContentSubtitle,
    ContentParagraphs,
    ContentSocialBox,
    ShareText,
    ContentBox,
    UsefulBox,
    RelatedContentBox,
    RelatedContentListBox,
    RelatedContentListInfo,
    RelatedContentHeaderText,
    RelatedContentHeaderTitle,
    RelatedContentHeaderDate,
    RelatedContentImg,
    ContentDarkBox,
    ContentDarkBoxText,
    ContentDarkBoxTextSection,
    ContentDarkBoxImageSection,
    ContentDarkBoxLink,
    MobileImageView,
    DesktopImageView,
    BoldParagraph,
    InfoTitle,
} = styles

const paragraphWithLinks = (text, boldSubstrings, link) => {
    let prevStart = 0
    const splittedStrings = []
    for (let subStrings of boldSubstrings) {
        splittedStrings.push(
            text.substr(prevStart, subStrings.offset - prevStart),
        )
        splittedStrings.push(
            <a
                href={link ? link : '..'}
                target="_blank"
                style={{ color: 'var(--blue)', fontWeight: 'bold' }}
                rel="noreferrer"
            >
                {text.substr(subStrings.offset, subStrings.length)}
            </a>,
        )
        prevStart = subStrings.offset + subStrings.length
    }
    splittedStrings.push(text.substr(prevStart))
    return <p>{splittedStrings}</p>
}

const LearnContentDetail = ({
    contentId,
    noAction,
    yesAction,
    feedbackResponse,
    noRelated,
}) => {
    const [contentDetail, setContentDetail] = useState({})
    const [relatedContent, setRelatedContent] = useState([])

    useEffect(() => {
        const detail = contentData.filter(data => data.id === contentId)
        setContentDetail(detail[0])
        const otherContent = contentData.filter(data => data.id !== contentId)
        let newContent = []
        for (let i = 0; i < 2; i++) {
            newContent.push(otherContent[i])
        }
        setRelatedContent(newContent)
    }, [contentId])

    const redirectTo = pageUrl => {
        window.location.href = pageUrl
    }

    const shareUrl = window.location.href
    const quote = contentDetail['headerText']

    return (
        <>
            <Navigation
                bgColor="var(--white)"
                btnBgColor="var(--white"
                textColor="var(--black)"
                btnOutlineColor="var(--blue)"
                btnTextColorOutline="var(--blue)"
                page="learn"
                btnType="outline"
            />
            <h1
                className={`${HeadingText} ${
                    contentDetail.id === 12 && WiderHeading
                }`}
            >
                {contentDetail['headerText']}
            </h1>
            <div className={HeadingDate}>
                {contentDetail['date']}
                <div className={CircleDot}></div>
                {contentDetail['time']}
            </div>
            <div className={SocialBox}>
                <FacebookShareButton url={shareUrl} quote={quote}>
                    <div className={SocialCircle}>
                        <FacebookDark />
                    </div>
                </FacebookShareButton>

                <LinkedinShareButton url={shareUrl} quote={quote}>
                    <div className={SocialCircle}>
                        <LinkedInDark />
                    </div>
                </LinkedinShareButton>

                <TwitterShareButton url={shareUrl} quote={quote}>
                    <div className={SocialCircle}>
                        <XIcon />
                    </div>
                </TwitterShareButton>
            </div>
            <div className={ContentImage}>
                <picture className={MobileImageView}>
                    <img src={contentDetail['thumbnailUrl']} alt="content" />
                </picture>
                <picture className={DesktopImageView}>
                    <img src={contentDetail['imageUrl']} alt="content" />
                </picture>
            </div>
            <div className={ContentBox}>
                <div className={ContentSocialBox}>
                    <span className={ShareText}>Share</span>
                    <FacebookShareButton url={shareUrl} quote={quote}>
                        <div className={SocialCircle}>
                            <FacebookDark />
                        </div>
                    </FacebookShareButton>

                    <LinkedinShareButton url={shareUrl} quote={quote}>
                        <div className={SocialCircle}>
                            <LinkedInDark />
                        </div>
                    </LinkedinShareButton>

                    <TwitterShareButton url={shareUrl} quote={quote}>
                        <div className={SocialCircle}>
                            <XIcon />
                        </div>
                    </TwitterShareButton>
                </div>
                <div>
                    <div className={ContentTitle}>{contentDetail['title']}</div>
                    {contentDetail.id === 4 ? (
                        <>
                            {contentDetail['paragraphs']?.map(
                                (information, index) => {
                                    if (information.title) {
                                        return (
                                            <div
                                                className={InfoTitle}
                                                key={index + 1}
                                            >
                                                <h2 className={ContentSubtitle}>
                                                    {information.title}
                                                </h2>
                                                <p
                                                    className={
                                                        ContentParagraphs
                                                    }
                                                >
                                                    {information.text}
                                                </p>
                                            </div>
                                        )
                                    }
                                    if (information.imageUrl) {
                                        return (
                                            <div
                                                className={ContentDarkBox}
                                                key={index + 1}
                                            >
                                                <div
                                                    className={
                                                        ContentDarkBoxTextSection
                                                    }
                                                >
                                                    <img
                                                        src="https://sunfiwebassets.blob.core.windows.net/brand/sunfi-sm.png"
                                                        width="50px"
                                                        height="16px"
                                                        alt="brand-article"
                                                    />
                                                    <br />
                                                    <div
                                                        className={
                                                            ContentDarkBoxText
                                                        }
                                                    >
                                                        The fastest way to
                                                        finance clean energy
                                                        solutions
                                                    </div>
                                                    <br />
                                                    <a
                                                        href="/"
                                                        className={
                                                            ContentDarkBoxLink
                                                        }
                                                    >
                                                        Learn more
                                                    </a>
                                                </div>
                                                <div
                                                    className={
                                                        ContentDarkBoxImageSection
                                                    }
                                                >
                                                    <img
                                                        width="100%"
                                                        height="85%"
                                                        src="https://sunfiwebassets.blob.core.windows.net/brand/fastest-way.svg"
                                                        alt="brand-article"
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                    if (information.offset) {
                                        return (
                                            <p
                                                key={`${index}-paragraph-text-content`}
                                                className={ContentParagraphs}
                                            >
                                                {paragraphWithLinks(
                                                    information.text,
                                                    information.offset,
                                                    information.link,
                                                )}
                                            </p>
                                        )
                                    } else {
                                        return (
                                            <p
                                                className={ContentParagraphs}
                                                key={index + 1}
                                            >
                                                {information.text}
                                            </p>
                                        )
                                    }
                                },
                            )}
                            <div>
                                <p className={ContentParagraphs}>
                                    Rotimi Thomas on behalf of{' '}
                                </p>
                                <p
                                    className={`${ContentParagraphs} ${BoldParagraph}`}
                                >
                                    Team SunFi
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            {contentDetail['paragraphs']?.map(
                                (information, index) => {
                                    return (
                                        <p
                                            key={`${index}-paragraph-text-content`}
                                            className={ContentParagraphs}
                                            dangerouslySetInnerHTML={{
                                                __html: information,
                                            }}
                                        ></p>
                                    )
                                },
                            )}
                            {bulletArticleList.includes(contentDetail.id) &&
                                contentDetail.bulletedArticle}

                            {contentDetail.id === 12 ||
                            contentDetail.id === 13 ? (
                                <TwelveMeta
                                    subListHeader={contentDetail?.subListHeader}
                                    bulletArticle2={
                                        contentDetail?.bulletedArticle2
                                    }
                                    conclusions={contentDetail['conclusion']}
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </div>
            {contentDetail.id !== 4 ||
                (contentDetail.id === 6 && (
                    <>
                        <div className={UsefulBox}>
                            {feedbackResponse
                                ? 'Thanks for your feedback'
                                : 'Was this article helpful to you?'}
                            <br />
                            <br />
                            {!feedbackResponse ? (
                                <>
                                    <Button
                                        btnBgColor="(--white)"
                                        btnTextColor="var(--blue)"
                                        type="small"
                                        btnTextColorOutline="var(--blue)"
                                        handleClick={noAction}
                                    >
                                        No
                                    </Button>
                                    &nbsp; &nbsp;
                                    <Button
                                        btnBgColor="(--white)"
                                        btnTextColor="var(--blue)"
                                        btnTextColorOutline="var(--blue)"
                                        type="small"
                                        handleClick={yesAction}
                                    >
                                        Yes
                                    </Button>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        {noRelated ? (
                            ''
                        ) : (
                            <>
                                <div className={RelatedContentBox}>
                                    Related Contents
                                </div>
                                <div style={{ display: 'grid' }}>
                                    {relatedContent.map((content, index) => {
                                        return (
                                            <div
                                                className={
                                                    RelatedContentListBox
                                                }
                                                key={`${index}-related-content`}
                                                onClick={() =>
                                                    redirectTo(content['link'])
                                                }
                                            >
                                                <div>
                                                    <img
                                                        src={
                                                            content[
                                                                'smallImageUrl'
                                                            ]
                                                        }
                                                        alt="related-content"
                                                        className={
                                                            RelatedContentImg
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        RelatedContentListInfo
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            RelatedContentHeaderText
                                                        }
                                                    >
                                                        {content['headerText']}
                                                    </div>
                                                    <div
                                                        className={
                                                            RelatedContentHeaderTitle
                                                        }
                                                    >
                                                        {subStringText(
                                                            content['title'],
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            RelatedContentHeaderDate
                                                        }
                                                    >
                                                        {content['date']}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        )}
                    </>
                ))}
        </>
    )
}

export default LearnContentDetail
