/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { InputFields, TextArea } from '../../../../components/InputFields'
import LabelTitle from '../../../../components/LabelTitle'
import Checkbox from '../../../../components/Checkbox'
import { DateField } from '../../../../components/InputFields'
import Button from '../../../../components/Button'
import ActionModal from '../../../../components/ActionModal'
import { InlineLoader } from '../../../../components/Loader'

import { MultipleSelectField } from '../../../../components/InputFields'

import { ReactComponent as EditIcon } from '../../../../assets/images/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete-icon.svg'

import styles from './work.module.scss'

const {
    AddressBlock,
    Separator,
    CheckBoxWrapper,
    TextField,
    DateBlock,
    DateInputRight,
    DateInputLeft,
    SaveButton,
    CloseCard,
    Role,
    Action,
    Period,
    ActionButton,
    EditActionButton,
    DeleteActionButton,
} = styles

const DataForm = ({
    formId,
    checkCloseFormState,
    closeFormHandler,
    handleInputChange,
    formInputs,
    editFormHandler,
    removeForm,
    formPosition,
    totalForm,
    errorMessages,
    loading,
    updateLoading,
    deleteModalHandler,
    unSaveDeleteHandler,
    techUsedData,
    handleInputUpdate,
}) => {
    const [inputs, setInputs] = useState({
        companyname: null,
        role: null,
        description: null,
        tech: null,
        startDate: null,
        endDate: null,
        currentWork: false,
        updateId: null,
        formId: null,
    })
    const [deleteModal, setDeleteModal] = useState(false)
    const [updateParent, setUpdateParent] = useState(false)

    const [showCalendar, setShowCalendar] = useState(0)
    const [windowsWidth, setWindowWidth] = useState(window.innerWidth)
    const [currentStartDate, setCurrentStartDate] = useState(
        inputs.startDate === null ? null : new Date(inputs.startDate),
    )
    const [currentEndDate, setCurrentEndDate] = useState(
        inputs.endDate === null ? null : new Date(inputs.endDate),
    )

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)
        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    function handleWindowResize(event) {
        const newWindowWidth = window.innerWidth
        setWindowWidth(newWindowWidth)
    }

    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(date, 'MM/dd/yyyy')
        }
        if (name === 'endDate') {
            setCurrentEndDate(date, 'MM/dd/yyyy')
        }

        setTimeout(() => {
            setUpdateParent(true)
        }, 1000)
    }

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]: name === 'startDate' ? currentStartDate : currentEndDate,
        }))
    }

    const handleChecked = () => {
        setInputs(preState => ({
            ...preState,
            currentWork: !inputs.currentWork,
        }))

        setTimeout(() => {
            setUpdateParent(true)
        }, 1000)
    }

    const saveExperienceHandler = e => {
        // closeFormHandler(formId)
        handleInputChange(e, formId, inputs)
    }

    const editExperienceHandler = () => {
        editFormHandler(formId)
    }

    const handleFormInputUpdate = event => {
        event.persist()
        setInputs(preState => ({
            ...preState,
            [event.target.name]: event.target.value,
        }))
        updateInputsParent()
    }

    const inputValueCrossCheck = name => {
        if (formInputs[name]) {
            return formInputs[name]
        } else {
            return null
        }
    }

    const removeFormUpdate = () => {
        updateModalState()
        removeForm(formId)
    }

    const updateModalState = () => {
        setDeleteModal(!deleteModal)
    }

    useEffect(() => {
        setInputs({
            companyname: inputValueCrossCheck('companyname'),
            role: inputValueCrossCheck('role'),
            description: inputValueCrossCheck('description'),
            tech: inputValueCrossCheck('tech'),
            startDate: inputValueCrossCheck('startDate'),
            endDate: inputValueCrossCheck('endDate'),
            currentWork: inputValueCrossCheck('currentWork'),
            updateId: inputValueCrossCheck('updateId'),
        })
        // eslint-disable-next-line
    }, [])

    const handleMultiSelect = value => {
        setInputs(preState => ({
            ...preState,
            tech: value,
        }))

        setTimeout(() => {
            updateInputsParent()
        }, 1000)
    }

    const updateInputsParent = () => {
        handleInputUpdate(formId, inputs)
    }

    useEffect(() => {
        if (updateParent) {
            handleInputUpdate(formId, inputs)
        }

        // eslint-disable-next-line
    }, [updateParent])

    useEffect(() => {}, [showCalendar])

    return (
        <>
            <ActionModal
                actionModalOpen={deleteModal}
                headerText="Delete Experience"
                subTitle="If you delete this work experience, you’ll have to refill the data"
                closeModal={updateModalState}
                actionHandler={removeFormUpdate}
                actionType="delete"
                actionText="Delete"
            />
            {!checkCloseFormState ? (
                <div>
                    <div>
                        <LabelTitle title={`Work Experience ${formPosition}`} />
                        <div className={AddressBlock}>
                            <div className={TextField}>
                                <InputFields
                                    inputValue={
                                        inputs.companyname ||
                                        formInputs?.companyname
                                    }
                                    handleChange={handleFormInputUpdate}
                                    name="companyname"
                                    title="Company/Project Name"
                                    type="text"
                                    errorMessage={
                                        errorMessages?.formId === formId
                                            ? errorMessages?.companyname
                                            : ''
                                    }
                                />
                            </div>

                            <span className={Separator}></span>
                            <div className={TextField}>
                                <InputFields
                                    inputValue={inputs.role || formInputs?.role}
                                    handleChange={handleFormInputUpdate}
                                    name="role"
                                    title="Role"
                                    type="text"
                                    errorMessage={
                                        errorMessages?.formId === formId
                                            ? errorMessages?.role
                                            : ''
                                    }
                                />
                            </div>
                        </div>
                        <div className={DateBlock}>
                            <div className={DateInputLeft}>
                                <DateField
                                    dateValue={
                                        inputs.startDate === null
                                            ? null
                                            : new Date(inputs.startDate)
                                    }
                                    handleDateChange={(name, date) =>
                                        handleDateChange(name, date)
                                    }
                                    placeholder="Start Date"
                                    name="startDate"
                                    position="Left"
                                    openCalendar={
                                        showCalendar === 1 ? true : false
                                    }
                                    closeCalendarHandler={() =>
                                        setShowCalendar(null)
                                    }
                                    openCalendarHandler={() =>
                                        setShowCalendar(1)
                                    }
                                    setHandleDateChange={name =>
                                        setHandleDateChange(name)
                                    }
                                    errorMessage={
                                        errorMessages?.formId === formId
                                            ? errorMessages?.startDate
                                            : ''
                                    }
                                />
                            </div>
                            <span className={Separator}></span>

                            {!inputs.currentWork && (
                                <div className={DateInputRight}>
                                    <DateField
                                        dateValue={
                                            inputs.endDate === null
                                                ? null
                                                : new Date(inputs.endDate)
                                        }
                                        placeholder="Exit Date"
                                        handleDateChange={(name, date) => {
                                            setUpdateParent(false)
                                            handleDateChange(name, date)
                                        }}
                                        name="endDate"
                                        position="Right"
                                        openCalendar={
                                            showCalendar === 2 ? true : false
                                        }
                                        closeCalendarHandler={() =>
                                            setShowCalendar(null)
                                        }
                                        openCalendarHandler={() =>
                                            setShowCalendar(2)
                                        }
                                        setHandleDateChange={name =>
                                            setHandleDateChange(name)
                                        }
                                        errorMessage={
                                            errorMessages?.formId === formId
                                                ? errorMessages?.endDate
                                                : ''
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        <Checkbox
                            className={CheckBoxWrapper}
                            handleChecked={handleChecked}
                            isChecked={inputs.currentWork}
                        >
                            I am currently working in this role
                        </Checkbox>
                        <div style={{ margin: '20px 0px' }}>
                            <MultipleSelectField
                                values={techUsedData}
                                title={
                                    'Technologies used (Select as many as possible)'
                                }
                                handleMultipleSelectChange={value => {
                                    setUpdateParent(false)
                                    handleMultiSelect(value)
                                }}
                                selectWidth="95%"
                                dropDownWidth="538px"
                                inputValue={inputs.tech || formInputs?.tech}
                                errorMessage={
                                    errorMessages?.formId === formId
                                        ? errorMessages?.tech
                                        : ''
                                }
                                disabled={
                                    techUsedData?.length > 0 ? false : true
                                }
                            />
                        </div>
                        <TextArea
                            value={
                                inputs.description || formInputs?.description
                            }
                            name="description"
                            title="Project/Job Description"
                            rows={10}
                            height="150px"
                            width="100%"
                            handleChange={handleFormInputUpdate}
                            errorMessage={
                                errorMessages?.formId === formId
                                    ? errorMessages?.description
                                    : ''
                            }
                        />
                    </div>

                    <div className={SaveButton}>
                        {totalForm > 1 && (
                            <>
                                <Button
                                    btnBgColor="#FFE8EE"
                                    btnTextColor="#DA1E28"
                                    btnTextColorOutline="#DA1E28"
                                    btnOutlineColor="#FFE8EE"
                                    btnBgColorOutline="#FFE8EE"
                                    type="outline"
                                    btnWidth="123px"
                                    btnHeight="40px"
                                    handleClick={() =>
                                        formInputs?.updateId
                                            ? deleteModalHandler(
                                                  formInputs?.updateId,
                                              )
                                            : unSaveDeleteHandler(
                                                  formInputs?.formId,
                                              )
                                    }
                                >
                                    Remove
                                </Button>
                                &nbsp;&nbsp;
                            </>
                        )}
                        <Button
                            btnBgColor="var(--purple-light)"
                            btnTextColor="var(--blue)"
                            btnTextColorOutline="var(--blue)"
                            btnOutlineColor="var(--purple-light)"
                            btnBgColorOutline="#E2EEFF"
                            type="outline"
                            btnWidth="123px"
                            btnHeight="40px"
                            handleClick={e => saveExperienceHandler(e)}
                        >
                            {loading || updateLoading ? (
                                <InlineLoader />
                            ) : (
                                'Save Experience'
                            )}
                        </Button>
                    </div>
                </div>
            ) : (
                <>
                    <LabelTitle title={`Work Experience ${formPosition}`} />
                    <br />
                    <div className={CloseCard}>
                        {windowsWidth > 600 ? (
                            <>
                                <div className={Role}>{formInputs?.role}</div>
                                <div className={Period}>
                                    {format(
                                        new Date(formInputs?.startDate),
                                        'd MMM, yyyy',
                                    )}
                                    &nbsp; - &nbsp;
                                    {formInputs?.currentWork
                                        ? 'Present'
                                        : format(
                                              new Date(formInputs?.endDate),
                                              'd MMM, yyyy',
                                          )}
                                </div>
                            </>
                        ) : (
                            <div style={{ flex: 1 }}>
                                <div
                                    className={Role}
                                    style={{ margin: '0px', width: '100%' }}
                                >
                                    {formInputs?.role}
                                </div>
                                <div
                                    className={Period}
                                    style={{ margin: '0px', width: '100%' }}
                                >
                                    {format(
                                        new Date(formInputs?.startDate),
                                        'd MMM, yyyy',
                                    )}
                                    &nbsp; - &nbsp;
                                    {formInputs?.currentWork
                                        ? 'Present'
                                        : format(
                                              new Date(formInputs?.endDate),
                                              'd MMM, yyyy',
                                          )}
                                </div>
                            </div>
                        )}
                        <div className={Action}>
                            <span
                                className={`${ActionButton} ${EditActionButton}`}
                                onClick={() => editExperienceHandler()}
                            >
                                <EditIcon />
                            </span>
                            {totalForm > 1 && (
                                <span
                                    className={`${ActionButton} ${DeleteActionButton}`}
                                    onClick={() =>
                                        deleteModalHandler(formInputs?.updateId)
                                    }
                                >
                                    <DeleteIcon />
                                </span>
                            )}
                        </div>
                    </div>
                    <br />
                </>
            )}
        </>
    )
}

export default DataForm
