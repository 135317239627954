import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    page: '1',
    name: null,
    start_date: null,
    end_date: null,
    start_amount: null,
    end_amount: null,
    start_tenure: null,
    end_tenure: null,
    statuses: null,
    current_tab: '1',
    filter_mode: 'false',
}

const providerPaymentPlanListing = createSlice({
    name: 'providerPaymentPlanListingFilter',
    initialState,
    reducers: {
        updateProviderPaymentPlanFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetProviderPaymentPlanFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const {
    updateProviderPaymentPlanFilters,
    resetProviderPaymentPlanFilters,
} = providerPaymentPlanListing.actions

export const updatedProviderPaymentPlanFilters = state =>
    state.providerPaymentPlanFilter

const persistConfig = {
    key: 'providerPaymentPlanListingFilter',
    storage,
}

export const persistedProviderPaymentPlanListingReducer = persistReducer(
    persistConfig,
    providerPaymentPlanListing.reducer,
)

export default providerPaymentPlanListing.reducer
