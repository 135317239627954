// import React from 'react'
import PropTypes from 'prop-types'
import { Drawer, Slider } from 'antd'
import { DateField } from '../../../../../components/InputFields'
import { AppButton } from '../../../../../components/Button'
import RadioButton from '../../../../../components/RadioButton'
import { isMobile } from 'src/utils/mediaQueries'

const Filter = ({
    showDrawer,
    drawerVisible,
    inputs,
    handleDateChange,
    setHandleDateChange,
    showCalendar,
    setShowCalendar,
    packagesRange,
    setPackagesRange,
    componentsRange,
    setComponentsRange,
    handleAddFilter,
    globalAccess,
    setGlobalAccess,
    handleReset,
    clearGlobalAccess,
}) => {
    const maxFilterAmount = 100
    return (
        <div>
            <Drawer
                placement="right"
                width="367px"
                onClose={showDrawer}
                visible={drawerVisible}
                closable={isMobile}
                footerStyle={{
                    border: 'none',
                    padding: isMobile ? '' : '16px 16px 30px 16px',
                }}
                footer={
                    <div className="APLFilterButtonWrapper">
                        <AppButton
                            btnBgColor="#E2EEFF"
                            btnTextColor="#004AAD"
                            btnWidth={160}
                            btnHeight={56}
                            handleClick={() => handleReset()}
                        >
                            Reset Filter
                        </AppButton>
                        <AppButton
                            btnTextColor="#FFFFFF"
                            btnBgColor="#004AAD"
                            btnWidth={160}
                            btnHeight={56}
                            handleClick={() => handleAddFilter()}
                        >
                            Apply Filter
                        </AppButton>
                    </div>
                }
            >
                <h2 className="ARLFilterHeader">Filter Requests</h2>
                {/* filtering by date */}
                <h4 className="ARLDateFilterHeader">Filter By Created Date</h4>
                <div className="ARLDateFieldsWrapper">
                    <div className="ARLDateField">
                        <DateField
                            placeholder="Start Date"
                            name="startDate"
                            caretStyle="caretStyle"
                            dateValue={
                                inputs.startDate === null
                                    ? null
                                    : new Date(inputs.startDate)
                            }
                            handleDateChange={(name, date) =>
                                handleDateChange(name, date)
                            }
                            openCalendar={showCalendar === 1 ? true : false}
                            closeCalendarHandler={() => setShowCalendar(null)}
                            openCalendarHandler={() => setShowCalendar(1)}
                            setHandleDateChange={name =>
                                setHandleDateChange(name)
                            }
                        />
                    </div>

                    <div className="ARLDateField">
                        <DateField
                            placeholder="End Date"
                            name="endDate"
                            calendarClassName="AdminPLEndDateFilter"
                            caretStyle="caretStyle"
                            dateValue={
                                inputs.endDate === null
                                    ? null
                                    : new Date(inputs.endDate)
                            }
                            handleDateChange={(name, date) =>
                                handleDateChange(name, date)
                            }
                            openCalendar={showCalendar === 2 ? true : false}
                            closeCalendarHandler={() => setShowCalendar(null)}
                            openCalendarHandler={() => setShowCalendar(2)}
                            setHandleDateChange={name =>
                                setHandleDateChange(name)
                            }
                            placePopperRight
                        />
                    </div>
                </div>
                <h4 className="ARLStatementFilterHeader">Global Access</h4>
                <RadioButton
                    list={['User', 'Contributor', 'Both']}
                    onCheck={v => setGlobalAccess(v)}
                    clearSelected={clearGlobalAccess}
                    defaultChecked={globalAccess}
                />
                <div style={{ marginTop: 10 }}>
                    <h4 className="ARLStatementFilterHeader">No of Packages</h4>
                    <Slider
                        range={{ draggableTrack: true }}
                        defaultValue={[0, 0]}
                        max={maxFilterAmount}
                        value={packagesRange}
                        tooltipPlacement={'bottom'}
                        onChange={value => setPackagesRange(value)}
                        tipFormatter={value =>
                            `${new Intl.NumberFormat().format(value)}`
                        }
                    />
                </div>
                <div style={{ marginTop: 25 }}>
                    <h4 className="ARLStatementFilterHeader">
                        No of Components
                    </h4>
                    <Slider
                        range={{ draggableTrack: true }}
                        defaultValue={[0, 0]}
                        max={maxFilterAmount}
                        value={componentsRange}
                        tooltipPlacement={'bottom'}
                        onChange={value => setComponentsRange(value)}
                        tipFormatter={value =>
                            `${new Intl.NumberFormat().format(value)}`
                        }
                    />
                </div>
            </Drawer>
        </div>
    )
}

Filter.propTypes = {
    showDrawer: PropTypes.func,
    drawerVisible: PropTypes.any,
    inputs: PropTypes.any,
    handleDateChange: PropTypes.any,
    setHandleDateChange: PropTypes.func,
    showCalendar: PropTypes.number,
    setShowCalendar: PropTypes.func,
    packagesRange: PropTypes.array,
    setPackagesRange: PropTypes.func,
    componentsRange: PropTypes.array,
    setComponentsRange: PropTypes.func,
    handleAddFilter: PropTypes.func,
    globalAccess: PropTypes.string,
    setGlobalAccess: PropTypes.func,
    handleReset: PropTypes.func,
    clearGlobalAccess: PropTypes.bool,
}

export default Filter
