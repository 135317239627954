import { appTracking } from 'src/utils/appTracker'
import { consumerKYCEvents } from 'src/utils/mp-event-tracker/consumer-kyc'

export const pageTitle =
    'KYC - Overview | SunFi | Simplifying and Scaling Clean Energy'
export const pageUrlName = window.location.pathname
export const pageTracker = 'KYC_SSE'

export const handleAppTracking = event => {
    appTracking(
        pageUrlName,
        pageTracker,
        pageTitle,
        consumerKYCEvents[event].action,
        consumerKYCEvents[event].label,
        consumerKYCEvents[event].category,
        ['MP', 'GA'],
        'event',
    )
}
