import PropTypes from 'prop-types'
import FormTitleBar from 'src/components/FormTitleBar'
import s from './styles.module.scss'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import { InputFields } from 'src/components/InputFields'
import useAddOrEditBrand from './hooks/useAddOrEditBrand'
import { ReactComponent as CloseIcon } from 'src/assets/images/close-icon-no-radius-white.svg'
import ButtonComponent from 'src/components/Button'
import { InlineLoader } from 'src/components/Loader'

const AddOrEditBrand = ({
    actionType,
    componentType,
    brandNameArr = [],
    handleGoBack,
    handleSave,
    approvedBrands, // available brand names to pick from
    loading,
    approvedComponentBrands = [],
    pendingComponentBrands = [],
    module = '',
    handlePersistEdit,
    outlinedGoBackBtn,
}) => {
    const {
        localBrandName,
        handleBrandInputChange,
        handleBrandInputEnter,
        localBrandNameArr,
        removeBrandNameFromLocal,
        handleGoBackClick,
        handleSaveClick,
        error,
    } = useAddOrEditBrand(
        brandNameArr,
        handleGoBack,
        handleSave,
        approvedComponentBrands,
        pendingComponentBrands,
        module,
        actionType,
        handlePersistEdit,
    )

    return (
        <div>
            <FormTitleBar
                title={`${actionType} your ${componentType?.toLowerCase()} component brand`}
                subtitle="Enter your brands you currently work with"
                marginBottom={70}
            />
            <h4 className={s.inputTitle}>Enter your brand name</h4>
            <p className={s.inputSubTitle}>
                Select from the suggestion list or press enter on your keyboard
            </p>
            <VerticalSpacer top={actionType === 'Add' ? 11 : 24} />
            {actionType === 'Add' ? (
                <InputFields
                    title={'Enter a brand name'}
                    name={'localBrandName'}
                    value={localBrandName}
                    handleChange={handleBrandInputChange}
                    activateEnterClick
                    enterFunc={handleBrandInputEnter}
                    listId={'brandNames'}
                    dataList={approvedBrands}
                    errorMessage={error}
                />
            ) : null}
            {actionType === 'Edit' && <p className={s.errorMsg}>{error}</p>}
            <div
                className={s.pills}
                style={{ maxHeight: actionType === 'Add' ? 100 : 150 }}
            >
                {localBrandNameArr?.map((each, i) => (
                    <div key={i} className={s.pill}>
                        {each}
                        <CloseIcon
                            role={'button'}
                            onClick={() => removeBrandNameFromLocal(each)}
                        />
                    </div>
                ))}
            </div>
            <VerticalSpacer top={actionType === 'Add' ? 50 : 121} />
            <div className={s.btns}>
                <ButtonComponent
                    type={outlinedGoBackBtn ? 'outline' : null}
                    btnBgColor={outlinedGoBackBtn ? null : '#E2EEFF'}
                    btnBgColorOutline={outlinedGoBackBtn ? '#F9FCFF' : null}
                    border={outlinedGoBackBtn ? '0.5px solid #004AAD' : null}
                    btnTextColor={'#004AAD'}
                    btnTextColorOutline={outlinedGoBackBtn ? '#004AAD' : null}
                    btnWidth={'160px'}
                    btnHeight={'56px'}
                    handleClick={handleGoBackClick}
                >
                    Go back
                </ButtonComponent>
                <ButtonComponent
                    btnBgColor={'#004AAD'}
                    btnTextColor={'#FFFFFF'}
                    btnWidth={'160px'}
                    btnHeight={'56px'}
                    handleClick={handleSaveClick}
                    disabled={error !== ''}
                >
                    {loading ? <InlineLoader /> : 'Save this component'}
                </ButtonComponent>
            </div>
        </div>
    )
}

AddOrEditBrand.propTypes = {
    actionType: PropTypes.oneOf(['Add', 'Edit', '']),
    componentType: PropTypes.string,
    brandName: PropTypes.string,
    brandNameArr: PropTypes.array,
    handleGoBack: PropTypes.func,
    handleSave: PropTypes.func,
    approvedBrands: PropTypes.array,
    loading: PropTypes.bool,
    approvedComponentBrands: PropTypes.array,
    pendingComponentBrands: PropTypes.array,
    module: PropTypes.oneOf(['profile-component-brands-page', '']),
    handlePersistEdit: PropTypes.func,
    outlinedGoBackBtn: PropTypes.bool,
}

export default AddOrEditBrand
