import { useMutation, useQueryClient } from 'react-query'
import { persistOutrightSaleKycInfoApi } from 'src/api/shoppingExperience/kyc/outright-sale'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const usePersistOutrightSaleInfo = (
    saveOrSubmitAction,
    onSaveSuccess,
    onSubmitSuccess,
    setActionModalToast,
    resetToast,
) => {
    const queryClient = useQueryClient()
    const { errorAlert } = useCustomToast()

    const { mutate, isLoading } = useMutation({
        mutationFn: payload => persistOutrightSaleKycInfoApi(payload),
        onSuccess: () => {
            queryClient.invalidateQueries('fetch-outright-sale-kyc')
            queryClient.invalidateQueries('fetch-estimation')
            if (saveOrSubmitAction === 'Save') {
                onSaveSuccess()
            } else {
                onSubmitSuccess()
            }
        },
        onError: error => {
            if (saveOrSubmitAction === 'Save') {
                setActionModalToast({
                    showToast: true,
                    toastMessage:
                        errorHandler(error?.response?.data) ||
                        'An error occurred, please try again later.',
                    messageType: 'error-secondary',
                })
                resetToast()
            } else {
                errorAlert(
                    errorHandler(
                        error?.response?.data ||
                            'An error occurred, please try again later.',
                    ),
                )
            }
        },
    })

    return {
        persistInfoMutate: mutate,
        persistInfoLoading: isLoading,
    }
}

export default usePersistOutrightSaleInfo
