import { ReactComponent as Email } from 'src/assets/images/email-no-stroke.svg'
import { ReactComponent as Phone } from 'src/assets/images/phone-no-stroke.svg'
import { extractFileNameFromUrl } from 'src/utils/formatting'

export const formatBvnVerifyMethods = data => {
    const mapMethodToIcon = {
        email: <Email />,
        phone: <Phone />,
    }

    const updatedData = data.map(item => {
        if (
            !Object.prototype.hasOwnProperty.call(mapMethodToIcon, item.method)
        ) {
            return null
        }

        const icon = mapMethodToIcon[item.method]
        const emailOrPhone = item.hint.split(' ').slice(-1)[0]
        const title = 'Through ' + emailOrPhone
        const description = `An OTP will be sent to the ${item.method} linked to your BVN`
        const key = item.method

        return {
            title,
            description,
            key,
            icon,
        }
    })
    return updatedData.filter(Boolean)
}

export const formatBusinessVerificationData = data => {
    let result = {
        director_bvn: data?.financial_verification?.bvn?.number,
        director_bvn_verified:
            data?.financial_verification?.bvn?.verified || false,
        cac: [],
        moa: [],
        director_firstName: data?.consumer?.director_details?.first_name,
        director_lastName: data?.consumer?.director_details?.last_name,
        director_email: data?.consumer?.director_details?.email,
        director_phoneNumber: data?.consumer?.director_details?.phone_number,
    }

    if (data?.consumer?.business?.cac_certificate?.url) {
        result.cac.push({
            name: extractFileNameFromUrl(
                data?.consumer?.business?.cac_certificate?.url,
            ),
            url: data?.consumer?.business?.cac_certificate?.url,
        })
    }

    if (data?.consumer?.business?.memorandum_of_association?.url) {
        result.moa.push({
            name: extractFileNameFromUrl(
                data?.consumer?.business?.memorandum_of_association?.url,
            ),
            url: data?.consumer?.business?.memorandum_of_association?.url,
        })
    }
    return result
}
