import { useQuery } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { getAllInverterCapacities } from 'src/api/admin/profiles/manage-profile'

export const useGetInverterCapacities = ({
    sortChoice,
    setAvailableCapacities,
}) => {
    const { errorAlert } = useCustomToast()

    const {
        refetch: refetchInverterCapacities,
        isFetching: isLoadingInverterCapacities,
    } = useQuery({
        queryKey: [],
        queryFn: () => getAllInverterCapacities(sortChoice),
        retry: false,
        enabled: true,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        onSuccess: res => {
            const data = res.data

            if (data?.data) {
                const capacities = data.data.map(capacity => ({
                    name: `${capacity.energy_capacity}${capacity.unit_of_measurement}`,
                    value: JSON.stringify({
                        value: capacity.energy_capacity,
                        unit_of_measurement: capacity.unit_of_measurement,
                    }),
                }))

                if (setAvailableCapacities) {
                    setAvailableCapacities(capacities)
                }
            }
        },
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })

    return {
        refetchInverterCapacities,
        isLoadingInverterCapacities,
    }
}
