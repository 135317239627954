import { initGA } from './gaUtils.js'
import { initMixPanel } from './mixpanelUtils.js'

export const appTracking = (
    pageUrl,
    pageTracker,
    pageTitle,
    eventAction,
    eventLabel,
    eventCategory,
    products = ['GA', 'MP'],
    type = 'event',
    payload = {},
) => {
    const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID
    if (GA_TRACKING_ID && products.includes('GA')) {
        initGA(
            GA_TRACKING_ID,
            type,
            pageUrl,
            pageTracker,
            pageTitle,
            eventAction,
            eventLabel,
            eventCategory,
        )
    }

    const MIXPANEL_ID = process.env.REACT_APP_MIXPANEL_ID
    if (MIXPANEL_ID && products.includes('MP')) {
        initMixPanel(
            MIXPANEL_ID,
            type,
            pageUrl,
            pageTracker,
            pageTitle,
            eventAction,
            eventLabel,
            eventCategory,
            payload,
        )
    }
}
