import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { ConsumerButton } from 'src/components/Button'
import questionMarkIcon from 'src/assets/images/question-mark-circle-small.svg'

import styles from './profilecard.module.scss'
import CustomToolTip from 'src/components/CustomToolTip'
import { applianceIcon } from '../../data'
import { incrementCount } from 'src/redux/reducers/consumer/energy-profiles'
import { appTracking } from 'src/utils/appTracker'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'
import { visitCount } from 'src/utils/visitCount'
import { parseHTML } from 'src/utils/parseHTML'

const ProfileCard = ({
    id,
    title,
    description,
    specification,
    img,
    appliances,
}) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const isBusiness =
        history.location.pathname === '/consumer/business-profiles'
    const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = `View ${isBusiness ? 'BP' : 'RP'} listing`

    const handleViewProfile = () => {
        dispatch(incrementCount())
        visitCount(id, 'profile_listing_visits') > 0 &&
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                consumerProfileEvents(
                    `${visitCount(id, 'profile_listing_visits')} ${
                        isBusiness ? 'BPs' : 'RPs'
                    }`,
                )['viewProfileCount'].action,
                consumerProfileEvents(
                    `${visitCount(id, 'profile_listing_visits')} ${
                        isBusiness ? 'BPs' : 'RPs'
                    }`,
                ).label,
                consumerProfileEvents(
                    `${visitCount(id, 'profile_listing_visits')} ${
                        isBusiness ? 'BPs' : 'RPs'
                    }`,
                ).category,
                ['MP', 'GA'],
                'event',
            )
        history.push(`/consumer/profile-details/${id}`)
    }

    return (
        <div className={styles.Card}>
            <div className={styles.CardImageWrapper}>
                <img
                    className={styles.CardImage}
                    src={
                        img ||
                        'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/manufacter-installer.svg'
                    }
                    alt="consumer-profile-card-image"
                    role={'presentation'}
                />
                <div className={styles.CardImageOverlay}>
                    <h4>{title}</h4>
                </div>
            </div>
            <div className={styles.CardBody}>
                <p>
                    {description?.includes('<')
                        ? parseHTML(description)
                        : description}
                </p>
                <CardBodySection
                    title="Min and Max System Size"
                    info="This is the smallest and largest system size available in this profile"
                >
                    <h5>{specification}</h5>
                </CardBodySection>
                <CardBodySection
                    title="Suitable Appliances"
                    info="This list showcases an example set of appliances that this profile can power. 
                    You have the freedom to customize further based on your specific needs when you select your package."
                >
                    <div className={styles.PoweringEssentialsBox}>
                        {appliances.map((item, idx) => (
                            <EssentialsItem
                                key={idx}
                                icon={applianceIcon(item.name)}
                                content={`${item.quantity} ${item.name}`}
                            />
                        ))}
                    </div>
                </CardBodySection>
                <div className={styles.CardButtonWrapper}>
                    <ConsumerButton
                        secondaryHover
                        btnBgColor="#004AAD"
                        btnTextColor="#FFFFFF"
                        btnWidth={'100%'}
                        btnHeight={'56px'}
                        fontSize={'14px'}
                        handleClick={handleViewProfile}
                    >
                        View this profile
                    </ConsumerButton>
                </div>
            </div>
        </div>
    )
}

ProfileCard.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    specification: PropTypes.string,
    img: PropTypes.string,
    appliances: PropTypes.array,
}

export default ProfileCard

const CardBodySection = ({ title, info, children }) => {
    return (
        <div className={styles.CardBodySectionWrapper}>
            <div className={styles.CardBodyHeadingBox}>
                <h4>{title}</h4>
                <div className={styles.CardBodyHeadingIconBox}>
                    <CustomToolTip
                        arrowPositionLeft
                        text={info}
                        toolTipPopupWidth="194px"
                        toolTipMarginLeft={-30}
                        toolTipFontSize="12px"
                        toolTipPadding="15px 10px"
                    >
                        <img
                            src={questionMarkIcon}
                            alt="question-mark-circle"
                            role={'presentation'}
                        />
                    </CustomToolTip>
                </div>
            </div>
            {children}
        </div>
    )
}

CardBodySection.propTypes = {
    title: PropTypes.string,
    info: PropTypes.string,
    children: PropTypes.node,
}

const EssentialsItem = ({ content, icon }) => {
    return (
        <div className={styles.PoweringEssentialsItem}>
            <img src={icon} alt="icon" role={'presentation'} />
            <p>{content}(s)</p>
        </div>
    )
}

EssentialsItem.propTypes = {
    content: PropTypes.string,
    icon: PropTypes.string,
}
