import PropTypes from 'prop-types'
import CircularProgress from './components/CircularProgress'
import { statusIndicatorValues } from './data'
import './statusindicator.scss'
import CustomToolTip from '../CustomToolTip'
import { ReactComponent as InfoIcon } from 'src/assets/images/Info-warning-blue.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const StatusIndicator = ({
    status,
    arrowPositionLeft,
    arrowPositionCenter,
    toolTipMarginLeft,
    margin,
    page,
}) => {
    const {
        title,
        tooltipText,
        trailColor,
        pathColor,
        innerCircleColor,
        percentage,
    } = statusIndicatorValues(status, page)

    const { isMobile } = useMediaQueries()
    const effectiveArrowPositionLeft =
        typeof arrowPositionLeft === 'undefined' ? isMobile : arrowPositionLeft

    return (
        <div className="StatusIndicator_Wrapper" style={{ margin: margin }}>
            <CircularProgress
                trailColor={trailColor}
                pathColor={pathColor}
                percentage={percentage}
                innerCircleColor={innerCircleColor}
            />
            <p className="StatusIndicator_Title">{title}</p>
            <CustomToolTip
                text={tooltipText}
                toolTipFontSize="12px"
                toolTipMarginLeft={
                    toolTipMarginLeft
                        ? toolTipMarginLeft
                        : isMobile
                        ? '-18px'
                        : '-150px'
                }
                arrowPositionLeft={effectiveArrowPositionLeft}
                arrowPositionCenter={arrowPositionCenter}
                toolTipPopupWidth="174px"
                borderRadius="8px"
                background="#070C18"
                lineHeight="20px"
                tooltipZIndex="100"
                textAlign="left"
            >
                <div className="StatusIndicator_Tooltip">
                    <InfoIcon />
                </div>
            </CustomToolTip>
        </div>
    )
}

StatusIndicator.propTypes = {
    status: PropTypes.string,
    arrowPositionLeft: PropTypes.bool,
    arrowPositionCenter: PropTypes.bool,
    toolTipMarginLeft: PropTypes.string,
    margin: PropTypes.string,
    page: PropTypes.string,
}

export default StatusIndicator
