import { configureStore } from '@reduxjs/toolkit'
import {
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import { persistedAdminPaymentPlanListingReducer } from '../reducers/admin/payment-plan/listing'
import { persistedAdminProductsListingReducer } from '../reducers/admin/products/listing'
import { persistedAdminDTCReviewListingReducer } from '../reducers/admin/dtc-review/listing'
import { persistedAdminCreditSystemListingReducer } from '../reducers/admin/credit-system/listing'
import { persistedAdminUsersListingReducer } from '../reducers/admin/users/listing'
import { persistedAdminSettingsListingReducer } from '../reducers/admin/settings/listing'
import { persistedAdminProfilesListingReducer } from '../reducers/admin/profiles/listing'
import { persistedProfileViewReducer } from '../reducers/consumer/energy-profiles'
import { persistedBuildCustomSystemReducer } from '../reducers/consumer/build-custom-system'
import { persistedAdminOutrightSaleListingReducer } from '../reducers/admin/outright-sale/listing'
import { persistedProviderProductsListingReducer } from '../reducers/provider/products/listing'
import { persistedProviderCustomersListingReducer } from '../reducers/provider/customers/listing'
import { persistedProviderPaymentPlanListingReducer } from '../reducers/provider/payment-plan/listing'
import { persistedConsumerProfileReducer } from '../reducers/admin/profiles/create-profile'
import { persistedClusterRepMembersListingReducer } from '../reducers/representative/members/listing'

export const store = configureStore({
    reducer: {
        adminPaymentPlanListingFilter: persistedAdminPaymentPlanListingReducer,
        adminProductsFilter: persistedAdminProductsListingReducer,
        adminDTCReviewListingFilter: persistedAdminDTCReviewListingReducer,
        adminCreditSystemListingFilter:
            persistedAdminCreditSystemListingReducer,

        adminUsersListingFilter: persistedAdminUsersListingReducer,
        adminSettingsListingFilter: persistedAdminSettingsListingReducer,
        adminProfilesListingFilter: persistedAdminProfilesListingReducer,
        createconsumerProfileData: persistedConsumerProfileReducer,
        consumerProfileView: persistedProfileViewReducer,
        consumerBuildCustomSystem: persistedBuildCustomSystemReducer,
        adminOutrightSaleListingFilter:
            persistedAdminOutrightSaleListingReducer,
        providerProductsFilter: persistedProviderProductsListingReducer,
        providerCustomersFilter: persistedProviderCustomersListingReducer,
        providerPaymentPlanFilter: persistedProviderPaymentPlanListingReducer,
        clusterRepMembersListingFilter:
            persistedClusterRepMembersListingReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
})

export const persistor = persistStore(store)
