import { v4 as uuidv4 } from 'uuid'

// Dynamic Information Setup of Promo Flow
export const promoPackagesDetailsBank = [
    // uncomment this when we want to bring back dlight promo
    // {
    //     code: 'DLIGHT_LEASE_TO_OWN',
    //     img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/626+x+523+Yellow_v2.0.png',
    //     unique_id: uuidv4(),
    //     carousel_images: [
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/994+x+523+Yellow_v2.0.png',
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-2.png',
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-3.png',
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-4.png',
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-5.png',
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-6.png',
    //     ],
    //     name: 'Lease to Own',
    //     description:
    //         'This offers up to <b>18hrs</b> of electricity and can power your essentials: laptop, phones, fans, speakers, & routers.',
    //     amount: '25000',
    //     upfront: '25000',
    //     tenure: '24',
    //     delivery: [
    //         'Super affordable. Pay in installments over time to fully own.',
    //         'Noiseless',
    //     ],
    // },
    // {
    //     code: 'DLIGHT_SUBSCRIPTION',
    //     img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/626+x+523+Yellow_v2.0.png',
    //     unique_id: uuidv4(),
    //     carousel_images: [
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/994+x+523+Yellow_v2.0.png',
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-2.png',
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-3.png',
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-4.png',
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-5.png',
    //         'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-6.png',
    //     ],
    //     name: 'Subscription',
    //     description:
    //         'This offers up to <b>18hrs</b> of electricity and can power your essentials: laptop, phones, fans, speakers, & routers.',
    //     amount: '15000',
    //     delivery: [
    //         'No additional upfront costs.',
    //         'Prepaid pay-as-you-go rental.',
    //     ],
    // },

    {
        code: 'SURECHILL_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_fridge_.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_fridge.svg',
        ],
        name: 'Lease to Own',
        description:
            'The solar solution is a 65-litre solar-powered fridge with two units of 120W solar panels. No inverter or battery is required.',
        amount: '28000',
        upfront: '16000',
        tenure: '24',
        delivery: [
            'Pay in installments over a period of time to fully own.',
            'Occupies less space.',
        ],
    },
    {
        code: 'SURECHILL_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_fridge_.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_fridge.svg',
        ],
        name: 'Subscription',
        description:
            'The solar solution is a 65-litre solar-powered fridge with two units of 120W solar panels. No inverter or battery is required.',
        amount: '13609.70',
        delivery: [
            'Super affordable. Operates on a pay-as-you-go payment model.',
            'Occupies less space.',
        ],
    },
    {
        code: 'ARNERGY_5000_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_5k+Anergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_5k+Anergy.svg',
        ],
        name: 'ARN 5000 L20',
        description:
            'This solution is a complete 5kVA microgrid solar solution that comes with one 5kW inverter, 10.8kWh (2*5.4kWh) lithium-ion (LiFePO₄) batteries, 4.62kWp (12*385W) monocrystalline solar panels.',
        amount: '230205.54',
        upfront: '441939.40',
        tenure: '24',
        delivery: [
            'Affordable. Pay in instalments over a period to fully own.',
            'Perfectly suited for professional use. Well-adapted to power businesses needs.',
        ],
    },
    {
        code: 'ARNERGY_5000_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_5k+Anergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_5k+Anergy.svg',
        ],
        name: 'ARN 5000 SUBSCRIPTION',
        description:
            'This solution is a complete 5kVA microgrid solar solution that comes with one 5kW inverter, 10.8kWh (2*5.4kWh) lithium-ion (LiFePO₄) batteries, 4.62kWp (12*385W) monocrystalline solar panels.',
        amount: '116622.90',
        delivery: [
            'Super affordable. Operates on a pay-as-you-go payment model.',
            'Perfectly suited for professional use. Well-adapted to power businesses needs.',
        ],
    },
    {
        code: 'ARNERGY_5000X_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_5k+Anergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_5k+Anergy.svg',
        ],
        name: 'ARN 5000X L20',
        description:
            'This solution is a complete 5kVA microgrid solar solution that comes with one 5kW inverter, 10.8kWh (2*5.4kWh) lithium-ion (LiFePO₄) batteries, 4.62kWp (12*385W) monocrystalline solar panels.',
        amount: '136623.43',
        upfront: '262284.20',
        tenure: '24',
        delivery: [
            'Affordable. Pay in instalments over a period to fully own.',
            'Perfectly suited for professional use. Well-adapted to power businesses needs.',
        ],
    },
    {
        code: 'ARNERGY_5000X_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_5k+Anergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_5k+Anergy.svg',
        ],
        name: 'ARN 5000X SUBSCRIPTION',
        description:
            'This solution is a complete 3kVA microgrid solar solution that comes with one 5kW inverter, one 5.4kWh lithium-ion (LiFePO₄) battery, 2.31kWp (6*385W) monocrystalline solar panel.',
        amount: '69213.89',
        delivery: [
            'Super affordable. Operates on a pay-as-you-go payment model.',
            'Perfectly suited for professional use. Well-adapted to power businesses needs.',
        ],
    },
    {
        code: 'RENERGY_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_1kVA+Renergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200+1kVA+Renergy.svg',
        ],
        name: 'RENERGY',
        description:
            'The solar solution is a 1kVA Inverter with two 150w Solar panels, a 30A Charge controller and a 200Wh Tubular battery that can be charged with a grid or generator. It powers 6 Bulbs, one Fan, Phones and 32 inch TV',
        amount: '38675.87',
        upfront: '153000',
        tenure: '12',
        delivery: [
            'Affordable. Pay in instalments over a period to fully own.',
            'Noiseless',
        ],
    },

    // PBN
    {
        code: 'PBN_DLIGHT_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/626+x+523+Yellow_v2.0.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/994+x+523+Yellow_v2.0.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-2.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-3.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-4.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-5.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-6.png',
        ],
        name: '500W - Lease to Own',
        description:
            'This offers up to <b>18hrs</b> of electricity and can power your essentials: laptop, phones, fans, small TV, speakers, & routers.',
        amount: '25000',
        upfront: '25000',
        tenure: '24',
        delivery: [
            'Super affordable. Pay in installments over time to fully own.',
            'Noiseless',
        ],
    },
    {
        code: 'PBN_DLIGHT_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/626+x+523+Yellow_v2.0.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/994+x+523+Yellow_v2.0.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-2.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-3.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-4.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-5.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-6.png',
        ],
        name: '500W - Subscription',
        description:
            'This offers up to <b>18hrs</b> of electricity and can power your essentials: laptop, phones, fans, small TV, speakers, & routers.',
        amount: '15000',
        delivery: [
            'Zero upfront cost',
            'Super affordable. Operates on a pay-as-you-go plan.',
            'Noiseless',
        ],
    },

    {
        code: 'PBN_SURECHILL_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_fridge_.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_fridge.svg',
        ],
        name: '65L Solar Fridge - Lease to Own',
        description:
            'The solar solution is a 65-litre solar-powered fridge with two units of 120W solar panels. No inverter or battery is required.',
        amount: '28000',
        upfront: '16000',
        tenure: '24',
        delivery: [
            'Pay in installments over a period of time to fully own.',
            'Occupies less space.',
        ],
    },
    {
        code: 'PBN_SURECHILL_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_fridge_.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_fridge.svg',
        ],
        name: '65L Solar Fridge - Subscription',
        description:
            'The solar solution is a 65-litre solar-powered fridge with two units of 120W solar panels. No inverter or battery is required.',
        amount: '13609.70',
        delivery: [
            'Super affordable. Operates on a pay-as-you-go plan.',
            'Zero upfront cost',
            'Occupies less space.',
        ],
    },
    {
        code: 'PBN_RENERGY_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_1kVA+Renergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200+1kVA+Renergy.svg',
        ],
        name: '1kVA Solar - Lease to Own',
        description:
            'The solar solution is a 1kVA Inverter with two 150W Solar panels, a 30A Charge controller, and a 200Ah Tubular battery that can be charged with the grid or a generator. It powers six Bulbs, one Fan, Phones, and a 32-inch TV.',
        amount: '37917.52',
        upfront: '150000',
        tenure: '12',
        delivery: [
            'Affordable. Pay in instalments over a period to fully own.',
            'Noiseless',
        ],
    },

    //VP
    {
        code: 'VP_DLIGHT_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/626+x+523+Yellow_v2.0.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/994+x+523+Yellow_v2.0.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-2.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-3.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-4.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-5.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-6.png',
        ],
        name: '500W - Lease to Own',
        description:
            'This offers up to <b>18hrs</b> of electricity and can power your essentials: laptop, phones, fans, small TV, speakers, & routers.',
        amount: '25000',
        upfront: '25000',
        tenure: '24',
        delivery: [
            'Super affordable. Pay in installments over time to fully own.',
            'Noiseless',
        ],
    },
    {
        code: 'VP_DLIGHT_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/626+x+523+Yellow_v2.0.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/994+x+523+Yellow_v2.0.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-2.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-3.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-4.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-5.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-6.png',
        ],
        name: '500W - Subscription',
        description:
            'This offers up to <b>18hrs</b> of electricity and can power your essentials: laptop, phones, fans, small TV, speakers, & routers.',
        amount: '15000',
        delivery: [
            'Zero upfront cost',
            'Super affordable. Operates on a pay-as-you-go plan.',
            'Noiseless',
        ],
    },

    {
        code: 'VP_SURECHILL_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_fridge_.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_fridge.svg',
        ],
        name: '65L Solar Fridge - Lease to Own',
        description:
            'The solar solution is a 65-litre solar-powered fridge with two units of 120W solar panels. No inverter or battery is required.',
        amount: '28000',
        upfront: '16000',
        tenure: '24',
        delivery: [
            'Pay in installments over a period of time to fully own.',
            'Occupies less space.',
        ],
    },
    {
        code: 'VP_SURECHILL_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_fridge_.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_fridge.svg',
        ],
        name: '65L Solar Fridge - Subscription',
        description:
            'The solar solution is a 65-litre solar-powered fridge with two units of 120W solar panels. No inverter or battery is required.',
        amount: '13609.70',
        delivery: [
            'Super affordable. Operates on a pay-as-you-go plan.',
            'Zero upfront cost',
            'Occupies less space.',
        ],
    },
    {
        code: 'VP_RENERGY_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_1kVA+Renergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200+1kVA+Renergy.svg',
        ],
        name: '1kVA Solar - Lease to Own',
        description:
            'The solar solution is a 1kVA Inverter with two 150W Solar panels, a 30A Charge controller, and a 200Ah Tubular battery that can be charged with the grid or a generator. It powers six Bulbs, one Fan, Phones, and a 32-inch TV.',
        amount: '37917.52',
        upfront: '150000',
        tenure: '12',
        delivery: [
            'Affordable. Pay in instalments over a period to fully own.',
            'Noiseless',
        ],
    },

    // Damilola-Ola
    {
        code: 'DAMI_DLIGHT_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/626+x+523+Yellow_v2.0.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/994+x+523+Yellow_v2.0.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-2.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-3.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-4.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-5.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-6.png',
        ],
        name: '500W - Lease to Own',
        description:
            'This offers up to <b>18hrs</b> of electricity and can power your essentials: laptop, phones, fans, small TV, speakers, & routers.',
        amount: '25000',
        upfront: '25000',
        tenure: '24',
        delivery: [
            'Super affordable. Pay in installments over time to fully own.',
            'Noiseless',
        ],
    },
    {
        code: 'DAMI_DLIGHT_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/626+x+523+Yellow_v2.0.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/994+x+523+Yellow_v2.0.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-2.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-3.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-4.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-5.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-6.png',
        ],
        name: '500W - Subscription',
        description:
            'This offers up to <b>18hrs</b> of electricity and can power your essentials: laptop, phones, fans, small TV, speakers, & routers.',
        amount: '15000',
        delivery: [
            'Zero upfront cost',
            'Super affordable. Operates on a pay-as-you-go plan.',
            'Noiseless',
        ],
    },

    {
        code: 'DAMI_SURECHILL_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_fridge_.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_fridge.svg',
        ],
        name: '65L Solar Fridge - Lease to Own',
        description:
            'The solar solution is a 65-litre solar-powered fridge with two units of 120W solar panels. No inverter or battery is required.',
        amount: '28000',
        upfront: '16000',
        tenure: '24',
        delivery: [
            'Pay in installments over a period of time to fully own.',
            'Occupies less space.',
        ],
    },
    {
        code: 'DAMI_SURECHILL_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_fridge_.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_fridge.svg',
        ],
        name: '65L Solar Fridge - Subscription',
        description:
            'The solar solution is a 65-litre solar-powered fridge with two units of 120W solar panels. No inverter or battery is required.',
        amount: '13609.70',
        delivery: [
            'Super affordable. Operates on a pay-as-you-go plan.',
            'Zero upfront cost',
            'Occupies less space.',
        ],
    },
    {
        code: 'DAMI_RENERGY_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_1kVA+Renergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200+1kVA+Renergy.svg',
        ],
        name: '1kVA Solar - Lease to Own',
        description:
            'The solar solution is a 1kVA Inverter with two 150W Solar panels, a 30A Charge controller, and a 200Ah Tubular battery that can be charged with the grid or a generator. It powers six Bulbs, one Fan, Phones, and a 32-inch TV.',
        amount: '37917.52',
        upfront: '150000',
        tenure: '12',
        delivery: [
            'Affordable. Pay in instalments over a period to fully own.',
            'Noiseless',
        ],
    },

    // CLAFIYA
    {
        code: 'CLAFIYA_DLIGHT_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/626+x+523+Yellow_v2.0.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/994+x+523+Yellow_v2.0.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-2.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-3.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-4.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-5.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-6.png',
        ],
        name: '500W - Lease to Own',
        description:
            'This offers up to <b>18hrs</b> of electricity and can power your essentials: laptop, phones, fans, small TV, speakers, & routers.',
        amount: '25000',
        upfront: '25000',
        tenure: '24',
        delivery: [
            'Super affordable. Pay in installments over time to fully own.',
            'Noiseless',
        ],
    },
    {
        code: 'CLAFIYA_DLIGHT_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/626+x+523+Yellow_v2.0.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/994+x+523+Yellow_v2.0.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-2.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-3.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-4.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-5.png',
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Slider-380-x-200Card-6.png',
        ],
        name: '500W - Subscription',
        description:
            'This offers up to <b>18hrs</b> of electricity and can power your essentials: laptop, phones, fans, small TV, speakers, & routers.',
        amount: '15000',
        delivery: [
            'Zero upfront cost',
            'Super affordable. Operates on a pay-as-you-go plan.',
            'Noiseless',
        ],
    },

    {
        code: 'CLAFIYA_SURECHILL_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_fridge_.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_fridge.svg',
        ],
        name: '65L Solar Fridge - Lease to Own',
        description:
            'The solar solution is a 65-litre solar-powered fridge with two units of 120W solar panels. No inverter or battery is required.',
        amount: '28000',
        upfront: '16000',
        tenure: '24',
        delivery: [
            'Pay in installments over a period of time to fully own.',
            'Occupies less space.',
        ],
    },
    {
        code: 'CLAFIYA_SURECHILL_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_fridge_.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_fridge.svg',
        ],
        name: '65L Solar Fridge - Subscription',
        description:
            'The solar solution is a 65-litre solar-powered fridge with two units of 120W solar panels. No inverter or battery is required.',
        amount: '13609.70',
        delivery: [
            'Super affordable. Operates on a pay-as-you-go plan.',
            'Zero upfront cost',
            'Occupies less space.',
        ],
    },
    {
        code: 'CLAFIYA_RENERGY_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_1kVA+Renergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200+1kVA+Renergy.svg',
        ],
        name: '1kVA Solar - Lease to Own',
        description:
            'The solar solution is a 1kVA Inverter with two 150W Solar panels, a 30A Charge controller, and a 200Ah Tubular battery that can be charged with the grid or a generator. It powers six Bulbs, one Fan, Phones, and a 32-inch TV.',
        amount: '37917.52',
        upfront: '150000',
        tenure: '12',
        delivery: [
            'Affordable. Pay in instalments over a period to fully own.',
            'Noiseless',
        ],
    },

    // BETKING
    {
        code: 'BETKING_SUNKING_LEASE_TO_OWN_A',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sunking_326x350.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sunking_1020x537.png',
        ],
        name: 'HOME 8000 - L20 - A',
        description:
            'This solar solution has a long-lasting 2.56kWh lithium ferro-phosphate battery, a 2000W inverter, and a 900W or 1800W solar panel. It is built to last and offers great value for your money.<br/>It powers the following appliances: light bulbs, TVs, refrigerators, speakers, electric fans, mobile phones, and laptops.',
        amount: '174881',
        upfront: '275940',
        tenure: '12',
        delivery: [
            'Affordable: You can pay in instalments to eventually own it.',
            'It comes with a five-year warranty.',
            'It can be charged using solar power or a regular grid connection.',
        ],
    },
    {
        code: 'BETKING_SUNKING_LEASE_TO_OWN_B',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sunking_326x350.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sunking_1020x537.png',
        ],
        name: 'HOME 8000 - L20 - B',
        description:
            'This solar solution has a long-lasting 2.56kWh lithium ferro-phosphate battery, a 2000W inverter, and a 900W or 1800W solar panel. It is built to last and offers great value for your money.<br/>It powers the following appliances: light bulbs, TVs, refrigerators, speakers, electric fans, mobile phones, and laptops.',
        amount: '96211.05',
        upfront: '275940',
        tenure: '24',
        delivery: [
            'Affordable: You can pay in instalments to eventually own it.',
            'It comes with a five-year warranty.',
            'It can be charged using solar power or a regular grid connection.',
        ],
    },
    {
        code: 'BETKING_SUNKING_SUBSCRIPTION_A',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sunking_326x350.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sunking_1020x537.png',
        ],
        name: 'HOME 8000 - Rental - A',
        description:
            'This solar solution has a long-lasting 2.56kWh lithium ferro-phosphate battery, a 2000W inverter, and a 900W or 1800W solar panel. It is built to last and offers great value for your money.<br/>It powers the following appliances: light bulbs, TVs, refrigerators, speakers, electric fans, mobile phones, and laptops.',
        amount: '52030',
        delivery: [
            'Super affordable: Operates on a pay-as-you-go plan.',
            'It comes with a five-year warranty.',
            'It can be charged using solar power or a regular grid connection.',
            'Zero upfront cost',
        ],
    },
    {
        code: 'BETKING_SUNKING_SUBSCRIPTION_B',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sunking_326x350.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sunking_1020x537.png',
        ],
        name: 'HOME 8000 - Rental - B',
        description:
            'This solar solution has a long-lasting 2.56kWh lithium ferro-phosphate battery, a 2000W inverter, and a 900W or 1800W solar panel. It is built to last and offers great value for your money.<br/>It powers the following appliances: light bulbs, TVs, refrigerators, speakers, electric fans, mobile phones, and laptops.',
        amount: '47300',
        delivery: [
            'Super affordable: Operates on a pay-as-you-go plan.',
            'It comes with a five-year warranty.',
            'It can be charged using solar power or a regular grid connection.',
            'Zero upfront cost',
        ],
    },
    {
        code: 'BETKING_SUNKING_SUBSCRIPTION_C',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sunking_326x350.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sunking_1020x537.png',
        ],
        name: 'HOME 8000 - Rental - C',
        description:
            'This solar solution has a long-lasting 2.56kWh lithium ferro-phosphate battery, a 2000W inverter, and a 900W or 1800W solar panel. It is built to last and offers great value for your money.<br/>It powers the following appliances: light bulbs, TVs, refrigerators, speakers, electric fans, mobile phones, and laptops.',
        amount: '43000',
        delivery: [
            'Super affordable: Operates on a pay-as-you-go plan.',
            'It comes with a five-year warranty.',
            'It can be charged using solar power or a regular grid connection.',
            'Zero upfront cost',
        ],
    },

    // A2EI
    {
        code: 'BETKING_A2EI_LEASE_TO_OWN_A',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/a2ei_326x350.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/a2ei_1020x537.png',
        ],
        name: 'HOME 8000 - L20 - A',
        description:
            'This solar solution comes with a long-lasting 50Ah lithium ferro phosphate battery, a 3000W inverter, and an 800W or 1200W solar panel. It powers the following appliances: light bulbs, TVs, decoders, refrigerators, speakers, electric fans, mobile phones, and laptops.',
        amount: '224449',
        upfront: '373770',
        tenure: '12',
        delivery: [
            'Affordable: You can pay in instalments to eventually own it.',
            'It comes with a five-year warranty.',
        ],
    },
    {
        code: 'BETKING_A2EI_LEASE_TO_OWN_B',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/a2ei_326x350.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/a2ei_1020x537.png',
        ],
        name: 'AAM3000 - L20 - B',
        description:
            'This solar solution comes with a long-lasting 50Ah lithium ferro phosphate battery, a 3000W inverter, and an 800W or 1200W solar panel. It powers the following appliances: light bulbs, TVs, decoders, refrigerators, speakers, electric fans, mobile phones, and laptops.',
        amount: '131211.19',
        upfront: '373770',
        tenure: '24',
        delivery: [
            'Affordable: You can pay in instalments to eventually own it.',
            'It comes with a five-year warranty.',
        ],
    },
    {
        code: 'BETKING_A2EI_SUBSCRIPTION_A',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/a2ei_326x350.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/a2ei_1020x537.png',
        ],
        name: 'AAM3000 - Rental - A',
        description:
            'This solar solution comes with a long-lasting 50Ah lithium ferro phosphate battery, a 3000W inverter, and an 800W or 1200W solar panel. It powers the following appliances: light bulbs, TVs, decoders, refrigerators, speakers, electric fans, mobile phones, and laptops.',
        amount: '59512.50',
        delivery: [
            'Super affordable: Operates on a pay-as-you-go plan.',
            'It comes with a five-year warranty.',
            'Zero upfront cost',
        ],
    },
    {
        code: 'BETKING_A2EI_SUBSCRIPTION_B',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/a2ei_326x350.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/a2ei_1020x537.png',
        ],
        name: 'AAM3000 - Rental - B',
        description:
            'This solar solution comes with a long-lasting 50Ah lithium ferro phosphate battery, a 3000W inverter, and an 800W or 1200W solar panel. It powers the following appliances: light bulbs, TVs, decoders, refrigerators, speakers, electric fans, mobile phones, and laptops.',
        amount: '51750',
        delivery: [
            'Super affordable: Operates on a pay-as-you-go plan.',
            'It comes with a five-year warranty.',
            'Zero upfront cost',
        ],
    },
    {
        code: 'BETKING_A2EI_SUBSCRIPTION_C',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/a2ei_326x350.png',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/a2ei_1020x537.png',
        ],
        name: 'AAM3000 - Rental - C',
        description:
            'This solar solution comes with a long-lasting 50Ah lithium ferro phosphate battery, a 3000W inverter, and an 800W or 1200W solar panel. It powers the following appliances: light bulbs, TVs, decoders, refrigerators, speakers, electric fans, mobile phones, and laptops.',
        amount: '45000',
        delivery: [
            'Super affordable: Operates on a pay-as-you-go plan.',
            'It comes with a five-year warranty.',
            'Zero upfront cost',
        ],
    },
    // Arnergy
    {
        code: 'BETKING_ARNERGY_5000_LEASE_TO_OWN',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_5k+Anergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_5k+Anergy.svg',
        ],
        name: '5000 - Lease to Own',
        description:
            'This solution is a complete 5kVA microgrid solar solution that comes with one 5kW inverter, 10.8kWh (2*5.4kWh) lithium-ion (LiFePO₄) batteries, 4.62kWp (12*385W) monocrystalline solar panels.<br/>It is estimated to power the following appliances in an office building: Lighting (LEDs recommended), two 56 inches televisions, Projectors, Four standing fans, a Refrigerator, a Dispenser, a Microwave, an Air conditioner, Laptops and desktops.',
        amount: '310075.59',
        upfront: '662262.50',
        tenure: '24',
        delivery: [
            'Affordable: You can pay in instalments to eventually own it.',
            'It comes with a five-year warranty, twelve months of free maintenance, and a real-time online energy management platform.',
            'Well-adapted to power businesses energy needs',
        ],
    },
    {
        code: 'BETKING_ARNERGY_5000_SUBSCRIPTION',
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/152x127_5k+Anergy.svg',
        unique_id: uuidv4(),
        carousel_images: [
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/379x200_5k+Anergy.svg',
        ],
        name: '5000 - Subscription',
        description:
            'This solution is a complete 5kVA microgrid solar solution that comes with one 5kW inverter, 10.8kWh (2*5.4kWh) lithium-ion (LiFePO₄) batteries, 4.62kWp (12*385W) monocrystalline solar panels.<br/>It is estimated to power the following appliances in an office building: Lighting (LEDs recommended), two 56 inches televisions, Projectors, Four standing fans, a Refrigerator, a Dispenser, a Microwave, an Air conditioner, Laptops and desktops.',
        amount: '179000',
        delivery: [
            'Super affordable. Operates on a pay-as-you-go payment model.',
            'It comes with a five-year warranty, twelve months of free maintenance, and a real-time online energy management platform.',
            'Well-adapted to power businesses energy needs',
        ],
    },
]

const promoPackagesSuite = JSON.parse(
    process.env.REACT_APP_PROMO_PACKAGES_SUITE || '[]',
)

export const promos = [
    {
        id: 1,
        title: 'D.Light Promo',
        mobileImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Delight+promo+Mobile.png',
        desktopImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Delight+promo.png',
        link: '',
        type: 'dlight',
        active: false,
    },
    {
        id: 2,
        title: 'SureChill Promo',
        mobileImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Surechill+promo++Mobile.png',
        desktopImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Surechill+promo.png',
        link: '',
        type: 'surechill',
        active: false,
    },
    {
        id: 3,
        title: 'Renergy Promo',
        mobileImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Renergy+promo+Mobile.png',
        desktopImgSrc:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Banner+4.png',
        link: '',
        type: 'renergy',
        active: false,
    },
]

export const promoPackages = promoType => {
    const promoPackagesSuiteFilter = promoPackagesSuite.filter(
        promoPackage => promoPackage.type === promoType,
    )

    return promoPackagesDetailsBank
        .filter(packageDetails =>
            promoPackagesSuiteFilter.some(
                promoPackage => promoPackage.code === packageDetails.code,
            ),
        )
        .map(({ code, unique_id, ...rest }) => {
            const { id, clusterCode } =
                promoPackagesSuiteFilter.find(
                    promoPackage => promoPackage.code === code,
                ) || {}
            return { package_id: id, id: unique_id, clusterCode, ...rest }
        })
}

export const activePromos = promos.filter(promo => promo.active)
export const REGISTERED_SSE_PROMO_TYPES = activePromos.map(promo => promo.type)

export const promoBanners = {
    dlight: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Dlight+Banner.png',
    surechill:
        'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Surechill+Banner.png',
    renergy:
        'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Renergy+Banner.png',
}
