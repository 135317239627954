import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import s from '../profilesdetails.module.scss'
import PropTypes from 'prop-types'
import { extractFileNameFromUrl, titleCase } from 'src/utils/formatting'
import { ReactComponent as DownLoadIcon } from 'src/assets/images/download.svg'

const ClusterInformation = ({ data, buttonDisabled }) => {
    const history = useHistory()
    return (
        <div className={s.cluster_info_wrapper}>
            <div>
                <h3>
                    {data?.promotional_type?.toLowerCase() === 'private'
                        ? data?.cluster_origination?.name
                        : data?.name}
                </h3>
                <button
                    onClick={() => {
                        data?.promotional_type?.toLowerCase() === 'public'
                            ? history.push(
                                  `/admin/consumer-profiles/edit-cluster-banner/${data?.id}`,
                              )
                            : history.push(
                                  `/admin/consumer-profiles/edit-cluster-information/${data?.id}`,
                              )
                    }}
                    disabled={buttonDisabled}
                    style={{
                        cursor: buttonDisabled ? 'not-allowed' : 'pointer',
                    }}
                >
                    {data?.promotional_type?.toLowerCase() === 'public'
                        ? 'Update cluster banner'
                        : 'Edit cluster information'}
                </button>
            </div>
            {data?.promotional_type?.toLowerCase() === 'private' && (
                <div>
                    <h3>Cluster email domain</h3>
                    <a
                        href={data?.cluster_origination?.website}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {data?.cluster_origination?.website}
                    </a>
                </div>
            )}
            {data?.promotional_type?.toLowerCase() === 'private' && (
                <div>
                    <h3>Cluster representatives</h3>
                    <p>{data?.cluster_origination?.representative_count}</p>
                </div>
            )}
            <div>
                <h3>Cluster members</h3>
                <p>{data?.cluster_origination?.members_count}</p>
            </div>
            {data?.promotional_type?.toLowerCase() === 'public' && (
                <div>
                    <h3>Cluster banner</h3>
                    <a
                        href={data?.meta_data?.image_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        className={s.cluster_banner_info}
                    >
                        <DownLoadIcon />
                        {titleCase(
                            extractFileNameFromUrl(data?.meta_data?.image_url),
                        )}
                    </a>
                </div>
            )}
        </div>
    )
}

ClusterInformation.propTypes = {
    data: PropTypes.object.isRequired,
    buttonDisabled: PropTypes.bool,
}

export default ClusterInformation
