import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import styles from './accounttypemodal.module.scss'
import { accountTypeOptions } from '../../data'

const AccountTypeModal = ({
    showModal,
    onCancel,
    handleClick,
    accountType,
    setAccountType,
}) => {
    const { wrapper, type, typeActive, typeIcon, typeText } = styles
    return (
        <SecondaryModal
            showModal={showModal}
            onCancel={onCancel}
            v2
            showFooter
            modalWidth={476}
            title="Select account type"
            content={
                <div className={wrapper}>
                    {accountTypeOptions?.map((each, i) => {
                        const typeClass =
                            each?.key === accountType ? typeActive : ''
                        const icon =
                            each?.key === accountType
                                ? each?.activeIcon
                                : each?.inactiveIcon
                        return (
                            <div
                                key={i}
                                role="button"
                                className={`${type} ${typeClass}`}
                                onClick={() => setAccountType(each?.key)}
                            >
                                <div className={typeIcon}>{icon}</div>
                                <div className={typeText}>
                                    <h4>{each?.title}</h4>
                                    <p>{each?.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            primaryBtnText={'Continue'}
            handlePrimaryBtnClick={handleClick}
            primaryBtnDisabled={accountType === ''}
        />
    )
}

AccountTypeModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    handleClick: PropTypes.func,
    accountType: PropTypes.string,
    setAccountType: PropTypes.func,
}

export default AccountTypeModal
