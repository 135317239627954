import PropTypes from 'prop-types'
import ImageOptions from './ImageOptions'
import UploadImage from './UploadImage'

const IdentityModal = ({
    step,
    selected,
    setSelected,
    webcamRef,
    successUpload,
    setSuccessUpload,
    pictureUrl,
    setPictureUrl,
    uploading,
    width,
    setWidth,
    IdentitySelfieRef
}) => {
    return (
        <div className="IdentityModalWrapper">
            {step > 1 ? (
                <UploadImage
                    step={step}
                    selected={selected}
                    webcamRef={webcamRef}
                    successUpload={successUpload}
                    setSuccessUpload={setSuccessUpload}
                    pictureUrl={pictureUrl}
                    setPictureUrl={setPictureUrl}
                    uploading={uploading}
                    width={width}
                    setWidth={setWidth}
                    IdentitySelfieRef={IdentitySelfieRef}
                />
            ) : (
                <ImageOptions selected={selected} setSelected={setSelected} />
            )}
        </div>
    )
}

IdentityModal.propTypes = {
    step: PropTypes.number.isRequired,
    selected: PropTypes.string.isRequired,
    setSelected: PropTypes.func.isRequired,
    webcamRef: PropTypes.any.isRequired,
    IdentitySelfieRef: PropTypes.any.isRequired,
    successUpload: PropTypes.any.isRequired,
    setSuccessUpload: PropTypes.func.isRequired,
    pictureUrl: PropTypes.string.isRequired,
    setPictureUrl: PropTypes.func.isRequired,
    uploading: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    setWidth: PropTypes.func.isRequired,
}

export default IdentityModal
