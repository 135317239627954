import { uploadToS3 } from 'src/api/utils/file-upload'
import { useMutation } from 'react-query'

const useUploadEvidenceToS3 = (file, onSuccess, onError) => {
    const mutationFn = ({ actionKeyID, file }) => {
        return uploadToS3(actionKeyID, file)
    }

    const { mutate, isLoading } = useMutation(mutationFn, {})
    const uploadEvidenceMutate = actionKeyID => {
        mutate(
            {
                actionKeyID,
                file,
            },
            {
                onSuccess: data => {
                    const s3Url = data?.url + data?.fields?.key
                    onSuccess(s3Url, actionKeyID)
                },
                onError: err => {
                    onError(err)
                },
            },
        )
    }

    return {
        uploadEvidenceMutate,
        uploadEvidenceLoading: isLoading,
    }
}

export default useUploadEvidenceToS3
