export const alternateStatusOptions = [
    {
        value: 'Awaiting solution',
    },
    {
        value: 'Solution provided',
    },
]

export const mapStatusValue = {
    'Solution provided':
        'SSE_CONSUMER_DROP_OFF_RECEIVED_MANUAL_RECOMMENDATIONS_FROM_ADMIN',
    'Awaiting solution': 'SSE_CONSUMER_DROP_OFF_REQUEST_MANUAL_RECOMMENDATION',
}

export const reverseStatusMap = {
    SSE_CONSUMER_DROP_OFF_RECEIVED_MANUAL_RECOMMENDATIONS_FROM_ADMIN: {
        label: 'Solution provided',
        class: 'solution_provided',
    },
    SSE_CONSUMER_DROP_OFF_REQUEST_MANUAL_RECOMMENDATION: {
        label: 'Awaiting solution',
        class: 'awaiting_solution',
    },
}

export function getAltFullName(dropOff) {
    const nameStr = `${dropOff?.first_name ?? ''} ${dropOff?.last_name ?? ''}`

    return nameStr.trim() || '--'
}
