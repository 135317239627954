import { AppButton } from 'src/components/Button'

export const statusMapping = {
    AWAITING_APPROVAL: 'AWAITING APPROVAL',
    APPROVED: 'APPROVED',
    AWAITING_UPDATE: 'AWAITING UPDATE',
    IN_PROGRESS: 'RE-ESTIMATE',
    DECLINED: 'DECLINED',
    CLOSED: 'CLOSED',
}

export const pathMapping = {
    AWAITING_APPROVAL: 'awaiting-approval',
    APPROVED: 'approved',
    AWAITING_UPDATE: 'awaiting-update',
    IN_PROGRESS: 're-estimate',
    DECLINED: 'declined',
    CLOSED: 'closed',
}

export const formatInfoFromDep = data => {
    let formattedValues = []
    formattedValues.push(
        {
            id: 1,
            label: 'Full Name',
            value:
                data?.consumer?.user?.first_name +
                ' ' +
                data?.consumer?.user?.last_name,
        },
        { id: 2, label: 'Email Address', value: data?.consumer?.user?.email },
        {
            id: 3,
            label: 'Phone Number',
            value: data?.consumer?.user?.phone_number,
        },
        {
            id: 4,
            label: 'Energy Needs (Appliances)',
            value: data?.appliances?.length,
        },
        {
            id: 5,
            label: 'Customer Acquisition',
            value: data?.customer_acquisition,
        },
        { id: 6, label: 'Repayment Method', value: data?.repayment_method },
        {
            id: 7,
            label: 'Upfront Deposit',
            value:
                data?.upfront_deposit_percentage !== 0
                    ? data?.upfront_deposit_percentage + '%'
                    : 'Not Set',
        },

        {
            id: 8,
            label: 'Tenure of Payment',
            value: data?.financing_term
                ? data?.financing_term + ' ' + 'Months'
                : 'Not Set',
        },
        {
            id: 9,
            label: 'System’s Ease of Recovery',
            value: data?.system_ease_of_recovery
                ? data?.system_ease_of_recovery
                : 'Not Set',
        },
        {
            id: 10,
            label: 'Remote Monitoring Capabilities',
            value: data?.remote_monitoring_capabilities
                ? data?.remote_monitoring_capabilities
                : 'Not Set',
        },
        {
            id: 11,
            label: 'Payment Plan Type',
            value: data?.payment_plan ? data?.payment_plan : 'Not Set',
        },
        {
            id: 12,
            label: 'Provider Performed Credit Check',
            value: data?.ran_credit_check === false ? 'No' : 'Yes',
        },
        data?.ran_credit_check === true && {
            id: 13,
            label: 'Credit Check Proof',
            value: (
                <AppButton
                    btnTextColor={'#004AAD'}
                    btnBgColor={'#E2EEFF'}
                    type="buttonTwo"
                >
                    <a
                        href={data?.credit_check_proof}
                        rel="noreferrer"
                        target="_blank"
                    >
                        View
                    </a>
                </AppButton>
            ),
        },
    )

    return formattedValues.filter(Boolean)
}
