import styles from './stepper.module.scss'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const StepperStep = ({ step, title, active, width }) => {
    const { StepperEllipse, StepperLine, StepperProgress, StepperTitle } = styles;
    return (
      <div>
        <div style={{ position: 'relative' }}>
          <div
            className={StepperEllipse}
            style={{
              background: active ? '#004AAD' : '',
            }}
          ></div>
          <div className={StepperLine} style={{display: step === 3 && 'none'}}>
            <span
              className={StepperProgress}
              style={{
                width: width
              }}
            ></span>
          </div>
        </div>
        <h2 className={StepperTitle}>{title}</h2>
      </div>
    );
  };

  const Stepper = ({ userType }) => {
    const { StepperWrapper } = styles;
    const basicInformation = userType === 'business' ? 'Business' : 'Basic';
    const identityInformation =
      userType === 'business' ? 'Business Verification' : 'Identity Information';
    const [step, setStep] = useState(1);
    const pageUrl = window.location.pathname;
  
    useEffect(() => {
      if (pageUrl.includes('business-verification') || pageUrl.includes('identity-information')) {
        setStep(2);
      } else if (pageUrl.includes('financial-information')) {
        setStep(3);
      } else {
        setStep(1);
      }
    }, [pageUrl]);
  
    return (
      <div className={StepperWrapper}>
        <StepperStep step={1} title={`${basicInformation} Information`} active={true} width={step >= 2 ? '100%' : '10px'}/>
        <StepperStep step={2} title={identityInformation} active={step >= 2} width={step === 3 ? '100%' : '' }/>
        <StepperStep step={3} title="Financial Information" active={step === 3} width={''} />
         {/* //TODO: Remove third stepper line and progress temporarily till it's needed */}
      </div>
    );
  };

  

StepperStep.propTypes = {
    step: PropTypes.number,
    title: PropTypes.string,
    active: PropTypes.bool,
    width: PropTypes.string,
  };

Stepper.propTypes = {
    userType: PropTypes.string.isRequired,
}

export default Stepper
