/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ConsumerNavigation from 'src/components/Navigation/consumerNav'
import FrequentlyAskedQuestions from 'src/components/Faq'
import ConsumerFooter from 'src/components/ConsumerFooter'
import { SelectField } from 'src/components/InputFields'
import circleLeftBlue from 'src/assets/images/circle-left-pri-blue.svg'
import fallbackImage from 'src/assets/images/fallback.svg'
import infoIcon from 'src/assets/images/Info-warning-blue.svg'
import infoIconWhite from 'src/assets/images/info-icon-white.svg'

import { ReactComponent as FilterIcon } from 'src/assets/images/filter-blue.svg'
import { ReactComponent as MobileFilterIcon } from 'src/assets/images/mobile-filter.svg'
import { ConsumerButton } from 'src/components/Button'
import EditIcon from 'src/assets/images/caret-circle-left.svg'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { ReactComponent as InfoIcon } from 'src/assets/images/consumer-Info.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { ReactComponent as WarningIcon } from 'src/assets/images/warning-icon.svg'
import EditApplianceInput from './EditApplianceInput'
import FilterRecommendations from './FilterRecommendations'
import { ReactComponent as ConsumerCloseIcon } from 'src/assets/images/consumer-close-icon.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import { useAppContext } from 'src/appContext/AppContext'
import { v4 as uuidv4 } from 'uuid'
import { Pagination } from 'antd'
import {
    getRecommendation,
    getFormResponse,
    getRecommendationsForAuthUser,
} from 'src/api/shoppingExperience/landingPage'
import { errorHandler } from 'src/utils/errorHandler'
import { SkeletalCards } from 'src/components/SkeletalCards'
import EmptyState from 'src/components/EmptyState'
import { decrypt } from 'src/utils/cryptography'
import Toast from 'src/components/Toast'

import './solarsystemlistings.scss'
import { retrieveEstimationApi } from 'src/api/shoppingExperience/account-overview'
import { formatEnergyInputs } from '../AccountSetupOverview/data'
import NairaSymbol from 'src/components/NairaSymbol'
import SeoComponent from 'src/components/Seo'
import { formatPhoneNumber } from 'src/utils/formatting'
import CookieNotification from 'src/components/CookieNotificiation'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import ChatCTA from 'src/components/ChatCTA'
import {
    manualRecommReason,
    modalType,
    sortByData,
    handleAppTracking,
} from './utils'
import { encrypt } from 'src/utils/cryptography'
import { incrementCount } from 'src/redux/reducers/consumer/build-custom-system'

const ConsumerSolarSystemListings = () => {
    const { formResponse, recommendations, setFormResponse } = useAppContext()
    const location = useLocation()
    const history = useHistory()

    const pageUrlName = window.location.pathname
    const pageTitle =
        'Recommended Systems | SunFi | Simplifying and Scaling Clean Energy'
    const pageTracker = 'SOLAR_SYSTEM_LISTING'

    const productFit = {
        TECHNICAL_FIT: 'Technical Fit',
        BUDGET_FIT: 'Budget Fit',
        ECONOMY_FIT: 'Economy Fit',
    }

    const decryptedData =
        localStorage?.getItem('sunfiUserSSEGettingStartedInfo') &&
        JSON.parse(
            decrypt(localStorage?.getItem('sunfiUserSSEGettingStartedInfo')),
        )
    const user_id =
        decryptedData?.contactType === 'email'
            ? decryptedData?.email
            : decryptedData?.phone

    const [solarSystemsData, setSolarSystemsData] = useState([])
    const [energyNeeds, setEnergyNeeds] = useState([])
    const [energySummary, setEnerySummary] = useState()
    const [sortBy, setSortBy] = useState('All')
    const [showEditModal, setShowEditModal] = useState(false)
    const [editModalType, setEditModalType] = useState(modalType.edit)
    const [manualRecommCategory, setManualRecommCategory] = useState('')
    const [showEditInputDrawer, setShowEditInputDrawer] = useState(false)
    const [addFilter, setAddFilter] = useState(false)
    const [showPackages, setShowPackages] = useState(
        false || location?.state?.showPackages,
    )
    const [editedPackages, setEditedPackages] = useState(false)
    const [count, setCount] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [errorMessage, setErrorMessage] = useState('')

    const { isMobile } = useMediaQueries()
    const dispatch = useDispatch()

    //get returning consumer email or phone
    const queryParams = new URLSearchParams(history?.location?.search)
    const consumerEmail = queryParams.get('email')
    const consumerPhone = queryParams.get('phone')?.replace('234', '')
    const [userId, setUserId] = useState(
        user_id || consumerEmail || consumerPhone,
    )

    const [filtersPayload, setFiltersPayload] = useState(
        location?.state?.filterPayload,
    )
    const [showToastError, setShowToastError] = useState(false)
    const [prevEnergyNeeds, setPrevEnergyNeeds] = useState([])
    const [energyInputs, setEnergyInputs] = useState([])

    const isAuthenticatedUser = location?.state?.isAuthenticatedUser ?? false
    const estimation_id = location?.state?.estimation_id ?? null
    const auth_user_id = location?.state?.auth_user_id ?? null
    const handleSelectChange = (name, value) => {
        if (!userId) {
            setUserId(user_id || consumerEmail || consumerPhone)
        }
        setSortBy(value)
        handleAppTracking('usedSort')
    }
    useEffect(() => {
        setCount(recommendations?.count)
        setTotalPages(recommendations?.total_pages)
        setSolarSystemsData(recommendations?.data)

        const selectedAppliances = formResponse?.data?.appliances?.map(
            appliance => ({
                id: uuidv4(),
                backup_hours: appliance.backup_hours,
                backup_hours_night: appliance.backup_hours_night,
                name: appliance.name,
                quantity: appliance.quantity,
                powerRating: appliance.power_rating,
                unitOfMeasurement: appliance.unit_of_measurement,
            }),
        )

        setEnergyNeeds(selectedAppliances)
        setUserId(formResponse?.data?.user_id)
        calculateEnergySummary(selectedAppliances)
    }, [recommendations, formResponse])

    const calculateEnergySummary = selectedAppliances => {
        //summary calculation
        const wattsPerAppliance = selectedAppliances?.map(
            item => item.powerRating * item.quantity,
        )
        const totalWattsAdded = wattsPerAppliance?.reduce((a, b) => a + b)

        const dayUsage = selectedAppliances?.map(
            item => item.powerRating * item.backup_hours * item.quantity,
        )
        const totalDayUsage = dayUsage?.reduce((a, b) => a + b)
        const nightUsage = selectedAppliances?.map(
            item => item.powerRating * item.backup_hours_night * item.quantity,
        )
        const totalNightUsage = nightUsage?.reduce((a, b) => a + b)

        const averageDailyUsage = totalDayUsage + totalNightUsage

        const totalAppliances = selectedAppliances?.reduce((acc, item) => {
            return acc + parseInt(item.quantity)
        }, 0)

        setEnerySummary({
            totalWattsAdded,
            averageDailyUsage,
            totalAppliances,
            totalDayUsage,
            totalNightUsage,
        })
    }
    let priceSort = '',
        popularitySort = ''

    if (/Price.*low to high/.test(sortBy)) {
        priceSort = 'ascending'
    } else if (/Price.*high to low/.test(sortBy)) {
        priceSort = 'descending'
    }

    if (/Popularity/.test(sortBy)) {
        popularitySort = 'ascending'
    }

    const differenceAppliance = energyNeeds?.filter(
        selected => !prevEnergyNeeds?.some(prev => prev.name === selected.name),
    )

    //get recommendations
    const getRecommendationMutation = useMutation({
        mutationKey: ['solar-listings', currentPage],
        mutationFn: payload =>
            isAuthenticatedUser
                ? getRecommendationsForAuthUser(
                      estimation_id,
                      currentPage,
                      filtersPayload,
                      priceSort,
                      popularitySort,
                  )
                : getRecommendation(
                      payload,
                      currentPage,
                      filtersPayload,
                      priceSort,
                      popularitySort,
                  ),

        onSuccess: data => {
            setCount(data?.data?.count)
            setTotalPages(data?.data?.total_pages)
            setSolarSystemsData(data?.data?.data)
            setAddFilter(false)
            setEditedPackages(false)
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    //get form response
    const getFormResponseMutation = useMutation({
        mutationFn: payload => getFormResponse(payload),
        onSuccess: data => {
            // if nothing is returned, this means the consumer does not have a recommendation
            if (Object.keys(data?.data?.data).length === 0) {
                history.push('/')
            } else {
                const selectedAppliances = data?.data?.data.appliances?.map(
                    appliance => ({
                        id: uuidv4(),
                        backup_hours: appliance.backup_hours,
                        backup_hours_night: appliance.backup_hours_night,
                        name: appliance.name,
                        quantity: appliance.quantity,
                        powerRating: appliance.power_rating,
                        unitOfMeasurement: appliance.unit_of_measurement,
                    }),
                )
                setEnergyNeeds(selectedAppliances)
                setUserId(data?.data?.data?.user_id)
                calculateEnergySummary(selectedAppliances)
                if (consumerEmail || consumerPhone) {
                    setFormResponse(data?.data)
                    localStorage.setItem(
                        'sunfiUserSSEGettingStartedInfo',
                        encrypt(
                            JSON.stringify({
                                ...(consumerEmail && {
                                    email: consumerEmail,
                                }),
                                ...(consumerPhone && {
                                    phone: formatPhoneNumber(consumerPhone),
                                }),
                                contactType: consumerEmail ? 'email' : 'phone',
                                energyNeeds: selectedAppliances,
                            }),
                        ),
                    )
                }
            }
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    // get form response for an authenticated user
    const { refetch: fetchEstimation, isFetching: estimationsFetching } =
        useQuery('fetch-estimation', () => retrieveEstimationApi(), {
            enabled: false,
            retry: false,
            onSuccess: res => {
                const data = res?.data?.data
                const selectedAppliances =
                    data?.consumer_form_questions?.appliances.map(
                        appliance => ({
                            id: uuidv4(),
                            backup_hours: appliance.backup_hours,
                            backup_hours_night: appliance.backup_hours_night,
                            name: appliance.name,
                            quantity: appliance.quantity,
                            powerRating: appliance.power_rating,
                            unitOfMeasurement: appliance.unit_of_measurement,
                        }),
                    )
                setEnergyNeeds(selectedAppliances)
                calculateEnergySummary(selectedAppliances)
                setEnergyInputs(formatEnergyInputs(data))
            },
            onError: error => {
                setShowToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        })

    const updatePage = page => {
        setCurrentPage(page)
    }

    useEffect(() => {
        if (isAuthenticatedUser) {
            fetchEstimation()
        } else if (userId) {
            getFormResponseMutation.mutate({ user_id: userId })
        }
    }, [])

    useEffect(() => {
        setTotalPages(0)
        setSolarSystemsData([])

        if (isAuthenticatedUser || userId) {
            getRecommendationMutation.mutate(
                isAuthenticatedUser
                    ? { estimation_id: estimation_id }
                    : { user_id: userId },
            )
        }
    }, [userId !== '' && currentPage])

    const { isLoading: solarListingsFetching } = getRecommendationMutation

    const handleFilter = filterVariables => {
        setFiltersPayload(filterVariables)
    }

    useEffect(() => {
        setTotalPages(0)
        setSolarSystemsData([])

        if (isAuthenticatedUser || userId) {
            getRecommendationMutation.mutate(
                isAuthenticatedUser
                    ? { estimation_id: estimation_id }
                    : { user_id: userId },
            )
        }
    }, [filtersPayload])

    useEffect(() => {
        setTotalPages(0)
        setSolarSystemsData([])
        if (isAuthenticatedUser || userId) {
            getRecommendationMutation.mutate(
                isAuthenticatedUser
                    ? { estimation_id: estimation_id }
                    : { user_id: userId },
            )
        }
    }, [sortBy])

    useEffect(() => {
        if (consumerEmail || consumerPhone) {
            getFormResponseMutation.mutate({
                user_id: consumerEmail || formatPhoneNumber(consumerPhone),
            })
            getRecommendationMutation.mutate({
                user_id: consumerEmail || formatPhoneNumber(consumerPhone),
            })
        }
    }, [consumerEmail || consumerPhone])

    useEffect(() => {
        handleAppTracking('viewListing')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <SeoComponent
                title={pageTitle}
                tracker={pageTracker}
                keywords="SunFi, Inverters, Solar Panels, 10VA solar system, 10kw solar battery"
            />
            {/* Filter recommendations modal */}
            {addFilter && (
                <FilterRecommendations
                    addFilter={addFilter}
                    setAddFilter={setAddFilter}
                    setShowPackages={setShowPackages}
                    handleFilter={handleFilter}
                    isLoading={solarListingsFetching || estimationsFetching}
                />
            )}
            {/* edit input modal */}
            <SecondaryModal
                showModal={showEditModal}
                onCancel={() => setShowEditModal(false)}
                modalWidth={'475px'}
                modalHeight={'525px'}
                title={
                    editModalType === modalType.edit
                        ? 'Editing your Info'
                        : 'Review your recommendations'
                }
                showFooter
                content={
                    <div className="SSEEditModalWrapper">
                        <WarningIcon />
                        <h3>
                            {editModalType === modalType.edit
                                ? 'This action might affect your recommendations'
                                : 'Share your recommendation with us for review'}
                        </h3>
                        <p>
                            {editModalType === modalType.edit
                                ? `Please, be aware that editing your previously added
                                info might affect your recommendations.`
                                : 'A member of our team will provide a new manual recommendation to you and be sent to your submitted email address. Please confirm to go ahead'}
                        </p>
                    </div>
                }
                v2
                primaryBtnText={
                    editModalType === modalType.edit
                        ? 'Edit my Info'
                        : 'Confirm'
                }
                handlePrimaryBtnClick={() => {
                    setShowEditInputDrawer(true)
                    setShowEditModal(false)
                }}
                primaryBtnWidth={isMobile ? '116px' : '160px'}
                primaryBtnHeight={isMobile ? '45px' : '53px'}
            />

            {showEditInputDrawer && (
                <EditApplianceInput
                    showEditInputDrawer={showEditInputDrawer}
                    hideDrawer={() => setShowEditInputDrawer(false)}
                    energyNeeds={energyNeeds}
                    setShowEditModal={setShowEditModal}
                    setEditedPackages={setEditedPackages}
                    setPrevEnergyNeeds={setPrevEnergyNeeds}
                    energyInputs={energyInputs}
                    action={
                        isAuthenticatedUser
                            ? 'update_listings_as_auth_user'
                            : null
                    }
                    auth_user_id={isAuthenticatedUser ? auth_user_id : null}
                    knowGenMonthlyCost={decryptedData?.knowsGenMonthlyCost}
                    editModalType={editModalType}
                    manualRecommCategory={manualRecommCategory}
                    editAppTrackingCallback={() =>
                        handleAppTracking('editsAppliances')
                    }
                />
            )}
            <ConsumerNavigation showUserBtn page={'sseSystemsListings'} />
            <div className="SolarSystemsWrapper">
                <div className="ViewSystemTopButtons">
                    <div
                        onClick={() => {
                            if (isAuthenticatedUser) {
                                history.goBack()
                            } else {
                                history.push('/')
                            }
                        }}
                    >
                        <img
                            src={circleLeftBlue}
                            alt="circle-left-blue"
                            role={'presentation'}
                        />
                        <span>Go back</span>
                    </div>
                    <div>
                        {showPackages ? (
                            <div
                                onClick={() => {
                                    setShowPackages(false),
                                        setFiltersPayload({})
                                }}
                            >
                                <ConsumerCloseIcon
                                    fill={'#004AAD'}
                                    stroke={'#004AAD'}
                                />
                                <span>
                                    {isMobile ? (
                                        <span>Filtered recommendations</span>
                                    ) : (
                                        <>
                                            <span
                                                style={{
                                                    fontFamily:
                                                        'SF-Pro-Display',
                                                }}
                                            >
                                                Filtered by payment model:{' '}
                                            </span>
                                            <span
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {filtersPayload?.payment_model}
                                            </span>
                                        </>
                                    )}
                                </span>
                            </div>
                        ) : (
                            <div onClick={() => setAddFilter(true)}>
                                {isMobile ? (
                                    <MobileFilterIcon />
                                ) : (
                                    <FilterIcon />
                                )}

                                <span>Filter recommendations</span>
                            </div>
                        )}
                    </div>
                </div>
                {showToastError && (
                    <Toast
                        messageType="error-secondary"
                        message={errorMessage}
                        closable={true}
                    />
                )}
                <section
                    className="EnergyNeedsSummaryWrapper"
                    style={{
                        height:
                            editedPackages && differenceAppliance?.length !== 0
                                ? isMobile
                                    ? 'auto'
                                    : '280px'
                                : '',
                        transition: '0.5 ease all',
                    }}
                >
                    <div className="EnergyNeedsSummaryTopStyle">
                        <p>Your energy needs summary</p>
                        <ConsumerButton
                            btnBgColor="#004AAD"
                            btnTextColor="#FFFFFF"
                            btnHeight="29px"
                            borderRadius="30px"
                            handleClick={() => {
                                setShowEditModal(true)
                                setEditModalType(modalType.edit)
                            }}
                        >
                            <div className="EditYourInput">
                                <img src={EditIcon} />
                                <span>
                                    {isMobile
                                        ? 'Edit Appliances'
                                        : 'Edit your input'}
                                </span>
                            </div>
                        </ConsumerButton>
                    </div>
                    <div className="EnergyNeedsSummaryBottomStyle">
                        <div>
                            <p>Total appliances added</p>
                            <p>
                                {Math.round(
                                    energySummary?.totalAppliances || 0,
                                )?.toLocaleString()}
                                <sup>Appliances</sup>
                            </p>
                        </div>
                        <p className="SSESummaryVerticalLine"></p>

                        <div>
                            <p>Total watts added</p>
                            <p>
                                {Math.round(
                                    energySummary?.totalWattsAdded || 0,
                                )?.toLocaleString()}
                                <sup>watts</sup>
                            </p>
                        </div>
                        <p className="SSESummaryVerticalLine"></p>
                        <div>
                            <p>Total day usage</p>
                            <p>
                                {Math.round(
                                    energySummary?.totalDayUsage || 0,
                                )?.toLocaleString()}
                                <sup>wh</sup>
                            </p>
                        </div>
                        <p className="SSESummaryVerticalLine"></p>

                        <div>
                            <p>Total night usage</p>
                            <p>
                                {Math.round(
                                    energySummary?.totalNightUsage || 0,
                                )?.toLocaleString()}
                                <sup>wh</sup>
                            </p>
                        </div>
                        <p className="SSESummaryVerticalLine"></p>

                        <div style={{ marginRight: isMobile ? 0 : '30px' }}>
                            <p>Daily Energy Consumption </p>
                            <p>
                                {Math.round(
                                    energySummary?.averageDailyUsage || 0,
                                )?.toLocaleString()}
                                <sup>wh</sup>
                            </p>
                        </div>
                    </div>
                    {editedPackages && differenceAppliance?.length !== 0 && (
                        <div className="ShowFilteredPackagesWrapper">
                            <p>
                                {`The addition of
                            ${differenceAppliance?.map(
                                appliance => appliance.name,
                            )} has
                            increased your overall energy consumption. As a
                            result, the payment plans that we will offer you
                            may be outside of your budget`}
                            </p>
                        </div>
                    )}
                </section>
                <section className="SystemsListWraaper">
                    {count > 0 ? (
                        <div className="SystemsListTopStyle">
                            <div className="SystemsListTopStyle__btns">
                                <p>{count} recommendations for you </p>
                                {!isAuthenticatedUser ? (
                                    <ConsumerButton
                                        btnBgColor="#F9FCFF"
                                        btnTextColor="#004AAD"
                                        borderOutlineColor="#004AAD"
                                        btnHeight="31px"
                                        borderRadius="30px"
                                        handleClick={() => {
                                            setShowEditModal(true)
                                            setEditModalType(modalType.manual)
                                            setManualRecommCategory(
                                                manualRecommReason.unsatisfiedRecommendation,
                                            )
                                        }}
                                    >
                                        Don’t like what you see? Click here!
                                    </ConsumerButton>
                                ) : null}
                            </div>
                            <div className="SystemsSortByWrapper">
                                <span>Sort by</span>
                                <SelectField
                                    name="sortBy"
                                    initialOption={sortBy}
                                    selectWidth="171px"
                                    handleChange={handleSelectChange}
                                    withCheckBox={true}
                                    dropdownPositionRelative
                                    type="SSEOffset"
                                    selectedValue={sortBy}
                                    currentSelected={sortBy}
                                    values={sortByData.map(option => ({
                                        value: option,
                                    }))}
                                    value={sortBy}
                                    optionStyle={{
                                        paddingTop: '15px',
                                        paddingBottom: '15px',
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}
                    {solarListingsFetching ? (
                        <SkeletalCards total={6} />
                    ) : solarSystemsData?.length > 0 ? (
                        <div className="SystemsListBottomStyle">
                            {solarSystemsData?.map((system, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`SolarSystemListWrapper ${
                                            system?.best_fit
                                                ? 'SolarSystemListWrapper_BestFit'
                                                : ''
                                        }`}
                                        style={{
                                            padding: system?.best_fit && 0,
                                        }}
                                    >
                                        <LazyLoadImage
                                            src={
                                                system?.has_solar_panel
                                                    ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+thumbnail.png'
                                                    : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Without+panels+thumbnail.png'
                                            }
                                            alt="solar-system-img"
                                            style={{
                                                paddingTop:
                                                    system?.best_fit && isMobile
                                                        ? '20px'
                                                        : '',
                                            }}
                                            placeholderSrc={fallbackImage}
                                        />
                                        <div
                                            className={
                                                system?.best_fit &&
                                                'SolarSystemDescriptionWrapper'
                                            }
                                        >
                                            <div
                                                className={`SolarSystemNameWrapper ${
                                                    system?.best_fit &&
                                                    'BestFitStyle'
                                                }`}
                                            >
                                                <p className="SolarSystemNameStyle">
                                                    {system.name}
                                                </p>
                                                <CustomToolTip
                                                    text={
                                                        system?.best_fit
                                                            ? 'This is the best option for you based on what you’re looking to solve with solar'
                                                            : productFit[
                                                                  system
                                                                      ?.recommendation_context
                                                                      ?.remark
                                                              ] ===
                                                              'Technical Fit'
                                                            ? 'This means this package is the right fit for your energy needs'
                                                            : productFit[
                                                                  system
                                                                      ?.recommendation_context
                                                                      ?.remark
                                                              ] ===
                                                              'Economy Fit'
                                                            ? 'This means this package suits your monthly earnings'
                                                            : 'This means this package fits your monthly budget'
                                                    }
                                                    toolTipFontSize="12px"
                                                    toolTipPadding="10px 6px 14px 11px"
                                                    toolTipPopupWidth="174px"
                                                    toolTipMarginLeft={
                                                        isMobile
                                                            ? '-120px'
                                                            : '5px'
                                                    }
                                                    showTooltip={true}
                                                    lineHeight="16px"
                                                    background="#070C18"
                                                    borderRadius="8px"
                                                    arrowPositionLeft={
                                                        !isMobile && true
                                                    }
                                                >
                                                    <div
                                                        className="SolarSystemFitStyle"
                                                        style={{
                                                            marginTop:
                                                                system?.best_fit &&
                                                                '2px',
                                                        }}
                                                    >
                                                        <p>
                                                            {system.best_fit
                                                                ? 'Best Fit'
                                                                : productFit[
                                                                      system
                                                                          ?.recommendation_context
                                                                          ?.remark
                                                                  ] || ''}
                                                        </p>
                                                        {system
                                                            ?.recommendation_context
                                                            ?.remark && (
                                                            <InfoIcon
                                                                style={{
                                                                    marginLeft:
                                                                        '3px',
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </CustomToolTip>
                                            </div>
                                            <p className="SolarSystemDescriptionStyle">
                                                {system.energy_capacity?.value +
                                                    system.energy_capacity
                                                        ?.unit_of_measurement}
                                            </p>
                                            <div className="SolarSystemPlanWrapper">
                                                <p
                                                    style={{
                                                        background:
                                                            system?.best_fit &&
                                                            '#002C68',
                                                    }}
                                                >{`${system.number_of_plans} Plans`}</p>
                                                <p>{system.payment_model}</p>
                                            </div>
                                            <section className="SolarSystemPriceWrapper">
                                                {system.upfront_deposit && (
                                                    <div>
                                                        <p
                                                            style={{
                                                                color:
                                                                    system?.best_fit &&
                                                                    '#E2EEFF',
                                                            }}
                                                        >
                                                            Upfront deposit
                                                        </p>
                                                        <p className="SolarSystemNameStyle">
                                                            <NairaSymbol />
                                                            {currencyFormatter(
                                                                system?.upfront_deposit,
                                                            )}
                                                        </p>
                                                    </div>
                                                )}
                                                <div>
                                                    <p
                                                        style={{
                                                            color:
                                                                system?.best_fit &&
                                                                '#E2EEFF',
                                                        }}
                                                    >
                                                        Least amount payable
                                                    </p>
                                                    <p
                                                        className="SolarSystemDescriptionStyle"
                                                        style={{
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        <NairaSymbol />
                                                        {currencyFormatter(
                                                            system.least_payable_amount,
                                                        )}
                                                    </p>
                                                </div>
                                            </section>
                                            <hr className="SolarSystemListingsCardHR" />
                                            <section className="SolarSystemPriceWrapper">
                                                <div>
                                                    <p
                                                        style={{
                                                            color:
                                                                system?.best_fit &&
                                                                '#E2EEFF',
                                                        }}
                                                    >
                                                        Backup duration
                                                    </p>
                                                    <p className={''}>
                                                        {Math.round(
                                                            system?.average_backup_hours,
                                                        )}{' '}
                                                        Hour
                                                        {system?.average_backup_hours >
                                                        1
                                                            ? 's'
                                                            : ''}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p
                                                        style={{
                                                            color:
                                                                system?.best_fit &&
                                                                '#E2EEFF',
                                                        }}
                                                    >
                                                        Avg. load size
                                                    </p>
                                                    <div className="DemandInfoWrapper">
                                                        <p
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                                color: system?.best_fit
                                                                    ? '#E2EEFF'
                                                                    : '#070c18',
                                                            }}
                                                        >
                                                            {
                                                                system?.consumer_avg_demand
                                                            }
                                                        </p>
                                                        <CustomToolTip
                                                            text={`${
                                                                system?.consumer_avg_demand?.includes(
                                                                    'kVA',
                                                                )
                                                                    ? ''
                                                                    : `${system?.consumer_avg_demand} also means ${system?.consumer_avg_demand_kva}. `
                                                            }Keep total power use at the recommended level to get the listed backup hours.`}
                                                            toolTipFontSize="12px"
                                                            toolTipPadding="9px 12px 9px 8px"
                                                            toolTipPopupWidth={
                                                                174
                                                            }
                                                            toolTipMarginLeft={
                                                                -150
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    system?.best_fit
                                                                        ? infoIconWhite
                                                                        : infoIcon
                                                                }
                                                                alt={
                                                                    'info icon'
                                                                }
                                                                role={
                                                                    'presentation'
                                                                }
                                                            />
                                                        </CustomToolTip>
                                                    </div>
                                                </div>
                                            </section>
                                            <div className="SolarSytemViewBtn">
                                                <ConsumerButton
                                                    btnHeight="53px"
                                                    btnWidth="160px"
                                                    btnBgColor={
                                                        system?.best_fit
                                                            ? '#FFFFFF'
                                                            : '#004AAD'
                                                    }
                                                    btnTextColor={
                                                        system?.best_fit
                                                            ? '#004AAD'
                                                            : '#FFFFFF'
                                                    }
                                                    btnFontSize="14px"
                                                    secondaryHover
                                                    handleClick={() => {
                                                        if (
                                                            isAuthenticatedUser
                                                        ) {
                                                            history.push({
                                                                pathname: `/consumer/view-system/${system?.id}`,
                                                                state: {
                                                                    isAuthenticatedUser:
                                                                        isAuthenticatedUser,
                                                                    estimation_id:
                                                                        estimation_id,
                                                                    action: 'select_new_system',
                                                                    auth_user_id:
                                                                        auth_user_id,
                                                                },
                                                            })
                                                        } else {
                                                            history.push({
                                                                pathname: `/consumer/view-system/${system?.id}`,
                                                                state: {
                                                                    onboardingType:
                                                                        'custom-build',
                                                                },
                                                            })
                                                        }
                                                        appTracking(
                                                            pageUrlName,
                                                            pageTracker,
                                                            pageTitle,
                                                            eventTrackers[
                                                                'sseViewSolarSystem'
                                                            ].action,
                                                            eventTrackers[
                                                                'sseViewSolarSystem'
                                                            ].label,
                                                            eventTrackers[
                                                                'sseViewSolarSystem'
                                                            ].category,
                                                            ['MP', 'GA'],
                                                            'event',
                                                            {
                                                                event: {
                                                                    name: system.name,
                                                                    id: system.id,
                                                                },
                                                            },
                                                        )
                                                        handleAppTracking(
                                                            'viewDetails',
                                                        )
                                                        dispatch(
                                                            incrementCount(),
                                                        )
                                                    }}
                                                >
                                                    View this system
                                                </ConsumerButton>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <div className="emptyStateWrapper">
                            {!isAuthenticatedUser ? (
                                <>
                                    <EmptyState
                                        title="We could not get any recommendation"
                                        type="noActivity"
                                        showBrightIcon
                                        subTitle={
                                            <span className="emptyStateWrapper__text">
                                                We are unable to recommend a
                                                solar package that matches your
                                                energy needs at this time. The
                                                good news is, we have an
                                                experienced technical team that
                                                can help curate a customized
                                                solution for you from our
                                                extensive library.
                                            </span>
                                        }
                                        buttonText="Request Alternative Recommendation"
                                        secondaryTitle="If you would like to share more information about your needs, kindly click on the button below to get a customized solution from our team."
                                        buttonWidth="287px"
                                        buttonClick={() => {
                                            setEditModalType(modalType.manual)
                                            setShowEditInputDrawer(true)
                                            setManualRecommCategory(
                                                manualRecommReason.noRecommendation,
                                            )
                                        }}
                                    />
                                    <ChatCTA
                                        text="Have questions?"
                                        width="fit-content"
                                        textOneFontSize="14px"
                                    />
                                </>
                            ) : (
                                <EmptyState
                                    title="No recommendations"
                                    type="noActivity"
                                    subTitle="No recommendations yet"
                                />
                            )}
                        </div>
                    )}
                    {solarSystemsData?.length > 0 && !solarListingsFetching ? (
                        <div className="ACLPaginationWrapper">
                            <Pagination
                                defaultCurrent={1}
                                current={currentPage}
                                total={parseInt(totalPages, 10)}
                                defaultPageSize={1}
                                showSizeChanger={false}
                                showTitle={false}
                                showLessItems
                                onChange={updatePage}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </section>
            </div>
            <FrequentlyAskedQuestions useV2 />
            <CookieNotification />
            <ConsumerFooter />
        </>
    )
}

export default ConsumerSolarSystemListings
