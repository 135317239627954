import PropTypes from 'prop-types'
import FormTitleBar from 'src/components/FormTitleBar'
import BrandCard from 'src/pages/Profile/ComponentBrands/components/BrandCard'
import BrandCardLoader from 'src/pages/Profile/ComponentBrands/components/BrandCardLoader'
import s from '../addcomponentbrands.module.scss'
import ButtonComponent from 'src/components/Button'
import { InlineLoader } from 'src/components/Loader'

const AddComponentBrandsOverview = ({
    componentTypes,
    componentBrands,
    errors,
    componentBrandsFetching,
    handleAddBrand,
    handleEditBrand,
    prevHandler,
    handleSubmit,
    updateBrandsLoading,
}) => {
    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <FormTitleBar
                    title="What brands do you work with?"
                    subtitle="Enter your brands you currently work with"
                />
                {componentBrandsFetching
                    ? [...Array(4)]?.map((_, index) => (
                          <BrandCardLoader key={index} margin="0 0 24px 0" />
                      ))
                    : componentTypes?.map((type, index) => (
                          <BrandCard
                              key={index}
                              category={type}
                              brandNames={componentBrands[type]}
                              margin="0 0 24px 0"
                              showButtons
                              handleAddBrand={handleAddBrand}
                              handleEditBrand={handleEditBrand}
                              error={errors[type]}
                          />
                      ))}
                <div className={s.btn}>
                    <ButtonComponent
                        btnBgColor={'#E2EEFF'}
                        btnTextColor={'#004AAD'}
                        btnWidth={'160px'}
                        btnHeight={'56px'}
                        handleClick={prevHandler}
                    >
                        Previous
                    </ButtonComponent>
                    <ButtonComponent
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#FFFFFF'}
                        btnWidth={'160px'}
                        btnHeight={'56px'}
                        handleClick={handleSubmit}
                    >
                        {updateBrandsLoading ? <InlineLoader /> : 'Continue'}
                    </ButtonComponent>
                </div>
            </div>
        </div>
    )
}

AddComponentBrandsOverview.propTypes = {
    componentTypes: PropTypes.array,
    componentBrands: PropTypes.array,
    errors: PropTypes.object,
    componentBrandsFetching: PropTypes.bool,
    handleAddBrand: PropTypes.func,
    handleEditBrand: PropTypes.func,
    prevHandler: PropTypes.func,
    handleSubmit: PropTypes.func,
    updateBrandsLoading: PropTypes.bool,
}

export default AddComponentBrandsOverview
