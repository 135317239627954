import axios from 'axios'
import {
    setLocalStorageWithExpiry,
    getLocalStorageWithExpiry,
} from './localStorageMgr'

export const getClientLocator = async (dry_run = true) => {
    if (dry_run) {
        return {
            ipAddress: '',
        }
    }
    // Retrieve the IP address, or null if expired
    const clientIP = getLocalStorageWithExpiry('clientIP')
    if (!clientIP) {
        try {
            const ipAddress = await axios.get(
                'https://api.ipify.org/?format=json',
            )
            // Store the IP address with a 10-minute expiration time
            setLocalStorageWithExpiry(
                'clientIP',
                ipAddress.data?.ip,
                10 * 60 * 1000,
            ) // 10 minutes in milliseconds
            return {
                ipAddress: ipAddress.data?.ip,
            }
        } catch (error) {
            return {
                ipAddress: '',
            }
        }
    } else {
        return {
            ipAddress: clientIP,
        }
    }
}
