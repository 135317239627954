import '../identityinformation.scss'
import { InputFields, SelectField } from 'src/components/InputFields'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import FormWrapper from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/_components/FormWrapper'
import Label from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/_components/Label/index.jsx'
import { meansOfIdentityV2 } from 'src/utils/Data/meansOfIdentity'
import ImageIcon from 'src/assets/images/image-icon.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { isMobile } from 'src/utils/mediaQueries'
import IdentityModal from './IdentityModal'
import { ReactComponent as WarningIconSmall } from 'src/assets/images/warning-icon-small.svg'
import { useContext, useEffect, useState } from 'react'
import { SSEResidentialIdentityInfoContext } from '../context'
import Toast from 'src/components/Toast'
import VerifyIdentity from './VerifyIdentity'
import ActionModal from 'src/components/ActionModalV2'
import {
    DriversLicenseRegex,
    InternationalPassportRegex,
    VirtualNINRegex,
    VotersCardRegex,
} from 'src/utils/validationSchema'
import { decodeUserInfo } from 'src/utils/auth'
import ImageDataSection from './ImageDataSection'
import IdCardDataSection from './IdCardDataSection'
import OverrideCheckModal from '../../_components/OverrideCheckModal'
import useMappedErrors from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/hooks/useMappedErrors'
import { RESIDENTIAL_IDENTITY_INFO_UPDATE_REQUESTS_MAP } from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/utils'
import useScrollToError from '../../hooks/useScrollToError'
import FormSkeleton from '../../_components/FormSkeleton'
import { useAppContext } from 'src/appContext/AppContext'
import { useKYCCompletionStatus } from 'src/utils/Hooks/useKYCCompletionStatus'
import { appTracking } from 'src/utils/appTracker'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useKYCFormCompletionGuard from '../../hooks/useKYCFormCompletionGuard'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import CSSEVideoPlayer from 'src/components/CSSEVideoPlayer'
import IdentityVerificationFailed from '../../Verification/components/IdentityVerificationFailed'

const IdentityInformationForm = () => {
    const value = useContext(SSEResidentialIdentityInfoContext)
    const {
        sseBasicInfoRefetch,
        sseBasicInfoFetching,
        persistResidentialIdentityInfo,
        toast,
        setToast,
        step,
        setStep,
        selected,
        setSelected,
        inputs,
        showModal,
        setShowModal,
        uploading,
        successUpload,
        setSuccessUpload,
        pictureUrl,
        setPictureUrl,
        width,
        setWidth,
        handleInputChange,
        handleBlur,
        handleSelectChange,
        closeModal,
        modalFooterLeftContent,
        modalPrimaryBtnText,
        modalTitle,
        disableModalPrimaryBtn,
        handleImageSteps,
        webcamRef,
        handleRetake,
        showIdentityModal,
        setShowIdentityModal,
        fieldErrors,
        actionModal,
        setActionModal,
        persistResidentialIdentityInfoLoading,
        selfieLoading,
        IdentitySelfieRef,
        verificationFailedModal,
        setVerificationFailedModal,
        identityToast,
        setIdentityToast,
        verificationInProgress,
    } = value
    const { isCustomerApproved, estimationId, customerType } = useAppContext()
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const [showOverrideModal, setShowOverrideModal] = useState(false)
    const [updateErrors, setUpdateErrors] = useState({})
    const mappedErrors = useMappedErrors(
        RESIDENTIAL_IDENTITY_INFO_UPDATE_REQUESTS_MAP,
    )
    const pageTitle =
        'KYC - Identity Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const { kycStatus } = useKYCCompletionStatus(estimationId, customerType)
    const identityInfoCompleted =
        kycStatus?.residential_identity_information_completion_status
    const [showVideoModal, setShowVideoModal] = useState(!isAdminUser)
    const [showVideo, setShowVideo] = useState(false)

    const removeFromUpdateErrs = field => {
        const newErrs = { ...updateErrors }
        delete newErrs[field]
        setUpdateErrors(newErrs)
    }

    // check if user has completed previous forms
    useKYCFormCompletionGuard(kycStatus, 2, 'RESIDENTIAL')

    useEffect(() => {
        setUpdateErrors(mappedErrors)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mappedErrors])

    useScrollToError(mappedErrors)

    const handleTracking = e => {
        const { name, value } = e.target
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_BASIC_IDENTITY_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    const { BVNAlert } = useCustomToast()

    return (
        <FormWrapper>
            {/* Modal Start */}
            <SecondaryModal
                showModal={showModal}
                onCancel={() => closeModal()}
                modalWidth={'475px'}
                title={modalTitle}
                content={
                    <IdentityModal
                        step={step}
                        setStep={setStep}
                        selected={selected}
                        setSelected={setSelected}
                        webcamRef={webcamRef}
                        IdentitySelfieRef={IdentitySelfieRef}
                        successUpload={successUpload}
                        setSuccessUpload={setSuccessUpload}
                        pictureUrl={pictureUrl}
                        setPictureUrl={setPictureUrl}
                        uploading={uploading}
                        width={width}
                        setWidth={setWidth}
                    />
                }
                v2
                showFooter
                footerLeftContent={
                    step === 3 && selfieLoading
                        ? 'none'
                        : modalFooterLeftContent
                }
                primaryBtnText={modalPrimaryBtnText}
                primaryBtnDisabled={disableModalPrimaryBtn}
                handlePrimaryBtnClick={handleImageSteps}
                primaryBtnLoading={step === 3 && selfieLoading}
                primaryBtnWidth={'160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
                noPrimaryBtnTextInSecondary
                secondaryBtnText="Retake"
                handleSecondaryBtnClick={() => {
                    step === 3 && handleRetake()
                }}
            />
            <ActionModal
                title={'Identity Verification '}
                actionModalOpen={actionModal?.open}
                actionType={
                    actionModal?.type === 'error' ? 'delete' : 'success'
                }
                headerText={
                    actionModal?.type === 'error'
                        ? 'Verification error'
                        : 'Verification successful'
                }
                subTitle={
                    actionModal?.type === 'error'
                        ? actionModal?.errMessage ||
                          'Your First and last name on your ID card does not match the first and last name you submitted. Please check and confirm'
                        : 'Your identity has been verified successfully and this part of your KYC has been completed'
                }
                footerLeftContent="chat"
                primaryBtnText={
                    actionModal?.type === 'error' ? 'Resubmit' : 'Great'
                }
                handlePrimaryBtnClick={() => {
                    if (actionModal?.type === 'error') {
                        setShowIdentityModal(true)
                        setActionModal({
                            open: false,
                            type: '',
                            errMessage: '',
                        })
                    } else {
                        setActionModal({
                            open: false,
                            type: '',
                            errMessage: '',
                        })
                    }
                    actionModal?.type !== 'error' && sseBasicInfoRefetch()
                }}
                onCancel={() => {
                    setActionModal({ open: false, type: '', errMessage: '' })
                }}
            />

            <SecondaryModal
                showModal={showIdentityModal}
                onCancel={() => {
                    setShowIdentityModal(false)
                    setVerificationFailedModal(false)
                    setIdentityToast('')
                }}
                modalWidth={'475px'}
                title={
                    verificationFailedModal
                        ? 'Unable to verify'
                        : 'Confirm your details'
                }
                content={
                    <>
                        {verificationFailedModal ? (
                            <IdentityVerificationFailed />
                        ) : (
                            <VerifyIdentity
                                inputs={inputs}
                                handleInputChange={handleInputChange}
                                handleBlur={handleBlur}
                                fieldErrors={fieldErrors}
                                identityToast={identityToast}
                            />
                        )}
                    </>
                }
                showFooter
                footerLeftContent={verificationFailedModal ? 'chat' : 'button'}
                primaryBtnText={
                    verificationFailedModal ? 'Continue with KYC' : 'Confirm'
                }
                handlePrimaryBtnClick={() =>
                    verificationFailedModal
                        ? setShowIdentityModal(false)
                        : persistResidentialIdentityInfo('Submit')
                }
                v2
                primaryBtnLoading={persistResidentialIdentityInfoLoading}
                primaryBtnDisabled={persistResidentialIdentityInfoLoading}
                primaryBtnWidth={'160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
                noPrimaryBtnTextInSecondary
                secondaryBtnText="I'll verify later"
                handleSecondaryBtnClick={() => {
                    setShowIdentityModal(false)
                    BVNAlert(
                        'Please, go ahead to provide your other details',
                        isMobile ? '100%' : '511px',
                    )
                }}
                secondaryBtnLoading={false}
                kycSecondaryButtonStopLoading={true}
            />

            {(showVideoModal || showVideo) && (
                <CSSEVideoPlayer
                    showVideoModal={showVideoModal}
                    setShowVideoModal={setShowVideoModal}
                    showVideo={showVideo}
                    setShowVideo={setShowVideo}
                    modalTitle="Next step"
                    header="Verify your Identity"
                    videoInfo="Here’s a video to guide you on how you can verify your ID"
                    videoUrl="https://www.youtube.com/watch?v=AkLVZVvfqoU"
                    videoGIF="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/How_to_Verify_Your_Identity.gif"
                    description="We need to verify your identity before we can provide financing, to do this, you’ll have to add an image of your self and also an ID number of a valid government-issued ID number such as: 
                    International Passport, Driver’s license or National ID (NIN)"
                />
            )}

            {/* Modal end  */}
            <Toast
                messageType={toast?.type || ''}
                message={toast?.message || ''}
                setToast={setToast}
                position="fixed"
                minHeight={'50px'}
                left={isMobile ? '0%' : '20%'}
                top={isMobile ? '2%' : '20%'}
                zIndex="999"
            />
            {sseBasicInfoFetching ? (
                <FormSkeleton type={'includesAvatar'} />
            ) : (
                <>
                    {!isAdminUser && (
                        <div
                            className="FinancialFormVideoWrapper"
                            onClick={() => setShowVideoModal(true)}
                        >
                            <PlayIcon />
                            <p>Watch how to verify your identity</p>
                        </div>
                    )}
                    {!isAdminUser && (
                        <Label
                            title="Add a photo"
                            subTitle="We need your photo to verify that you are the one"
                        />
                    )}

                    {inputs?.imageUrl?.length > 0 ? (
                        <>
                            <div
                                hidden={isAdminUser}
                                className="ConfirmedImgWrapper"
                            >
                                <img
                                    src={inputs?.imageUrl}
                                    alt="uploaded-image"
                                />
                                <button
                                    onClick={() => {
                                        setShowModal(true)
                                        handleRetake()
                                        removeFromUpdateErrs('imageUrl')
                                    }}
                                    disabled={isAdminUser || isCustomerApproved}
                                >
                                    <div>
                                        <img src={ImageIcon} alt="img-icon" />
                                    </div>
                                    <p>Take a selfie or Upload a photo</p>
                                </button>
                            </div>
                            {isAdminUser && (
                                <div className="imageDataContainer">
                                    <ImageDataSection />
                                </div>
                            )}
                        </>
                    ) : (
                        <button
                            className="UploadImageCTA"
                            onClick={() => {
                                setShowModal(true)
                                removeFromUpdateErrs('imageUrl')
                            }}
                            disabled={isAdminUser || isCustomerApproved}
                        >
                            <div>
                                <img src={ImageIcon} alt="img-icon" />
                            </div>
                            <p>Take a selfie or Upload a photo</p>
                        </button>
                    )}
                    {(fieldErrors?.imageUrl?.length > 0 ||
                        updateErrors.imageUrl) && (
                        <p className="ErrorText">
                            {fieldErrors?.imageUrl || updateErrors.imageUrl}
                        </p>
                    )}
                    <VerticalSpacer top="32px" />
                    <Label
                        title="Add your ID card"
                        subTitle="To verify your identity, please add your ID card number."
                        showHelpIcon
                        tooltipText={`To enhance your security, we'll use your submitted ID details for identity verification purposes only.`}
                    />
                    <SelectField
                        floatLabelOnSelectedValue
                        floatingLabel="ID card type"
                        name="identityType"
                        initialOption="ID card type"
                        selectWidth="100%"
                        marginBottom="24px"
                        values={meansOfIdentityV2.map(option => ({
                            value: option,
                        }))}
                        disabled={
                            inputs?.identityVerified ||
                            isAdminUser ||
                            isCustomerApproved
                        }
                        value={inputs.identityType}
                        currentSelected={inputs.identityType}
                        handleChange={(name, value) => {
                            handleSelectChange(name, value)
                            removeFromUpdateErrs('identityType')
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                `FILLED_${name?.toUpperCase()}`,
                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                'KYC_BASIC_IDENTITY_INFO',
                                ['GA', 'MP'],
                                'event',
                                { value },
                            )
                        }}
                        withCheckBox
                        errorMessage={
                            fieldErrors?.identityType ||
                            updateErrors.identityType
                        }
                    />
                    <div className="VerifyInputWrapper">
                        <InputFields
                            title="ID card number"
                            name="identityNumber"
                            value={inputs.identityNumber}
                            handleChange={e => {
                                handleInputChange(e)
                                removeFromUpdateErrs('identityNumber')
                            }}
                            type={
                                inputs?.identityType ===
                                'National Identification Number'
                                    ? 'number'
                                    : 'text'
                            }
                            onBlur={e => {
                                handleBlur(e)
                                handleTracking(e)
                            }}
                            marginBottom={'8px'}
                            errorMessage={
                                fieldErrors?.identityNumber ||
                                fieldErrors?.identityVerified ||
                                updateErrors.identityNumber
                            }
                            disabled={
                                inputs?.identityVerified ||
                                !inputs?.identityType?.length ||
                                isAdminUser ||
                                isCustomerApproved ||
                                verificationInProgress
                            }
                            verified={inputs?.identityVerified}
                            inprogress={verificationInProgress}
                        />
                        {verificationInProgress && (
                            <span className="verificationInProgressStyle">
                                Verification In Progress
                            </span>
                        )}
                        {inputs?.identityVerified !== true &&
                            !isAdminUser &&
                            !verificationInProgress && (
                                <div className="VerifyCTA">
                                    <button
                                        onClick={() => {
                                            setShowIdentityModal(true)
                                        }}
                                        data-test-id="identity-verify-btn"
                                        disabled={
                                            inputs?.identityType?.length ===
                                                0 ||
                                            (inputs?.identityType ===
                                                'Drivers License' &&
                                                DriversLicenseRegex.test(
                                                    inputs?.identityNumber,
                                                ) === false) ||
                                            (inputs?.identityType ===
                                                'Virtual National Identification Number' &&
                                                VirtualNINRegex.test(
                                                    inputs?.identityNumber,
                                                ) === false) ||
                                            (inputs?.identityType ===
                                                'National Identification Number' &&
                                                inputs?.identityNumber
                                                    ?.length !== 11) ||
                                            (inputs?.identityType ===
                                                'Voters Card' &&
                                                VotersCardRegex.test(
                                                    inputs?.identityNumber,
                                                ) === false) ||
                                            (inputs?.identityType ===
                                                'International Passport' &&
                                                InternationalPassportRegex.test(
                                                    inputs?.identityNumber,
                                                ) === false) ||
                                            isAdminUser ||
                                            isCustomerApproved
                                        }
                                        style={{ width: '121px' }}
                                    >
                                        {isMobile
                                            ? 'Verify'
                                            : 'Verify your Identity'}
                                    </button>
                                </div>
                            )}
                    </div>
                    {inputs.identityType ===
                        'Virtual National Identification Number' &&
                        inputs?.identityVerified !== true && (
                            <div className="vNINWrapper">
                                <WarningIconSmall />
                                <p>
                                    Use our Sort Code <strong>715461</strong> to
                                    generate your Virtual NIN on the NIMC app or
                                    dial
                                    <strong>*346*3*Your NIN*715461#</strong> to
                                    generate via USSD
                                </p>
                            </div>
                        )}
                    <OverrideCheckModal
                        showOverrideModal={showOverrideModal}
                        type="ID"
                        onCancel={() => {
                            setShowOverrideModal(false)
                        }}
                    />
                    {isAdminUser && identityInfoCompleted && (
                        <IdCardDataSection
                            title="ID CARD"
                            handleOverride={() => setShowOverrideModal(true)}
                        />
                    )}
                </>
            )}
        </FormWrapper>
    )
}

export default IdentityInformationForm
