import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { testimonies } from './data'
import styles from './testimonial.module.scss'
import circleLeftBlue from 'src/assets/images/csse-carousel-left.svg'
import circleRightWhite from 'src/assets/images/csse-carousel-right.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const PrevButton = props => {
    const { className, onClick } = props
    return (
        <div className={className} onClick={onClick} style={{ zIndex: 1 }}>
            <img src={circleLeftBlue} alt="carousel prev button" />
        </div>
    )
}

const NextButton = props => {
    const { className, onClick } = props
    return (
        <div onClick={onClick} style={{ zIndex: 1 }} className={className}>
            <img src={circleRightWhite} alt="carousel right button" />
        </div>
    )
}

const Testimonial = ({ userType = 'both' }) => {
    const { isMobile } = useMediaQueries()
    const filteredTestimonies = testimonies.filter(data => {
        if (userType === 'both') {
            return true
        }
        return data.user_type === userType
    })

    const settings = {
        className: 'center testimonial_slide',
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        draggable: true,
        infinite: true,
        speed: 500,
        variableWidth: isMobile ? false : true,
        dots: false,
        arrows: true,
        prevArrow: !isMobile && <PrevButton />,
        nextArrow: !isMobile && <NextButton />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    }

    return (
        <div className={styles.wrapper}>
            <h2>
                Trusted by our{' '}
                {userType === 'both'
                    ? 'partners and customers'
                    : userType === 'provider'
                    ? 'partners'
                    : 'customers'}
            </h2>
            <Slider {...settings}>
                {filteredTestimonies.map(data => (
                    <div className={styles.card} key={data.id}>
                        <span>{data.user_type}</span>
                        <p>{data.testimony}</p>
                        <div>
                            <img src={data.img} alt={data.name} />
                            <div>
                                <h3>{data.name}</h3>
                                <p>{data.description}</p>
                                <a
                                    href={data.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data.link_text}
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default Testimonial

PrevButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
}

NextButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
}

Testimonial.propTypes = {
    userType: PropTypes.string, //both, consumers, providers
}
