import { convertToSentenceCase } from '../../../utils/formatting'

export const formatAttributes = attributes => {
  let formattedValues = []

  Object.keys(attributes).forEach(key => {
    formattedValues.push({
      name: convertToSentenceCase(key),
      attributeValue: attributes[key]?.value,
      unit: attributes[key]?.unit_of_measurement,
    })
  })

  return formattedValues
}
