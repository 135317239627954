import { useState, useEffect } from 'react'

const useCountdownTimer = (seconds, onTimeEnd, isActive) => {
    const [timeLeft, setTimeLeft] = useState(seconds)

    useEffect(() => {
        if (timeLeft === 0 && isActive) {
            if (onTimeEnd) {
                onTimeEnd()
            }
            return
        }

        const intervalId = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [timeLeft, onTimeEnd, isActive])

    return {
        timeLeft,
    }
}

export default useCountdownTimer
