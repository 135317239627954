import { permissionsControl } from 'src/utils/permissionsControl'

export const moreActions = [
    { id: 1, label: 'assign to resource', action: 'Assign To Resource' },
    { id: 2, label: 'deactivate provider', action: 'Deactivate Provider' },
]

export const actionMappings = {
    'deactivate-provider': {
        headerText: 'Provider Deactivated Successfully',
        subTitle: 'Provider has been deactivated successfully.',
    },
    'assign-resource': {
        headerText: 'Resource Added Successfully',
        subTitle: 'Resource has been successfully assigned to the provider',
    },
    'add-user': {
        headerText: 'Invitation Successfully Sent',
        subTitle: 'The invitation has been successfully sent to the user.',
    },
    default: {
        headerText: '',
        subTitle:
            'We’ve solved the problems that make it difficult for energy providers to transition millions',
    },
}

export const userTableActionModalMappings = {
    'resend-invite': {
        actionType: 'warning',
        headerText: 'Resend Invitation?',
        subTitle:
            'We will be sending another invitation to this user, would you like to continue?',
        actionText: 'Resend',
    },
    'revoke-invite': {
        actionType: 'delete',
        headerText: 'Revoke Invitation?',
        subTitle:
            'The invitation sent to the user will be revoked, would you like to continue?',
        actionText: 'Revoke',
    },
    default: {
        actionType: '',
        headerText: '',
        subTitle: '',
        actionText: '',
    },
}

export const userTableSuccessActionModalMappings = {
    'resend-invite': {
        successSubTitle:
            'The invitation has been successfully sent to the user.',
    },
    'revoke-invite': {
        successSubTitle: 'The invitation has successfully been revoked.',
    },
    default: {
        successSubTitle:
            'We’ve solved the problems that make it difficult for energy providers to transition millions',
    },
}

const checkCreateLibraryUserPermissions = () => {
    const permissions = {
        'Global Library Contributor': permissionsControl([
            'can_create_global_library_contributor',
        ]),
        'Global Library User': permissionsControl([
            'can_create_global_library_user',
        ]),
        'Subscription Model Provider': permissionsControl([
            'can_create_subscription_model_provider',
        ]),
    }
    return permissions
}

export const createLibraryUserPermissions = checkCreateLibraryUserPermissions()

export const hasAnyCreateLibraryUserPermission = Object.values(
    createLibraryUserPermissions,
).some(Boolean)

const checkCanRemoveResourcePermissions = () => {
    const permissions = {
        'Global Library Contributor': permissionsControl([
            'can_delete_global_library_contributor',
        ]),
        'Global Library User': permissionsControl([
            'can_delete_global_library_user',
        ]),
        'Subscription Model Provider': permissionsControl([
            'can_delete_subscription_model_provider',
        ]),
    }
    return permissions
}

export const canRemoveResourcePermissions = checkCanRemoveResourcePermissions()

export const canViewProviderUserList = permissionsControl([
    'can_view_provider_user_list',
])

export const canViewProviderUserDetails = permissionsControl([
    'can_view_provider_user_detail',
])

export const canUpdateProviderUserDetails = permissionsControl([
    'can_update_provider_user',
])

export const canInviteProviderUser = permissionsControl([
    'can_invite_provider_user',
])

export const canDeactivateExistingProvider = permissionsControl([
    'can_deactivate_user',
])
