import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { IconInputField } from 'src/components/InputFields/CostField'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import { InputFields } from 'src/components/InputFields'

const EmployeeData = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    isCustomerApproved,
}) => {
    return (
        <>
            <Label
                title={'Employee data'}
                subTitle={'Provide us with your data as an employee'}
            />

            <IconInputField
                icon={NairaIcon}
                name="netMonthlySalary"
                title="Net monthly salary"
                width={'100%'}
                value={inputs?.netMonthlySalary}
                handleChange={handleChange}
                onBlur={handleOnBlur}
                errorMessage={errors?.netMonthlySalary}
                disabled={isAdminUser || isCustomerApproved}
            />

            <InputFields
                name="employeeId"
                title="Employee ID"
                value={inputs.employeeId}
                handleChange={handleChange}
                errorMessage={errors?.employeeId}
                onBlur={handleOnBlur}
                disabled={isAdminUser || isCustomerApproved}
            />

            <VerticalSpacer top={12} />
        </>
    )
}

EmployeeData.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default EmployeeData
