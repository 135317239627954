//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { Pagination, Skeleton } from 'antd'
import BackNav from '../../components/BackNav'
import { AppButton } from '../../components/Button'
import EnergyRecommendation from './energyrecommendation'
import { NoFLoatingLabelInputFields } from '../../components/InputFields'
import Modal from '../../components/Modal'
import { InlineLoader } from '../../components/Loader'
import Toast from '../../components/Toast'
import EmptyState from '../../components/EmptyState'
import { getPackagesApi } from '../../api/products/library'
import { simulatePaymentPlanApi } from '../../api/estimations/estimations'
import { adminSimulatePaymentPlan } from '../../api/admin/credit-variables/detailed-page'
import './simulation-stepone.scss'
import { currencyFormatter } from '../../utils/currencyFormatter'
import { getPackages } from '../../api/admin/credit-variables/detailed-page'
import { errorHandler } from '../../utils/errorHandler'
import { decodeUserInfo } from '../../utils/auth'
import Label from 'src/components/Label'
import { isMobile } from 'src/utils/mediaQueries'
import SeoComponent from 'src/components/Seo'
import { appTracking } from 'src/utils/appTracker'

const SimulationStepOne = () => {
    let history = useHistory()
    const location = useLocation()
    const userInfo = decodeUserInfo()
    const providerCreditId = localStorage.getItem('providerCreditId')
    const providerId =
        localStorage.getItem('providerId') === 'undefined'
            ? userInfo?.providerId
            : localStorage.getItem('providerId')
    const isAdmin = userInfo?.isAdminWorkspaceUser === 'true'
    const [showModal, setShowModal] = useState(false)
    const [solution, setSolution] = useState([])
    const [packages, setPackages] = useState([])
    const [solutions, setSolutions] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [listTotal, setListTotal] = useState(1)
    const [planType, setPlanType] = useState('')
    const [upfrontDeposit, setUpfrontDeposit] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [searchInput, setSearchInput] = useState('')
    const [energySolutionsLoading, setEnergySolutionsLoading] = useState(true)
    const [adminSimulateData, setAdminSimulateData] = useState({})
    const [margin, setMargin] = useState({
        action: '',
        amount: '',
        percentage: '',
        isExisting: false,
    })
    const [shouldFetchSinglePackage, setShouldFetchSinglePackage] =
        useState(false)

    const pageTitle = 'SunFi - Create A Payment Plan | Payment Plans'
    const pageUrlName = window.location.pathname
    const pageTracker = 'SIMULATE_PAYMENT_PLAN_TRACKER'

    useEffect(() => {
        const cachedSolution = JSON.parse(localStorage.getItem('solutions'))
        const formatSelectedPlanType = location?.state?.formatSelectedPlanType
        const formatUpfrontDeposit = location?.state?.upfrontDeposit
        if (cachedSolution?.length > 0) {
            setSolutions(cachedSolution)
        }

        if (formatSelectedPlanType) {
            setPlanType(formatSelectedPlanType)
            localStorage.setItem(
                'formatSelectedPlanType',
                formatSelectedPlanType,
            )
        } else {
            setPlanType(localStorage.getItem('formatSelectedPlanType'))
        }

        if (formatUpfrontDeposit) {
            setUpfrontDeposit(formatUpfrontDeposit)
            localStorage.setItem('formatUpfrontDeposit', formatUpfrontDeposit)
        } else {
            setUpfrontDeposit(localStorage.getItem('formatUpfrontDeposit'))
        }
    }, [])

    useEffect(() => {
        if (isAdmin) {
            setAdminSimulateData(
                JSON.parse(localStorage.getItem('simulatePlanData')),
            )
        }
    }, [isAdmin])

    const handleShowModal = () => {
        setShowModal(true)
        setShouldFetchSinglePackage(true)

        //set shouldFetchSinglePackage to false after 2000mms, so it'll always be set back to true when handleShowModal is clicked
        const timeout = setTimeout(
            () => setShouldFetchSinglePackage(false),
            2000,
        )
        return () => clearTimeout(timeout)
    }

    const handleSelect = data => {
        setSolutions([...solutions, data])
    }
    const handleUnselect = data => {
        const a = solutions.filter(sol => sol.id !== data.id)
        setSolutions(a)
    }

    const checkSelected = solution => {
        return solutions?.find(sol => sol.id === solution.id)
    }

    const data = solutions?.map(sol => {
        return {
            name: sol.name,
            cost: sol.total,
            id: sol?.id,
        }
    })

    useEffect(() => {
        location?.state !== true && localStorage.removeItem('solutions')
        location?.state !== true && setSolutions([])
    }, [])

    const { refetch: fetchPackages } = useQuery(
        ['fetchAllPackages', currentPage],
        () =>
            isAdmin
                ? getPackages(
                      searchInput.length > 0 ? 1 : currentPage,
                      searchInput,
                      providerId,
                      JSON.parse(localStorage.getItem('simulatePlanData'))
                          ?.cluster_code,
                  )
                : getPackagesApi(
                      searchInput.length > 0 ? 1 : currentPage,
                      searchInput,
                  ),
        {
            enabled: true,
            retry: false,
            onSuccess: data => {
                setPackages(data?.data?.data)
                setListTotal(data?.data?.total_pages)
                setEnergySolutionsLoading(false)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to fetch packages. Refresh page')
            },
        },
    )
    const {
        isFetching: simulatePaymentPlansFetching,
        refetch: simulatePaymentPlanRefetch,
    } = useQuery(
        'simulatePaymentPlan',
        () =>
            isAdmin
                ? adminSimulatePaymentPlan({
                      packages: data,
                      plan_type: adminSimulateData.plan_type,
                      upfront_deposit: parseFloat(
                          adminSimulateData.upfront_deposit,
                      ),
                      provider_id: providerId,
                      cluster_code: adminSimulateData?.cluster_code,
                      margin:
                          margin.action === ''
                              ? 0
                              : margin.action === 'Add Margin'
                              ? margin.percentage
                              : margin.percentage < 0
                              ? margin.percentage
                              : -margin.percentage,
                  })
                : simulatePaymentPlanApi({
                      plan_type: planType,
                      packages: data,
                      upfront_deposit: parseFloat(upfrontDeposit),
                      margin:
                          margin.action === ''
                              ? 0
                              : margin.action === 'Add Margin'
                              ? margin.percentage
                              : margin.percentage < 0
                              ? margin.percentage
                              : -margin.percentage,
                  }),
        {
            enabled: false,
            refetchIntervalInBackground: true,
            retry: false,
            onSuccess: data => {
                history.push({
                    state: data?.data?.data,
                    pathname: `/app/payment-plans/simulate/two`,
                })
                setMargin({
                    action: '',
                    amount: '',
                    percentage: '',
                    isExisting: false,
                })
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
                setToastError(true)
            },
        },
    )

    useEffect(() => {
        searchInput === '' && setCurrentPage(1)
        searchInput === '' && setEnergySolutionsLoading(true)
        searchInput === '' && fetchPackages()
    }, [searchInput])

    const handleSearchInput = e => {
        const { value } = e.target
        setSearchInput(value)
    }
    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    const handleOnBlur = e => {
        const { value } = e.target
        setSearchInput(value.trim())
    }

    const handleSearch = () => {
        fetchPackages()
        setEnergySolutionsLoading(true)
    }

    const handlePage = pageNumber => {
        setCurrentPage(pageNumber)
        fetchPackages()
        setEnergySolutionsLoading(true)
    }

    const PaginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={currentPage}
                defaultPageSize={1}
                total={parseInt(listTotal, 10)}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={value => handlePage(value)}
            />
        )
    }

    const handleCancel = () => {
        setShowModal(false)
        setMargin({
            action: '',
            amount: '',
            percentage: '',
            isExisting: false,
        })
    }

    return (
        <>
            <SeoComponent
                title="Simulate Payment Plans - Step One | SunFi | Simplifying and Scaling Clean Energy"
                tracker="SimulatePaymentPlansTracker"
            />
            <Modal
                app="energy"
                showModal={showModal}
                modalWidth="550px"
                modalHeight="100%"
                noPaddingBoth
                onCancel={handleCancel}
                content={
                    <EnergyRecommendation
                        solution={solution}
                        onSelect={handleSelect}
                        closeModal={() => setShowModal(false)}
                        openModal={() => setShowModal(true)}
                        margin={margin}
                        setMargin={setMargin}
                        shouldFetchSinglePackage={shouldFetchSinglePackage}
                    />
                }
            />
            <div className="SimulationContainer">
                <BackNav
                    title={
                        isAdmin ? 'Back to Provider' : 'Back to Payment Plans'
                    }
                    top={isMobile ? '-30px' : '-50px'}
                    onClick={() =>
                        history.push(
                            isAdmin
                                ? `/admin/credit/view/${providerCreditId}`
                                : '/app/payment-plans',
                        )
                    }
                />
                <h1 className="SimulationTitle">Simulate Payment Plans</h1>
                {toastError && (
                    <Toast message={errorMessage} messageType="error" />
                )}
                <div className="SimulationStepsContainer">
                    <div className="SimulationStepsChildContainer">
                        <button className="SimulationStepsButton SimulationActiveStepsButton">
                            1
                        </button>
                        <p className="StepsText">Energy Solution</p>
                    </div>
                    <div className="SimulationStepsLine"></div>
                    <div className="SimulationStepsChildContainer">
                        <button className="SimulationStepsButton SimulationPointerStyle">
                            2
                        </button>
                        <p className="StepsText">Payment Plans</p>
                    </div>
                </div>
                <div className="SimulationEnergySolutionContainer">
                    <div className="SimulationTopContent">
                        <div>
                            <p className="SimulationFirstText">
                                Select upto 3 solutions
                            </p>
                            <p className="SimulationSecondText">
                                Select Energy Solution
                            </p>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <NoFLoatingLabelInputFields
                                inputHeight="44px"
                                inputWidth="361px"
                                borderRadius="10px"
                                prefilled
                                iconType="search"
                                inputBackground="#F6F6F6"
                                type="text"
                                inputPlaceholder="Search Products"
                                TextPrefilled={searchInput ? true : false}
                                value={searchInput}
                                handleChange={handleSearchInput}
                                onBlur={handleOnBlur}
                                onIconClick={handleSearch}
                                onKeyUp={handleKeyPress}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: 40 }}>
                        {energySolutionsLoading ? (
                            <div className="EnergySolutionSelectContainer">
                                {[...Array(8)].map(i => (
                                    <div
                                        key={i}
                                        className="EnergySolutionSelectChildContainer StepOne"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Skeleton.Button
                                            active
                                            style={{ width: '200px' }}
                                        />
                                        <Skeleton.Button active />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <>
                                {packages.length > 0 ? (
                                    <div className="SimulationEnergySolutionSelectContainer">
                                        {packages?.map(solution => (
                                            <div
                                                className="SimulationEnergySolutionSelectChildContainer"
                                                key={solution.id}
                                            >
                                                <div className="SimulationPackageName">
                                                    <p>{solution.name}</p>
                                                    {solution?.is_global ===
                                                    true ? (
                                                        <Label title="GLOBAL" />
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <div className="SimulationPackageAmountAndButton">
                                                    {!isMobile && (
                                                        <p className="SimulatedSolutionAmount">
                                                            {solution.currency}
                                                            {currencyFormatter(
                                                                solution.total,
                                                            )}
                                                        </p>
                                                    )}
                                                    <div className="SimulatedSolutionSelectBtn">
                                                        <AppButton
                                                            btnWidth="80px"
                                                            btnHeight="37px"
                                                            btnBgColor={
                                                                checkSelected(
                                                                    solution,
                                                                )
                                                                    ? 'var(--blue)'
                                                                    : '#E2EEFF'
                                                            }
                                                            btnTextColor={
                                                                checkSelected(
                                                                    solution,
                                                                )
                                                                    ? 'var(--white)'
                                                                    : 'var(--blue)'
                                                            }
                                                            opacityValue={
                                                                checkSelected(
                                                                    solution,
                                                                )
                                                                    ? '0.7'
                                                                    : '1'
                                                            }
                                                            handleClick={() => {
                                                                if (
                                                                    checkSelected(
                                                                        solution,
                                                                    )
                                                                ) {
                                                                    handleUnselect(
                                                                        solution,
                                                                    )
                                                                } else {
                                                                    setSolution(
                                                                        solution,
                                                                    )
                                                                    handleShowModal()
                                                                }
                                                            }}
                                                            disabled={
                                                                !checkSelected(
                                                                    solution,
                                                                ) &&
                                                                solutions.length ===
                                                                    3
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            {checkSelected(
                                                                solution,
                                                            )
                                                                ? 'Selected'
                                                                : 'Select'}
                                                        </AppButton>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        <EmptyState
                                            subTitle={
                                                'You do not have any products in your library.'
                                            }
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {!energySolutionsLoading && solutions.length > 0 && (
                    <div className="SelectedPaymentWrapper">
                        <div className="SelectedPaymentPlans">
                            <div className="SolutionsTextDesktop">
                                <p style={{ fontWeight: 500 }}>
                                    You selected {solutions.length}{' '}
                                    {solutions.length === 1
                                        ? 'Solution'
                                        : 'Solutions'}
                                </p>
                                <p
                                    style={{ fontWeight: 300 }}
                                    className="SolutionsTextListDesktop"
                                >
                                    &nbsp;
                                    {solutions.length > 0 && (
                                        <span>{`(${solutions.map(
                                            sol => sol.name,
                                        )})`}</span>
                                    )}
                                </p>
                            </div>

                            <AppButton
                                type="buttonTwo"
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                btnWidth="80px"
                                handleClick={() => {
                                    simulatePaymentPlanRefetch()
                                    localStorage.setItem(
                                        'solutions',
                                        JSON.stringify(solutions),
                                    )
                                    appTracking(
                                        pageUrlName,
                                        pageTracker,
                                        pageTitle,
                                        'SIMULATE_PAYMENT_PLAN_PACKAGES',
                                        'SIMULATE_PAYMENT_PLAN_PACKAGES',
                                        'SIMULATE_PAYMENT_PLAN',
                                        ['MP', 'GA'],
                                        'event',
                                        {
                                            event: {
                                                selected_packages:
                                                    solutions.map(item => ({
                                                        name: item?.name,
                                                        cost: item?.total,
                                                        components:
                                                            item?.components,
                                                    })),
                                            },
                                        },
                                    )
                                }}
                            >
                                {simulatePaymentPlansFetching ? (
                                    <InlineLoader />
                                ) : (
                                    'Continue'
                                )}
                            </AppButton>
                        </div>
                    </div>
                )}
                {!energySolutionsLoading && packages?.length > 0 && (
                    <div className="SimulationPagination">
                        <PaginationBlock />
                    </div>
                )}
            </div>
        </>
    )
}

export default SimulationStepOne
