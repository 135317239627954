import { ReactComponent as DeleteIcon } from 'src/assets/images/deletemodal-icon.svg'

const IdentityVerificationFailed = () => {
    return (
        <section className="SSEBVNFailedWrapper">
            <DeleteIcon />
            <h4>Verification is in progress, proceed with KYC</h4>
            <p>
                We cannot verify your ID card at this time, but you can continue
                with your KYC. We will notify you once we can verify your ID.
            </p>
        </section>
    )
}

export default IdentityVerificationFailed
