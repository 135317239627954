import axios from '../../config/axios'

export const getRecommendedSolutions = async (id, pageNumber) => {
    const request = await axios.get(
        `/user-services/estimations/${id}/recommended-solutions?current_page=${pageNumber}`,
    )
    return request
}

export const rerunPowerCurve = async (id, body) => {
    const request = await axios.post(
        `/user-services/estimations/${id}/rerun-power-curve`,
        body,
    )
    return request
}

export const updateSolutionComponents = async inputs => {
    const request = await axios.post(
        `/user-services/estimations/${inputs.estimationId}/offer-package-snapshot`,
        {
            id: inputs.packageId,
            components: inputs.components,
        },
    )
    return request
}

export const updateSolutionPaymentPlans = async inputs => {
    const request = await axios.post(
        `/user-services/estimations/${inputs.estimationId}/offer-package-payment-plans
        `,
        {
            solution_id: inputs.packageId,
            discard_plan_ids: inputs.discardedPaymentPlanIds,
            include_plan_ids: inputs.includedPaymentPlanIds,
        },
    )
    return request
}

export const updateRecommendationContextApi = async (
    id,
    note,
    remark,
    packageId,
) => {
    const request = await axios.post(
        `/user-services/estimations/${id}/update-recommendation-context`,
        {
            recommendation_remark: remark,
            recommendation_note: note,
            package_id: packageId,
        },
    )
    return request
}

export const updateAltRecommendationSnapshotApi = async (
    dropOffId,
    packageId,
    payload,
) => {
    const request = await axios.post(
        `/admin/sse-consumers/drop-offs/${dropOffId}/recommend-solutions/${packageId}`,
        payload,
    )
    return request
}
