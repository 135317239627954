import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { InputFields } from 'src/components/InputFields'
import ButtonComponent from 'src/components/Button'

import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { InlineLoader } from 'src/components/Loader'
import styles from './editmodal.module.scss'
import { useUpdateProviderBrands } from '../../hooks/useUpdateProviderBrands'
import { editBrandNameSchema } from 'src/utils/validationSchema'

const EditBrandModal = ({
    componentBrands,
    componentType,
    showModal,
    closeModal,
    providerId,
    handleRefetch,
}) => {
    const [brandNames, setBrandNames] = useState([])
    const [errors, setErrors] = useState(null)

    const { handleUpdateProviderBrands, isLoading } = useUpdateProviderBrands({
        showAlert: true,
        onSuccess: () => {
            handleRefetch && handleRefetch()
            closeModal && closeModal()
        },
    })

    const handleValidation = data => {
        editBrandNameSchema
            .validate(brandNames, { abortEarly: false })
            .then(validData => {
                data?.onSuccess && data.onSuccess(validData)
            })
            .catch(err => {
                let errList = null

                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })

                setErrors(errList)

                data?.onError && data.onError()
            })
    }

    const handleInputChange = (event, id) => {
        const { name, value } = event.target

        setBrandNames(prev => {
            const cloned = [...prev].map(brandName => {
                if (brandName.id === id) {
                    brandName.name = value
                }

                return brandName
            })

            return cloned
        })

        let clonedErrors = {
            ...errors,
            [name]: 'Name is required',
        }

        if (value) {
            delete clonedErrors[name]
        }

        const errorKeys = Object.keys(clonedErrors)

        if (errorKeys.length) {
            setErrors(clonedErrors)
        } else {
            setErrors(null)
        }
    }

    const handleSave = () => {
        handleValidation({
            onSuccess: validData => {
                handleUpdateProviderBrands({
                    providerId,
                    data: [
                        {
                            component_type_id: componentType.id,
                            component_brands: validData,
                        },
                    ],
                })
            },
        })
    }

    useEffect(() => {
        setBrandNames(
            componentBrands?.map(componentBrand => ({
                id: componentBrand.id,
                name: componentBrand.name,
            })) || [],
        )
    }, [componentBrands])

    return (
        <SecondaryModal
            type="recommendedEnergy"
            modalWidth="624px"
            modalHeight="760px"
            showModal={showModal}
            closable={true}
            onCancel={closeModal}
            content={
                <div className={styles.Container}>
                    <div className={styles.TitleWrapper}>
                        <h2>Added component brands</h2>
                        <p>Edit names of component brand added</p>
                    </div>
                    <section className={styles.InputSection}>
                        {brandNames?.map((brandName, i) => (
                            <InputFields
                                key={i}
                                title={`Added ${
                                    componentType?.name || ''
                                } brand `}
                                name={`[${i}].name`}
                                value={brandName.name}
                                errorMessage={errors?.[`[${i}].name`]}
                                handleChange={event => {
                                    handleInputChange(event, brandName.id)
                                }}
                            />
                        ))}
                    </section>
                    <div className={styles.ButtonWrapper}>
                        <ButtonComponent
                            type="primary"
                            btnWidth="160px"
                            btnHeight="56px"
                            handleClick={handleSave}
                        >
                            {isLoading ? <InlineLoader /> : 'Save'}
                        </ButtonComponent>
                    </div>
                </div>
            }
        />
    )
}

EditBrandModal.propTypes = {
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    providerId: PropTypes.string,
    componentBrands: PropTypes.array,
    componentType: PropTypes.object,
    handleRefetch: PropTypes.func,
}

export default EditBrandModal
