import PropTypes from 'prop-types'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { createBrandNamesApi } from 'src/api/products/component'
import { InputFields, TextArea } from 'src/components/InputFields'
import ConsumerModalCloseIcon from 'src/assets/images/consumer-modal-close-icon.svg'
import { ConsumerButton } from 'src/components/Button'
import Toast from 'src/components/Toast'
import { InlineLoader } from 'src/components/Loader'
import { errorHandler } from 'src/utils/errorHandler'
import ActionModal from 'src/components/ActionModal'

import styles from './addcomponent.module.scss'

const CreateBrand = ({ closeModal, handleSuccessSubmit }) => {
    const {
        CreateBrandWrapper,
        BrandHeader,
        BrandText,
        CloseIconWrapper,
        BrandForm,
    } = styles

    const [errors, setErrors] = useState({ brandName: '' })
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const initailInputs = {
        brandName: '',
        imageUrl: '',
        description: '',
    }

    const [inputs, setInputs] = useState(initailInputs)

    const handleInputChange = event => {
        const { name, value } = event.target
        setInputs(prev => ({ ...prev, [name]: value }))
        setErrors('')
    }

    const handleInputBlur = event => {
        const { name, value } = event.target
        setInputs(prev => ({ ...prev, [name]: value.trim() }))
    }

    const creatBrandPayload = {
        name: inputs?.brandName,
        ...(inputs?.imageUrl !== '' && {
            image_url: inputs?.imageUrl,
        }),
        ...(inputs?.description !== '' && {
            description: inputs?.description,
        }),
    }

    const { isFetching: createBrandFetching, refetch: createBrandRefetch } =
        useQuery('createBrand', () => createBrandNamesApi(creatBrandPayload), {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeModal()
                setShowSuccessModal(true)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error.response.data))
            },
        })

    const handleSubmit = () => {
        if (inputs.brandName === '') {
            setErrors({ brandName: 'This field is required' })
        } else {
            createBrandRefetch()
        }
    }

    const handleCloseSuccessModal = () => {
        handleSuccessSubmit()
        setShowSuccessModal(false)
        setInputs(initailInputs)
    }

    return (
        <>
            <ActionModal
                actionModalOpen={showSuccessModal}
                headerText="New brand added successfully"
                subTitle="Your new brand was added successfully and you can now see it on the list"
                closeModal={handleCloseSuccessModal}
                onCancel={handleCloseSuccessModal}
                actionHandler={handleCloseSuccessModal}
                actionType="success"
                actionText="Done"
                noCancelBtn
                closable
            />
            <section className={CreateBrandWrapper}>
                {toastError && (
                    <Toast messageType="error" message={errorMessage} />
                )}

                <header>
                    <div style={{ position: 'relative' }}>
                        <p className={BrandHeader}>Add a new component brand</p>
                        <span className={CloseIconWrapper}>
                            <img
                                src={ConsumerModalCloseIcon}
                                alt="close icon"
                                onClick={() => {
                                    closeModal()
                                    setInputs(initailInputs)
                                }}
                            />
                        </span>
                    </div>
                    <p className={BrandText}>
                        Add a new component brand that is not on the list
                    </p>
                </header>
                <form className={BrandForm}>
                    <InputFields
                        name="brandName"
                        value={inputs.brandName}
                        handleChange={handleInputChange}
                        paddingBottom="18px"
                        title="Brand name"
                        errorMessage={errors.brandName}
                        onBlur={handleInputBlur}
                        inputWidth="464px"
                        marginBottom="20px"
                    />
                    <InputFields
                        name="imageUrl"
                        value={inputs.imageUrl}
                        handleChange={handleInputChange}
                        paddingBottom="18px"
                        title="Image url"
                        errorMessage={errors.imageUrl}
                        onBlur={handleInputBlur}
                        inputWidth="464px"
                    />
                    <TextArea
                        width="464px"
                        height="136px"
                        placeholder={'Description'}
                        name="description"
                        value={inputs.description}
                        handleChange={handleInputChange}
                        errorMessage={errors.description}
                        padding="10px 20px"
                    />
                    <div style={{ marginTop: '25px' }}>
                        <ConsumerButton
                            btnBgColor="#004AAD"
                            btnTextColor="#FFF"
                            btnFontSize="14px"
                            btnHeight="56px"
                            btnWidth="160px"
                            handleClick={handleSubmit}
                        >
                            {createBrandFetching ? <InlineLoader /> : 'Confirm'}
                        </ConsumerButton>
                    </div>
                </form>
            </section>
        </>
    )
}

CreateBrand.propTypes = {
    closeModal: PropTypes.func,
    handleSuccessSubmit: PropTypes.func,
}

export default CreateBrand
