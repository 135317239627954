import PropTypes from 'prop-types'
import Button from 'src/components/Button'

import ClusterLayerDefault from 'src/assets/images/Layer.svg'
import ProductLayersDefault from 'src/assets/images/layers.svg'
import ClusterLayerActive from 'src/assets/images/Layer-white.svg'
import ProductLayersActive from 'src/assets/images/Layers-white.svg'
import { InlineLoader } from 'src/components/Loader'

import style from '../Estimations/customertypemodal.module.scss'

const SelectLibrary = ({
    loading = false,
    library,
    setLibrary,
    handleProductPackages,
}) => {
    const {
        ModalWrapper,
        HeaderWrapper,
        HeaderStyle,
        SubheaderStyle,
        SectionWrapper,
        UserImgStyle,
        SectionTextWrapper,
        SectionHeader,
        SectionText,
    } = style

    return (
        <div className={ModalWrapper}>
            <div className={HeaderWrapper} style={{ marginBottom: '30px' }}>
                <header className={HeaderStyle}>Choose a library</header>
                <h3 className={SubheaderStyle} style={{ width: '100%' }}>
                    Where do you want to add packages from
                </h3>
            </div>
            <section
                className={SectionWrapper}
                onClick={() => setLibrary('product')}
                style={{
                    background: library === 'product' ? '#E2EEFF' : '#fff',
                    border:
                        library === 'product'
                            ? '0.5px solid #004AAD'
                            : '0.5px solid #E5E5E5',
                }}
            >
                <div
                    className={UserImgStyle}
                    style={{
                        background: library === 'product' ? '#004AAD' : '#fff',
                    }}
                >
                    <img
                        src={
                            library === 'product'
                                ? ProductLayersActive
                                : ProductLayersDefault
                        }
                        alt="new-user-icon"
                    />
                </div>

                <div className={SectionTextWrapper}>
                    <h3 className={SectionHeader}>Choose from my library</h3>
                    <p className={SectionText}>
                        You want to add packages from your own library
                    </p>
                </div>
            </section>
            <section
                className={SectionWrapper}
                onClick={() => setLibrary('cluster')}
                style={{
                    background: library === 'cluster' ? '#E2EEFF' : '#fff',
                    border:
                        library === 'cluster'
                            ? '0.5px solid #004AAD'
                            : '0.5px solid #E5E5E5',
                }}
            >
                <div
                    className={UserImgStyle}
                    style={{
                        background: library === 'cluster' && '#004AAD',
                    }}
                >
                    <img
                        src={
                            library === 'cluster'
                                ? ClusterLayerActive
                                : ClusterLayerDefault
                        }
                        alt="new-user-icon"
                    />
                </div>
                <div className={SectionTextWrapper}>
                    <h3 className={SectionHeader}>Choose from a cluster</h3>
                    <p className={SectionText}>
                        You want to add packages from a cluster library
                    </p>
                </div>
            </section>

            <div style={{ marginTop: '20px' }}>
                <Button
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnWidth="160px"
                    btnHeight="56px"
                    disabled={library === ''}
                    handleClick={() => handleProductPackages()}
                >
                    {loading ? <InlineLoader /> : 'Continue'}
                </Button>
            </div>
        </div>
    )
}

SelectLibrary.propTypes = {
    setLibrary: PropTypes.func,
    library: PropTypes.string,
    handleProductPackages: PropTypes.func,
    loading: PropTypes.bool,
}

export default SelectLibrary
