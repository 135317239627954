/* eslint-disable react/prop-types */

import { useState, useEffect } from 'react'
import DraftedSchedule from '../AdminPlansTable/DraftedSchedule'
import SecondaryModal from '../../components/Modal/SecondaryModal'
import styles from './adminsolutionmodal.module.scss'
import './adminsolutionmodal.module.scss'
import { AppButton } from '../Button'

const PaymentPlan = ({
    setShowModal,
    closeModal,
    repaymentPlan,
    page,
    selectedPackageId,
    planType,
    markupValue,
    amortizationMonth,
}) => {
    const {
        MainWrapper,
        DepositTitle,
        ASMPaymentPlanTitle,
        PaymentPlanBox,
        PaymentPlanWrapper,
        ContentWrapper,
        ButtonWrapper,
        ASMDepositAmount,
        Plan,
        FigureWrapper,
        ASMFigure,
        MonthTitle,
        ASMMonth,
        Selected,
        ASMPaymentPlanScrollableHeight,
        ASMPlanSummary,
    } = styles

    const [repaymentPlans, setRepaymentPlans] = useState([])
    const [showDraftedSchedule, setShowDraftedSchedule] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState({})
    const handleShowDraftedSchedule = id => {
        const plan = repaymentPlans.find(plan => plan.id === id)
        setShowDraftedSchedule(true)
        setSelectedPlan(plan)
    }

    useEffect(() => {
        setRepaymentPlans(repaymentPlan)
    }, [repaymentPlan, showDraftedSchedule])

    return (
        <>
            <SecondaryModal
                showModal={showDraftedSchedule}
                onCancel={() => {
                    setShowDraftedSchedule(false)
                    setShowModal(true)
                }}
                modalHeight="450px"
                modalWidth="550px"
                modalClass="adminModal"
                content={
                    <DraftedSchedule
                        closeModal={() => {
                            setShowDraftedSchedule(false)
                            setShowModal(true)
                        }}
                        data={selectedPlan}
                        markupValue={markupValue}
                        planType={planType}
                        amortizationMonth={amortizationMonth}
                    />
                }
            />
            <div className={MainWrapper}>
                <div className={ASMPaymentPlanScrollableHeight}>
                    <h1 className={DepositTitle}>INITIAL DEPOSIT</h1>
                    <h2 className={ASMDepositAmount}>{`${
                        repaymentPlans[0]?.currency?.symbol
                    }${
                        repaymentPlans && repaymentPlans[0]?.initial_deposit
                    }`}</h2>
                    {page === 'requests' && planType === 'Subscription' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <div className={ASMPlanSummary}>
                                <div>
                                    <h2>AmortiSation Month</h2>
                                    <p>{amortizationMonth}</p>
                                </div>
                                <div>
                                    <h2>Markup Value</h2>
                                    <p>{markupValue}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <h2 className={ASMPaymentPlanTitle}>
                        Available Payment Plans
                    </h2>
                    {repaymentPlans?.map((plan, index) => {
                        return (
                            <div
                                key={plan.id}
                                onClick={() => {
                                    page === 'requests' &&
                                        handleShowDraftedSchedule(plan?.id)
                                    closeModal(selectedPackageId)
                                }}
                            >
                                <div
                                    style={{
                                        cursor:
                                            page === 'requests' && 'pointer',
                                    }}
                                >
                                    <div className={PaymentPlanWrapper}>
                                        <div className={PaymentPlanBox}>
                                            <div className={ContentWrapper}>
                                                <div>
                                                    <h3 className={Plan}>
                                                        Plan {index + 1}
                                                        {(plan.selected ===
                                                            true ||
                                                            plan.admin_approved ===
                                                                true) && (
                                                            <span
                                                                className={
                                                                    Selected
                                                                }
                                                            >
                                                                {plan.admin_approved ===
                                                                true
                                                                    ? 'APPROVED'
                                                                    : 'SELECTED'}
                                                            </span>
                                                        )}
                                                    </h3>
                                                    <p
                                                        className={
                                                            FigureWrapper
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                ASMFigure
                                                            }
                                                        >
                                                            {`₦${plan.monthly_payment}`}
                                                        </span>{' '}
                                                        / Month
                                                    </p>
                                                </div>
                                                {planType !==
                                                    'Subscription' && (
                                                    <div>
                                                        <h3
                                                            className={
                                                                MonthTitle
                                                            }
                                                        >
                                                            MONTHS OF REPAYMENT
                                                        </h3>
                                                        <p
                                                            className={ASMMonth}
                                                        >{`${plan.tenure} Months`}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={ButtonWrapper}>
                    <AppButton
                        btnTextColor="var(--white)"
                        btnBgColor="var(--blue)"
                        btnWidth="160px"
                        btnHeight="56px"
                        handleClick={closeModal}
                    >
                        {' '}
                        Okay, Got it
                    </AppButton>
                </div>
            </div>
        </>
    )
}

export default PaymentPlan
