export const onboardingStage = {
  COMPLETED_PERSONAL_INFORMATION: 'COMPLETED_PERSONAL_INFORMATION',
  COMPLETED_WORK_HISTORY: 'COMPLETED_WORK_HISTORY',
  COMPLETED_IDENTITY_INFORMATION: 'COMPLETED_IDENTITY_INFORMATION',
  COMPLETED_SIGN_UP: 'COMPLETED_SIGN_UP'
}

export const stageNumber = {
  COMPLETE_PERSONAL_INFORMATION: 0,
  COMPLETE_WORK_HISTORY: 1,
  COMPLETE_IDENTITY_INFORMATION: 2,
  COMPLETE_COMPANY_INFORMATION: 0,
  COMPLETE_CONTACT_INFORMATION: 1
}
