export const ASITermsOfService = [
    {
        title: 'Introduction',
        text: 'Please read these Terms of Service (“Terms”), which set forth the legally binding terms and conditions between you and SunFi Technology Solutions Ltd ("<b>The Company</b>" or "<b>SunFi</b>" or "<b>we</b>" or "<b>our</b>" or "<b>us</b>"). It governs your access to and the use of SunFi’s website, mobile application and the services provided by SunFi.</br><div style="margin-bottom: 10px"></div>Our collection and use of your personal information in connection with your use of our services is described in our <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a>.  Your access to our services is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all Associate Installers who access or use our services.</br><div style="margin-bottom: 10px"></div>Our website is not directed at you if we are prohibited by any law of any jurisdiction from making the information on our website available to you and is not intended for any use that would be contrary to your local law or regulation. By accessing or using our services, you agree to be bound by these Terms. If you disagree with any part of these Terms then you may not access our services.',
    },
    {
        title: 'Definition',
        text: 'In these Terms,',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    '<strong style="color: #011A3C">Account</strong><br /><div style="margin-bottom: 5px"></div>Account means a unique personified account registered in the name of the User and which contains details of the User’s transactions/operations on the Platform (as defined below).',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Applicable Laws</strong><br /><div style="margin-bottom: 5px"></div>Applicable Laws means all applicable regulations, laws, codes, guidelines, policies, rules, and directives of any competent regulatory authority in the Federal Republic of Nigeria.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Associate Installer</strong><br /><div style="margin-bottom: 5px"></div>Associate Installer means users who have the verified skills of a solar, sales and installation Associate.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Consumer(s)</strong><br /><div style="margin-bottom: 5px"></div>Consumer means the customers of the Associate Installer or the Company.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Platform</strong><br /><div style="margin-bottom: 5px"></div>Platform means SunFi’s website at <a href="https://www.sunfi.co" target="_blank">sunfi.co.</a>',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Service(s)</strong><br /><div style="margin-bottom: 5px"></div>Service smeans all services provided by SunFi and as described in clause 3 of these Terms.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">System</strong><br /><div style="margin-bottom: 5px"></div>System means the energy solutions provided by the Associate Installer or the Company.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">User</strong><br /><div style="margin-bottom: 5px"></div>User means the Associate Installer.',
            },
        ],
    },
    {
        title: 'Acceptance of Terms',
        text: "Our Services are offered subject to acceptance of all the terms and conditions contained in these Terms and all other operating rules, policies, and procedures that may be published on the Platform or signed as an agreement with you, which are incorporated by reference, including operating rules, policies, and procedures of third-party service providers to the Platform that are referenced herein. These Terms apply to all Associate Installers that we onboard on our Platform. In addition, some Services offered through the Platform may be subject to additional terms and conditions adopted by the Platform. Your use of those services is subject to those additional terms and conditions, which are incorporated into these Terms by this reference.</br><div style='margin-bottom: 10px'></div>SunFi reserves the right, at its sole discretion, to modify or replace these Terms from time to time by posting the updated terms on the Platform. It is your responsibility to check the Terms periodically for changes. If you object to any such changes, your sole recourse will be to cease using the Platform and the Service.  Your continued use of the Service following the posting of any changes to the Terms will indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</br><div style='margin-bottom: 10px'></div>SunFi reserves the right to change, suspend, or discontinue the Service (including, but not limited to, the availability of any feature, database, or Content) at any time for any reason. SunFi may also impose limits on certain features and Services or restrict your access to parts of or the entire Platform without notice or liability.",
    },
    {
        title: 'Scope of SunFi’s Services',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'SunFi is a technology company that provides energy financing solutions with the objective to simplify and scale access to clean energy for consumers in Africa.',
            },
            {
                subtext: 'Our Services can be accessed through our Platform.',
            },
        ],
    },
    {
        title: 'SunFi Account',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'SunFi may make the access to the Platform and the Services, or certain areas or features of the Platform, subject to certain conditions or requirements, such as, meeting specific eligibility criteria.',
            },
            {
                subtext:
                    'To access our Services as an Distributed Energy Provider, you are required to sign up on the Platform as an Distributed Energy Provider and create an account (SunFi Account) by providing us the following information:',
            },
            {
                subtext:
                    'You have a responsibility to ensure that no third-party gains access to your account information.',
            },
            {
                subtext:
                    'You agree to strictly observe the security and authentication procedures of the Platform and you will log out from the Platform at the end of every visit.',
            },
            {
                subtext:
                    'When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of these Terms, which may result in immediate termination of your account on our Platform and the Service.',
            },
        ],
    },
    {
        title: 'Users’ Representations & Warranties',
        text: 'Representations and warranties are statements and promises made by you to SunFi, which we rely on as being accurate in our dealings with you. You make the following representations and warranties to us at the time of agreeing to these Terms and every time you use the Services;',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext: 'you are over the age of 18 years;',
            },
            {
                subtext:
                    'you are of sound mind and have the capacity to enter into a legally binding contract;',
            },
            {
                subtext:
                    'all personal information that you provide about yourself is accurate and true to the best of your knowledge;',
            },
            {
                subtext:
                    'all information that you provide about your company is accurate and true;',
            },
            {
                subtext:
                    'you are a skilled solar system installer and have relevant work experience providing installation services; ',
            },
            {
                subtext:
                    'all information that you provide about any Consumer is accurate;',
            },
            {
                subtext:
                    'you shall not create more than one account or create an account for anyone other than yourself (with the exception of accounts created for corporate organisations); and',
            },
            {
                subtext:
                    'you are not breaching any Applicable Laws or regulations that are applicable to you or any company, trust or partnership. ',
            },
        ],
    },
    {
        title: 'Consumers',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'You can use our Services to provide financing solutions to the Consumers that you provide Systems to. ',
            },
            {
                subtext:
                    'Our financing solutions are based on two models – a lease-to-own model and a subscription model. The lease-to-own model entails the Consumers making monthly payments for the System you provide during the lease period and the Consumer has the option of purchasing the System. The subscription model entails the Consumers paying a subscription fee for their use of the System during the subscription period. ',
            },
            {
                subtext:
                    'Depending on the model the Consumers opt for, the terms of the financing will be governed by a separate agreement - Consumer Financing Agreement between SunFi and the Consumers. The Consumers will also be bound by our Terms of Service for Consumers and our <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a>. The terms of the Consumer Financing Agreement will supersede our Terms of Service for Consumers in the event of any dispute.',
            },
        ],
    },
    {
        title: 'Payment',
        text: 'Based on the financing model and payment plan chosen by the Consumer, the applicable payments to be made by the Consumer for the System shall be detailed in the Consumer Financing Agreement.',
    },
    {
        title: 'Obligations of the Distributed Energy Provider',
        text: 'As an Distributed Energy Provider, you shall:',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'provide your services to the Consumers with reasonable care and skill;',
            },
            {
                subtext:
                    'where required, use commercially reasonable efforts to obtain all permits and approvals necessary for the provision of the System to the Consumers;',
            },
            {
                subtext:
                    'be responsible for the construction and installation of the System at the facility of the Consumer;',
            },
            {
                subtext:
                    'provide clear communication channels and technical support to the Consumers;',
            },
            {
                subtext:
                    'be responsible for the actions, inactions or omissions of any subcontractor or contractors that it engages to provide its services to the Consumers;',
            },
            {
                subtext:
                    'where required by SunFi, provide routine maintenance and repair services to the SunFi’s Consumers; and',
            },
            {
                subtext:
                    'be responsible for removing the System from the facilities of your Consumers at the termination or expiration of your service relationship with the Consumers.',
            },
        ],
    },
    {
        title: 'Intellectual Property',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'The Service and all rights in our product name, trade names, logos, service marks, slogans, product packaging, its original content, features and functionality are and will remain the exclusive property of SunFi. Our trademarks may not be used in connection with any product or service without our prior written consent.',
            },
            {
                subtext:
                    'SunFi may, at its sole discretion, enable you to (i) create, upload, post, send, receive and store content, such as text, photos, audio, video, or other materials and information on or through the Platform ("User Content"); and (ii) access and view User Content and any content that SunFi itself makes available on or through the Platform, including proprietary content and any content licensed or authorized for use by or through SunFi from a third party ("SunFi’s Content" and together with User Content, "Collective Content").',
            },
            {
                subtext:
                    'You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the Platform or Collective Content, except to the extent that you are the legal owner of certain User Content or as expressly permitted in these Terms. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by SunFi or its licensors, except for the licenses and rights expressly granted in these Terms.',
            },
            {
                subtext:
                    'Subject to your compliance with these Terms, SunFi grants you a limited, non-exclusive, non-sub licensable, revocable, non-transferable license to access and view any Collective Content made available on or through the Platform and accessible to you, solely for your personal and non-commercial use.',
            },
            {
                subtext:
                    'You shall not directly or indirectly: (i) decipher, decompile, disassemble, reverse engineer, or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Service, except to the extent applicable laws specifically prohibit such restriction; (ii) modify, translate, or otherwise create derivative works of any part of the Service; or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder.',
            },
        ],
    },
    {
        title: 'Prohibited Activities',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'You shall not use the Platform for any purpose that is prohibited by these Terms. You are responsible for all of your activity in connection with the Service. Violation of our rules may result in the termination and cancellation of your Account. You acknowledge and agree that we may terminate your SunFi Account at any time for any reason (including, but not limited to, our independent assessment or the receipt of claims or allegations from third parties or authorities).',
            },
            {
                subtext:
                    'You shall not utilize the Platform or the Services for any illegal purpose.',
            },
            {
                subtext: {
                    text: 'You are solely responsible for compliance with any and all laws, rules, regulations, and tax obligations that may apply to your use of the Services. In connection with your use of the Platform, you will not and will not assist or enable others to:',
                    type: '',
                    children: [
                        'breach or circumvent any applicable laws or regulations, agreements with third-parties, third-party rights, or our Terms;',
                        'use the Platform or any content therein for any commercial or other purposes that are not expressly permitted by these Terms or in a manner that falsely implies endorsement, partnership or otherwise misleads others as to your affiliation with SunFi;',
                        'copy, store or otherwise access or use any information, including personally identifiable information about any other User, contained on the Platform in any way that is inconsistent with SunFi’s <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a> or these Terms or that otherwise violates the privacy rights of Users or third parties;',
                        'use the Platform in connection with the distribution of unsolicited commercial messages ("spam");',
                        'discriminate against or harass anyone on the basis of race, tribe, origin, religion, gender, physical or mental disability, medical condition, marital status, or age, or otherwise engage in any abusive or disruptive behaviour;',
                        'use, display, mirror or frame the Platform or Collective Content, or any individual element within the Platform, SunFi’s name, any trademark, logo or other proprietary information belonging to SunFi, or the layout and design of any page or form contained on a page in the Platform, without SunFi’s express written consent;',
                        'dilute, tarnish or otherwise harm SunFi’s brand in any way, including through unauthorized use of Collective Content, registering and/or using derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domains names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to SunFi’s domains, trademarks, taglines, promotional campaigns or Collective Content;',
                        'use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Platform for any purpose;',
                        'avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent any technological measure implemented by SunFi or any of SunFi’s providers or any other third party to protect the Platform;',
                        'attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Platform;',
                        'take any action that damages or adversely affects, or could damage or adversely affect the performance or proper functioning of the Platform;',
                        'violate or infringe anyone else’s rights or otherwise cause harm to anyone.',
                    ],
                },
            },
            {
                subtext:
                    'You acknowledge that SunFi has no obligation to monitor the access to or use of the Platform by any User or to review, disable access to, or edit any User Content, but has the right to do so to (i) operate, secure and improve the Platform (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes); (ii) ensure Users’ compliance with these Terms; (iii) comply with applicable law or the order or requirement of a court, law enforcement or other administrative agency or governmental body; (iv) respond to User Content that it determines is harmful or objectionable; or (v) as otherwise set forth in these Terms. Users agree to cooperate with and assist SunFi in good faith, and to provide SunFi with such information and take such actions as may be reasonably requested by SunFi with respect to any investigation undertaken by SunFi or a representative of SunFi regarding the use or abuse of the Platform.',
            },
        ],
    },
]

export const DISITermsOfService = [
    {
        title: 'Introduction',
        text: 'Please read these Terms of Service (“Terms”), which set forth the legally binding terms and conditions between you and SunFi Technology Solutions Ltd ("<b>The Company</b>" or "<b>SunFi</b>" or "<b>we</b>" or "<b>our</b>" or "<b>us</b>"). It governs your access to and the use of SunFi’s website, mobile application and the services provided by SunFi.</br><div style="margin-bottom: 10px"></div>Our collection and use of your personal information in connection with your use of our services is described in our <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a>.  Your access to our services is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all Distributed Energy Provider who access or use our services.</br><div style="margin-bottom: 10px"></div>Our website is not directed at you if we are prohibited by any law of any jurisdiction from making the information on our website available to you and is not intended for any use that would be contrary to your local law or regulation. By accessing or using our services, you agree to be bound by these Terms. If you disagree with any part of these Terms then you may not access our services.',
    },
    {
        title: 'Definition',
        text: 'In these Terms,',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    '<strong style="color: #011A3C">Account</strong><br /><div style="margin-bottom: 5px"></div>Account means a unique personified account registered in the name of the User and which contains details of the User’s transactions/operations on the Platform (as defined below).',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Applicable Laws</strong><br /><div style="margin-bottom: 5px"></div>Applicable Laws means all applicable regulations, laws, codes, guidelines, policies, rules, and directives of any competent regulatory authority in the Federal Republic of Nigeria.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Distributed Energy Provider(s)</strong><br /><div style="margin-bottom: 5px"></div>Distributed Energy Provider(s) means users who own solar sales and installation businesses.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Consumer(s)</strong><br /><div style="margin-bottom: 5px"></div>Consumer means the customers of the Distributed Energy Provider.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Platform</strong><br /><div style="margin-bottom: 5px"></div>Platform means SunFi’s website at <a href="https://www.sunfi.co" target="_blank">sunfi.co.</a>',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Service(s)</strong><br /><div style="margin-bottom: 5px"></div>Service smeans all services provided by SunFi and as described in clause 3 of these Terms.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">System</strong><br /><div style="margin-bottom: 5px"></div>System means the energy solutions provided by the Distributed Energy Provider.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">User</strong><br /><div style="margin-bottom: 5px"></div>User means the Distributed Energy Providers.',
            },
        ],
    },
    {
        title: 'Acceptance of Terms',
        text: "Our Services are offered subject to acceptance of all the terms and conditions contained in these Terms and all other operating rules, policies, and procedures that may be published on the Platform or signed as an agreement with you, which are incorporated by reference, including operating rules, policies, and procedures of third-party service providers to the Platform that are referenced herein. These Terms apply to all Distributed Energy Providers that we onboard on our Platform. In addition, some Services offered through the Platform may be subject to additional terms and conditions adopted by the Platform. Your use of those services is subject to those additional terms and conditions, which are incorporated into these Terms by this reference.</br><div style='margin-bottom: 10px'></div>SunFi reserves the right, at its sole discretion, to modify or replace these Terms from time to time by posting the updated terms on the Platform. It is your responsibility to check the Terms periodically for changes. If you object to any such changes, your sole recourse will be to cease using the Platform and the Service.  Your continued use of the Service following the posting of any changes to the Terms will indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</br><div style='margin-bottom: 10px'></div>SunFi reserves the right to change, suspend, or discontinue the Service (including, but not limited to, the availability of any feature, database, or Content) at any time for any reason. SunFi may also impose limits on certain features and Services or restrict your access to parts of or the entire Platform without notice or liability.",
    },
    {
        title: 'Scope of SunFi’s Services',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'SunFi is a technology company that provides energy financing solutions with the objective to simplify and scale access to clean energy for consumers in Africa.',
            },
            {
                subtext: 'Our Services can be accessed through our Platform.',
            },
        ],
    },
    {
        title: 'SunFi Account',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'SunFi may make the access to the Platform and the Services, or certain areas or features of the Platform, subject to certain conditions or requirements, such as, meeting specific eligibility criteria.',
            },
            {
                subtext: {
                    text: 'To access our Services as a Distributed Energy Provider, you are required to sign up on the Platform as a Distributed Energy Provider and create an account (SunFi Account) by providing us with the following information:',
                    type: 'decimal-numbered-list',
                    children: [
                        'The name of your company',
                        'Your company’s address, email address and phone number',
                        'The nature of your company’s business',
                        'Your company’s website',
                        'The number of employees you have',
                        'The kind of equipment you supply',
                        'Consumer basic information',
                        'Consumer credit information, If available',
                    ],
                },
            },
            {
                subtext:
                    'You have a responsibility to ensure that no third-party gains access to your account information.',
            },
            {
                subtext:
                    'You agree to strictly observe the security and authentication procedures of the Platform and you will log out from the Platform at the end of every visit.',
            },
            {
                subtext:
                    'When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of these Terms, which may result in immediate termination of your account on our Platform and the Service.',
            },
        ],
    },
    {
        title: 'Users’ Representations & Warranties',
        text: 'Representations and warranties are statements and promises made by you to SunFi, which we rely on as being accurate in our dealings with you. You make the following representations and warranties to us at the time of agreeing to these Terms and every time you use the Services;',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'your company is duly registered under the laws of the country of its incorporation;',
            },
            {
                subtext:
                    'all information that you provide about any Consumer that accesses our Services through you is accurate;                    ',
            },
            {
                subtext:
                    'you have obtained the consent of your Consumers to share their personal data with us;',
            },
            {
                subtext:
                    'you shall not create more than one account for your company; and',
            },
            {
                subtext:
                    'you are not breaching any Applicable Laws or regulations that are applicable to you or any company, trust or partnership.',
            },
        ],
    },
    {
        title: 'Consumers',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'You can use our Services to provide financing solutions to the Consumers that you provide Systems to. ',
            },
            {
                subtext:
                    'Our financing solutions are based on two models – a lease-to-own model and a subscription model. The lease-to-own model entails the Consumers making monthly payments for the System you provide during the lease period and the Consumer has the option of purchasing the System. The subscription model entails the Consumers paying a subscription fee for their use of the System during the subscription period. ',
            },
            {
                subtext:
                    'Depending on the model the Consumers opt for, the terms of the financing will be governed by a separate agreement - Consumer Financing Agreement between SunFi and the Consumers. The Consumers will also be bound by our Terms of Service for Consumers and our <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a>. The terms of the Consumer Financing Agreement will supersede our Terms of Service for Consumers in the event of any dispute.',
            },
        ],
    },
    {
        title: 'Payments',
        text: 'Based on the financing model and payment plan chosen by the Consumer, the applicable payments to be made by the Consumer for the System shall be detailed in the Consumer Financing Agreement.',
    },
    {
        title: 'Obligations of the Distributed Energy Provider',
        text: 'As an Distributed Energy Provider, you shall:',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'use commercially reasonable efforts to obtain, at your sole cost and expense, all permits and approvals necessary for the provision of the System to the Consumers;',
            },
            {
                subtext: 'provide the System to the Consumers;',
            },
            {
                subtext:
                    'provide SunFi with remote monitoring and shut down access and capabilities to all Systems financed by SunFi;',
            },
            {
                subtext:
                    'be responsible for the construction and installation of the System at the facilities of the Consumers;',
            },
            {
                subtext:
                    'be responsible for performing all routine maintenance and repairs on the System;',
            },
            {
                subtext:
                    'execute your services to the Consumers with reasonable care and skill;',
            },
            {
                subtext:
                    'provide clear communication channels; technical support and customer support services to the Consumers;',
            },
            {
                subtext:
                    'honour warranties and representations made to the Consumers;',
            },
            {
                subtext:
                    'handle all complaints of the Consumers relating to the System;',
            },
            {
                subtext:
                    'be responsible for the actions, inactions or omissions of any subcontractor or contractors that it engages to provide its services to the Consumers; and',
            },
            {
                subtext:
                    'be responsible for removing the System from the facilities of its Consumers at the termination or expiration of its service relationship with the Consumers.',
            },
        ],
    },
    {
        title: 'Intellectual Property',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'The Service and all rights in our product name, trade names, logos, service marks, slogans, product packaging, its original content, features and functionality are and will remain the exclusive property of SunFi. Our trademarks may not be used in connection with any product or service without our prior written consent.',
            },
            {
                subtext:
                    'SunFi may, at its sole discretion, enable you to (i) create, upload, post, send, receive and store content, such as text, photos, audio, video, or other materials and information on or through the Platform ("User Content"); and (ii) access and view User Content and any content that SunFi itself makes available on or through the Platform, including proprietary content and any content licensed or authorized for use by or through SunFi from a third party ("SunFi’s Content" and together with User Content, "Collective Content").',
            },
            {
                subtext:
                    'You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the Platform or Collective Content, except to the extent that you are the legal owner of certain User Content or as expressly permitted in these Terms. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by SunFi or its licensors, except for the licenses and rights expressly granted in these Terms.',
            },
            {
                subtext:
                    'Subject to your compliance with these Terms, SunFi grants you a limited, non-exclusive, non-sub licensable, revocable, non-transferable license to access and view any Collective Content made available on or through the Platform and accessible to you, solely for your personal and non-commercial use.',
            },
            {
                subtext:
                    'You shall not directly or indirectly: (i) decipher, decompile, disassemble, reverse engineer, or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Service, except to the extent applicable laws specifically prohibit such restriction; (ii) modify, translate, or otherwise create derivative works of any part of the Service; or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder.',
            },
        ],
    },
    {
        title: 'No Exclusivity',
        text: 'Neither these Terms nor the provision of the Services, shall in any way limit the ability of SunFi to provide similar services to other companies who are your competitors.',
    },
    {
        title: 'Prohibited Activities',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'You shall not use the Platform for any purpose that is prohibited by these Terms. You are responsible for all of your activity in connection with the Service. Violation of our rules may result in the termination and cancellation of your Account. You acknowledge and agree that we may terminate your SunFi Account at any time for any reason (including, but not limited to, our independent assessment or the receipt of claims or allegations from third parties or authorities).',
            },
            {
                subtext:
                    'You shall not utilize the Platform or the Services for any illegal purpose.',
            },
            {
                subtext: {
                    text: 'You are solely responsible for compliance with any and all laws, rules, regulations, and tax obligations that may apply to your use of the Services. In connection with your use of the Platform, you will not and will not assist or enable others to:',
                    type: '',
                    children: [
                        'breach or circumvent any applicable laws or regulations, agreements with third-parties, third-party rights, or our Terms;',
                        'use the Platform or any content therein for any commercial or other purposes that are not expressly permitted by these Terms or in a manner that falsely implies endorsement, partnership or otherwise misleads others as to your affiliation with SunFi;',
                        'copy, store or otherwise access or use any information, including personally identifiable information about any other User, contained on the Platform in any way that is inconsistent with SunFi’s <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a> or these Terms or that otherwise violates the privacy rights of Users or third parties;',
                        'use the Platform in connection with the distribution of unsolicited commercial messages ("spam");',
                        'discriminate against or harass anyone on the basis of race, tribe, origin, religion, gender, physical or mental disability, medical condition, marital status, or age, or otherwise engage in any abusive or disruptive behaviour;',
                        'use, display, mirror or frame the Platform or Collective Content, or any individual element within the Platform, SunFi’s name, any trademark, logo or other proprietary information belonging to SunFi, or the layout and design of any page or form contained on a page in the Platform, without SunFi’s express written consent;',
                        'dilute, tarnish or otherwise harm SunFi’s brand in any way, including through unauthorized use of Collective Content, registering and/or using derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domains names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to SunFi’s domains, trademarks, taglines, promotional campaigns or Collective Content;',
                        'use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Platform for any purpose;',
                        'avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent any technological measure implemented by SunFi or any of SunFi’s providers or any other third party to protect the Platform;',
                        'attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Platform;',
                        'take any action that damages or adversely affects, or could damage or adversely affect the performance or proper functioning of the Platform;',
                        'violate or infringe anyone else’s rights or otherwise cause harm to anyone.',
                    ],
                },
            },
            {
                subtext:
                    'You acknowledge that SunFi has no obligation to monitor the access to or use of the Platform by any User or to review, disable access to, or edit any User Content, but has the right to do so to (i) operate, secure and improve the Platform (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes); (ii) ensure Users’ compliance with these Terms; (iii) comply with applicable law or the order or requirement of a court, law enforcement or other administrative agency or governmental body; (iv) respond to User Content that it determines is harmful or objectionable; or (v) as otherwise set forth in these Terms. Users agree to cooperate with and assist SunFi in good faith, and to provide SunFi with such information and take such actions as may be reasonably requested by SunFi with respect to any investigation undertaken by SunFi or a representative of SunFi regarding the use or abuse of the Platform.',
            },
        ],
    },
    {
        title: 'Links to Other Websites',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'The Platform may contain links to third-party websites or services that are not owned or controlled by SunFi.',
            },
            {
                subtext:
                    'SunFi has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that SunFi shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.',
            },
        ],
    },
    {
        title: 'Termination',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'We may terminate or suspend our Service to you immediately, without prior notice or liability, for any reason whatsoever, including without limitation, if you breach these Terms.',
            },
            {
                subtext:
                    'Upon termination, your right to use the Platform and our Service will immediately cease. If you wish to terminate your transaction, you may simply send an email to <a href="mailto:help@sunfi.co" target="_blank">help@sunfi.co</a>',
            },
        ],
    },
    {
        title: 'Indemnity',
        text: 'You agree to indemnify and hold harmless SunFi, its affiliates and subsidiaries, its officers, directors, employees and agents, against all liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest penalties and legal and other reasonable professional costs and expenses) suffered or incurred as a result of:',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'your fraudulent or illegal use of the Services or the Platform;',
            },
            {
                subtext:
                    'your negligence or any default by you of any of these Terms;',
            },
            {
                subtext:
                    'any inaccurate or incomplete information that you have knowingly provided to us;',
            },
            {
                subtext:
                    'you allowing any other person to access your account either with your permission or as a result of your failure to keep your username and password private;',
            },
            {
                subtext:
                    'any claim made against you for actual or alleged infringement of SunFi’s Intellectual Property or any actual or alleged infringement of a third party’s Intellectual Property arising out of or in connection with our Services or your use of the Platform.',
            },
        ],
    },
    {
        title: 'Limitation of Liability',
        text: 'In no event shall SunFi, its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'your use of the Platform or the Services or your inability to use the Platform or the Services;',
            },
            {
                subtext:
                    'any conduct or content of any third party on the Platform;',
            },
            {
                subtext:
                    'any unauthorised access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose;',
            },
            {
                subtext:
                    'any legal proceedings between you and any third parties.',
            },
        ],
    },
    {
        title: 'Disclaimer',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'Your use of the Service is at your sole risk. The Platform is provided on an “AS IS” and “AS AVAILABLE” basis. The Platform is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.',
            },
            {
                subtext:
                    'SunFi, its subsidiaries, affiliates, and its licensors do not warrant that a) the Platform will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Platform is free of viruses or other harmful components; or d) the results of using the Platform will meet your requirements.',
            },
        ],
    },
    {
        title: 'Governing Law',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'These Terms shall be governed and construed in accordance with the laws of the Federal Republic of Nigeria, without regard to its conflict of law provisions.',
            },
            {
                subtext:
                    'Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.',
            },
        ],
    },
    {
        title: 'Dispute Resolution',
        text: '',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'Any disputes arising under or in connection with the validity, interpretation and performance of this Terms between you and SunFi or between SunFi and any third party that cannot be resolved amicably by the parties through negotiation within 30 (thirty) days shall be resolved by Arbitration at the Lagos Court of Arbitration (LCA) before a single arbitrator in accordance with the Arbitration and Conciliation Act, Cap A18, Laws of the Federation of Nigeria.',
            },
            {
                subtext:
                    'The Parties shall endeavour in good faith to mutually agree on the selection of an arbitrator. If the Parties cannot mutually agree on the selection of an arbitrator within ten (10) days of the request, they shall apply to the LCA to appoint an arbitrator. Arbitration proceedings shall be conducted in Lagos. The arbitrator will be requested to render an award within ninety (90) days and to provide, in writing, the reasoning for the award. The decision of any such arbitrator shall be final and binding on the parties.',
            },
            {
                subtext:
                    'Each party shall bear its cost in connection with the Arbitration and the arbitrator’s fees shall be split equally between both parties.',
            },
        ],
    },
    {
        title: 'Force Majeure',
        text: 'SunFi shall not be liable for failure to perform, or for delay in performing its obligations hereunder if such failure or delay shall be due to natural disasters, war, riot, civil commotion, weather, pandemics, epidemics, labour disputes, failure of sub-contractors or any other cause beyond its reasonable control and whether or not of a similar nature to the foregoing.',
    },
    {
        title: 'Feedback',
        text: 'We welcome and encourage you to provide feedback, comments and suggestions for improvements to SunFi’s Platform or Services. You may submit your feedback by emailing us at <a href="mailto:help@sunfi.co" target="_blank">help@sunfi.co</a>. Any feedback you submit to us will be considered non-confidential and non-proprietary to you. By submitting your feedback to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.',
    },
    {
        title: 'Changes to Terms & Conditions',
        text: 'SunFi reserves the right, in its sole discretion, to change the Terms of Service. SunFi encourages you to periodically review the Terms to stay informed of our updates.',
    },
    {
        title: 'Contact Us',
        text: 'If you have any questions about these Terms, please contact us at <a href="mailto:help@sunfi.co" target="_blank">help@sunfi.co</a>.',
    },
]

export const OEMITermsOfService = [
    {
        title: 'Introduction',
        text: 'Please read these Terms of Service (“Terms”), which set forth the legally binding terms and conditions between you and SunFi Technology Solutions Ltd ("<b>The Company</b>" or "<b>SunFi</b>" or "<b>we</b>" or "<b>our</b>" or "<b>us</b>"). It governs your access to and the use of SunFi’s website, mobile application and the services provided by SunFi.</br><div style="margin-bottom: 10px"></div>Our collection and use of your personal information in connection with your use of our services is described in our <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a>.  Your access to our services is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all Equipment Manufacturers who access or use our services.</br><div style="margin-bottom: 10px"></div>Our website is not directed at you if we are prohibited by any law of any jurisdiction from making the information on our website available to you and is not intended for any use that would be contrary to your local law or regulation. By accessing or using our services, you agree to be bound by these Terms. If you disagree with any part of these Terms then you may not access our services.',
    },
    {
        title: 'Definition',
        text: 'In these Terms,',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    '<strong style="color: #011A3C">Account</strong><br /><div style="margin-bottom: 5px"></div>Account means a unique personified account registered in the name of the User and which contains details of the User’s transactions/operations on the Platform (as defined below).',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Applicable Laws</strong><br /><div style="margin-bottom: 5px"></div>Applicable Laws means all applicable regulations, laws, codes, guidelines, policies, rules, and directives of any competent regulatory authority in the Federal Republic of Nigeria.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Original Equipment Manufacturer(s)</strong><br /><div style="margin-bottom: 5px"></div>Original Equipment Manufacturer(s) means users who have the verified skills of a solar, sales and installation Associate.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Consumer(s)</strong><br /><div style="margin-bottom: 5px"></div>Consumer means the customers of the Original Equipment Manufacturer.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Platform</strong><br /><div style="margin-bottom: 5px"></div>Platform means SunFi’s website at <a href="https://www.sunfi.co" target="_blank">sunfi.co.</a>',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Service(s)</strong><br /><div style="margin-bottom: 5px"></div>Service smeans all services provided by SunFi and as described in clause 3 of these Terms.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">System</strong><br /><div style="margin-bottom: 5px"></div>System means the energy solutions provided by the Original Equipment Manufacturer.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">User</strong><br /><div style="margin-bottom: 5px"></div>User means the Original Equipment Manufacturer.',
            },
        ],
    },
    {
        title: 'Acceptance of Terms',
        text: "Our Services are offered subject to acceptance of all the terms and conditions contained in these Terms and all other operating rules, policies, and procedures that may be published on the Platform or signed as an agreement with you, which are incorporated by reference, including operating rules, policies, and procedures of third-party service providers to the Platform that are referenced herein. These Terms apply to all Original Equipment Manufacturers that we onboard on our Platform. In addition, some Services offered through the Platform may be subject to additional terms and conditions adopted by the Platform. Your use of those services is subject to those additional terms and conditions, which are incorporated into these Terms by this reference.</br><div style='margin-bottom: 10px'></div>SunFi reserves the right, at its sole discretion, to modify or replace these Terms from time to time by posting the updated terms on the Platform. It is your responsibility to check the Terms periodically for changes. If you object to any such changes, your sole recourse will be to cease using the Platform and the Service.  Your continued use of the Service following the posting of any changes to the Terms will indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</br><div style='margin-bottom: 10px'></div>SunFi reserves the right to change, suspend, or discontinue the Service (including, but not limited to, the availability of any feature, database, or Content) at any time for any reason. SunFi may also impose limits on certain features and Services or restrict your access to parts of or the entire Platform without notice or liability.",
    },
    {
        title: 'Scope of SunFi’s Services',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'SunFi is a technology company that provides energy financing solutions with the objective to simplify and scale access to clean energy for consumers in Africa.',
            },
            {
                subtext: 'Our Services can be accessed through our Platform.',
            },
        ],
    },
    {
        title: 'SunFi Account',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'SunFi may make the access to the Platform and the Services, or certain areas or features of the Platform, subject to certain conditions or requirements, such as, meeting specific eligibility criteria.',
            },
            {
                subtext: {
                    text: 'To access our Services as an Original Equipment Manufacturer, you are required to sign up on the Platform as an Original Equipment Manufacturer and create an account (SunFi Account) by providing us the following information:',
                    type: 'decimal-numbered-list',
                    children: [
                        'Original Equipment Manufacturer',
                        'Your company’s address, email address and phone number',
                        'The nature of your company’s business',
                        'Your company’s website',
                        'The kind of equipment you supply',
                        'Product category, product warranty and product technology',
                        'Payment options',
                        'Consumer basic information',
                        'Consumer credit information, if available',
                    ],
                },
            },
            {
                subtext:
                    'You have a responsibility to ensure that no third-party gains access to your account information.',
            },
            {
                subtext:
                    'You agree to strictly observe the security and authentication procedures of the Platform and you will log out from the Platform at the end of every visit.',
            },
            {
                subtext:
                    'When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of these Terms, which may result in immediate termination of your account on our Platform and the Service.',
            },
        ],
    },
    {
        title: 'Users’ Representations & Warranties',
        text: 'Representations and warranties are statements and promises made by you to SunFi, which we rely on as being accurate in our dealings with you. You make the following representations and warranties to us at the time of agreeing to these Terms and every time you use the Services;',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'your company is duly registered under the laws of the country of its incorporation;',
            },
            {
                subtext:
                    'all information that you provide about any Consumer that accesses our Services through you is accurate;',
            },
            {
                subtext:
                    'you have obtained the consent of your Consumers to share their personal data with us;',
            },
            {
                subtext:
                    'you shall not create more than one account for your company; and;',
            },
            {
                subtext:
                    'you are not breaching any Applicable Laws or regulations that are applicable to you or any company, trust or partnership.',
            },
        ],
    },
    {
        title: 'Consumers',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'You can use our Services to provide financing solutions to the Consumers that you provide Systems to. ',
            },
            {
                subtext:
                    'Our financing solutions are based on two models – a lease-to-own model and a subscription model. The lease-to-own model entails the Consumers making monthly payments for the System you provide during the lease period and the Consumer has the option of purchasing the System. The subscription model entails the Consumers paying a subscription fee for their use of the System during the subscription period. ',
            },
            {
                subtext:
                    'Depending on the model the Consumers opt for, the terms of the financing will be governed by a separate agreement - Consumer Financing Agreement between SunFi and the Consumers. The Consumers will also be bound by our Terms of Service for Consumers and our <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a>. The terms of the Consumer Financing Agreement will supersede our Terms of Service for Consumers in the event of any dispute.',
            },
        ],
    },
    {
        title: 'Payments',
        text: 'Based on the financing model and payment plan chosen by the Consumer, the applicable payments to be made by the Consumer for the System shall be detailed in the Consumer Financing Agreement.',
    },
    {
        title: 'Obligations of the Original Equipment Manufacturer',
        text: 'As an Original Equipment Manufacturer, you shall:',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'use commercially reasonable efforts to obtain, at your sole cost and expense, all permits and approvals necessary for the provision of the System to the Consumers;',
            },
            {
                subtext: 'provide the System to the Consumers;',
            },
            {
                subtext:
                    'be responsible for the construction and installation of the System at the facility of the Consumer;',
            },
            {
                subtext:
                    'provide SunFi with remote monitoring and shut down access and capabilities to all Systems financed by SunFi;',
            },
            {
                subtext:
                    'be responsible for the construction and installation of the System at the facilities of the Consumers;',
            },
            {
                subtext:
                    'be responsible for performing all routine maintenance and repairs on the System;',
            },
            {
                subtext:
                    'execute your services to the Consumers with reasonable care and skill;',
            },
            {
                subtext:
                    'provide clear communication channels; technical support and customer support services to the Consumers;',
            },
            {
                subtext:
                    'honour warranties and representations made to the Consumers;',
            },
            {
                subtext:
                    'handle all complaints of the Consumers relating to the System;',
            },
            {
                subtext:
                    'be responsible for the actions, inactions or omissions of any subcontractor or contractors that it engages to provide its services to the Consumers; and',
            },
            {
                subtext:
                    'be responsible for removing the System from the facilities of its Consumers at the termination or expiration of its service relationship with the Consumers.',
            },
        ],
    },
    {
        title: 'Intellectual Property',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'The Service and all rights in our product name, trade names, logos, service marks, slogans, product packaging, its original content, features and functionality are and will remain the exclusive property of SunFi. Our trademarks may not be used in connection with any product or service without our prior written consent.',
            },
            {
                subtext:
                    'SunFi may, at its sole discretion, enable you to (i) create, upload, post, send, receive and store content, such as text, photos, audio, video, or other materials and information on or through the Platform ("User Content"); and (ii) access and view User Content and any content that SunFi itself makes available on or through the Platform, including proprietary content and any content licensed or authorized for use by or through SunFi from a third party ("SunFi’s Content" and together with User Content, "Collective Content").',
            },
            {
                subtext:
                    'You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the Platform or Collective Content, except to the extent that you are the legal owner of certain User Content or as expressly permitted in these Terms. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by SunFi or its licensors, except for the licenses and rights expressly granted in these Terms.',
            },
            {
                subtext:
                    'Subject to your compliance with these Terms, SunFi grants you a limited, non-exclusive, non-sub licensable, revocable, non-transferable license to access and view any Collective Content made available on or through the Platform and accessible to you, solely for your personal and non-commercial use.',
            },
            {
                subtext:
                    'You shall not directly or indirectly: (i) decipher, decompile, disassemble, reverse engineer, or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Service, except to the extent applicable laws specifically prohibit such restriction; (ii) modify, translate, or otherwise create derivative works of any part of the Service; or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder.',
            },
        ],
    },
    {
        title: 'No Exclusivity',
        text: 'Neither these Terms nor the provision of the Services, shall in any way limit the ability of SunFi to provide similar services to other companies who are your competitors.',
    },
    {
        title: 'Prohibited Activities',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'You shall not use the Platform for any purpose that is prohibited by these Terms. You are responsible for all of your activity in connection with the Service. Violation of our rules may result in the termination and cancellation of your Account. You acknowledge and agree that we may terminate your SunFi Account at any time for any reason (including, but not limited to, our independent assessment or the receipt of claims or allegations from third parties or authorities).',
            },
            {
                subtext:
                    'You shall not utilize the Platform or the Services for any illegal purpose.',
            },
            {
                subtext: {
                    text: 'You are solely responsible for compliance with any and all laws, rules, regulations, and tax obligations that may apply to your use of the Services. In connection with your use of the Platform, you will not and will not assist or enable others to:',
                    type: '',
                    children: [
                        'breach or circumvent any applicable laws or regulations, agreements with third-parties, third-party rights, or our Terms;',
                        'use the Platform or any content therein for any commercial or other purposes that are not expressly permitted by these Terms or in a manner that falsely implies endorsement, partnership or otherwise misleads others as to your affiliation with SunFi;',
                        'copy, store or otherwise access or use any information, including personally identifiable information about any other User, contained on the Platform in any way that is inconsistent with SunFi’s <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a> or these Terms or that otherwise violates the privacy rights of Users or third parties;',
                        'use the Platform in connection with the distribution of unsolicited commercial messages ("spam");',
                        'discriminate against or harass anyone on the basis of race, tribe, origin, religion, gender, physical or mental disability, medical condition, marital status, or age, or otherwise engage in any abusive or disruptive behaviour;',
                        'use, display, mirror or frame the Platform or Collective Content, or any individual element within the Platform, SunFi’s name, any trademark, logo or other proprietary information belonging to SunFi, or the layout and design of any page or form contained on a page in the Platform, without SunFi’s express written consent;',
                        'dilute, tarnish or otherwise harm SunFi’s brand in any way, including through unauthorized use of Collective Content, registering and/or using derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domains names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to SunFi’s domains, trademarks, taglines, promotional campaigns or Collective Content;',
                        'use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Platform for any purpose;',
                        'avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent any technological measure implemented by SunFi or any of SunFi’s providers or any other third party to protect the Platform;',
                        'attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Platform;',
                        'take any action that damages or adversely affects, or could damage or adversely affect the performance or proper functioning of the Platform;',
                        'violate or infringe anyone else’s rights or otherwise cause harm to anyone.',
                    ],
                },
            },
            {
                subtext:
                    'You acknowledge that SunFi has no obligation to monitor the access to or use of the Platform by any User or to review, disable access to, or edit any User Content, but has the right to do so to (i) operate, secure and improve the Platform (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes); (ii) ensure Users’ compliance with these Terms; (iii) comply with applicable law or the order or requirement of a court, law enforcement or other administrative agency or governmental body; (iv) respond to User Content that it determines is harmful or objectionable; or (v) as otherwise set forth in these Terms. Users agree to cooperate with and assist SunFi in good faith, and to provide SunFi with such information and take such actions as may be reasonably requested by SunFi with respect to any investigation undertaken by SunFi or a representative of SunFi regarding the use or abuse of the Platform.',
            },
        ],
    },
    {
        title: 'Links to Other Websites',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'The Platform may contain links to third-party websites or services that are not owned or controlled by SunFi.',
            },
            {
                subtext:
                    'SunFi has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that SunFi shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.',
            },
        ],
    },
    {
        title: 'Termination',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'We may terminate or suspend our Service to you immediately, without prior notice or liability, for any reason whatsoever, including without limitation, if you breach these Terms.',
            },
            {
                subtext:
                    'Upon termination, your right to use the Platform and our Service will immediately cease. If you wish to terminate your transaction, you may simply send an email to <a href="mailto:help@sunfi.co" target="_blank">help@sunfi.co</a>',
            },
        ],
    },
    {
        title: 'Indemnity',
        text: 'You agree to indemnify and hold harmless SunFi, its affiliates and subsidiaries, its officers, directors, employees and agents, against all liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest penalties and legal and other reasonable professional costs and expenses) suffered or incurred as a result of:',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'your fraudulent or illegal use of the Services or the Platform;',
            },
            {
                subtext:
                    'your negligence or any default by you of any of these Terms;',
            },
            {
                subtext:
                    'any inaccurate or incomplete information that you have knowingly provided to us;',
            },
            {
                subtext:
                    'you allowing any other person to access your account either with your permission or as a result of your failure to keep your username and password private;',
            },
            {
                subtext:
                    'any claim made against you for actual or alleged infringement of SunFi’s Intellectual Property or any actual or alleged infringement of a third party’s Intellectual Property arising out of or in connection with our Services or your use of the Platform.',
            },
        ],
    },
    {
        title: 'Limitation of Liability',
        text: 'In no event shall SunFi, its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'your use of the Platform or the Services or your inability to use the Platform or the Services;',
            },
            {
                subtext:
                    'any conduct or content of any third party on the Platform;',
            },
            {
                subtext:
                    'any unauthorised access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose;',
            },
            {
                subtext:
                    'any legal proceedings between the you and any third parties.',
            },
        ],
    },
    {
        title: 'Disclaimer',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'Your use of the Service is at your sole risk. The Platform is provided on an “AS IS” and “AS AVAILABLE” basis. The Platform is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.',
            },
            {
                subtext:
                    'SunFi, its subsidiaries, affiliates, and its licensors do not warrant that a) the Platform will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Platform is free of viruses or other harmful components; or d) the results of using the Platform will meet your requirements.',
            },
        ],
    },
    {
        title: 'Governing Law',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'These Terms shall be governed and construed in accordance with the laws of the Federal Republic of Nigeria, without regard to its conflict of law provisions.',
            },
            {
                subtext:
                    'Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.',
            },
        ],
    },
    {
        title: 'Dispute Resolution',
        text: '',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'Any disputes arising under or in connection with the validity, interpretation and performance of this Terms between you and SunFi or between SunFi and any third party that cannot be resolved amicably by the parties through negotiation within 30 (thirty) days shall be resolved by Arbitration at the Lagos Court of Arbitration (LCA) before a single arbitrator in accordance with the Arbitration and Conciliation Act, Cap A18, Laws of the Federation of Nigeria.',
            },
            {
                subtext:
                    'The Parties shall endeavour in good faith to mutually agree on the selection of an arbitrator. If the Parties cannot mutually agree on the selection of an arbitrator within ten (10) days of the request, they shall apply to the LCA to appoint an arbitrator. Arbitration proceedings shall be conducted in Lagos. The arbitrator will be requested to render an award within ninety (90) days and to provide, in writing, the reasoning for the award. The decision of any such arbitrator shall be final and binding on the parties.',
            },
            {
                subtext:
                    'Each party shall bear its cost in connection with the Arbitration and the arbitrator’s fees shall be split equally between both parties.',
            },
        ],
    },
    {
        title: 'Force Majeure',
        text: 'SunFi shall not be liable for failure to perform, or for delay in performing its obligations hereunder if such failure or delay shall be due to natural disasters, war, riot, civil commotion, weather, pandemics, epidemics, labour disputes, failure of sub-contractors or any other cause beyond its reasonable control and whether or not of a similar nature to the foregoing.',
    },
    {
        title: 'Feedback',
        text: 'We welcome and encourage you to provide feedback, comments and suggestions for improvements to SunFi’s Platform or Services. You may submit your feedback by emailing us at <a href="mailto:help@sunfi.co" target="_blank">help@sunfi.co</a>. Any feedback you submit to us will be considered non-confidential and non-proprietary to you. By submitting your feedback to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.',
    },
    {
        title: 'Changes to Terms & Conditions',
        text: 'SunFi reserves the right, in its sole discretion, to change the Terms of Service. SunFi encourages you to periodically review the Terms to stay informed of our updates.',
    },
    {
        title: 'Contact Us',
        text: 'If you have any questions about these Terms, please contact us at <a href="mailto:help@sunfi.co" target="_blank">help@sunfi.co</a>.',
    },
]
export const ConsumerTermsOfService = [
    {
        title: 'Introduction',
        text: 'Please read these Terms of Service (“Terms”), which set forth the legally binding terms and conditions between you and SunFi Technology Solutions Ltd ("<b>The Company</b>" or "<b>SunFi</b>" or "<b>we</b>" or "<b>our</b>" or "<b>us</b>"). It governs your access to and the use of SunFi’s website, mobile application and the services provided by SunFi.</br><div style="margin-bottom: 10px"></div>Our collection and use of your personal information in connection with your use of our services is described in our <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a>.  Your access to our services is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all Consumers who access or use our services.</br><div style="margin-bottom: 10px"></div>Our website is not directed at you if we are prohibited by any law of any jurisdiction from making the information on our website available to you and is not intended for any use that would be contrary to your local law or regulation. By accessing or using our services, you agree to be bound by these Terms. If you disagree with any part of these Terms then you may not access our services.',
    },
    {
        title: 'Definition',
        text: 'In these Terms,',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    '<strong style="color: #011A3C">Account</strong><br /><div style="margin-bottom: 5px"></div>Account means a unique personified account registered in the name of the User and which contains details of the User’s transactions/operations on the Platform (as defined below).',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Applicable Laws</strong><br /><div style="margin-bottom: 5px"></div>Applicable Laws means all applicable regulations, laws, codes, guidelines, policies, rules, and directives of any competent regulatory authority in the Federal Republic of Nigeria.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Consumer(s)</strong><br /><div style="margin-bottom: 5px"></div>Consumer means the customers of SunFi and SunFi’s partner energy service providers.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Platform</strong><br /><div style="margin-bottom: 5px"></div>Platform means SunFi’s website at <a href="https://www.sunfi.co" target="_blank">sunfi.co.</a>',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">Service(s)</strong><br /><div style="margin-bottom: 5px"></div>Service smeans all services provided by SunFi and as described in clause 3 of these Terms.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">System</strong><br /><div style="margin-bottom: 5px"></div>System means the solar systems and other energy solutions provided by SunFi or SunFi’s partner energy service providers.',
            },
            {
                subtext:
                    '<strong style="color: #011A3C">User</strong><br /><div style="margin-bottom: 5px"></div>User means the Consumer.',
            },
        ],
    },
    {
        title: 'Acceptance of Terms',
        text: "Our Services are offered subject to acceptance of all the terms and conditions contained in these Terms and all other operating rules, policies, and procedures that may be published on the Platform or signed as an agreement with you, which are incorporated by reference, including operating rules, policies, and procedures of third-party service providers to the Platform that are referenced herein. These Terms apply to all Consumers that we onboard on our Platform. In addition, some Services offered through the Platform may be subject to additional terms and conditions adopted by the Platform. Your use of those services is subject to those additional terms and conditions, which are incorporated into these Terms by this reference.</br><div style='margin-bottom: 10px'></div>SunFi reserves the right, at its sole discretion, to modify or replace these Terms from time to time by posting the updated terms on the Platform. It is your responsibility to check the Terms periodically for changes. If you object to any such changes, your sole recourse will be to cease using the Platform and the Service.  Your continued use of the Service following the posting of any changes to the Terms will indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</br><div style='margin-bottom: 10px'></div>SunFi reserves the right to change, suspend, or discontinue the Service (including, but not limited to, the availability of any feature, database, or Content) at any time for any reason. SunFi may also impose limits on certain features and Services or restrict your access to parts of or the entire Platform without notice or liability.",
    },
    {
        title: 'Scope of SunFi’s Services',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'SunFi is a technology company that provides energy financing solutions with the objective to simplify and scale access to clean energy for consumers in Africa.',
            },
            {
                subtext: 'Our Services can be accessed through our Platform.',
            },
        ],
    },
    {
        title: 'SunFi Account',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'SunFi may make the access to the Platform and the Services, or certain areas or features of the Platform, subject to certain conditions or requirements, such as, meeting specific eligibility criteria.',
            },
            {
                subtext: {
                    text: 'To access our Services as an Consumer, you are required to sign up on the Platform as an Consumer and create an account (SunFi Account) by providing the following information:',
                    type: 'decimal-numbered-list',
                    children: [
                        'Your name, email address, phone number and residential address',
                        'Valid government-issued identity card (NIN, International Passport or Voters’ Card)',
                        'Details of your energy needs assessment such as building type and structure, power needs, preferred payment plan, income range, appliances to be powered, preferred repayment method and account statement for credit assessment',
                    ],
                },
            },
            {
                subtext:
                    'You have a responsibility to ensure that no third-party gains access to your account information.',
            },
            {
                subtext:
                    'You agree to strictly observe the security and authentication procedures of the Platform and you will log out from the Platform at the end of every visit.',
            },
            {
                subtext:
                    'When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of these Terms, which may result in immediate termination of your account on our Platform and the Service.',
            },
        ],
    },
    {
        title: 'Users’ Representations & Warranties',
        text: 'Representations and warranties are statements and promises made by you to SunFi, which we rely on as being accurate in our dealings with you. You make the following representations and warranties to us at the time of agreeing to these Terms and every time you use the Services;',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext: 'you are over the age of 18 years;',
            },
            {
                subtext:
                    'you are of sound mind and have the capacity to enter into a legally binding contract;',
            },
            {
                subtext:
                    'all personal information that you provide about yourself is accurate and true to the best of your knowledge;',
            },
            {
                subtext:
                    'where applicable, all information that you provide about your company is accurate and true;',
            },
            {
                subtext:
                    'you shall not create more than one account or create an account for anyone other than yourself (with the exception of accounts created for corporate organisations); and',
            },
            {
                subtext:
                    'you are not breaching any Applicable Laws or regulations that are applicable to you or any company, trust or partnership.',
            },
        ],
    },
    {
        title: 'Consumer Financing',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'You can access financing solutions through our Platform for the use or purchase of any System we or our partner energy service providers may provide.',
            },
            {
                subtext:
                    'Our financing solutions are based on two models – a lease-to-own model and a subscription model. The lease-to-own model entails the Consumers making monthly payments for the System you provide during the lease period and the Consumer has the option of purchasing the System. The subscription model entails the Consumers paying a subscription fee for their use of the System during the subscription period. ',
            },
            {
                subtext:
                    'Depending on the model the you opt for, the terms of the financing will be governed by a separate agreement - Consumer Financing Agreement between you and SunFi. Where applicable, you will also be bound by any agreement or terms provided by our partner energy service providers who provide the System.  The terms of the Consumer Financing Agreement will supersede our Terms of Service for Consumers in the event of any dispute.',
            },
        ],
    },
    {
        title: 'Payment',
        text: 'Depending on the financing model you choose, the applicable payments to be made by you for the System shall be detailed in the Consumer Financing Agreement.',
    },
    {
        title: 'Intellectual Property',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'The Service and all rights in our product name, trade names, logos, service marks, slogans, product packaging, its original content, features and functionality are and will remain the exclusive property of SunFi. Our trademarks may not be used in connection with any product or service without our prior written consent.',
            },
            {
                subtext:
                    'SunFi may, at its sole discretion, enable you to (i) create, upload, post, send, receive and store content, such as text, photos, audio, video, or other materials and information on or through the Platform ("User Content"); and (ii) access and view User Content and any content that SunFi itself makes available on or through the Platform, including proprietary content and any content licensed or authorized for use by or through SunFi from a third party ("SunFi’s Content" and together with User Content, "Collective Content").',
            },
            {
                subtext:
                    'You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the Platform or Collective Content, except to the extent that you are the legal owner of certain User Content or as expressly permitted in these Terms. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by SunFi or its licensors, except for the licenses and rights expressly granted in these Terms.',
            },
            {
                subtext:
                    'Subject to your compliance with these Terms, SunFi grants you a limited, non-exclusive, non-sub licensable, revocable, non-transferable license to access and view any Collective Content made available on or through the Platform and accessible to you, solely for your personal and non-commercial use.',
            },
            {
                subtext:
                    'You shall not directly or indirectly: (i) decipher, decompile, disassemble, reverse engineer, or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Service, except to the extent applicable laws specifically prohibit such restriction; (ii) modify, translate, or otherwise create derivative works of any part of the Service; or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder.',
            },
        ],
    },
    {
        title: 'Prohibited Activities',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'You shall not use the Platform for any purpose that is prohibited by these Terms. You are responsible for all of your activity in connection with the Service. Violation of our rules may result in the termination and cancellation of your Account. You acknowledge and agree that we may terminate your SunFi Account at any time for any reason (including, but not limited to, our independent assessment or the receipt of claims or allegations from third parties or authorities).',
            },
            {
                subtext:
                    'You shall not utilize the Platform or the Services for any illegal purpose.',
            },
            {
                subtext: {
                    text: 'You are solely responsible for compliance with any and all laws, rules, regulations, and tax obligations that may apply to your use of the Services. In connection with your use of the Platform, you will not and will not assist or enable others to:',
                    type: '',
                    children: [
                        'breach or circumvent any applicable laws or regulations, agreements with third-parties, third-party rights, or our Terms;',
                        'use the Platform or any content therein for any commercial or other purposes that are not expressly permitted by these Terms or in a manner that falsely implies endorsement, partnership or otherwise misleads others as to your affiliation with SunFi;',
                        'copy, store or otherwise access or use any information, including personally identifiable information about any other User, contained on the Platform in any way that is inconsistent with SunFi’s <a href="https://www.sunfi.co/privacy-policy" target="_blank">Privacy Policy</a> or these Terms or that otherwise violates the privacy rights of Users or third parties;',
                        'use the Platform in connection with the distribution of unsolicited commercial messages ("spam");',
                        'discriminate against or harass anyone on the basis of race, tribe, origin, religion, gender, physical or mental disability, medical condition, marital status, or age, or otherwise engage in any abusive or disruptive behaviour;',
                        'use, display, mirror or frame the Platform or Collective Content, or any individual element within the Platform, SunFi’s name, any trademark, logo or other proprietary information belonging to SunFi, or the layout and design of any page or form contained on a page in the Platform, without SunFi’s express written consent;',
                        'dilute, tarnish or otherwise harm SunFi’s brand in any way, including through unauthorized use of Collective Content, registering and/or using derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domains names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to SunFi’s domains, trademarks, taglines, promotional campaigns or Collective Content;',
                        'use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Platform for any purpose;',
                        'avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent any technological measure implemented by SunFi or any of SunFi’s providers or any other third party to protect the Platform;',
                        'attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Platform;',
                        'take any action that damages or adversely affects, or could damage or adversely affect the performance or proper functioning of the Platform;',
                        'violate or infringe anyone else’s rights or otherwise cause harm to anyone.',
                    ],
                },
            },
            {
                subtext:
                    'You acknowledge that SunFi has no obligation to monitor the access to or use of the Platform by any User or to review, disable access to, or edit any User Content, but has the right to do so to (i) operate, secure and improve the Platform (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes); (ii) ensure Users’ compliance with these Terms; (iii) comply with applicable law or the order or requirement of a court, law enforcement or other administrative agency or governmental body; (iv) respond to User Content that it determines is harmful or objectionable; or (v) as otherwise set forth in these Terms. Users agree to cooperate with and assist SunFi in good faith, and to provide SunFi with such information and take such actions as may be reasonably requested by SunFi with respect to any investigation undertaken by SunFi or a representative of SunFi regarding the use or abuse of the Platform.',
            },
        ],
    },
    {
        title: 'Links to Other Websites',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'The Platform may contain links to third-party websites or services that are not owned or controlled by SunFi.',
            },
            {
                subtext:
                    'SunFi has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that SunFi shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.',
            },
        ],
    },
    {
        title: 'Termination',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'We may terminate or suspend our Service to you immediately, without prior notice or liability, for any reason whatsoever, including without limitation, if you breach these Terms.',
            },
            {
                subtext:
                    'Upon termination, your right to use the Platform and our Service will immediately cease. If you wish to terminate your transaction, you may simply send an email to <a href="mailto:help@sunfi.co" target="_blank">help@sunfi.co</a>',
            },
        ],
    },
    {
        title: 'Indemnity',
        text: 'You agree to indemnify and hold harmless SunFi, its affiliates and subsidiaries, its officers, directors, employees and agents, against all liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest penalties and legal and other reasonable professional costs and expenses) suffered or incurred as a result of:',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'your fraudulent or illegal use of the Services or the Platform;',
            },
            {
                subtext:
                    'your negligence or any default by you of any of these Terms or subsequent agreement between you and SunFi;',
            },
            {
                subtext:
                    'any inaccurate or incomplete information that you have knowingly provided to us;',
            },
            {
                subtext:
                    'you allowing any other person to access your account either with your permission or as a result of your failure to keep your username and password private;',
            },
            {
                subtext:
                    'any claim made against you for actual or alleged infringement of SunFi’s Intellectual Property or any actual or alleged infringement of a third party’s Intellectual Property arising out of or in connection with our Services or your use of the Platform.',
            },
        ],
    },
    {
        title: 'Limitation of Liability',
        text: 'In no event shall SunFi, its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'your use of the Platform or the Services or your inability to use the Platform or the Services;',
            },
            {
                subtext:
                    'any conduct or content of any third party on the Platform;',
            },
            {
                subtext:
                    'any unauthorised access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose;',
            },
            {
                subtext:
                    'any legal proceedings between the you and any third parties.',
            },
        ],
    },
    {
        title: 'Disclaimer',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'Your use of the Service is at your sole risk. The Platform is provided on an “AS IS” and “AS AVAILABLE” basis. The Platform is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.',
            },
            {
                subtext:
                    'SunFi, its subsidiaries, affiliates, and its licensors do not warrant that a) the Platform will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Platform is free of viruses or other harmful components; or d) the results of using the Platform will meet your requirements.',
            },
        ],
    },
    {
        title: 'Governing Law',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'These Terms shall be governed and construed in accordance with the laws of the Federal Republic of Nigeria, without regard to its conflict of law provisions.',
            },
            {
                subtext:
                    'Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.',
            },
        ],
    },
    {
        title: 'Dispute Resolution',
        text: '',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'Any disputes arising under or in connection with the validity, interpretation and performance of this Terms between you and SunFi or between SunFi and any third party that cannot be resolved amicably by the parties through negotiation within 30 (thirty) days shall be resolved by Arbitration at the Lagos Court of Arbitration (LCA) before a single arbitrator in accordance with the Arbitration and Conciliation Act, Cap A18, Laws of the Federation of Nigeria.',
            },
            {
                subtext:
                    'The Parties shall endeavour in good faith to mutually agree on the selection of an arbitrator. If the Parties cannot mutually agree on the selection of an arbitrator within ten (10) days of the request, they shall apply to the LCA to appoint an arbitrator. Arbitration proceedings shall be conducted in Lagos. The arbitrator will be requested to render an award within ninety (90) days and to provide, in writing, the reasoning for the award. The decision of any such arbitrator shall be final and binding on the parties.',
            },
            {
                subtext:
                    'Each party shall bear its cost in connection with the Arbitration and the arbitrator’s fees shall be split equally between both parties.',
            },
        ],
    },
    {
        title: 'Force Majeure',
        text: 'SunFi shall not be liable for failure to perform, or for delay in performing its obligations hereunder if such failure or delay shall be due to natural disasters, war, riot, civil commotion, weather, pandemics, epidemics, labour disputes, failure of sub-contractors or any other cause beyond its reasonable control and whether or not of a similar nature to the foregoing.',
    },
    {
        title: 'Feedback',
        text: 'We welcome and encourage you to provide feedback, comments and suggestions for improvements to SunFi’s Platform or Services. You may submit your feedback by emailing us at <a href="mailto:help@sunfi.co" target="_blank">help@sunfi.co</a>. Any feedback you submit to us will be considered non-confidential and non-proprietary to you. By submitting your feedback to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.',
    },
    {
        title: 'Changes to Terms & Conditions',
        text: 'SunFi reserves the right, in its sole discretion, to change the Terms of Service. SunFi encourages you to periodically review the Terms to stay informed of our updates.',
    },
    {
        title: 'Contact Us',
        text: 'If you have any questions about these Terms, please contact us at <a href="mailto:help@sunfi.co" target="_blank">help@sunfi.co</a>.',
    },
]
