export const paymentPlanTenure = [
    '3 Months',
    '6 Months',
    '9 Months',
    '12 Months',
    '15 Months',
    '18 Months',
    '21 Months',
    '24 Months',
    '27 Months',
    '30 Months',
    '33 Months',
    '36 Months',
]
