import PropTypes from 'prop-types'
import Button from 'src/components/Button'
import EditPackageDrawer from 'src/pages/Admin/Profiles/Details/components/EditPackageDrawer'
import { usePackageCard } from './hooks/usePackageCard'
import { getApplianceAdded } from '../../data'

import styles from './packagecard.module.scss'

const PackageCard = ({ packageData, setSelectedPackages, profileName }) => {
    const {
        appliances,
        openEditDrawer,
        toggleEditDrawer,
        setAppliances,
        handlePackageUpdate,
        cluster,
        setCluster,
        paymentType,
        setPaymentType,
        paymentTypeError,
        setPaymentTypeError,
    } = usePackageCard({
        packageData,
        setSelectedPackages,
    })

    return (
        <>
            <div className={styles.Container}>
                <div className={styles.CardHeader}>
                    <h3>{packageData.name}</h3>
                    <Button
                        btnBgColor="#E2EEFF"
                        btnTextColor="var(--blue)"
                        btnWidth="92px"
                        btnHeight="29px"
                        fontSize="12px"
                        handleClick={toggleEditDrawer}
                    >
                        Edit package
                    </Button>
                </div>
                <div className={styles.CardBody}>
                    <p>{packageData.description}</p>
                </div>
                <div className={styles.CardFooter}>
                    <div className={styles.CardFooterCol}>
                        <h5>PACKAGE AMOUNT</h5>
                        <p>{packageData.amount}</p>
                    </div>
                    <div className={styles.CardFooterCol}>
                        <h5>APPLIANCES ADDED</h5>
                        <p>{getApplianceAdded(packageData.appliances)}</p>
                    </div>
                </div>
            </div>
            <EditPackageDrawer
                visible={openEditDrawer}
                toggleEditPackageDrawer={toggleEditDrawer}
                singlePackageEnergyNeeds={appliances}
                singlePackageEnergyInfo={packageData}
                handleSavePackage={() => handlePackageUpdate(true)}
                handleRemovePackage={handlePackageUpdate}
                setSinglePackageEnergyNeeds={setAppliances}
                singleCluster={cluster}
                setSingleCluster={setCluster}
                profileName={profileName}
                paymentPlanTypes={paymentType}
                setPaymentPlanTypes={setPaymentType}
                paymentPlanTypesError={paymentTypeError}
                setPaymentPlanTypesError={setPaymentTypeError}
            />
        </>
    )
}

PackageCard.propTypes = {
    packageData: PropTypes.obj,
    setSelectedPackages: PropTypes.func,
    profileName: PropTypes.string,
}

export default PackageCard
