// import { removeEmptyValues } from 'src/utils/removeEmptyValues'
import { removeEmptyObjects } from 'src/utils/removeEmptyValues'
import axios from '../../config/axios'

export const runCreditDecision = async estimation_id => {
    const request = axios.post(
        `/credit/decision/estimations/${estimation_id}/run`,
    )
    return request
}

export const runCreditDecisionWithBody = async ({ estimation_id, type }) => {
    const request = axios.post(
        `/credit/decision/estimations/${estimation_id}/run`,
        {
            decision_result_type: type,
        },
    )
    return request
}

export const retrievePaymentPlans = async estimation_id => {
    const request = axios.get(
        `/consumers/estimations/${estimation_id}/retrieve-payment-plans`,
    )
    return request
}

export const simulateSSEPaymentPlans = async ({
    estimation_id,
    payment_model,
    upfront_deposit,
    selected_tenure,
}) => {
    const body = {
        payment_model: payment_model,
        upfront_deposit: upfront_deposit,
        selected_tenure: selected_tenure,
    }
    const payload = removeEmptyObjects(body)

    const request = axios.post(
        `/consumers/estimations/${estimation_id}/payment-plan/simulate`,
        payload,
    )
    return request
}

export const switchSSEPaymentPlans = async ({
    estimation_id,
    payment_plan,
}) => {
    const request = axios.post(
        `/consumers/estimations/${estimation_id}/payment-plan/switch`,
        {
            payment_plan: payment_plan,
        },
    )
    return request
}

export const selectSSEPaymentPlans = async ({
    estimation_id,
    payment_plan_id,
}) => {
    const request = axios.post(
        `/consumers/estimations/${estimation_id}/select-payment-plan`,
        {
            payment_plan_id: payment_plan_id,
        },
    )
    return request
}

export const selectSSEPaymentPlanWithUpfrontDeposit = async ({
    estimation_id,
    upfront_deposit,
    tenure,
}) => {
    const body = {
        upfront_deposit: upfront_deposit,
        tenure: tenure,
    }

    const request = axios.post(
        `/consumers/estimations/${estimation_id}/select-plan-with-upfront-deposit`,
        body,
    )
    return request
}

export const rerunCreditDecision = async (estimationId, payload) => {
    const request = axios.post(
        `/credit/decision/estimations/${estimationId}/rerun`,
        payload,
    )
    return request
}

export const adminUpdateOfferApprovalStateApi = async (
    estimationId,
    payload,
) => {
    const request = axios.post(
        `admin/sse-consumers/estimations/${estimationId}/approval-status`,
        payload,
    )
    return request
}

export const sendAgreementReminderEmailToAdmin = async (
    estimationId,
    payload,
) => {
    const request = axios.post(
        `/consumers/estimations/${estimationId}/agreement-reminder-email`,
        payload,
    )
    return request
}
