import { InputFields } from 'src/components/InputFields'
import PropTypes from 'prop-types'
import '../gettingstarted.scss'

const AddEmail = ({ email, setEmail, emailError }) => {
    return (
        <div className={'GettingStartedWrapper'}>
            <div className={'GettingStartedHeadingAndText'}>
                <h3>Add your email address</h3>
                <p>
                    To save and continue later, we need your email address to
                    send you the details on how you can continue
                </p>
                <InputFields
                    title={'Email Address'}
                    name={'email'}
                    value={email}
                    errorMessage={emailError}
                    handleChange={e => {
                        setEmail(e.target.value)
                    }}
                />
            </div>
        </div>
    )
}

AddEmail.propTypes = {
    email: PropTypes.string,
    setEmail: PropTypes.func,
    emailError: PropTypes.string,
}

export default AddEmail
