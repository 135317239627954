import { isMobile } from 'src/utils/mediaQueries'
import '../../consumerkyc.scss'
import ImageIcon from 'src/assets/images/image-icon.svg'
import NoImage from 'src/assets/images/no-image.svg'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import { useState } from 'react'
import IdentityModal from '../../../CreateAccount/IdentityInformation/components/IdentityModal'
import { useUploadImage } from '../../../CreateAccount/IdentityInformation/hook'
import { useConsumerKYCContext } from '../../context'
import { ReactComponent as WhiteImageIcon } from 'src/assets/images/image-icon-white.svg'
import { decodeUserInfo } from 'src/utils/auth'

const IdentityInformationSection = () => {
    const { inputs, errors, removeErrors, kycRefetch, isCustomerApproved } =
        useConsumerKYCContext()
    const [showModal, setShowModal] = useState(false)
    const [preview, setPreview] = useState(false)
    const closeModal = () => {
        setShowModal(false)
    }

    const onSelfieUploadSuccess = () => {
        closeModal()
        kycRefetch()
    }

    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const {
        step,
        setStep,
        selected,
        setSelected,
        uploading,
        successUpload,
        setSuccessUpload,
        pictureUrl,
        setPictureUrl,
        width,
        setWidth,
        modalFooterLeftContent,
        modalPrimaryBtnText,
        modalTitle,
        disableModalPrimaryBtn,
        handleImageSteps,
        webcamRef,
        handleRetake,
        selfieLoading,
        IdentitySelfieRef,
    } = useUploadImage(closeModal, onSelfieUploadSuccess)

    return (
        <>
            <ConsumerModalBase
                showModal={showModal}
                onCancel={() => {
                    setPreview(false)
                    closeModal()
                }}
                title={modalTitle}
                footerLeftContent={
                    step === 3 && selfieLoading
                        ? 'none'
                        : modalFooterLeftContent
                }
                primaryBtnText={preview ? 'Close' : modalPrimaryBtnText}
                primaryBtnDisabled={disableModalPrimaryBtn}
                handlePrimaryBtnClick={() => {
                    !preview && handleImageSteps()
                    preview && setPreview(false)
                    preview && closeModal()
                }}
                primaryBtnLoading={step === 3 && selfieLoading}
                primaryBtnWidth={'160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
                secondaryBtnText="Retake"
                handleSecondaryBtnClick={() => {
                    step === 3 && handleRetake()
                    step === 3 && setPreview(false)
                }}
                closable
            >
                <IdentityModal
                    step={step}
                    setStep={setStep}
                    selected={selected}
                    setSelected={setSelected}
                    webcamRef={webcamRef}
                    IdentitySelfieRef={IdentitySelfieRef}
                    successUpload={successUpload}
                    setSuccessUpload={setSuccessUpload}
                    pictureUrl={pictureUrl || inputs?.selfieImageUrl}
                    setPictureUrl={setPictureUrl}
                    uploading={uploading}
                    width={width}
                    setWidth={setWidth}
                />
            </ConsumerModalBase>

            <div className="ImageContainer">
                {inputs?.selfieImageUrl === '' ? (
                    <div>
                        {isAdminUser ? (
                            <div className="noImage">
                                <button>
                                    <span>
                                        <img src={NoImage} />
                                    </span>

                                    <p>No image added yet</p>
                                </button>
                            </div>
                        ) : (
                            <div className="AddImage">
                                <button
                                    onClick={() => {
                                        setShowModal(true)
                                        removeErrors('selfieImageUrl')
                                    }}
                                    disabled={isAdminUser || isCustomerApproved}
                                >
                                    <span>
                                        <img src={ImageIcon} />
                                    </span>

                                    <p>Add your image</p>
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="imageContainer">
                        <img src={inputs?.selfieImageUrl} className="image" />

                        {!isAdminUser && !isCustomerApproved && (
                            <div
                                className="imageIcon"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    handleRetake()
                                    setShowModal(true)
                                    setStep(3)
                                    setPreview(true)
                                }}
                            >
                                <WhiteImageIcon />
                            </div>
                        )}
                    </div>
                )}
                <p className="ErrorText">{errors?.selfieImageUrl}</p>
            </div>
        </>
    )
}

export default IdentityInformationSection
