//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { format, parseISO } from 'date-fns'
import ActionModal from 'src/components/ActionModal'
import CompanyInfoTable from 'src/pages/Admin/Providers/Active/CompanyInfoTable'
import ProviderBankDetailsTable from 'src/pages/Admin/Providers/Active/BankDetailsTable'
import ProviderResourcesTable from 'src/pages/Admin/Providers/Active/ResourcesTable'
import ProviderUserTable from 'src/pages/Admin/Providers/Active/UserTable'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import EditUserDetails from 'src/pages/Admin/Providers/Active/EditUserDetails'
import ProviderInstallerInfo from 'src/pages/Admin/Providers/Active/InstallerInfoTable'
import WorkExperience from 'src/pages/Admin/Providers/Active/WorkExperience'
import DetailsPage from 'src/components/DetailsPage'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'

import {
    getAdminProviderUsersApi,
    getSingleAdminProviderApi,
    updateProviderStatusApi,
    getActiveProviderGroupsApi,
} from 'src/api/admin/provider'
import './deactivated-providers.scss'
import SeoComponent from '../../../../components/Seo'
import ComponentBrands from '../Active/ComponentBrands'
import { canViewProviderUserList } from '../Active/data'
import { permissionsControl } from 'src/utils/permissionsControl'

const AdminDeactivatedProvider = () => {
    const { id } = useParams()
    const [showModal, setShowModal] = useState(false)
    const [activateProvider, setActivateProvider] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [providerData, setProviderData] = useState([])
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [providerUsersData, setProviderUsersData] = useState([])
    const [triggerRefetch, setTriggerRefetch] = useState(false)
    const [groups, setGroups] = useState([])
    const history = useHistory()
    const providerIsAnAsi = history?.location?.search?.includes('asi')
    const canApproveOrDeclineProvider = permissionsControl([
        'can_update_provider_status',
    ])

    const handleModalAction = () => {
        setShowModal(false)
    }

    const handleEditModal = () => {
        setEditModal(false)
    }
    //get single provider
    const {
        refetch: singleProviderRefetch,
        isLoading: singleProviderLoading,
        isFetching: singleProviderFetching,
    } = useQuery(
        ['get-pending-single-provider'],
        () => getSingleAdminProviderApi(id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setProviderData(data?.data?.data)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage(`Couldn't fetch details. Refresh page`)
            },
        },
    )

    //get all users under the provider
    const { refetch: providerUsersRefetch, isFetching: providerUsersFetching } =
        useQuery(
            ['get-pending-provider-users'],
            () => getAdminProviderUsersApi(id),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setProviderUsersData(data?.data?.data)
                },
                onError: () => {
                    setToastError(true)
                    setErrorMessage(`Couldn't fetch details. Refresh page`)
                },
            },
        )

    // get list of groups (permission roles)
    const { refetch: getGroupsRefetch } = useQuery(
        ['get-provider-groups'],
        () => getActiveProviderGroupsApi(id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const groupData = data?.data?.data
                for (let i = 0; i < groupData?.length; i++) {
                    setGroups(prev => [
                        ...prev,
                        {
                            label: groupData?.[i]?.name,
                            value: groupData?.[i]?.name,
                            id: groupData?.[i]?.id,
                        },
                    ])
                }
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const approveProviderPayload = {
        status: 'APPROVED',
    }

    // activate a provider
    const {
        refetch: approveProviderRefetch,
        isLoading: approveProviderLoading,
    } = useQuery(
        ['approve-admin-provider'],
        () => updateProviderStatusApi(id, approveProviderPayload),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setShowModal(false)
                setActivateProvider(true)
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const handleActivateProvider = () => {
        approveProviderRefetch()
    }

    useEffect(() => {
        singleProviderRefetch()
        providerUsersRefetch()
        setTriggerRefetch(false)
    }, [triggerRefetch])

    useEffect(() => {
        getGroupsRefetch()
    }, [])

    return (
        <>
            <SeoComponent
                title="Suspended Provider - Provider Management | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Admin-Provider-Management"
            />
            <ActionModal
                actionModalOpen={showModal}
                headerText="Activate Provider?"
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                closeModal={handleModalAction}
                actionHandler={handleActivateProvider}
                actionType="delete"
                actionText="Activate"
                loading={approveProviderLoading}
                cancelText="Cancel"
                noBackLink
            />
            <ActionModal
                actionModalOpen={activateProvider}
                headerText="Provider Activated Successfully"
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionHandler={() => history.push('/admin/providers')}
                actionType="success"
                actionText="Okay, Got it"
                loading={''}
                noCancelBtn={true}
                noBackLink
            />
            {/*edit user details modal*/}
            <SecondaryModal
                showModal={editModal}
                modalWidth="624px"
                modalHeight={'fit-content'}
                noPadding="48px 80px"
                modalClass="RecommendedEnergy"
                onCancel={handleEditModal}
                content={<EditUserDetails />}
            />
            <div>
                {toastError && (
                    <Toast messageType="error" message={errorMessage} />
                )}
                <div className="APPWrapper">
                    <DetailsPage
                        backNavTitle="Back to Providers"
                        backNavOnClick={() => {
                            history.goBack()
                        }}
                        name={providerData?.display_name}
                        status={providerData?.status}
                        infoSubhead={
                            providerIsAnAsi ? 'INSTALLER BIO' : 'BUSINESS INFO'
                        }
                        infoParagraph={
                            providerIsAnAsi
                                ? providerData?.user?.bio
                                    ? providerData?.user?.bio
                                    : 'Not available'
                                : providerData?.business_info
                                ? providerData?.business_info
                                : 'Not available'
                        }
                        tabPaneOneTab={
                            providerIsAnAsi ? 'Installer Info' : 'Company Info'
                        }
                        tabPaneOneContent={
                            providerIsAnAsi ? (
                                <ProviderInstallerInfo data={providerData} />
                            ) : (
                                <CompanyInfoTable data={providerData} />
                            )
                        }
                        tabPaneTwo={
                            providerIsAnAsi ? true : canViewProviderUserList
                        }
                        tabPaneTwoTab={
                            providerIsAnAsi ? 'Work Experience' : 'Users'
                        }
                        tabPaneTwoContent={
                            providerIsAnAsi ? (
                                <WorkExperience providerId={id} />
                            ) : (
                                <ProviderUserTable
                                    data={providerUsersData}
                                    setTriggerRefetch={setTriggerRefetch}
                                    groups={groups}
                                />
                            )
                        }
                        tabPaneThree
                        tabPaneThreeTab="Bank Details"
                        tabPaneThreeContent={
                            <ProviderBankDetailsTable data={providerData} />
                        }
                        tabPaneFour
                        tabPaneFourTab="Resources"
                        tabPaneFourContent={
                            <ProviderResourcesTable
                                resources={providerData?.resources}
                                providerId={id}
                                setTriggerRefetch={setTriggerRefetch}
                                pending
                            />
                        }
                        {...(process.env
                            .REACT_APP_FLAG_SHOW_COMPONENT_BRANDS === 'true'
                            ? {
                                  tabPaneFive: true,
                                  tabPaneFiveTab: 'Component Brands',
                                  tabPaneFiveContent: (
                                      <ComponentBrands providerId={id} />
                                  ),
                              }
                            : {})}
                        rowCount={3}
                        rightSectionHeading="Provider Details"
                        gridItemOneHeading="CREATION DATE"
                        gridItemOneContent={
                            providerData?.created_at !== undefined &&
                            format(
                                parseISO(providerData?.created_at),
                                'dd MMM, yyyy, hh:mm',
                            )
                        }
                        gridItemTwoHeading="CREATED BY"
                        gridItemTwoContent="Samuel SunFi"
                        gridItemThreeHeading="PROVIDER TYPE"
                        gridItemThreeContent={`${providerData?.user?.provider?.type?.name} (${providerData?.user?.provider?.type?.identifier})`}
                        gridItemFourHeading={
                            providerIsAnAsi ? 'QUALIFICATION' : 'NO. USERS'
                        }
                        gridItemFourContent={
                            providerIsAnAsi
                                ? providerData?.user?.education_level ?? '-'
                                : providerUsersData?.length
                        }
                        gridItemFiveHeading={'REASON FOR DEACTIVATION'}
                        actionButton
                        buttonOneText="Activate"
                        buttonOneDisabled={!canApproveOrDeclineProvider}
                        buttonOneTooltipText={
                            !canApproveOrDeclineProvider
                                ? 'You are not authorised to perform this action'
                                : null
                        }
                        gridItemWidth={'299px'}
                        gridItemFiveContent={
                            providerData?.status_updates?.map(i => i.reason) ??
                            '-'
                        }
                        buttonOneHandleClick={() => setShowModal(true)}
                        isLoading={
                            singleProviderLoading ||
                            providerUsersFetching ||
                            singleProviderFetching
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default AdminDeactivatedProvider
