/* eslint-disable react/prop-types */
import { Skeleton } from 'antd'
import { InlineLoader } from '../../../components/Loader'

const CapexReplacement = ({
    energySolutionsData,
    modalId,
    skeletalState,
    selected,
    createEnergySolutionsLoading,
    handleSaveEnergySolutions,
}) => {
    return (
        <>
            {energySolutionsData.map(
                (energy, index) =>
                    energy.id === modalId && (
                        <div key={index} style={{ padding: '40px 60px 30px' }}>
                            <h2 className="ModalTitle">{energy.name}</h2>
                            <h3 className="ModalSubTitle1">DESCRIPTION</h3>
                            <p
                                className="ModalParagraph"
                                dangerouslySetInnerHTML={{
                                    __html: energy.description,
                                }}
                            ></p>
                            <h3 className="ModalSubTitle2">
                                Capex Replacement
                            </h3>

                            <div>
                                <div
                                    className="SelectedComponentsWrapper"
                                    style={{ width: '100%', marginTop: '0px' }}
                                >
                                    <table>
                                        {!skeletalState &&
                                            selected.map((item, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="SelectedComponents"
                                                        style={{
                                                            justifyContent:
                                                                'space-between',
                                                        }}
                                                    >
                                                        <td
                                                            style={{
                                                                maxWidth:
                                                                    '220px',
                                                            }}
                                                        >
                                                            <span
                                                                className="SelectedName"
                                                                style={{
                                                                    marginRight:
                                                                        '0px',
                                                                }}
                                                            >
                                                                {item?.name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {item
                                                                ?.capexReplacement
                                                                ?.length ===
                                                                0 ||
                                                            item?.capexReplacement ===
                                                                undefined ? (
                                                                <span>
                                                                    No Schedule
                                                                </span>
                                                            ) : (
                                                                `${
                                                                    item
                                                                        ?.capexReplacement
                                                                        ?.length
                                                                } Schedule${
                                                                    item
                                                                        ?.capexReplacement
                                                                        ?.length >
                                                                    1
                                                                        ? 's'
                                                                        : ''
                                                                }`
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        {skeletalState &&
                                            [...Array(4)].map(i => (
                                                <Skeleton.Input
                                                    key={i}
                                                    active
                                                    className="EstimationSkeletalField"
                                                />
                                            ))}
                                    </table>
                                </div>
                            </div>
                            <div className="SelectPackageDiv">
                                {!skeletalState ? (
                                    <button
                                        className="ModalButton"
                                        disabled={createEnergySolutionsLoading}
                                        onClick={() =>
                                            handleSaveEnergySolutions()
                                        }
                                    >
                                        {createEnergySolutionsLoading ? (
                                            <InlineLoader />
                                        ) : (
                                            'Select Package'
                                        )}
                                    </button>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    ),
            )}
        </>
    )
}

export default CapexReplacement
