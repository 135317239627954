/* eslint-disable react/prop-types */
import { useRef, useCallback, useState } from 'react'
import Button from '../../../../components/Button'
import Webcam from 'react-webcam'
import CloseIcon from '../../../../assets/images/closeIcon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/deletemodal-icon.svg'

import styles from './webcam.module.scss'

const videoConstraints = {
    width: 700,
    height: 450,
    facingMode: 'user',
}

export const WebCamCapture = ({
    uploadSelfie,
    setEnableCamera,
    mediumWidth,
    noMinHeight,
    page,
    webCamRef,
}) => {
    const {
        HeaderWrapper,
        Header,
        CloseImg,
        VideoWrapper2,
        VideoWrapper,
        MobileVideoWrapper,
        NoPermissionWrapper,
        NoPermissionTitle,
        NoPermissionSubtitle,
    } = styles
    const webcamRef = useRef(null)

    const [permissionStatus, setPermissionStatus] = useState('')

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot()
        uploadSelfie(imageSrc)
        setEnableCamera(false)
    }, [webcamRef, uploadSelfie, setEnableCamera])

    const onUserMediaError = error => {
        let permissionStatus = error?.toString().split(':')[1]
        setPermissionStatus(permissionStatus.trim())
    }

    return (
        <>
            {page !== 'sse-identity-information' && (
                <div className={HeaderWrapper}>
                    <p className={Header}>Capture your Photo</p>
                    <img
                        src={CloseIcon}
                        className={CloseImg}
                        onClick={() => setEnableCamera(false)}
                        alt="close icon"
                    />
                </div>
            )}
            {permissionStatus === '' ? (
                <>
                    <div
                        className={
                            page !== 'sse-identity-information' &&
                            MobileVideoWrapper
                        }
                        style={{
                            width:
                                page !== 'sse-identity-information'
                                    ? ''
                                    : '100%',
                            position: 'relative',
                            minHeight: noMinHeight ? 0 : 450,
                        }}
                    >
                        <div
                            className={
                                page === 'sse-identity-information'
                                    ? VideoWrapper2
                                    : VideoWrapper
                            }
                        >
                            <Webcam
                                audio={false}
                                height={noMinHeight ? 'auto' : 450}
                                ref={
                                    page === 'sse-identity-information'
                                        ? webCamRef
                                        : webcamRef
                                }
                                screenshotFormat="image/jpeg"
                                width={mediumWidth ? mediumWidth || 500 : 700}
                                videoConstraints={videoConstraints}
                                onUserMediaError={onUserMediaError}
                            />
                        </div>
                    </div>
                    {page !== 'sse-identity-information' && (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Button
                                btnBgColor="var(--purple-light)"
                                btnTextColor="var(--blue)"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                btnWidth="150px"
                                handleClick={e => {
                                    e.preventDefault()
                                    capture()
                                }}
                            >
                                Capture photo
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <div className={NoPermissionWrapper}>
                    <DeleteIcon />
                    <p className={NoPermissionTitle}>Permission Denied</p>
                    <p className={NoPermissionSubtitle}>
                        Your camera access is currently restricted for this
                        website. You’ll need to enable it in your browser’s
                        settings to proceed.
                    </p>
                </div>
            )}
        </>
    )
}
