export const months = [
    '1st Month',
    '2nd Month',
    '3rd Month',
    '4th Month',
    '5th Month',
    '6th Month',
    '7th Month',
    '8th Month',
    '9th Month',
    '10th Month',
    '11th Month',
    '12th Month',
    '13th Month',
    '14th Month',
    '15th Month',
    '16th Month',
    '17th Month',
    '18th Month',
    '19th Month',
    '20th Month',
    '21st Month',
    '22nd Month',
    '23rd Month',
    '24th Month',
    '25th Month',
    '26th Month',
    '27th Month',
    '28th Month',
    '29th Month',
    '30th Month',
    '31st Month',
    '32nd Month',
    '33rd Month',
    '34th Month',
    '35th Month',
    '36th Month',
    '37th Month',
    '38th Month',
    '39th Month',
    '40th Month',
    '41st Month',
    '42nd Month',
    '43rd Month',
    '44th Month',
    '45th Month',
    '46th Month',
    '47th Month',
    '48th Month',
    '49th Month',
    '50th Month',
    '51st Month',
    '52nd Month',
    '53rd Month',
    '54th Month',
    '55th Month',
    '56th Month',
    '57th Month',
    '58th Month',
    '59th Month',
    '60th Month',
]
