import axios from '../../config/axios'

export const getComponentsApi = async (
    pageNumber,
    searchText = '',
    startDate = '',
    endDate = '',
    startAmount = '',
    endAmount = '',
    componentTypes = '',
    provider_id = '',
    isAdminWorkspace = false,
) => {
    const request = await axios.get(
        `${
            provider_id.length > 1 || isAdminWorkspace ? '/admin' : '/products'
        }/components?page=${pageNumber}&name=${searchText}&start_date=${startDate}&end_date=${endDate}&start_amount=${startAmount}&end_amount=${endAmount}&component_types=${componentTypes}&provider_id=${provider_id}`,
    )
    return request
}

export const getAllComponentsApi = async (isAdminWorkspace = false) => {
    const request = await axios.get(
        `/${
            isAdminWorkspace ? 'admin' : 'products'
        }/components?paginate=${false}`,
    )
    return request
}

export const getPackagesApi = async (
    pageNumber,
    searchText = '',
    startDate = '',
    endDate = '',
    startAmount = '',
    endAmount = '',
    provider_id = '',
    isAdminWorkspace = false,
) => {
    const request = await axios.get(
        `${
            provider_id.length > 1 || isAdminWorkspace ? '/admin' : '/products'
        }/packages?page=${pageNumber}&name=${searchText}&start_date=${startDate}&end_date=${endDate}&start_amount=${startAmount}&end_amount=${endAmount}&provider_id=${provider_id}`,
    )
    return request
}

export const addPackageApi = async (packageInput, isAdminWorkspace = false) => {
    const request = await axios.post(
        `/${isAdminWorkspace ? 'admin' : 'products'}/packages`,
        packageInput,
    )
    return request
}

export const feedbackOnPackageCreationApi = async (packageId, payload) => {
    const request = await axios.post(
        `/products/packages/${packageId}/feedback`,
        payload,
    )
    return request
}
