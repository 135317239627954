import styles from '../solarsystemslistingsv2.module.scss'

const BuildCustomsModalContent = () => {
    return (
        <div className={styles.BuildCustomModalContentWrapper}>
            <div>
                <img
                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Illutrations-woman+-on-roof.png"
                    alt="consumer-profile-card-image"
                    role={'presentation'}
                    height={118}
                />
                <h3>Can&apos;t find what works for you?</h3>
                <p>
                    Customise your own system by providing your budget,
                    location, what you want to power, and our system will
                    generate recommendations based on what you have provided
                </p>
            </div>
        </div>
    )
}

export default BuildCustomsModalContent
