/* eslint-disable react/prop-types */
import { useState } from 'react'
import { Select } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import NigeriaFlag from './../../../assets/images/NigeriaFlag.svg'
import GhanaFlag from './../../../assets/images/GhanaFlag.svg'
import UKFlag from './../../../assets/images/UKFlag.svg'
import USAFlag from './../../../assets/images/USAFlag.svg'
import SouthAfricaFlag from './../../../assets/images/SouthAfricaFlag.svg'
import AustraliaFlag from './../../../assets/images/AustraliaFlag.svg'
import CanadaFlag from './../../../assets/images/CanadaFlag.svg'
import UAEFlag from './../../../assets/images/UAEFlag.svg'
import IvoryCoastFlag from './../../../assets/images/IvoryCoastFlag.svg'
import LockIcon from './../../../assets/images/lock.svg'
import { InlineLoaderDark } from '../../Loader'
import { ReactComponent as VerifiedIcon } from 'src/assets/images/circle-check-green.svg'

import './selectcountrycode.scss'

export const SelectCountryCode = ({
    title,
    handleChange,
    name,
    inputValue,
    defaultValue,
    errorMessage = '',
    inputWidth,
    disabled,
    fullWidth,
    prefilled,
    selectWidth,
    dropDown,
    countryCodeDropdownClassName,
    inputStyle = {},
    setCountryCode,
    pii,
    onBlur,
    onMouseOut,
    loadingIcon,
    verified = false,
    marginBottom,
    noMarginBottom,
    sseUse = false,
    inputType,
    activateEnterClick,
    enterFunc,
}) => {
    const [selectedValue, setSelectedValue] = useState('+234')
    const [toggleStyle, setToggleStyle] = useState(false)
    const { Option } = Select
    const countryCodes = [
        {
            img: USAFlag,
            number: '+1',
            country: 'US',
        },
        {
            img: UKFlag,
            number: '+44',
            country: 'GB',
        },
        {
            img: GhanaFlag,
            number: '+233',
            country: 'GH',
        },
        {
            img: SouthAfricaFlag,
            number: '+27',
            country: 'ZA',
        },
        {
            img: AustraliaFlag,
            number: '+61',
            country: 'AU',
        },
        {
            img: CanadaFlag,
            number: '+1',
            country: 'CA',
        },
        {
            img: UAEFlag,
            number: '+971',
            country: 'AE',
        },
        {
            img: IvoryCoastFlag,
            number: '+353',
            country: 'CI',
        },
    ]

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            enterFunc()
        }
    }

    return (
        <div onMouseOut={onMouseOut && onMouseOut}>
            <div
                className={`InputWrapper ${pii && 'piiInputWrapper'}`}
                onClick={() => setToggleStyle(pii ? false : true)}
                onBlur={() => setToggleStyle(false)}
                style={{
                    border: pii
                        ? 'none'
                        : errorMessage !== ''
                        ? '1px solid #DA1E28'
                        : toggleStyle && !disabled && '1px solid var(--blue)',
                    marginBottom: marginBottom
                        ? marginBottom
                        : (errorMessage || pii || noMarginBottom) && '0px',
                    width: inputWidth ? inputWidth : '100%',
                }}
            >
                <Select
                    value={
                        defaultValue?.length > 1 && defaultValue !== 'NG'
                            ? defaultValue
                            : selectedValue
                    }
                    style={{
                        width: '120px',
                        background: '#fff !important',
                        pointerEvents: !dropDown || defaultValue ? 'none' : '',
                    }}
                    onChange={e => {
                        setSelectedValue(e)
                    }}
                    className={`SelectCountry ${
                        pii && 'piiMobileSelectCountry'
                    }`}
                    suffixIcon={dropDown ? <CaretDownOutlined /> : <></>}
                    disabled={disabled ? true : !dropDown ? true : false}
                    dropdownClassName={
                        countryCodeDropdownClassName
                            ? countryCodeDropdownClassName
                            : 'countrySelect'
                    }
                    onSelect={data => {
                        let selectedCountry = countryCodes.find(
                            code => code.country === data,
                        )
                        setCountryCode(
                            selectedCountry === undefined
                                ? '+234'
                                : selectedCountry.number,
                        )
                    }}
                >
                    <Option value={'+234'}>
                        <div className="CountryCodeBox">
                            <img
                                src={NigeriaFlag}
                                alt="flag"
                                style={{
                                    display: defaultValue && 'inline-block',
                                }}
                                className="CountryFlag"
                            />
                            <span className="CountryCode">+234</span>
                        </div>
                    </Option>
                    {countryCodes.map(code => {
                        return (
                            <Option value={code.country} key={Math.random()}>
                                <div className="CountryCodeBox">
                                    <img
                                        src={code.img}
                                        alt="flag"
                                        className="CountryFlag"
                                    />
                                    <span className="CountryCode">
                                        {code.number}
                                    </span>
                                </div>
                            </Option>
                        )
                    })}
                </Select>
                {!pii && (
                    <input
                        type={inputType || 'text'}
                        name={name}
                        value={inputValue}
                        className="PhoneNumberDropdownInput"
                        onChange={handleChange}
                        onBlur={onBlur && onBlur}
                        required
                        style={{
                            width: fullWidth ? '400px' : 'auto',
                            ...inputStyle,
                        }}
                        disabled={disabled ? true : false}
                        data-testid={`input-${name}`}
                        onKeyUp={activateEnterClick && handleKeyPress}
                    />
                )}
                {prefilled && (
                    <img
                        src={LockIcon}
                        style={{
                            color: 'var(--blue-2)',
                            cursor: 'not-allowed',
                            position: dropDown ? 'relative' : '',
                            right: dropDown ? '11.5px' : '',
                        }}
                    />
                )}
                {verified && (
                    <span
                        className="password-icon"
                        style={{
                            left: 'calc(100% - 42px)',
                            cursor: verified ? 'not-allowed' : '',
                        }}
                    >
                        <VerifiedIcon role="presentation" />
                    </span>
                )}
                <span
                    className={`FloatingInput SCC ${
                        selectWidth || sseUse
                            ? 'FloatingInputAlignSSE'
                            : 'FloatingInputAlign'
                    }`}
                >
                    {title}
                </span>
                {loadingIcon && (
                    <i
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            position: 'absolute',
                            right: '10px',
                        }}
                    >
                        <InlineLoaderDark sseUse={sseUse} />
                        <InlineLoaderDark sseUse={sseUse} />
                        <InlineLoaderDark sseUse={sseUse} />
                    </i>
                )}
            </div>
            {!pii && errorMessage !== '' && (
                <p className="ErrorText">{errorMessage}</p>
            )}
        </div>
    )
}
