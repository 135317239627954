import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import {
    SelectField,
    InputFields,
    NoFLoatingLabelInputFields,
} from 'src/components/InputFields'
import Toast from 'src/components/Toast'
import RemitaLogo from 'src/assets/images/remita-logo.svg'
import debitstyles from './setupdirectdebit.module.scss'
import { getDirectDebitBankAPi } from 'src/api/consumer/directDebit'
import { errorHandler } from 'src/utils/errorHandler'
import PayStackLogo from 'src/assets/images/paystack-logo.svg'
import RecovaFullLogo from 'src/assets/images/recova-logo-full.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import NairaSymbol from 'src/components/NairaSymbol'
import infoIcon from 'src/assets/images/warning-info-thick.svg'

const SetupDirectDebitKyc = ({
    handleOnBlur,
    handleInputChange,
    errors,
    accountName,
    accountNumber,
    bankDetails,
    toastError,
    errorMessage,
    directDebitBanks,
    handleSelectChange,
    setToastError,
    setErrorMessage,
    setDirectDebitBanks,
    directDebitType,
}) => {
    const {
        BanksDetailsWrapper,
        BottomWrapper,
        SetupWarning,
        InsuranceCost,
        InsuranceCostRight,
    } = debitstyles
    const [accountInput, setAccountInput] = useState(false)

    //get direct debit banks
    const { refetch: bankRefetch } = useQuery(
        'fetch-direct-debit-bank',
        () => getDirectDebitBankAPi(directDebitType),
        {
            enabled: !!directDebitType,
            retry: false,
            onSuccess: data => {
                setDirectDebitBanks(data?.data?.data)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error.response.data))
            },
        },
    )

    useEffect(() => {
        bankRefetch()
    }, [bankRefetch, directDebitType])

    return (
        <>
            <div>
                {toastError && (
                    <Toast
                        messageType="error"
                        message={errorMessage}
                        setToast={setErrorMessage}
                    />
                )}
                <p className={SetupWarning}>
                    We are setting you up on the bank account you were reviewed
                    or credit approved for. If you have issues with this and
                    can&apos;t proceed with that account, please reach out to
                    us.
                </p>
                {['PAYSTACK', 'RECOVA'].includes(directDebitType) &&
                    (process.env.REACT_APP_SHOW_PAYSTACK_DIRECT_DEBIT ===
                        'true' ||
                        process.env.REACT_APP_ENABLE_RECOVA_DIRECT_DEBIT ===
                            'true') && (
                        <div className={InsuranceCost}>
                            <p>Required bank authorisation fee </p>
                            <CustomToolTip
                                text={`You will be charged a non-refundable authorisation fee of ₦50 to setup your bank direct debit.`}
                                toolTipPopupWidth={174}
                                toolTipMarginLeft={-104}
                                toolTipPadding={'9px 10px 15px 10px'}
                                toolTipFontSize={'12px'}
                            >
                                <div className={InsuranceCostRight}>
                                    <span>
                                        <NairaSymbol />
                                        {'50.00'}
                                    </span>
                                    <img src={infoIcon} alt={'info icon'} />
                                </div>
                            </CustomToolTip>
                        </div>
                    )}
                <div
                    className="ACVAddInputMainWrapper"
                    style={{ marginTop: 12 }}
                >
                    <div className={BanksDetailsWrapper}>
                        <SelectField
                            name="bankName"
                            initialOption="Select your bank"
                            floatingLabel="Select your bank"
                            selectWidth="100%"
                            handleChange={handleSelectChange}
                            withCheckBox={true}
                            dropdownPositionRelative
                            type="direct-debit"
                            selectedValue={bankDetails?.bankName}
                            currentSelected={bankDetails?.bankName}
                            values={directDebitBanks?.map(bank => ({
                                value: bank.name,
                            }))}
                            errorMessage={errors?.bankName}
                            value={bankDetails?.bankName}
                            marginBottom="20px"
                            disabled={
                                directDebitBanks?.length === 0 ||
                                bankDetails?.bankName
                            }
                            showSearch
                            floatLabelOnSelectedValue
                        />
                        <InputFields
                            inputWidth="100%"
                            title="Enter your account number"
                            type={'number'}
                            value={accountNumber}
                            inputValue={accountNumber}
                            handleChange={e => {
                                setAccountInput(true)
                                handleInputChange(e)
                            }}
                            name="accountNumber"
                            errorMessage={errors?.accountNumber}
                            onBlur={handleOnBlur}
                            inputFieldWrapperStyle={{
                                pointerEvents:
                                    bankDetails?.bankName?.length === 0
                                        ? 'none'
                                        : '',
                            }}
                            disabled={accountNumber && !accountInput}
                        />
                        {accountName && (
                            <NoFLoatingLabelInputFields
                                name="bankName"
                                inputPlaceholder="Account Name"
                                inputWidth="100%"
                                value={accountName}
                                inputBackground="#f8f8f8"
                                disabled
                            />
                        )}
                    </div>
                </div>
                <p className={BottomWrapper}>
                    Powered by{' '}
                    {directDebitType === 'PAYSTACK' ? (
                        <img
                            src={PayStackLogo}
                            style={{ marginLeft: '5px', marginTop: '-5px' }}
                            alt="remita-logo"
                        />
                    ) : directDebitType === 'RECOVA' ? (
                        <img
                            src={RecovaFullLogo}
                            style={{ marginLeft: '5px', marginTop: '-5px' }}
                            alt="remita-logo"
                        />
                    ) : (
                        <img
                            src={RemitaLogo}
                            style={{ marginLeft: '5px', marginTop: '-5px' }}
                            alt="remita-logo"
                        />
                    )}
                </p>
            </div>
        </>
    )
}

SetupDirectDebitKyc.propTypes = {
    showSelectedBank: PropTypes.bool,
    setShowSelectedBank: PropTypes.func,
    estimationId: PropTypes.string,
    closeDirectDebitModal: PropTypes.func,
    handleOnBlur: PropTypes.func,
    handleInputChange: PropTypes.func,
    errors: PropTypes.any,
    accountName: PropTypes.string,
    accountNumber: PropTypes.string,
    bankDetails: PropTypes.any,
    toastError: PropTypes.any,
    errorMessage: PropTypes.any,
    directDebitBanks: PropTypes.any,
    handleSelectChange: PropTypes.func,
    setToastError: PropTypes.func,
    setErrorMessage: PropTypes.func,
    setDirectDebitBanks: PropTypes.func,
    directDebitType: PropTypes.string,
}

export default SetupDirectDebitKyc
