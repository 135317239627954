import { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
    AIValidateSchema,
    DISIValidateSchema,
} from '../../utils/validationSchema'
import { registerCompleteApi } from '../../api/signup/register'
import Toast from '../../components/Toast'
import partnerTypes from '../../utils/partnerTypes'

import { InputFields } from '../../components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import Formbox from '../../components/FormBox'
import FormTitleBar from '../../components/FormTitleBar'
import ButtonComponent from '../../components/Button'
import PasswordInfo from '../../assets/images/passwordInfo.svg'
import Confirmation from '../../components/Confirmation'
import successImage from '../../assets/images/successImage.svg'
import { errorHandler } from '../../utils/errorHandler'
import styles from './completesignup.module.scss'
import { formatInputValue, formatPhoneNumber } from '../../utils/formatting'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'

const {
    NameFieldWrapper,
    ButtonWrapper,
    LinkWrapper,
    Wrapper,
    PasswordStyle,
    LoadingWrapper,
    BtnLoading,
} = styles

// eslint-disable-next-line react/prop-types
const CompleteSignup = ({ showTextHandler }) => {
    const history = useHistory()
    const [inputs, setInputs] = useState({
        firstname: '',
        lastname: '',
        companyname: '',
        phonenumber: '',
        email: '',
        password: '',
        countryCode: '+234',
        emailOrPhone: '',
    })
    const [errors, setErrors] = useState({})
    const [partnerDetails, setPartnerDetails] = useState()
    const [success, setSuccess] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])

    const location = useLocation()
    const validateField = useValidateOnBlur()

    useEffect(() => {
        setPartnerDetails(location.state?.partnerDetails)
        showTextHandler(true)
    }, [location.state?.partnerDetails, showTextHandler])

    const handleChange = e => {
        const { name, value } = e.target
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
        setInputs(prev => ({ ...prev, [name]: formatInputValue(name, value) }))
        if (name === 'email' || name === 'phonenumber') {
            setInputs(prev => ({
                ...prev,
                emailOrPhone: formatInputValue(name, value),
            }))
        }
    }

    const handleOnBlur = async e => {
        const validationSchema =
            partnerTypes[partnerDetails?.provider?.type?.identifier] === 'ai'
                ? AIValidateSchema(
                      `${partnerDetails?.email ? 'phone' : 'email'}`,
                  )
                : DISIValidateSchema(
                      `${partnerDetails?.email ? 'phone' : 'email'}`,
                  )
        const { name, value } = e.target

        await validateField({
            name,
            value,
            schema: validationSchema,
            setErrors,
        })
        setInputs(prev => ({ ...prev, [name]: value.trim() }))
        if (name === 'email' || name === 'phonenumber') {
            setInputs(prev => ({ ...prev, emailOrPhone: value.trim() }))
        }
    }

    const handleSelectChange = value => {
        setInputs(prev => ({ ...prev, countryCode: value }))
    }

    const handleSubmit = () => {
        setToastError(false)
        if (partnerDetails?.provider?.type?.identifier === 'AI') {
            AIValidateSchema(`${partnerDetails?.email ? 'phone' : 'email'}`)
                .validate(inputs, { abortEarly: false })
                .then(() => {
                    setErrors('')
                    handleClick()
                })
                .catch(err => {
                    let errList = {}
                    err?.inner?.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        } else {
            DISIValidateSchema(`${partnerDetails?.email ? 'phone' : 'email'}`)
                .validate(inputs, { abortEarly: false })
                .then(() => {
                    setErrors('')
                    handleClick()
                })
                .catch(err => {
                    let errList = {}
                    err?.inner?.forEach(e => {
                        errList = { ...errList, [e.path]: e.message }
                    })
                    setErrors(errList)
                })
        }
    }

    const AI = {
        first_name: inputs.firstname,
        last_name: inputs.lastname,
        [`${partnerDetails?.email !== '' ? 'phone_number' : 'email'}`]: `${
            partnerDetails?.email !== ''
                ? formatPhoneNumber(inputs.phonenumber, inputs.countryCode)
                : inputs.email
        }`,
        password: inputs.password,
        provider_type: partnerDetails?.provider?.type?.identifier,
    }
    const DISI = {
        company_name: inputs.companyname,
        [`${partnerDetails?.email !== '' ? 'phone_number' : 'email'}`]: `${
            partnerDetails?.email !== ''
                ? formatPhoneNumber(inputs.phonenumber, inputs.countryCode)
                : inputs.email
        }`,
        password: inputs.password,
        provider_type: partnerDetails?.provider?.type?.identifier,
    }

    const { isLoading, refetch } = useQuery(
        'register',
        () =>
            registerCompleteApi(
                partnerTypes[partnerDetails?.provider?.type?.identifier] ===
                    'ai'
                    ? AI
                    : DISI,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setSuccess(true)
                showTextHandler(false)
            },
            onError: error => {
                if (error) {
                    const errObj = error?.response?.data
                    if (error?.response?.status === 500) {
                        setErrorMessage(['Request failed. Please try again.'])
                    } else {
                        setErrorMessage(errorHandler(errObj))
                    }
                }
                setToastError(true)
            },
        },
    )

    const handleClick = () => {
        refetch()
    }

    const authorizeFinalSignup = () => {
        if (history?.location?.state?.from !== 'intialSignup') {
            history.push('/signup')
        }
    }

    useEffect(() => {
        authorizeFinalSignup()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {}, [errors])

    const onButtonClick = () => {
        history.push({
            pathname: '/login',
            state: {
                verificationType: partnerDetails?.phone_verified
                    ? 'phone'
                    : 'email',
            },
        })
    }

    return (
        <>
            {success ? (
                <Confirmation
                    imgUrl={successImage}
                    header="Registration Successful"
                    subHeader="We’ve solved the problems that make it difficult for energy providers to transition"
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    onButtonClick={onButtonClick}
                    btnText="Proceed to Sign In"
                />
            ) : (
                <Formbox>
                    <div className={Wrapper}>
                        <FormTitleBar
                            title="Let’s Sign You Up"
                            subtitle="Enter your details to get started"
                        />
                        <form>
                            {errorMessage && (
                                <Toast
                                    message={toastError ? errorMessage[0] : ''}
                                    messageType="error"
                                />
                            )}
                            {partnerTypes[
                                partnerDetails?.provider?.type?.identifier
                            ] === 'ai' ? (
                                <div className={NameFieldWrapper}>
                                    <InputFields
                                        value={inputs.firstname}
                                        handleChange={handleChange}
                                        name="firstname"
                                        title="First Name"
                                        type="text"
                                        errorMessage={errors.firstname}
                                        onBlur={handleOnBlur}
                                    />
                                    <InputFields
                                        value={inputs.lastname}
                                        handleChange={handleChange}
                                        title="Last Name"
                                        type="text"
                                        name="lastname"
                                        errorMessage={errors.lastname}
                                        onBlur={handleOnBlur}
                                    />
                                </div>
                            ) : (
                                <InputFields
                                    value={inputs.companyname}
                                    handleChange={handleChange}
                                    title="Company’s Name"
                                    type="text"
                                    name="companyname"
                                    errorMessage={errors.companyname}
                                    onBlur={handleOnBlur}
                                />
                            )}
                            {partnerDetails?.email ? (
                                <SelectCountryCode
                                    handleChange={handleChange}
                                    handleSelectChange={handleSelectChange}
                                    title="Phone Number"
                                    name="phonenumber"
                                    inputValue={inputs.phonenumber}
                                    errorMessage={errors.phonenumber}
                                    onBlur={handleOnBlur}
                                />
                            ) : (
                                <InputFields
                                    handleChange={handleChange}
                                    name="email"
                                    title="Email Address"
                                    type="email"
                                    inputWidth="389px"
                                    value={inputs.email}
                                    errorMessage={errors.email}
                                    onBlur={handleOnBlur}
                                />
                            )}

                            <InputFields
                                type="password"
                                title="Choose a Password"
                                handleChange={handleChange}
                                value={inputs.password}
                                name="password"
                                errorMessage={errors.password}
                                onBlur={handleOnBlur}
                                preventAutoComplete
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <img
                                    src={PasswordInfo}
                                    alt="password info icon"
                                />
                                <p className={PasswordStyle}>
                                    Password must be at least one UPPERCASE,
                                    lowercase and a number
                                </p>
                            </div>

                            <div className={ButtonWrapper}>
                                <ButtonComponent
                                    btnBgColor="var(--blue)"
                                    btnTextColor="var(--white)"
                                    disabled={
                                        partnerTypes[
                                            partnerDetails?.provider?.type
                                                ?.identifier
                                        ] === 'ai'
                                            ? inputs.firstname === '' ||
                                              inputs.lastname === '' ||
                                              inputs.password === ''
                                            : partnerTypes[
                                                  partnerDetails?.provider?.type
                                                      ?.identifier
                                              ] !== 'ai'
                                            ? inputs.companyname === '' ||
                                              inputs.password === ''
                                            : partnerDetails?.email
                                            ? inputs.phonenumber === ''
                                            : inputs.email === ''
                                    }
                                    handleClick={handleSubmit}
                                >
                                    {isLoading ? (
                                        <div className={LoadingWrapper}>
                                            <div className={BtnLoading}></div>
                                        </div>
                                    ) : (
                                        'Create Account'
                                    )}
                                </ButtonComponent>
                            </div>
                            <div className={LinkWrapper}>
                                <p>
                                    Already have an account?{' '}
                                    <span>
                                        <Link
                                            to="/login"
                                            style={{ color: 'var(--blue)' }}
                                        >
                                            Sign In
                                        </Link>
                                    </span>
                                </p>
                            </div>
                        </form>
                    </div>
                </Formbox>
            )}
        </>
    )
}

export default CompleteSignup
