/* eslint-disable react/prop-types */
import SeoComponent from 'src/components/Seo'
import Navigation from 'src/components/Navigation'
import Paragraph from 'src/components/Paragraph'
import styles from './privacypolicy.module.scss'
import { PrivacyPolicyData } from './data'
import parse from 'html-react-parser'
import CtaSection from 'src/components/CtaSection'
import FrequentlyAskedQuestions from 'src/components/Faq'
import CookieNotification from 'src/components/CookieNotificiation'
import Testimonial from '../Consumer/SmartShoppingExperience/Home/Testimonial/Testimonial'
import ConsumerFooter from 'src/components/ConsumerFooter'

const PrivacyPolicy = ({ isModal }) => {
    const {
        HeaderText,
        ModalHeaderText,
        ContentWrapper,
        ModalContentWrapper,
        SubParagraph,
        HeaderSubText,
        TextWrapper,
        ModalTextWrapper,
        AlphabetList,
    } = styles

    return (
        <>
            {!isModal && (
                <SeoComponent
                    title="Privacy Policy | SunFi | Simplifying and Scaling Clean Energy"
                    tracker="PrivacyPolicyTracker"
                />
            )}
            {!isModal && (
                <Navigation
                    bgColor="var(--blue-4)"
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnBgColorOutline="var(--blue-4)"
                    btnTextColorOutline="var(--blue)"
                    textColor="var(--black)"
                    page="privacyPolicy"
                    btnType="none"
                />
            )}

            <div className={isModal ? ModalTextWrapper : TextWrapper}>
                <p className={HeaderSubText}>LAST UPDATED IN MARCH, 2023</p>

                <p className={isModal ? ModalHeaderText : HeaderText}>
                    Privacy Policy
                </p>
            </div>
            {!isModal && <CookieNotification />}
            <div className={isModal ? ModalContentWrapper : ContentWrapper}>
                <>
                    {PrivacyPolicyData.map((data, i) => (
                        <div style={{ marginBottom: '50px' }} key={i}>
                            <Paragraph
                                count={`${i + 1}.`}
                                title={data.title}
                                size={isModal ? '20px' : '30px'}
                                text={data.text ? parse(data.text) : ''}
                                fontSize={isModal && '15px'}
                                marginBottom={isModal && '15px'}
                                textLineHeight={isModal && '25px'}
                                titleLineHeight={isModal && '24px'}
                            >
                                {data.subParagraph &&
                                    data.subParagraph.map((sub, j) => {
                                        const textCount =
                                            data.type ===
                                            'decimal-numbered-list'
                                                ? `${i + 1}.${j + 1}.`
                                                : data.type === 'numbered-list'
                                                ? `${j + 1}.`
                                                : ''
                                        return (
                                            <div
                                                className={SubParagraph}
                                                key={j}
                                            >
                                                <Paragraph
                                                    textCount={textCount}
                                                    size="20px"
                                                    text={parse(sub.subtext)}
                                                    fontSize={isModal && '15px'}
                                                    textLineHeight={
                                                        isModal && '25px'
                                                    }
                                                    titleLineHeight={
                                                        isModal && '24px'
                                                    }
                                                >
                                                    {sub.child &&
                                                        sub.child.map(
                                                            (child, k) => {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            marginLeft: 50,
                                                                        }}
                                                                        key={k}
                                                                    >
                                                                        <Paragraph
                                                                            textCount={
                                                                                textCount +
                                                                                `${
                                                                                    k +
                                                                                    1
                                                                                }.`
                                                                            }
                                                                            text={
                                                                                child.childText
                                                                            }
                                                                            size="20px"
                                                                            fontSize={
                                                                                isModal &&
                                                                                '15px'
                                                                            }
                                                                            textLineHeight={
                                                                                isModal &&
                                                                                '25px'
                                                                            }
                                                                            titleLineHeight={
                                                                                isModal &&
                                                                                '24px'
                                                                            }
                                                                        >
                                                                            <ul
                                                                                style={{
                                                                                    marginLeft: 53,
                                                                                }}
                                                                            >
                                                                                {child?.textItems?.map(
                                                                                    (
                                                                                        textItem,
                                                                                        l,
                                                                                    ) => (
                                                                                        <li
                                                                                            className={
                                                                                                AlphabetList
                                                                                            }
                                                                                            type="a"
                                                                                            key={
                                                                                                l
                                                                                            }
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    isModal
                                                                                                        ? '15px'
                                                                                                        : '18px',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                textItem
                                                                                            }
                                                                                        </li>
                                                                                    ),
                                                                                )}
                                                                            </ul>
                                                                        </Paragraph>
                                                                    </div>
                                                                )
                                                            },
                                                        )}
                                                </Paragraph>
                                            </div>
                                        )
                                    })}
                                {data.addonText ? (
                                    <Paragraph
                                        size="20px"
                                        text={parse(data.addonText)}
                                    />
                                ) : (
                                    <Paragraph />
                                )}
                            </Paragraph>
                        </div>
                    ))}
                </>
            </div>
            {!isModal && (
                <>
                    <CtaSection
                        title={'Simple and automated steps to the'}
                        subtitle={'Build your small solar business on SunFi'}
                        bgColor="#011A3C"
                        page="partners"
                    />
                    <FrequentlyAskedQuestions />
                    <Testimonial />
                </>
            )}

            {!isModal && <ConsumerFooter />}
        </>
    )
}

export default PrivacyPolicy
