import { isMobile } from 'src/utils/mediaQueries'
import styles from '../solarsystemslistingsv2.module.scss'
import { ReactComponent as ShareIcon } from 'src/assets/images/share-icon.svg'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { useSolarSystemsContext } from '../hooks/useSolarSystems'
import { appTracking } from 'src/utils/appTracker'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'
import { parseHTML } from 'src/utils/parseHTML'
import ChatCTA from 'src/components/ChatCTA'

const ProfileCard = () => {
    const {
        ProfileCardWrapper,
        PCImageWrapper,
        PCTextContent,
        PCShareWrapper,
        ClusterProfileCardWrapper,
        ClusterPararagraph,
        ChatWrapper,
    } = styles
    const { successAlert } = useCustomToast()
    const pageTitle =
        'Solar systems | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = `SOLAR_SYSTEM_LISTING`

    const { getEnergyProfileData, isClosedCluster, isPromo } =
        useSolarSystemsContext()
    const isResidential =
        getEnergyProfileData?.data?.data?.customer_type == 'RESIDENTIAL'

    const closedClusterLogo =
        getEnergyProfileData?.data?.data?.cluster_origination?.logo

    const handleCopy = () => {
        navigator.clipboard.writeText(location.href + '/shared')
        successAlert(
            'The link to this profile has been copied',
            '350px',
            isMobile ? '' : '100px',
        )
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            consumerProfileEvents(!isResidential ? 'BP' : 'RP')['shareProfile']
                .action,
            consumerProfileEvents(!isResidential ? 'BP' : 'RP').label,
            consumerProfileEvents(!isResidential ? 'BP' : 'RP').category,
            ['MP', 'GA'],
            'event',
        )
    }

    return (
        <div
            className={`${ProfileCardWrapper} ${
                isClosedCluster && ClusterProfileCardWrapper
            }`}
        >
            <div className={PCImageWrapper}>
                <img
                    src={
                        getEnergyProfileData?.data?.data?.meta_data
                            ?.image_url ||
                        'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/manufacter-installer.svg'
                    }
                    alt="profile-card-img"
                />
            </div>
            <div className={PCTextContent}>
                {closedClusterLogo ? (
                    <img src={closedClusterLogo} alt="cluster-img" />
                ) : (
                    <>
                        <div>
                            <div>
                                <h3>
                                    {isPromo ? 'Promo' : 'Profile'} selected
                                </h3>
                                <h2>
                                    {getEnergyProfileData?.data?.data?.name}
                                </h2>
                            </div>
                            <button
                                className={PCShareWrapper}
                                onClick={handleCopy}
                            >
                                <ShareIcon /> Share this{' '}
                                {isPromo ? 'promo' : 'profile'}
                            </button>
                        </div>
                        <h3>About this {isPromo ? 'promo' : 'profile'}</h3>
                    </>
                )}

                <p className={ClusterPararagraph}>
                    {getEnergyProfileData?.data?.data?.description?.includes(
                        '<',
                    )
                        ? parseHTML(
                              getEnergyProfileData?.data?.data?.description,
                          )
                        : getEnergyProfileData?.data?.data?.description}
                </p>
                {isClosedCluster && (
                    <div className={ChatWrapper}>
                        <ChatCTA margin="0px" width={'100%'} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProfileCard
