import { useMutation } from 'react-query'
import { discardDealApi } from 'src/api/shoppingExperience/landingPage'
import { errorHandler } from 'src/utils/errorHandler'

const useDiscardDeal = ({ id, onSuccess, onError }) => {
    const { mutate, isLoading } = useMutation({
        mutationFn: () => discardDealApi(id),
        onSuccess: () => onSuccess?.(),
        onError: error => onError?.(errorHandler(error?.response?.data)),
    })

    return {
        discardDealMutate: mutate,
        discardDealLoading: isLoading,
    }
}

export default useDiscardDeal
