import { useEffect } from 'react'

const useOTPVerify = ({ otp = [], handleOtpSubmit }) => {
    useEffect(() => {
        const otpFilledArray = otp.filter(Boolean)

        if (otpFilledArray.length === 6) {
            handleOtpSubmit()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otp])

    return null
}

export default useOTPVerify
