import axios from '../../config/axios'

export const getSinglePackageApi = async (
    id,
    isAdminWorkspace = false,
    clusterCode = '',
) => {
    const request = await axios.get(
        `/${
            isAdminWorkspace ? 'admin' : 'products'
        }/packages/${id}?cluster_code=${clusterCode}`,
    )
    return request
}

export const getSingleAlternativePackageApi = async (dropOffId, packageId) => {
    const request = await axios.get(
        `/admin/sse-consumers/drop-offs/${dropOffId}/recommend-solutions/${packageId}`,
    )
    return request
}

export const deleteSinglePackageApi = async (id, isAdminWorkspace = false) => {
    const request = await axios.delete(
        `/${isAdminWorkspace ? 'admin' : 'products'}/packages/${id}`,
    )
    return request
}

export const updateSinglePackageApi = async (
    id,
    data,
    isAdminWorkspace = false,
) => {
    const request = await axios.patch(
        `/${isAdminWorkspace ? 'admin' : 'products'}/packages/${id}`,
        data,
    )
    return request
}

export const validatePackageNameApi = async packageName => {
    const request = await axios.post(
        `/products/packages/validate-package-name`,
        {
            name: packageName,
        },
    )
    return request
}

export const bulkCreatePackageApi = async packageFile => {
    let formData = new FormData()
    if (packageFile) {
        formData.append('file', packageFile)
    }

    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    const request = await axios.post('/products/packages/import', formData, {
        headers,
    })
    return request
}

// get protective devices for admins and providers
export const getProtectiveDevicesApi = async isAdminWorkspace => {
    const request = axios.get(
        `/${
            isAdminWorkspace ? 'admin' : 'products'
        }/protective-devices?paginate=false`,
    )
    return request
}

export const getMaxWarranty = async () => {
    const request = axios.get(`/products/packages/max-warranty`)
    return request
}
