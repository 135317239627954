/* eslint-disable react/prop-types */
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import FormTitleBar from '../../../../../components/FormTitleBar'
import { InputFields } from '../../../../../components/InputFields'
import { ActionSection } from '../../../../Onboarding'
import ActionModal from '../../../../../components/ActionModal'
import CloseIcon from '../../../../../assets/images/black-close-icon.svg'
import { errorHandler } from '../../../../../utils/errorHandler'
import '../addvariable.scss'
import { isMobile } from '../../../../../utils/mediaQueries'

import { createCreditVariable } from '../../../../../api/admin/credit-variables/detailed-page'

const StepTwo = ({
    prevHandler,
    closeModal,
    cancelModal,
    showAddVariableModal,
    setData,
    data,
    updateVariable,
    updateVariableLoading,
    provider,
    clusterInfo,
    selectedVariable,
    type,
    creditVariableType,
}) => {
    const [successModal, setSuccessModal] = useState(false)
    const [createVariable, setCreateVariable] = useState(false)
    const [creditId, setCreditId] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleChange = e => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    const formatSelectedVariable = selectedVariable
        ?.split(' ')
        .join('_')
        .toUpperCase()

    const existingProviderPayload = {
        ...data,
        provider_id: provider,
        payment_model: formatSelectedVariable,
    }

    const payload = {
        ...data,
        provider_id: provider,
        cluster_info: clusterInfo,
        payment_model: formatSelectedVariable,
    }
    let history = useHistory()

    //create credit variable
    const {
        refetch: createCreditVariableRefetch,
        isFetching: createCreditFetching,
    } = useQuery(
        ['create-credit-variable'],
        () =>
            createCreditVariable(
                creditVariableType === 'existing_provider'
                    ? existingProviderPayload
                    : payload,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setSuccessModal(false)
                setCreateVariable(true)
                setCreditId(data?.data?.data)
            },
            onError: error => {
                setErrorMessage(errorHandler(error?.response?.data))
                setToastError(true)
                const timer = setTimeout(() => setToastError(false), [4000])
                return () => clearTimeout(timer)
            },
        },
    )

    const handleSubmit = () => {
        if (type === 'addVariable') {
            createCreditVariableRefetch()
        } else {
            updateVariable()
            setSuccessModal(false)
        }
    }

    return (
        <>
            <ActionModal
                actionModalOpen={successModal}
                headerText={'Save Changes?'}
                subTitle={
                    type === 'addVariable'
                        ? 'These variables will be saved to the cluster or provider'
                        : 'This will update the exisiting variables, would you like to continue?'
                }
                actionHandler={handleSubmit}
                loading={createCreditFetching || updateVariableLoading}
                actionType={'warning'}
                actionText={'Ok, got it'}
                cancelText="Cancel"
                onCancel={() => setSuccessModal(false)}
                closeModal={() => {
                    showAddVariableModal()
                    setSuccessModal(false)
                }}
                toastError={toastError}
                errorMessage={toastError ? errorMessage : ''}
                noBackLink
            />
            <ActionModal
                actionModalOpen={createVariable}
                headerText="Variable Saved"
                subTitle="Credit variables has been saved successfully"
                actionHandler={() => {
                    history.push(`/admin/credit/view/${creditId?.id}`)
                }}
                actionType={'success'}
                actionText={'Ok, got it'}
                noCancelBtn
                loading={''}
                noBackLink
            />
            <div className="ACVAddWrapper">
                <div className="ACVAddTopWrapper">
                    <span className="AWCloseIconWrapper">
                        <img
                            src={CloseIcon}
                            alt="close icon"
                            onClick={cancelModal}
                            style={{
                                width: '27px',
                                marginTop: isMobile ? '24px' : 0,
                            }}
                        />
                    </span>
                    <FormTitleBar
                        title={
                            type === 'addVariable'
                                ? 'Set Variable Details'
                                : 'Update Variable Details'
                        }
                        subtitle="Please enter the details of the variables"
                        titleFontSize="20px"
                        alignLeft={isMobile}
                        marginBottom={isMobile && '0'}
                    />
                </div>
                <div style={{ marginTop: 32 }}>
                    <div className="ACVAddInputMainWrapper">
                        <div className="ACVInputWrapper">
                            <InputFields
                                inputWidth="224px"
                                title="Re-Investment Rate (%)"
                                type="text"
                                value={data?.reinvestment_rate}
                                name="reinvestment_rate"
                                handleChange={handleChange}
                            />
                            <div style={{ marginLeft: 15 }}>
                                <InputFields
                                    inputWidth="224px"
                                    title="Min. Upfront Deposit (%)"
                                    type="text"
                                    value={data?.upfront_deposit}
                                    name="upfront_deposit"
                                    handleChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    {selectedVariable?.toLowerCase() === 'subscription' && (
                        <div className="ACVAddInputMainWrapper">
                            <div className="ACVInputWrapper">
                                <InputFields
                                    inputWidth="224px"
                                    title="Amortization Period (Months)"
                                    type="text"
                                    value={data?.amortization_month}
                                    name="amortization_month"
                                    handleChange={handleChange}
                                />
                                <div style={{ marginLeft: 15 }}>
                                    <InputFields
                                        inputWidth="224px"
                                        title="Monthly Payment Markup Value (%)"
                                        type="text"
                                        value={data?.markup_value}
                                        name="markup_value"
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="ACVAddInputMainWrapper">
                        <div className="ACVInputWrapper">
                            <InputFields
                                inputWidth="224px"
                                title="Debt to Equity in Project (%)"
                                type="text"
                                value={data?.debt_to_equity}
                                name="debt_to_equity"
                                handleChange={handleChange}
                            />
                            <div style={{ marginLeft: 15 }}>
                                <InputFields
                                    inputWidth="224px"
                                    title="Debt Cost (%)"
                                    type="text"
                                    value={data?.debt_cost}
                                    name="debt_cost"
                                    handleChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ACVAddInputMainWrapper">
                        <InputFields
                            inputWidth="464px"
                            title="Debt Months"
                            type="text"
                            value={data?.debt_months}
                            name="debt_months"
                            handleChange={handleChange}
                        />
                    </div>
                </div>
                <ActionSection
                    stage={2}
                    nextHandler={() => {
                        closeModal()
                        setSuccessModal(true)
                    }}
                    prevHandler={prevHandler}
                    margin="30px 0px 0px"
                />
            </div>
        </>
    )
}

export default StepTwo
