import { useMemo } from 'react'
import { decodeUserInfo } from 'src/utils/auth'

const usePaymentPlanTypes = () => {
    const userInfo = useMemo(() => decodeUserInfo(), [])
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const paymentPlanTypesData = useMemo(
        () => [
            { value: 'Lease to Own' },
            { value: 'Subscription' },
            ...(isAdminUser ? [{ value: 'Outright sale' }] : []),
        ],
        [isAdminUser],
    )

    return paymentPlanTypesData
}

export default usePaymentPlanTypes
