import PropTypes from 'prop-types'
import useAddComponentBrands from './hook'
import AddComponentBrandsOverview from './components/Overview'
import AddOrEditBrand from 'src/pages/Profile/ComponentBrands/components/AddOrEditBrand'
import s from './addcomponentbrands.module.scss'
import useFetchApprovedBrands from 'src/pages/Profile/ComponentBrands/components/AddOrEditBrand/hooks/useFetchApprovedBrands'
import Toast from 'src/components/Toast'

const AddComponentBrands = ({
    prevHandler,
    nextHandler,
    setModalHeight,
    carousel,
}) => {
    const {
        componentTypes,
        componentBrandsFetching,
        action,
        handleAddBrand,
        handleEditBrand,
        componentType,
        inputs,
        errors,
        handleGoBack,
        handleSave,
        handleSubmit,
        updateBrandsLoading,
        toast,
        onAPIError,
    } = useAddComponentBrands(setModalHeight, nextHandler)
    const { approvedBrands } = useFetchApprovedBrands(onAPIError)
    return (
        <>
            {toast?.showToast && (
                <Toast
                    message={toast?.toastMessage}
                    messageType={toast?.messageType}
                />
            )}
            {action === 'see-overview' ? (
                <AddComponentBrandsOverview
                    componentTypes={componentTypes}
                    componentBrands={inputs}
                    errors={errors}
                    componentBrandsFetching={componentBrandsFetching}
                    handleAddBrand={handleAddBrand}
                    handleEditBrand={handleEditBrand}
                    prevHandler={prevHandler}
                    handleSubmit={handleSubmit}
                    updateBrandsLoading={updateBrandsLoading}
                />
            ) : (
                <div className={s.content}>
                    <AddOrEditBrand
                        componentType={componentType}
                        actionType={action === 'add-brands' ? 'Add' : 'Edit'}
                        brandNameArr={inputs[componentType]}
                        handleGoBack={handleGoBack}
                        handleSave={handleSave}
                        approvedBrands={approvedBrands}
                    />
                </div>
            )}
            {action === 'see-overview' ? carousel : null}
        </>
    )
}

AddComponentBrands.propTypes = {
    prevHandler: PropTypes.func,
    nextHandler: PropTypes.func,
    setModalHeight: PropTypes.func,
    carousel: PropTypes.node,
}

export default AddComponentBrands
