import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { consumerHomeValidationV2Schema } from 'src/utils/validationSchema'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'
import useValidateExistingCustomer from 'src/components/ConsumerLandingPageBody/hooks/useValidateExistingCustomer'
import { useMutation } from 'react-query'
import { registerDropOffApi } from 'src/api/shoppingExperience/landingPage'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { decrypt, encrypt } from 'src/utils/cryptography'
import { isMobile } from 'src/utils/mediaQueries'

export const usePageNav = ({ isBusiness }) => {
    const history = useHistory()
    const { errorAlert } = useCustomToast()
    const [errors, setErrors] = useState({})
    const { validateUserIdMutation } = useValidateExistingCustomer({
        setErrors,
    })
    const [showRequestNewDealModal, setShowRequestNewDealModal] =
        useState(false)

    const decryptedData =
        localStorage?.getItem('sunfiUserSSEGettingStartedInfo') &&
        JSON.parse(
            decrypt(localStorage?.getItem('sunfiUserSSEGettingStartedInfo')),
        )
    const [inputs, setInputs] = useState({
        useType: decryptedData?.useType || '',
        phone: decryptedData?.phone?.replace('+234', 0) || '',
        email: decryptedData?.email || '',
    })
    const [showModal, setShowModal] = useState(false)
    const returningActiveOrClosedCustomer = JSON.parse(
        sessionStorage.getItem('returningActiveOrClosedCustomer'),
    )

    const handleBackNav = () => {
        if (returningActiveOrClosedCustomer?.status) {
            history.push('/consumer/workspace')
        } else history.push('/')
    }

    const toggleRequestNewDealModal = () => {
        setShowRequestNewDealModal(prev => !prev)
    }

    const contact = decryptedData?.contactType
    const switchText = `Getting this solution for a ${
        !isBusiness ? 'large ' : 'home or small'
    } business instead?`

    const switchBtnText =
        !isBusiness && isMobile
            ? 'See profiles for large business'
            : !isMobile
            ? 'Switch profiles'
            : 'See profiles for home or small business'

    const [countryCode] = useState('+234')

    const routeMap = {
        RESIDENTIAL: '/consumer/individual-profiles',
        BUSINESS: '/consumer/business-profiles',
    }

    const { mutate: registerDropOffMutate, isLoading: registerDropOffLoading } =
        useMutation({
            mutationFn: () =>
                registerDropOffApi({
                    ...(contact === 'email'
                        ? {
                              email: inputs?.email,
                          }
                        : {
                              phone_number: formatPhoneNumber(inputs?.phone),
                          }),
                    customer_type: isBusiness ? 'RESIDENTIAL' : 'BUSINESS',
                }),
            onSuccess: () => {
                localStorage.setItem(
                    'sunfiUserSSEGettingStartedInfo',
                    encrypt(
                        JSON.stringify({
                            email: inputs.email,
                            ...(inputs?.phone !== '' && {
                                phone: formatPhoneNumber(inputs.phone),
                            }),
                            contactType:
                                contact === 'email' ? 'email' : 'phone',
                            useType: isBusiness ? 'RESIDENTIAL' : 'BUSINESS',
                        }),
                    ),
                )
                setShowModal(false)
                history.push(routeMap[isBusiness ? 'RESIDENTIAL' : 'BUSINESS'])
            },
            onError: error => {
                errorAlert(errorHandler(error?.response?.data))
            },
        })

    const handleInputChange = event => {
        const { name, value } = event.target
        if (name === 'email') {
            setInputs(prev => ({ ...prev, [name]: value.replaceAll(' ', '') }))
        } else if (name === 'phone') {
            setInputs(prev => ({
                ...prev,
                [name]: formatInputValue(name, value),
            }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value }))
        }

        if (errors[name]) {
            delete errors[name]
        }
    }

    const handleInputSubmit = async (name, value) => {
        const existingConsumerPayload =
            contact === 'email'
                ? { email: value }
                : { phone_number: formatPhoneNumber(value, countryCode) }

        if (!existingConsumerPayload['email'] && contact === 'email') {
            return
        }
        if (
            existingConsumerPayload['phone_number']?.length < 5 &&
            contact === 'phone'
        ) {
            return
        }

        const field = contact === 'email' ? 'email' : 'phone number'

        const result = await validateUserIdMutation.mutateAsync(
            existingConsumerPayload,
        )
        const exists = result?.data?.data
        if (exists) {
            setErrors({
                ...errors,
                [name]: `This ${field} already exist, you can try Sign In`,
            })
        } else {
            delete errors[name]
            registerDropOffMutate()
        }
    }

    const validateInputs = () => {
        consumerHomeValidationV2Schema(contact === 'email' ? 'email' : 'phone')
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                handleInputSubmit(
                    contact === 'email' ? 'email' : 'phone',
                    contact === 'email' ? inputs.email : inputs.phone,
                )
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    const handleSwitchType = () => {
        validateInputs()
    }

    return {
        errors,
        inputs,
        contact,
        handleInputChange,
        validateUserIdMutation,
        registerDropOffLoading,
        showModal,
        setShowModal,
        handleBackNav,
        switchText,
        switchBtnText,
        handleSwitchType,
        showRequestNewDealModal,
        toggleRequestNewDealModal,
    }
}
