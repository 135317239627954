export const actionModalValues = action => {
    switch (action) {
        case 'bulk-edit-variables-successful':
            return {
                actionModalHeaderText: 'Changes Applied Successfully',
                actionModalSubtitle:
                    'You have successfully applied changes to selected credit variables',
                actionModalActionType: 'success',
                actionModalShowCancelBtn: false,
                actionModalActionText: 'Okay, Got it',
            }
        default:
            return {
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalShowCancelBtn: true,
                actionModalActionText: '',
            }
    }
}
