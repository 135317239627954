import PropTypes from 'prop-types'
import { currencyFormatter } from '../../utils/currencyFormatter'
import styles from './adminsolutionmodal.module.scss'

const EnergySolution = ({ closeModal, recommendedSolution, tab }) => {
    const {
        ASMWrapper,
        ASMLabel,
        ASMTitle,
        ASMEnergyCapacity,
        ASMSubTitle,
        ASMParagraph,
        ASMComponentsTitle,
        ASMSelectedComponentsWrapper,
        ASMComponentsRow,
        ASMSpaceBetween,
        ASMComponentName,
        ASMComponentType,
        ASMComponentAmount,
        ASMComponentQuantity,
        ASMSubtotal,
        ASMMargin,
        ASMTotal,
        ASMButtonWrapper,
        ASMButton,
    } = styles
    return (
        <>
            <div
                className={ASMWrapper}
                style={{ height: tab !== 'awaitingApproval' && '430px' }}
            >
                <p className={ASMLabel}>BEST MATCH</p>
                <h1 className={ASMTitle}>
                    {recommendedSolution?.package_name}
                    <span className={ASMEnergyCapacity}>
                        ({recommendedSolution?.package_energy_capacity?.value}
                        {
                            recommendedSolution?.package_energy_capacity
                                ?.unit_of_measurement
                        }
                        )
                    </span>
                </h1>
                <div>
                    <p className={ASMSubTitle}>DESCRIPTION</p>
                    <p
                        className={ASMParagraph}
                        dangerouslySetInnerHTML={{
                            __html: recommendedSolution?.package_description,
                        }}
                    ></p>
                </div>
                <h3 className={ASMComponentsTitle}>Components Information</h3>
                <div>
                    <div className={ASMSelectedComponentsWrapper}>
                        <table>
                            {recommendedSolution?.components?.map((item, i) => (
                                <tbody key={i}>
                                    <tr className={ASMComponentsRow}>
                                        <td className={ASMComponentName}>
                                            {item?.component_name || item?.name}
                                        </td>

                                        <td className={ASMComponentType}>
                                            {item?.component_type || item?.type}
                                        </td>
                                        <td className={ASMComponentAmount}>
                                            &#8358;{' '}
                                            {currencyFormatter(item?.unit_cost)}
                                        </td>
                                        <td className={ASMComponentQuantity}>
                                            {item?.quantity}
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>
                    {recommendedSolution?.margin !== 0 && (
                        <div className={ASMSpaceBetween}>
                            <span className={ASMSubtotal}>Subtotal</span>
                            <p className={ASMSubtotal}>
                                &#8358;{' '}
                                {currencyFormatter(recommendedSolution?.cost)}
                            </p>
                        </div>
                    )}
                    <>
                        {recommendedSolution?.margin !== 0 && (
                            <div className={ASMSpaceBetween}>
                                <p className={ASMMargin}>Margin</p>
                                <p className={ASMMargin}>
                                    <span
                                        style={{
                                            color:
                                                recommendedSolution?.margin > 0
                                                    ? '#32CC79'
                                                    : 'red',
                                        }}
                                    >
                                        {recommendedSolution?.margin > 0 && '+'}
                                        {recommendedSolution?.margin}% (&#8358;
                                        {currencyFormatter(
                                            recommendedSolution?.total_cost -
                                                recommendedSolution?.cost,
                                        )}
                                        )
                                    </span>
                                </p>
                            </div>
                        )}
                        <div className={ASMSpaceBetween}>
                            <span className={ASMTotal}>Total</span>
                            <p className={ASMTotal}>
                                &#8358;{' '}
                                {currencyFormatter(
                                    recommendedSolution?.total_cost,
                                )}
                            </p>
                        </div>
                    </>
                </div>
            </div>
            <div className={ASMButtonWrapper}>
                <button className={ASMButton} onClick={closeModal}>
                    Okay, Got it
                </button>
            </div>
        </>
    )
}

EnergySolution.propTypes = {
    closeModal: PropTypes.func,
    recommendedSolution: PropTypes.any,
    tab: PropTypes.string,
}

export default EnergySolution
