/* eslint-disable react/prop-types */
import { useHistory } from 'react-router-dom'
import FormTitleBar from '../../../../../components/FormTitleBar'
import { CostField } from '../../../../../components/InputFields/CostField'
import { SelectField } from '../../../../../components/InputFields'
import Button from '../../../../../components/Button'
import './../UpdateCreditModal/updatecredit.scss'
import CloseIcon from '../../../../../assets/images/black-close-icon.svg'
import { floatWithCommas } from 'src/utils/formatting'
import { useState } from 'react'
import './manualsimulation.scss'
import { isMobile } from 'src/utils/mediaQueries'

const ManualSimulation = ({
    closeModal,
    simulatePlanData,
    providerCreditId,
    providerId,
}) => {
    let history = useHistory()
    const [simulationMethod, setSimulationMethod] = useState('')
    const [amount, setAmount] = useState('0')
    const [showCostField, setShowCostField] = useState(false)
    const [costFieldError, setCostFieldError] = useState('')
    const [dropdownError, setDropdownError] = useState('')

    const handleSelectChange = (name, value) => {
        setSimulationMethod(value)
    }

    const handleSimulation = () => {
        localStorage.setItem(
            'simulatePlanData',
            JSON.stringify(simulatePlanData),
        )
        localStorage.setItem('providerCreditId', providerCreditId)
        localStorage.setItem('providerId', providerId)
        if (simulationMethod == '') {
            setDropdownError('You need to make a selection from the dropdown')
            return
        }
        if (simulationMethod == 'Enter Price Manually') {
            setShowCostField(true)
        } else {
            history.push(`/app/payment-plans/simulate/one`)
        }
    }
    const dropDownItems = [
        { value: 'Enter Price Manually' },
        {
            value: simulatePlanData?.cluster_packages
                ? 'Select from Cluster Packages'
                : 'Select from Product Library',
        },
    ]

    const simulatePlanClick = () => {
        const formattedAmount = parseFloat(amount.replace(/,/g, ''))
        if (formattedAmount <= 0 || isNaN(formattedAmount)) {
            setCostFieldError('The amount has to be greater than zero')
            return
        }
        localStorage.setItem('packageAmount', formattedAmount.toString())
        history.push('/app/payment-plans/simulate/two?manualPrice=true')
    }

    const handleChange = e => {
        const inputValue = e.target.value
        const rawValue = inputValue.replace(/,/g, '')
        const fullStopCount = (inputValue.match(/\./g) || []).length

        if (
            (/^\d*\.?\d*$/.test(rawValue) && fullStopCount <= 1) ||
            rawValue === '' ||
            rawValue.endsWith('.')
        ) {
            setAmount(rawValue)
        }
    }

    return (
        <>
            <div className="ACVWrapper">
                <div className="ACVSimulationTopWrapper">
                    <FormTitleBar
                        title={
                            showCostField
                                ? 'Enter Amount'
                                : 'Select Simulation Method'
                        }
                        subtitle={`Please select a simulation ${
                            showCostField ? 'amount' : 'method'
                        }`}
                        marginTop="70px"
                        alignLeft={isMobile}
                    />
                    <span className="ACVCloseIconWrapper">
                        <img
                            src={CloseIcon}
                            alt="close icon"
                            onClick={closeModal}
                            style={{
                                width: '27px',
                                marginTop: isMobile ? '-16px' : '-10px',
                                marginRight: '20px',
                            }}
                        />
                    </span>
                </div>
                <div style={{ marginTop: 32 }}>
                    <div className="ACVInputMainWrapper">
                        {showCostField ? (
                            <CostField
                                inputWidth="464px"
                                title="Enter Amount"
                                value={floatWithCommas(amount)}
                                name="amount"
                                currencyCode={'NGN'}
                                handleChange={e => handleChange(e)}
                                floatTitlePositionLeft="90px"
                                selectWidth="100px"
                                noFloat
                                cssStyle={{
                                    position: 'relative',
                                    top: '6px',
                                    marginLeft: '-14px',
                                }}
                                errorMessage={costFieldError}
                            />
                        ) : (
                            <SelectField
                                name="provider"
                                initialOption="Select Method"
                                selectWidth="464px"
                                handleChange={handleSelectChange}
                                withCheckBox={true}
                                dropdownPositionRelative
                                type="approvedModal"
                                value={simulationMethod}
                                selectedValue={simulationMethod}
                                currentSelected={simulationMethod}
                                values={dropDownItems}
                                errorMessage={dropdownError}
                            />
                        )}
                    </div>
                </div>
                <div className="ACVButtonWrapper" style={{ marginTop: 144 }}>
                    {showCostField ? (
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnWidth="162px"
                            btnHeight="56px"
                            handleClick={simulatePlanClick}
                        >
                            Simulate Plan
                        </Button>
                    ) : (
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnWidth="162px"
                            btnHeight="56px"
                            handleClick={handleSimulation}
                        >
                            Continue
                        </Button>
                    )}
                </div>
            </div>
        </>
    )
}

export default ManualSimulation
