export const contentSixBulletList = [
    {
        title: 'Zero release of harmful fumes.',
        text: 'The gas released from the exhaust fumes in generators, carbon monoxide, is dangerous and can be linked to some life-threatening problems such as pneumonia, heart attacks, and cancer. With solar, there are no associated air pollution emissions.',
    },
    {
        title: 'Fuel price will increase after subsidy removal.',
        text: 'Currently, fuel is sold at N 350/litre on average and will be sold at 462 Naira per litre after the fuel subsidy removal in June 2023. With the high fuel costs and subsidy removal, it will become more challenging for businesses and households to buy fuel and run generators for longer hours. However, with solar, you can stay protected from these costs.',
    },
    {
        title: 'Reliable power supply for up to 24 hours.',
        text: 'In April 2021, the World Bank reported that businesses in Nigeria suffered an annual loss of $29 billion due to unreliable power supply. Remote workers need reliable electricity to have fully powered laptops, mobile phones, and not to mention regular internet service to be productive at their jobs. Business owners also need regular power to run their businesses, attend to customers and make sales.',
    },
    {
        title: 'Reliable power supply for up to 24 hours.',
        text: `In April 2021, the World Bank reported that businesses in Nigeria suffered an annual loss of $29 billion due to unreliable power supply. Remote workers need reliable electricity to have fully powered laptops, mobile phones, and not to mention regular internet service to be productive at their jobs. Business owners also need regular power to run their businesses, attend to customers and make sales.
    Generators aren’t reliable as they could get faulty at any time and cost a lot to repair, but with quality lithium solar systems gotten and financed through SunFi, remote workers/business owners are guaranteed to have a more reliable source of power to function and be the best at their jobs and live an easier life.
    `,
    },
    {
        title: 'It’s cheap with a payment plan from SunFi.',
        text: 'SunFi lessens the financial barrier attached to owning solar systems with payment plans that allow you to pay in 3- 36 instalments. SunFi also partners with the best solar energy companies in Nigeria to help consumers get quality systems that last long.',
    },
    {
        title: 'It requires little to no maintenance.',
        text: 'Unlike generators that require costly weekly/monthly maintenance to keep functioning, solar systems require little to no maintenance when appropriately utilised. ',
    },
]

export const contentSevenBulletList = [
    {
        title: 'Cost savings:',
        text: `Fuel can be expensive and subject to price fluctuations. Petrol retails at an expensive price of N300 -N400/litre in many places nationwide.<br/>This is causing businesses to spend much of their budget on fuel to run their generators and could lead to the death of many small businesses, as seen in a 2022 report from <a href="https://www.aljazeera.com/features/2022/2/24/small-businesses-hurting-from-recurring-fuel-scarcity-in-nigeria" target="_blank">Al-Jazeera</a>.<br/><br/>In contrast, solar has lower operating costs and can help provide a stable energy source. With a payment plan from <a href="https://www.sunfi.co/" target="_blank">SunFi</a>, it's 100 times cheaper to own a solar system; this can help businesses budget more and increase profits.`,
    },
    {
        title: 'Improved Brand Awareness:',
        text: 'Another good thing about using clean energy is that it makes a business look good. People are starting to pay attention to how their actions affect the planet. They also want to buy from companies trying to help the environment. Businesses can show they care about the environment with clean energy.',
    },
    {
        title: 'Reliability:',
        text: "Clean energy can improve the reliability of the power supply. Nigeria's power grid is unreliable, with frequent collapses and blackouts- this can be costly for businesses, leading to lost production and revenue. Clean energy is a reliable source for businesses to work better and increase productivity.",
    },
    {
        title: 'Increased competitiveness:',
        text: 'Adopting clean energy can make your business stand out and compete in global markets.',
    },
    {
        title: 'Improved health and environment:',
        text: "Clean energy reduces air pollution, improves public health, and positively impacts businesses' relationships with local communities.",
    },
]

export const contentEightBulletList = [
    {
        title: 'Verify that you can install a solar panel',
        text: `This is important, especially if you live in a rented apartment. Unlike easily moved generators, solar panels are mounted on rooftops to generate maximum electricity for your home. So, getting permission from your landlord is necessary before proceeding. Additionally, look out for regulatory and legal considerations in your area. Some estates require permits for solar installations. Ensure compliance with local authorities or the Nigerian Electricity Regulatory Commission (NERC).
        `,
    },
    {
        title: 'Assess your energy needs',
        text: `Do you plan to use the solar inverter as your sole power source or to augment the grid power? Do you plan to power every item in your home or only the essentials? The answers to these questions will guide your decision-making process.<br/>
        Determine the appliances and devices you want to power and how long you want to power them. This will help you choose the suitable capacity for your home or business.`,
    },
    {
        title: 'Consider your financing options',
        text: `Explore various financing options for solar installations, such as loans, leasing, or pay-as-you-go schemes, to make the investment more affordable.
        For instance, if you plan to relocate soon, the subscription option is best suited for you. If you have a big budget, you can buy a system outright and if you don't, no worries. With <a href="https://www.sunfi.co/" target="_blank">SunFi</a>, you can enjoy solar on a subscription plan or pay in instalments to fully own a solar system.
        `,
    },
    {
        title: 'Consider the type of solar battery you want',
        text: `Now that you have settled the basics, it is time to go into the specifics of the solar system itself. We understand this aspect can throw you off, but not to worry; we'll break it down.<br/> Two main options are the Lead Acid Battery and the Lithium-ion Battery.<br/>
        <b>Lead Acid battery</b><br/>
        This is one of the oldest battery technologies and is a mixture of water and acids. It is widely used because of its recyclability and affordability. Despite this, <a href="https://www.powertechsystems.eu/home/tech-corner/lead-acid-battery-downsides/#:~:text=Even%20if%20you%20are%20going,less%20than%202%20years%20use." target="_blank">it has a short life span</a>. After a few months, you can only use 80% of the battery's capacity. The 20% is left to waste and will clog the battery, reduce its performance, and eventually damage it. These batteries take longer and do not charge during the rainy season.<br/>
        <b>Lithium-ion batteries</b><br/>
        Li-ion batteries use the newest technology in the solar industry and do not require maintenance or venting. <a href="https://sygniteng.com/lead-acid-vs-lithium-ion-batteries/#:~:text=Instead%2C%20they%20have%20a%20recommended,discharges%20of%2080%25%20or%20more." target="_blank">Unlike the lead acid battery, the power can be used up by the user.</a> This means you can use your battery up to 0% without reducing lifespan. Even more, it can be charged and discharged up to 3,000 times. Now, that's something to consider!<br/>
       
        Overall, lithium-ion batteries are the best bet for your home and business. With an over ten-year lifespan, <a href="https://news.energysage.com/lithium-ion-vs-lead-acid-batteries/" target="_blank">the lithium-ion battery is the best solar battery for Nigerians </a>  because it is not affected by harsh weather conditions and has a fast charge rate.
        As you consider which is best for you, here's a thought - six lead acid batteries are the equivalent of two lithium-ion batteries.`,
    },
    {
        title: 'Research solar energy providers',
        text: `Once you've chosen the type of battery you prefer, the next step is to find a reliable company to do business with. You'll want to partner with a reputable solar energy company or supplier in Nigeria. You can start your search online or ask for recommendations from friends and family.<br/>
        But here's the stress-free alternative with <a href="https://www.sunfi.co/" target="_blank">SunFi</a>: We've partnered with the best <a href="https://www.sunfi.co/partners" target="_blank">solar providers</a>, and we take care of recommending the finest solutions and providers to our valued customers. You won't have to deal with the hassle.
        `,
    },
    {
        title: 'Prepare your home for solar',
        text: `As you prepare for solar energy, it's essential to ensure that your appliances are energy-efficient, meaning they consume less power. Before transitioning to solar, consider switching your appliances to energy-saving appliances. This will enable you to maximise the benefits of your solar investment.
        As you plan for solar energy, ensure your appliances are energy efficient, i.e. they consume low energy. Before going solar, improve energy efficiency in your home or business by using energy-saving appliances and implementing energy conservation practices. This will help you make the most of your solar investment.
        
        Embracing solar energy is a step toward a more sustainable and cost-effective future. By following these steps, you're setting yourself on a path to energy independence. Like Lola, who can now enjoy affordable and reliable electricity, you can also benefit from solar power. 
        `,
    },
]

export const contentTwelveBulletList = [
    {
        title: 'Boosted Productivity:',
        text: `Solar energy made Adedamola more productive, allowing him to work without interruptions. Being an active remote worker, he cannot afford to miss valuable information from work or clients.`,
    },
    {
        title: 'Reliable Energy:',
        text: `He now works confidently, knowing he will not run out of power without the hassle of fetching fuel or waiting for the grid. He no longer waited on the unreliable grid, especially during important tasks. Switching to solar power gave him a dependable solution, eliminating the fear of rushing to save his work due to unstable grid electricity.`,
    },
    {
        title: 'Efficient Charging:',
        text: `With solar, his devices are always charged, eliminating worries about low current from the grid. It has been a game-changer for him. Additionally, he stopped visiting tech hubs to charge and work. Now, he enjoys the convenience of a trusted light source at home, bringing him genuine joy in his work routine.`,
    },
]

export const contentTwelveBulletListOne = [
    {
        title: 'Learning Opportunity:',
        text: `When you dive into solar technology, it is like an open door to understanding renewable energy systems. It presents an opportunity to learn more about our environment and the importance of keeping it sustainable.`,
    },
    {
        title: 'Inspiring others to adopt solar technology:',
        text: `By adopting solar energy, remote workers can serve as role models within their communities, inspiring others to consider clean energy solutions and promoting a culture of environmental responsibility.`,
    },
    {
        title: 'Efficient Charging:',
        text: `With solar, his devices are always charged, eliminating worries about low current from the grid. It has been a game-changer for him. Additionally, he stopped visiting tech hubs to charge and work. Now, he enjoys the convenience of a trusted light source at home, bringing him genuine joy in his work routine.`,
    },
]

export const contentThirteenBulletList = [
    {
        title: 'Easy Navigation on our Landing Page',
        text: `With the new improvement on the landing page, we're now inviting you to explore a world of possibilities with just a click. You only need to select the category you fall under to get started. Whether you're an individual or apartment owner looking to power your home essentials, a remote worker, a homeowner, or a large and small business owner, all you have to do is select your category and we'll provide you with just the right shopping experience you're looking for.`,
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Easy-Navigation-on-our-Landing-Page-thumbnail.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Easy-Navigation-on-our-Landing-Page.png',
    },
    {
        title: 'Listing Page for Package Selection',
        text: `We've organized the packages into categories, making it simple for you to find options that align with your specific requirements and preferences. You can easily navigate through the available packages, each presented with clear and detailed information.`,
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Listing-Page-for-Package-Selection-thumbnail.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Listing-Page-for-Package-Selection.png',
    },
    {
        title: 'Customize your package',
        text: `If our package options don't quite match your preferences, you can now explore the possibilities of customization, and design every detail to align precisely with your unique requirements and preferences.`,
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Customize-your-package-thumbnail.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Customize-your-package.png',
    },
    {
        title: 'Seamless Sharing',
        text: `Introducing our new share feature, allowing you to spread the love with just a click. Whether you're recommending a great deal or sharing a package with a loved one, it's easier than ever to enjoy our services together.`,
        thumbnailUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Seamless-Sharing-thumbnail.png',
        imageUrl:
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Seamless-Sharing.png',
    },
]
