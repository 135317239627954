import { permissionsControl } from 'src/utils/permissionsControl'

export const canViewProfileGroups = permissionsControl([
    'can_list_energy_profile_group',
])

export const canViewEnergyProfileDetails = permissionsControl([
    'can_retrieve_energy_profile',
])

export const canCreateEnergyProfile = permissionsControl([
    'can_create_energy_profile',
])
