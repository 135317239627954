import { useState } from 'react'
import useGetProfiles from './useGetProfiles'
import difference from 'lodash/difference'
import { useMutation } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import {
    createProfilesGroupApi,
    updateProfilesGroupApi,
} from 'src/api/admin/profiles/groups'
import { profileGroupSchema } from 'src/utils/validationSchema'
import { profileSortingType, profileType } from '../data'

const useCreateGroups = ({ onSuccess, data, enabled = false }) => {
    const { errorAlert } = useCustomToast()
    const [inputs, setInputs] = useState({
        name: '',
        customer_type: '',
        profile_sorting: 'Default: Sort by capacity (Ascending)',
        profiles: [],
    })
    const [selectedProfiles, setSelectedProfiles] = useState([])
    const [errors, setErrors] = useState({})
    const [searchValue, setSearchValue] = useState('')

    const { profiles, profilesRefetch, profilesFetching } = useGetProfiles({
        params: {
            customer_type: inputs.customer_type || '',
            ungrouped_profile: true,
            profileGroupId: data?.id || '',
        },
        enabled,
        onSuccess: data => {
            setInputs(prev => ({
                ...prev,
                profiles: data
                    .filter(profile => profile.group_id !== null)
                    ?.map(item => item.id),
            }))
            setSelectedProfiles(
                data
                    .filter(profile => profile.group_id !== null)
                    ?.map(item => item.id),
            )
        },
    })

    const removedProfiles = () => {
        const existingProfiles = profiles
            .filter(profile => profile.group_id !== null)
            ?.map(profile => profile.id)

        if (existingProfiles.length > 0)
            return difference(existingProfiles, selectedProfiles)
        else []
    }

    const addedProfiles = () => {
        const existingProfiles = profiles
            .filter(profile => profile.group_id !== null)
            ?.map(profile => profile.id)

        return difference(selectedProfiles, existingProfiles)
    }

    const { mutate: createProfileGroup, isLoading: createProfileGroupLoading } =
        useMutation({
            mutationFn: () =>
                data?.id?.length > 0
                    ? updateProfilesGroupApi(data?.id, {
                          ...inputs,
                          customer_type: profileType.filter(
                              item => item.name === inputs?.customer_type,
                          )[0]?.value,
                          profile_sorting: profileSortingType.filter(
                              item => item.name === inputs?.profile_sorting,
                          )[0]?.value,
                          profiles: {
                              add: addedProfiles() ?? [],
                              remove: removedProfiles() ?? [],
                          },
                      })
                    : createProfilesGroupApi({
                          ...inputs,
                          customer_type: profileType.filter(
                              item => item.name === inputs?.customer_type,
                          )[0]?.value,
                          profile_sorting: profileSortingType.filter(
                              item => item.name === inputs?.profile_sorting,
                          )[0]?.value,
                      }),
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
                setInputs({
                    name: '',
                    customer_type: '',
                    profile_sorting: '',
                    profiles: [],
                })
                setSelectedProfiles([])
            },
            onError: err => {
                errorAlert(
                    errorHandler(err?.response?.data) ||
                        'Something went wrong!',
                )
            },
        })

    const handleSubmit = () => {
        profileGroupSchema
            .validate(inputs, {
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                createProfileGroup()
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setErrors(prev => ({ ...prev, [name]: '' }))
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }
    const handleSelectChange = (name, value) => {
        setErrors(prev => ({ ...prev, [name]: '' }))
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleSearch = value => {
        setSearchValue(value)
    }

    const handleSelectAll = () => {
        if (searchValue === '') {
            const allIds = profiles?.map(profile => profile.id)
            setSelectedProfiles(allIds)
            setInputs(prev => ({
                ...prev,
                profiles: allIds,
            }))
        } else {
            const searchedIds = profiles.reduce((acc, product) => {
                if (
                    product.name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                ) {
                    acc.push(product.id)
                }
                return acc
            }, [])
            setSelectedProfiles(searchedIds)
            setInputs(prev => ({
                ...prev,
                profiles: searchedIds,
            }))
        }
    }

    const handleUnselectAll = () => {
        setSelectedProfiles([])
        setInputs(prev => ({
            ...prev,
            profiles: [],
        }))
    }

    const updateCheckedProfiles = values => {
        setSelectedProfiles(values)
        setInputs(prev => ({
            ...prev,
            profiles: values,
        }))
    }

    return {
        inputs,
        setInputs,
        profiles,
        profilesRefetch,
        profilesFetching,
        selectedProfiles,
        setSelectedProfiles,
        errors,
        updateCheckedProfiles,
        handleSearch,
        handleSelectAll,
        handleUnselectAll,
        handleInputChange,
        handleSelectChange,
        createProfileGroup,
        createProfileGroupLoading,
        handleSubmit,
    }
}

export default useCreateGroups
