//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Navigation from '../../components/Navigation'
import SeoComponent from '../../components/Seo'
import FrequentlyAskedQuestions from '../../components/Faq'
import CtaSection from '../../components/CtaSection'
import './termsofservice.scss'

import { ASI, DISI, OEMI, Consumer } from '../../utils/termsOfServiceData'

import { Tabs } from 'antd'
import Testimonial from '../Consumer/SmartShoppingExperience/Home/Testimonial/Testimonial'
import ConsumerFooter from 'src/components/ConsumerFooter'

const TermsOfService = ({ isModal, userType }) => {
    const { TabPane } = Tabs
    const location = useLocation()
    const history = useHistory()
    const provider_parameters = location?.search?.split('=')[1]
    const [activeTab, setActiveTab] = useState('1')

    useEffect(() => {
        if (provider_parameters?.length > 0) {
            if (provider_parameters === 'AI') {
                setActiveTab('2')
            }
            if (provider_parameters === 'DISI') {
                setActiveTab('3')
            }
            if (provider_parameters === 'OEMI') {
                setActiveTab('4')
            }
        }
    }, [])

    const handleTabUrlSwitch = currentTab => {
        const currentTabUrl =
            currentTab === '4'
                ? 'OEMI'
                : currentTab === '3'
                ? 'DISI'
                : currentTab === '2'
                ? 'AI'
                : 'Consumer'
        history.push(`/terms-of-service?tab=${currentTabUrl}`)
    }

    return (
        <>
            {!isModal && (
                <SeoComponent
                    title="Terms of Service | SunFi | Simplifying and Scaling Clean Energy"
                    tracker="TermsOfServiceTracker"
                />
            )}

            {!isModal && (
                <Navigation
                    bgColor="var(--blue-4)"
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnBgColorOutline="var(--blue-4)"
                    btnTextColorOutline="var(--blue)"
                    textColor="var(--black)"
                    page="termsofservice"
                    btnType="none"
                />
            )}

            <div
                className={
                    isModal ? 'TOSModalHeaderContainer' : 'HeaderContainer'
                }
            >
                <p
                    className={
                        isModal ? 'TOSMobileHeaderSubText' : 'HeaderSubText'
                    }
                >
                    LAST UPDATED IN MARCH, 2022
                </p>
                <h1 className={isModal ? 'TOSModalHeaderText' : 'HeaderText'}>
                    Terms of Service
                </h1>
            </div>

            {userType === 'consumer' ? (
                <div className="TOSModalContentWrapper">
                    <Consumer signUpPageModal />
                </div>
            ) : (
                <div className="TabsContainer">
                    <Tabs
                        activeKey={activeTab}
                        onChange={values => {
                            setActiveTab(values)
                            handleTabUrlSwitch(values)
                        }}
                    >
                        <TabPane tab="Consumer" key="1">
                            <div className="TOSContentWrapper">
                                <Consumer />
                            </div>
                        </TabPane>
                        <TabPane tab="Associate Installer" key="2">
                            <div className="TOSContentWrapper">
                                <ASI />
                            </div>
                        </TabPane>
                        <TabPane tab="Distributor that installs" key="3">
                            <div className="TOSContentWrapper">
                                <DISI />
                            </div>
                        </TabPane>
                        <TabPane tab="Manufacturer that installs" key="4">
                            <div className="TOSContentWrapper">
                                <OEMI />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            )}
            {!isModal && (
                <CtaSection
                    title="Simple and automated steps to the"
                    subtitle="Build your small solar business on SunFi"
                    bgColor="#011A3C"
                    page="partners"
                />
            )}
            {!isModal && <FrequentlyAskedQuestions />}
            {!isModal && <Testimonial />}

            {!isModal && <ConsumerFooter />}
        </>
    )
}

export default TermsOfService
