import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    page: '1',
    provider_name: '',
    start_date: null,
    end_date: null,
    start_lowest_tenure_annual_interest: '0',
    end_lowest_tenure_annual_interest: null,
    payment_model: '',
    is_global: 'false',
    current_tab: '1',
}

const adminCreditSystemListing = createSlice({
    name: 'adminCreditSystemListingFilter',
    initialState,
    reducers: {
        updateAdminCreditSystemFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetAdminCreditSystemFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateAdminCreditSystemFilters, resetAdminCreditSystemFilters } =
    adminCreditSystemListing.actions

export const updatedAdminCreditSystemFilters = state =>
    state.adminCreditSystemListingFilter

const persistConfig = {
    key: 'adminCreditSystemListingFilter',
    storage,
}

export const persistedAdminCreditSystemListingReducer = persistReducer(
    persistConfig,
    adminCreditSystemListing.reducer,
)

export default adminCreditSystemListing.reducer
