import ChatCTA from 'src/components/ChatCTA'
import styles from './form-wrapper.module.scss'
import PropTypes from 'prop-types'

const FormWrapper = ({ children }) => {
    const { Wrapper, ChildrenWrapper, CtaWrapper } = styles

    return (
        <div className={Wrapper}>
            <div id="scrolltoview_residence"></div>
            <div className={ChildrenWrapper}>{children}</div>
            <div className={CtaWrapper}>
                <ChatCTA text={'Have questions about this process?'} />
            </div>
        </div>
    )
}

FormWrapper.propTypes = {
    children: PropTypes.node.isRequired,
}

export default FormWrapper
