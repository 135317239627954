/* eslint-disable react/prop-types */
import { useCustomFormik } from "src/utils/Hooks/useCustomFormik"
import * as Yup from 'yup'
import styles from './consumerfooter.module.scss'
import { ReactComponent as SendIcon } from 'src/assets/images/email-send-icon.svg'
import { ReactComponent as VerifiedIcon } from 'src/assets/images/circle-check-green.svg'
import { InlineLoaderDark } from "../Loader"
import { useEffect, useState } from "react"


const InlineLoader = () => {
  return (
    <i
      style={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      <InlineLoaderDark />
      <InlineLoaderDark />
      <InlineLoaderDark />
    </i>
  )
}
const NewsLetterForm = ({ status, message, onValidated }) => {
  const {
    ConsumerFooterInput,
    InputWithIconStyle,
    SendIconStyle,
    whiteText,
    errText,
    verifiedIcon
  } = styles
  const [newStatus, setNewStatus] = useState('')
  const [newMessage, setNewMessage] = useState('')
  const { values, errors, touched, handleChange, handleBlur, setFieldTouched, setFieldValue, setFieldError } = useCustomFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Please provide a valid email').required('Please provide your email')
    }),
    validateOnBlur:true,
    validateOnChange:false
  })

  const handleSubscribe = () => {
    if (errors.email || !values.email) {
      setFieldTouched('email', true)
      return
    } else {
      onValidated({ EMAIL: values.email })
    }
  }

  useEffect(() => {
    if (status === 'success') {
      setNewStatus('success')
      setNewMessage(message)
      setTimeout(() => {
        setFieldValue('email', '')
        setFieldTouched('email', false)
        setNewMessage('')
        setNewStatus('default')
      }, 2000);
      return
    } else {
      setNewMessage(message)
      setNewStatus(status)
    }
  }, [message, setFieldTouched, setFieldValue, status])

  const getSubscribeStatusIcon = (status) => {
    const icons = {
      success: <VerifiedIcon className={verifiedIcon} />,
      sending: <InlineLoader />,
      default: <SendIcon onClick={handleSubscribe} />
    }
    return  icons[status]
  }

  return (
    <div>
      <div className={InputWithIconStyle}>
        <input
          type="email"
          placeholder="Enter your email here"
          name="email"
          className={`${ConsumerFooterInput} ${(status === 'error' || (touched.email && errors.email)) ? errText : ''}`}
          value={values.email}
          onChange={e=> {
            handleChange(e)
            setFieldError('email','')
          }}
          onBlur={handleBlur}
        />
        <div className={SendIconStyle}>
          {(!status || status === 'error') && <SendIcon onClick={handleSubscribe} />}
          {getSubscribeStatusIcon(newStatus)}
        </div>

      </div>
      {touched.email && errors.email && <div className={whiteText}>{errors.email}</div>}
      {status === "error" && <div className={whiteText} dangerouslySetInnerHTML={{ __html: newMessage }} />}
      {status === "success" && <div className={whiteText}>{newMessage}</div>}
    </div>
  )
}

export default NewsLetterForm