export const PrivacyPolicyData = [
    {
        title: 'Introduction',
        text: '<b>sunfi.co</b> is provided by SunFi Technology Solutions Ltd (“SunFi”). At SunFi, we value your privacy and we are committed to safeguarding your personal information. All personal data that you provide us will be protected and kept confidential among our affiliates, representatives, and privies.<br /><br /> Throughout the website, the terms “<b>we</b>”, “<b>us</b>” and “<b>our</b>” refer to SunFi. <br /><br /> This Privacy Policy explains how we collect, use, share and protect your personal data or any personal data that you share with us in connection with your relationship with us as a user or potential user. It applies to all our clients, potential clients, consultants, partners, and every other person we hold information about. <br /><br /> This policy also sets out your rights and who you may contact for further information. <br /><br /> You agree to this Privacy Policy by visiting our website and when you use our services. <br /><br /> Your use of our services, and any dispute over privacy is subject to this Policy and our Terms of Service, including its applicable limitations on damages and the resolution of disputes. Our Terms of Service are incorporated by reference into this Policy. <br /><br />Our website and services are not directed at you if we are prohibited by any law of any jurisdiction from making the information on our website available to you and is not intended for any use that would be contrary to local law or regulation.',
    },
    {
        title: 'Consent',
        text: 'You accept this privacy policy when you give consent upon access to our platforms, or use our services, content, features, technologies or functions offered on our website, digital platforms or visit any of our offices for official or non-official purposes.',
    },
    {
        title: 'Age Restriction ',
        text: 'You affirm that you are over the age of 18 years and have the right to contract in your own name, and that you have read the above authorisation and fully understand its contents.',
    },
    {
        title: 'Information We Collect.',
        type: 'decimal-numbered-list',
        text: 'There are two categories of information we collect. We collect a variety of information from our users and visitors to our website. As described below, some information is automatically collected when you visit our website, and some you provide to us when filling out a form or communicating with us.',
        subParagraph: [
            {
                subtext:
                    '<b>Information Collected Automatically:</b> Whenever you visit our website, our web servers automatically collect non-personal information such as the domain name of the internet access provider, the internet protocol address used to connect the computer to the internet, the average time spent on our website, pages viewed, information searched for, access times, and other relevant statistics.',
            },
            {
                subtext:
                    '<b>Information You Provide Us:</b> If you provide us with personal information, by contacting us, or subscribing to our services we collect the following personal information:',
                child: [
                    {
                        childText: 'Username and password;',
                    },
                    {
                        childText: 'Contact information;',
                    },
                    {
                        childText: 'Payment information;',
                    },
                    {
                        childText: 'Any other information you provide to us.',
                    },
                ],
            },
            {
                subtext:
                    '<b>Bank Verification Number:</b> When you apply for finance,  we collect your Bank Verification Number (BVN) to assess your creditworthiness. This information is only used for credit risk analysis purposes.',
            },
        ],
    },
    {
        title: 'Using Your Personally Identifiable Information',
        type: 'decimal-numbered-list',
        text: '“Personally Identifiable Information” means any information that (a) identifies or can be used to identify, contact, or locate the person to whom such information pertains, or (b) from which identification or contact information of an individual person can be derived. <br /><br />We primarily collect your information to ensure that we provide the most efficient service to you, monitor the use and improve our website and other legitimate interests. Your information will solely be used and disclosed for the following purposes:',
        subParagraph: [
            {
                subtext: 'To help us verify your identity;',
            },
            {
                subtext:
                    'To carry out our obligations ensuing from any contracts entered into between you and us;',
            },
            {
                subtext:
                    'To provide you with the products, services and information you request from us;',
            },
            {
                subtext:
                    'To assist you with enquiries and improve our customer service;',
            },
            {
                subtext:
                    'To assist us in carrying out marketing analysis and customer profiling (including transactional information), conduct research, including creating statistical and testing information;',
            },
            {
                subtext:
                    'To allow us to communicate with you in any way (including e-mail, telephone, visit, and text or multimedia messages);',
            },
            {
                subtext: 'For our billing and account purposes;',
            },
            {
                subtext: 'To help prevent and detect fraud or loss;',
            },
            {
                subtext: 'To update our records;',
            },
            {
                subtext:
                    'To make recommendations and suggestions to you about services offered by us unless you have previously asked us not to do so;',
            },
            {
                subtext:
                    'To send you service or support messages, such as updates, security alerts, email notifications and /or newsletters;',
            },
            {
                subtext:
                    'To comply with the regulatory obligation by the CBN. We share customer data with registered credit bureau partners to ensure that other financing companies can access your credit history to aid their financing decisions.',
                child: [
                    {
                        childText:
                            'In the event of non-performing loans, we may report this information to the credit bureau portal, in line with industry best practices.',
                    },
                ],
            },
            {
                subtext:
                    'For compliance with legal and regulatory obligations.',
            },
        ],
    },
    {
        title: 'Data Accuracy',
        text: 'Your personal data must be accurate and kept up to date. In this regard, SunFi shall ensure that any data it collects and/or processes is accurate and not misleading in a way that could be harmful to you; make efforts to keep your personal data updated where reasonable and applicable; and make timely efforts to correct or erase your personal data when inaccuracies are discovered.',
    },
    {
        title: 'Other Information We Collect',
        text: 'Other information which may be automatically collected from you when you visit our website include; domain name of your internet service provider, the internet protocol address used to connect the computer to the internet, the average time spent on our website, pages viewed, information searched for, access times, your geographical location, operating system, referral source, and other relevant statistics. <br /><br />We may also collect information from you using cookies and other analytical tools especially when you use our products and services. More details are provided below in our section on Cookies.',
    },
    {
        title: 'Data Confidentiality',
        text: 'Your information is regarded as confidential and will not be divulged to any third party, except under legal and/or regulatory conditions. You have the right to request copies of any and all information we keep on you, if such requests are made in compliance with the Freedom of Information Act and other relevant enactments. While we are responsible for safeguarding the information entrusted to us, your role in fulfilling confidentiality duties includes, but is not limited to, adopting and enforcing appropriate security measures such as non-sharing of passwords and other platform login details, adherence with physical security protocols on our premises, dealing with only authorized officers of SunFi. ',
    },
    {
        title: 'Disclosures',
        type: 'decimal-numbered-list',
        text: 'Keeping your account information accurate and up to date is very important. If your account information is incomplete, inaccurate, or not current, please use the Contact Us option on our Site, or call or write to us at the telephone numbers or appropriate address for changes listed on your account statements, records, online, or other account materials. You can also speak to a customer representative from the Company.',
        subParagraph: [
            {
                subtext:
                    'We will not sell, publish, or disclose to third parties your personally identifiable information collected on our website, through our servers or otherwise obtained by us, other than to provide our services and as set forth in this Privacy Policy. We may share generic aggregated demographic information not linked to any personally identifiable information regarding visitors and Users with our business partners, trusted affiliates, professional advisers and advertisers for the purposes outlined above. We may share your information with these third parties for those limited purposes if you have given us your permission and in compliance with the Nigeria Data Protection Regulation 2019 (NDPR).',
            },
            {
                subtext:
                    'We may request and provide information about you from and to third parties to provide our services.',
            },
            {
                subtext:
                    'We will notify you as soon as we become aware of a harmful data breach which may result in a risk of your rights and freedom.',
            },
            {
                subtext:
                    'You have the right to request an erasure of your data at any time.',
            },
            {
                subtext: 'We will notify you if we are transferring your data.',
            },
            {
                subtext:
                    'You may request at any time that we halt further dissemination of your data or cease to use your data.',
            },
            {
                subtext:
                    'If you submit content in a public forum or a social media post, or use a similar feature on our website, that content is publicly visible. ',
            },
            {
                subtext:
                    'We may disclose Personally Identifiable Information if required to do so by law or in the good faith belief that such action is necessary to (a) conform with the requirements of the law or comply with legal process served on us, or (b) act in urgent circumstances to protect the personal safety of users of our service or members of the public.',
            },
            {
                subtext:
                    'To the extent practicable and legally permitted, we will attempt to advise you prior to any such disclosure, so that you may seek a protective order or other relief limiting such disclosure.',
            },
        ],
    },
    {
        title: 'Transfer of Personal Data',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    '<b>Third Party Processor within Nigeria.</b> <br /> We may engage the services of third parties in order to process your personal data. The processing by such third parties shall be governed by a written contract with SunFi to ensure adequate protection and security measures are put in place by the third party for the protection of your personal data in accordance with the terms of this policy and the Nigeria Data Protection Regulation (NDPR).',
            },

            {
                subtext: 'Transfer of Personal Data to Foreign Country',
                child: [
                    {
                        childText:
                            'Where your personal data is to be transferred to a country outside Nigeria, SunFi shall put adequate measures in place to ensure the security of such data. In particular, SunFi shall, among other things, conduct a detailed assessment of whether the said country is on the National Information Technology Development Agency (NITDA) White List of Countries with adequate data protection laws. ',
                    },
                    {
                        childText:
                            'Add assessment of whether the said country is on the National Information Technology Development Agency (NITDA) White List of Countries with adequate data protection laws. ',
                    },
                    {
                        childText:
                            'Transfer of your personal data out of Nigeria would be in accordance with the provisions of the NDPR. SunFi will therefore only transfer your personal data out of Nigeria on one of the following conditions: ',
                        textItems: [
                            'Your explicit consent has been obtained;',
                            'The transfer is necessary for the performance of a contract between you and SunFi;',
                            'The transfer is necessary to conclude a contract between SunFi and a third party in your interest;',
                            'The transfer is necessary for reason of public interest;',
                            'The transfer is for the establishment, exercise or defense of legal claims;',
                            'The transfer is necessary in order to protect your vital interests or the interests of other persons, where the you are physically or legally incapable of giving consent.',
                        ],
                    },
                ],
            },
            {
                subtext:
                    'Provided, in all circumstances, that you have been manifestly made to understand through clear warnings of the specific principle(s) of data protection that are likely to be violated in the event of transfer to a third country, this provision shall not apply to any instance where you are answerable in duly established legal action for any civil or criminal claim in another country.',
            },
            {
                subtext:
                    'We will take all necessary steps to ensure that your personal data is transmitted in a safe and secure manner. Details of the protection given to your information when it is transferred outside Nigeria shall be provided to you upon request.',
            },
            {
                subtext:
                    'Where the recipient country is not on the White List and none of the conditions stipulated in clause 9.2.2 of this policy is met, SunFi will engage with NITDA and the Office of the Honourable Attorney General of the Federation (HAGF) for approval with respect to such transfer.',
            },
        ],
    },
    {
        title: 'Your Rights ',
        type: 'decimal-numbered-list',
        text: 'Subject to certain limitations and exceptions, you are entitled to the following principal rights under the NDPR:',
        subParagraph: [
            {
                subtext:
                    'You have the right to be notified if we are transferring your personal information.',
            },
            {
                subtext:
                    'You have the right to request an erasure of your personal information at any time.',
            },
            {
                subtext:
                    'You have the right to request that we rectify inaccurate personal information.',
            },
            {
                subtext:
                    'You may request at any time that we halt further dissemination of your data or cease to use your personal information.',
            },
            {
                subtext:
                    'You have the right to request for copies of your personal information.',
            },
        ],
    },
    {
        title: 'Website Security',
        text: 'We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures such as secure sockets layer (SSL) to safeguard and secure the information we collect online. We use encryption tools when accepting and transmitting delicate visitor information through our website. Some of the other safeguards we use are firewalls and physical access controls to our data centres, and information access authorization controls.',
    },
    {
        title: 'Training',
        text: 'We shall ensure that employees who collect, access and process your personal data receive adequate data privacy and protection training in order to develop the necessary knowledge, skills and competence required to effectively manage the compliance framework under this policy and the NDPR with regard to the protection of personal data. On an annual basis, we shall develop a capacity building plan for our employees on data privacy and protection in accordance with the NDPR',
    },
    {
        title: 'Use of Cookies',
        text: 'We use cookies to identify you as a user and make your user experience easier, customise our services, content and advertising; help you ensure that your account security is not compromised, mitigate risk and prevent fraud; and to promote trust and safety on our website. Cookies allow our servers to remember your account log-in information when you visit our website, IP addresses, date and time of visits, monitor web traffic and prevent fraudulent activities. If your browser or browser add-on permits, you have the choice to disable cookies on our website; however, this may limit your ability to use our website.',
    },
    {
        title: 'The Data We Retain',
        text: 'We will retain your information for as long as needed to provide you with our services, comply with our legal and statutory obligations or verify your information with a financial institution. We are statutory obligated to retain the data you provide us with in order to process transactions, ensure settlements, make refunds, identify fraud and in compliance with laws and regulatory guidelines applicable to us, our banking providers and credit card processors.',
    },
    {
        title: 'Data Breach Management Procedure ',
        text: '',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'In the event where there is any accidental or unlawful destruction, processing, loss, alteration, unauthorized disclosure of, or access to your personal data, we shall: ',
                child: [
                    {
                        childText:
                            'notify you within 24 hours of the occurrence of the data breach;',
                    },
                    {
                        childText:
                            'properly investigate the breach and take the necessary steps to mitigate such breach;',
                    },
                    {
                        childText:
                            'identify remediation requirements and track the resolution of such breach; and',
                    },
                    {
                        childText:
                            'notify NITDA or any other regulatory authority, where necessary. ',
                    },
                ],
            },
        ],
    },
    {
        title: 'Links to Third Party Websites',
        type: 'decimal-numbered-list',
        subParagraph: [
            {
                subtext:
                    'Our website may contain links to third-party websites or services that are not owned or controlled by us.',
            },
            {
                subtext:
                    'We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.',
            },
            {
                subtext:
                    'We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.	',
            },
        ],
    },
    {
        title: 'Limitation of Liability ',
        text: 'We exercise reasonable efforts to safeguard the security and confidentiality of your personal data; however, we will not be liable for unauthorised disclosure of personal data that occurs through no fault of ours',
    },
    {
        title: 'Changes to this Privacy Policy',
        text: 'Changes may be made to this Privacy Policy from time. Whenever such changes are made, we will notify you. These changes will take effect immediately after you have been notified.',
    },
    {
        title: 'Contact Us',
        text: 'If you would like more information or you have any comments or questions on our privacy policy, please contact us at help@sunfi.co  ',
    },
]
