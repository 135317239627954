/* eslint-disable react/prop-types */

import styles from './formtitlebar.module.scss'

const { HeaderWrapper, Title, SubTitle } = styles

const FormTitleBar = ({
    title,
    subtitle,
    alignLeft,
    marginTop,
    marginBottom,
    marginLeft,
    fontFamily,
    titleFontSize,
    subtitleFontSize,
    textAlignCenter,
    subtitleWidth,
    subtitleLineHeight,
    subtitleColor
}) => {
    return (
        <div
            className={HeaderWrapper}
            style={{
                alignItems: alignLeft && 'flex-start',
                textAlign: textAlignCenter && 'center',
                marginTop: marginTop,
                marginBottom: marginBottom,
                marginLeft: marginLeft,
            }}
        >
            <h2
                className={Title}
                style={{ fontFamily: fontFamily, fontSize: titleFontSize }}
            >
                {title}
            </h2>
            <p className={SubTitle} style={{ fontSize: subtitleFontSize, width: subtitleWidth, lineHeight: subtitleLineHeight, color: subtitleColor }}>
                {subtitle}
            </p>
        </div>
    )
}

export default FormTitleBar
