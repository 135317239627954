import axios from 'src/config/axios'

export const fetchApprovedBrandsApi = async () => {
    const request = await axios.get('/products/component-brands/names')
    return request
}

export const persistBrandsApi = async payload => {
    const request = await axios.post('/products/component-brands', payload)
    return request
}

export const deleteApprovedBrandsApi = async payload => {
    const request = await axios.post(
        '/products/component-brands/delete-approved-brands',
        payload,
    )
    return request
}
