import { useMutation, useQueryClient } from 'react-query'
import { updateProfilePackageApi } from 'src/api/admin/profiles/details'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const useUpdateProfilePackage = onSuccess => {
    const queryClient = useQueryClient()
    const { successAlert, errorAlert } = useCustomToast()

    const { mutate, mutateAsync, isLoading } = useMutation(
        ({ profileId, packageId, payload }) =>
            updateProfilePackageApi(profileId, packageId, payload),
        {
            onSuccess: () => {
                successAlert('Package updated successfully')
                queryClient.invalidateQueries('fetch-admin-consumer-profile')
                queryClient.invalidateQueries('fetch-admin-profile-package')
                onSuccess?.()
            },
            onError: error => {
                errorAlert(errorHandler(error?.response?.data))
            },
        },
    )

    return {
        updateProfilePackageMutate: mutate,
        updateProfilePackageMutateAsync: mutateAsync,
        updateProfilePackageLoading: isLoading,
    }
}

export default useUpdateProfilePackage
