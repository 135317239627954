import { useMutation } from 'react-query'
import { submitPagaAuth } from './api'
import { errorHandler } from 'src/utils/errorHandler'

export const useSubmitPagaAuth = ({ onSuccess, onError }) => {
    const { data, mutate, isLoading } = useMutation({
        mutationFn: data =>
            submitPagaAuth(data.estimation_id, data.authorization_code),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: error => {
            if (onError) {
                onError(
                    error?.response?.data?.message ||
                        errorHandler(error?.response?.data)[0],
                )
            }
        },
    })

    return {
        submitPagaAuthCode: mutate,
        submitPagaAuthCodeLoading: isLoading,
        submitPagaAuthCodeData: data,
    }
}
