import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import SolarCity from 'src/assets/images/solar-city-bg.svg'
import ButtonComponent from 'src/components/Button'
import ChatCTA from 'src/components/ChatCTA'
import s from './styles.module.scss'
import { ReactComponent as BigWhiteCloseIcon } from 'src/assets/images/close-icon-white-big.svg'
import { isMobile } from 'src/utils/mediaQueries'

const WelcomeModal = ({ showModal, onCancel, firstName, companyLogo }) => {
    return (
        <SecondaryModal
            showModal={showModal}
            modalHeight={isMobile ? '475px' : '436px'}
            modalWidth={'100% !important'}
            noPadding={isMobile ? '15px' : '0px'}
            onCancel={onCancel}
            closable={isMobile}
            modalClass={isMobile ? s.modalClass : undefined}
            content={
                <div className={s.wrapper}>
                    <div className={s.left}>
                        <img src={companyLogo} alt="company logo" />
                        <h2>Hey {firstName}, welcome to your workspace!</h2>
                        <p>
                            We are really excited about this partnership, this
                            workspace is for you to manage all your group
                            members, adding or removing members, and making bulk
                            payment for all members. Please reach out to us if
                            you have any question
                        </p>
                        <div className={s.btnAndCta}>
                            <ButtonComponent
                                btnBgColor={'#004aad'}
                                btnTextColor={'#ffffff'}
                                btnWidth={isMobile ? '260px' : '166px'}
                                btnHeight={isMobile ? '50px' : '56px'}
                                handleClick={onCancel}
                            >
                                Get Started
                            </ButtonComponent>
                            <ChatCTA />
                        </div>
                    </div>
                    <div className={s.cityImgWrapper}>
                        <img
                            src={SolarCity}
                            alt="solar city"
                            className={s.solarCityImg}
                        />
                        <BigWhiteCloseIcon
                            className={s.closeIcon}
                            role="button"
                            onClick={onCancel}
                        />
                    </div>
                </div>
            }
        />
    )
}

WelcomeModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    firstName: PropTypes.string,
    companyLogo: PropTypes.string,
}

export default WelcomeModal
