export const tableHeaders = [
    'Group name',
    'Profiles added',
    'Profile type',
    'Last Updated Time',
    'Actions',
]

export const actionModalTypes = type => {
    switch (type) {
        case 'group_created':
            return {
                title: 'Profile group created',
                subTitle: 'You have successfully created a new profile group',
                primaryBtnText: 'Done',
                noCancelBtn: true,
                noPrimaryBtn: false,
                actionType: 'success',
            }
        case 'group_updated':
            return {
                title: 'Profile group updated',
                subTitle: 'You have successfully updated this profile group',
                primaryBtnText: 'Done',
                noCancelBtn: true,
                noPrimaryBtn: false,
                actionType: 'success',
            }
        case 'group_deleted':
            return {
                title: 'Profile group deleted',
                subTitle: 'You have successfully deleted this profile group',
                primaryBtnText: 'Done',
                noCancelBtn: true,
                noPrimaryBtn: false,
                actionType: 'success',
            }
        case 'delete_group_with_no_profiles':
            return {
                title: 'Delete profile group',
                subTitle:
                    'You’re about to delete this profile group, it’ll be taken out from the list of available profiles groups. Please confirm',
                primaryBtnText: 'Delete group',
                noCancelBtn: false,
                noPrimaryBtn: false,
                actionType: 'warning',
            }
        case 'delete_group_with_profiles':
            return {
                title: 'Delete profile group',
                subTitle:
                    'This profile group currently contains profiles, please reassign each profile to a new group before you can delete the group',
                primaryBtnText: '',
                noCancelBtn: false,
                noPrimaryBtn: true,
                actionType: 'warning',
            }
        default:
            return {
                title: '',
                subTitle: '',
                primaryBtnText: '',
                noCancelBtn: false,
                primaryBtn: false,
                actionType: '',
            }
    }
}

export const profileSortingTypeMapping = {
    ENERGY_CAPACITY_ASCENDING: 'Default: Sort by capacity (Ascending)',
    ENERGY_CAPACITY_DESCENDING: 'Sort by capacity (Descending)',
    ALPHABETICALLY_ASCENDING: 'Sort alphabetically (Ascending)',
    ALPHABETICALLY_DESCENDING: 'Sort alphabetically (Descending)',
}
