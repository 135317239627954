import { useEffect, useState } from 'react'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import { validateIdCardDetails } from 'src/utils/validationSchema'

const useVerifyIdModal = (inputs, onCancel) => {
    const [modalInputs, setModalInputs] = useState({
        firstName: '',
        lastName: '',
        identityType: '',
        identityNumber: '',
    })
    const [modalOnlyInputError, setModalOnlyInputError] = useState({})
    const validateField = useValidateOnBlur()

    const removeErrors = name => {
        setModalOnlyInputError(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const handleChange = e => {
        const { name, value } = e.target

        setModalInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
        removeErrors(name)
    }

    const closeModal = () => {
        setModalInputs({
            firstName: inputs?.firstName,
            lastName: inputs?.lastName,
            identityType: inputs?.identityType,
            identityNumber: inputs?.identityNumber,
        })
        setModalOnlyInputError({})
        onCancel()
    }

    const handleBlur = async e => {
        const { name, value } = e.target

        await validateField({
            name,
            value,
            schema: validateIdCardDetails,
            setErrors: setModalOnlyInputError,
            context: {
                identityType: modalInputs?.identityType,
            },
        })
    }

    const idHelpPayload = {
        name: inputs?.firstName + ' ' + inputs?.lastName,
        phone_number: formatPhoneNumber(inputs?.phone),
        email: inputs?.email,
        message: 'ID Verification failed and consumer is requesting for help',
    }

    useEffect(() => {
        setModalInputs({
            firstName: inputs?.firstName,
            lastName: inputs?.lastName,
            identityType: inputs?.identityType,
            identityNumber: inputs?.identityNumber,
        })
    }, [
        inputs?.identityType,
        inputs?.identityNumber,
        inputs?.firstName,
        inputs?.lastName,
    ])

    return {
        modalInputs,
        idHelpPayload,
        modalOnlyInputError,
        handleChange,
        handleBlur,
        closeModal,
    }
}

export default useVerifyIdModal
