/* eslint-disable react/prop-types */
import { useState, Fragment, useEffect } from 'react'
import FormTitleBar from 'src/components/FormTitleBar'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { ReactComponent as EditIcon } from 'src/assets/images/EditIcon.svg'
import { ReactComponent as DeleteIcon } from 'src/assets/images/delete-icon.svg'
import { ReactComponent as PlusIcon } from 'src/assets/images/plus-icon.svg'
import {
    InputFields,
    SelectField,
    MultipleSelectField,
} from 'src/components/InputFields'
import Checkbox from 'src/components/Checkbox'
import Button from 'src/components/Button'
import styles from '../componentType.module.scss'
import { v4 as uuidv4 } from 'uuid'

import { attributeValidationSchema } from 'src/utils/validationSchema'
import {
    attributeTypeOptions,
    unitOfMeasurement,
    allowedOptions,
} from '../data'
import { addComponentTypeAttributeApi } from 'src/api/admin/settings/componenttypes'
import { addNewComponentTypeAttributeApi } from 'src/api/admin/settings'
import { useQuery } from 'react-query'
import { InlineLoader } from 'src/components/Loader'
import Toast from 'src/components/Toast'
import { errorHandler } from 'src/utils/errorHandler'
import { isMobile } from 'src/utils/mediaQueries'

const {
    ModalContent,
    ModalBtn,
    MoreActions,
    CheckBoxText,
    CheckBoxWrapper,
    AttributeCount,
    SavedAttributeWrapper,
    SavedAttributeName,
    ModalTitleWrapper,
    SavedAttributeActions,
} = styles

const AttributeCard = ({ attribute, idx, handleDelete, handleEdit }) => {
    return (
        <>
            <p className={AttributeCount}>Attribute {idx + 1}</p>
            <div className={SavedAttributeWrapper}>
                <h1 className={SavedAttributeName}>{attribute.name}</h1>
                <p className={CheckBoxText}>
                    {attribute.field_type}
                    {isMobile ? <br /> : ''}Attribute type
                </p>
                <div className={SavedAttributeActions}>
                    <EditIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEdit(attribute.id)}
                    />
                    <DeleteIcon
                        onClick={() => handleDelete(attribute.id)}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </div>
        </>
    )
}

const AddAttribute = ({ showModal, onCancel, handleAddAttribute, id, cancelText='Cancel', componentName  }) => {
    const [errors, setErrors] = useState({})
    const [formMode, setFormMode] = useState(true)
    const [attributes, setAttributes] = useState([])
    const [attributesEdit, setAttributesEdit] = useState([])
    const [editIdx, setEditIdx] = useState(null)
    const [inputs, setInputs] = useState({
        name: '',
        field_type: '',
        units_of_measurement: [],
        required: false,
        options: [],
        id: '',
    })
    const [payload, setPayload] = useState([])
    const [triggerSubmit, setTriggerSubmit] = useState(false)
    const [modalError, setModalError] = useState(false)
    const [modalErrorMessage, setModalErrorMessage] = useState('')

    const newComponentTypePayload = {
        name: componentName,
        attributes: payload
    }

    const { refetch: addAttrRefetch, isLoading: addAttrLoading } = useQuery(
        'add-attribute',
        () => componentName ? addNewComponentTypeAttributeApi(newComponentTypePayload) : addComponentTypeAttributeApi(id, payload),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                handleAddAttribute()
                setTriggerSubmit(false)
                handleCancel()
            },
            onError: error => {
                setModalError(true)
                setModalErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const handleChange = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleCheck = () => {
        const value = inputs?.required
        setInputs(prev => ({ ...prev, required: !value }))
    }

    const createPayload = () => {
        setPayload([])
        for (let i = 0; i < attributes.length; i++) {
            setPayload(prev => [
                ...prev,
                {
                    name: attributes[i].name,
                    field_type: attributes[i].field_type.toLowerCase(),
                    units_of_measurement: attributes[
                        i
                    ].units_of_measurement.map(each => each.value),
                    options: attributes[i].options.map(each => each.value),
                    required: attributes[i].required,
                },
            ])
        }
        setTriggerSubmit(true)
    }

    useEffect(() => {
        if (payload.length === attributes.length && triggerSubmit) {
            addAttrRefetch()
        }
    }, [addAttrRefetch, attributes.length, payload.length, triggerSubmit])

    const emptyField = () => {
        setInputs({
            name: '',
            field_type: '',
            units_of_measurement: [],
            required: false,
            options: [],
        })
    }

    const handlePrevious = () => {
        const lastItem = attributes[attributes.length - 1]
        setAttributes(prev => prev.slice(0, -1))
        setInputs(lastItem)
        setFormMode(!formMode)
    }

    const handleCancel = () => {
        setFormMode(true)
        emptyField()
        setAttributes([])
        onCancel()
    }

    const handleSave = e => {
        e.preventDefault()
        if (formMode) {
            attributeValidationSchema
                .validate(inputs, { abortEarly: false })
                .then(() => {
                    const data = [...attributes, { ...inputs, id: uuidv4() }]
                    setAttributes(data)
                    setAttributesEdit(data)
                    setFormMode(!formMode)
                    setErrors({})
                    setInputs({
                        name: '',
                        field_type: '',
                        units_of_measurement: [],
                        required: false,
                        options: [],
                        id: '',
                    })
                    setEditIdx(null)
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = {
                            ...errList,
                            [e.path]: e.message,
                        }
                    })
                    setErrors(errList)
                })
        } else {
            setFormMode(!formMode)
            emptyField()
        }
    }

    const handleDelete = idx => {
        const filterList = attributes.filter(atr => atr.id !== idx)
        setAttributes(filterList)
        setAttributesEdit(filterList)
        if (filterList.length === 0) {
            setFormMode(true)
            setInputs({
                name: '',
                field_type: '',
                units_of_measurement: [],
                required: false,
                options: [],
            })
        }
    }

    const handleEdit = idx => {
        setFormMode(true)
        setEditIdx(idx)
        const filterInput = attributes.find(atr => atr.id === idx)
        const filter = attributesEdit.filter(atr => atr.id !== idx)
        setAttributes(filter)
        setInputs(filterInput)
    }

    const onEditAction = () => {
        const newAtr = attributesEdit.map(attr => {
            if (attr.id === editIdx) {
                attr.name = inputs.name
                attr.field_type = inputs.field_type
                attr.url = inputs.units_of_measurement
                attr.required = inputs.required
                attr.options = inputs.options
            }
            return attr
        })
        setAttributes(newAtr)
        setAttributesEdit(newAtr)
        setFormMode(!formMode)
        setEditIdx(null)
    }

    return (
        <>
            <SecondaryModal
                showModal={showModal}
                modalWidth="624px"
                modalHeight="100%"
                onCancel={() => handleCancel()}
                content={
                    <>
                        <div className={ModalTitleWrapper}>
                            <FormTitleBar
                                title={'Add Attribute'}
                                subtitle={'Enter the details of the attribute'}
                                titleFontSize={'20px'}
                                alignLeft={isMobile}
                                marginBottom={0}
                            />
                        </div>

                        {modalError && (
                            <Toast
                                messageType="error"
                                message={modalErrorMessage}
                            />
                        )}
                        <div className={ModalContent}>
                            {attributes?.map((attribute, i) => (
                                <Fragment key={i}>
                                    <AttributeCard
                                        handleDelete={handleDelete}
                                        handleEdit={handleEdit}
                                        idx={i}
                                        attribute={attribute}
                                    />
                                </Fragment>
                            ))}
                            {formMode && (
                                <>
                                    <InputFields
                                        title="Attribute Name"
                                        inputWidth="100%"
                                        name="name"
                                        type="text"
                                        value={inputs?.name}
                                        handleChange={handleChange}
                                        errorMessage={errors?.name}
                                    />
                                    <SelectField
                                        name="field_type"
                                        selectWidth="100%"
                                        withCheckBox={true}
                                        initialOption={'Attribute Type'}
                                        floatingLabel={
                                            inputs?.field_type !== '' &&
                                            'Attribute Type'
                                        }
                                        value={inputs?.field_type}
                                        values={attributeTypeOptions}
                                        handleChange={handleSelectChange}
                                        selectedValue={inputs?.field_type}
                                        currentSelected={inputs?.field_type}
                                        type="approvedModal"
                                        errorMessage={errors?.field_type}
                                    />
                                    <div className={CheckBoxWrapper}>
                                        <Checkbox
                                            isChecked={inputs?.required}
                                            handleChecked={handleCheck}
                                        >
                                            <p className={CheckBoxText}>
                                                This attribute is required
                                            </p>
                                        </Checkbox>
                                    </div>
                                    <div style={{ marginBottom: 20 }}>
                                        <MultipleSelectField
                                            title="Units of Measurement"
                                            selectWidth="100%"
                                            name="units_of_measurement"
                                            values={unitOfMeasurement}
                                            value={inputs?.units_of_measurement?.map(
                                                item => item.value,
                                            )}
                                            inputValue={inputs?.units_of_measurement?.map(
                                                item => item.value,
                                            )}
                                            dropDownWidth="150px"
                                            dropdownMatchSelectWidth={false}
                                            handleMultipleSelectChange={value => {
                                                const values = value?.map(
                                                    item => ({
                                                        value: item,
                                                        label: item,
                                                    }),
                                                )
                                                inputs.units_of_measurement =
                                                    values
                                                setInputs(prev => ({
                                                    ...prev,
                                                    units_of_measurement:
                                                        values,
                                                }))
                                            }}
                                            errorMessage={
                                                errors?.units_of_measurement
                                            }
                                        />
                                    </div>
                                    <div style={{ marginBottom: 20 }}>
                                        <MultipleSelectField
                                            title="Allowed Options"
                                            selectWidth="100%"
                                            name="options"
                                            value={inputs?.options?.map(
                                                item => item.value,
                                            )}
                                            inputValue={inputs?.options?.map(
                                                item => item.value,
                                            )}
                                            values={allowedOptions}
                                            dropDownWidth="150px"
                                            dropdownMatchSelectWidth={false}
                                            handleMultipleSelectChange={value => {
                                                const values = value?.map(
                                                    item => ({
                                                        value: item,
                                                        label: item,
                                                    }),
                                                )
                                                inputs.options = values
                                                setInputs(prev => ({
                                                    ...prev,
                                                    options: values,
                                                }))
                                            }}
                                            errorMessage={errors?.options}
                                        />
                                    </div>
                                </>
                            )}

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    btnWidth="123px"
                                    btnHeight="44px"
                                    fontSize="14px"
                                    btnBgColor="var(--purple-light)"
                                    btnTextColor="#004AAD"
                                    btnTextColorOutline="var(--blue)"
                                    btnOutlineColor="var(--purple-light)"
                                    btnBgColorOutline="#E2EEFF"
                                    type="outline"
                                    handleClick={handleSave}
                                >
                                    {formMode ? (
                                        'Save Attribute'
                                    ) : (
                                        <>
                                            <PlusIcon
                                                style={{ marginLeft: 5 }}
                                            />{' '}
                                            Add New
                                        </>
                                    )}
                                </Button>
                            </div>
                        </div>
                        <div className={`${ModalBtn} ${MoreActions}`}>
                            <Button
                                btnWidth="160px"
                                btnHeight="56px"
                                fontSize="14px"
                                btnBgColor="var(--purple-light)"
                                btnTextColor="#004AAD"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                handleClick={() => {
                                    if (formMode) {
                                        handleCancel()
                                    } else {
                                        handlePrevious()
                                    }
                                }}
                            >
                                {formMode ? cancelText: 'Previous'}
                            </Button>
                            <Button
                                btnBgColor="#004AAD"
                                btnTextColor="#FFFFFF"
                                btnWidth="160px"
                                btnHeight="56px"
                                fontSize="14px"
                                handleClick={
                                    editIdx ? onEditAction : createPayload
                                }
                                disabled={formMode}
                            >
                                {addAttrLoading ? <InlineLoader /> : `Save`}
                            </Button>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default AddAttribute
