import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { AppButton } from '../../../../components/Button'
import { format, parseISO } from 'date-fns'

import './admincreditlistings.scss'
import { permissionsControl } from 'src/utils/permissionsControl'

const CreditListingTable = ({ tableData, tabId }) => {
    let history = useHistory()
    const paymentModel = {
        SUBSCRIPTION: 'Subscription',
        LEASE_TO_OWN: 'Lease to Own',
    }
    const previousPath = ['all-variables', 'lease-to-own', 'subscription']

    const redirectTo = pageUrl => {
        history.push({
            pathname: pageUrl,
            state: {
                previousPath: `/admin/credit?tab=${previousPath[tabId - 1]}`,
            },
        })
    }

    const canViewCreditVariableDetails = permissionsControl([
        'can_view_credit_variable_details',
    ])

    return (
        <>
            <div className="ACLLargeTable">
                {/*    table head*/}
                <div className="ACLTableHead">
                    <p>Provider/Cluster</p>
                    {tabId === 1 && <p>Payment Model</p>}
                    <p>Start Decli. Bal. Rate</p>
                    {tabId !== 1 && <p>Min. Upfront Deposit</p>}
                    <p>Last Updated Time</p>
                    <p>Action</p>
                </div>

                {/*table row*/}

                <div className="ACLTableWrapper">
                    {tableData.map((data, i) => (
                        <div className="ACLTableRow" key={i}>
                            <p>
                                {data?.cluster_info?.providers?.length > 0
                                    ? `DEP Cluster: ${data?.cluster_info?.code}`
                                    : (data?.cluster_info?.providers?.length ==
                                          0 ||
                                          data?.cluster_info?.providers ===
                                              undefined) &&
                                      data?.cluster_info?.packages
                                    ? `DTC Cluster: ${data?.cluster_info?.code}`
                                    : data?.provider?.display_name}
                            </p>
                            {tabId === 1 && (
                                <p>{paymentModel[data?.payment_model]}</p>
                            )}
                            <p>
                                {parseFloat(
                                    data?.lowest_tenure_annual_interest,
                                )}
                                %
                            </p>
                            {tabId !== 1 && (
                                <p>{parseFloat(data?.upfront_deposit)}%</p>
                            )}
                            <p>
                                {format(
                                    parseISO(data.updated_at),
                                    'dd MMM yyyy, HH:mm',
                                )}
                            </p>
                            <AppButton
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                btnWidth="70px"
                                btnHeight="37px"
                                fontSize="12px"
                                fontFamily="SF-Pro-Display-Medium"
                                handleClick={() =>
                                    redirectTo(`/admin/credit/view/${data?.id}`)
                                }
                                disabled={!canViewCreditVariableDetails}
                                toolTipText={
                                    !canViewCreditVariableDetails
                                        ? 'You are not authorised to perform this action'
                                        : null
                                }
                                toolTipPopupWidth={164}
                                arrowPositionCenter
                                toolTipMarginLeft={-52}
                            >
                                View
                            </AppButton>
                        </div>
                    ))}
                </div>
            </div>

            {/*    mobile table*/}
            {tableData?.map((data, i) => (
                <div className="ACLMobileTable" key={i}>
                    <div className="ACLMobileTableSection">
                        <div>
                            <h4 className="ACLMobileTableHeader">
                                PROVIDER/CLUSTER
                            </h4>
                            <p
                                className="ACLMobileText Name"
                                style={{
                                    fontSize:
                                        data?.cluster_info?.name && '12px',
                                }}
                            >
                                {data?.cluster_info?.name
                                    ? `CLUSTER: ${data?.cluster_info?.code}`
                                    : data?.provider?.display_name}
                            </p>
                        </div>
                        <div>
                            <h4 className="ACLMobileTableHeader">
                                PAYMENT MODEL
                            </h4>
                            <p className="ACLMobileText">
                                {paymentModel[data?.payment_model]}
                            </p>
                        </div>
                    </div>
                    <div className="ACLMobileTableSection">
                        <AppButton
                            btnBgColor="#E2EEFF"
                            btnTextColor="#004AAD"
                            btnWidth="70px"
                            btnHeight="37px"
                            fontSize="12px"
                            fontFamily="SF-Pro-Display-Medium"
                            handleClick={() =>
                                redirectTo(`/admin/credit/view/${data?.id}`)
                            }
                            disabled={!canViewCreditVariableDetails}
                            toolTipText={
                                !canViewCreditVariableDetails
                                    ? 'You are not authorised to perform this action'
                                    : null
                            }
                            toolTipPopupWidth={164}
                            arrowPositionCenter
                        >
                            View
                        </AppButton>
                        <div>
                            <h4 className="ACLMobileTableHeader">
                                LOWEST RATE
                            </h4>
                            <p className="ACLMobileText Right">
                                {parseInt(data?.lowest_tenure_annual_interest)}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

CreditListingTable.propTypes = {
    tableData: PropTypes.array,
    tabId: PropTypes.string,
}

export default CreditListingTable
