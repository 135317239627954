import { useState } from 'react'
import { useQuery } from 'react-query'
import { getProfilesGroupsApi } from 'src/api/admin/profiles/groups'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const useGetProfilesGroups = ({ filterState, onSuccess }) => {
    const [profilesGroups, setProfilesGroups] = useState([])
    const [totalPages, setTotalPages] = useState(null)

    const { errorAlert } = useCustomToast()
    const { isFetching, refetch: profilesGroupsRefetch } = useQuery({
        queryKey: [
            'fetch-profiles-groups',
            filterState?.currentPage,
            filterState?.searchText,
            filterState?.customerType,
            filterState?.paginate,
        ],
        queryFn: () =>
            getProfilesGroupsApi(
                filterState?.currentPage,
                filterState?.searchText,
                filterState?.customerType,
                filterState?.paginate,
            ),
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        onSuccess: res => {
            const data = res?.data?.data
            setProfilesGroups(data)
            setTotalPages(res?.data?.total_pages)
            if (onSuccess) {
                onSuccess(data)
            }
        },
        onError: error => {
            errorAlert(
                errorHandler(error.response?.data) ||
                    `Couldn't fetch consumer profile groups. please try again`,
            )
        },
    })

    return {
        profilesGroups,
        profilesGroupsFetching: isFetching,
        totalPages,
        profilesGroupsRefetch,
    }
}

export default useGetProfilesGroups
