//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useHistory } from 'react-router-dom'
export const providerTypeContent = () => {
    const history = useHistory()

    let data = [
        {
            id: '1',
            name: 'Associate Installer  (ASI)',
            body: "Do you have the electrical skills to be a world-class solar sales and installation associate but don't have the capital to build your business? Create a free Associate Installer account on SunFi and start earning today.",
            buttonText: 'Sign Up as an Installer',
            redirectTo: () => {
                history.push({
                    pathname: '/signup',
                    state: {
                        partnerId: 1,
                    },
                })
            },
        },
        {
            id: '2',
            name: 'Distributor that Installs (DISI)',
            body: 'Need financial support and the tools to grow your small or medium solar sales and installation business? Open and set up a Distributor Installer account on SunFi and watch your business grow.',
            buttonText: 'Sign Up as a Distributor',
            redirectTo: () => {
                history.push({
                    pathname: '/signup',
                    state: {
                        partnerId: 2,
                    },
                })
            },
        },
        {
            id: '3',
            name: 'Manufacturer that Installs (OEMI)',
            body: 'Need financial support and the tools to support your clean energy equipment manufacturing and installation business? Set your business up for success by opening a free OEMI account on SunFi today.',
            buttonText: 'Sign Up as a Manufacturer',
            redirectTo: () => {
                history.push({
                    pathname: '/signup',
                    state: {
                        partnerId: 3,
                    },
                })
            },
        },
    ]
    return data
}
