import DirectorInfo from './components/Business/DirectorInfo'
import IdentityInformationSection from './components/Residential/IdentityInformationSection'
import BasicInformationSection from './components/Shared/BasicInformationSection'
import PersonalInfo from './components/Shared/PersonalInfo'
import FinancialInformationSection from './components/Shared/FinancialInformation'

export const residentialKYC = [
    {
        title: 'Basic Information',
        component: <BasicInformationSection />,
        info: 'This information helps us know you more. Please provide accurate information below.',
    },
    {
        title: 'Identity Information',
        component: <IdentityInformationSection />,
        info: 'This will help us to verify your identity and protect your account against unauthorised access.',
    },
    {
        title: 'Financial Information',
        component: <FinancialInformationSection />,
        info: 'Kindly upload a minimum of 6-months bank statement or retrieve your bank statement faster by clicking on "connect to Mono".',
    },
]

export const businessKYC = [
    {
        title: 'Business Information',
        component: <BasicInformationSection />,
        info: 'This information helps us know more about your business. Please provide accurate information below.',
    },
    {
        title: "Director's Information",
        component: <DirectorInfo />,
        info: 'This requires that you provide the contact information of the director of the business.',
    },
    {
        title: 'Contact Information',
        component: <PersonalInfo />,
        info: 'Provide any other contact information different from the one previously provided.',
    },
    {
        title: 'Financial Information',
        component: <FinancialInformationSection />,
        info: 'Kindly upload a minimum of 12-months bank statement or retrieve your bank statement faster by clicking on "connect to Mono".',
    },
]

export const useTypeMapping = {
    RESIDENTIAL: 'residential',
    BUSINESS: 'business',
}

export const actionModalValues = (action, useType, hasNotifiedProvider) => {
    switch (action) {
        case 'progress-saved':
            return {
                actionModalTitle: 'Redirecting to home page',
                actionModalHeaderText: 'Progress Saved, check your mail',
                actionModalSubtitle:
                    "Progress saved! We've emailed you instructions to resume when you have the required information.",
                actionModalActionType: 'countdown-timer',
                actionModalPrimaryBtnText: 'Proceed to Log Out',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Continue Application',
            }
        case 'edit-info':
            return {
                actionModalTitle: 'Editing your info',
                actionModalHeaderText:
                    'This action might affect your recommendations',
                actionModalSubtitle:
                    'Please, be aware that editing your previously added info might affect your recommendations.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Edit my info',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'change-use-type':
            return {
                actionModalTitle: `Switch to ${
                    useType === 'business' ? 'residential' : 'business'
                } use`,
                actionModalHeaderText: `Details required for ${
                    useType === 'business' ? 'residential' : 'business'
                } use are different`,
                actionModalSubtitle:
                    useType === 'business'
                        ? 'Please, be aware that switching to a residential use will require you to submit documents such as ID numbers and 6-12 months of up-to-date, verified bank statements'
                        : 'Please, be aware that switching to a business use will require you to submit documents such as CAC documents and 12-24 months of up-to-date, verified bank statements',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'submit-kyc':
            return {
                actionModalTitle: `Submit Your KYC`,
                actionModalHeaderText: `Submit Your KYC`,
                actionModalSubtitle: 'Submit Your KYC',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Submit',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'save-and-continue-later':
            return {
                actionModalTitle: `Save your progress`,
                actionModalHeaderText: `Save your progress? Please confirm`,
                actionModalSubtitle:
                    'You can save your progress and come back to continue anytime. We’ll also send an email on how you can continue',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'progress-saved-for-dep-consumer':
            return {
                actionModalTitle: `Redirecting to home page`,
                actionModalHeaderText: `Thats fine! We have saved your progress`,
                actionModalSubtitle:
                    "Progress saved! We've emailed you instructions to resume when you have the required information.",
                actionModalActionType: 'mail-sent',
                actionModalPrimaryBtnText: 'Great, Thank you',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'reject-new-package':
            return {
                actionModalTitle: `Reject this package`,
                actionModalHeaderText: `Are you sure you want to reject the new package?`,
                actionModalSubtitle:
                    'Please be aware that continuing would mean you are not do not want the the package previously chosen for you by your provider and you would like another.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'initiate-dispute':
            return {
                actionModalTitle: 'Reason for disputing package',
                actionModalHeaderText:
                    'Your disputed information won’t change immediately',
                actionModalSubtitle:
                    'Please be aware that you will not be able to change the disputed information until your provider is done with the re-estimation. You can also choose to reach out to your provider while this is pending.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Continue',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'package-change-request-in-progress':
            return {
                actionModalTitle: 'Change Package',
                actionModalHeaderText: 'Package request already in progress',
                actionModalSubtitle: `Please be aware that the package change request is already in progress and your Provider is already on it. ${
                    !hasNotifiedProvider
                        ? 'However, your Provider can be notified to speed up the process so you can get your application submitted as soon as possible.'
                        : ''
                }`,
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Got it',
                actionModalFooterLeftContent: hasNotifiedProvider
                    ? 'chat'
                    : 'button',
                actionModalSecondaryBtnText: hasNotifiedProvider
                    ? ''
                    : 'Nudge Provider',
            }
        case 'provider-nudged-successfully':
            return {
                actionModalTitle: 'Provider Successfully Notified',
                actionModalHeaderText: 'Your Provider has been notified',
                actionModalSubtitle:
                    'An email has been sent out to your Provider to notify them about the pending request.',
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Great, Thank you',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'new-package-rejected-successfully':
            return {
                actionModalTitle: 'Package Successfully Rejected',
                actionModalHeaderText: 'Your Provider has been notified',
                actionModalSubtitle:
                    'Please be aware that continuing would mean you are not do not want the the package previously chosen for you by your provider and you would like another.',

                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Great, Thank you',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'package-change-requested-successfully':
            return {
                actionModalTitle: 'Request Successfully Sent',
                actionModalHeaderText:
                    'Your disputed information won’t change immediately',
                actionModalSubtitle:
                    'Please be aware that you will not be able to change the disputed information until your provider is done with the re-estimation. You can also choose to reach out to your provider while this is pending.',
                actionModalActionType: 'success',
                actionModalPrimaryBtnText: 'Great, Thank you',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'confirm-package-change-request':
            return {
                actionModalTitle: 'Change Package',
                actionModalHeaderText:
                    'You are about to request for a package change?',
                actionModalSubtitle:
                    'Please confirm if you would like to proceed with the request for a new package.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'data-fetch-failed':
            return {
                actionModalTitle: 'KYC data not retrieved',
                actionModalHeaderText:
                    'Your KYC data retrieval is taking longer',
                actionModalSubtitle:
                    'Retrieving your KYC data is taking longer than expected, possibly due to a slow internet connection or an issue on our end. Please try again with a stronger connection.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Try again',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'bank-statement-in-review':
            return {
                actionModalTitle: 'Save to continue later',
                actionModalHeaderText:
                    'Your bank statement is currently being analyzed',
                actionModalSubtitle:
                    'Thank you for completing your KYC, please note that we will get back to you once your bank statement has been analysed. Please Proceed to save.',
                actionModalActionType: 'document',
                actionModalPrimaryBtnText: 'Save and continue',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
    }
}

export const employmentTypes = [
    'Full Time',
    'Part Time',
    'Contract',
    'Self Employed',
    'Retired',
]

export const businessTypes = [
    'Sole Proprietorship',
    'Limited Liability Company',
    'Non-governmental Organisation',
    'Self-Employed',
]

export const countries = ['Nigeria']
