import PropTypes from 'prop-types'
import ActionModal from 'src/components/ActionModalV2'
import useRemoveFileModal from './hook'

const RemoveFileModal = ({
    showModal,
    fileToBeRemoved,
    closeRemoveFileModal,
    closeRemoveFileModalAfterSuccess,
}) => {
    const { toastError, errorMessage, mutate, isLoading } = useRemoveFileModal({
        closeRemoveFileModalAfterSuccess: closeRemoveFileModalAfterSuccess,
        fileToBeRemoved: fileToBeRemoved,
    })
    return (
        <ActionModal
            v2
            actionModalOpen={showModal}
            title={'Confirm File Deletion'}
            actionType={'warning'}
            headerText={`Are you sure you want to delete your ${fileToBeRemoved?.title}?`}
            subTitle={`This action cannot be undone and the document will be deleted permanently. Click 'Confirm' to proceed.`}
            primaryBtnText={'Confirm'}
            handlePrimaryBtnClick={() => mutate()}
            primaryBtnLoading={isLoading}
            onCancel={() => closeRemoveFileModal()}
            toastError={toastError}
            errorMessage={errorMessage}
        />
    )
}

RemoveFileModal.propTypes = {
    showModal: PropTypes.bool,
    fileToBeRemoved: PropTypes.object,
    closeRemoveFileModal: PropTypes.func,
    closeRemoveFileModalAfterSuccess: PropTypes.func,
}

export default RemoveFileModal
