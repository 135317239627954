import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/ResidenceForm/residenceform.scss'
import Label from '../../../../PersonalInformation/_components/Label'
import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { sharedFormComponentPropTypes } from '../utils'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import { InlineLoader } from 'src/components/Loader'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const ContactInfo = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    initiateContactVerification,
    initiateEmailVerificationLoading,
    initiatePhoneVerificationLoading,
    isCustomerApproved,
}) => {
    const { isMobile } = useMediaQueries()
    return (
        <>
            <Label
                title={'Your phone number and email'}
                subTitle={
                    'Provide us with your phone number and confirm your email address.'
                }
            />
            <div className="VerifyInputWrapper OSIFormPhoneWrapper">
                <SelectCountryCode
                    name="phone"
                    title="Phone Number"
                    inputType={'tel'}
                    inputValue={inputs.phone}
                    handleChange={handleChange}
                    onBlur={handleOnBlur}
                    disabled={
                        inputs?.phoneVerified ||
                        isAdminUser ||
                        isCustomerApproved
                    }
                    selectWidth="114px"
                    inputStyle={{ width: '100%' }}
                    errorMessage={errors?.phone || errors?.phoneVerified}
                    marginBottom={16}
                    verified={inputs?.phoneVerified}
                />
                {!isAdminUser && !inputs?.phoneVerified && (
                    <div className="VerifyCTA">
                        <button
                            style={{
                                width: initiatePhoneVerificationLoading
                                    ? '84px'
                                    : '',
                            }}
                            onClick={() => initiateContactVerification('phone')}
                            data-testid="verify-phone-button"
                        >
                            {initiatePhoneVerificationLoading ? (
                                <InlineLoader color="primary" />
                            ) : isMobile ? (
                                'Verify'
                            ) : (
                                'Verify phone number'
                            )}
                        </button>
                    </div>
                )}
            </div>
            <div className="VerifyInputWrapper">
                <InputFields
                    name="email"
                    title="Your email address"
                    value={inputs.email}
                    handleChange={handleChange}
                    onBlur={handleOnBlur}
                    disabled={
                        inputs?.emailVerified ||
                        isAdminUser ||
                        isCustomerApproved
                    }
                    marginBottom={inputs?.emailVerified ? '16px' : '8px'}
                    errorMessage={errors?.email || errors?.emailVerified}
                    inputWidth={'100%'}
                    verified={inputs?.emailVerified}
                />
                {!isAdminUser && !inputs?.emailVerified && (
                    <div className="VerifyCTA">
                        <button
                            style={{ width: '84px' }}
                            onClick={() => initiateContactVerification('email')}
                            data-testid="verify-email-button"
                        >
                            {initiateEmailVerificationLoading ? (
                                <InlineLoader color="primary" />
                            ) : isMobile ? (
                                'Verify'
                            ) : (
                                'Verify email'
                            )}
                        </button>
                    </div>
                )}
            </div>

            <VerticalSpacer top={12} />
        </>
    )
}

ContactInfo.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default ContactInfo
