import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { AppButton } from 'src/components/Button'
import { titleCase } from 'src/utils/formatting'
import styles from './customerlistings.module.scss'

const CustomersListingTable = ({ tableData }) => {
    const {
        MobileCLWrapper,
        MobileTopSection,
        CLMobileTableHeader,
        CLMobileText,
        CLBtnWrapper,
    } = styles
    const history = useHistory()

    return (
        <>
            <div className="ACLLargeTable">
                {/*    table head*/}
                <div
                    className="ACLTableHead"
                    style={{
                        gridTemplateColumns: '24% 13% 30% 21% 12%',
                    }}
                >
                    <p>First/Last Name</p>
                    <p>Customer Type</p>
                    <p>Email Address</p>
                    <p>Phone Number</p>
                    <p>Actions</p>
                </div>

                {/*table row*/}

                <div className="ACLTableWrapper">
                    {tableData.map((data, i) => (
                        <div
                            className="ACLTableRow"
                            key={i}
                            style={{
                                gridTemplateColumns: '24% 13% 30% 21% 11%',
                            }}
                        >
                            <p>{data?.user?.display_name}</p>

                            <p>
                                {titleCase(
                                    data?.customer_type === 'INDIVIDUAL'
                                        ? 'Residential'
                                        : data?.customer_type,
                                )}
                            </p>

                            <p>{data?.user?.email}</p>

                            <p>{data?.user?.phone_number}</p>

                            <AppButton
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                btnWidth="70px"
                                btnHeight="37px"
                                fontSize="12px"
                                fontFamily="SF-Pro-Display-Medium"
                                handleClick={() =>
                                    history.push({
                                        pathname:
                                            '/app/payment-plans/create/one',
                                        state: {
                                            newCustomerType:
                                                data?.customer_type,
                                            customerData: {
                                                customerName:
                                                    data?.user?.display_name,
                                                firstName:
                                                    data?.user?.first_name,
                                                lastName: data?.user?.last_name,
                                                phoneNumber:
                                                    data?.user?.phone_number,
                                                email: data?.user?.email,
                                            },
                                        },
                                    })
                                }
                            >
                                Select
                            </AppButton>
                        </div>
                    ))}
                </div>
            </div>

            {/*    mobile table*/}
            {tableData?.map((data, i) => (
                <div className={MobileCLWrapper} key={i}>
                    <div className={MobileTopSection}>
                        <div>
                            <div style={{ marginBottom: '40px' }}>
                                <h4 className={CLMobileTableHeader}>
                                    First/Last Name
                                </h4>
                                <p
                                    className={CLMobileText}
                                    style={{
                                        fontFamily: 'SF-Pro-Display-Semibold',
                                    }}
                                >
                                    {data?.user?.display_name}
                                </p>
                            </div>

                            <div>
                                <h4 className={CLMobileTableHeader}>
                                    Email address
                                </h4>
                                <p className={CLMobileText}>
                                    {data?.user?.email}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div style={{ marginBottom: '40px' }}>
                                <h4 className={CLMobileTableHeader}>
                                    Customer type
                                </h4>
                                <p className={CLMobileText}>
                                    {titleCase(
                                        data?.customer_type === 'INDIVIDUAL'
                                            ? 'Residential'
                                            : data?.customer_type,
                                    )}
                                </p>
                            </div>
                            <div>
                                <h4 className={CLMobileTableHeader}>
                                    Phone Number
                                </h4>
                                <p className={CLMobileText}>
                                    {data?.user?.phone_number}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={CLBtnWrapper}>
                        <AppButton
                            btnBgColor="#E2EEFF"
                            btnTextColor="#004AAD"
                            btnWidth="82px"
                            btnHeight="36px"
                            fontSize="12px"
                            fontFamily="SF-Pro-Display-Medium"
                            handleClick={() =>
                                history.push({
                                    pathname: '/app/payment-plans/create/one',
                                    state: {
                                        newCustomerType: data?.customer_type,
                                        customerData: {
                                            customerName:
                                                data?.user?.display_name,
                                            firstName: data?.user?.first_name,
                                            lastName: data?.user?.last_name,
                                            phoneNumber:
                                                data?.user?.phone_number,
                                            email: data?.user?.email,
                                        },
                                    },
                                })
                            }
                        >
                            Select
                        </AppButton>
                    </div>
                </div>
            ))}
        </>
    )
}

CustomersListingTable.propTypes = {
    tableData: PropTypes.array,
}

export default CustomersListingTable
