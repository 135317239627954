import Header from './components/Header'
import styles from '../_components/Layout/layout.module.scss'
import osStyles from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/OutrightSaleInformation/styles.module.scss'
import Description from '../_components/Description'
import AccountSetupFooter from 'src/components/ConsumerFooter/AccountSetupFooter'
import SeoComponent from 'src/components/Seo'
import { actionModalValues, descriptionStep } from './utils'
import DynamicForm from './components/Form'
import useDynamicInformation from './hooks/useDynamicInformation'
import FormSkeleton from '../_components/FormSkeleton'
import VerifyContactModal from '../OutrightSaleInformation/components/VerifyContactModal'
import ActionModal from 'src/components/ActionModalV2'
import { useLocation } from 'react-router-dom'
import VerifyCacModal from './components/VerifyCacModal'
import SelfieImageModal from './components/SelfieImageModal'
import VerifyIdModal from './components/VerifyIdModal'
import VerifyBVNModal from '../Verification/components/VerifyBVNModal'

const DynamicInformation = () => {
    const { LayoutWrapper, MainWrapper } = styles
    const location = useLocation()
    const {
        kycModule,
        pageLoading,
        inputs,
        errors,
        handleChange,
        handleSelectChange,
        handleOnBlur,
        handleSelectOnBlur,
        handleSameAddressOptionChange,
        showRemoveFileModal,
        fileToBeRemoved,
        closeRemoveFileModal,
        closeRemoveFileModalAfterSuccess,
        showCalendar,
        setShowCalendar,
        setShowUploadModal,
        handleRemoveFile,
        employmentContract,
        setEmploymentContract,
        showUploadModal,
        handleCloseUploadModal,
        successEmploymentContractUpload,
        setSuccessEmploymentContractUpload,
        actionModalToast,
        showVerifyContactModal,
        initiateContactVerification,
        closeVerifyContactModal,
        contactTypeToVerify,
        verifyContactsModalInputs,
        otp,
        setOtp,
        otpError,
        handleResendOtp,
        verifyContactToast,
        initiateVerificationResent,
        initiateVerificationSetResent,
        handleOtpSubmit,
        initiateEmailVerificationLoading,
        initiatePhoneVerificationLoading,
        sseValidateAuthOtpLoading,
        action,
        actionModalOpen,
        closeActionModal,
        goToSSEHome,
        actionModalHandler,
        actionModalSecondaryHandler,
        handleSaveInfo,
        handleSubmitInfo,
        saveLoading,
        submitLoading,
        handleBusinessRegisteredChange,
        showCacVerifyModal,
        toggleShowCacVerifyModal,
        cacHelpPayload,
        cacVerificationInProgress,
        setCacVerificationInProgress,
        isCustomerApproved,
        showSelfieImageModal,
        toggleSelfieImageModal,
        showIdVerifyModal,
        openIdVerifyModal,
        closeIdVerifyModal,
        idVerificationInProgress,
        setIdVerificationInProgress,
        showIdOverrideModal,
        toggleIdOverrideModal,
        kycStatus,
        handleSubmitIdCardInfo,
        submitIdCardLoading,
        idVerificationFailedModal,
        showBVNModal,
        openBVNModal,
        closeBVNModal,
        offer,
        bvnVerifiedSuccessfully,
        handleBvnVerifyLater,
        bvnVerificationInProgress,
        setBvnVerificationInProgress,
        kycData,
        showVideoModal,
        setShowVideoModal,
        showVideo,
        setShowVideo,
        removeErrors,
    } = useDynamicInformation(location?.state?.estimationId)

    const {
        actionModalTitle,
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalPrimaryBtnText,
        actionModalFooterLeftContent,
        actionModalSecondaryBtnText,
    } = actionModalValues(action)

    return (
        <>
            {/* modals */}
            <ActionModal
                actionModalOpen={actionModalOpen}
                title={actionModalTitle}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                actionType={actionModalActionType}
                primaryBtnText={actionModalPrimaryBtnText}
                handlePrimaryBtnClick={actionModalHandler}
                closable
                onCancel={() => closeActionModal()}
                noBackLink
                countdownTimerSeconds={10}
                onTimeEnd={
                    action === 'progress-saved' ? () => goToSSEHome() : () => {}
                }
                footerLeftContent={actionModalFooterLeftContent}
                secondaryBtnText={actionModalSecondaryBtnText}
                handleSecondaryBtnClick={actionModalSecondaryHandler}
                primaryBtnLoading={saveLoading}
                toastError={actionModalToast?.showToast}
                errorMessage={actionModalToast?.toastMessage}
                errorMessageType={actionModalToast?.messageType}
            />

            <VerifyContactModal
                showModal={showVerifyContactModal}
                onCancel={closeVerifyContactModal}
                contact={contactTypeToVerify}
                inputs={verifyContactsModalInputs}
                otp={otp}
                setOtp={setOtp}
                otpError={otpError}
                handleResendOtp={handleResendOtp}
                verifyContactToast={verifyContactToast}
                resent={initiateVerificationResent}
                setResent={initiateVerificationSetResent}
                handleOtpSubmit={handleOtpSubmit}
                loading={sseValidateAuthOtpLoading}
            />

            <VerifyCacModal
                showModal={showCacVerifyModal}
                onCancel={toggleShowCacVerifyModal}
                cacHelpPayload={cacHelpPayload}
                setVerificationInProgress={setCacVerificationInProgress}
                inputs={inputs}
                isCustomerApproved={isCustomerApproved}
            />

            <SelfieImageModal
                showModal={showSelfieImageModal}
                closeModal={toggleSelfieImageModal}
            />

            <VerifyIdModal
                showModal={showIdVerifyModal}
                inputs={inputs}
                closeIdVerifyModal={closeIdVerifyModal}
                idVerificationInProgress={idVerificationInProgress}
                setIdVerificationInProgress={setIdVerificationInProgress}
                handleSubmitIdCardInfo={handleSubmitIdCardInfo}
                submitIdCardLoading={submitIdCardLoading}
                actionModalToast={actionModalToast}
                idVerificationFailedModal={idVerificationFailedModal}
            />

            <VerifyBVNModal
                showModal={showBVNModal}
                closeBVNModal={closeBVNModal}
                storedBvn={inputs?.bvn}
                bvnVerifiedSuccessfully={bvnVerifiedSuccessfully}
                userType={offer?.userType}
                storedFirstName={inputs?.firstName}
                storedLastName={inputs?.lastName}
                storedEmail={inputs?.email}
                storedPhoneNumber={inputs?.phone}
                handleBvnVerifyLater={handleBvnVerifyLater}
                setVerificationInProgress={setBvnVerificationInProgress}
            />

            {/* page */}
            <SeoComponent
                title={`KYC - ${
                    kycModule?.title ?? ''
                } | SunFi | Simplifying and Scaling Clean Energy`}
            />

            <div className={LayoutWrapper}>
                <Header
                    handleSaveInfo={handleSaveInfo}
                    handleSubmitInfo={handleSubmitInfo}
                    submitLoading={submitLoading}
                    isCustomerApproved={isCustomerApproved}
                    pageLoading={pageLoading}
                />
                <main className={MainWrapper}>
                    <Description
                        title={`Add your ${kycModule?.title ?? 'information'}`}
                        subTitle={`To create your account and confirm your order, we need you to provide your ${
                            kycModule?.title ?? 'information'
                        }`}
                        step={descriptionStep(kycModule?.key)}
                        durationInMins={10}
                    />
                    <div className={osStyles.rightLayoutWrapper}>
                        <div
                            className={osStyles.rightLayoutContent}
                            style={{ width: pageLoading ? '60%' : '' }}
                        >
                            {pageLoading ? (
                                <FormSkeleton style={{ padding: 20 }} />
                            ) : (
                                <DynamicForm
                                    kycModule={kycModule}
                                    inputs={inputs}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleSelectChange={handleSelectChange}
                                    handleOnBlur={handleOnBlur}
                                    handleSelectOnBlur={handleSelectOnBlur}
                                    handleSameAddressOptionChange={
                                        handleSameAddressOptionChange
                                    }
                                    showRemoveFileModal={showRemoveFileModal}
                                    fileToBeRemoved={fileToBeRemoved}
                                    closeRemoveFileModal={closeRemoveFileModal}
                                    closeRemoveFileModalAfterSuccess={
                                        closeRemoveFileModalAfterSuccess
                                    }
                                    showCalendar={showCalendar}
                                    setShowCalendar={setShowCalendar}
                                    setShowUploadModal={setShowUploadModal}
                                    handleRemoveFile={handleRemoveFile}
                                    employmentContract={employmentContract}
                                    setEmploymentContract={
                                        setEmploymentContract
                                    }
                                    showUploadModal={showUploadModal}
                                    handleCloseUploadModal={
                                        handleCloseUploadModal
                                    }
                                    successEmploymentContractUpload={
                                        successEmploymentContractUpload
                                    }
                                    setSuccessEmploymentContractUpload={
                                        setSuccessEmploymentContractUpload
                                    }
                                    initiateContactVerification={
                                        initiateContactVerification
                                    }
                                    initiateEmailVerificationLoading={
                                        initiateEmailVerificationLoading
                                    }
                                    initiatePhoneVerificationLoading={
                                        initiatePhoneVerificationLoading
                                    }
                                    handleBusinessRegisteredChange={
                                        handleBusinessRegisteredChange
                                    }
                                    toggleShowCacVerifyModal={
                                        toggleShowCacVerifyModal
                                    }
                                    cacVerificationInProgress={
                                        cacVerificationInProgress
                                    }
                                    isCustomerApproved={isCustomerApproved}
                                    toggleSelfieImageModal={
                                        toggleSelfieImageModal
                                    }
                                    showIdOverrideModal={showIdOverrideModal}
                                    toggleIdOverrideModal={
                                        toggleIdOverrideModal
                                    }
                                    kycStatus={kycStatus}
                                    idVerificationInProgress={
                                        idVerificationInProgress
                                    }
                                    openIdVerifyModal={openIdVerifyModal}
                                    openBVNModal={openBVNModal}
                                    bvnVerificationInProgress={
                                        bvnVerificationInProgress
                                    }
                                    kycData={kycData}
                                    showVideoModal={showVideoModal}
                                    setShowVideoModal={setShowVideoModal}
                                    showVideo={showVideo}
                                    setShowVideo={setShowVideo}
                                    removeErrors={removeErrors}
                                    offer={offer}
                                />
                            )}
                        </div>
                    </div>
                </main>
                <AccountSetupFooter />
            </div>
        </>
    )
}

export default DynamicInformation
