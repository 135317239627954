/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Upload, message } from 'antd'

import UploadLogo from 'src/assets/images/upload.svg'
import SuccessUpload from 'src/assets/images/successUpload.svg'
import CheckIcon from 'src/assets/images/circle_check_blue.svg'
import ImageEditor from 'src/pages/Onboarding/Steps/ImageEditor'
import { WebCamCapture } from 'src/pages/Onboarding/Steps/Webcam'

const UploadImage = ({
    step,
    selected,
    webcamRef,
    successUpload,
    setSuccessUpload,
    pictureUrl,
    setPictureUrl,
    uploading,
    width,
    setWidth,
    IdentitySelfieRef,
}) => {
    const [fileValid, setFileValid] = useState(true)

    useEffect(() => {
        const handleSlider = () => {
            if (uploading && width < 100) {
                setWidth(prev => prev + 20)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploading, setWidth])

    const { Dragger } = Upload

    const props = {
        name: 'file',

        onChange({ fileList }) {
            setSuccessUpload(fileList)
            onPreview(fileList[0])
            setFileValid(true)
        },
    }

    const onPreview = async file => {
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        if (fileValid) {
            setPictureUrl(src)
        }
    }

    const beforeUpload = file => {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
        const isLt2M = file.size / 1000 / 1000 < 10

        if (!isJPG) {
            message.error('You can only upload JPG or PNG file!')
            setFileValid(false)
            return false
        } else if (!isLt2M) {
            message.error('Image should be less than 10mb')
            setFileValid(false)
            return false
        } else {
            return true
        }
    }

    return (
        <>
            {uploading ? (
                <div className="UploadLoadingWrapper">
                    <div className="SlideContainer">
                        <p
                            className="Slider"
                            style={{
                                width: `${width}%`,
                            }}
                        ></p>
                    </div>
                    <p>Uploading Image</p>
                </div>
            ) : step === 3 && !uploading ? (
                <ImageEditor
                    page={'sse-identity-information'}
                    pictureUrl={pictureUrl}
                    Ref={IdentitySelfieRef}
                />
            ) : step === 2 && selected === 'selfie' && !uploading ? (
                <>

                <div className="SelfieWrapper">
                    <WebCamCapture
                        mediumWidth="100%"
                        page="sse-identity-information"
                        noMinHeight
                        webCamRef={webcamRef}
                    />
                    
                <div className='SelfieInstructions'>
                        <div>
                            <img src={CheckIcon} />
                            <p> Open your browser’s settings.</p>
                        </div>
                        <div>
                            <img src={CheckIcon} />
                            <p>Find the permissions section and locate “Camera”.</p>
                        </div>
                        <div>
                            <img src={CheckIcon} />
                            <p>Look for this website under blocked sites and allow camera access.</p>
                        </div>
                    </div>
                </div>
                </>
            ) : (
                <div className="DraggerWrapper">
                    <Dragger
                        className="Dragger"
                        fileList={successUpload}
                        maxCount={1}
                        beforeUpload={beforeUpload}
                        showUploadList={true}
                        accept="image/jpeg,image/png"
                        customRequest={() => {}}
                        {...props}
                    >
                        <p className="ant-upload-drag-icon">
                            {successUpload.length > 0 ? (
                                <img
                                    src={SuccessUpload}
                                    className="UploadLogo"
                                    alt="success logo"
                                />
                            ) : (
                                <img
                                    src={UploadLogo}
                                    className="UploadLogo"
                                    alt="upload logo"
                                />
                            )}
                        </p>
                        <p className="UploadText">
                            {successUpload.length === 0 ? (
                                <>
                                    Upload file. Drag and drop or
                                    <u className="Browse">browse</u>
                                </>
                            ) : null}
                        </p>
                    </Dragger>
                </div>
            )}
        </>
    )
}

UploadImage.propTypes = {
    step: PropTypes.number.isRequired,
    selected: PropTypes.string.isRequired,
    webcamRef: PropTypes.any.isRequired,
    IdentitySelfieRef: PropTypes.any.isRequired,
    successUpload: PropTypes.any.isRequired,
    setSuccessUpload: PropTypes.func.isRequired,
    pictureUrl: PropTypes.string.isRequired,
    setPictureUrl: PropTypes.func.isRequired,
    uploading: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    setWidth: PropTypes.func.isRequired,
}

export default UploadImage
