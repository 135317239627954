import { useState } from 'react'
import {
    getSingleAdminDropOffSSEApi,
    getSingleAdminPaymentPlanSSEApi,
} from 'src/api/admin/payment-plans/ssePlan'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

const useGetPlanInfo = (estimationId, recommendationType) => {
    const [paymentPlanData, setPaymentPlanData] = useState({})
    const { errorAlert } = useCustomToast()
    const history = useHistory()

    const {
        refetch: paymentPlanDataRefetch,
        isFetching: isPaymentPlanLoading,
    } = useQuery({
        queryKey: ['single-payment-plan-estimation', estimationId],
        queryFn: () =>
            recommendationType === 'alternative'
                ? getSingleAdminDropOffSSEApi(estimationId)
                : getSingleAdminPaymentPlanSSEApi(estimationId),
        retry: false,
        enabled: estimationId !== (null || undefined),
        refetchOnMount: true,
        onSuccess: data => {
            const paymentPlanData = data?.data?.data
            setPaymentPlanData(paymentPlanData)

            // ensure user is on the right module
            if (
                paymentPlanData?.payment_plan === 'Outright Sale' &&
                !history?.location?.pathname?.includes('outright-sale')
            ) {
                history.push(`/admin/outright-sale/${paymentPlanData?.id}`)
            }
            if (
                (paymentPlanData?.payment_plan === 'Lease to Own' ||
                    paymentPlanData?.payment_plan === 'Subscription') &&
                !history?.location?.pathname?.includes('plans')
            ) {
                history.push(`/admin/plans/${paymentPlanData?.id}`)
            }
        },
        onError: error => {
            errorAlert(
                errorHandler(error.response?.data) ||
                    `Couldn't fetch plan details. please try again`,
            )
        },
    })

    return {
        paymentPlanDataRefetch,
        isPaymentPlanLoading,
        paymentPlanData,
    }
}

export default useGetPlanInfo
