import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
    updateUpfrontDeposit,
    getProviderClusterMinUpfrontDeposit,
} from 'src/api/estimations/estimations-step-one'
import { useLocation, useHistory } from 'react-router-dom'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const useGetMinimumDeposit = clusterCode => {
    const [minimumDeposit, setMinimumDeposit] = useState(0)
    const { errorAlert } = useCustomToast()
    const location = useLocation()
    const history = useHistory()

    const estimationsId = localStorage.getItem('estimationsId')

    const paymentPlanModel = location?.state?.paymentPlanModel

    const { refetch: refetchMinimumDeposit } = useQuery({
        queryKey: ['getProviderClusterMinUpfrontDeposit'],
        queryFn: () => getProviderClusterMinUpfrontDeposit(),
        enabled: true,
        onSuccess: data => {
            let objectData = {}
            data.data.data.forEach(item => {
                if (item.payment_model === 'LEASE_TO_OWN') {
                    objectData['Lease to Own'] = item.upfront_deposit
                } else {
                    objectData['Subscription'] = item.upfront_deposit
                }
            })

            setMinimumDeposit(objectData[paymentPlanModel])
        },
        onError: error => {
            errorAlert(
                errorHandler(error.response?.data) ||
                    `Couldn't get minimum deposit. please try again`,
            )
        },
    })

    const {
        mutate: updateCreateEstimationsStepOne2,
        isLoading: isLoadingCreateEstimationsStepOne2,
    } = useMutation(
        ['UpdateCreateEstimationsStepOne2'],
        upfrontDeposit =>
            updateUpfrontDeposit(estimationsId, {
                upfront_deposit: upfrontDeposit,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                history.push({
                    pathname: '/app/payment-plans/create/two',
                    state: {
                        clusterCode,
                    },
                })
            },
            onError: error => {
                errorAlert(
                    errorHandler(error.response?.data) ||
                        `Could not save minimum deposit. please try again`,
                )
            },
        },
    )

    useEffect(() => {
        refetchMinimumDeposit()
    }, [refetchMinimumDeposit])

    return {
        minimumDeposit,
        updateCreateEstimationsStepOne2,
        isLoadingCreateEstimationsStepOne2,
    }
}

export default useGetMinimumDeposit
