import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import GetModal from '../Modal'
import EnergySolution from './EnergySolution.jsx'
import PaymentPlan from './PaymentPlan'
import styles from './adminsolutionmodal.module.scss'

const AdminSolutionModal = ({
    showModal,
    setShowModal,
    closeModal,
    tab,
    page,
    recommendedSolution,
    repaymentPlans,
    selectedPackageId,
    planType,
    markupValue,
    amortizationMonth
}) => {
    const { TabPane } = Tabs
    const { ModalWrapper } = styles

    return (
        <>
            <GetModal
                app="adminsolution"
                showModal={showModal}
                onCancel={closeModal}
                closable={true}
                modalWidth="624px"
                modalHeight="600px"
                signUpPageModal
                content={
                    tab === 'awaitingApproval' ? (
                        <div className={ModalWrapper}>
                            <Tabs
                                defaultActiveKey="1"
                                className="RecommendedSolutionTab"
                            >
                                <TabPane tab="Energy Recommendation" key="1">
                                    <EnergySolution
                                        closeModal={closeModal}
                                        recommendedSolution={
                                            recommendedSolution
                                        }
                                        tab={tab}
                                    />
                                </TabPane>
                                <TabPane tab="Payment Plans" key="2">
                                    <PaymentPlan
                                        setShowModal={setShowModal}
                                        closeModal={closeModal}
                                        repaymentPlan={repaymentPlans}
                                        page={page}
                                        selectedPackageId={selectedPackageId}
                                        planType={planType}
                                        markupValue={markupValue}
                                        amortizationMonth={amortizationMonth}
                                    />
                                </TabPane>
                            </Tabs>
                        </div>
                    ) : (
                        <div className={ModalWrapper}>
                            <EnergySolution
                                closeModal={closeModal}
                                recommendedSolution={recommendedSolution}
                                tab={tab}
                            />
                        </div>
                    )
                }
            />
        </>
    )
}

AdminSolutionModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    tab: PropTypes.string,
    page: PropTypes.string,
    recommendedSolution: PropTypes.any,
    repaymentPlans: PropTypes.array,
    selectedPackageId: PropTypes.string,
    planType: PropTypes.string,
    markupValue: PropTypes.number,
    amortizationMonth: PropTypes.number,
}

export default AdminSolutionModal
