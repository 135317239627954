/* eslint-disable react/prop-types */
//TODO: take out disabled eslint and refactor code for best practices in extreme cases
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import FormTitleBar from 'src/components/FormTitleBar'
import {
    fetchCardDetailsApi,
    payWithExistingCardApi,
} from 'src/api/consumer/payment'
import Toast from 'src/components/Toast'
import { errorHandler } from 'src/utils/errorHandler'
import { ConsumerButton } from 'src/components/Button'
import plusicon from 'src/assets/images/plus-icon.svg'
import mastercardicon from 'src/assets/images/mastercard.svg'
import visacardicon from 'src/assets/images/visa-card-logo.svg'
import vervecardicon from 'src/assets/images/verve.svg'
import arrowright from 'src/assets/images/ArrorRight.svg'
import ActionModal from 'src/components/ActionModal'
import { RecommendedSkeletalCards } from 'src/components/SkeletalCards'
import { InlineLoader } from 'src/components/Loader'
import PayStack from 'src/pages/Consumer/SmartShoppingExperience/CreditDecision/components/PayStack'
import addcreditcardicon from 'src/assets/images/add-credit-card.svg'

import styles from './carddetails.module.scss'
import { appTracking } from 'src/utils/appTracker'

const CardDetails = ({
    estimationId,
    closeCardDetailsModal,
    openCardDetailsModal,
    email,
    setVerifyLoading,
}) => {
    const {
        CardDetailsWrapper,
        PlusIconStyle,
        PlusTextStyle,
        CardStyle,
        CardIconWrapper,
        CardNumberStyle,
        CTATextStyle,
        CardDetailsStyle,
        AddButtonStyle,
        BtnWrapper,
    } = styles
    const [cardDetails, setCardDetails] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [cardSelected, setCardSelected] = useState(false)
    const [showSetupSuccessfulModal, setShowSetupSuccessfulModal] =
        useState(false)
    const [showAddNewCardModal, setShowAddNewCardModal] = useState(false)
    const [setupError, setSetupError] = useState(false)
    const [showPayStackModal, setShowPaystackModal] = useState(false)
    const [paystackLoading, setPaystackLoading] = useState(false)
    const [initializePaymentError, setInitializePaymentError] = useState(false)
    const [initializePayment, setInitializePayment] = useState(false)

    const { isFetching: cardDetailsFetching, refetch: cardDetailsRefetch } =
        useQuery(
            'fetch-card-details',
            () => fetchCardDetailsApi(estimationId),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setCardDetails(data?.data?.data)
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )
    const {
        isFetching: payWithExistingCardFetching,
        refetch: payWithExistingCardRefetch,
    } = useQuery(
        'pay-with-existing-card',
        () => payWithExistingCardApi(estimationId),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeCardDetailsModal()
                setCardSelected(false)
                setShowSetupSuccessfulModal(true)
                appTracking(
                    '',
                    '',
                    '',
                    'REPAYMENT_SETUP_CARD_DEBIT_SUCCESSFUL',
                    'REPAYMENT_SETUP_CARD_DEBIT',
                    'REPAYMENT_SETUP',
                    ['MP', 'GA'],
                    'event',
                    {
                        event: {},
                    },
                )
            },
            onError: () => {
                closeCardDetailsModal()
                setCardSelected(false)
                setShowSetupSuccessfulModal(true)
                setSetupError(true)
            },
        },
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => cardDetailsRefetch(), [estimationId])

    const selectCard = () => {
        setCardSelected(true)
    }

    const handleInitializePaymentError = error => {
        setInitializePaymentError(true)
        setErrorMessage(error)
    }

    const handleShowPaystackModal = () => {
        setShowPaystackModal(true)
        setPaystackLoading(true)
        setInitializePayment(true)
        setTimeout(() => setInitializePayment(false), 1000)
    }

    const handleSetupSuccess = () => {
        setShowSetupSuccessfulModal(false)
        window.location.reload()
    }

    const handleAddNewCard = () => {
        closeCardDetailsModal()
        setShowAddNewCardModal(true)
    }
    const NoCardDetails = Object.keys(cardDetails)?.length === 0

    return (
        <>
            {showPayStackModal && (
                <PayStack
                    estimationId={estimationId}
                    reloadPage={() => window.location.reload()}
                    event="repayment_method_setup"
                    email={email}
                    handleLoading={() => setPaystackLoading(false)}
                    handleError={handleInitializePaymentError}
                    closeAddNewModal={() => setShowAddNewCardModal(false)}
                    openCardDetailsModal={openCardDetailsModal}
                    callInitializePayment={initializePayment}
                    setVerifyLoading={setVerifyLoading}
                />
            )}
            <ActionModal
                actionModalOpen={showSetupSuccessfulModal}
                headerText={
                    setupError === true
                        ? 'Setup unsuccessfully'
                        : 'Setup Successfully'
                }
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                closeModal={() => setShowSetupSuccessfulModal(false)}
                actionHandler={
                    setupError === true
                        ? () => setShowSetupSuccessfulModal(false)
                        : handleSetupSuccess
                }
                actionType={setupError === true ? 'delete' : 'success'}
                actionText="Okay, Got it"
                loading={''}
                noCancelBtn={true}
                closable
            />

            <ActionModal
                actionModalOpen={showAddNewCardModal}
                headerText="Add New Card"
                subTitle="This card will be added as your Repayment Method, would you like to continue?"
                closeModal={() => {
                    setShowAddNewCardModal(false)
                    openCardDetailsModal()
                }}
                actionHandler={handleShowPaystackModal}
                onCancel={() => {
                    setShowAddNewCardModal(false)
                    openCardDetailsModal()
                }}
                actionType="warning"
                actionText="Continue"
                cancelText="Cancel"
                actionHeaderClassName="ActionHeader"
                closable
                toastError={initializePaymentError}
                errorMessage={initializePaymentError ? errorMessage : ''}
                messageType="error"
                loading={paystackLoading}
            />
            <div className={CardDetailsWrapper}>
                {toastError && (
                    <Toast messageType="error" message={errorMessage} />
                )}

                <>
                    <FormTitleBar
                        title="Select Payment"
                        subtitle="Enter the details of the request below to continue"
                    />

                    {cardDetailsFetching ? (
                        <RecommendedSkeletalCards total={1} />
                    ) : (
                        <>
                            <section
                                className={CardStyle}
                                onClick={selectCard}
                                style={{
                                    borderColor: cardSelected && '#004AAD',
                                    background: cardSelected && '#E2EEFF',
                                }}
                            >
                                <div className={CardDetailsStyle}>
                                    <div
                                        className={CardIconWrapper}
                                        style={{
                                            background: NoCardDetails
                                                ? '#fff'
                                                : cardSelected
                                                ? cardDetails?.brand?.includes(
                                                      'master',
                                                  )
                                                    ? '#004AAD'
                                                    : '#E2EEFF'
                                                : '#FFFFFF',
                                            border:
                                                NoCardDetails &&
                                                cardSelected &&
                                                '0.5px solid rgba(0, 74, 173, 0.79)',
                                        }}
                                    >
                                        <img
                                            src={
                                                NoCardDetails
                                                    ? addcreditcardicon
                                                    : cardDetails?.brand?.includes(
                                                          'visa',
                                                      )
                                                    ? visacardicon
                                                    : cardDetails?.brand?.includes(
                                                          'verve',
                                                      )
                                                    ? vervecardicon
                                                    : cardDetails?.brand?.includes(
                                                          'master',
                                                      ) && mastercardicon
                                            }
                                            alt="card type icon"
                                        />
                                    </div>
                                    <div>
                                        <p className={CardNumberStyle}>
                                            {NoCardDetails ? (
                                                'Add a new card'
                                            ) : (
                                                <>
                                                    {cardDetails?.bin?.substring(
                                                        0,
                                                        4,
                                                    )}
                                                    <sup>**** **** ****</sup>
                                                    {cardDetails?.last4}
                                                </>
                                            )}
                                        </p>
                                        <p className={CTATextStyle}>
                                            {NoCardDetails
                                                ? 'Click to add a new card here'
                                                : 'Click to pay with the card'}
                                        </p>
                                    </div>
                                </div>
                                {NoCardDetails ? (
                                    <img
                                        src={plusicon}
                                        className={PlusIconStyle}
                                        alt="plus icon"
                                        style={{ marginRight: '25px' }}
                                    />
                                ) : (
                                    <img
                                        src={arrowright}
                                        alt="right arrow icon"
                                        style={{ margin: '22px' }}
                                    />
                                )}
                            </section>
                            {!NoCardDetails && (
                                <div className={AddButtonStyle}>
                                    <ConsumerButton
                                        btnBgColor="#E2EEFF"
                                        btnWidth="131px"
                                        btnHeight="40px"
                                        btnTextColor="#004AAD"
                                        handleClick={handleAddNewCard}
                                    >
                                        <img
                                            src={plusicon}
                                            className={PlusIconStyle}
                                            alt="plus icon"
                                        />
                                        <span className={PlusTextStyle}>
                                            Use New Card
                                        </span>
                                    </ConsumerButton>
                                </div>
                            )}

                            <div className={BtnWrapper}>
                                <ConsumerButton
                                    btnBgColor="#004AAD"
                                    btnWidth="160px"
                                    btnHeight="56px"
                                    btnTextColor="#FFFFFF"
                                    handleClick={() => {
                                        NoCardDetails
                                            ? handleAddNewCard()
                                            : payWithExistingCardRefetch()
                                    }}
                                    disabled={cardSelected === false}
                                >
                                    {payWithExistingCardFetching ? (
                                        <InlineLoader />
                                    ) : (
                                        'Continue'
                                    )}
                                </ConsumerButton>
                            </div>
                        </>
                    )}
                </>
            </div>
        </>
    )
}

CardDetails.propTypes = {
    estimationId: PropTypes.string,
    closeCardDetailsModal: PropTypes.func,
    openCardDetailsModal: PropTypes.func,
    email: PropTypes.string,
}

export default CardDetails
