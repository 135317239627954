import PropTypes from 'prop-types'
import FormTitleBar from '../../FormTitleBar'
import SecondaryModal from '../../Modal/SecondaryModal'
import { TextArea } from '../../InputFields'
import Button from '../../Button'
import styles from './styles.module.scss'
import { InlineLoader } from '../../Loader'
import Toast from '../../Toast'

export const UserActionModal = ({
    showModal,
    closeModal,
    actionType,
    inputs,
    setInputs,
    errors,
    setErrors,
    handleActionCallback,
    isLoading,
    actionToast,
    actionToastMessage,
}) => {
    const { Wrapper, ButtonDiv } = styles
    const handleChange = event => {
        setInputs(prev => ({
            ...prev,
            reason: event.target.value,
        }))
    }

    const handleSubmit = () => {
        if (inputs?.reason === '') {
            setErrors({ reason: 'Please enter your reason for this action' })
        } else {
            handleActionCallback()
        }
    }
    return (
        <>
            <SecondaryModal
                app="adminsolution"
                showModal={showModal}
                onCancel={closeModal}
                closable
                modalWidth="624px"
                modalHeight="100%"
                content={
                    <div className={Wrapper}>
                        <FormTitleBar
                            title={
                                actionType === 'deactivate-user'
                                    ? 'Deactivate User'
                                    : 'Activate User'
                            }
                            subtitle={`Enter your reason for ${
                                actionType === 'deactivate-user'
                                    ? 'deactivating'
                                    : 'activating'
                            } this user`}
                            fontFamily="SF-Pro-Display-Bold"
                            titleFontSize="20px"
                            marginTop="24px"
                            marginBottom="32px"
                        />

                        {actionToast && (
                            <Toast
                                messageType="error"
                                message={actionToastMessage}
                            />
                        )}
                        <TextArea
                            placeholder="Enter reason"
                            rows={10}
                            height="146px"
                            name="reason"
                            value={inputs.reason}
                            handleChange={handleChange}
                            errorMessage={errors?.reason}
                        />
                        <div className={ButtonDiv}>
                            <Button
                                btnWidth="160px"
                                btnHeight="56px"
                                btnBgColor="#004AAD"
                                btnTextColor="var(--white)"
                                handleClick={() => handleSubmit()}
                            >
                                {isLoading ? (
                                    <InlineLoader />
                                ) : (
                                    `${
                                        actionType === 'deactivate-user'
                                            ? 'Deactivate'
                                            : 'Activate'
                                    }`
                                )}
                            </Button>
                        </div>
                    </div>
                }
            />
        </>
    )
}

UserActionModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    actionType: PropTypes.string,
    inputs: PropTypes.any,
    setInputs: PropTypes.func,
    errors: PropTypes.any,
    setErrors: PropTypes.func,
    handleActionCallback: PropTypes.func,
    isLoading: PropTypes.bool,
    actionToast: PropTypes.bool,
    actionToastMessage: PropTypes.string,
}

export default UserActionModal
