/* eslint-disable react/prop-types */
import { useState, useCallback, useEffect } from 'react'
import { useQuery } from 'react-query'
import { InputFields, SelectField } from 'src/components/InputFields'
import SeoComponent from 'src/components/Seo'
import Button from 'src/components/Button'
import { CostField } from 'src/components/InputFields/CostField'
import { CreateComponentValidationSchema } from 'src/utils/validationSchema'
import Editor from 'src/components/Editor'
import FormTitleBar from 'src/components/FormTitleBar'
import NotFoundImage from 'src/assets/images/NotFoundIcon.svg'
import Toast from 'src/components/Toast'
import { InlineLoader } from 'src/components/Loader'

import styles from './addcomponent.module.scss'
import { floatWithCommas, formatPlainNumberInput } from 'src/utils/formatting'

import {
    validateComponentNameApi,
    getBrandNamesApi,
} from 'src/api/products/component'
import { eventTrackers } from 'src/utils/eventTrackers.js'
import useErrorHandler from 'src/utils/Hooks/useErrorHandler'
import { decodeUserInfo } from 'src/utils/auth'
import RadioSelected from 'src/assets/images/radio-selected-icon.svg'
import RadioUnselected from 'src/assets/images/radio-unselected-icon.svg'
import { appTracking } from '../../../utils/appTracker.js'
import { ReactComponent as ConsumerAddIcon } from 'src/assets/images/consumer-plus-icon.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import CreateBrand from './CreateBrand'

const NotFound = ({ componentType }) => {
    const { NotFoundWrapper, NotFoundTitle, NotFoundIcon, NotFoundSubtitle } =
        styles
    return (
        <div className={NotFoundWrapper}>
            <img src={NotFoundImage} className={NotFoundIcon} alt="not found" />
            <p className={NotFoundTitle}>No Result Found</p>
            <p className={NotFoundSubtitle}>
                Click enter to create the <strong>“{componentType}”</strong>{' '}
                component type
            </p>
        </div>
    )
}

const StepOne = ({
    initialState,
    gotoNext,
    gotoStep3,
    modalFlow,
    setComponentModal,
}) => {
    const {
        CreateComponentSubHeader,
        PageForm,
        ComponentActionHeader,
        ButtonWrapper,
        modalButtonWrapper,
        GlobalComponentTitle,
        AddBrandIcon,
        AddBrandIconWrapper,
    } = styles
    const [inputs, setInputs] = useState(initialState)
    const [errors, setErrors] = useState({})
    const [, setEditorIsFocus] = useState(false)
    const [editorErrMsg, setEditorErrMsg] = useState('')
    const [editorNode, setEditorNode] = useState(null)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [validatingName, setValidatingName] = useState(false)
    const pageTitle =
        'Create A Component - Add Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'ADD_COMPONENT_TRACKER'
    const [errs, setErrs] = useState({})
    const [brandNames, setBrandNames] = useState([])
    const [showBrandModal, setShowBrandModal] = useState(false)

    const editorRef = useCallback(node => {
        setEditorNode(node)
    }, [])
    const editor = editorNode?.getEditor()

    const unprivilegedEditor = editorNode?.makeUnprivilegedEditor(editor)
    const formatComponentType = initialState?.formatedComponentTypes
    const userInfo = decodeUserInfo()
    const isAdminWorkspace = userInfo?.isAdminWorkspaceUser === 'true'
    const componentIsSolarPanel = inputs?.componentType === 'Solar Panel'

    const handleInputChange = event => {
        setToastError(false)
        //regex to to allow numbers and comma
        const numberRegex = /^[0-9.,]*$/
        const { name, value } = event.target
        if (value === '' && name !== 'componentWarranty') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else if (
            value === '' &&
            name == 'componentWarranty' &&
            !componentIsSolarPanel
        ) {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
            setErrs({})
        }
        if (name === 'componentCost') {
            setInputs(prev => ({
                ...prev,
                componentCost: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.componentCost,
            }))
        } else if (name === 'componentWarranty') {
            setInputs(prev => ({
                ...prev,
                [name]: formatPlainNumberInput(value),
            }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value }))
        }
    }
    const handleInputBlur = event => {
        //regex to to allow numbers and comma
        const numberRegex = /^[0-9.,]*$/
        const { name, value } = event.target
        if (name === 'componentCost') {
            setInputs(prev => ({
                ...prev,
                componentCost: numberRegex.test(value.trim())
                    ? floatWithCommas(value.trim())
                    : inputs.componentCost,
            }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value.trim() }))
        }
    }
    const handleEditorChange = value => {
        setInputs(prev => ({ ...prev, componentDescription: value }))
        setEditorErrMsg('')
    }

    const [searchValue, setSearchValue] = useState('')
    const [search, setSearch] = useState(false)

    const handleSelectChange = (name, value) => {
        setSearch(false)

        let filteredComponentRevamped = formatComponentType.filter(
            data => data.value === value,
        )

        let filteredBrand = brandNames?.filter(data => data.name === value)

        let selectedOption = {}
        let selectedBrandOption = {}

        if (name === 'componentType') {
            if (filteredComponentRevamped.length < 1) {
                selectedOption = {
                    value,
                    id: '',
                }
            } else {
                selectedOption = filteredComponentRevamped[0]
            }
        } else if (name === 'brandName') {
            selectedBrandOption = filteredBrand[0]
        }

        setErrors(prev => ({ ...prev, [name]: '' }))

        setInputs(prev => ({
            ...prev,
            [name]:
                name === 'componentType'
                    ? selectedOption.value
                    : selectedBrandOption.name,
            ...(name === 'componentType'
                ? {
                      componentTypeId: selectedOption.id,
                  }
                : { brandId: selectedBrandOption.id }),
        }))

        setInputs(prev => ({
            ...prev,
            attributes: selectedOption.attributes,
            selectedAttributes:
                initialState?.userSelectedType !== value
                    ? []
                    : initialState?.selectedAttributes,
            userSelectedType: value,
            formatedComponentTypes: formatComponentType,
        }))
    }

    const handleEnterKey = e => {
        let filteredComponent = formatComponentType.filter(data =>
            data.value.includes(inputs.componentType),
        )
        if (e.key === 'Enter') {
            if (filteredComponent.length === 0) {
                formatComponentType.push({
                    attributes: [],
                    id: '',
                    name: inputs.componentType,
                    value: inputs.componentType,
                })
                formatComponentType.sort((a, b) => a.name.localeCompare(b.name))
                handleSelectChange('componentType', inputs.componentType)
            }
        }
    }

    const handleSearch = (name, value) => {
        if (value !== '') {
            setInputs(prev => ({ ...prev, [name]: value }))
        } else {
            setSearch(true)
            setSearchValue(value)
        }
    }
    const handleEditorNext = () => {
        if (inputs?.attributes?.length < 1) {
            gotoStep3(inputs)
        } else {
            gotoNext(inputs)
        }
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['addComponent'].action,
            eventTrackers['addComponent'].label,
            eventTrackers['addComponent'].category,
        )
    }

    function handleNext() {
        setEditorIsFocus(true)
        if (inputs?.attributes === undefined) {
            formatComponentType.push({
                attributes: [],
                id: '',
                name: inputs.componentType,
                value: inputs.componentType,
            })
            formatComponentType.sort((a, b) => a.name.localeCompare(b.name))
            handleSelectChange('componentType', inputs.componentType)
        }

        const editorValue = unprivilegedEditor?.getText().trim()
        if (editorValue?.trim()?.length < 1) {
            setEditorErrMsg('Component description is required')
        }

        CreateComponentValidationSchema.validate(inputs, {
            context: {
                checkWarranty: isAdminWorkspace && !componentIsSolarPanel,
            },
            abortEarly: false,
        })
            .then(() => {
                setErrors('')
                if (
                    isGlobalContributor &&
                    (inputs?.isGlobal === null || inputs?.isGlobal === 'none')
                ) {
                    handlePackageIsGlobal('none')
                } else if (editorValue?.trim()?.length > 0) {
                    setValidatingName(true)
                    validateComponentName()
                }
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const { refetch: validateComponentName } = useQuery(
        'validateComponent',
        () =>
            validateComponentNameApi(
                inputs.componentName,
                inputs.componentTypeId,
                inputs.componentType,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setValidatingName(false)
                handleEditorNext()
            },
            onError: data => {
                if (data?.response?.data?.errors?.message) {
                    setErrs(data?.response?.data)
                } else if (data?.response?.data?.errors) {
                    setErrs(data?.response?.data)
                } else {
                    setErrorMessage(['Component name validation failed.'])
                }
                setValidatingName(false)
                setToastError(true)
            },
        },
    )

    //retrieve brand names
    const { refetch: getBrandNamesRefetch } = useQuery(
        'getBrandNames',
        () => getBrandNamesApi(isAdminWorkspace),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setBrandNames(data?.data?.data)
            },
            onError: data => {
                if (data?.response?.data?.errors?.message) {
                    setErrs(data?.response?.data)
                } else if (data?.response?.data?.errors) {
                    setErrs(data?.response?.data)
                } else {
                    setErrorMessage(['Failed to fetch Brand Names'])
                }
                setToastError(true)
            },
        },
    )
    const handlePackageIsGlobal = value => {
        setInputs(prev => ({ ...prev, isGlobal: value }))
    }
    // radio button border styles
    const yes =
        inputs?.isGlobal === 'none'
            ? 'red'
            : inputs?.isGlobal === true
            ? '#004aad'
            : '#e5e5e5'

    const no =
        inputs?.isGlobal === 'none'
            ? 'red'
            : inputs?.isGlobal === false
            ? '#004aad'
            : '#e5e5e5'

    const isGlobalContributor = userInfo?.globalContributor === 'true'
    const errorValue = useErrorHandler(errs)

    useEffect(() => {
        process.env.REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE === 'true' &&
            getBrandNamesRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <SeoComponent
                title="Create A Component - Add Information | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Create A Component - Add Information"
            />
            <SecondaryModal
                showModal={showBrandModal}
                modalHeight="606px"
                modalWidth="624px"
                modalClass="adminModal"
                onCancel={() => setShowBrandModal(false)}
                closable={false}
                content={
                    <CreateBrand
                        closeModal={() => setShowBrandModal(false)}
                        handleSuccessSubmit={() => {
                            getBrandNamesRefetch()
                            modalFlow && setComponentModal(true)
                        }}
                    />
                }
            />

            {toastError && (
                <Toast
                    messageType="error"
                    message={
                        errorMessage?.[0]?.['message'] || errorValue['msg']
                            ? errorMessage?.[0]?.['message'] ||
                              errorValue['msg']
                            : ''
                    }
                />
            )}
            {modalFlow ? (
                <FormTitleBar
                    title="Create New Component"
                    subtitle="Enter the component details"
                />
            ) : (
                <>
                    <div className={CreateComponentSubHeader}>
                        Create New Component
                    </div>

                    <div className={ComponentActionHeader}>
                        Enter Component Information
                    </div>
                </>
            )}
            <div className={PageForm}>
                <InputFields
                    type="text"
                    inputWidth="100%"
                    handleChange={handleInputChange}
                    onBlur={handleInputBlur}
                    value={inputs?.componentName}
                    title="Component Name"
                    name="componentName"
                    errorMessage={
                        errors?.componentName || errorValue?.name?.[0]
                    }
                    marginBottom="10px"
                />

                <CostField
                    name="componentCost"
                    defaultValue={'₦'}
                    currencyCode={'₦'}
                    value={inputs?.componentCost}
                    inputWidth="100%"
                    fullWidth={true}
                    title="Cost of Component"
                    floatTitlePositionLeft={'48px'}
                    handleChange={handleInputChange}
                    errorMessage={errors?.componentCost}
                    noFloat
                    marginBottom="10px"
                    selectWidth="90px"
                    prefixDisabled={true}
                />
                <SelectField
                    onKeyUp={handleEnterKey}
                    values={formatComponentType}
                    value={search ? searchValue : inputs?.componentType}
                    currentSelected={inputs?.componentType}
                    handleChange={handleSelectChange}
                    name="componentType"
                    selectedValue={inputs?.componentType}
                    withCheckBox={true}
                    selectWidth="100%"
                    dropdownClassName="componentType"
                    initialOption={'Select Component Type'}
                    floatingLabel={'Select Component Type'}
                    floatLabelOnSelectedValue
                    errorMessage={errors?.componentType}
                    marginBottom="10px"
                    handleSearch={handleSearch}
                    notFoundContent={
                        <NotFound componentType={inputs?.componentType} />
                    }
                    searchValue
                    dropdownPositionRelative
                    showSearch
                    onSearch
                />
                {isAdminWorkspace && (
                    <InputFields
                        type="text"
                        inputWidth="100%"
                        handleChange={handleInputChange}
                        onBlur={handleInputBlur}
                        value={inputs?.componentWarranty}
                        title="Component Warranty (Months)"
                        name="componentWarranty"
                        errorMessage={errors?.componentWarranty}
                        marginBottom="10px"
                        optional={componentIsSolarPanel}
                    />
                )}
                {process.env.REACT_APP_FLAG_SHOW_PROTECTIVE_DEVICE ===
                    'true' && (
                    <div>
                        <SelectField
                            values={brandNames?.map(brand => ({
                                value: brand.name,
                            }))}
                            value={inputs?.brandName}
                            currentSelected={inputs?.brandName}
                            handleChange={handleSelectChange}
                            name="brandName"
                            selectedValue={inputs?.brandName}
                            withCheckBox={true}
                            selectWidth="100%"
                            dropdownClassName="brandName"
                            initialOption={'Select Brand Name'}
                            floatingLabel={'Select Brand Name'}
                            floatLabelOnSelectedValue
                            errorMessage={errors?.brandName}
                            marginBottom="10px"
                            searchValue
                            dropdownPositionRelative
                            showSearch
                        />
                        {isAdminWorkspace && (
                            <div className={AddBrandIconWrapper}>
                                <div
                                    onClick={() => {
                                        setShowBrandModal(true)
                                        modalFlow && setComponentModal(false)
                                    }}
                                >
                                    <ConsumerAddIcon className={AddBrandIcon} />
                                    <span>Add new brand</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <Editor
                    value={inputs?.componentDescription}
                    name="componentDescription"
                    placeholder="Component Description"
                    onChangeHandler={handleEditorChange}
                    editorWidth="100%"
                    editorHeight="150px"
                    errorMessage={editorErrMsg}
                    editorRef={editorRef}
                />
                <br />
                {isGlobalContributor && (
                    <>
                        <p className={GlobalComponentTitle}>
                            Is this a global component?
                        </p>
                        <div>
                            <div className={`InputsContainer`}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <button
                                        className="RadioContainer"
                                        style={{
                                            border: `0.7px solid ${yes}`,
                                            fontWeight:
                                                inputs?.isGlobal === true
                                                    ? 600
                                                    : 400,
                                            marginRight: '3.5px',
                                        }}
                                        onClick={e => {
                                            e.preventDefault()
                                            handlePackageIsGlobal(true)
                                        }}
                                    >
                                        <input
                                            value={true}
                                            onClick
                                            name="is_global"
                                            defaultChecked={
                                                inputs?.isGlobal === true
                                            }
                                            type="radio"
                                            style={{
                                                display: 'none',
                                            }}
                                        />
                                        <img
                                            src={
                                                inputs?.isGlobal === true
                                                    ? RadioSelected
                                                    : RadioUnselected
                                            }
                                            alt="radio"
                                        />
                                        Yes, It is
                                    </button>
                                    <button
                                        className="RadioContainer"
                                        onClick={e => {
                                            e.preventDefault()
                                            handlePackageIsGlobal(false)
                                        }}
                                        style={{
                                            border: `0.7px solid ${no}`,
                                            fontWeight:
                                                inputs?.isGlobal === false
                                                    ? 600
                                                    : 400,
                                            marginLeft: '3.5px',
                                        }}
                                    >
                                        <input
                                            value={false}
                                            name="is_global"
                                            defaultChecked={
                                                inputs?.isGlobal === false
                                            }
                                            type="radio"
                                            style={{
                                                display: 'none',
                                            }}
                                        />
                                        <img
                                            src={
                                                inputs?.isGlobal === false
                                                    ? RadioSelected
                                                    : RadioUnselected
                                            }
                                            alt="radio"
                                        />
                                        No, It’s not
                                    </button>
                                </div>
                            </div>
                            {inputs?.isGlobal === 'none' ? (
                                <p
                                    className="CreditCheckErrorText"
                                    style={{ marginBottom: '15px' }}
                                >
                                    Please select an option
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                    </>
                )}
                <div className={modalFlow ? modalButtonWrapper : ButtonWrapper}>
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        btnWidth="160px"
                        handleClick={handleNext}
                    >
                        {validatingName ? <InlineLoader /> : 'Continue'}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default StepOne
