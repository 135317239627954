import axios from 'src/config/axios'
import { sanitizeFileName } from 'src/utils/formatting'

export const uploadToS3 = async (category, file, isAuthenticated) => {
    const presignedPostUrl = await generatePresignedPostUrl(
        sanitizeFileName(file?.name),
        category,
        isAuthenticated,
    )

    await axios.put(presignedPostUrl.url, file?.originFileObj, {
        headers: {
            'Content-Type': file?.type,
            'x-ms-blob-type': 'BlockBlob',
            thirdParty: 'AZURE',
        },
    })

    // If third party is Azure
    const url = new URL(presignedPostUrl.url)
    // Get the container and sub-folder and file name
    const baseUrl = `${url.protocol}//${url.hostname}`
    const path = `/${url.pathname.substring(1)}`
    const newPresignedPostUrl = {
        url: baseUrl,
        fields: {
            key: path,
        },
    }
    return newPresignedPostUrl
}

export const uploadMultipleToS3 = async (payload, files) => {
    const sanitizedPayload = payload?.map(data => ({
        ...data,
        file_name: sanitizeFileName(data.file_name),
    }))

    const response = await axios.post(
        `/utils/generate-presigned-url/with-auth/multiple`,
        sanitizedPayload,
    )

    const data = response?.data?.data

    const uploadPromises = []

    const presignedPostUrls = data?.map(presignedPostUrl => {
        const url = new URL(presignedPostUrl.url)
        const filename = presignedPostUrl.fields?.key?.match(/[^/]+$/)?.[0]

        if (filename) {
            const file = files.find(
                file => sanitizeFileName(file.name) === filename,
            )

            if (file) {
                uploadPromises.push(
                    axios.put(presignedPostUrl.url, file?.originFileObj, {
                        headers: {
                            'Content-Type': file?.type,
                            'x-ms-blob-type': 'BlockBlob',
                            thirdParty: 'AZURE',
                        },
                    }),
                )
            }
        }

        const baseUrl = `${url.protocol}//${url.hostname}`
        const path = `/${url.pathname.substring(1)}`

        return {
            url: baseUrl,
            fields: {
                key: path,
            },
        }
    })

    await Promise.all(uploadPromises)

    return presignedPostUrls
}

const generatePresignedPostUrl = async (
    fileName,
    category,
    isAuthenticated = true,
) => {
    const payload = { file_name: fileName, category: category }
    const api = `/utils/generate-presigned-url/${
        isAuthenticated ? 'with-auth' : 'with-no-auth'
    }`
    const response = await axios.post(`${api}`, payload)
    return response?.data?.data
}
