import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    published: true,
    page: '1',
    name: '',
    current_tab: '1',
}

const adminProfilesListing = createSlice({
    name: 'adminProfilesListingFilter',
    initialState,
    reducers: {
        updateAdminProfilesFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetAdminProfilesFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateAdminProfilesFilters, resetAdminProfilesFilters } =
    adminProfilesListing.actions

export const updatedAdminProfilesFilters = state =>
    state.adminProfilesListingFilter

const persistConfig = {
    key: 'adminProfilesListingFilter',
    storage,
}

export const persistedAdminProfilesListingReducer = persistReducer(
    persistConfig,
    adminProfilesListing.reducer,
)

export default adminProfilesListing.reducer
