import { useState } from 'react'
import styles from './statusbreakdown.module.scss'
import PropTypes from 'prop-types'
import { ReactComponent as CheckedIcon } from 'src/assets/images/check-green.svg'
import { orderedStatusBreakdownForAdmins } from './data'
import { ReactComponent as CircleArrowIcon } from 'src/assets/images/circle-down-no-stroke.svg'
import StatusIndicator from '../StatusIndicator'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const StatusBreakdown = ({
    margin,
    status,
    consumerType,
    showTitle = true,
    plan,
}) => {
    const [showFullList, setShowFullList] = useState(false)

    const { isMobile } = useMediaQueries()

    const toggleListDisplay = () => {
        setShowFullList(!showFullList)
        setTimeout(() => {
            window.scrollTo({
                left: 0,
                top: document.body.scrollHeight,
                behavior: 'smooth',
            })
        }, 100)
    }

    return (
        <div style={{ margin: margin }}>
            {showTitle && <h4 className={styles.title}>STATUS BREAKDOWN</h4>}
            <div
                className={`${styles.statusList} ${
                    showFullList ? styles.fullList : styles.truncList
                }`}
            >
                <div className={styles.dashed}></div>
                <div className={styles.allStatusOptions}>
                    {orderedStatusBreakdownForAdmins(
                        status,
                        consumerType,
                        plan,
                    )?.map((status, index) => {
                        if (status.isCurrent) {
                            return (
                                <div
                                    key={index}
                                    className={styles.currentStatus}
                                >
                                    <StatusIndicator
                                        status={status?.status}
                                        arrowPositionCenter
                                        toolTipMarginLeft={
                                            isMobile ? '-18px' : '-82px'
                                        }
                                        page={
                                            plan === 'Outright Sale' &&
                                            'outright-sale'
                                        }
                                    />
                                </div>
                            )
                        } else
                            return (
                                <div key={index} className={styles.status}>
                                    {status.isCompleted ? (
                                        <CheckedIcon
                                            className={styles.checkedIcon}
                                        />
                                    ) : (
                                        <div
                                            className={styles.uncheckedIcon}
                                        ></div>
                                    )}
                                    <span>{status.title}</span>
                                </div>
                            )
                    })}
                </div>
                <div
                    className={`${styles.toggleBtnWrapper} ${
                        showFullList
                            ? styles.lessBtnWrapper
                            : styles.moreBtnWrapper
                    }`}
                >
                    <button
                        onClick={toggleListDisplay}
                        className={`${styles.toggleBtn} ${
                            showFullList ? styles.lessBtn : styles.moreBtn
                        }`}
                    >
                        {showFullList ? 'See less' : 'See more'}
                        <CircleArrowIcon />
                    </button>
                </div>
            </div>
        </div>
    )
}

StatusBreakdown.propTypes = {
    margin: PropTypes.string,
    status: PropTypes.string,
    consumerType: PropTypes.oneOf(['DTC', 'DEP']),
    showTitle: PropTypes.bool,
    plan: PropTypes.oneOf(['Subscription', 'Lease to Own', 'Outright Sale']),
}

export default StatusBreakdown
