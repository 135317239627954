import PropTypes from 'prop-types'
import s from './styles.module.scss'
import { decodeUserInfo } from 'src/utils/auth'
import { ReactComponent as LightBulb } from 'src/assets/images/lightbulb.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import fallbackImage from 'src/assets/images/fallback.svg'
import NairaSymbol from 'src/components/NairaSymbol'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const SelectedSolutionCard = ({
    offer,
    handleChangePackage,
    seePackageInfo,
    hasPaidOutrightly,
}) => {
    const userInfo = decodeUserInfo()
    const { isMobile } = useMediaQueries()

    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const isAdminDepConsumer = isAdminUser && offer?.survey_type === 'DFS'

    return (
        <div className={s.card} data-testid="selected-solution-card-container">
            <div
                className={s.titleAndBtn}
                data-testid="selected-solution-card-title-and-btn"
            >
                <h4>{!isMobile && !isAdminUser && 'YOUR'} SELECTED SOLUTION</h4>
                {!isAdminDepConsumer && !hasPaidOutrightly && (
                    <div
                        className={s.iconBtn}
                        role={'button'}
                        onClick={handleChangePackage}
                        aria-label="change package"
                        data-testid="selected-solution-card-change-package-button"
                    >
                        <p>Change package</p>
                        {!isMobile && <LightBulb role={'presentation'} />}
                    </div>
                )}
            </div>
            <div
                className={s.info}
                data-testid="selected-solution-card-info-section"
            >
                <LazyLoadImage
                    src={
                        'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+thumbnail.png'
                    }
                    placeholderSrc={fallbackImage}
                    alt="image of the system"
                    wrapperClassName={'SelectedSolutionCard-InfoImgWrapper'}
                    data-testid="selected-solution-card-lazy-load-image"
                />
                <div
                    className={s.infoText}
                    data-testid="selected-solution-card-info-text"
                >
                    {/* row 1 */}
                    <div
                        className={s.row}
                        data-testid="selected-solution-card-row-1"
                    >
                        <div>
                            <h5>{offer?.systemName}</h5>
                            <p>{offer?.capacity + ' system'} </p>
                        </div>
                        <div className={s.infoText_Right}>
                            <h5>Full amount</h5>
                            <p>
                                <NairaSymbol />
                                {offer?.totalPrice}
                            </p>
                        </div>
                    </div>

                    {/* row 2 */}
                    <div
                        className={s.row}
                        data-testid="selected-solution-card-row-2"
                    >
                        <div>
                            <h5>Back-up hours</h5>
                            <p>{offer?.backupHours} hours</p>
                        </div>

                        {!isAdminUser && (
                            <span
                                className={s.seePackageBtn}
                                role={'button'}
                                onClick={seePackageInfo}
                                aria-label="see package"
                                data-testid="selected-solution-card-see-package-button"
                            >
                                See package info
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

SelectedSolutionCard.propTypes = {
    offer: PropTypes.object,
    handleChangePackage: PropTypes.func,
    seePackageInfo: PropTypes.func,
    hasPaidOutrightly: PropTypes.func,
}

export default SelectedSolutionCard
