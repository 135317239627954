import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/ResidenceForm/residenceform.scss'

import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { InputFields, SelectField, TextArea } from 'src/components/InputFields'
import { IconInputField } from 'src/components/InputFields/CostField'
import LinkIcon from 'src/assets/images/link.svg'
import { SelectButtons } from 'src/components/SelectButtons'
import { registeredBusinessOptions } from '../../../utils'
import { businessIndustry } from 'src/utils/business'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const BusinessDetails = ({
    inputs,
    errors,
    handleChange,
    handleSelectChange,
    handleOnBlur,
    handleSelectOnBlur,
    isAdminUser,
    handleBusinessRegisteredChange,
    toggleShowCacVerifyModal,
    cacVerificationInProgress,
    isCustomerApproved,
}) => {
    const { isMobile } = useMediaQueries()
    return (
        <>
            <Label
                title={'Employment details'}
                subTitle={'Provide us with more details about your work'}
            />

            <div className="ContactAddress_Confirmation-Wrapper">
                <p>Is your business registered?</p>
                <SelectButtons
                    selectedValue={inputs.registeredBusiness.title}
                    values={registeredBusinessOptions?.map(o => o.title)}
                    handleChange={value =>
                        handleBusinessRegisteredChange(value)
                    }
                    width={163}
                    height={46}
                    disabled={isAdminUser || isCustomerApproved}
                    margin="0 0 24px 0"
                />
            </div>

            <InputFields
                name="businessName"
                title="Business Name"
                value={inputs.businessName}
                handleChange={handleChange}
                errorMessage={errors?.businessName}
                onBlur={handleOnBlur}
                disabled={isAdminUser || isCustomerApproved}
            />

            <SelectField
                floatLabelOnSelectedValue
                name="businessIndustry"
                floatingLabel="Business Industry"
                initialOption="Business Industry"
                selectWidth="100%"
                handleChange={handleSelectChange}
                onBlur={() => handleSelectOnBlur('businessIndustry')}
                values={businessIndustry.map(option => ({
                    value: option,
                }))}
                value={inputs?.businessIndustry}
                errorMessage={errors?.businessIndustry}
                showSearch
                dropdownPositionRelative
                disabled={isAdminUser || isCustomerApproved}
                withCheckBox
                currentSelected={inputs?.businessIndustry}
                selectedValue={inputs?.businessIndustry}
                marginBottom={'24px'}
            />

            {inputs.registeredBusiness.value && (
                <div className="VerifyInputWrapper">
                    <InputFields
                        name="cacNumber"
                        title="CAC Number"
                        value={inputs.cacNumber}
                        handleChange={handleChange}
                        onBlur={handleOnBlur}
                        disabled={
                            inputs?.cacNumberVerified ||
                            isAdminUser ||
                            cacVerificationInProgress ||
                            isCustomerApproved
                        }
                        marginBottom={
                            inputs?.cacNumberVerified ? '16px' : '8px'
                        }
                        errorMessage={
                            errors?.cacNumber || errors?.cacNumberVerified
                        }
                        inputWidth={'100%'}
                        verified={inputs?.cacNumberVerified}
                    />
                    {cacVerificationInProgress && (
                        <span className="verificationInProgressStyle">
                            Verification In Progress
                        </span>
                    )}
                    {!isAdminUser &&
                        !cacVerificationInProgress &&
                        !inputs?.cacNumberVerified && (
                            <div className="VerifyCTA">
                                <button
                                    style={{ width: '123px' }}
                                    onClick={() => toggleShowCacVerifyModal()}
                                    disabled={
                                        inputs?.businessName === '' ||
                                        inputs?.cacNumber === '' ||
                                        errors.cacNumber ||
                                        isCustomerApproved
                                    }
                                >
                                    {isMobile
                                        ? 'Verify CAC'
                                        : 'Verify CAC number'}
                                </button>
                            </div>
                        )}
                </div>
            )}

            <InputFields
                name="numberOfYearsInOperation"
                title="Number of years in operation"
                value={inputs.numberOfYearsInOperation}
                handleChange={handleChange}
                onBlur={handleOnBlur}
                errorMessage={errors?.numberOfYearsInOperation}
                disabled={isAdminUser || isCustomerApproved}
            />

            <>
                <IconInputField
                    icon={LinkIcon}
                    name="businessWebsite"
                    title="Website"
                    width={'100%'}
                    value={inputs?.businessWebsite}
                    handleChange={handleChange}
                    onBlur={handleOnBlur}
                    errorMessage={errors?.businessWebsite}
                    disabled={isAdminUser || isCustomerApproved}
                />
                {!errors?.businessWebsite && (
                    <p className="OptionalTypoGraphy">Optional</p>
                )}
            </>

            <TextArea
                title="Description of business"
                name="businessDescription"
                value={inputs.businessDescription}
                handleChange={handleChange}
                onBlur={handleOnBlur}
                errorMessage={errors?.businessDescription}
                rows={5}
                height="162px"
                disabled={isAdminUser || isCustomerApproved}
            />
        </>
    )
}

BusinessDetails.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default BusinessDetails
