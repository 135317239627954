import { useState } from 'react'
import { useQuery } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { getBrandNamesApi } from 'src/api/admin/provider'

export const useGetProviderBrands = ({ providerId, status }) => {
    const { errorAlert } = useCustomToast()
    const [componentBrands, setComponentBrands] = useState([])

    const {
        refetch: refetchProviderBrands,
        isFetching: isLoadingProviderBrands,
    } = useQuery({
        queryKey: [providerId, status],
        queryFn: () => getBrandNamesApi(providerId, status),
        retry: false,
        enabled: !!providerId,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        onSuccess: res => {
            const data = res.data

            if (data?.data) {
                const dataMap = {}

                for (const entry of data.data) {
                    if (entry.component_type?.name) {
                        const key = entry.component_type?.name
                        if (!dataMap[key]) {
                            dataMap[key] = {
                                componentType: entry.component_type,
                                componentBrands: [],
                            }
                        }

                        dataMap[key].componentBrands.push(entry)
                    }
                }

                const componentBrandsArr = Object.values(dataMap)
                    .sort((dataA, dataB) =>
                        dataA.componentType?.name.localeCompare(
                            dataB.componentType?.name,
                        ),
                    )
                    .map(entry => entry)

                setComponentBrands(componentBrandsArr)
            }
        },
        onError: err =>
            errorAlert(
                errorHandler(err?.response?.data) || 'Something went wrong!',
            ),
    })

    return {
        componentBrands,
        refetchProviderBrands,
        isLoadingProviderBrands,
    }
}
