import { mapOutrightSaleOOfferStatusAndTitle } from 'src/pages/Admin/OutrightSale/Listings/data'
import { mapOfferStatusAndTitle } from 'src/utils/Data/status'

export const orderedStatusBreakdownForAdmins = (status, consumerType, plan) => {
    const isCurrent = itemStatus => itemStatus === status
    let items =
        plan === 'Outright Sale'
            ? mapOutrightSaleOOfferStatusAndTitle()
            : mapOfferStatusAndTitle()

    if (consumerType === 'DTC') {
        items = items.filter(item => item.status !== 'IN_PROGRESS')
    }
    const findInsertionIndex = (items, conditionStatus) => {
        const index = items.findIndex(item => item.status === conditionStatus)
        return index === -1 ? items.length : index + 1
    }

    const insertItemAtCorrectPosition = (
        currentStatus,
        conditionStatus,
        items,
    ) => {
        if (currentStatus === 'ARCHIVED' || currentStatus === 'DECLINED') {
            const index = findInsertionIndex(items, 'AWAITING_DECISION')
            const newItem = {
                status: currentStatus,
                isCompleted: false,
                isCurrent: true,
            }
            items.splice(index, 0, newItem)
            return items.slice(0, index + 1)
        }

        // key's item goes after value's item
        const statusToInsertAfterMap = {
            AWAITING_RE_ESTIMATION: 'IN_PROGRESS',
            AWAITING_UPDATE: 'KYC_IN_PROGRESS',
            CREDIT_DECISION_MAYBE: 'AWAITING_DECISION',
            AWAITING_APPROVAL: 'AWAITING_DECISION',
        }

        const conditionStatusToUse = statusToInsertAfterMap[currentStatus]
        if (conditionStatusToUse) {
            const index = findInsertionIndex(items, conditionStatusToUse)
            const newItem = {
                status: currentStatus,
                isCompleted: false,
                isCurrent: true,
            }
            items.splice(index, 0, newItem)
        }

        return items
    }

    // exclude specific statuses from the initial list
    items = items.filter(
        item =>
            ![
                'AWAITING_RE_ESTIMATION',
                'AWAITING_UPDATE',
                'AWAITING_APPROVAL',
                'CREDIT_DECISION_MAYBE',
                'DECLINED',
                'ARCHIVED',
            ].includes(item.status),
    )
    // insert statuses at the correct position
    items = insertItemAtCorrectPosition(status, null, items)

    // mark items as completed or current
    let currentFound = false
    return items.map(item => {
        if (currentFound) {
            item.isCompleted = false
        } else {
            if (isCurrent(item.status)) {
                item.isCompleted = false
                currentFound = true
            } else {
                item.isCompleted = true
            }
        }
        item.isCurrent = isCurrent(item.status)
        return item
    })
}
