import { InputFields, SelectField } from 'src/components/InputFields'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { meansOfIdentityV2 } from 'src/utils/Data/meansOfIdentity'
import {
    DriversLicenseRegex,
    InternationalPassportRegex,
    VirtualNINRegex,
    VotersCardRegex,
} from 'src/utils/validationSchema'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { ReactComponent as WarningIconSmall } from 'src/assets/images/warning-icon-small.svg'
import OverrideCheckModal from '../../../../_components/OverrideCheckModal'
import IdCardDataSection from '../../../../IdentityInformation/components/IdCardDataSection'

const IdCard = ({
    inputs,
    errors,
    handleChange,
    handleSelectChange,
    handleOnBlur,
    handleSelectOnBlur,
    isAdminUser,
    isCustomerApproved,
    showIdOverrideModal,
    toggleIdOverrideModal,
    kycStatus,
    idVerificationInProgress,
    openIdVerifyModal,
}) => {
    const { isMobile } = useMediaQueries()
    const identityInfoCompleted =
        kycStatus?.identity_information_completion_status

    return (
        <>
            <Label
                title="Add your ID card"
                subTitle="To verify your identity, please add your ID card number."
                showHelpIcon
                tooltipText={`To enhance your security, we'll use your submitted ID details for identity verification purposes only.`}
            />
            <SelectField
                floatLabelOnSelectedValue
                floatingLabel="ID card type"
                name="identityType"
                initialOption="ID card type"
                selectWidth="100%"
                marginBottom="24px"
                values={meansOfIdentityV2.map(option => ({
                    value: option,
                }))}
                disabled={
                    inputs?.identityVerified ||
                    isAdminUser ||
                    isCustomerApproved
                }
                value={inputs.identityType}
                currentSelected={inputs.identityType}
                handleChange={handleSelectChange}
                onBlur={() => handleSelectOnBlur('identityType')}
                withCheckBox
                errorMessage={errors?.identityType}
            />
            <div className="VerifyInputWrapper">
                <InputFields
                    title="ID card number"
                    name="identityNumber"
                    value={inputs.identityNumber}
                    handleChange={handleChange}
                    type={
                        inputs?.identityType ===
                        'National Identification Number'
                            ? 'number'
                            : 'text'
                    }
                    onBlur={handleOnBlur}
                    marginBottom={'8px'}
                    errorMessage={errors?.identityNumber}
                    disabled={
                        inputs?.identityVerified ||
                        isAdminUser ||
                        isCustomerApproved ||
                        idVerificationInProgress
                    }
                    verified={inputs?.identityVerified}
                    inprogress={idVerificationInProgress}
                />
                {idVerificationInProgress && (
                    <span className="verificationInProgressStyle">
                        Verification In Progress
                    </span>
                )}
                {inputs?.identityVerified !== true &&
                    !isAdminUser &&
                    !idVerificationInProgress && (
                        <div className="VerifyCTA">
                            <button
                                onClick={openIdVerifyModal}
                                disabled={
                                    inputs?.identityType?.length === 0 ||
                                    (inputs?.identityType ===
                                        'Drivers License' &&
                                        DriversLicenseRegex.test(
                                            inputs?.identityNumber,
                                        ) === false) ||
                                    (inputs?.identityType ===
                                        'Virtual National Identification Number' &&
                                        VirtualNINRegex.test(
                                            inputs?.identityNumber,
                                        ) === false) ||
                                    (inputs?.identityType ===
                                        'National Identification Number' &&
                                        inputs?.identityNumber?.length !==
                                            11) ||
                                    (inputs?.identityType === 'Voters Card' &&
                                        VotersCardRegex.test(
                                            inputs?.identityNumber,
                                        ) === false) ||
                                    (inputs?.identityType ===
                                        'International Passport' &&
                                        InternationalPassportRegex.test(
                                            inputs?.identityNumber,
                                        ) === false) ||
                                    isAdminUser ||
                                    isCustomerApproved
                                }
                                style={{ width: '121px' }}
                            >
                                {isMobile ? 'Verify' : 'Verify your Identity'}
                            </button>
                        </div>
                    )}
            </div>
            {inputs.identityType === 'Virtual National Identification Number' &&
                inputs?.identityVerified !== true && (
                    <div className="vNINWrapper">
                        <WarningIconSmall />
                        <p>
                            Use our Sort Code <strong>715461</strong> to
                            generate your Virtual NIN on the NIMC app or dial
                            <strong>*346*3*Your NIN*715461#</strong> to generate
                            via USSD
                        </p>
                    </div>
                )}
            <OverrideCheckModal
                showOverrideModal={showIdOverrideModal}
                type="ID"
                onCancel={toggleIdOverrideModal}
            />
            {isAdminUser && identityInfoCompleted && (
                <IdCardDataSection
                    title="ID CARD"
                    handleOverride={toggleIdOverrideModal}
                />
            )}
        </>
    )
}

IdCard.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default IdCard
