import { ReactComponent as MailIllustration } from 'src/assets/images/mail-illustration.svg'
import '../gettingstarted.scss'
const EmailSent = () => {
    return (
        <div
            className={'GettingStartedEmailSentWrapper'}
            data-testid="email-sent-wrapper"
        >
            <MailIllustration
                className={'GettingStartedEmailSentImg'}
                data-testid="mail-illustration"
            />
            <h2 data-testid="saved-progress-heading">
                That&apos;s fine! We have saved your progress
            </h2>
            <p data-testid="saved-progress-description">
                Your progress has been saved! You can come back to continue to
                order the system once you have the required information
                available. We have sent you and email on how you can continue
                once you’re ready!
            </p>
        </div>
    )
}

export default EmailSent
