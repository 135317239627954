import PropTypes from 'prop-types'
import Button from '../../../../../components/Button'
import styles from './modal.module.scss'
import SecondaryModal from '../../../../../components/Modal/SecondaryModal'
import { currencyFormatter } from '../../../../../utils/currencyFormatter'

const CapexReplacementModal = ({ showModal, closeModal, item }) => {
    const { Wrapper, Title, Name, Schedules, TableHead, RowItem, TableBody } =
        styles
    return (
        <>
            <SecondaryModal
                showModal={showModal}
                onCancel={closeModal}
                closable
                modalWidth="624px"
                modalHeight="550px"
                content={
                    <div className={Wrapper}>
                        <h4 className={Title}>CAPEX REPLACEMENT</h4>
                        <h3 className={Name}>{item?.component_name}</h3>
                        <h5 className={Schedules}>
                            {item?.capex_replacement_schedule?.length} Schedules
                        </h5>
                        <div>
                            <div className={TableHead}>
                                <p>Period</p>
                                <p>Unit Cost</p>
                            </div>
                            <div className={TableBody}>
                                {item?.capex_replacement_schedule?.map(
                                    (each, i) => (
                                        <div key={i} className={RowItem}>
                                            <p>Month {each?.month}</p>
                                            {/*TODO: include date of replacement when data is available*/}
                                            {/*<p>{item.date}</p>*/}
                                            <p>
                                                ₦ {currencyFormatter(each.cost)}
                                            </p>
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                btnWidth="160px"
                                btnHeight="56px"
                                btnBgColor="#004AAD"
                                btnTextColor="#FFFFFF"
                                fontSize="14px"
                                handleClick={() => closeModal()}
                            >
                                Okay, Got it
                            </Button>
                        </div>
                    </div>
                }
            />
        </>
    )
}

CapexReplacementModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
}

export default CapexReplacementModal
