import axios from '../../config/axios'

export const registerApi = async (type, inputs) => {
  if (type === 'phone') {
    const request = await axios.post('/auth/providers/phone-signup', inputs)
    return request
  } else {
    const request = await axios.post('/auth/providers/email-signup', inputs)
    return request
  }
}

export const registerCompleteApi = async inputs => {
  const request = await axios.post('/auth/providers/complete-signup', inputs)
  return request
}
