import axios from '../../config/axios'

export const getRepaymentPlansApi = async id => {
    const request = await axios.get(`/estimations/mini/${id}/repayment-plans`)
    return request
}

export const createRepaymentPlansApi = async (id, repaymentPlans) => {
    const request = await axios.post(
        `/estimations/mini/${id}/repayment-plans`,
        repaymentPlans,
    )
    return request
}

export const getEstimationByIdApi = async id => {
    const request = await axios.get(`/estimations/${id}`)
    return request
}

export const paymentPlanFeedbackApi = async (id, payload) => {
    const request = await axios.post(
        `/estimations/mini/${id}/payment-plan/feedback`,
        payload,
    )
    return request
}
