import { useState } from 'react'
import PropTypes from 'prop-types'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { InlineLoader } from 'src/components/Loader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { promoBanners } from 'src/pages/Consumer/SmartShoppingExperience/Home/PromoCarousel/data'
import fallbackImage from 'src/assets/images/fallback.svg'
import s from './styles.module.scss'
import Toast from 'src/components/Toast'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'

const SharePackageModal = ({
    showModal,
    onCancel,
    sharePackageLink,
    shortenLinkLoading,
    isPromoPage,
    promoType,
    systemDetails,
    formatPaymentModel,
    paymentModel,
    repaymentPlans,
}) => {
    const [toastMessage, setToastMessage] = useState('')
    const { isMobile } = useMediaQueries()

    const handleCopyLink = () => {
        navigator.clipboard.writeText(sharePackageLink)
        setToastMessage('The link to this package has been copied')
        setTimeout(() => {
            setToastMessage('')
        }, [2000])
    }

    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={onCancel}
            mobileHeight={'60%'}
            standardHeight={525}
            standardWidth={475}
            title={'Share this package'}
            primaryBtnText={'Copy this link'}
            primaryBtnWidth={isMobile ? '120px' : '160px'}
            primaryBtnHeight={isMobile ? '45px' : '53px'}
            handlePrimaryBtnClick={handleCopyLink}
            primaryBtnDisabled={shortenLinkLoading}
            dataTestId="share-package-modal"
            primaryBtnDataTestId={'share-package-modal-primary-btn'}
        >
            <div className={s.packageContent}>
                {toastMessage !== '' && (
                    <Toast
                        messageType={'success-secondary'}
                        message={toastMessage}
                        closable
                    />
                )}
                <div className={s.details}>
                    <div className={s.packageImg}>
                        <LazyLoadImage
                            src={
                                isPromoPage && promoType !== undefined
                                    ? promoBanners[promoType]
                                    : systemDetails?.has_solar_panel
                                    ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+Banner.png'
                                    : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Without+panels+Banner.png'
                            }
                            alt="image of the system"
                            placeholderSrc={fallbackImage}
                        />
                    </div>
                    <div className={s.detailsText}>
                        <h4>
                            <span>{systemDetails?.name}</span>
                            <span>
                                {systemDetails?.energy_capacity?.value +
                                    systemDetails?.energy_capacity
                                        ?.unit_of_measurement}{' '}
                                system
                            </span>
                        </h4>
                        <div className={s.detailsTags}>
                            <div>
                                <p>
                                    {isPromoPage
                                        ? formatPaymentModel[
                                              paymentModel
                                          ]?.toUpperCase()
                                        : systemDetails?.payment_model?.toUpperCase()}
                                </p>
                            </div>
                            {systemDetails?.payment_model?.toLowerCase() !==
                                'subscription' && (
                                <div>
                                    <p>{`${
                                        isPromoPage
                                            ? repaymentPlans?.length
                                            : systemDetails?.payment_plans
                                                  ?.length
                                    } PLANS`}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={s.linkEl}>
                    {shortenLinkLoading ? (
                        <div>
                            <InlineLoader color="primary" />
                        </div>
                    ) : (
                        <p data-testid="share-package-modal-share-link">
                            {sharePackageLink}
                        </p>
                    )}
                </div>
            </div>
        </ConsumerModalBase>
    )
}

SharePackageModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    sharePackageLink: PropTypes.string,
    shortenLinkLoading: PropTypes.bool,
    isPromoPage: PropTypes.bool,
    promoType: PropTypes.string,
    systemDetails: PropTypes.object,
    formatPaymentModel: PropTypes.object,
    paymentModel: PropTypes.string,
    repaymentPlans: PropTypes.array,
}

export default SharePackageModal
