const descriptionStepMapping = {
    personal_information: 'basic-info',
    employment_information: 'basic-info',
}
export const descriptionStep = (moduleKey = '') => {
    if (Object.keys(descriptionStepMapping).find(key => key === moduleKey)) {
        return descriptionStepMapping[
            Object.keys(descriptionStepMapping).find(key => key === moduleKey)
        ]
    } else {
        return ''
    }
}

export const formatOfferData = data => {
    let values = {
        offerId: data?.id,
        userType: data?.customer_type,
        isCustomerApproved: data?.approval_status === 'APPROVED',
    }

    return values
}

export const actionModalValues = action => {
    switch (action) {
        case 'save-progress':
            return {
                actionModalTitle: 'Save your progress',
                actionModalHeaderText: 'Save your progress? Please confirm',
                actionModalSubtitle:
                    'You can save your progress and come back to continue anytime. We’ll also send an email on how you can continue.',
                actionModalActionType: 'warning',
                actionModalPrimaryBtnText: 'Confirm',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
        case 'progress-saved':
            return {
                actionModalTitle: 'Redirecting to home page',
                actionModalHeaderText: 'Progress Saved, check your mail',
                actionModalSubtitle:
                    "Progress saved! We've emailed you instructions to resume when you have the required information.",
                actionModalActionType: 'countdown-timer',
                actionModalPrimaryBtnText: 'Proceed to Log Out',
                actionModalFooterLeftContent: 'button',
                actionModalSecondaryBtnText: 'Continue KYC',
            }
        default:
            return {
                actionModalTitle: '',
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalPrimaryBtnText: '',
                actionModalFooterLeftContent: 'chat',
                actionModalSecondaryBtnText: '',
            }
    }
}

export const registeredBusinessOptions = [
    {
        title: 'Yes, it is',
        value: true,
    },
    {
        title: `No, it's not`,
        value: false,
    },
]

export const getRequiredKeys = data => {
    let requiredKeys = []
    if (data && data.modules && Array.isArray(data.modules)) {
        data.modules.forEach(module => {
            if (module.section && Array.isArray(module.section)) {
                const keys = module.section
                    .filter(section => section.required)
                    .map(section => section.key)
                requiredKeys = requiredKeys.concat(keys)
            }
        })
    }

    return requiredKeys
}
