import 'src/pages/EstimationsStepOne/estimations-stepone.scss'
import PropTypes from 'prop-types'
import { numberWithCommas } from 'src/utils/formatting'
import HalfMoonIcon from 'src/assets/images/half-moon-icon.svg'
import SunIcon from 'src/assets/images/sunlight.svg'
import Button from 'src/components/Button'
import { ReactComponent as PullEnergyIcon } from 'src/assets/images/pull-energy-icon.svg'
import styles from './card.module.scss'

const EnergyNeedsCard = ({
    energyNeeds,
    totalWattsAdded,
    totalDayUsage,
    totalNightUsage,
    averageDailyUsage,
    section,
    actionText = 'Edit',
    openModal,
    cardBorderWithNoShadow,
    canPullEnergyNeeds,
    handlePullEnergyNeedsClick,
    cardButtonDisabeld,
}) => {
    return (
        <div
            className={`SummaryWrapper EnergyNeeds_SummaryWrapper ${
                cardBorderWithNoShadow ? 'borderWithNoShadow' : ''
            } ${canPullEnergyNeeds ? styles.CardContainer : ''}`}
        >
            <section className="SectionOneWrapper">
                <p
                    className="
        ConsumerEnergyNeedsStyle
    "
                >
                    {section} ENERGY NEEDS
                </p>
                <p
                    className="
        ApplianceAddedStyle
    "
                >
                    <span
                        className="
            ApplianceSpanStyle
        "
                    >
                        {energyNeeds?.length ?? 0}
                    </span>
                    Appliance(s) added
                </p>
            </section>
            <section className="SectionTwoWrapper EnergyNeeds_SectionTwoWrapper">
                <div className="TotalAddedStyle">
                    <p>
                        Total watts added <br />{' '}
                        <span
                            className="
                SectionTwoSpanStyle
            "
                        >
                            {totalWattsAdded
                                ? numberWithCommas(
                                      parseFloat(totalWattsAdded, 10).toFixed(
                                          2,
                                      ),
                                  )
                                : 0}
                        </span>
                        <sup>watts</sup>
                    </p>
                </div>
                <div>
                    <p
                        className="
            TotalUsageStyle
        "
                    >
                        <img src={SunIcon} alt="a sunlight icon" />
                        <span
                            className="
                TotalUsageSpanOne
            "
                        >
                            Total day usage
                        </span>
                        <span
                            className="
                TotalUsageSpan
            "
                        >
                            {totalDayUsage
                                ? numberWithCommas(
                                      parseFloat(totalDayUsage, 10).toFixed(2),
                                  )
                                : 0}{' '}
                            wh
                        </span>
                    </p>
                    <p
                        className="
            TotalUsageStyle
        "
                    >
                        <img src={HalfMoonIcon} alt="a half moon icon" />
                        <span
                            className="
                TotalUsageSpanOne
            "
                        >
                            Total night usage
                        </span>
                        <span
                            className="
                TotalUsageSpan
            "
                        >
                            {totalNightUsage
                                ? numberWithCommas(
                                      parseFloat(totalNightUsage, 10).toFixed(
                                          2,
                                      ),
                                  )
                                : 0}{' '}
                            wh
                        </span>
                    </p>
                </div>
            </section>
            <hr className="HRStyle" />
            <section className="SectionThreeWrapper">
                <Button
                    btnBgColor="#E2EEFF"
                    btnTextColor="var(--blue)"
                    btnWidth="216.33px"
                    btnHeight="44px"
                    handleClick={() => openModal()}
                    disabled={cardButtonDisabeld}
                >
                    {actionText} Appliances
                </Button>
                <div
                    className="
        AverageUsageStyle
    "
                >
                    <p
                        className="
            AverageUsageSpanOne
        "
                    >
                        Daily Energy Consumption
                    </p>
                    <span
                        className="
            AverageUsageSpanTwo
        "
                    >
                        {averageDailyUsage
                            ? numberWithCommas(
                                  parseFloat(averageDailyUsage, 10).toFixed(2),
                              )
                            : 0}{' '}
                        wh
                    </span>
                </div>
            </section>
            {canPullEnergyNeeds && (
                <section className={styles.PullEnergyNeedsSection}>
                    <button onClick={handlePullEnergyNeedsClick}>
                        <PullEnergyIcon />
                        <p>Pull energy needs from a profile package</p>
                    </button>
                </section>
            )}
        </div>
    )
}

EnergyNeedsCard.propTypes = {
    section: PropTypes.string,
    actionText: PropTypes.string,
    energyNeeds: PropTypes.array,
    totalWattsAdded: PropTypes.number,
    totalDayUsage: PropTypes.number,
    totalNightUsage: PropTypes.number,
    averageDailyUsage: PropTypes.number,
    handleClick: PropTypes.func,
    openModal: PropTypes.func,
    cardBorderWithNoShadow: PropTypes.bool,
    canPullEnergyNeeds: PropTypes.bool,
    handlePullEnergyNeedsClick: PropTypes.func,
    cardButtonDisabeld: PropTypes.bool,
}

export default EnergyNeedsCard
