import PropTypes from 'prop-types'

const CircularProgress = ({
    trailColor,
    pathColor,
    innerCircleColor,
    percentage,
}) => {
    const componentSize = 20
    const stroke = 2.91
    const radius = componentSize / 2 - stroke / 2
    const circumference = radius * 2 * Math.PI

    // size for the inner circle
    const innerCircleSize = 10

    // center of the SVG
    const centerPosition = componentSize / 2

    // adjusting the strokeDashoffset for counter-clockwise reduction
    // strokeDashoffset needs to start from 0 and increase to move counter-clockwise
    const strokeDashoffset = (1 - percentage / 100) * circumference

    return (
        <svg
            height={componentSize}
            width={componentSize}
            viewBox={`0 0 ${componentSize} ${componentSize}`}
            data-testid="circular-progress"
        >
            {/* trail circle - background */}
            <circle
                stroke={trailColor}
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={circumference + ' ' + circumference}
                r={radius}
                cx={centerPosition}
                cy={centerPosition}
            />
            {/* path circle - progress bar */}
            <circle
                stroke={pathColor}
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={circumference + ' ' + circumference}
                style={{ strokeDashoffset }}
                r={radius}
                cx={centerPosition}
                cy={centerPosition}
                transform={`rotate(-90 ${centerPosition} ${centerPosition})`}
            />
            {/* white ring (larger circle behind the colored circle) */}
            <circle
                fill="white"
                r={(innerCircleSize + stroke) / 2}
                cx={centerPosition}
                cy={centerPosition}
            />
            {/* inner colored circle */}
            <circle
                fill={innerCircleColor || pathColor}
                r={innerCircleSize / 2}
                cx={centerPosition}
                cy={centerPosition}
            />
        </svg>
    )
}

CircularProgress.propTypes = {
    trailColor: PropTypes.string,
    pathColor: PropTypes.string,
    percentage: PropTypes.number,
    innerCircleColor: PropTypes.string,
}

export default CircularProgress
