/* eslint-disable react/prop-types */
import GetModal from '../Modal'
import { LeftOutlined } from '@ant-design/icons'
import styles from './actionmodal.module.scss'
import Button from '../Button'
import { InlineLoader } from '../Loader'
import { ReactComponent as DeleteIcon } from '../../assets/images/deletemodal-icon.svg'
import { ReactComponent as SuccessIcon } from '../../assets/images/successImage.svg'
import { ReactComponent as WarningIcon } from '../../assets/images/warning-icon.svg'
import { ReactComponent as ConfirmActionIcon } from '../../assets/images/confirm-action.svg'
import { ReactComponent as MessageIcon } from '../../assets/images/messagemodal-icon.svg'
import Toast from '../Toast'
import Checkbox from '../Checkbox'
import { ReactComponent as CloseIcon } from 'src/assets/images/close-icon-no-radius-large.svg'

const {
    ActionHeader,
    ActionContainer,
    ActionText,
    ActionBox,
    MobileBackLink,
    BackLinkIcon,
    ActionCheckboxWrapper,
    mobileActionModalStyle,
    closeIconStyles,
} = styles

const ActionModal = ({
    actionModalOpen,
    headerText,
    subTitle,
    closeModal,
    actionHandler,
    actionText,
    actionType,
    actionHeaderClassName,
    loading,
    closable,
    cancelText,
    noCancelBtn,
    noPrimaryBtn,
    onCancel,
    noBackLink,
    disableActionButton,
    errorMessage,
    toastError,
    showCheckBox,
    checked,
    handleChecked,
    primaryBtnWidth,
    cancelButtonOutlined,
    cancelButtonWidth,
    modalBorderRadius,
    checkBoxText,
    mobileStyle,
}) => {
    const iconType = () => {
        if (actionType === 'delete') {
            return <DeleteIcon />
        }
        if (actionType === 'success') {
            return <SuccessIcon />
        }
        if (actionType === 'warning') {
            return <WarningIcon />
        }
        if (actionType === 'confirm-action') {
            return <ConfirmActionIcon />
        }
        if (actionType === 'message') {
            return <MessageIcon />
        }
    }
    return (
        <GetModal
            app="workspace"
            showModal={actionModalOpen}
            closable={closable ? true : false}
            onCancel={onCancel ? onCancel : () => {}}
            modalWidth="459px"
            modalBorderRadius={modalBorderRadius}
            closeIcon={<CloseIcon className={closeIconStyles} />}
            content={
                <>
                    {toastError && (
                        <Toast
                            messageType="error"
                            message={errorMessage?.[0]}
                        />
                    )}
                    {noBackLink ? (
                        ''
                    ) : (
                        <div className={MobileBackLink}>
                            <LeftOutlined className={BackLinkIcon} />
                            <a href="/app/products">Back to products</a>
                        </div>
                    )}
                    <div
                        className={`${ActionContainer}  ${
                            mobileStyle && mobileActionModalStyle
                        }`}
                    >
                        {iconType()}
                        <div
                            className={
                                actionHeaderClassName
                                    ? actionHeaderClassName
                                    : ActionHeader
                            }
                        >
                            {headerText}
                        </div>
                        <div className={ActionText}>{subTitle}</div>
                        {showCheckBox && (
                            <div className={ActionCheckboxWrapper}>
                                <Checkbox
                                    page="SSE"
                                    isChecked={checked}
                                    handleChecked={handleChecked}
                                >
                                    {checkBoxText ??
                                        'Notify consumer to make payment plan selection from the new update'}
                                </Checkbox>
                            </div>
                        )}
                        <br />
                        <div
                            className={ActionBox}
                            style={{
                                justifyContent: noCancelBtn ? 'center' : '',
                            }}
                        >
                            {!loading && (
                                <span
                                    style={{
                                        display: noCancelBtn ? 'none' : '',
                                    }}
                                >
                                    <Button
                                        btnBgColor={
                                            cancelButtonOutlined
                                                ? null
                                                : 'var(--purple-light)'
                                        }
                                        btnTextColor="var(--blue)"
                                        btnTextColorOutline="var(--blue)"
                                        btnOutlineColor="var(--purple-light)"
                                        btnBgColorOutline={
                                            cancelButtonOutlined
                                                ? 'rgba(249, 252, 255, 1)'
                                                : '#E2EEFF'
                                        }
                                        border={
                                            cancelButtonOutlined
                                                ? '0.5px solid #004AAD'
                                                : null
                                        }
                                        type="outline-no-shadow"
                                        btnWidth={cancelButtonWidth ?? '150px'}
                                        handleClick={closeModal}
                                        disabled={loading ? true : false}
                                        dataTestId={'action-modal-close-btn'}
                                    >
                                        {cancelText ? cancelText : 'Cancel'}
                                    </Button>
                                </span>
                            )}
                            &nbsp;&nbsp;&nbsp;
                            <span
                                style={{
                                    display: noPrimaryBtn ? 'none' : '',
                                    width: '100%',
                                }}
                            >
                                <Button
                                    btnBgColor="var(--blue)"
                                    btnTextColor="var(--white)"
                                    btnWidth={
                                        loading
                                            ? '100%'
                                            : primaryBtnWidth
                                            ? primaryBtnWidth
                                            : '150px'
                                    }
                                    btnPadding={'0 16px'}
                                    handleClick={actionHandler}
                                    disabled={
                                        loading || disableActionButton
                                            ? true
                                            : false
                                    }
                                    noFocus
                                >
                                    {loading ? <InlineLoader /> : actionText}
                                </Button>
                            </span>
                        </div>
                    </div>
                </>
            }
        />
    )
}

export default ActionModal
