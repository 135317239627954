import { useEffect } from 'react'
import styles from './welcome.module.scss'
import ChatCTA from 'src/components/ChatCTA'
import { pageTitle, pageTracker, signInOptions } from './data'
import { ReactComponent as Arrow } from 'src/assets/images/blue-circle-right.svg'
import SolarCityImg from 'src/assets/images/solar-city-bg.svg'
import { removeToken } from 'src/utils/auth'
import SeoComponent from 'src/components/Seo'
import useWelcome from './hook'

const Welcome = () => {
    const { handleSignIn } = useWelcome()
    const { page, left, right, signInOption } = styles

    useEffect(() => {
        removeToken()
    }, [])

    return (
        <>
            <SeoComponent title={pageTitle} tracker={pageTracker} />

            <div className={page}>
                <section className={left}>
                    <h1>Welcome back to SunFi</h1>
                    <p>
                        We’ve solved the problems that make it difficult for
                        energy provide to transition millions.
                    </p>
                    <img src={SolarCityImg} alt="solar city illustration" />
                    <ChatCTA
                        textOneColor="#FFFFFF"
                        textTwoColor="#FFFFFF"
                        margin="0 auto 62px auto"
                        justifyContent="center"
                    />
                </section>
                <section className={right}>
                    <h2>Jump right back in</h2>
                    <p>Get started by selecting your account type</p>
                    {signInOptions?.map((each, i) => (
                        <div
                            key={i}
                            role="button"
                            data-testid={`sign-in-button-${i}`}
                            className={signInOption}
                            onClick={() => handleSignIn(each, i)}
                        >
                            <div>
                                <h4>{each?.title}</h4>
                                <p>{each?.description}</p>
                            </div>
                            <Arrow role="presentation" />
                        </div>
                    ))}
                </section>
            </div>
        </>
    )
}

export default Welcome
