/* eslint-disable no-undef */
import ReactGA from 'react-ga4'

export const initGA = (
    id,
    type,
    pageUrl,
    pageTracker,
    pageTitle,
    eventAction,
    eventLabel,
    eventCategory,
) => {
    if (
        ['production', 'sandbox', 'staging'].includes(
            process.env.REACT_APP_NODE_ENV,
        )
    ) {
        ReactGA.initialize(id, {
            testMode: process.env.REACT_APP_NODE_ENV === 'staging',
        })
        if (type === 'event') {
            ReactGA.event(
                {
                    category: eventCategory,
                    action: eventAction,
                    label: eventLabel,
                },
                pageTracker,
            )
        }
        if (type === 'pageview') {
            ReactGA.send({
                hitType: 'pageview',
                page: pageUrl,
                pageTracker,
                pageTitle,
            })
        }
    }
}
