import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { fetchCardDetailsApi } from 'src/api/consumer/payment'
import Toast from 'src/components/Toast'
import { errorHandler } from 'src/utils/errorHandler'
import { ConsumerButton } from 'src/components/Button'
import plusicon from 'src/assets/images/plus-icon.svg'
import mastercardicon from 'src/assets/images/mastercard.svg'
import visacardicon from 'src/assets/images/visa-card-logo.svg'
import vervecardicon from 'src/assets/images/verve.svg'
import arrowright from 'src/assets/images/caret-circle-right.svg'
import arrowrightblue from 'src/assets/images/caret-circle-right-blue.svg'
import { RecommendedSkeletalCards } from 'src/components/SkeletalCards'
import addcreditcardicon from 'src/assets/images/add-credit-card.svg'

import styles from './carddetailskyc.module.scss'

const CardDetailsKyc = ({
    estimationId,
    selectedCard,
    setSelectedCard,
    handleShowInstruction,
}) => {
    const {
        PlusIconStyle,
        PlusTextStyle,
        CardStyle,
        CardIconWrapper,
        CardNumberStyle,
        CTATextStyle,
        CardDetailsStyle,
        AddButtonStyleV2,
    } = styles
    const [cardDetails, setCardDetails] = useState({
        bin: '',
        last4: '',
        card_type: ' ',
        brand: '',
    })
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [cardMatch, setCardMatch] = useState(false)

    const { isFetching: cardDetailsFetching, refetch: cardDetailsRefetch } =
        useQuery(
            'fetch-card-details',
            () => fetchCardDetailsApi(estimationId),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setCardDetails(data?.data?.data)
                    setCardMatch(
                        data?.data?.data?.card_and_statement_match_data?.match,
                    )
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    useEffect(() => {
        cardDetailsRefetch()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimationId])

    const selectCard = () => {
        setSelectedCard(true)
    }

    const NoCardDetails = Object.keys(cardDetails)?.length === 0 || !cardMatch

    return (
        <>
            <div>
                {toastError && (
                    <Toast messageType="error" message={errorMessage} />
                )}

                <>
                    {cardDetailsFetching ? (
                        <RecommendedSkeletalCards total={1} />
                    ) : (
                        <>
                            <section
                                className={CardStyle}
                                onClick={() => {
                                    NoCardDetails
                                        ? handleShowInstruction()
                                        : selectCard()
                                }}
                                style={{
                                    borderColor: selectedCard && '#004AAD',
                                    background: selectedCard && '#E2EEFF',
                                }}
                            >
                                <div className={CardDetailsStyle}>
                                    <div
                                        className={CardIconWrapper}
                                        style={{
                                            background: NoCardDetails
                                                ? '#fff'
                                                : selectedCard
                                                ? cardDetails?.brand?.includes(
                                                      'master',
                                                  )
                                                    ? '#004AAD'
                                                    : '#E2EEFF'
                                                : '#FFFFFF',
                                            border:
                                                NoCardDetails &&
                                                selectedCard &&
                                                '0.5px solid rgba(0, 74, 173, 0.79)',
                                        }}
                                    >
                                        <img
                                            src={
                                                NoCardDetails
                                                    ? addcreditcardicon
                                                    : cardDetails?.brand?.includes(
                                                          'visa',
                                                      )
                                                    ? visacardicon
                                                    : cardDetails?.brand?.includes(
                                                          'verve',
                                                      )
                                                    ? vervecardicon
                                                    : cardDetails?.brand?.includes(
                                                          'master',
                                                      ) && mastercardicon
                                            }
                                            alt="card type icon"
                                        />
                                    </div>
                                    <div>
                                        <p className={CardNumberStyle}>
                                            {NoCardDetails ? (
                                                'Add a new card'
                                            ) : (
                                                <>
                                                    {cardDetails?.bin?.substring(
                                                        0,
                                                        4,
                                                    )}
                                                    <sup>**** **** ****</sup>
                                                    {cardDetails?.last4}
                                                </>
                                            )}
                                        </p>
                                        <p className={CTATextStyle}>
                                            {NoCardDetails
                                                ? 'Click to add a new card here'
                                                : 'Click to pay with the card'}
                                        </p>
                                    </div>
                                </div>
                                {NoCardDetails ? (
                                    <img
                                        src={plusicon}
                                        className={PlusIconStyle}
                                        alt="plus icon"
                                        style={{ marginRight: '25px' }}
                                    />
                                ) : selectedCard ? (
                                    <img
                                        src={arrowrightblue}
                                        alt="right arrow icon"
                                        style={{ margin: '22px' }}
                                    />
                                ) : (
                                    <img
                                        src={arrowright}
                                        alt="right arrow icon"
                                        style={{ margin: '22px' }}
                                    />
                                )}
                            </section>
                            {!NoCardDetails && (
                                <div className={AddButtonStyleV2}>
                                    <ConsumerButton
                                        btnBgColor="#E2EEFF"
                                        btnWidth="131px"
                                        btnHeight="40px"
                                        btnTextColor="#004AAD"
                                        handleClick={handleShowInstruction}
                                    >
                                        <img
                                            src={plusicon}
                                            className={PlusIconStyle}
                                            alt="plus icon"
                                        />
                                        <span className={PlusTextStyle}>
                                            Use New Card
                                        </span>
                                    </ConsumerButton>
                                </div>
                            )}
                        </>
                    )}
                </>
            </div>
        </>
    )
}

CardDetailsKyc.propTypes = {
    estimationId: PropTypes.string,
    email: PropTypes.string,
    setVerifyLoading: PropTypes.func,
    selectedCard: PropTypes.bool,
    setSelectedCard: PropTypes.func,
    onSuccess: PropTypes.func,
    handleShowInstruction: PropTypes.func,
}

export default CardDetailsKyc
