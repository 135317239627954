import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { message, Upload } from 'antd'
import { ReactComponent as UploadIcon } from 'src/assets/images/upload-icon.svg'
import { ReactComponent as CloseIcon } from 'src/assets/images/consumer-close-icon.svg'
import { ReactComponent as PlusIcon } from 'src/assets/images/Plus-Blue.svg'
import UploadedImage from './UploadedImage'
import { validateFileUpload } from './utils'
import styles from './imageupload.module.scss'

const ImageUpload = ({
    successUpload,
    setSuccessUpload,
    acceptableFileTypes = { 'image/jpeg': 'jpeg', 'image/png': 'png' },
    isLoading,
    maxCount = 1,
    maxSize = 1,
    multiple = false,
    showUploadList = false,
}) => {
    const [showAdditionalLoadingCopy, setShowAdditionalLoadingCopy] =
        useState(false)
    const [uploading, setUploading] = useState(false)
    const [width, setWidth] = useState(40)
    const [showDragger, setShowDragger] = useState(false)
    const additionalCopyTimeoutRef = useRef(null)

    const maxSizeBytes = maxSize * 1024 * 1024

    const { Dragger } = Upload

    const props = {
        onChange({ file, fileList }) {
            const isValid = validateFileUpload({
                file,
                multiple,
                acceptableFileTypes,
                maxCount,
                maxSize,
                count: 0,
                availableSlotsCount: maxCount,
            })

            if (isValid) {
                if (multiple) {
                    const index = fileList.findIndex(item => {
                        if (typeof item === 'object') {
                            return item.uid === file.uid
                        }
                        return false
                    })

                    if (index > -1) {
                        setSuccessUpload(prev => {
                            const updatedUploads = [...prev, file].slice(
                                0,
                                maxCount,
                            )
                            return updatedUploads
                        })
                    }
                } else {
                    setSuccessUpload([file])
                }

                setUploading(false)
                setShowDragger(false)
            }
        },
        beforeUpload(file, fileList) {
            const index = fileList.indexOf(file)
            const remainingSlotsCount = maxCount - successUpload.length

            const isValid = validateFileUpload({
                file,
                message,
                multiple,
                acceptableFileTypes,
                maxCount,
                maxSize,
                count: index,
                availableSlotsCount: remainingSlotsCount,
            })

            if (isValid) {
                setShowDragger(false)
            }

            setUploading(false)
            return isValid
        },
        onDrop() {
            setUploading(false)
        },
    }

    useEffect(() => {
        const handleSlider = () => {
            if (uploading && width < 100) {
                setWidth(prev => prev + 20)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploading])

    useEffect(() => {
        if (isLoading) {
            additionalCopyTimeoutRef.current = setTimeout(() => {
                setShowAdditionalLoadingCopy(true)
            }, 5000)
        } else {
            setShowAdditionalLoadingCopy(false)
            if (additionalCopyTimeoutRef.current) {
                clearTimeout(additionalCopyTimeoutRef.current)
                additionalCopyTimeoutRef.current = null
            }
        }
    }, [isLoading])

    useEffect(() => {
        if (!successUpload?.length && !showDragger) {
            setShowDragger(true)
        }
    }, [successUpload, showDragger])

    return (
        <div className={styles.Container}>
            <UploadedImage
                successUpload={successUpload}
                showAdditionalLoadingCopy={showAdditionalLoadingCopy}
                setSuccessUpload={setSuccessUpload}
                setShowDragger={setShowDragger}
            />
            {showDragger && (
                <div className={styles.ImageUploadDraggerWrapper}>
                    <Dragger
                        className={`${styles.ImageUploadDragger}`}
                        fileList={successUpload}
                        maxCount={maxCount}
                        maxSize={maxSizeBytes}
                        showUploadList={showUploadList}
                        customRequest={() => {}}
                        multiple={multiple}
                        accept={Object.keys(acceptableFileTypes)?.join(',')}
                        {...props}
                    >
                        {uploading ? (
                            <div className="SlideContainer">
                                <p
                                    className="Slider"
                                    style={{
                                        width: `${width}%`,
                                    }}
                                ></p>
                            </div>
                        ) : (
                            <div className={styles.ImageUploadContent}>
                                <UploadIcon className={styles.uploadIcon} />
                                <p>
                                    Upload file. Drag and drop or{' '}
                                    <span>browse</span>
                                </p>
                                <p className={styles.fileSize}>
                                    The maximum accepted file size is {maxSize}
                                    mb
                                </p>
                                <p className={styles.fileFormat}>
                                    Supported format :{' '}
                                    {Object.values(acceptableFileTypes).join(
                                        ' & ',
                                    ) || ''}
                                </p>
                            </div>
                        )}
                    </Dragger>

                    <button
                        className={styles.CloseButton}
                        onClick={() => setShowDragger(false)}
                        disabled={!successUpload.length}
                        style={{
                            cursor: !successUpload.length
                                ? 'not-allowed'
                                : 'pointer',
                        }}
                    >
                        <CloseIcon />
                    </button>
                </div>
            )}
            {successUpload.length < maxCount && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <button
                        className={`AddNewImageButton ${
                            showDragger ? 'BtnDisabled' : ''
                        }`}
                        onClick={() => setShowDragger(true)}
                        disabled={showDragger}
                    >
                        <PlusIcon />
                        <span>Add a new image</span>
                    </button>
                </div>
            )}
        </div>
    )
}

ImageUpload.propTypes = {
    isLoading: PropTypes.bool,
    successUpload: PropTypes.array,
    setSuccessUpload: PropTypes.func,
    acceptableFileTypes: PropTypes.object,
    setFileToState: PropTypes.func,
    maxCount: PropTypes.number,
    maxSize: PropTypes.number,
    multiple: PropTypes.bool,
    showUploadList: PropTypes.bool,
    imageURL: PropTypes.string,
}

export default ImageUpload
