import PropTypes from 'prop-types'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import styles from 'src/pages/Admin/PaymentPlans/Details/components/solutionModalContent/SolutionModalContent.module.scss'
import Pill from 'src/components/Pill'

const PaymentPlansForAltRecommendations = ({ modalData }) => {
    return (
        <div
            className={styles.paymentPlans}
            style={{ margin: '0 90px 159px 90px' }}
        >
            <div className={styles.paymentPlans__header}>
                <small>INITIAL DEPOSIT</small>
                <h2>
                    {modalData?.payment_plans[0]?.currency?.symbol}{' '}
                    {currencyFormatter(
                        modalData?.payment_plans[0]?.initial_deposit,
                    )}
                </h2>
            </div>

            <ul className={styles.paymentPlans__body}>
                <h4>Available Payment Plans</h4>
                <div className={styles.paymentPlans__body__wrapper}>
                    {modalData?.payment_plans?.map((plan, index) => (
                        <li key={plan.id}>
                            <div className={styles.listTop}>
                                <div>
                                    <p>Plan {index + 1} </p>
                                    {plan?.selected && <Pill text="Provider" />}
                                    {plan?.admin_approved && (
                                        <Pill text="Customer" />
                                    )}
                                </div>
                                <small>MONTHS OF REPAYMENT</small>
                            </div>
                            <div className={styles.listBottom}>
                                <p>
                                    {plan?.currency.symbol}{' '}
                                    {currencyFormatter(plan?.monthly_payment)}
                                    <span>/ Month</span>
                                </p>
                                <small>{plan?.tenure} Months</small>
                            </div>
                        </li>
                    ))}
                </div>
            </ul>
        </div>
    )
}

PaymentPlansForAltRecommendations.propTypes = {
    modalData: PropTypes.object,
}

export default PaymentPlansForAltRecommendations
