export const CREATE_BUSINESS_ACCOUNT = {
    FINANCIAL_INFO: '/consumer/account-setup/business-financial-information',
    BUSINESS_INFO: '/consumer/account-setup/business-information',
    BUSINESS_VERIFICATION: '/consumer/account-setup/business-verification',
}

export const CREATE_RESIDENTIAL_ACCOUNT = {
    IDENTITY_INFO: '/consumer/account-setup/identity-information',
    FINANCIAL_INFO: '/consumer/account-setup/residential-financial-information',
}

export const LEARN_ROUTES = {
    learnContentOne: '/learn/we-envision-solar-on-every-roof',
    learnContentTwo:
        '/learn/a-virtual-agent-network-of-associate-installers-will-accelerate-the-transition',
    learnContentThree:
        '/learn/energy-storage-will-be-in-every-home-and-building',
    learnContentFour: '/learn/predictions-of-the-future-with-SunFi-Launch',
    learnContentFive: '/learn/payment-plans-are-the-bottleneck-of-clean-energy',
    learnContentSix: '/learn/5-reasons-why-you-should-switch-to-solar-in-2023',
    learnContentSeven:
        '/learn/five-ways-clean-energy-benefits-nigerian-businesses',
    learnContentEight:
        '/learn/solar-energy-made-simple-a-beginners-guide-to-going-solar',
    learnContentNine:
        '/learn/empower-your-customers-with-sunfis-financing-solutions',
    learnContentTen:
        '/learn/affordable-solar-energy-an-alternative-to-the-hike-in-fuel-price',
    learnContentEleven: '/learn/debunk-the-top-solar-energy-myth',
    learnContentTwelve:
        '/learn/how-solar-has-helped-adedamola-save-money-a-remote-workers-pov',
    learnContentThirteenOldUrl:
        '/learn/introducing-the-new-consumer-smart-shopping-experience-V2',
    learnContentThirteen:
        '/learn/introducing-a-simpler-way-to-shop-for-your-solar-systems',
}
