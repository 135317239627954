import { Skeleton } from 'antd'
import styles from '../paymentPlanDetails.module.scss'

export const BasicInfoLoaders = () => {
    return (
        <div className={styles.basicInfoLoader}>
            <Skeleton
                title={{ width: 100, height: 25 }}
                paragraph={{
                    rows: 1,
                    width: 120,
                }}
            />
            <Skeleton
                title={{ width: 100, height: 25 }}
                paragraph={{
                    rows: 1,
                    width: 120,
                }}
            />
            <Skeleton
                title={{ width: 100, height: 25 }}
                paragraph={{
                    rows: 1,
                    width: 120,
                }}
            />
        </div>
    )
}

export const AsideLoaders = () => {
    return (
        <div className={styles.asideLoader}>
            <Skeleton title={{ width: 100, height: 25 }} paragraph={false} />
            <div className={styles.asideLoader__body}>
                <Skeleton
                    title={{ width: 100, height: 25 }}
                    paragraph={{
                        rows: 3,
                        width: 120,
                    }}
                />
                <Skeleton
                    title={{ width: 100, height: 25 }}
                    paragraph={{
                        rows: 3,
                        width: 120,
                    }}
                />
            </div>
            <div className={styles.asideLoader__body}>
                <Skeleton
                    title={{ width: 100, height: 25 }}
                    paragraph={false}
                />
                <Skeleton
                    title={{ width: 100, height: 25 }}
                    paragraph={false}
                />
            </div>
            <Skeleton
                title={false}
                paragraph={{
                    rows: 3,
                    width: 120,
                }}
            />
        </div>
    )
}
