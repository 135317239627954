import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Checkbox from 'src/components/Checkbox'
import { IconInputField } from 'src/components/InputFields/CostField'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { isMobile } from 'src/utils/mediaQueries'
import { floatWithCommas } from 'src/utils/formatting'
import NairaSymbol from 'src/components/NairaSymbol'

const FilterRecommendations = ({
    addFilter,
    setAddFilter,
    setShowPackages,
    page,
    handleFilter = () => {},
    isLoading,
}) => {
    const history = useHistory()
    const [leastToOwnChecked, setLeaseToOwnChecked] = useState(false)
    const [rentalChecked, setRentalChecked] = useState(false)
    const [paymentModel, setPaymentModel] = useState('')
    const [planDuration, setPlanDuration] = useState(null)
    const [monthlyRepayment, setMonthlyRepayment] = useState({
        minimumRepayment: '',
        maximumRepayment: '',
    })
    const [inputs, setInputs] = useState({
        minimumAmount: '',
        maximumAmount: '',
    })

    const handleInputChange = event => {
        const { name, value } = event.target
        //regex to to allow numbers and comma
        const numberRegex = /^[0-9.,]*$/
        if (name === 'minimumAmount') {
            setInputs(prev => ({
                ...prev,
                minimumAmount: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.minimumAmount,
            }))
        }

        if (name === 'maximumAmount') {
            setInputs(prev => ({
                ...prev,
                maximumAmount: numberRegex.test(value)
                    ? floatWithCommas(value)
                    : inputs.maximumAmount,
            }))
        }
    }

    const handleLeaseToOwnChecked = checked => {
        setLeaseToOwnChecked(checked)
        setPaymentModel('Lease_to_Own')
    }
    const handleRentalChecked = checked => {
        setRentalChecked(checked)
        setPaymentModel('subscription')
    }

    const handleMonthlyRepayment = (min, max) => {
        setMonthlyRepayment({
            minimumRepayment: min,
            maximumRepayment: max,
        })
    }

    const handleClearAll = () => {
        setLeaseToOwnChecked(false)
        setRentalChecked(false)
        setPlanDuration()
        setMonthlyRepayment({
            minimumRepayment: '',
            maximumRepayment: '',
        })
        setInputs({
            minimumAmount: '',
            maximumAmount: '',
        })
    }

    const requestPayload = {
        min_initial_deposit: inputs.minimumAmount.replace(/,/g, ''),
        max_initial_deposit: inputs.maximumAmount.replace(/,/g, ''),
        min_monthly_repayment: monthlyRepayment.minimumRepayment,
        max_monthly_repayment: monthlyRepayment.maximumRepayment,
        payment_model: paymentModel,
        payment_plan_tenure: planDuration,
    }

    return (
        <SecondaryModal
            showModal={addFilter}
            onCancel={() => setAddFilter(false)}
            modalWidth={'649px'}
            title={'Add Filter'}
            showFooter
            footerLeftContent={'button'}
            v2
            secondaryBtnText="Clear all"
            secondaryBtnWidth={isMobile ? '119px' : '160px'}
            secondaryBtnHeight={isMobile ? '45px' : '53px'}
            handleSecondaryBtnClick={handleClearAll}
            content={
                <section className="SSEFilterRecommendationsWrapper">
                    <h3>Payment models</h3>
                    <div className="SSEFilterCheckboxWrapper">
                        <Checkbox
                            handleChecked={checked =>
                                handleLeaseToOwnChecked(checked)
                            }
                            isChecked={
                                paymentModel === 'Lease_to_Own' &&
                                leastToOwnChecked
                                    ? true
                                    : false
                            }
                            page="SSE"
                        >
                            <div
                                style={{
                                    marginTop: isMobile ? '-5px' : '-3px',
                                }}
                            >
                                <p className="SSEFilterCheckboxHeaderStyle">
                                    Lease to own
                                </p>
                                <p className="SSEFilterCheckboxText">
                                    Discover solar energy systems available for
                                    ownership through monthly payments.
                                </p>
                            </div>
                        </Checkbox>
                        <Checkbox
                            handleChecked={checked =>
                                handleRentalChecked(checked)
                            }
                            isChecked={
                                paymentModel === 'subscription' && rentalChecked
                                    ? true
                                    : false
                            }
                            page="SSE"
                        >
                            <div
                                style={{
                                    marginTop: isMobile ? '-5px' : '-3px',
                                }}
                            >
                                <p className="SSEFilterCheckboxHeaderStyle">
                                    Rental
                                </p>
                                <p className="SSEFilterCheckboxText">
                                    See solar energy systems that can be
                                    accessed through monthly subscription plans
                                </p>
                            </div>
                        </Checkbox>
                    </div>
                    <div className="SSEInitialDepositWrapper">
                        <h3>Initial deposit</h3>
                        <div className="SSEAmountInputsWrapper">
                            <IconInputField
                                icon={NairaIcon}
                                name="minimumAmount"
                                title="Min. amount"
                                value={inputs.minimumAmount}
                                handleChange={handleInputChange}
                                width={isMobile ? '100%' : '294px'}
                                marginBottom="6px"
                            />
                            <IconInputField
                                icon={NairaIcon}
                                name="maximumAmount"
                                title="Max. amount"
                                value={inputs.maximumAmount}
                                handleChange={handleInputChange}
                                width={isMobile ? '100%' : '294px'}
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: isMobile && '80px' }}>
                        <h3>
                            {isMobile
                                ? 'Filter by Length of Payment plan  '
                                : 'Payment plan duration'}{' '}
                        </h3>
                        <div className="SSEFilterButtonsWrapper">
                            <button
                                type="button"
                                onClick={() => setPlanDuration(36)}
                                className={
                                    planDuration === 36 &&
                                    'SSEFilterButtonsFocus'
                                }
                                style={{ width: '114px' }}
                            >
                                36 Months
                            </button>
                            <button
                                type="button"
                                onClick={() => setPlanDuration(24)}
                                className={
                                    planDuration === 24 &&
                                    'SSEFilterButtonsFocus'
                                }
                                style={{ width: '114px' }}
                            >
                                24 Months
                            </button>
                            <button
                                type="button"
                                onClick={() => setPlanDuration(12)}
                                className={
                                    planDuration === 12 &&
                                    'SSEFilterButtonsFocus'
                                }
                                style={{ width: '114px' }}
                            >
                                12 Months
                            </button>
                        </div>
                    </div>
                    {isMobile ? (
                        <div style={{ height: '100px' }}></div>
                    ) : (
                        <div className="SSEMonthlyRepaymentWrapper">
                            <h3>Monthly repayment</h3>
                            <div className="SSEFilterButtonsWrapper">
                                <button
                                    type="button"
                                    onClick={() =>
                                        handleMonthlyRepayment(10000, 50000)
                                    }
                                    className={
                                        monthlyRepayment.minimumRepayment ===
                                            10000 && 'SSEFilterButtonsFocus'
                                    }
                                >
                                    <NairaSymbol />
                                    10,000 to <NairaSymbol />
                                    50,000
                                </button>
                                <button
                                    type="button"
                                    onClick={() =>
                                        handleMonthlyRepayment(50000, 100000)
                                    }
                                    className={
                                        monthlyRepayment.minimumRepayment ===
                                            50000 && 'SSEFilterButtonsFocus'
                                    }
                                >
                                    <NairaSymbol />
                                    50,000 to <NairaSymbol />
                                    100,000
                                </button>
                                <button
                                    type="button"
                                    onClick={() =>
                                        handleMonthlyRepayment(100000, 200000)
                                    }
                                    className={
                                        monthlyRepayment.minimumRepayment ===
                                            100000 && 'SSEFilterButtonsFocus'
                                    }
                                >
                                    <NairaSymbol />
                                    100,000 to <NairaSymbol />
                                    200,000
                                </button>
                            </div>
                        </div>
                    )}
                </section>
            }
            primaryBtnText={'Show packages'}
            primaryBtnLoading={isLoading}
            handlePrimaryBtnClick={() => {
                setShowPackages(true)
                handleFilter(requestPayload)
                page === 'view-system' &&
                    history.push({
                        pathname: '/consumer/systems',
                        state: {
                            filterPayload: requestPayload,
                            showPackages: true,
                        },
                    })
            }}
            primaryBtnWidth={isMobile ? '143px' : '160px'}
            primaryBtnHeight={isMobile ? '45px' : '53px'}
        />
    )
}

FilterRecommendations.propTypes = {
    setShowPackages: PropTypes.func,
    setAddFilter: PropTypes.func,
    addFilter: PropTypes.bool,
    page: PropTypes.string,
    handleFilter: PropTypes.func,
    isLoading: PropTypes.bool,
}

export default FilterRecommendations
