import { useState } from 'react'
import { useQuery } from 'react-query'
import {
    getConsumerKYCInfoForAdmin,
    getKYCInfo,
} from 'src/api/shoppingExperience/account-overview'
import { decodeUserInfo } from 'src/utils/auth'
import { sameAddressOptions } from '../../OutrightSaleInformation/utils'
import { parsePhoneNumber } from 'libphonenumber-js'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { numberWithCommas } from 'src/utils/formatting'
import { registeredBusinessOptions } from '../utils'
import { repaymentMethodReverseMap } from 'src/utils/repaymentMethods'

const useFetchKycInfo = (inputs, setInputs, estimationId) => {
    const [kycData, setKycData] = useState({})
    const userInfo = decodeUserInfo()

    const { errorAlert } = useCustomToast()

    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const { isFetching } = useQuery(
        'fetch-dynamic-kyc-info',
        () =>
            isAdminUser
                ? getConsumerKYCInfoForAdmin(estimationId)
                : getKYCInfo(),
        {
            enabled: true,
            retry: false,
            refetchOnMount: true,
            onSuccess: res => {
                const data = res?.data?.data

                setKycData(data)
                let generalInfo = {
                    firstName:
                        data?.consumer?.user?.first_name ?? inputs?.firstName,
                    lastName:
                        data?.consumer?.user?.last_name ?? inputs?.lastName,
                    phone: data?.consumer?.user?.phone_number
                        ? parsePhoneNumber(
                              data?.consumer?.user?.phone_number || '',
                              'NG',
                          )?.nationalNumber
                        : inputs?.phone,
                    phoneVerified: data?.consumer?.user?.phone_verified,
                    email: data?.consumer?.user?.email ?? inputs?.email,
                    emailVerified: data?.consumer?.user?.email_verified,
                    contactAddress:
                        data?.consumer?.user?.street_address ??
                        inputs?.contactAddress,
                    contactState:
                        data?.consumer?.user?.state_of_residence ??
                        inputs?.contactState,
                    contactCity:
                        data?.consumer?.user?.city ?? inputs?.contactCity,
                    contactCountry:
                        data?.consumer?.user?.country ?? inputs?.contactCountry,
                    sameAddress:
                        data?.consumer?.user?.street_address ===
                            data?.consumer?.installation_details
                                ?.street_address &&
                        data?.consumer?.user?.state_of_residence ===
                            data?.consumer?.installation_details?.state &&
                        data?.consumer?.user?.city ===
                            data?.consumer?.installation_details?.city
                            ? sameAddressOptions[0]
                            : data?.consumer?.user?.street_address !==
                                  data?.consumer?.installation_details
                                      ?.street_address &&
                              data?.consumer?.installation_details
                                  ?.street_address?.length > 0 &&
                              data?.consumer?.installation_details?.state
                                  ?.length > 0 &&
                              data?.consumer?.installation_details?.city
                                  ?.length > 0
                            ? sameAddressOptions[1]
                            : sameAddressOptions[0],
                    installationAddress:
                        data?.consumer?.installation_details?.street_address ??
                        inputs?.installationAddress,
                    installationState:
                        data?.consumer?.installation_details?.state ??
                        inputs?.installationState,
                    installationCity:
                        data?.consumer?.installation_details?.city ??
                        inputs?.installationCity,
                    installationCountry:
                        data?.consumer?.installation_details?.country ??
                        inputs?.installationCountry,
                    employmentType:
                        data?.consumer?.employment_status ??
                        inputs?.employmentType,
                    companyName:
                        data?.consumer?.employment_details?.company_name ??
                        inputs?.companyName,
                    jobTitle:
                        data?.consumer?.employment_details?.job_title ??
                        inputs?.jobTitle,
                    jobStartDate:
                        data?.consumer?.employment_details
                            ?.employment_start_date ?? inputs?.jobStartDate,
                    employmentContractUrl:
                        data?.consumer?.employment_details
                            ?.employment_contract_url ??
                        inputs?.employmentContractUrl,
                    linkedinPage:
                        data?.consumer?.employment_details?.linkedin_url ??
                        inputs?.linkedinPage,
                    netMonthlySalary: data?.consumer?.employment_details
                        ?.net_monthly_salary
                        ? numberWithCommas(
                              data?.consumer?.employment_details
                                  ?.net_monthly_salary,
                          )
                        : '',
                    employeeId:
                        data?.consumer?.employment_details?.employee_id ??
                        inputs?.employeeId,
                    registeredBusiness:
                        data?.consumer?.business?.is_business_registered ===
                        true
                            ? registeredBusinessOptions[0]
                            : inputs?.registeredBusiness,
                    businessName:
                        data?.consumer?.business?.business_name ??
                        inputs?.businessName,
                    businessIndustry:
                        data?.consumer?.business?.business_industry ??
                        inputs?.businessIndustry,
                    cacNumber:
                        data?.consumer?.business?.business_registration_number,
                    cacNumberVerified:
                        data?.consumer?.business
                            ?.business_registration_number_verified,
                    numberOfYearsInOperation:
                        data?.consumer?.business?.years_in_operation ??
                        inputs?.numberOfYearsInOperation,
                    businessWebsite:
                        data?.consumer?.business?.business_website ??
                        inputs?.businessWebsite,
                    businessDescription:
                        data?.consumer?.business?.business_description ??
                        inputs?.businessDescription,
                    pensionManager:
                        data?.consumer?.pension_details?.pension_manager ??
                        inputs?.pensionManager,
                    retirementDate:
                        data?.consumer?.pension_details?.retirement_date ??
                        inputs?.retirementDate,
                    selfieImageUrl: data?.consumer?.user?.avatar_url ?? '',
                    identityType:
                        data?.identity_verification?.type ??
                        inputs?.identityType,
                    identityNumber:
                        data?.identity_verification?.number ??
                        inputs?.identityNumber,
                    identityVerificationId:
                        data?.identity_verification?.id ??
                        inputs?.identityVerificationId,
                    identityVerified:
                        data?.identity_verification?.verified ??
                        inputs?.identityVerified,
                    bvn:
                        data?.financial_verification?.bvn?.number ??
                        inputs?.bvn,
                    bvnVerified:
                        data?.financial_verification?.bvn?.verified ??
                        inputs?.bvnVerified,
                    repaymentMethod: data?.consumer?.repayment_method
                        ? repaymentMethodReverseMap[
                              data?.consumer?.repayment_method
                          ]
                        : inputs?.repaymentMethod,
                    bankStatements:
                        data?.financial_verification?.bank_statements ??
                        inputs?.bankStatements,
                }
                setInputs(prev => ({
                    ...prev,
                    ...generalInfo,
                }))
            },
            onError: error => {
                errorAlert(
                    errorHandler(
                        error?.response?.data ||
                            'An error occurred, please try again later.',
                    ),
                )
            },
        },
    )

    return {
        kycData,
        kycInfoFetching: isFetching,
    }
}

export default useFetchKycInfo
