import './npsratingmodal.scss'
import PropTypes from 'prop-types'
import { useState } from 'react'
import SecondaryModal from '../Modal/SecondaryModal'
import {
    npsRatingModalCustomPlaceholder,
    npsRatingModalSubTitleMap,
    npsRatingModalTitleMap,
    ratingButtonEmoji,
} from './data'
import { TextArea } from '../InputFields'
import CustomToolTip from '../CustomToolTip'
import { parseHTML } from 'src/utils/parseHTML'
const NPSRatingModal = ({
    showModal,
    closeModal,
    feature,
    setAdditionalPayload,
    handleClick,
    btnLoading,
}) => {
    const [rating, setRating] = useState(-1)
    const [notes, setNotes] = useState('')

    const handleCloseModal = () => {
        setRating(-1)
        setNotes('')
        closeModal()
    }

    const onBtnClick = value => {
        setRating(value)
        setAdditionalPayload(prev => ({
            ...prev,
            rating: value,
        }))
    }

    return (
        <SecondaryModal
            v2
            showModal={showModal}
            onCancel={() => handleCloseModal()}
            showFooter
            modalWidth={476}
            title={npsRatingModalTitleMap[feature]}
            primaryBtnText={'Rate Your Experience'}
            primaryBtnDisabled={rating === -1}
            handlePrimaryBtnClick={() => handleClick()}
            primaryBtnLoading={btnLoading}
            dataTestId={'nps-modal'}
            content={
                <div className={'NPSRatingModal'}>
                    <h4 className={'NPSRatingModal_Subtitle'}>
                        {npsRatingModalSubTitleMap[feature]}
                    </h4>

                    <div className={'NPSRatingModal_Middle'}>
                        <div className={'NPSRatingModal_Mid_Text'}>
                            <span>Terrible</span>
                            <span>Loved it!</span>
                        </div>
                        <div className={'NPSRatingModal_Mid_Btns'}>
                            {[...Array(10)].map((_, i) => (
                                <CustomToolTip
                                    key={i}
                                    text={parseHTML(ratingButtonEmoji?.[i + 1])}
                                    width={'0px'}
                                    toolTipMarginLeft={'-0px'}
                                >
                                    <div
                                        className={`NPSRatingModal_Mid_Btn ${
                                            rating === i + 1 ? 'active' : ''
                                        }`}
                                        role={'button'}
                                        onClick={() => onBtnClick(i + 1)}
                                        data-testid="rating-btn"
                                    >
                                        {i + 1}
                                    </div>
                                </CustomToolTip>
                            ))}
                        </div>
                        <div className={'NPSRatingModal_TextArea'}>
                            <TextArea
                                title={npsRatingModalCustomPlaceholder(feature)}
                                name={'notes'}
                                value={notes}
                                inputValue={notes}
                                handleChange={e => {
                                    const value = e.target.value
                                    setNotes(value)
                                    if (value === '') {
                                        setAdditionalPayload(prev => {
                                            const newState = { ...prev }
                                            delete newState.remark
                                            return newState
                                        })
                                    } else {
                                        setAdditionalPayload(prev => ({
                                            ...prev,
                                            remark: value,
                                        }))
                                    }
                                }}
                                height={99}
                                resize={false}
                            />
                        </div>
                    </div>
                </div>
            }
        />
    )
}

NPSRatingModal.propTypes = {
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    feature: PropTypes.string,
    setAdditionalPayload: PropTypes.func,
    handleClick: PropTypes.func,
    btnLoading: PropTypes.bool,
}

export default NPSRatingModal
