export const meansOfIdentification = [
    {
        id: 1,
        value: 'Virtual National Identification Number',
    },
    {
        id: 2,
        value: 'Voters Card',
    },
    {
        id: 3,
        value: 'Drivers License',
    },
]
