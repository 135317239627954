/* eslint-disable react/prop-types */

import React from 'react'
import { Modal } from 'antd'
import './consumermodal.scss'

const ConsumerModal = ({
    showModal,
    onCancel,
    content,
    modalWidth,
    modalHeight,
    closable,
    top,
}) => {
    const [width] = React.useState(window.innerWidth)

    let newWidth = ''
    let newHeight = ''

    if (width < 500) {
        newWidth = '100%'
        newHeight = modalHeight
    } else {
        newWidth = modalWidth
        newHeight = modalHeight
    }

    return (
        <div>
            <Modal
                width={newWidth}
                bodyStyle={{
                    height: newHeight,
                    width: newWidth,
                }}
                open={showModal}
                onCancel={onCancel ? onCancel : () => {}}
                footer={null}
                wrapClassName={`consumerModal ${top && 'consumerModalTop'}`}
                closable={closable}
                centered
            >
                {content}
            </Modal>
        </div>
    )
}

export default ConsumerModal
