export const getSystemPageEvents = {
    proceedToKYC: {
        action: 'KYC w/o editing Apps',
        category: 'Get Systems Page',
        label: 'KYC w/o editing Apps',
    },
    editAppliance: {
        action: 'Edit Apps. on PCM',
        category: 'Get Systems Page',
        label: 'Edit Apps. on PCM',
    },
    buildCustomSystem: {
        action: 'Select BCS on PCM',
        category: 'Get Systems Page',
        label: 'Select BCS on PCM',
    },
    undoChanges: {
        action: 'Undo changes to PCM',
        category: 'Get Systems Page',
        label: 'Undo changes to PCM',
    },
}
