import { useMutation, useQuery } from 'react-query'
import { payWithExistingCardApi } from 'src/api/consumer/payment'
import {
    retrievePaymentPlans,
    selectSSEPaymentPlanWithUpfrontDeposit,
    selectSSEPaymentPlans,
    simulateSSEPaymentPlans,
    switchSSEPaymentPlans,
    sendAgreementReminderEmailToAdmin,
} from 'src/api/shoppingExperience/creditDecision'
import { appTracking } from 'src/utils/appTracker'
import { errorHandler } from 'src/utils/errorHandler'

export const useSimulateSSEPaymentPlans = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: ({
            estimation_id,
            payment_model,
            upfront_deposit,
            selected_tenure,
        }) =>
            simulateSSEPaymentPlans({
                estimation_id,
                payment_model,
                upfront_deposit,
                selected_tenure,
            }),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: error => {
            if (onError) {
                onError(
                    error?.response?.data?.message ||
                        errorHandler(error?.response?.data)[0],
                )
            }
        },
    })

    return {
        mutateSSESimulatePaymentPlans: mutate,
        simulateSSEPaymentPlansLoading: isLoading,
        simulateSSEPaymentPlansData: data,
        simulateSSEPaymentPlansError: error,
        simulateSSEPaymentPlansSuccess: isSuccess,
    }
}

export const useSwitchSSEPaymentPlans = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: ({ estimation_id, payment_plan }) =>
            switchSSEPaymentPlans({ estimation_id, payment_plan }),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: error => {
            if (onError) {
                onError(
                    error?.response?.data?.message ||
                        errorHandler(error?.response?.data)[0],
                )
            }
        },
    })

    return {
        mutateSSESwitchPaymentPlans: mutate,
        switchSSEPaymentPlansLoading: isLoading,
        switchSSEPaymentPlansData: data,
        switchSSEPaymentPlansError: error,
        switchSSEPaymentPlansSuccess: isSuccess,
    }
}

export const useExistingCard = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: estimation_id => payWithExistingCardApi(estimation_id),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
            appTracking(
                '',
                '',
                '',
                'REPAYMENT_SETUP_CARD_DEBIT_SUCCESSFUL',
                'REPAYMENT_SETUP_CARD_DEBIT',
                'REPAYMENT_SETUP',
                ['MP', 'GA'],
                'event',
                {
                    event: {},
                },
            )
        },
        onError: error => {
            if (onError) {
                onError(
                    error?.response?.data?.message ||
                        errorHandler(error?.response?.data)[0],
                )
            }
        },
    })

    return {
        mutateExistingCard: mutate,
        existingCardLoading: isLoading,
        existingCardData: data,
        existingCardError: error,
        existingCardSuccess: isSuccess,
    }
}

export const useRetrieveSSEPaymentPlansData = ({
    estimationId,
    onSuccess,
    onError,
    enabled,
}) => {
    const {
        data: ssePaymentPlans,
        refetch: refetchPaymentPlans,
        isFetching: paymentPlansIsLoading,
    } = useQuery('paymentPlansSSE', () => retrievePaymentPlans(estimationId), {
        enabled: enabled,
        retry: false,
        onSuccess: ({ data }) => {
            if (onSuccess) {
                onSuccess(data?.data?.payment_plans)
            }
        },
        onError: error => {
            onError(
                error?.response?.data?.message ||
                    'An error occured, please try again later.',
            )
        },
    })
    return {
        refetchPaymentPlans,
        ssePaymentPlans,
        paymentPlansIsLoading,
    }
}

export const useSelectSSEPaymentPlan = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: ({ estimation_id, payment_plan_id }) =>
            selectSSEPaymentPlans({
                estimation_id,
                payment_plan_id,
            }),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: error => {
            if (onError) {
                onError(
                    error?.response?.data?.message ||
                        errorHandler(error?.response?.data)[0],
                )
            }
        },
    })

    return {
        mutateSSESelectPaymentPlan: mutate,
        selectSSEPaymentPlanLoading: isLoading,
        selectSSEPaymentPlanData: data,
        selectSSEPaymentPlanError: error,
        selectSSEPaymentPlanSuccess: isSuccess,
    }
}

export const useSelectSSEPaymentPlanWithUpfrontDeposit = ({
    onSuccess,
    onError,
}) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: ({ estimation_id, upfront_deposit, tenure }) => {
            selectSSEPaymentPlanWithUpfrontDeposit({
                estimation_id,
                upfront_deposit,
                tenure,
            })
        },
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: error => {
            if (onError) {
                onError(
                    error?.response?.data?.message ||
                        errorHandler(error?.response?.data)[0],
                )
            }
        },
    })

    return {
        mutateSelectSSEPaymentPlanWithUpfrontDeposit: mutate,
        selectSSEPaymentPlanWithUpfrontDepositLoading: isLoading,
        selectSSEPaymentPlanWithUpfrontDepositData: data,
        selectSSEPaymentPlanWithUpfrontDepositError: error,
        selectSSEPaymentPlanWithUpfrontDepositSuccess: isSuccess,
    }
}

export const useSendAgreementReminderEmail = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: ({ estimationId }) =>
            sendAgreementReminderEmailToAdmin(estimationId),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: error => {
            if (onError) {
                onError(
                    error?.response?.data?.message ||
                        errorHandler(error?.response?.data)[0],
                )
            }
        },
    })

    return {
        mutateSendAgreementEmail: mutate,
        sendAgreementEmailLoading: isLoading,
        sendAgreementEmailData: data,
        sendAgreementEmailError: error,
        sendAgreementEmailSuccess: isSuccess,
    }
}
