import { permissionsControl } from 'src/utils/permissionsControl'

export const unitsOfMeasurementOptions = [{ value: 'Watts' }, { value: 'kVa' }]

export const filterDrawerValues = tab => {
    switch (tab) {
        case '1':
            return {
                filterDrawerTitle: 'Filter Appliances',
                minInputTitle: 'Min. Rating',
                maxInputTitle: 'Max. Rating',
            }
        case '2':
            return {
                filterDrawerTitle: 'Filter Provider Groups',
                minInputTitle: 'Min. users',
                maxInputTitle: 'Max. users',
                // TODO: add other values when working on provider groups
            }

        case '4':
            return {
                filterDrawerTitle: 'Filter Component Types',
            }

        default:
            return {
                filterDrawerTitle: 'Filter',
            }
    }
}

const checkViewLibraryUserPermissions = () => {
    const permissions = {
        'global-library-contributors': permissionsControl([
            'can_view_global_library_contributor_list',
        ]),
        'global-library-users': permissionsControl([
            'can_view_global_library_user_list',
        ]),
        'subscription-model-providers': permissionsControl([
            'can_view_subscription_model_provider_list',
        ]),
    }
    return permissions
}

export const viewLibraryUserPermissions = checkViewLibraryUserPermissions()

const checkCanRemoveLibraryUserPermissions = () => {
    const permissions = {
        'global-library-contributors': permissionsControl([
            'can_delete_global_library_contributor',
        ]),
        'global-library-users': permissionsControl([
            'can_delete_global_library_user',
        ]),
        'subscription-model-providers': permissionsControl([
            'can_delete_subscription_model_provider',
        ]),
    }
    return permissions
}

export const canRemoveLibraryUserPermissions =
    checkCanRemoveLibraryUserPermissions()

export const canViewComponentTypeList = permissionsControl([
    'can_view_component_type_list',
])

export const canViewComponentTypeDetails = permissionsControl([
    'can_view_component_type_detail',
])

export const canCreateComponentType = permissionsControl([
    'can_create_a_component_type',
])

export const canViewApplianceList = permissionsControl([
    'can_view_appliance_list',
])
export const canViewApplianceDetail = permissionsControl([
    'can_view_appliance_detail',
])
export const canCreateAppliance = permissionsControl(['can_create_appliance'])
export const canUpdateAppliance = permissionsControl(['can_update_appliance'])
export const canDeleteAppliance = permissionsControl(['can_delete_appliance'])

export const checkPemmisionsForDefaultQueries = defaultQueries => {
    if (defaultQueries.current_tab === '1' && !canViewApplianceList) {
        return {
            ...defaultQueries,
            current_tab: '2',
            page: 1,
        }
    }
    return defaultQueries
}
