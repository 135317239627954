import { useQuery } from 'react-query'
import { getAdminCreditVariablesApi } from 'src/api/admin/credit-variables/listing'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

export const useGetClusterVariables = () => {
    const { errorAlert } = useCustomToast()
    const {
        isFetching: subCreditVariablesFetching,
        refetch: subCreditVariableRefetch,
        data: subCreditVariableData,
    } = useQuery(
        ['get-sub-credit-variables'],
        () =>
            getAdminCreditVariablesApi(
                '',
                'subscription',
                '',
                '',
                '',
                '',
                '',
                false,
                false,
                'DTC',
            ),
        {
            enabled: true,
            retry: false,
            onError: error => {
                errorAlert(errorHandler(error?.response?.data))
            },
        },
    )

    const {
        isFetching: l2OCreditVariablesFetching,
        refetch: l2OCreditVariableRefetch,
        data: l2OCreditVariableData,
    } = useQuery(
        ['get-l2o-credit-variables'],
        () =>
            getAdminCreditVariablesApi(
                '',
                'lease',
                '',
                '',
                '',
                '',
                '',
                false,
                false,
                'DTC',
            ),
        {
            enabled: true,
            retry: false,
            onError: error => {
                errorAlert(errorHandler(error?.response?.data))
            },
        },
    )

    const formatClusterData = data => {
        const formattedData = data?.map(item => ({
            name: item?.cluster_info?.code,
            value: item?.cluster_info?.code,
        }))
        return formattedData
    }

    return {
        subCreditVariablesFetching,
        subCreditVariableRefetch,
        subCreditVariableData,
        l2OCreditVariablesFetching,
        l2OCreditVariableRefetch,
        l2OCreditVariableData,
        formatClusterData,
    }
}
