import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import ExploreSolarPackages from 'src/assets/images/explore-solar-packages-bg.svg'
import ButtonComponent from 'src/components/Button'
import ChatCTA from 'src/components/ChatCTA'
import styles from './usetypemodal.module.scss'
import { ReactComponent as BigWhiteCloseIcon } from 'src/assets/images/close-icon-white-big.svg'
import { appTracking } from 'src/utils/appTracker'
import { useTypeDetailsNew } from 'src/pages/Consumer/SmartShoppingExperience/Home/SelectUseType/data'
import { ReactComponent as Checked } from 'src/assets/images/circle-check-no-stroke-white-fill.svg'
import { landingPageEvents } from 'src/utils/mp-event-tracker/landing-page'
import { InlineLoader } from 'src/components/Loader'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'View consumer page'

const UseTypeModal = ({
    showModal,
    onCancel,
    firstName,
    useType,
    setUseType,
    setErrors,
    errors,
    handleViewAllSystems,
    viewAllSolutionsBtnLoading,
    continueBtnLoading,
    handleContinueClick,
}) => {
    const showAllAvailableSolutions =
        process.env.REACT_APP_FLAG_SHOW_AVAILABLE_SOLUTIONS === 'true'
    const { isMobile } = useMediaQueries()

    return (
        <SecondaryModal
            modalClass="NewSecondaryModal"
            showModal={showModal}
            noPadding={'0px'}
            onCancel={onCancel}
            closable={false}
            content={
                <div className={styles.wrapper}>
                    <div className={styles.left}>
                        <h2>
                            Hi {firstName || ''}, start your journey with SunFi
                        </h2>
                        <p>Begin by selecting what you will like to power.</p>

                        <div className={styles.options}>
                            {useTypeDetailsNew?.map((each, i) => (
                                <div
                                    key={i}
                                    className={`${styles.option} ${
                                        useType === each.value
                                            ? styles.selected
                                            : ''
                                    }`}
                                    role={'button'}
                                    onClick={() => {
                                        setUseType(each.value)
                                        setErrors({ ...errors, useType: '' })
                                        appTracking(
                                            pageUrlName,
                                            pageTracker,
                                            pageTitle,
                                            landingPageEvents[
                                                i === 0
                                                    ? 'residential'
                                                    : 'business'
                                            ].action,
                                            landingPageEvents[
                                                i === 0
                                                    ? 'residential'
                                                    : 'business'
                                            ].label,
                                            landingPageEvents[
                                                i === 0
                                                    ? 'residential'
                                                    : 'business'
                                            ].category,
                                            ['MP', 'GA'],
                                            'event',
                                        )
                                    }}
                                >
                                    <img
                                        src={each.icon}
                                        alt={each.title}
                                        className={styles.optionImg}
                                    />
                                    <div>
                                        <h4>{each.title}</h4>
                                        <p>{each.description}</p>
                                    </div>
                                    <Checked
                                        className={styles.checkIcon}
                                        style={{
                                            stroke:
                                                useType === each.value
                                                    ? '#004AAD'
                                                    : '#E2EEFF',
                                        }}
                                    />
                                </div>
                            ))}
                            {errors?.useType && (
                                <p
                                    className={styles.error}
                                    style={{ marginTop: '-10px' }}
                                >
                                    {errors?.useType}
                                </p>
                            )}
                        </div>

                        <div className={styles.btnAndCta}>
                            {!isMobile && <ChatCTA />}
                            <ButtonComponent
                                btnBgColor={'#004aad'}
                                btnTextColor={'#ffffff'}
                                btnWidth={isMobile ? '100%' : '210px'}
                                btnHeight={'53px'}
                                handleClick={handleContinueClick}
                                fontSize={isMobile ?? '14px'}
                            >
                                {continueBtnLoading ? (
                                    <InlineLoader />
                                ) : (
                                    'Continue'
                                )}
                            </ButtonComponent>
                        </div>

                        {showAllAvailableSolutions && (
                            <>
                                <div className={styles.divider}>
                                    <hr />
                                    <p>OR</p>
                                    <hr />
                                </div>
                                <ButtonComponent
                                    btnBgColor={'#FFFFFF'}
                                    btnTextColor={'#004AAD'}
                                    btnWidth={'100%'}
                                    btnHeight={'44px'}
                                    marginTop={'16px'}
                                    btnTextColorOutline={'#E2EEFF'}
                                    fontSize={isMobile ?? '13px'}
                                    handleClick={() => {
                                        handleViewAllSystems()
                                        appTracking(
                                            pageUrlName,
                                            pageTracker,
                                            pageTitle,
                                            landingPageEvents['allSystems']
                                                .action,
                                            landingPageEvents['allSystems']
                                                .label,
                                            landingPageEvents['allSystems']
                                                .category,
                                            ['MP', 'GA'],
                                            'event',
                                        )
                                    }}
                                >
                                    {viewAllSolutionsBtnLoading ? (
                                        <InlineLoader />
                                    ) : (
                                        'View all available solutions'
                                    )}
                                </ButtonComponent>
                            </>
                        )}
                        {isMobile && (
                            <div className={styles.mobileChatBox}>
                                <ChatCTA />
                            </div>
                        )}
                    </div>
                    <div className={styles.exploreImgWrapper}>
                        <img
                            src={ExploreSolarPackages}
                            alt="solar city"
                            className={styles.exploreSolarPackagesImg}
                        />
                        <BigWhiteCloseIcon
                            className={styles.closeIcon}
                            role="button"
                            onClick={onCancel}
                        />
                    </div>
                    <BigWhiteCloseIcon
                        className={styles.mobileCloseIcon}
                        role="button"
                        onClick={onCancel}
                    />
                </div>
            }
        />
    )
}

UseTypeModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    firstName: PropTypes.string,
    useType: PropTypes.string,
    setUseType: PropTypes.func,
    setErrors: PropTypes.func,
    errors: PropTypes.object,
    handleViewAllSystems: PropTypes.func,
    viewAllSolutionsBtnLoading: PropTypes.bool,
    continueBtnLoading: PropTypes.bool,
    handleContinueClick: PropTypes.func,
}

export default UseTypeModal
