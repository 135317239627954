//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Dropdown, Menu, Skeleton } from 'antd'
import { useParams } from 'react-router-dom'
import BackNav from 'src/components/BackNav'
import Button from 'src/components/Button'
import Toast from 'src/components/Toast'
import { ReactComponent as ArrowDown } from 'src/assets/images/arrowDown.svg'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import ManualSimulation from './ManualSimulation'
import {
    canDeleteCreditVariable,
    canSimulatePlanWithCreditVariable,
    canUpdateCreditVariable,
    creditDetails,
} from './formatter'
import ActionModal from 'src/components/ActionModal'
import CreditVariableModal from '../AddVariable/CreditVariableModal'
import {
    getSingleCreditVariable,
    updateSingleCreditVariable,
    deleteCreditVariable,
} from 'src/api/admin/credit-variables/detailed-page'
import { errorHandler } from 'src/utils/errorHandler'
import SeoComponent from 'src/components/Seo'
import { isTablet } from 'src/utils/mediaQueries'
import { denyPermission } from 'src/utils/permissionFramework'
import ViewAddedPackages from './ViewAddedPackages'
import { AppButton } from 'src/components/Button'
import { ReactComponent as ArrowUp } from 'src/assets/images/ArrowUp.svg'

import './viewvariable.scss'
import { applyDynamicPermissionsToAdminUser } from 'src/utils/permissionsControl'

const ViewCreditVariable = () => {
    let history = useHistory()
    const { id } = useParams()
    const [showCreditModal, setShowCreditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [successDelete, setSuccessDelete] = useState(false)
    const [creditVariableData, setCreditVariableData] = useState({})
    const [toastError, setToastError] = useState(false)
    const [saveVariable, setSaveVariable] = useState(false)
    const [updatedCreditData, setUpdatedCreditData] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [manualSimulationModal, setShowManualSimulationModal] =
        useState(false)
    const [tab, setTab] = useState()
    const [viewAddedPackages, setViewAddedPackages] = useState(false)
    const [showMoreTenures, setShowMoreTenures] = useState(false)
    const [isProvider, setIsProvider] = useState('false')
    //get single credit variable
    const {
        isFetching: getCrediVariablesFetching,
        refetch: getSingleCreditVariableRefetch,
    } = useQuery(
        ['get-single-credit-variable'],
        () => getSingleCreditVariable(id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setCreditVariableData(data?.data?.data)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )
    //delete  credit variable
    const {
        isFetching: deleteCrediVariableFetching,
        refetch: deleteCreditVariableRefetch,
    } = useQuery(['delete-credit-variable'], () => deleteCreditVariable(id), {
        enabled: false,
        retry: false,
        onSuccess: () => {
            setSuccessDelete(true)
        },
    })

    // this function comapares the difference between two objects
    const diffObj = (obj1, obj2) => {
        let diff = Object.keys(obj2).reduce((diff, key) => {
            if (obj1[key] === obj2[key]) return diff
            return {
                ...diff,
                [key]: obj2[key],
            }
        }, {})
        return diff
    }

    // update single credit variable
    const {
        isFetching: updateCrediVariablesFetching,
        refetch: updateSingleCreditVariableRefetch,
    } = useQuery(
        ['update-single-credit-variable'],
        () =>
            updateSingleCreditVariable(
                id,
                diffObj(creditVariableData, updatedCreditData),
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setSaveVariable(true)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const handleUpdateCredit = () => {
        updateSingleCreditVariableRefetch()
    }

    useEffect(() => {
        getSingleCreditVariableRefetch()
    }, [id])

    useEffect(() => {
        if (history?.location?.state?.previousPath.includes('all-variables')) {
            setTab(1)
        }
        if (history?.location?.state?.previousPath.includes('lease-to-own')) {
            setTab(2)
        }
        if (history?.location?.state?.previousPath.includes('subscription')) {
            setTab(3)
        }
    }, [])
    const hasClusterInfo =
        JSON.stringify(creditVariableData?.cluster_info) !== '{}'

    const isProviderCluster = creditVariableData?.cluster_info?.providers
        ? true
        : false

    const moreActionsMenu = (
        <Menu>
            <Menu.Item
                key="1"
                onClick={() => {
                    setShowManualSimulationModal(true)
                }}
                disabled={
                    applyDynamicPermissionsToAdminUser
                        ? !canSimulatePlanWithCreditVariable
                        : denyPermission(
                              'admin',
                              'credit_variable',
                              'credit_variable:can_simulate_plan',
                          )
                }
            >
                <span className="dropdownLinks">Simulate Plan</span>
            </Menu.Item>

            <Menu.Item
                key="2"
                onClick={() => {
                    setShowDeleteModal(true)
                }}
                disabled={
                    applyDynamicPermissionsToAdminUser
                        ? !canDeleteCreditVariable
                        : denyPermission(
                              'admin',
                              'credit_variable',
                              'credit_variable:can_delete',
                          )
                }
            >
                <span className="dropdownLinks">Delete Variable</span>
            </Menu.Item>
            {hasClusterInfo && (
                <>
                    <Menu.Item
                        key="3"
                        onClick={() => {
                            setViewAddedPackages(true)
                            setIsProvider(false)
                        }}
                        disabled={denyPermission(
                            'admin',
                            'credit_variable',
                            'credit_variable:can_create',
                        )}
                    >
                        <span className="dropdownLinks">
                            View Added Packages
                        </span>
                    </Menu.Item>
                    {isProviderCluster && (
                        <Menu.Item
                            key="4"
                            onClick={() => {
                                setViewAddedPackages(true)
                                setIsProvider(true)
                            }}
                            disabled={denyPermission(
                                'admin',
                                'credit_variable',
                                'credit_variable:can_create',
                            )}
                        >
                            <span className="dropdownLinks">
                                View Added Providers
                            </span>
                        </Menu.Item>
                    )}
                </>
            )}
        </Menu>
    )

    // TODO - remove this function later when date-fns works
    const format = date => {
        const m = new Date(date).toString('YYYY-MM-dd')?.substring(0, 21)
        const a = m.split(' ')
        return a[2] + ' ' + a[1] + ', ' + a[3] + ', ' + a[4]?.substring(0, 5)
    }

    // right side data - first row
    const sectionOneData = [
        {
            title: 'CREATION DATE',
            subtitle: format(creditVariableData?.created_at),
        },
        {
            title: 'CREATED BY',
            subtitle: creditVariableData?.created_by
                ? creditVariableData?.created_by
                : '-',
        },
    ]

    const sectionThreeData = [
        {
            title: 'CLUSTER NAME',
            subtitle: creditVariableData?.cluster_info?.name,
        },
        {
            title: 'CLUSTER PACKAGES',
            subtitle: creditVariableData?.cluster_info?.packages?.length,
        },
    ]

    const sectionFourData = [
        {
            title: 'CLUSTER CODE',
            subtitle: creditVariableData?.cluster_info?.code,
        },
        isProviderCluster && {
            title: 'CLUSTER PROVIDERS',
            subtitle: creditVariableData?.cluster_info?.providers?.length,
        },
        ,
    ]

    // right side data - second row
    const sectionTwoData = [
        {
            title: 'UPDATED DATE',
            subtitle: format(creditVariableData?.updated_at),
        },
        {
            title: ' UPDATED BY',
            subtitle: creditVariableData?.updated_by
                ? creditVariableData?.updated_by
                : '-',
        },
    ]
    const simulatePlanData = {
        plan_type: creditVariableData.payment_model,
        upfront_deposit: creditVariableData.upfront_deposit,
        cluster_code: creditVariableData?.cluster_info?.code,
        cluster_packages: creditVariableData?.cluster_info?.packages,
    }

    const handleCloseViewAddedPackages = () => {
        setViewAddedPackages(false)
    }

    const handleShowMoreTenure = () => {
        setShowMoreTenures(!showMoreTenures)
        document.getElementById('lessTenure').className = showMoreTenures
            ? 'ViewSmallTenure'
            : 'HideSmallTenure'
        document.getElementById('moreTenure').className = showMoreTenures
            ? 'HideMoreTenure'
            : 'ViewMoreTenure'
        document.getElementById('moreTenureDiv').className = showMoreTenures
            ? 'ViewTenureTableNoHeader'
            : 'ViewMoreTenureDiv'
    }
    return (
        <>
            <SeoComponent
                title="SunFi - Admin Credits System | Simplifying and Scaling Clean Energy"
                tracker="AdminCreditsSystemTracker"
            />

            {viewAddedPackages && (
                <ViewAddedPackages
                    viewAddedPackages={viewAddedPackages}
                    handleCloseViewAddedPackages={handleCloseViewAddedPackages}
                    clusterCode={creditVariableData?.cluster_info?.code}
                    fetchSingleCreditVariable={getSingleCreditVariableRefetch}
                    isProvider={isProvider}
                />
            )}

            <CreditVariableModal
                showModal={showCreditModal}
                setShowModal={setShowCreditModal}
                save={values => setUpdatedCreditData(values)}
                creditVariableData={creditVariableData}
                selectedVariable={creditVariableData?.payment_model}
                showSaveVariableModal={() => setSaveVariable(true)}
                updateVariable={handleUpdateCredit}
                updateVariableLoading={updateCrediVariablesFetching}
                closeVariableDetailsModal={setShowCreditModal}
            />
            <SecondaryModal
                modalWidth={isTablet ? '580px' : '624px'}
                closable={false}
                modalHeight="475px"
                app="adminsolution"
                showModal={manualSimulationModal}
                content={
                    <ManualSimulation
                        closeModal={() => setShowManualSimulationModal(false)}
                        simulatePlanData={simulatePlanData}
                        providerCreditId={id}
                        providerId={creditVariableData?.provider?.id}
                    />
                }
            />
            <ActionModal
                actionModalOpen={saveVariable}
                headerText="Variable Saved"
                subTitle="Credit variables has been saved successfully"
                actionHandler={() => {
                    setSaveVariable(false)
                    getSingleCreditVariableRefetch()
                }}
                actionType="success"
                actionText="Ok, got it"
                loading={''}
                noCancelBtn={true}
                cancelText="Cancel"
                noBackLink
            />
            <ActionModal
                actionModalOpen={showDeleteModal}
                headerText={
                    successDelete
                        ? 'Variable Successfully Deleted'
                        : 'Delete Variable?'
                }
                subTitle={
                    successDelete
                        ? 'Credit variables has been deleted successfully'
                        : 'A deleted variable cannot be recovered, would you like to continue?'
                }
                actionHandler={() => {
                    if (successDelete) {
                        history.push('/admin/credit?tab=all-variables')
                    } else {
                        deleteCreditVariableRefetch()
                    }
                }}
                actionType={successDelete ? 'success' : 'delete'}
                actionText={successDelete ? 'Okay, Got it' : 'Delete'}
                loading={deleteCrediVariableFetching}
                noCancelBtn={successDelete ? true : false}
                cancelText="Cancel"
                onCancel={() => setShowDeleteModal(false)}
                closeModal={() => {
                    setShowDeleteModal(false)
                }}
                noBackLink
            />
            <div className="ViewDeclined">
                {getCrediVariablesFetching ? (
                    <Skeleton.Input
                        active
                        size="small"
                        style={{ width: '125px' }}
                    />
                ) : (
                    <BackNav
                        title="Back to Credit System"
                        onClick={() => {
                            history.goBack()
                        }}
                    />
                )}
                <div>
                    <div
                        style={{ display: 'flex' }}
                        className="ViewDeclinedViewWrapper"
                    >
                        {toastError && (
                            <Toast messageType="error" message={errorMessage} />
                        )}
                        <div className="ViewDeclinedLeftSection">
                            <div className="CVViewDeclinedTitleWrapper Desktop">
                                {getCrediVariablesFetching ? (
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{
                                            width: '125px',
                                            marginRight: 10,
                                        }}
                                    />
                                ) : (
                                    <div className="ViewDeclinedMainTitle">
                                        {creditVariableData.provider === null
                                            ? 'Global Variable'
                                            : creditVariableData?.provider
                                                  ?.display_name}
                                    </div>
                                )}
                                {getCrediVariablesFetching ? (
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{ width: '125px' }}
                                    />
                                ) : (
                                    <div className="ViewDeclinedLabel">
                                        {creditVariableData.payment_model
                                            ?.split('_')
                                            .join(' ')}
                                    </div>
                                )}
                            </div>
                            {getCrediVariablesFetching ? (
                                <Skeleton.Input
                                    active
                                    size="small"
                                    style={{ width: '125px', marginRight: 10 }}
                                />
                            ) : (
                                <h2 className="CreditVariableTitle">
                                    Credit Variables
                                </h2>
                            )}
                            <div>
                                {getCrediVariablesFetching ? (
                                    <div>
                                        {[...Array(2)]?.map(i => (
                                            <div key={i}>
                                                <div>
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '500px',
                                                            marginTop: '20px',
                                                            height: '40px',
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '500px',
                                                            marginTop: '50px',
                                                            height: '40px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="creditDetailsWrapper">
                                        {creditDetails(creditVariableData).map(
                                            (info, i) => (
                                                <>
                                                    {info.value ===
                                                    'possible_tenures' ? (
                                                        <div
                                                            key={i}
                                                            className="ViewTenureTableNoHeader"
                                                            id="moreTenureDiv"
                                                        >
                                                            <div className="ViewTenureRow">
                                                                <p className="ViewDeclinedTableNoHeaderName">
                                                                    {info.label}
                                                                </p>
                                                                <p
                                                                    className="ViewDeclinedTableNoHeaderContent"
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    {
                                                                        <>
                                                                            <p
                                                                                className="ViewSmallTenure"
                                                                                id="lessTenure"
                                                                            >
                                                                                {creditVariableData[
                                                                                    info
                                                                                        .value
                                                                                ]
                                                                                    ?.slice(
                                                                                        0,
                                                                                        6,
                                                                                    )
                                                                                    ?.join(
                                                                                        ', ',
                                                                                    )}
                                                                            </p>
                                                                            {creditVariableData[
                                                                                info
                                                                                    .value
                                                                            ]
                                                                                ?.length >
                                                                                6 && (
                                                                                <AppButton
                                                                                    btnBgColor="#e2eeff"
                                                                                    btnHeight="36px"
                                                                                    btnWidth="100px"
                                                                                    btnTextColor="#004aad"
                                                                                    type="buttonTwo"
                                                                                    fontSize="12px"
                                                                                    fontWeight={
                                                                                        500
                                                                                    }
                                                                                    margin="0px 0px 0px 5px"
                                                                                    handleClick={
                                                                                        handleShowMoreTenure
                                                                                    }
                                                                                >
                                                                                    {showMoreTenures
                                                                                        ? 'See less'
                                                                                        : 'See more'}
                                                                                    {showMoreTenures ? (
                                                                                        <ArrowUp
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '3px',
                                                                                                height: '6px',
                                                                                                marginBottom:
                                                                                                    '1px',
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <ArrowDown
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '3px',
                                                                                                height: '6px',
                                                                                                marginBottom:
                                                                                                    '1px',
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </AppButton>
                                                                            )}
                                                                        </>
                                                                    }
                                                                </p>
                                                            </div>

                                                            <p
                                                                className="HideMoreTenure"
                                                                id="moreTenure"
                                                            >
                                                                {creditVariableData[
                                                                    info.value
                                                                ]?.join(', ')}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            key={i}
                                                            className="ViewDeclinedTableNoHeader"
                                                        >
                                                            <p className="ViewDeclinedTableNoHeaderName">
                                                                {info.label}
                                                            </p>
                                                            <p className="ViewDeclinedTableNoHeaderContent">
                                                                {`${parseFloat(
                                                                    creditVariableData[
                                                                        info
                                                                            .value
                                                                    ],
                                                                )}${
                                                                    info?.suffix
                                                                        ? info?.suffix
                                                                        : ''
                                                                }`}
                                                            </p>
                                                        </div>
                                                    )}
                                                </>
                                            ),
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="ViewDeclinedRightSection">
                            <div className="CVViewDeclinedTitleWrapper Mobile">
                                {getCrediVariablesFetching ? (
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{
                                            width: '125px',
                                            marginRight: 10,
                                        }}
                                    />
                                ) : (
                                    <div className="ViewDeclinedMainTitle">
                                        {creditVariableData.provider === null
                                            ? 'Global Variable'
                                            : creditVariableData?.provider
                                                  ?.display_name}
                                    </div>
                                )}
                                {getCrediVariablesFetching ? (
                                    <Skeleton.Input
                                        active
                                        size="small"
                                        style={{ width: '125px' }}
                                    />
                                ) : (
                                    <div className="ViewDeclinedLabel">
                                        {creditVariableData.payment_model
                                            ?.split('_')
                                            .join(' ')}
                                    </div>
                                )}
                            </div>
                            {getCrediVariablesFetching ? (
                                <Skeleton.Input
                                    active
                                    size="small"
                                    style={{ width: '125px', marginBottom: 30 }}
                                />
                            ) : (
                                <h2 className="ViewDeclinedDetailsTitle">
                                    Variable Details
                                </h2>
                            )}

                            {getCrediVariablesFetching ? (
                                <div>
                                    {[...Array(2)]?.map(i => (
                                        <div key={i}>
                                            <div>
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '113px',
                                                        marginTop: '20px',
                                                        height: '21px',
                                                    }}
                                                />
                                                <Skeleton.Input
                                                    active
                                                    size="small"
                                                    style={{
                                                        width: '113px',
                                                        marginTop: '20px',
                                                        height: '21px',
                                                        marginLeft: '30px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="ViewDeclinedFlexrow">
                                    {sectionOneData.map((info, index) => (
                                        <div key={index}>
                                            <p className="ViewDeclinedSubTitle">
                                                {info.title}
                                            </p>
                                            <p className="ViewDeclinedDetails">
                                                {info.subtitle}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div
                                className="ViewDeclinedFlexrow"
                                style={{ marginTop: 50 }}
                            >
                                {getCrediVariablesFetching ? (
                                    <div>
                                        {[...Array(2)]?.map(i => (
                                            <div key={i}>
                                                <div>
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '113px',
                                                            marginTop: '20px',
                                                            height: '21px',
                                                        }}
                                                    />
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '113px',
                                                            marginTop: '20px',
                                                            height: '21px',
                                                            marginLeft: '30px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        {sectionTwoData.map((info, index) => (
                                            <div key={index}>
                                                <p className="ViewDeclinedSubTitle">
                                                    {info.title}
                                                </p>
                                                <p className="ViewDeclinedDetails">
                                                    {info.subtitle}
                                                </p>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                            <div
                                className="ViewDeclinedFlexrow"
                                style={{
                                    marginTop:
                                        !getCrediVariablesFetching &&
                                        hasClusterInfo &&
                                        50,
                                }}
                            >
                                {getCrediVariablesFetching ? (
                                    <div>
                                        {[...Array(2)]?.map(i => (
                                            <div key={i}>
                                                <div>
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '113px',
                                                            marginTop: '20px',
                                                            height: '21px',
                                                        }}
                                                    />
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '113px',
                                                            marginTop: '20px',
                                                            height: '21px',
                                                            marginLeft: '30px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        {hasClusterInfo &&
                                            sectionThreeData.map(
                                                (info, index) => (
                                                    <div key={index}>
                                                        <p className="ViewDeclinedSubTitle">
                                                            {info.title}
                                                        </p>
                                                        <p className="ViewDeclinedDetails">
                                                            {info.subtitle}
                                                        </p>
                                                    </div>
                                                ),
                                            )}
                                    </>
                                )}
                            </div>
                            <div
                                className="ViewDeclinedFlexrow"
                                style={{
                                    marginTop:
                                        !getCrediVariablesFetching &&
                                        hasClusterInfo &&
                                        50,
                                }}
                            >
                                {getCrediVariablesFetching ? (
                                    <div>
                                        {[...Array(2)]?.map(i => (
                                            <div key={i}>
                                                <div>
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '113px',
                                                            marginTop: '20px',
                                                            height: '21px',
                                                        }}
                                                    />
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '113px',
                                                            marginTop: '20px',
                                                            height: '21px',
                                                            marginLeft: '30px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        {hasClusterInfo &&
                                            sectionFourData.map(
                                                (info, index) => (
                                                    <div key={index}>
                                                        <p className="ViewDeclinedSubTitle">
                                                            {info.title}
                                                        </p>
                                                        <p className="ViewDeclinedDetails">
                                                            {info.subtitle}
                                                        </p>
                                                    </div>
                                                ),
                                            )}
                                    </>
                                )}
                            </div>
                            <div
                                className="ViewDeclinedFlexrow"
                                style={{ marginTop: 50, columnGap: '15px' }}
                            >
                                {getCrediVariablesFetching ? (
                                    <div>
                                        {[...Array(1)]?.map(i => (
                                            <div key={i}>
                                                <div>
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '113px',
                                                            marginTop: '20px',
                                                            height: '44px',
                                                        }}
                                                    />
                                                    <Skeleton.Input
                                                        active
                                                        size="small"
                                                        style={{
                                                            width: '113px',
                                                            marginTop: '20px',
                                                            height: '44px',
                                                            marginLeft: '30px',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            <Button
                                                btnBgColor="var(--blue)"
                                                btnTextColor="var(--white)"
                                                btnWidth="136px"
                                                btnHeight="44px"
                                                handleClick={() =>
                                                    setShowCreditModal(true)
                                                }
                                                disabled={
                                                    applyDynamicPermissionsToAdminUser
                                                        ? !canUpdateCreditVariable
                                                        : denyPermission(
                                                              'admin',
                                                              'credit_variable',
                                                              'credit_variable:can_create',
                                                          )
                                                }
                                                toolTipText={
                                                    !canUpdateCreditVariable
                                                        ? 'You are not authorised to perform this action'
                                                        : null
                                                }
                                                toolTipPopupWidth={164}
                                            >
                                                Update
                                            </Button>
                                        </div>
                                        <div>
                                            <Dropdown
                                                overlay={moreActionsMenu}
                                                placement="bottomCenter"
                                                trigger={['click']}
                                            >
                                                <a
                                                    onClick={e =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    <Button
                                                        btnBgColor="var(--purple-light)"
                                                        btnTextColor="var(--blue)"
                                                        btnTextColorOutline="var(--blue)"
                                                        btnOutlineColor="var(--purple-light)"
                                                        btnBgColorOutline="#E2EEFF"
                                                        type="outline"
                                                        btnHeight="44px"
                                                        btnWidth="136px"
                                                    >
                                                        More Actions
                                                        <ArrowDown
                                                            style={{
                                                                marginLeft: 12,
                                                            }}
                                                        />
                                                    </Button>
                                                </a>
                                            </Dropdown>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewCreditVariable
