import { useEffect, useState } from 'react'
import NairaSymbol from 'src/components/NairaSymbol'
import styles from './all-components.module.scss'
import arrow from 'src/assets/images/blue-arrow.svg'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { getSinglePackageApi } from 'src/api/products/packages'

const AllComponents = ({ solutionId, isOpen }) => {
    const [showComponents, setShowComponents] = useState(false)
    const [solutionComponents, setSolutionComponents] = useState([])

    const { refetch } = useQuery(
        ['fetchSinglePackage'],
        () => getSinglePackageApi(solutionId, true),
        {
            enabled: !!solutionId,
            retry: false,
            onSuccess: data => {
                setSolutionComponents(data.data.data.components)
            },
        },
    )

    useEffect(() => {
        if (!isOpen) {
            setShowComponents(false)
        }
    }, [isOpen])

    useEffect(() => {
        if (solutionId) {
            refetch()
        }
        return
        // eslint-disable-next-line
    }, [solutionId])
    return (
        <div className={styles.AllComponents}>
            <div>
                <h5>ALL COMPONENTS</h5>
                <div
                    onClick={() => setShowComponents(!showComponents)}
                    className={styles.TopArrowWrapper}
                >
                    <img
                        src={arrow}
                        alt="arrow"
                        className={
                            showComponents
                                ? styles.TopArrowOpen
                                : styles.TopArrowClosed
                        }
                    />
                </div>
            </div>

            {showComponents &&
                solutionComponents.map(item => (
                    <ul key={item.id}>
                        <li>
                            <p className={styles.componentName}>
                                {item?.component?.name}
                            </p>
                            <p className={styles.componentCapacity}>
                                {
                                    item?.component?.attributes[
                                        'Rated Capacity'
                                    ]?.value
                                }{' '}
                                {
                                    item?.component?.attributes[
                                        'Rated Capacity'
                                    ]?.unit_of_measurement
                                }
                            </p>
                            <p className={styles.componentType}>
                                {item?.component?.type?.name}
                            </p>
                            <p className={styles.componentCost}>
                                <NairaSymbol /> {item?.cost}
                            </p>
                            <p className={styles.componentQuantity}>
                                {item?.quantity}
                            </p>
                        </li>
                    </ul>
                ))}
        </div>
    )
}

AllComponents.propTypes = {
    solutionId: PropTypes.string,
    isOpen: PropTypes.bool,
}

export default AllComponents
