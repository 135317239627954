import PropTypes from 'prop-types'
import { detectDescriptionLabel } from '../utils'
import Description from '../_components/Description'
import Layout from '../_components/Layout/Layout'
import FinancialForm from './FinancialInfoForm'
import SeoComponent from 'src/components/Seo'

const FinancialInformation = ({ userType }) => {
    const description = detectDescriptionLabel('financial')

    return (
        <>
            <SeoComponent title="KYC - Financial Information | SunFi | Simplifying and Scaling Clean Energy" />
            <Layout
                description={
                    <Description
                        title={description?.title}
                        subTitle={description?.subTitle}
                        step={'financial-info'}
                    />
                }
                step={'financial-info'}
                form={<FinancialForm userType={userType} />}
                userType={userType}
            />
        </>
    )
}

FinancialInformation.propTypes = {
    userType: PropTypes.string.isRequired,
}
export default FinancialInformation
