import { useState, useEffect } from 'react'

function useMediaQueries() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const [isMobile, setIsMobile] = useState(false)
    const [isTablet, setIsTablet] = useState(false)
    const [isMobileOrTablet, setIsMobileOrTablet] = useState(false)
    const [largeTablet, setLargeTablet] = useState(false)

    useEffect(() => {
        setIsMobile(window.innerWidth < 501)
        setIsTablet(window.innerWidth < 700 && window.innerWidth > 500)
        setIsMobileOrTablet(window.innerWidth < 800)
        setLargeTablet(window.innerWidth < 998)

        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
            setIsMobile(window.innerWidth < 501)
            setIsTablet(window.innerWidth < 700 && window.innerWidth > 500)
            setIsMobileOrTablet(window.innerWidth < 800)
            setLargeTablet(window.innerWidth < 998)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return {
        isMobile,
        isTablet,
        isMobileOrTablet,
        largeTablet,
        width: windowSize.width,
        height: windowSize.height,
    }
}

export default useMediaQueries
