export const initialFinancialInfoValues = {
    isAuditReportOpen: false,
    isBankStatementOpen: false,
    auditReportSuccessUpload: [],
    bankStatementSuccessUpload: [],
    showThirdParty: true,
    showUploadBankStatement: true,
    bankStatement: [],
    auditReport: [],
    estimation_id: '',
    showBankStatementPasswordModal: false,
    showAuditReportPasswordModal: false,
    mono_code: '',
    okra_id: '',
    docUrl: '',
    monthly_revenue: '',
    auditReportPassword: '',
    auditFile: {},
    bvn: '',
    isBvnVerified: false,
    errors: {
        monthly_revenue: '',
        payment_method: '',
        bvn: '',
        audit_report: '',
        bankStatement: '',
        bvnError: '',
    },
    firstName: '',
    lastName: '',
    isNameMismatch: false,
}

export const SHOW_AUDIT_MODAL = 'show_audit_modal'
export const SHOW_BANKSTATEMENT_MODAL = 'show_bankstatement_modal'
export const SET_AUDIT_REPORT = 'set_audit_report'
export const SET_BANK_STATEMENT = 'set_bank_statement'
export const SET_AUDIT_REPORT_SUCCESS = 'set_audit_report_success'
export const SET_BANK_STATEMENT_SUCCESS = 'set_bank_statement_success'
export const SHOW_THIRD_PARTY = 'show_third_party'
export const SHOW_UPLOAD_BANK_STATEMENT = 'show_upload_bank_statement'
export const SHOW_BANKSTATEMENT_PASSWORD_MODAL =
    'show_bankstatement_password_modal'
export const SHOW_AUDITREPORT_PASSWORD_MODAL = 'show_auditreport_password_modal'
export const SET_MONO_CODE = 'set_mono_code'
export const SET_OKRA_ID = 'set_okra_id'
export const SET_DOC_URL = 'set_doc_url'
export const SET_MONTHLY_REVENUE = 'set_monthly_payment'
export const SET_ESTIMATION_ID = 'set_estimation_id'
export const SET_AUDIT_REPORT_PASSWORD = 'set_audit_report_password'
export const SET_AUDIT_FILE = 'set_audit_file'
export const SET_MONTHLY_REVENUE_ERROR = 'set_monthly_revenue'
export const SET_PAYMENT_METHOD_ERROR = 'set_payment_method'
export const SET_BVN = 'set_bvn'
export const SET_BVN_VERIFIED = 'set_bvn_verified'
export const SET_BVN_ERROR = 'set_bvn_error'
export const SET_BANK_STATEMENT_ERROR = 'set_bank_statement_error'
export const SET_UPDATE_REQUEST_ERRORS = 'set_update_request_errors'
export const SET_AUDIT_REPORT_ERROR = 'set_audit_report_error'
export const SET_FIRST_NAME = 'set_first_name'
export const SET_LAST_NAME = 'set_last_name'
export const SET_EMAIL = 'set_email'
export const SET_PHONE_NUMBER = 'set_phone_number'
export const SET_BANK_STATEMENT_NAME_MISMATCH =
    'set_bank_statement_name_mismatch'

export const financialInfoReducer = (
    state = initialFinancialInfoValues,
    action,
) => {
    switch (action.type) {
        case SHOW_AUDIT_MODAL:
            return { ...state, isAuditReportOpen: action.payload }
        case SHOW_BANKSTATEMENT_MODAL:
            return { ...state, isBankStatementOpen: action.payload }
        case SET_AUDIT_REPORT:
            return { ...state, auditReport: action.payload }
        case SET_BANK_STATEMENT:
            return { ...state, bankStatement: action.payload }
        case SET_AUDIT_REPORT_SUCCESS:
            return { ...state, auditReportSuccessUpload: action.payload }
        case SET_BANK_STATEMENT_SUCCESS:
            return { ...state, bankStatementSuccessUpload: action.payload }
        case SHOW_THIRD_PARTY:
            return { ...state, showThirdParty: action.payload }
        case SHOW_BANKSTATEMENT_PASSWORD_MODAL:
            return { ...state, showBankStatementPasswordModal: action.payload }
        case SHOW_AUDITREPORT_PASSWORD_MODAL:
            return { ...state, showAuditReportPasswordModal: action.payload }
        case SET_MONO_CODE:
            return { ...state, mono_code: action.payload }
        case SET_OKRA_ID:
            return { ...state, okra_id: action.payload }
        case SET_DOC_URL:
            return { ...state, docUrl: action.payload }
        case SET_MONTHLY_REVENUE:
            return { ...state, monthly_revenue: action.payload }
        case SET_ESTIMATION_ID:
            return { ...state, estimation_id: action.payload }
        case SET_AUDIT_REPORT_PASSWORD:
            return { ...state, auditReportPassword: action.payload }
        case SHOW_UPLOAD_BANK_STATEMENT:
            return { ...state, showUploadBankStatement: action.payload }
        case SET_AUDIT_FILE:
            return { ...state, auditFile: action.payload }
        case SET_BVN:
            return { ...state, bvn: action.payload }
        case SET_BVN_VERIFIED:
            return { ...state, isBvnVerified: action.payload }
        case SET_MONTHLY_REVENUE_ERROR:
            return {
                ...state,
                errors: { ...state.errors, monthly_revenue: action.payload },
            }
        case SET_PAYMENT_METHOD_ERROR:
            return {
                ...state,
                errors: { ...state.errors, payment_method: action.payload },
            }
        case SET_BVN_ERROR:
            return {
                ...state,
                errors: { ...state.errors, bvn: action.payload },
            }
        case SET_BANK_STATEMENT_ERROR:
            return {
                ...state,
                errors: { ...state.errors, bankStatement: action.payload },
            }
        case SET_UPDATE_REQUEST_ERRORS:
            return {
                ...state,
                errors: { ...state.errors, ...action.payload },
            }
        case SET_AUDIT_REPORT_ERROR:
            return {
                ...state,
                errors: { ...state.errors, audit_report: action.payload },
            }
        case SET_FIRST_NAME:
            return { ...state, firstName: action.payload }
        case SET_LAST_NAME:
            return { ...state, lastName: action.payload }
        case SET_EMAIL:
            return { ...state, email: action.payload }
        case SET_PHONE_NUMBER:
            return { ...state, phoneNumber: action.payload }
        case SET_BANK_STATEMENT_NAME_MISMATCH:
            return { ...state, isNameMismatch: action.payload }
        default:
            return state
    }
}
