/* eslint-disable  */

import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Drawer, Pagination } from 'antd'
import { format } from 'date-fns'
import { DateField } from 'src/components/InputFields'
import { getProviderCustomersApi } from 'src/api/providers/customers'
import { errorHandler } from 'src/utils/errorHandler'
import { NoFLoatingLabelInputFields } from 'src/components/InputFields'
import { AppButton } from 'src/components/Button'
import { InlineLoader } from 'src/components/Loader'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import Toast from 'src/components/Toast'
import EmptyState from 'src/components/EmptyState'
import CustomerListingTable from './CustomersListingTable'
import { isMobile } from 'src/utils/mediaQueries'

import styles from './customerlistings.module.scss'

export const customerType = [
    {
        id: 'all',
        label: 'All',
    },
    {
        id: 'INDIVIDUAL',
        label: 'Residential',
    },
    {
        id: 'BUSINESS',
        label: 'Businesses',
    },
]

const CustomersListings = () => {
    const { SubTitle, CustomersListingsWrapper } = styles

    const [inputs, setInputs] = useState({
        startDate: null,
    })

    const [drawerVisible, setDrawerVisible] = useState(false)
    const [filterMode, setFilterMode] = useState(false)
    const [filterLoading, setFilterLoading] = useState(false)
    const [showCalendar, setShowCalendar] = useState(0)
    const [customerTypeQuery, setCustomerTypeQuery] = useState([])

    const [searchText, setSearchText] = useState('')
    const [totalPages, setTotalPages] = useState(0)
    const [count, setCount] = useState(0)
    const [customers, setCustomers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [width, setWidth] = useState(window.innerWidth)

    const [currentStartDate, setCurrentStartDate] = useState(
        inputs.startDate === null ? null : new Date(inputs.startDate),
    )
    let reset
    // fetch customers
    const { isFetching: customersFetching, refetch: customersRefetch } =
        useQuery(
            ['get-customers', currentPage],
            () =>
                getProviderCustomersApi(
                    currentPage,
                    reset ? '' : searchText,
                    reset
                        ? ''
                        : currentStartDate
                        ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                        : '',
                    reset
                        ? ''
                        : customerTypeQuery[0] === 'all'
                        ? ''
                        : customerTypeQuery,
                ),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setCustomers(data?.data?.data)
                    setCount(data?.data?.count)
                    setTotalPages(data?.data?.total_pages)
                    if (filterMode) {
                        setFilterLoading(false)
                    }
                },
                onError: error => {
                    setToastError(true)
                    setErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    const handleDrawer = () => {
        setDrawerVisible(!drawerVisible)
    }

    const handleSearch = () => {
        setTotalPages(0)
        setCustomers([])
        customersRefetch()
    }

    const handleSearchInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
    }

    const handleOnBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    const handleAddFilter = () => {
        setFilterMode(true)
        setFilterLoading(true)
        customersRefetch()
        setDrawerVisible(false)
    }

    const setHandleDateChange = () => {
        setInputs(preState => ({
            ...preState,
            startDate: currentStartDate,
        }))
    }

    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(format(date, 'MM/dd/yyyy'))
        }
    }

    function getCustomerTypeCheckboxValues() {
        const customerTypeCheckboxes = document.querySelectorAll(
            `input[name="customerType"]:checked`,
        )
        let customerTypeValues = []
        customerTypeCheckboxes.forEach(checkbox => {
            customerTypeValues.push(checkbox.value)
        })
        setCustomerTypeQuery(customerTypeValues)
    }

    const handleReset = () => {
        reset = true
        setInputs(prev => ({
            ...prev,
            startDate: null,
        }))
        setCurrentStartDate(null)
        setFilterMode(false)
        setSearchText('')
        setTotalPages(0)
        setCustomers([])
        customersRefetch()
        setDrawerVisible(false)
        setCustomerTypeQuery([])
    }

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleWindowResize)

        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    useEffect(() => searchText === '' && customersRefetch(), [searchText])

    useEffect(() => {
        setTotalPages(0)
        setCustomers([])
        customersRefetch()
    }, [currentPage])

    return (
        <div className={CustomersListingsWrapper}>
            <Drawer
                placement="right"
                width="367px"
                visible={drawerVisible}
                onClose={handleDrawer}
                closable={width <= 500}
            >
                <h2 className="ACLFilterHeader">Filter Variables</h2>

                {/*filer by date*/}
                <h4 className="ACLFilterByDateHeader">Filter by Date Added</h4>
                <div className="ACLDateFieldsWrapper">
                    <div className="CustomersListingsDateStyle">
                        <DateField
                            placeholder="Date of Creation"
                            name="startDate"
                            dateValue={
                                inputs.startDate === null
                                    ? null
                                    : new Date(inputs.startDate)
                            }
                            handleDateChange={(name, date) =>
                                handleDateChange(name, date)
                            }
                            openCalendar={showCalendar === 1 ? true : false}
                            closeCalendarHandler={() => setShowCalendar(null)}
                            openCalendarHandler={() => setShowCalendar(1)}
                            setHandleDateChange={name =>
                                setHandleDateChange(name)
                            }
                        />
                    </div>
                </div>

                {/*    filter by interest*/}
                <h4 className="ACLFilterByInterestHeader">Lowest Rate</h4>
                {customerType.map((data, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => {
                                getCustomerTypeCheckboxValues()
                            }}
                            style={{
                                marginBottom: 20,
                            }}
                        >
                            <input
                                type="radio"
                                name="customerType"
                                id={data.id}
                                value={data.id}
                                style={{
                                    display: 'none',
                                }}
                            />
                            <label
                                htmlFor={data.id}
                                className="AdminPaymentPlanStatusLabel"
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 19.1667C4.93729 19.1667 0.833374 15.0627 0.833374 10C0.833374 4.93725 4.93729 0.833333 10 0.833333C15.0628 0.833333 19.1667 4.93725 19.1667 10C19.1667 15.0627 15.0628 19.1667 10 19.1667ZM9.08612 13.6667L15.567 7.18492L14.2708 5.88875L9.08612 11.0743L6.49287 8.48108L5.19671 9.77725L9.08612 13.6667Z"
                                        fill={
                                            customerTypeQuery.find(
                                                query => query === data.id,
                                            )
                                                ? 'var(--blue)'
                                                : ' var(--grey)'
                                        }
                                    />
                                </svg>
                                <p
                                    className="AdminPaymentPlanFilterStatusText"
                                    style={{
                                        marginBottom: 0,
                                    }}
                                >
                                    {data.label}
                                </p>
                            </label>
                        </div>
                    )
                })}
                {/*    buttons*/}
                <div className="ACLFilterButtonWrapper">
                    <AppButton
                        btnBgColor="#E2EEFF"
                        btnTextColor="#004AAD"
                        btnWidth={160}
                        btnHeight={56}
                        fontFamily="SF-Pro-Display-Semibold"
                        fontSize="14px"
                        handleClick={handleReset}
                    >
                        Reset Filter
                    </AppButton>
                    <AppButton
                        btnTextColor="#FFFFFF"
                        btnBgColor="#004AAD"
                        btnWidth={160}
                        btnHeight={56}
                        fontFamily="SF-Pro-Display-Semibold"
                        fontSize="14px"
                        handleClick={handleAddFilter}
                        disabled={filterLoading ? true : false}
                    >
                        {filterLoading ? <InlineLoader /> : 'Apply Filter'}
                    </AppButton>
                </div>
            </Drawer>
            {/*    search & filter, view buttons*/}

            <p className={SubTitle}>Select a Customer</p>
            <div className="ACLTopWrapper">
                <div className="ACLInputWrapper">
                    <NoFLoatingLabelInputFields
                        TextPrefilled={searchText ? true : false}
                        borderRadius="10px"
                        inputWidth="350px"
                        inputHeight="45px"
                        value={searchText}
                        type="text"
                        inputBackground="#F6F6F6"
                        iconType="search"
                        prefilled
                        inputPlaceholder="Search"
                        style={{ paddingBottom: 7 }}
                        handleChange={handleSearchInputChange}
                        onIconClick={handleSearch}
                        onKeyUp={handleKeyPress}
                        onBlur={handleOnBlur}
                    />
                    <div
                        className="AdminRequestListingFilterBox"
                        style={{ border: '1px solid #e5e5e5' }}
                        onClick={handleDrawer}
                    >
                        <svg
                            width="12"
                            height="15"
                            viewBox="0 0 12 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginTop: 4 }}
                        >
                            <path
                                d="M7.5 8.5V13L4.5 14.5V8.5L0 1.75V0.25H12V1.75L7.5 8.5ZM1.803 1.75L6 8.0455L10.197 1.75H1.803Z"
                                fill={'#1D2A30'}
                            />
                        </svg>

                        <p className="AdminRequestListingFilterText">Filters</p>
                    </div>
                </div>
            </div>

            <div className="ARLTableWrapper">
                {filterMode && (
                    <h1 className="ACLFilterModeText">
                        Filter Results ({count})
                    </h1>
                )}
                {customersFetching ? (
                    <ListingsSkeletalCards total={isMobile ? 2 : 3} />
                ) : (
                    <>
                        {toastError && (
                            <Toast messageType="error" message={errorMessage} />
                        )}

                        {customers.length > 0 ? (
                            <CustomerListingTable tableData={customers} />
                        ) : (
                            <div className="NoActivityStyle">
                                <EmptyState
                                    type="noActivity"
                                    title="No recent Credit Variables"
                                    subTitle="No Customers"
                                />
                            </div>
                        )}
                    </>
                )}
            </div>

            {customers.length > 0 && !customersFetching ? (
                <div className="ACLPaginationWrapper">
                    <Pagination
                        defaultCurrent={1}
                        current={currentPage}
                        total={parseInt(totalPages, 10)}
                        defaultPageSize={1}
                        showSizeChanger={false}
                        showTitle={false}
                        showLessItems
                        onChange={page => setCurrentPage(page)}
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default CustomersListings
