import PropTypes from 'prop-types'
import './countdowntimer.scss'
import { Progress } from 'antd'
import useCountdownTimer from './hook'

const CountdownTimer = ({
    seconds = 10,
    margin = '',
    onTimeEnd = () => {},
    isActive = undefined,
}) => {
    const { timeLeft } = useCountdownTimer(seconds, onTimeEnd, isActive)
    return (
        <div
            className={'CountdownTimer'}
            style={{ margin: margin }}
            data-testid="countdown-timer"
        >
            <div className={'CountdownTimer_Dotted'}>
                {Array.from({ length: 28 }).map((_, i) => (
                    <div className={'CountdownTimer_YellowDot'} key={i}></div>
                ))}
            </div>
            <Progress
                type="circle"
                percent={timeLeft * 10}
                strokeColor={'#FFC222'}
                trailColor="transparent"
                format={percent => `${percent / 10}`}
                className={'CountdownTimer_Progress'}
            />
        </div>
    )
}

CountdownTimer.propTypes = {
    seconds: PropTypes.number.isRequired,
    margin: PropTypes.string,
    onTimeEnd: PropTypes.func,
    isActive: PropTypes.bool,
}

export default CountdownTimer
