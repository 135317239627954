import PropTypes from 'prop-types'
import styles from './layout.module.scss'
import Header from '../Header'
import AccountSetupFooter from 'src/components/ConsumerFooter/AccountSetupFooter'

const Layout = ({
    form,
    description,
    userType,
    submitHandler,
    saveHandler,
    handleSaveAction,
    handleSubmitAction,
    submitLoading,
    step,
}) => {
    const { LayoutWrapper, MainWrapper } = styles

    return (
        <div className={LayoutWrapper}>
            <Header
                userType={userType}
                submitHandler={submitHandler}
                saveHandler={saveHandler}
                handleSaveAction={handleSaveAction}
                handleSubmitAction={handleSubmitAction}
                submitLoading={submitLoading}
                step={step}
            />
            <main className={MainWrapper}>
                <>{description}</>
                <div>{form}</div>
            </main>
            <AccountSetupFooter />
        </div>
    )
}

Layout.propTypes = {
    form: PropTypes.element.isRequired,
    description: PropTypes.node.isRequired,
    userType: PropTypes.string.isRequired,
    submitHandler: PropTypes.func,
    saveHandler: PropTypes.func,
    handleSaveAction: PropTypes.func,
    handleSubmitAction: PropTypes.func,
    submitLoading: PropTypes.bool,
    step: PropTypes.number || PropTypes.string,
}

export default Layout
