export const setLocalStorageWithExpiry = (key, value, ttl) => {
    const now = Date.now()
    const item = {
        value, // The actual data
        expiry: now + ttl, // The expiration time
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export const getLocalStorageWithExpiry = key => {
    const itemStr = localStorage.getItem(key)

    // If the item doesn't exist, return null
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = Date.now()

    // Compare the current time with the item's expiry time
    if (now > item.expiry) {
        // If expired, remove the item and return null
        localStorage.removeItem(key)
        return null
    }

    return item.value
}
