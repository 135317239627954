import PropTypes from 'prop-types'
import ConsumerModal from 'src/components/Modal/ConsumerModal'
import { ReactComponent as CloseIcon } from 'src/assets/images/close-circle-white.svg'
import { ReactComponent as SolarCity } from 'src/assets/images/solar-city-banner.svg'
import { ConsumerButton } from 'src/components/Button'
import { isMobile } from 'src/utils/mediaQueries'
import styles from './closedclustermodal.module.scss'

const ClosedClusterModal = ({ showModal, setShowModal, promoType }) => {
    const {
        ClusterModalWrapper,
        ClusterModalDiv,
        ClusterModalImagesWrapper,
        ClusterModalCloseIcon,
        clusterImageStyle,
    } = styles

    const closedClustersImages = {
        'pbn-promo':
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/PremiaBN+1.svg',
        'ventures-platform-promo':
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Ventures+Platform.svg',
        'damilola-ola-promo':
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Damilola+Ola.jpeg',
        'clafiya-promo':
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Clafiya+logo.svg',
        'betking-promo':
            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Betking-logo.webp',
        'sms-promo': '',
    }

    const clusterImageUrl = closedClustersImages[promoType]
    const organisationFirstName = promoType.split('-')

    return (
        <ConsumerModal
            showModal={showModal}
            onCancel={() => setShowModal(false)}
            modalHeight="434px"
            modalWidth="1152px"
            top="20%"
            closable={false}
            content={
                <section className={ClusterModalWrapper}>
                    <div className={ClusterModalDiv}>
                        {clusterImageUrl && (
                            <img
                                src={clusterImageUrl}
                                alt="closed cluster image"
                                className={clusterImageStyle}
                                style={{
                                    borderRadius:
                                        promoType === 'damilola-ola-promo' &&
                                        '18px',
                                }}
                            />
                        )}
                        <h3>
                            {`Welcome to SunFi, we have created a set of packages
                            specifically for ${organisationFirstName[0]}'s network.`}
                        </h3>
                        <p>
                            Get access to some already available product, all
                            you just need to do is choose the product that can
                            best power what you want to power and choose a plan
                        </p>
                        <ConsumerButton
                            btnTextColor="#FFFFFF"
                            btnWidth={isMobile ? '100%' : '253px'}
                            btnHeight="53px"
                            btnFontSize="14px"
                            btnBgColor="#004AAD"
                            handleClick={() => setShowModal(false)}
                        >
                            Get Started
                        </ConsumerButton>
                    </div>
                    {!isMobile && (
                        <div className={ClusterModalImagesWrapper}>
                            <CloseIcon
                                className={ClusterModalCloseIcon}
                                onClick={() => setShowModal(false)}
                            />
                            <SolarCity />
                        </div>
                    )}
                </section>
            }
        />
    )
}

ClosedClusterModal.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    promoType: PropTypes.string,
}

export default ClosedClusterModal
