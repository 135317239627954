import PropTypes from 'prop-types'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import IdentityModal from '../../../IdentityInformation/components/IdentityModal'
import { useUploadImage } from '../../../IdentityInformation/hook'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useQueryClient } from 'react-query'

const SelfieImageModal = ({ showModal, closeModal }) => {
    const queryClient = useQueryClient()

    const onSelfieUploadSuccess = () => {
        queryClient.invalidateQueries('fetch-dynamic-kyc-info')
        closeModal()
    }
    const {
        step,
        setStep,
        selected,
        setSelected,
        uploading,
        successUpload,
        setSuccessUpload,
        pictureUrl,
        setPictureUrl,
        width,
        setWidth,
        modalFooterLeftContent,
        modalPrimaryBtnText,
        modalTitle,
        disableModalPrimaryBtn,
        handleImageSteps,
        webcamRef,
        handleRetake,
        selfieLoading,
        IdentitySelfieRef,
    } = useUploadImage(closeModal, onSelfieUploadSuccess)
    const { isMobile } = useMediaQueries()

    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={() => closeModal()}
            title={modalTitle}
            footerLeftContent={
                step === 3 && selfieLoading ? 'none' : modalFooterLeftContent
            }
            primaryBtnText={modalPrimaryBtnText}
            primaryBtnDisabled={disableModalPrimaryBtn}
            handlePrimaryBtnClick={handleImageSteps}
            primaryBtnLoading={step === 3 && selfieLoading}
            primaryBtnWidth={'160px'}
            primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            secondaryBtnText="Retake"
            handleSecondaryBtnClick={() => {
                step === 3 && handleRetake()
            }}
        >
            <IdentityModal
                step={step}
                setStep={setStep}
                selected={selected}
                setSelected={setSelected}
                webcamRef={webcamRef}
                IdentitySelfieRef={IdentitySelfieRef}
                successUpload={successUpload}
                setSuccessUpload={setSuccessUpload}
                pictureUrl={pictureUrl}
                setPictureUrl={setPictureUrl}
                uploading={uploading}
                width={width}
                setWidth={setWidth}
            />
        </ConsumerModalBase>
    )
}

SelfieImageModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
}

export default SelfieImageModal
