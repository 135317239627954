import PropTypes from 'prop-types'
import ActionModal from 'src/components/ActionModal'

const CloseureActionModal = ({
    actionModalOpen,
    closeModal,
    estimationType,
    actionHandler,
    loading,
    onCancel,
    errorMessage,
    toastError,
}) => {
    return (
        <>
            <ActionModal
                headerText={`Close ${estimationType}`}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionModalOpen={actionModalOpen}
                closable
                closeModal={closeModal}
                onCancel={onCancel}
                actionType="warning"
                actionText="Close"
                actionHandler={actionHandler}
                loading={loading}
                noBackLink
                errorMessage={errorMessage}
                toastError={toastError}
            />
        </>
    )
}

CloseureActionModal.propTypes = {
    actionModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    estimationType: PropTypes.string,
    actionHandler: PropTypes.func,
    loading: PropTypes.bool,
    onCancel: PropTypes.func,
    errorMessage: PropTypes.string,
    toastError: PropTypes.bool,
}

export default CloseureActionModal
