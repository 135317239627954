import { useMemo, useState } from 'react'
import MonoConnect from '@mono.co/connect.js'

export const useMono = ({
    onClose = () => {
        console.log('closed mono widget')
    },
    onLoad = () => {
        console.log('loaded mono widget')
    },
    onSuccess,
    data,
}) => {
    const [successCode, setSuccessCode] = useState(null)
    const handleSuccess = code => {
        setSuccessCode(code)
    }

    const customer = {
        name: `${data?.first_name} ${data?.last_name}`,
        email: data?.email,
    }

    const monoConnect = useMemo(() => {
        const monoInstance = new MonoConnect({
            onClose,
            onLoad,
            // eslint-disable-next-line no-unused-vars
            onSuccess: data => {
                onSuccess(data)
                handleSuccess(data)
            },
            key: process.env.REACT_APP_MONO_CONNECT_API_KEY,
            ...(process.env.REACT_APP_FLAG_USE_MONO_V2 === 'true' && {
                data: { customer },
            }),
        })

        monoInstance.setup()
        return monoInstance

        //eslint-disable-next-line
    }, [onClose, onLoad, onSuccess])

    return {
        monoConnect,
        successCode,
        handleSuccess,
    }
}
