import { PropTypes } from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { ConsumerButton } from 'src/components/Button'
import { usePullEnergyNeeds } from './hooks/usePullEnergyNeeds'
import UpdateNeeds from 'src/components/UpdateNeeds'
import ActionModal from 'src/components/ActionModal'
import { RecommendedSkeletalCards } from 'src/components/SkeletalCards'
import PackageItem from './PackageItem'
import ProfileItem from './ProfileItem'
import { getActionModalData } from './data'
import styles from './pullenergyneeds.module.scss'

const PullEnergyNeedsModal = ({
    showModal,
    onClose,
    handlePullEnergyNeeds,
    profileName,
}) => {
    const {
        selectedProfile,
        selectedPackage,
        handleButtonClick,
        handleClose,
        handleViewPackage,
        handleViewProfile,
        profiles,
        paginationBlock,
        profilePackages,
        selectedEnergyNeeds,
        actionModalOpen,
        actionModalHandler,
        actionModalType,
        handleActionModalCancel,
        handleUseThisPackageClick,
        currentScreen,
        setSelectedPackage,
        profilePackagesFetching,
    } = usePullEnergyNeeds()

    const actionModalData = getActionModalData(
        actionModalType,
        profileName,
        selectedPackage,
    )

    return (
        <>
            {actionModalOpen ? (
                <ActionModal
                    closable
                    actionModalOpen={actionModalOpen}
                    headerText={actionModalData.headerText}
                    subTitle={actionModalData.subTitle}
                    actionHandler={() =>
                        actionModalHandler(onClose, handlePullEnergyNeeds)
                    }
                    actionType={actionModalData.actionType}
                    actionText={actionModalData.actionText}
                    noCancelBtn={actionModalData.noCancelBtn}
                    closeModal={() => handleActionModalCancel(onClose)}
                    onCancel={() => handleActionModalCancel(onClose)}
                    noBackLink
                />
            ) : (
                <SecondaryModal
                    v2
                    closable={currentScreen !== 3}
                    showModal={showModal}
                    onCancel={() => handleClose(onClose)}
                    modalWidth="605px"
                    modalHeight="620px"
                    content={
                        <>
                            {selectedPackage && currentScreen === 3 ? (
                                <UpdateNeeds
                                    isPullEnergyNeeds
                                    actionType={'preview'}
                                    energyNeeds={selectedEnergyNeeds}
                                    selectedAppliances={selectedEnergyNeeds}
                                    updateEnergyNeeds={
                                        handleUseThisPackageClick
                                    }
                                    underscoredKeys
                                    title={'Energy Needs'}
                                    description={`Packages under ${selectedProfile?.name}`}
                                    btnText={'Use this package'}
                                    handleSecondaryButtonClick={
                                        handleButtonClick
                                    }
                                    closeModalAfterUpdate={false}
                                    closeModal={() => handleClose(onClose)}
                                />
                            ) : (
                                <div className={styles.Container}>
                                    <div className={styles.ModalHeader}>
                                        <h3>
                                            {selectedProfile &&
                                            currentScreen === 2
                                                ? `Packages under ${selectedProfile.name}`
                                                : 'All Profiles'}
                                        </h3>
                                        <p>
                                            {selectedProfile &&
                                            currentScreen === 2
                                                ? 'Select a package you’d like to ...'
                                                : 'View a profile with a package you need'}
                                        </p>
                                    </div>
                                    <div className={styles.ModalBody}>
                                        {selectedProfile &&
                                        currentScreen === 2 ? (
                                            <>
                                                <div
                                                    className={
                                                        styles.ContentBox
                                                    }
                                                >
                                                    {profilePackagesFetching &&
                                                    !selectedPackage ? (
                                                        <RecommendedSkeletalCards />
                                                    ) : (
                                                        <>
                                                            {profilePackages.map(
                                                                (
                                                                    pkg,
                                                                    index,
                                                                ) => (
                                                                    <PackageItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        useUnderscoredKeys
                                                                        title={
                                                                            pkg.name
                                                                        }
                                                                        appliances={
                                                                            pkg.appliances
                                                                        }
                                                                        handleView={() =>
                                                                            handleViewPackage(
                                                                                pkg,
                                                                            )
                                                                        }
                                                                        handleUse={() => {
                                                                            setSelectedPackage(
                                                                                pkg,
                                                                            )
                                                                            handleUseThisPackageClick(
                                                                                pkg.appliances,
                                                                            )
                                                                        }}
                                                                    />
                                                                ),
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        styles.PaginationWrapper
                                                    }
                                                >
                                                    {paginationBlock()}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={
                                                        styles.ContentBox
                                                    }
                                                >
                                                    {profiles.map(
                                                        (profile, index) => (
                                                            <ProfileItem
                                                                key={index}
                                                                title={
                                                                    profile.name
                                                                }
                                                                handleClick={() =>
                                                                    handleViewProfile(
                                                                        profile,
                                                                    )
                                                                }
                                                            />
                                                        ),
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        styles.PaginationWrapper
                                                    }
                                                >
                                                    {paginationBlock()}
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    {selectedProfile && currentScreen === 2 && (
                                        <div className={styles.ModalFooter}>
                                            <ConsumerButton
                                                btnBgColor="#E2EEFF"
                                                btnTextColor="var(--blue)"
                                                btnWidth={'160px'}
                                                btnHeight={'56px'}
                                                handleClick={() =>
                                                    handleButtonClick(onClose)
                                                }
                                                hover
                                            >
                                                Go back
                                            </ConsumerButton>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    }
                />
            )}
        </>
    )
}

PullEnergyNeedsModal.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
    handlePullEnergyNeeds: PropTypes.func,
    profileName: PropTypes.string,
}

export default PullEnergyNeedsModal
