import { useMutation } from 'react-query'
import { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
    getRecommendationStatus,
    getRecommendationStatusAuthenticatedUser,
    getFormResponse,
    getRecommendation,
    getRecommendationsForAuthUser,
} from 'src/api/shoppingExperience/landingPage'
import { useAppContext } from 'src/appContext/AppContext'
import { fieldErrorHandler } from 'src/utils/errorHandler'
import { recommendationsErrorFormatter } from '../utils'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'

const useGetRecommendations = (
    hideDrawer,
    setEditedPackages,
    page,
    auth_user_id = '',
    estimation_id = '',
) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const { setFormResponse, setRecommendations } = useAppContext()
    const [errorState, setErrorState] = useState('')
    const { errorAlert } = useCustomToast()
    const { isMobile } = useMediaQueries()
    const pageTitle =
        'KYC - Recommendations | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'

    const getFormResponseMutation = useMutation({
        mutationFn: payload => getFormResponse(payload),
        onSuccess: response => {
            setFormResponse(response.data)
        },
    })
    const getRecommendationMutation = useMutation({
        mutationFn: payload => getRecommendation(payload),
        onSuccess: response => {
            setRecommendations(response.data)
            setLoading(false)
            history.push('/consumer/systems')
            page === 'edit' && hideDrawer()
            page === 'edit' && setEditedPackages(true)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseGetSolarRecommendations'].action,
                eventTrackers['sseGetSolarRecommendations'].label,
                eventTrackers['sseGetSolarRecommendations'].category,
                ['MP', 'GA'],
                'event',
                {
                    event: response.data,
                },
            )
        },
    })

    // for authenticated users
    const getRecommendationAuthMutation = useMutation({
        mutationFn: () => getRecommendationsForAuthUser(estimation_id),
        onSuccess: response => {
            setRecommendations(response.data)
            setLoading(false)
            history.push({
                pathname: '/consumer/systems',
                state: {
                    isAuthenticatedUser: true,
                    estimation_id: estimation_id,
                    auth_user_id: auth_user_id,
                },
            })
            page === 'edit' && hideDrawer()
            page === 'edit' && setEditedPackages(true)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['sseGetSolarRecommendations'].action,
                eventTrackers['sseGetSolarRecommendations'].label,
                eventTrackers['sseGetSolarRecommendations'].category,
                ['MP', 'GA'],
                'event',
                {
                    event: response.data,
                },
            )
        },
    })

    function createStream(user_id) {
        const sseSource = new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET_URL}/solution-recommendation/stream/${user_id}/`,
        )

        sseSource.onmessage = event => {
            if (event.data === 'READY') {
                getRecommendationMutation.mutate({ user_id })
                getFormResponseMutation.mutate({ user_id })
            }
        }

        sseSource.onerror = () => {
            setLoading(false)
            setErrorState('something went wrong, please try again later')
            errorAlert(
                'something went wrong, please try again later',
                isMobile ? 320 : 511,
                110,
            )
            sseSource.close()
        }
    }

    // for authenticated users
    function createAuthStream() {
        const sseSource = new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET_URL}/solution-recommendation/stream/${auth_user_id}/`,
        )

        sseSource.onmessage = event => {
            if (event.data === 'READY') {
                getRecommendationAuthMutation.mutate()
            }
        }

        sseSource.onerror = () => {
            setLoading(false)
            setErrorState('something went wrong, please try again later')
            sseSource.close()
        }
    }

    const validateRequestInfoMutation = useMutation({
        mutationFn: payload => getRecommendationStatus(payload),
        onSuccess: (response, { user_id }) => {
            createStream(user_id)
        },
        onError: error => {
            let formattedMessage = ''
            const response = fieldErrorHandler(error?.response?.data)
            if (response) {
                response.forEach(error => {
                    formattedMessage += recommendationsErrorFormatter(error)
                })
            }

            const errorsResponse = formattedMessage
                ? formattedMessage
                : 'something went wrong, please try again later'

            setErrorState(errorsResponse)
            setLoading(false)
        },
    })

    const validateRequestInfoAuthUserMutation = useMutation({
        mutationFn: ({ id, payload }) =>
            getRecommendationStatusAuthenticatedUser(id, payload),
        onSuccess: () => {
            createAuthStream()
        },
        onError: error => {
            let formattedMessage = ''
            const response = fieldErrorHandler(error?.response?.data)
            if (response) {
                response.forEach(error => {
                    formattedMessage += recommendationsErrorFormatter(error)
                })
            }

            const errorsResponse = formattedMessage
                ? formattedMessage
                : 'something went wrong, please try again later'

            setErrorState(errorsResponse)
            setLoading(false)
        },
    })

    return {
        validateRequestInfoMutation,
        validateRequestInfoAuthUserMutation,
        getRecommendationMutation,
        loading,
        setLoading,
        errorState,
        setErrorState,
    }
}

export default useGetRecommendations
