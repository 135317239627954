import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Button from 'src/components/Button'
import userImg from 'src/assets/images/user2.svg'
import userAddImg from 'src/assets/images/user-add.svg'
import userWhiteImg from 'src/assets/images/user-white.svg'
import userAddWhiteImg from 'src/assets/images/user-add-white.svg'

import style from './customertypemodal.module.scss'

const CustomerTypeModal = ({
    closeModal,
    customerType,
    setCustomerType,
    setShowCustomerType,
}) => {
    const {
        ModalWrapper,
        HeaderWrapper,
        HeaderStyle,
        SubheaderStyle,
        SectionWrapper,
        UserImgStyle,
        SectionTextWrapper,
        SectionHeader,
        SectionText,
        CustomerTypeBtnWrapper,
    } = style

    const history = useHistory()
    return (
        <div className={ModalWrapper}>
            <div className={HeaderWrapper}>
                <header className={HeaderStyle}>Customer Type</header>
                <h3 className={SubheaderStyle}>
                    Which type of customer do you want to create a payment plan
                    for?
                </h3>
            </div>
            <section
                className={SectionWrapper}
                onClick={() => setCustomerType('new')}
                style={{
                    background: customerType === 'new' ? '#E2EEFF' : '#fff',
                    border:
                        customerType === 'new'
                            ? '0.5px solid #004AAD'
                            : '0.5px solid #E5E5E5',
                }}
            >
                <div
                    className={UserImgStyle}
                    style={{
                        background: customerType === 'new' ? '#004AAD' : '#fff',
                    }}
                >
                    <img
                        src={
                            customerType === 'new'
                                ? userAddWhiteImg
                                : userAddImg
                        }
                        alt="new-user-icon"
                    />
                </div>

                <div className={SectionTextWrapper}>
                    <h3 className={SectionHeader}>New Customer</h3>
                    <p className={SectionText}>
                        I want to create for a new customer
                    </p>
                </div>
            </section>
            <section
                className={SectionWrapper}
                onClick={() => setCustomerType('existing')}
                style={{
                    background:
                        customerType === 'existing' ? '#E2EEFF' : '#fff',
                    border:
                        customerType === 'existing'
                            ? '0.5px solid #004AAD'
                            : '0.5px solid #E5E5E5',
                }}
            >
                <div
                    className={UserImgStyle}
                    style={{
                        background: customerType === 'existing' && '#004AAD',
                    }}
                >
                    <img
                        src={
                            customerType === 'existing' ? userWhiteImg : userImg
                        }
                        alt="new-user-icon"
                    />
                </div>
                <div className={SectionTextWrapper}>
                    <h3 className={SectionHeader}>Existing Customer</h3>
                    <p className={SectionText}>
                        I want to create for an existing customer
                    </p>
                </div>
            </section>
            <div className={CustomerTypeBtnWrapper}>
                <Button
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnWidth="160px"
                    btnHeight="56px"
                    disabled={customerType.length === 0}
                    handleClick={() => {
                        closeModal()
                        if (customerType === 'new') {
                            setShowCustomerType(true)
                        } else {
                            history.push({
                                pathname: '/app/payment-plans/create',
                                state: { customerType },
                            })
                        }
                    }}
                >
                    Create Payment Plans
                </Button>
            </div>
        </div>
    )
}

CustomerTypeModal.propTypes = {
    closeModal: PropTypes.func,
    setCustomerType: PropTypes.func,
    customerType: PropTypes.string,
    setShowCustomerType: PropTypes.func,
}

export default CustomerTypeModal
