import AirConditioner from 'src/assets/appliances/air-conditioner.svg'
import General from 'src/assets/appliances/generic-appliance.svg'
import CakeMixer from 'src/assets/appliances/cake-mixer.svg'
import Speaker from 'src/assets/appliances/speaker.svg'
import TV from 'src/assets/appliances/tv.svg'
import MediumFridge from 'src/assets/appliances/medium-fridge.svg'
import SmallFridge from 'src/assets/appliances/bed-size-fridge.svg'
import AirFryer from 'src/assets/appliances/air-fryer.svg'
import WashingMachine from 'src/assets/appliances/washing-machine.svg'
import Phone from 'src/assets/appliances/phone.svg'
import Laptop from 'src/assets/appliances/laptop.svg'
import StandingFan from 'src/assets/appliances/standing-fan.svg'
import CeilingFan from 'src/assets/appliances/ceiling-fan.svg'
import WaterDispenser from 'src/assets/appliances/water-dispenser.svg'
import Xbox from 'src/assets/appliances/xbox.svg'
import Playstation from 'src/assets/appliances/playstation.svg'
import MicroWave from 'src/assets/appliances/microwave.svg'
import Freezer from 'src/assets/appliances/freezer.svg'
import LightBulb from 'src/assets/appliances/light-bulb.svg'
import DishWasher from 'src/assets/appliances/dish-washer.svg'
import SewingMachine from 'src/assets/appliances/sewing-machine.svg'
import Chandelier from 'src/assets/appliances/chandelier.svg'
import Heater from 'src/assets/appliances/heater.svg'
import HairDryer from 'src/assets/appliances/hair-dryer.svg'
import BigFridge from 'src/assets/appliances/big-fridge.svg'
import RiceCooker from 'src/assets/appliances/rice-cooker.svg'
import CoffeeMaker from 'src/assets/appliances/coffee-maker.svg'
import Humidifier from 'src/assets/appliances/humidifier.svg'
import Blender from 'src/assets/appliances/blender.svg'
import Toaster from 'src/assets/appliances/toaster.svg'
import Kettle from 'src/assets/appliances/kettle.svg'
import Iron from 'src/assets/appliances/iron.svg'
import HotPlate from 'src/assets/appliances/hot-plate.svg'
import MixerBlender from 'src/assets/appliances/mixer-blender.svg'
import HandMixer from 'src/assets/appliances/hand-mixer.svg'

export const applianceIcon = name => {
    if (name.includes('conditioner')) {
        return AirConditioner
    } else if (name.toLowerCase().includes('television')) {
        return TV
    } else if (name.toLowerCase().includes('washing')) {
        return WashingMachine
    } else if (name.toLowerCase().includes('standing fan')) {
        return StandingFan
    } else if (name.toLowerCase().includes('ceiling fan')) {
        return CeilingFan
    } else if (name.toLowerCase().includes('fan')) {
        return StandingFan
    } else if (name.toLowerCase().includes('washing machine')) {
        return WashingMachine
    } else if (name.toLowerCase().includes('xbox')) {
        return Xbox
    } else if (name.toLowerCase().includes('toaster')) {
        return Toaster
    } else if (name.toLowerCase().includes('playstation')) {
        return Playstation
    } else if (name.toLowerCase().includes('hot plate')) {
        return HotPlate
    } else if (name.toLowerCase().includes('freezer')) {
        return Freezer
    } else if (name.toLowerCase().includes('microwave')) {
        return MicroWave
    } else if (name.toLowerCase().includes('hair')) {
        return HairDryer
    } else if (name.toLowerCase().includes('humidifier')) {
        return Humidifier
    } else if (name.toLowerCase().includes('chandelier')) {
        return Chandelier
    } else if (name.toLowerCase().includes('bulb')) {
        return LightBulb
    } else if (name.toLowerCase().includes('led')) {
        return LightBulb
    } else if (name.toLowerCase().includes('sound')) {
        return Speaker
    } else if (name.toLowerCase().includes('kettle')) {
        return Kettle
    } else if (name.toLowerCase().includes('rice')) {
        return RiceCooker
    } else if (name.toLowerCase().includes('iron')) {
        return Iron
    } else if (name.toLowerCase().includes('blender')) {
        return Blender
    } else if (name.toLowerCase().includes('sewing')) {
        return SewingMachine
    } else if (name.toLowerCase().includes('juice')) {
        return MixerBlender
    } else if (name.toLowerCase().includes('heater')) {
        return Heater
    } else if (name.toLowerCase().includes('coffee')) {
        return CoffeeMaker
    } else if (name.toLowerCase().includes('dish')) {
        return DishWasher
    } else if (name.toLowerCase().includes('hand mixer')) {
        return HandMixer
    }

    switch (name) {
        case 'Cake Mixer':
            return CakeMixer
        case 'Speaker':
            return Speaker
        case 'Fridge (Medium Size)':
            return MediumFridge
        case 'Fridge (Big Size)':
            return BigFridge
        case 'Fridge (Small Size)':
            return SmallFridge
        case 'Air Fryer':
            return AirFryer
        case 'Phone':
            return Phone
        case 'Laptop' || 'Desktop':
            return Laptop
        case 'Water Dispenser':
            return WaterDispenser
        default:
            return General
    }
}
