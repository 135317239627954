/* eslint-disable react/prop-types */
import Formbox from '../FormBox'
import ButtonComponent from '../Button'
// import NavBar from '../AuthenticatedNavBar/NavBar'

import styles from './confirmation.module.scss'

const Confirmation = ({
    imgUrl,
    header,
    subHeader,
    bottomText,
    redirectUrl,
    children,
    subHeaderStyle,
    btnText,
    boxPadding,
    mailTo = false,
    onButtonClick,
    scrollIndicator,
    scrollWidth,
    height,
    fontFamily,
    showImage,
    headerMarginBottom,
    btnWidth,
    fontSize,
    formWidth,
    formHeight,
}) => {
    const {
        Wrapper,
        ButtonWrapper,
        Header,
        Subheader,
        ImageWrapper,
        SlideContainer,
        Slider,
        SliderText,
        SlideWrapper,
    } = styles

    return (
        <>
            <Formbox width={formWidth} height={formHeight} text={bottomText} url={redirectUrl} padding={boxPadding}>
                <div className={Wrapper}>
                    {showImage ? (
                        ''
                    ) : (
                        <div className={ImageWrapper}>
                            <img src={imgUrl} alt="confirmation Icon" />
                        </div>
                    )}
                    <div>
                        <p
                            className={Header}
                            style={{
                                fontFamily: fontFamily,
                                marginBottom: headerMarginBottom,
                                fontSize: fontSize || '22px',
                            }}
                        >
                            {header}{' '}
                        </p>
                        <p
                            className={Subheader}
                            style={{
                                marginBottom: subHeaderStyle,
                                height: height && height,
                            }}
                        >
                            {subHeader}
                        </p>
                    </div>
                    {children}

                    {scrollIndicator ? (
                        <div className={SlideWrapper}>
                            <div className={SlideContainer}>
                                <p
                                    className={Slider}
                                    style={{ width: `${scrollWidth}%` }}
                                ></p>
                            </div>
                            <p className={SliderText}>
                                Please wait, redirecting....{' '}
                            </p>
                        </div>
                    ) : (
                        <div className={ButtonWrapper}>
                            <ButtonComponent
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                handleClick={!mailTo ? onButtonClick : null}
                                btnWidth={btnWidth || '160px'}
                            >
                                {mailTo ? (
                                    <a href="mailto:">{btnText}</a>
                                ) : (
                                    btnText
                                )}
                            </ButtonComponent>
                        </div>
                    )}
                </div>
            </Formbox>
        </>
    )
}

export default Confirmation
