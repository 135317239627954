import helpIcon from 'src/assets/images/help-circle-black.svg'
import PropTypes from 'prop-types'
import './bvn-data.scss'
import attachIcon from 'src/assets/images/attach-blue.svg'
import { Tooltip } from 'antd'
import { useSSERetrieveBasicInfo } from 'src/api/shoppingExperience/kyc/queries'
import { formatGender, getAgeInYearsFromDob } from 'src/utils/formatting'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { useState } from 'react'

const BvnData = ({ title, handleOverride, page }) => {
    const { sseBasicInfoData } = useSSERetrieveBasicInfo({
        enabled: true,
    })
    const bvnInfo =
        sseBasicInfoData?.data?.data?.financial_verification?.bvn?.context
    const customerSelfie =
        sseBasicInfoData?.data?.data?.consumer?.user?.avatar_url
    const imageAnalysis =
        sseBasicInfoData?.data?.data?.consumer?.user?.avatar_meta_data
    const min_age = imageAnalysis?.facial_recognition?.age_analysis?.value[0]
    const max_age = imageAnalysis?.facial_recognition?.age_analysis?.value[1]
    const gender = imageAnalysis?.facial_recognition?.gender_analysis?.value
    const [showBvnImageModal, setShowBvnImageModal] = useState(false)
    const [showUserImageModal, setShowUserImageModal] = useState(false)
    const allChecksPassed = bvnInfo?.all_checks_passed

    return (
        <>
            <SecondaryModal
                v2
                showModal={showBvnImageModal}
                onCancel={() => setShowBvnImageModal(false)}
                modalHeight="450px"
                modalWidth="550px"
                modalClass="adminModal"
                closable
                title="Image returned"
                content={
                    <>
                        <div className="imageModalContainer">
                            <div className="imageModalContainer">
                                <img
                                    src={
                                        'data:image/png;base64,' +
                                        bvnInfo?.verified_data?.photoId
                                    }
                                    alt="Image returned from BVN"
                                />
                            </div>
                        </div>
                    </>
                }
            />
            <SecondaryModal
                v2
                showModal={showUserImageModal}
                onCancel={() => setShowUserImageModal(false)}
                modalHeight="450px"
                modalWidth="550px"
                modalClass="adminModal"
                closable
                title="Image returned"
                content={
                    <>
                        <div className="imageModalContainer">
                            <img
                                src={customerSelfie}
                                alt="Image returned from BVN"
                            />
                        </div>
                    </>
                }
            />
            <div
                className="bvnDataContainer"
                style={{
                    margin: page === 'business-verification' ? '10px 0' : '',
                    border:
                        page === 'business-verification'
                            ? '1px solid #e2eeff'
                            : '',
                }}
            >
                <div className="titleSection">
                    <div className="title">
                        <h3>{title}</h3>
                        {page !== 'business-verification' && (
                            <Tooltip
                                overlayStyle={{
                                    padding: '9px 8px',
                                    maxWidth: '178px',
                                }}
                                overlayInnerStyle={{
                                    fontSize: '12px',
                                    fontFamily: 'SF-Pro-Display',
                                }}
                                color="#070C18"
                                placement="bottom"
                                title="This data  is being matched  with the data result from the selfie /photo the consumer added on their KYC"
                            >
                                <img src={helpIcon} />
                            </Tooltip>
                        )}
                    </div>
                    {page !== 'business-verification' && allChecksPassed && (
                        <div className={`tag success`}>PASSED</div>
                    )}
                    {page !== 'business-verification' && !allChecksPassed && (
                        <button
                            className="override_btn"
                            onClick={handleOverride}
                        >
                            Override
                        </button>
                    )}
                </div>
                <div className="identityMetaData">
                    <div>
                        {bvnInfo?.comparison_data.find(
                            rep => rep?.criteria === 'gender',
                        )?.match === false &&
                        page !== 'business-verification' ? (
                            <div className={`check_failure`}>
                                <h4>Gender</h4> <div>FAILED</div>
                            </div>
                        ) : (
                            <h4>Gender</h4>
                        )}
                        <p>{formatGender(bvnInfo?.verified_data?.gender)}</p>
                    </div>
                    <div>
                        {bvnInfo?.comparison_data.find(
                            rep => rep?.criteria === 'dob',
                        )?.match === false &&
                        page !== 'business-verification' ? (
                            <div className={`check_failure`}>
                                <h4>Age</h4> <div>FAILED</div>
                            </div>
                        ) : (
                            <h4>Age</h4>
                        )}
                        <p>
                            {getAgeInYearsFromDob(bvnInfo?.verified_data?.dob)}
                        </p>
                    </div>
                    <div>
                        <h4>Date of birth</h4>
                        <p>{bvnInfo?.verified_data?.dob}</p>
                    </div>
                    <div>
                        {bvnInfo?.comparison_data.find(
                            rep => rep?.criteria === 'photo',
                        )?.match === false &&
                        page !== 'business-verification' ? (
                            <div className={`check_failure`}>
                                <h4>Image returned</h4> <div>FAILED</div>
                            </div>
                        ) : (
                            <h4>Image returned</h4>
                        )}
                        <div
                            className={'imageAnalyzed'}
                            onClick={() => setShowBvnImageModal(true)}
                        >
                            <img
                                src={attachIcon}
                                width="18px"
                                alt="Image returned from BVN"
                            />
                            <p>Image</p>
                        </div>
                    </div>
                </div>

                {page !== 'business-verification' && (
                    <div className="titleSection">
                        <div className="title">
                            <h3>Compare with data from image</h3>
                            <Tooltip
                                overlayStyle={{
                                    padding: '9px 8px',
                                    maxWidth: '178px',
                                }}
                                overlayInnerStyle={{
                                    fontSize: '12px',
                                    fontFamily: 'SF-Pro-Display',
                                }}
                                color="#070C18"
                                placement="bottom"
                                title="This is the data set generated from the selfie or photo this consumer added on their KYC"
                            >
                                <img src={helpIcon} />
                            </Tooltip>
                        </div>
                    </div>
                )}
                {page !== 'business-verification' && (
                    <div className="identityMetaData">
                        <div>
                            <h4>Gender</h4>
                            <p>{formatGender(gender)}</p>
                        </div>
                        <div>
                            <h4>Age</h4>
                            <p>
                                {min_age}-{max_age}
                            </p>
                        </div>
                        <div>
                            <h4>Image returned</h4>
                            <div
                                className={'imageAnalyzed'}
                                onClick={() => setShowUserImageModal(true)}
                            >
                                <img
                                    src={attachIcon}
                                    width="18px"
                                    alt="Image returned from BVN"
                                />
                                <p>Image</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

BvnData.propTypes = {
    title: PropTypes.string,
    status: PropTypes.string,
    page: PropTypes.string,
    handleOverride: PropTypes.func,
}

export default BvnData
