import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    survey_type: 'CFS,UFS',
    page: '1',
    name: null,
    start_date: null,
    end_date: null,
    payment_plan: null,
    provider_type: null,
    campaign: null,
    bank_statement_source: null,
    csse_offers: 'true',
    application_status: null,
    statuses: null,
    onboarding_type: null,
}

const adminOutrightSaleListing = createSlice({
    name: 'adminOutrightSaleListingFilter',
    initialState,
    reducers: {
        updateAdminOutrightSaleFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetAdminOutrightSaleFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateAdminOutrightSaleFilters, resetAdminOutrightSaleFilters } =
    adminOutrightSaleListing.actions

export const updatedAdminOutrightSaleFilters = state =>
    state.adminOutrightSaleListingFilter

const persistConfig = {
    key: 'adminOutrightSaleListingFilter',
    storage,
}

export const persistedAdminOutrightSaleListingReducer = persistReducer(
    persistConfig,
    adminOutrightSaleListing.reducer,
)

export default adminOutrightSaleListing.reducer
