import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import ConsumerNavigation from '../Navigation/consumerNav'
import SeoComponent from '../Seo'
import { ReactComponent as BrokenIcon } from 'src/assets/images/broken.svg'
import { ReactComponent as CircleLeftIcon } from 'src/assets/images/circle-left-thick-outline-thin-arrow.svg'
import { ReactComponent as CircleRightIcon } from 'src/assets/images/circle-right-white.svg'
import s from './errornotice.module.scss'
import ButtonComponent from '../Button'
import { footerOptions } from './data'
import useHandleSectionScroll from 'src/utils/Hooks/useHandleSectionScroll'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const ErrorNotice = ({ isFullPage = true }) => {
    const history = useHistory()
    const currentUrl = history?.location?.pathname
    const { handleSectionScroll } = useHandleSectionScroll()
    const { isMobile } = useMediaQueries()

    const openChatWidget = () => {
        window.$zoho?.salesiq?.floatwindow.visible('show')
    }

    const handleFooterOptionClick = option => {
        if (option?.key === 'go-home') {
            history.push('/')
        } else if (option?.key === 'chat') {
            openChatWidget()
        } else if (option?.key === 'faq') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
            handleSectionScroll('faq', '', isMobile ? 0 : 150)
        }
    }

    const handleGoBackClick = () => {
        if (currentUrl === '/app/not-found') {
            history.go(-2)
        } else {
            history.goBack()
        }
    }

    return (
        <div
            className={`${s.wrapper} ${
                !isFullPage ? s.notFullPageWrapper : ''
            }`}
            data-testid="error-notice-wrapper"
        >
            {isFullPage && (
                <SeoComponent
                    title="Not Found | SunFi | Simplifying and Scaling Clean Energy"
                    tracker="NotFoundTracker"
                />
            )}
            {isFullPage && <ConsumerNavigation page={'error-notice'} />}

            <div className={s.infoSectionWrapper}>
                <div className={s.infoSection}>
                    <BrokenIcon
                        className={s.infoIcon}
                        data-testid="error-notice-broken-icon"
                    />
                    <h1>Oh! Sorry about that!</h1>
                    <h2>
                        We seem to have lost this page, but we don’t want to
                        lose you.
                        {isMobile ? <></> : <br />}
                        Use your browser’s back button or click on the button
                        below and try again.
                    </h2>
                    <ButtonComponent
                        btnWidth={'124.44px'}
                        btnHeight={'50.44px'}
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#FFFFFF'}
                        handleClick={handleGoBackClick}
                        dataTestId="error-notice-go-back-btn"
                    >
                        <span className={s.infoButtonContent}>
                            <CircleLeftIcon />
                            Go back
                        </span>
                    </ButtonComponent>
                </div>
            </div>

            <footer className={s.footer} data-testid="error-notice-footer">
                <h3 className={s.footerTitle}>
                    You can also use any of the options below
                </h3>
                <div
                    className={s.footerOptionsSection}
                    data-testid="error-notice-footer-options-section"
                >
                    {footerOptions?.map((item, i) => (
                        <div
                            key={i}
                            role="button"
                            className={s.footerOption}
                            onClick={() => handleFooterOptionClick(item)}
                            data-testid={`error-notice-footer-option-${item.key}`}
                        >
                            <div className={s.footerOptionText}>
                                <h4>{item?.title}</h4>
                                <p>{item?.description}</p>
                            </div>
                            <div className={s.footerOptionBtn}>
                                <CircleRightIcon />
                            </div>
                        </div>
                    ))}
                </div>
            </footer>
        </div>
    )
}

ErrorNotice.propTypes = {
    isFullPage: PropTypes.bool,
}

export default ErrorNotice
