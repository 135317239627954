import { useHistory } from 'react-router-dom'
import { ConsumerButton } from 'src/components/Button'
import ChatCTA from 'src/components/ChatCTA'
import styles from './buildyoursolar.module.scss'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'

// app tracker variables
const pageTitle = 'Partners | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'PARTNERS_LANDING_PAGE'

const BuildYourSolar = () => {
    const {
        BuildYourSolarWrapper,
        BuildYourSolarLeftWrapper,
        BuildYourSolarHeader,
        BuildYourSolarText,
        BuildYourSolarBtnWrapper,
    } = styles
    const { isMobile } = useMediaQueries()
    const history = useHistory()

    return (
        <section className={BuildYourSolarWrapper}>
            <div>
                <div className={BuildYourSolarLeftWrapper}>
                    <p className={BuildYourSolarHeader}>
                        Build your solar business on SunFi
                    </p>
                    <p className={BuildYourSolarText}>
                        Don&apos;t let financing stop your solar business
                        growth, partner with SunFi to keep your customers and
                        expand your business
                    </p>
                    <div className={BuildYourSolarBtnWrapper}>
                        <ConsumerButton
                            btnBgColor="#F9FCFF"
                            btnTextColor="#004AAD"
                            btnFontSize="14px"
                            btnHeight="53px"
                            btnWidth={isMobile ? '100%' : '160px'}
                            handleClick={() => {
                                history.push('/signup')

                                appTracking(
                                    pageUrlName,
                                    pageTracker,
                                    pageTitle,
                                    eventTrackers['clickedPartnerButton']
                                        .action,
                                    eventTrackers['clickedPartnerButton'].label,
                                    eventTrackers['clickedPartnerButton']
                                        .category,
                                    ['MP', 'GA'],
                                )
                            }}
                        >
                            Become a partner
                        </ConsumerButton>
                        <ChatCTA
                            text="Have questions?"
                            fontFamily="SF-Pro-Display-Medium"
                            textOneColor="#FFF"
                            textTwoColor="#FFF"
                            width="290px"
                        />
                    </div>
                </div>
                <img
                    src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/DEP+section+image_high.png"
                    alt="sunfi woman engineer"
                />
            </div>
        </section>
    )
}

export default BuildYourSolar
