/* eslint-disable react/prop-types */
import { useState } from 'react'
import { useHistory } from 'react-router'
import { Upload } from 'antd'
import ActionModal from '../../../components/ActionModal'
import Button from '../../../components/Button'
import ImageListing from '../../../components/ImageListing'
import successUpload from '../../../assets/images/successUpload.svg'
import { createComponentApi } from '../../../api/products/component'
import { removeCommaFromNum } from '../../../utils/NumberFormatter'
import { useQuery } from 'react-query'
import Toast from '../../../components/Toast'
import { InlineLoader } from '../../../components/Loader'
import { truncateFileName } from '../../../utils/formatting'
import { eventTrackers } from '../../../utils/eventTrackers.js'
import { appTracking } from '../../../utils/appTracker.js'

import styles from './addcomponent.module.scss'
import SeoComponent from '../../../components/Seo'

import useErrorHandler from '../../../utils/Hooks/useErrorHandler'

const StepThree = ({
    goToPrevious,
    // eslint-disable-next-line no-unused-vars
    closeModal,
    initialState,
    modalFlow,
    sunfiId,
    isAdminWorkspace,
    providerName,
}) => {
    const {
        CreateComponentSubHeader,
        PageForm,
        ImageBox,
        ComponentActionHeader,
        ImgWrapperMobile,
        mobileSampleImageBtn,
        ShowSampleImage,
        ContinueBtn,
        MobileCreateComponentBtn,
        mobileImgWrapper,
        UseImageSampleText,
    } = styles

    const [showImageSample, setShowImageSample] = useState(false)
    const [fileName, setFileName] = useState('')
    const [uploadedFile, setUploadedFile] = useState([])
    const [successModal, setSuccessModal] = useState(false)
    const [sampleImgUrl, setSampleImgUrl] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [validationMessage, setValidationMessage] = useState('')
    const [errs, setErrs] = useState({})

    const pageTitle =
        'Create A Component - Upload Image | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'Create A Component - Upload Image'

    const props = {
        name: 'file',
        onChange(info) {
            setUploadedFile(info.fileList)
            setFileName(info.file.name)
        },
        showUploadList: false,
    }

    let history = useHistory()
    const redirectTo = pageUrl => {
        history.push(
            sunfiId.length < 0
                ? {
                      pathname: pageUrl,
                      search: '?tab=components',
                  }
                : {
                      pathname: pageUrl,
                      search: '?tab=components',
                      state: {
                          sunfiId: sunfiId,
                          isAdminWorkspace: isAdminWorkspace,
                          name: providerName,
                      },
                  },
        )
    }

    const handleModalAction = () => {
        redirectTo('/app/products')
    }

    let selectedAttributes = initialState?.selectedAttributes
        ?.filter(value => value?.show === true)
        .map(att => {
            const { name, unit, value } = att
            return {
                [name]: { value: value, unit_of_measurement: unit },
            }
        })

    let formattedAttribute = selectedAttributes?.reduce(function (
        result,
        item,
    ) {
        var key = Object.keys(item)[0]
        result[key] = item[key]
        return result
    },
    {})
    const { isFetching, refetch } = useQuery(
        'createComponent',
        () =>
            createComponentApi(
                {
                    name: initialState.componentName,
                    description: initialState.componentDescription,
                    type_id: initialState.componentTypeId,
                    componentType: initialState.componentType,
                    warranty: initialState?.componentWarranty,
                    cost: removeCommaFromNum(initialState.componentCost),
                    attributes: JSON.stringify(formattedAttribute),
                    image:
                        uploadedFile.length > 0 &&
                        uploadedFile[0]?.originFileObj,
                    suggested_image_url: sampleImgUrl && sampleImgUrl,
                    is_global:
                        initialState.isGlobal === true ? 'True' : 'False',
                    providerId: sunfiId,
                    brand_id: initialState.brandId,
                },
                isAdminWorkspace,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setSuccessModal(true)
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['createComponent'].action,
                    eventTrackers['createComponent'].label,
                    eventTrackers['createComponent'].category,
                )
            },
            onError: data => {
                if (data?.response?.data?.errors) {
                    setErrs(data?.response?.data)
                } else {
                    setErrorMessage([
                        'Failed to create Component. Refresh/Try Again',
                    ])
                }
                setToastError(true)
            },
        },
    )

    const handleSubmit = () => {
        setToastError(false)
        setValidationMessage('')
        refetch()
    }

    const errorValue = useErrorHandler(errs)

    return (
        <>
            <SeoComponent
                title="Create A Component - Upload Image | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Create A Component - Upload Image"
            />
            {toastError ? (
                <Toast
                    messageType="error"
                    message={errorMessage[0] || errorValue['msg']}
                />
            ) : validationMessage ? (
                <Toast messageType="error" message={validationMessage} />
            ) : null}

            <ActionModal
                actionModalOpen={successModal}
                headerText="Component Created Successfully"
                subTitle="You have successfully added a new component to SunFi product library."
                closeModal={handleModalAction}
                actionHandler={handleModalAction}
                actionType="success"
                actionText="Go Back"
                noCancelBtn
                loading={''}
            />

            <div className={CreateComponentSubHeader}>Create New Component</div>

            <div className={ComponentActionHeader}>
                Add Image to your Component
            </div>

            <div
                className={PageForm}
                style={{
                    maxWidth: '100%',
                }}
            >
                <div
                    style={{ display: 'flex', padding: '0px 0px 30px' }}
                    className={ImgWrapperMobile}
                >
                    <div>
                        <div
                            className={`img-wrapper ${mobileImgWrapper}`}
                            style={{ height: '226px' }}
                        >
                            <p className="ant-upload-drag-icon">
                                <img
                                    src={successUpload}
                                    alt="successful-upload"
                                />
                            </p>
                            <div className="filename">
                                {truncateFileName(fileName)}
                            </div>

                            <Upload customRequest={() => {}} {...props}>
                                <div
                                    style={{
                                        marginBottom: '13px',
                                        color: 'rgba(35, 56, 98, 0.78)',
                                    }}
                                >
                                    {!fileName ? (
                                        <>
                                            <span>
                                                Upload image. Drag and drop or
                                                &nbsp;
                                            </span>
                                            <span className="replaceDoc">
                                                browse
                                            </span>
                                        </>
                                    ) : (
                                        <span className="replaceDoc">
                                            Replace Image
                                        </span>
                                    )}
                                </div>
                            </Upload>

                            {modalFlow ? (
                                ''
                            ) : (
                                <div className={mobileSampleImageBtn}>
                                    <Button
                                        btnBgColor="var(--purple-light)"
                                        btnTextColor="var(--blue)"
                                        btnTextColorOutline="var(--blue)"
                                        btnOutlineColor="var(--purple-light)"
                                        btnBgColorOutline="#E2EEFF"
                                        type="outline"
                                        btnWidth="147px"
                                        handleClick={() =>
                                            setShowImageSample(true)
                                        }
                                    >
                                        Use Image Sample
                                    </Button>
                                </div>
                            )}
                        </div>
                        <p className={UseImageSampleText}> Use Image Sample </p>
                        <div className={ContinueBtn}>
                            <Button
                                btnBgColor="var(--purple-light)"
                                btnTextColor="var(--blue)"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                btnWidth="150px"
                                handleClick={() => goToPrevious(initialState)}
                            >
                                Previous
                            </Button>
                            &nbsp; &nbsp; &nbsp;
                            <Button
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                btnWidth="160px"
                                handleClick={handleSubmit}
                                disabled={isFetching ? true : false}
                            >
                                {isFetching ? (
                                    <InlineLoader />
                                ) : (
                                    'Create Component'
                                )}
                            </Button>
                        </div>
                    </div>

                    <div
                        className={`${ImageBox} ${
                            showImageSample && ShowSampleImage
                        }`}
                    >
                        <ImageListing
                            onClose={() => setShowImageSample(false)}
                            onSelect={(imageName, imageUrl) => {
                                setFileName(imageName)
                                setSampleImgUrl(imageUrl)
                            }}
                            componentType={initialState?.componentType}
                            componentName={initialState?.componentName}
                        />
                    </div>
                </div>

                <div className={MobileCreateComponentBtn}>
                    <Button
                        btnBgColor="var(--purple-light)"
                        btnTextColor="var(--blue)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        type="outline"
                        btnWidth="150px"
                        handleClick={() => goToPrevious(initialState)}
                    >
                        Previous
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        btnWidth="160px"
                        handleClick={handleSubmit}
                        disabled={isFetching ? true : false}
                        btnPadding="10px 15px"
                        btnHeight="50px"
                    >
                        {isFetching ? <InlineLoader /> : 'Create Component'}
                    </Button>
                    <br />
                </div>
            </div>
        </>
    )
}

export default StepThree
