import axios from '../../config/axios'

export const submitEnergyAssessmentRatingApi = data => {
    const request = axios.post(`/power-curve/feedback`, data)
    return request
}

export const submitSolarCalculatorRatingApi = data => {
    const request = axios.post(`/solar-savings/feedback`, data)
    return request
}

export const submitKYCRatingApi = (data, estimationId) => {
    const request = axios.post(`/consumers/kyc/${estimationId}/feedback`, data)
    return request
}
