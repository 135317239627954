import axios from '../../config/axios'

export const phoneOTPValidationApi = async inputs => {
    const request = await axios.post(
        '/auth/phone-verification/validate',
        inputs,
    )
    return request
}

export const resendPhoneVerification = async inputs => {
    const request = await axios.post(
        '/auth/phone-verification/initiate',
        inputs,
    )
    return request
}

export const consumerPhoneVerificationApi = async inputs => {
    const request = await axios.post(
        '/auth/consumers/phone-verification/initiate',
        inputs,
    )
    return request
}
