import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Tabs, Pagination, Drawer } from 'antd'
import { format, parseISO } from 'date-fns'
import { useGetProviders } from 'src/api/admin/providers/listings'
import { ListingsSkeletalCards } from 'src/components/SkeletalCards'
import { InlineLoader } from 'src/components/Loader'
import EmptyState from 'src/components/EmptyState'
import ButtonComponent, { AppButton } from 'src/components/Button'
import Toast from 'src/components/Toast'
import {
    DateField,
    NoFLoatingLabelInputFields,
    SelectField,
} from 'src/components/InputFields'
import 'src/pages/Admin/Providers/Listings/providers-listing.scss'
import { errorHandler } from 'src/utils/errorHandler'
import SeoComponent from '../../../../components/Seo'
import {
    addQueryParamsToUrl,
    removeQueryParamFromUrl,
    getSearchParamsFromUrl,
} from 'src/utils/urlTracker'
import { getKeyByValue } from 'src/utils/formatting'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { canViewProviderDetails } from './data'
import ActionModal from 'src/components/ActionModal'
import { stateData } from 'src/utils/stateData'

const Listings = () => {
    const statusTab = {
        1: 'all-providers',
        2: 'approved',
        3: 'unapproved',
        4: 'suspended',
    }
    const { TabPane } = Tabs
    const history = useHistory()
    const location = useLocation()
    const filterQuery = getSearchParamsFromUrl()
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [currentTab, setCurrentTab] = useState(
        getKeyByValue(statusTab, filterQuery.tab) || '1',
    )
    const [currentPage, setCurrentPage] = useState(
        Number(filterQuery.page) || 1,
    )
    const [totalPages, setTotalPages] = useState(null)
    const [filterMode, setFilterMode] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [inputs, setInputs] = useState({
        startDate: filterQuery.startDate || null,
        endDate: filterQuery.endDate || null,
    })
    const [showCalendar, setShowCalendar] = useState(0)
    const [currentStartDate, setCurrentStartDate] = useState(
        inputs.startDate === null ? null : new Date(inputs.startDate),
    )

    const [currentEndDate, setCurrentEndDate] = useState(
        inputs.endDate === null ? null : new Date(inputs.endDate),
    )
    const [searchText, setSearchText] = useState(filterQuery?.search || '')
    const [providerName, setProviderName] = useState(filterQuery?.search || '')
    const [providerLocation, setProviderLocation] = useState(
        filterQuery?.location || '',
    )
    const [exportProviders, setExportProviders] = useState(
        filterQuery?.providers === 'true' ? true : false,
    )
    const [exportModal, setExportModal] = useState(false)
    const { isMobile } = useMediaQueries()
    const redirectTo = pageUrl => {
        history.push(pageUrl)
    }
    // redirect to the detailed page on the all providers tab
    const redirectLink = (status, type, id) => {
        let providerStatus = ''

        switch (status) {
            case 'APPROVED':
                providerStatus = 'active'
                break
            case 'UNAPPROVED':
                providerStatus = 'pending'
                break
            case 'SUSPENDED':
                providerStatus = 'deactivated'
                break
            default:
                providerStatus = ''
        }
        return history.push(
            `/admin/providers/${providerStatus}/${id}${
                type === 'AI' ? '?asi' : ''
            }`,
        )
    }

    const handleDateChange = (name, date) => {
        if (name === 'startDate') {
            setCurrentStartDate(format(date, 'MM/dd/yyyy'))
        }
        if (name === 'endDate') {
            setCurrentEndDate(format(date, 'MM/dd/yyyy'))
        }
    }

    const setHandleDateChange = name => {
        setInputs(preState => ({
            ...preState,
            [name]: name === 'startDate' ? currentStartDate : currentEndDate,
        }))
    }
    const showDrawer = () => {
        setDrawerVisible(!drawerVisible)
        setExportProviders('')
    }

    const resetQueryParams = () => {
        history.push({
            pathname: location.pathname,
            search: '',
        })
    }

    const resetValues = () => {
        setDrawerVisible(false)
        setSearchText('')
        setProviderName('')
        setProviderLocation('')
        setFilterMode(false)
        setInputs(prev => ({
            ...prev,
            endDate: null,
            startDate: null,
        }))
        setCurrentStartDate(null)
        setCurrentEndDate(null)
        setCurrentPage(1)
        setCurrentTab('1')
        setExportProviders('')
        resetQueryParams()
    }

    const handleReset = () => {
        resetValues()
    }

    const { data, isFetching: isProvidersLoading } = useGetProviders(
        currentPage,
        providerName || '',
        filterQuery.startDate || '',
        filterQuery.endDate || '',
        filterQuery.tab?.toUpperCase() === 'ALL-PROVIDERS'
            ? ''
            : filterQuery.tab?.toUpperCase(),
        filterQuery.providers || '',
        // param below is for the provider type but this hasnt been implemented yet
        '',
        filterQuery.location || '',
        {
            cacheTime: 0,
            onSuccess: res => {
                if (exportProviders) {
                    handleReset()
                    setExportModal(true)
                }
                setTotalPages(res?.data?.total_pages)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )
    const allProviders = data?.data?.data
    const providerCount = data?.data?.count || ''

    const updatePage = page => {
        setCurrentPage(page)
        addQueryParamsToUrl({
            page: page,
        })
    }

    const handleSearchInputChange = e => {
        e.persist()
        setSearchText(e.target.value)

        if (!e.target.value) {
            handleSearch()
            setProviderName(e.target.value)
            removeQueryParamFromUrl('search')
            addQueryParamsToUrl({
                page: '1',
            })
        }
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
            setProviderName(event.target.value)
            if (event.target.value.length > 0) {
                addQueryParamsToUrl({
                    search: event.target.value,
                    page: '1',
                })
            } else {
                removeQueryParamFromUrl('search')
                addQueryParamsToUrl({
                    page: '1',
                })
            }
        }
    }

    const handleSearch = () => {
        setCurrentPage(1)
        setFilterMode(searchText.length > 0)
    }

    const handleAddFilter = () => {
        setCurrentPage(1)
        setDrawerVisible(false)
        setFilterMode(true)
        history.push({
            pathname: location.pathname,
            search: '',
        })
        addQueryParamsToUrl({
            startDate: currentStartDate
                ? format(new Date(currentStartDate), 'yyyy-MM-dd')
                : '',
            endDate: currentEndDate
                ? format(new Date(currentEndDate), 'yyyy-MM-dd')
                : '',
            page: '1',
            tab: filterQuery.tab || '1',
            location: providerLocation,
            providers: exportProviders,
        })
    }

    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={currentPage}
                current={currentPage}
                total={parseInt(totalPages, 10)}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={updatePage}
            />
        )
    }

    const statusName = ['All Providers', 'Approved', 'Unapproved', 'Suspended']
    const statusNameObject = {
        Approved: 'active',
        Unapproved: 'pending',
        Suspended: 'deactivated',
    }

    const handleTabChange = val => {
        addQueryParamsToUrl({
            tab: statusTab[val],
            page: '1',
        })
        setCurrentTab(val)
        setCurrentPage(1)
    }
    return (
        <>
            <SeoComponent
                title="Provider Management | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Admin-Provider-Management"
            />
            <ActionModal
                actionModalOpen={exportModal}
                headerText={'Data exported successfully'}
                subTitle="Kindly check your mail to view the exported providers data"
                closeModal={() => setExportModal(false)}
                actionHandler={() => setExportModal(false)}
                actionType="success"
                actionText="Okay, Got it"
                noBackLink
                noCancelBtn
            />

            <div className="APLContainer">
                <div>
                    <p className="APLPageTitle">Provider Management</p>
                </div>
                <Drawer
                    placement="right"
                    width="367px"
                    onClose={showDrawer}
                    visible={drawerVisible}
                    closable={isMobile}
                >
                    <h2 className="ARLFilterHeader">
                        {exportProviders ? 'Export' : 'Filter'} Providers
                    </h2>
                    {/* filtering by date */}
                    <h4 className="ARLDateFilterHeader">
                        {exportProviders ? 'Export' : 'Filter'} By Created Date
                    </h4>
                    <div className="ARLDateFieldsWrapper">
                        <div className="ARLDateField">
                            <DateField
                                placeholder="Start Date"
                                name="startDate"
                                caretStyle="caretStyle"
                                dateValue={
                                    inputs.startDate === null
                                        ? null
                                        : new Date(inputs.startDate)
                                }
                                handleDateChange={(name, date) => {
                                    handleDateChange(name, date)
                                }}
                                openCalendar={showCalendar === 1 ? true : false}
                                closeCalendarHandler={() =>
                                    setShowCalendar(null)
                                }
                                openCalendarHandler={() => setShowCalendar(1)}
                                setHandleDateChange={name =>
                                    setHandleDateChange(name)
                                }
                            />
                        </div>

                        <div className="ARLDateField">
                            <DateField
                                placeholder="End Date"
                                name="endDate"
                                calendarClassName="APLEndDateFilter"
                                caretStyle="caretStyle"
                                dateValue={
                                    inputs.endDate === null
                                        ? null
                                        : new Date(inputs.endDate)
                                }
                                handleDateChange={(name, date) => {
                                    handleDateChange(name, date)
                                }}
                                openCalendar={showCalendar === 2 ? true : false}
                                closeCalendarHandler={() =>
                                    setShowCalendar(null)
                                }
                                openCalendarHandler={() => setShowCalendar(2)}
                                setHandleDateChange={name =>
                                    setHandleDateChange(name)
                                }
                            />
                        </div>
                    </div>
                    <h4 className="ARLDateFilterHeader">
                        {exportProviders ? 'Export' : 'Filter'} By Location
                    </h4>
                    <div className="">
                        <SelectField
                            floatLabelOnSelectedValue
                            name="location"
                            floatingLabel="Select a state"
                            initialOption="Select a state"
                            selectWidth="100%"
                            values={stateData?.map(option => ({
                                value: option,
                            }))}
                            value={providerLocation}
                            handleChange={(_, value) => {
                                setProviderLocation(value)
                            }}
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={providerLocation}
                            selectedValue={providerLocation}
                        />
                    </div>

                    <div className="ARLFilterButtonWrapper">
                        <AppButton
                            btnBgColor="#E2EEFF"
                            btnTextColor="#004AAD"
                            btnWidth={160}
                            btnHeight={56}
                            handleClick={handleReset}
                        >
                            Reset {exportProviders ? 'Data' : 'Filter'}
                        </AppButton>
                        <AppButton
                            btnTextColor="#FFFFFF"
                            btnBgColor="#004AAD"
                            btnWidth={160}
                            btnHeight={56}
                            handleClick={handleAddFilter}
                            disabled={isProvidersLoading ? true : false}
                        >
                            {isProvidersLoading ? (
                                <InlineLoader />
                            ) : exportProviders ? (
                                'Export Data'
                            ) : (
                                'Add Filter'
                            )}
                        </AppButton>
                    </div>
                </Drawer>
                <div className="APLWrapper">
                    <div className="APLSpaceBetween">
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <NoFLoatingLabelInputFields
                                TextPrefilled={searchText ? true : false}
                                borderRadius="10px"
                                inputWidth="350px"
                                inputHeight="45px"
                                value={searchText}
                                type="text"
                                inputBackground="#F6F6F6"
                                iconType="search"
                                prefilled
                                inputPlaceholder="Search"
                                style={{ paddingBottom: 7 }}
                                handleChange={handleSearchInputChange}
                                onIconClick={handleSearch}
                                onKeyUp={handleKeyPress}
                            />
                            <div
                                className="APLFilterBox"
                                style={{ border: '1px solid #e5e5e5' }}
                                onClick={showDrawer}
                            >
                                <svg
                                    width="12"
                                    height="15"
                                    viewBox="0 0 12 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginTop: 4 }}
                                >
                                    <path
                                        d="M7.5 8.5V13L4.5 14.5V8.5L0 1.75V0.25H12V1.75L7.5 8.5ZM1.803 1.75L6 8.0455L10.197 1.75H1.803Z"
                                        fill={'#1D2A30'}
                                    />
                                </svg>

                                <p className="APLFilterText">Filters</p>
                            </div>
                            {process.env
                                .REACT_APP_FLAG_SHOW_EXPORT_PROVIDERS_DATA ===
                                'true' && (
                                <div
                                    className="APLFilterBox"
                                    style={{ border: '1px solid #e5e5e5' }}
                                    onClick={() => {
                                        showDrawer()
                                        setExportProviders(true)
                                    }}
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.50031 15.75H13.5003"
                                            stroke="#14181F"
                                            strokeWidth="1.78675"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M9.00079 2.25L9.00079 12.75"
                                            stroke="#14181F"
                                            strokeWidth="1.78675"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M12.7508 9L9.00079 12.75L5.25079 9"
                                            stroke="#14181F"
                                            strokeWidth="1.78675"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>

                                    <p className="APLFilterText">Export</p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="APLTabWrapper">
                        {isProvidersLoading ? (
                            <ListingsSkeletalCards />
                        ) : (
                            <Tabs
                                activeKey={currentTab}
                                style={{ marginTop: 38 }}
                                onChange={values => {
                                    handleTabChange(values)
                                }}
                            >
                                {toastError && (
                                    <Toast
                                        messageType="error"
                                        message={errorMessage}
                                    />
                                )}
                                {statusName.map((status, index) => {
                                    return (
                                        <TabPane
                                            tab={`${status} ${
                                                index + 1 ===
                                                parseInt(currentTab, 10)
                                                    ? '(' + providerCount + ')'
                                                    : ''
                                            }`}
                                            key={index + 1}
                                        >
                                            <div className="APLTablewWrapper">
                                                {allProviders?.length > 0 ? (
                                                    <>
                                                        <div className="APLTableHeader">
                                                            <span>
                                                                Provider
                                                            </span>
                                                            <span>
                                                                Provider Type
                                                            </span>
                                                            <span>Users</span>
                                                            <span>
                                                                {status ===
                                                                'All Providers'
                                                                    ? 'Status'
                                                                    : 'Created Time'}
                                                            </span>
                                                            <span>Actions</span>
                                                        </div>
                                                        {allProviders?.map(
                                                            item => (
                                                                <div
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                    className="APLTableContent"
                                                                >
                                                                    <span>
                                                                        {
                                                                            item?.display_name
                                                                        }
                                                                    </span>
                                                                    <span>
                                                                        {
                                                                            item
                                                                                ?.user
                                                                                ?.provider
                                                                                ?.type
                                                                                ?.identifier
                                                                        }
                                                                    </span>
                                                                    <span>
                                                                        {
                                                                            item?.total_members
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            textTransform:
                                                                                status ===
                                                                                    'All Providers' &&
                                                                                'capitalize',
                                                                        }}
                                                                    >
                                                                        {status ===
                                                                        'All Providers'
                                                                            ? item?.status.toLowerCase()
                                                                            : format(
                                                                                  parseISO(
                                                                                      item?.created_at,
                                                                                  ),
                                                                                  'dd MMM, yyyy, HH:mm',
                                                                              )}
                                                                    </span>
                                                                    <ButtonComponent
                                                                        type="XSmall"
                                                                        handleClick={
                                                                            status ===
                                                                            'All Providers'
                                                                                ? () =>
                                                                                      redirectLink(
                                                                                          item?.status,
                                                                                          item
                                                                                              ?.user
                                                                                              ?.provider
                                                                                              ?.type
                                                                                              ?.identifier,
                                                                                          item?.id,
                                                                                      )
                                                                                : () =>
                                                                                      redirectTo(
                                                                                          item
                                                                                              ?.user
                                                                                              ?.provider
                                                                                              ?.type
                                                                                              ?.identifier ===
                                                                                              'AI'
                                                                                              ? `/admin/providers/${statusNameObject[status]}/${item?.id}?asi`
                                                                                              : `/admin/providers/${statusNameObject[status]}/${item?.id}`,
                                                                                      )
                                                                        }
                                                                        disabled={
                                                                            !canViewProviderDetails
                                                                        }
                                                                        toolTipText={
                                                                            !canViewProviderDetails
                                                                                ? 'You are not authorised to perform this action'
                                                                                : null
                                                                        }
                                                                        toolTipPopupWidth={
                                                                            164
                                                                        }
                                                                        arrowPositionCenter
                                                                        toolTipMarginLeft={
                                                                            -52
                                                                        }
                                                                    >
                                                                        View
                                                                    </ButtonComponent>
                                                                </div>
                                                            ),
                                                        )}
                                                        {/* mobile table content  */}
                                                        <div className="APLTableMobile">
                                                            {allProviders?.map(
                                                                item => (
                                                                    <div
                                                                        key={
                                                                            item?.id
                                                                        }
                                                                        className="APLTableMobileContentWrapper"
                                                                    >
                                                                        <div className="APLMobileContent">
                                                                            <div className="APLMobileTopWrapper">
                                                                                <p
                                                                                    className="APLMobileContentTitle"
                                                                                    style={{
                                                                                        textAlign:
                                                                                            'left',
                                                                                    }}
                                                                                >
                                                                                    PROVIDER
                                                                                </p>
                                                                                <span
                                                                                    className="APLMobileContentData"
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        item?.display_name
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <ButtonComponent
                                                                                type="XSmall"
                                                                                handleClick={
                                                                                    status ===
                                                                                    'All Providers'
                                                                                        ? () =>
                                                                                              redirectLink(
                                                                                                  item?.status,
                                                                                                  item
                                                                                                      ?.user
                                                                                                      ?.provider
                                                                                                      ?.type
                                                                                                      ?.identifier,
                                                                                                  item?.id,
                                                                                              )
                                                                                        : () =>
                                                                                              redirectTo(
                                                                                                  item
                                                                                                      ?.user
                                                                                                      ?.provider
                                                                                                      ?.type
                                                                                                      ?.identifier ===
                                                                                                      'AI'
                                                                                                      ? `/admin/providers/${statusNameObject[status]}/${item?.id}?asi`
                                                                                                      : `/admin/providers/${statusNameObject[status]}/${item?.id}`,
                                                                                              )
                                                                                }
                                                                                disabled={
                                                                                    !canViewProviderDetails
                                                                                }
                                                                                toolTipText={
                                                                                    !canViewProviderDetails
                                                                                        ? 'You are not authorised to perform this action'
                                                                                        : null
                                                                                }
                                                                                toolTipPopupWidth={
                                                                                    164
                                                                                }
                                                                                arrowPositionCenter
                                                                            >
                                                                                View
                                                                            </ButtonComponent>
                                                                        </div>
                                                                        <div className="APLMobileContent">
                                                                            <div>
                                                                                <p className="APLMobileContentTitle">
                                                                                    PROVIDER
                                                                                    TYPE
                                                                                </p>
                                                                                <span className="APLMobileContentData">
                                                                                    {
                                                                                        item
                                                                                            ?.user
                                                                                            ?.provider
                                                                                            ?.type
                                                                                            ?.identifier
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <p className="APLMobileContentTitle">
                                                                                    {status ===
                                                                                    'All Providers'
                                                                                        ? 'STATUS'
                                                                                        : 'USERS'}
                                                                                </p>
                                                                                <p
                                                                                    className="APLMobileContentData"
                                                                                    style={{
                                                                                        textTransform:
                                                                                            status ===
                                                                                                'All Providers' &&
                                                                                            'capitalize',
                                                                                        marginLeft:
                                                                                            status ===
                                                                                                'All Providers' &&
                                                                                            '15px',
                                                                                        textAlign:
                                                                                            'right',
                                                                                    }}
                                                                                >
                                                                                    {status ===
                                                                                    'All Providers'
                                                                                        ? item?.status.toLowerCase()
                                                                                        : item.total_members}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ),
                                                            )}
                                                        </div>
                                                        <div className="APLPaginationWrapper">
                                                            {paginationBlock()}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <EmptyState
                                                        type="noActivity"
                                                        title={
                                                            filterMode
                                                                ? 'There are no results, try again!'
                                                                : 'No provider'
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </TabPane>
                                    )
                                })}
                            </Tabs>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Listings
