import PropTypes from 'prop-types'
import ButtonComponent from 'src/components/Button'

import styles from './buildsystemcard.module.scss'

const BuildSystemCard = ({ toggleModal }) => {
    return (
        <>
            <div className={styles.Card}>
                <div className={styles.CardImageWrapper}>
                    <img
                        className={styles.CardImage}
                        src={
                            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/distributor-installer.svg'
                        }
                        alt="consumer-profile-card-image"
                        role={'presentation'}
                    />
                </div>
                <div className={styles.CardBody}>
                    <h4>Can&apos;t find what works for you?</h4>
                    <p>
                        Customise your own system by providing your budget,
                        location, what you want to power, etc
                    </p>
                    <div className={styles.CardButtonWrapper}>
                        <ButtonComponent
                            type={'outline'}
                            btnTextColorOutline="#004AAD"
                            btnOutlineColor="#004AAD"
                            btnWidth={'100%'}
                            btnHeight={'56px'}
                            fontSize={'14px'}
                            border="0.3px solid #004AAD"
                            handleClick={toggleModal}
                        >
                            Build your own system
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </>
    )
}

BuildSystemCard.propTypes = {
    toggleModal: PropTypes.func,
}

export default BuildSystemCard
