import { uploadToS3, uploadMultipleToS3 } from 'src/api/utils/file-upload'

import { useMutation } from 'react-query'

export const useUploadToS3 = (options = {}, isAuthenticated) => {
    const { onSuccess, onError, ...others } = options
    const {
        isLoading: uploadToS3Loading,
        mutate: uploadToS3Mutate,
        error: uploadToS3Error,
    } = useMutation({
        mutationFn: ({ category, file }) =>
            uploadToS3(category, file, isAuthenticated),
        onSuccess: data => {
            const s3Url = data?.url + data?.fields?.key

            if (onSuccess) {
                onSuccess(s3Url)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
        },
        ...others,
    })

    return {
        uploadToS3Loading,
        uploadToS3Error,
        uploadToS3Mutate,
    }
}

export const useUploadMultipleToS3 = (options = {}) => {
    const { onSuccess, onError, ...others } = options
    const {
        isLoading: uploadMultipleFilesToS3Loading,
        mutate: uploadMultipleToS3Mutate,
        error: uploadMultipleToS3Error,
    } = useMutation({
        mutationFn: ({ category, files }) =>
            uploadMultipleToS3(
                files?.map(item => ({
                    file_name: item.name,
                    category,
                })),
                files,
            ),
        onSuccess: data => {
            const urls = data?.map(file => {
                const docUrl = file?.url + file?.fields?.key || ''
                return docUrl
            })

            if (onSuccess) {
                onSuccess(urls)
            }
        },
        onError: err => {
            if (onError) {
                const data = err?.response?.data
                if (data.errors && Array.isArray(data.errors)) {
                    data.errors = data.errors[0]
                }
                onError(data)
            }
        },
        ...others,
    })

    return {
        uploadMultipleFilesToS3Loading,
        uploadMultipleToS3Error,
        uploadMultipleToS3Mutate,
    }
}
