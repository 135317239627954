import { InputFields } from 'src/components/InputFields'
import InputFieldWithButton from 'src/components/InputFields/InputFieldWithButton'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'
import { useConsumerKYCContext } from '../../context'

const DirectorInfo = () => {
    const {
        inputs,
        handleInputChange,
        handleBlur,
        errors,
        verificationInProgress,
        handleBvnVerification,
        isAdminUser,
        isCustomerApproved,
    } = useConsumerKYCContext()
    return (
        <div className="DirectorInfo">
            <div className="GroupItems">
                <InputFields
                    title="Director's First Name"
                    value={inputs?.directorFirstName}
                    handleChange={handleInputChange}
                    name="directorFirstName"
                    onBlur={handleBlur}
                    errorMessage={errors?.directorFirstName}
                    disabled={isCustomerApproved}
                />
                <InputFields
                    title="Director's Last Name"
                    value={inputs?.directorLastName}
                    handleChange={handleInputChange}
                    name="directorLastName"
                    onBlur={handleBlur}
                    errorMessage={errors?.directorLastName}
                    disabled={isCustomerApproved}
                />
            </div>

            <div className="GroupItems">
                <InputFields
                    title="Director's Email Address"
                    value={inputs?.directorEmail}
                    handleChange={handleInputChange}
                    name="directorEmail"
                    onBlur={handleBlur}
                    errorMessage={errors?.directorEmail}
                    disabled={isCustomerApproved}
                />
                <SelectCountryCode
                    title="Director's Phone number"
                    inputValue={inputs?.directorPhone}
                    name="directorPhone"
                    onBlur={handleBlur}
                    handleChange={handleInputChange}
                    errorMessage={errors?.directorPhone}
                    disabled={isCustomerApproved}
                />
            </div>

            <InputFieldWithButton
                title="Director's BVN"
                btnText="Verify this BVN"
                mobileBtnText="Verify"
                value={inputs?.bvn}
                type={inputs?.bvnVerified ? 'text' : 'number'}
                name="bvn"
                handleChange={handleInputChange}
                verified={inputs?.bvnVerified}
                disabled={
                    inputs?.bvnVerified ||
                    verificationInProgress ||
                    isAdminUser ||
                    isCustomerApproved
                }
                handleBlur={handleBlur}
                errorMessage={errors?.bvn}
                showBtn={!inputs?.bvnVerified && !verificationInProgress}
                inprogress={verificationInProgress}
                progressText="Verification in Progress"
                btnDisabled={
                    inputs?.bvn === undefined ||
                    inputs?.bvn?.length === 0 ||
                    inputs?.bvn?.length < 11 ||
                    inputs?.bvn?.length > 11 ||
                    inputs?.bvnVerified ||
                    isAdminUser ||
                    isCustomerApproved
                }
                onBtnClick={handleBvnVerification}
            />
        </div>
    )
}

export default DirectorInfo
