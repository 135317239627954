import { useState } from 'react'
import Button from '../../../components/Button'
import { useQuery } from 'react-query'
import Toast from '../../../components/Toast'
import { InlineLoader } from '../../../components/Loader'
import { InputFields } from '../../../components/InputFields'
import { changeAiPasswordApi } from '../../../api/associateinstaller'
import { errorHandler } from '../../../utils/errorHandler'
import { PasswordFieldsValidationSchema } from '../../../utils/validationSchema'
import styles from '../ASIPersonalInfo/asipersonalinfo.module.scss'

const ASISecurity = () => {
    const { Wrapper, Row, LeftWrapper, Header, SubHeader, FullName, SaveBtn } =
        styles

    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errors, setErrors] = useState({})
    const [successMessage, setSuccessMessage] = useState('')
    const [inputs, setInputs] = useState({
        newPassword: '',
        oldPassword: '',
    })

    const handleInputChange = event => {
        const { name, value } = event.target

        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const clearInputValue = () => {
        setInputs(preState => ({
            ...preState,
            oldPassword: '',
            newPassword: '',
        }))
    }

    const handlePasswordSubmit = () => {
        setErrors({})
        setTimeout(() => {
            clearInputValue()
        }, 2000)

        PasswordFieldsValidationSchema.validate(inputs, { abortEarly: false })
            .then(() => {
                setErrors('')
                refetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const { data, isFetching, refetch } = useQuery(
        'changeAiPassword',
        () =>
            changeAiPasswordApi({
                old_password: inputs.oldPassword,
                new_password: inputs.newPassword,
            }),
        {
            enabled: false,
            retry: false,
            onError: error => {
                if (error) {
                    const errObj = error?.response?.data
                    if (error?.response?.status === 500) {
                        setErrorMessage(['Request failed. Please try again.'])
                    } else {
                        setErrorMessage(errorHandler(errObj))
                    }
                }
                setToastError(true)
            },
            onSuccess: () => {
                setSuccessMessage('Your password has been updated')
            },
        },
    )

    return (
        <>
            <div className={Wrapper}>
                {toastError ? (
                    <Toast messageType="error" message={errorMessage} />
                ) : data ? (
                    <Toast messageType="success" message={successMessage} />
                ) : (
                    ''
                )}
                <div className={Row}>
                    <div className={LeftWrapper}>
                        <p className={Header}>Change Password</p>
                        <p className={SubHeader}>
                            Please enter your new password
                        </p>
                    </div>

                    <div className={FullName}>
                        <InputFields
                            inputWidth="185px"
                            title="Old Password"
                            type="password"
                            passwordColor="rgba(35, 56, 98, 0.51)"
                            name="oldPassword"
                            value={inputs.oldPassword}
                            inputValue={inputs.oldPassword}
                            handleChange={handleInputChange}
                            errorMessage={errors.oldPassword}
                        />
                        <InputFields
                            inputWidth="185px"
                            title="New Password"
                            type="password"
                            passwordColor="rgba(35, 56, 98, 0.51)"
                            name="newPassword"
                            value={inputs.newPassword}
                            inputValue={inputs.newPassword}
                            handleChange={handleInputChange}
                            errorMessage={errors.newPassword}
                        />
                    </div>
                </div>

                <div className={SaveBtn} style={{ marginTop: '40px' }}>
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnWidth="160px"
                        btnHeight="56px"
                        handleClick={handlePasswordSubmit}
                    >
                        {isFetching ? <InlineLoader /> : 'Save Changes'}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default ASISecurity
