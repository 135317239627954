import { useState } from 'react'
import { useQuery } from 'react-query'
import { getProfilesApi } from 'src/api/admin/profiles/listings'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const useGetProfiles = (filterState, setFilterState) => {
    const [profiles, setProfiles] = useState([])
    const [totalPages, setTotalPages] = useState(null)

    const { errorAlert } = useCustomToast()

    const { isFetching, refetch: profilesRefetch } = useQuery({
        queryKey: [
            'fetch-profiles',
            filterState?.published,
            filterState?.currentPage,
            filterState?.searchText,
        ],
        queryFn: () =>
            getProfilesApi(
                filterState?.published,
                filterState?.currentPage,
                filterState?.searchText,
            ),
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        onSuccess: res => {
            const data = res?.data?.data
            setProfiles(data)
            setTotalPages(res?.data?.total_pages)
        },
        onError: error => {
            if (error.response.data.message === 'Invalid page.') {
                setFilterState(prevState => ({
                    ...prevState,
                    currentPage:
                        filterState?.currentPage > 1
                            ? filterState?.currentPage - 1
                            : 1,
                }))
                profilesRefetch()
            } else {
                errorAlert(
                    errorHandler(error.response?.data) ||
                        `Couldn't fetch consumer profiles. please try again`,
                )
            }
        },
    })

    return {
        profiles,
        profilesFetching: isFetching,
        totalPages,
        profilesRefetch,
    }
}

export default useGetProfiles
