/* eslint-disable react/prop-types */
import './requestbankstatement.scss'
import { useState } from 'react'
import { useQuery } from 'react-query'
import ActionModal from 'src/components/ActionModal'
import FormTitleBar from 'src/components/FormTitleBar'
import { TextArea } from 'src/components/InputFields'
import CloseIcon from 'src/assets/images/black-close-icon.svg'
import { requestBankStatementApi } from 'src/api/admin/payment-plans/ssePlan'
import Toast from 'src/components/Toast'
import { InlineLoader } from 'src/components/Loader'
import { errorHandler } from 'src/utils/errorHandler'
import Button from 'src/components/Button'
import { isMobile } from 'src/utils/mediaQueries'

const RequestBankStatementModal = ({ closeModal, estimationId }) => {
    const [successModal, setSuccessModal] = useState(false)
    const [requestReason, setRequestReason] = useState('')
    const [errors, setErrors] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleInputChange = event => {
        if (requestReason === '') {
            setErrors('This field is required')
        } else {
            setErrors('')
        }
        setRequestReason(event.target.value)
    }

    const handleRequestBankStatement = () => {
        if (requestReason === '') {
            setErrors({ requestReason: 'This field is required' })
        } else {
            requestBankstatementRefetch()
        }
    }

    const handleModalAction = () => {
        setSuccessModal(false)
        window.location.reload()
    }

    const {
        refetch: requestBankstatementRefetch,
        isLoading: requestBankStatementLoading,
    } = useQuery(
        ['requestConsumerBankStatementApi'],
        () =>
            requestBankStatementApi(estimationId, {
                reason: requestReason,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setSuccessModal(true)
                closeModal()
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    return (
        <div style={{ padding: 29 }}>
            <div style={{ position: 'relative' }}>
                <span className="AWCloseIconWrapper">
                    <img
                        src={CloseIcon}
                        alt="close icon"
                        onClick={closeModal}
                        style={{
                            width: '27px',
                            marginTop: isMobile ? 0 : -57,
                        }}
                    />
                </span>
                <FormTitleBar
                    title={'Request Bank Statement'}
                    subtitle="Enter a reason for requesting bank statement"
                    marginTop={isMobile && 20}
                />
            </div>
            {toastError && <Toast messageType="error" message={errorMessage} />}
            <ActionModal
                actionModalOpen={successModal}
                headerText={'Request Sent Successfully'}
                subTitle="We've solved the problems that make it difficult for energy providers to transition millions"
                closeModal={handleModalAction}
                actionHandler={handleModalAction}
                actionType="success"
                actionText="Okay, Got it"
                loading={''}
                noCancelBtn={true}
                noBackLink
            />
            <div className="DeclinePlanWrapper">
                <div style={{ marginTop: 28 }}>
                    <TextArea
                        width="464px"
                        height="146px"
                        name="requestReason"
                        placeholder={'Enter Reason'}
                        rows={10}
                        maxHeight={193}
                        value={requestReason}
                        handleChange={handleInputChange}
                        errorMessage={errors.requestReason}
                    />
                </div>

                <div style={{ marginTop: 60 }}>
                    <Button
                        btnWidth="160px"
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnHeight="56px"
                        handleClick={handleRequestBankStatement}
                    >
                        {requestBankStatementLoading ? (
                            <InlineLoader />
                        ) : (
                            'Request'
                        )}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default RequestBankStatementModal
