const showOnePageKYC = process.env.REACT_APP_FLAG_SHOW_ONE_PAGE_KYC === 'true'
const showDynamicOnePageKYC =
    process.env.REACT_APP_FLAG_SHOW_DYNAMIC_ONE_PAGE_KYC === 'true'

const getConsumerKYCHome = (usedOnePageKYC, customerType, usesDynamicKYC) => {
    if (!showOnePageKYC || !usedOnePageKYC) {
        return '/consumer/account-setup/overview'
    } else if (showDynamicOnePageKYC && usedOnePageKYC && usesDynamicKYC) {
        return '/consumer/account-setup/kyc'
    } else if (
        showOnePageKYC &&
        usedOnePageKYC &&
        customerType === 'RESIDENTIAL'
    ) {
        return '/consumer/account-setup/residential'
    } else if (
        showOnePageKYC &&
        usedOnePageKYC &&
        customerType === 'BUSINESS'
    ) {
        return '/consumer/account-setup/business'
    } else {
        return '/consumer/account-setup/overview'
    }
}

export default getConsumerKYCHome
