/* eslint-disable react/prop-types */
import { useReducer, useCallback, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import ProductsContainer from '../../../components/ProductsContainer'
import { getComponentTypeApi } from '../../../api/products/component'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'

import ModalStepThree from './modalStepThree'
import { denyPermission } from '../../../utils/permissionFramework'

const initialState = {
    formatedComponentTypes: [],
    componentName: '',
    componentType: '',
    componentWarranty: '',
    componentCost: '',
    componentDescription: '',
    componentTypeId: '',
    processStep: 1,
    selectedAttributes: [],
    userSelectedType: '',
    isGlobal: null,
    brandName: '',
    brandId: '',
}

function productReducer(state = initialState, action) {
    switch (action.type) {
        case 'parent/set-component-type': {
            const payload = action.payload
            const formatedComponentTypes = payload.map(item => {
                return {
                    ...item,
                    value: item.name,
                    id: item.id,
                }
            })

            return { ...state, formatedComponentTypes }
        }

        case 'parent/goto-next': {
            const payload = action.payload

            // Todo: Know when to submit

            const step = (state.processStep + 1) % 3

            return { ...state, ...payload, processStep: step }
        }

        case 'parent/goto-previous': {
            const payload = action.payload
            let step = state.processStep - 1

            if (payload?.attributes?.length < 1) {
                step = 1
            }

            return { ...state, ...payload, processStep: step }
        }

        case 'parent/goto-step3': {
            const payload = action.payload
            if (payload?.attributes?.length < 1) {
                return {
                    ...state,
                    ...payload,
                    processStep: 3,
                }
            } else {
                return {
                    ...state,
                    selectedAttributes: payload,
                    processStep: 3,
                }
            }
        }

        case 'parent/goto-step1': {
            const payload = action.payload

            return { ...state, selectedAttributes: payload, processStep: 1 }
        }

        case 'refresh': {
            return {
                ...state,
                componentName: '',
                componentType: '',
                componentCost: '',
                componentDescription: '',
                componentTypeId: '',
                processStep: 1,
                selectedAttributes: [],
                userSelectedType: '',
                isGlobal: null,
            }
        }

        default:
            return state
    }
}

// eslint-disable-next-line react/prop-types
const AddComponent = ({
    modalFlow = false,
    parentCallback,
    setComponentModal,
}) => {
    const [state, dispatch] = useReducer(productReducer, initialState)
    const history = useHistory()
    const sunfiId = history?.location?.state?.sunfiId ?? ''
    const isAdminWorkspace = history?.location?.state?.isAdminWorkspace ?? false
    const providerName = history?.location?.state?.name ?? ''

    useEffect(() => {
        if (
            denyPermission('admin', 'product_library', 'component:can_create')
        ) {
            history.push('/app/403-page')
        }
    }, [history])

    const { refetch } = useQuery(
        'getComponentTypes',
        () => getComponentTypeApi(isAdminWorkspace),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                dispatch({
                    type: 'parent/set-component-type',
                    payload: data?.data?.data,
                })
            },
        },
    )
    const gotoNextStep = useCallback(payload => {
        dispatch({ type: 'parent/goto-next', payload })
    }, [])

    const gotoPreviousStep = useCallback(payload => {
        dispatch({ type: 'parent/goto-previous', payload })
    }, [])

    const step = state.processStep

    useEffect(() => {
        refetch()

        // eslint-disable-next-line
    }, [])

    return (
        <>
            {step === 1 &&
                (!modalFlow ? (
                    <ProductsContainer text="Pro Tip: Components are the building blocks of letsdrop-downenergy solutions. Build your component library to more easily access our payment plans">
                        <StepOne
                            initialState={state}
                            gotoNext={gotoNextStep}
                            gotoStep3={payload =>
                                dispatch({ type: 'parent/goto-step3', payload })
                            }
                            goToPrevious={gotoPreviousStep}
                        />
                    </ProductsContainer>
                ) : (
                    <>
                        <StepOne
                            initialState={state}
                            gotoNext={gotoNextStep}
                            gotoStep3={payload =>
                                dispatch({ type: 'parent/goto-step3', payload })
                            }
                            goToPrevious={gotoPreviousStep}
                            setComponentModal={setComponentModal}
                            modalFlow
                        />
                    </>
                ))}

            {step === 2 &&
                (!modalFlow ? (
                    <ProductsContainer text="Pro Tip: Components have attributes. For example, a Lead battery component might have attributes of 12 V, 100 AH, 2.4kW capacity">
                        <StepTwo
                            initialState={state}
                            gotoNext={payload =>
                                dispatch({ type: 'parent/goto-step3', payload })
                            }
                            gotoPrevious={payload =>
                                dispatch({ type: 'parent/goto-step1', payload })
                            }
                        />
                    </ProductsContainer>
                ) : (
                    <StepTwo
                        initialState={state}
                        gotoNext={payload =>
                            dispatch({ type: 'parent/goto-step3', payload })
                        }
                        gotoPrevious={payload =>
                            dispatch({ type: 'parent/goto-step1', payload })
                        }
                        modalFlow
                    />
                ))}

            {step === 3 &&
                (!modalFlow ? (
                    <ProductsContainer text="Pro Tip: Uploading pictures helps your future product library become easier to navigate for you and your team">
                        <StepThree
                            initialState={state}
                            gotoNext={gotoNextStep}
                            goToPrevious={gotoPreviousStep}
                            restart={() => {
                                dispatch({ type: 'refresh' })
                                refetch()
                            }}
                            sunfiId={sunfiId}
                            isAdminWorkspace={isAdminWorkspace}
                            providerName={providerName}
                        />
                    </ProductsContainer>
                ) : (
                    <ModalStepThree
                        initialState={state}
                        gotoNext={gotoNextStep}
                        goToPrevious={gotoPreviousStep}
                        parentCallback={parentCallback}
                        restart={() => {
                            dispatch({ type: 'refresh' })
                            refetch()
                        }}
                        sunfiId={sunfiId}
                        isAdminWorkspace={isAdminWorkspace}
                        providerName={providerName}
                    />
                ))}
        </>
    )
}

export default AddComponent
