export const DepRoleData = [
    {
        id: 1,
        value: 'Business Development Manager',
    },
    {
        id: 2,
        value: 'Business Development Officer',
    },
    {
        id: 3,
        value: 'Business Development Associate',
    },
    {
        id: 4,
        value: 'Sales Manager',
    },
    {
        id: 5,
        value: 'Sales Officer',
    },
    {
        id: 6,
        value: 'Sales Associate',
    },
    {
        id: 7,
        value: 'Admin Officer',
    },
    {
        id: 8,
        value: 'Admin Manager',
    },
    {
        id: 9,
        value: 'Admin Associate',
    },
    {
        id: 10,
        value: 'Project Manager',
    },
    {
        id: 11,
        value: 'Project Management Associate',
    },
    {
        id: 12,
        value: 'Project Designer',
    },
    {
        id: 13,
        value: 'Technical Officer',
    },
    {
        id: 14,
        value: 'Technical Associate',
    },
    {
        id: 15,
        value: 'Solar Engineer',
    },
    {
        id: 16,
        value: 'Customers Relation/Experience Manager',
    },
    {
        id: 17,
        value: 'Energy Management Consultant',
    },
    {
        id: 18,
        value: 'Team lead',
    },
    {
        id: 19,
        value: 'Chief Executive Officer',
    },
    {
        id: 20,
        value: 'Chief Technology Officer ',
    },
    {
        id: 21,
        value: 'Chief Operations Officer ',
    },
    {
        id: 22,
        value: 'Chief Business Officer ',
    },
    {
        id: 23,
        value: 'Chief Financial Officer ',
    },
    {
        id: 24,
        value: 'Chief Marketing Officer ',
    },
    {
        id: 25,
        value: 'Chief Information Officer ',
    },
]
