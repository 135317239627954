import { dashboardMetricsData } from '../../utils'
import 'src/pages/Consumer/ConsumerWorkspace/consumerworkspace.scss'
import NairaSymbol from 'src/components/NairaSymbol'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

const Metrics = clusterSummary => {
    return dashboardMetricsData(clusterSummary)?.map((item, i) => (
        <div
            key={i}
            className={`ConsumerWorkspaceSummary_Card DynamicCard_Idx${i} RepDashboard`}
        >
            <>
                <div className={'ConsumerWorkspaceSummary_Card_Icon'}>
                    {item?.icon}
                </div>
                <h4>{item?.title}</h4>
                <p>
                    {item?.symbol === 'naira' ? (
                        <>
                            <NairaSymbol />{' '}
                        </>
                    ) : (
                        ''
                    )}
                    {item?.value}
                </p>
            </>
        </div>
    ))
}

const DashboardMetrics = ({ clusterSummary }) => {
    const settings = {
        arrows: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        dots: true,
    }

    return (
        <>
            <div className="RepDashboardMobile">
                <Slider className="center" {...settings}>
                    {Metrics(clusterSummary)}
                </Slider>
            </div>
            <div className={'ConsumerWorkspaceSummary RepDashboard'}>
                {Metrics(clusterSummary)}
            </div>
        </>
    )
}

DashboardMetrics.propTypes = {
    clusterSummary: PropTypes.object,
}

export default DashboardMetrics
