export const determineModalTexts = actionType => {
    switch (actionType) {
        case 'edit-user-details':
            return {
                header: 'User Details Updated Successfully',
                subtitle: 'User details have been updated successfully',
            }
        case 'deactivate-user':
            return {
                header: 'User Deactivated Successfully',
                subtitle: 'User has been deactivated Successfully',
            }
        case 'activate-user':
            return {
                header: 'User Activated Successfully',
                subtitle: 'User has been activated successfully',
            }
        default:
            return {
                header: '',
                subtitle: '',
            }
    }
}

export const determineButtonTwoProps = userData => {
    if (
        userData?.state === 'ACTIVE' &&
        userData?.userTypes?.includes('DEACTIVATED_USER')
    ) {
        return {
            buttonTwoTextValue: 'Activate User',
            buttonTwoActionTypeValue: 'activate-user',
        }
    }
    return {
        buttonTwoTextValue: 'Deactivate User',
        buttonTwoActionTypeValue: 'deactivate-user',
    }
}
