import { ReactComponent as UserActive } from 'src/assets/images/user-square-white.svg'
import { ReactComponent as UserInactive } from 'src/assets/images/user-square-blue.svg'
import { ReactComponent as MoneyActive } from 'src/assets/images/moneys.svg'
import { ReactComponent as MoneyInactive } from 'src/assets/images/moneys-blue.svg'

export const accountTypeOptions = [
    {
        title: 'Your salary account',
        description: 'The account is used to receive your salary',
        key: 'salary-account',
        activeIcon: <UserActive />,
        inactiveIcon: <UserInactive />,
    },
    {
        title: 'Not a salary account',
        description: 'The account is used for other transactions',
        key: 'not-salary',
        activeIcon: <MoneyActive />,
        inactiveIcon: <MoneyInactive />,
    },
]
