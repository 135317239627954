import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NewLandingPageFormSchema } from 'src/utils/validationSchema'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'
import useValidateExistingCustomer from 'src/components/ConsumerLandingPageBody/hooks/useValidateExistingCustomer'
import { useMutation } from 'react-query'
import { registerDropOffApi } from 'src/api/shoppingExperience/landingPage'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { encrypt } from 'src/utils/cryptography'
import useGetReturningCustomerStatus from 'src/components/ConsumerLandingPageBody/hooks/useGetReturningCustomerStatus'

export const useSharedForm = (
    closedClusterData,
    isClosedCluster,
    openActionModal,
    closeSharedModal,
    setLoginInputs,
    setReturningCustomerId,
    setHasDiscardedDeal,
) => {
    const history = useHistory()
    const { errorAlert } = useCustomToast()
    const [errors, setErrors] = useState({})

    const { validateUserIdMutation } = useValidateExistingCustomer({
        setErrors,
    })
    const [inputs, setInputs] = useState({
        firstName: '',
        email: '',
        phone: '',
    })

    const { getReturningCustomerStatus, getReturningCustomerStatusLoading } =
        useGetReturningCustomerStatus({
            email: inputs?.email,
            phone: inputs?.phone,
            onSuccess: data => {
                const isNewCustomer = data?.status === 'NEW_CUSTOMER'
                const hasDiscardedDeal = data?.has_discarded_deal
                if (data?.id) {
                    setReturningCustomerId(data?.id)
                }
                if (setHasDiscardedDeal) {
                    setHasDiscardedDeal(hasDiscardedDeal)
                }
                if (isNewCustomer) {
                    registerDropOffMutate()
                } else {
                    setLoginInputs({
                        firstName: inputs?.firstName,
                        email: inputs?.email,
                        phone: formatPhoneNumber(inputs?.phone),
                    })
                    openActionModal('customer-exists')
                    closeSharedModal()
                }
            },
        })

    const [countryCode] = useState('+234')
    const showReturningCustomerFlow =
        process.env
            .REACT_APP_FLAG_RETURNING_CUSTOMERS_ON_SHARED_PROFILES_AND_PACKAGES ===
        'true'

    const { mutate: registerDropOffMutate, isLoading: registerDropOffLoading } =
        useMutation({
            mutationFn: () =>
                registerDropOffApi({
                    email: inputs?.email,
                    phone_number: formatPhoneNumber(inputs.phone, countryCode),
                    first_name: inputs?.firstName,
                }),
            onSuccess: () => {
                localStorage.setItem(
                    'sunfiUserSSEGettingStartedInfo',
                    encrypt(
                        JSON.stringify({
                            firstName: inputs.firstName,
                            email: inputs.email,
                            ...(inputs?.phone !== '' && {
                                phone: formatPhoneNumber(inputs.phone),
                            }),
                            ...(isClosedCluster && {
                                useType: closedClusterData?.customer_type,
                            }),
                        }),
                    ),
                )
                history.push(
                    location.pathname.replace(
                        '/shared',
                        '' + `${window.location.search}`,
                    ),
                    {
                        shared: true,
                    },
                )
                location.reload()
            },
            onError: error => {
                errorAlert(errorHandler(error?.response?.data))
            },
        })

    const handleInputChange = event => {
        const { name, value } = event.target
        if (name === 'email') {
            setInputs(prev => ({ ...prev, [name]: value.replaceAll(' ', '') }))
        } else if (name === 'phone' || name === 'firstName') {
            setInputs(prev => ({
                ...prev,
                [name]: formatInputValue(name, value),
            }))
        } else {
            setInputs(prev => ({ ...prev, [name]: value }))
        }

        if (errors[name]) {
            delete errors[name]
        }
    }

    const handleInputSubmitNewOnboardingFlow = async () => {
        if (!inputs.email) {
            return
        }
        if (inputs.phone?.length < 5) {
            return
        }

        if (showReturningCustomerFlow) {
            getReturningCustomerStatus()
        } else {
            const [emailResult, phoneResult] = await Promise.all([
                validateUserIdMutation.mutateAsync({ email: inputs.email }),
                validateUserIdMutation.mutateAsync({
                    phone_number: formatPhoneNumber(inputs.phone, countryCode),
                }),
            ])

            const emailExists = emailResult?.data?.data
            const phoneExists = phoneResult?.data?.data

            if (emailExists || phoneExists) {
                const updatedErrors = {
                    ...errors,
                }

                if (emailExists) {
                    updatedErrors.email =
                        'This email address already exist, you can try Sign In'
                }

                if (phoneExists) {
                    updatedErrors.phone =
                        'This phone number already exist, you can try Sign In'
                }

                setErrors(updatedErrors)
            } else {
                delete errors.email
                delete errors.phone
                registerDropOffMutate()
            }
        }
    }

    const validateInputs = () => {
        NewLandingPageFormSchema({})
            .validate(inputs, {
                context: {
                    fields: ['email', 'phone', 'firstName'],
                },
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                handleInputSubmitNewOnboardingFlow()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    const handleGetStarted = () => {
        validateInputs()
    }

    const closeClusterModal = () => {
        registerDropOffMutate()
    }

    const validateClusterEmailDomain = (email, requiredDomain) => {
        const emailParts = email.split('@')
        const baseDomain = requiredDomain
        if (emailParts.length !== 2) {
            return false
        }
        const domain = emailParts[1]
        if (domain.toLowerCase() !== baseDomain.toLowerCase()) {
            setErrors({ ...errors, email: 'Invalid email address' })
        } else {
            closeClusterModal()
        }
    }

    const handleClosedClusterGetStarted = () => {
        NewLandingPageFormSchema({})
            .validate(inputs, {
                context: { fields: ['email', 'phone', 'firstName'] },
                abortEarly: false,
            })
            .then(() => {
                setErrors({})
                if (closedClusterData?.cluster_origination?.website) {
                    validateClusterEmailDomain(
                        inputs.email,
                        closedClusterData?.cluster_origination?.website,
                    )
                } else {
                    closeClusterModal()
                }
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = {
                        ...errList,
                        [e.path]: e.message,
                    }
                })
                setErrors(errList)
            })
    }

    return {
        errors,
        inputs,
        setInputs,

        handleInputChange,
        validateUserIdMutation,
        handleGetStarted,
        registerDropOffLoading,
        handleClosedClusterGetStarted,
        getReturningCustomerStatusLoading,
    }
}
