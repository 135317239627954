import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/IdentityInformation/identityinformation.scss'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import ImageIcon from 'src/assets/images/image-icon.svg'
import ImageDataSection from '../../../../IdentityInformation/components/ImageDataSection'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'

const Avatar = ({
    inputs,
    errors,
    isAdminUser,
    isCustomerApproved,
    toggleSelfieImageModal,
}) => {
    return (
        <>
            {!isAdminUser && (
                <Label
                    title="Add a photo"
                    subTitle="We need your photo to verify that you are the one"
                />
            )}
            {inputs?.selfieImageUrl?.length > 0 ? (
                <>
                    <div
                        hidden={isAdminUser}
                        className="ConfirmedImgWrapper"
                        data-testid="confirmed-img-wrapper"
                    >
                        <img
                            src={inputs?.selfieImageUrl}
                            alt="uploaded-image"
                            data-testid="uploaded-image"
                        />
                        <button
                            onClick={() => {
                                toggleSelfieImageModal()
                            }}
                            disabled={isAdminUser || isCustomerApproved}
                            data-testid="change-image-button"
                        >
                            <div>
                                <img src={ImageIcon} alt="img-icon" />
                            </div>
                            <p>Take a selfie or Upload a photo</p>
                        </button>
                    </div>
                    {isAdminUser && (
                        <div
                            className="imageDataContainer"
                            data-testid="image-data-section"
                        >
                            <ImageDataSection />
                        </div>
                    )}
                </>
            ) : (
                <button
                    className="UploadImageCTA"
                    onClick={() => {
                        toggleSelfieImageModal()
                    }}
                    disabled={isAdminUser || isCustomerApproved}
                    data-testid="upload-image-button"
                >
                    <div>
                        <img src={ImageIcon} alt="img-icon" />
                    </div>
                    <p>Take a selfie or Upload a photo</p>
                </button>
            )}
            {errors?.selfieImageUrl && (
                <p className="ErrorText" data-testid="error-selfie-image-url">
                    {errors?.selfieImageUrl}
                </p>
            )}
            <VerticalSpacer top={24} />
        </>
    )
}

Avatar.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default Avatar
