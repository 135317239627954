export const formatCompanyInfo = data => {
    let formattedValues = []

    const email = data?.user?.email ?? '-'
    const phoneNumber = data?.user?.phone_number ?? '-'
    const companyName = data?.company_name ?? '-'
    const staffStrength = data?.staff_strength ?? '-'
    const businessType = data?.business_type ?? '-'
    const rcNumber = data?.cac_number ?? '-'
    const companyAddress = data?.company_street_address
        ? `${data?.company_street_address}, ${data?.city}, ${data?.state}`
        : '-'

    formattedValues.push(
        { label: 'Email', value: email },
        {
            label: 'Phone Number',
            value: phoneNumber,
        },
        {
            label: 'Company Name',
            value: companyName,
        },
        {
            label: 'Staff Strength',
            value: staffStrength,
        },
        {
            label: 'Business  Type',
            value: businessType,
        },
        {
            label: 'RC Number',
            value: rcNumber,
        },
        {
            label: 'Address',
            value: companyAddress,
        },
    )
    return formattedValues.filter(Boolean)
}
