import { useQuery, useMutation } from 'react-query'
import {
    sseRetrieveConsumerEstimation,
    sseRetrieveConsumerEstimationSummary,
    sseRepaymentTenure,
    sseRetriveRepaymentSetupTypeApi,
} from './api'

export const useSSERetrieveConsumerEstimation = ({
    enabled,
    onSuccess,
    onError,
    options,
}) => {
    const { data, refetch, isLoading } = useQuery(
        'sse-consumer-estimation',
        () => sseRetrieveConsumerEstimation(),
        {
            enabled: enabled,
            retry: false,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: err => {
                if (onError) {
                    onError(err?.response?.data?.errors)
                }
            },
            ...options,
        },
    )

    return {
        sseConsumerEstimationRefetch: refetch,
        sseConsumerEstimationLoading: isLoading,
        sseConsumerEstimationData: data,
    }
}

export const useSSERetrieveConsumerEstimationSummary = ({
    enabled,
    onSuccess,
    onError,
    options,
}) => {
    const { data, refetch, isLoading } = useQuery(
        'sse-consumer-estimation-summary',
        () => sseRetrieveConsumerEstimationSummary(),
        {
            enabled: enabled,
            retry: false,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: err => {
                if (onError) {
                    onError(err?.response?.data?.errors)
                }
            },
            ...options,
        },
    )

    return {
        sseConsumerEstimationSummaryRefetch: refetch,
        sseConsumerEstimationSummaryLoading: isLoading,
        sseConsumerEstimationSummaryData: data,
    }
}

export const useSSEPersistRepaymentTenure = ({ onSuccess, onError }) => {
    const { error, data, isSuccess, mutate, isLoading } = useMutation({
        mutationFn: ({ estimation_id, tenure }) =>
            sseRepaymentTenure({
                estimation_id,
                tenure,
            }),
        onSuccess: data => {
            if (onSuccess) {
                onSuccess(data?.data?.data)
            }
        },
        onError: err => {
            if (onError) {
                onError(err?.response?.data)
            }
        },
    })

    return {
        submitSSERepaymentTenure: mutate,
        sseRepaymentTenureLoading: isLoading,
        sseRepaymentTenureData: data,
        sseRepaymentTenureError: error,
        sseRepaymentTenureSuccess: isSuccess,
    }
}

export const useRetriveRepaymentMethodSetupType = ({
    estimationId,
    enabled,
    onSuccess,
    onError,
}) => {
    const { refetch: refetchRepaymentMethodType } = useQuery(
        'repayment-setup-type',
        () => sseRetriveRepaymentSetupTypeApi(estimationId),
        {
            enabled: enabled,
            retry: false,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: error => {
                if (onError) {
                    onError(error?.response?.data?.message)
                }
            },
        },
    )

    return {
        refetchRepaymentMethodType,
    }
}
