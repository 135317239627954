import PropTypes from 'prop-types'
import { SelectField } from 'src/components/InputFields'

import { decisionData } from './data'

const Tenure = ({ inputs, handleSelectChange, errors, paymentPlans }) => {
    return (
        <>
            <div className={'UploadModalWrapper'}>
                <SelectField
                    name="decision"
                    initialOption="Select decision"
                    selectWidth="100%"
                    handleChange={handleSelectChange}
                    withCheckBox={true}
                    dropdownPositionRelative
                    selectedValue={inputs?.decision}
                    currentSelected={inputs?.decision}
                    values={decisionData?.map(data => ({
                        value: data,
                    }))}
                    value={inputs?.decision}
                    marginBottom="16px"
                    errorMessage={errors.decision}
                    floatingLabel={
                        inputs.decision === '' ? '' : 'Select decision'
                    }
                />
                <SelectField
                    name="tenure"
                    initialOption="Select tenure"
                    selectWidth="100%"
                    handleChange={handleSelectChange}
                    withCheckBox={true}
                    dropdownPositionRelative
                    selectedValue={inputs?.denure}
                    currentSelected={inputs?.tenure}
                    values={paymentPlans?.map(plan => ({
                        value: plan.tenure,
                    }))}
                    value={inputs?.tenure}
                    marginBottom="16px"
                    errorMessage={errors.tenure}
                    floatingLabel={inputs.tenure === '' ? '' : 'Select tenure'}
                />
            </div>
        </>
    )
}

export default Tenure

Tenure.propTypes = {
    inputs: PropTypes.object,
    handleSelectChange: PropTypes.func,
    errors: PropTypes.object,
    paymentPlans: PropTypes.array,
}
