//custom hook to tell if an element is in the viewport or not
//pass in scrollDirection to keep the value true as the user continues to scroll down
import { useState, useEffect, useRef, useCallback } from 'react'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

export const useOnScreen = (scrollDirection = '') => {
    const [isIntersecting, setIntersecting] = useState(false)
    const ref = useRef()

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    if (scrollDirection === 'down' || scrollDirection === '') {
                        setIntersecting(true)
                    } else {
                        setIntersecting(false)
                    }
                }
            },
            { threshold: 0.4, rootMargin: '0px' },
        )

        if (ref.current) {
            observer.observe(ref.current)
        }

        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect()
        }
    }, [ref, scrollDirection])
    return [isIntersecting, ref]
}

//This was created because the first useOnScreen was a bit unstable
export const useOnScreenV2 = (threshold = 0) => {
    const [inViewport, setInViewport] = useState(false)
    const targetRef = useRef(null)
    const { isMobile } = useMediaQueries()
    const timeoutId = useRef(null)

    const checkVisibility = useCallback(() => {
        if (targetRef.current) {
            const target = targetRef.current
            const targetRect = target.getBoundingClientRect()
            const windowHeight =
                window.innerHeight || document.documentElement.clientHeight
            const windowWidth =
                window.innerWidth || document.documentElement.clientWidth

            const isElementInViewport =
                ((targetRect.top <= windowHeight && targetRect.top >= 0) ||
                    (targetRect.bottom <= windowHeight &&
                        targetRect.bottom >= 0 &&
                        targetRect.bottom - targetRect.top >=
                            targetRect.height * threshold)) &&
                ((targetRect.left <= windowWidth && targetRect.left >= 0) ||
                    (targetRect.right <= windowWidth &&
                        targetRect.right >= 0 &&
                        targetRect.right - targetRect.left >=
                            targetRect.width * threshold))

            const updateViewportState = () => setInViewport(isElementInViewport)

            // clear existing timeout if there is one
            if (timeoutId.current) {
                clearTimeout(timeoutId.current)
            }

            // timeout to prevent rapid blinking on mobile
            if (isMobile) {
                timeoutId.current = setTimeout(updateViewportState, 60)
            } else {
                updateViewportState()
            }
        }
    }, [threshold, isMobile])

    useEffect(() => {
        const handleScroll = () => {
            checkVisibility()
        }

        const handleResize = () => {
            checkVisibility()
        }

        window.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleResize)
        checkVisibility() // Initial check

        // cleanup
        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current)
            }
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleResize)
        }
    }, [threshold, checkVisibility])

    return [targetRef, inViewport]
}
