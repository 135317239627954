/* eslint-disable react/prop-types */
import styles from 'src/pages/NotFound/notfound.module.scss'
import { ReactComponent as LeftArrow } from '../../assets/images/LeftArrow.svg'
import { ReactComponent as IllustrationBgWhite } from '../../assets/images/404IllustrationBgWhiteResp.svg'

const ErrorFallback = () => {
    const { NotFoundContainer, NavHome, Illustrator } = styles

    return (
        <>
            <div className={NotFoundContainer}>
                <IllustrationBgWhite className={Illustrator} />
                <h2>Something went wrong</h2>
                <p>
                    Energy Storage helps you store energy for consumption
                    whenever it is needed.
                    <br />
                    Imagine the possibilities. As the world transitions to
                    cleaner energy.
                </p>
                <div>
                    <a
                        href={window.location.href}
                        className={NavHome}
                        style={{
                            color: 'white',
                            fontWeight: '600',
                        }}
                    >
                        <LeftArrow /> Go Home
                    </a>
                </div>
            </div>
        </>
    )
}

export default ErrorFallback
