/* eslint-disable react/prop-types */

import React, { useEffect } from 'react'
import { Modal } from 'antd'
import './secondarymodal.scss'
import Button from 'src/components/Button'
import { ReactComponent as CloseIcon } from 'src/assets/images/close-icon-no-radius-large.svg'
import { ReactComponent as WhiteCloseIcon } from 'src/assets/images/close-icon-white.svg'
import { InlineLoader, InlineLoaderDark } from 'src/components/Loader'
import ChatCTA from 'src/components/ChatCTA'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const SecondaryModal = ({
    showModal,
    onCancel,
    content,
    noPadding,
    modalWidth,
    modalHeight,
    modalClass,
    closable,
    title = '',
    showFooter = false,
    footerLeftContent = 'chat',
    primaryBtnText,
    primaryBtnWidth,
    primaryBtnHeight,
    handlePrimaryBtnClick,
    primaryBtnLoading,
    primaryBtnDisabled,
    secondaryBtnDisabled,
    secondaryBtnText = '',
    secondaryBtnWidth,
    secondaryBtnHeight,
    handleSecondaryBtnClick,
    noPrimaryBtnTextInSecondary = true,
    chatCTAText,
    v2,
    kycSecondaryButtonStopLoading = false,
    secondaryBtnLoading = false,
    footerClassName = '',
    mobileModalTop = '20%',
    dataTestId = undefined,
    primaryBtnDataTestId = undefined,
}) => {
    const [width] = React.useState(window.innerWidth)
    const { isMobile } = useMediaQueries()

    let newWidth = ''
    let newHeight = ''

    if (width < 500) {
        newWidth = '100%'
        newHeight = modalHeight
    } else {
        newWidth = modalWidth
        newHeight = modalHeight
    }

    // set the ant-modal-content mobile top dynamically
    useEffect(() => {
        document.documentElement.style.setProperty(
            '--modal-top',
            mobileModalTop,
        )
    }, [mobileModalTop, showModal])

    useEffect(() => {
        const chatElement = document.querySelector('[data-id="zsalesiq"]')
        if (chatElement) {
            if (isMobile && showModal) {
                chatElement.style.top = '13%'
            } else {
                chatElement.style.top = 'unset'
            }
        }
    }, [isMobile, showModal])

    return (
        <>
            {v2 ? (
                <Modal
                    width={newWidth}
                    bodyStyle={{
                        height: newHeight,
                        padding: noPadding,
                        width: newWidth,
                    }}
                    open={showModal}
                    onCancel={onCancel ? onCancel : () => {}}
                    footer={null}
                    wrapClassName={
                        modalClass || title !== ''
                            ? 'SecondaryModalV2'
                            : 'SecondaryModal'
                    }
                    closable={closable}
                    closeIcon={
                        <CloseIcon
                            data-testid="modal-close-icon"
                            className="SecondaryModal_CloseIcon"
                        />
                    }
                    destroyOnClose
                    data-testid={dataTestId}
                >
                    {title !== '' ? (
                        <div
                            className={
                                'SecondaryModalV2ContentWrapper noBodyScrollbar'
                            }
                        >
                            <section className={'SecondaryModalTitleWrapper'}>
                                <h2>{title}</h2>
                            </section>
                            {content}
                            {showFooter && (
                                <section
                                    className={`SecondaryModalFooterWrapper ${footerClassName}`}
                                >
                                    {footerLeftContent === 'chat' ? (
                                        <ChatCTA
                                            width={'fit-content'}
                                            text={chatCTAText}
                                        />
                                    ) : footerLeftContent === 'button' ? (
                                        <Button
                                            btnBgColorOutline={'#FFFFFF'}
                                            btnTextColorOutline={'#004AAD'}
                                            btnWidth={
                                                secondaryBtnWidth || '160px'
                                            }
                                            btnHeight={
                                                secondaryBtnHeight || '53px'
                                            }
                                            handleClick={
                                                handleSecondaryBtnClick
                                            }
                                            type="outline"
                                            disabled={secondaryBtnDisabled}
                                        >
                                            {primaryBtnLoading ? (
                                                !kycSecondaryButtonStopLoading ? (
                                                    <InlineLoader />
                                                ) : null
                                            ) : (
                                                !noPrimaryBtnTextInSecondary &&
                                                primaryBtnText
                                            )}
                                            {secondaryBtnLoading ? (
                                                <InlineLoaderDark />
                                            ) : (
                                                secondaryBtnText
                                            )}
                                        </Button>
                                    ) : null}

                                    <Button
                                        btnBgColor={'#004AAD'}
                                        btnTextColor={'#FFFFFF'}
                                        btnWidth={primaryBtnWidth || '160px'}
                                        btnHeight={primaryBtnHeight || '53px'}
                                        handleClick={handlePrimaryBtnClick}
                                        marginLeft={'auto'}
                                        disabled={primaryBtnDisabled}
                                        dataTestId={primaryBtnDataTestId}
                                    >
                                        {primaryBtnLoading ? (
                                            <InlineLoader />
                                        ) : (
                                            primaryBtnText
                                        )}
                                    </Button>
                                </section>
                            )}
                        </div>
                    ) : (
                        content
                    )}
                </Modal>
            ) : (
                <Modal
                    width={newWidth}
                    bodyStyle={{
                        height: newHeight,
                        padding: noPadding,
                        width: newWidth,
                    }}
                    open={showModal}
                    onCancel={onCancel ? onCancel : () => {}}
                    footer={null}
                    wrapClassName={modalClass || 'SecondaryModal'}
                    closable={closable}
                    closeIcon={
                        modalClass === 'videoModal' ? (
                            <WhiteCloseIcon />
                        ) : (
                            <CloseIcon
                                data-testid="modal-close-icon"
                                className="SecondaryModal_CloseIcon"
                            />
                        )
                    }
                    data-testid={dataTestId}
                >
                    {content}
                </Modal>
            )}
        </>
    )
}

export default SecondaryModal
