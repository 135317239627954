import { appTracking } from 'src/utils/appTracker'
import { pageTitle, pageUrlName, pageTracker } from './data'

const useWelcome = () => {
    const handleSignIn = (each, i) => {
        window.location.pathname = each?.link
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            i === 0
                ? 'CONSUMER_CLICKS_SIGN_IN_AS_A_CONSUMER'
                : 'CONSUMER_CLICKS_SIGN_IN_AS_A_PROVIDER',
            'KYC_CONSUMER_TYPE',
            'KYC_CONSUMER_WELCOME_PAGE',
        )
    }

    return {
        handleSignIn,
    }
}

export default useWelcome
