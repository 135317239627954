import { useQuery } from 'react-query'
import { getCreditScoreCard } from './api'

export const useGetCreditScoreCard = ({
    enabled,
    onSuccess,
    onError,
    customer_type = '',
}) => {
    const { data, refetch, isFetching } = useQuery(
        'get-credit-scorecard',
        () => getCreditScoreCard(customer_type),
        {
            enabled: enabled,
            retry: false,
            onSuccess: data => {
                if (onSuccess) {
                    onSuccess(data?.data?.data)
                }
            },
            onError: err => {
                if (onError) {
                    onError(err?.response?.data?.errors)
                }
            },
        },
    )

    return {
        creditScoreCardRefetch: refetch,
        creditScoreCardFetching: isFetching,
        creditScoreCardData: data?.data?.data,
    }
}
