export const npsRatingModalTitleMap = {
    'savings-calculator': 'Rate Our Solar Savings Calculator',
    'energy-assessment': 'Rate our package recommendation',
    'completed-kyc': 'Rate your onboarding experience',
    'provider-completed-onboarding': 'Rate your onboarding experience',
    'created-payment-plan': 'Rate your experience creating a payment plan',
    'created-package': 'Rate your experience creating a package',
}

export const npsRatingModalSubTitleMap = {
    'savings-calculator': 'How helpful was our solar savings calculator?',
    'energy-assessment':
        'What do you think about the package recommendation list we provided?',
    'completed-kyc': 'How has your experience with us been so far?',
    'provider-completed-onboarding': 'How easy was your onboarding?',
    'created-payment-plan': 'How easy was it to create your payment plan?',
    'created-package': 'How do you feel about our package creation process?',
}

export const npsRatingModalCustomPlaceholder = feature => {
    const notesPlaceholders = {
        'created-payment-plan':
            'What feature can we add to improve the payment plan creation?(Optional)',
        'created-package':
            'What feature can we add to improve the flow for creating a package?(Optional)',
    }
    return notesPlaceholders[feature] || 'Tell Us More (Optional)'
}

export const ratingButtonEmoji = {
    1: '&#128545',
    2: '&#128542',
    3: '&#128532',
    4: '&#128533',
    5: '&#128528',
    6: '&#128578',
    7: '&#128522',
    8: '&#128516',
    9: '&#128515',
    10: '&#127775',
}
