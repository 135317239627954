import PropTypes from 'prop-types'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import Toast from 'src/components/Toast'
import VerificationFailedContent from '../../../Verification/components/verificationHelp'
import VerifyIdentity from '../../../IdentityInformation/components/VerifyIdentity'
import useVerifyIdModal from './hook'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useCustomToast } from 'src/utils/Hooks/useToast'

const VerifyIdModal = ({
    showModal,
    inputs,
    closeIdVerifyModal,
    setIdVerificationInProgress,
    handleSubmitIdCardInfo,
    submitIdCardLoading,
    actionModalToast,
    idVerificationFailedModal,
}) => {
    const {
        modalInputs,
        idHelpPayload,
        modalOnlyInputError,
        setShowToast,
        setToastMessage,
        handleChange,
        handleBlur,
        closeModal,
    } = useVerifyIdModal(inputs, closeIdVerifyModal)

    const { isMobile } = useMediaQueries()
    const { BVNAlert } = useCustomToast()

    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={closeModal}
            title={
                idVerificationFailedModal
                    ? 'Unable to verify'
                    : 'Confirm your details'
            }
            footerLeftContent={idVerificationFailedModal ? 'chat' : 'button'}
            primaryBtnText={idVerificationFailedModal ? 'Try again' : 'Confirm'}
            handlePrimaryBtnClick={() => handleSubmitIdCardInfo(modalInputs)}
            primaryBtnLoading={submitIdCardLoading}
            primaryBtnWidth={'160px'}
            primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            noPrimaryBtnTextInSecondary
            secondaryBtnText="I'll verify later"
            handleSecondaryBtnClick={() => {
                closeModal()
                BVNAlert(
                    'Please, go ahead to provide your other details',
                    isMobile ? '100%' : '511px',
                )
            }}
        >
            {actionModalToast?.showToast && (
                <Toast
                    messageType={actionModalToast?.messageType}
                    message={actionModalToast?.toastMessage}
                    position={'absolute'}
                    top={30}
                    closable={true}
                />
            )}

            {idVerificationFailedModal ? (
                <VerificationFailedContent
                    payload={idHelpPayload}
                    onCancel={closeModal}
                    setVerificationInProgress={setIdVerificationInProgress}
                    setToastMessage={setToastMessage}
                    setShowToast={setShowToast}
                    type={'ID'}
                />
            ) : (
                <VerifyIdentity
                    inputs={modalInputs}
                    handleInputChange={handleChange}
                    handleBlur={handleBlur}
                    fieldErrors={modalOnlyInputError}
                />
            )}
        </ConsumerModalBase>
    )
}

VerifyIdModal.propTypes = {
    showModal: PropTypes.bool,
    inputs: PropTypes.object,
    closeIdVerifyModal: PropTypes.func,
    idVerificationInProgress: PropTypes.bool,
    setIdVerificationInProgress: PropTypes.func,
    handleSubmitIdCardInfo: PropTypes.func,
    submitIdCardLoading: PropTypes.bool,
    actionModalToast: PropTypes.object,
    idVerificationFailedModal: PropTypes.bool,
}

export default VerifyIdModal
