import PropTypes from 'prop-types'
import '../gettingstarted.scss'
import { ReactComponent as SuccessIcon } from 'src/assets/images/successImage.svg'
import Toast from 'src/components/Toast'

const SystemSelected = ({ showToastError, errorMessage }) => {
    return (
        <>
            {showToastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                    position={'absolute'}
                    top={'70px'}
                    autoHide={false}
                />
            )}
            <div
                className={
                    'GettingStartedEmailSentWrapper ForDealApproved SystemSelected'
                }
            >
                <SuccessIcon className={'GettingStartedDealApprovedImg'} />
                <h2>Your selection has been successfully submitted</h2>
                <p>
                    Your selected system has been confirmed and your group
                    representative will be notified. You also need to provide
                    some information for your KYC
                </p>
            </div>
        </>
    )
}

SystemSelected.propTypes = {
    showToastError: PropTypes.bool,
    errorMessage: PropTypes.string,
}

export default SystemSelected
