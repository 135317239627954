export const consumerBuildCustomSystemEvents = {
    viewListing: {
        action: 'View PCR Listing',
        category: 'Consumer Power Curve Recommendation',
        label: 'View PCR Listing',
    },
    viewDetails: {
        action: 'View PCRP Details Page',
        category: 'Consumer Power Curve Recommendation',
        label: 'View PCR Listing',
    },
    clicksGetStartedOnPackage: {
        action: 'Wants PCRP',
        category: 'Consumer Power Curve Recommendation',
        label: 'Wants PCRP',
    },
    editsAppliances: {
        action: 'Edited Appliances on PCR',
        category: 'Consumer Power Curve Recommendation',
        label: 'Edited Appliances on PCR',
    },
    viewedTwoPackages: {
        action: 'Viewed 2 PCRPs',
        category: 'Consumer Power Curve Recommendation',
        label: 'Viewed 2 PCRPs',
    },
    viewedFourPackages: {
        action: 'Viewed 4 PCRPs',
        category: 'Consumer Power Curve Recommendation',
        label: 'Viewed 4 PCRPs',
    },
    viewedSixPackages: {
        action: 'Viewed 6 PCRPs',
        category: 'Consumer Power Curve Recommendation',
        label: 'Viewed 6 PCRPs',
    },
    viewedEightPackages: {
        action: 'Viewed 8 PCRPs',
        category: 'Consumer Power Curve Recommendation',
        label: 'Viewed 8 PCRPs',
    },
    viewedTenPackages: {
        action: 'Viewed 10 PCRPs',
        category: 'Consumer Power Curve Recommendation',
        label: 'Viewed 10 PCRPs',
    },
    viewedGreaterThanTenPackages: {
        action: 'Viewed > 10 PCRPs',
        category: 'Consumer Power Curve Recommendation',
        label: 'Viewed > 10 PCRPs',
    },
    wantsAltRecommendation: {
        action: 'Wants Alt. Rec.',
        category: 'Consumer Power Curve Recommendation',
        label: 'Wants Alt. Rec.',
    },
    usedSort: {
        action: 'Used Sort - PCR',
        category: 'Consumer Power Curve Recommendation',
        label: 'Used Sort - PCR',
    },
}
