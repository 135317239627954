import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ReactComponent as GoBackIcon } from 'src/assets/images/blue-circle-left.svg'
import '../accountsetupoverview.scss'
import { Progress } from 'antd'
import { decodeUserInfo } from 'src/utils/auth'
import BackNav from 'src/components/BackNav'
import { ConsumerButton } from 'src/components/Button'
import { isMobile } from 'src/utils/mediaQueries'
import { permissionsControl } from 'src/utils/permissionsControl'

const TopColumn = ({
    kycStatus,
    useType,
    goBackToSystem,
    hideBackNav = false,
    handleRequestUpdate,
    isDynamicKycUser,
    fetching,
}) => {
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const canRequestUpdate = permissionsControl(['can_request_update'])
    const history = useHistory()

    function calculatePercentage() {
        let count = 0
        let totalModules = 0

        if (isDynamicKycUser) {
            for (const key in kycStatus) {
                if (Object.prototype.hasOwnProperty.call(kycStatus, key)) {
                    const value = kycStatus[key]
                    if (
                        (value === true || value === false) &&
                        key !== 'account_setup_completion_status'
                    ) {
                        totalModules++
                        if (value === true) {
                            count++
                        }
                    }
                }
            }
            const percentage =
                totalModules === 0 ? 0 : (count / totalModules) * 100
            const fraction = `${count}/${totalModules}`

            return [percentage, fraction]
        } else {
            if (useType === 'Residential use') {
                if (kycStatus?.residential_basic_information_completion_status)
                    count++
                if (
                    kycStatus?.residential_identity_information_completion_status
                )
                    count++
                if (
                    kycStatus?.residential_financial_information_completion_status
                )
                    count++
                totalModules = 3
            } else {
                if (kycStatus?.business_information_completion_status) count++
                if (kycStatus?.business_verification_completion_status) count++
                if (kycStatus?.business_financial_information_completion_status)
                    count++
                totalModules = 3
            }
            const percentage = (count / totalModules) * 100

            switch (count) {
                case 1:
                    return [percentage, '1/3']
                case 2:
                    return [percentage, '2/3']
                case 3:
                    return [percentage, '3/3']
                default:
                    return [percentage, '0/3']
            }
        }
    }

    return (
        <>
            {isAdminUser ? (
                <section className={'ASOTopColumn_Admin'}>
                    <BackNav
                        v2
                        title={'Back to admin portal'}
                        onClick={() => history.goBack()}
                    />
                    <ConsumerButton
                        btnBgColor={'#F9FCFF'}
                        border={'0.5px solid #004AAD'}
                        btnTextColor={'#004AAD'}
                        btnWidth={isMobile ? '130px' : '158px'}
                        btnHeight={'44px'}
                        handleClick={() => handleRequestUpdate()}
                        disabled={!canRequestUpdate}
                        toolTipText={
                            canRequestUpdate
                                ? null
                                : 'You are not authorised to perform this action'
                        }
                    >
                        Request Update
                    </ConsumerButton>
                </section>
            ) : (
                <section
                    className={'ASOTopColumn'}
                    data-testid="account-setup-overview-top-column"
                >
                    {!hideBackNav && (
                        <div
                            className={'ASOGoBackBtn'}
                            onClick={() => goBackToSystem()}
                        >
                            <GoBackIcon />
                            <span>Go back</span>
                        </div>
                    )}
                    {!fetching && (
                        <div className={'ASOProgress'}>
                            <p>{calculatePercentage()?.[1]} Completed</p>
                            <Progress
                                percent={calculatePercentage()?.[0]}
                                showInfo={false}
                                strokeColor={'#004AAD'}
                                trailColor={'#E2EEFF'}
                                strokeWidth={7}
                            />
                        </div>
                    )}
                </section>
            )}
        </>
    )
}

TopColumn.propTypes = {
    kycStatus: PropTypes.object,
    useType: PropTypes.string,
    goBackToSystem: PropTypes.func,
    hideBackNav: PropTypes.bool,
    handleRequestUpdate: PropTypes.func,
    estimation_id_for_admin: PropTypes.string,
    isDynamicKycUser: PropTypes.bool,
    fetching: PropTypes.bool,
}

export default TopColumn
