/* eslint-disable react/prop-types */
import { useState } from 'react'
import { useQuery } from 'react-query'
import { companyContactApi } from '../../../../api/companyInfo'
import { InputFields, SelectField } from '../../../../components/InputFields'
import FormTitleBar from '../../../../components/FormTitleBar'
import FormSubBox from '../../FormSubBox'
import { ActionSection } from '../../index'
import { SelectCountryCode } from '../../../../components/InputFields/SelectCountryCode'
import { CompanyContactValidationSchema } from '../../../../utils/validationSchema'
import Toast from '../../../../components/Toast'
import { DepRoleData } from '../../../../utils/Data/roles'
import styles from './companycontact.module.scss'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { formatInputValue } from 'src/utils/formatting'

const { FormBox, InputBox, NameFieldWrapper } = styles

const CompanyContact = ({ nextHandler, prevHandler, email, phoneNumber }) => {
    const pageTitle = 'SunFi - Onboarding | Company Contact'
    const pageUrlName = window.location.pathname
    const pageTracker = 'Onboarding'
    const [inputs, setInputs] = useState({
        role: '',
        firstName: '',
        lastName: '',
    })
    const [errors, setErrors] = useState({})
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleInputChange = event => {
        const { name, value } = event.target

        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: formatInputValue(name, value),
        }))
    }

    const handleOnBlur = event => {
        const { name, value } = event.target
        setInputs(preState => ({
            ...preState,
            [name]: value.trim(),
        }))
    }

    const handleSelectChange = (name, value) => {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const nextStageHandler = () => {
        setToastError(false)
        setErrors({})
        CompanyContactValidationSchema('onboarding')
            .validate(inputs, { abortEarly: false })
            .then(() => {
                setErrors('')
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['onboardingCompanyContact'].action,
                    eventTrackers['onboardingCompanyContact'].label,
                    eventTrackers['onboardingCompanyContact'].category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event: inputs,
                    },
                )
                refetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const { isFetching, refetch } = useQuery(
        'companyContact',
        () =>
            companyContactApi({
                first_name: inputs.firstName,
                last_name: inputs.lastName,
                contact_role: inputs.role,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => nextHandler(),
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to save information. Try Again')
            },
        },
    )

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            nextStageHandler()
        }
    }

    const countryCode = phoneNumber?.substring(0, 4)

    return (
        <>
            <div className={FormBox}>
                <FormSubBox>
                    <FormTitleBar
                        title={`Contact Information`}
                        subtitle="Fill your company contact details below"
                    />
                    {toastError && (
                        <Toast messageType="error" message={errorMessage} />
                    )}
                    <div className={NameFieldWrapper}>
                        <InputFields
                            value={inputs.firstName}
                            handleChange={handleInputChange}
                            name="firstName"
                            title="First Name"
                            type="text"
                            errorMessage={errors?.firstName}
                            fullWidth={true}
                            inputFieldWrapperStyle={{ width: '100%' }}
                            inputWidth="224px"
                            onBlur={handleOnBlur}
                        />
                        <div style={{ width: 30 }} />
                        <InputFields
                            value={inputs.lastName}
                            handleChange={handleInputChange}
                            name="lastName"
                            title="Last Name"
                            type="text"
                            errorMessage={errors?.lastName}
                            fullWidth={true}
                            inputFieldWrapperStyle={{ width: '100%' }}
                            inputWidth="224px"
                            onBlur={handleOnBlur}
                        />
                    </div>
                    <div>
                        <div>
                            <SelectCountryCode
                                title="Phone Number"
                                name="phoneNumber"
                                defaultValue={phoneNumber.substring(0, 4)}
                                inputValue={phoneNumber.replace(
                                    countryCode,
                                    '',
                                )}
                                prefilled={true}
                                disabled={true}
                                inputStyle={{ width: '61%' }}
                            />
                        </div>
                        <div>
                            <InputFields
                                name="email"
                                title="Email Address"
                                type="email"
                                inputValue={email}
                                disabled={true}
                                prefilled={true}
                                inputWidth="100%"
                            />
                        </div>
                        <div className={InputBox}>
                            <SelectField
                                values={DepRoleData}
                                currentSelected={inputs.role}
                                handleChange={handleSelectChange}
                                name="role"
                                initialOption="Enter Role"
                                selectedValue={inputs.role}
                                withCheckBox={true}
                                selectWidth="100%"
                                errorMessage={errors?.role}
                            />
                        </div>
                    </div>

                    <br />
                </FormSubBox>
            </div>

            <ActionSection
                stage={2}
                loading={isFetching}
                nextHandler={nextStageHandler}
                prevHandler={prevHandler}
                onKeyUp={handleKeyPress}
            />
        </>
    )
}

export default CompanyContact
