/* eslint-disable react/prop-types */
import './providercompanyinfo.scss'
import { formatCompanyInfo } from './data'

const ProviderCompanyInfo = ({ data }) => {
    return (
        <div className="AdminProviderCompanyInfoTable">
            {formatCompanyInfo(data).map((item, i) => (
                <div key={i} className="AdminProviderCompanyInfoTableItem">
                    <h4>{item.label}</h4>
                    <p>{item.value}</p>
                </div>
            ))}
        </div>
    )
}

export default ProviderCompanyInfo
