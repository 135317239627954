import { createContext } from 'react'

export const SSEResidentialIdentityInfoContext = createContext({
    persistResidentialIdentityInfo: () => {},
    persistResidentialIdentityInfoLoading: false,
    errorResidentialIdentityInfo: undefined,
    persistResidentialIdentityInfoSuccess: false,
    sseBasicInfoRefetch: () => {},
    sseBasicInfoData: undefined,
    sseBasicInfoFetching: false,
    toast: { type: '', message: '' },
    setToast: () => {},
    step: 2,
    setStep: () => {},
    selected: '',
    setSelected: () => {},
    inputs: {
        identityType: '',
        identityNumber: '',
        imageUrl: '',
        firstName: '',
        lastName: '',
        identityVerified: false,
    },
    showModal: false,
    setShowModal: () => {},
    uploading: false,
    successUpload: [],
    setSuccessUpload: () => {},
    pictureUrl: '',
    setPictureUrl: () => {},
    width: 0,
    setWidth: () => {},
    handleInputChange: () => {},
    handleBlur: () => {},
    handleSelectChange: () => {},
    closeModal: () => {},
    modalFooterLeftContent: '',
    modalPrimaryBtnText: '',
    modalTitle: '',
    disableModalPrimaryBtn: false,
    handleImageSteps: () => {},
    webcamRef: '',
    handleRetake: () => {},
    uploadImageToS3: () => {},
    showIdentityModal: false,
    setShowIdentityModal: () => {},
    fieldErrors: {},
    actionModal: { open: false, type: '', errMessage: '' },
    setActionModal: () => {},
    selfieLoading: false,
    modalRIDType: '',
    setModalRIDType: () => {},
    kycResidentialIdentityCompareLoading: false,
    IdentitySelfieRef: '',
    verificationFailedModal: false,
    setVerificationFailedModal: () => {},
    setIdentityToast: () => {},
    identityToast: '',
    verificationFailed: false,
    setVerificationFailed: () => {},
})
