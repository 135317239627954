const packageData = {
    name: 'Koolboks Fridge',
    description:
        'SunFi is solving a problem we know better than anyone else. We’ve solved the problems that make it difficult for energy...',
    packageAmount: '₦ 5,012,450.50',
    paymentPlans: 154,
    appliancesAdded: 15,
    energyCapacity: 1000,
    energyNeeds: [],
}

export const packageListData = new Array(4)
    .fill(packageData)
    .map((data, id) => ({ id, ...data }))

export const getApplianceAdded = appliances => {
    return appliances.reduce((total, appliance) => {
        const quantity = parseInt(appliance.quantity) || 0
        return total + quantity
    }, 0)
}

export const getUpdatedPackages = (initialPackages, selectedPackages) => {
    let initialPackagesClone = [...initialPackages]
    const add_packages = []
    let remove_packages_ids = []

    for (const selectedPackage of selectedPackages) {
        const index = initialPackagesClone.findIndex(
            pkg => JSON.stringify(pkg) === JSON.stringify(selectedPackage),
        )

        if (index === -1) {
            add_packages.push(selectedPackage)
        } else {
            initialPackagesClone = initialPackagesClone.filter(
                pkg => pkg.id !== selectedPackage.id,
            )
        }
    }

    if (initialPackagesClone.length) {
        remove_packages_ids = initialPackagesClone.map(pkg => pkg.id)
    }

    return {
        add_packages,
        remove_packages_ids,
    }
}
