/* eslint-disable react/prop-types */
import { Drawer, Pagination } from 'antd'
import Button from 'src/components/Button'
import React, { useState, useEffect, useRef } from 'react'
import {
    useGetRecommendablePackages,
    useRecommendSolutionsForDropoffConsumer,
} from 'src/api/admin/payment-plans/ssePlan'
import BackNav from 'src/components/BackNav'
import {
    NoFLoatingLabelInputFields,
    SelectField,
} from 'src/components/InputFields'
import { InlineLoader } from 'src/components/Loader'
import { NewSolutionSkeletalCards } from 'src/components/SkeletalCards'
import { remarkOptions, transformRemarkValue } from 'src/utils/recommendations'
import arrow from 'src/assets/images/blue-arrow.svg'
import close from 'src/assets/images/close-icon.svg'
import overviewNoActivity from 'src/assets/images/overviewNoActivity.svg'
import { useHistory, useParams } from 'react-router-dom'
import { AddCustomSolutionSchema } from 'src/utils/validationSchema'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import Checkbox from 'src/components/Checkbox'
import { errorHandler } from 'src/utils/errorHandler'
import { formatAlternatePackage } from './data'
import Editor from 'src/components/Editor'
import { replaceHtmlTags } from 'src/utils/formatting'
import './alternativepackages.scss'

const AlternativePackages = ({
    showAlternativePackages,
    setShowAlternativePackages,
    initialPackages = [],
    module = '',
    handlePackagesReselectionSuccess = () => {},
    subscriptionSupported,
    leaseToOwnSupported,
    canEnableFetch,
}) => {
    const [searchText, setSearchText] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedSolutions, setSelectedSolutions] = useState([])
    const [packagesData, setPackagesData] = useState([])
    const [bestFit, setBestFit] = useState(null)
    const [inputs, setInputs] = useState([])
    const [errors, setErrors] = useState({})
    const [isOpen, setIsOpen] = useState(
        Array(packagesData?.length).fill(false),
    )
    const [listTotal, setListTotal] = useState(1)
    const [initialPackagesAdded, setInitialPackagesAdded] = useState(false)

    const { successAlert, errorAlert } = useCustomToast()
    const { id: dropOffId } = useParams()
    const history = useHistory()
    const editorRefs = useRef(inputs.map(() => React.createRef()))
    const { refetch: refetchPackages, isFetching: isLoadingPackages } =
        useGetRecommendablePackages({
            name: searchText,
            pageNumber: currentPage,
            subscriptionSupported: subscriptionSupported === true ? true : '',
            leaseToOwnSupported: leaseToOwnSupported === true ? true : '',
            onSuccess: data => {
                const packages = formatAlternatePackage(data?.data)
                setListTotal(data?.total_pages)
                setInputs([])
                setPackagesData(packages)

                //merge persisted and currently selected solutions
                const transformedInitialPackages = initialPackages?.map(
                    ({ recommendation_context, ...rest }) => ({
                        ...rest,
                        recommendation_remark: recommendation_context?.remark,
                        recommendation_note: recommendation_context?.note,
                    }),
                )
                const currentSelectedPackages = [
                    ...selectedSolutions,
                    ...(initialPackagesAdded ? [] : transformedInitialPackages),
                ]
                setSelectedSolutions(currentSelectedPackages)
                if (!initialPackagesAdded) {
                    setInitialPackagesAdded(true)
                }

                // map to represent selected solution from current list on the UI
                for (let i = 0; i < packages.length; i++) {
                    const currentSelectedPackage = currentSelectedPackages.find(
                        sol => sol.id === packages[i].id,
                    )
                    if (currentSelectedPackage?.best_fit) {
                        setBestFit(currentSelectedPackage?.id)
                    }
                    // set recommendation context
                    let recommendation_remark = ''
                    let recommendation_note = ''

                    if (currentSelectedPackage !== undefined) {
                        recommendation_remark =
                            transformRemarkValue(
                                currentSelectedPackage?.recommendation_remark,
                                false,
                            ) || ''
                        recommendation_note =
                            currentSelectedPackage?.recommendation_note || ''
                    }

                    // Update the state
                    setInputs(prevState => [
                        ...prevState,
                        {
                            id: packages[i].id,
                            recommendation_remark,
                            recommendation_note,
                        },
                    ])
                }
            },
        })

    const { mutate: mutateAddPackages, isLoading: isAddPackageLoading } =
        useRecommendSolutionsForDropoffConsumer({
            onSuccess: res => {
                successAlert(res?.message)
                setTimeout(() => {
                    if (module === 'selected-alt-recommendations') {
                        handlePackagesReselectionSuccess()
                    } else {
                        history.push(
                            `/admin/alternative-recommendations/selected/${dropOffId}`,
                        )
                    }
                }, 3000)
            },
            onError: err =>
                errorAlert(
                    errorHandler(err?.response?.data) ||
                        'Something went wrong!',
                ),
        })

    const hideDrawer = () => {
        setShowAlternativePackages(false)
    }

    const handlePage = pageNumber => {
        setSearchText('')
        setCurrentPage(pageNumber)
    }

    const handleInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
    }

    const handleBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const handleSearch = () => {
        refetchPackages()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }
    const toggleBox = index => {
        setIsOpen(prevOpen => {
            const newOpen = [...prevOpen]
            newOpen[index] = !newOpen[index]
            return newOpen
        })
        //close all other boxes
        for (let i = 0; i < isOpen.length; i++) {
            if (i !== index) {
                setIsOpen(prevOpen => {
                    const newOpen = [...prevOpen]
                    newOpen[i] = false
                    return newOpen
                })
            }
        }
    }
    const handleChange = (value, i) => {
        const newInputs = inputs.map((el, idx) =>
            idx === i ? { ...el, recommendation_remark: value } : el,
        )
        setInputs(newInputs)
    }

    const handleTextAreaChange = (newValue, index) => {
        const newInputs = inputs.map((input, idx) =>
            idx === index ? { ...input, recommendation_note: newValue } : input,
        )
        setInputs(newInputs)
    }

    const checkSelected = data => {
        return selectedSolutions?.find(sol => sol?.id === data.id)
    }

    const handleBestFitChange = packageId => {
        setBestFit(packageId)
    }

    const handleAddPackageForDropoffConsumer = () => {
        const packagesSelected = selectedSolutions.map(el => ({
            id: el.id,
            remark: transformRemarkValue(el.recommendation_remark, true),
            recommendation_note: replaceHtmlTags(el.recommendation_note),
            best_fit: bestFit === el.id,
        }))
        mutateAddPackages({ dropOffId, body: packagesSelected })
    }

    const handleSelectedSolution = (data, i) => {
        const currentSolution = selectedSolutions.find(
            sol => sol?.id === data.id,
        )
        if (!currentSolution) {
            AddCustomSolutionSchema.validate(inputs[i], { abortEarly: false })
                .then(() => {
                    setErrors(prev => {
                        let newErrors = { ...prev }
                        delete newErrors[i]
                        return newErrors
                    })
                    //set selected solution to payload without changing inputs
                    const filterInputs = inputs.find(
                        input => input.id === data.id,
                    )

                    setSelectedSolutions(prev => [...prev, filterInputs])
                })
                .catch(err => {
                    let errList = {}
                    err.inner.forEach(e => {
                        errList = {
                            ...errList,
                            [e.path]: e.message,
                        }
                    })
                    setErrors(prevState => ({
                        ...prevState,
                        [i]: errList,
                    }))
                })
        } else {
            const otherSelectedSolutions = selectedSolutions.filter(
                sol => sol?.id !== data.id,
            )
            setSelectedSolutions(otherSelectedSolutions)
        }
    }

    useEffect(() => {
        if (
            searchText === '' &&
            canEnableFetch &&
            (subscriptionSupported || leaseToOwnSupported)
        ) {
            refetchPackages()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchText,
        canEnableFetch,
        subscriptionSupported,
        leaseToOwnSupported,
        currentPage,
    ])

    return (
        <Drawer
            visible={showAlternativePackages}
            closable={false}
            width="450px"
            onClose={hideDrawer}
            className="RecommendedSolutionDrawer"
        >
            <div className={'SelectEnergyCloseAndBackNav'}>
                <BackNav
                    title="Back to Recommendations"
                    onClick={hideDrawer}
                    marginLeft="6px"
                    v2
                />
                <div>
                    <img src={close} alt="close" onClick={hideDrawer} />
                </div>
            </div>

            <div>
                <div className="SelectEnergyTitleAndButton">
                    <h1 className="SelectEnergyTitle">
                        Select Energy Solution
                    </h1>
                    <Button
                        btnWidth={'160px'}
                        btnHeight={'56px'}
                        btnBgColor={'#004AAD'}
                        btnTextColor={'#FFFFFF'}
                        fontSize={'14px'}
                        handleClick={handleAddPackageForDropoffConsumer}
                        disabled={selectedSolutions?.length === 0}
                    >
                        {isAddPackageLoading ? (
                            <InlineLoader />
                        ) : (
                            `Add Packages (${selectedSolutions?.length})`
                        )}
                    </Button>
                </div>
                <div className="CRESSearchProductWrapper">
                    <NoFLoatingLabelInputFields
                        TextPrefilled={searchText ? true : false}
                        borderRadius="7px"
                        inputWidth="390px"
                        inputHeight="45px"
                        value={searchText}
                        type="text"
                        iconType="search"
                        prefilled
                        inputPlaceholder="Search Products"
                        style={{ paddingBottom: 7 }}
                        handleChange={handleInputChange}
                        onIconClick={handleSearch}
                        onKeyUp={handleKeyPress}
                        onBlur={handleBlur}
                    />
                </div>

                {isLoadingPackages ? (
                    <NewSolutionSkeletalCards total={10} />
                ) : searchText !== '' && packagesData.length === 0 ? (
                    <div className="emptyContainer">
                        <img src={overviewNoActivity} alt="no result" />
                        <p className="emptyTitle">No Result Found</p>
                        <p className="emptySubtitle">
                            We can’t find any result for &quot;
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#011A3C',
                                }}
                            >
                                {searchText}
                            </span>
                            &quot;
                        </p>
                    </div>
                ) : (
                    packagesData?.map((data, i) => {
                        return (
                            <div
                                key={data.id}
                                className="CustomRecommendationEnergySolutionWrapper"
                            >
                                <div className="CustomRecommendationEnergySolutionTop">
                                    <h4>{data?.name}</h4>
                                    <div className="CRESAmountAndFlag">
                                        <p>{data?.amount}</p>
                                        {checkSelected(data) ? (
                                            <div className="CRESTopSelectedFlag">
                                                <p>SELECTED</p>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div
                                        onClick={() => toggleBox(i)}
                                        className={'CRESTopArrowWrapper'}
                                    >
                                        <img
                                            src={arrow}
                                            alt="arrow"
                                            className={
                                                isOpen[i]
                                                    ? 'CRESTopArrowOpen'
                                                    : 'CRESTopArrowClosed'
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        isOpen[i]
                                            ? 'CustomRecommendationEnergySolutionBottomOpen'
                                            : 'CustomRecommendationEnergySolutionBottomClosed'
                                    }
                                >
                                    <div className="CRESBottomTextWrapper ALTRecommendations">
                                        <div>
                                            <h5>COMPONENTS</h5>
                                            <p>{data?.components}</p>
                                        </div>
                                        <div>
                                            <h5>CAPACITY</h5>
                                            <p>{data?.energyCapacity}</p>
                                        </div>
                                        <div>
                                            <Checkbox
                                                page={'SSE'}
                                                isChecked={bestFit === data?.id}
                                                handleChecked={() =>
                                                    handleBestFitChange(
                                                        data?.id,
                                                    )
                                                }
                                            >
                                                Best Fit
                                            </Checkbox>
                                        </div>
                                    </div>

                                    <div>
                                        <SelectField
                                            marginBottom={'25px'}
                                            selectWidth={'100%'}
                                            name={'recommendation_remark'}
                                            type={'approvedModal'}
                                            floatingLabel={
                                                inputs[i]
                                                    ?.recommendation_remark !==
                                                ''
                                                    ? 'Remark'
                                                    : ''
                                            }
                                            initialOption={
                                                inputs[i]
                                                    ?.recommendation_remark ===
                                                ''
                                                    ? 'Select Remark'
                                                    : inputs[i]
                                                          ?.recommendation_remark
                                            }
                                            value={
                                                inputs[i]?.recommendation_remark
                                            }
                                            values={remarkOptions.map(
                                                option => ({
                                                    value: option,
                                                }),
                                            )}
                                            withCheckBox={true}
                                            optionStyle={{
                                                width: '100%',
                                                marginTop: '4px',
                                                marginBottom: '4px',
                                            }}
                                            handleChange={(name, value) =>
                                                handleChange(value, i)
                                            }
                                            currentSelected={
                                                inputs[i]?.recommendation_remark
                                            }
                                            errorMessage={
                                                errors?.[i]
                                                    ?.recommendation_remark
                                            }
                                            disabled={checkSelected(data)}
                                        />
                                        <Editor
                                            editorHeight={'140px'}
                                            editorWidth={'100%'}
                                            placeholder={
                                                inputs[i]
                                                    ?.recommendation_note ===
                                                    '' ||
                                                inputs[i]
                                                    ?.recommendation_note ===
                                                    null
                                                    ? 'Add a Description'
                                                    : ''
                                            }
                                            name={'recommendation_note'}
                                            value={
                                                inputs[i]?.recommendation_note
                                            }
                                            onChangeHandler={value =>
                                                handleTextAreaChange(value, i)
                                            }
                                            errorMessage={
                                                errors?.[i]?.recommendation_note
                                            }
                                            disabled={checkSelected(data)}
                                            editorRef={editorRefs.current[i]}
                                            bulletOnly
                                        />
                                        <div
                                            className={
                                                'CRESBottomButtonsWrapper'
                                            }
                                        >
                                            <Button
                                                btnWidth={'70px'}
                                                btnHeight={'35.58px'}
                                                btnBgColor={'#E2EEFF'}
                                                btnTextColor={'#004AAD'}
                                                handleClick={() => toggleBox(i)}
                                            >
                                                Close
                                            </Button>
                                            <Button
                                                btnWidth={'70px'}
                                                btnHeight={'35.58px'}
                                                btnBgColor={'#004AAD'}
                                                btnTextColor={'#FFFFFF'}
                                                handleClick={() =>
                                                    handleSelectedSolution(
                                                        data,
                                                        i,
                                                    )
                                                }
                                            >
                                                {checkSelected(data)
                                                    ? 'Unselect'
                                                    : 'Select'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )}

                {packagesData?.length > 0 && (
                    <div className="EnergyProductPaginationWrapper">
                        <Pagination
                            defaultCurrent={currentPage}
                            defaultPageSize={1}
                            total={parseInt(listTotal, 10)}
                            showSizeChanger={false}
                            showTitle={false}
                            showLessItems
                            onChange={value => {
                                handlePage(value)
                            }}
                        />
                    </div>
                )}
            </div>
        </Drawer>
    )
}

export default AlternativePackages
