import axios from '../../../config/axios'

export const uploadOurightSalePaymentEvidenceApi = async (
    estimationId,
    file,
) => {
    let formData = new FormData()
    formData.append('date_repayment_was_made', file.date_repayment_was_made)
    formData.append('total_amount_paid', file.total_amount_paid)
    formData.append('repayment_method', file.repayment_method)

    file.repayment_evidence_url !== null &&
        formData.append('repayment_evidence_url', file.repayment_evidence_url)

    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(
        `/admin/approved/estimations/${estimationId}/mark-outright-sale-payment`,
        formData,
        { headers },
    )
    return request
}
