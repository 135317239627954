import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import UpdateNeeds from 'src/components/UpdateNeeds'

const EnergyNeedsModal = ({
    showModal,
    energyNeeds,
    closeModal,
    closeModalAfterUpdate,
    updateEnergyNeeds,
    modalTitle,
    modalDescription,
    modalSummaryTitle,
    modalBtnText,
    page,
    modalBtnLoading,
    modalToastError,
    modalErrorMessage,
}) => {
    return (
        <SecondaryModal
            v2
            showModal={showModal}
            onCancel={closeModal}
            modalWidth="649px"
            modalHeight="687px"
            closable={false}
            content={
                <UpdateNeeds
                    page={page}
                    energyNeeds={energyNeeds}
                    selectedAppliances={energyNeeds}
                    updateEnergyNeeds={updateEnergyNeeds}
                    closeModal={() => closeModal()}
                    closeModalAfterUpdate={closeModalAfterUpdate}
                    underscoredKeys
                    title={modalTitle}
                    description={modalDescription}
                    summaryTitle={modalSummaryTitle}
                    btnText={modalBtnText}
                    isLoading={modalBtnLoading}
                    toastError={modalToastError}
                    errorMessage={modalErrorMessage}
                />
            }
        />
    )
}

EnergyNeedsModal.propTypes = {
    energyNeeds: PropTypes.array,
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    closeModalAfterUpdate: PropTypes.bool,
    updateEnergyNeeds: PropTypes.func,
    modalTitle: PropTypes.string,
    modalDescription: PropTypes.string,
    modalSummaryTitle: PropTypes.string,
    modalBtnText: PropTypes.string,
    page: PropTypes.string,
    modalBtnLoading: PropTypes.bool,
    modalToastError: PropTypes.bool,
    modalErrorMessage: PropTypes.string,
}

export default EnergyNeedsModal
