import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconInputField } from '../InputFields/CostField'
import { SelectField } from '../InputFields'
import {
    generatorSizeData,
    generatorMaintenanceData,
    PAYMENT_TYPES,
} from './data'
import NairaIcon from 'src/assets/images/naira-sign.svg'
import { isMobile } from 'src/utils/mediaQueries'
import { ConsumerButton } from '../Button'

const GetOfferForm = ({
    inputs,
    errors,
    generatorUse,
    knowsGenMonthlyCost,
    canMountSolar,
    paymentModel,
    setPaymentModel,
    setCanMountSolar,
    deleteErrorOnSelection,
    handleInputChange,
    handleSelectChange,
    handleBlur,
    handleSubmit,
}) => {
    return (
        <form className="ConsumerNextFormWrapper">
            <p className="FormPStyle">
                Do you want to own or rent a solar inverter?
            </p>
            <div className="AnswerStyleWrapper">
                <button
                    type="button"
                    onClick={() => {
                        setPaymentModel(PAYMENT_TYPES.OWN)
                        deleteErrorOnSelection('paymentModelErr')
                    }}
                    className={
                        paymentModel === PAYMENT_TYPES.OWN &&
                        'AnswerStyleWrapper__active'
                    }
                >
                    Own
                </button>
                <button
                    type="button"
                    onClick={() => {
                        setPaymentModel(PAYMENT_TYPES.RENT)
                        deleteErrorOnSelection('paymentModelErr')
                    }}
                    className={
                        paymentModel === PAYMENT_TYPES.RENT &&
                        'AnswerStyleWrapper__active'
                    }
                >
                    Rent
                </button>
            </div>
            <p className="AnswerStyleWrapperError">
                {errors['paymentModelErr']}
            </p>

            <IconInputField
                icon={NairaIcon}
                name="monthlyPayment"
                value={inputs.monthlyPayment}
                handleChange={handleInputChange}
                title={`How much do you want to pay monthly?`}
                onBlur={handleBlur}
                errorMessage={errors['monthlyPayment']}
            />
            {generatorUse === 'yes' ? (
                knowsGenMonthlyCost === 'yes' ? (
                    <IconInputField
                        icon={NairaIcon}
                        name="generatorSpend"
                        title="What is your monthly spend on generators?"
                        value={inputs.generatorSpend}
                        handleChange={handleInputChange}
                        onBlur={handleBlur}
                        errorMessage={errors['generatorSpend']}
                    />
                ) : (
                    <>
                        <SelectField
                            values={generatorSizeData.map(option => ({
                                value: option,
                            }))}
                            selectWidth="338px"
                            name="generatorSize"
                            initialOption="Whats the size of your generator?"
                            handleChange={handleSelectChange}
                            marginBottom="10px"
                            dropdownPositionRelative
                            errorMessage={errors['generatorSize']}
                            floatingLabel={
                                inputs.generatorSize === ''
                                    ? ''
                                    : 'Whats the size of your generator?'
                            }
                            currentSelected={inputs.generatorSize}
                            withCheckBox
                        />
                        <SelectField
                            values={generatorMaintenanceData.map(option => ({
                                value: option,
                            }))}
                            selectWidth="338px"
                            name="generatorMaintenance"
                            initialOption="How often do you maintain your generator?"
                            handleChange={handleSelectChange}
                            marginBottom="15px"
                            dropdownPositionRelative
                            errorMessage={errors['generatorMaintenance']}
                            floatingLabel={
                                inputs.generatorMaintenance === ''
                                    ? ''
                                    : 'How often do you maintain your generator?'
                            }
                            currentSelected={inputs.generatorMaintenance}
                            withCheckBox
                        />
                    </>
                )
            ) : (
                ''
            )}
            <SelectField
                values={Array.from({ length: 25 }, (_, i) => i).map(option => ({
                    value: `${option} ${option <= 1 ? 'hour' : 'hours'}`,
                }))}
                selectWidth="338px"
                name="electricityPerDay"
                initialOption={
                    inputs.electricityPerDay ||
                    'How much of electricity do you get in a day?'
                }
                handleChange={handleSelectChange}
                marginBottom="15px"
                dropdownPositionRelative
                errorMessage={errors['electricityPerDay']}
                floatingLabel={
                    inputs.electricityPerDay === ''
                        ? ''
                        : 'How much of electricity do you get in a day?'
                }
                currentSelected={inputs.electricityPerDay}
                withCheckBox
                showSearch
            />
            <p className="FormPStyle">
                Can you authorize solar installation on your roof?
            </p>
            <div className="AnswerStyleWrapper">
                <button
                    type="button"
                    onClick={() => {
                        setCanMountSolar(true)
                        deleteErrorOnSelection('canMountSolarErr')
                    }}
                    className={canMountSolar && 'AnswerStyleWrapper__active'}
                >
                    Yes, I can
                </button>
                <button
                    type="button"
                    onClick={() => {
                        setCanMountSolar(false)
                        deleteErrorOnSelection('canMountSolarErr')
                    }}
                    className={
                        canMountSolar === false && 'AnswerStyleWrapper__active'
                    }
                >
                    No, I can’t
                </button>
            </div>
            <p className="AnswerStyleWrapperError">
                {errors['canMountSolarErr']}
            </p>

            <div>
                <ConsumerButton
                    btnWidth={isMobile ? '100%' : '334px'}
                    btnHeight="53px"
                    btnBgColor="#004AAD"
                    btnTextColor="#FFFFFF"
                    handleClick={handleSubmit}
                    role="submit"
                >
                    See available Solar Inverters for you
                </ConsumerButton>
            </div>
            {!isMobile && (
                <p className="ConsumerFormTOS">
                    By seeing available solar inverters, you agree to SunFi{' '}
                    <Link to="/terms-of-service" target="_blank">
                        Terms of use
                    </Link>{' '}
                    &{' '}
                    <Link to="/privacy-policy" target="_blank">
                        Privacy policy
                    </Link>
                </p>
            )}
        </form>
    )
}

GetOfferForm.propTypes = {
    inputs: PropTypes.string,
    errors: PropTypes.string,
    generatorUse: PropTypes.string,
    knowsGenMonthlyCost: PropTypes.string,
    canMountSolar: PropTypes.string,
    paymentModel: PropTypes.string,
    setPaymentModel: PropTypes.func,
    setCanMountSolar: PropTypes.func,
    deleteErrorOnSelection: PropTypes.func,
    handleInputChange: PropTypes.func,
    handleSelectChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
}

export default GetOfferForm
