import axios from '../../../config/axios'
export const getPaymentPlans = async (
    surveyType,
    currentPage,
    searchText = '',
    statuses = '',
    startDate = '',
    endDate = '',
    paymentPlan = '',
    providerType = '',
    campaignType = '',
    statementType = '',
    showUpdatedSSEFlow = false,
    onboardingType = '',
) => {
    let request = await axios.get(
        `/admin/estimations?survey_type=${surveyType}&page=${currentPage}&name=${searchText}&start_date=${startDate}&end_date=${endDate}&payment_plan=${paymentPlan}&provider_type=${providerType}&campaign=${campaignType}&bank_statement_source=${statementType}${
            showUpdatedSSEFlow && `&onboarding_type=${onboardingType}`
        }&csse_offers=true&application_status=${statuses}`,
    )

    return request
}

export const getAlternateRecommendations = async (
    currentPage,
    searchText = '',
    statuses = '',
    startDate = '',
    endDate = '',
    payment_model = '',
) => {
    let request = await axios.get(
        `/admin/sse-consumers/drop-offs?page=${currentPage}&start_date=${startDate}&end_date=${endDate}&name=${searchText}&status=${statuses}&paginate=true&payment_model=${payment_model}`,
    )

    return request
}
