export const meansOfIdentityV2 = [
    'Voters Card',
    'National Identification Number',
]

process.env
    .REACT_APP_ENABLE_INTERNATIONAL_PASSPORT_FOR_IDENTITY_VERIFICATION ===
    'true' && meansOfIdentityV2.push('International Passport')

process.env.REACT_APP_ENABLE_DRIVERS_LICENCE_FOR_IDENTITY_VERIFICATION ===
    'true' && meansOfIdentityV2.push('Drivers License')
