import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { updateProfileApi } from 'src/api/admin/profiles/details'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const useUpdateProfile = (action, onSuccess) => {
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const queryClient = useQueryClient()
    const { errorAlert } = useCustomToast()

    const fromModal = [
        'unpublish-profile',
        'publish-profile',
        'unpublish-profile-before-deleting-confirmation',
        'delete-profile',
        'update-profile-appliances',
    ]

    const { mutate, isLoading } = useMutation(
        ({ id, payload }) => updateProfileApi(id, payload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('fetch-admin-consumer-profile')
                queryClient.invalidateQueries('fetch-admin-profile-package')
                onSuccess?.()
            },
            onError: error => {
                const errorMessage = errorHandler(error?.response?.data)

                // show error alert inside modal or on the page
                if (fromModal?.includes(action)) {
                    setToastError(true)
                    setErrorMessage(errorMessage)
                } else {
                    errorAlert(errorHandler(errorMessage))
                }
            },
        },
    )

    return {
        updateProfileMutate: mutate,
        updateProfileLoading: isLoading,
        toastError,
        errorMessage,
    }
}

export default useUpdateProfile
