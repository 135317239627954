//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { AppButton } from 'src/components/Button'
import { format } from 'date-fns'
import EmptyState from 'src/components/EmptyState'
import { InlineLoader } from 'src/components/Loader'
import { TableSkeletalCards } from 'src/components/SkeletalCards'
import { isMobile } from 'src/utils/mediaQueries'
import Card from 'src/components/Card'
import { permissionsControl } from 'src/utils/permissionsControl'
import './repaymentscheduletable.scss'

const ProviderRepaymentTable = ({
    openModal,
    setDetails,
    data,
    handleShowPaystackModal,
    paystackLoading,
    dataFetching,
    verifyLoading,
}) => {
    const [selected, setSelected] = useState('')
    const [paidMonths, setPaidMonths] = useState(0)

    useEffect(() => {
        for (let i = 0; i < data?.repayment_schedule?.length; i++) {
            if (data?.repayment_schedule?.[i]?.status === 'PAID') {
                setPaidMonths(prev => prev + 1)
            }
        }
    }, [])

    const providerCanPay = permissionsControl(['can_make_payment'])

    let tableRows = []
    data?.repayment_schedule.forEach((row, index) => {
        // date countdown & color coding
        let statusBoxColor = ''
        let statusTextColor = ''
        let dateCountdown = ''
        let canPay = false
        let today = new Date()
        let date = row.due_date
        let mdy = date.split('/')
        let dueDate = new Date(mdy[2], mdy[0] - 1, mdy[1])
        let timeDiff = Math.ceil((dueDate - today) / (1000 * 3600 * 24))
        dateCountdown = timeDiff
        dateCountdown = '\n'

        if (row.status === 'PAID') {
            dateCountdown = 'PAID'
            statusBoxColor = '#daf9ea'
            statusTextColor = '#32cc79'
            canPay = true
        } else if (timeDiff === -1) {
            dateCountdown = 'DUE YESTERDAY'
            statusBoxColor = '#FFF1F1'
            statusTextColor = '#DA1E28'
            canPay = true
        } else if (timeDiff < 0) {
            dateCountdown = +-1 * timeDiff + ' DAYS OVER'
            statusBoxColor = '#FFF1F1'
            statusTextColor = '#DA1E28'
            canPay = true
        } else if (timeDiff === 0) {
            dateCountdown = 'DUE TODAY'
            statusBoxColor = '#fffaea'
            statusTextColor = '#ffc222'
            canPay = true
        } else if (timeDiff === 1) {
            dateCountdown = 'DUE IN 1 DAY'
            statusBoxColor = '#fffaea'
            statusTextColor = '#ffc222'
        } else if (timeDiff > 1 && timeDiff < 30) {
            dateCountdown = 'DUE IN ' + timeDiff + ' DAYS'
            statusBoxColor = '#fffaea'
            statusTextColor = '#ffc222'
        } else {
            // Greater than 30 days
            dateCountdown = ''
            statusBoxColor = '#ffffff'
            statusTextColor = '#011A3C'
        }

        tableRows.push(
            isMobile ? (
                <Card
                    key={index}
                    topLeftContent={`Month ${row?.month}`}
                    includeFlag
                    flagText={row?.status === 'UNPAID' ? dateCountdown : 'PAID'}
                    flagStyleProps={{
                        backgroundColor: statusBoxColor,
                        color: statusTextColor,
                    }}
                    topLeftContentSize={17}
                    bottomLeftHeading={'Due Date'}
                    bottomLeftContent={
                        row?.due_date !== undefined &&
                        format(new Date(row?.due_date), 'd MMM yyyy')
                    }
                    bottomRightHeading={'Amount Due'}
                    bottomRightContent={
                        row?.currency?.symbol + '' + row?.amount_due
                    }
                    btnText={
                        (paystackLoading || verifyLoading) &&
                        row?.id === selected ? (
                            <InlineLoader />
                        ) : row?.status === 'UNPAID' &&
                          process.env
                              .REACT_APP_FLAG_PROVIDERS_CAN_ACCESS_PAY_NOW_FUNCTIONALITY ===
                              'true' ? (
                            'Pay Now'
                        ) : (
                            'View'
                        )
                    }
                    handleClick={
                        row?.status === 'PAID'
                            ? () => {
                                  setDetails(row)
                                  openModal()
                              }
                            : process.env
                                  .REACT_APP_FLAG_PROVIDERS_CAN_ACCESS_PAY_NOW_FUNCTIONALITY ===
                              'true'
                            ? () => {
                                  setSelected(row?.id)
                                  handleShowPaystackModal(row?.amount_due)
                              }
                            : () => {}
                    }
                    btnDisabled={
                        canPay === false ||
                        index > paidMonths ||
                        !providerCanPay
                    }
                    cardStyleProps={{ margin: '3px 0 12px 3px' }}
                    showTooltip={!providerCanPay && true}
                    toolTipText={
                        !providerCanPay &&
                        'You are not authorised to perform this action'
                    }
                    toolTipFontSize={'12px'}
                    toolTipPadding={'10px 6px 14px 11px'}
                    toolTipPopupWidth={'130px'}
                    toolTipMarginLeft={'-40px'}
                    arrowPositionCenter={true}
                />
            ) : (
                <div className="ARSTGridItem" key={index}>
                    {/* month and payment status */}
                    <div className="ARSTGridItemStatus">
                        {/* month of payment */}
                        <p className="ARSTGridItemStatusText">
                            Month {row?.month}
                        </p>
                        {/* payment status: paid / unpaid */}
                        <div
                            className="ARSTStatusTag"
                            style={{ backgroundColor: statusBoxColor }}
                        >
                            <p style={{ color: statusTextColor }}>
                                {row?.status === 'UNPAID'
                                    ? dateCountdown
                                    : 'PAID'}
                            </p>
                        </div>
                    </div>
                    {/* payment due date */}

                    <p className="ARSTGridItemStatusDate">
                        {row?.due_date !== undefined &&
                            format(new Date(row?.due_date), 'd MMM yyyy')}
                    </p>
                    {/* amount due */}
                    <p className="ARSTAmount">
                        {row?.currency?.symbol} {row?.amount_due}
                    </p>

                    <AppButton
                        btnBgColor="#E2EEFF"
                        btnTextColor="#004AAD"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        type="outline"
                        btnWidth="70px"
                        btnHeight="37px"
                        fontSize="12px"
                        fontFamily="SF-Pro-Display-Medium"
                        handleClick={
                            row?.status === 'PAID'
                                ? () => {
                                      setDetails(row)
                                      openModal()
                                  }
                                : process.env
                                      .REACT_APP_FLAG_PROVIDERS_CAN_ACCESS_PAY_NOW_FUNCTIONALITY ===
                                  'true'
                                ? () => {
                                      setSelected(row?.id)
                                      handleShowPaystackModal(row?.amount_due)
                                  }
                                : () => {}
                        }
                        disabled={
                            canPay === false ||
                            index > paidMonths ||
                            !providerCanPay
                        }
                        toolTipText={
                            !providerCanPay &&
                            'You are not authorised to perform this action'
                        }
                        showTooltip={!providerCanPay && true}
                        toolTipFontSize={'12px'}
                        toolTipPadding={'10px 6px 14px 11px'}
                        toolTipPopupWidth={'140px'}
                        toolTipMarginLeft={'-40px'}
                        arrowPositionCenter={true}
                    >
                        {(paystackLoading || verifyLoading) &&
                        row?.id === selected ? (
                            <InlineLoader />
                        ) : row?.status === 'UNPAID' &&
                          process.env
                              .REACT_APP_FLAG_PROVIDERS_CAN_ACCESS_PAY_NOW_FUNCTIONALITY ===
                              'true' ? (
                            'Pay Now'
                        ) : (
                            'View'
                        )}
                    </AppButton>
                </div>
            ),
        )
    })

    return (
        <>
            {dataFetching ? (
                <TableSkeletalCards total={3} />
            ) : data?.repayment_schedule?.length === 0 ? (
                <EmptyState subTitle="A repayment schedule has not yet started for this plan." />
            ) : (
                <div className="ARSTableWrapper">
                    <div
                        className="ARSTHead PCATHead"
                        style={{ paddingTop: 0 }}
                    >
                        <p>Month</p>
                        <p>Due Date</p>
                        <p>Amount</p>
                        <p style={{ visibility: 'hidden' }}>Action</p>
                    </div>

                    <div className="ARSTGrid">{tableRows}</div>
                </div>
            )}
        </>
    )
}

ProviderRepaymentTable.propTypes = {
    openModal: PropTypes.func,
    setDetails: PropTypes.func,
    data: PropTypes.any,
    handleShowPaystackModal: PropTypes.func,
    paystackLoading: PropTypes.bool,
    dataFetching: PropTypes.bool,
    verifyLoading: PropTypes.bool,
}

export default ProviderRepaymentTable
