import axios from 'src/config/axios'

export const getClusterOrigination = async id => {
    const request = axios.get(`/cluster-origination/${id}/representatives`)
    return request
}

export const postClusterOrigination = async (id, payload) => {
    const request = await axios.post(
        `/cluster-origination/${id}/representatives`,
        payload,
    )
    return request
}
