import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { registerDropOffApi } from 'src/api/shoppingExperience/landingPage'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { appTracking } from 'src/utils/appTracker'
import { errorHandler } from 'src/utils/errorHandler'
import { landingPageEvents } from 'src/utils/mp-event-tracker/landing-page'

// app tracker variables
const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'View consumer page'

const useRegisterDropOff = (payload, customerType, useTypeFormAction) => {
    const history = useHistory()
    const { errorAlert } = useCustomToast()

    const routeMap = {
        RESIDENTIAL: '/consumer/individual-profiles',
        BUSINESS: '/consumer/business-profiles',
    }

    const { mutate, isLoading } = useMutation({
        mutationFn: () => registerDropOffApi(payload),
        onSuccess: () => {
            if (useTypeFormAction === 'get-started') {
                history.push(routeMap[customerType])
            } else {
                history.push('/consumer/all-systems')
            }
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                landingPageEvents['formCompletion'].action,
                landingPageEvents['formCompletion'].label,
                landingPageEvents['formCompletion'].category,
                ['MP', 'GA'],
                'event',
            )
        },
        onError: error => {
            errorAlert(errorHandler(error?.response?.data))
        },
    })

    return {
        registerDropOffMutate: mutate,
        getStartedBtnLoading: useTypeFormAction === 'get-started' && isLoading,
        viewAllSolutionsBtnLoading:
            useTypeFormAction === 'view-all-solutions' && isLoading,
    }
}

export default useRegisterDropOff
