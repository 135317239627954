/* eslint-disable react/prop-types */
import { useState } from 'react'
import { useQuery } from 'react-query'
import { companyInfoApi } from 'src/api/companyInfo'
import { InputFields, SelectField, TextArea } from 'src/components/InputFields'
import LabelTitle from 'src/components/LabelTitle'
import FormTitleBar from 'src/components/FormTitleBar'
import FormSubBox from '../../FormSubBox'
import { ActionSection } from '../../index'
import { CompanyInfoValidationSchema } from 'src/utils/validationSchema'
import Toast from 'src/components/Toast'
import businessTypeData from 'src/utils/Data/businessType'
import sizeData from 'src/utils/Data/companySize'
import { stateData } from 'src/utils/stateData'
import styles from './companyinfo.module.scss'
import { removeEmptyFieldFromObject } from 'src/utils/formatting'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'

const { AddressBlock, SelectBox, FormBox, InputBox } = styles

const CompanyInfo = ({ nextHandler, prevHandler, companyName }) => {
    const pageTitle = 'SunFi - Onboarding | Company Information'
    const pageUrlName = window.location.pathname
    const pageTracker = 'Onboarding'
    const [inputs, setInputs] = useState({
        address: '',
        city: '',
        state: '',
        website: '',
        businessType: '',
        size: '',
        businessName: companyName,
        rcNumber: '',
        businessInfo: '',
    })

    const [errors, setErrors] = useState({})
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleInputChange = event => {
        const { name, value } = event.target
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [event.target.name]: event.target.value,
        }))
    }

    const handleSelectChange = (name, value) => {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const nextStageHandler = () => {
        setErrors({})

        CompanyInfoValidationSchema('onboarding')
            .validate(inputs, { abortEarly: false })
            .then(() => {
                setErrors('')
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    eventTrackers['onboardingCompanyInformation'].action,
                    eventTrackers['onboardingCompanyInformation'].label,
                    eventTrackers['onboardingCompanyInformation'].category,
                    ['MP', 'GA'],
                    'event',
                    {
                        event: inputs,
                    },
                )
                refetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const { isFetching, refetch } = useQuery(
        'companyInfo',
        () =>
            companyInfoApi(
                removeEmptyFieldFromObject({
                    company_name: inputs.businessName,
                    staff_strength: inputs.size,
                    company_street_address: inputs.address,
                    state: inputs.state,
                    city: inputs.city,
                    business_type: inputs.businessType.toUpperCase(),
                    company_website: inputs.website,
                    cac_number: inputs.rcNumber,
                    business_info: inputs.businessInfo,
                    is_update: false,
                }),
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => nextHandler(),
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to update information. Try Again')
            },
        },
    )

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            nextStageHandler()
        }
    }

    return (
        <>
            <div className={FormBox}>
                <FormSubBox>
                    <FormTitleBar
                        title={`Company's Information`}
                        subtitle="Fill your company information below"
                    />
                    {toastError && (
                        <Toast messageType="error" message={errorMessage} />
                    )}
                    <div style={{ margin: '20px 0' }}>
                        <div className={InputBox}>
                            <SelectField
                                values={sizeData}
                                currentSelected={inputs.size}
                                handleChange={handleSelectChange}
                                name="size"
                                initialOption="Staff Strength or Size"
                                selectedValue={inputs.size}
                                withCheckBox={true}
                                selectWidth="100%"
                                errorMessage={errors?.size}
                            />
                        </div>
                        <div>
                            <InputFields
                                inputValue={inputs.businessName}
                                handleChange={handleInputChange}
                                name="businessName"
                                title={`Business Name`}
                                type="text"
                                errorMessage={errors?.businessName}
                            />
                        </div>
                        <div className={InputBox}>
                            <InputFields
                                inputValue={inputs.website}
                                handleChange={handleInputChange}
                                name="website"
                                title={`Company's website`}
                                type="text"
                                errorMessage={errors?.website}
                            />
                        </div>
                        <div className={InputBox}>
                            <TextArea
                                height="146px"
                                name="businessInfo"
                                placeholder="Business Info"
                                rows={10}
                                maxHeight={193}
                                value={inputs.businessInfo}
                                handleChange={handleInputChange}
                            />
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <SelectField
                                values={businessTypeData}
                                currentSelected={inputs.businessType}
                                handleChange={handleSelectChange}
                                name="businessType"
                                initialOption="Business type"
                                selectedValue={inputs.businessType}
                                withCheckBox={true}
                                selectWidth="224px"
                                errorMessage={errors?.businessType}
                            />
                            <InputFields
                                inputValue={inputs.rcNumber}
                                handleChange={handleInputChange}
                                inputWidth="224px"
                                name="rcNumber"
                                title={`RC Number`}
                                type="text"
                                errorMessage={errors?.rcNumber}
                            />
                        </div>
                    </div>

                    <br />

                    <div>
                        <LabelTitle title="Full Address" />
                        <InputFields
                            inputValue={inputs.address}
                            handleChange={handleInputChange}
                            name="address"
                            title="Street Address"
                            type="text"
                            errorMessage={errors?.address}
                        />
                        <div className={AddressBlock}>
                            <div>
                                <InputFields
                                    inputValue={inputs.city}
                                    handleChange={handleInputChange}
                                    name="city"
                                    title="City"
                                    type="text"
                                    errorMessage={errors?.city}
                                />
                            </div>
                            <span></span>
                            <div className={SelectBox}>
                                <SelectField
                                    values={stateData.map(option => ({
                                        value: option,
                                    }))}
                                    currentSelected={inputs.state}
                                    handleChange={handleSelectChange}
                                    name="state"
                                    initialOption="State"
                                    selectedValue={inputs.state}
                                    withCheckBox={true}
                                    errorMessage={errors?.state}
                                    showSearch
                                />
                            </div>
                        </div>
                    </div>
                </FormSubBox>
            </div>
            <ActionSection
                stage={1}
                loading={isFetching}
                nextHandler={nextStageHandler}
                prevHandler={prevHandler}
                onKeyUp={handleKeyPress}
            />
        </>
    )
}

export default CompanyInfo
