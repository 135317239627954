import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    page: 1,
    name: '',
    state: '',
    start_date: null,
    end_date: null,
    current_tab: '1',
    filter_mode: false,
}

const adminUsersListing = createSlice({
    name: 'adminUsersListingFilter',
    initialState,
    reducers: {
        updateAdminUsersFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetAdminUsersFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateAdminUsersFilters, resetAdminUsersFilters } =
    adminUsersListing.actions

export const updatedAdminUsersFilters = state => state.adminUsersListingFilter

const persistConfig = {
    key: 'adminUsersListingFilter',
    storage,
}

export const persistedAdminUsersListingReducer = persistReducer(
    persistConfig,
    adminUsersListing.reducer,
)

export default adminUsersListing.reducer
