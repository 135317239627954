export const qualificationData = [
  {
    id: 1,
    value: 'BSc. / BEng. / BTech.'
  },
  {
    id: 2,
    value: 'Diploma'
  },
  {
    id: 3,
    value: 'OND / HND'
  },
  {
    id: 4,
    value: 'SSCE'
  },
  {
    id: 3,
    value: 'No Formal Education'
  }
]
