/* eslint-disable react/display-name */
import Paragraph from '../Paragraph'
import PropTypes from 'react'

const answers = {
    'How does SunFi help me?':
        'SunFi helps energy companies offer their consumers payment plans to make clean energy, energy efficiency, and energy management technology accessible for consumers.',
    'What other tools does SunFi offer Energy Companies?':
        'SunFi offers energy companies a workspace which helps energy companies organize their product packages and components into product libraries which they can easily turn into payment plans for their consumers. SunFi sends energy providers in its network customer opportunities, and also supports providers with energy technology that helps an energy provider instantly determine the right energy solution or package to meet consumer needs. SunFi helps energy providers serve more customers in a more efficient and accelerated manner. SunFi also provides energy providers tips, and training opportunities to improve the quality of their service to their customers resulting in greater customer retention and larger volumes of referrals.',
    'What is a Component?':
        'A component is a part such as a solar panel, used in creating a package in your product library. For example, you might have a package that includes 4 solar panels, or 4 solar panel components.',
    'What is a Product Library?':
        'A Product Library represents a combination of packages and components populated by a specific energy provider. This product library can then be used by the energy provider to quickly access payment plans for their consumers enabling the consumer of an energy provider to pay in installments for their energy solutions. Product libraries made up of packages can have packages or components added and have no limit.',
    'What is a Package?':
        'A package is a combination of components making up a functional solution for a customer which can be given a name or shorthand description by the energy provider populating their product library.',
    'What is an Estimate?':
        'An estimate is a payment plan of a package or component created by an energy provider using their SunFi workspace to estimate an installment payment plan for a prospective customer.',
    'What is a Payment Plan?':
        'A payment plan enables the customer of an energy provider using SunFi’s workspace, to buy their clean energy solution now, but pay to own their solution in installments. Payment plan charges are applied to packages or components that are bought using payment plans.',
    'Who is responsible for collecting payments from the end consumer?':
        'SunFi sets up automatic debits or payment collections with the consumers of an energy provider. The customer repays SunFi, but the energy provider that makes the sale is responsible for ensuring the customer’s energy experience is of high quality ensuring the customer is happy with the solution and minimizing the risk that the customer stops paying for the solution due to dissatisfaction with solution performance. This arrangement may vary depending on the energy provider or the payment plan service SunFi is offering to that energy provider.',
    'What legal agreements are required to use the payment plan service?':
        'The energy provider signs a service level agreement with SunFi ensuring that it is prepared to provide excellent customer service to the end customer and that the customer will be supported with warranty, and service and repair needs. This agreement also ensures the energy provider is also responsive to requests by SunFi to ensure customer or SunFi concerns regarding systems deployed using payment plans from SunFi are addressed in a timely manner. The consumer will typically sign a repayment plan agreement with SunFi.',
}

const numberedList = [
    'We store data for the purpose for which it was collected. The retention period for Storage of data is also largely determined by Legal and Regulatory bodies.',
    'To respond to your inquiries and fulfill your requests',
    'administer your accounts with us',
    'process orders or applications submitted by you',
    'to inform you about important information regarding the Site, products or services for which you apply or may be interested in applying for, or in which you are already enrolled, changes to terms, conditions, and policies, and/or other administrative information',
    'send you information about our products and services;',
    'carry out marketing analysis and make general improvements to our Site',
    'obtain your views or comments on the services we provide',
    'to deliver marketing communications that we believe may be of interest to you, including, ads or offers tailored to you;',
    'to allow you to apply for products or services.',
    'to verify your identity and/or location, in order to allow access to your accounts, conduct online transactions, and maintain measures aimed at preventing fraud and protecting the security of the account and personal information;',
    'for risk control, for fraud detection and prevention, to comply with laws and regulations, and to comply with other legal process and law enforcement requirements;',
    'Based on the request of Governmental compliance bodies, our regulators, and judicial bodies, SunFi may disclose personal information.',
    'SunFi will also use your information to detect abnormalities or fraudulent activities on our system.We may contact you as necessary to enforce our policies, applicable law, or any agreement we may have with you.',
]

const NumberedAnswerComponent = () => (
    <>
        {numberedList.map((p, i) => {
            return (
                <Paragraph
                    key={i}
                    text={p}
                    textStyle="AnswerText"
                    textCountStyle="textCountStyle"
                    textCount={`${i + 1}.`}
                />
            )
        })}
    </>
)

export const AnswerComponent = ({ answer }) => (
    <>
        <Paragraph
            titleStyle="AnswerTitle"
            text={answers[answer]}
            size="10px"
            textStyle="AnswerText"
        />
    </>
)

export const categories = [
    {
        name: 'Common Questions',
        questions: [
            {
                question: 'How does SunFi help me?',
                answer: () => (
                    <AnswerComponent answer="How does SunFi help me?" />
                ),
            },
            {
                question: 'What other tools does SunFi offer Energy Companies?',
                answer: () => (
                    <AnswerComponent answer="What other tools does SunFi offer Energy Companies?" />
                ),
            },
        ],
    },
    {
        name: 'Products Library',
        questions: [
            {
                question: 'What is a Component?',
                answer: () => <AnswerComponent answer="What is a Component?" />,
            },
            {
                question: 'What is a Package?',
                answer: () => <AnswerComponent answer="What is a Package?" />,
            },
            {
                question: 'What is a Product Library?',
                answer: () => (
                    <AnswerComponent answer="What is a Product Library?" />
                ),
            },
        ],
    },
    {
        name: 'Estimations',
        questions: [
            {
                question: 'What is an Estimate?',
                answer: () => <AnswerComponent answer="What is an Estimate?" />,
            },
        ],
    },
    {
        name: 'Payment Plans',
        questions: [
            {
                question: 'What is a Payment Plan?',
                answer: () => (
                    <AnswerComponent answer="What is a Payment Plan?" />
                ),
            },
            {
                question:
                    'Who is responsible for collecting payments from the end consumer?',
                answer: () => (
                    <AnswerComponent answer="Who is responsible for collecting payments from the end consumer?" />
                ),
            },
            {
                question:
                    'What legal agreements are required to use the payment plan service?',
                answer: () => (
                    <AnswerComponent answer="What legal agreements are required to use the payment plan service?" />
                ),
            },
        ],
    },
    {
        name: 'Data Security',
        questions: [
            {
                question:
                    'What happens with the data of the energy provider and the consumer?',
                answer: () => (
                    <NumberedAnswerComponent answer="What happens with the data of the energy provider and the consumer?" />
                ),
            },
        ],
    },
]

AnswerComponent.propTypes = {
    answer: PropTypes.any,
}
