import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Steps } from 'antd'
import '../viewsystem.scss'
import { stepsItems, getStartedStepsItems } from '../data'

const PackageSteps = ({ page, style = {}, collapsible = false, v2Style }) => {
    const [isOpen, setIsOpen] = useState(false)

    const isPromoPage = page === 'promo'
    const toggleSteps = () => {
        if (isOpen) {
            window.scrollBy({
                top: -400,
                left: 0,
                behavior: 'smooth',
            })
        }
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        if (!collapsible) {
            setIsOpen(true)
        }
    }, [collapsible])

    return (
        <div
            className={`ViewSystemStepsWrapper ${
                collapsible ? 'collapsible' : ''
            } ${isOpen ? 'open' : ''} ${
                v2Style ? 'ViewSystemStepsWrapper_v2' : ''
            }`}
            style={{ border: isPromoPage ? 0 : undefined, ...style }}
        >
            <h4 style={{ marginBottom: isPromoPage ? '-6px' : undefined }}>
                How getting a package works on SunFi
            </h4>
            <Steps
                direction="vertical"
                className={`ViewSystemSteps ${
                    isPromoPage ? 'ViewGetsystemSteps' : ''
                }`}
                items={isPromoPage ? getStartedStepsItems : stepsItems}
                style={{
                    maxHeight: isOpen ? 'none' : '100px',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease',
                }}
            />
            <div style={{ marginTop: collapsible ? 16 : 0 }}>
                {collapsible && (
                    <span
                        className="ViewGetSystemSteps_CollapseBtn"
                        role="button"
                        onClick={toggleSteps}
                    >
                        {isOpen ? 'See less' : 'Read more'}
                    </span>
                )}
            </div>
        </div>
    )
}

PackageSteps.propTypes = {
    page: PropTypes.string,
    style: PropTypes.object,
    collapsible: PropTypes.bool,
    v2Style: PropTypes.bool,
}

export default PackageSteps
