import { useState, useEffect } from 'react'
import { formatPaymentTypesOptionsForApi } from '../../AddPackageDrawer/data'

export const usePackageCard = ({ packageData, setSelectedPackages }) => {
    const [appliances, setAppliances] = useState([])
    const [cluster, setCluster] = useState({
        subscription_cluster_code: '',
        lease_to_own_cluster_code: '',
    })
    const [openEditDrawer, setOpenEditDrawer] = useState(false)
    const [paymentType, setPaymentType] = useState([])
    const [paymentTypeError, setPaymentTypeError] = useState('')

    const toggleEditDrawer = () => {
        setOpenEditDrawer(prev => !prev)
    }

    const handlePackageUpdate = isSave => {
        let payment_type = [...paymentType]
        if (!payment_type.length) {
            setPaymentTypeError('Payment type is required')
        }

        setSelectedPackages(prev => {
            const selectedPackages = [...prev]
            const index = selectedPackages.findIndex(
                obj => obj.id === packageData?.id,
            )

            if (index !== -1) {
                if (isSave) {
                    selectedPackages.splice(index, 1, {
                        ...packageData,
                        appliances,
                        ...cluster,
                        payment_plan_types:
                            formatPaymentTypesOptionsForApi(payment_type),
                    })
                } else {
                    selectedPackages.splice(index, 1)
                }
            }

            return selectedPackages
        })
        toggleEditDrawer()
    }

    useEffect(() => {
        if (packageData) {
            setAppliances(packageData.appliances)
            setCluster({
                lease_to_own_cluster_code:
                    packageData.lease_to_own_cluster_code ?? '',
                subscription_cluster_code:
                    packageData.subscription_cluster_code ?? '',
            })
            setPaymentType(packageData.payment_plan_types)
        }
    }, [packageData])

    return {
        appliances,
        setAppliances,
        openEditDrawer,
        toggleEditDrawer,
        handlePackageUpdate,
        cluster,
        setCluster,
        paymentType,
        setPaymentType,
        paymentTypeError,
        setPaymentTypeError,
    }
}
