import { ReactComponent as CloseIcon } from 'src/assets/images/close-icon-grey.svg'
import PropTypes from 'prop-types'
import '../consumermodalbase.scss'

const Header = ({ title, onCancel, showCloseIcon }) => {
    return (
        <div className="consumer-modal-base-header">
            <h4>{title}</h4>
            {showCloseIcon && (
                <CloseIcon
                    role="button"
                    onClick={onCancel}
                    aria-label="close button"
                />
            )}
        </div>
    )
}

Header.propTypes = {
    title: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    showCloseIcon: PropTypes.bool,
}

export default Header
