// import React from 'react'
import styles from './video-section.module.scss'
import ReactPlayer from 'react-player/lazy'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon.svg'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const VideoSection = () => {
    const { isMobile } = useMediaQueries()

    return (
        <div className={styles.Wrapper}>
            <div>
                <h2>
                    Check out this video of how we&apos;ve added value to the
                    business of one of our solar partners
                    &quot;wavelength&quot;.
                </h2>
                <p>
                    Ensuring our solar partners thrive is our top priority.
                    Partnering with us means delivering quality and value every
                    step of the way. We&apos;re dedicated to making sure your
                    customers get top-notch solar solutions that match their
                    needs and budget.
                </p>
                <div>
                    <ReactPlayer
                        url="https://www.youtube.com/watch?v=coAaPM37vBY"
                        width="100%"
                        height={isMobile ? '180px' : '643px'}
                        playing
                        playIcon={<PlayIcon width={70} height={70} />}
                        light="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/sse-landing-page-video-thumbnail.png"
                        controls={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default VideoSection
