export const handleMultipleFilesUpload = (
    fileList,
    setFileValid,
    setUploading,
    message,
    setSuccessUpload,
    acceptableFileTypes,
) => {
    if (!Object.keys(acceptableFileTypes)?.includes(fileList.type)) {
        message.error(
            `${
                fileList.name
            } is not one of the supported file types (${Object.values(
                acceptableFileTypes,
            )?.join(',')})`,
        )
        setFileValid(false)
        setUploading(false)
        return false
    } else if (!(fileList.size / 1000 / 1000 <= 10)) {
        message.error(`${fileList.name} should be less than 10mb`)
        setFileValid(false)
        setUploading(false)
        return false
    } else {
        setFileValid(true)
        setSuccessUpload(prev => [...prev, fileList])
        setUploading(false)
        return true
    }
}
