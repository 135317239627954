import s from '../componentbrands.module.scss'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'

const BrandCardLoader = ({ margin }) => {
    return (
        <div className={s.brandCard} style={{ margin: margin }}>
            <Skeleton.Button
                active
                className="brand-card-loader-skeleton-button"
                style={{ width: 100, marginBottom: 12 }}
            />
            <span>ADDED BRANDS</span>
            <div className={s.brandCardLoader_names}>
                {[...Array(3)].map((_, index) => (
                    <Skeleton.Button
                        key={index}
                        style={{ width: 80, borderRadius: 21 }}
                    />
                ))}
            </div>
        </div>
    )
}

BrandCardLoader.propTypes = {
    margin: PropTypes.string,
}

export default BrandCardLoader
