import PropTypes from 'prop-types'
import SingleCluster from './Components/SingleCluster'
import SingleMobileCluster from './Components/SingleMobileCluster'

const ClusterLibraryTable = ({ tableData }) => {
    return (
        <>
            <div className="ACLLargeTable">
                {/*  table head  */}
                <div
                    className="ACLTableHead"
                    style={{ gridTemplateColumns: '30% 28% 22% 27%' }}
                >
                    <p>Cluster Name</p>
                    <p>Cluster Code</p>
                    <p>Packages</p>
                    <p>Action</p>
                </div>

                {/*table row*/}
                <div className="ACLTableWrapper">
                    {tableData.map((data, i) => (
                        <SingleCluster data={data} key={i} />
                    ))}
                </div>
            </div>

            {/*    mobile table*/}
            {tableData?.map((data, i) => (
                <SingleMobileCluster data={data} key={i} />
            ))}
        </>
    )
}

ClusterLibraryTable.propTypes = {
    tableData: PropTypes.array,
    tabId: PropTypes.string,
}

export default ClusterLibraryTable
