import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Upload } from 'antd'
import styles from '../pageform.module.scss'
import { SelectField } from 'src/components/InputFields'

import UploadLogo from 'src/assets/images/upload.svg'
import SuccessUpload from 'src/assets/images/successUpload.svg'
import { profileSortingType } from 'src/pages/Admin/Profiles/components/data'
import CreateGroups from 'src/pages/Admin/Profiles/components/create-groups'
import useGetProfilesGroups from 'src/pages/Admin/Profiles/Groups/Listings/hooks/useGetProfilesGroups'
import { extractFileNameFromUrl } from 'src/utils/formatting'
import ActionModal from 'src/components/ActionModal'
import { actionModalTypes } from 'src/pages/Admin/Profiles/Groups/Listings/data'

const StepTwo = ({
    profileData,
    errors,
    handleSelectChange,
    uploadToS3Loading,
    uploadToS3Mutate,
    setErrors,
}) => {
    const { Dragger } = Upload
    const [showActionModal, setShowActionModal] = useState({
        open: false,
        type: '',
    })
    const [showCreateProfileGroupModal, setShowCreateProfileGroupModal] =
        useState(false)

    const [width, setWidth] = useState(5)
    const { profilesGroups, profilesGroupsFetching, profilesGroupsRefetch } =
        useGetProfilesGroups({
            filterState: {
                currentPage: '',
                searchText: '',
                customerType: profileData.customer_type,
                paginate: false,
            },
            onSuccess: () => {
                if (showActionModal) {
                    setShowActionModal({ open: false, type: '' })
                }
            },
        })
    const formattedProfileGroups = () => {
        const result = profilesGroups.map(profile => ({
            name: profile.name,
            value: profile.id,
        }))
        return result
    }

    useEffect(() => {
        const handleSlider = () => {
            if (uploadToS3Loading && width < 100) {
                setWidth(prev => prev + 2.5)
            } else if (!uploadToS3Loading && width > 5) {
                setWidth(100)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploadToS3Loading])

    const beforeUpload = file => {
        const isLt2M = file.size / 1000 / 1000 < 2

        if (!isLt2M) {
            setErrors(prev => ({
                ...prev,
                image_url:
                    'Please upload an svg, jpeg, or png file no more than 2mb',
            }))
        } else if (
            file.type !== 'image/svg+xml' ||
            file.type !== 'image/jpeg' ||
            file.type !== 'image/png'
        ) {
            setErrors(prev => ({
                ...prev,
                image_url: 'Please upload an svg, jpeg, or png file',
            }))
        }
    }

    const props = {
        name: 'file',

        onChange({ fileList }) {
            uploadToS3Mutate({
                category: 'ENERGY_PROFILE',
                file: fileList[0],
            })
        },
    }

    return (
        <div className={styles.Container} style={{ marginBottom: 46 }}>
            <section className={styles.FormSection}>
                <div
                    className={styles.FormSectionContentCol}
                    style={{ gap: 16 }}
                >
                    <div className={styles.ProfileGroupWrapper}>
                        <SelectField
                            selectWidth="100%"
                            marginBottom="0px"
                            marginTop="0px"
                            initialOption="Consumer Profile Group"
                            optionStyleNoCheckbox={{ color: '#233862' }}
                            name="group_id"
                            contentField="name"
                            dropdownPositionRelative
                            withCheckBox
                            floatingLabel={
                                profileData.group_id && 'Consumer Profile Group'
                            }
                            disabled={profilesGroupsFetching}
                            currentSelected={profileData.group_id}
                            value={profileData.group_id}
                            values={formattedProfileGroups()}
                            handleChange={handleSelectChange}
                            errorMessage={errors.group_id}
                        />
                        <button
                            onClick={() => setShowCreateProfileGroupModal(true)}
                        >
                            Create New Profile Group
                        </button>
                        <CreateGroups
                            showModal={showCreateProfileGroupModal}
                            onClose={() =>
                                setShowCreateProfileGroupModal(false)
                            }
                            data={{
                                id: '',
                                customer_type: profileData?.customer_type,
                            }}
                            setShowActionModal={setShowActionModal}
                            noProfile
                            utilFunc={data => {
                                handleSelectChange('group_id', data.id)
                            }}
                        />

                        <ActionModal
                            actionModalOpen={showActionModal.open}
                            headerText={actionModalTypes('group_created').title}
                            subTitle={
                                actionModalTypes('group_created').subTitle
                            }
                            actionType={
                                actionModalTypes('group_created').actionType
                            }
                            actionText={
                                actionModalTypes('group_created').primaryBtnText
                            }
                            noCancelBtn
                            loading={profilesGroupsFetching}
                            actionHandler={() => {
                                profilesGroupsRefetch()
                            }}
                            noBackLink
                        />
                    </div>

                    <SelectField
                        selectWidth="100%"
                        marginBottom="0px"
                        marginTop="0px"
                        initialOption="Packaging ordering"
                        optionStyleNoCheckbox={{ color: '#233862' }}
                        name="sort_by"
                        contentField="name"
                        dropdownPositionRelative
                        withCheckBox
                        floatingLabel={
                            profileData.sort_by && 'Package ordering'
                        }
                        currentSelected={profileData.sort_by}
                        value={profileData.sort_by}
                        values={profileSortingType}
                        handleChange={handleSelectChange}
                        errorMessage={errors.sort_by}
                    />
                    <div>
                        <Dragger
                            className={styles.UploadContainer}
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            showUploadList={false}
                            accept="image/svg+xml,image/jpeg,image/png"
                            disabled={uploadToS3Loading}
                            customRequest={() => {}}
                            {...props}
                        >
                            <p className="ant-upload-drag-icon">
                                {uploadToS3Loading && width < 100 ? (
                                    <div className="SlideContainer">
                                        <p
                                            className="Slider"
                                            style={{
                                                width: `${width}%`,
                                            }}
                                        ></p>
                                    </div>
                                ) : profileData?.image_url?.length > 0 ? (
                                    <img
                                        src={SuccessUpload}
                                        className="UploadLogo"
                                        alt="success logo"
                                    />
                                ) : (
                                    <img
                                        src={UploadLogo}
                                        className="UploadLogo"
                                        alt="upload logo"
                                    />
                                )}
                            </p>
                            <p
                                className="UploadText"
                                style={{ position: 'relative' }}
                            >
                                {uploadToS3Loading && width < 100 ? (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            left: '36%',
                                        }}
                                    >
                                        Uploading picture...
                                    </div>
                                ) : profileData?.image_url?.length > 0 ? (
                                    <>
                                        <p style={{ marginTop: 10 }}>
                                            {extractFileNameFromUrl(
                                                profileData?.image_url,
                                            )}
                                        </p>
                                        <p>
                                            <u
                                                className="Browse"
                                                style={{
                                                    marginTop: '20px',
                                                    lineHeight: '40px',
                                                }}
                                            >
                                                Replace document
                                            </u>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p>
                                            Upload file. Drag and drop or
                                            <u className="Browse">browse</u>
                                        </p>
                                        <p className={styles.fileSize}>
                                            The maximum accepted file size is
                                            2mb
                                        </p>
                                        <p className="OIDSupportedFormatText">
                                            Supported format : SVG, JPEG, PNG
                                        </p>
                                    </>
                                )}
                            </p>
                        </Dragger>
                        {!uploadToS3Loading && <p className="ErrorText">{errors?.image_url}</p>}
                    </div>
                </div>
            </section>
        </div>
    )
}

StepTwo.propTypes = {
    profileData: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    uploadToS3Mutate: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    uploadToS3Loading: PropTypes.bool.isRequired,
    setErrors: PropTypes.func.isRequired,
}

export default StepTwo
