import styles from './avatar.module.scss'
import { convertToInitials } from '../../utils/formatting'
import PropTypes from 'prop-types'

const Avatar = ({
    avatar,
    alt,
    first_name,
    last_name,
    background_color,
    text_color,
}) => {
    const { Wrapper, Image } = styles

    return (
        <div
            className={Wrapper}
            style={{ background: avatar === null && background_color }}
        >
            {avatar !== null ? (
                <img src={avatar} className={Image} alt={alt} />
            ) : (
                <p style={{ color: text_color }}>
                    {convertToInitials(first_name || '', last_name || '')}
                </p>
            )}
        </div>
    )
}

Avatar.propTypes = {
    avatar: PropTypes.string,
    alt: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    background_color: PropTypes.string,
    text_color: PropTypes.string,
}

export default Avatar
