/* eslint-disable react/prop-types */

import PackageImage from '../../assets/images/package.svg'
import Info from '../../assets/images/info.svg'
import BackNav from '../BackNav'
import { useLocation, useHistory } from 'react-router-dom'
import './productscontainer.scss'

const ProductsContainer = ({ children, text }) => {
    const location = useLocation()
    const prevLocation = location?.state?.prev === 'all products library'
    const sunfiId = location?.state?.sunfiId ?? ''
    const isAdminWorkspace = location?.state?.isAdminWorkspace ?? false
    const providerName = location?.state?.name ?? ''

    const history = useHistory()
    const redirectTo = pageUrl => {
        history.push(
            prevLocation || sunfiId.length < 0
                ? pageUrl
                : {
                      pathname: pageUrl,
                      search: '?tab=components',
                      state: {
                          sunfiId: sunfiId,
                          isAdminWorkspace: isAdminWorkspace,
                          name: providerName,
                      },
                  },
        )
    }
    return (
        <>
            <div className="ProductsContainer">
                <div className="MobileBackLink">
                    <BackNav
                        title="Back to Products"
                        onClick={
                            prevLocation
                                ? () => redirectTo('/admin/products')
                                : () => redirectTo('/app/products')
                        }
                    />
                </div>
                <div className="ProductsPageBox">
                    <div className="PageBoxSectionOne">{children}</div>
                    <div className={'ProductsRightWrapper'}>
                        <img
                            src={Info}
                            alt="info-icon"
                            className={'ProductsInfoIcon'}
                        />
                        <p className={'ProductsHeader'}>{text}</p>
                        <img src={PackageImage} alt="solar" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductsContainer
