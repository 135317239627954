import PropTypes from 'prop-types'
import Label from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/_components/Label'
import s from '../styles.module.scss'
import { InputFields } from 'src/components/InputFields'
import { SelectCountryCode } from 'src/components/InputFields/SelectCountryCode'

const PersonalInfo = ({ inputs, errors, handleChange, handleOnBlur }) => {
    return (
        <div className={s.wrapper}>
            <Label
                title="Member full name"
                subTitle="Provide member first name and surname as it appears on their ID card"
                titleFontFamily="var(--font-regular)"
            />
            <InputFields
                title={'First Name'}
                name={'first_name'}
                value={inputs?.first_name}
                errorMessage={errors?.first_name}
                handleChange={handleChange}
                onBlur={handleOnBlur}
            />
            <InputFields
                title={'Surname'}
                marginBottom={'32px'}
                name={'last_name'}
                value={inputs?.last_name}
                errorMessage={errors?.last_name}
                handleChange={handleChange}
                onBlur={handleOnBlur}
            />
            <Label
                title="Member phone number and email"
                subTitle="Provide  member phone number and email address."
                titleFontFamily="var(--font-regular)"
            />
            <SelectCountryCode
                title={'Phone Number'}
                name={'phone_number'}
                value={inputs?.phone_number}
                errorMessage={errors?.phone_number}
                handleChange={handleChange}
                onBlur={handleOnBlur}
                selectWidth={'100px'}
            />
            <InputFields
                title={'Work Email Address'}
                marginBottom={'32px'}
                name={'email'}
                value={inputs?.email}
                errorMessage={errors?.email}
                handleChange={handleChange}
                onBlur={handleOnBlur}
            />
        </div>
    )
}

PersonalInfo.propTypes = {
    inputs: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleOnBlur: PropTypes.func,
}

export default PersonalInfo
