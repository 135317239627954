/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { Menu, Dropdown } from 'antd'
import SeoComponent from '../../../components/Seo'
import { InputFields, SelectField } from '../../../components/InputFields'
import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'
import FormTitleBar from '../../../components/FormTitleBar'
import { eventTrackers } from '../../../utils/eventTrackers.js'
import { appTracking } from '../../../utils/appTracker.js'
import styles from './addcomponent.module.scss'

const StepTwo = ({ initialState, gotoNext, gotoPrevious, modalFlow }) => {
    const {
        CreateComponentSubHeader,
        PageForm,
        ComponentActionHeader,
        HidePrevContinueBtns,
        MobileContinueBtn,
        unitContainer,
        modalButtonWrapper,
    } = styles

    const [allInputs, setAllInputs] = useState(initialState?.selectedAttributes)
    const [showMenu, setShowMenu] = useState(false)
    const [, setShowAddBtn] = useState(true)
    const [allErrors, setAllErrors] = useState([])

    const pageTitle =
        'Create A Component - Add Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'ADD_COMPONENT_TRACKER'

    const componentErrorHandler = (name, value) => {
        const errorsCopy = [...allErrors]
        if (value?.length < 1) {
            errorsCopy.push(name)
        } else {
            const index = errorsCopy.indexOf(name)
            if (index > -1) {
                errorsCopy.splice(index, 1)
            }
        }

        setAllErrors(errorsCopy)
    }
    const handleInputChange = (event, inputValue) => {
        const name = event?.target?.name || event
        const value = event?.target?.value || inputValue

        const attributeInputs = [...allInputs]

        const findInput = attributeInputs?.findIndex(q => q.name === name)

        if (findInput > -1) {
            const formattedInput = {
                name,
                value: value !== '' ? value : '',
                unit: attributeInputs[findInput]?.unit,
                required: attributeInputs[findInput]?.required,
                show: attributeInputs[findInput]?.show,
            }

            attributeInputs[findInput] = formattedInput
        }

        setAllInputs(attributeInputs)
        componentErrorHandler(name, value)
    }

    const handleSelectChange = (name, value) => {
        const attributeInputs = [...allInputs]
        let findInput = attributeInputs?.findIndex(q => q.name === name)
        if (findInput > -1) {
            const formattedInput = {
                name: attributeInputs[findInput]?.name,
                value: attributeInputs[findInput]?.value,
                unit: value,
                required: attributeInputs[findInput]?.required,
                show: attributeInputs[findInput]?.show,
            }
            attributeInputs[findInput] = formattedInput
        }
        setAllInputs(attributeInputs)
        componentErrorHandler('unit' + name, value)
    }

    const handleVisibleChange = flag => {
        setShowMenu(flag)
    }

    const removeAttribute = value => {
        setShowAddBtn(true)

        const attributeInputs = [...allInputs]

        const findInput = attributeInputs?.findIndex(q => q.name === value)

        if (findInput > -1) {
            const formattedInput = {
                name: attributeInputs[findInput]?.name,
                unit: attributeInputs[findInput]?.units_of_measurement,
                value: 0,
                show: false,
                required: attributeInputs[findInput]?.required,
            }

            attributeInputs[findInput] = formattedInput
        }

        setAllInputs(attributeInputs)
    }

    const handleAdd = value => {
        const attributeInputs = [...allInputs]
        const findInput = attributeInputs?.findIndex(
            q => q.name === value?.name,
        )

        if (findInput > -1) {
            const formattedInput = {
                name: value.name,
                unit: value?.units_of_measurement ? '' : value.unit,
                value: '',
                show: true,
                required: value?.required,
            }

            attributeInputs[findInput] = formattedInput
        }
        setAllInputs(attributeInputs)
    }

    const menu = (
        <Menu className="menu">
            {allInputs
                ?.filter(value => value?.required === false)
                ?.map((menuItem, index) => (
                    <Menu.Item key={index} onClick={() => handleAdd(menuItem)}>
                        <Checkbox acceptTextClick>{menuItem?.name}</Checkbox>
                    </Menu.Item>
                ))}
        </Menu>
    )
    const handlePrevious = () => {
        gotoPrevious(allInputs)
    }

    const handleNext = () => {
        const checkInputsUnit = allInputs.filter(
            input => input?.unit === '' && input?.unitLength > 0,
        )
        const checkInputsText = allInputs.filter(
            input => input?.value === '' && input?.required === true,
        )

        const errors = []

        if (checkInputsUnit?.length > 0 || checkInputsText?.length > 0) {
            checkInputsUnit.forEach(value => {
                errors.push('unit' + value?.name)
            })
            checkInputsText.forEach(value => {
                errors.push(value?.name)
            })

            setAllErrors(errors)
            return
        } else {
            gotoNext(allInputs)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                eventTrackers['addComponentAttributes'].action,
                eventTrackers['addComponentAttributes'].label,
                eventTrackers['addComponentAttributes'].category,
            )
        }
    }

    const getAttributeUnits = name => {
        let unitArray = []
        initialState?.attributes
            ?.filter(value => value?.name === name)[0]
            ?.units_of_measurement?.forEach(value => {
                unitArray.push({
                    value,
                })
            })
        return unitArray
    }

    const showAddButton = () => {
        const checkIfOptionalAttributeExist = initialState?.attributes?.filter(
            value => value?.required === false,
        )

        if (checkIfOptionalAttributeExist?.length < 1) {
            return false
        } else {
            let list = []

            checkIfOptionalAttributeExist?.forEach(value => {
                const checkIfSelected = allInputs?.filter(
                    input =>
                        input?.name === value?.name && input?.show === true,
                )

                if (checkIfSelected?.length > 0) {
                    list.push(value)
                }
            })
            if (checkIfOptionalAttributeExist?.length - list?.length > 0) {
                return true
            } else {
                return false
            }
        }
    }

    useEffect(() => {
        let newInputs = []
        if (initialState?.selectedAttributes?.length < 1) {
            initialState?.attributes?.forEach(value => {
                newInputs.push({
                    name: value.name,
                    unit:
                        value?.units_of_measurement.length === 1
                            ? value?.units_of_measurement[0]
                            : '',
                    value: '',
                    required: value?.required,
                    show: value?.required,
                    unitLength: value?.units_of_measurement.length,
                })
            })
            setAllInputs(
                newInputs.sort((a, b) => {
                    if (a.required < b.required) return 1
                    if (a.required > b.required) return -1
                    return 0
                }),
            )
        } else {
            setAllInputs(
                initialState?.selectedAttributes?.sort((a, b) => {
                    if (a.required < b.required) return 1
                    if (a.required > b.required) return -1
                    return 0
                }),
            )
        }
        // eslint-disable-next-line
    }, [initialState])

    const checkFiledType = name => {
        const checkType = initialState?.attributes?.filter(
            value => value?.name === name,
        )
        return checkType[0]?.field_type
    }

    const getAttributeOptions = name => {
        let unitOptions = []

        initialState?.attributes
            ?.filter(value => value?.name === name)[0]
            ?.options?.forEach(value => {
                unitOptions.push({
                    value,
                })
            })

        return unitOptions
    }

    return (
        <>
            <SeoComponent
                title="Create A Component - Add Attributes | SunFi | Simplifying and Scaling Clean Energy"
                tracker="Create A Component - Add Attributes"
            />
            {modalFlow ? (
                <FormTitleBar
                    title="Create New Component"
                    subtitle="Add Attributes to your Component"
                />
            ) : (
                <>
                    <div className={CreateComponentSubHeader}>
                        Create New Component
                    </div>

                    <div className={ComponentActionHeader}>
                        Add Attributes to your Component
                    </div>
                </>
            )}

            <div className={PageForm}>
                <div>
                    {allInputs
                        ?.filter(value => value?.show === true)
                        ?.map((attribute, index) => (
                            <div key={index + 'add-component-attribute'}>
                                <div
                                    style={{
                                        display:
                                            getAttributeUnits(attribute?.name)
                                                ?.length > 0
                                                ? 'flex'
                                                : 'block',
                                    }}
                                >
                                    {getAttributeOptions(attribute?.name)
                                        ?.length > 0 ? (
                                        <SelectField
                                            values={getAttributeOptions(
                                                attribute?.name,
                                            )}
                                            currentSelected={attribute?.value}
                                            initialOption={attribute?.name}
                                            handleChange={handleInputChange}
                                            name={attribute.name}
                                            value={attribute?.value}
                                            withCheckBox={true}
                                            selectWidth={
                                                getAttributeUnits(
                                                    attribute?.name,
                                                )?.length > 0
                                                    ? '200px'
                                                    : '100%'
                                            }
                                            dropdownClassName=""
                                            errorMessage={
                                                allErrors?.includes(
                                                    attribute.name,
                                                )
                                                    ? 'This field is Required'
                                                    : ''
                                            }
                                        />
                                    ) : (
                                        <InputFields
                                            step="addAttributesToComponent"
                                            type={checkFiledType(
                                                attribute?.name,
                                            )}
                                            inputWidth={
                                                getAttributeUnits(
                                                    attribute?.name,
                                                )?.length > 0
                                                    ? '200px'
                                                    : 100
                                            }
                                            title={attribute.name}
                                            value={attribute?.value}
                                            handleChange={handleInputChange}
                                            name={attribute.name}
                                            autoFocus={
                                                index === 0 ? true : false
                                            }
                                            errorMessage={
                                                allErrors?.includes(
                                                    attribute.name,
                                                )
                                                    ? 'This field is Required'
                                                    : ''
                                            }
                                        />
                                    )}
                                    &nbsp; &nbsp;
                                    {getAttributeUnits(attribute?.name)
                                        ?.length > 0 && (
                                        <div
                                            style={{ display: 'block' }}
                                            className={unitContainer}
                                        >
                                            <SelectField
                                                values={getAttributeUnits(
                                                    attribute?.name,
                                                )}
                                                currentSelected={
                                                    allInputs.find(
                                                        i =>
                                                            i.name ===
                                                            attribute.name,
                                                    )?.unit
                                                }
                                                initialOption="Unit of Measurement"
                                                handleChange={
                                                    handleSelectChange
                                                }
                                                name={attribute.name}
                                                // value={
                                                //   allInputs.find((i) => i.name === attribute.name)?.unit
                                                // }
                                                value={
                                                    getAttributeUnits(
                                                        attribute?.name,
                                                    ).length === 1
                                                        ? getAttributeUnits(
                                                              attribute?.name,
                                                          )[0].value
                                                        : allInputs.find(
                                                              i =>
                                                                  i.name ===
                                                                  attribute.name,
                                                          )?.unit
                                                }
                                                withCheckBox={true}
                                                selectWidth="200px"
                                                dropdownClassName="units"
                                                errorMessage={
                                                    allErrors?.includes(
                                                        'unit' + attribute.name,
                                                    )
                                                        ? 'This field is Required'
                                                        : ''
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                {!attribute?.required ? (
                                    <div className="removeAttributeWrapper">
                                        <CloseOutlined className="removeAttributeIcon" />
                                        <span
                                            onClick={() =>
                                                removeAttribute(attribute.name)
                                            }
                                            className="replaceDoc"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            Remove attribute
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ))}
                </div>
                {showAddButton() && (
                    <div className="AddNewButton">
                        <Dropdown
                            overlay={menu}
                            visible={showMenu}
                            onVisibleChange={handleVisibleChange}
                        >
                            <Button
                                btnBgColor="var(--purple-light)"
                                btnTextColor="var(--blue)"
                                btnTextColorOutline="var(--blue)"
                                btnOutlineColor="var(--purple-light)"
                                btnBgColorOutline="#E2EEFF"
                                type="outline"
                                btnWidth="127px"
                                handleClick={setShowMenu}
                            >
                                <PlusOutlined
                                    style={{
                                        fontSize: 16,
                                        color: 'var(--blue)',
                                    }}
                                />
                                Add New
                            </Button>
                        </Dropdown>
                    </div>
                )}
                <br />
                <br />
                <div
                    className={
                        modalFlow ? modalButtonWrapper : HidePrevContinueBtns
                    }
                >
                    <Button
                        btnBgColor="var(--purple-light)"
                        btnTextColor="var(--blue)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        type="outline"
                        btnWidth="150px"
                        handleClick={handlePrevious}
                    >
                        Previous
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        btnWidth="160px"
                        handleClick={handleNext}
                    >
                        Continue
                    </Button>
                </div>

                <div className={MobileContinueBtn}>
                    <Button
                        btnBgColor="var(--purple-light)"
                        btnTextColor="var(--blue)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        type="outline"
                        btnWidth="150px"
                        handleClick={handlePrevious}
                    >
                        Previous
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        btnWidth="150px"
                        handleClick={handleNext}
                    >
                        Continue
                    </Button>
                    <br />
                </div>
            </div>
        </>
    )
}

export default StepTwo
