import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { AppButton } from 'src/components/Button'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { titleCase } from 'src/utils/formatting'
import { pathMapping, statusMapping } from '../Active/data'
import styles from './providercustomerslisting.module.scss'
import Card from 'src/components/Card'
import { permissionsControl } from 'src/utils/permissionsControl'
import StatusIndicator from 'src/components/StatusIndicator'

const CustomerListingTable = ({ tableData, tabId, openModal }) => {
    const history = useHistory()

    const { desktopTable, cards } = styles

    const redirectTo = pageUrl => {
        history.push({
            pathname: pageUrl,
        })
    }

    const canViewPaymentPlansDetails = permissionsControl([
        'can_view_estimation_detail',
    ])

    const status = data => {
        return (
            <StatusIndicator
                status={data?.estimation[0]?.application_status}
                arrowPositionLeft={false}
                toolTipMarginLeft={'-150px'}
            />
        )
    }

    return (
        <>
            <div className={desktopTable}>
                {/*    table head*/}
                <div
                    className="ACLTableHead"
                    style={{ gridTemplateColumns: '27% 25% 10% 24% 13%' }}
                >
                    <p>First/Last Name</p>
                    <p>Phone Number</p>
                    <p>Plans</p>
                    <p>{tabId === 1 ? 'Status' : 'Total Fin. Amount'}</p>
                    <p>Actions</p>
                </div>

                {/*table row*/}

                <div className="ACLTableWrapper">
                    {tableData.map((data, i) => (
                        <div
                            className="ACLTableRow"
                            key={i}
                            style={{
                                gridTemplateColumns: '27% 25% 10% 24% 13%',
                            }}
                        >
                            <p>{data?.display_name}</p>
                            <p>{data?.phone_numer}</p>
                            <p>{data?.estimation?.length}</p>
                            <>
                                {tabId === 1 ? (
                                    data?.estimation[0]?.approval_status ? (
                                        status(data)
                                    ) : (
                                        <p>N/A</p>
                                    )
                                ) : (
                                    <p>
                                        ₦
                                        {currencyFormatter(
                                            data?.estimation[0]
                                                ?.financing_amount,
                                        )}
                                    </p>
                                )}
                            </>
                            <AppButton
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                btnWidth="70px"
                                btnHeight="37px"
                                fontSize="12px"
                                fontFamily="SF-Pro-Display-Medium"
                                disabled={!canViewPaymentPlansDetails}
                                toolTipText={
                                    !canViewPaymentPlansDetails &&
                                    'You are not authorised to perform this action'
                                }
                                showTooltip={
                                    !canViewPaymentPlansDetails && true
                                }
                                toolTipFontSize={'12px'}
                                toolTipPadding={'10px 6px 14px 11px'}
                                toolTipPopupWidth={'140px'}
                                toolTipMarginLeft={'-40px'}
                                arrowPositionCenter={true}
                                handleClick={
                                    data?.estimation?.length > 1
                                        ? () => openModal(data)
                                        : () => {
                                              if (tabId === 2) {
                                                  redirectTo(
                                                      `/app/customers/active/${data?.estimation[0]?.id}`,
                                                  )
                                              } else {
                                                  redirectTo(
                                                      `/app/customers/${
                                                          pathMapping[
                                                              data
                                                                  ?.estimation[0]
                                                                  ?.approval_status
                                                          ]
                                                      }/${
                                                          data?.estimation[0]
                                                              ?.id
                                                      }`,
                                                  )
                                              }
                                          }
                                }
                            >
                                View
                            </AppButton>
                        </div>
                    ))}
                </div>
            </div>
            <div className={cards}>
                {tableData.map((data, i) => (
                    <Card
                        key={i}
                        topLeftHeading={'FIRST/LAST NAME'}
                        topLeftContent={data?.display_name}
                        bottomLeftHeading={'PLANS'}
                        bottomLeftContent={data?.estimation?.length}
                        bottomRightHeading={
                            tabId === 1 ? 'STATUS' : 'TOTAL FIN. AMT.'
                        }
                        bottomRightContent={
                            tabId === 1
                                ? data?.estimation[0]?.approval_status
                                    ? titleCase(
                                          statusMapping[
                                              data?.estimation[0]
                                                  ?.approval_status
                                          ],
                                      )
                                    : 'N/A'
                                : `₦ ${currencyFormatter(
                                      data?.estimation[0]?.financing_amount,
                                  )}`
                        }
                        bottomRightElement={tabId === 1 && status(data)}
                        handleClick={
                            data?.estimation?.length > 1
                                ? () => openModal(data)
                                : () => {
                                      if (tabId === 2) {
                                          redirectTo(
                                              `/app/customers/active/${data?.estimation[0]?.id}`,
                                          )
                                      } else {
                                          redirectTo(
                                              `/app/customers/${
                                                  pathMapping[
                                                      data?.estimation[0]
                                                          ?.approval_status
                                                  ]
                                              }/${data?.estimation[0]?.id}`,
                                          )
                                      }
                                  }
                        }
                        topLeftContentSize={15}
                        btnDisabled={!canViewPaymentPlansDetails}
                        showTooltip={!canViewPaymentPlansDetails && true}
                        toolTipText={
                            !canViewPaymentPlansDetails &&
                            'You are not authorised to perform this action'
                        }
                        toolTipFontSize={'12px'}
                        toolTipPadding={'10px 6px 14px 11px'}
                        toolTipPopupWidth={'130px'}
                        toolTipMarginLeft={'-40px'}
                        arrowPositionCenter={true}
                    />
                ))}
            </div>
        </>
    )
}

CustomerListingTable.propTypes = {
    tableData: PropTypes.array,
    tabId: PropTypes.string,
    openModal: PropTypes.func,
}

export default CustomerListingTable
