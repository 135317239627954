import axios from '../../config/axios'

export const getAllBanksApi = async () => {
    const request = await axios.get(`/payments/banks?paginate=${false}`)
    return request
}

export const verifyAccountNumberApi = async (
    accountNumber = '',
    bankCode = ''
) => {
    const request = await axios.get(`/payments/banks/resolve-name?account_number=${accountNumber}&bank_code=${bankCode}`)
    return request
}

export const bankAccountDetailsApi = async inputs => {
    const request = await axios.post(`/providers/bank-details`, inputs)
    return request
  }
