export const formatProviderUserActivity = data => {
    let action = []

    for (let i = 0; i < data.length; i++) {
        switch (data[i].action) {
            case 'PPL_PACKAGE_CREATED':
                action.push({
                    task: 'Created a package in the product library',
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPL_PACKAGE_DELETED':
                action.push({
                    task: 'Deleted a package from the product library',
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPL_COMPONENT_CREATED':
                action.push({
                    task: 'Created a component in the product library',
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PDPL_COMPONENT_DELETED':
                action.push({
                    task: 'Deleted a component from the product library',
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPP_UPDATE_PAYMENT_PLAN_POST_PACKAGE_SNAPSHOT':
                action.push({
                    task: `Generated recommended solutions using Power Curve
                    `,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPP_UPDATED_PAYMENT_PLAN':
                action.push({
                    task: `Updated a payment plan for <span>${
                        data?.[i]?.target ?? 'a consumer'
                    }</span>
                    `,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPP_PAYMENT_PLAN_CREATED':
                action.push({
                    task: `Created a payment plan for
                        <span>${data?.[i]?.target ?? 'a consumer'}</span>
                    `,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPP_UPDATE_PAYMENT_PLAN_POST_REPAYMENT_PLANS_MAIL_SENT_TO_ADMIN_AND_CONSUMER':
                action.push({
                    task: `Sent payment plan to SunFi admins and <span>${
                        data?.[i]?.target ?? 'a consumer'
                    }</span>`,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPP_UPDATE_PAYMENT_PLAN_POST_REPAYMENT_PLANS_MAIL_SENT_TO_CONSUMER':
                action.push({
                    task: `Sent payment plan to <span>${
                        data?.[i]?.target ?? 'a consumer'
                    }</span>`,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPP_UPDATE_PAYMENT_PLAN_REPAYMENT_PLANS_UPDATED':
                action.push({
                    task: `Updated a repayment plan for <span>${
                        data?.[i]?.target ?? 'a consumer'
                    }</span>`,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPL_IMAGE_UPLOADED_TO_S3':
                action.push({
                    task: `Added an image to a product in the product library`,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPL_EXISTING_PACKAGE_NAME':
                action.push({
                    task: `Changed the name of a package in the product library`,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPP_BANK_STATEMENT_SUBMISSION':
                action.push({
                    task: `Uploaded a bank statement for <span>${
                        data?.[i]?.target ?? 'a consumer'
                    }</span>`,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPP_UPDATE_PAYMENT_PLAN_PACKAGE_SNAPSHOT_INVALID_DATA':
                action.push({
                    task: `Unsuccessfully attempted to update a recommended solution`,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPP_CREATE_PAYMENT_PLAN_NEW_USER_CREATED':
                action.push({
                    task: `Created a new user`,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'PPP_CREATE_PAYMENT_PLAN_NEW_CONSUMER_CREATED':
                action.push({
                    task: `Created a new consumer`,
                    date: `${data?.[i]?.date}`,
                })
                break
            case 'CP_MONTHLY_REPAYMENT_VERIFICATION_SUCCEEDS':
                action.push({
                    task: `Made a monthly repayment on behalf of <span>${
                        data?.[i]?.target ?? 'a consumer'
                    }</span>`,
                    date: `${data?.[i]?.date}`,
                })
                break
            default:
                action.push({
                    task: `Performed an action in the following category: <span>${data?.[i]?.category}</span>`,
                    date: `${data?.[i]?.date}`,
                })
        }
    }

    return action
}


export const formatAdminUserActivity = data => {
    let action = []

    for (let i = 0; i < data.length; i++) {
        switch (data[i].action) {
            case 'AE_RESUBMIT_BANK_STATEMENT':
                    action.push({
                        task: `Request Bank statement submission from
                            <span>${data?.[i]?.target ?? 'a consumer'}</span>
                        `,
                        date: `${data?.[i]?.date}`,
                    })
                    break
            case 'APRD_DELETE_APPLIANCE':
                    action.push({
                        task: 'Deleted an appliance',
                        date: `${data?.[i]?.date}`,
                    })
                break
            case 'ADMIN_UPDATE_USER':
                    action.push({
                        task: `Updated
                            <span>${data?.[i]?.target ?? 'a consumer'}</span>
                        user details`,
                        date: `${data?.[i]?.date}`,
                    })
                    break
            case 'AE_CLOSE_APPLICATION':
                        action.push({
                            task: `Declined 
                                <span>${data?.[i]?.target ?? 'a consumer'}</span>
                            plan with estimation`,
                            date: `${data?.[i]?.date}`,
                        })
                        break
                    
            case 'AE_APPROVE_OFFER':
                    action.push({
                        task: `Approved 
                            <span>${data?.[i]?.target ?? 'a consumer'}</span>
                        Payment plan`,
                        date: `${data?.[i]?.date}`,
                    })
                break
            case 'APRD_CREATE_COMPONENT_TYP':
                    action.push({
                        task: 'Create component type',
                        date: `${data?.[i]?.date}`,
                    })
                break
            case 'APRD_BULK_CREATE_APPLIANCE':
                    action.push({
                        task: 'Create bulk appliance',
                        date: `${data?.[i]?.date}`,
                    })
                break
            case 'APRD_UPDATE_COMPONENT_TYPE':
                    action.push({
                        task: 'Update component type',
                        date: `${data?.[i]?.date}`,
                    })
                break
            case 'APRD_DELETE_COMPONENT_TYPE':
                    action.push({
                        task: 'Delete component type',
                        date: `${data?.[i]?.date}`,
                    })
                break
            case 'APRD_UPDATE_APPLIANCE':
                    action.push({
                        task: 'Updated an appliance',
                        date: `${data?.[i]?.date}`,
                    })
                break
            case 'AC_UPDATE_CREDIT_VARIABLE':
                    action.push({
                        task: 'Made update to a credit variable',
                        date: `${data?.[i]?.date}`,
                    })
                break

            default:
                action.push({
                    task: `Performed an action in the following category: <span>${data?.[i]?.category}</span>`,
                    date: `${data?.[i]?.date}`,
                })
        }
    }

    return action
}