import PropTypes from 'prop-types'
import { ReactComponent as SuccessIcon } from 'src/assets/images/successImage.svg'
import ButtonComponent from 'src/components/Button'
import s from './success.module.scss'

const Success = ({ buttonAction }) => {
    return (
        <div className={s.wrapper}>
            <SuccessIcon />
            <h4>You have successfully submitted brands you work with</h4>
            <p>
                These brands you’ve submitted will be reviewed by our team and
                you’ll get an email on the result of the review
            </p>
            <ButtonComponent
                btnBgColor={'#004AAD'}
                btnTextColor={'#FFFFFF'}
                btnWidth={'259px'}
                btnHeight={'56px'}
                handleClick={buttonAction}
            >
                Go Back to Partners Page
            </ButtonComponent>
        </div>
    )
}

Success.propTypes = {
    buttonAction: PropTypes.func,
}

export default Success
