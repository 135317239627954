import { useHistory } from 'react-router-dom'
import { scrollToById } from 'src/utils/scrollToById'

const useHandleSectionScroll = () => {
    const history = useHistory()

    const handleSectionScroll = (section, page = '', offset = 150) => {
        if (page === 'closedCluster') {
            const targetElement = document.getElementById(section)
            if (targetElement) {
                scrollToById(targetElement.id, offset)
                return
            }
        }
        if (location.pathname === '/') {
            const targetElement = document.getElementById(section)
            if (targetElement) {
                scrollToById(targetElement.id, offset)
            }
        } else {
            history.push({
                pathname: '/',
                state: {
                    scrollToSection: section,
                    scrollToSectionOffset: offset,
                },
            })
        }
    }

    return { handleSectionScroll }
}

export default useHandleSectionScroll
