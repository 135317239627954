import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
    page: '1',
    name: null,
    created_at_start: null,
    created_at_stop: null,
    status: null,
    approval_status: null,
    current_tab: '1',
    filter_mode: 'false',
}

const providerCustomersListing = createSlice({
    name: 'providerCustomersListingFilter',
    initialState,
    reducers: {
        updateProviderCustomersFilters(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetProviderCustomersFilters(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateProviderCustomersFilters, resetProviderCustomersFilters } =
    providerCustomersListing.actions

export const updatedProviderCustomersFilters = state =>
    state.providerCustomersFilter

const persistConfig = {
    key: 'providerCustomersListingFilter',
    storage,
}

export const persistedProviderCustomersListingReducer = persistReducer(
    persistConfig,
    providerCustomersListing.reducer,
)

export default providerCustomersListing.reducer
