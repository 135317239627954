/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useState } from 'react'
import { Upload, message } from 'antd'
import { saveAs } from 'file-saver'
import { useQuery } from 'react-query'
import { errorHandler } from '../../../utils/errorHandler'
import Toast from '../../../components/Toast'
import { InlineLoader } from '../../../components/Loader'
import FormTitleBar from '../../../components/FormTitleBar'
import { SelectField } from '../../../components/InputFields'
import Button from '../../../components/Button'
import successUpload from '../../../assets/images/successUpload.svg'
import { uploadProductFileTypes } from '../../../utils/supportedFileTypes'
import { types } from './data.js'
import styles from './uploadproducts.module.scss'

import { bulkCreatePackageApi } from '../../../api/products/packages'

const UploadProducts = ({ handleModal }) => {
    const { MobileWrapper, MobileUpload, MobileUploadBtnWrapper } = styles

    const [inputs, setInputs] = useState({
        productType: '',
    })

    const [fileName, setFileName] = useState('')
    const [uploadedFile, setUploadedFile] = useState([])
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [validationMessage, setValidationMessage] = useState('')
    const [selectError, setSelectError] = useState('')

    const handleSelectChange = (name, value) => {
        setSelectError('')
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const downloadFile = url => {
        saveAs(url, 'template')
    }

    const props = {
        name: 'file',
        beforeUpload: file => {
            if (!uploadProductFileTypes?.includes(file.type)) {
                message.error(`${file.name} is not a .xls/.xlsx or csv file`)
            }
            return uploadProductFileTypes?.includes(file.type)
                ? true
                : Upload.LIST_IGNORE
        },
        onChange(info) {
            setFileName(info.file.name)
            setUploadedFile(info.fileList)
        },
        showUploadList: false,
    }

    const { isLoading, refetch: packageRefetch } = useQuery(
        'createBulkPackage',
        () =>
            bulkCreatePackageApi(
                uploadedFile.length > 0 && uploadedFile[0]?.originFileObj,
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                handleModal()
            },
            onError: data => {
                if (data?.response?.data?.errors) {
                    setErrorMessage(errorHandler(data?.response?.data))
                } else {
                    setErrorMessage(['Failed to create packages. Try Again'])
                }

                setToastError(true)

                setTimeout(() => {
                    setErrorMessage('')
                    setToastError(false)
                }, 4000)
            },
        },
    )

    const handleSaveProcess = () => {
        if (fileName === '' || inputs.productType === '') {
            inputs.productType === ''
                ? setSelectError('This is required')
                : setValidationMessage('Upload a template file')

            setTimeout(() => {
                setValidationMessage('')
            }, 4000)
        } else {
            if (inputs.productType === 'Package') {
                packageRefetch()
            }
        }
    }

    const saveHandler = () => {
        setValidationMessage('')
        setSelectError('')
        handleSaveProcess()
    }

    return (
        <>
            <FormTitleBar
                title={`Upload Products`}
                subtitle="Easily select products for your library"
            />
            {toastError ? (
                <Toast messageType="error" message={errorMessage[0]} />
            ) : validationMessage ? (
                <Toast messageType="error" message={validationMessage} />
            ) : null}

            <div className={MobileWrapper}>
                <br />
                <SelectField
                    values={types}
                    currentSelected={inputs.productType}
                    initialOption="Select Products"
                    handleChange={handleSelectChange}
                    name="productType"
                    selectedValue={inputs.productType}
                    withCheckBox={true}
                    selectWidth="100%"
                    dropdownClassName="units"
                    errorMessage={selectError}
                />
                <br />
                <div>
                    <div
                        className={`img-wrapper ${MobileUpload}`}
                        style={{ width: '100%', height: '226px' }}
                    >
                        <p className="ant-upload-drag-icon">
                            <img src={successUpload} alt="successful-upload" />
                        </p>
                        <div className="filename">{fileName}</div>

                        <Upload {...props}>
                            <div
                                style={{
                                    marginBottom: '13px',
                                    color: 'rgba(35, 56, 98, 0.78)',
                                }}
                            >
                                {!fileName ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <span>
                                            Upload image. Drag and drop or
                                            &nbsp;
                                        </span>
                                        <span className="replaceDoc">
                                            browse
                                        </span>
                                        <br />
                                        <br />
                                        <span>
                                            <b>Supported format:</b> .xls, .csv
                                        </span>
                                    </div>
                                ) : (
                                    <span className="replaceDoc">
                                        Replace document
                                    </span>
                                )}
                            </div>
                        </Upload>

                        <Button
                            btnBgColor="var(--purple-light)"
                            btnTextColor="var(--blue)"
                            btnTextColorOutline="var(--blue)"
                            btnOutlineColor="var(--purple-light)"
                            btnBgColorOutline="#E2EEFF"
                            type="outline"
                            btnWidth="147px"
                            handleClick={() => {
                                if (inputs?.productType === 'Package') {
                                    downloadFile(
                                        process.env
                                            .REACT_APP_PACKAGES_TEMPLATE_LINK,
                                    )
                                } else {
                                    downloadFile(
                                        process.env
                                            .REACT_APP_COMPONENTS_TEMPLATE_LINK,
                                    )
                                }
                            }}
                        >
                            Download Template
                        </Button>
                    </div>
                </div>
                <div
                    style={{ textAlign: 'center', width: '425px' }}
                    className={MobileUploadBtnWrapper}
                >
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        btnWidth="160px"
                        handleClick={saveHandler}
                        disabled={isLoading ? true : false}
                    >
                        {isLoading ? <InlineLoader /> : 'Upload Product'}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default UploadProducts
