//TODO: take out disabled eslint and refactor code for best practices in extreme cases
/* eslint-disable  */
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'

import { ActionSection } from '../../index'
import FormTitleBar from '../../../../components/FormTitleBar'
import Toast from '../../../../components/Toast'
import Button from '../../../../components/Button'
import {
    SelectField,
    InputFields,
    NoFLoatingLabelInputFields,
} from '../../../../components/InputFields'
import { NubanAccountDetailsValidationSchema } from '../../../../utils/validationSchema'
import {
    getAllBanksApi,
    verifyAccountNumberApi,
    bankAccountDetailsApi,
} from '../../../../api/onboarding/nubanaccountdetails'
import { errorHandler } from '../../../../utils/errorHandler'

import styles from './nubanaccountdetails.module.scss'
import { InlineLoader } from '../../../../components/Loader'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'

const { InputBox, closeFormText, ProfilePageButton } = styles

const NubanAccountDetails = ({
    prevHandler,
    nextHandler,
    handleFormClose,
    depType,
    page,
    profilePageNextHandler,
}) => {
    const pageTitle = 'SunFi - Onboarding | Nuban Account Details'
    const pageUrlName = window.location.pathname
    const pageTracker = 'Onboarding'
    const [errors, setErrors] = useState({})
    const [bankList, setbankList] = useState([])
    const [inputs, setInputs] = useState({
        bankName: '',
        accountNumber: '',
    })
    const [bankCode, setBankCode] = useState('')
    const [accountName, setAccountName] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [toastError, setToastError] = useState(false)

    const handleInputChange = event => {
        const { name, value } = event.target
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleOnBlur = event => {
        const { name, value } = event.target
        setInputs(preState => ({
            ...preState,
            [name]: value.trim(),
        }))
    }

    const handleSelectChange = (name, value) => {
        if (name === 'bankName') {
            const bankCode = bankList.find(bank => bank.name === value).code
            if (bankCode) setBankCode(bankCode)
        }

        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }
        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const nextStageHandler = () => {
        setErrors({})

        NubanAccountDetailsValidationSchema.validate(inputs, {
            abortEarly: false,
        })
            .then(() => {
                setErrors('')
                setErrorMessage('')
                verifyAccountNumberRefetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const handleSubmit = () => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['onboardingNubanAccountDetails'].action,
            eventTrackers['onboardingNubanAccountDetails'].label,
            eventTrackers['onboardingNubanAccountDetails'].category,
            ['MP', 'GA'],
            'event',
            {
                event: {
                    bank_account: inputs.accountNumber,
                    bank_name: inputs?.bankName,
                    bank_account_name: accountName?.account_name,
                    bank_code: bankCode,
                },
            },
        )
        bankAccountDetailsRefetch()
    }

    const { refetch } = useQuery('getAllBanksApi', () => getAllBanksApi(), {
        enabled: false,
        retry: false,
        onSuccess: data => {
            setbankList(data?.data?.data)
        },
    })

    const {
        refetch: verifyAccountNumberRefetch,
        data: accountNameData,
        isLoading: accountNameLoading,
    } = useQuery(
        'verifyAccountNumberApi',
        () => verifyAccountNumberApi(inputs.accountNumber, bankCode),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setAccountName(data?.data?.data)
                setToastError(false)
            },
            onError: error => {
                setErrorMessage(errorHandler(error))
                setToastError(true)
            },
        },
    )

    const {
        refetch: bankAccountDetailsRefetch,
        isLoading: bankAccountDetailsLoading,
    } = useQuery(
        'bankAccountDetailsApi',
        () =>
            bankAccountDetailsApi({
                bank_account: inputs.accountNumber,
                bank_name: inputs?.bankName,
                bank_account_name: accountName?.account_name,
                bank_code: bankCode,
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                page === 'profilePage'
                    ? profilePageNextHandler()
                    : nextHandler()
            },
            onError: () => {
                setErrorMessage(
                    'Failed to save your Information. Please try again',
                )
                setToastError(true)
            },
        },
    )

    useEffect(() => {
        refetch()
    }, [])

    const bankAccountFound =
        Object.keys(accountNameData?.data?.data || {}).length > 0

    return (
        <div>
            <FormTitleBar
                title="NUBAN Account Details"
                subtitle="Fill your account details below"
            />
            {toastError && (
                <Toast
                    messageType="error"
                    message={accountNameLoading ? '' : errorMessage[0]}
                />
            )}

            <div className={InputBox}>
                <SelectField
                    name="bankName"
                    initialOption="Bank Name"
                    selectWidth="100%"
                    value={inputs?.bankName}
                    handleChange={handleSelectChange}
                    selectedValue={inputs?.bankName}
                    errorMessage={errors?.bankName}
                    currentSelected={inputs?.bankName}
                    values={bankList.map(bank => ({
                        value: bank.name,
                    }))}
                    withCheckBox={true}
                    showSearch
                    dropdownPositionRelative
                />
            </div>
            <div className={InputBox}>
                <InputFields
                    title="Account Number"
                    inputWidth="100%"
                    name="accountNumber"
                    handleChange={handleInputChange}
                    errorMessage={errors?.accountNumber}
                    value={inputs.accountNumber}
                    onBlur={handleOnBlur}
                />
            </div>
            {bankAccountFound && (
                <div className={InputBox}>
                    <NoFLoatingLabelInputFields
                        inputWidth="100%"
                        inputBackground="#F8F8F8"
                        value={accountName?.account_name}
                        disabled={true}
                    />
                </div>
            )}

            {page === 'profilePage' ? (
                <div className={ProfilePageButton}>
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnWidth="160px"
                        btnHeight="56px"
                        handleClick={
                            bankAccountFound ? handleSubmit : nextStageHandler
                        }
                    >
                        {accountNameLoading || bankAccountDetailsLoading ? (
                            <InlineLoader />
                        ) : bankAccountFound ? (
                            'Add Bank'
                        ) : (
                            'Confirm Account'
                        )}
                    </Button>
                </div>
            ) : (
                <>
                    <div className={closeFormText} onClick={handleFormClose}>
                        <p>I’ll Do This Later</p>
                    </div>

                    <ActionSection
                        stage={depType === 'ai' ? 4 : 3}
                        loading={
                            bankAccountDetailsLoading || accountNameLoading
                        }
                        nextHandler={
                            bankAccountFound ? handleSubmit : nextStageHandler
                        }
                        prevHandler={prevHandler}
                        btnText={
                            bankAccountFound ? 'Continue' : 'Confirm Account'
                        }
                        margin="29px 0px 20px"
                    />
                </>
            )}
        </div>
    )
}

export default NubanAccountDetails
