/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import FormBox from '../../components/FormBox'
import { InputFields } from '../../components/InputFields'
import FormTitleBar from '../../components/FormTitleBar'
import Checkbox from '../../components/Checkbox'
import Toast from '../../components/Toast'
import Button from '../../components/Button'
import { InlineLoader } from '../../components/Loader'
import ConfirmationScreen from '../../components/Confirmation'
import successImage from '../../assets/images/successImage.svg'

import styles from './resetpassword.module.scss'

import { resetPasswordFinalizeApi } from '../../api/reset'

const ResetPassword = ({ showTextHandler }) => {
    const { CheckboxWrapper, ButtonWrapper, CheckboxText, CheckboxContent } =
        styles
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [successPasswordReset, setSuccessPasswordReset] = useState(false)
    const [validationRules, setValidationRules] = useState({
        length: false,
        containANumber: false,
        casing: false,
    })
    const [errors, setErrors] = useState({})

    const handleChange = e => setPassword(e.target.value)
    const handleConfirmPassword = e => setConfirmPassword(e.target.value)

    const handleClick = () => {
        setErrors({})
        if (password !== confirmPassword) {
            setErrors({
                confirmPassword: 'Password do not match',
            })
        } else {
            refetch()
        }
    }

    const history = useHistory()

    const onButtonClick = () => {
        history.push('/login')
    }
    const checkPasswordLength = () => {
        if (password.length > 7) {
            setValidationRules(preState => ({
                ...preState,
                length: true,
            }))
        } else {
            setValidationRules(preState => ({
                ...preState,
                length: false,
            }))
        }
    }

    const checkCasing = () => {
        const expectedCasing = /(?=.*[a-z])(?=.*[A-Z])\w+/.test(password)
        if (expectedCasing) {
            setValidationRules(preState => ({
                ...preState,
                casing: true,
            }))
        } else {
            setValidationRules(preState => ({
                ...preState,
                casing: false,
            }))
        }
    }

    const checkNumber = () => {
        const value = /\d/.test(password)
        if (value) {
            setValidationRules(preState => ({
                ...preState,
                containANumber: true,
            }))
        } else {
            setValidationRules(preState => ({
                ...preState,
                containANumber: false,
            }))
        }
    }

    const { isLoading, data, refetch, error } = useQuery(
        'resetFinalize',
        () =>
            resetPasswordFinalizeApi(
                history?.location?.state?.verificationType,
                history?.location?.state?.verificationType === 'email'
                    ? {
                          email: history?.location?.state?.email,
                          reset_token: history?.location?.state?.resetToken,
                          new_password: password,
                      }
                    : {
                          phone_number: history?.location?.state?.phoneNumber,
                          reset_token: history?.location?.state?.resetToken,
                          new_password: password,
                      },
            ),
        {
            enabled: false,
            retry: false,
        },
    )

    useEffect(() => {
        checkPasswordLength()
        checkCasing()
        checkNumber()

        // eslint-disable-next-line
    }, [password])

    useEffect(() => {
        if (history?.location?.state?.page !== 'resetVerify') {
            history.push('/login')
        }

        // eslint-disable-next-line
    }, [history])

    useEffect(() => {
        if (data) {
            showTextHandler()
            setSuccessPasswordReset(true)
        }

        // eslint-disable-next-line
    }, [data])

    let errorMessage

    if (error && !isLoading) {
        const errObj = error?.response?.data?.error
        if (errObj) {
            errorMessage = Object.keys(errObj)?.map(key => {
                return errObj[key][0]
            })
        }
    }

    return (
        <div>
            {successPasswordReset ? (
                <ConfirmationScreen
                    imgUrl={successImage}
                    header="Password Reset Successful"
                    subHeader="We’ve solved the problems that make it difficult for energy providers to transition"
                    btnText="Proceed to Sign In"
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    onButtonClick={onButtonClick}
                    headerMarginBottom="30px"
                />
            ) : (
                <FormBox>
                    <FormTitleBar
                        title="Reset Password"
                        subtitle="Enter your email address or number"
                    />
                    {errorMessage && (
                        <Toast message={errorMessage[0]} messageType="error" />
                    )}
                    <InputFields
                        name="choose-password"
                        type="password"
                        title="Choose a password"
                        handleChange={handleChange}
                        inputValue={password}
                    />
                    <div className={CheckboxWrapper}>
                        <Checkbox
                            type="validation"
                            className={CheckboxContent}
                            validate={validationRules.length}
                        >
                            <p className={CheckboxText}>
                                Contains atleast 8 Characters
                            </p>
                        </Checkbox>
                        <Checkbox
                            type="validation"
                            className={CheckboxContent}
                            validate={validationRules.casing}
                        >
                            <p className={CheckboxText}>
                                Contains a mixture of UPPERCASE and lowercase
                            </p>
                        </Checkbox>
                        <Checkbox
                            type="validation"
                            className={CheckboxContent}
                            validate={validationRules.containANumber}
                        >
                            <p className={CheckboxText}>
                                Contains at least a number
                            </p>
                        </Checkbox>
                    </div>
                    <InputFields
                        type="password"
                        name="confirm-password"
                        title="Confirm password"
                        handleChange={handleConfirmPassword}
                        inputValue={confirmPassword}
                        errorMessage={errors.confirmPassword}
                    />
                    <div className={ButtonWrapper}>
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="#fff"
                            handleClick={handleClick}
                            disabled={
                                validationRules.length === false ||
                                validationRules.containANumber === false ||
                                validationRules.casing === false ||
                                password === '' ||
                                confirmPassword === '' ||
                                isLoading === true
                                    ? true
                                    : false
                            }
                        >
                            {isLoading ? <InlineLoader /> : 'Reset Password'}
                        </Button>
                    </div>
                </FormBox>
            )}
        </div>
    )
}

export default ResetPassword
