import PropTypes from 'prop-types'
import Label from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/_components/Label'
import s from '../styles.module.scss'
import { InputFields, SelectField } from 'src/components/InputFields'
import { meansOfIdentityV2 } from 'src/utils/Data/meansOfIdentity'

const IdInfo = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    handleSelectChange,
    handleSelectOnBlur,
}) => {
    return (
        <div className={s.wrapper}>
            <Label
                title="Member ID card"
                subTitle="Add member ID card for verification"
                titleFontFamily="var(--font-regular)"
            />
            <SelectField
                name={'idCardType'}
                value={inputs?.idCardType}
                errorMessage={errors?.idCardType}
                floatLabelOnSelectedValue
                floatingLabel={'ID card type'}
                initialOption={'ID card type'}
                selectWidth="100%"
                handleChange={handleSelectChange}
                onBlur={() => handleSelectOnBlur('idCardType')}
                dropdownPositionRelative
                withCheckBox
                values={meansOfIdentityV2.map(option => ({
                    value: option,
                }))}
                currentSelected={inputs?.idCardType}
                selectedValue={inputs?.idCardType}
                marginBottom={'16px'}
            />
            <InputFields
                title={'ID card number'}
                name={'idCardNo'}
                value={inputs?.idCardNo}
                errorMessage={errors?.idCardNo}
                handleChange={handleChange}
                onBlur={handleOnBlur}
            />
        </div>
    )
}

IdInfo.propTypes = {
    inputs: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleOnBlur: PropTypes.func,
    handleSelectChange: PropTypes.func,
    handleSelectOnBlur: PropTypes.func,
}

export default IdInfo
