import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Pagination } from 'antd'
import { getProfilePackagesApi } from 'src/api/admin/profiles/details'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const useGetProfilePackages = () => {
    const [page, setPage] = useState(1)
    const { id } = useParams()
    const { errorAlert } = useCustomToast()

    const updatePage = page => {
        setPage(page)
    }

    const { isFetching, isLoading, isRefetching, data, refetch } = useQuery({
        queryKey: ['fetch-admin-profile-package', page],
        queryFn: () => getProfilePackagesApi(id, page),
        retry: false,
        enabled: !['private', 'public'].includes(id) ? true : false,
        refetchOnWindowFocus: false,
        refetchOnMount: !['private', 'public'].includes(id) ? true : false,
        onError: error => {
            errorAlert(
                errorHandler(error.response?.data) ||
                    `Couldn't fetch profile packages. please try again`,
            )
        },
    })

    const paginationBlock = () => {
        return (
            <Pagination
                defaultCurrent={1}
                current={page}
                total={parseInt(data?.data?.total_pages, 10)}
                defaultPageSize={1}
                showSizeChanger={false}
                showTitle={false}
                showLessItems
                onChange={updatePage}
                disabled={isFetching || isLoading || isRefetching}
            />
        )
    }

    return {
        profilePackages: data?.data,
        profilePackagesFetching: isFetching || isLoading || isRefetching,
        paginationBlock,
        refetchProfilePackages: refetch,
        setPage,
    }
}

export default useGetProfilePackages
