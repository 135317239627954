/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useQuery } from 'react-query'
import { resetPasswordValidateApi } from '../../../api/reset'
import Confirmation from '../../../components/Confirmation'
import errorImage from '../../../assets/images/deletemodal-icon.svg'
import successImage from '../../../assets/images/successImage.svg'

const Verify = props => {
    const history = useHistory()
    const parsedValues = queryString.parse(props?.location?.search)
    const splitParams = props?.location?.search?.split('&')
    const email = splitParams[0]?.split('=')[1]

    const [verified, setVerified] = useState(false)
    const [width, setwidth] = useState(50)

    useEffect(() => {
        const handleSlider = () => {
            if (verified && width < 100) {
                setwidth(prev => prev + 50)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 2000)
        return () => clearTimeout(sliderWidth)
    }, [width, verified])

    const { data, refetch, error } = useQuery(
        'resetValidation',
        () =>
            resetPasswordValidateApi('email', {
                email,
                reset_token: parsedValues?.token,
                reset_code: parseInt(parsedValues?.code, 10),
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => setVerified(true),
        },
    )

    useEffect(() => {
        refetch()
    }, [refetch])

    useEffect(() => {
        if (verified && width === 100) {
            history.push({
                pathname: '/reset-password',
                state: {
                    page: 'resetVerify',
                    resetToken: data?.data?.data?.reset_token,
                    resetCode: parseInt(parsedValues?.code, 10),
                    verificationType: 'email',
                    email,
                },
            })
        }
        // eslint-disable-next-line
    }, [verified, width])

    const onButtonClick = () => {
        history.push('/forgot-password')
    }

    return (
        <>
            {verified ? (
                <Confirmation
                    imgUrl={successImage}
                    header="Verification Successful"
                    subHeader="We’ve solved the problems that make it difficult for energy providers to transition"
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    scrollIndicator
                    scrollWidth={width}
                />
            ) : (
                error && (
                    <Confirmation
                        imgUrl={errorImage}
                        header="Verification Unsuccessful"
                        subHeader="We’ve solved the problems that make it difficult for energy providers to transition"
                        subHeaderStyle="48px"
                        boxPadding="45px"
                        onButtonClick={onButtonClick}
                        btnText="Retry"
                    />
                )
            )}
        </>
    )
}

export default Verify
