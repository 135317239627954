import { getComponentBrandsApi } from 'src/api/profile'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'

const useGetComponentBrands = (enabled = true) => {
    const [componentTypes, setComponentTypes] = useState([])
    const [componentTypesInReview, setComponentTypesInReview] = useState([])
    const [componentBrands, setComponentBrands] = useState([])
    const [componentBrandsInReview, setComponentBrandsInReview] = useState([])
    const [componentTypeMap, setComponentTypeMap] = useState({})
    const [approvedBrandsIdMap, setApprovedBrandsIdMap] = useState({})
    const { errorAlert } = useCustomToast()

    const { isFetching: componentBrandsFetching, status } = useQuery({
        queryKey: 'component-brands',
        queryFn: () => getComponentBrandsApi(),
        retry: false,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        enabled: enabled,
        onSuccess: res => {
            const arr = res?.data?.data
            const componentBrands = {}
            const componentBrandsInReview = {}
            const newApprovedBrandsIdMap = {}
            const componentTypesSet = new Set()
            const componentTypesInReviewSet = new Set()
            const typeMap = {}

            arr?.forEach(component => {
                const componentTypeName = component?.component_type?.name
                const componentTypeId = component?.component_type?.id
                const brands = component?.brands

                componentTypesSet.add(componentTypeName)
                typeMap[componentTypeName] = componentTypeId
                newApprovedBrandsIdMap[componentTypeName] =
                    newApprovedBrandsIdMap[componentTypeName] || {}

                let brandsList = []
                let brandsInReviewList = []

                brands?.forEach(brand => {
                    if (brand.status === 'PENDING') {
                        brandsInReviewList.push(brand.name)
                        componentTypesInReviewSet.add(componentTypeName)
                    } else if (brand.status === 'APPROVED') {
                        brandsList.push(brand.name)
                        newApprovedBrandsIdMap[componentTypeName][brand.name] =
                            brand.id
                    }
                })

                if (brandsList.length > 0) {
                    componentBrands[componentTypeName] = brandsList
                }
                if (brandsInReviewList.length > 0) {
                    componentBrandsInReview[componentTypeName] = (
                        componentBrandsInReview[componentTypeName] || []
                    ).concat(brandsInReviewList)
                }
            })

            setComponentBrands(componentBrands)
            setComponentBrandsInReview(componentBrandsInReview)
            setComponentTypes(Array.from(componentTypesSet))
            setComponentTypesInReview(Array.from(componentTypesInReviewSet))
            setComponentTypeMap(typeMap)
            setApprovedBrandsIdMap(newApprovedBrandsIdMap)
        },
        onError: error => {
            errorAlert(
                errorHandler(error.response?.data) ||
                    `Couldn't retrieve component brands. please try again`,
            )
        },
    })

    return {
        componentTypes,
        componentTypesInReview,
        componentBrands,
        componentBrandsInReview,
        componentTypeMap,
        componentBrandsFetching,
        getComponentBrandsSuccess: status === 'success',
        approvedBrandsIdMap,
    }
}

export default useGetComponentBrands
