import PropTypes from 'prop-types'
import { AppButton } from 'src/components/Button'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import ClusterMinimumDeposit from './ClusterMinimumDeposit'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import { useState } from 'react'

const SingleMobileCluster = ({ data }) => {
    const { isMobile } = useMediaQueries()
    const [showMinimumDepositModal, setShowMinimumDepositModal] =
        useState(false)
    const [clickedClusterCode, setClickedClusterCode] = useState('')

    return (
        <>
            <SecondaryModal
                showModal={showMinimumDepositModal}
                modalHeight="487px"
                modalWidth="469px"
                noPadding={isMobile ? '16px' : '40px'}
                onCancel={() => setShowMinimumDepositModal(false)}
                content={
                    <ClusterMinimumDeposit clusterCode={clickedClusterCode} />
                }
            />

            <div className="ACLMobileTable">
                <div className="ACLMobileTableSection">
                    <div>
                        <h4 className="ACLMobileTableHeader">CLUSTER NAME</h4>
                        <p
                            className="ACLMobileText Name"
                            style={{
                                fontSize: data?.cluster_info?.name && '12px',
                            }}
                        >
                            {data?.cluster_info?.name}
                        </p>
                    </div>
                    <div>
                        <h4 className="ACLMobileTableHeader">CLUSTER CODE</h4>
                        <p className="ACLMobileText">
                            {data?.cluster_info?.code}
                        </p>
                    </div>
                </div>
                <div className="ACLMobileTableSection">
                    <AppButton
                        btnBgColor="#E2EEFF"
                        btnTextColor="#004AAD"
                        btnWidth="70px"
                        btnHeight="37px"
                        fontSize="12px"
                        fontFamily="SF-Pro-Display-Medium"
                        handleClick={() => {
                            setClickedClusterCode(data?.cluster_info?.code)
                            setShowMinimumDepositModal(true)
                        }}
                    >
                        View
                    </AppButton>
                    <div>
                        <h4
                            className="ACLMobileTableHeader"
                            style={{ textAlign: 'right' }}
                        >
                            PACKAGES
                        </h4>
                        <p className="ACLMobileText Right">
                            {data?.cluster_info?.packages?.length}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

SingleMobileCluster.propTypes = {
    data: PropTypes.object.isRequired,
}

export default SingleMobileCluster
