import { permissionsControl } from 'src/utils/permissionsControl'

export const canUpdateEnergyProfile = permissionsControl([
    'can_update_energy_profile',
])
export const canDeleteEnergyProfile = permissionsControl([
    'can_delete_energy_profile',
])
export const canDuplicateEnergyProfile = permissionsControl([
    'can_duplicate_energy_profile',
])
export const canModifyEnergyProfilePackage = permissionsControl([
    'can_modify_energy_profile_package',
])
