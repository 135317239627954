export const actionModalValues = (action, componentType = '') => {
    switch (action) {
        case 'add-brands':
            return {
                actionModalHeaderText: `You have successfully submitted
brands for your ${componentType?.toLowerCase()} component`,
                actionModalSubtitle:
                    'These brands you’ve submitted will be reviewed by our team and you’ll get an email on the result of the review',
                actionModalActionType: 'success',
                actionModalShowCancelBtn: false,
                actionModalActionText: 'Done',
            }
        case 'edit-brands':
            return {
                actionModalHeaderText: `You have successfully edited
brands for your ${componentType?.toLowerCase()} component`,
                actionModalSubtitle: `Your brands for the ${componentType?.toLowerCase()} component 
have been updated`,
                actionModalActionType: 'success',
                actionModalShowCancelBtn: false,
                actionModalActionText: 'Done',
            }
        default:
            return {
                actionModalHeaderText: '',
                actionModalSubtitle: '',
                actionModalActionType: '',
                actionModalShowCancelBtn: true,
                actionModalActionText: '',
            }
    }
}
