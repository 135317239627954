import { truncateSentence } from 'src/utils/formatting'
import s from '../styles.module.scss'
import PropTypes from 'prop-types'

const ShowCSVFile = ({ handleSampleCSVdownload, sampleCSVFile }) => {
    return (
        <div className={s.wrapper}>
            <p className={s.title}>You need to upload a CSV file</p>
            <p className={s.subtitle}>
                Ready to upload multiple members? Just pop their data into a CSV
                file and upload it. For a smooth ride, grab our CSV template
                with all the requirements!
            </p>
            <div className={s.howToContainer}>
                <p className={s.howToTitle}>How to use THE CSV file</p>
                <ul>
                    <li>
                        <span>1</span>Download the added file below
                    </li>
                    <li>
                        <span>2</span>
                        Fill the file with the required details as listed in
                        each row
                    </li>
                    <li>
                        <span>3</span>
                        Upload the file back once you’re done
                    </li>
                </ul>

                <div className={s.downloadFileContainer}>
                    <div className={s.file}>
                        <div className={s.icon}></div>
                        <div>
                            <p className={s.fileName}>
                                {sampleCSVFile?.file_name &&
                                    truncateSentence(
                                        sampleCSVFile?.file_name,
                                        40,
                                    )}
                            </p>
                            <p className={s.fileSize}>473bytes</p>
                        </div>
                    </div>
                    <button
                        className={s.downloadFile}
                        onClick={handleSampleCSVdownload}
                        disabled={!sampleCSVFile}
                    >
                        Download file
                    </button>
                </div>
            </div>
        </div>
    )
}

ShowCSVFile.propTypes = {
    handleSampleCSVdownload: PropTypes.func,
    sampleCSVFile: PropTypes.object,
}

export default ShowCSVFile
