export const faq3 = [
    {
        title: 'What is SunFi?',
        message:
            'SunFi is an energy financial technology platform that connects people who want solar to payment plans that match their needs From payment plans to installation support and after-care services, we’ve got you covered.',
        id: 1,
    },
    {
        title: 'Do I have to pay any interest?',
        message:
            'There are no hidden charges when you request a solution from SunFi. We however charge a small fee for late repayments.',
        id: 2,
    },
    {
        title: 'What will be my initial payment?',
        message:
            'Yes, you will be charged a small part of the total system cost and you will be able to spread the rest across a 6 - 24 month period.',
        id: 3,
    },
    {
        title: 'What solar system sizes are available?',
        message: 'We offer 1.5kVA, 3kVA, 5kVA, 10kVA, and 25kVA systems.',
        id: 4,
    },
    {
        title: 'How do I apply?',
        message: `You can apply by filling out the <a href='/consumer/get-started'
                 style="color:#0066f5">Get Your Offer</a> form, providing all the required details.`,
        id: 5,
    },
]

export const faq4 = [
    {
        title: 'Do I need to be a salary earner?',
        message:
            'No, but you have to share proof of a verifiable steady income source to get an offer.',
        id: 7,
    },
    {
        title: ' Is SunFi available everywhere in Nigeria?',
        message:
            "SunFi currently operates in Lagos but is capable of expanding to other Nigerian cities. Fill the 'Get Offer' form with the preferred location and we'll work to get you a solution.",
        id: 8,
    },
    {
        title: 'How can I contact the SunFi team?',
        message:
            'You can send us a message on Facebook, Twitter and IG @sunfi_hq or send an email to <a href="mailto:hello@sunfi.co" style="color:#0066f5">hello@sunfi.co</a>',
        id: 9,
    },
    {
        title: 'Are my financial details safe?',
        message:
            ' Yes, your security is at the heart of everything we do - details shared with us will be managed appropriately and safely.',
        id: 10,
    },
    {
        title: 'What is the environmental impact of using SunFi?',
        message:
            'It varies, but our average consumer will eliminate more than 17,000 pounds of CO2 annually.',
        id: 11,
    },
]

export const faq3b = [
    {
        title: 'How do I become an Associate Installer?',
        message:
            'Go to <a href="/" target="_blank" style="color:#0066f5">www.sunfi.co</a>, select "Partner," and then scroll down to "Become a SunFi Associate Installer." Select "partner as an installer". then register for an account to get started.',
        id: 1,
    },
    {
        title: 'How do I become a Distributor Installer?',
        message:
            'Go to <a href="/" target="_blank" style="color:#0066f5">www.sunfi.co</a>, select "Partner," and then click on "Become a partner." Then register for an account to get started.',
        id: 2,
    },
    {
        title: 'How do I become a Manufacturer Installer?',
        message:
            'You can partner as an OEMI if you either manufacture your product or white label them. Click here to <a href="/signup" target="_blank" style="color:#0066f5">sign up</a>',
        id: 3,
    },
    {
        title: 'Will I pay a monthly subscription fee?',
        message:
            'There are no subscription fees. However, customers are expected to make their monthly payment as at when due.',
        id: 4,
    },
]

export const faq4b = [
    {
        title: 'How much will be charged for a transaction?',
        message:
            'There are no fees. However, each financed transaction is subject to a margin.',
        id: 5,
    },
    {
        title: 'What cities does SunFi operate in?',
        message:
            'We are currently based in Lagos, but we support businesses and individuals across the country.',
        id: 6,
    },
    {
        title: 'What systems can I list in my workspace?',
        message: 'All systems or tools required for solar system installation',
        id: 7,
    },
    {
        title: 'Is there a financing limit?',
        message: 'System financing can start from 200k to 20 million.',
        id: 8,
    },
]

export const faq3c = [
    {
        title: 'What is SunFi?',
        message: `SunFi is an energy financing technology company that connects people who want to buy solar through a financing method that allows for customized repayment plans to ensure you do not strain your financial budget. We also provide and recommend reliable solar solutions that meet your needs and offer installation support and after-care services for your solar systems.
            `,
        id: 1,
    },
    {
        title: 'Do I have to pay any interest?',
        message: 'We charge margins on the plans we finance.',
        id: 2,
    },
    {
        title: 'What will be my initial payment?',
        message:
            'You will be required to make an upfront payment of 30-35% of the total system cost, and you will be able to spread the rest across a 6 - 36-month period, depending on the size of the system.',
        id: 3,
    },
    {
        title: 'What solar system sizes are available?',
        message:
            'We offer 0.5kva, 0.85kva, 1kva, 1.5kVA, 3kVA, 5kVA, 10kVA, and 25kVA systems.',
        id: 4,
    },
    {
        title: 'How do I apply?',
        message: `You can apply by simply <a href="javascript: (function() {
            const targetElement = document.getElementById('landing-page-form');
         if (targetElement) {
                const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: elementPosition - 150,
                    behavior: 'smooth'
                });
        } })();" 
        style="color:#004AAD; border-bottom: 1px solid #004AAD;">selecting what you need</a> the solar system for to view all available options we have for you.`,
        id: 5,
    },
    {
        title: 'Will I pay for solar delivery?',
        message:
            'Depending on your location, you may be required to pay an extra fee for logistics.',
        id: 6,
    },
    // TODO: This can be uncommented when faq4c has been updated with ten different content
    // {
    //     title: 'Do I need to be a salary earner?',
    //     message:
    //         'No, but you have to share proof of a verifiable steady income source to get an offer.',
    //     id: 6,
    // },
    // {
    //     title: 'How can I contact the SunFi team?',
    //     message: `You can send us a message on Facebook <a href='https://web.facebook.com/sunfihq'
    //     style="color:#0066f5" target="_blank">@SunFi Tech</a>, X (formerly known as Twitter) <a href='https://twitter.com/sunfi_hq'
    //     style="color:#0066f5" target="_blank">@sunfi_hq</a> and IG <a href='https://www.instagram.com/sunfihq/'
    //     style="color:#0066f5" target="_blank">@sunfihq</a> or email <a href='mailto:hello@sunfi.co' style="color:#0066f5">hello@sunfi.co</a>`,
    //     id: 7,
    // },
    // {
    //     title: 'Are my financial details safe?',
    //     message:
    //         'Yes, your security is at the heart of everything we do - details shared with us will be managed appropriately and safely. We leverage the latest technology to safeguard the information provided by our customers.',
    //     id: 8,
    // },
    // {
    //     title: 'Is SunFi available everywhere in Nigeria?',
    //     message:
    //         "SunFi currently operates in Lagos but is capable of expanding to other Nigerian cities. Fill the 'Get Offer' form with the preferred location and we'll work to get you a solution.",
    //     id: 9,
    // },
    // {
    //     title: 'What is your address and phone number to reach you on?',
    //     message:
    //         '9a Joseph Adu Close, Oniru, Victoria Island, Lagos. <a href="tel:08022426861">08022426861</a>, <a href="tel:07080639920">07080639920</a>',
    //     id: 10,
    // },
]

export const faq4c = [
    {
        title: 'Is there a limit to the capacity/amount of solar systems SunFi can finance?',
        message:
            "At the moment, there isn't a limit to the capacity of the system SunFi can finance. We are happy to look into your project and talk about the financing.",
        id: 11,
    },
    {
        title: 'What happens if the customer defaults?',
        message:
            'The customer is contacted to understand the reason behind the default; if it’s a technical reason, a three-way conversation involving the provider is set up to rectify the challenge.',
        id: 12,
    },
    {
        title: 'What happens if there’s a system issue within the repayment period?',
        message:
            'A conversation involving the provider, the client and SunFi’s User services Team is initiated to understand the problem and to resolve the customer’s issue.',
        id: 13,
    },
    {
        title: 'How do I apply to be a partner?',
        message:
            'You can apply by visiting <a href="https://www.sunfi.co/partners" style="color:#004AAD; border-bottom: 1px solid #004AAD">sunfi.co/partners</a> and clicking the “Become a Partner” button.',
        id: 14,
    },
    {
        title: 'How long does the whole process take?',
        message:
            'The entire process takes 3 to 5 working days (within Lagos) once the upfront deposit payment is confirmed, while other states outside Lagos can take longer depending on the location.',
        id: 15,
    },
    {
        title: 'Are there any fees associated with becoming a partner?',
        message: 'No, we do not request any fee to become our partner.',
        id: 16,
    },
]

export const faq5 = [
    {
        title: 'What is SunFi?',
        message:
            'SunFi is an energy financing technology company that connects people who want to buy solar through a financing method that allows for customized repayment plans to ensure you do not strain your financial budget.<br /> <br /> We also provide and recommend reliable solar solutions that meet your needs and offer installation support and after-care services for your solar systems.',
        id: 1,
    },
    {
        title: 'What is the onboarding process like?',
        message:
            'Our process includes system selection, KYC completion, conducting a site inspection, confirmation of approval for financing from us, paying of upfront deposit, system installation, setting up the repayment process, and a lifecycle customer support service. ',
        id: 2,
    },
    {
        title: 'What do I need to complete my KYC?',
        message:
            'You are to provide your photo, valid ID, BVN, and six-month bank statement.',
        id: 3,
    },

    {
        title: 'How long will it take to have my system installed?',
        message: `The entire process takes 3 to 5 working days (within Lagos) once the upfront deposit payment is confirmed, while other states outside Lagos can take longer depending on the location. `,
        id: 4,
    },

    {
        title: 'Who do I call when there is an issue?',
        message: `You can contact us at <a href="mailto:help@sunfi.co" style="color:#0066f5">help@sunfi.co</a>or at <a href="tel:02013306111">02013306111</a>. Where necessary, we will activate our manufacturing partners to address the system issues.`,
        id: 5,
    },
    {
        title: 'Can I pay in installments?',
        message:
            'Yes, you can pay in installments. We have a plan that allows you to spread your payments over a period of time, making it easier and more convenient for you.',
        id: 6,
    },
    {
        title: 'Can I opt-out at any time?',
        message:
            'If you are on our solar-as-a-service membership plan, you can terminate the plan after 12 months, however termination fees will apply.',
        id: 7,
    },

    {
        title: 'Will my system work well during the rainy season?',
        message: `Yes, your system will still work during the rainy season. Solar panels are designed to generate power even when sunlight is limited. Although they may produce less energy when compared to sunny days.        `,
        id: 8,
    },

    {
        title: 'Are there any hidden charges?',
        message: `No, aside from payment for delivery of your solar system depending on your location where you may be required to pay an extra fee for logistics, we do not have any hidden charges.`,
        id: 9,
    },
]
