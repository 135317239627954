export const adminActionSaveModalInitialData = {
    modalType: '',
    title: '',
    actionType: 'warning',
    heading: '',
    subHeading: '',
    leftContent: 'chat',
    leftButtonText: '',
    rightButtonText: '',
}

export const nameMismatchConsumerActionModalData = {
    modalType: 'name-mismatch',
    title: 'Getting started on SunFi',
    actionType: 'warning',
    heading: 'Verification error',
    subHeading:
        "The name you provided doesn't match the name on your uploaded bank statement. Please ensure both names are identical to proceed with your submission.",
    leftContent: 'chat',
    leftButtonText: '',
    rightButtonText: 'Okay, got it!',
}
