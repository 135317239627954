import { useEffect, useState } from 'react'
import { currencyFormatter } from 'src/utils/currencyFormatter'

const useMargin = count => {
    const [margin, setMargin] = useState({
        action: '',
        amount: '',
        percentage: '',
        isExisting: false,
    })
    const [subtotal, setSubtotal] = useState(0)
    const [marginUnit, setMarginUnit] = useState(0)
    const [total, setTotal] = useState(0)

    const handleMargin = (amount, percent) => {
        setMarginUnit(percent)
    }

    const getMargin = (action, amount, percentage, isExisting) => {
        const formatAmount = amount.toString().replaceAll(',', '')
        setMargin({ action, amount, percentage, isExisting })
        if (action === 'Add Margin') {
            let Total = currencyFormatter(subtotal + parseFloat(formatAmount))
            handleMargin(amount, percentage)
            setTotal(Total)
        } else if (action === 'Subtract Margin') {
            let Total = currencyFormatter(subtotal - parseFloat(formatAmount))
            handleMargin(-amount, -percentage)
            setTotal(Total)
        } else {
            setTotal(subtotal)
        }
    }

    const handleRemoveMargin = () => {
        setMargin({ action: '', amount: '', percentage: '', isExisting: false })
        handleMargin(0, 0)
        setMarginUnit(0)
        setTotal(subtotal)
    }

    useEffect(() => {
        let calculateSubtotal = count?.reduce(
            (total, item) =>
                total + (item?.realCost ?? item?.cost) * item?.count,
            0,
        )
        setSubtotal(calculateSubtotal)
        const packageMarginPercentage =
            margin && margin.percentage ? parseFloat(margin.percentage) : 0
        if (
            packageMarginPercentage !== 0 &&
            calculateSubtotal !== 0 &&
            subtotal !== 0
        ) {
            const packageMarginAmount = (
                (packageMarginPercentage / 100) *
                parseFloat(calculateSubtotal)
            ).toFixed(2)
            const marginDescription =
                margin.action === 'Subtract Margin'
                    ? 'Subtract Margin'
                    : 'Add Margin'
            getMargin(
                marginDescription,
                packageMarginAmount || '',
                packageMarginPercentage || '',
                margin.percentage === '' ? false : true,
            )
        }
        // eslint-disable-next-line
    }, [count, subtotal])

    return {
        margin,
        setMargin,
        total,
        subtotal,
        handleRemoveMargin,
        getMargin,
        marginUnit,
    }
}

export default useMargin
