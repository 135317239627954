import axios from 'src/config/axios'

export const getSingleComponentApi = async (id, isAdminWorkspace = false) => {
    const request = await axios.get(
        `/${isAdminWorkspace ? 'admin' : 'products'}/components/${id}`,
    )
    return request
}

export const deleteSingleComponentApi = async (
    id,
    isAdminWorkspace = false,
) => {
    const request = await axios.delete(
        `/${isAdminWorkspace ? 'admin' : 'products'}/components/${id}`,
    )
    return request
}

export const getComponentTypeApi = async (isAdminWorkspace = false) => {
    const request = await axios.get(
        `/${
            isAdminWorkspace ? 'admin' : 'products'
        }/component-types?paginate=false&all_workspaces=false`,
    )
    return request
}

export const searchImagesApi = async (name, type) => {
    const componentName = name.trim()
    const componentType = type.trim()
    const request = await axios.get(
        `/products/components/search-images?component_name=${componentName}&component_type=${componentType}`,
    )
    return request
}

export const createComponentApi = async (inputs, isAdminWorkspace = false) => {
    let formData = new FormData()
    formData.append('name', inputs.name)
    formData.append('description', inputs.description)
    formData.append('cost', inputs.cost)
    formData.append('attributes', inputs.attributes)
    formData.append('is_global', inputs.is_global)

    if (inputs.brand_id !== '') {
        formData.append('brand_id', inputs.brand_id)
    }

    if (inputs.type_id !== '') {
        formData.append('type_id', inputs.type_id)
    }

    if (inputs.type_id === '') {
        formData.append('type_name', inputs.componentType)
    }

    if (inputs.suggested_image_url !== '') {
        formData.append('suggested_image_url', inputs.suggested_image_url)
    }

    if (inputs.image) {
        formData.append('image', inputs.image)
    }

    if (isAdminWorkspace) {
        formData.append('provider_id', inputs.providerId)
    }

    if (isAdminWorkspace && inputs?.warranty !== '') {
        formData.append('warranty', parseInt(inputs.warranty))
    }

    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    const request = await axios.post(
        `/${isAdminWorkspace ? 'admin' : 'products'}/components`,
        formData,
        {
            headers,
        },
    )
    return request
}

export const updateComponentApi = async (
    id,
    inputs,
    isAdminWorkspace = false,
) => {
    let formData = new FormData()
    formData.append('name', inputs.name)
    formData.append('description', inputs.description)
    formData.append('attributes', inputs.attributes)
    formData.append('type_id', inputs.typeId)
    formData.append('cost', inputs.cost)

    if (inputs.suggested_image_url) {
        formData.append('suggested_image_url', inputs.suggested_image_url)
    }

    if (inputs.image.length > 0 || inputs.image) {
        formData.append('image', inputs.image)
    }

    if (isAdminWorkspace && inputs?.warranty !== '') {
        formData.append('warranty', parseInt(inputs.warranty))
    }

    const headers = {
        'Content-Type': 'multipart/form-data',
    }

    const request = await axios.patch(`/products/components/${id}`, formData, {
        headers,
    })
    return request
}

export const validateComponentNameApi = async (name, typeId, typeName) => {
    const payload = typeId
        ? { name: name, type_id: typeId }
        : { name: name, type_name: typeName }
    const request = await axios.post(
        `/products/components/validate-component-name`,
        payload,
    )
    return request
}

export const getBrandNamesApi = async (isAdminWorkspace = false) => {
    const request = await axios.get(
        `/${
            isAdminWorkspace ? 'admin' : 'products'
        }/component-brands?paginate=false`,
    )
    return request
}

export const createBrandNamesApi = async inputs => {
    const request = await axios.post(`/admin/component-brands`, inputs)
    return request
}
