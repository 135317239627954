import axios from '../../../config/axios'

export const getSingleAdminUserApi = async userId => {
    const request = await axios.get(`admin/users/${userId}`)
    return request
}

export const deactivateAdminUserApi = async (userId, obj) => {
    const request = await axios.post(`/admin/users/${userId}/deactivate`, obj)
    return request
}

export const activateAdminUserApi = async (userId, obj) => {
    const request = await axios.post(`/admin/users/${userId}/activate`, obj)
    return request
}

export const updateAdminUserApi = async (userId, obj) => {
    const request = await axios.post(`/admin/users/${userId}/update`, obj)
    return request
}

export const getAdminUserActivitiesApi = async userId => {
    const request = await axios.get(
        `admin/users/activities?user_id=${userId}&categories=ADMIN_ESTIMATION,ADMIN_USER,ADMIN_PAYMENT,ADMIN_CREDIT,ADMIN_PRODUCT,ADMIN_GLOBAL_LIBRARY`,
    )
    return request
}

export const getActiveAdminGroupsApi = async () => {
    const request = await axios.get(
        `admin/roles?workspace=admin&state=active&paginate=false`,
    )
    return request
}
