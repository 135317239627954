/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useState } from 'react'
import { Widget } from 'react-typeform-embed'
import { useHistory } from 'react-router-dom'
import Button from '../../Button'
import './subsections.scss'
import { Carousel } from 'antd'

import GetModal from '../../Modal'

import { appTracking } from '../../../utils/appTracker.js'
import { eventTrackers } from '../../../utils/eventTrackers.js'

const TextSectionOne = ({
    title,
    titleStyle,
    subTitleSize,
    subTitle,
    subTitleWidth,
    buttonOneTitle,
    buttonTwoTitle,
    titleWidth,
    outlineBtnBg,
    page,
    btnText,
    section,
    buttonTwoAction,
    partnerStyle,
    heading,
    paddingStyle,
    style,
    handleClick,
}) => {
    const [showModal, setShowModal] = useState(false)

    const pageTitle = title
    const pageUrlName = window.location.pathname
    const pageTracker = 'CTA_TRACKER'

    if (showModal) {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers[section].action,
            eventTrackers[section].label,
            eventTrackers[section].category,
        )
    }

    let typeFormUrl = ''

    const redirectTo = pageUrl => {
        window.location.href = pageUrl
    }

    switch (section) {
        case 'installer':
            typeFormUrl = process.env.REACT_APP_AI_FORM_URL
            break
        case 'distributor':
            typeFormUrl = process.env.REACT_APP_DISI_FORM_URL
            break
        case 'manufacturer':
            typeFormUrl = process.env.REACT_APP_OEMI_FORM_URL
            break
        default:
            typeFormUrl = process.env.REACT_APP_GET_OFFER_FORM_URL
    }

    const getTypeForm = <Widget id={`${typeFormUrl}`} height={700} />

    let history = useHistory()

    const handleCancel = () => {
        setShowModal(false)
    }

    const redirectToPartnerSignUp = depType => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['partnerSignup'].action,
            eventTrackers['partnerSignup'].label,
            eventTrackers['partnerSignup'].category,
        )
        let mapping = {
            installer: 1,
            distributor: 2,
            manufacturer: 3,
        }
        history.push({
            pathname: '/signup',
            state: {
                partnerId: mapping[depType],
            },
        })
    }

    const AITestimonials = [
        {
            testimony:
                'This platform will help me reduce energy poverty and indirectly increase my income; by helping people have access to clean and affordable renewable energy.',
            name: 'Tosin | Associate Installer',
            avatar: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/bright-ai.jpg',
        },
        {
            testimony:
                "Aspire will open up new business boundaries with SunFi. Now my clients can enjoy peace of mind that comes with measurable values through Aspire's payment scheme, online monitoring and service systems.",
            name: 'Bright | Associate Installer',
            avatar: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/tosin-ai.jpg',
        },
    ]

    const OEMITestimonials = [
        {
            testimony:
                'SunFi is simplifying access to capital to help scale our clean energy business.',
            name: 'Ayoola Dominic CEO | KoolBoks',
            avatar: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/koolboks-logo.jpg',
            avatarAlt: 'koolboks logo',
        },
        {
            testimony: '',
            name: '',
            avatar: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Arnergy_a.jpg',
            avatarAlt: 'arnergy logo',
        },
    ]

    return (
        <div
            className={`TextSectionBox ${paddingStyle && 'TextSectionBox2'}`}
            style={{
                padding: page === 'partners' ? '0' : '',
                ...style,
            }}
        >
            {heading && <p className="HeadingStyle">{heading}</p>}
            {partnerStyle === 'textStyle' ? (
                <p
                    style={{
                        width: titleWidth,
                        ...titleStyle,
                    }}
                    className="PartnerHeader"
                >
                    {title}
                </p>
            ) : (
                <p
                    style={{
                        width: titleWidth,
                        ...titleStyle,
                    }}
                    className={heading && 'NewTitleStyle'}
                >
                    {title}
                </p>
            )}

            <p
                className="SubHeader"
                style={{
                    width: subTitleWidth ? subTitleWidth : null,
                    fontSize: subTitleSize,
                }}
            >
                {subTitle}
            </p>

            {page === 'partner' ? (
                <Carousel autoplay>
                    {AITestimonials.map((testimonials, index) => {
                        return (
                            <div key={index} className="Slides">
                                {/* <img src={avatar} alt='avatar'/> */}
                                <div>
                                    <p className="PartnerQuote">
                                        {testimonials.testimony}
                                    </p>
                                    <p className="PartnerName">
                                        {' '}
                                        {testimonials.name}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </Carousel>
            ) : page === 'partner2' ? (
                <Carousel>
                    {OEMITestimonials.map((testimonials, index) => {
                        return (
                            <div
                                key={index}
                                className="Slides2"
                                style={{ marginBottom: '30px' }}
                            >
                                <div
                                    className="LogoWrapper"
                                    style={{ marginRight: '0px' }}
                                >
                                    <img
                                        className="imgLogoWrapper"
                                        src={testimonials.avatar}
                                        alt={testimonials.avatarAlt}
                                    />
                                </div>
                                <div style={{ marginLeft: '20px' }}>
                                    <p className="PartnerQuote">
                                        {' '}
                                        {testimonials.testimony}
                                    </p>
                                    <p className="PartnerName">
                                        {' '}
                                        {testimonials.name}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </Carousel>
            ) : page === 'partner3' ? (
                <Carousel>
                    {Array(2)
                        .fill()
                        .map(logo => {
                            return (
                                <div
                                    className="Slides"
                                    key={logo * Math.random()}
                                >
                                    <img
                                        style={{
                                            width: 120,
                                            height: 44,
                                            borderRadius: 5,
                                        }}
                                        src="https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Aspire_Logo.jpg"
                                        alt="aspire logo"
                                    />
                                    <div style={{ marginLeft: '20px' }}>
                                        <p className="PartnerQuote">
                                            SunFi is accelerating our business
                                            by providing us financial
                                            technology, payment plans and
                                            solution design tools to help us
                                            move faster.
                                        </p>
                                        <p className="PartnerName">
                                            {' '}
                                            Kehinde Yinusa, BDM | Aspire
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                </Carousel>
            ) : null}

            {page === 'partner' ||
            page === 'partner2' ||
            page === 'partner3' ? (
                <div style={{ marginTop: '35px' }}>
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="#fff"
                        type="partner"
                        handleClick={() => redirectToPartnerSignUp(section)}
                    >
                        {btnText}
                    </Button>
                </div>
            ) : (
                <div className={page === 'partners' ? '' : 'ButtonContainer'}>
                    {buttonOneTitle === 'Learn More' ? (
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            handleClick={() => redirectTo('/learn')}
                        >
                            {buttonOneTitle}
                        </Button>
                    ) : (
                        <Button
                            btnBgColor="var(--blue)"
                            btnTextColor="var(--white)"
                            btnWidth="150px"
                            btnHeight="54px"
                            fontSize="14px"
                            handleClick={() => {
                                if (handleClick) {
                                    handleClick()
                                } else {
                                    history.push('/consumer/welcome')
                                }
                            }}
                        >
                            {buttonOneTitle}
                        </Button>
                    )}
                    &nbsp;
                    {buttonTwoTitle && (
                        <Button
                            btnBgColorOutline={outlineBtnBg}
                            btnTextColorOutline="var(--blue)"
                            btnTextColor="var(--blue)"
                            type="default"
                            handleClick={
                                buttonTwoAction ? buttonTwoAction : () => {}
                            }
                        >
                            {buttonTwoTitle}
                        </Button>
                    )}
                </div>
            )}
            <GetModal
                showModal={showModal}
                onCancel={handleCancel}
                content={getTypeForm}
            />
        </div>
    )
}

const PictureSectionOne = ({ imgUrl, picHeight, picWidth, imgUrl2, page }) => {
    return (
        <div
            className="PictureSectionBox"
            style={{ paddingLeft: imgUrl2 && '20%' }}
        >
            <img
                src={imgUrl}
                alt="section-img"
                width={picWidth}
                height={picHeight}
                className={`ImageBox ${
                    page === 'home'
                        ? 'mobileImageBox'
                        : page === 'partner'
                        ? 'PartnerMobileImageBox'
                        : ''
                }`}
            />
            {imgUrl2 && (
                <img
                    src={imgUrl2}
                    alt="section-img"
                    width={picWidth}
                    height={picHeight}
                    className="ImageWrapper"
                />
            )}
        </div>
    )
}

const PictureSectionTwo = ({
    imgUrl,
    circle,
    picHeight,
    page,
    style,
    picWidth,
}) => {
    return (
        <div
            className="PictureSectionBox"
            style={{ marginTop: page === 'partners' ? '0px' : '', ...style }}
        >
            {circle && circle}
            <img
                src={imgUrl}
                height={picHeight}
                width={picWidth}
                alt="section-img"
                className={`${page === 'partners' ? '' : 'ImageBox2'} ${
                    page === 'home' ? 'mobileImageBox2' : ''
                }`}
            />
        </div>
    )
}
export { TextSectionOne, PictureSectionOne, PictureSectionTwo }
