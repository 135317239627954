export const statusOptions = [
    {
        status: 'Active',
        filterType: 'member_status',
    },
    {
        status: 'Inactive',
        filterType: 'member_status',
    },
    {
        status: 'Due payment',
        filterType: 'due_payment',
    },
]

export const paymentTypeOptions = [
    'Lease to Own',
    'Subscription',
    'Outright Sale',
]
