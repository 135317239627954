import axios from '../../config/axios'

export const createEstimationsStepOneApi = async estimationStepOne => {
    let formData = new FormData()
    formData.append('first_name', estimationStepOne.first_name)
    formData.append('last_name', estimationStepOne.last_name)
    // Check if the email is not set, ensure it is set to the system generated email
    if (estimationStepOne.email) {
        // Set the system generated email here
        formData.append('email', estimationStepOne.email)
    } else {
        // Set the system generated email here.
        // TODO: We should move this to the backend.
        formData.append(
            'email',
            `${estimationStepOne.phone_number}@sunfi.consumer`,
        )
    }
    formData.append('phone_number', estimationStepOne.phone_number)
    formData.append(
        'customer_acquisition',
        estimationStepOne.customer_acquisition,
    )
    formData.append('ran_credit_check', estimationStepOne.ran_credit_check)
    let repayment_method = ''
    if (estimationStepOne.repayment_method.toLowerCase() === 'direct debit') {
        repayment_method = 'DIRECT_DEBIT'
    }

    if (estimationStepOne.repayment_method.toLowerCase() === 'card debit') {
        repayment_method = 'CARD_DEBIT'
    }

    formData.append('repayment_method', repayment_method)
    estimationStepOne.credit_check_proof !== undefined &&
        formData.append(
            'credit_check_proof',
            estimationStepOne.credit_check_proof,
        )
    estimationStepOne.business !== undefined &&
        formData.append('business', estimationStepOne.business)
    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.post(`/estimations/mini`, formData, {
        headers,
    })
    return request
}

export const reEstimationsStepOneApi = async (id, estimationStepOne) => {
    let formData = new FormData()
    formData.append(
        'customer_acquisition',
        estimationStepOne.customer_acquisition,
    )
    formData.append('ran_credit_check', estimationStepOne.ran_credit_check)
    let repayment_method = ''
    if (estimationStepOne.repayment_method.toLowerCase() === 'direct debit') {
        repayment_method = 'DIRECT_DEBIT'
    }

    if (estimationStepOne.repayment_method.toLowerCase() === 'card debit') {
        repayment_method = 'CARD_DEBIT'
    }

    formData.append('repayment_method', repayment_method)
    estimationStepOne.credit_check_proof !== undefined &&
        formData.append(
            'credit_check_proof',
            estimationStepOne.credit_check_proof,
        )
    estimationStepOne.business !== undefined &&
        formData.append('business', estimationStepOne.business)

    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.patch(`/estimations/mini/${id}`, formData, {
        headers,
    })
    return request
}

export const createEstimationsStepOne2Api = async (id, estimationStepOne2) => {
    let formData = new FormData()
    formData.append(
        'system_ease_of_recovery',
        estimationStepOne2.system_ease_of_recovery,
    )
    formData.append(
        'remote_monitoring_capabilities',
        estimationStepOne2.remote_monitoring_capabilities,
    )
    formData.append('financing_term', estimationStepOne2.financing_term)
    formData.append('payment_plan', estimationStepOne2.payment_plan)
    formData.append(
        'package_selected_from',
        estimationStepOne2.package_selected_from,
    )
    if (estimationStepOne2.package_selected_from === 'Product Library') {
        formData.append('upfront_deposit', estimationStepOne2.upfront_deposit)
    }

    formData.append('appliances', JSON.stringify(estimationStepOne2.appliances))

    if (process.env.REACT_APP_FLAG_SHOW_INSTALLATION_DETAILS === 'true') {
        formData.append(
            'customer_generator_size',
            estimationStepOne2.customer_generator_size,
        )
        formData.append('is_site_ready', estimationStepOne2.is_site_ready)
        formData.append('roof_assessment', estimationStepOne2.roof_assessment)
        formData.append(
            'customer_installation_awareness',
            estimationStepOne2.customer_installation_awareness,
        )
        formData.append(
            'additional_comment',
            estimationStepOne2.additional_comment,
        )
    }
    const headers = {
        'Content-Type': 'multipart/form-data',
    }
    const request = await axios.patch(`/estimations/mini/${id}`, formData, {
        headers,
    })
    return request
}

export const getEstimationById = async id => {
    const request = await axios.get(`/estimations/${id}`)
    return request
}

export const getCreditVariables = async () => {
    const request = await axios.get(
        '/estimations/mini/provider-credit-variables',
    )
    return request
}

export const getProviderSubscriptionModelEnabled = async () => {
    const request = await axios.get(
        '/estimations/mini/validate-provider-subscription-model-enabled',
    )
    return request
}

export const getProviderClusterMinUpfrontDeposit = async () => {
    const request = await axios.get(
        `/estimations/mini/provider-cluster-upfront-deposit-value`,
    )

    return request
}

export const updateUpfrontDeposit = async (id, payload) => {
    const request = await axios.patch(`/estimations/mini/${id}`, payload)
    return request
}

export const getCreditVariableCount = async () => {
    const request = await axios.get(`/providers/credit-variables/count`)
    return request
}
