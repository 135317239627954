import { useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { loginSchema } from 'src/utils/validationSchema'
import { initiateSSEConsumerLoginApi } from 'src/api/shoppingExperience/login'
import { errorHandler } from 'src/utils/errorHandler'
import { removeToken } from 'src/utils/auth'
import { appTracking } from 'src/utils/appTracker'
import { formatPhoneNumber } from 'src/utils/formatting'
import { initiateClusterRepLoginApi } from 'src/api/representative/auth'

const useLoginComponent = (method, setMethod, userType) => {
    const [phoneNumber, setPhoneNumber] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [errors, setErrors] = useState({})
    const [toastError, setToastError] = useState(false)
    const pageTitle =
        'Consumer Login | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_LOGIN'
    const [errorMessage, setErrorMessage] = useState('')

    const newMethod = method === 'phone' ? 'email address' : 'phone number'
    const subHeading = `Enter your ${
        method === 'phone' ? 'phone number' : 'email address'
    } below to get started`
    const history = useHistory()
    const loginApi =
        userType === 'consumer'
            ? initiateSSEConsumerLoginApi
            : initiateClusterRepLoginApi

    const handleChangeMethod = () => {
        if (method === 'phone') {
            setPhoneNumber('')
        } else {
            setEmailAddress('')
        }
        setMethod(method === 'phone' ? 'email' : 'phone')
        userType === 'consumer' &&
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                method === 'phone'
                    ? 'CONSUMER_SWITCH_TO_SIGN_IN_WITH_EMAIL_ADDRESS'
                    : 'CONSUMER_SWITCH_TO_SIGN_IN_WITH_PHONE_NUMBER',
                'KYC_CONSUMER_LOGIN_TYPE',
                'KYC_CONSUMER_LOGIN_PAGE',
            )
    }

    const handleBlur = async e => {
        const { name, value } = e.target
        try {
            await loginSchema
                .validate(
                    { [name]: value },
                    { context: { method: method }, abortEarly: false },
                )
                .then(() => {
                    setErrors({})
                })
        } catch (err) {
            const errorObj = {}
            err.inner.forEach(error => {
                errorObj[error.path] = error.message
            })
            setErrors(errorObj)
        }
    }

    const initiateLoginMutation = useMutation({
        mutationFn: () => {
            return loginApi(
                method === 'phone'
                    ? { phone_number: formatPhoneNumber(phoneNumber) }
                    : { email: emailAddress },
            )
        },
        onSuccess: data => {
            const response = data?.data?.data
            if (response.action === 'REDIRECT') {
                const url = new URL(response.url)
                const redirectUrl = url.pathname + url.search
                history.push(redirectUrl)
            } else {
                setShowModal(true)
            }
            userType === 'consumer' &&
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    'CONSUMER_LOGIN_SUCCESSFUL',
                    'KYC_CONSUMER_LOGIN_METHOD_PAGE',
                    'KYC_CONSUMER_LOGIN_PAGE',
                )
            setErrors({})
        },
        onError: err => {
            setToastError(true)
            setErrorMessage(errorHandler(err))
            userType === 'consumer' &&
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    'CONSUMER_LOGIN_UNSUCCESSFUL',
                    'KYC_CONSUMER_LOGIN_METHOD_PAGE',
                    'KYC_CONSUMER_LOGIN_PAGE',
                    ['GA', 'MP'],
                    'event',
                    { err },
                )
        },
    })

    const handleContinueClick = async () => {
        try {
            await loginSchema
                .validate(
                    { phoneNumber: phoneNumber, emailAddress: emailAddress },
                    { context: { method: method }, abortEarly: false },
                )
                .then(() => {
                    removeToken()
                    setErrors({})
                    initiateLoginMutation.mutate()
                })
        } catch (err) {
            const errorObj = {}
            err.inner.forEach(error => {
                errorObj[error.path] = error.message
            })
            setErrors(errorObj)
            userType === 'consumer' &&
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    'CONSUMER_SUBMITS_LOGIN_DETAILS',
                    'KYC_CONSUMER_LOGIN_METHOD_PAGE',
                    'KYC_CONSUMER_LOGIN_PAGE',
                    ['GA', 'MP'],
                    'event',
                    { errorObj },
                )
        }
        userType === 'consumer' &&
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                'CONSUMER_SUBMITS_LOGIN_DETAILS',
                'KYC_CONSUMER_LOGIN_METHOD_PAGE',
                'KYC_CONSUMER_LOGIN_PAGE',
            )
    }

    return {
        showModal,
        errors,
        toastError,
        errorMessage,
        newMethod,
        subHeading,
        handleChangeMethod,
        handleContinueClick,
        setShowModal,
        emailAddress,
        phoneNumber,
        setPhoneNumber,
        setEmailAddress,
        initiateLoginMutation,
        handleBlur,
    }
}

export default useLoginComponent
