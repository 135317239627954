import PropTypes from 'prop-types'
import infoIcon from 'src/assets/images/Info-warning-blue.svg'
import {
    requiredBusinessInfo,
    requiredOutrightSaleInfo,
    requiredResidentialInfo,
} from '../data'

const ChangePaymentModel = ({ activePaymentModel, useType }) => {
    const requiredInfoList =
        activePaymentModel === 'Outright Sale'
            ? requiredOutrightSaleInfo
            : useType === 'Residential use'
            ? requiredResidentialInfo
            : requiredBusinessInfo
    return (
        <div className="ChangePaymentModel">
            <p className="Heading">Switching to different payment plan</p>
            <p className="SubHeading">
                Switching to{' '}
                <span className="PaymentModel">{activePaymentModel}</span>{' '}
                required a different kind of kyc
            </p>

            <div className={'GettingStartedRequiredInfoList'}>
                <h3>Required information to be requested from you:</h3>
                <ul>
                    {requiredInfoList?.map((each, i) => (
                        <li key={i}>{each}</li>
                    ))}
                </ul>
            </div>
            {activePaymentModel !== 'Outright Sale' && (
                <div className={'GettingStartedRequiredInfoDisclaimer'}>
                    <img src={infoIcon} alt={'info'} role={'presentation'} />
                    <p>
                        Please note that none of these information requested
                        from you will give us access to your bank account or
                        transactions.{' '}
                    </p>
                </div>
            )}
        </div>
    )
}
ChangePaymentModel.propTypes = {
    activePaymentModel: PropTypes.string,
    useType: PropTypes.string,
}

export default ChangePaymentModel
