import { useQuery } from 'react-query'
import { getClientLocator } from './user-tracking'
import { decode, encode } from './cryptography'

export const useVerifyAstronautAddress = () => {
    const ip = decode(sessionStorage.getItem('astronaut') ?? '')

    // fetches the client's IP address and updates the state accordingly using react-query
    useQuery('ip-locator', () => getClientLocator(false), {
        enabled:
            // ensure that api calls are only allowed when maintenance is set to true and ip is available
            process.env.REACT_APP_REDIRECT_TO_MAINTENANCE_PAGE === 'true' &&
            ip?.length === 0
                ? true
                : false,
        onSuccess: data => {
            sessionStorage.setItem('astronaut', encode(data?.ipAddress))
        },
    })

    // parses the white listed ip addresses from the env
    const allowedAstronautAddresses = JSON.parse(
        process.env.REACT_APP_MAINTENANCE_MODE_ASTRONAUT_ADDRESSES || '[]',
    )

    // checks to see if the ip address the browser returns is included in the list of whitelisted ip addresses
    const verifiedAstronautAddress = allowedAstronautAddresses.includes(ip)

    return {
        allowedAstronautAddresses,
        verifiedAstronautAddress,
    }
}
