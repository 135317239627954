export const currencyFormatter = value => {
    if (typeof value === 'string') {
        value = value.replace(/,/g, '')
    }
    let formattedValue = parseFloat(value, 10)
        ?.toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')

    return formattedValue
}
