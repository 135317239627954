/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import parsePhoneNumber from 'libphonenumber-js'
import BackNav from '../../BackNav'
import { InputFields, TextArea } from '../../InputFields'
import { SelectCountryCode } from '../../InputFields/SelectCountryCode'
import ButtonComponent from '../../Button'
import { InlineLoader } from '../../Loader'
import Toast from '../../Toast'
import { ReactComponent as EmailIcon } from '../../../assets/images/Email.svg'
import { ReactComponent as PhoneIcon } from '../../../assets/images/Phone.svg'
import { ReactComponent as SuccessImage } from '../../../assets/images/successImage.svg'

import { ConsumerHelpQuestionValidationSchema } from '../../../utils/validationSchema'
import { helpQuestionApi } from '../../../api/consumer/helpform'
import { formatPhoneNumber } from '../../../utils/formatting'
import { questionDB } from './data'

import './consumerhelpcenter.scss'
import { decrypt } from 'src/utils/cryptography'

const ConsumerQuestion = ({ close, handleBackToHelp }) => {
    const [askQuestionModal, setAskQuestionModal] = useState(true)
    const [errors, setErrors] = useState('')
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [countryCode, setCountryCode] = useState('+234')
    const getUserInfo = JSON.parse(localStorage.getItem('sunfiUserInfo')) || {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    }
    const [randomQuestion, setRandomQuestion] = useState('')

    const [inputs, setInputs] = useState({
        fullName:
            decrypt(getUserInfo?.['firstName']).length > 1
                ? `${decrypt(getUserInfo?.['firstName'])} ${decrypt(
                      getUserInfo?.['lastName'],
                  )}`
                : '',
        phoneNumber:
            parsePhoneNumber(decrypt(getUserInfo?.['phoneNumber']) || '')
                ?.nationalNumber || '',
        fullPhoneNumber: decrypt(getUserInfo?.['phoneNumber']) || '',
        email: decrypt(getUserInfo?.['email']) || '',
        message: '',
        captcha: null,
    })

    const handleInputChange = event => {
        const { name, value } = event.target

        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleBlur = e => {
        const { name, value } = e.target

        setInputs(preState => ({
            ...preState,
            [name]: value.trim(),
        }))
    }

    const handleSubmit = () => {
        inputs.fullPhoneNumber = formatPhoneNumber(
            inputs.phoneNumber,
            countryCode,
        )

        ConsumerHelpQuestionValidationSchema.validate(inputs, {
            abortEarly: false,
        })
            .then(() => {
                setErrors('')
                const correctAnswer = questionDB.filter(
                    ques => ques.question === randomQuestion,
                )[0].answer

                if (parseInt(inputs.captcha) === correctAnswer) {
                    refetch()
                } else {
                    setErrors({ captcha: 'Please enter the right answer' })
                }
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }
    const { isLoading, refetch } = useQuery(
        'helpQuestionApi',
        () =>
            helpQuestionApi({
                name: inputs.fullName,
                message: inputs.message,
                email: inputs.email,
                phone_number: formatPhoneNumber(
                    inputs.phoneNumber,
                    countryCode,
                ),
            }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setAskQuestionModal(false)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to send your message. Try Again')
            },
        },
    )

    const sendMessage = () => {
        handleSubmit()
    }

    const handleRandomQuestion = () => {
        setRandomQuestion(
            questionDB[Math.floor(Math.random() * questionDB.length)].question,
        )
    }

    useEffect(() => handleRandomQuestion(), [])

    return (
        <>
            {askQuestionModal ? (
                <>
                    <div>
                        <BackNav
                            title="Back to Help"
                            onClick={handleBackToHelp}
                        />
                        <h1 className="AskQuestionTitle">Ask a Question</h1>
                        <p className="AskQuestionTitleDescription">
                            You can’t find answers? Drop a message for us and we
                            respond as soon as possible
                        </p>
                    </div>

                    <div>
                        <div className="AskQuestionInfoWrapper">
                            <div className="AskQuestionEmailWrapper">
                                <div className="Ask_EmailIconWrapper">
                                    <EmailIcon
                                        style={{
                                            height: 15,
                                        }}
                                    />
                                </div>

                                <a href="mailto:hello@sunfi.co">
                                    hello@sunfi.co
                                </a>
                            </div>

                            <div className="AskQuestionPhoneWrapper">
                                <div className="Ask_PhoneIconWrapper">
                                    <PhoneIcon
                                        style={{
                                            height: 15,
                                        }}
                                    />
                                </div>

                                <a href="tel: +2348163399714">+2348163399714</a>
                            </div>
                        </div>
                        {toastError && (
                            <Toast messageType="error" message={errorMessage} />
                        )}
                        <div>
                            <InputFields
                                title="Full Name"
                                inputWidth="100%"
                                name="fullName"
                                type="text"
                                handleChange={handleInputChange}
                                errorMessage={errors?.fullName}
                                value={inputs.fullName}
                                onBlur={handleBlur}
                            />
                            <SelectCountryCode
                                title="Phone Number"
                                inputWidth="100%"
                                name="phoneNumber"
                                dropDown
                                setCountryCode={setCountryCode}
                                handleChange={handleInputChange}
                                errorMessage={errors?.fullPhoneNumber}
                                inputValue={inputs.phoneNumber}
                                onBlur={handleBlur}
                            />
                            <InputFields
                                title="Email Address"
                                inputWidth="100%"
                                name="email"
                                handleChange={handleInputChange}
                                errorMessage={errors?.email}
                                value={inputs.email}
                                onBlur={handleBlur}
                            />
                            <TextArea
                                title="Message"
                                width="100%"
                                height="144px"
                                name="message"
                                handleChange={handleInputChange}
                                errorMessage={errors?.message}
                                rows={10}
                                inputValue={inputs.message}
                            />
                            <div>
                                <h2 className="CaptchaTitle">
                                    {randomQuestion}
                                </h2>

                                <p className="CaptchaSubtitle">
                                    Please enter the answer in digits
                                </p>
                                <InputFields
                                    title="Answer In Digits"
                                    inputWidth="100%"
                                    name="captcha"
                                    type="number"
                                    handleChange={handleInputChange}
                                    errorMessage={errors?.captcha}
                                    value={inputs.captcha}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="sendQuestionBtnWrapper">
                                <ButtonComponent
                                    btnBgColor="var(--blue)"
                                    btnTextColor="var(--white)"
                                    btnWidth="150px"
                                    handleClick={sendMessage}
                                >
                                    {isLoading ? <InlineLoader /> : 'Send'}
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <BackNav
                            title="Back to Help"
                            onClick={handleBackToHelp}
                        />
                    </div>

                    <div className="SentMessagePageWrapper">
                        <div className="MessageSentIconWrapper">
                            <SuccessImage
                                style={{
                                    height: 140,
                                }}
                            />
                        </div>

                        <p className="MessageSentHeader">
                            Message Sent Successfully
                        </p>

                        <p className="MessageSentHeaderDescription">
                            Thank you for sending your question. Your question
                            has been sent and a representive from Sunfi will
                            reach out to you
                        </p>

                        <div className="CloseHelpSliderBtnWrapper">
                            <ButtonComponent
                                btnBgColor="var(--blue)"
                                btnTextColor="var(--white)"
                                handleClick={close}
                            >
                                Close
                            </ButtonComponent>
                        </div>

                        <div className="AskQuestionInfoWrapper">
                            <div className="AskQuestionEmailWrapper">
                                <div className="Ask_EmailIconWrapper">
                                    <EmailIcon
                                        style={{
                                            height: 15,
                                        }}
                                    />
                                </div>
                                <a href="mailto:hello@sunfi.co">
                                    hello@sunfi.co
                                </a>
                            </div>

                            {/* <div className="AskQuestionPhoneWrapper">
                                <div className="Ask_PhoneIconWrapper">
                                    <PhoneIcon
                                        style={{
                                            height: 15,
                                        }}
                                    />
                                </div>
                                <a href="tel: +2348163399714">+2348163399714</a>
                            </div> */}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default ConsumerQuestion
