import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Confirmation from '../../components/Confirmation'
import Error from '../../assets/images/Error.svg'
import errorImage from '../../assets/images/deletemodal-icon.svg'

const WorkspaceStatus = () => {
    const history = useHistory()
    const [unApproved, setUnApproved] = useState(false)
    const [suspended, setSuspended] = useState(false)
    useEffect(() => {
        if (history?.location?.state?.workspace) {
            setUnApproved(history?.location?.state?.workspace?.unapproved)
            setSuspended(history?.location?.state?.workspace?.suspended)
            // Clear the state of the history
            const state = {}
            history.replace({ ...history.location, state })
        } else {
            history.push('/partners')
        }
    }, [history])

    const onButtonClick = () => {
        history.push('/partners')
    }

    return (
        <>
            {unApproved && (
                <Confirmation
                    imgUrl={Error}
                    header="Pending Approval"
                    subHeader="Thank you for your interest in partnering with SunFi to grow your clean energy business. 
                            We are currently only accepting a handful of providers at this time so that we can deliver utmost value to all who have onboarded to ensure our offerings are well designed specifically for future partners like you. 
                            To reserve your spot on our platform, we have placed you on a waitlist and will be approving off the waitlist gradually. We will get back to you as soon as there are available slots."
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    onButtonClick={onButtonClick}
                    btnText="Go Home"
                    height="100%"
                />
            )}
            {suspended && (
                <Confirmation
                    imgUrl={errorImage}
                    header="Suspended"
                    subHeader="Your workspace is currently suspended. If this is not meant to be so, please reach out to hello@sunfi.co."
                    subHeaderStyle="48px"
                    boxPadding="45px"
                    onButtonClick={onButtonClick}
                    btnText="Go Home"
                />
            )}
        </>
    )
}

export default WorkspaceStatus
