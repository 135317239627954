import PropTypes from 'prop-types'
import SecondaryModal from '../Modal/SecondaryModal'
import { ReactComponent as YoutubeBtn } from 'src/assets/images/play-icon.svg'
import ReactPlayer from 'react-player'
import { isMobile } from 'src/utils/mediaQueries'
import styles from './cssevideoplayer.module.scss'
import ConsumerModalBase from '../Modal/ConsumerModalBase'

const CSSEVideoPlayer = ({
    showVideoModal,
    setShowVideoModal,
    showVideo,
    setShowVideo,
    modalTitle,
    header,
    videoInfo,
    videoUrl,
    videoGIF,
    description,
}) => {
    const { CSSEVideoPlayerWrapper, CSSEVideoPlayerImageWrapper } = styles

    const handleShowVideo = () => {
        setShowVideo(true)
        setShowVideoModal(false)
    }

    const handleShowVideoModal = () => {
        setShowVideo(false)
        setShowVideoModal(true)
    }

    return (
        <>
            {showVideo && (
                <SecondaryModal
                    showModal={showVideo}
                    modalWidth="1000px"
                    modalHeight="100%"
                    onCancel={handleShowVideoModal}
                    noPadding
                    modalClass="videoModal"
                    content={
                        <div>
                            <ReactPlayer
                                url={videoUrl}
                                width="100%"
                                height={isMobile ? '250px' : '643px'}
                                playing={showVideo}
                                controls={true}
                                loop={true}
                            />
                        </div>
                    }
                />
            )}
            <ConsumerModalBase
                showModal={showVideoModal}
                onCancel={() => setShowVideoModal(false)}
                standardWidth={477}
                standardHeight={523}
                mobileHeight={527}
                title={modalTitle}
                footerLeftContent="chat"
                primaryBtnText="Get started"
                handlePrimaryBtnClick={() => setShowVideoModal(false)}
                primaryBtnWidth={isMobile ? '127.15px' : '160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            >
                <section className={CSSEVideoPlayerWrapper}>
                    <h3>{header}</h3>
                    <div
                        className={CSSEVideoPlayerImageWrapper}
                        onClick={handleShowVideo}
                    >
                        <img src={videoGIF} alt="How to Video Gif" />
                        <YoutubeBtn />
                        <span>{videoInfo}</span>
                    </div>
                    <p>{description}</p>
                </section>
            </ConsumerModalBase>
        </>
    )
}

CSSEVideoPlayer.propTypes = {
    showVideoModal: PropTypes.bool,
    setShowVideoModal: PropTypes.func,
    showVideo: PropTypes.bool,
    setShowVideo: PropTypes.func,
    modalTitle: PropTypes.string,
    header: PropTypes.string,
    videoInfo: PropTypes.string,
    videoUrl: PropTypes.string,
    videoGIF: PropTypes.string,
    description: PropTypes.string,
}

export default CSSEVideoPlayer
