import BestFitSolarImg from 'src/assets/images/bestfit-solar-system.svg'
import SolarSystemImg from 'src/assets/images/solar-system-img.svg'

export const paymentPlansData = [
    '3 Months',
    '6 Months',
    '9 Months',
    '12 Months',
    '15 Months',
    '18 Months',
    '21 Months',
    '24 Months',
    '36 Months',
]

export const paymentPlansMap = {
    [paymentPlansData[0]]: '3',
    [paymentPlansData[1]]: '6',
    [paymentPlansData[2]]: '9',
    [paymentPlansData[3]]: '12',
    [paymentPlansData[4]]: '15',
    [paymentPlansData[5]]: '18',
    [paymentPlansData[6]]: '21',
    [paymentPlansData[7]]: '24',
    [paymentPlansData[8]]: '36',
}

export const paymentPlansMapReverse = {
    3: [paymentPlansData[0]],
    6: [paymentPlansData[1]],
    9: [paymentPlansData[2]],
    12: [paymentPlansData[3]],
    15: [paymentPlansData[4]],
    18: [paymentPlansData[5]],
    21: [paymentPlansData[6]],
    24: [paymentPlansData[7]],
    36: [paymentPlansData[8]],
}

export const electricityPerDayData = [
    '0 hour',
    '1 hour',
    '2 hours',
    '3 hours',
    '4 hours',
    '5 hours',
    '6 hours',
    '7 hours',
    '8 hours',
    '9 hours',
    '10 hours',
    '11 hours',
    '12 hours',
    '13 hours',
    '14 hours',
    '15 hours',
    '16 hours',
    '17 hours',
    '18 hours',
    '19 hours',
    '20 hours',
    '21 hours',
    '22 hours',
    '23 hours',
    '24 hours',
]

export const electricityPerDayDataMap = {
    0: '0 hour',
    1: '1 hour',
    2: '2 hours',
    3: '3 hours',
    4: '4 hours',
    5: '5 hours',
    6: '6 hours',
    7: '7 hours',
    8: '8 hours',
    9: '9 hours',
    10: '10 hours',
    11: '11 hours',
    12: '12 hours',
    13: '13 hours',
    14: '14 hours',
    15: '15 hours',
    16: '16 hours',
    17: '17 hours',
    18: '18 hours',
    19: '19 hours',
    20: '20 hours',
    21: '21 hours',
    22: '22 hours',
    23: '23 hours',
    24: '24 hours',
}

export const reasonForSolarData = [
    'To reduce my monthly power costs',
    'To increase availability of power',
    'To save money long-term',
    'To switch to clean energy',
]

export const reasonForSolarMap = {
    [reasonForSolarData[0]]: 'REDUCE_MONTHLY_COST',
    [reasonForSolarData[1]]: 'INCREASE_POWER_AVAILABILITY',
    [reasonForSolarData[2]]: 'SAVE_MONEY_LONG_TERM',
    [reasonForSolarData[3]]: 'INTEGRATE_CLEAN_ENERGY',
}

export const reasonForSolarMapReverse = {
    REDUCE_MONTHLY_COST: [reasonForSolarData[0]],
    INCREASE_POWER_AVAILABILITY: [reasonForSolarData[1]],
    SAVE_MONEY_LONG_TERM: [reasonForSolarData[2]],
    INTEGRATE_CLEAN_ENERGY: [reasonForSolarData[3]],
}

export const generatorSizeData = ['1kva', '2.5kva', '3.0kva', '5kva', '6.0kva']

export const generatorMaintenanceData = [
    'Every month',
    'Once in two months',
    'Twice in six months',
    'Once every year',
    'Twice every year',
]

export const solarSystemsData = [
    {
        name: 'iMax10 (400Wp)',
        description: '500kVa system',
        fit: 'Best fit',
        plans: 3,
        planType: 'Lease to own',
        duration: '18 hours',
        fullPrice: 500000,
        leastPrice: 50000,
        img: BestFitSolarImg,
    },
    {
        name: 'iMax10 (400Wp)',
        description: '500kVa system',
        fit: 'Technical fit',
        plans: 3,
        planType: 'Lease to own',
        duration: '18 hours',
        fullPrice: 500000,
        leastPrice: 50000,
        img: SolarSystemImg,
    },
    {
        name: 'iMax10 (400Wp)',
        description: '500kVa system',
        fit: 'Economy fit',
        plans: 3,
        planType: 'Lease to own',
        duration: '18 hours',
        fullPrice: 500000,
        leastPrice: 50000,
        img: SolarSystemImg,
    },
    {
        name: 'iMax10 (400Wp)',
        description: '500kVa system',
        fit: 'Budget fit',
        plans: 3,
        planType: 'Lease to own',
        duration: '18 hours',
        fullPrice: 500000,
        leastPrice: 50000,
        img: SolarSystemImg,
    },
    {
        name: 'iMax10 (400Wp)',
        description: '500kVa system',
        plans: 3,
        planType: 'Lease to own',
        duration: '18 hours',
        fullPrice: 500000,
        leastPrice: 50000,
        img: SolarSystemImg,
    },
    {
        name: 'iMax10 (400Wp)',
        description: '500kVa system',
        plans: 3,
        planType: 'Lease to own',
        duration: '18hours',
        fullPrice: 500000,
        leastPrice: 50000,
        img: SolarSystemImg,
    },
    {
        name: 'iMax10 (400Wp)',
        description: '500kVa system',
        plans: 3,
        planType: 'Lease to own',
        duration: '18 hours',
        fullPrice: 500000,
        leastPrice: 50000,
        img: SolarSystemImg,
    },
    {
        name: 'iMax10 (400Wp)',
        description: '500kVa system',
        plans: 3,
        planType: 'Lease to own',
        duration: '18 hours',
        fullPrice: 500000,
        leastPrice: 50000,
        img: SolarSystemImg,
    },
]

export const CONTACT_METHODS = {
    EMAIL: '1',
    PHONE: '2',
}

export const PAYMENT_TYPES = {
    OWN: 'Lease to Own',
    RENT: 'Subscription',
}
