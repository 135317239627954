import PropTypes from 'prop-types'
import style from './label.module.scss'
import { ReactComponent as HelpIcon } from 'src/assets/images/help-circle.svg'
import CustomToolTip from 'src/components/CustomToolTip'

const Label = ({
    title,
    subTitle,
    variant,
    showHelpIcon,
    tooltipVisible,
    tooltipText,
    titleFontFamily,
    titleFont,
    subtitleFont,
}) => {
    const { Title, SecTitle, SubTitle, SecSubTitle, titleWrapper, helpIcon } =
        style
    const titleClass = variant === 'secondary' ? SecTitle : Title
    const subTitleClass = variant === 'secondary' ? SecSubTitle : SubTitle

    return (
        <>
            <div className={titleWrapper}>
                <h2
                    className={titleClass}
                    style={{ fontFamily: titleFontFamily, font: titleFont }}
                >
                    {title}
                </h2>
                {showHelpIcon && (
                    <CustomToolTip
                        text={tooltipText}
                        toolTipFontSize="12px"
                        toolTipMarginLeft="-150px"
                        toolTipPopupWidth="174px"
                        borderRadius="8px"
                        background="#070C18"
                        lineHeight="18px"
                        visibility={tooltipVisible}
                    >
                        <HelpIcon className={helpIcon} />
                    </CustomToolTip>
                )}
            </div>
            <p className={subTitleClass} style={{ font: subtitleFont }}>
                {subTitle}
            </p>
        </>
    )
}

Label.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['primary', 'secondary']),
    showHelpIcon: PropTypes.bool,
    tooltipVisible: PropTypes.bool,
    tooltipText: PropTypes.string,
    titleFontFamily: PropTypes.string,
    titleFont: PropTypes.string,
    subtitleFont: PropTypes.string,
}

export default Label
