import PropTypes from 'prop-types'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import PackageImageCard from '../PackageImageCard'
import styles from './viewallpackageimagesmodal.module.scss'

const ViewAllPackageImagesModal = ({ showModal, onCancel, images }) => {
    return (
        <SecondaryModal
            showModal={showModal}
            closable
            onCancel={onCancel ? onCancel : () => {}}
            modalWidth={'631px'}
            modalHeight={'496px'}
            v2
            content={
                <div className={styles.Container}>
                    <h2 className={styles.Title}>All Added Images</h2>
                    <div className={styles.Content}>
                        {images?.map((image, idx) => (
                            <PackageImageCard key={idx} image={image} />
                        ))}
                    </div>
                </div>
            }
        />
    )
}

ViewAllPackageImagesModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    images: PropTypes.array,
}

export default ViewAllPackageImagesModal
