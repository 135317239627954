import PropTypes from 'prop-types'
import FormTitleBar from 'src/components/FormTitleBar'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import s from './editandpreview.module.scss'
import ButtonComponent from 'src/components/Button'
import { InputFields } from 'src/components/InputFields'
import { creditVariablesInputData } from '../SelectVariablesDrawer/data'
import { useState } from 'react'
import arrow from 'src/assets/images/blue-arrow.svg'

const EditAndPreviewModal = ({
    showModal,
    closeModal,
    editAndPreviewModalAction,
    setEditAndPreviewModalAction,
    handleSuccess,
}) => {
    const [isOpen, setIsOpen] = useState(
        Array(creditVariablesInputData?.length).fill(false),
    )

    const onCancel = () => {
        setEditAndPreviewModalAction('edit')
        closeModal()
    }

    const titles = ['Variables', 'Payment Model', 'Lowest Interest']

    const toggleBox = index => {
        setIsOpen(prevOpen => {
            const newOpen = [...prevOpen]
            newOpen[index] = !newOpen[index]
            return newOpen
        })
        //close all other boxes
        for (let i = 0; i < isOpen.length; i++) {
            if (i !== index) {
                setIsOpen(prevOpen => {
                    const newOpen = [...prevOpen]
                    newOpen[i] = false
                    return newOpen
                })
            }
        }
    }

    const handleContinue = () => {
        if (editAndPreviewModalAction === 'edit') {
            setEditAndPreviewModalAction('preview')
        } else {
            handleSuccess()
        }
    }

    const handleBack = () => {
        setEditAndPreviewModalAction('edit')
    }

    return (
        <SecondaryModal
            closable
            showModal={showModal}
            onCancel={onCancel}
            modalWidth={'624px'}
            modalHeight={'717px'}
            content={
                <div
                    className={`${s.wrapper} ${
                        editAndPreviewModalAction === 'edit'
                            ? s.editWrapper
                            : s.previewWrapper
                    }`}
                >
                    <FormTitleBar
                        title={'Bulk Edit'}
                        subtitle={
                            'We’ve solved the problems that make it difficult for'
                        }
                    />
                    <div
                        className={`${
                            editAndPreviewModalAction === 'edit'
                                ? s.editContentWrapper
                                : s.previewContentWrapper
                        } `}
                    >
                        <div className={s.tableTitles}>
                            {titles?.map((title, i) => (
                                <h5 key={i}>{title}</h5>
                            ))}
                        </div>
                        <div className={s.editVariableSections}>
                            {creditVariablesInputData?.map((variable, i) => {
                                const paymentModel = variable?.details?.find(
                                    detail => detail?.name === 'paymentModel',
                                )?.value
                                return (
                                    <div
                                        key={variable?.id}
                                        className={`${s.variableSection} ${
                                            isOpen[i]
                                                ? s.variableSectionScrollable
                                                : ''
                                        }`}
                                    >
                                        <div className={s.variableHeading}>
                                            <h4>{variable?.name}</h4>
                                            <h5>{paymentModel}</h5>
                                            <p>3%</p>
                                            <div
                                                onClick={() => toggleBox(i)}
                                                className={
                                                    'CRESTopArrowWrapper'
                                                }
                                            >
                                                <img
                                                    src={arrow}
                                                    alt="arrow"
                                                    className={
                                                        isOpen[i]
                                                            ? 'CRESTopArrowOpen'
                                                            : 'CRESTopArrowClosed'
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className={
                                                isOpen[i]
                                                    ? 'CustomRecommendationEnergySolutionBottomOpen'
                                                    : 'CustomRecommendationEnergySolutionBottomClosed'
                                            }
                                            style={{ margin: 0 }}
                                        >
                                            {editAndPreviewModalAction ===
                                            'edit' ? (
                                                <div
                                                    className={
                                                        s.editVariablesValuesGrid
                                                    }
                                                >
                                                    {variable?.details?.map(
                                                        (detail, index) => (
                                                            <InputFields
                                                                key={index}
                                                                title={
                                                                    detail?.label
                                                                }
                                                                type="text"
                                                                name={
                                                                    detail?.name
                                                                }
                                                                value={
                                                                    detail.value
                                                                }
                                                                inputValue={
                                                                    detail?.value
                                                                }
                                                                inputWidth="100%"
                                                                marginBottom={
                                                                    '0px'
                                                                }
                                                            />
                                                        ),
                                                    )}
                                                </div>
                                            ) : (
                                                <div className={s.detailsGrid}>
                                                    {variable?.details?.map(
                                                        (detail, i) => (
                                                            <div key={i}>
                                                                <h5>
                                                                    {
                                                                        detail?.label
                                                                    }
                                                                </h5>
                                                                <p>
                                                                    {
                                                                        detail?.value
                                                                    }
                                                                </p>
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className={s.btn}>
                        {editAndPreviewModalAction === 'preview' && (
                            <ButtonComponent
                                btnBgColor={'#E2EEFF'}
                                btnTextColor={'#004AAD'}
                                btnWidth={'124px'}
                                btnHeight={'50px'}
                                marginTop={'32px'}
                                handleClick={handleBack}
                            >
                                Back
                            </ButtonComponent>
                        )}
                        <ButtonComponent
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            btnWidth={
                                editAndPreviewModalAction === 'edit'
                                    ? '160px'
                                    : '125px'
                            }
                            btnHeight={
                                editAndPreviewModalAction === 'edit'
                                    ? '56px'
                                    : '50px'
                            }
                            marginTop={'32px'}
                            handleClick={handleContinue}
                        >
                            {editAndPreviewModalAction === 'edit'
                                ? 'Continue'
                                : 'Yes, proceed'}
                        </ButtonComponent>
                    </div>
                </div>
            }
        />
    )
}

EditAndPreviewModal.propTypes = {
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    editAndPreviewModalAction: PropTypes.string,
    setEditAndPreviewModalAction: PropTypes.func,
    handleSuccess: PropTypes.func,
}

export default EditAndPreviewModal
