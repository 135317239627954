import axios from '../../config/axios'

export const solarSavingsCalculatorApi = async inputs => {
    const request = axios.post(`/solar-savings/run-calculator`, inputs)
    return request
}

export const sendDetailedCalculatorReportApi = async payload => {
    const request = axios.post(`/solar-savings/send-detailed-report`, payload)
    return request
}
