import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { getProfileApi, updateProfileApi } from 'src/api/admin/profiles/details'
import { errorHandler } from 'src/utils/errorHandler'
import useGetProfilePackages from '../../Details/hooks/useGetProfilePackages'
import { updatedConsumerProfile } from 'src/redux/reducers/admin/profiles/create-profile'
import { useEditPackage } from './useEditPackage'
import { useCreateProfile } from '../../AddPackage/hooks/useCreateProfile'
import { formatPhoneNumber } from 'src/utils/formatting'

const useAdminProfileReview = () => {
    const consumer_profile_data = useSelector(updatedConsumerProfile)
    const isPrivateProfile =
        consumer_profile_data?.promotional_type?.toLowerCase() === 'private' &&
        consumer_profile_data?.is_promotional === true
    const isPublicProfile =
        consumer_profile_data?.promotional_type?.toLowerCase() === 'public' &&
        consumer_profile_data?.is_promotional === true
    const [selectedPackages, setSelectedPackages] = useState(
        consumer_profile_data?.packages,
    )

    const [notifyRepresentatives, setNotifyRepresentatives] = useState(false)
    const [showActionModal, setShowActionModal] = useState(false)
    const [actionType, setActionType] = useState('')
    const [confirmAction, setConfirmAction] = useState(false)
    const history = useHistory()
    const params = useParams()
    const [representatives, setRepresentatives] = useState(
        consumer_profile_data?.representatives,
    )
    const [profileDetails, setProfileDetails] = useState({
        name: consumer_profile_data?.name,
        description: consumer_profile_data?.description,
        customer_type: consumer_profile_data?.customer_type,
        image_url: consumer_profile_data?.image_url,
        meta_data: {
            sort_by: consumer_profile_data?.sort_by,
            energy_capacity: {
                minimum_capacity: {
                    value:
                        consumer_profile_data?.min_capacity &&
                        JSON.parse(consumer_profile_data?.min_capacity)?.value,

                    unit_of_measurement: 'kVA',
                },
                maximum_capacity: {
                    value:
                        consumer_profile_data?.max_capacity &&
                        JSON.parse(consumer_profile_data?.max_capacity)?.value,

                    unit_of_measurement: 'kVA',
                },
            },
            cluster_info: {
                LEASE_TO_OWN:
                    consumer_profile_data?.lease_to_own_cluster_code || null,
                SUBSCRIPTION:
                    consumer_profile_data?.subscription_cluster_code || null,
            },
        },
        energy_needs: consumer_profile_data?.energy_needs,
        representatives: consumer_profile_data?.representatives,
        modules: consumer_profile_data?.modules,
        cluster_origination_setup:
            consumer_profile_data?.cluster_origination_setup,
        credit_scorecard_name: consumer_profile_data?.credit_scorecard_name,
    })

    const [energyNeeds, setEnergyNeeds] = useState()
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleActionModal = action => {
        setActionType(action)
        setShowActionModal(!showActionModal)
    }

    const actionHeaderMapping = action => {
        switch (action) {
            case 'save':
                return confirmAction
                    ? 'Consumer Profile saved'
                    : 'Save consumer profile and continue later'
            case 'publish':
                return confirmAction
                    ? 'Consumer profile published'
                    : 'Publish consumer profile'
        }
    }

    const actionSubtitleMapping = action => {
        switch (action) {
            case 'save':
                return confirmAction
                    ? 'You have successfully saved this consumer profile, you can come back anytime to continue.'
                    : 'This consumer profile will be saved and added as an unpublished consumer profile, please confirm'
            case 'publish':
                return confirmAction
                    ? 'You have successfully published this consumer profile, and its been added to the list of available profiles'
                    : isPrivateProfile || isPublicProfile
                    ? 'You’re about to publish a promo for this profile, please confirm. You can also choose to notify the cluster reps'
                    : 'You’re about to publish this consumer profile, it’ll be added to the list of available profiles. Please confirm'
        }
    }

    const { isLoading: fecthingProfileDetails } = useQuery({
        queryKey: ['fetch-admin-consumer-profile', params.id],
        queryFn: () => getProfileApi(params.id),
        enabled: !['private', 'public'].includes(params.id) ? true : false,
        retry: false,
        refetchOnMount: !['private', 'public'].includes(params.id)
            ? true
            : false,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            setProfileDetails(data.data)
            setEnergyNeeds(data.data.appliances)
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const updateEnergyProfile = useMutation({
        mutationKey: ['update-energy-profile'],
        mutationFn: () =>
            updateProfileApi(params.id, {
                appliances: energyNeeds,
                is_published: actionType === 'save' ? false : true,
            }),
        onSuccess: () => {
            setConfirmAction(true)
        },
        onError: error => {
            setToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })

    const privateProfilePayload = {
        name: consumer_profile_data?.name,
        customer_type: consumer_profile_data?.customer_type,
        description: consumer_profile_data?.description,
        min_capacity:
            profileDetails?.meta_data?.energy_capacity?.minimum_capacity ?? '0',
        max_capacity:
            profileDetails?.meta_data?.energy_capacity?.maximum_capacity ?? '0',
        appliances: consumer_profile_data?.appliances?.map(item => {
            return {
                backup_hours: item.backup_hours,
                backup_hours_night: item.backup_hours_night,
                name: item.name,
                power_rating: item.power_rating,
                quantity: item.quantity,
                unit_of_measurement: item.unit_of_measurement,
            }
        }),
        ...(consumer_profile_data?.sort_by !== '' && {
            sort_by: consumer_profile_data?.sort_by,
        }),
        ...(consumer_profile_data?.subscription_cluster_code !== '' && {
            subscription_cluster_code:
                consumer_profile_data?.subscription_cluster_code,
        }),
        ...(consumer_profile_data?.lease_to_own_cluster_code !== '' && {
            lease_to_own_cluster_code:
                consumer_profile_data?.lease_to_own_cluster_code,
        }),

        packages: selectedPackages.map(pkg => ({
            id: pkg.id,
            appliances: pkg.appliances,
            ...(pkg?.subscription_cluster_code !== '' && {
                subscription_cluster_code: pkg?.subscription_cluster_code,
            }),
            ...(pkg?.lease_to_own_cluster_code !== '' && {
                lease_to_own_cluster_code: pkg?.lease_to_own_cluster_code,
            }),
            payment_plan_types: pkg.payment_plan_types,
        })),
        is_published: actionType === 'save' ? false : true,
        is_promotional: consumer_profile_data?.is_promotional,
        promotional_type: consumer_profile_data?.promotional_type,
        cluster_origination_setup: {
            credit_scorecard_id: consumer_profile_data?.credit_scorecard_id,
            name: consumer_profile_data?.cluster_origination_setup?.name,
            ...(consumer_profile_data?.cluster_origination_setup?.website !==
                '' && {
                website:
                    consumer_profile_data?.cluster_origination_setup?.website,
            }),
            logo: consumer_profile_data?.cluster_origination_setup?.logo,
            modules: consumer_profile_data?.modules?.map((item, i) => ({
                id: item?.id,
                section: item?.section?.filter(item => {
                    return {
                        key: item.key,
                        required: item.required,
                    }
                }),
                order: i + 1,
            })),
            representatives: consumer_profile_data?.representatives?.map(
                item => {
                    return {
                        first_name: item.first_name,
                        last_name: item.last_name,
                        email: item.email,
                        phone_number: formatPhoneNumber(item.phone),
                        // permission_level: item.permission_level,
                    }
                },
            ),
            notify_representatives: notifyRepresentatives,
        },
    }

    const publicProfilePayload = {
        name: consumer_profile_data?.name,
        customer_type: consumer_profile_data?.customer_type,
        description: consumer_profile_data?.description,
        image_url: consumer_profile_data?.image_url,
        min_capacity:
            profileDetails?.meta_data?.energy_capacity?.minimum_capacity ?? '0',
        max_capacity:
            profileDetails?.meta_data?.energy_capacity?.maximum_capacity ?? '0',
        appliances: consumer_profile_data?.appliances?.map(item => {
            return {
                backup_hours: item.backup_hours,
                backup_hours_night: item.backup_hours_night,
                name: item.name,
                power_rating: item.power_rating,
                quantity: item.quantity,
                unit_of_measurement: item.unit_of_measurement,
            }
        }),
        ...(consumer_profile_data?.sort_by !== '' && {
            sort_by: consumer_profile_data?.sort_by,
        }),
        ...(consumer_profile_data?.subscription_cluster_code !== '' && {
            subscription_cluster_code:
                consumer_profile_data?.subscription_cluster_code,
        }),
        ...(consumer_profile_data?.lease_to_own_cluster_code !== '' && {
            lease_to_own_cluster_code:
                consumer_profile_data?.lease_to_own_cluster_code,
        }),

        packages: selectedPackages.map(pkg => ({
            id: pkg.id,
            appliances: pkg.appliances,
            ...(pkg?.subscription_cluster_code !== '' && {
                subscription_cluster_code: pkg?.subscription_cluster_code,
            }),
            ...(pkg?.lease_to_own_cluster_code !== '' && {
                lease_to_own_cluster_code: pkg?.lease_to_own_cluster_code,
            }),
            payment_plan_types: pkg.payment_plan_types,
        })),
        is_published: actionType === 'save' ? false : true,
        is_promotional: consumer_profile_data?.is_promotional,
        promotional_type: consumer_profile_data?.promotional_type,
        cluster_origination_setup: {
            credit_scorecard_id: consumer_profile_data?.credit_scorecard_id,
            modules: consumer_profile_data?.modules?.map((item, i) => ({
                id: item?.id,
                section: item?.section?.filter(item => {
                    return {
                        key: item.key,
                        required: item.required,
                    }
                }),
                order: i + 1,
            })),
        },
    }

    const { handleCreateProfile, isLoading: createProfileLoading } =
        useCreateProfile({
            data: isPrivateProfile
                ? {
                      ...privateProfilePayload,
                  }
                : isPublicProfile
                ? publicProfilePayload
                : {},
            handleResposneData: () => setConfirmAction(true),
        })

    const {
        paginationBlock,
        profilePackages,
        profilePackagesFetching,
        setPage,
    } = useGetProfilePackages()

    const handleConfirmation = () => {
        if (!confirmAction) {
            isPrivateProfile || isPublicProfile
                ? handleCreateProfile()
                : updateEnergyProfile.mutate()
        } else {
            setShowActionModal(false)
            history.push('/admin/consumer-profiles')
        }
    }

    const {
        appliances,
        openEditDrawer,
        toggleEditDrawer,
        setAppliances,
        handlePackageUpdate,
        cluster,
        setCluster,
        packageData,
        paymentType,
        setPaymentType,
        paymentTypeError,
        setPaymentTypeError,
    } = useEditPackage({
        setSelectedPackages,
    })

    return {
        handleActionModal,
        handleConfirmation,
        fecthingProfileDetails:
            isPrivateProfile || isPublicProfile
                ? false
                : fecthingProfileDetails,
        actionHeaderMapping,
        actionSubtitleMapping,
        profileDetails,
        updateEnergyProfile,
        errorMessage,
        toastError,
        confirmAction,
        showActionModal,
        actionType,
        energyNeeds:
            isPrivateProfile || isPublicProfile
                ? consumer_profile_data?.appliances
                : energyNeeds,
        setEnergyNeeds,
        setShowActionModal,
        paginationBlock,
        profilePackages:
            isPrivateProfile || isPublicProfile
                ? selectedPackages
                : profilePackages,
        profilePackagesFetching,
        setPage,
        setSelectedPackages,
        isPrivateProfile,
        isPublicProfile,
        //
        appliances,
        openEditDrawer,
        toggleEditDrawer,
        setAppliances,
        handlePackageUpdate,
        cluster,
        setCluster,
        packageData,
        createProfileLoading,
        setNotifyRepresentatives,
        representatives,
        setRepresentatives,
        paymentType,
        setPaymentType,
        paymentTypeError,
        setPaymentTypeError,
    }
}

export default useAdminProfileReview
