import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as SuccessUploadIcon } from 'src/assets/images/double-tick-success-icon.svg'
import { ReactComponent as CloseIcon } from 'src/assets/images/consumer-close-icon.svg'
import { truncateSentence } from 'src/utils/formatting'
import ActionModal from 'src/components/ActionModal'

import styles from './uploadedimage.module.scss'
import imageUploadStyles from '../imageupload.module.scss'

const UploadedImage = ({
    successUpload,
    setSuccessUpload,
    setShowDragger,
    showAdditionalLoadingCopy,
}) => {
    const [images, setImages] = useState([])
    const [currentImageIndex, setCurrentImageIndex] = useState(null)
    const [showActionModal, setShowActionModal] = useState(false)

    const handleViewImageButtonClick = (event, url) => {
        event.stopPropagation()
        window.open(url, '_blank')
    }

    const handleActionModalClose = () => {
        setShowActionModal(false)
        setCurrentImageIndex(null)
    }

    const handleRemoveImage = index => {
        setCurrentImageIndex(index)
        setShowActionModal(true)
    }

    const actionModalHander = () => {
        if (currentImageIndex !== null && successUpload[currentImageIndex]) {
            const updatedUploadElements = [...successUpload]
            updatedUploadElements.splice(currentImageIndex, 1)

            setSuccessUpload(updatedUploadElements)

            handleActionModalClose()
        }
    }

    useEffect(() => {
        const createBlobURLFromFile = file => {
            if (file) {
                const blobUrl = URL.createObjectURL(file.originFileObj || file)
                return blobUrl
            }

            return ''
        }

        if (successUpload && Array.isArray(successUpload)) {
            const imageFiles = []

            for (const file of successUpload) {
                let name = ''
                let url = ''

                if (typeof file === 'object') {
                    url = createBlobURLFromFile(file)
                    name = file.name
                } else if (typeof file === 'string') {
                    url = file
                    name = url.split('/').pop()
                }

                if (url && name) {
                    imageFiles.push({ name: truncateSentence(name, 40), url })
                }
            }

            setImages(imageFiles)

            if (!imageFiles.length) {
                setShowDragger(true)
            }
        }
    }, [successUpload, setShowDragger])

    return (
        <>
            <ActionModal
                actionModalOpen={showActionModal}
                headerText="Confirm you want to remove this image"
                subTitle="You’re about to remove an image already uploaded for this package"
                closeModal={handleActionModalClose}
                actionHandler={actionModalHander}
                actionType="warning"
                actionText="Confirm"
                cancelText="Cancel"
            />

            {images?.map(({ url, name }, index) => (
                <div key={index} className={styles.ImageUploadContent}>
                    <SuccessUploadIcon />
                    <>
                        <p>{name}</p>
                        <button
                            className={styles.viewFileButton}
                            onClick={event =>
                                handleViewImageButtonClick(event, url)
                            }
                        >
                            View this file
                        </button>
                    </>

                    {showAdditionalLoadingCopy && (
                        <p className={styles.UMC__AdditionalLoadingCopy}>
                            Your upload is taking a bit longer than
                            <br />
                            usual, we are working on it
                        </p>
                    )}

                    <button
                        className={imageUploadStyles.CloseButton}
                        onClick={() => handleRemoveImage(index)}
                    >
                        <CloseIcon />
                    </button>
                </div>
            ))}
        </>
    )
}

UploadedImage.propTypes = {
    successUpload: PropTypes.array,
    setSuccessUpload: PropTypes.func,
    setShowDragger: PropTypes.func,
    showAdditionalLoadingCopy: PropTypes.bool,
}

export default UploadedImage
