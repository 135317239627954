import { useEffect } from 'react'
import { scrollToById } from 'src/utils/scrollToById'

const useScrollToError = (data, triggerScroll = true) => {
    useEffect(() => {
        if (triggerScroll) {
            const errorClassNames = ['ErrorText']
            let errorElement = null

            for (const className of errorClassNames) {
                errorElement = document.querySelector(`.${className}`)
                if (errorElement) break
            }

            if (errorElement) {
                if (!errorElement.id) {
                    errorElement.id = 'errorElement'
                }
                scrollToById(errorElement.id, 200)
            }
        }
    }, [data, triggerScroll])
}

export default useScrollToError
