import AccountSetupFooter from 'src/components/ConsumerFooter/AccountSetupFooter'
import './consumerkyc.scss'
import PropTypes from 'prop-types'
import ConsumerKYCContextProvider, { useConsumerKYCContext } from './context'
import Toast from 'src/components/Toast'
import SetupNav from './components/SetupNav'
import SideContent from './components/SideContent'
import MainContent from './components/MainContent'
import SeoComponent from 'src/components/Seo'

const ConsumerKYCContent = () => {
    const {
        estimation,
        handleSaveProgress,
        kycData,
        errorMessage,
        toastError,
        canSubmitKYC,
    } = useConsumerKYCContext()

    return (
        <>
            {toastError && (
                <Toast messageType={'error-secondary'} message={errorMessage} />
            )}
            <div className="ConsumerKYC">
                <SetupNav
                    canSubmitKYC={canSubmitKYC}
                    handleSaveProgress={handleSaveProgress}
                    estimationId={estimation?.id}
                    consumerName={
                        kycData?.consumer?.user
                            ? `${kycData.consumer.user.first_name || ''} ${
                                  kycData.consumer.user.last_name || ''
                              }`
                            : ''
                    }
                />
                <div className="KYCContainer">
                    <div className="KYCContent">
                        <SideContent />
                        <MainContent />
                    </div>
                </div>
                <AccountSetupFooter />
            </div>
        </>
    )
}

const ConsumerKYC = ({ useType }) => {
    return (
        <ConsumerKYCContextProvider useType={useType}>
            <SeoComponent title="KYC -  SunFi | Simplifying and Scaling Clean Energy" />
            <ConsumerKYCContent />
        </ConsumerKYCContextProvider>
    )
}

ConsumerKYC.propTypes = {
    useType: PropTypes.string,
}

export default ConsumerKYC
