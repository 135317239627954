import { useState } from 'react'
import { useQuery } from 'react-query'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { getSunfiPackages } from 'src/api/admin/profiles/manage-profile'

export const useGetAllPackages = ({
    name,
    pageNumber,
    providerId,
    clusterPackagesCode,
    packageDataFormatter,
    minCapacity,
    maxCapacity,
}) => {
    const [packagesData, setPackagesData] = useState([])
    const [listTotal, setListTotal] = useState(1)

    const { errorAlert } = useCustomToast()

    const { refetch: refetchPackages, isFetching: isLoadingPackages } =
        useQuery({
            queryKey: [
                name,
                pageNumber,
                providerId,
                clusterPackagesCode,
                minCapacity,
                maxCapacity,
            ],
            queryFn: () =>
                getSunfiPackages(
                    pageNumber,
                    name,
                    providerId,
                    clusterPackagesCode,
                    minCapacity,
                    maxCapacity,
                ),
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            onSuccess: res => {
                const data = res.data

                if (data) {
                    let packages = data.data

                    if (packageDataFormatter) {
                        packages = packageDataFormatter(data.data)
                    }

                    setListTotal(data.total_pages)
                    setPackagesData(packages)
                }
            },
            onError: err =>
                errorAlert(
                    errorHandler(err?.response?.data) ||
                        'Something went wrong!',
                ),
        })

    return {
        packagesData,
        listTotal,
        setPackagesData,
        setListTotal,
        isLoadingPackages,
        refetchPackages,
    }
}
