/* eslint-disable no-unused-vars */
import '../identityinformation.scss'
import helpIcon from 'src/assets/images/help-circle-black.svg'
import failIcon from 'src/assets/images/circle-close-red.svg'
import passIcon from 'src/assets/images/check-green.svg'
import { useState } from 'react'
import { Tooltip } from 'antd'
import { useSSERetrieveBasicInfo } from 'src/api/shoppingExperience/kyc/queries'
import { formatGender } from 'src/utils/formatting'
import SecondaryModal from 'src/components/Modal/SecondaryModal'

const ImageDataSection = () => {
  const [showCustomerSelfie, setShowCustomerSelfie] = useState(false)
  const { sseBasicInfoData } = useSSERetrieveBasicInfo({
    enabled: true,
  })
  const customerSelfie = sseBasicInfoData?.data?.data?.consumer?.user?.avatar_url
  const imageAnalysis = sseBasicInfoData?.data?.data?.consumer?.user?.avatar_meta_data
  const min_age = imageAnalysis?.facial_recognition?.age_analysis?.value[0]
  const max_age = imageAnalysis?.facial_recognition?.age_analysis?.value[1]
  const face_check = imageAnalysis?.facial_recognition?.single_face_detected
  const gender = imageAnalysis?.facial_recognition?.gender_analysis?.value


  return (
    <>
      <SecondaryModal
        v2
        showModal={showCustomerSelfie}
        onCancel={() => setShowCustomerSelfie(false)}
        modalHeight="450px"
        modalWidth="550px"
        modalClass="adminModal"
        closable
        title="Image returned"
        content={
          <>
            <div className="imageModalContainer">
              <img
                src={customerSelfie}
              />
            </div>
          </>
        }
      />
      <h2 className='customerSelfieTitle'>Customer&apos;s selfie</h2>
      <div className='customerSelfie'>
        <img src={customerSelfie} onClick={()=>setShowCustomerSelfie(true)}/>
      </div>

      <div className="imageMetaDataContainer">
        <div className="title">
          <h3>PROFILE PICTURE</h3>
          <Tooltip
            overlayStyle={{
              padding: '9px 8px',
              maxWidth: '178px',
            }}
            overlayInnerStyle={{
              fontSize: '12px',
              fontFamily: 'SF-Pro-Display',
            }}
            color="#070C18"
            placement="bottom"
            title="This data was collected by the machine learning analysic performed on the image added by customer"
          >
            <img src={helpIcon} />
          </Tooltip>
        </div>
        <div className="identityMetaData">
          <div>
            <h4>Gender</h4>
            <p>{formatGender(gender)}</p>
          </div>
          <div>
            <h4>Age</h4>
            {imageAnalysis && <p>{min_age} - {max_age}</p>}
          </div>
          <div>
            <h4>Face check</h4>
            {face_check
            ?
            <div className="face-check">
              <img src={passIcon} />
              <p>True</p>
            </div>
            :
            <div className="face-check">
              <img src={failIcon} />
              <p>False</p>
            </div>
          }
          </div>
        </div>
      </div>
    </>
    )
}

export default ImageDataSection
