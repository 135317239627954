import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { initiatePhoneVerify } from 'src/api/create-account'
import { errorHandler } from 'src/utils/errorHandler'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { shortenEmail } from 'src/utils/shortenEmail'

export const useInitiateVerification = (
    contactTypeToVerify,
    verifyContactsModalInputs,
    setShowVerifyContactModal,
    setVerifyContactToast,
    resetToast,
) => {
    const [sentOptCount, setSentOptCount] = useState(0)
    const [resent, setResent] = useState(false)

    const { errorAlert } = useCustomToast()

    const { mutate, isLoading } = useMutation({
        mutationKey: ['initiate-contact-verification'],
        mutationFn: payload => initiatePhoneVerify(payload),
        onSuccess: () => {
            setSentOptCount(prev => prev + 1)
            setShowVerifyContactModal(true)

            if (sentOptCount > 0) {
                setResent(true)
                const timeout = setTimeout(() => setResent(false), [2000])
                return () => clearTimeout(timeout)
            } else {
                setVerifyContactToast({
                    showToast: true,
                    toastMessage: `Verification code sent to ${
                        contactTypeToVerify === 'email'
                            ? shortenEmail(verifyContactsModalInputs?.email)
                            : `+234******${verifyContactsModalInputs?.phone.slice(
                                  -4,
                              )}`
                    }`,
                    messageType: 'success-secondary',
                })
                resetToast()
            }
        },
        onError: error => {
            errorAlert(
                errorHandler(error?.response?.data) ||
                    'Unable to send otp, please try again',
            )
        },
    })

    useEffect(() => {
        return () => setSentOptCount(0)
    }, [])

    return {
        initiateVerificationMutate: mutate,
        initiateEmailVerificationLoading:
            isLoading && contactTypeToVerify === 'email',
        initiatePhoneVerificationLoading:
            isLoading && contactTypeToVerify === 'phone',
        initiateVerificationResent: resent,
        initiateVerificationSetResent: setResent,
    }
}
