import PropTypes from 'prop-types'
import { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import DetailsPage from '../DetailsPage'
import UserActivitiesTable from './UserActivitiesTable'
import { useQuery } from 'react-query'
import {
    deactivateAdminUserApi,
    updateAdminUserApi,
    getAdminUserActivitiesApi,
    getActiveAdminGroupsApi,
    getSingleAdminUserApi,
    activateAdminUserApi,
} from '../../api/admin/user/userActivities'
import {
    deactivateProviderUserApi,
    getProviderUserActivitiesApi,
    getSingleProviderUserApi,
    reactivateProviderUserApi,
    updateProviderUserDetailsApi,
} from '../../api/providers/users'
import UserActionModal from './UserActionModal'
import { shortenEmail } from '../../utils/shortenEmail'
import SecondaryModal from '../Modal/SecondaryModal'
import EditUserDetails from '../../pages/Admin/Providers/Active/EditUserDetails'
import { errorHandler } from '../../utils/errorHandler'
import ActionModal from '../ActionModal'
import { removeCountryCode } from '../../utils/NumberFormatter'
import { formatPhoneNumber } from 'src/utils/formatting'
import Toast from '../Toast'
import { getActiveProviderGroupsApi } from 'src/api/providers/users'
import { determineModalTexts, determineButtonTwoProps } from './utils'
import { permissionsControl } from 'src/utils/permissionsControl'

const AdminUser = ({ userType }) => {
    const [userData, setUserData] = useState({})
    const [actions, setActions] = useState([])
    const [actionInputs, setActionInputs] = useState({ reason: '' })
    const [groups, setGroups] = useState([])
    const [showUserActionModal, setShowUserActionModal] = useState(false)
    const [errors, setErrors] = useState({})
    const [actionType, setActionType] = useState('')
    const [editModal, setEditModal] = useState(false)
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        role: '',
        groups: [],
        password: '',
    })
    const [savedUserData, setSavedUserData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        role: '',
        groups: [],
        password: '',
    })
    const [newData, setNewData] = useState({})
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [actionToast, setActionToast] = useState(false)
    const [actionToastMessage, setActionToastMessage] = useState('')
    const [pageToast, setPageToast] = useState(false)
    const [pageToastMessage, setPageToastMessage] = useState('')
    const [createActivities, setCreateActivities] = useState(false)

    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const { header, subtitle } = determineModalTexts(actionType)
    const { buttonTwoTextValue, buttonTwoActionTypeValue } =
        determineButtonTwoProps(userData)

    const providerId = location.state?.providerId

    const canUpdateAdminUser = permissionsControl(['can_update_admin_user'])
    const canActivateAdminUser = permissionsControl(['can_activate_user'])
    const canDeactivateAdminUser = permissionsControl(['can_deactivate_user'])

    useEffect(() => {
        if (window.location.href.includes('/admin/providers/user')) {
            if (!providerId) {
                history.goBack()
            }
        }
    }, [providerId, history, userType])

    const {
        refetch: providerUserDetailsRefetch,
        isLoading: providerUserDetailsLoading,
        isFetching: providerUserDetailsFetching,
    } = useQuery(
        ['fetch-admin-provider-users'],
        () =>
            userType === 'admin-user'
                ? getSingleAdminUserApi(params?.id)
                : getSingleProviderUserApi(params?.id, providerId),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const fetchedData = data?.data?.data
                //leave page if user is still pending or deleted
                if (
                    ((fetchedData?.state === 'ACTIVE' &&
                        fetchedData?.email_verified === false) ||
                        fetchedData?.state === 'DELETED') &&
                    userType !== 'provider-user'
                ) {
                    history.goBack()
                }
                setUserData({
                    displayName: fetchedData?.display_name,
                    phoneNumber: fetchedData?.phone_number,
                    addedBy: fetchedData?.provider?.display_name,
                    email: fetchedData?.email,
                    role: fetchedData?.role,
                    state: fetchedData?.state,
                    userTypes: fetchedData?.user_types,
                    groups: fetchedData?.roles?.map(role => role?.name),
                })
                setInputs({
                    firstName: fetchedData?.first_name,
                    lastName: fetchedData?.last_name,
                    phoneNumber: removeCountryCode(fetchedData?.phone_number),
                    email: fetchedData?.email,
                    role: fetchedData?.role,
                    groups: fetchedData?.roles.map(item => ({
                        label: item?.name,
                        value: item?.name,
                        id: item?.id,
                    })),
                })
                setSavedUserData({
                    firstName: fetchedData?.first_name,
                    lastName: fetchedData?.last_name,
                    phoneNumber: removeCountryCode(fetchedData?.phone_number),
                    email: fetchedData?.email,
                    role: fetchedData?.role,
                    groups: fetchedData?.roles.map(item => ({
                        label: item?.name,
                        value: item?.name,
                        id: item?.id,
                    })),
                })
            },
            onError: error => {
                setPageToast(true)
                setPageToastMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const { refetch: userActivitiesRefetch, isLoading: userActivitiesLoading } =
        useQuery(
            ['fetch-admin-user-activities'],
            () =>
                userType === 'admin-user'
                    ? getAdminUserActivitiesApi(params?.id)
                    : getProviderUserActivitiesApi(params?.id, providerId),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    const fetchedData = data?.data?.data
                    for (let i = 0; i < fetchedData.length; i++) {
                        setActions(prev => [
                            ...prev,
                            {
                                action: fetchedData?.[i]?.action,
                                category: fetchedData?.[i]?.category,
                                target: fetchedData?.[i]?.consumer?.user
                                    ?.display_name,
                                date: fetchedData?.[i]?.created_at,
                            },
                        ])
                        if (i === fetchedData.length - 1) {
                            setCreateActivities(true)
                        }
                    }
                },
                onError: error => {
                    setPageToast(true)
                    setPageToastMessage(errorHandler(error?.response?.data))
                },
            },
        )

    //edit user details
    const { refetch: editUserRefetch, isLoading: editUserLoading } = useQuery(
        ['edit-single-provider-user-details'],
        () =>
            userType === 'admin-user'
                ? updateAdminUserApi(params?.id, newData)
                : updateProviderUserDetailsApi(params?.id, newData),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setEditModal(false)
                setShowSuccessModal(true)
                setErrors('')
                providerUserDetailsRefetch()
            },
            onError: error => {
                setToastError(true)
                if (error?.response?.data?.errors?.add_role_ids) {
                    setErrorMessage(
                        errorHandler(
                            error?.response?.data?.errors?.add_role_ids,
                        ),
                    )
                } else {
                    setErrorMessage(errorHandler(error?.response?.data))
                }
            },
        },
    )
    // get list of groups available to provider user (permission roles)
    const { refetch: getGroupsRefetch } = useQuery(
        ['get-provider-groups'],
        () =>
            userType === 'admin-user'
                ? getActiveAdminGroupsApi()
                : getActiveProviderGroupsApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const groupData = data?.data?.data
                for (let i = 0; i < groupData?.length; i++) {
                    setGroups(prev => [
                        ...prev,
                        {
                            label: groupData?.[i]?.name,
                            value: groupData?.[i]?.name,
                            id: groupData?.[i]?.id,
                        },
                    ])
                }
            },
            onError: error => {
                setPageToast(true)
                setPageToastMessage(errorHandler(error?.response?.data))
            },
        },
    )

    //deactivate provider user
    const {
        refetch: deactivateProviderUserRefetch,
        isLoading: deactivateProviderUserLoading,
    } = useQuery(
        ['deactivate-provider-user'],
        () =>
            userType === 'admin-user'
                ? deactivateAdminUserApi(params?.id, {
                      reason: actionInputs?.reason,
                  })
                : deactivateProviderUserApi(params?.id, {
                      action: 'DEACTIVATE',
                      reason: actionInputs?.reason,
                  }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeUserActionModal()
                setShowSuccessModal(true)
                providerUserDetailsRefetch()
            },
            onError: error => {
                setActionToast(true)
                setActionToastMessage(errorHandler(error?.response?.data))
            },
        },
    )

    //activate provider user
    const {
        refetch: activateProviderUserRefetch,
        isLoading: activateProviderUserLoading,
    } = useQuery(
        ['activate-provider-user'],
        () =>
            userType === 'admin-user'
                ? activateAdminUserApi(params?.id, {
                      action: 'ACTIVATE',
                      reason: actionInputs?.reason,
                  })
                : reactivateProviderUserApi(params?.id, {
                      action: 'ACTIVATE',
                      reason: actionInputs?.reason,
                  }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                closeUserActionModal()
                setShowSuccessModal(true)
                providerUserDetailsRefetch()
            },
            onError: error => {
                setActionToast(true)
                setActionToastMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        if (userType === 'provider-user' || userType === 'admin-user') {
            providerUserDetailsRefetch()
        }
    }, [providerUserDetailsRefetch, userType])

    useEffect(() => {
        if (userType === 'provider-user' || userType === 'admin-user') {
            userActivitiesRefetch()
        }
    }, [userActivitiesRefetch, userType])

    useEffect(() => {
        getGroupsRefetch()
    }, [getGroupsRefetch, userType])

    const addGroup = useCallback(() => {
        return inputs?.groups?.filter(object => {
            return !savedUserData?.groups?.some(oldObject => {
                return object?.id === oldObject?.id
            })
        })
    }, [inputs?.groups, savedUserData?.groups])

    const removeGroup = useCallback(() => {
        return savedUserData?.groups?.filter(object => {
            return !inputs?.groups?.some(oldObject => {
                return object?.id === oldObject?.id
            })
        })
    }, [inputs?.groups, savedUserData?.groups])

    useEffect(() => {
        if (
            inputs?.firstName !== '' &&
            savedUserData?.firstName !== '' &&
            inputs?.firstName !== savedUserData?.firstName
        ) {
            setNewData(prev => ({
                ...prev,
                first_name: inputs?.firstName,
            }))
        }

        if (
            inputs?.lastName !== '' &&
            savedUserData?.lastName !== '' &&
            inputs?.lastName !== savedUserData?.lastName
        ) {
            setNewData(prev => ({
                ...prev,
                last_name: inputs?.lastName,
            }))
        }

        if (
            inputs?.phoneNumber !== '' &&
            savedUserData?.phoneNumber !== '' &&
            inputs?.phoneNumber !== savedUserData?.phoneNumber
        ) {
            setNewData(prev => ({
                ...prev,
                phone_number: formatPhoneNumber(inputs?.phoneNumber),
            }))
        }

        if (
            inputs?.email !== '' &&
            savedUserData?.email !== '' &&
            inputs?.email !== savedUserData?.email
        ) {
            setNewData(prev => ({
                ...prev,
                email: inputs?.email,
            }))
        }

        if (
            inputs?.role !== '' &&
            savedUserData?.role !== '' &&
            inputs?.role !== savedUserData?.role
        ) {
            setNewData(prev => ({
                ...prev,
                role_name: inputs?.role,
            }))
        }

        if (
            inputs?.password !== '' &&
            savedUserData?.password !== '' &&
            inputs?.password !== savedUserData?.password
        ) {
            setNewData(prev => ({
                ...prev,
                password: inputs?.password,
            }))
        }

        setNewData(prev => ({
            ...prev,
            add_role_ids: addGroup().map(each => each?.id),
        }))

        setNewData(prev => ({
            ...prev,
            remove_role_ids: removeGroup().map(each => each?.id),
        }))
    }, [addGroup, inputs, removeGroup, savedUserData])

    const handleEditModal = () => {
        setEditModal(false)
        setInputs(savedUserData)
        setNewData({})
        setErrors('')
    }

    const closeUserActionModal = () => {
        setShowUserActionModal(false)
        setActionInputs({ reason: '' })
        setErrors({})
    }

    const handleSubmitCallback = () => {
        editUserRefetch()
    }

    const handleActionCallback = () => {
        if (actionType === 'deactivate-user') {
            deactivateProviderUserRefetch()
        }

        if (actionType === 'activate-user') {
            activateProviderUserRefetch()
        }
    }

    const pageLoading =
        providerUserDetailsLoading ||
        providerUserDetailsFetching ||
        userActivitiesLoading

    const userGroup = (
        <div style={{ textTransform: 'capitalize', margin: 0 }}>
            {userData?.groups?.map((u, i) => (
                <span key={u}>
                    {u}
                    {i !== userData?.groups.length - 1 && ', '}
                </span>
            ))}
        </div>
    )

    return (
        <>
            {/*edit user details modal*/}
            <SecondaryModal
                showModal={editModal}
                modalWidth="624px"
                modalHeight={'fit-content'}
                noPadding="48px 80px"
                modalClass="RecommendedEnergy"
                onCancel={() => handleEditModal()}
                content={
                    <EditUserDetails
                        inputs={inputs}
                        setInputs={setInputs}
                        handleSubmitCallback={handleSubmitCallback}
                        errors={errors}
                        setErrors={setErrors}
                        toastError={toastError}
                        errorMessage={errorMessage}
                        groups={groups}
                        loading={editUserLoading}
                        userType={userType}
                    />
                }
            />

            <UserActionModal
                closeModal={closeUserActionModal}
                showModal={showUserActionModal}
                actionType={actionType}
                inputs={actionInputs}
                setInputs={setActionInputs}
                errors={errors}
                setErrors={setErrors}
                handleActionCallback={handleActionCallback}
                isLoading={
                    deactivateProviderUserLoading || activateProviderUserLoading
                }
                actionToast={actionToast}
                actionToastMessage={actionToastMessage}
            />

            <ActionModal
                actionModalOpen={showSuccessModal}
                actionType="success"
                headerText={header}
                subTitle={subtitle}
                actionText="Okay, Got it"
                noCancelBtn
                closable
                onCancel={() => setShowSuccessModal(false)}
                actionHandler={() => setShowSuccessModal(false)}
                noBackLink
            />

            {pageToast && (
                <Toast messageType="error" message={pageToastMessage} />
            )}

            {/*page*/}
            <DetailsPage
                noTopInfo
                useCustomTable
                backNavTitle={
                    userType === 'admin-user' || userType === 'provider-user'
                        ? 'Back to Users'
                        : ''
                }
                backNavOnClick={() => history.goBack()}
                name={userData?.displayName}
                status={
                    userData?.state === 'DELETED' ||
                    userData?.userTypes?.includes('DEACTIVATED_USER')
                        ? 'DEACTIVATED'
                        : 'ACTIVE'
                }
                customTable={
                    <UserActivitiesTable
                        actions={actions}
                        userType={userType}
                        pageLoading={pageLoading}
                        createActivities={createActivities}
                        setCreateActivities={setCreateActivities}
                    />
                }
                rightSectionHeading="User Details"
                rowCount={2}
                gridItemOneHeading="PHONE NUMBER"
                gridItemOneContent={
                    userData?.phoneNumber?.split('-').pop() ?? '-'
                }
                gridItemTwoHeading={
                    userType === 'admin-user' || userType === 'provider-user'
                        ? 'CREATED BY'
                        : 'ADDED BY'
                }
                gridItemTwoContent={userData?.addedBy ?? '-'}
                gridItemThreeHeading="EMAIL ADDRESS"
                gridItemThreeContent={
                    userData?.email
                        ? shortenEmail(userData?.email?.split('-').pop())
                        : '-'
                }
                gridItemFourHeading={
                    userType === 'admin-user' || userType === 'provider-user'
                        ? 'GROUP'
                        : 'ROLE'
                }
                gridItemFourContent={
                    userType === 'admin-user' || userType === 'provider-user'
                        ? userGroup ?? '-'
                        : userData?.role ?? '-'
                }
                actionButton
                secondButton
                buttonOneText="Update Details"
                buttonOneDisabled={
                    !(
                        permissionsControl(['can_update_contact_info']) ||
                        canUpdateAdminUser
                    )
                }
                buttonOneHandleClick={() => {
                    setActionType('edit-user-details')
                    setEditModal(true)
                }}
                userType={userType}
                buttonTwoText={buttonTwoTextValue}
                buttonTwoHandleClick={() => {
                    setActionType(buttonTwoActionTypeValue)
                    setShowUserActionModal(true)
                }}
                buttonTwoDisabled={
                    buttonTwoTextValue === 'Activate User' &&
                    (permissionsControl(['can_activate_provider_user']) ||
                        canActivateAdminUser)
                        ? false
                        : buttonTwoTextValue === 'Deactivate User' &&
                          (permissionsControl(['can_deactivate_user']) ||
                              canDeactivateAdminUser)
                        ? false
                        : true
                }
                isLoading={pageLoading}
            />
        </>
    )
}

AdminUser.propTypes = {
    userType: PropTypes.string.isRequired,
}

export default AdminUser
