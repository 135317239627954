/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useRef, useEffect } from 'react'
import AvatarEditor from 'react-avatar-editor'
import CloseIcon from '../../../../assets/images/closeIcon.svg'
import Zoomin from '../../../../assets/images/zoomin.svg'
import Zoomout from '../../../../assets/images/zoomout.svg'
import RotateLeft from '../../../../assets/images/RotateLeft.svg'
import RotateRight from '../../../../assets/images/rotateRight.svg'
import RotateLeftBlue from '../../../../assets/images/rotate-left-blue.svg'
import RotateRightBlue from '../../../../assets/images/rotate-right-blue.svg'

import RotateLeftWhite from '../../../../assets/images/RotateLeftWhite.svg'
import RotateRightWhite from '../../../../assets/images/RotateRightWhite.svg'
import Button from '../../../../components/Button'

import styles from './imageeditor.module.scss'
import { decodeUserInfo } from 'src/utils/auth'

const ImageEditor = ({
    setShowEditor,
    pictureUrl,
    setEditedPicture,
    type,
    setEnableCamera,
    imageEditorStyle,
    mediumHeight,
    ZoomWrapperStyle,
    triggerReplace,
    page,
    onSave,
    Ref,
}) => {
    const {
        Header,
        EditorWrapper,
        EditorPageContainer,
        Zoom,
        ZoomImg,
        ZoomWrapper,
        FooterWrapper,
        FooterWrapper2,
        RotateText,
        RotateText2,
        HeaderWrapper,
        CloseImg,
        RotateLargeScreen,
        RotateSmallScreen,
        FlexCenter,
        AvatarEditorAbsolute,
        ImageWrapper,
    } = styles

    const [image, setImage] = useState('')
    const [rotate, setRotate] = useState(0)
    const [scale, setScale] = useState(1)
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 })
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const handleScale = value => {
        if (value === '+') {
            setScale(prev => prev + 0.05)
        }
        if (value === '-') {
            setScale(prev => prev - 0.05)
        }
    }

    const handleRotate = value => {
        if (value === 'left') {
            setRotate(prev => prev + 90)
        }
        if (value === 'right') {
            setRotate(prev => prev - 90)
        }
    }

    const onPositionChange = position => {
        setPosition(position)
    }

    const handleSave = () => {
        const canvas = EditorRef.current.getImageScaledToCanvas().toDataURL()
        setImage(canvas)
        setShowEditor(false)
        setEditedPicture(canvas)
        if (onSave) {
            onSave()
        }
    }

    const handleReplace = () => {
        setShowEditor(false)
        triggerReplace()
    }

    const handleRetake = () => {
        setShowEditor(false)
        setEnableCamera(true)
    }

    const EditorRef = useRef(null)

    useEffect(() => setImage(pictureUrl), [pictureUrl])

    const renderSmallScreenRotateButton = () => {
        return (
            <div className={RotateSmallScreen}>
                <div className={FlexCenter}>
                    <div
                        className={FlexCenter}
                        style={{ flexDirection: 'column', marginRight: 30 }}
                        onClick={() => handleRotate('left')}
                    >
                        <img src={RotateLeftWhite} alt="rotate left icon" />
                        <p className={RotateText}>Rotate Left</p>
                    </div>
                    <div
                        className={FlexCenter}
                        style={{ flexDirection: 'column', marginLeft: 30 }}
                        onClick={() => handleRotate('right')}
                    >
                        <img src={RotateRightWhite} alt="rotate right icon" />
                        <p className={RotateText}>Rotate Right</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            className={
                page !== 'sse-identity-information' && EditorPageContainer
            }
        >
            {page !== 'sse-identity-information' && (
                <div className={HeaderWrapper}>
                    <p className={Header}>Edit Photo</p>
                    <img
                        src={CloseIcon}
                        className={CloseImg}
                        onClick={() => setShowEditor(false)}
                        alt="close icon"
                    />
                </div>
            )}

            <div className={AvatarEditorAbsolute}>
                <div
                    className={
                        imageEditorStyle
                            ? imageEditorStyle
                            : page === 'sse-identity-information'
                            ? ImageWrapper
                            : EditorWrapper
                    }
                    style={{
                        height: !imageEditorStyle && mediumHeight ? 'auto' : '',
                        width: !imageEditorStyle && mediumHeight ? '500px' : '',
                    }}
                >
                    <AvatarEditor
                        image={image}
                        width={page === 'sse-identity-information' ? 400 : 250}
                        height={page === 'sse-identity-information' ? 400 : 250}
                        border={50}
                        scale={scale}
                        rotate={rotate}
                        borderRadius={
                            page === 'sse-identity-information' ? 300 : 150
                        }
                        position={position}
                        disableBoundaryChecks={false}
                        onPositionChange={onPositionChange}
                        ref={
                            page === 'sse-identity-information'
                                ? Ref
                                : EditorRef
                        }
                    />
                    {isAdminUser ? null : renderSmallScreenRotateButton()}
                    <div
                        className={
                            ZoomWrapperStyle ? ZoomWrapperStyle : ZoomWrapper
                        }
                    >
                        <button
                            onClick={() => handleScale('-')}
                            disabled={scale < 0.5 ? true : false}
                            className={Zoom}
                        >
                            <img
                                src={Zoomout}
                                className={ZoomImg}
                                alt="zoomout icon"
                            />
                        </button>
                        <button
                            onClick={() => handleScale('+')}
                            disabled={scale >= 5 ? true : false}
                            className={Zoom}
                        >
                            <img
                                src={Zoomin}
                                className={ZoomImg}
                                alt="zoomin icon"
                            />
                        </button>
                    </div>
                </div>
            </div>
            {!isAdminUser && <div
                className={
                    page === 'sse-identity-information'
                        ? FooterWrapper2
                        : FooterWrapper
                }
            >
                {page !== 'sse-identity-information' && (
                    <Button
                        btnBgColor="var(--purple-light)"
                        btnTextColor="var(--blue)"
                        btnTextColorOutline="var(--blue)"
                        btnOutlineColor="var(--purple-light)"
                        btnBgColorOutline="#E2EEFF"
                        type="outline"
                        btnWidth="100px"
                        handleClick={
                            type === 'selfie' ? handleRetake : handleReplace
                        }
                    >
                        {type === 'selfie' ? 'Retake' : 'Replace'}
                    </Button>
                )}
                <div
                    className={RotateLargeScreen}
                    onClick={() => handleRotate('left')}
                >
                    <img
                        src={
                            page === 'sse-identity-information'
                                ? RotateLeftBlue
                                : RotateLeft
                        }
                        alt="rotate left icon"
                    />
                    <p
                        className={
                            page === 'sse-identity-information'
                                ? RotateText2
                                : RotateText
                        }
                    >
                        Rotate Left
                    </p>
                </div>
                <div
                    className={RotateLargeScreen}
                    onClick={() => handleRotate('right')}
                >
                    <img
                        src={
                            page === 'sse-identity-information'
                                ? RotateRightBlue
                                : RotateRight
                        }
                        alt="rotate right icon"
                    />
                    <p
                        className={
                            page === 'sse-identity-information'
                                ? RotateText2
                                : RotateText
                        }
                    >
                        Rotate Right
                    </p>
                </div>
                {page !== 'sse-identity-information' && (
                    <Button
                        btnBgColor="var(--blue)"
                        btnTextColor="var(--white)"
                        btnWidth="100px"
                        handleClick={handleSave}
                    >
                        Save
                    </Button>
                )}
            </div>}
        </div>
    )
}

export default ImageEditor
