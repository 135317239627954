/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import Paragraph from '../../Paragraph'
import './consumerhelpcenter.scss'

const answers = {
    'I don’t have a password. How can I sign in? What is a magic link?':
        'We’ll send you a magic link or one-time passcode after you provide the email address or phone number used when you requested an offer. Magic links are one-time use links sent to your email address during the authentication or sign-in process.',
    'Can I correct my details if I made a mistake?':
        'Yes, but with the help of a SunFi representative. Send an email to hello@sunfi.co describing the issue and we’ll come back with a response.',
    'I verified my account with the wrong phone number or email address. How can I correct it?':
        'Send an email to hello@sunfi.co and a SunFi representative will respond with a solution to your request.',
    'Can I request more than one offer?':
        'Each customer is limited to one offer at a time per site. If you would like to request offers for more than one site, please send your request to hello@sunfi.co and a SunFi representative will contact you with the next steps.',
    'Can I pay off my offer before the last repayment date?':
        'Yes you can. Send an email to hello@sunfi.co with your request.',
    'How can I view my pending repayments?':
        'Click on the repayment schedule tab and enter your login details (phone number or email address) and we’ll send you a One-Time-Password or magic link to access your pending repayments.',
    'Can I view my receipts?':
        'Yes, you can. Click on the repayment tab to sign in to your schedule. Navigate to the View Invoice tab to access your invoices.',
    'What do I need to view my repayment schedule? I didn’t get the confirmation link, what should I do?':
        'You can sign in to view your repayment schedule by providing the email address or phone number used to request an offer. Click on the resend code to link to request another code.',
    'Can I download my receipts?':
        'Click on the repayment schedule tab to sign in to view past payments. Click on the view invoice tab to access the download button.',
    'When will I get feedback about my offer?':
        'You should get feedback on your offer within 24 - 48 hours of submitting your details through the Get New Offer form.',
}

const bulletedList = [
    'Personal details (Name, Phone Number, Email Address, Address)',
    'Employment information',
    'Energy needs etc.',
]

const ProductLibraryAnswer = () => (
    <>
        <Paragraph
            titleStyle="AnswerTitle"
            text="You can request a payment plan by clicking on the Get New Offer button and providing the following information:"
            textStyle="ConsumerAnswerText"
        />

        <div>
            {bulletedList.map((p, i) => (
                <div key={i} style={{ display: 'flex' }}>
                    <div>
                        <svg
                            width="6"
                            height="6"
                            viewBox="0 0 6 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginRight: '15px' }}
                        >
                            <circle
                                cx="3"
                                cy="3"
                                r="3"
                                fill="#011A3C"
                                fillOpacity="0.8"
                            />
                        </svg>
                    </div>
                    <div>
                        <p className="ConsumerAnswerText">{p}</p>
                    </div>
                </div>
            ))}
        </div>

        <p className="AnswerText" style={{ margin: '6px 0 10px' }}>
            Submit provided information and we’ll suggest a few solutions for
            you to choose from.
        </p>
    </>
)

const AnswerComponent = ({ answer }) => (
    <>
        <Paragraph
            titleStyle="AnswerTitle"
            text={answers[answer]}
            size="10px"
            textStyle="AnswerText"
        />
    </>
)

export const categories = [
    {
        category: 'General Questions',
        id: 0,
        questions: [
            {
                question:
                    'I don’t have a password. How can I sign in? What is a magic link?',
                id: 0,
                answer: () => (
                    <AnswerComponent answer="I don’t have a password. How can I sign in? What is a magic link?" />
                ),
            },
            {
                question: 'Can I correct my details if I made a mistake?',
                id: 1,
                answer: () => (
                    <AnswerComponent answer="Can I correct my details if I made a mistake?" />
                ),
            },
            {
                question:
                    'I verified my account with the wrong phone number or email address. How can I correct it?',
                id: 2,
                answer: () => (
                    <AnswerComponent answer="I verified my account with the wrong phone number or email address. How can I correct it?" />
                ),
            },
        ],
    },
    {
        category: 'Common Questions',
        id: 1,
        questions: [
            {
                question: 'How do I request a payment plan?',
                id: 0,
                answer: () => (
                    <ProductLibraryAnswer answer="How do I request a payment plan?" />
                ),
            },
        ],
    },
    {
        category: 'Getting Started',
        id: 2,
        questions: [
            {
                question: 'How do I request a payment plan?',
                id: 0,
                answer: () => (
                    <ProductLibraryAnswer answer="How do I request a payment plan?" />
                ),
            },
            {
                question: 'Can I request more than one offer?',
                id: 1,
                answer: () => (
                    <AnswerComponent answer="Can I request more than one offer?" />
                ),
            },
            {
                question: 'When will I get feedback about my offer?',
                id: 2,
                answer: () => (
                    <AnswerComponent answer="When will I get feedback about my offer?" />
                ),
            },
        ],
    },
    {
        category: 'Repayment Schedule',
        id: 3,
        questions: [
            {
                question:
                    'Can I pay off my offer before the last repayment date?',
                id: 0,
                answer: () => (
                    <AnswerComponent answer="Can I pay off my offer before the last repayment date?" />
                ),
            },
            {
                question: 'How can I view my pending repayments?',
                id: 1,
                answer: () => (
                    <AnswerComponent answer="How can I view my pending repayments?" />
                ),
            },
            {
                question: 'Can I view my receipts?',
                id: 2,
                answer: () => (
                    <AnswerComponent answer="Can I view my receipts?" />
                ),
            },
            {
                question:
                    'What do I need to view my repayment schedule? I didn’t get the confirmation link, what should I do?',
                id: 3,
                answer: () => (
                    <AnswerComponent answer="What do I need to view my repayment schedule? I didn’t get the confirmation link, what should I do?" />
                ),
            },
            {
                question: 'Can I download my receipts?',
                id: 4,
                answer: () => (
                    <AnswerComponent answer="Can I download my receipts?" />
                ),
            },
        ],
    },
]

// AnswerComponent.propTypes = {
//     answer: PropTypes.any,
// }

export const questionDB = [
    { question: 'Twenty one + Ten = ', answer: 31 },
    { question: 'Twenty One - Two = ', answer: 19 },
    { question: 'One + Ten = ', answer: 11 },
    { question: 'Fifty Five + Two = ', answer: 57 },
    { question: 'Thirty + Ten = ', answer: 40 },
    { question: 'Eleven + Eleven = ', answer: 22 },
    { question: 'Twelve + Ten = ', answer: 22 },
    { question: 'Twenty Seven - Six = ', answer: 21 },
    { question: 'Three * Five = ', answer: 15 },
    { question: 'Three + Two = ', answer: 5 },
    { question: 'Eight / Two = ', answer: 4 },
    { question: 'Nine * Ten = ', answer: 90 },
    { question: 'Eleven - Eleven = ', answer: 0 },
    { question: 'Twelve * Ten = ', answer: 120 },
    { question: 'Four / Four = ', answer: 1 },
    { question: 'Six * Five = ', answer: 30 },
    { question: 'Six / Two = ', answer: 3 },
    { question: 'Twelve / Three = ', answer: 4 },
    { question: 'Sixty + Five = ', answer: 65 },
    { question: 'Fifty Five + Seven = ', answer: 62 },
]
