import { useMutation, useQuery } from 'react-query'
import axios from 'src/config/axios'
export const uploadBankStatment = async (payload) => {
  const res = await axios.post(`/credit/sse-bank-statement/estimation/${payload.estimationId}`, payload)
  return res
}

export const uploadAuditReport = async (payload) => {
  const res = await axios.patch(`/consumers/estimations/${payload.estimationId}`, payload)
  return res
}

export const getBanks = async () => {
  const res = await axios.get(`/payments/banks?paginate=false`)
  return res.data
}

export const useGetBanks = () => {
  return useQuery(['all-banks'], getBanks)
}


export const useUploadBankStatement = (options = {}) => {
  return useMutation((bankDetails) =>
    uploadBankStatment(bankDetails), { ...options }
  )
}

export const useUploadAuditReport = (options = {}) => {
  return useMutation((financialReport) =>
    uploadAuditReport(financialReport), { ...options }
  )
}

export const getBankAnalysisResultApi = async (estimationId) => {
  const result = axios.get(`/credit/sse-bank-statement/estimation/${estimationId}/analysis/status`)
  return result
}