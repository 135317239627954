import PropTypes from 'prop-types'
import styles from './modal.module.scss'
import FormTitleBar from 'src/components/FormTitleBar'
import { InputFields } from 'src/components/InputFields'
import Button from 'src/components/Button'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { format, parseISO } from 'date-fns'
import { isMobile } from 'src/utils/mediaQueries'

const ViewRepaymentModal = ({ showModal, closeModal, repaymentDetails }) => {
    const { Wrapper, SmallInputs, EvidenceHeader, Evidence } = styles
    return (
        <div>
            <SecondaryModal
                app="adminsolution"
                showModal={showModal}
                onCancel={closeModal}
                closable
                modalWidth="624px"
                modalHeight="100%"
                content={
                    <div className={Wrapper}>
                        <FormTitleBar
                            title="Payment Information"
                            subtitle="Enter the details of repayment made"
                            fontFamily="SF-Pro-Display-Bold"
                            titleFontSize="20px"
                            marginTop="24px"
                            marginBottom="32px"
                        />

                        <div className={SmallInputs}>
                            <InputFields
                                inputWidth={isMobile ? '100%' : '222px'}
                                prefilled
                                disabled
                                title="Date Paid"
                                value={
                                    repaymentDetails?.date_paid !== undefined &&
                                    format(
                                        parseISO(repaymentDetails?.date_paid),
                                        'd MMMM yyyy',
                                    )
                                }
                            />
                            <InputFields
                                inputWidth={isMobile ? '100%' : '222px'}
                                prefilled
                                disabled
                                title="Time Paid"
                                value={
                                    repaymentDetails?.date_paid !== undefined &&
                                    format(
                                        parseISO(repaymentDetails?.date_paid),
                                        'hh:mm',
                                    )
                                }
                            />
                        </div>
                        <InputFields
                            inputWidth="100%"
                            prefilled
                            disabled
                            title="Repayment Method"
                            value={repaymentDetails?.repayment_method}
                        />

                        {repaymentDetails?.invoice_url !== '' && (
                            <>
                                <h4 className={EvidenceHeader}>
                                    Payment Evidence
                                </h4>
                                <div className={Evidence}>
                                    <h6>evidence.file</h6>
                                    <a
                                        href={`${repaymentDetails?.invoice_url?.secure_url}`}
                                    >
                                        View uploaded file
                                    </a>
                                </div>
                            </>
                        )}

                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '60px',
                                marginBottom: '40px',
                            }}
                        >
                            <Button
                                btnWidth="160px"
                                btnHeight="56px"
                                btnBgColor="#004AAD"
                                btnTextColor="#FFFFFF"
                                fontSize="14px"
                                handleClick={() => closeModal()}
                            >
                                Okay, Got it
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    )
}

ViewRepaymentModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    repaymentDetails: PropTypes.any,
}

export default ViewRepaymentModal
