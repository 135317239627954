import PropTypes from 'prop-types'
import { useState } from 'react'
import { useMutation } from 'react-query'
import CloseIcon from 'src/assets/images/black-close-icon.svg'
import FormTitleBar from 'src/components/FormTitleBar'
import { isMobile } from 'src/utils/mediaQueries'
import styles from './CreditReviewTab.module.scss'
import Button from 'src/components/Button'
import ActionModal from 'src/components/ActionModal'
import { adminUpdateOfferApprovalStateApi } from 'src/api/shoppingExperience/creditDecision'
import { useAppContext } from 'src/appContext/AppContext'
import { InlineLoader } from 'src/components/Loader'
import UploadDocuments from './UploadDocuments'
import { manualReviewSchema } from 'src/utils/validationSchema'
import Tenure from './Tenure'
import {
    documentTypeMapping,
    decisionDataMapping,
    documentUploadMapping,
    titleData,
    subtitleData,
} from './data'
import { TextArea } from 'src/components/InputFields'

const ManualDispute = ({
    closeModal,
    paymentPlans,
    estimationId,
    categoryId,
    setCategoryId,
}) => {
    const {
        DisputeCreditDecisionWrapper,
        DCDCategoryWrapperStyle,
        ManualDCDButtonWrapper,
    } = styles

    const { errorAlert, postDocumentToS3, isUploadingFileUrl } = useAppContext()

    const initialInputs = {
        documentType: '',
        note: '',
        decision: '',
        tenure: '',
        reason: '',
    }

    const [inputs, setInputs] = useState(initialInputs)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [actionType, setActionType] = useState('')
    const [fileValid, setFileValid] = useState(true)
    const [successUpload, setSuccessUpload] = useState([])
    const [errors, setErrors] = useState({})
    const [creditReviewDocuments, setCreditReviewDocuments] = useState([])
    const [eventType, setEventType] = useState('')

    const handleInputChange = event => {
        const { name, value } = event.target
        setErrors(prev => ({ ...prev, [name]: '' }))
        setInputs(prev => ({ ...prev, [name]: value }))
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({ ...prev, [name]: value }))
    }
    const handleBlur = e => {
        const { name, value } = e.target
        setInputs(prev => ({ ...prev, [name]: value.trim() }))
    }

    const selectedPaymentPlanId = paymentPlans?.find(
        plan => plan.tenure === inputs.tenure,
    )?.id

    const payload = {
        reason: inputs?.reason,
        credit_review_documents: creditReviewDocuments,
        status: decisionDataMapping[inputs?.decision],
        selected_payment_plan_id: selectedPaymentPlanId,
    }

    // admin update approval state
    const adminUpdateApprovalState = useMutation({
        mutationKey: ['admin-update-approval-state'],
        mutationFn: payload =>
            adminUpdateOfferApprovalStateApi(estimationId, payload),
        onSuccess: () => {
            if (actionType === 'apply') {
                setActionType('success')
            }
        },
        onError: error => {
            errorAlert(
                error?.response?.data?.message ||
                    'An error occured, please try again later.',
            )
        },
    })

    const updateCreditReviewDoc = (fileUrl, eventType) => {
        const creditReviewDoc = {
            file_url: fileUrl,
            document_type: documentTypeMapping[inputs.documentType],
            note: inputs?.note,
        }
        setCreditReviewDocuments(inputs => [...inputs, creditReviewDoc])
        if (eventType === 'save') {
            setInputs(inputs => ({ ...inputs, documentType: '', note: '' }))
            setSuccessUpload([])
        } else {
            setCategoryId(1)
        }
    }

    //upload to s3
    const handleUploadBankStatementUrl = eventType => {
        postDocumentToS3(
            {
                category: documentUploadMapping[inputs.documentType],
                file: successUpload[0],
            },
            {
                onSuccess: res => {
                    const docUrl = res?.url + res?.fields?.key || ''
                    updateCreditReviewDoc(docUrl, eventType)
                },
                onError: error => {
                    errorAlert(
                        error?.response?.data?.message ||
                            'An error occured, please try again later.',
                    )
                },
            },
        )
    }

    const handleCloseDisputeModal = () => {
        setCategoryId(0)
        setInputs(initialInputs)
        setSuccessUpload([])
        setCreditReviewDocuments([])
        setActionType('')
        setShowConfirmModal(false)
        closeModal()
        actionType === 'success' && window.location.reload()
    }

    const handleDiscard = () => {
        setShowConfirmModal(false)
        handleCloseDisputeModal()
    }

    const handleApplySuccess = () => {
        // make api call to apply changes
        adminUpdateApprovalState.mutate(payload)
    }

    const handleApply = () => {
        setShowConfirmModal(true)
        setActionType('apply')
        closeModal()
    }

    const handleContinue = eventType => {
        setEventType(eventType)
        manualReviewSchema
            .validate(
                {
                    documentType: inputs.documentType,
                    document: successUpload,
                    note: inputs.note,
                    decision: inputs.decision,
                    tenure: inputs.tenure,
                    reason: inputs.reason,
                },
                {
                    context: { categoryId: categoryId },
                    abortEarly: false,
                },
            )
            .then(() => {
                if (categoryId === 0) {
                    if (successUpload.length > 0) {
                        handleUploadBankStatementUrl(eventType)
                    }
                }
                if (categoryId === 1) {
                    setCategoryId(2)
                }
                if (categoryId === 2) {
                    handleApply()
                }
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    return (
        <>
            {/* Modal for discard, apply and success */}
            <ActionModal
                actionModalOpen={showConfirmModal}
                headerText={
                    actionType === 'success'
                        ? 'Decision disputed successfully'
                        : actionType === 'discard'
                        ? 'Confirm you want to discard changes'
                        : 'Confirm you want to apply changes'
                }
                subTitle={
                    actionType === 'success'
                        ? 'You have successfully disputed the credit decision and this consumer is notified'
                        : actionType === 'discard'
                        ? "You'll loose all the changes that you’ve made to the credit decision"
                        : 'Confirm your want to make changes to the consumer’s credit decision'
                }
                closeModal={() => {
                    if (actionType === 'discard') {
                        setActionType('apply')
                    } else {
                        setActionType('discard')
                    }
                }}
                actionHandler={() => {
                    actionType === 'success'
                        ? handleCloseDisputeModal()
                        : actionType === 'discard'
                        ? handleDiscard()
                        : handleApplySuccess()
                }}
                onCancel={() => {
                    setShowConfirmModal(false)
                }}
                actionType={actionType === 'success' ? 'success' : 'warning'}
                actionText={
                    actionType === 'success'
                        ? 'Okay, Got it'
                        : actionType === 'discard'
                        ? 'Confirm'
                        : 'Yes'
                }
                cancelText={actionType === 'discard' ? 'Go back' : 'No'}
                noCancelBtn={actionType === 'success' ? true : false}
                loading={adminUpdateApprovalState.isLoading}
                actionHeaderClassName="ActionHeader"
                noBackLink
                mobileStyle={true}
            />

            <section className={DisputeCreditDecisionWrapper}>
                <div className="ACVAddTopWrapper">
                    <span
                        className="AWCloseIconWrapper"
                        style={{ alignSelf: isMobile ? 'flex-start' : '' }}
                    >
                        <img
                            src={CloseIcon}
                            alt="close icon"
                            onClick={handleCloseDisputeModal}
                            style={{ width: '27px', marginTop: '-10px' }}
                        />
                    </span>
                    <FormTitleBar
                        title={titleData[categoryId]}
                        subtitle={subtitleData[categoryId]}
                        titleFontSize={isMobile ? '14px' : '20px'}
                        marginBottom={isMobile && '0'}
                        subtitleFontSize={isMobile ? '12px' : '15px'}
                        subtitleWidth={!isMobile ? '400px' : '100%'}
                        subtitleLineHeight="normal"
                        subtitleColor="rgba(35, 56, 98, 0.70)"
                        textAlignCenter
                    />
                </div>
                <div
                    className={DCDCategoryWrapperStyle}
                    style={{ height: 'auto' }}
                >
                    {categoryId === 0 ? (
                        <UploadDocuments
                            categoryId={categoryId}
                            inputs={inputs}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            setFileValid={setFileValid}
                            successUpload={successUpload}
                            setSuccessUpload={setSuccessUpload}
                            handleBlur={handleBlur}
                            errors={errors}
                        />
                    ) : categoryId === 1 ? (
                        <Tenure
                            inputs={inputs}
                            handleSelectChange={handleSelectChange}
                            errors={errors}
                            paymentPlans={paymentPlans}
                        />
                    ) : (
                        categoryId === 2 && (
                            <TextArea
                                placeholder="Enter Reason"
                                rows={10}
                                height="146px"
                                name="reason"
                                value={inputs.reason}
                                handleChange={handleInputChange}
                                errorMessage={errors.reason}
                            />
                        )
                    )}
                </div>

                <div className={ManualDCDButtonWrapper}>
                    {categoryId === 2 ? (
                        <Button
                            btnBgColor="#004AAD"
                            btnWidth="160px"
                            btnTextColor="#FFFFFF"
                            btnHeight="56px"
                            disabled={!fileValid}
                            handleClick={() => {
                                handleContinue()
                            }}
                        >
                            Confirm
                        </Button>
                    ) : (
                        <>
                            <Button
                                btnBgColor="#E2EEFF"
                                btnWidth={isMobile ? '150px' : '208px'}
                                btnTextColor="#004AAD"
                                btnHeight="50px"
                                handleClick={() => {
                                    if (categoryId === 0) {
                                        handleContinue('save')
                                    } else {
                                        setCategoryId(0)
                                    }
                                }}
                            >
                                {isUploadingFileUrl && eventType === 'save' ? (
                                    <InlineLoader />
                                ) : categoryId === 1 ? (
                                    'Previous'
                                ) : (
                                    'Add a new document'
                                )}
                            </Button>
                            &nbsp; &nbsp; &nbsp;
                            <Button
                                btnBgColor="#004AAD"
                                btnWidth={isMobile ? '150px' : '208px'}
                                btnTextColor="#FFFFFF"
                                btnHeight="50px"
                                disabled={!fileValid}
                                handleClick={() => {
                                    handleContinue('continue')
                                }}
                            >
                                {isUploadingFileUrl &&
                                eventType === 'continue' ? (
                                    <InlineLoader />
                                ) : categoryId === 1 ? (
                                    'Submit'
                                ) : (
                                    'Continue'
                                )}
                            </Button>
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default ManualDispute
ManualDispute.propTypes = {
    closeModal: PropTypes.func,
    estimationId: PropTypes.string,
    paymentPlans: PropTypes.array,
    categoryId: PropTypes.number,
    setCategoryId: PropTypes.func,
}
