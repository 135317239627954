import { v4 as uuidv4 } from 'uuid'

export const testimonies = [
    {
        id: uuidv4(),
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Default+image+For+providers.png',
        name: 'Engr Olawale Tinuoye (CEM, MBA)',
        testimony:
            "I see SunFi as the future 'Central Energy Bank' that would enhance Nigerian solar project developers to focus more on sustainable solutions.",
        description: 'C.E.O. Renergy Integrated Systems and Projects Limited',
        link_text: 'www.renergy.com.ng',
        link: 'https://www.renergy.com.ng',
        user_type: 'provider',
    },
    {
        id: uuidv4(),
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Leleji+Mudiaga+Herry+testimonial.jpeg',
        name: 'Leleji Mudiaga Harry',
        testimony:
            'Our partnership with SunFi is helping us reach businesses and individuals who prefer a payment plan instead of a one-time payment that may be too hefty for them.',
        description: 'MD/CEO Retailhubsolar.ng',
        // link_text: 'www.retailhubsolar.ng',
        // link: 'https://www.renergy.com.ng',
        user_type: 'provider',
    },
    {
        id: uuidv4(),
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Consumer+Testimonial+image.png',
        name: 'Aniekan Udoh',
        testimony:
            "One day, while surfing the internet, I came across SunFi and decided to try it. I have been using solar for a while now and am really enjoying it. I can't even remember when I last used my generator. I don't even know if it's working anymore because of my solar system.",
        description: '',
        link: '',
        user_type: 'consumer',
    },
    {
        id: uuidv4(),
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Consumer+Testimonial+image.png',
        name: 'Shina Arogundade',
        testimony:
            'I got my mum a 3.5kVA inverter, and she calls to pray for me every week for this gesture. The flexibility of spreading out the payments made this purchase even more manageable for me. It eased the financial burden and has made life more comfortable. So many thanks to SunFi🥂',
        description: '',
        link: '',
        user_type: 'consumer',
    },
    {
        id: uuidv4(),
        img: 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Consumer+Testimonial+image.png',
        name: 'Peter Otabor',
        testimony:
            "I couldn’t be happier because I have been enjoying the incredible benefits of a solar home system through SunFi. Not only have I seen a significant reduction in my electricity bills, but the removal of noisy generators has brought peace and quiet to my home. It's a win-win for my wallet and my sanity.",
        description: '',
        link: '',
        user_type: 'consumer',
    },
]
