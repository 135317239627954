export const initialBusinessInfoData = {
    businessInfo: {
        businessName: '',
        businessType: '',
        businessIndustry: '',
        cacNumber: '',
        numberOfYearsInOperation: '',
        website: '',
        businessDescription: '',
        businessNameConfirmed: '',
        cacNumberConfirmed: '',
        businessRegistrationNumberVerified: false,
        businessNameVerified: false,
    },
    contactInfo: {
        firstName: '',
        lastName: '',
        phone: '',
        phone_verified: false,
        email: '',
        directorsFirstName: '',
        directorsLastName: '',
        directorsPhoneNumber: '',
        directorsEmail: '',
        businessAddress: '',
        businessCity: '',
        businessState: '',
        businessCountry: '',
        installationAddress: '',
        installationCity: '',
        installationState: '',
        installationCountry: '',
        businessAddressAndInstallationAddressAreSame: false,
        confirmAddress: '',
    },
}
