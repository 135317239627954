import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'

import Button from '../../../components/Button'
import { InputFields, SelectField } from '../../../components/InputFields'
import Toast from '../../../components/Toast'
import { InlineLoader } from '../../../components/Loader'

import { SelectCountryCode } from '../../../components/InputFields/SelectCountryCode'
import { CompanyContactValidationSchema } from '../../../utils/validationSchema'
import {
    companyContactApi,
    getDisiOemiInfoApi,
} from '../../../api/companyInfo/index.js'
import styles from './contactinfo.module.scss'
import { DepRoleData } from '../../../utils/Data/roles'
import { formatInputValue, formatPhoneNumber } from '../../../utils/formatting'

const DISIContactInfo = () => {
    const {
        Wrapper,
        Row,
        LeftWrapper,
        Header,
        SubHeader,
        FullName,
        SaveBtn,
        MobileFieldWrapper,
    } = styles

    const [errors, setErrors] = useState({})
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [verifyEmail, setVerifyEmail] = useState(false)
    const [verifyPhone, setVerifyPhone] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [countryCode] = useState('+234')

    const [inputs, setInputs] = useState({
        role: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    })

    const handleInputChange = event => {
        const { name, value } = event.target

        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: formatInputValue(name, value),
        }))
    }

    const handleBlur = event => {
        const { name, value } = event.target

        setInputs(preState => ({
            ...preState,
            [name]: value.trim(),
        }))
    }

    const handleSelectChange = (name, value) => {
        if (value === '') {
            setErrors(prev => ({ ...prev, [name]: 'This field is required' }))
        } else {
            setErrors(prev => ({ ...prev, [name]: '' }))
        }

        setInputs(preState => ({
            ...preState,
            [name]: value,
        }))
    }

    const handleSubmit = () => {
        setToastError(false)
        setErrors({})
        // refetch()

        CompanyContactValidationSchema('profile')
            .validate(inputs, { abortEarly: false })
            .then(() => {
                setErrors('')
                refetch()
            })
            .catch(err => {
                let errList = {}
                err.inner.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const { isFetching, data, refetch } = useQuery(
        'companyContact',
        () =>
            companyContactApi({
                first_name: inputs.firstName,
                last_name: inputs.lastName,
                contact_role: inputs.role,
                email: inputs.email,
                phone_number: formatPhoneNumber(
                    inputs.phoneNumber,
                    countryCode,
                ),
            }),
        {
            enabled: false,
            retry: false,
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to save information. Try Again')
            },
            onSuccess: () => {
                setSuccessMessage('Your profile has been updated')
            },
        },
    )

    const { refetch: getInfoRefetch } = useQuery(
        'getDisiOemiInfoApi',
        () => getDisiOemiInfoApi(),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setInputs(preState => ({
                    ...preState,
                    firstName: data?.data?.data?.user?.first_name,
                    lastName: data?.data?.data?.user?.last_name,
                    phoneNumber: data?.data?.data?.user?.phone_number.replace(
                        countryCode,
                        '',
                    ),
                    email: data?.data?.data?.user?.email,
                    role: data?.data?.data?.contact_role,
                }))
                setVerifyEmail(data?.data?.data?.user?.email_verified)
                setVerifyPhone(data?.data?.data?.user?.phone_verified)
            },
            onError: () => {
                setToastError(true)
                setErrorMessage('Failed to get your information. Refresh Page')
            },
        },
    )

    useEffect(() => {
        setToastError(false)
        getInfoRefetch()

        // eslint-disable-next-line
    }, [])

    return (
        <div className={Wrapper}>
            {toastError ? (
                <Toast messageType="error" message={errorMessage} />
            ) : data ? (
                <Toast messageType="success" message={successMessage} />
            ) : (
                ''
            )}

            <div className={Row}>
                <div className={LeftWrapper}>
                    <p className={Header}>Full Name</p>
                    <p className={SubHeader}>Enter your first and last name</p>
                </div>

                <div className={FullName}>
                    <InputFields
                        inputWidth="185px"
                        title="First Name"
                        name="firstName"
                        handleChange={handleInputChange}
                        value={inputs.firstName}
                        inputValue={inputs.firstName}
                        errorMessage={errors?.firstName}
                        onBlur={handleBlur}
                    />
                    <InputFields
                        inputWidth="185px"
                        title="Last Name"
                        name="lastName"
                        handleChange={handleInputChange}
                        errorMessage={errors?.lastName}
                        inputValue={inputs.lastName}
                        value={inputs.lastName}
                        onBlur={handleBlur}
                    />
                </div>
            </div>
            <div className={Row}>
                <div className={LeftWrapper}>
                    <p className={Header}>
                        {verifyPhone && 'Verified'} Phone Number
                    </p>
                    <p className={SubHeader}>Provide your phone number </p>
                </div>

                <div>
                    <SelectCountryCode
                        inputWidth="389px"
                        title="Phone Number"
                        name="phoneNumber"
                        value={inputs.phoneNumber}
                        inputValue={inputs.phoneNumber}
                        handleChange={handleInputChange}
                        disabled={verifyPhone}
                        prefilled={verifyPhone}
                        errorMessage={errors.phoneNumber}
                        onBlur={handleBlur}
                    />
                </div>
            </div>
            <div className={Row}>
                <div className={LeftWrapper}>
                    <p className={Header}>
                        {verifyEmail && 'Verified'} Email Address
                    </p>
                    <p className={SubHeader}>Enter your email address</p>
                </div>

                <div style={{ width: '389px' }} className={MobileFieldWrapper}>
                    <InputFields
                        inputWidth="100%"
                        title="Email Address"
                        type="email"
                        prefilled={verifyEmail}
                        name="email"
                        disabled={verifyEmail}
                        value={inputs.email}
                        inputValue={inputs.email}
                        handleChange={handleInputChange}
                        errorMessage={errors?.email}
                        onBlur={handleBlur}
                    />
                </div>
            </div>
            <div className={Row}>
                <div className={LeftWrapper}>
                    <p className={Header}>Role</p>
                    <p className={SubHeader}>Please select your role</p>
                </div>

                <div style={{ width: '389px' }} className={MobileFieldWrapper}>
                    <SelectField
                        selectWidth="100%"
                        initialOption={inputs.role || 'Role'}
                        name="role"
                        selectedValue={inputs.role}
                        handleChange={handleSelectChange}
                        values={DepRoleData}
                        currentSelected={inputs.role}
                        withCheckBox={true}
                        errorMessage={errors?.role}
                        dropdownPositionRelative
                    />
                </div>
            </div>
            <div className={SaveBtn}>
                <Button
                    btnBgColor="var(--blue)"
                    btnTextColor="var(--white)"
                    btnWidth="160px"
                    btnHeight="56px"
                    handleClick={handleSubmit}
                >
                    {isFetching ? <InlineLoader /> : 'Save Changes'}
                </Button>
            </div>
        </div>
    )
}

export default DISIContactInfo
