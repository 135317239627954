import { PropTypes } from 'prop-types'
import { ConsumerButton } from 'src/components/Button'
import styles from './profileitem.module.scss'

const ProfileItem = props => {
    return (
        <div className={styles.Container}>
            <div className={styles.ContentBox}>
                <p>{props.title}</p>
            </div>
            <div>
                <ConsumerButton
                    btnBgColor="#E2EEFF"
                    btnTextColor="var(--blue)"
                    btnWidth={'105px'}
                    btnHeight={'37px'}
                    btnFontSize="12px"
                    fontFamily={'SF-Pro-Display-Medium'}
                    handleClick={props.handleClick}
                    hover
                >
                    View profile
                </ConsumerButton>
            </div>
        </div>
    )
}

ProfileItem.propTypes = {
    title: PropTypes.string,
    handleClick: PropTypes.func,
}

export default ProfileItem
