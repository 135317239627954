/* eslint-disable react-hooks/exhaustive-deps */
//TODO: take out disabled eslint and refactor code for best practices in extreme cases
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Drawer, Pagination } from 'antd'
import { NoFLoatingLabelInputFields } from 'src/components/InputFields'
import { AppButton } from 'src/components/Button'
import BackNav from 'src/components/BackNav'
import Toast from 'src/components/Toast'
import { useQuery } from 'react-query'
import {
    getAddedClusterPackagesApi,
    removeClusterPackagesApi,
    getAddedProviderClusterPackagesApi,
    removeAddProviderClusterPackagesApi,
} from 'src/api/admin/credit-variables/detailed-page'
import { NewSolutionSkeletalCards } from 'src/components/SkeletalCards'
import overviewNoActivity from 'src/assets/images/overviewNoActivity.svg'
import close from 'src/assets/images/close-icon.svg'
import ActionModal from 'src/components/ActionModal'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import { errorHandler } from 'src/utils/errorHandler'
import { isMobile } from 'src/utils/mediaQueries'
import LayerIcon from 'src/assets/images/layers.svg'
import plusicon from 'src/assets/images/plus-icon.svg'

import styles from './viewaddedpackages.module.scss'
import './viewaddedpackage.scss'

const ViewAddedPackages = ({
    viewAddedPackages,
    handleCloseViewAddedPackages,
    clusterCode,
    fetchSingleCreditVariable,
    isProvider,
}) => {
    const {
        AddMorePackagesWrapper,
        AddMorePackagesLeftWrapper,
        AddMorePackageImageWrapper,
        AddMorePackagetext,
        MobileWrapper,
        MobileAmountWrapper,
        MobileTitle,
    } = styles
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [toastError, setToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [searchText, setSearchText] = useState('')
    const [addedClusterPackages, setAddedClusterPackages] = useState([])
    const [count, setCount] = useState(0)
    const [selectedPackagesArray, setSelectedPackagesArray] = useState([])
    const [showSaveChangesModal, setShowSaveChangesModal] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [addMorePackages, setAddMorePackages] = useState(false)

    const hideDrawer = () => {
        handleCloseViewAddedPackages()
    }

    const handlePage = pageNumber => {
        setCurrentPage(pageNumber)
    }

    const handleInputChange = e => {
        e.persist()
        setSearchText(e.target.value)
    }

    const handleBlur = e => {
        setSearchText(e.target.value.trim())
    }

    const handleSearch = () => {
        addedClusterPackageRefetch()
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    // get added and unadded cluster packages
    const {
        isFetching: addedClusterPackagesFetching,
        refetch: addedClusterPackageRefetch,
    } = useQuery(
        ['get-added-cluster-package', currentPage],
        () =>
            isProvider
                ? getAddedProviderClusterPackagesApi(
                      searchText ? 1 : currentPage,
                      clusterCode,
                      addMorePackages ? false : true,
                      searchText,
                  )
                : getAddedClusterPackagesApi(
                      searchText ? 1 : currentPage,
                      clusterCode,
                      addMorePackages ? false : true,
                      searchText,
                  ),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setAddedClusterPackages(data?.data?.data)
                setCount(data?.data?.count)
                setTotalPages(data?.data?.total_pages)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    //remove and add cluster packages
    const {
        isFetching: removeClusterPackageFetching,
        refetch: removeClusterPackageRefetch,
    } = useQuery(
        'remove-cluster-package',
        () =>
            isProvider
                ? removeAddProviderClusterPackagesApi(clusterCode, {
                      cluster_code: clusterCode,
                      [addMorePackages ? 'add' : 'remove']:
                          selectedPackagesArray,
                  })
                : removeClusterPackagesApi({
                      cluster_code: clusterCode,
                      [addMorePackages ? 'add' : 'remove']:
                          selectedPackagesArray,
                  }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setShowSaveChangesModal(false)
                setShowSuccessModal(true)
            },
            onError: error => {
                setToastError(true)
                setErrorMessage(error?.response?.data)
            },
        },
    )

    useEffect(() => {
        if (searchText === '') {
            setCurrentPage(1)
            addedClusterPackageRefetch()
        }
    }, [searchText])

    const checkSelected = id => {
        return selectedPackagesArray.find(packageId => packageId === id)
    }
    const handleSelected = id => {
        const filteredId = selectedPackagesArray.find(
            packageId => packageId === id,
        )
        if (filteredId) {
            setSelectedPackagesArray(
                selectedPackagesArray.filter(
                    packageId => packageId !== filteredId,
                ),
            )
        } else {
            setSelectedPackagesArray(prevIds => [...prevIds, id])
        }
    }
    const handleBackToCluster = () => {
        setAddMorePackages(false)
        setSelectedPackagesArray([])
    }
    useEffect(() => {
        setTotalPages(0)
        setAddedClusterPackages([])
        addedClusterPackageRefetch()
    }, [currentPage])

    useEffect(() => addedClusterPackageRefetch(), [addMorePackages])

    const cannotDelete = selectedPackagesArray.length === count

    const handleCannotDelete = () => {
        setShowSaveChangesModal(false)
        setSelectedPackagesArray([])
    }
    return (
        <div>
            <ActionModal
                actionModalOpen={showSaveChangesModal}
                headerText={
                    !addMorePackages && cannotDelete
                        ? 'Warning'
                        : 'Save Changes?'
                }
                subTitle={
                    !addMorePackages && cannotDelete
                        ? `You cannot delete all ${
                              isProvider ? 'provider(s)' : 'package(s)'
                          }`
                        : `You’re about to ${
                              addMorePackages ? 'add' : 'remove'
                          } ${selectedPackagesArray.length} ${
                              isProvider ? 'provider(s)' : 'package(s)'
                          } ${
                              addMorePackages ? 'to' : 'from'
                          } this cluster, would you like to continue?`
                }
                closeModal={() => {
                    setShowSaveChangesModal(false)
                    setSelectedPackagesArray([])
                }}
                actionHandler={() =>
                    !addMorePackages && cannotDelete
                        ? handleCannotDelete()
                        : removeClusterPackageRefetch()
                }
                onCancel={() => {
                    setShowSaveChangesModal(false)
                    setSelectedPackagesArray([])
                }}
                actionType="warning"
                actionText={
                    !addMorePackages && cannotDelete
                        ? 'Okay, Got it'
                        : 'Confirm'
                }
                loading={removeClusterPackageFetching}
                noBackLink
                noCancelBtn={!addMorePackages && cannotDelete ? true : false}
            />
            <ActionModal
                actionModalOpen={showSuccessModal}
                headerText="Changes Saved"
                subTitle={`${isProvider ? 'Provider' : 'Package'} has been ${
                    addMorePackages ? 'added to' : 'removed from'
                } this 
                cluster successfully`}
                closeModal={() => {
                    setShowSuccessModal(false)
                }}
                actionHandler={() => {
                    setShowSuccessModal(false)
                    handleCloseViewAddedPackages()
                    fetchSingleCreditVariable()
                }}
                actionType="success"
                actionText="Okay, Got it"
                loading={removeClusterPackageFetching}
                noCancelBtn
                noBackLink
            />

            <Drawer
                visible={viewAddedPackages}
                closable={false}
                width="450px"
                onClose={hideDrawer}
                className="RecommendedSolutionDrawer"
            >
                <div className={'SelectEnergyCloseAndBackNav'}>
                    <BackNav
                        title={
                            addMorePackages
                                ? `Back to cluster ${
                                      isProvider ? 'providers' : 'packages'
                                  }`
                                : 'Back to Credit Variables'
                        }
                        onClick={() =>
                            addMorePackages
                                ? handleBackToCluster()
                                : hideDrawer()
                        }
                        marginLeft="6px"
                    />
                    <div>
                        <img
                            src={close}
                            alt="close"
                            onClick={() =>
                                addMorePackages
                                    ? handleBackToCluster()
                                    : hideDrawer()
                            }
                        />
                    </div>
                </div>

                <div>
                    <div className="SelectEnergyTitleAndButton">
                        <h1 className={`SelectEnergyTitle ${MobileTitle}`}>
                            {addMorePackages
                                ? `Add new ${
                                      isProvider ? 'providers' : 'packages'
                                  }(${selectedPackagesArray.length})`
                                : `Cluster ${
                                      isProvider ? 'Providers' : 'Packages'
                                  } (${count})`}
                        </h1>
                        <AppButton
                            btnWidth={isMobile ? '130px' : '160px'}
                            btnHeight={'56px'}
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            fontSize={'14px'}
                            handleClick={() => setShowSaveChangesModal(true)}
                            disabled={selectedPackagesArray.length === 0}
                            fontWeight="600"
                        >
                            Save Changes
                        </AppButton>
                    </div>
                    <div className="CRESSearchProductWrapper">
                        <NoFLoatingLabelInputFields
                            TextPrefilled={searchText ? true : false}
                            borderRadius="7px"
                            inputWidth="390px"
                            inputHeight="45px"
                            value={searchText}
                            type="text"
                            iconType="search"
                            prefilled
                            inputPlaceholder={`Search ${
                                isProvider ? 'Providers' : 'Products'
                            }`}
                            style={{ paddingBottom: 7 }}
                            handleChange={handleInputChange}
                            onIconClick={handleSearch}
                            onKeyUp={handleKeyPress}
                            onBlur={handleBlur}
                        />
                    </div>

                    {toastError && (
                        <Toast messageType="error" message={errorMessage} />
                    )}
                    {!addMorePackages && (
                        <section
                            className={AddMorePackagesWrapper}
                            onClick={() => {
                                setAddMorePackages(true)
                                setSelectedPackagesArray([])
                                setSearchText('')
                            }}
                        >
                            <div className={AddMorePackagesLeftWrapper}>
                                <div className={AddMorePackageImageWrapper}>
                                    <img
                                        src={LayerIcon}
                                        alt="add more package icon"
                                    />
                                </div>
                                <p className={AddMorePackagetext}>
                                    Add more{' '}
                                    {isProvider ? 'providers' : 'packages'}
                                </p>
                            </div>
                            <img src={plusicon} alt="plus icon" />
                        </section>
                    )}
                    {addedClusterPackagesFetching ? (
                        <NewSolutionSkeletalCards total={6} />
                    ) : searchText !== '' &&
                      addedClusterPackages.length === 0 ? (
                        <div className="emptyContainer">
                            <img src={overviewNoActivity} alt="no result" />
                            <p className="emptyTitle">No Result Found</p>
                            <p className="emptySubtitle">
                                We can’t find any result for &quot;
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        color: '#011A3C',
                                    }}
                                >
                                    {searchText}
                                </span>
                                &quot;
                            </p>
                        </div>
                    ) : (
                        addedClusterPackages.map(data => {
                            return (
                                <div
                                    key={data.id}
                                    className="CustomRecommendationEnergySolutionWrapper"
                                >
                                    <div
                                        className={`CustomRecommendationEnergySolutionTop ${MobileWrapper}`}
                                        style={{
                                            justifyContent:
                                                isProvider && 'space-between',
                                        }}
                                    >
                                        <h4>
                                            {isProvider
                                                ? data?.company_name
                                                : data?.name}
                                        </h4>
                                        <div
                                            className={`CRESAmountAndFlag ${MobileAmountWrapper}`}
                                            style={{
                                                width: isProvider && 'auto',
                                            }}
                                        >
                                            {!isProvider && (
                                                <p
                                                    style={{
                                                        width: '90px',
                                                        textAlign: 'left',
                                                    }}
                                                >{`₦${currencyFormatter(
                                                    data?.total,
                                                )}`}</p>
                                            )}
                                            <div
                                                style={{
                                                    width: '90px',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <AppButton
                                                    btnWidth="70px"
                                                    btnHeight="32px"
                                                    btnBgColor={
                                                        checkSelected(data.id)
                                                            ? '#004AAD'
                                                            : '#E2EEFF'
                                                    }
                                                    btnTextColor={
                                                        checkSelected(data.id)
                                                            ? '#FFFFFF'
                                                            : '#004AAD'
                                                    }
                                                    fontSize="12px"
                                                    handleClick={() => {
                                                        handleSelected(data.id)
                                                    }}
                                                    fontWeight="600"
                                                >
                                                    {checkSelected(data.id)
                                                        ? addMorePackages
                                                            ? 'Unselect'
                                                            : 'Select'
                                                        : addMorePackages
                                                        ? 'Select'
                                                        : 'Remove'}
                                                </AppButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    )}

                    {addedClusterPackages.length > 0 &&
                    !addedClusterPackagesFetching ? (
                        <div className="EnergyProductPaginationWrapper">
                            <Pagination
                                defaultCurrent={1}
                                defaultPageSize={1}
                                current={currentPage}
                                total={parseInt(totalPages, 10)}
                                showSizeChanger={false}
                                showTitle={false}
                                showLessItems
                                onChange={handlePage}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Drawer>
        </div>
    )
}

ViewAddedPackages.propTypes = {
    viewAddedPackages: PropTypes.bool,
    handleCloseViewAddedPackages: PropTypes.func,
    clusterCode: PropTypes.number,
    fetchSingleCreditVariable: PropTypes.func,
    isProvider: PropTypes.bool,
}
export default ViewAddedPackages
