import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Pagination } from 'antd'
import useGetProfiles from './useGetProfiles'
import { useSelector } from 'react-redux'
import { updatedAdminProfilesFilters } from 'src/redux/reducers/admin/profiles/listing'
import {
    addQueryParamsToUrl,
    getSearchParamsFromUrl,
    removeQueryParamFromUrl,
} from 'src/utils/urlTracker'
import { getKeyByValue } from 'src/utils/formatting'

const useAdminProfiles = () => {
    const consumerProfileTabs = {
        1: 'published',
        2: 'unpublished',
    }
    const filterQuery = getSearchParamsFromUrl()
    const defaultQueries = useSelector(updatedAdminProfilesFilters)
    const [currentTab, setCurrentTab] = useState(
        getKeyByValue(consumerProfileTabs, filterQuery.tab) ||
            defaultQueries.current_tab ||
            '1',
    )
    const [filterState, setFilterState] = useState({
        published: filterQuery.published || defaultQueries.published || true,
        currentPage:
            Number(filterQuery.page) || Number(defaultQueries.page) || 1,
        searchText: filterQuery.search || defaultQueries.name || '',
    })
    const [searchTextInput, setSearchTextInput] = useState(
        filterQuery.search || defaultQueries.name || '',
    )
    const [showProfileCreation, setShowProfileCreation] = useState(false)

    const history = useHistory()

    const { profiles, profilesFetching, totalPages, profilesRefetch } =
        useGetProfiles(filterState, currentTab, setFilterState)

    const handleFilterChange = newFilters => {
        setFilterState(prevState => ({
            ...prevState,
            currentPage: 1,
            ...newFilters,
        }))
    }

    const closeProfileCreationModal = () => {
        setShowProfileCreation(false)
    }

    const displayProfileCreationModal = () => {
        setShowProfileCreation(true)
    }

    const onTabChange = key => {
        setCurrentTab(key)
        const newPublishedValue = key === '1'
        handleFilterChange({
            published: newPublishedValue,
            currentPage: 1,
            searchText: '',
        })
        addQueryParamsToUrl({
            tab: consumerProfileTabs[key],
            page: '1',
        })
        removeQueryParamFromUrl('search')
        setSearchTextInput('')
    }

    const updatePage = page => {
        handleFilterChange({ currentPage: page })
        addQueryParamsToUrl({
            page: page,
        })
    }

    const goToCreateProfile = () => {
        history.push('/admin/consumer-profiles/add-consumer-profile')
    }

    const goToProfileGroups = () => {
        history.push('/admin/consumer-profiles-groups')
    }

    const addSearchTextToFilter = () => {
        if (searchTextInput !== '') {
            handleFilterChange({ searchText: searchTextInput })
            addQueryParamsToUrl({
                search: searchTextInput,
                page: '1',
            })
        }
    }

    const paginationBlock = () => {
        return (
            <>
                {totalPages < 2 ? null : (
                    <Pagination
                        defaultCurrent={1}
                        current={filterState?.currentPage}
                        total={parseInt(totalPages, 10)}
                        defaultPageSize={1}
                        showSizeChanger={false}
                        showTitle={false}
                        showLessItems
                        onChange={updatePage}
                    />
                )}
            </>
        )
    }

    return {
        currentTab,
        profiles,
        profilesFetching,
        onTabChange,
        paginationBlock,
        goToCreateProfile,
        searchTextInput,
        setSearchTextInput,
        handleFilterChange,
        addSearchTextToFilter,
        profilesRefetch,
        goToProfileGroups,
        showProfileCreation,
        closeProfileCreationModal,
        displayProfileCreationModal,
    }
}

export default useAdminProfiles
