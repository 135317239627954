import { useState } from 'react'
import { useQuery } from 'react-query'
import { fetchApprovedBrandsApi } from 'src/api/providers/brand-names'
import { errorHandler } from 'src/utils/errorHandler'

const useFetchApprovedBrands = onError => {
    const [approvedBrands, setApprovedBrands] = useState([])

    const { isFetching } = useQuery({
        queryKey: ['fetch-approved-brands'],
        queryFn: () => fetchApprovedBrandsApi(),
        enabled: true,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        onSuccess: res => {
            const brands = res?.data?.data?.approved_brands
            setApprovedBrands(brands)
        },
        onError: error => {
            onError?.(errorHandler(error?.response?.data))
        },
    })

    return {
        approvedBrands,
        approvedBrandsFetching: isFetching,
    }
}

export default useFetchApprovedBrands
