import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useCreateProfile } from './useCreateProfile'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateConsumerProfile,
    updatedConsumerProfile,
} from 'src/redux/reducers/admin/profiles/create-profile'

export const useAddPackage = () => {
    const dispatch = useDispatch()
    const consumer_profile_data = useSelector(updatedConsumerProfile)
    const [showAddPackageModal, setShowAddPackageModal] = useState(false)
    const [profileData, setProfileData] = useState(null)
    const [selectedPackages, setSelectedPackages] = useState(
        consumer_profile_data?.packages,
    )
    const [parsedCapacities, setParsedCapacities] = useState({
        minCapacity: 0,
        maxCapacity: 0,
    })
    const location = useLocation()
    const history = useHistory()
    const isPrivateProfile =
        consumer_profile_data?.promotional_type?.toLowerCase() === 'private' &&
        consumer_profile_data?.is_promotional === true
    const isPublicProfile =
        consumer_profile_data?.promotional_type?.toLowerCase() === 'public' &&
        consumer_profile_data?.is_promotional === true

    const handleCreateProfileResponse = data => {
        if (data?.data) {
            const id = data.data.id
            history.replace({
                pathname: location.pathname,
                state: {
                    profileData,
                    packages: selectedPackages,
                },
                id: id,
            })

            history.push({
                pathname: `/admin/consumer-profiles/review/${id}`,
            })
        }
    }

    const { handleCreateProfile, isLoading: createProfileLoading } =
        useCreateProfile({
            data: {
                name: profileData?.name,
                customer_type: profileData?.customer_type,
                description: profileData?.description,
                min_capacity: JSON.parse(profileData?.min_capacity ?? '0'),
                max_capacity: JSON.parse(profileData?.max_capacity ?? '0'),
                appliances: profileData?.appliances,
                ...(profileData?.group_id !== '' && {
                    group_id: profileData?.group_id,
                }),
                ...(profileData?.sort_by !== '' && {
                    sort_by: profileData?.sort_by,
                }),
                ...(profileData?.image_url !== '' && {
                    image_url: profileData?.image_url,
                }),
                ...(profileData?.subscription_cluster_code !== '' && {
                    subscription_cluster_code:
                        profileData?.subscription_cluster_code,
                }),
                ...(profileData?.lease_to_own_cluster_code !== '' && {
                    lease_to_own_cluster_code:
                        profileData?.lease_to_own_cluster_code,
                }),

                packages: selectedPackages.map(pkg => ({
                    id: pkg.id,
                    appliances: pkg.appliances,
                    ...(pkg?.subscription_cluster_code !== '' && {
                        subscription_cluster_code:
                            pkg?.subscription_cluster_code,
                    }),
                    ...(pkg?.lease_to_own_cluster_code !== '' && {
                        lease_to_own_cluster_code:
                            pkg?.lease_to_own_cluster_code,
                    }),
                    payment_plan_types: pkg.payment_plan_types,
                })),
            },
            handleResposneData: handleCreateProfileResponse,
        })

    const toggleAddPackageModal = () => {
        setShowAddPackageModal(prev => !prev)
    }

    const goBackNav = () => {
        isPrivateProfile || isPublicProfile
            ? history.push('/admin/consumer-profiles/add-consumer-profile')
            : history.push({
                  pathname:
                      location.state?.backUrl ||
                      '/admin/consumer-profiles/add-consumer-profile',
                  state: {
                      step: 2,
                      profileData: {
                          ...location.state?.profileData,
                          packages: selectedPackages,
                      },
                  },
              })
    }

    const handleConfirmPackages = () => {
        if (isPrivateProfile || isPublicProfile) {
            dispatch(updateConsumerProfile({ packages: selectedPackages }))
            history.push({
                pathname: `/admin/consumer-profiles/add-kyc-information`,
            })
        } else handleCreateProfile()
    }

    useEffect(() => {
        const prevData = location.state?.profileData

        if (!prevData) {
            history.push('/admin/consumer-profiles/add-consumer-profile')
        } else if (!isPrivateProfile || !isPublicProfile) {
            const packagesData = prevData.packages
            setProfileData(prevData)

            if (packagesData) {
                const filteredPackages = [...packagesData].filter(pkg => {
                    if (!pkg.energy_capacity?.value) {
                        return false
                    }

                    const parseValue = parseFloat(pkg.energy_capacity?.value)

                    const parsedMin = JSON.parse(prevData.min_capacity)?.value
                    const parsedMax = JSON.parse(prevData.max_capacity)?.value
                    if (!(parseValue >= parsedMin && parseValue <= parsedMax)) {
                        return false
                    }
                    return true
                })
                setSelectedPackages(filteredPackages)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, history, isPrivateProfile])

    useEffect(() => {
        if (profileData) {
            setParsedCapacities({
                minCapacity:
                    JSON.parse(profileData?.min_capacity)?.value ?? '0',
                maxCapacity:
                    JSON.parse(profileData?.max_capacity)?.value ?? '0',
            })
        } else if (isPrivateProfile || isPublicProfile) {
            setParsedCapacities({
                minCapacity: JSON.parse(consumer_profile_data?.min_capacity)
                    ?.value,

                maxCapacity: JSON.parse(consumer_profile_data?.max_capacity)
                    ?.value,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData, isPrivateProfile])

    return {
        showAddPackageModal,
        toggleAddPackageModal,
        setShowAddPackageModal,
        selectedPackages,
        setSelectedPackages,
        profileData,
        handleConfirmPackages,
        isLoading: createProfileLoading,
        goBackNav,
        parsedCapacities,
    }
}
