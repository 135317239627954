import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ChatCTA from 'src/components/ChatCTA'
import styles from '../solarsystemslistingsv2.module.scss'
import BackNav from 'src/components/BackNav'
import { useSolarSystemsContext } from '../hooks/useSolarSystems'

const Header = () => {
    const profileViewCount = useSelector(
        state => state.consumerProfileView.count,
    )
    const history = useHistory()
    const location = useLocation()

    const { HeaderWrapper } = styles
    const { setShowBuildCustomModal, isPromo, goBack, getEnergyProfileData } =
        useSolarSystemsContext()
    const isAllSystems = location.pathname.includes('/all-systems')
    const isResidential =
        getEnergyProfileData?.data?.data?.customer_type == 'RESIDENTIAL'

    const isAuthenticated = location?.state?.isAuthenticatedUser

    return (
        <div className={HeaderWrapper}>
            <BackNav
                title="Go back"
                v2
                onClick={() => {
                    if (profileViewCount >= 5) {
                        setShowBuildCustomModal({
                            open: true,
                            type: 'go-back',
                        })
                    } else {
                        isAuthenticated
                            ? goBack()
                            : isPromo
                            ? history.goBack()
                            : history.push(
                                  isAllSystems
                                      ? '/'
                                      : isResidential
                                      ? '/consumer/individual-profiles'
                                      : '/consumer/business-profiles',
                              )
                    }
                }}
            />
            <div>
                <ChatCTA />
            </div>
        </div>
    )
}

export default Header
