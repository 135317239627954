export const handleMultipleFilesUpload = (
    fileList,
    setFileValid,
    setUploading,
    message,
    setSuccessUpload,
    acceptableFileTypes,
    maxSize,
) => {
    if (!Object.keys(acceptableFileTypes)?.includes(fileList.type)) {
        message.error(
            `${
                fileList.name
            } is not one of the supported file types (${Object.values(
                acceptableFileTypes,
            )?.join(',')})`,
        )
        setFileValid(false)
        setUploading(false)
        return false
    } else if (!(fileList.size / 1000 / 1000 <= maxSize)) {
        message.error(`${fileList.name} should be less than ${maxSize}mb`)
        setFileValid(false)
        setUploading(false)
        return false
    } else {
        setFileValid(true)
        // setSuccessUpload(prev => [...prev, fileList])
        setSuccessUpload([fileList])
        setUploading(false)
        return true
    }
}

export const validateFileUpload = ({
    file,
    message,
    multiple,
    acceptableFileTypes,
    maxCount,
    maxSize,
    count,
    availableSlotsCount,
    includeStrings,
}) => {
    const maxSizeBytes = maxSize * 1024 * 1024
    const isLessThanMaxSize = file.size <= maxSizeBytes

    if (typeof file === 'string') {
        if (includeStrings) {
            return true
        }

        return false
    }

    if (!Object.keys(acceptableFileTypes)?.includes(file.type)) {
        if (message) {
            message.error(
                `${
                    file.name
                } is not one of the supported file types (${Object.values(
                    acceptableFileTypes,
                )?.join(',')})`,
            )
        }

        return false
    }

    if (!isLessThanMaxSize && (!multiple || count <= availableSlotsCount)) {
        if (message) {
            message.error(`${file.name} is greater than ${maxSize}mb`)
        }

        return false
    }

    if (multiple) {
        if (count === availableSlotsCount + 1) {
            if (message) {
                message.error(`Maximum of ${maxCount} files are allowed`)
            }

            return false
        }
    }

    return true
}
