import PropTypes from 'prop-types'
import styles from './consumerinfotable.module.scss'
import { formatConsumerInfoTableData } from '../../utils/data'

const ConsumerInfoTable = ({ data }) => {
    return (
        <div
            className={styles.consumer_info_wrapper}
            data-testid="consumer-info-wrapper"
        >
            {formatConsumerInfoTableData(data?.form_question)?.map((row, i) => (
                <div key={i} className={styles.consumer_info_row}>
                    {row?.key === 'payment-plan-type' ? (
                        <>
                            <h3>{row?.title}</h3>
                            {row?.value}
                        </>
                    ) : (
                        <>
                            <h4>{row?.title}</h4>
                            <p>{row?.value}</p>
                        </>
                    )}
                </div>
            ))}
            {data?.reason?.length > 0 && (
                <div
                    className={styles.consumer_note}
                    data-testid="consumer-info-additional-info"
                >
                    <h4>Additional Info</h4>
                    <p>{data?.reason}</p>
                </div>
            )}
        </div>
    )
}

ConsumerInfoTable.propTypes = {
    data: PropTypes.object,
}

export default ConsumerInfoTable
