import CtaSection from 'src/components/CtaSection'
import LearnContentDetail from 'src/components/LearnContentDetail'
import SeoComponent from 'src/components/Seo'
import Testimonial from '../Consumer/SmartShoppingExperience/Home/Testimonial/Testimonial'
import PropTypes from 'prop-types'
import styles from '../Learn/learn.module.scss'
import ConsumerFooter from 'src/components/ConsumerFooter'

export function BlogSEOMetaData({
    created,
    updated = '',
    tracker,
    category = 'Learn Content',
    tags = 'solar, clean energy',
}) {
    const pageTitle = 'Learn | SunFi | Simplifying and Scaling Clean Energy',
        description = '',
        image = '',
        imageDescription = '',
        post = {
            created_at: created,
            updated_at: updated || created,
            category: category,
            tags: tags,
        }

    return (
        <SeoComponent
            title={pageTitle}
            tracker={tracker}
            description={description}
            image={image}
            imageDescription={imageDescription}
            post={post}
            keywords="SunFi, buy solar, affordable solar, Price of Solar, Electricity challenge, Solar installment"
        />
    )
}

BlogSEOMetaData.propTypes = {
    created: PropTypes.string,
    updated: PropTypes.string,
    tracker: PropTypes.string,
    category: PropTypes.string,
    tags: PropTypes.string,
}

export function BlogContentData({
    contentId,
    title,
    yesAction,
    noAction,
    feedbackResponse,
}) {
    return (
        <>
            <LearnContentDetail
                contentId={contentId}
                yesAction={yesAction}
                noAction={noAction}
                feedbackResponse={feedbackResponse}
            />
            <CtaSection title={title} />
            <div className={styles.new__testimonial}>
                <Testimonial />
            </div>
            <ConsumerFooter />
        </>
    )
}

BlogContentData.propTypes = {
    contentId: PropTypes.number,
    title: PropTypes.string,
    yesAction: PropTypes.func,
    noAction: PropTypes.func,
    feedbackResponse: PropTypes.boolean,
}
