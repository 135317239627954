export const sortByData = ['Price: low to high', 'Price: high to low', 'All']

export const productFit = {
    TECHNICAL_FIT: 'Technical Fit',
    BUDGET_FIT: 'Budget Fit',
    ECONOMY_FIT: 'Economy Fit',
}

export const sortByParam = {
    All: 'all',
    'Price: low to high': 'ascending',
    'Price: high to low': 'descending',
}

export const showClearFilter = (data, serverFilter) => {
    const systemSizeIsSame =
        data?.system_size[0] === serverFilter?.min_system_size &&
        data?.system_size[1] === serverFilter?.max_system_size
    const result =
        systemSizeIsSame &&
        data?.backup_hours[1] <= serverFilter?.min_backup_hour &&
        data?.cost[1] <= serverFilter?.min_cost &&
        data?.battery_type?.length === 0 &&
        data?.system_type?.length === 0 &&
        data?.payment_plan?.length === 0        
    return result === true ? false : true
}